import { FC, memo } from 'react';
import { Container } from 'components/paywall/templates/Container';
import { ItemWallDialog } from 'components/paywall/pages/ItemDesignList/ItemWallDialog';
import { ItemDesignTable } from 'components/paywall/pages/ItemDesignList/ItemDesignTable';

export const ItemDesignList: FC = memo(() => (
  <Container
    title={<>単品ウォール設定</>}
    label={<ItemWallDialog isAdd buttonName="新規作成" />}
  >
    <ItemDesignTable />
  </Container>
));
