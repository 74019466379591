import { getMember } from 'admin/api/member/getMember';
import { CustomError } from 'admin/error/CustomError';
import { queryMemberKey } from 'admin/hooks/member/queryMemberKey';
import { MemberType } from 'admin/types/member';
import { logger } from 'api/logger';
import { useCustomToast } from 'hooks/useCustomToast';
import { useQuery } from 'react-query';
import { errorToast } from 'utils/toast';

export const useMember = (
  userPoolId: MemberType['userPool'],
  memberId: MemberType['uid'],
) => {
  const toast = useCustomToast();
  const { data } = useQuery({
    queryKey: queryMemberKey.getMember(userPoolId, memberId),
    queryFn: () => getMember({ userPoolId, memberId }),
    onError: async (error) => {
      if (error instanceof CustomError) {
        await logger({
          loglevel: error.logLevel,
          data: JSON.stringify(error.cause),
          message: error.message,
        });
      }
      toast({
        ...errorToast,
        duration: 4000,
        title: '会員詳細情報の取得に失敗しました',
        position: 'bottom',
        variant: 'solid',
      });
    },
  });

  return { data };
};
