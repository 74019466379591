import {
  AlertDialogCloseButton,
  AlertDialogContent,
  AlertDialogHeader,
  AlertDialogOverlay,
  Text,
} from '@chakra-ui/react';
import { StatusChangeForm } from 'admin/components/idPoolConsumer/newsletter/statusChangeForm';
import { NewsletterType } from 'admin/types/userPool/newsletter';
import { FC, memo } from 'react';

type Props = {
  idPoolConsumerId: string;
  target: NewsletterType | undefined;
  onClose: () => void;
};

export const StatusChangeDialog: FC<Props> = memo(
  ({ idPoolConsumerId, target, onClose }: Props) => (
    <>
      <AlertDialogOverlay>
        <AlertDialogContent>
          <AlertDialogHeader p={6}>
            <Text>ステータス変更の確認</Text>
          </AlertDialogHeader>
          <StatusChangeForm
            idPoolConsumerId={idPoolConsumerId}
            onClose={onClose}
            target={target}
          />
          <AlertDialogCloseButton top="24px" right="24px" />
        </AlertDialogContent>
      </AlertDialogOverlay>
    </>
  ),
);
