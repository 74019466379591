/**
 * 空白や空リスト、空オブジェクトなどを判定
 *
 * @param val unknown 検査対象
 * @returns boolean true: blank, false: blankではない
 */
export const isBlank = (val: unknown): boolean => {
  if (val === null || val === undefined || typeof val === 'undefined') {
    return true;
  }

  if (Array.isArray(val) && val.length === 0) {
    return true;
  }

  if (typeof val === 'object' && Object.keys(val).length === 0) {
    return true;
  }

  if (typeof val === 'string' && val === '') {
    return true;
  }

  return false;
};

/**
 * 文字列のパターンを正規表現で判定
 *
 * ptnの設定例1: 半角英字で始まる文字列
 *   isStrTest('any', '^[a-z]')
 *
 * ptnの設定例2: 空白やタブなどスペース的な文字で開始
 *             "\s" ではなく "\\s" とするので注意!
 *
 *   isStrTest('any', '^\\s')
 *
 * @param val string チェック対象文字列
 * @param ptn string 正規表現文字列
 * @returns boolean true: マッチ, false: マッチせず
 */
export const isStrTest = (val: unknown, ptn: string): boolean => {
  if (isBlank(val) || typeof val !== 'string') {
    return false;
  }
  const reg = new RegExp(ptn);

  return reg.test(val);
};

/**
 * 任意の桁数でランダム文字列生成
 * @param len サイズ
 * @returns string
 */
export const randStr = (len = 8): string => {
  const S = 'abcdefghijklmnopqrstuvwxyzABCDEFGHIJKLMNOPQRSTUVWXYZ0123456789';

  return Array.from(Array(len))
    .map(() => S[Math.floor(Math.random() * S.length)])
    .join('');
};

/**
 * ファイルの拡張子取得
 * @param filename ファイル名
 * @returns string 拡張子
 */
export const getFileExt = (filename: string): string => {
  if (!filename || filename.indexOf('.') === -1) {
    return '';
  }
  const fileExt = filename.split('.').pop();

  if (typeof fileExt === 'undefined') {
    return '';
  }

  return fileExt;
};

/**
 * スネークケースをキャメルケースへ変換
 */
export const camelCase = (str: string): string => {
  const words = str.split(/[\W_]+/);
  const camelizedWords = words.map((word, index) => {
    if (index === 0) {
      return word;
    }
    const capitalizedWord = word.charAt(0).toUpperCase() + word.slice(1).toLowerCase();

    return capitalizedWord;
  });

  return camelizedWords.join('');
};

/**
 *キャメルケースをスネークケースへ変換
 */
export const snakeCase = (str: string): string => {
  const words = str.split(/(?=[A-Z])|[\W_]+/);
  const snakeCaseWords = words.map((word) => word.toLowerCase());

  return snakeCaseWords.join('_');
};
