import { Icon, IconProps } from '@chakra-ui/react';
import { FC, memo } from 'react';

interface Props extends IconProps {
  solid?: boolean;
}

export const CircleIcon: FC<Props> = memo(({ solid, ...props }) => {
  if (solid) {
    return (
      <Icon viewBox="0 0 200 200" {...props}>
        <path
          fill="none"
          stroke="currentColor"
          strokeWidth="20"
          d="M 100, 100 m -75, 0 a 75,75 0 1,0 150,0 a 75,75 0 1,0 -150,0"
        />
      </Icon>
    );
  }

  return (
    <Icon viewBox="0 0 200 200" {...props}>
      <path
        fill="currentColor"
        d="M 100, 100 m -75, 0 a 75,75 0 1,0 150,0 a 75,75 0 1,0 -150,0"
      />
    </Icon>
  );
});

// defaultPropsを設定することでエラーを解消
CircleIcon.defaultProps = {
  solid: false,
};
