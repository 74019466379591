import {
  Box, DrawerCloseButton,
  DrawerContent,
  DrawerHeader,
  DrawerOverlay,
  Text
} from '@chakra-ui/react';
import { UserInfoForm } from 'admin/components/idPoolConsumer/userInfo/userInfoForm';
import { UserPool } from 'admin/types/userPool';
import { FC, memo } from 'react';

type Props = {
  userInfoDetail: UserPool | undefined;
  onClose: () => void;
};

export const UserInfoDrawer: FC<Props> = memo(
  ({ userInfoDetail, onClose }: Props) => (
    <>
      <DrawerOverlay />
      <DrawerContent>
        <DrawerHeader pb={0}>
          <Box m={0} px={0} pb="24px" borderBottomWidth="1px">
            <Text>外部IDシステムJSON設定</Text>
          </Box>
        </DrawerHeader>
        <UserInfoForm userInfoDetail={userInfoDetail} onClose={onClose} />
        <DrawerCloseButton top="16px" right="24px" />
      </DrawerContent>
    </>
  ),
);
