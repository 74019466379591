import { VFC, memo } from 'react';
import { Box, Divider, Text } from '@chakra-ui/react';

type Props = {
  index: number;
};

export const PageSplit: VFC<Props> = memo(() => (
  <Box w="100%" py={5} textAlign="left" position="relative">
    <Divider />
    <Text
      style={{
        position: 'absolute',
        top: '50%',
        left: '50%',
        transform: 'translate(-50%, -50%)',
        background: '#fff',
        padding: '10px',
      }}
    >
      ここでページが区切られ、これ以降は別ページになります。
    </Text>
  </Box>
));
