import { WarningTwoIcon } from '@chakra-ui/icons';
import { Box, Button, Tooltip } from '@chakra-ui/react';
import { Colors } from 'components/paywall/config';
import { FC, memo } from 'react';
import { useNavigate } from 'react-router-dom';

type Props = {
  path: string;
  buttonText: string;
  status: number;
  tooltipLabel: string;
};

export const CardHeaderButton: FC<Props> = memo(
  ({ path, buttonText, status, tooltipLabel }: Props) => {
    const navigate = useNavigate();

    return (
      <Box position="relative">
        <Button
          bg={Colors.WHITE}
          borderWidth="1px"
          borderColor={status !== 0 ? Colors.RED : Colors.GRAY_30}
          onClick={() => navigate(path)}
        >
          {buttonText}
        </Button>
        {status !== 0 && (
          <Box
            position="absolute"
            top="0"
            left="-4px"
            transform="translateY(-68%)"
          >
            <Tooltip label={tooltipLabel} bgColor="red.400" color="white">
              <WarningTwoIcon color="red" fontSize={16} mr={1} />
            </Tooltip>
          </Box>
        )}
      </Box>
    );
  },
);
