import { Box, Button, Drawer, Modal, useDisclosure } from '@chakra-ui/react';
import { withSuspenseAndErrorBoundary } from 'admin/components/Error/WithErrorBoundary';
import { TenantUsersDeleteModal } from 'admin/components/tenantUsers/tenantUsersDeleteModal';
import { TenantUsersEditDrawer } from 'admin/components/tenantUsers/tenantUsersEditDrawer';
import { TenantUsersListComponent } from 'admin/components/tenantUsers/tenantUsersList/TenantUsersComponent';
import { Colors } from 'admin/define/colors';
import { useTenantUsers } from 'admin/hooks/tenantUsers/useTenantUsers';
import { useUserCorporationInfo } from 'admin/hooks/user/useUserCorporationInfo';
import {
  TenantUserDeleteStateType,
  TenantUsersType,
} from 'admin/types/tenantUsers';
import { useUserInfo } from 'hooks/useUserInfo';
import { FC, memo, useCallback, useEffect, useMemo, useState } from 'react';

type Props = {
  searchKeyword: string;
  tenantId: string;
};

const FetchTenantUsersList: FC<Props> = memo(
  ({ searchKeyword, tenantId }: Props) => {
    const {
      isOpen: isDrawerOpen,
      onClose: onDrawerClose,
      onOpen: onDrawerOpen,
    } = useDisclosure();
    const {
      isOpen: isModalOpen,
      onClose: onModalClose,
      onOpen: onModalOpen,
    } = useDisclosure();
    const { hasPermissionTenantUser } = useUserCorporationInfo();
    const { isMine } = useUserInfo();
    const [detailTenantUsersId, setDetailTenantUsersId] = useState<string>('');
    const [detailTenantId, setDetailTenantId] = useState<string>('');
    const [tenantUserStateList, setTenantUserStateList] = useState<
      TenantUserDeleteStateType[]
    >([]);
    const { data: tenantUsers } = useTenantUsers();
    const [isAllChecked, setAllChecked] = useState(false);

    const onOpenModalHandler = useCallback(() => {
      onModalOpen();
    }, [onModalOpen]);

    const onOpenDrawerHandler = (id: string, selectTenantId: string) => {
      setDetailTenantUsersId(id);
      setDetailTenantId(selectTenantId);
      onDrawerOpen();
    };

    useEffect(() => {
      if (tenantUsers) {
        const tmpUserStateList = tenantUsers.map((user) => ({
          ...user,
          state: false,
        }));
        setTenantUserStateList(tmpUserStateList);
      }
    }, [tenantUsers]);

    // 個別チェック
    const setTargetCheck = useCallback(
      (user: TenantUsersType): void => {
        const changedCheckItem: TenantUserDeleteStateType[] =
          tenantUserStateList.map((item) => {
            if (
              item.id === user.id &&
              item.tenant.id === user.tenant.id &&
              !isMine(item.id)
            ) {
              return {
                ...item,
                state: !item.state,
              };
            }

            return item;
          });

        setTenantUserStateList(changedCheckItem);

        const allChecked = changedCheckItem.every(
          (val) => !isMine(val.id) && val.state,
        );
        setAllChecked(allChecked);
      },
      [tenantUserStateList, isMine],
    );

    const tenantUsersList = useMemo(() => {
      if (!tenantUserStateList) return [];

      let result = tenantUserStateList;

      if (tenantId) {
        result = tenantUserStateList.filter((x) => x.tenant.id === tenantId);
      }

      const list = result.filter(
        (user) =>
          user.name.indexOf(searchKeyword) !== -1 ||
          user.email.indexOf(searchKeyword) !== -1,
      );

      return list;
    }, [tenantUserStateList, searchKeyword, tenantId]);

    // 削除対象ユーザー一覧取得フィルター処理
    const filterDelTargetUsers = (): TenantUserDeleteStateType[] =>
      tenantUserStateList.filter(
        (item) =>
          item.state &&
          tenantUsersList?.some(
            (user) => !isMine(user.id) && user.id === item.id,
          ),
      );

    // 全チェック
    const setAllCheck = useCallback((): void => {
      const changedCheckItem: TenantUserDeleteStateType[] =
        tenantUserStateList.map((item) => {
          if (isMine(item.id)) {
            return {
              ...item,
              state: false,
            };
          }

          return {
            ...item,
            state: !isAllChecked,
          };
        });

      setTenantUserStateList(changedCheckItem);
      setAllChecked(!isAllChecked);
    }, [tenantUserStateList, isAllChecked, isMine]);

    if (!tenantUsersList) {
      return null;
    }

    return (
      <Box>
        {hasPermissionTenantUser && (
          <Box mb="16px">
            <Button
              variant="outline"
              // カラー定義が決まったら修正する想定
              color={Colors.RED_20}
              borderColor={Colors.RED_20}
              bgColor={Colors.WHITE}
              _hover={{ bg: '#FFF5F5' }}
              _active={{ bg: '#FED7D7' }}
              onClick={onOpenModalHandler}
              isDisabled={filterDelTargetUsers().length === 0}
            >
              ユーザーを削除
            </Button>
          </Box>
        )}
        <TenantUsersListComponent
          isAllChecked={isAllChecked}
          tenantUsersList={tenantUsersList}
          onOpen={onOpenDrawerHandler}
          setAllCheck={setAllCheck}
          setTargetCheck={setTargetCheck}
        />
        <Modal
          isOpen={isModalOpen}
          onClose={onModalClose}
          isCentered
          size="xl"
          closeOnOverlayClick={false}
          closeOnEsc={false}
        >
          <TenantUsersDeleteModal
            onClose={onModalClose}
            deleteList={filterDelTargetUsers()}
          />
        </Modal>
        <Drawer
          isOpen={isDrawerOpen}
          placement="right"
          size="sm"
          onClose={onDrawerClose}
          closeOnOverlayClick={false}
          closeOnEsc={false}
          autoFocus={false}
        >
          <TenantUsersEditDrawer
            tenantUsersId={detailTenantUsersId}
            onClose={onDrawerClose}
            tenantId={detailTenantId}
          />
        </Drawer>
      </Box>
    );
  },
);

export const TenantUsersList =
  withSuspenseAndErrorBoundary(FetchTenantUsersList);
