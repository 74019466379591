import { Box, Stack, StackDivider } from '@chakra-ui/react';
import { withSuspenseAndErrorBoundary } from 'admin/components/Error/WithErrorBoundary';
import { ServiceInfo } from 'admin/components/member/service/serviceInfo';
import { ServiceMemberLists } from 'admin/components/member/service/serviceMemberList';
import { Colors } from 'admin/define/colors';
import { ErrorContents, LoadingSpinner } from 'components/common/atoms';
import { FC, memo } from 'react';

const serviceBody: FC = memo(() => (
  <Box
    backgroundColor={Colors.WHITE}
    p={6}
    mt="16px"
    borderRadius="8px"
    minWidth="858px"
  >
    <Stack spacing={4} divider={<StackDivider borderColor={Colors.GRAY_30} />}>
      <ServiceInfo />
      <ServiceMemberLists />
    </Stack>
  </Box>
));

export const ServiceBody = withSuspenseAndErrorBoundary(serviceBody, {
  ErrorComponent: <ErrorContents name="テナント詳細情報" />,
  LoadingComponent: <LoadingSpinner />,
});
