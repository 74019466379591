import { useDashboard } from 'hooks/paywall/useDashboard';
import { FC, useEffect, useState } from 'react';

export const Frame: FC = () => {
  const results = useDashboard();
  const [dashboardUrl, setDashboardUrl] = useState<string>('');

  useEffect(() => {
    setDashboardUrl(results.url);
  }, [results]);

  return (
    <iframe
      width="100%"
      height="100%"
      style={{
        minHeight: 'calc(100vh - 170px)',
      }}
      src={dashboardUrl}
      title="dashboard"
    />
  );
};
