import { QuestionIcon } from '@chakra-ui/icons';
import {
  Accordion,
  AccordionButton,
  AccordionIcon,
  AccordionItem,
  AccordionPanel, Badge, Box,
  Checkbox,
  Flex,
  Grid,
  Text,
  Tooltip
} from '@chakra-ui/react';
import styled from '@emotion/styled';
import { ErrorTextMsg } from 'components/common/atoms';
import { SelectForm } from 'components/common/molecules';
import { Calendar } from 'components/paywall/atoms/Calendar';
import { Input } from 'components/paywall/atoms/Input';
// import { Select } from 'components/paywall/atoms/Select';
import { Textarea } from 'components/paywall/atoms/Textarea';
import { Colors } from 'components/paywall/config';
import { AmmountWithTax } from 'components/paywall/features/AmmountWithTax';
import { WallItemDefaultValues } from 'components/paywall/pages/WallItemList/initial';
import {
  WallItem,
  WallItemFormType,
  WallItemSellTypeEnum
} from 'components/paywall/pages/WallItemList/typed';
import { format } from 'date-fns';
import { FC, memo, useCallback, useEffect, useState } from 'react';
import { useFormContext } from 'react-hook-form';
import { AiOutlineInfoCircle } from 'react-icons/ai';
import { toErrMsgList } from 'utils/form';

const StyledFormFlex = styled(Flex)({
  alignItems: 'center',
  justifyContent: 'space-between',
  gap: '1rem',
  textAlign: 'center',
});
const StyledToolTip = styled(Tooltip)({
  fontSize: '12px',
  borderRadius: '4px',
});
const StyledQuestionIcon = styled(QuestionIcon)({
  marginLeft: '0.5rem',
  cursor: 'default',
});

const DATETIME_FORMAT = 'yyyy-MM-dd HH:mm';

// 必須表示
const RequiredBadge: FC = () => (
  <Badge
    border="1px solid #EC0000"
    bgColor={Colors.WHITE}
    color={Colors.RED_60}
    borderRadius="4px"
    px="8px"
    minW="56px"
    textAlign="center"
    ml="8px"
  >
    必須
  </Badge>
)

type DartyType = {
  [key in "price" | "sellType" | "period"]: boolean;
}

type Props = {
  isAdd?: boolean;
  obj?: WallItem;
  isDarty: DartyType;
  setIsDarty: React.Dispatch<React.SetStateAction<DartyType>>;
};
export const Form: FC<Props> = memo(({ isAdd, obj, isDarty, setIsDarty }) => {
  const [sellTypeSelected, setSellTypeSelected] = useState<
    '' | WallItemSellTypeEnum
  >('');
  const [startDate, setStartDate] = useState<Date | null>(null);
  const [endDate, setEndDate] = useState<Date | null>(null);
  const [isOgp, setIsOgp] = useState<boolean>(false);
  const [isCheckSkipUrl, setIsCheckSkipUrl] = useState<boolean>(false);
  const [initialDatas, setInitialDatas] = useState<Pick<WallItemFormType, 'price' | 'sellType' | 'period'>>({
    price: 0,
    sellType: 'period',
    period: null
  });
  const {
    setValue,
    getValues,
    register,
    clearErrors,
    watch,
    formState: { errors },
  } = useFormContext<WallItemFormType>();

  /**
   * 初期処理
   */
  useEffect(() => {
    if (!isAdd) {
      setValue('id', obj?.id);
      setValue('wall', obj?.wall || WallItemDefaultValues.wall);
      setValue('wallName', obj?.wallName || WallItemDefaultValues.wallName);
      setValue('name', obj?.name || WallItemDefaultValues.name);
      setValue('url', obj?.url || WallItemDefaultValues.url);
      setValue('price', obj?.price || WallItemDefaultValues.price);
      setValue('sellType', obj?.sellType || WallItemDefaultValues.sellType);
      setValue('period', obj?.period || WallItemDefaultValues.period);
      setValue('sellStart', obj?.sellStart || WallItemDefaultValues.sellStart);
      setValue('sellEnd', obj?.sellEnd || WallItemDefaultValues.sellEnd);
      setValue(
        'systemCode',
        obj?.systemCode || WallItemDefaultValues.systemCode,
      );
      setValue(
        'useOgpFlag',
        obj?.useOgpFlag || WallItemDefaultValues.useOgpFlag,
      );
      setValue(
        'description',
        obj?.description || WallItemDefaultValues.description,
      );
      setValue(
        'thumbnailUrl',
        obj?.thumbnailUrl || WallItemDefaultValues.thumbnailUrl,
      );
      setValue('tags', obj?.tags || WallItemDefaultValues.tags);
      setValue('memo', obj?.memo || WallItemDefaultValues.memo);
      setSellTypeSelected(obj?.sellType || WallItemDefaultValues.sellType);
      setValue('isCheckSkipUrl', !!obj?.isCheckSkipUrl);
      setIsOgp(obj?.useOgpFlag || WallItemDefaultValues.useOgpFlag);
      setIsCheckSkipUrl(!!obj?.isCheckSkipUrl);
      // 初期データセット
      setInitialDatas({
        price: obj?.price || WallItemDefaultValues.price,
        sellType: obj?.sellType || WallItemDefaultValues.sellType,
        period: obj?.period || WallItemDefaultValues.period
      })
    }
    const sDate = getValues('sellStart') || WallItemDefaultValues.sellStart;
    setStartDate(sDate ? new Date(sDate) : null);
    const eDate = getValues('sellEnd') || WallItemDefaultValues.sellEnd;
    setEndDate(eDate ? new Date(eDate) : null);
  }, [getValues, setValue, isAdd, obj]);

  useEffect(() => {
    let tmpIsDarty: DartyType = {
      ...isDarty
    };
    const fname = watch((val, { name }) => {
      if (name === 'sellType') {
        clearErrors('period');
        setSellTypeSelected(val[name] || '');
        if (val[name] === 'indefinite') setValue('period', null);
      }

      if (name === 'price' || name === 'sellType' || name === 'period') {
        tmpIsDarty = {
          ...tmpIsDarty,
          // 意図して厳密等価演算子を利用しない
          // eslint-disable-next-line eqeqeq
          [name]: val[name] != initialDatas[name]
        }
        setIsDarty(tmpIsDarty)
      }
    });

    return () => fname.unsubscribe();
  }, [watch, setValue, clearErrors, setIsDarty, initialDatas, isDarty]);

  const isPeriodCheck = (from: Date, to: Date): boolean =>
    from.getTime() <= to.getTime();

  const onStartDateChange = useCallback(
    (date: Date | null) => {
      setStartDate(date);
      setValue('sellStart', date ? format(date, DATETIME_FORMAT) : null);
      // エラーが発生した際、入力された期間の関係をチェックして、エラーを解除するかどうか制御する
      clearErrors('sellEnd');
      if (date === null || (date && endDate && isPeriodCheck(date, endDate))) {
        clearErrors('sellStart');
      }
    },
    [setValue, clearErrors, endDate],
  );

  const onEndDateChange = useCallback(
    (date: Date | null) => {
      setEndDate(date);
      setValue('sellEnd', date ? format(date, DATETIME_FORMAT) : null);
      // エラーが発生した際、入力された期間の関係をチェックして、エラーを解除するかどうか制御する
      clearErrors('sellStart');
      if (date && startDate && isPeriodCheck(startDate, date)) {
        clearErrors('sellEnd');
      }
    },
    [setValue, clearErrors, startDate],
  );

  return (
    <form>
      <Grid gap={5} mt={2}>
        <Box>
          {isAdd ? (
            <>単品商品の新規作成を行います</>
          ) : (
            <>単品商品の編集を行います</>
          )}
        </Box>
        <Grid gap={2}>
          <Flex alignItems="center" fontWeight={700}>
            名前
            <StyledToolTip
              label="商品名です。ユーザーにも表示されます。"
            >
              <StyledQuestionIcon ml={4} style={{ cursor: 'pointer' }} />
            </StyledToolTip>
            <RequiredBadge />
          </Flex>
          <Input
            defaultValue={isAdd ? WallItemDefaultValues.name : obj?.name}
            ref={register('name', {
              required: '名前を入力してください',
            })}
          />
          {toErrMsgList(errors, 'name').map((err, idx) => (
            <ErrorTextMsg
              key={`error-paywall-promotion-${String(idx)}`}
              msg={err}
            />
          ))}
        </Grid>
        <Grid gap={2}>
          <Flex alignItems="center" fontWeight={700}>
            記事URL
            <StyledToolTip
              label="購入記事のリンク、OCG取得元に利用します。このURLのページでのみユーザーは購入可能です。このURL以外のページで購入させる場合は「記事URL以外のページでの購入オプション」を有効にしてください。"
            >
              <StyledQuestionIcon ml={4} style={{ cursor: 'pointer' }} />
            </StyledToolTip>
            <RequiredBadge />
          </Flex>
          <Textarea
            ref={register('url', {
              required: '記事URLを入力してください',
            })}
            resize='none'
            rows={2}
          />
          {toErrMsgList(errors, 'url').map((err, idx) => (
            <ErrorTextMsg
              key={`error-paywall-wallItem-${String(idx)}`}
              msg={err}
            />
          ))}
        </Grid>
        <Grid gap={2}>
          <Box fontWeight={700}>記事URL以外のページでの購入オプション</Box>
          <Checkbox
            {...register('isCheckSkipUrl')}
            isChecked={isCheckSkipUrl}
            onChange={() => setIsCheckSkipUrl(!isCheckSkipUrl)}
            alignItems="flex-start"
          >
            <Text fontSize={14} mt="-4px">
              記事URL以外のURLでも購入可能にします。記事URL以外のページで購入させる場合には有効にしてください。
            </Text>
          </Checkbox>
          {toErrMsgList(errors, 'isCheckSkipUrl').map((err, idx) => (
            <ErrorTextMsg
              key={`error-paywall-wallItem-${String(idx)}`}
              msg={err}
            />
          ))}
        </Grid>
        <Grid gap={2}>
          <Flex alignItems="center" fontWeight={700}>
            税抜価格
            <RequiredBadge />
          </Flex>
          {!isAdd && (
            <Accordion allowMultiple>
              <AccordionItem>
                <h2>
                  <AccordionButton bg="gray.50" fontSize="sm">
                    <Flex alignItems="center">
                      <AiOutlineInfoCircle />
                      <Text ml={4}>税抜価格について</Text>
                    </Flex>
                    <AccordionIcon />
                  </AccordionButton>
                </h2>
                <AccordionPanel pt={4} pb={4} fontSize="xs">
                  <Text>
                    設定後に新しく購入される方から、新しい価格で決済されます。
                  </Text>
                </AccordionPanel>
              </AccordionItem>
            </Accordion>
          )}
          <StyledFormFlex>
            <Box width="50%">
              <Input
                type="text"
                ref={register('price', {
                  required: '税抜価格を入力してください',
                })}
              />
            </Box>
            <Box width="50%" textAlign="right">
              <AmmountWithTax<WallItem> name="price" />
            </Box>
          </StyledFormFlex>
          {toErrMsgList(errors, 'price').map((err, idx) => (
            <ErrorTextMsg
              key={`error-paywall-wallItem-${String(idx)}`}
              msg={err}
            />
          ))}
        </Grid>
        <Grid gap={2}>
          <Flex alignItems="center" fontWeight={700}>
            販売期間（任意）
            <StyledToolTip
              label="開始日・終了日を指定するとその期間に限って販売可能になります。記入しない場合は開始・終了期間の指定なく販売されます。"
            >
              <StyledQuestionIcon ml={4} style={{ cursor: 'pointer' }} />
            </StyledToolTip>
          </Flex>
          {!isAdd && (
            <Accordion allowMultiple>
              <AccordionItem>
                <h2>
                  <AccordionButton bg="gray.50" fontSize="sm">
                    <Flex alignItems="center">
                      <AiOutlineInfoCircle />
                      <Text ml={4}>販売期間（任意）について</Text>
                    </Flex>
                    <AccordionIcon />
                  </AccordionButton>
                </h2>
                <AccordionPanel pt={4} pb={4} fontSize="xs">
                  <Text>即時に反映されます</Text>
                </AccordionPanel>
              </AccordionItem>
            </Accordion>
          )}
          <Flex alignItems="center">
            <Calendar<WallItemFormType>
              selectedDate={startDate ? new Date(startDate) : null}
              fieldName="sellStart"
              placeholder="販売期間 From"
              onChange={onStartDateChange}
              bgcolor="#fff"
              inputSize="sm"
              isShowTime
              width="100%"
            />
            <span style={{ margin: '0 10px' }}>〜</span>
            <Calendar<WallItemFormType>
              selectedDate={endDate ? new Date(endDate) : null}
              fieldName="sellStart"
              placeholder="販売期間 To"
              onChange={onEndDateChange}
              bgcolor="#fff"
              inputSize="sm"
              isShowTime
              width="100%"
            />
          </Flex>
          {toErrMsgList({ sellStart: errors.sellStart }, 'sellStart').map(
            (err, idx) => (
              <ErrorTextMsg
                key={`error-paywall-wallItem-${String(idx)}`}
                msg={err}
              />
            ),
          )}
          {toErrMsgList({ sellEnd: errors.sellEnd }, 'sellEnd').map(
            (err, idx) => (
              <ErrorTextMsg
                key={`error-paywall-wallItem-${String(idx)}`}
                msg={err}
              />
            ),
          )}
        </Grid>
        <Grid gap={2}>
          <Flex alignItems="center" fontWeight={700}>
            購入後の閲覧期間制限
            <StyledToolTip
              label="購入後に閲覧できる有効期限を設定できます。「閲覧期間制限あり」とした場合には、購入から「閲覧期間の日数」後の23:59を期限として閲覧可能になります。「閲覧期間制限なし」の場合は購入後は期限なく閲覧可能になります。"
            >
              <StyledQuestionIcon ml={4} style={{ cursor: 'pointer' }} />
            </StyledToolTip>
            <RequiredBadge />
          </Flex>
          {!isAdd && (
            <Accordion allowMultiple>
              <AccordionItem>
                <h2>
                  <AccordionButton bg="gray.50" fontSize="sm">
                    <Flex alignItems="center">
                      <AiOutlineInfoCircle />
                      <Text ml={4}>購入後の閲覧期間制限について</Text>
                    </Flex>
                    <AccordionIcon />
                  </AccordionButton>
                </h2>
                <AccordionPanel pt={4} pb={4} fontSize="xs">
                  <Text>
                    購入後の設定変更は、既に購入された方の設定に影響を与えません。たとえば、「閲覧期間制限なし」で購入した場合、後に「閲覧期間制限あり」に変更しても、その方の設定は「閲覧期間制限なし」のままです。
                  </Text>
                </AccordionPanel>
              </AccordionItem>
            </Accordion>
          )}
          <SelectForm<WallItem>
            name="sellType"
            label=""
            placeholder="選択してください"
            attr={{
              required: '',
            }}
            optionList={[
              { val: 'period', name: '期限あり' },
              { val: 'indefinite', name: '無期限' },
            ].map((item) => (
              <option key={`WallItem_sellType_${item.val}`} value={item.val}>
                {item.name}
              </option>
            ))}
          />
          {/* TODO: 2023/03/03
            一旦元々使用していたSelectを利用する
          */}
          {/* <Box>購入タイプ</Box>
          <Select
            selectedValue={obj?.sellType}
            array={[
              { val: '', name: '選択してください' },
              { val: 'period', name: '期間限定' },
              { val: 'indefinite', name: '無期限' },
            ]}
            ref={register('sellType')}
            // disabled={!isAdd}
          />
          {toErrMsgList(errors, 'parent').map((err, idx) => (
            <ErrorTextMsg
              key={`error-paywall-wallItem-${String(idx)}`}
              msg={err}
            />
          ))} */}
        </Grid>
        <Grid gap={2}>
          <Flex alignItems="center" fontWeight={700}>
            閲覧期間の日数
            <StyledToolTip
              label="購入後に閲覧可能となる日数を設定できます。"
            >
              <StyledQuestionIcon ml={4} style={{ cursor: 'pointer' }} />
            </StyledToolTip>
            {/* 閲覧期間制限: 期限ありの時のみ、必須バッジを表示する */}
            { getValues('sellType') === 'period' && (
              <RequiredBadge />
            )}
          </Flex>
          {!isAdd && (
            <Accordion allowMultiple>
              <AccordionItem>
                <h2>
                  <AccordionButton bg="gray.50" fontSize="sm">
                    <Flex alignItems="center">
                      <AiOutlineInfoCircle />
                      <Text ml={4}>閲覧期間の日数について</Text>
                    </Flex>
                    <AccordionIcon />
                  </AccordionButton>
                </h2>
                <AccordionPanel pt={4} pb={4} fontSize="xs">
                  <Text>
                    既に購入された方の閲覧期間は変更されません。設定後に新たに購入される方は新しい設定が適用されます。
                  </Text>
                </AccordionPanel>
              </AccordionItem>
            </Accordion>
          )}
          <Flex>
            <Input
              ref={register('period')}
              disabled={sellTypeSelected === 'indefinite'}
              width="50%"
            />
            <Text pl="8px" mt="9px">
              日間
            </Text>
          </Flex>
          {toErrMsgList(errors, 'period').map((err, idx) => (
            <ErrorTextMsg
              key={`error-paywall-wallItem-${String(idx)}`}
              msg={err}
            />
          ))}
        </Grid>
        <Grid gap={2}>
          <Box fontWeight={700}>OGP利用フラグ</Box>
          <Checkbox
            {...register('useOgpFlag')}
            isChecked={isOgp}
            onChange={() => setIsOgp(!isOgp)}
            alignItems="flex-start"
          >
            <Text fontSize={14} mt="-2px">
              単品記事の購入履歴に表示する記事の説明文章、サムネイルはOGPの値を利用します
            </Text>
          </Checkbox>
          {toErrMsgList(errors, 'useOgpFlag').map((err, idx) => (
            <ErrorTextMsg
              key={`error-paywall-wallItem-${String(idx)}`}
              msg={err}
            />
          ))}
        </Grid>
        {!isOgp && (
          <Grid gap={2}>
            <Flex alignItems="center" fontWeight={700}>
              説明
              <StyledToolTip
                label="購入記事一覧画面などでユーザーに表示される記事説明文です。"
              >
                <StyledQuestionIcon ml={4} style={{ cursor: 'pointer' }} />
              </StyledToolTip>
            </Flex>
            <Textarea ref={register('description')} rows={3}/>
            {toErrMsgList(errors, 'description').map((err, idx) => (
              <ErrorTextMsg
                key={`error-paywall-wallItem-${String(idx)}`}
                msg={err}
              />
            ))}
          </Grid>
        )}
        {!isOgp && (
          <Grid gap={2}>
            <Flex alignItems="center" fontWeight={700}>
              サムネイル画像URL
              <StyledToolTip
                label="購入記事一覧画面などでユーザーに表示される記事サムネイルです。"
              >
                <StyledQuestionIcon />
              </StyledToolTip>
            </Flex>
            <Input
              ref={register('thumbnailUrl')}
              placeholder="https://www.uniikey-cloud.com/thumbnail.jpg"
            />
            {toErrMsgList(errors, 'thumbnailUrl').map((err, idx) => (
              <ErrorTextMsg
                key={`error-paywall-wallItem-${String(idx)}`}
                msg={err}
              />
            ))}
          </Grid>
        )}
        <Grid gap={2}>
          <Flex alignItems="center" fontWeight={700}>
            システム番号
            <StyledToolTip
              label={
                <Text>
                  決済一覧画面でこの商品の決済をフィルターとして利用できます。例えば、基幹システムで発行した「商品ID」などの値を設定可能です。
                  <br />
                  ユーザーには表示されません。
                </Text>
              }
            >
              <StyledQuestionIcon ml={4} style={{ cursor: 'pointer' }} />
            </StyledToolTip>
          </Flex>
          <Input ref={register('systemCode')} />
          {toErrMsgList(errors, 'systemCode').map((err, idx) => (
            <ErrorTextMsg
              key={`error-paywall-wallItem-${String(idx)}`}
              msg={err}
            />
          ))}
        </Grid>
        <Grid gap={2}>
          <Flex alignItems="center" fontWeight={700}>
            タグ
            <StyledToolTip
              label={
                <Text>
                  決済一覧画面で該当の記事カテゴリ等の決済をフィルターとして利用できます。改行区切りで複数のタグが設定できます。
                  <br />
                  ユーザーには表示されません。
                </Text>
              }
            >
              <StyledQuestionIcon ml={4} style={{ cursor: 'pointer' }} />
            </StyledToolTip>
          </Flex>
          <Textarea ref={register('tags')} rows={2}/>
          {toErrMsgList(errors, 'tags').map((err, idx) => (
            <ErrorTextMsg
              key={`error-paywall-wallItem-${String(idx)}`}
              msg={err}
            />
          ))}
        </Grid>
        <Grid gap={2}>
          <Flex alignItems="center" fontWeight={700}>
            メモ
            <StyledToolTip
              label={
                <Text>
                  このモーダル上でのみ表示できるメモ欄です。
                  <br />
                  ユーザーには表示されません。
                </Text>
              }
            >
              <StyledQuestionIcon ml={4} style={{ cursor: 'pointer' }} />
            </StyledToolTip>
          </Flex>
          <Textarea ref={register('memo')} />
          {toErrMsgList(errors, 'memo').map((err, idx) => (
            <ErrorTextMsg
              key={`error-paywall-wallItem-${String(idx)}`}
              msg={err}
            />
          ))}
        </Grid>
      </Grid>
    </form>
  );
});
