import { VFC, memo, useCallback, useEffect } from 'react';
import { Flex, useDisclosure } from '@chakra-ui/react';
import { UserDeleteState, UserTenantDelete } from 'api/user/types';
import { useUserInfo } from 'hooks/useUserInfo';
import { useUserTenantDelete } from 'hooks/user/useUserTenantDelete';
import { SearchForm } from 'components/setting/users/atoms/SearchForm';
import { UsersHeaderInner } from 'components/setting/users/organisms/UsersHeaderInner';

type UsersHeaderProps = {
  userFilter: (keyword: string) => void;
  deleteList: UserDeleteState[];
  refetch: () => void;
};

// 削除処理
const sendData: UserTenantDelete = {
  users: [],
  tenantId: '',
};

export const UsersHeader: VFC<UsersHeaderProps> = memo(
  ({ userFilter, deleteList, refetch }) => {
    const { isOpen, onOpen, onClose } = useDisclosure();
    const { isOwner, userTenant } = useUserInfo();
    const { onDelete, isSuccess, isLoading } = useUserTenantDelete();
    const isDeletable = deleteList.length > 0;

    // 削除ポップアップ表示
    const deleteBtnHandler = useCallback(() => {
      if (isDeletable) onOpen();
    }, [isDeletable, onOpen]);

    // 削除処理
    const submitDeleteBtnHandler = useCallback(() => {
      sendData.users = deleteList.map((val) => val.id);
      sendData.tenantId = userTenant?.id as string;
      onDelete(sendData);
    }, [deleteList, userTenant, onDelete]);

    // 削除キャンセル
    const cancelDeleteBtnHandler = useCallback(() => {
      onClose();
    }, [onClose]);

    // 削除成功後ポップアップを閉じる
    useEffect(() => {
      if (isSuccess) {
        onClose();
        refetch();
      }
    }, [isSuccess, onClose, refetch]);

    return (
      <Flex
        h={12}
        flexBasis={12}
        flexShrink={0}
        w="full"
        alignItems="center"
        justifyContent="space-between"
      >
        {isOwner && (
          <UsersHeaderInner
            deleteList={deleteList}
            isOpen={isOpen}
            onOpen={deleteBtnHandler}
            onClose={onClose}
            cancelBtnHandelr={cancelDeleteBtnHandler}
            isLoading={isLoading}
            submitBtnHandelr={submitDeleteBtnHandler}
          />
        )}
        {/* 検索ボックス */}
        <SearchForm filter={userFilter} />
      </Flex>
    );
  },
);
