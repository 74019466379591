import { Box, Button, Flex, Text } from '@chakra-ui/react';
import { withSuspenseAndErrorBoundary } from 'admin/components/Error/WithErrorBoundary';
import { Colors } from 'admin/define/colors';
import { useIdPoolConsumerId } from 'admin/hooks/useIdPoolConsumerId';
import { ErrorContents } from 'components/common/atoms';
import { useCustomNavigate } from 'hooks/useCustomNavigate';
import { FC, memo } from 'react';
import { MdOutlineAdd } from 'react-icons/md';

const formGroupTitle: FC = memo(() => {
  const idPoolConsumerId = useIdPoolConsumerId();
  const { navigate } = useCustomNavigate({});

  return (
    <Box mt="40px">
      <Flex justifyContent="flex-end">
        <Box mr="auto">
          <Text
            fontFamily="Hiragino Sans"
            fontSize="18px"
            fontWeight="700"
            lineHeight="170%"
          >
            フォーム設問グループ設定
          </Text>
        </Box>
        <Button
          colorScheme="primary"
          p="8px 16px"
          onClick={() => navigate(`/admin/idPoolConsumer/${idPoolConsumerId}/formGroup/detail`)}
        >
          <MdOutlineAdd />
          新規作成
        </Button>
      </Flex>
      <Box mt="16px">
        <Text
          fontWeight="400"
          fontSize="14px"
          lineHeight="170%"
          fontFamily="Hiragino Sans"
          color={Colors.GRAY_00}
        >
          指定した設問パーツを一つにまとめて利用するフォーム設問グループを設定します。
          <br />
          <Text
            as="span"
            fontWeight="700"
          >
            ステータスを「有効」
          </Text>
          にすることで、フォーム作成時に利用できるようになります。
        </Text>
      </Box>
    </Box>
  );
});

export const FormGroupTitle = withSuspenseAndErrorBoundary(formGroupTitle, {
  ErrorComponent: <ErrorContents name="フォーム設問グループ設定タイトル" />,
  LoadingComponent: <></>,
});
