import { Box, Tbody } from '@chakra-ui/react';
import { LoadingSkeleton } from 'components/common/atoms';
import { Table, Thead } from 'components/paywall/features/Table';
import { CustomErrorBoundary } from 'components/paywall/molecules/CustomErrorBoundary';
import { MembersOnlyDialog } from 'components/paywall/pages/MembersOnlyWallSetting/MembersOnlyDialog';
import { MembersOnlyWallSettingColumns } from 'components/paywall/pages/MembersOnlyWallSetting/MembersOnlyWallSettingColumns';
import { MembersOnlyWallSettingRows } from 'components/paywall/pages/MembersOnlyWallSetting/MembersOnlyWallSettingRows';
import { Container } from 'components/paywall/templates/Container';
import { FC, memo, Suspense } from 'react';

export const MembersOnlyWallSetting: FC = memo(() => (
  <Container
    title={<>会員限定ウォール設定</>}
    label={<MembersOnlyDialog isAdd buttonName="新規作成" />}
  >
    <Box mt="40px">
      <Table>
        <Thead>
          <MembersOnlyWallSettingColumns />
        </Thead>
        <Tbody>
          <CustomErrorBoundary pageTitle="会員限定ウォール設定">
            <Suspense
              fallback={
                <tr>
                  <td colSpan={4}>
                    <LoadingSkeleton />
                  </td>
                </tr>
              }
            >
              <MembersOnlyWallSettingRows />
            </Suspense>
          </CustomErrorBoundary>
        </Tbody>
      </Table>
    </Box>
  </Container>
));
