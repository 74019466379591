import { VFC } from 'react';
import { Box, Tooltip } from '@chakra-ui/react';
import { WarningTwoIcon, RepeatIcon } from '@chakra-ui/icons';

export const TableTreeError: VFC = () => (
  <Box display="flex" alignItems="center" justifyContent="space-between" pr={4}>
    <Box ml={1} display="flex" alignItems="center">
      <WarningTwoIcon color="yellow.600" />
      <Box ml={2}>コンテンツ取得エラー</Box>
    </Box>
    <Tooltip label="リロード" fontSize="xs" bg="gray.600">
      <RepeatIcon cursor="pointer" onClick={() => window.location.reload()} />
    </Tooltip>
  </Box>
);
