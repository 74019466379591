import {
  HTTP_STATUS_FORCE_LOGOUT,
  HTTP_STATUS_TENANT_NOT_AVAILABLE,
} from 'define';

export const getLogLevel = (status?: number) => {
  if (status && status < 400) {
    return 'info';
  }
  if (status === HTTP_STATUS_FORCE_LOGOUT) {
    return 'info';
  }
  if (status === HTTP_STATUS_TENANT_NOT_AVAILABLE) {
    return 'warning';
  }
  if (status === 404) {
    return 'warning';
  }
  if (status === 403) {
    return 'warning';
  }

  return 'error';
};

export const isForceLogout = (status: number) =>
  status === HTTP_STATUS_FORCE_LOGOUT;
