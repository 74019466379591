import { SettingMaskingTextComponent } from 'admin/components/service/auth/settingMaskingText/SettingMaskingTextComponent';
import { FC, memo, useCallback, useMemo, useState } from 'react';

type Props = {
  text: string;
};

export const SettingMaskingText: FC<Props> = memo(({ text }: Props) => {
  const [isVisibility, setIsVisibility] = useState<boolean>(false);

  const onClickHandler = useCallback(() => {
    setIsVisibility(!isVisibility);
  }, [isVisibility]);

  const viewText = useMemo<string>(() => {
    if (!isVisibility) {
      return new Array(text.length + 1).join('･');
    }

    return text;
  }, [isVisibility, text]);

  return (
    <SettingMaskingTextComponent
      onClickIsVisibility={onClickHandler}
      viewText={viewText}
      isVisibility={isVisibility}
    />
  );
});
