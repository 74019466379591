import { VFC } from 'react';
import { useForm, FormProvider } from 'react-hook-form';
import { Stack } from '@chakra-ui/react';
import { EmailIcon } from '@chakra-ui/icons';
import { IoPersonOutline } from 'react-icons/io5';
import { useSetPageTitle } from 'hooks/useSetPageTitle';
import { InputText } from 'components/common/atoms/InputText';
import { InputTextForm } from 'components/common/atoms/InputTextForm';

const validateDisplayName = {
  required: '表示名を入力してください',
};
const validateAge = {
  min: {
    value: 3,
    message: '3以上を入力してください',
  },
};

type FormParam = {
  displayName: string;
  age: number;
};

export const Api: VFC = () => {
  useSetPageTitle('API設定画面');
  const methods = useForm<FormParam>({
    mode: 'onBlur',
  });

  return (
    <FormProvider {...methods}>
      <form>
        <Stack spacing={8}>
          <InputTextForm<FormParam>
            name="displayName"
            icon={IoPersonOutline}
            placeholder="表示名"
            label="input-displayname"
            labelCaption="表示名"
            value="表示名"
            attr={validateDisplayName}
          />
          <InputTextForm<FormParam>
            name="age"
            icon={IoPersonOutline}
            placeholder="年齢"
            label="input-age"
            labelCaption="年齢"
            value={0}
            attr={validateAge}
          />
          <InputText
            name="email"
            icon={EmailIcon}
            placeholder="メールアドレス"
            label="input-mail"
            labelCaption="メールアドレス"
            type="email"
            errors={['入力してください', '3文字以上で入力してください']}
          />
        </Stack>
      </form>
    </FormProvider>
  );
};
