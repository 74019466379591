import { Box, Button, Flex, Link, Text } from '@chakra-ui/react';
import { Colors } from 'admin/define/colors';
import { FC, memo } from 'react';
import { MdKeyboardArrowRight, MdOutlineAdd } from 'react-icons/md';
import { NavigateFunction } from 'react-router-dom';

type Props = {
  onOpen: () => void;
  navigate: NavigateFunction;
};

export const AttributeTitleComponent: FC<Props> = memo(
  ({ onOpen, navigate }: Props) => (
    <Box mt="40px">
      <Flex justifyContent="flex-end">
        <Box mr="auto">
          <Text
            fontFamily="Hiragino Sans"
            fontSize="18px"
            fontWeight="700"
            lineHeight="170%"
          >
            属性設定
          </Text>
        </Box>
        <Button
          variant="outline"
          colorScheme="primary"
          bgColor={Colors.WHITE}
          p="8px 16px"
          mr="8px"
          onClick={() => navigate('group')}
        >
          属性グループ設定へ
          <Text as="span" ml="4px">
            <MdKeyboardArrowRight fontSize="18px" />
          </Text>
        </Button>
        <Button colorScheme="primary" p="8px 16px" onClick={onOpen}>
          <MdOutlineAdd />
          属性作成
        </Button>
      </Flex>
      <Box mt="16px">
        <Text
          fontWeight="400"
          fontSize="14px"
          lineHeight="170%"
          fontFamily="Hiragino Sans"
          color={Colors.GRAY_00}
        >
          登録されている組織レベル属性を確認できます。詳しくは
          <Link
            href="https://uniikey.notion.site/cd028b76235f41339a5a4a759ac352cd"
            color={Colors.BLUE_20}
            target="_blank"
            textDecoration="underline"
            fontWeight="bold"
          >
            属性設定の使い方
          </Link>
          を参照してください。
          <br />
          新規作成した属性のステータスは必ず
          <Text as="span" fontWeight="700">
            「下書き」
          </Text>
          になります。属性を利用可能な状態にするには、
          <Text as="span" fontWeight="700">
            「下書き」ボタン
          </Text>
          をクリックし、ステータスの変更を行う必要があります。
        </Text>
      </Box>
    </Box>
  ),
);
