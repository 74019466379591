import { customErrorMessage, toZod } from 'admin/config/zod';
import { SamlSettingFormType, SamlSettingType } from 'admin/types/saml';
import { z } from 'zod';
// zodErrorの日本語化対応
z.setErrorMap(customErrorMessage);

export const samlSettingSchema = z.object<toZod<SamlSettingFormType>>({
  domains: z
    .array(
      z.object({
        url: z.string().min(1, '対象ドメインは必須です'),
      }),
    )
    .superRefine((value, ctx) => {
      const urlSet = new Set<string>();
      value.forEach((item, index) => {
        if (urlSet.has(item.url)) {
          ctx.addIssue({
            code: 'custom',
            message: '対象ドメインが重複しています',
            path: [index, 'url'],
            fatal: true,
          });
        }
        urlSet.add(item.url);
      });
    }),
  entityId: z.string().min(1, '識別子URLは必須です'),
  singleSignOnUrl: z.string().min(1, 'ログインURLは必須です'),
  responseCertificate: z.string().min(1, 'X.509 証明書は必須です'),
});

export const samlSettingResponseSchema = z.object<toZod<SamlSettingType>>({
  domains: z.array(z.string()),
  entityId: z.string(),
  singleSignOnUrl: z.string(),
  responseCertificate: z.string(),
  info: z.object({
    assertionUrl: z.string(),
    entityUrl: z.string(),
  }).optional()
});

export const domainSchema = samlSettingResponseSchema.shape.domains;


export const samlSettingAgreeSchema = z.object({
  word: z.string().refine((val) => val === 'saml', {
    message: '"saml"と入力してください',
  }),
});
