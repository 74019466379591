import { request } from 'admin/api/Request';
import { API_MESSAGES } from 'admin/define/apiMessages';
import { CustomError } from 'admin/error/CustomError';
import { userInviteCorporationSchema } from 'admin/schema/user';
import { InviteUserCorporationResponseType } from 'admin/types/user';
import {
  InviteCorporationForm,
  InviteCorporationSubmitForm,
} from 'admin/types/user/form';
import { isResponseError } from 'api/types';
import { Options } from 'ky';
import { getValidationError } from 'utils/form';
import { ZodError } from 'zod';

export const postUserInviteCorporation = async (
  data: InviteCorporationForm,
  options?: Options,
): Promise<InviteUserCorporationResponseType> => {
  const params = data;
  const path = 'user/invite_corporation/';
  const response = await request<InviteUserCorporationResponseType>({
    path,
    method: 'post',
    body: params,
    options,
  });

  if (response.hasError) {
    if (response.status === 404) {
      throw new CustomError(API_MESSAGES.USER.INVITE_CORPORATION.FAILED, {
        path,
        options,
        params,
        status: response.status,
      });
    } else if (isResponseError(response.error)) {
      const customError = response.error;

      const formError = getValidationError<InviteCorporationSubmitForm>({
        formData: data,
        response: customError,
      });

      throw new CustomError(API_MESSAGES.USER.INVITE_CORPORATION.ERROR, {
        path,
        options,
        params,
        errors: formError,
        logLevel: response.status === 400 ? 'warning' : 'error',
      });
    } else {
      throw new CustomError(API_MESSAGES.USER.INVITE_CORPORATION.ERROR, {
        path,
        status: response.status,
      });
    }
  }
  try {
    userInviteCorporationSchema.parse(response.data);
  } catch (error) {
    if (error instanceof ZodError) {
      throw new CustomError(error.message, {
        path,
        status: response.status,
        logLevel: 'error',
      });
    }
  }

  return response.data;
};
