export const messages = {
  // メール設定取得
  getMailSetting: {
    customer: {
      notFound: 'メール設定が存在しません。既に削除された可能性があります。',
      error:
        '申し訳ございません。メール設定が取得できません。時間を置いて再表示をお願いします。',
      typeError: '申し訳ありません。正しいメール設定を得ることができません。',
    },
    system: {
      typeError:
        '[api.transactionMail.getMailSetting] メール設定取得APIのレスポンス型が予期せぬデータ型です。フロントの修正をしてください',
    },
  },
  // 送信内容設定一覧取得
  getSendingContentSettingsList: {
    customer: {
      notFound:
        '送信内容設定一覧が存在しません。既に削除された可能性があります。',
      error:
        '申し訳ございません。送信内容設定一覧が取得できません。時間を置いて再表示をお願いします。',
      typeError:
        '申し訳ありません。正しい送信内容設定一覧を得ることができません。',
    },
    system: {
      typeError:
        '[api.transactionMail.getSendingContentSettingsList] 送信内容設定一覧APIのレスポンス型が予期せぬデータ型です。フロントの修正をしてください',
    },
  },
  // 送信内容設定一覧取得
  getSendingContent: {
    customer: {
      notFound:
        '送信内容設定詳細が存在しません。既に削除された可能性があります。',
      error:
        '申し訳ございません。送信内容設定詳細が取得できません。時間を置いて再表示をお願いします。',
      typeError:
        '申し訳ありません。正しい送信内容設定一覧を得ることができません。',
    },
    system: {
      typeError:
        '[api.transactionMail.getSendingContent] 送信内容設定詳細APIのレスポンス型が予期せぬデータ型です。フロントの修正をしてください',
    },
  },
  // メール設定登録
  createMailSetting: {
    customer: {
      error: '申し訳ございません。メール設定の登録に失敗しました。',
      failed:
        'メール設定の登録に失敗しました。恐れ入りますが時間をおいて再度登録してください。',
    },
    system: {
      typeError:
        '[api.transactionMail.createMailSetting] 予期せぬエラーが発生しました。',
    },
  },
  // メール設定変更
  editMailSetting: {
    customer: {
      error: '申し訳ございません。メール設定の保存に失敗しました。',
      failed:
        'メール設定の保存に失敗しました。恐れ入りますが時間をおいて再度保存してください。',
    },
    system: {
      typeError:
        '[api.transactionMail.editMailSetting] 予期せぬエラーが発生しました。',
    },
  },
  // メール設定ステータス変更
  changeMailSettingStatus: {
    customer: {
      error: '申し訳ございません。メール設定のステータスの変更に失敗しました。',
      failed:
        'メール設定のステータスの変更に失敗しました。恐れ入りますが時間をおいてステータスを変更してください。',
    },
    system: {
      typeError:
        '[api.transactionMail.changeMailSettingStatus] 予期せぬエラーが発生しました。',
    },
  },
  // 送信内容設定登録
  createSendingContent: {
    customer: {
      error: '申し訳ございません。送信内容設定の登録に失敗しました。',
      failed:
        '送信内容設定の登録に失敗しました。恐れ入りますが時間をおいて再度登録してください。',
    },
    system: {
      typeError:
        '[api.transactionMail.createSendingContent] 予期せぬエラーが発生しました。',
    },
  },
  // 送信内容設定変更
  editSendingContent: {
    customer: {
      error: '申し訳ございません。送信内容設定の保存に失敗しました。',
      failed:
        '送信内容設定の保存に失敗しました。恐れ入りますが時間をおいて再度保存してください。',
    },
    system: {
      typeError:
        '[api.transactionMail.editSendingContent] 予期せぬエラーが発生しました。',
    },
  },
  // 送信内容設定ステータス変更
  changeSendingContentStatus: {
    customer: {
      error: '申し訳ございません。メール設定のステータスの変更に失敗しました。',
      failed:
        'メール設定のステータスの変更に失敗しました。恐れ入りますが時間をおいてステータスを変更してください。',
    },
    system: {
      typeError:
        '[api.transactionMail.changeMailSettingStatus] 予期せぬエラーが発生しました。',
    },
  },
  // メール設定変更
  sendTestMail: {
    customer: {
      error: '申し訳ございません。テストメールの送信に失敗しました。',
      failed:
        'テストメールの送信に失敗しました。恐れ入りますが時間をおいて再度保存してください。',
    },
    system: {
      typeError:
        '[api.transactionMail.sendTestMail] 予期せぬエラーが発生しました。',
    },
  },
};
