import { customErrorMessage, toZod } from 'admin/config/zod';
import { UserPool, UserPoolsCreatedCount } from 'admin/types/userPool';
import { z } from 'zod';

// zodErrorの日本語化対応
z.setErrorMap(customErrorMessage);

export const baseUserPoolSchema = z.object<toZod<Omit<UserPool, 'id'>>>({
  name: z.string(),
  authenticationType: z.number(),
  isSelfRegistration: z.boolean(),
  fieldName: z.string().nullable(),
  userPoolGroup: z.string(),
  userInfoResponse: z.string().nullable(),
  authHubDomain: z.string().nullable(),
  authHubDuration: z.number().nullable(),
  recaptchaSecretKey: z.string().nullable(),
  recaptchaSiteKey: z.string().nullable(),
  recaptchaLoginFailureLimit: z.number().nullable(),
});

export const userPoolSchema = baseUserPoolSchema.extend<
  toZod<Pick<UserPool, 'id'>>
>({
  id: z.string(),
});

export const userPoolsSchema = z.array(userPoolSchema);

export const userPoolsCreatedCountSchema = z.object<
  toZod<UserPoolsCreatedCount>
>({
  max: z.number(),
  count: z.number(),
});
