import { VFC } from 'react';
import { FormControl, FormLabel } from '@chakra-ui/react';
import { InputTextForm } from 'components/common/atoms';
import { ContentsDbTblFormType } from 'api/contents/types';

const tblTitleAttr = {
  required: 'テーブルタイトルを入力してください',
  maxLength: {
    value: 32,
    message: '32文字以下で入力してください',
  },
  validate: {
    startsWith: (val: string) =>
      /^\S/.test(val) || '先頭に空白が含まれています',
    endWith: (val: string) => /\S$/.test(val) || '末尾に空白が含まれています',
  },
};

type InputTableTitleProps = {
  value?: string;
  readonly?: boolean;
};

export const InputTableTitle: VFC<InputTableTitleProps> = ({
  value = '',
  readonly = false,
}) => (
  <FormControl>
    <FormLabel htmlFor="title">テーブルタイトル</FormLabel>
    <InputTextForm<ContentsDbTblFormType>
      label="テーブルタイトル"
      name="title"
      value={value}
      readOnly={readonly}
      attr={tblTitleAttr}
      labelCaption=""
      placeholder="商品データ"
    />
  </FormControl>
);
