import { patchGmoInfo } from 'api/tenant/patchGmoInfo';
import { GmoInfo, isGmoInfo } from 'api/tenant/types';
import { ResponseError } from 'api/types';
import { queryTenantKey } from 'hooks/tenant/queryTenantKey';
import { UseFormSetError } from 'react-hook-form';
import { UseMutateFunction, useMutation, useQueryClient } from 'react-query';
import { isGetValidationError, toMultiError } from 'utils/form';

type UnPromisify<T> = T extends Promise<infer U> ? U : T;

const GmoInfoFormData: GmoInfo = {
  gmoShopId: '',
  gmoShopPass: '',
  gmoSiteId: '',
  gmoSitePass: '',
};

/**
 * Gmo情報更新 hooks
 * @returns {Object} { onSubmit, isLoading }
 */
export const useGmoEdit = ({
  tenantId,
  setError,
  setDialogErrors,
  onDialogClose,
  onOpenCbResponseModal,
}: {
  tenantId: string;
  setError: UseFormSetError<GmoInfo>;
  setDialogErrors: (errors: string[]) => void;
  onDialogClose: () => void;
  onOpenCbResponseModal: () => void;
}): {
  onSubmit: UseMutateFunction<
    UnPromisify<ReturnType<typeof patchGmoInfo>>,
    unknown,
    GmoInfo,
    unknown
  >;
  isLoading: boolean;
  isSuccess: boolean;
} => {
  const queryClient = useQueryClient();

  const {
    mutate: onSubmit,
    isLoading,
    isSuccess,
  } = useMutation(
    (params: GmoInfo) =>
      patchGmoInfo(tenantId, params),
    {
      onSuccess: (result: GmoInfo | boolean | ResponseError) => {
        void queryClient.invalidateQueries( queryTenantKey.gmoInfo );
        // ダイアログのエラーを初期化
        setDialogErrors([]);
        if (isGmoInfo(result)) {
          // confirm dialog close
          onDialogClose();
          // callback dialog open
          onOpenCbResponseModal();

          return;
        }

        if (
          isGetValidationError<GmoInfo>(
            result,
            Object.keys(GmoInfoFormData),
          )
        ) {
          onDialogClose();
          if (result?.others) {
            setDialogErrors(result?.others);
            onOpenCbResponseModal();
          } else {
            Object.keys(result).forEach((k) => {
              const key = k as keyof GmoInfo;
              const errMsgs = result?.[key];
              if (errMsgs) {
                setError(key, { types: toMultiError(errMsgs) });
              }
            });
            onDialogClose();
          }
        }
      },
    },
  );

  return { onSubmit, isLoading, isSuccess };
};
