export const queryTenantKey = {
  groupList: ['tenant/group/list'],
  tenantGroupList: ({ tenantId }: { tenantId: string }) => [
    'tenant/tenantId/group/',
    tenantId,
  ],
  groupDetail: ({
    tenantId,
    groupId,
  }: {
    tenantId: string;
    groupId: string;
  }) => ['tenant/tenantId/group/groupId', tenantId, groupId],
  apiKey: ['tenant/apiKey'],
  getTenant: ({ tenantId }: { tenantId: string }) => ['tenant', tenantId],
  getWebUrls: ({ tenantId }: { tenantId: string }) => [
    'tenant/tenantId/website',
    tenantId,
  ],
  gmoInfo: ['tenant/getGmoInfo'],
  formHosting: ['tenant/getformHosting'],
  getLoginSetting: ({ tenantId }: { tenantId: string }) => [
    'tenant/tenantId/loginSetting',
    tenantId,
  ],
} as const;
