import { request } from 'admin/api/Request';
import { API_MESSAGES } from 'admin/define/apiMessages';
import { CustomError } from 'admin/error/CustomError';
import { baseUserPoolSchema } from 'admin/schema/userPool';
import { UserPool } from 'admin/types/userPool';
import { CreateIdPoolConsumerFormType } from 'admin/types/userPool/form';
import { isResponseError } from 'api/types';
import { Options } from 'ky';
import { getValidationError } from 'utils/form';
import { ZodError } from 'zod';

export const createUserPool = async ({
  data,
  userPoolGroupId,
  options,
}: {
  data: CreateIdPoolConsumerFormType;
  userPoolGroupId: string;
  options?: Options;
}): Promise<UserPool> => {
  const params = data;
  const path = `uniikey/${userPoolGroupId}/user_pool/`;

  const response = await request<UserPool>({
    path,
    method: 'post',
    body: params,
    options,
  });

  if (response.hasError) {
    if (response.status === 404) {
      throw new CustomError(API_MESSAGES.USER_POOL.CREATE_USER_POOL.FAILED, {
        path,
        status: response.status,
      });
    } else if (isResponseError(response.error)) {
      const customError = response.error;
      const formError = getValidationError<CreateIdPoolConsumerFormType>({
        formData: params,
        response: customError,
        replaceKeys: {
          isSelfRegistration: 'others',
        },
      });

      throw new CustomError(API_MESSAGES.USER_POOL.CREATE_USER_POOL.ERROR, {
        path,
        options,
        params,
        errors: formError,
        status: response.status,
        logLevel: response.status === 400 ? 'warning' : 'error',
      });
    } else {
      throw new CustomError(API_MESSAGES.USER_POOL.CREATE_USER_POOL.ERROR, {
        path,
        status: response.status,
      });
    }
  }
  try {
    baseUserPoolSchema.parse(response.data);
  } catch (error) {
    if (error instanceof ZodError) {
      throw new CustomError(error.message, {
        path,
        status: response.status,
        logLevel: 'error',
      });
    }
  }

  return response.data;
};
