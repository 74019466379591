import { Box } from '@chakra-ui/react';
import { ActivateDialog } from 'components/paywall/features/ActivateDialog';
import { CardHeaderTitle } from 'components/paywall/features/Card';
import { usePaywallPromotion } from 'components/paywall/hooks/usePaywallPromotion';
import { PromotionDrawer } from 'components/paywall/pages/PromotionList/PromotionDrawer';
import { Promotion } from 'components/paywall/pages/PromotionList/typed';
import { PERMISSION_MANAGER } from 'define';
import { useUserInfo } from 'hooks/useUserInfo';
import { FC, memo } from 'react';

type Props = {
  promotion: Promotion;
};
export const PromotionCardHeader: FC<Props> = memo(({ promotion }: Props) => {
  const { onActivate } = usePaywallPromotion({});
  const { isEditableTarget } = useUserInfo();

  return (
    <>
      <Box width="100%">
        <CardHeaderTitle
          id={promotion.id}
          fontFamily="Hiragino sans"
          type="wall"
          isIdCopy={false}
        >
          <PromotionDrawer
            isAdd={false}
            buttonMode="text"
            buttonName={promotion.name}
            obj={promotion}
          />
        </CardHeaderTitle>
      </Box>
      <Box
        display="flex"
        alignItems="center"
        ml="24px"
        minWidth="fit-content"
        fontFamily="Hiragino sans"
      >
        <ActivateDialog
          id={promotion.id}
          msg={`「${promotion.name}」の販売ステータスを「${
            promotion.status ? '無効' : '有効'
          }」に変更しますか？`}
          isEnable={promotion.status}
          activate={onActivate}
          isDisabled={!isEditableTarget(PERMISSION_MANAGER)}
        />
      </Box>
    </>
  );
});
