import {
  VFC,
  memo,
  useState,
  useEffect,
  Dispatch,
  SetStateAction,
} from 'react';
import { useFormContext } from 'react-hook-form';
import {
  Box,
  Text,
  Button,
  Modal,
  ModalOverlay,
  ModalContent,
  ModalHeader,
  ModalBody,
  ModalCloseButton,
  useDisclosure,
  ButtonProps,
} from '@chakra-ui/react';
import { MailSetting } from 'api/mail/types';
import { ErrorTextMsg } from 'components/common/atoms';
import { FileUpload } from 'components/mail/atoms/FileUpload';

type CsvFileUploadProps = {
  fileName: string;
  setSegmentTarget: (val: number) => void;
  setTargetValue: Dispatch<SetStateAction<number | string>>;
} & Pick<ButtonProps, 'isDisabled'>;

export const CsvFileUpload: VFC<CsvFileUploadProps> = memo(
  ({
    fileName,
    setSegmentTarget,
    setTargetValue,
    isDisabled = false,
  }: CsvFileUploadProps) => {
    const [csvFileName, setCsvFileName] = useState('');
    const [uploadError, setUploadError] = useState('');
    const { isOpen, onOpen, onClose } = useDisclosure();
    const { watch } = useFormContext<MailSetting>();

    useEffect(() => {
      setCsvFileName(fileName);
    }, [fileName]);

    // アップロードファイルに変更があった場合の処理
    useEffect(() => {
      const fname = watch((val, { name }) => {
        if (name === 'addressFile') {
          if (!val[name]) {
            setTargetValue(0);
            setSegmentTarget(0);
          }
        }
      });

      return () => fname.unsubscribe();
    }, [watch, setSegmentTarget, setTargetValue]);

    return (
      <Box mb={4}>
        {uploadError !== '' && (
          <Box ml={4} mb={2}>
            <ErrorTextMsg msg={uploadError} />
          </Box>
        )}
        <Button
          variant="outline"
          ml={6}
          mb={2}
          onClick={() => onOpen()}
          isDisabled={isDisabled}
        >
          CSVファイルアップロード
        </Button>
        {csvFileName !== '' && (
          <Box ml={8} p={2} bg="gray.50">
            <Text mb={1}>現在設定されているファイル</Text>
            <Box
              bg="white"
              boxShadow="md"
              borderRadius={4}
              p={1}
              display="flex"
              justifyContent="space-between"
              alignItems="center"
            >
              <Text>{csvFileName}</Text>
            </Box>
          </Box>
        )}

        <Modal isOpen={isOpen} size="lg" onClose={onClose} isCentered>
          <ModalOverlay />
          <ModalContent bg="White">
            <ModalHeader pb={0}>
              <Text color="gray.800">データアップロード</Text>
            </ModalHeader>
            <ModalBody pt={0} pb={6}>
              <Box
                mt={4}
                border="2px"
                borderColor="teal.50"
                shadow="xs"
                rounded="md"
              >
                <FileUpload
                  type="csvFile"
                  ext=".csv"
                  uploadParentPath="mail"
                  modalClose={onClose}
                  setFileName={setCsvFileName}
                  setSegmentTarget={setSegmentTarget}
                  setCsvUploadError={setUploadError}
                />
              </Box>
            </ModalBody>
            <ModalCloseButton />
          </ModalContent>
        </Modal>
      </Box>
    );
  },
);
