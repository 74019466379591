import { Box } from '@chakra-ui/react';
import { Colors } from 'admin/define/colors';
import { FC, memo } from 'react';
import { AuthHubSettings } from '../authHubSettings';
// import { RecaptchaSettings } from '../recaptchaSettings';
import { TokenSettings } from '../tokenSettings';

export const AuthBody: FC = memo(() => (
  <Box
    w="100%"
    bg="white"
    mt="16px"
    borderRadius="8px"
    padding="24px"
    color={Colors.GRAY_03}
  >
    <AuthHubSettings />
    <TokenSettings />
    {/* TODO: 動作検証できるまで一旦非表示: 2024.11.28 */}
    {/* <RecaptchaSettings /> */}
  </Box>
));
