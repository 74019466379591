/**
 * カスタムツールオプション作成
 */
export const useCustomToolOptions = (): {
  createCustomToolCourseOption: () => string;
  createCustomToolClassOption: () => string;
  createCustomToolButtonTextOption: (defaultValue: string, hasColorPicker: boolean) => string;
  createCustomToolButtonStyleOption: (defaultBgColor: string, defaultHoverBgColor: string) => string;
} => {

  /**
   * コース設定
   */
  const createCustomToolCourseOption = () => `
    Course: {
      title: "コース設定",
      position: 1,
      options: {
        courseId: {
          label: "コースID",
          defaultValue: "",
          widget: "dropdown"
        },
      }
    }
  `;

  const createCustomToolClassOption = () => `
    Class: {
      title: "HTML クラス設定",
      position: 1,
      options: {
        elmClass: {
          label: "クラス (スペース区切りで複数クラスを指定できます)",
          defaultValue: "",
          widget: "text"
        },
      }
    }
  `;

  /**
   * ボタンテキスト
   */
  const createCustomToolButtonTextOption = (defaultValue: string, hasColorPicker: boolean) => `
    text: {
      title: "ボタンテキスト",
      position: 2,
      options: {
        ${hasColorPicker
          ? `
            btnSample: {
              label: "サンプル",
              defaultValue: "",
              widget: "my_color_picker"
            },
          `
          : ''
        }
        btnValue: {
          label: "ボタン名",
          defaultValue: "${defaultValue}",
          widget: "rich_text"
        },
      }
    }
  `;

  const createCustomToolButtonStyleOption = (defaultBgColor: string, defaultHoverBgColor: string): string => `
    btnOptions: {
      title: "ボタンオプション",
      position: 3,
      options: {
        btnColor: {
          label: "テキスト",
          defaultValue: "#FFFFFF",
          widget: "color_picker"
        },
        btnBackgroundColor: {
          label: "背景",
          defaultValue: "${defaultBgColor}",
          widget: "color_picker"
        },
        btnHoverColor: {
          label: "ホバーテキスト",
          defaultValue: "#FFFFFF",
          widget: "color_picker"
        },
        btnHoverBackgroundColor: {
          label: "ホバー背景",
          defaultValue: "${defaultHoverBgColor}",
          widget: "color_picker"
        },
        btnAlignment: {
          label: "配置",
          defaultValue: "center",
          widget: "alignment"
        },
        btnWidth: {
          label: "幅：({数値}+('%'or'px')形式で入力してください。指定しない場合はauto扱いとなります)",
          defaultValue: "",
          widget: "text"
        },
        btnBorder: {
          label: "ボーダー",
          defaultValue: {
            borderTopWidth: "0px",
            borderTopStyle: "solid",
            borderTopColor: "#CCC",
            borderLeftWidth: "0px",
            borderLeftStyle: "solid",
            borderLeftColor: "#CCC",
            borderRightWidth: "0px",
            borderRightStyle: "solid",
            borderRightColor: "#CCC",
            borderBottomWidth: "0px",
            borderBottomStyle: "solid",
            borderBottomColor: "#CCC"
          },
          widget: "border"
        },
        btnBorderRadius: {
          label: "角丸：({数値}形式で入力してください。指定しない場合は0px扱いとなります)",
          defaultValue: "4",
          widget: "text"
        }
      }
    }
  `;

  return {
    createCustomToolCourseOption,
    createCustomToolClassOption,
    createCustomToolButtonTextOption,
    createCustomToolButtonStyleOption
  };
}
