import { Box, Button } from '@chakra-ui/react';
import { LoginAuthModeValue } from 'api/user/types';
import { LoginEnvironmentInfo } from 'components/login/loginEnvironmentInfo';
import { LoginForm } from 'components/login/loginForm';
import {
  LOGIN_AUTH_MODE_EMAIL,
  LOGIN_AUTH_MODE_GIDP_AUTH
} from 'define';
import { FC, memo, useState } from 'react';

export const LoginContents: FC = memo(() => {
  const [authMode, setAuthMode] = useState<LoginAuthModeValue>(LOGIN_AUTH_MODE_EMAIL);

  return (
    <>
      <Box
        p="1rem"
        backgroundColor="whiteAlpha.900"
        boxShadow="md"
      >
        <LoginEnvironmentInfo />
        <LoginForm authMode={authMode} setAuthMode={setAuthMode} />
      </Box>
      {authMode === LOGIN_AUTH_MODE_GIDP_AUTH && (
        <Button
          w="100%"
          type="button"
          variant='link'
          onClick={() => setAuthMode(LOGIN_AUTH_MODE_EMAIL)}
          mt="24px"
          textDecoration="underline"
        >
          メールアドレス入力に戻る
        </Button>
      )}
    </>
  )
});
