import { RouteObject } from 'react-router-dom';
import { ContentsdbBody } from 'components/contentsdb/templates/ContentsdbBody';
import { TableSearch } from 'components/contentsdb/TableSearch';
import { TableUpload } from 'components/contentsdb/TableUpload';
import { TablePublish } from 'components/contentsdb/TablePublish';
import { TableDetail } from 'components/contentsdb/TableDetail';

export const contetsdbRoutes: RouteObject = {
  path: ':tableId',
  element: <ContentsdbBody />,
  children: [
    {
      path: 'search',
      element: <TableSearch />,
    },
    {
      path: 'upload',
      element: <TableUpload />,
    },
    {
      path: 'publish',
      element: <TablePublish />,
    },
    {
      path: '',
      element: <TableDetail />,
    },
  ],
};
