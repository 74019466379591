import {
  Box,
  Button,
  Flex,
  FormControl,
  FormErrorMessage,
  Heading,
  Popover,
  PopoverBody,
  PopoverContent,
  PopoverTrigger,
  Text,
  Textarea,
} from '@chakra-ui/react';
import { Colors, ColorSet } from 'admin/define/colors';
import { TransactionMailTestMailFormType } from 'admin/types/userPool/transactionMail/form';
import { FC, memo } from 'react';
import { useFormState } from 'react-hook-form';
import { IoMailOutline } from 'react-icons/io5';
import { toErrMsgList } from 'utils/form';

type Props = {
  onTestMailSubmit: () => void;
  onChangeTextMailAddress: (text: string) => void;
  mailAddress: string;
  mailAddressRef: React.RefObject<HTMLTextAreaElement>;
  hasFromAddress: boolean;
  isLoading: boolean;
  isOpen: boolean;
  onOpen: () => void;
  onClose: () => void;
};

export const TestMailPopoverComponent: FC<Props> = memo(
  ({
    onTestMailSubmit,
    onChangeTextMailAddress,
    mailAddress,
    mailAddressRef,
    hasFromAddress,
    isLoading,
    isOpen,
    onOpen,
    onClose,
  }) => {
    const { errors } = useFormState<TransactionMailTestMailFormType>();

    return (
      <>
        <Popover placement="bottom-end" isOpen={isOpen} onClose={onClose}>
          <PopoverTrigger>
            <Button
              variant="outline"
              mr={1}
              p="8px 24px"
              w="183px"
              h="40px"
              fontSize="14px"
              fontWeight={700}
              onClick={onOpen}
              {...ColorSet.Default}
              color={Colors.BLUE_20}
              borderColor={Colors.BLUE_20}
            >
              <Flex justifyContent="left" alignItems="center">
                <IoMailOutline size="20px" />
                <Text ml={1} lineHeight="23.8px">
                  テストメール送信
                </Text>
              </Flex>
            </Button>
          </PopoverTrigger>
          <PopoverContent w="424px" p="24px">
            <PopoverBody p={0} m={0}>
              <Heading
                as="h2"
                size="sm"
                mb={6}
                fontFamily="Hiragino Sans"
                fontSize="18px"
                fontWeight={700}
                lineHeight="30.6px"
                textAlign="left"
                color={Colors.GRAY_03}
              >
                テストメールを送信します。
              </Heading>
              <Box mb={6}>
                {!hasFromAddress && (
                  <Box>
                    <Text>
                      テストメールを送信するには、
                      <Text as="span" fontWeight={700}>
                        「トランザクションメール設定&nbsp;&gt;&nbsp;共通設定」
                      </Text>
                      から、
                      <Text as="span" fontWeight={700}>
                        差出人メールアドレス
                      </Text>
                      を設定してください。
                    </Text>
                  </Box>
                )}
                {hasFromAddress && (
                  <>
                    <Box mb={2}>
                      <Text
                        fontSize="14px"
                        fontWeight={700}
                        lineHeight="21px"
                        color={Colors.GRAY_03}
                        mb={1}
                      >
                        宛先メールアドレス
                      </Text>
                      <Text fontSize="12px" color={Colors.GRAY_00}>
                        宛先メールアドレスを
                        <Text fontWeight={700} as="span">
                          改行区切りで記入
                        </Text>
                        してください
                      </Text>
                    </Box>
                    <FormControl
                      isInvalid={
                        Boolean(errors.toAddress?.message) ||
                        toErrMsgList(errors, 'toAddress').length > 0
                      }
                    >
                      <Box>
                        <Textarea
                          value={mailAddress}
                          ref={mailAddressRef} // 複数行の placeholder を設定する為
                          borderColor="gray.200"
                          onChange={(e) =>
                            onChangeTextMailAddress(e.target.value)
                          }
                          h="256px"
                          size="sm"
                          resize="none"
                          borderRadius="md"
                        />
                      </Box>
                      {toErrMsgList(errors, 'toAddress').map((err) => (
                        <FormErrorMessage key={`toAddress_${err}`}>
                          {err}
                        </FormErrorMessage>
                      ))}
                    </FormControl>
                  </>
                )}
              </Box>
              <Flex justifyContent="flex-end">
                <Button
                  colorScheme="primary"
                  w="118px"
                  h="40px"
                  variant="solid"
                  fontWeight={700}
                  fontSize="14px"
                  disabled={!hasFromAddress}
                  onClick={onTestMailSubmit}
                  isLoading={isLoading}
                >
                  送信
                </Button>
              </Flex>
            </PopoverBody>
          </PopoverContent>
        </Popover>
      </>
    );
  },
);
