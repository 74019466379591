import { Box, Heading, useDisclosure } from '@chakra-ui/react';
import { withSuspenseAndErrorBoundary } from 'admin/components/Error/WithErrorBoundary';
import { CodeEditDrawer } from 'admin/components/idPoolConsumer/code/codeDrawer';
import { useCodeSettings } from 'admin/hooks/userPool/code/useCodeSettings';
import { CodeSettingType } from 'admin/types/userPool/code';
import { Page } from 'api/common/types';
import { ErrorContents } from 'components/common/atoms';
import {
  Dispatch,
  FC,
  memo,
  SetStateAction,
  useEffect,
  useMemo,
  useState,
} from 'react';
import { useParams } from 'react-router-dom';
import { CodeTableComponent } from './CodeTableComponent';

type Props = {
  currentPage: number;
  setPage: Dispatch<SetStateAction<Page>>;
};

const fetchCodeTable: FC<Props> = memo(({ setPage, currentPage }) => {
  const params = useParams();
  const {
    isOpen: isDrawerOpen,
    onClose: onDrawerClose,
    onOpen: onDrawerOpen,
  } = useDisclosure();

  const [codeSetting, setCodeSetting] = useState<CodeSettingType>();
  const codeSettingId = useMemo(() => codeSetting?.id ?? '', [codeSetting]);
  const formId = `codeSettingEditForm${codeSettingId}`;
  const codeGroupId = useMemo(() => params.masterId ?? '', [params]);

  const { data: codeSettings, page } = useCodeSettings({
    id: codeGroupId,
    page: currentPage,
  });
  // 削除したコード値と同じコード値で登録するとエラーになるため一旦消す
  // const openHandler = useCallback(
  //   (value: CodeSettingType) => {
  //     onModalOpen();
  //     setCodeSetting(value);
  //   },
  //   [onModalOpen],
  // );

  useEffect(() => {
    setPage(page);
  }, [setPage, page]);

  if (!codeSettings) {
    return null;
  }

  if (codeSettings.length === 0) {
    return (
      <Box
        w="100%"
        textAlign="center"
        bg="white"
        mt={6}
        borderRadius={4}
        padding={10}
      >
        <Heading as="h3" fontWeight="bold" fontSize="24px">
          コードが未登録です。
        </Heading>
      </Box>
    );
  }

  return (
    <>
      <CodeTableComponent
        codeSettings={codeSettings}
        setCodeSetting={setCodeSetting}
        onDrawerOpen={onDrawerOpen}
        // onModalOpen={openHandler}
      />
      <CodeEditDrawer
        codeSettingId={codeSettingId}
        onClose={onDrawerClose}
        isOpen={isDrawerOpen}
        formId={formId}
      />
      {/* 削除したコード値と同じコード値で登録するとエラーになるため一旦消す
      <Modal isOpen={isModalOpen} onClose={onModalClose} isCentered size="xl">
        <CodeDeleteModal onClose={onModalClose} codeSetting={codeSetting} />
      </Modal> */}
    </>
  );
});

export const CodeTable = memo(
  withSuspenseAndErrorBoundary(fetchCodeTable, {
    ErrorComponent: <ErrorContents name="コード設定一覧" />,
  }),
);
