import {
  Box,
  DrawerCloseButton,
  DrawerContent,
  DrawerHeader,
  DrawerOverlay,
  Text,
} from '@chakra-ui/react';
import { withSuspenseAndErrorBoundary } from 'admin/components/Error/WithErrorBoundary';
import { NewsletterEditForm } from 'admin/components/idPoolConsumer/newsletter/newsletterForm';
import { useIdPoolConsumerId } from 'admin/hooks/useIdPoolConsumerId';
import { useNewsletterDetail } from 'admin/hooks/userPool/newsletter/useNewsletterDetail';
import { ErrorContents, LoadingSpinner } from 'components/common/atoms';
import { FC, memo } from 'react';

type Props = {
  newsletterId: string;
  onClose: () => void;
};

const fetchNewsletterEditDrawer: FC<Props> = memo(
  ({ newsletterId, onClose }: Props) => {
    const idPoolConsumerId = useIdPoolConsumerId();
    const { data: newsletterDetail } = useNewsletterDetail({
      newsletterId,
      userpoolId: idPoolConsumerId,
    });

    if (!newsletterDetail) {
      return null;
    }

    return (
      <NewsletterEditForm
        newsletterDetail={newsletterDetail}
        onClose={onClose}
      />
    );
  },
);

export const FetchNewsletterEditDrawer = withSuspenseAndErrorBoundary(
  fetchNewsletterEditDrawer,
  {
    ErrorComponent: <ErrorContents name="組織レベル属性詳細" />,
    LoadingComponent: <LoadingSpinner />,
  },
);

export const NewsletterEditDrawer: FC<Props> = memo((props: Props) => (
  <>
    <DrawerOverlay />
    <DrawerContent>
      <DrawerHeader pb={0}>
        <Box m={0} px={0} pb="24px" borderBottomWidth="1px">
          <Text>メールマガジン購読設定 編集</Text>
        </Box>
      </DrawerHeader>
      <FetchNewsletterEditDrawer {...props} />
      <DrawerCloseButton top="16px" right="24px" />
    </DrawerContent>
  </>
));
