export const messages = {
  // ファイルアップロード
  gcsFileUpload: {
    customer: {
      error:
        '申し訳ございません。ファイルアップロードに失敗しました。時間を置いて再度実行してください。',
      failed: '申し訳ありません。ファイルアップロードに失敗しました',
    },
    system: {
      typeError:
        '[api.mail.gcsFileUpload] ファイルアップロードAPIのレスポンス型が予期せぬデータ型です。フロントの修正をしてください',
    },
  },
};
