import { editorTypeInfo } from 'api/enquete/types';
import ErrorBoundary from 'api/ErrorBoundary';
import { ErrorContentsReload } from 'components/common/atoms/ErrorContentsReload';
import {
  ContentState, convertFromRaw, convertToRaw, EditorState, Modifier
} from 'draft-js';
import draftToHtml from 'draftjs-to-html';
import { useErrorBoundaryReset } from 'hooks/useErrorBoundaryReset';
import {
  Dispatch, FC, SetStateAction, useCallback,
  useEffect, useState
} from 'react';
import { Editor } from 'react-draft-wysiwyg';
import 'react-draft-wysiwyg/dist/react-draft-wysiwyg.css';
import { FieldValues, Path, PathValue, UnpackNestedValue, useFormContext } from 'react-hook-form';
import './RichTextElement.css';

type Props<T> = {
  baseName: Path<T>;
  placeholder?: string;
  option?: string[];
  index: number | -1;
  position: string;
  value: string;
  setIsEdited: Dispatch<SetStateAction<boolean>>;
  row?: number;
  bgColor?: string;
};

// カラーパレットから文字色・ハイライトのスタイルを削除する為の色(通常使用する色と別の色である事)
const COLOR_TO_REMOVE_STYLE = 'rgba(255,0,255,0)';
const STYLE_TO_REMOVE_TEXT_COLOR = `color-${COLOR_TO_REMOVE_STYLE}`;
const STYLE_TO_REMOVE_HIGHLIGHT = `bgcolor-${COLOR_TO_REMOVE_STYLE}`;

export const RichTextElement = <T extends FieldValues>({
  baseName,
  placeholder = '説明文を入力してください。',
  option = ['inline', 'colorPicker', 'fontSize', 'link'],
  index,
  position,
  value,
  setIsEdited,
  row,
  bgColor = '#f9f9f9',
}: Props<T>): ReturnType<FC> => {
  const { ebKey, onError } = useErrorBoundaryReset();
  const { setValue, getValues } = useFormContext<T>();
  const [editorState, setEditorState] = useState(EditorState.createEmpty());

  useEffect(() => {
    setEditorState(() => {
      if (value) {
        const jsonData = convertFromRaw(JSON.parse(value) as editorTypeInfo);

        return EditorState.createWithContent(jsonData);
      }

      return EditorState.createEmpty();
    });
  }, [value]);

  const convertContentToRaw = useCallback(
    (state: EditorState) => {
      const currentContentAsRaw = JSON.stringify(
        convertToRaw(state.getCurrentContent()),
      );
      const rawObject = convertToRaw(state.getCurrentContent());
      const convertHtml = draftToHtml(rawObject);
      const textExist = state.getCurrentContent().hasText();
      if (textExist) {
        if (index > -1) {
          if (position === 'heading') {
            setValue(`${baseName}.${index}.heading` as Path<T>, currentContentAsRaw as UnpackNestedValue<PathValue<T, Path<T>>>);
            setValue(`${baseName}.${index}.headingHtml` as Path<T>, convertHtml as UnpackNestedValue<PathValue<T, Path<T>>>);
          } else if (position === 'wysiwyg') {
            setValue(`${baseName}.${index}.wysiwyg` as Path<T>, currentContentAsRaw as UnpackNestedValue<PathValue<T, Path<T>>>);
            setValue(`${baseName}.${index}.wysiwygHtml` as Path<T>, convertHtml as UnpackNestedValue<PathValue<T, Path<T>>>);
          } else if (position === 'descriptionTop') {
            setValue(
              `${baseName}.${index}.descriptionTop` as Path<T>,
              currentContentAsRaw as UnpackNestedValue<PathValue<T, Path<T>>>,
            );
            setValue(
              `${baseName}.${index}.descriptionTopHtml` as Path<T>,
              convertHtml as UnpackNestedValue<PathValue<T, Path<T>>>,
            );
          } else if (position === 'descriptionBottom') {
            setValue(
              `${baseName}.${index}.descriptionBottom` as Path<T>,
              currentContentAsRaw as UnpackNestedValue<PathValue<T, Path<T>>>,
            );
            setValue(
              `${baseName}.${index}.descriptionBottomHtml` as Path<T>,
              convertHtml as UnpackNestedValue<PathValue<T, Path<T>>>,
            );
          }
        }
        if (position === 'thanks') {
          setValue('thanks' as Path<T>, currentContentAsRaw as UnpackNestedValue<PathValue<T, Path<T>>>);
          setValue('thanksHtml' as Path<T>, convertHtml as UnpackNestedValue<PathValue<T, Path<T>>>);
        }
      } else {
        if (index > -1) {
          if (position === 'heading') {
            setValue(`${baseName}.${index}.heading` as Path<T>, '' as UnpackNestedValue<PathValue<T, Path<T>>>);
            setValue(`${baseName}.${index}.headingHtml` as Path<T>, '' as UnpackNestedValue<PathValue<T, Path<T>>>);
          } else if (position === 'wysiwyg') {
            setValue(`${baseName}.${index}.wysiwyg` as Path<T>, '' as UnpackNestedValue<PathValue<T, Path<T>>>);
            setValue(`${baseName}.${index}.wysiwygHtml` as Path<T>, '' as UnpackNestedValue<PathValue<T, Path<T>>>);
          } else if (position === 'descriptionTop') {
            setValue(`${baseName}.${index}.descriptionTop` as Path<T>, '' as UnpackNestedValue<PathValue<T, Path<T>>>);
            setValue(`${baseName}.${index}.descriptionTopHtml` as Path<T>, '' as UnpackNestedValue<PathValue<T, Path<T>>>);
          } else if (position === 'descriptionBottom') {
            setValue(`${baseName}.${index}.descriptionBottom` as Path<T>, '' as UnpackNestedValue<PathValue<T, Path<T>>>);
            setValue(`${baseName}.${index}.descriptionBottomHtml` as Path<T>, '' as UnpackNestedValue<PathValue<T, Path<T>>>);
          }
        }
        if (position === 'thanks') {
          setValue('thanks' as Path<T>, '' as UnpackNestedValue<PathValue<T, Path<T>>>);
          setValue('thanksHtml' as Path<T>, '' as UnpackNestedValue<PathValue<T, Path<T>>>);
        }
      }
    },
    [index, position, setValue],
  );

  const handleEditorChange = useCallback(
    (state: EditorState) => {
      setEditorState(state);
      convertContentToRaw(state);
      setIsEdited(true);
    },
    [setEditorState, convertContentToRaw, setIsEdited],
  );

  // 貼り付けを行った時に、プレーンテキストとして貼り付ける
  const handlePastedText = useCallback(
    (text: string, html: string, argState: EditorState) => {
      const blockMap = ContentState.createFromText(text).getBlockMap();
      const newContentState = Modifier.replaceWithFragment(
        argState.getCurrentContent(),
        argState.getSelection(),
        blockMap,
      );

      const newEditorState = EditorState.push(
        argState,
        newContentState,
        'insert-fragment',
      );

      setEditorState(newEditorState);

      return true;
    },
    [setEditorState],
  );

  const editorStyle = useCallback(() => {
    if (row) {
      return {
        backgroundColor: bgColor,
        border: '1px solid #ccc',
        borderRadius: '0 0 5px 5px',
        fontSize: '1rem',
        padding: '5px 16px',
        minHeight: `${row * 24 + 44}px`,
      };
    }

    return {
      backgroundColor: bgColor,
      border: '1px solid #ccc',
      borderRadius: '0 0 5px 5px',
      fontSize: '1rem',
      padding: '5px 16px',
    };
  }, [row, bgColor]);

  const onChange = useCallback(() => {
    const curStyle = editorState.getCurrentInlineStyle();

    let remStyle = '';
    if (curStyle.has(STYLE_TO_REMOVE_TEXT_COLOR)) {
      remStyle = STYLE_TO_REMOVE_TEXT_COLOR;
    } else if (curStyle.has(STYLE_TO_REMOVE_HIGHLIGHT)) {
      remStyle = STYLE_TO_REMOVE_HIGHLIGHT;
    } else {
      return;
    }

    // Undo/Redo の履歴に削除用の色情報を残さないようにする
    const es1 = EditorState.undo(editorState);
    const es2 = EditorState.undo(es1);

    const newContentState = Modifier.removeInlineStyle(
      es1.getCurrentContent(),
      es1.getSelection(),
      remStyle,
    );

    const newEditorState = EditorState.push(
      es2,
      newContentState,
      'change-inline-style',
    );

    setEditorState(newEditorState);
  }, [editorState, setEditorState]);

  return (
    <>
      <ErrorBoundary
        onError={onError}
        key={ebKey.current}
        errorComponent={
          <ErrorContentsReload
            msg="エディター内でエラーが発生しました。"
            onClick={() => {
              // このコンポーネントだけをリロードする
              const newEditorState = EditorState.createWithContent(
                editorState.getCurrentContent(),
              );
              setEditorState(newEditorState);
            }}
          />
        }
      >
        <Editor
          editorState={editorState}
          onEditorStateChange={handleEditorChange}
          onChange={onChange}
          handlePastedText={handlePastedText}
          toolbarClassName="toolbarClassName"
          wrapperClassName="wrapperClassName"
          editorClassName="editorClassName"
          placeholder={placeholder}
          wrapperStyle={{
            width: '100%',
          }}
          editorStyle={editorStyle()}
          localization={{ locale: 'ja' }}
          toolbarStyle={{
            borderRadius: '5px 5px 0 0',
            border: '1px solid #ccc',
            borderBottom: 'none',
            marginBottom: 0,
          }}
          toolbar={{
            options: option,
            inline: {
              options: ['bold'],
            },
            link: {
              inDropdown: false,
              defaultTargetOption: '_blank',
              options: ['link', 'unlink'],
            },
            colorPicker: {
              colors: [
                'rgb(97,189,109)',
                'rgb(26,188,156)',
                'rgb(84,172,210)',
                'rgb(44,130,201)',
                'rgb(147,101,184)',
                'rgb(71,85,119)',
                'rgb(204,204,204)',
                'rgb(65,168,95)',
                'rgb(0,168,133)',
                'rgb(61,142,185)',
                'rgb(41,105,176)',
                'rgb(85,57,130)',
                'rgb(40,50,78)',
                'rgb(0,0,0)',
                'rgb(247,218,100)',
                'rgb(251,160,38)',
                'rgb(235,107,86)',
                'rgb(226,80,65)',
                'rgb(163,143,132)',
                'rgb(239,239,239)',
                'rgb(255,255,255)',
                'rgb(250,197,28)',
                'rgb(243,121,52)',
                'rgb(209,72,65)',
                'rgb(184,49,47)',
                'rgb(124,112,107)',
                'rgb(209,213,216)',
                COLOR_TO_REMOVE_STYLE,
              ],
            },
          }}
          readOnly={getValues('uneditableState' as Path<T>)}
        />
      </ErrorBoundary>
    </>
  );
};
