import {
  FIELD_UI_TYPE_LIST,
  UI_TYPE_CHECKBOX,
  UI_TYPE_EMAIL,
  UI_TYPE_RADIO,
  UI_TYPE_SELECTBOX,
  UI_TYPE_TEL,
  UI_TYPE_TEXTAREA,
  UI_TYPE_ZIP_CODE_JA,
} from 'admin/define/field';
import { AttributeFieldType } from 'admin/types/userPool/attribute';
import { useMemo } from 'react';

export const fieldTypeList: AttributeFieldType[] = [
  { value: 'string', name: '文字列（指定なし）' },
  { value: 'string_hiragana', name: '文字列（かなのみ）' },
  { value: 'string_kana', name: '文字列（カナのみ）' },
  { value: 'integer', name: '整数' },
  { value: 'date', name: '日付' },
  { value: 'datetime', name: '日時' },
];

export const fieldTypeSelectList: AttributeFieldType[] = [
  { value: 'string', name: '文字列（指定なし）' },
  { value: 'string_hiragana', name: '文字列（かなのみ）' },
  { value: 'string_kana', name: '文字列（カナのみ）' },
  { value: 'integer', name: 'number ： 整数' },
  { value: 'date', name: 'date ： 日付' },
  { value: 'datetime', name: 'datetime ： 日時' },
];

export const getDataTypeNameByValue = (value: string): string => {
  const fieldType = fieldTypeList.find((field) => field.value === value);

  return fieldType ? fieldType.name : '';
};

const filterFieldUiTypeWithString = [
  UI_TYPE_TEXTAREA,
  UI_TYPE_RADIO,
  UI_TYPE_CHECKBOX,
  UI_TYPE_SELECTBOX,
  UI_TYPE_EMAIL,
  UI_TYPE_TEL,
  UI_TYPE_ZIP_CODE_JA,
  // 'zip_code_foreign',
];

const filterFieldUiTypeWithDate = ['date_split', 'date_calendar'];

const filterFieldUiTypeWithStringAndNumber = ['text'];

export const useFilterFieldTypeList = (selectedFieldUiType: string | number) =>
  useMemo<AttributeFieldType[]>(() => {
    if (filterFieldUiTypeWithString.includes(String(selectedFieldUiType))) {
      return fieldTypeSelectList.filter((x) => x.value === 'string');
    }

    if (filterFieldUiTypeWithDate.includes(String(selectedFieldUiType))) {
      return fieldTypeSelectList.filter((x) => x.value === 'date');
    }

    if (
      // テキスト一行か判定
      filterFieldUiTypeWithStringAndNumber.includes(String(selectedFieldUiType))
    ) {
      return fieldTypeSelectList.filter(
        (x) =>
          x.value === 'string' ||
          x.value === 'string_hiragana' ||
          x.value === 'string_kana' ||
          x.value === 'integer',
      );
    }

    return fieldTypeSelectList;
  }, [selectedFieldUiType]);

export const getUiTypeNameByValue = (value: string): string => {
  const fieldType = FIELD_UI_TYPE_LIST.find((field) => field.value === value);

  return fieldType ? fieldType.name : '';
};
