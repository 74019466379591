import { Modal, useDisclosure } from '@chakra-ui/react';
import { zodResolver } from '@hookform/resolvers/zod';
import { withSuspenseAndErrorBoundary } from 'admin/components/Error/WithErrorBoundary';
import { AgreeModal } from 'admin/components/saml/agreeModal';
import { useCreateSamlSetting } from 'admin/hooks/saml/useCreateSamlSetting';
import { useEditSamlSetting } from 'admin/hooks/saml/useEditSamlSetting';
import {
  samlSettingSchema
} from 'admin/schema/saml';
import { SamlSettingFormType, SamlSettingType } from 'admin/types/saml/index';
import { AlertBar } from 'components/common/atoms';
import { FC, memo, useMemo, useState } from 'react';
import { FormProvider, useForm } from 'react-hook-form';
import { SamlFormComponent } from './SamlFormComponent';

type Props = {
  domainList: string[];
  samlSetting: SamlSettingType;
  onClose: () => void;
};

const FetchSamlForm: FC<Props> = memo(({ domainList, samlSetting, onClose }: Props) => {
  const [globalErrors, setGlobalErrors] = useState<string[]>([]);
  const formId = 'samlSetting';
  const { isOpen: isModalOpen, onClose: onModalClose, onOpen: onModalOpen } = useDisclosure();
  const [modalPromise, setModalPromise] = useState<() => void>(() => null);

  /**
   * ドメインリストが存在しない（空配列）の場合、設定できるものが無いため空とする。
   * また、上記の場合、ドメインを保有していても、マッピング対象が存在しないため登録できない
   */
  const samlSettingDomainList = useMemo(() => {
    if(domainList.length === 0) return [{url: ''}];
    if(samlSetting?.domains.length !== 0) {
      return samlSetting?.domains.map((item) => ({
        url: item,
      }));
    }

    return [{url: ''}];
  }, [domainList.length, samlSetting?.domains]);

  const defaultValues: SamlSettingFormType = {
    domains: samlSettingDomainList,
    entityId: samlSetting?.entityId ?? '',
    singleSignOnUrl: samlSetting?.singleSignOnUrl ?? '',
    responseCertificate: samlSetting?.responseCertificate ?? '',
  };

  const methods = useForm<SamlSettingFormType>({
    defaultValues,
    resolver: zodResolver(samlSettingSchema),
  });

  const { getValues, setError } = methods;
  const { mutate: createMutate, isLoading: isCreateLoading } = useCreateSamlSetting(
    setError,
    setGlobalErrors,
  );
  const { mutate: editMutate, isLoading: isEditLoading } = useEditSamlSetting(
    setError,
    setGlobalErrors,
  );

  /**
   * isEdit
   * return true: edit, false: create
   */
  const isEdit = useMemo<boolean>(() => {
    const result = !(
      samlSetting.domains.length === 0 &&
      samlSetting.singleSignOnUrl === '' &&
      samlSetting.entityId === '' &&
      samlSetting.responseCertificate === ''
    );

    return result;
  }, [samlSetting])

  const mutate = useMemo(() =>
    isEdit ? editMutate : createMutate
    ,[editMutate, createMutate, isEdit]
  );

  const isLoading = useMemo(() =>
    isEdit ? isEditLoading : isCreateLoading
    ,[isCreateLoading, isEditLoading, isEdit]);

  const onSubmit = async (data: SamlSettingFormType) => {
    const promise = new Promise<void>((resolve) => {
      setModalPromise(() => resolve);
    });
    onModalOpen();
    await promise;
    onModalClose();
    await mutate(data);
    onClose();
  };

  if (!domainList) {
    return null;
  };

  return (
    <FormProvider {...methods}>
      {globalErrors.map((err, idx) => (
        <AlertBar
          key={`global-err-idx${String(idx)}`}
          message={err}
          status="error"
          mb={4}
        />
      ))}
      <SamlFormComponent
        onSubmit={onSubmit}
        formId={formId}
        isLoading={isLoading}
        domainList={domainList}
        isEdit={isEdit}
        onClose={onClose}
        defaultValues={defaultValues}
      />
      <Modal
        isOpen={isModalOpen}
        onClose={onModalClose}
        isCentered
        closeOnOverlayClick={false}
        closeOnEsc={false}
        size="xl"
      >
        <AgreeModal
          isLoading={isLoading}
          onClose={onModalClose}
          onSubmit={modalPromise}
          samlSetting={getValues()}
        />
      </Modal>
    </FormProvider>
  );
});

export const SamlForm = withSuspenseAndErrorBoundary(FetchSamlForm);
