import { request } from 'admin/api/Request';
import { CustomError } from 'admin/error/CustomError';
import { messages } from 'api/transactionMail/messages';
import {
  CreateFirstLoginMailFormType,
  FirstLoginMailType,
} from 'api/transactionMail/type';
import { isResponseError } from 'api/types';
import { Options } from 'ky';
import { FirstLoginMailSchema } from 'schema/transactionMail/index';
import { getValidationError } from 'utils/form';
import { camelCase, snakeCase } from 'utils/str';
import { ZodError } from 'zod';

export const createSendingContent = async (
  tenantId: string,
  params: CreateFirstLoginMailFormType,
  options?: Options,
): Promise<FirstLoginMailType> => {
  const path = `tenant/${tenantId}/transaction_mails/`;

  const response = await request<FirstLoginMailType>({
    path,
    body: {
      ...params,
      type: snakeCase(params.type),
    },
    method: 'post',
  });

  if (response.hasError) {
    if (response.status === 404) {
      throw new CustomError(messages.createSendingContent.customer.failed, {
        path,
        options,
        status: response.status,
      });
    } else if (isResponseError(response.error)) {
      const customError = response.error;

      const formError = getValidationError<CreateFirstLoginMailFormType>({
        formData: params,
        response: customError,
      });

      throw new CustomError(messages.createSendingContent.customer.error, {
        path,
        params,
        errors: formError,
        status: response.status,
        logLevel: response.status === 400 ? 'warning' : 'error',
      });
    } else {
      throw new CustomError(messages.createSendingContent.customer.error, {
        path,
        options,
        status: response.status,
      });
    }
  }

  // メール情報に含まれるtypeのデータを修正する
  const firstLoginMailData = {
    ...response.data,
    type: camelCase(response.data.type),
  } as FirstLoginMailType;

  try {
    FirstLoginMailSchema.parse(firstLoginMailData);
  } catch (error) {
    if (error instanceof ZodError) {
      throw new CustomError(error.message, {
        path,
        options,
        status: response.status,
        logLevel: 'error',
      });
    }
  }

  return firstLoginMailData;
};
