import { UseMutateFunction, useQueryClient, useMutation } from 'react-query';
import { putEditGroup } from 'api/tenant/putEditGroup';
import { ResponseError } from 'api/types';
import { EditGroupFormType, GroupFlat, isGroup } from 'api/tenant/types';
import { isGetValidationError } from 'utils/form';
import { useCustomToast } from 'hooks/useCustomToast';
import { queryTenantKey } from 'hooks/tenant/queryTenantKey';

type UnPromisify<T> = T extends Promise<infer U> ? U : T;

const editGroupFormData: EditGroupFormType = {
  name: '',
  tenantId: '',
  groupId: '',
  parentId: '',
};

/**
 * テナントグループ更新 hooks
 * @returns {Object} { onSubmit, isLoading }
 */
export const useEditGroup = (): {
  onSubmit: UseMutateFunction<
    UnPromisify<ReturnType<typeof putEditGroup>>,
    unknown,
    EditGroupFormType,
    unknown
  >;
  isLoading: boolean;
  isSuccess: boolean;
} => {
  const queryClient = useQueryClient();
  const toast = useCustomToast();

  const {
    mutate: onSubmit,
    isLoading,
    isSuccess,
  } = useMutation(
    ({ name, tenantId, groupId, parentId = '' }: EditGroupFormType) =>
      putEditGroup({ name, tenantId, groupId, parentId }),
    {
      onSuccess: (result: GroupFlat | boolean | ResponseError) => {
        void queryClient.invalidateQueries(queryTenantKey.groupList);

        if (isGroup(result)) {
          void queryClient.invalidateQueries(
            queryTenantKey.tenantGroupList({ tenantId: result.tenantId }),
          );
          toast({
            status: 'success',
            position: 'bottom',
            duration: 2000,
            isClosable: true,
            title: '修正しました',
          });

          return;
        }

        if (
          isGetValidationError<EditGroupFormType>(
            result,
            Object.keys(editGroupFormData),
          )
        ) {
          const errorMsg = Object.entries(result).map(([_, value]) => value);
          toast({
            status: 'error',
            position: 'bottom',
            duration: 4000,
            isClosable: true,
            title: errorMsg[0],
          });
        }
      },
    },
  );

  return { onSubmit, isLoading, isSuccess };
};
