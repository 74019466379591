import { Button, DrawerFooter } from '@chakra-ui/react';
import { zodResolver } from '@hookform/resolvers/zod';
import { withSuspenseAndErrorBoundary } from 'admin/components/Error/WithErrorBoundary';
import { ColorSet } from 'admin/define/colors';
import { useIdPoolConsumerId } from 'admin/hooks/useIdPoolConsumerId';
import { useCreateCodeGroup } from 'admin/hooks/userPool/master/useCreateCodeGroup';
import { createCodeGroupSchema } from 'admin/schema/idPoolConsumer/master';
import { CreateCodeGroupFormType } from 'admin/types/userPool/master/form';
import { AlertBar } from 'components/common/atoms';
import { FC, memo, useState } from 'react';
import { FormProvider, SubmitHandler, useForm } from 'react-hook-form';
import { MasterCreateFormComponent } from './MasterCreateFormComponent';

type Props = {
  onClose: () => void;
  formId: string;
  currentPage: number;
};

const FetchMasterCreateForm: FC<Props> = memo(
  ({ onClose, formId, currentPage }: Props) => {
    const idPoolConsumerId = useIdPoolConsumerId();

    const methods = useForm<CreateCodeGroupFormType>({
      defaultValues: {
        id: idPoolConsumerId,
        displayNameJa: '',
        displayNameForeign: '',
      },
      resolver: zodResolver(createCodeGroupSchema),
    });

    const [globalErrors, setGlobalErrors] = useState<string[]>([]);

    const { mutate, isLoading } = useCreateCodeGroup(
      methods.setError,
      setGlobalErrors,
      currentPage,
    );

    const onSubmit: SubmitHandler<CreateCodeGroupFormType> = async (
      data: CreateCodeGroupFormType,
    ) => {
      if (!isLoading) {
        await mutate(data);
      }
      methods.reset();
      onClose();
    };

    return (
      <>
        <FormProvider {...methods}>
          {globalErrors.map((err, idx) => (
            <AlertBar
              key={`global-err-idx${String(idx)}`}
              message={err}
              status="error"
              mb={4}
            />
          ))}
          <MasterCreateFormComponent
            onSubmit={onSubmit}
            formId={formId}
            isLoading={isLoading}
          />
        </FormProvider>
        <DrawerFooter>
          <Button
            variant="outline"
            mr={3}
            onClick={onClose}
            p="16px 24px"
            w="120px"
            {...ColorSet.Default}
          >
            キャンセル
          </Button>
          <Button
            type="submit"
            form={formId}
            p="16px 24px"
            w="120px"
            colorScheme="primary"
            isLoading={isLoading}
          >
            登録
          </Button>
        </DrawerFooter>
      </>
    );
  },
);

export const MasterCreateForm = withSuspenseAndErrorBoundary(
  FetchMasterCreateForm,
);
