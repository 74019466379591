import { getNewsletterDetail } from 'admin/api/userPool/newsletter/getNewsletterDetail';
import { CustomError } from 'admin/error/CustomError';
import { queryNewsletterKey } from 'admin/hooks/userPool/newsletter/queryNewsletterKey';
import { UserPool } from 'admin/types/userPool';
import { NewsletterType } from 'admin/types/userPool/newsletter';
import { logger } from 'api/logger';
import { useCustomToast } from 'hooks/useCustomToast';
import { useQuery } from 'react-query';
import { errorToast } from 'utils/toast';

export const useNewsletterDetail = ({
  newsletterId,
  userpoolId,
}: {
  newsletterId: NewsletterType['id'];
  userpoolId: UserPool['id'];
}) => {
  const toast = useCustomToast();
  const { data } = useQuery({
    queryKey: queryNewsletterKey.getMailMagazineDetail(newsletterId),
    queryFn: () => getNewsletterDetail({ newsletterId, userpoolId }),
    onError: async (error) => {
      if (error instanceof CustomError) {
        await logger({
          loglevel: error.logLevel,
          data: JSON.stringify(error.cause),
          message: error.message,
        });
      }
      toast({
        ...errorToast,
        duration: 4000,
        title: 'メールマガジン購読設定詳細情報の取得に失敗しました',
        position: 'bottom',
        variant: 'solid',
      });
    },
  });

  return { data };
};
