import {
  Box,
  Button,
  Checkbox,
  ListItem,
  StackDivider,
  Text,
  Tr as ChakraTr,
  UnorderedList,
  VStack,
} from '@chakra-ui/react';
import { Table, Tbody, Td, Th, Thead, Tr } from 'admin/components/ui/table';
import { Colors } from 'admin/define/colors';
import {
  PERMISSION_FEATURES,
  PERMISSION_FEATURE_ADMIN_SAML,
  PERMISSION_FEATURE_ADMIN_SERVICE,
  PERMISSION_FEATURE_ADMIN_USER,
  PERMISSION_FEATURE_ADMIN_USER_POOL,
} from 'admin/define/permissions';
import { useUserCorporationInfo } from 'admin/hooks/user/useUserCorporationInfo';
import { UserDeleteStateType, UserPoolType, UserType } from 'admin/types/user';
import { UsersListType } from 'admin/types/users';
import {
  ALLOWED_AUTH_CODE_TYPES,
  AUTH_CODE_TYPE_UNIIKEY,
  CORPORATION_USER_POOL_PERMISSION_AUTH_HUB,
  CORPORATION_USER_POOL_PERMISSION_MEMBER,
  CORPORATION_USER_POOL_PERMISSION_TENANT,
  CORPORATION_USER_POOL_PERMISSION_USER_POOL,
} from 'define';
import { useUserInfo } from 'hooks/useUserInfo';
import { FC, memo, useCallback, useMemo } from 'react';
import { MdOutlineCreate } from 'react-icons/md';

export type UsersListComponentProps = {
  userList: UsersListType;
  isAllChecked: boolean;
  userStateList: UserDeleteStateType[];
  onOpen: (id: UserType['id']) => void;
  setAllCheck: () => void;
  setTargetCheck: (id: string) => void;
};

export const UsersListComponent: FC<UsersListComponentProps> = memo(
  ({
    userList,
    isAllChecked,
    userStateList,
    onOpen,
    setAllCheck,
    setTargetCheck,
  }) => {
    const { isMine } = useUserInfo();
    const checkedList = (id: string) =>
      userStateList.filter((chkbox) => chkbox.id === id);
    const { hasPermissionUser, userPoolIdWithSaml } = useUserCorporationInfo();
    // 権限設定が可能なパーミッションリストの生成
    const permissionSettingList = useMemo(() => {
      const permissionList = [
        PERMISSION_FEATURE_ADMIN_USER,
        PERMISSION_FEATURE_ADMIN_SERVICE,
        PERMISSION_FEATURE_ADMIN_USER_POOL,
      ];

      // SAML設定権限が存在する場合のみ設定可能
      if (userPoolIdWithSaml !== '') {
        permissionList.push(PERMISSION_FEATURE_ADMIN_SAML);
      }

      return permissionList;
    }, [userPoolIdWithSaml]);

    const generateFilterUserPools = useCallback(
      (up: UserPoolType[]) =>
        up.filter(
          (x) =>
            !!x.authenticationType &&
            ALLOWED_AUTH_CODE_TYPES.includes(x.authenticationType),
        ),
      [],
    );

    return (
      <Table tableHeight="100%">
        <Thead position="sticky" zIndex="sticky" top={0}>
          <ChakraTr>
            <Th w="40px">
              <Checkbox
                isChecked={isAllChecked}
                onChange={() => setAllCheck()}
              />
            </Th>
            <Th minW="210px" w="210px">
              ユーザー名
            </Th>
            <Th minW="210px">メールアドレス</Th>
            <Th minW="220px" w="220px" px={0}>
              権限
            </Th>
            <Th minW="150px" w="150px" px={0}>
              {' '}
            </Th>
            {hasPermissionUser && <Th w="100px"> </Th>}
          </ChakraTr>
        </Thead>
        <Tbody>
          {userList.map((user: UserType) => (
            <Tr key={user.id}>
              <Td py="4px">
                {!isMine(user.id) && (
                  <Checkbox
                    isChecked={
                      checkedList(user.id).length !== 0
                        ? checkedList(user.id)[0].state
                        : false
                    }
                    onChange={() => setTargetCheck(user.id)}
                  />
                )}
              </Td>
              <Td py="4px">
                <Text>{user.name}</Text>
              </Td>
              <Td py="4px">
                <Text>{user.email}</Text>
              </Td>
              <Td py="8px" pr="4px" verticalAlign="top" h="100%" px={0}>
                <Box
                  bgColor={Colors.GRAY_70}
                  borderRadius="4px"
                  p="8px 16px"
                  h="100%"
                >
                  <Text fontWeight="700">一般</Text>
                  <Box ml="4px">
                    <UnorderedList>
                      {user.permission
                        .filter((x) => permissionSettingList.includes(x))
                        .map((permission, idx) => (
                          <ListItem
                            key={`user_permission_${permission}_${String(idx)}`}
                          >
                            {PERMISSION_FEATURES.get(permission)}
                          </ListItem>
                        ))}
                    </UnorderedList>
                  </Box>
                </Box>
              </Td>
              <Td py="8px" pl="4px" verticalAlign="top" h="100%" px={0}>
                <Box
                  bgColor={Colors.GRAY_70}
                  borderRadius="4px"
                  p="8px 16px"
                  h="100%"
                >
                  <VStack
                    alignItems="flex-start"
                    divider={<StackDivider borderColor={Colors.GRAY_30} />}
                  >
                    {generateFilterUserPools(user.userPools).map(
                      (userPool, xIdx) => (
                        <Box key={`user_userPool_${String(xIdx)}`}>
                          <Text fontWeight="700">{userPool.userPoolName}</Text>
                          <Box ml="4px">
                            <UnorderedList>
                              {userPool.permission.includes(
                                CORPORATION_USER_POOL_PERMISSION_USER_POOL,
                              ) && <ListItem>IDシステム設定管理</ListItem>}
                              {userPool.permission.includes(
                                CORPORATION_USER_POOL_PERMISSION_TENANT,
                              ) && <ListItem>テナント設定管理</ListItem>}
                              {userPool.permission.includes(
                                CORPORATION_USER_POOL_PERMISSION_MEMBER,
                              ) && <ListItem>会員管理</ListItem>}
                              {userPool.permission.includes(
                                CORPORATION_USER_POOL_PERMISSION_AUTH_HUB,
                              ) &&
                                userPool.authenticationType ===
                                  AUTH_CODE_TYPE_UNIIKEY && (
                                  <ListItem>Auth&nbsp;Hub設定</ListItem>
                                )}
                            </UnorderedList>
                          </Box>
                        </Box>
                      ),
                    )}
                  </VStack>
                </Box>
              </Td>
              {hasPermissionUser && (
                <Td py="4px">
                  <Button
                    variant="outline"
                    colorScheme="primary"
                    bg="WHITE"
                    leftIcon={<MdOutlineCreate />}
                    onClick={() => onOpen(user.id)}
                  >
                    編集
                  </Button>
                </Td>
              )}
            </Tr>
          ))}
        </Tbody>
      </Table>
    );
  },
);
