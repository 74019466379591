import { LoginAuthModeValue, LoginFormType } from 'api/user/types';
import { LoginFormStepEmail } from 'components/login/loginForm/loginFormStepEmail';
import { LoginFormStepGidp } from 'components/login/loginForm/loginFormStepGidp';
import {
  LOGIN_AUTH_MODE_EMAIL,
  LOGIN_AUTH_MODE_GIDP_AUTH
} from 'define';
import { FC, memo } from 'react';
import { useFormContext } from 'react-hook-form';

type Props = {
  formId: string;
  authMode: LoginAuthModeValue;
  isLoading: boolean;
  onSubmit: (value: LoginFormType) => void;
};

export const LoginFormComponent: FC<Props> = memo(
  ({ formId, authMode, isLoading, onSubmit }: Props) => {
    const { handleSubmit } = useFormContext<LoginFormType>();

    return (
      <form onSubmit={handleSubmit(onSubmit)} id={formId}>
        <LoginFormStepEmail
          isShow={authMode === LOGIN_AUTH_MODE_EMAIL}
          formId={formId}
          isLoading={isLoading}
        />
        <LoginFormStepGidp
          isShow={authMode === LOGIN_AUTH_MODE_GIDP_AUTH}
          formId={formId}
          isLoading={isLoading}
        />
      </form>
    );
  },
);
