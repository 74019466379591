export const messages = {
  // ウォール一覧取得
  getWallList: {
    customer: {
      notFound: 'ウォール一覧が存在しません。既に削除された可能性があります。',
      error:
        '申し訳ございません。ウォール一覧情報取得できません。時間を置いて再表示をお願いします。',
      failed:
        '申し訳ありません。正しいウォール一覧情報を得ることができません。',
    },
    system: {
      typeError:
        '[api.paywall.getWallList] ウォール一覧取得APIのレスポンス型が予期せぬデータ型です。フロントの修正をしてください',
    },
  },
  // ウォール詳細取得
  getWall: {
    customer: {
      notFound: 'ウォールが存在しません。既に削除された可能性があります。',
      error:
        '申し訳ございません。ウォール情報取得できません。時間を置いて再表示をお願いします。',
      failed: '申し訳ありません。正しいウォール情報を得ることができません。',
    },
    system: {
      typeError:
        '[api.paywall.getWall] ウォール取得APIのレスポンス型が予期せぬデータ型です。フロントの修正をしてください',
    },
  },
  // ウォールを登録
  postWall: {
    customer: {
      error: '申し訳ございません。ウォールの保存に失敗しました。',
      failed:
        'ウォールの保存に失敗しました。恐れ入りますが時間をおいて再度登録してください。',
    },
    system: {
      typeError: '[api.paywall.postWall] 予期せぬエラーが発生しました。',
    },
  },
  // ウォールを変更
  patchWall: {
    customer: {
      error: '申し訳ございません。ウォールの修正に失敗しました。',
      failed:
        'ウォールの修正に失敗しました。恐れ入りますが時間をおいて再度登録してください。',
    },
    system: {
      typeError: '[api.paywall.patchWall] 予期せぬエラーが発生しました。',
    },
  },
  // ウォールステータス変更
  patchWallPublish: {
    customer: {
      error: '申し訳ございません。ウォールステータス情報の保存に失敗しました。',
      failed:
        'ウォールステータス情報の保存に失敗しました。恐れ入りますが時間をおいて再度登録してください。',
    },
    system: {
      typeError:
        '[api.paywall.patchWallPublish] 予期せぬエラーが発生しました。',
    },
  },
  // ウォールデザイン一覧取得
  getWallDesignList: {
    customer: {
      notFound:
        'ウォールデザイン一覧が存在しません。既に削除された可能性があります。',
      error:
        '申し訳ございません。ウォールデザイン一覧情報取得できません。時間を置いて再表示をお願いします。',
      failed:
        '申し訳ありません。正しいウォールデザイン一覧情報を得ることができません。',
    },
    system: {
      typeError:
        '[api.paywall.getWallDesignList] ウォールデザイン一覧取得APIのレスポンス型が予期せぬデータ型です。フロントの修正をしてください',
    },
  },
  // ウォールデザイン詳細情報取得
  getWallDesign: {
    customer: {
      notFound:
        'ウォールデザインが存在しません。既に削除された可能性があります。',
      error:
        '申し訳ございません。ウォールデザイン詳細情報取得できません。時間を置いて再表示をお願いします。',
      failed:
        '申し訳ありません。正しいウォールデザイン詳細情報を得ることができません。',
    },
    system: {
      typeError:
        '[api.paywall.getMailId] ウォールデザイン詳細情報取得APIのレスポンス型が予期せぬデータ型です。フロントの修正をしてください',
    },
  },
  // ウォールデザイン作成
  postWallDesign: {
    customer: {
      error: '申し訳ございません。ウォールデザイン作成に失敗しました。',
      failed:
        'ウォールデザイン作成に失敗しました。恐れ入りますが時間をおいて再度登録してください。',
    },
    system: {
      typeError: '[api.paywall.postWallDesign] 予期せぬエラーが発生しました。',
    },
  },
  // ウォールデザイン編集
  patchWallDesign: {
    customer: {
      error: '申し訳ございません。ウォールデザイン修正に失敗しました。',
      failed:
        'ウォールデザイン修正に失敗しました。恐れ入りますが時間をおいて再度登録してください。',
    },
    system: {
      typeError: '[api.paywall.patchWallDesign] 予期せぬエラーが発生しました。',
    },
  },
  // ウォールデザインステータス変更
  patchWallDesignPublish: {
    customer: {
      error:
        '申し訳ございません。ウォールデザインステータス情報の保存に失敗しました。',
      failed:
        'ウォールデザインステータス情報の保存に失敗しました。恐れ入りますが時間をおいて再度登録してください。',
    },
    system: {
      typeError:
        '[api.paywall.patchWallDesignPublish] 予期せぬエラーが発生しました。',
    },
  },
  //  ダッシュボード情報取得処理
  getDashboard: {
    customer: {
      notFound: '登録会員数などの推移データが見つかりませんでした。',
      error:
        '申し訳ございません。データを取得できません。時間を置いて再表示をお願いします。',
      typeError: '申し訳ございません。データ表示できません',
      failed:
        '申し訳ありません。正しいダッシュボード情報を得ることができません。',
    },
    system: {
      typeError:
        '[api.paywall.dashboard.getDashboard] ダッシュボード情報取得APIのレスポンス型が予期せぬデータ型です。フロントの修正をしてください',
    },
  },
  // コース一覧取得
  getCourseList: {
    customer: {
      notFound: 'コース一覧が存在しません。既に削除された可能性があります。',
      error:
        '申し訳ございません。コース一覧情報取得できません。時間を置いて再表示をお願いします。',
      failed: '申し訳ありません。正しいコース一覧情報を得ることができません。',
    },
    system: {
      typeError:
        '[api.paywall.getCourseList] コース一覧取得APIのレスポンス型が予期せぬデータ型です。フロントの修正をしてください',
    },
  },
  // コースを登録
  postCourse: {
    customer: {
      error: '申し訳ございません。コースの保存に失敗しました。',
      failed:
        'コースの保存に失敗しました。恐れ入りますが時間をおいて再度登録してください。',
    },
    system: {
      typeError: '[api.paywall.postCourse] 予期せぬエラーが発生しました。',
    },
  },
  // コースを変更
  patchCourse: {
    customer: {
      error: '申し訳ございません。コースの修正に失敗しました。',
      failed:
        'コースの修正に失敗しました。恐れ入りますが時間をおいて再度登録してください。',
    },
    system: {
      typeError: '[api.paywall.patchCourse] 予期せぬエラーが発生しました。',
    },
  },
  // コースステータス変更
  patchCoursePublish: {
    customer: {
      error: '申し訳ございません。コースステータス情報の保存に失敗しました。',
      failed:
        'コースステータス情報の保存に失敗しました。恐れ入りますが時間をおいて再度登録してください。',
    },
    system: {
      typeError:
        '[api.paywall.patchCoursePublish] 予期せぬエラーが発生しました。',
    },
  },
  // プロモーション一覧取得
  getPromotionList: {
    customer: {
      notFound:
        'プロモーション一覧が存在しません。既に削除された可能性があります。',
      error:
        '申し訳ございません。プロモーション一覧情報取得できません。時間を置いて再表示をお願いします。',
      failed:
        '申し訳ありません。正しいプロモーション一覧情報を得ることができません。',
    },
    system: {
      typeError:
        '[api.paywall.getPromotionList] プロモーション一覧取得APIのレスポンス型が予期せぬデータ型です。フロントの修正をしてください',
    },
  },
  // プロモーションを登録
  postPromotion: {
    customer: {
      error: '申し訳ございません。プロモーションの保存に失敗しました。',
      failed:
        'プロモーションの保存に失敗しました。恐れ入りますが時間をおいて再度登録してください。',
    },
    system: {
      typeError: '[api.paywall.postPromotion] 予期せぬエラーが発生しました。',
    },
  },
  // プロモーションを変更
  patchPromotion: {
    customer: {
      error: '申し訳ございません。プロモーションの修正に失敗しました。',
      failed:
        'プロモーションの修正に失敗しました。恐れ入りますが時間をおいて再度登録してください。',
    },
    system: {
      typeError: '[api.paywall.patchPromotion] 予期せぬエラーが発生しました。',
    },
  },
  // プロモーションステータス変更
  patchPromotionPublish: {
    customer: {
      error:
        '申し訳ございません。プロモーションステータス情報の保存に失敗しました。',
      failed:
        'プロモーションステータス情報の保存に失敗しました。恐れ入りますが時間をおいて再度登録してください。',
    },
    system: {
      typeError:
        '[api.paywall.patchPromotionPublish] 予期せぬエラーが発生しました。',
    },
  },
  // 単品商品一覧取得
  getWallItemList: {
    customer: {
      notFound: '単品商品一覧が存在しません。既に削除された可能性があります。',
      error:
        '申し訳ございません。単品商品一覧情報取得できません。時間を置いて再表示をお願いします。',
      failed:
        '申し訳ありません。正しい単品商品一覧情報を得ることができません。',
    },
    system: {
      typeError:
        '[api.paywall.getWallItemList] 単品商品一覧取得APIのレスポンス型が予期せぬデータ型です。フロントの修正をしてください',
    },
  },
  // 単品商品を登録
  postWallItem: {
    customer: {
      error: '申し訳ございません。単品商品の保存に失敗しました。',
      failed:
        '単品商品の保存に失敗しました。恐れ入りますが時間をおいて再度登録してください。',
    },
    system: {
      typeError: '[api.paywall.postWallItem] 予期せぬエラーが発生しました。',
    },
  },
  // 単品商品を変更
  patchWallItem: {
    customer: {
      error: '申し訳ございません。単品商品の修正に失敗しました。',
      failed:
        '単品商品の修正に失敗しました。恐れ入りますが時間をおいて再度登録してください。',
    },
    system: {
      typeError: '[api.paywall.patchWallItem] 予期せぬエラーが発生しました。',
    },
  },
  // 単品商品ステータス変更
  patchWallItemPublish: {
    customer: {
      error: '申し訳ございません。単品商品ステータス情報の保存に失敗しました。',
      failed:
        '単品商品ステータス情報の保存に失敗しました。恐れ入りますが時間をおいて再度登録してください。',
    },
    system: {
      typeError:
        '[api.paywall.patchWallItemPublish] 予期せぬエラーが発生しました。',
    },
  },
  // 単品商品一覧取得
  getMailSettings: {
    customer: {
      notFound: '単品商品一覧が存在しません。既に削除された可能性があります。',
      error:
        '申し訳ございません。単品商品一覧情報取得できません。時間を置いて再表示をお願いします。',
      failed:
        '申し訳ありません。正しい単品商品一覧情報を得ることができません。',
    },
    system: {
      typeError:
        '[api.paywall.getWallItemList] 単品商品一覧取得APIのレスポンス型が予期せぬデータ型です。フロントの修正をしてください',
    },
  },
  // 単品商品一覧変更
  patchMailSettings: {
    customer: {
      notFound: '単品商品一覧が存在しません。既に削除された可能性があります。',
      error:
        '申し訳ございません。単品商品一覧情報取得できません。時間を置いて再表示をお願いします。',
      failed:
        '申し訳ありません。正しい単品商品一覧情報を得ることができません。',
    },
    system: {
      typeError:
        '[api.paywall.getWallItemList] 単品商品一覧取得APIのレスポンス型が予期せぬデータ型です。フロントの修正をしてください',
    },
  },
  // 規約情報取得
  getTerm: {
    customer: {
      notFound: '規約情報が存在しません。既に削除された可能性があります。',
      error:
        '申し訳ございません。規約情報取得できません。時間を置いて再表示をお願いします。',
      failed: '申し訳ありません。正しい規約情報を得ることができません。',
    },
    system: {
      typeError:
        '[api.paywall.getTerm] 規約情報取得APIのレスポンス型が予期せぬデータ型です。フロントの修正をしてください',
    },
  },
  // 規約情報更新
  putTerm: {
    customer: {
      error: '申し訳ございません。規約情報の更新に失敗しました。',
      failed:
        '規約情報の更新に失敗しました。恐れ入りますが時間をおいて再度登録してください。',
    },
    system: {
      typeError: '[api.paywall.putTerm] 予期せぬエラーが発生しました。',
    },
  },
  // ペイウォールメール共通設定取得
  getMailSettingCommon: {
    customer: {
      notFound: 'ペイウォールメール共通設定情報が存在しません。既に削除された可能性があります。',
      error:
        '申し訳ございません。ペイウォールメール共通設定情報取得できません。時間を置いて再表示をお願いします。',
      failed: '申し訳ありません。正しいペイウォールメール共通設定情報を得ることができません。',
    },
    system: {
      typeError:
        '[api.paywall.getMailSettingCommon] ペイウォールメール共通設定情報取得APIのレスポンス型が予期せぬデータ型です。フロントの修正をしてください',
    },
  },
  // ペイウォールメール共通設定更新
  patchMailSettingCommon: {
    customer: {
      error: '申し訳ございません。ペイウォールメール共通設定の更新に失敗しました。',
      failed:
        'ペイウォールメール共通設定の更新に失敗しました。恐れ入りますが時間をおいて再度登録してください。',
    },
    system: {
      typeError: '[api.paywall.patchMailSettingCommon] 予期せぬエラーが発生しました。',
    },
  },
  // ペイウォールメール設定取得
  getMailSettingPersonal: {
    customer: {
      notFound: 'ペイウォールメール設定情報が存在しません。既に削除された可能性があります。',
      error:
        '申し訳ございません。ペイウォールメール設定情報取得できません。時間を置いて再表示をお願いします。',
      failed: '申し訳ありません。正しいペイウォールメール設定情報を得ることができません。',
    },
    system: {
      typeError:
        '[api.paywall.getMailSettingPersonal] ペイウォールメール設定情報取得APIのレスポンス型が予期せぬデータ型です。フロントの修正をしてください',
    },
  },
  // ペイウォールメール設定更新
  patchMailSettingPersonal: {
    customer: {
      error: '申し訳ございません。ペイウォールメール設定の更新に失敗しました。',
      failed:
        'ペイウォールメール設定の更新に失敗しました。恐れ入りますが時間をおいて再度登録してください。',
    },
    system: {
      typeError: '[api.paywall.patchMailSettingPersonal] 予期せぬエラーが発生しました。',
    },
  },
  // ペイウォールメールテスト送信
  postMailSettingSendTestMail: {
    customer: {
      error: '申し訳ございません。ペイウォールメールテスト送信に失敗しました。',
      failed:
        'ペイウォールメールテスト送信に失敗しました。恐れ入りますが時間をおいて再度登録してください。',
    },
    system: {
      typeError: '[api.paywall.postMailSettingSendTestMail] 予期せぬエラーが発生しました。',
    },
  },
  // オーダーキャンセル
  deleteOrder: {
    customer: {
      error: '申し訳ございません。自動購読停止処理に失敗しました。',
      failed:
        '自動購読停止処理に失敗しました。恐れ入りますが時間をおいて再度登録してください。',
      notFound: '対象の注文が見つかりませんでした。恐れ入りますが画面をリロードして再度実行をお願いします',
      noAurthority: 'この操作を行う権限がありません'
    },
    system: {
      typeError: '[api.paywall.deleteOrder] 予期せぬエラーが発生しました。',
    },
  },
  // チャージバック
  orderChargeBack: {
    customer: {
      error: '申し訳ございません。購入取り消し処理に失敗しました。',
      failed:
        '購入取り消し処理に失敗しました。恐れ入りますが時間をおいて再度登録してください。',
      noAurthority: 'このアクションを実行する権限がありません'
    },
    system: {
      typeError: '[api.paywall.orderChargeBack] 予期せぬエラーが発生しました。',
    },
  },
};
