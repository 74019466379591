import { request } from 'admin/api/Request';
import { API_MESSAGES } from 'admin/define/apiMessages';
import { CustomError } from 'admin/error/CustomError';
import { CustomZodError } from 'admin/error/CustomZodError';
import { codeGroupsSchema } from 'admin/schema/idPoolConsumer/master';
import { UserPool } from 'admin/types/userPool/index';
import { CodeGroupsType } from 'admin/types/userPool/master/index';
import { ZodError } from 'zod';

export const getAllCodeGroups = async ({
  id,
  isEnabled,
}: {
  id: UserPool['id'];
  isEnabled?: boolean;
}): Promise<CodeGroupsType> => {
  const path = `uniikey/${id}/code_group/?is_all=true${
    isEnabled ? '&is_enabled=true' : ''
  }`;
  const response = await request<CodeGroupsType>({
    path,
    method: 'get',
  });

  if (response.hasError) {
    if (response.status === 404) {
      throw new CustomError(
        API_MESSAGES.USER_POOL.GET_MASTER_SETTINGS.NOT_FOUND,
        {
          path,
          status: response.status,
        },
      );
    } else {
      throw new CustomError(API_MESSAGES.USER_POOL.GET_MASTER_SETTINGS.ERROR, {
        path,
        status: response.status,
        logLevel: response.status === 400 ? 'warning' : 'error',
      });
    }
  }
  try {
    codeGroupsSchema.parse(response.data);
  } catch (error) {
    if (error instanceof ZodError) {
      throw new CustomZodError(error.message, {
        path,
        status: response.status,
        logLevel: 'error',
      });
    }
  }

  return response.data;
};
