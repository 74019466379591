import { Box, Tag as ChakraTag } from '@chakra-ui/react';
import { useSearch } from 'components/opt/hooks/useSearch';
import { StyledFlex } from 'components/paywall/styled/global';
import { StyledIconClose } from 'components/paywall/styled/local';
import { FC, memo } from 'react';

type SearchedName = {
  id: string;
  orderId: string;
  period: string;
  productId: string;
  productName: string;
  price: string;
  email: string;
  customerUid: string;
  course: string;
  courseId: string;
  courseName: string;
  status: string;
  systemCode: string;
  tags: string;
};
const searchedName = {
  id: '決済ID',
  orderId: '注文ID',
  period: '期間',
  productId: '商品ID',
  productName: 'プラン名',
  price: '金額',
  email: 'メールアドレス',
  customerUid: '顧客ID',
  course: 'コース',
  courseId: 'コース',
  courseName: 'コース',
  status: 'ステータス',
  cancelReason: '終了理由',
  systemCode: 'システム番号',
  tags: '単品タグ',
};
type Props = {
  d: string;
};
export const Tag: FC<Props> = memo(({ d }) => {
  const { removeFilter } = useSearch();
  const { searchParam } = useSearch();

  const multiSearchParamToString = (params: string[]) => {
    if(params.length === 0) return '';

    return params.join(', ');
  }

  const getTagTitle = (key: string) => {
    if(key === searchedName.cancelReason || key === searchedName.status) {
      const tagTitle = multiSearchParamToString(searchParam[d as keyof unknown] as string[]);
      if(tagTitle) return key;

      return '';
    }

    return key;
  }

  if(!getTagTitle(searchedName[d as keyof SearchedName])) return <></>;

  return (
    <ChakraTag>
      <StyledFlex style={{ gap: 5 }}>
        <Box style={{ fontSize: 12, color: '#999' }}>
          {getTagTitle(searchedName[d as keyof SearchedName])}
        </Box>
        {d === 'period' &&
          (searchParam.execTranDateFrom || searchParam.execTranDateTo) && (
            <StyledFlex style={{ fontSize: 14 }}>
              <Box>{searchParam.execTranDateFrom}</Box>〜
              <Box>{searchParam.execTranDateTo}</Box>
            </StyledFlex>
          )}
        {d === 'period' &&
          (searchParam.courseStartFrom || searchParam.courseStartTo) && (
            <StyledFlex style={{ fontSize: 14 }}>
              <Box style={{ fontSize: 11, color: '#999' }}>（契約開始日）</Box>
              <Box>{searchParam.courseStartFrom}</Box>〜
              <Box>{searchParam.courseStartTo}</Box>
            </StyledFlex>
          )}
        {d === 'period' &&
          (searchParam.courseEndFrom || searchParam.courseEndTo) && (
            <StyledFlex style={{ fontSize: 14 }}>
              <Box style={{ fontSize: 11, color: '#999' }}>（契約終了日）</Box>
              <Box>{searchParam.courseEndFrom}</Box>〜
              <Box>{searchParam.courseEndTo}</Box>
            </StyledFlex>
          )}
        {d === 'price' && (
          <StyledFlex style={{ fontSize: 14 }}>
            <Box>
              {searchParam.priceFrom !== 0 ? (
                <>{searchParam.priceFrom}円</>
              ) : (
                ''
              )}
            </Box>
            〜
            <Box>
              {searchParam.priceTo !== 0 ? <>{searchParam.priceTo}円</> : ''}
            </Box>
          </StyledFlex>
        )}
        {(d === 'cancelReason' || d === 'status') && (
          <Box style={{ fontSize: 14 }}>{multiSearchParamToString(searchParam[d as keyof unknown] as string[])}</Box>
        )}

        {d !== 'period' && d !== 'price' && d !== 'cancelReason' && d !== 'status'  && (
          <Box style={{ fontSize: 14 }}>{searchParam[d as keyof unknown]}</Box>
        )}
        <StyledIconClose
          onClick={() => {
            removeFilter(d);
          }}
        />
      </StyledFlex>
    </ChakraTag>
  );
});
