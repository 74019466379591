import { Box } from '@chakra-ui/react';
import { withSuspenseAndErrorBoundary } from 'admin/components/Error/WithErrorBoundary';
import { MemberIdPoolTabsComponent } from 'admin/components/member/memberIdPoolTabs/MemberIdPoolTabsComponent';
import { Colors } from 'admin/define/colors';
import { useMemberIdPoolConsumers } from 'admin/hooks/member/useMemberIdPoolConsumers';
import { IdPoolConsumerListItem } from 'admin/types/userPool';
import { ErrorContents, LoadingSpinner } from 'components/common/atoms';
import { FC, memo, useCallback, useEffect, useState } from 'react';

const fetchMemberIdPoolTabs: FC = memo(() => {
  const [memberIdPoolConsumer, setMemberIdPoolConsumer] =
    useState<IdPoolConsumerListItem>();
  const memberIdPoolConsumers = useMemberIdPoolConsumers();
  const onChangeTab = useCallback(
    (idx: number) => {
      setMemberIdPoolConsumer(memberIdPoolConsumers[idx]);
    },
    [memberIdPoolConsumers],
  );

  useEffect(() => {
    // idPoolConsumerのデータ取得が出来ていない場合はセットするタブが存在しない為、何もしない
    if (!memberIdPoolConsumers) return;

    // 初期処理
    // 選択されている0番目のタブ情報としてidPoolConsumers[0]をセットする
    setMemberIdPoolConsumer(memberIdPoolConsumers[0]);
  }, []);

  if (!memberIdPoolConsumers || !memberIdPoolConsumer) {
    return null;
  }

  return (
    <Box backgroundColor={Colors.WHITE} p={6} mt="16px" borderRadius="8px">
      <MemberIdPoolTabsComponent
        memberIdPoolConsumers={memberIdPoolConsumers}
        selectedId={memberIdPoolConsumer.id}
        onChangeTab={onChangeTab}
      />
    </Box>
  );
});

export const MemberIdPoolTabs = withSuspenseAndErrorBoundary(
  fetchMemberIdPoolTabs,
  {
    ErrorComponent: <ErrorContents name="会員管理情報" />,
    LoadingComponent: <LoadingSpinner />,
  },
);
