import { request } from 'api/request';
import { isResponseError, ResponseError } from 'api/types';
import { isVerifyUser, VerifyUser } from 'api/user/types';
import { Options } from 'ky';
import { getValidationError, ValidationError } from 'utils/form';
import { messages } from './messages';

const DEFAULT_ERR_MESSAGE: ValidationError<VerifyUser> = {
  others: [messages.getInvitation.customer.error],
};
const DEFAULT_SYSTEM_ERR_MESSAGE: ValidationError<VerifyUser> = {
  others: [messages.getInvitation.system.error],
};

/**
 * 招待コードチェックAPI
 *
 * 正常時: void
 * エラー: throw Error
 */
export const getInvitation = async (
  verifyCode: string,
  options?: Options,
): Promise<VerifyUser | ResponseError> => {
  const path = `user/invite/${verifyCode}/`;

  const response = await request({
    path,
    method: 'get',
    options,
  });

  const responseData = await response.data;

  const { error } = response;
  if (response.hasError) {
    if (response.status === 404) {
      return DEFAULT_ERR_MESSAGE;
    }

    // APIにて返却されたエラー形式
    if (isResponseError(error)) {
      const formError = getValidationError<VerifyUser>({
        formData: {
          invitationCode: '',
          authTypeCode: 1,
          message: '',
          email: '',
        },
        response: error,
        replaceKeys: {
          invitationCode: 'others',
        },
      });

      return formError;
    }
  }

  if (isVerifyUser(responseData)) {
    return responseData;
  }

  return DEFAULT_SYSTEM_ERR_MESSAGE;
};
