import { ModalCloseButton, ModalContent, ModalHeader, ModalOverlay } from '@chakra-ui/react';
import { zodResolver } from '@hookform/resolvers/zod';
import { AgreeModalComponent } from 'admin/components/saml/agreeModal/AgreeModalComponent';
import { LoadingLayer } from 'admin/components/ui/loadingLayer';
import { samlSettingAgreeSchema } from 'admin/schema/saml';
import { SamlSettingAgreeType, SamlSettingFormType } from 'admin/types/saml/index';
import { FC, memo } from 'react';
import { FormProvider, useForm } from 'react-hook-form';

type Props = {
  isLoading: boolean;
  samlSetting: SamlSettingFormType;
  onClose: () => void;
  onSubmit: () => void;
};

export const AgreeModal: FC<Props> = memo(
  ({ isLoading, samlSetting, onClose, onSubmit }) => {
    const formId = 'agreeModalForm';

    const methods = useForm<SamlSettingAgreeType>({
      defaultValues: {
        word: '',
      },
      mode: 'onBlur',
      resolver: zodResolver(samlSettingAgreeSchema),
    });

    return (
      <>
        <ModalOverlay />
        <ModalContent fontSize="md" zIndex={100}>
          <ModalHeader fontSize="lg" fontWeight="bold">
            SAML設定確認
          </ModalHeader>
          <FormProvider {...methods}>
            {isLoading && <LoadingLayer />}
            <AgreeModalComponent
              formId={formId}
              samlSetting={samlSetting}
              isLoading={isLoading}
              onClose={onClose}
              onSubmit={onSubmit}
            />
          </FormProvider>
          <ModalCloseButton />
        </ModalContent>
      </>
    )
  },
);
