import {
  maxLength,
  headTitleOptions,
  itemNameOptions,
  screenNameOptions,
  screenDescriptionOptions,
  buttonNameOptions,
  textareaElementOptions,
} from './options';

const groupName = 'missingInfoEntry';

// 不足情報入力画面
export default [
  // ロゴ	画像
  {
    name: `${groupName}.headTitle`,
    label: 'ブラウザタブ表示名',
    tooltip: '',
    type: 'Text',
    defaultValue: '情報の登録',
    options: headTitleOptions,
    elementOptions: {},
  },
  {
    name: `${groupName}.screenName`,
    label: '画面名',
    tooltip: '',
    type: 'Text',
    defaultValue: '情報の登録',
    options: screenNameOptions,
    elementOptions: {},
  },
  {
    name: `${groupName}.screenDescription`,
    label: '案内文 画面',
    tooltip: '',
    type: 'Textarea',
    defaultValue: 'お客さまの情報を入力してください。',
    options: screenDescriptionOptions,
    elementOptions: textareaElementOptions,
  },
  {
    name: `${groupName}.emailItemName`,
    label: '表示名 メールアドレス',
    tooltip: '',
    type: 'Text',
    defaultValue: 'メールアドレス',
    options: itemNameOptions,
    elementOptions: {},
  },
  {
    name: `${groupName}.termsDescription`,
    label: '案内文 規約全体の説明',
    tooltip: '',
    type: 'Textarea',
    defaultValue:
      '本サービスの利用には、以下の各規約に同意する必要があります。',
    options: {
      maxLength: maxLength(50),
    },
    elementOptions: textareaElementOptions,
  },
  {
    name: `${groupName}.termsAgreementCheckbox`,
    label: 'チェックボックス名 規約同意',
    tooltip: '',
    type: 'Textarea',
    defaultValue: '各規約規約に同意する',
    options: {
      maxLength: maxLength(50),
    },
    elementOptions: textareaElementOptions,
  },
  {
    name: `${groupName}.continueButtonName`,
    label: 'ボタン名 入力情報確認画面に遷移',
    tooltip: '',
    type: 'Text',
    defaultValue: '入力内容の確認',
    options: buttonNameOptions,
    elementOptions: {},
  },
] as const;
