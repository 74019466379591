import { useCallback } from 'react';
import { Checkbox } from '@chakra-ui/react';
import { isFalse } from 'utils/bool';
import { CheckBoxEditorProps, DataType } from './types';

/**
 * チェックボックスエディター
 *
 * 値は "0" | "1" の文字列フラグで更新します
 *
 * @param props CheckBoxEditorProps<セルのデータ型>
 * @returns JSX.Element
 */
export const CheckBoxEditor = <T extends DataType>(
  props: CheckBoxEditorProps<T>,
): JSX.Element => {
  const { onChange, value } = props;
  const checkValue = !isFalse(value);

  const changeHandler = useCallback(() => {
    // true だったら false へ
    onChange(checkValue ? '' : '1');
  }, [onChange, checkValue]);

  return (
    <Checkbox
      autoFocus
      isChecked={checkValue}
      onChange={changeHandler}
      borderColor="blue.800"
    />
  );
};
