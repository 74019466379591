import {
  Button,
  DrawerBody,
  DrawerFooter,
  FormControl,
  FormErrorMessage,
  HStack,
  List,
  ListItem,
  Text,
  UnorderedList,
} from '@chakra-ui/react';
import { RhfInput } from 'admin/components/form/RhfInput';
import { AlertInfoBar } from 'admin/components/ui/alertInfo';
import { Colors, ColorSet } from 'admin/define/colors';
import { UserDeleteStateType, UserType } from 'admin/types/user';
import { UserDeleteSubmitFormType } from 'admin/types/users';
import { FC, memo } from 'react';
import { useFormContext, useFormState } from 'react-hook-form';

export type UsersEditFormComponentProps = {
  formId: string;
  deleteList: UserDeleteStateType[];
  isLoading: boolean;
  onClose: () => void;
  onDelete: () => void;
};

export const UsersDeleteFormComponent: FC<UsersEditFormComponentProps> = memo(
  ({ formId, deleteList, isLoading, onClose, onDelete }) => {
    const { handleSubmit } = useFormContext<UserType>();
    const { errors, isValid } = useFormState<UserDeleteSubmitFormType>();

    return (
      <>
        <DrawerBody>
          <AlertInfoBar type="error" iconType="error">
            <UnorderedList>
              <ListItem>
                一度実行すると元の状態に戻せないため注意してください。
              </ListItem>
              <ListItem>削除したユーザーは元に戻せません。</ListItem>
            </UnorderedList>
          </AlertInfoBar>
          <form onSubmit={handleSubmit(onDelete)} id={formId}>
            <Text as="p" mb={1} fontSize="sm" fontWeight="bold" mt={6}>
              下記ユーザーを削除します。よろしいですか？
              <br />
            </Text>
            <List>
              {deleteList.map((item) => (
                <ListItem key={`del-alert-dialog-${item.id}`}>
                  <Text as="p">{`${item.name}さん（${item.email}）`}</Text>
                </ListItem>
              ))}
            </List>
            <Text
              fontWeight="700"
              fontSize="sm"
              mb={6}
              mt={1}
              color={Colors.RED_20}
            >
              この手続きは取り消しすることが出来ません。
            </Text>
            <Text mb={1} fontSize="sm" fontWeight="bold">
              削除する場合は「delete」と入力後に「削除ボタン」を押下してください。
            </Text>
            <FormControl isInvalid={Boolean(errors.word?.message)}>
              <RhfInput<UserDeleteSubmitFormType> name="word" type="text" />
              <FormErrorMessage>{errors.word?.message}</FormErrorMessage>
            </FormControl>
          </form>
        </DrawerBody>
        <DrawerFooter>
          <HStack spacing={4}>
            <Button
              variant="outline"
              onClick={() => onClose()}
              {...ColorSet.Default}
              padding="8px 16px"
            >
              キャンセル
            </Button>
            <Button
              colorScheme="danger"
              type="submit"
              mr={3}
              form={formId}
              disabled={!isValid || isLoading}
              padding="8px 36px"
            >
              削除
            </Button>
          </HStack>
        </DrawerFooter>
      </>
    );
  },
);
