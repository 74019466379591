import { PERMISSION_DIRECTOR } from 'define';
import { useUserInfo } from 'hooks/useUserInfo';

export const useTransactionMailPermission = () => {
  const { isEditableTarget } = useUserInfo();
  const isEditable = isEditableTarget(PERMISSION_DIRECTOR);

  return {
    isEditable,
  };
};
