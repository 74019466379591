/* eslint-disable @typescript-eslint/no-unsafe-member-access */
import { Box, Icon } from '@chakra-ui/react';
import styled from '@emotion/styled';
import { SearchParam, useSearch } from 'components/opt/hooks/useSearch';
import { Input } from 'components/paywall/atoms/Input';
import { Colors } from 'components/paywall/config';
import { StyledFlex } from 'components/paywall/styled/global';
import { FC, memo, useEffect } from 'react';
import {
  MdSearch
} from 'react-icons/md';

const StyledContainer = styled(Box)({});

type Props = {
  title?: string;
  explain?: string;
  inputName: string;
};

export const FilterText: FC<Props> = memo(
  ({ title = 'フリーワード', explain, inputName }) => {
    const { register, setValue, handleFilterTextSearch } = useSearch();
    const { searchParam } = useSearch();

    useEffect(() => {
      const key = inputName as keyof SearchParam;
      setValue(inputName, searchParam[key] as string);
    }, [inputName, searchParam, setValue]);

    return (
      <StyledContainer>
        <StyledFlex style={{ gap: 5, fontSize: "14px", fontWeight: "500" }}>
          {title}
          <Input
            size="sm"
            height="30px"
            width="150px"
            p="8px 16px 8px 12px"
            alignItems="center"
            gap="8px"
            alignSelf="stretch"
            borderWidth="1px"
            borderStyle="solid"
            backgroundColor={Colors.WHITE}
            borderColor={Colors.GRAY_30}
            ref={register(inputName)}
          />
          <Box
            onClick={() => handleFilterTextSearch(inputName)}
            w="24px"
            minW="24px"
            h="24px"
            bgColor={Colors.GRAY_60}
            borderRadius="50%"
            textAlign="center"
            cursor="pointer"
          >
            <Icon
              as={MdSearch}
              boxSize={5}
              color={Colors.GRAY_03}
              verticalAlign="bottom"
            />
          </Box>
          {explain && <>{explain}</>}
        </StyledFlex>
      </StyledContainer>
    );
  },
);
