import { Box } from '@chakra-ui/react';
import { FC, memo } from 'react';

type Props = {
  type: number;
  children: React.ReactNode;
}

/**
 * type: 1 borderあり、背景なし、paddingあり
 * type: 2 borderなし、背景gray、paddingあり
 * type: 3 borderなし、背景なし、paddingなし
 */
export const DetailInner: FC<Props> = memo(({type, children}) => (
  <Box
    p={type !== 3 ? "16px" : ""}
    borderRadius="8px"
    border={type === 1 ? '1px solid #DEE3E7' : ''}
    bgColor={type === 2 ? '#F5F7F9' : ''}
  >
    {children}
  </Box>
));
