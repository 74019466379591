import { Input, InputProps } from '@chakra-ui/react';
import ja from 'date-fns/locale/ja';
import { useCallback, useEffect, useState, VFC } from 'react';
import DatePicker, { registerLocale } from 'react-datepicker';
import { FieldValues, Path, useFormContext } from 'react-hook-form';

registerLocale('ja', ja);

type Props<T extends FieldValues> = {
  selectedDate?: Date | null;
  fieldName: Path<T>;
  placeholder?: string;
  width?: number | string;
  bgcolor?: string;
  inputSize?: string;
  isShowTime?: boolean;
  onChange: (date: Date | null) => void;
} & Pick<InputProps, 'isDisabled'>;
/**
 * @see fieldNameに値をセットするカレンダー
 * @param selectedDate 選択中の日付
 * @param fieldName 日付を設定するインプットのフィールド名
 * @param placeholder 初期説明テキスト
 * @param width インプットのサイズ、未指定でauto
 * @param onChange 親のイベントハンドラ
 * @param isShowTime 時間セレクト許可FLG
 */

export const Calendar = <T extends FieldValues>({
  selectedDate,
  fieldName,
  placeholder,
  width = 'auto',
  bgcolor,
  inputSize = 'md',
  isShowTime = false,
  onChange,
  isDisabled = false,
}: Props<T>): ReturnType<VFC> => {
  const DATETIME_FORMAT = 'yyyy-MM-dd HH:mm';
  const [myDate, setMyDate] = useState<Date | null>();
  const { register } = useFormContext<T>();

  useEffect(() => {
    setMyDate(selectedDate);
  }, [setMyDate, selectedDate]);

  const handleSetDate = useCallback(
    (date: Date | null) => {
      setMyDate(date);
      onChange(date);
    },
    [onChange],
  );

  return (
    <>
      <DatePicker
        selected={myDate}
        onChange={(date: Date) => handleSetDate(date)}
        placeholderText={placeholder}
        showTimeSelect={isShowTime}
        locale="ja"
        customInput={
          <Input
            width={width}
            size={inputSize || 'md'}
            style={{ backgroundColor: bgcolor || '' }}
            {...register(fieldName)}
          />
        }
        dateFormat={DATETIME_FORMAT}
        disabled={isDisabled}
      />
    </>
  );
};
