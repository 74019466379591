import { Tr } from '@chakra-ui/react';
import { Th } from 'components/paywall/features/Table';
import { FC, memo } from 'react';

export const WallDesignColumns: FC = memo(() => (
  <Tr>
    <Th
      maxWidth="122px"
      minWidth="122px"
      fontFamily="Hiragino sans"
      name="画面ID"
    />
    <Th
      maxWidth="631px"
      minWidth="631px"
      fontFamily="Hiragino sans"
      name="画面名"
    />
    <Th
      maxWidth="92px"
      minWidth="92px"
      fontFamily="Hiragino sans"
      name="ステータス"
      titleAlign="flex-end"
    />
  </Tr>
));
