import { ExternalLinkIcon } from '@chakra-ui/icons';
import { Box, Flex, Link, List, ListItem, Text } from '@chakra-ui/react';
import styled from '@emotion/styled';
import { Colors } from 'components/paywall/config';
import { useTerm } from 'hooks/paywall/useTerm';
import { FC, memo } from 'react';
import { Term } from '../typed';

const TermSectionStyledContainer = styled(Box)({
  display: 'flex',
  alignItems: 'baseline',
  borderBottom: '1px solid #E2E8F0',
  paddingBottom: '1rem',
  marginBottom: '1rem',
});
const TermDescriptionStyledContainer = styled(Text)({
  whiteSpace: 'pre-wrap',
});
const LabelStyledContainer = styled(Text)({
  minWidth: '150px',
  color: Colors.GRAY_10,
});
const ListStyledContainer = styled(List)({
  width: 'calc(100% - 150px)',
});
const ListItemFirstStyledContainer = styled(ListItem)({
  paddingBottom: '0.5rem',
});
const ListItemStyledContainer = styled(ListItem)({
  borderTop: '1px solid #E2E8F0',
  paddingTop: '0.5rem',
  paddingBottom: '0.5rem',
});
const termsListElement = (term: Term, idx: number) => {
  if (idx === 0) {
    return (
      <ListItemFirstStyledContainer key={`terms-list-${idx}`}>
        <Text>{term.title}</Text>
        <Link href={term.url} isExternal textDecoration="underline">
          {term.url} <ExternalLinkIcon mx="2px" />
        </Link>
      </ListItemFirstStyledContainer>
    );
  }

  return (
    <ListItemStyledContainer key={`terms-list-${idx}`}>
      <Text>{term.title}</Text>
      <Link href={term.url} isExternal textDecoration="underline">
        {term.url} <ExternalLinkIcon mx="2px" />
      </Link>
    </ListItemStyledContainer>
  );
};

export const TermsSettingArea: FC = memo(() => {
  const termData = useTerm();

  return (
    <Box my="16px">
      <Box
        borderWidth="1px"
        borderRadius="xl"
        p={4}
        w="100%"
        fontFamily="Hiragino Sans"
      >
        <TermSectionStyledContainer>
          <LabelStyledContainer>タイトル</LabelStyledContainer>
          <Text>{termData?.title}</Text>
        </TermSectionStyledContainer>
        <TermSectionStyledContainer>
          <LabelStyledContainer>説明文</LabelStyledContainer>
          <TermDescriptionStyledContainer maxWidth={464}>
            {termData?.description}
          </TermDescriptionStyledContainer>
        </TermSectionStyledContainer>
        <Flex>
          <LabelStyledContainer>規約リンク</LabelStyledContainer>
          <ListStyledContainer>
            {termData?.terms.map((term, index) =>
              termsListElement(term, index),
            )}
          </ListStyledContainer>
        </Flex>
      </Box>
    </Box>
  );
});
