import { Box, Button, Flex, Text } from '@chakra-ui/react';
import { Colors } from 'admin/define/colors';
import { FC, memo } from 'react';
import { MdOutlineAdd } from 'react-icons/md';

type Props = {
  onOpen: () => void;
};

export const AttributeGroupTitleComponent: FC<Props> = memo(
  ({ onOpen }: Props) => (
    <Box mt="40px">
      <Flex justifyContent="flex-end">
        <Box mr="auto">
          <Text
            fontFamily="Hiragino Sans"
            fontSize="18px"
            fontWeight="700"
            lineHeight="170%"
          >
            属性グループ設定
          </Text>
          <Box>
            <Text
              fontWeight="400"
              fontSize="14px"
              lineHeight="170%"
              fontFamily="Hiragino Sans"
              color={Colors.GRAY_00}
            >
              よく使う属性の組み合わせをグループ化することで、フォーム作成時に利用しやすくします。
              <br />
              <br />
              フォーム作成・フォーム編集画面で設問に対して行う「回答必須設定」は、属性グループ単位で適用されます。
              <br />
              例：フォーム作成・フォーム編集画面にて、属性「姓」と属性「名」で構成される属性グループ「姓名」の必須設定を
              <br />
              ONにした場合、グループ内の属性「姓」「名」の両方が回答必須項目になる。
            </Text>
          </Box>
        </Box>

        <Button colorScheme="primary" p="8px 16px" onClick={onOpen}>
          <MdOutlineAdd />
          属性グループ作成
        </Button>
      </Flex>
    </Box>
  ),
);
