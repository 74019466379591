// ----- fetch関連 -----
const GET_SERVICES = {
  ERROR:
    '申し訳ございません。テナント一覧情報の取得ができません。時間を置いて再表示をお願いします。',
  NOT_FOUND: 'テナント一覧が存在しません。既に削除された可能性があります。',
} as const;
const GET_SERVICE = {
  ERROR:
    '申し訳ございません。テナント情報の取得ができません。時間を置いて再表示をお願いします。',
  NOT_FOUND: 'テナントが存在しません。既に削除された可能性があります。',
} as const;
const GET_AUTH_SETTING = {
  ERROR:
    '申し訳ございません。認証情報設定情報の取得ができません。時間を置いて再表示をお願いします。',
  NOT_FOUND: '認証情報設定情報が存在しません。既に削除された可能性があります。',
} as const;
const GET_AUTH_SETTING_DETAIL = {
  ERROR:
    '申し訳ございません。認証情報設定の詳細情報の取得ができません。時間を置いて再表示をお願いします。',
  NOT_FOUND:
    '認証情報設定の詳細情報が存在しません。既に削除された可能性があります。',
} as const;
const GET_TERM = {
  ERROR:
    '申し訳ございません。規約設定情報の取得ができません。時間を置いて再表示をお願いします。',
  NOT_FOUND: '規約設定が存在しません。既に削除された可能性があります。',
} as const;
const GET_TERMS = {
  ERROR:
    '申し訳ございません。規約設定一覧情報の取得ができません。時間を置いて再表示をお願いします。',
  NOT_FOUND: '規約設定一覧が存在しません。既に削除された可能性があります。',
} as const;
const GET_SEVICE_ATTRIBUTE = {
  ERROR:
    '申し訳ございません。属性一覧情報の取得ができません。時間を置いて再表示をお願いします。',
  NOT_FOUND: '属性一覧情報が存在しません。既に削除された可能性があります。',
} as const;
const GET_SEVICE_ATTRIBUTE_DETAIL = {
  ERROR:
    '申し訳ございません。属性利用設定情報の取得ができません。時間を置いて再表示をお願いします。',
  NOT_FOUND: '属性利用設定情報が存在しません。既に削除された可能性があります。',
} as const;
const GET_SEVICE_ATTRIBUTE_GROUPS = {
  ERROR:
    '申し訳ございません。属性グループ一覧情報の取得ができません。時間を置いて再表示をお願いします。',
  NOT_FOUND:
    '属性グループ一覧情報が存在しません。既に削除された可能性があります。',
} as const;
const GET_SEVICE_ATTRIBUTE_GROUP_DETAIL = {
  ERROR:
    '申し訳ございません。属性グループ利用設定情報の取得ができません。時間を置いて再表示をお願いします。',
  NOT_FOUND:
    '属性グループ利用設定情報が存在しません。既に削除された可能性があります。',
} as const;
const GET_NEWSLETTER = {
  ERROR:
    '申し訳ございません。メールマガジン購読一覧の取得ができません。時間を置いて再表示をお願いします。',
  NOT_FOUND:
    'メールマガジン購読一覧が存在しません。既に削除された可能性があります。',
} as const;
const GET_NEWSLETTER_DETAIL = {
  ERROR:
    '申し訳ございません。メールマガジン購読設定詳細情報の取得ができません。時間を置いて再表示をお願いします。',
  NOT_FOUND:
    'メールマガジン購読設定詳細が存在しません。既に削除された可能性があります。',
} as const;

// ----- Mutate関連 -----
const UPDATE_SERVICE = {
  ERROR: '申し訳ございません。ユーザープールの割り当てに失敗しました。',
  FAILED:
    'ユーザープールの割り当てに失敗しました。恐れ入りますが時間をおいて再度実行してください。',
} as const;
const UPDATE_SETTING_WHITELIST = {
  ERROR: '申し訳ございません。ホワイトリストの保存に失敗しました。',
  FAILED:
    'ホワイトリストの保存に失敗しました。恐れ入りますが時間をおいて再度実行してください。',
} as const;
const UPDATE_SETTING_REDIRECT_URI = {
  ERROR: '申し訳ございません。コールバックURL設定の保存に失敗しました。',
  FAILED:
    'コールバックURL設定の保存に失敗しました。恐れ入りますが時間をおいて再度実行してください。',
} as const;
const UPDATE_SEVICE_ATTRIBUTE = {
  ERROR: '申し訳ございません。属性利用設定の保存に失敗しました。',
  FAILED:
    '属性利用設定の保存に失敗しました。恐れ入りますが時間をおいて再度実行してください。',
} as const;
const UPDATE_SEVICE_ATTRIBUTE_GROUP = {
  ERROR: '申し訳ございません。属性グループ利用設定の保存に失敗しました。',
  FAILED:
    '属性グループ利用設定の保存に失敗しました。恐れ入りますが時間をおいて再度実行してください。',
} as const;
const UPDATE_TERM = {
  ERROR: '申し訳ございません。規約設定の保存に失敗しました。',
  FAILED:
    '規約設定の保存に失敗しました。恐れ入りますが時間をおいて再度実行してください。',
} as const;
const CHANGE_SEVICE_ATTRIBUTE_AUTO_APPEND = {
  ERROR: '申し訳ございません。「全テナント割当」の変更に失敗しました。',
  FAILED:
    '「全テナント割当」の変更に失敗しました。恐れ入りますが時間をおいて再度実行してください。',
} as const;
const CHANGE_SEVICE_ATTRIBUTE_FORM_ATTR_CHANGE = {
  ERROR: '申し訳ございません。「全フォーム利用」の変更に失敗しました。',
  FAILED:
    '「全フォーム利用」の変更に失敗しました。恐れ入りますが時間をおいて再度実行してください。',
} as const;
const CHANGE_SEVICE_ATTRIBUTE_USER_ATTR = {
  ERROR: '申し訳ございません。「会員登録時利用」の変更に失敗しました。',
  FAILED:
    '「会員登録時利用」の変更に失敗しました。恐れ入りますが時間をおいて再度実行してください。',
} as const;
const CHANGE_SEVICE_ATTRIBUTE_USER_ATTR_REQUIRED = {
  ERROR: '申し訳ございません。「会員登録時利用 必須」の変更に失敗しました。',
  FAILED:
    '「会員登録時利用 必須」の変更に失敗しました。恐れ入りますが時間をおいて再度実行してください。',
} as const;
const CHANGE_SEVICE_NEWSLETTER_TENANT_APPEND = {
  ERROR: '申し訳ございません。「テナント割り当て」の変更に失敗しました。',
  FAILED:
    '「テナント割り当て」の変更に失敗しました。恐れ入りますが時間をおいて再度実行してください。',
} as const;
const CHANGE_SEVICE_NEWSLETTER_DELIVERY_AVAILABLE = {
  ERROR: '申し訳ございません。「配信利用」の変更に失敗しました。',
  FAILED:
    '「配信利用」の変更に失敗しました。恐れ入りますが時間をおいて再度実行してください。',
} as const;
const CHANGE_SEVICE_NEWSLETTER_DISPLAY_WHEN_REGISTRATION = {
  ERROR: '申し訳ございません。「初期登録時表示」の変更に失敗しました。',
  FAILED:
    '「初期登録時表示」の変更に失敗しました。恐れ入りますが時間をおいて再度実行してください。',
} as const;
const CHANGE_SEVICE_NEWSLETTER_CHECK_WHEN_REGISTRATION = {
  ERROR: '申し訳ございません。「初期表示チェック」の変更に失敗しました。',
  FAILED:
    '「初期表示チェック」の変更に失敗しました。恐れ入りますが時間をおいて再度実行してください。',
} as const;

export const SERVICE = {
  SERVICE: {
    GET_SERVICES,
    GET_SERVICE,
    GET_AUTH_SETTING,
    GET_AUTH_SETTING_DETAIL,
    GET_SEVICE_ATTRIBUTE,
    GET_SEVICE_ATTRIBUTE_DETAIL,
    GET_SEVICE_ATTRIBUTE_GROUPS,
    GET_SEVICE_ATTRIBUTE_GROUP_DETAIL,
    GET_NEWSLETTER,
    GET_NEWSLETTER_DETAIL,
    GET_TERM,
    GET_TERMS,
    UPDATE_SERVICE,
    UPDATE_SETTING_WHITELIST,
    UPDATE_SETTING_REDIRECT_URI,
    UPDATE_SEVICE_ATTRIBUTE,
    UPDATE_SEVICE_ATTRIBUTE_GROUP,
    UPDATE_TERM,
    CHANGE_SEVICE_ATTRIBUTE_AUTO_APPEND,
    CHANGE_SEVICE_ATTRIBUTE_FORM_ATTR_CHANGE,
    CHANGE_SEVICE_ATTRIBUTE_USER_ATTR,
    CHANGE_SEVICE_ATTRIBUTE_USER_ATTR_REQUIRED,
    CHANGE_SEVICE_NEWSLETTER_TENANT_APPEND,
    CHANGE_SEVICE_NEWSLETTER_DELIVERY_AVAILABLE,
    CHANGE_SEVICE_NEWSLETTER_DISPLAY_WHEN_REGISTRATION,
    CHANGE_SEVICE_NEWSLETTER_CHECK_WHEN_REGISTRATION,
  },
};
