import { UserPoolChildAttributeType } from 'admin/types/service/attribute';
import { Page } from 'api/common/types';

export const queryKeyNames = {
  getSerivceAttributes: 'getSerivceAttributes',
  getSerivceAttributeDetail: 'getSerivceAttributeDetail',
  getSerivceAttributeGroups: 'getSerivceAttributeGroups',
  getSerivceAttributeGroupDetail: 'getSerivceAttributeGroupDetail',
};

export const querySerivceAttributeKey = {
  getSerivceAttributes: (
    userPoolChildId: string,
    currentPage?: Page['currentPage'],
    perPage?: Page['perPage'],
  ) =>
    [
      queryKeyNames.getSerivceAttributes,
      userPoolChildId,
      currentPage,
      perPage,
    ].filter((item) => item),
  getSerivceAttributeDetail: (
    userPoolChildId: string,
    userPoolCHildAttributeId: UserPoolChildAttributeType['id'],
  ) => [
    queryKeyNames.getSerivceAttributeDetail,
    userPoolChildId,
    userPoolCHildAttributeId,
  ],
  getSerivceAttributeGroups: (
    userPoolChildId: string,
    currentPage?: Page['currentPage'],
    perPage?: Page['perPage'],
  ) =>
    [
      queryKeyNames.getSerivceAttributeGroups,
      userPoolChildId,
      currentPage,
      perPage,
    ].filter((item) => item),
  getSerivceAttributeGroupDetail: (
    userPoolChildId: string,
    userPoolCHildAttributeId: UserPoolChildAttributeType['id'],
  ) => [
    queryKeyNames.getSerivceAttributeGroupDetail,
    userPoolChildId,
    userPoolCHildAttributeId,
  ],
} as const;
