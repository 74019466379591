import { Text } from '@chakra-ui/react';
import { withSuspenseAndErrorBoundary } from 'admin/components/Error/WithErrorBoundary';
import { useUserPoolsCreatedCount } from 'admin/hooks/idPoolConsumers/useUserPoolsCreatedCount';
import { ErrorContents, LoadingSkeleton } from 'components/common/atoms';
import { FC, memo } from 'react';

export const FetchIdPoolConsumerUserPoolsCreatedCountComponent: FC = memo(
  () => {
    const userPoolsCreatedCount = useUserPoolsCreatedCount();

    return (
      <Text mt="4px">
        {userPoolsCreatedCount.count}/{userPoolsCreatedCount.max}
      </Text>
    );
  },
);

// ErrorBoundaryを仕込まないとAPIエラーが発生した場合は真っ白な画面になる可能性がある
export const IdPoolConsumerUserPoolsCreatedCountComponent =
  withSuspenseAndErrorBoundary(
    FetchIdPoolConsumerUserPoolsCreatedCountComponent,
    {
      ErrorComponent: <ErrorContents name="ユーザープール作成数" />,
      LoadingComponent: <LoadingSkeleton line={1} />,
    },
  );
