import { customErrorMessage, toZod } from 'admin/config/zod';
import { AuthType } from 'admin/types/service/auth';
import { z } from 'zod';

// zodErrorの日本語化対応
z.setErrorMap(customErrorMessage);

export const multiLineStringSchema = z
  .string()
  .transform((val) => val.split(/\r?\n/).filter((line) => line !== '')); // 改行コードで分割して string[] に変換

export const authSchema = z.object<toZod<AuthType>>({
  tenantId: z.string(),
  clientId: z.string(),
  authHubSecret: z.string().nullable(),
  authHubDomain: z.string().nullable().optional(),
  redirectUri: z.array(z.string()),
  uniikeyApiKey: z.string().optional(),
  uniikeySecretKey: z.string().optional(),
  allowIpList: z.array(z.string()).optional(),
});

export const authFormSchema = z.object({
  redirectUri: multiLineStringSchema,
});
