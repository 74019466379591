import { VFC, memo } from 'react';
import {
  Flex,
  Box,
  Table,
  Thead,
  Tbody,
  Tr,
  Th,
  Td,
  Checkbox,
  Icon,
} from '@chakra-ui/react';
import { MdCreate } from 'react-icons/md';
import { User, UserDeleteState } from 'api/user/types';
import { useUserInfo } from 'hooks/useUserInfo';
import { UsersEditDrawerForm } from 'components/setting/users/molecules/UsersEditDrawerForm';

type UsersBodyInnerProps = {
  list: User[];
  delStateList: UserDeleteState[];
  isAllChecked: boolean;
  setAllCheck: () => void;
  setTargetCheck: (id: string) => void;
  refetch: () => void;
};

export const UsersBodyInner: VFC<UsersBodyInnerProps> = memo(
  ({
    list,
    delStateList,
    isAllChecked,
    setAllCheck,
    setTargetCheck,
    refetch,
  }: UsersBodyInnerProps) => {
    const { isOwner, isMine, getPermissionName, isEditableTarget } =
      useUserInfo();
    const checkedList = (id: string) =>
      delStateList.filter((chkbox) => chkbox.id === id);

    return (
      <Flex w="full" alignItems="center" justifyContent="flex-start">
        <Box w="full" overflowY="auto" maxHeight="calc(100vh - 145px)">
          <Table variant="striped">
            <Thead position="sticky" zIndex="sticky" top={0} bgColor="white">
              <Tr>
                {isOwner && (
                  <Th fontSize="sm">
                    <Checkbox
                      borderColor="blue.600"
                      isChecked={isAllChecked}
                      onChange={() => setAllCheck()}
                      name="setting-user-all-checkbox"
                    />
                  </Th>
                )}
                <Th fontSize="sm">ユーザー名</Th>
                <Th fontSize="sm">メールアドレス</Th>
                <Th fontSize="sm">権限</Th>
                <Th fontSize="sm"> </Th>
              </Tr>
            </Thead>
            <Tbody>
              {list.map((item, idx) => (
                <Tr
                  key={`setting-user-data-${String(idx)}`}
                  data-testid="setting-user-table-tr"
                >
                  {isOwner && (
                    <Td w={4}>
                      {!Array.isArray(item.tenant) &&
                        isEditableTarget(item.tenant.permission) &&
                        !isMine(item.id) && (
                          <Checkbox
                            borderColor="blue.600"
                            isChecked={
                              checkedList(item.id).length !== 0
                                ? checkedList(item.id)[0].state
                                : false
                            }
                            onChange={() => setTargetCheck(item.id)}
                          />
                        )}
                    </Td>
                  )}
                  <Td minW={150} w={180}>
                    {item.name}
                  </Td>
                  <Td minW={150} w={240}>
                    {item.email}
                  </Td>
                  <Td minW={40} w={40}>
                    {!Array.isArray(item.tenant) &&
                      getPermissionName(item.tenant.permission)}
                  </Td>
                  <Td>
                    {isOwner &&
                      !Array.isArray(item.tenant) &&
                      isEditableTarget(item.tenant.permission) &&
                      !isMine(item.id) && (
                        <Flex justifyContent="flex-end">
                          <UsersEditDrawerForm
                            title="ユーザー修正"
                            user={item}
                            openBtnProps={{ variant: 'ghost' }}
                            openBtnChildNode={
                              <>
                                <Icon as={MdCreate} />
                              </>
                            }
                            openBtnTestId="setting-user-edit-btn"
                            submitBtnTitle="修正"
                            submitBtnHandler={refetch}
                          />
                        </Flex>
                      )}
                  </Td>
                </Tr>
              ))}
            </Tbody>
          </Table>
        </Box>
      </Flex>
    );
  },
);
