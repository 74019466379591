import { useRouteAuth } from 'auth/authContext';
import { LogoHeaderProps } from 'components/common/molecules';
import { TemplateNotLogged } from 'components/common/templates/TemplateNotLogged';
import { FORCE_LOGOUT_STATUS_MESSAGE, HTTP_STATUS_FORCE_LOGOUT } from 'define';
import { FC, useEffect, useMemo } from 'react';
import { Helmet } from 'react-helmet-async';
import { useSearchParams } from 'react-router-dom';

export const Logout: FC<LogoHeaderProps> = () => {
  const { onLogout } = useRouteAuth();
  const [searchParams] = useSearchParams();

  const statusCode = useMemo<string>(
    () => searchParams.get('status') ?? '',
    [searchParams],
  );

  useEffect(() => {
    let msg = '';
    // 強制ログアウト時はメッセージを付与する
    if (String(HTTP_STATUS_FORCE_LOGOUT) === statusCode) {
      msg = FORCE_LOGOUT_STATUS_MESSAGE;
    }

    onLogout(msg);
  }, [onLogout]);

  return (
    <TemplateNotLogged ml="148px" formPosition="flex-start">
      <Helmet>
        <title>ログアウト</title>
      </Helmet>
    </TemplateNotLogged>
  );
};

export default Logout;
