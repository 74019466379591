import { VFC, memo } from 'react';
import {
  Table,
  Thead,
  Tr,
  Th,
  Tbody,
  Flex,
  Tooltip,
  Text,
  css,
} from '@chakra-ui/react';
import { QuestionIcon } from '@chakra-ui/icons';
import { GridTableProps, DataType } from './types';
import './react-datasheet.css';

const cssTbl = css({
  width: '100%',
  textAlign: 'center',
  borderCollapse: 'collapse',
  borderSpacing: 0,

  th: {
    background: '#e9faf9',
    border: 'solid 1px #CBD5E0',
    textAlign: 'center',
    position: 'sticky',
    top: '-1px',
    zIndex: 1,
    // '::before': {
    //   content: '""',
    //   position: 'absolute',
    //   top: '-1px',
    //   left: '0',
    //   width: '100%',
    //   height: '100%',
    //   borderTop: 'solid 1px #CBD5E0',
    // },
  },
  tr: {
    border: 'dashed 1px #CBD5E0',
  },
  td: {
    padding: '2px !important',
    border: 'solid 1px #CBD5E0 !important',
    height: '30px',
  },
});

const ToolTipText: VFC<{ text: string }> = ({ text }) => (
  <Text fontSize="xs">{text}</Text>
);

const GridTableComponent = <T extends DataType>(
  props: GridTableProps<T>,
): JSX.Element => {
  const { className, rownumber, columns, children } = props;

  return (
    <Table variant="simple" className={className} css={cssTbl}>
      <Thead>
        <Tr>
          {rownumber ? (
            <Th width="5%" px={0}>
              <Text fontSize="xs">No</Text>
            </Th>
          ) : (
            {}
          )}
          {columns.map((col, idx) => (
            <Th key={`col-${String(idx)}`} w={col.width} px={0}>
              <Flex alignItems="center" justifyContent="center">
                <Text as="span">{col.label}</Text>
                {col.hint && (
                  <Tooltip
                    label={<ToolTipText text={col.hint} />}
                    bg="teal.50"
                    color="gray.800"
                    size="xs"
                  >
                    <QuestionIcon ml={4} style={{ cursor: 'pointer' }} />
                  </Tooltip>
                )}
              </Flex>
            </Th>
          ))}
        </Tr>
      </Thead>
      <Tbody>{children}</Tbody>
    </Table>
  );
};

export const GridTable = memo(GridTableComponent) as typeof GridTableComponent;
