import { withSuspenseAndErrorBoundary } from 'admin/components/Error/WithErrorBoundary';
import { TermsTable } from 'admin/components/idPoolConsumer/terms/termsTable';
import { TermsTitle } from 'admin/components/idPoolConsumer/terms/termsTitle';
import { Breadcrumb } from 'admin/components/ui/breadcrumb';
import { Pagination } from 'admin/components/ui/pagination';
import { Truncate } from 'admin/components/ui/truncate';
import { usePaginationQuery } from 'admin/hooks/pagination/usePaginationQuery';
import { useUserPoolId } from 'admin/hooks/params/useUserPoolId';
import { useUserPool } from 'admin/hooks/userPool/useUserPool';
import { BreadcrumbType } from 'admin/types/ui/breadcrumb';
import { Page } from 'api/common/types';
import { ErrorContents, LoadingSpinner } from 'components/common/atoms';
import { useSetPageTitle } from 'hooks/useSetPageTitle';
import { FC, memo, useMemo, useState } from 'react';

const PlainTerms: FC = memo(() => {
  useSetPageTitle('規約設定');
  const userPoolId = useUserPoolId();
  const { data: userPool } = useUserPool(userPoolId);
  const [page, setPage] = useState<Page>({
    count: 0,
    currentPage: 1,
    countFrom: 0,
    countTo: 0,
    perPage: 0,
    pageCount: 0,
    next: '',
    previous: '',
  });
  const { query } = usePaginationQuery({ page });
  const currentPage = useMemo(() => query.currentPage, [query.currentPage]);
  const perPage = useMemo(() => query.currentPerPage, [query.currentPerPage]);

  const breadcrumbItems: BreadcrumbType[] = [
    { url: '/admin', title: '組織管理TOP' },
    {
      url: '/admin/idPoolConsumer',
      title: 'IDシステム設定管理',
    },
    {
      url: '',
      title: <Truncate>{userPool?.name || ''}</Truncate>,
    },
    { url: '', title: '規約設定' },
  ];

  return (
    <>
      <Breadcrumb breadcrumbItems={breadcrumbItems} />
      <TermsTitle />
      <TermsTable
        currentPage={currentPage}
        setPage={setPage}
        perPage={perPage}
      />
      <Pagination page={page} />
    </>
  );
});

export const TermsIndex = withSuspenseAndErrorBoundary(PlainTerms, {
  ErrorComponent: <ErrorContents name="規約設定" />,
  LoadingComponent: <LoadingSpinner />,
});
