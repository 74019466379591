import { Options } from 'ky';
import { request } from 'api/request';
import produce from 'immer';
import { ResponseError, isResponseError } from 'api/types';
import { PasswordResetFormForget } from 'api/user/types';
import { getValidationError, ValidationError } from 'utils/form';

import { messages } from './messages';

const DEFAULT_ERR_MESSAGE: ValidationError<PasswordResetFormForget> = {
  others: [messages.postPasswordResetForget.customer.error],
};
const DEFAULT_SYSTEM_ERR_MESSAGE: ValidationError<PasswordResetFormForget> = {
  others: [messages.postPasswordResetForget.customer.failed],
};

/**
 * ユーザー情報変更
 *
 * 状態により以下のレスポンスを返却します
 * 正常時: true
 * エラー: ResponseError
 *
 */
export const postUserPasswordForget = async (
  formParam: PasswordResetFormForget,
  options?: Options,
): Promise<ResponseError | boolean> => {
  const { email } = formParam;
  const path = `user/password_forget/`;
  const postParam = { email };

  const mergedOptions = options
    ? produce(options, (draft) => {
        draft.json = { ...postParam };
      })
    : { json: postParam };

  const response = await request({
    path,
    method: 'post',
    options: mergedOptions,
  });

  if (response.status >= 500) {
    return DEFAULT_ERR_MESSAGE;
  }

  if (response.status === 404) {
    return DEFAULT_SYSTEM_ERR_MESSAGE;
  }

  const { error } = response;
  if (response.hasError) {
    // APIにて返却されたエラー形式
    if (isResponseError(error)) {
      const formError = getValidationError<PasswordResetFormForget>({
        formData: formParam,
        response: error,
      });

      return formError;
    }
  }

  return true;
};
