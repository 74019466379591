import { Text } from '@chakra-ui/react';
import { FC, memo } from 'react';

type Props = {
  date: string;
}

export const DetailMemoDate: FC<Props> = memo(({date}) => (
  <Text w="100%">{date}</Text>
));
