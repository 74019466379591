import { request } from 'api/request';
import produce from 'immer';
import { Options } from 'ky';
import { isUser, User } from './types';

const path = 'user/login_gidp/';

export const login = async ({
  email,
  password,
  options,
}: {
  email: string;
  password: string;
  options?: Options;
}): Promise<User | { title: string; description?: string }> => {
  const postParam = { email, password };

  const mergedOptions = options
    ? produce(options, (draft) => {
        draft.json = { ...postParam };
      })
    : { json: postParam };

  const response = await request({
    path,
    method: 'post',
    options: mergedOptions,
  });

  const responseData = response.data;

  // 成功
  if (isUser(responseData)) {
    return responseData;
  }

  // 入力不備 or 認証エラー
  if (response.status === 400) {
    return { title: 'ログイン失敗', description: '入力内容を確認ください' };
  }
  if (response.status === 401) {
    return {
      title: 'ログイン失敗',
      description: 'パスワードリセットが行われております。メールを確認ください',
    };
  }

  // 予期せぬエラー
  return { title: 'ログインできません' };
};
