import { Box, Heading, Modal, useDisclosure } from '@chakra-ui/react';
import { withSuspenseAndErrorBoundary } from 'admin/components/Error/WithErrorBoundary';
import { PublicChangeModal } from 'admin/components/service/terms/publicChangeModal';
import { TermDrawer } from 'admin/components/service/terms/termDrawer/index';
import { useServiceTerms } from 'admin/hooks/service/terms/useServiceTerms';
import { ServiceTermType } from 'admin/types/service/terms';
import { Page } from 'api/common/types';
import { ErrorContents } from 'components/common/atoms';
import { Dispatch, FC, memo, SetStateAction, useEffect, useState } from 'react';
import { RuleTableComponent } from './RuleTableComponent';

type Props = {
  currentPage: number;
  perPage: number;
  setPage: Dispatch<SetStateAction<Page>>;
};

const fetchTermsTable: FC<Props> = memo(({ currentPage, perPage, setPage }) => {
  const {
    isOpen: isModalOpen,
    onClose: onModalClose,
    onOpen: onModalOpen,
  } = useDisclosure();
  const {
    isOpen: isDrawerOpen,
    onClose: onDrawerClose,
    onOpen: onDrawerOpen,
  } = useDisclosure();

  const { data: terms, page } = useServiceTerms(currentPage, perPage);
  const [target, setTarget] = useState<ServiceTermType>();

  useEffect(() => {
    setPage(page);
  }, [page, setPage]);

  if (!terms) {
    return null;
  }

  if (terms.length === 0) {
    return (
      <Box
        w="100%"
        textAlign="center"
        bg="white"
        mt="16px"
        borderRadius={4}
        padding={10}
      >
        <Heading as="h3" fontWeight="bold" fontSize="24px">
          規約が未作成です。
        </Heading>
      </Box>
    );
  }

  return (
    <>
      <RuleTableComponent
        terms={terms}
        onDrawerOpen={onDrawerOpen}
        onModalOpen={onModalOpen}
        setTarget={setTarget}
      />
      <TermDrawer onClose={onDrawerClose} isOpen={isDrawerOpen} term={target} />
      <Modal isOpen={isModalOpen} onClose={onModalClose} isCentered size="xl">
        <PublicChangeModal onClose={onModalClose} term={target} />
      </Modal>
    </>
  );
});

export const TermsTable = memo(
  withSuspenseAndErrorBoundary(fetchTermsTable, {
    ErrorComponent: <ErrorContents name="規約一覧" />,
  }),
);
