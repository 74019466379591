import { getMailSettingPersonal } from 'api/paywall/getMailSettingPersonal';
import { MailSettingPersonal, MailSettingStatuses } from 'components/paywall/pages/MailSetting/typed';
import { useCustomToast } from 'hooks/useCustomToast';
import { useUserTenantId } from 'hooks/user/useUserTenantId';
import { useQuery } from 'react-query';
import { queryPaywallKey } from './queryPaywallKey';

/**
 * ペイウォールメール設定取得
 * @return {MailSettingPersonal | undefined} ペイウォールメール設定
 */
export const useMailSettingPersonal = (type: keyof MailSettingStatuses): MailSettingPersonal => {
  const toast = useCustomToast();
  const tenantId = useUserTenantId();
  const response = useQuery(
    queryPaywallKey.getMailSettingPersonal({ tenantId, type }),
    () => getMailSettingPersonal(tenantId, type),
    {
      onError: (err: unknown) => {
        if (err instanceof Error) {
          toast.closeAll();
          toast({
            title: err.message,
            status: 'error',
          });
        }
      },
    },
  );
  const { data } = response;

  return data as MailSettingPersonal;
};
