import { Box, Flex, Text, Tooltip } from '@chakra-ui/react';
import ErrorBoundary from 'api/ErrorBoundary';
import { ErrorContents, ErrorStatusContents } from 'components/common/atoms';
import { Link } from 'components/paywall/atoms/Link';
import { Colors } from 'components/paywall/config';
import { usePaywall } from 'components/paywall/hooks/usePaywall';
import { usePaywallUtil } from 'components/paywall/hooks/usePaywallUtil';
import { CustomErrorBoundary } from 'components/paywall/molecules/CustomErrorBoundary';
import { CourseDrawer } from 'components/paywall/pages/CourseList/CourseDrawer';
import { Container } from 'components/paywall/templates/Container';
import { useErrorBoundaryReset } from 'hooks/useErrorBoundaryReset';
import { FC, memo } from 'react';
import { CourseCardList } from './CourseCardList';

export const CourseList: FC = memo(() => {
  const { wallName } = usePaywall({});
  const { formatText } = usePaywallUtil();
  const { ebKey, onError } = useErrorBoundaryReset();
  const renderErrorComponent = {
    404: <ErrorStatusContents code={404} />,
    450: <ErrorStatusContents code={450} />,
  };

  return (
    <CustomErrorBoundary pageTitle="サブスクリプション一覧">
      <Container
        title={
          <Flex flexWrap="wrap">
            <Link to="/paywall/wallList">
              <Flex>
                <Text
                  fontSize="14px"
                  color={Colors.GRAY_20}
                  fontWeight="700"
                  cursor="pointer"
                >
                  サブスクリプション商品設定
                </Text>
              </Flex>
            </Link>
            <Text as="span" color={Colors.BLACK} fontWeight="700">
              &nbsp;/&nbsp;
            </Text>
            <Flex>
              <Tooltip
                label={formatText({ text: wallName, trimCnt: 10 }).originText}
                bg="gray.100"
                color="black"
                isDisabled={
                  formatText({ text: wallName, trimCnt: 10 }).isDisabled
                }
              >
                <Text>
                  {formatText({ text: wallName, trimCnt: 10 }).trimText} -
                  コース設定
                </Text>
              </Tooltip>
            </Flex>
          </Flex>
        }
        label={<CourseDrawer isAdd buttonName="新規作成" />}
        px="45px"
      >
        <Box mt="40px">
          <ErrorBoundary
            errorComponent={<ErrorContents name="コース一覧" />}
            statusComponent={renderErrorComponent}
            onError={onError}
            key={ebKey.current}
          >
            <CourseCardList />
          </ErrorBoundary>
        </Box>
      </Container>
    </CustomErrorBoundary>
  );
});
