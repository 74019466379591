import { Box, ListItem, Text, UnorderedList, VStack } from '@chakra-ui/react';
import { SettingMaskingText } from 'admin/components/service/auth/settingMaskingText';
import { Colors } from 'admin/define/colors';
import { AuthType } from 'admin/types/service/auth';
import { FC, memo } from 'react';

type Props = {
  authData: AuthType;
};

export const AccessSecretKey: FC<Props> = memo(({ authData }: Props) => (
  <VStack
    spacing={4}
    alignItems="flex-start"
    fontFamily="Hiragino Sans"
    display="inline-block"
    w="100%"
  >
    <Box>
      <Text fontWeight="700">IDシステム操作用APIキー・シークレットキー</Text>
      <UnorderedList mb="4" marginInlineStart="24px">
        <ListItem>
          <Text color={Colors.GRAY_00} fontSize="12px">
            テナント側システムからIDシステムを操作するために必要になります。
          </Text>
        </ListItem>
      </UnorderedList>
    </Box>
    <Box p="8px 16px" bgColor={Colors.GRAY_70} borderRadius="8px">
      <Text fontWeight="700">APIキー</Text>
      <Text fontSize="16px">{authData?.uniikeyApiKey ?? '-'}</Text>
    </Box>
    <Box p="8px 16px" bgColor={Colors.GRAY_70} borderRadius="8px">
      <Text fontWeight="700">シークレットキー</Text>
      <SettingMaskingText text={authData?.uniikeySecretKey ?? '-'} />
    </Box>
  </VStack>
));
