import { zodResolver } from '@hookform/resolvers/zod';
import { withSuspenseAndErrorBoundary } from 'admin/components/Error/WithErrorBoundary';
import { UsersEditFormComponent } from 'admin/components/users/usersEditForm/UsersEditFormComponent';
import { useUpdateUserDetail } from 'admin/hooks/user/useUpdateUserDetail';
import { userEditFormSchema } from 'admin/schema/user';
import { PermissionFeatureKeyType } from 'admin/types/permission';
import { UserPoolType, UserType } from 'admin/types/user';
import { UserEditFormValues } from 'admin/types/user/form';
import { CorporationUserPoolPermissionType } from 'api/user/types';
import { AlertBar } from 'components/common/atoms';
import { ALLOWED_AUTH_CODE_TYPES } from 'define';
import { FC, memo, useCallback, useEffect, useState } from 'react';
import { FormProvider, SubmitHandler, useForm } from 'react-hook-form';

export type UserEditFormProps = {
  userId: string;
  user: UserType;
  onClose: () => void;
};

export const usersEditForm: FC<UserEditFormProps> = memo(
  ({ userId, user, onClose }) => {
    const [globalErrors, setGlobalErrors] = useState<string[]>([]);
    const [userPermissionList, setUserPermissionList] = useState<
      PermissionFeatureKeyType[]
    >([]);
    const [userPoolPermissionList, setUserPoolPermissionList] = useState<
      UserPoolType[]
    >([]);
    const formId = 'userEditForm';
    const methods = useForm<UserEditFormValues>({
      defaultValues: {
        permission: [],
        userPools: [],
      },
      resolver: zodResolver(userEditFormSchema),
    });
    const { setValue, setError } = methods;
    const { mutate } = useUpdateUserDetail(userId, setError, setGlobalErrors);

    const onSubmit: SubmitHandler<UserEditFormValues> = async (
      data: UserEditFormValues,
    ) => {
      await mutate(data);
      onClose();
    };

    const generateFilterUserPools = useCallback(
      (up: UserPoolType[]) =>
        up.filter(
          (x) =>
            !!x.authenticationType &&
            ALLOWED_AUTH_CODE_TYPES.includes(x.authenticationType),
        ),
      [],
    );

    // TODO: 取得したデータの初期値をreact hook formに乗せる
    useEffect(() => {
      if (user) {
        setValue('permission', user.permission);
        setValue('userPools', generateFilterUserPools(user.userPools));
        setUserPermissionList(user.permission);
        setUserPoolPermissionList(generateFilterUserPools(user.userPools));
      }
    }, [setValue, setUserPermissionList, generateFilterUserPools, user]);

    const onChangePermissionCheck = useCallback(
      (permission: PermissionFeatureKeyType) => {
        let resultArray: PermissionFeatureKeyType[] = [];
        // 権限配列に既に存在している時
        if (userPermissionList.includes(permission)) {
          resultArray = [...userPermissionList.filter((x) => x !== permission)]; // 参照を切る
        } else {
          // 存在していないとき
          resultArray = [...userPermissionList]; // 参照を切る
          resultArray.push(permission);
        }
        setUserPermissionList(resultArray);
        setValue('permission', resultArray);
      },
      [setUserPermissionList, userPermissionList, setValue],
    );

    // ユーザープール権限設定
    const onChangeUserPoolPermissionCheck = useCallback(
      (index: number, userPoolKey: CorporationUserPoolPermissionType) => {
        const resultArray: UserPoolType[] = [...userPoolPermissionList];
        // 権限配列に既に存在している時
        if (userPoolPermissionList[index].permission.includes(userPoolKey)) {
          resultArray[index].permission = [
            ...userPoolPermissionList[index].permission.filter(
              (x) => x !== userPoolKey,
            ),
          ]; // 参照を切る
        } else {
          // 存在していないとき
          resultArray[index].permission = [
            ...userPoolPermissionList[index].permission,
          ]; // 参照を切る
          resultArray[index].permission.push(userPoolKey);
        }
        setUserPoolPermissionList(resultArray);
        setValue(
          `userPools.${index}.permission`,
          resultArray[index].permission,
        );
      },
      [setUserPoolPermissionList, userPoolPermissionList, setValue],
    );

    return (
      <FormProvider {...methods}>
        {globalErrors.map((err, idx) => (
          <AlertBar
            key={`global-err-idx${String(idx)}`}
            message={err}
            status="error"
            mb={4}
          />
        ))}
        <UsersEditFormComponent
          formId={formId}
          user={user}
          userPermissionList={userPermissionList}
          userPoolPermissionList={userPoolPermissionList}
          onChangePermissionCheck={onChangePermissionCheck}
          onChangeUserPoolPermissionCheck={onChangeUserPoolPermissionCheck}
          onClose={onClose}
          onSubmit={onSubmit}
        />
      </FormProvider>
    );
  },
);

export const UsersEditForm = withSuspenseAndErrorBoundary(usersEditForm);
