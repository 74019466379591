import {
  Promotion,
  PromotionFormType,
  PromotionPublish
} from 'components/paywall/pages/PromotionList/typed';

/**
 * プロモーション デフォルト値
 */
export const PromotionDefaultValues: Promotion = {
  id: '',
  name: '',
  price: null,
  month: 1,
  startDate: null,
  endDate: null,
  tags: '',
  status: false,
  memo: '',
  course: '',
  createdAt: '',
  updatedAt: '',
  sellStatus: 0,
} as const;

/**
 * PromotionFormType デフォルト値
 */
export const PromotionFormDefaultValues: PromotionFormType = {
  id: '',
  name: '',
  price: null,
  month: 1,
  startDate: null,
  endDate: null,
  tags: '',
  status: false,
  memo: '',
  course: '',
};

/**
 * PromotionPublish デフォルト値
 */
export const PromotionPublishDefaultValues: PromotionPublish = {
  id: '',
  tenantId: '',
  courseId: '',
  status: false,
};
