import { Text } from '@chakra-ui/react';
import { FC, memo, ReactNode } from 'react';

type Props = {
  children: ReactNode;
};

export const Truncate: FC<Props> = memo(({ children }) => {
  if (typeof children !== 'string') {
    return <>{children}</>;
  }

  const maxLength = 30;
  const truncatedString =
    children.length > maxLength ? (
      <Text title={children}>{children.slice(0, maxLength)}...</Text>
    ) : (
      children
    );

  return <>{truncatedString}</>;
});
