import { getAttributeGroupDetail } from 'admin/api/userPool/attributeGroup/getAttributeGroupDetail';
import { CustomError } from 'admin/error/CustomError';
import { UserPool } from 'admin/types/userPool';
import { AttributeGroupType } from 'admin/types/userPool/attributeGroup/index';
import { logger } from 'api/logger';
import { useQuery } from 'react-query';
import { queryAttributeGroupKey } from './queryAttributeGroupKey';

export const useAttributeGroupDetail = (
  id: UserPool['id'],
  attributeGroupId: AttributeGroupType['id'],
) => {
  const { data, isLoading } = useQuery({
    queryKey: queryAttributeGroupKey.getAttributeGroupDetail(
      id,
      attributeGroupId,
    ),
    queryFn: () => getAttributeGroupDetail({ id, attributeGroupId }),
    onError: async (error) => {
      if (error instanceof CustomError) {
        await logger({
          loglevel: error.logLevel,
          data: JSON.stringify(error.cause),
          message: error.message,
        });
      }
    },
  });

  return {
    data,
    isLoading,
  };
};
