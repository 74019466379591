import { MailMagazineForm } from './typed';

/**
 * MailMagazine デフォルト値
 */
export const mailMagazineDefaultValue: MailMagazineForm = {
  id: '',
  tenant: '',
  title: '',
  description: '',
  targetType: 0,
  color: '#333333',
  sort: 0,
  isPublic: false,
};
