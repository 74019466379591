import { customErrorMessage, toZod } from 'admin/config/zod';
import { userPoolSchema } from 'admin/schema/userPool';
import { IdPoolConsumerListItem } from 'admin/types/userPool';
import {
  CreateConfirmIdPoolConsumerFormType, CreateIdPoolConsumerFormType, EditIdPoolConsumerFormType
} from 'admin/types/userPool/form';
import { AUTH_CODE_TYPE_UNIIKEY } from 'define';
import { z } from 'zod';

// zodErrorの日本語化対応
z.setErrorMap(customErrorMessage);

export const idPoolConsumerListItemSchema = userPoolSchema.extend<toZod<Pick<IdPoolConsumerListItem, 'serviceNames'>>>({
  serviceNames: z.array(z.string()),
})

export const idPoolConsumerListSchema = z.array(idPoolConsumerListItemSchema);

export const idPoolConsumerFormSchema = z.object<toZod<EditIdPoolConsumerFormType>>(
  {
    id: z.string(), // logger送信用
    name: z.string().min(1, '名前は必須です。')
  },
);

export const createIdPoolConsumerFormSchema = z.object<
  toZod<CreateIdPoolConsumerFormType>
>({
  name: z.string().min(1, '名前は必須です。'),
  fieldName: z.string().nullable(),
  authenticationType: z.string().min(1, '使用するIDシステムは必須です。').or(z.number()),
  isSelfRegistration: z.boolean(),
})
.superRefine((value, ctx) => {
  if (String(value.authenticationType) === String(AUTH_CODE_TYPE_UNIIKEY) && !value.fieldName) {
    ctx.addIssue({
      code: 'custom',
      message: 'フィールド名は必須です。',
      path: ['fieldName'],
      fatal: true,
    })
  }
});

export const confirmIdPoolConsumerFormSchema = z.object<
  toZod<CreateConfirmIdPoolConsumerFormType>
>({
  word: z
    .string()
    .min(1, '"create"と入力してください')
    .refine((val) => val === 'create', {
      message: '"create"と入力してください',
    }),
});
