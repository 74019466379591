import { zodResolver } from '@hookform/resolvers/zod';
import { withSuspenseAndErrorBoundary } from 'admin/components/Error/WithErrorBoundary';
import { AttriburteCreateFormComponent } from 'admin/components/service/attribute/attributeForm/attriburteCreateForm/AttributeCreateFormComponent';
import { useCreateServiceAttribute } from 'admin/hooks/service/attribute/useCreateServiceAttribute';
import { serviceAttributeFormSchema } from 'admin/schema/service/attribute';
import { ServiceAttributeType } from 'admin/types/service/attribute';
import { ServiceAttributeFormType } from 'admin/types/service/attribute/form';
import {
  AlertBar,
  ErrorContents,
  LoadingSpinner,
} from 'components/common/atoms';
import { FC, memo, useState } from 'react';
import { FormProvider, SubmitHandler, useForm } from 'react-hook-form';

type Props = {
  serviceAttribute: ServiceAttributeType | null;
  onClose: () => void;
};

const attriburteCreateForm: FC<Props> = memo(
  ({ serviceAttribute, onClose }: Props) => {
    const [globalErrors, setGlobalErrors] = useState<string[]>([]);
    const formId = `serviceAttributeCreateForm`;
    const methods = useForm<ServiceAttributeFormType>({
      defaultValues: {
        userAttributeSetting: serviceAttribute?.id,
        attributeGroupHead: null,
        isFormAttr: false,
        isUserAttr: false,
        isUserAttrRequired: false,
      },
      resolver: zodResolver(serviceAttributeFormSchema),
    });
    const { setError } = methods;
    const { mutate, isLoading } = useCreateServiceAttribute(
      setError,
      setGlobalErrors,
    );

    const onSubmit: SubmitHandler<ServiceAttributeFormType> = async (
      data: ServiceAttributeFormType,
    ) => {
      await mutate(data);
      onClose();
    };

    return (
      <FormProvider {...methods}>
        {globalErrors.map((err, idx) => (
          <AlertBar
            key={`global-err-idx${String(idx)}`}
            message={err}
            status="error"
            mb={4}
          />
        ))}
        <AttriburteCreateFormComponent
          serviceAttribute={serviceAttribute}
          formId={formId}
          isLoading={isLoading}
          onClose={onClose}
          onSubmit={onSubmit}
        />
      </FormProvider>
    );
  },
);

export const AttriburteCreateForm = withSuspenseAndErrorBoundary(
  attriburteCreateForm,
  {
    ErrorComponent: <ErrorContents name="組織レベル属性詳細" />,
    LoadingComponent: <LoadingSpinner />,
  },
);
