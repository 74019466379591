import { FC, memo, useCallback } from 'react';
import styled from '@emotion/styled';
import {
  Box,
  Table,
  Thead,
  Tbody,
  Tr,
  Th,
  Td,
  Text,
  Tooltip,
  Switch,
} from '@chakra-ui/react';
import ErrorBoundary from 'api/ErrorBoundary';
import { useUserInfo } from 'hooks/useUserInfo';
import { useMailMagazineList } from 'hooks/mail/useMailMagazineList';
import { useErrorBoundaryReset } from 'hooks/useErrorBoundaryReset';
import { useMailMagazinePublish } from 'hooks/mail/useMailMagazinePublish';
import { MailMagazineDrawer } from 'components/setting/mailMagazine/MailMagazineDrawer';
import { PERMISSION_DIRECTOR } from 'define';
import { MailMagazine } from '../typed';

const StyledContainer = styled(Box)({
  width: '100%',
  margin: '0 auto',
});
const StyledThead = styled(Thead)({
  position: 'sticky',
  zIndex: 'sticky',
  top: 0,
  backgroundColor: '#FFF',
});
const StyledTheadSortRank = styled(Th)({
  fontSize: '0.875rem',
  width: '100px',
  minWidth: '100px',
});
const StyledTheadTitle = styled(Th)({
  fontSize: '0.875rem',
  width: '180px',
  minWidth: '180px',
});
const StyledTheadType = styled(Th)({
  fontSize: '0.875rem',
  width: '120px',
  minWidth: '120px',
});
const StyledTheadSubscribe = styled(Th)({
  fontSize: '0.875rem',
  width: '110px',
  minWidth: '110px',
});
const StyledTheadDescription = styled(Th)({
  fontSize: '0.875rem',
  width: '350px',
});
const StyledTheadDelete = styled(Th)({
  fontSize: '0.875rem',
  minWidth: '100px',
  display: 'flex',
});
const StyledIconFlex = styled(Box)({
  display: 'Flex',
  alignItems: 'center',
  gap: '20px',
  paddingLeft: '10px',
});

export const MailMagazineList: FC = memo(() => {
  const { ebKey, onError } = useErrorBoundaryReset();
  const mailMagazineList = useMailMagazineList('all');
  const { isEditableTarget } = useUserInfo();
  const isEditable = isEditableTarget(PERMISSION_DIRECTOR);
  const { onSubmit: onPublish } = useMailMagazinePublish();

  const viewMailMagazineTargetName = (val: number): string => {
    let targetName = '';
    if (val === 1) targetName = '会員ID';
    if (val === 2) targetName = 'メールアドレス';

    return targetName;
  };

  // 表示する説明文書文字数
  const allowedViewTextLen = 30;
  const showToolTip = (text: string): boolean =>
    text.length > allowedViewTextLen;

  const formatText = useCallback(
    (
      text: string,
    ): {
      originText: string;
      trimText: string;
    } => {
      const originText = String(text);
      const trimText = `${originText.substring(0, allowedViewTextLen)} ...`;

      return {
        originText,
        trimText,
      };
    },
    [],
  );

  // 公開設定トグルスイッチChangeイベント
  const onSwitchPublishChange = useCallback(
    (magazine: MailMagazine) => {
      onPublish({
        ...magazine,
        isPublic: !magazine.isPublic,
      });
    },
    [onPublish],
  );

  return (
    <StyledContainer>
      <ErrorBoundary onError={onError} key={ebKey.current}>
        <Box p={4} w="100%" minW="900px">
          <Table variant="striped">
            <StyledThead>
              <Tr>
                <StyledTheadSortRank>表示順</StyledTheadSortRank>
                <StyledTheadTitle>メルマガ名</StyledTheadTitle>
                <StyledTheadType>種別</StyledTheadType>
                <StyledTheadSubscribe>購読者数</StyledTheadSubscribe>
                <StyledTheadDescription>説明</StyledTheadDescription>
                <StyledTheadDelete>
                  {isEditable && (
                    <Text as="span" px="1rem" whiteSpace="nowrap">
                      編集
                    </Text>
                  )}
                  <Text as="span" pl="1rem" whiteSpace="nowrap">
                    公開設定
                  </Text>
                </StyledTheadDelete>
              </Tr>
            </StyledThead>
            <Tbody>
              {mailMagazineList.map((magazine) => (
                <Tr key={magazine.id}>
                  <Td>{magazine.sort}</Td>
                  <Td>{magazine.title}</Td>
                  <Td>{viewMailMagazineTargetName(magazine.targetType)}</Td>
                  <Td>{magazine.deliveryCount}人</Td>
                  <Td>
                    {showToolTip(magazine.description) ? (
                      <Tooltip
                        label={formatText(magazine.description).originText}
                        bg="gray.100"
                        color="black"
                      >
                        <Text cursor="default">
                          {formatText(magazine.description).trimText}
                        </Text>
                      </Tooltip>
                    ) : (
                      <Text cursor="default">{magazine.description}</Text>
                    )}
                  </Td>
                  <Td>
                    <StyledIconFlex>
                      {isEditable && <MailMagazineDrawer obj={magazine} />}
                      <Box width="70px">
                        <Switch
                          size="sm"
                          onChange={() => onSwitchPublishChange(magazine)}
                          isChecked={magazine.isPublic}
                          isDisabled={!isEditable}
                        >
                          {magazine.isPublic ? '有効' : '無効'}
                        </Switch>
                      </Box>
                    </StyledIconFlex>
                  </Td>
                </Tr>
              ))}
            </Tbody>
          </Table>
        </Box>
      </ErrorBoundary>
    </StyledContainer>
  );
});
