import {
  Button,
  HStack,
  ModalBody,
  ModalCloseButton,
  ModalContent,
  ModalFooter,
  ModalHeader,
  ModalOverlay,
  Text,
} from '@chakra-ui/react';
import { LoadingLayer } from 'admin/components/ui/loadingLayer';
import { Colors, ColorSet } from 'admin/define/colors';
import { FC, memo } from 'react';

type Props = {
  isLoading: boolean;
  status: boolean;
  onClose: () => void;
  onSubmit: () => void;
};

export const CheckWhenRegistrationModal: FC<Props> = memo(
  ({ isLoading, status, onClose, onSubmit }) => (
    <>
      {isLoading && <LoadingLayer />}
      <ModalOverlay />
      <ModalContent fontSize="md" zIndex={100} color={Colors.GRAY_03}>
        <ModalHeader fontSize="lg" fontWeight="bold" pt="24px">
          初期表示チェック済みの{!status ? '有効' : '無効'}化
        </ModalHeader>
        <ModalBody>
          <Text>
            初期表示チェック済みにする設定を
            <Text as="span" fontWeight="bold">
              {!status ? '有効' : '無効'}
            </Text>
            にします。
            <br />
            {!status ? '有効' : '無効'}
            にすると、エンドユーザー画面で表示されるチェックボックスが
            {!status ? 'ON' : 'OFF'}の状態で表示されます。
          </Text>
        </ModalBody>
        <ModalFooter pb="24px">
          <HStack spacing={4}>
            <Button
              variant="solid"
              {...ColorSet.Default}
              onClick={onClose}
              w="118px"
              h="40px"
            >
              キャンセル
            </Button>
            <Button
              colorScheme="primary"
              mr={3}
              onClick={() => onSubmit()}
              w="118px"
              h="40px"
            >
              実行
            </Button>
          </HStack>
        </ModalFooter>
        <ModalCloseButton top="22px" right="24px" />
      </ModalContent>
    </>
  ),
);
