import { useLayoutEffect } from 'react';
import { useSetRecoilState } from 'recoil';
import { pageTitleState } from 'store/pageTitleState';
import { PRODUCT_NAME } from 'define';

export const useSetPageTitle = (title: string): void => {
  const setTitle = useSetRecoilState(pageTitleState);
  useLayoutEffect(() => {
    setTitle(title || PRODUCT_NAME);
  }, [setTitle, title]);
};
