import { memo } from 'react';
import {
  useFormContext,
  RegisterOptions,
  Path,
  PathValue,
  UnpackNestedValue,
  useController,
} from 'react-hook-form';
import { toErrMsgList } from 'utils/form';
import { InputText, InputTextProps } from 'components/common/atoms/InputText';

type InputTextFormProps<T> = {
  name: Path<T>;
  value: UnpackNestedValue<PathValue<T, Path<T>>>;
  attr?: RegisterOptions;
} & InputTextProps;

const InputTextFormInner = <T,>({
  name,
  value,
  attr,
  ...rest
}: InputTextFormProps<T>): JSX.Element => {
  const { control } = useFormContext<T>();

  const {
    field: { value: formValue, ...formRest },
    fieldState: { error, invalid },
  } = useController({ name, control, defaultValue: value, rules: attr });

  const errMsgList = invalid ? toErrMsgList({ [name]: error }, name) : [];

  return (
    <InputText
      isInvalid={invalid}
      errors={errMsgList}
      value={formValue as string}
      {...formRest}
      {...rest}
    />
  );
};

InputTextFormInner.defaultProps = {
  attr: {},
};

const typedMemo: <T>(c: T) => T = memo;

export const InputTextForm = typedMemo(InputTextFormInner);
