import {
  WallFormType,
  WallPublish
} from 'components/paywall//pages/WallList/typed';

/**
 * WallFormType デフォルト値
 */
export const WallFormDefaultValues: WallFormType = {
  id: '',
  name: '',
  wallType: '',
  status: false,
  parent: '',
} as const;

/**
 * WallPublish デフォルト値
 */
export const WallPublishDefaultValues: WallPublish = {
  id: '',
  tenantId: '',
  status: false,
};
