import { VFC, memo, useCallback, useState } from 'react';
import { Flex, Box, Text, Icon, List, ListItem } from '@chakra-ui/react';
import { WarningIcon } from '@chakra-ui/icons';
import { MdPersonAdd, MdPersonRemove } from 'react-icons/md';
import { UserDeleteState } from 'api/user/types';
import { postUserTenantDelete } from 'api/user/postUserTenantDelete';
import { Dialog } from 'components/common/atoms';
import { UsersDrawerForm } from 'components/setting/users/molecules/UsersDrawerForm';
import { useUserInfo } from 'hooks/useUserInfo';

type UsersHeaderInnerProps = {
  deleteList: UserDeleteState[];
  isOpen: boolean;
  isLoading: boolean;
  onOpen: () => void;
  onClose: () => void;
  cancelBtnHandelr: () => void;
  submitBtnHandelr: () => void;
};

export const UsersHeaderInner: VFC<UsersHeaderInnerProps> = memo(
  ({
    deleteList,
    isOpen,
    isLoading,
    onOpen,
    onClose,
    cancelBtnHandelr,
    submitBtnHandelr,
  }: UsersHeaderInnerProps) => {
    const isDeletable = deleteList.length > 0;
    const { userTenant } = useUserInfo();
    const [alertText, setAlertText] = useState<string>('');
    const [alertSize, setAlertSize] = useState<string>('md');

    const onDeleteDialogOpen = useCallback(async () => {
      const userTenantId = userTenant?.id as string;
      const userTarget = deleteList.map((val) => val.id);
      const sendData = {
        users: userTarget,
        tenantId: userTenantId,
      };
      const deleteAlert = await postUserTenantDelete(sendData);

      if (deleteAlert?.warning) {
        setAlertText(deleteAlert?.warning as string);
        setAlertSize('2xl');
      } else {
        setAlertText('');
        setAlertSize('md');
      }
      onOpen();
    }, [onOpen, deleteList, userTenant?.id]);

    return (
      <Flex mr={4}>
        {/* 招待ボタン */}
        <Box>
          <UsersDrawerForm
            title="ユーザー招待"
            openBtnProps={{ variant: 'ghost', color: 'blue.400' }}
            submitBtnTitle="招待メール送信"
            openBtnChildNode={
              <>
                <Icon as={MdPersonAdd} fontSize={16} />
                <Text as="span">招待</Text>
              </>
            }
          />
        </Box>
        {/* 削除ボタン */}
        <Dialog
          title="ユーザー削除"
          isOpen={isOpen}
          onOpen={onDeleteDialogOpen}
          onClose={onClose}
          openBtnChildNode={
            <>
              <Icon as={MdPersonRemove} fontSize={16} />
              <Text as="span">削除</Text>
            </>
          }
          openBtnProps={{
            variant: 'ghost',
            color: 'gray.400',
            disabled: !isDeletable,
          }}
          cancelBtnTitle="キャンセル"
          cancelBtnHandelr={cancelBtnHandelr}
          cancelBtnProps={{
            isLoading: isLoading === true,
            variant: 'secondary',
          }}
          submitBtnTitle="削除"
          submitBtnHandelr={submitBtnHandelr}
          submitBtnProps={{
            colorScheme: 'red',
            isLoading: isLoading === true,
          }}
          size={alertSize}
        >
          <Text as="p" mb={2}>
            下記ユーザーを削除します。よろしいですか？
            <br />
          </Text>
          <List pl={4}>
            {deleteList.map((item) => (
              <ListItem key={`del-alert-dialog-${item.id}`}>
                <Text as="p">{`${item.name}さん（${item.email}）`}</Text>
              </ListItem>
            ))}
          </List>
          {alertText && (
            <Text backgroundColor="#edf2f6" p={4} mt={4} whiteSpace="pre-wrap">
              <WarningIcon color="#e53e3e" mt={-1} mr={2} />
              {alertText}
            </Text>
          )}
        </Dialog>
      </Flex>
    );
  },
);
