import { VFC, useState, useCallback } from 'react';
import { useFormContext } from 'react-hook-form';
import {
  Icon,
  Text,
  useDisclosure,
  Box,
  FormControl,
  FormLabel,
} from '@chakra-ui/react';
import { OptionBase, Select, SingleValue } from 'chakra-react-select';
import { MdPersonAdd } from 'react-icons/md';
import { AddGroupUserFormType } from 'api/tenant/types';
import { ErrorTextMsg, Dialog } from 'components/common/atoms';
import { toErrMsgList } from 'utils/form';
import { getTenantUserList } from 'api/user/getTenantUserList';
import { useGroupUserList } from 'hooks/user/useGroupUserList';
import { useUserTenantId } from 'hooks/user/useUserTenantId';
import { useAddGroupUser } from 'hooks/user/useAddGroupUser';

// chakra-react-select用インターフェース
interface userOption extends OptionBase {
  value: string;
  label: string;
}

/**
 * SettingUsersFormProps
 */
type AddGroupMemberDialogProps = {
  formId: string;
  groupId: string;
  openBtnElemType?: 'button' | 'text';
};

export const AddGroupMemberDialog: VFC<AddGroupMemberDialogProps> = ({
  formId,
  groupId = '',
  openBtnElemType = 'button',
}) => {
  const groupUserList = useGroupUserList({ groupId });
  const [userList, setUserList] = useState<userOption[]>([]);
  const [changed, setChanged] = useState(false);
  const tenantId = useUserTenantId();
  const { isOpen, onOpen, onClose } = useDisclosure();
  const {
    reset,
    handleSubmit,
    setValue,
    setError,
    clearErrors,
    formState: { errors },
  } = useFormContext<AddGroupUserFormType>();
  const { onSubmit, isLoading } = useAddGroupUser({ setError, onClose });

  // メンバー追加ポップアップ表示
  const addGroupBtnHandler = useCallback(async () => {
    setValue('groupId', groupId);
    const list = await getTenantUserList(tenantId);
    const selectUserList = list
      .filter((x) => !groupUserList?.some((u) => u.user === x.id))
      .map((item) => ({
        value: item.id,
        label: item.name,
      }));
    setUserList(selectUserList);
    onOpen();
  }, [groupUserList, setValue, groupId, tenantId, onOpen]);

  // メンバー追加処理
  const onAddGroupMemberSubmit = useCallback(
    (data: AddGroupUserFormType): void => {
      setChanged(false);
      onSubmit(data);
    },
    [onSubmit],
  );

  // closeボタン押下時
  const closeBtnHandler = useCallback(() => {
    reset();
    onClose();
  }, [reset, onClose]);

  // フォームエラーの存在チェック
  const isFailed = useCallback(
    (): boolean => !changed && Object.keys(errors).length > 0,
    [changed, errors],
  );

  // ユーザー選択時
  const userSelected = useCallback(
    (data: SingleValue<userOption>): void => {
      clearErrors();
      setChanged(true);
      const user = data;
      if (user) {
        setValue('user', user.value);
      }
    },
    [setValue, clearErrors],
  );

  return (
    <>
      <form
        id={`${formId}-${groupId}`}
        onSubmit={handleSubmit((data) => onAddGroupMemberSubmit(data))}
        autoComplete="off"
      >
        <Dialog
          title="グループにユーザーを追加"
          isOpen={isOpen}
          onOpen={addGroupBtnHandler}
          onClose={closeBtnHandler}
          openBtnElemType={openBtnElemType}
          openBtnChildNode={
            <>
              <Icon as={MdPersonAdd} fontSize={20} />
              <Text as="span">追加</Text>
            </>
          }
          openBtnProps={{
            variant: 'ghost',
            color: 'blue.400',
          }}
          submitBtnTitle="保存"
          submitBtnProps={{
            variant: 'primary',
            type: 'submit',
            form: `${formId}-${groupId}`,
            disabled: isFailed(),
            isLoading: isLoading === true,
          }}
        >
          {/* ユーザー名入力 */}
          <Box>
            <FormControl p={4} isInvalid={isFailed()}>
              <FormLabel>グループに追加するユーザー</FormLabel>
              <Select<userOption, false>
                id="name"
                options={userList}
                placeholder="ユーザー名を入力してください"
                closeMenuOnSelect={false}
                onChange={(data) => userSelected(data)}
              />
              {toErrMsgList(errors, 'user').map((err, idx) => (
                <ErrorTextMsg
                  key={`error-group-user-${String(idx)}`}
                  msg={err}
                />
              ))}
            </FormControl>
          </Box>
        </Dialog>
      </form>
    </>
  );
};
