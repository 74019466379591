import {
  Flex,
  Table,
  TableContainer,
  Tbody,
  Text,
  Thead,
  VStack,
} from '@chakra-ui/react';
import { useActivateAuthHub } from 'admin/hooks/authHub/useActivateAuthHub';
import { useAuthHubs } from 'admin/hooks/authHub/useAuthHubs';
import { useUserTenantId } from 'hooks/user/useUserTenantId';
import { memo, useState } from 'react';
import { Colors } from 'components/paywall/config';
import { Controls } from './Controls';
import { CopyModal } from './CopyModal';
import { DataRow } from './DataRow';
import { DeleteConfirmModal } from './DeleteConfirmModal';
import { EditModal } from './EditModal';
import { Header } from './Header';

export const Inner = memo(
  ({ userPoolId }: { userPoolId?: string }): JSX.Element => {
    const detailPath = userPoolId ? `/admin/authHub/${userPoolId}` : '/authHub';
    const tenantId = useUserTenantId();
    const { data, refetch } = useAuthHubs({ userPoolId, tenantId });
    const { activateMutate } = useActivateAuthHub();

    const [deleteTarget, setDeleteTarget] = useState<
      [string, string] | undefined
    >();
    const [copyTargetId, setCopyTargetId] = useState<string | undefined>();
    const [editTarget, setEditTarget] = useState<
      [string, string] | undefined
    >();

    const list = data?.results ?? [];

    return (
      <>
        <DeleteConfirmModal
          tenantId={tenantId}
          userPoolId={userPoolId ?? ''}
          deleteTarget={deleteTarget}
          setDeleteTarget={setDeleteTarget}
          refetch={refetch}
        />
        <CopyModal
          userPoolId={userPoolId ?? ''}
          tenantId={tenantId}
          copyTargetId={copyTargetId}
          setCopyTargetId={setCopyTargetId}
          refetch={refetch}
        />
        <EditModal
          key={editTarget?.toString()}
          tenantId={tenantId}
          userPoolId={userPoolId ?? ''}
          editTarget={editTarget}
          setEditTarget={setEditTarget}
          refetch={refetch}
        />
        <VStack w="full">
          <Flex w="full" justifyContent="space-between">
            <Flex direction="column">
              <Text fontWeight="700" fontSize="16px">
                Auth Hub設定一覧
              </Text>
              <Text fontSize="12px" color={Colors.GRAY_00} lineHeight="20.4px">
                Auth Hub設定の利用状況を制御します。
              </Text>
            </Flex>
            <Controls
              userPoolId={userPoolId}
              tenantId={tenantId}
              refetch={refetch}
            />
          </Flex>
          <TableContainer
            w="full"
            borderWidth="2px"
            borderStyle="solid"
            rounded="8px"
          >
            <Table>
              <Thead
                position="sticky"
                zIndex="sticky"
                top={0}
                bgColor={Colors.GRAY_70}
              >
                <Header />
              </Thead>
              <Tbody>
                {list?.map((item) => (
                  <DataRow
                    key={item.id}
                    userPoolId={userPoolId}
                    tenantId={tenantId}
                    item={item}
                    detailPath={detailPath}
                    activateMutate={activateMutate}
                    refetch={refetch}
                    setCopyTargetId={setCopyTargetId}
                    setEditTarget={setEditTarget}
                    setDeleteTarget={setDeleteTarget}
                  />
                ))}
              </Tbody>
            </Table>
          </TableContainer>
        </VStack>
      </>
    );
  },
);
