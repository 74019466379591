import {
  AlertDialogCloseButton,
  AlertDialogContent,
  AlertDialogHeader,
  AlertDialogOverlay,
  Text
} from '@chakra-ui/react';
import { withSuspenseAndErrorBoundary } from 'admin/components/Error/WithErrorBoundary';
import { IdPoolConsumerEditForm } from 'admin/components/idPoolConsumer/idPoolConsumerForm';
import { useUserPool } from 'admin/hooks/userPool/useUserPool';
import { IdPoolConsumerListItem } from 'admin/types/userPool';
import { ErrorContents, LoadingSpinner } from 'components/common/atoms';
import { FC, memo } from 'react';

type IdPoolConsumerEditDialogProps = {
  id: IdPoolConsumerListItem['id'];
  onClose: () => void;
};

// ユーザープール編集用fetchコンポーネント
const fetchIdPoolConsumer: FC<IdPoolConsumerEditDialogProps> = memo(
  ({ id, onClose }: IdPoolConsumerEditDialogProps) => {
    const { data: userPool } = useUserPool(id);

    return (
      <IdPoolConsumerEditForm
        userPool={userPool}
        onClose={onClose}
      />
    );
  },
);

export const FetchIdPoolConsumer = withSuspenseAndErrorBoundary(
  fetchIdPoolConsumer,
  {
    ErrorComponent: <ErrorContents name="ユーザープール編集ダイアログ" />,
    LoadingComponent: <LoadingSpinner />,
  },
);

// ユーザープール編集時モーダル
export const IdPoolConsumerEditDialog: FC<IdPoolConsumerEditDialogProps> = memo(
  ({ id, onClose }: IdPoolConsumerEditDialogProps) => (
    <AlertDialogOverlay>
      <AlertDialogContent>
        <AlertDialogHeader>
          <Text>ユーザープール名編集</Text>
        </AlertDialogHeader>
        <FetchIdPoolConsumer id={id} onClose={onClose} />
        <AlertDialogCloseButton />
      </AlertDialogContent>
    </AlertDialogOverlay>
  ),
);
