import { isPage, Page } from 'api/common/types';

/**
 * プロモーション一覧情報 Type
 */
export type PromotionListType = {
  results: Promotion[];
} & Page;

/**
 * プロモーションデータ型
 */
export type Promotion = {
  id: string;
  name: string;
  price: number | null;
  month: number | null;
  startDate: string | null;
  endDate: string | null;
  tags: string | null;
  status: boolean;
  memo: string;
  course: string;
  createdAt: string;
  updatedAt: string;
  sellStatus: number;
};

export type PromotionFormType = {
  id?: string;
  name: string;
  price: number | null;
  month: number | null;
  startDate: string | null;
  endDate: string | null;
  tags: string | null;
  status?: boolean;
  memo: string;
  course: string;
};

export type PromotionSubmitFormType = {
  tenantId: string;
  courseId: string;
  promotionForm: PromotionFormType;
};

export type PromotionPublish = {
  id: string;
  tenantId: string;
  courseId: string;
  status: boolean;
};

export const isPromotion = (data: unknown): data is Promotion => {
  const dt = data as Promotion;

  return (
    typeof dt?.id === 'string' &&
    typeof dt?.name === 'string' &&
    (dt?.month !== null ? typeof dt?.month === 'number' : true) &&
    (dt?.price !== null ? typeof dt?.price === 'number' : true) &&
    (dt?.startDate !== null ? typeof dt?.startDate === 'string' : true) &&
    (dt?.endDate !== null ? typeof dt?.endDate === 'string' : true) &&
    (dt?.tags !== null ? typeof dt?.tags === 'string' : true) &&
    typeof dt?.status === 'boolean' &&
    typeof dt?.memo === 'string' &&
    typeof dt?.course === 'string' &&
    typeof dt?.sellStatus === 'number' &&
    (dt?.createdAt !== null ? typeof dt?.createdAt === 'string' : true) &&
    (dt?.updatedAt !== null ? typeof dt?.updatedAt === 'string' : true)
  );
};

export const isPromotionList = (data: unknown): data is PromotionListType => {
  const dt = data as PromotionListType;

  return isPage(dt) && dt?.results.length !== 0
    ? dt?.results.some((d) => isPromotion(d))
    : true;
};
