import { Corporation } from 'api/user/types';

export const queryIdPoolConsumersKey = {
  getIdPoolConsumers: (userPoolGroupId: Corporation['userPoolGroupId']) => [
    'idPoolConsumers',
    userPoolGroupId,
  ],
  getIdPoolConsumerUserPoolsCreatedCount: (
    currentUserPoolGroupId: Corporation['userPoolGroupId'],
  ) => ['userPoolGroupId', currentUserPoolGroupId],
} as const;
