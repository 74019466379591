import { Options } from 'ky';
import { request } from 'api/request';
import { logger } from 'api/logger';
import { CustomError } from 'api/error/CustomError';
import { dashBoard, isDashboardType } from './types';
import { messages } from './messages';

// ペイウォールiframe取得
export const getDashboard = async (
  tenantId: string,
  options?: Options,
): Promise<dashBoard> => {
  const path = `paywall/${tenantId}/dashboard/`;

  const response = await request({
    path,
    method: 'get',
    options,
  });

  const responseData = (await response.data) as unknown[];

  if (response.hasError) {
    if (response.status === 404) {
      throw new CustomError(
        messages.getDashboard.customer.notFound,
        response.status,
      );
    } else {
      throw new CustomError(
        messages.getDashboard.customer.error,
        response.status,
      );
    }
  }

  if (!isDashboardType(responseData)) {
    await logger({
      loglevel:
        response.status === 404 || response.status === 450
          ? 'warning'
          : 'error',
      data: responseData,
      message: messages.getDashboard.system.typeError,
    });

    throw new CustomError(
      messages.getDashboard.customer.failed,
      response.status,
    );
  }

  return responseData;
};
