import { UseMutateFunction, useQueryClient, useMutation } from 'react-query';
import { useCustomToast } from 'hooks/useCustomToast';
import { queryTenantKey } from 'hooks/tenant/queryTenantKey';
import { ResponseError } from 'api/types';
import { deleteGroup } from 'api/tenant/deleteGroup';

type UseDeleteGroup = {
  tenantId: string;
  groupId: string;
};
type UnPromisify<T> = T extends Promise<infer U> ? U : T;

export const useDeleteGroup = ({
  tenantId,
  groupId,
}: UseDeleteGroup): {
  onDelete: UseMutateFunction<
    UnPromisify<ReturnType<typeof deleteGroup>>,
    // 例外時
    unknown,
    void,
    unknown
  >;
  isLoading: boolean;
  isSuccess: boolean;
} => {
  const queryClient = useQueryClient();
  const toast = useCustomToast();

  const {
    mutate: onDelete,
    isLoading,
    isSuccess,
  } = useMutation(() => deleteGroup({ tenantId, groupId }), {
    onSuccess: (result: boolean | string[] | ResponseError) => {
      void queryClient.invalidateQueries(queryTenantKey.groupList);

      if (typeof result === 'boolean' && result) {
        toast({
          status: 'success',
          position: 'bottom',
          duration: 2000,
          isClosable: true,
          title: '削除しました',
        });

        return;
      }

      if (Array.isArray(result)) {
        result.forEach((msg) => {
          toast({
            status: 'error',
            position: 'bottom',
            duration: 4000,
            isClosable: true,
            title: msg,
          });
        });
      }
    },
    onSettled: () => {
      void queryClient.invalidateQueries(
        queryTenantKey.tenantGroupList({ tenantId }),
      );
    },
  });

  return { onDelete, isLoading, isSuccess };
};
