import { VFC } from 'react';
import {
  Box,
  VStack,
  Flex,
  Tabs,
  TabList,
  Tab,
  TabPanels,
  Text,
} from '@chakra-ui/react';
import { Outlet, Link, useLocation, useParams } from 'react-router-dom';
import {
  AiOutlineTable,
  AiOutlineSearch,
  AiOutlineCloudUpload,
} from 'react-icons/ai';
import { BiTime } from 'react-icons/bi';

import { TableHeader } from 'components/contentsdb/molecules/TableHeader';
import { TableEditForm } from 'components/contentsdb/organisms/TableEditForm';

const tabList = [
  {
    icon: <AiOutlineTable />,
    link: './',
    name: 'テーブル定義',
  },
  {
    icon: <AiOutlineSearch />,
    link: 'search',
    name: 'データ検索',
  },
  {
    icon: <AiOutlineCloudUpload />,
    link: 'upload',
    name: 'データアップロード',
  },
  {
    icon: <BiTime />,
    link: 'publish',
    name: '設定',
  },
];

export const ContentsdbBody: VFC = () => {
  const params = useParams();
  const { pathname = '' } = useLocation();
  const { tableId = '' } = params;

  if (!tableId) {
    return <></>;
  }

  let selectTag = tabList.findIndex(
    (t) => pathname.indexOf(`${tableId}/${t.link}`) !== -1,
  );
  if (selectTag === -1) {
    selectTag = 0;
  }

  return (
    <VStack spacing={0} flex={1}>
      <Flex
        h={12}
        flexBasis={12}
        flexShrink={0}
        w="full"
        alignItems="center"
        justifyContent="space-between"
      >
        {/* メインヘッダー  */}
        <TableHeader />
        <TableEditForm />
      </Flex>
      <Box w="full" flex={1}>
        <Tabs index={selectTag} h="full">
          <TabList>
            {tabList.map((t, idx) => (
              <Link to={t.link} key={`contentsdb-tab-head-${String(idx)}`}>
                <Tab fontSize="sm">
                  {t.icon}
                  <Text ml={2}>{t.name}</Text>
                </Tab>
              </Link>
            ))}
          </TabList>
          <TabPanels h="full" mb={4} pt={4}>
            <Outlet />
          </TabPanels>
        </Tabs>
      </Box>
    </VStack>
  );
};
