import { Box } from '@chakra-ui/react';
import styled from '@emotion/styled';
import ErrorBoundary from 'api/ErrorBoundary';
import { ErrorContents, ErrorStatusContents } from 'components/common/atoms';
import { Form } from 'components/paywall/pages/WallDesignList/WallEditor/Form';
import { Container } from 'components/paywall/templates/Container';
import { useErrorBoundaryReset } from 'hooks/useErrorBoundaryReset';
import { FC, memo } from 'react';

const StyledWallEditorContainer = styled(Box)({
  paddingLeft: 12,
});

export const WallEditor: FC = memo(() => {
  const { ebKey, onError } = useErrorBoundaryReset();
  const renderErrorComponent = {
    404: <ErrorStatusContents code={404} />,
    450: <ErrorStatusContents code={450} />,
  };

  return (
    <Container>
      <ErrorBoundary
        errorComponent={<ErrorContents name="画面作成" />}
        statusComponent={renderErrorComponent}
        onError={onError}
        key={ebKey.current}
      >
        <StyledWallEditorContainer>
          <Form />
        </StyledWallEditorContainer>
      </ErrorBoundary>
    </Container>
  );
});
