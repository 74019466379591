import { Box, Button, Stack, Text } from '@chakra-ui/react';
import { InvitationUser, VerifyUser } from 'api/user/types';
import { AlertBar } from 'components/common/atoms';
import { InviteForm } from 'components/user/molecules/InviteForm';
import { useInvitationUserCreate } from 'hooks/user/useInvitationUserCreate';
import { FC, useEffect, useRef, useState } from 'react';
import { FormProvider, useForm } from 'react-hook-form';

/**
 * InvitationFormProps
 */
type InvitationFormProps = {
  createType: VerifyUser['authTypeCode'];
  inviteEmailAddress: VerifyUser['email'];
  errorMsgs?: string[];
};

export const InviteUserForm: FC<InvitationFormProps> = ({ createType, inviteEmailAddress, errorMsgs = [] }) => {
  const [globalErrors, setGlobalErrors] = useState<string[]>([]);
  const password = useRef({});
  const methods = useForm<InvitationUser>({
    mode: 'onBlur',
  });
  const {
    setError,
    formState: { errors },
    watch,
    handleSubmit,
  } = methods;
  password.current = watch('password', '');
  const { onSubmit, isLoading } = useInvitationUserCreate({
    setError,
    setGlobalErrors,
  });

  useEffect(() => {
    setGlobalErrors(errorMsgs);
  }, [errorMsgs]);

  return (
    <>
      <Box>
        <Text fontSize="24px" align="center" fontWeight="700">
          アカウント登録
        </Text>
      </Box>
      <Box minW={{ base: '90%', md: '368px' }} maxW="368px" mt={8}>
        {globalErrors.map((err, idx) => (
          <AlertBar
            key={`global-err-idx${String(idx)}`}
            message={err}
            status="error"
            mb={4}
          />
        ))}
        <FormProvider {...methods}>
          <form onSubmit={handleSubmit((data) => onSubmit(data))}>
            <Stack
              spacing={4}
              p="1rem"
              backgroundColor="whiteAlpha.900"
              boxShadow="md"
            >
              <InviteForm createType={createType} inviteEmailAddress={inviteEmailAddress} password={password} />
              <Button
                variant="primary"
                type="submit"
                disabled={
                  isLoading || errorMsgs.length > 0 || Object.keys(errors).length > 0
                }
                isLoading={isLoading}
              >
                登録
              </Button>
            </Stack>
          </form>
        </FormProvider>
      </Box>
    </>
  );
};

InviteUserForm.defaultProps = {
  errorMsgs: []
}
