import { zodResolver } from '@hookform/resolvers/zod';
import { StatusChangeFormComponent } from 'admin/components/idPoolConsumer/newsletter/statusChangeForm/StatusChangeFormComponent';
import { LoadingLayer } from 'admin/components/ui/loadingLayer';
import { useChangeNewsletterStatus } from 'admin/hooks/userPool/newsletter/useChangeNewsletterStatus';
import { confirmSubmitFormSchema } from 'admin/schema/util';
import { NewsletterType } from 'admin/types/userPool/newsletter';
import { FC, memo, useCallback } from 'react';
import { FormProvider, useForm } from 'react-hook-form';

type Props = {
  idPoolConsumerId: string;
  target: NewsletterType | undefined;
  onClose: () => void;
};

export const StatusChangeForm: FC<Props> = memo(
  ({ idPoolConsumerId, target, onClose }: Props) => {
    const formId = 'statusChangeForm';

    const methods = useForm({
      defaultValues: {
        word: '',
      },
      mode: 'onBlur',
      resolver: zodResolver(confirmSubmitFormSchema),
    });

    const { mutate, isLoading } = useChangeNewsletterStatus({
      id: target?.id ?? '',
      userPoolId: idPoolConsumerId,
    });

    const onSubmit = useCallback(async () => {
      await mutate();
      onClose();
    }, [mutate, onClose]);

    return (
      <FormProvider {...methods}>
        {isLoading && <LoadingLayer />}
        <StatusChangeFormComponent
          formId={formId}
          isLoading={isLoading}
          onClose={onClose}
          onSubmit={onSubmit}
        />
      </FormProvider>
    );
  },
);
