import { ChakraProvider } from '@chakra-ui/react';
import { CommonDialogProvider } from 'components/paywall/hooks/contexts/CommonDialog';
import { ReactElement, VFC } from 'react';
import { HelmetProvider } from 'react-helmet-async';
import { QueryClient, QueryClientProvider } from 'react-query';
import { BrowserRouter } from 'react-router-dom';
import { RecoilRoot } from 'recoil';
import theme from 'theme';

export const generateQueryClient = (): QueryClient =>
  new QueryClient({
    defaultOptions: {
      queries: {
        retry: false,
        refetchOnWindowFocus: false,
        staleTime: 300000,
        suspense: true,
      },
    },
  });

export const generateTestQueryClient = () => {
  const client = generateQueryClient();
  const options = client.getDefaultOptions();

  const queryOptions = {
    ...options.queries,
    suspense: false,
  };

  client.setDefaultOptions({ ...options, queries: { ...queryOptions } });

  return client;
};

export const Provider: VFC<{ children: ReactElement }> = ({ children }) => (
  <ChakraProvider theme={theme}>
    <BrowserRouter>
      <RecoilRoot>
        <HelmetProvider>
          <QueryClientProvider client={generateQueryClient()}>
            <CommonDialogProvider>{children}</CommonDialogProvider>
          </QueryClientProvider>
        </HelmetProvider>
      </RecoilRoot>
    </BrowserRouter>
  </ChakraProvider>
);
