import { useCallback, useEffect } from 'react';
import { useNavigate } from 'react-router-dom';
import { useRecoilState, useResetRecoilState } from 'recoil';
import { editCallbackState, isEditState, navigateState } from 'store/navigate';

/**
 * useNavigateのラッパー
 * ページ遷移時に制御を入れるため
 */
export const useCustomNavigate = ({
  isEdited = false,
  // isDirty = true, // 240524 revert
  callback,
}: {
  isEdited?: boolean;
  // isDirty?: boolean; // 240524 revert
  callback?: () => void;
}): {
  navigate: (path: string) => void;
  afterNavigate: () => void;
  resetEditState: () => void;
} => {
  const [navigatePath, setNavigatePath] = useRecoilState(navigateState);
  const [isEdit, setIsEdit] = useRecoilState(isEditState);
  const [editCallback, setEditCallback] = useRecoilState(editCallbackState);
  const resetNavigatePath = useResetRecoilState(navigateState);
  const resetIsEdit = useResetRecoilState(isEditState);
  const resetEditCallback = useResetRecoilState(editCallbackState);
  const reactNavigate = useNavigate();

  /**
   * navigateラッパー
   */
  const navigate = useCallback(
    (path: string) => {
      // 2024/05/24 isDirty を使用してメール編集画面から戻る時の表示判定に問題がある為一旦戻します。

      // 編集時
      // if (isEdit && editCallback && isDirty) { // 240524 revert
      if (isEdit && editCallback) {
        // ここでグローバル領域に遷移先のpathを一時的に保存する
        setNavigatePath(path);
        // 編集中のフラグが存在する場合はcallbackを実行する
        editCallback();

        return;
      }
      // 通常通りreact routerで遷移する
      // if (!isEdit || !isDirty) reactNavigate(path); // 240524 revert
      if (!isEdit) reactNavigate(path);
    },
    // [isEdit, isDirty, editCallback, reactNavigate, setNavigatePath], // 240524 revert
    [isEdit, editCallback, reactNavigate, setNavigatePath],
  );

  /**
   * 確認ダイアログより許可された後に実行するnavigate
   */
  const afterNavigate = useCallback(() => {
    // react routerで遷移する
    if (navigatePath !== '') reactNavigate(navigatePath);
    // cleanup
    resetNavigatePath();
    resetIsEdit();
    resetEditCallback();
  }, [
    navigatePath,
    reactNavigate,
    resetNavigatePath,
    resetIsEdit,
    resetEditCallback,
  ]);

  const resetEditState = useCallback(() => {
    resetIsEdit();
    resetEditCallback();
  }, [resetIsEdit, resetEditCallback]);

  useEffect(() => {
    setIsEdit(isEdited);
    setEditCallback(() => callback);

    // return () => resetEditState(); // 240524 revert
  }, [
    isEdited,
    setIsEdit,
    setEditCallback,
    callback,
    resetEditCallback,
    resetEditState,
  ]);

  return {
    navigate,
    afterNavigate,
    resetEditState,
  };
};
