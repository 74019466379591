import { Options } from 'ky';
import { request } from 'api/request';
import { logger } from 'api/logger';
import { isResponseError } from 'api/types';
import { getValidationError } from 'utils/form';
import {
  MAIL_DELIVERY_TO_DIRECT,
  MAIL_DELIVERY_TO_INSERT,
  MAIL_DELIVERY_TO_SEGMENT,
} from 'define';
import { MailTargetCnt, isMailTargetCnt } from './types';
import { messages } from './messages';

/**
 * メール配信対象件数取得API
 * @param id
 * @param options
 * @returns
 */
export const getMailTargetCnt = async (
  tenantId: string,
  deliveryTo: number,
  segment: string,
  subscription: string,
  addressFile: string,
  options?: Options,
): Promise<MailTargetCnt | string> => {
  const queryParam = [];
  queryParam.push(`delivery_to=${deliveryTo}`);
  if (deliveryTo === MAIL_DELIVERY_TO_SEGMENT) {
    queryParam.push(`segment_id=${segment}`);
  }
  if (
    deliveryTo !== MAIL_DELIVERY_TO_DIRECT &&
    deliveryTo !== MAIL_DELIVERY_TO_INSERT
  ) {
    queryParam.push(`subscription_id=${subscription}`);
  }
  if (
    (deliveryTo === MAIL_DELIVERY_TO_DIRECT ||
      deliveryTo === MAIL_DELIVERY_TO_INSERT) &&
    addressFile === ''
  ) {
    return { cnt: 0 };
  }
  if (
    deliveryTo === MAIL_DELIVERY_TO_DIRECT ||
    deliveryTo === MAIL_DELIVERY_TO_INSERT
  ) {
    queryParam.push(`address_file=${addressFile}`);
  }

  const param = queryParam.length > 0 ? `?${queryParam.join('&')}` : '';
  const path = `mail/${tenantId}/count/${param}`;

  const response = await request({
    path,
    method: 'get',
    options,
  });

  const responseData = (await response.data) as unknown[];

  const { error } = response;
  if (response.hasError) {
    let errorMsg = messages.getMailTargetCnt.customer.error;

    if (isResponseError(error)) {
      const formError = getValidationError<MailTargetCnt>({
        formData: { cnt: 0 },
        response: error,
      });
      if (formError?.others) {
        const msg = Object.entries(formError?.others).map(
          ([_, value]) => value,
        );
        errorMsg = msg.length > 0 ? msg[0] : '';
      }
    }

    return errorMsg;
  }

  if (!isMailTargetCnt(responseData)) {
    await logger({
      loglevel:
        response.status === 404 || response.status === 450
          ? 'warning'
          : 'error',
      data: responseData,
      message: messages.getMailTargetCnt.system.typeError,
    });
    let returnMsg = messages.getMailTargetCnt.customer.failed;
    const errorMsgWrap = Object.entries(responseData).map(
      ([_, value]) => value,
    );
    let errorMsg = null;
    if (Array.isArray(errorMsgWrap)) {
      // eslint-disable-next-line @typescript-eslint/no-unsafe-argument
      errorMsg = Object.entries(errorMsgWrap[0]).map(([_, value]) => value);
      errorMsg = errorMsg.join('');
      returnMsg = errorMsg;
    }

    return returnMsg;
  }

  return responseData;
};
