import { UseToastOptions } from '@chakra-ui/react';

const baseToast: UseToastOptions = {
  status: 'success',
  position: 'bottom',
  duration: 2000,
  isClosable: true,
  title: '',
};

export const successToast: UseToastOptions = { ...baseToast };
export const errorToast: UseToastOptions = { ...baseToast, status: 'error' };
export const warningToast: UseToastOptions = {
  ...baseToast,
  status: 'warning',
};
export const infoToast: UseToastOptions = { ...baseToast, status: 'info' };
