import { getAuthHub } from 'admin/api/authHub/getAuthHub';
import { CustomError } from 'admin/error/CustomError';
import { AuthHubResultType } from 'admin/types/authHub';
import { logger } from 'api/logger';
import { useCustomToast } from 'hooks/useCustomToast';
import { useQuery, QueryObserverResult } from 'react-query';
import { errorToast } from 'utils/toast';
import { queryAuthHubKey } from './queryAuthHubKey';

type UseAuthHubType = ({
  userPoolId,
  tenantId,
  authHubId,
}: {
  userPoolId?: string;
  tenantId?: string;
  authHubId: string;
}) => {
  data?: AuthHubResultType;
  refetch: (options?: {
    throwOnError: boolean;
    cancelRefetch: boolean;
  }) => Promise<QueryObserverResult<AuthHubResultType, unknown>>;
};

export const useAuthHub: UseAuthHubType = ({
  userPoolId,
  tenantId,
  authHubId,
}) => {
  const toast = useCustomToast();
  const options = {};

  const { data, refetch } = useQuery({
    queryKey: queryAuthHubKey.getAuthHub({ userPoolId, tenantId, authHubId }),
    queryFn: () => getAuthHub({ userPoolId, tenantId, authHubId, options }),
    refetchOnMount: 'always',
    onError: async (error) => {
      if (error instanceof CustomError) {
        await logger({
          loglevel: error.logLevel,
          data: JSON.stringify(error.cause),
          message: error.message,
        });
      }
      toast({
        ...errorToast,
        duration: 4000,
        title: 'Auth Hub設定情報の取得に失敗しました',
        position: 'bottom',
        variant: 'solid',
      });
    },
  });

  return { data, refetch };
};
