import {
  Box,
  Button,
  Drawer,
  DrawerCloseButton,
  DrawerContent,
  DrawerFooter,
  DrawerHeader,
  DrawerOverlay,
  Text,
} from '@chakra-ui/react';
import { TermDetail } from 'admin/components/service/terms/termDetail';
import { ColorSet } from 'admin/define/colors';
import { ServiceTermType } from 'admin/types/service/terms';
import { FC, memo } from 'react';

type Props = {
  onClose: () => void;
  isOpen: boolean;
  term?: ServiceTermType;
};

export const TermDrawer: FC<Props> = memo(({ onClose, isOpen, term }) => {
  if (!term) {
    return null;
  }

  return (
    <Drawer
      isOpen={isOpen}
      placement="right"
      onClose={onClose}
      size="md"
      closeOnOverlayClick={false}
      closeOnEsc={false}
      autoFocus={false}
    >
      <DrawerOverlay />
      <DrawerContent>
        <DrawerHeader pb={0}>
          <Box m={0} px={0} pb={6} borderBottomWidth="1px">
            <Text>規約設定</Text>
          </Box>
        </DrawerHeader>
        <TermDetail term={term} />
        <DrawerFooter>
          <Button
            variant="outline"
            onClick={onClose}
            p="16px 24px"
            w="120px"
            {...ColorSet.Default}
          >
            閉じる
          </Button>
        </DrawerFooter>
        <DrawerCloseButton top="16px" right="24px" />
      </DrawerContent>
    </Drawer>
  );
});
