import { createTerm } from 'admin/api/userPool/terms/createTerm';
import { CustomError } from 'admin/error/CustomError';
import { useUserPoolId } from 'admin/hooks/params/useUserPoolId';
import { queryKeyNames as serviceQueryKeyNames } from 'admin/hooks/service/terms/queryKeys';
import { TermFormType } from 'admin/types/userPool/terms/form';
import { logger } from 'api/logger';
import { useCustomToast } from 'hooks/useCustomToast';
import { Dispatch, SetStateAction } from 'react';
import { UseFormSetError } from 'react-hook-form';
import { useMutation, useQueryClient } from 'react-query';
import { toMultiError } from 'utils/form';
import { errorToast, successToast } from 'utils/toast';
import { queryKeyNames } from './queryKeys';

export const useCreateTerm = (
  setError: UseFormSetError<TermFormType>,
  setGlobalErrors: Dispatch<SetStateAction<string[]>>,
) => {
  const toast = useCustomToast();
  const queryClient = useQueryClient();
  const userPoolId = useUserPoolId();
  const { mutateAsync, isLoading } = useMutation({
    mutationFn: (data: TermFormType) =>
      createTerm({
        id: userPoolId,
        data,
      }),
    onError: async (error, variables: TermFormType) => {
      if (error instanceof CustomError) {
        if (error.cause.errors?.others) {
          const othersMsgs = Array.isArray(error.cause.errors?.others)
            ? error.cause.errors?.others
            : [error.cause.errors?.others];

          setGlobalErrors(othersMsgs);
        } else {
          Object.keys(variables).forEach((k) => {
            const key = k as keyof TermFormType;
            const errMsgs = error.cause.errors?.[key];
            if (errMsgs && Array.isArray(errMsgs)) {
              setError(key, { types: toMultiError(errMsgs) });
            }
          });
        }

        await logger({
          loglevel: error.logLevel,
          data: JSON.stringify(error.cause),
          message: error.message,
        });
      }
      toast({
        ...errorToast,
        duration: 4000,
        title: '規約設定の作成に失敗しました',
        position: 'bottom',
        variant: 'solid',
      });
    },
    onSuccess: () => {
      toast({
        ...successToast,
        duration: 4000,
        title: '規約設定を作成しました',
        position: 'bottom',
        variant: 'solid',
      });
      void queryClient.invalidateQueries(queryKeyNames.getTerms);
      void queryClient.invalidateQueries(serviceQueryKeyNames.getServiceTerms);
    },
  });

  return { mutate: mutateAsync, isLoading };
};
