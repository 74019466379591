import { useEffect, useState } from 'react';
import { useSearchParams } from 'react-router-dom';
import { useQueryClient } from 'react-query';
import { useUserTenantId } from 'hooks/user/useUserTenantId';
import { queryMailKey } from 'hooks/mail/queryMailKey';

export const useMailUtil = (): {
  ClearMailListCache: () => void;
  MailListRefetchQueries: () => void;
  MailFormRefetchListQueries: () => void;
  MailMagazineListRefetchQueries: () => void;
} => {
  const [searchParams] = useSearchParams();
  const queryClient = useQueryClient();
  const tenantId = useUserTenantId();

  const [queryOrdering, setQueryOrdering] = useState(
    searchParams.get('ordering') || '',
  );
  const [queryPage, setQueryPage] = useState(searchParams.get('page') || '');
  const [queryOpenDrawer, setQueryOpenDrawer] = useState(
    searchParams.get('openDrawer') || '',
  );
  const [queryPerPage, setQueryPerPage] = useState(
    searchParams.get('perPage') || '',
  );
  const [queryStatus, setQueryStatus] = useState(
    searchParams.get('status') || '',
  );
  const [queryDeliveryTo, setQueryDeliveryTo] = useState(
    searchParams.get('deliveryTo') || '',
  );
  const [querySegment, setQuerySegment] = useState(
    searchParams.get('segment') || '',
  );
  const [queryReserve, setQueryReserve] = useState(
    searchParams.get('reserve') || '',
  );

  useEffect(() => {
    setQueryOrdering(searchParams.get('ordering') || '');
    setQueryPage(searchParams.get('page') || '');
    setQueryOpenDrawer(searchParams.get('openDrawer') || '');
    setQueryPerPage(searchParams.get('perPage') || '');
    setQueryStatus(searchParams.get('status') || '');
    setQueryDeliveryTo(searchParams.get('deliveryTo') || '');
    setQuerySegment(searchParams.get('segment') || '');
    setQueryReserve(searchParams.get('reserve') || '');
  }, [searchParams]);

  /**
   * メールリストのキャッシュクリア
   */
  const ClearMailListCache = () => {
    void queryClient.invalidateQueries(
      queryMailKey.getMailList({
        tenantId,
        ordering: queryOrdering,
        page: queryPage,
        openDrawer: queryOpenDrawer,
        perPage: queryPerPage,
        status: queryStatus,
        deliveryTo: queryDeliveryTo,
        segment: querySegment,
        reserve: queryReserve,
      }),
    );
  };

  const MailListRefetchQueries = () => {
    void queryClient.refetchQueries(
      queryMailKey.getMailList({
        tenantId,
        ordering: queryOrdering,
        page: queryPage,
        openDrawer: '',
        perPage: queryPerPage,
        status: queryStatus,
        deliveryTo: queryDeliveryTo,
        segment: querySegment,
        reserve: queryReserve,
      }),
    );
  };

  const MailFormRefetchListQueries = () => {
    void queryClient.refetchQueries(
      queryMailKey.getMailList({
        tenantId,
        ordering: '',
        page: '1',
        openDrawer: '',
        perPage: '',
        status: '',
        deliveryTo: '',
        segment: '',
        reserve: '',
      }),
    );
  };

  const MailMagazineListRefetchQueries = () => {
    void queryClient.refetchQueries(
      queryMailKey.getMailMagazineList({ tenantId, type: 'all' }),
    );
    void queryClient.refetchQueries(
      queryMailKey.getMailMagazineList({ tenantId, type: '' }),
    );
  };

  return {
    ClearMailListCache,
    MailListRefetchQueries,
    MailFormRefetchListQueries,
    MailMagazineListRefetchQueries,
  };
};
