import { Textarea, TextareaProps } from '@chakra-ui/react';
import { FC } from 'react';
import {
  FieldValues,
  Path,
  useController,
  useFormContext,
} from 'react-hook-form';

type RhfTextareaProps<T> = {
  name: Path<T>;
} & Pick<
  TextareaProps,
  | 'width'
  | 'placeholder'
  | 'border'
  | 'bgColor'
  | 'size'
  | 'variant'
  | 'mt'
  | 'mb'
  | 'mr'
  | 'ml'
  | 'rows'
  | 'borderRadius'
  | 'p'
  | 'padding'
  | 'fontSize'
>;

export const RhfTextarea = <T extends FieldValues>({
  name,
  ...rest
}: RhfTextareaProps<T>): ReturnType<FC> => {
  const { control } = useFormContext<FieldValues>();
  const { field } = useController({ name, control });

  return <Textarea bgColor="white" {...field} {...rest} />;
};
