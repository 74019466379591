import { Box, Button, Flex, Text } from '@chakra-ui/react';
import { Colors, ColorSet } from 'components/paywall/config';
import { useCommonDialog } from 'components/paywall/hooks/contexts/CommonDialog';
import { personalSettingWords } from 'components/paywall/pages/MailSetting/constants';
import { MailSettingPersonalFormDefaultValue } from 'components/paywall/pages/MailSetting/initial';
import {
  MailSettingPersonalForm,
  MailSettingStatuses,
} from 'components/paywall/pages/MailSetting/typed';
import {
  convertStatusTitle,
  convertStatusTitleMessage,
} from 'components/paywall/pages/MailSetting/utils';
import { useMailSettingPersonal } from 'hooks/paywall/useMailSettingPersonal';
import { useMailSettingSendTestMail } from 'hooks/paywall/useMailSettingSendTestMail';
import { useUpdateMailSettingPersonal } from 'hooks/paywall/useUpdateMailSettingPersonal';
import { FC, memo, useCallback } from 'react';
import { FormProvider, useForm } from 'react-hook-form';
import { MdMail } from 'react-icons/md';
import { useNavigate } from 'react-router-dom';
import { Form } from './Form';

type Props = {
  type: keyof MailSettingStatuses;
  checked: boolean;
};

export const MailSettingEditForm: FC<Props> = memo(({ type, checked }) => {
  const personalData = useMailSettingPersonal(type);
  const navigate = useNavigate();
  const { openDialog } = useCommonDialog();
  const methods = useForm<MailSettingPersonalForm>({
    mode: 'onBlur',
    defaultValues: MailSettingPersonalFormDefaultValue,
  });
  const {
    getValues,
    reset,
    setError,
    formState: { errors },
  } = methods;
  const { onSubmit: onTestMailSubmit, isLoading: isTestMailLoading } =
    useMailSettingSendTestMail({
      type,
      setError,
    });
  const { onSubmit, isLoading } = useUpdateMailSettingPersonal({
    type,
    setError,
    reset,
  });

  /** 元のページに戻る前に確認ダイアログを開く */
  const handleBack = () => {
    openDialog({
      title: '編集を中断しますか？',
      okBtnColor: 'blue',
      okBtnCaption: '中断',
      cancelBtnCaption: 'キャンセル',
      cancelBtnColor: 'gray',
      size: 'lg',
      message: (
        <Text>
          保存ボタンを押さずに中断すると、
          <Text as="span" fontWeight="bold">
            入力中の内容は保存されません。
          </Text>
          編集を中断してもよろしいですか？
        </Text>
      ),
      onOk: () => {
        navigate(-1);
      },
    });
  };

  const onSendTestMail = useCallback(() => {
    onTestMailSubmit(getValues());
  }, [onTestMailSubmit, getValues]);

  /**
   * 保存ボタン押下時処理
   */
  const onSave = useCallback(() => {
    onSubmit(getValues());
  }, [onSubmit, getValues]);

  return (
    <FormProvider {...methods}>
      <Box mt="32px" fontFamily="Hiragino Sans">
        <Text
          fontSize="18px"
          fontWeight="700"
          lineHeight="170%"
          color={Colors.GRAY_03}
        >
          {convertStatusTitle(type)}
        </Text>
        <Text
          fontSize="14px"
          fontWeight="400"
          lineHeight="170%"
          color={Colors.GRAY_00}
        >
          {convertStatusTitleMessage(type)}
        </Text>
      </Box>
      <Box
        w="100%"
        borderWidth="1px"
        borderRadius="md"
        p="16px"
        mt="8px"
        fontFamily="Hiragino Sans"
      >
        <Form type={type} checked={checked} obj={personalData} />
      </Box>
      <Flex justifyContent="space-between" mt="32px">
        <Button
          onClick={onSendTestMail}
          border="1px solid"
          borderColor={Colors.BLUE_20}
          bgColor={Colors.WHITE}
          color={Colors.BLUE_20}
          isLoading={isLoading || isTestMailLoading}
          disabled={Object.keys(errors).length > 0}
          p="8px 16px"
        >
          <MdMail />
          <Text ml="8px">{personalSettingWords.testSend}</Text>
        </Button>
        <Box>
          <Button
            onClick={handleBack}
            bgColor={Colors.WHITE}
            border="1px solid #CFD6DD"
            p="8px 16px"
            w="100px"
          >
            {personalSettingWords.back}
          </Button>
          <Button
            type="button"
            onClick={onSave}
            isLoading={isLoading || isTestMailLoading}
            disabled={Object.keys(errors).length > 0}
            {...ColorSet.BLUE}
            p="8px 16px"
            ml="16px"
            minW="100px"
          >
            {personalSettingWords.submit}
          </Button>
        </Box>
      </Flex>
    </FormProvider>
  );
});
