import { useQuery } from 'react-query';
import { GroupUser } from 'api/tenant/types';
import { useCustomToast } from 'hooks/useCustomToast';
import { getGroupUserList } from 'api/user/getGroupUserList';
import { queryUserKey } from './queryUserKey';

/**
 * 選択したグループを参照できるユーザーリスト取得
 */
export const useGroupUserList = ({
  groupId,
}: {
  groupId: string;
}): GroupUser[] | undefined => {
  const toast = useCustomToast();
  const response = useQuery(
    queryUserKey.groupUserList({ groupId }),
    () => getGroupUserList(groupId),
    {
      onError: (err: unknown) => {
        if (err instanceof Error) {
          toast.closeAll();
          toast({
            title: err.message,
            status: 'error',
          });
        }
      },
    },
  );
  const { data } = response;

  // 重複しているユーザーが存在する場合はtreeLevelが大きいユーザー情報を取得する
  const groupInfo = data?.filter(
    (x) =>
      data.filter((y) => y.user === x.user).length === 1 ||
      (data.filter((y) => y.user === x.user).length > 1 && x.treeLevel > 0),
  );

  return groupInfo;
};
