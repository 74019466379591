import {
  Box,
  Button,
  Divider,
  Flex,
  Heading,
  Text,
  VStack,
} from '@chakra-ui/react';
import { Colors } from 'admin/define/colors';
import { TokenType } from 'admin/types/userPool/auth';
import { FC, memo } from 'react';

type Props = {
  token: TokenType;
  onDrawerOpen: () => void;
};

export const TokenSettingsComponent: FC<Props> = memo(
  ({ token, onDrawerOpen }: Props) => (
    <Box mt="32px">
      <Flex justifyContent="space-between">
        <Box>
          <Text fontSize="16px" color={Colors.GRAY_03} fontWeight={700}>
            トークン設定
          </Text>
          <Text fontSize="12px" color={Colors.GRAY_00}>
            各トークンの設定値は以下の通りです。
          </Text>
        </Box>
        <Button
          p="4px 24px"
          h="32px"
          fontWeight={700}
          variant="outline"
          colorScheme="primary"
          onClick={onDrawerOpen}
        >
          トークン設定
        </Button>
      </Flex>
      <VStack
        my="16px"
        py="8px"
        px="16px"
        bg={Colors.GRAY_70}
        borderRadius="8px"
        alignItems="left"
      >
        <Heading as="h3" size="sm" fontSize="14px" lineHeight="170%">
          JWT
        </Heading>
        <Divider borderColor={Colors.GRAY_30} />
        <Flex flexDirection="column" gridGap="8px">
          <Box>
            <Heading as="h3" size="sm" fontSize="14px" lineHeight="170%">
              有効期限
            </Heading>
            <Text fontSize="16px" lineHeight="170%">
              {token.jwtDuration.toLocaleString()}秒
            </Text>
          </Box>
          <Box>
            <Heading as="h3" size="sm" fontSize="14px" lineHeight="170%">
              issuer
            </Heading>
            <Text fontSize="16px" lineHeight="170%">
              {token.jwtIssuer || '-'}
            </Text>
          </Box>
        </Flex>
      </VStack>
      <VStack
        my="16px"
        py="8px"
        px="16px"
        bg={Colors.GRAY_70}
        borderRadius="8px"
        alignItems="left"
      >
        <Heading as="h3" size="sm" fontSize="14px" lineHeight="170%">
          Access Token
        </Heading>
        <Divider borderColor={Colors.GRAY_30} />
        <Flex flexDirection="column">
          <Heading as="h3" size="sm" fontSize="14px" lineHeight="170%">
            有効期限
          </Heading>
          <Text fontSize="16px" lineHeight="170%">
            {token.accessTokenDuration.toLocaleString()}秒
          </Text>
        </Flex>
      </VStack>
      <VStack
        my="16px"
        py="8px"
        px="16px"
        bg={Colors.GRAY_70}
        borderRadius="8px"
        alignItems="left"
      >
        <Heading as="h3" size="sm" fontSize="14px" lineHeight="170%">
          Refresh Token
        </Heading>
        <Divider borderColor={Colors.GRAY_30} />
        <Flex flexDirection="column">
          <Heading as="h3" size="sm" fontSize="14px" lineHeight="170%">
            有効期限
          </Heading>
          <Text fontSize="16px" lineHeight="170%">
            {token.refreshTokenDuration.toLocaleString()}秒
          </Text>
        </Flex>
      </VStack>
    </Box>
  ),
);
