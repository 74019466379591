import { Box } from '@chakra-ui/react';
import { Mail, MailEditorForm } from 'api/mail/types';
import { gcsFileUpload } from 'api/storage/gcsFileUpload';
import { isGcsFileUploadResponse } from 'api/storage/types';
import { useCustomToast } from 'hooks/useCustomToast';
import { useEmailEditor } from 'hooks/useEmailEditor';
import { useUserTenantId } from 'hooks/user/useUserTenantId';
import { useUserInfo } from 'hooks/useUserInfo';
import {
  Dispatch,
  memo,
  RefObject,
  SetStateAction,
  useCallback,
  useEffect,
  useState,
  VFC,
} from 'react';
import EmailEditor, {
  Design,
  FileInfo,
  FileUploadDoneCallback,
} from 'react-email-editor';
import { useFormContext } from 'react-hook-form';
import { errorToast, successToast } from 'utils/toast';

type HtmlEditorProps = {
  editor: RefObject<EmailEditor>;
  isEditable: boolean;
  setIsHandleLoad: (bool: boolean) => void;
  setEditable: (uneditableMess: string) => void;
  setIsEdited: Dispatch<SetStateAction<boolean>>;
  mailForm: Mail;
};

export const HtmlEditor: VFC<HtmlEditorProps> = memo(
  ({
    editor,
    isEditable,
    setIsHandleLoad,
    setEditable,
    setIsEdited,
    mailForm,
  }: HtmlEditorProps) => {
    const { setValue, getValues } = useFormContext<MailEditorForm>();
    const toast = useCustomToast();
    const tenantId = useUserTenantId();
    const {
      unlayerId,
      editorOptions,
      defaultDesignValue,
      loadDesign,
      editorReady,
      imageUploadFinish,
      editorUpdated,
    } = useEmailEditor({
      editor,
      options: { user: { id: 0 } },
    });
    const { isStaff, isAssistant } = useUserInfo();
    const [isEditAuth, setIsEditAuth] = useState(true);

    const handleLoad = useCallback(async () => {
      const editorText = getValues('editorHtml');
      // JSONの型安全の為、editorTextが空文字の場合デフォルトのDesign型オブジェクトをセットする
      if (editorText === '') {
        loadDesign(defaultDesignValue);
      } else {
        const editorDesignData = JSON.parse(editorText) as Design;
        Object.assign(defaultDesignValue, { body: editorDesignData });
        loadDesign(defaultDesignValue);
      }
      await editorReady().then(({ isReady }) => {
        setIsEdited(isReady);
        setIsHandleLoad(false);
      });

      if (editor.current) {
        editor.current.registerCallback(
          'image',
          (file: FileInfo, done: FileUploadDoneCallback) => {
            const uploadPath = `public/${file.attachments[0].name}`;
            done({ progress: 50 });
            gcsFileUpload({
              file: file.attachments[0],
              filePath: uploadPath,
              tenantId,
            })
              .then((res) => {
                if (isGcsFileUploadResponse(res)) {
                  done({
                    progress: 100,
                    url: res.publicUrl,
                  });
                }
              })
              .catch((_) => {
                toast({
                  ...errorToast,
                  title: 'ファイルアップロードに失敗しました。',
                });
              });
          },
        );
      }
      await imageUploadFinish().then(({ message }) => {
        toast({
          ...successToast,
          title: message,
        });
      });
      // エディタの更新が発生する際に都度エクスポートする
      await editorUpdated().then(({ design, html }) => {
        setValue('editorHtml', JSON.stringify(design.body));
        setValue('bodyHtml', html);
      });
      const uneditableMess =
        (mailForm.deliveryType === 1 || mailForm.deliveryType === 2) &&
        mailForm.reserve !== '' &&
        isEditAuth
          ? '配信予約済みのため、編集することができません。編集する場合は配信予約を解除してください。'
          : '';
      setEditable(uneditableMess);
    }, [
      getValues,
      editorReady,
      editor,
      imageUploadFinish,
      editorUpdated,
      mailForm,
      isEditAuth,
      setEditable,
      loadDesign,
      defaultDesignValue,
      setIsEdited,
      setIsHandleLoad,
      tenantId,
      toast,
      setValue,
    ]);

    useEffect(() => {
      setIsEditAuth(isStaff || isAssistant);
    }, [isStaff, isAssistant]);

    return (
      <Box w="100%" h="calc(100vh - 180px)">
        {isEditable && (
          <Box
            position="absolute"
            width="calc(100vw - 110px)"
            height="calc(100vh - 210px)"
            bgColor="#EEEEEE99"
          />
        )}
        <EmailEditor
          ref={editor}
          projectId={unlayerId}
          options={editorOptions()}
          style={{
            minHeight: 'calc(100vh - 210px)',
            minWidth: 'initial',
          }}
          onReady={handleLoad}
        />
      </Box>
    );
  },
);
