import { UseMutateFunction, useMutation } from 'react-query';
import { putUserTenantDelete } from 'api/user/putUserTenantDelete';
import { ResponseError } from 'api/types';
import { UserTenantDelete } from 'api/user/types';
import { isGetValidationError } from 'utils/form';
import { useCustomToast } from 'hooks/useCustomToast';

type UnPromisify<T> = T extends Promise<infer U> ? U : T;

const userTenantDeleteData: UserTenantDelete = {
  users: [],
  tenantId: '',
};

/**
 * テナント内のユーザーを削除 hooks
 * @returns {Object} { onSubmit, isLoading }
 */
export const useUserTenantDelete = (): {
  onDelete: UseMutateFunction<
    UnPromisify<ReturnType<typeof putUserTenantDelete>>,
    unknown,
    UserTenantDelete,
    unknown
  >;
  isLoading: boolean;
  isSuccess: boolean;
} => {
  const toast = useCustomToast();

  const {
    mutate: onDelete,
    isLoading,
    isSuccess,
  } = useMutation(
    ({ tenantId, users }: UserTenantDelete) =>
      putUserTenantDelete({ tenantId, users }),
    {
      onSuccess: (result: boolean | ResponseError) => {
        if (typeof result === 'boolean' && result) {
          toast({
            id: 'user-setting-delete-success',
            status: 'success',
            position: 'bottom',
            duration: 2000,
            isClosable: true,
            title: '削除しました',
          });

          return;
        }

        if (
          isGetValidationError<UserTenantDelete>(
            result,
            Object.keys(userTenantDeleteData),
          )
        ) {
          const errorMsg = Object.entries(result).map(([_, value]) => value);
          toast({
            id: 'user-setting-delete-error',
            status: 'error',
            position: 'bottom',
            duration: 4000,
            isClosable: true,
            title: errorMsg[0],
          });
        }
      },
    },
  );

  return { onDelete, isLoading, isSuccess };
};
