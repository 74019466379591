import { ResponseError } from 'api/types';
import { postAuthType } from 'api/user/postAuthType';
import { CertificationLoginFormType, isLoginAuthType, LoginAuthType, LoginFormType } from 'api/user/types';
import { UseFormSetError } from 'react-hook-form';
import { UseMutateFunction, useMutation } from 'react-query';
import { isGetValidationError, toMultiError } from 'utils/form';

type UnPromisify<T> = T extends Promise<infer U> ? U : T;

const LoginFormData: LoginFormType = {
  email: '',
  password: '',
};

/**
 * 認証タイプ判定hooks
 * @param {Object} UseFormSetError setError
 * @returns {Object} { onSubmit, isLoading }
 */
export const useAuthType = ({
  setError,
}: {
  setError: UseFormSetError<LoginFormType>;
}): {
  onSubmit: UseMutateFunction<
    UnPromisify<ReturnType<typeof postAuthType>>,
    unknown,
    CertificationLoginFormType,
    unknown
  >;
  isLoading: boolean;
} => {
  const { mutate: onSubmit, isLoading } = useMutation(
    ({ email, password, resolve }: CertificationLoginFormType) =>
      postAuthType({ email, password, resolve }),
    {
      onSuccess: (result: LoginAuthType | boolean | ResponseError, variable) => {
        if (isLoginAuthType(result)) {
          variable.resolve(result);

          return;
        }

        if (
          isGetValidationError<LoginFormType>(
            result,
            Object.keys(LoginFormData),
          )
        ) {
          if(!result.others) {
            Object.keys(result).forEach((k) => {
              const key = k as keyof LoginFormType;
              const errMsgs = result?.[key];
              if (errMsgs) {
                setError(key, { types: toMultiError(errMsgs) });
              }
            });
          }
        }
      },
    },
  );

  return { onSubmit, isLoading };
};
