import { useApiSubscriptionDetail } from 'api/paywall/useApi';
import { SubscriptionDetailInnerCustomer } from 'components/paywall/pages/SubscriptionDetail/SubscriptionDetailInner/SubscriptionDetailInnerCustomer';
import { SubscriptionDetailInnerHistory } from 'components/paywall/pages/SubscriptionDetail/SubscriptionDetailInner/SubscriptionDetailInnerHistory';
import { SubscriptionDetailInnerInfo } from 'components/paywall/pages/SubscriptionDetail/SubscriptionDetailInner/SubscriptionDetailInnerInfo';
import { SubscriptionDetailInnerMemo } from 'components/paywall/pages/SubscriptionDetail/SubscriptionDetailInner/SubscriptionDetailInnerMemo';
import { SubscriptionDetailInnerMemos } from 'components/paywall/pages/SubscriptionDetail/SubscriptionDetailInner/SubscriptionDetailInnerMemos';
import { defaultSubscriptionDetailOrder } from 'components/paywall/pages/SubscriptionList/initial';
import {
  ApiSubscriptionDetail,
  SubscriptionDetail
} from 'components/paywall/pages/SubscriptionList/typed';
import { FC, memo, useEffect, useState } from 'react';

type Props = {
  orderId: string;
};

export const SubscriptionDetailInner: FC<Props> = memo(({ orderId }) => {
  const [subscriptionDetail, setSubscriptionDetail] = useState<SubscriptionDetail>();
  const { data, isLoading, isFetching } = useApiSubscriptionDetail(orderId);

  useEffect(() => {
    if (isLoading || isFetching) return;
    if (!data) return;
    const apiReturn = data as unknown as ApiSubscriptionDetail;
    if (!apiReturn.data) return;
    setSubscriptionDetail(apiReturn.data as unknown as SubscriptionDetail);
  }, [data, isFetching, isLoading]);

  return (
    <>
      {/* サブスクリプション情報 */}
      <SubscriptionDetailInnerInfo order={subscriptionDetail?.order||defaultSubscriptionDetailOrder}/>
      {/* 顧客情報 */}
      <SubscriptionDetailInnerCustomer detail={subscriptionDetail}/>
      {/* 決済履歴 */}
      <SubscriptionDetailInnerHistory detailOrderGmo={subscriptionDetail?.orderGmo||[]}/>
      {/* メモ */}
      <SubscriptionDetailInnerMemos memos={subscriptionDetail?.memos||[]}/>
      {/* 編集メモ入力欄 */}
      <SubscriptionDetailInnerMemo orderId={subscriptionDetail?.order.id||''} customerUid={subscriptionDetail?.customerUid||''} />
    </>
  );
});
