import {
  Box,
  Button,
  HStack,
  List,
  ListItem,
  Stack,
  Text,
  useDisclosure,
} from '@chakra-ui/react';
import { FormHostingType } from 'api/tenant/types';
import { DrawerForm } from 'components/common/atoms';
import { DoubleConfirmDialog, TextareaForm } from 'components/common/molecules';
import { ColorSet } from 'components/paywall/config';
import { PERMISSION_DIRECTOR } from 'define';
import { useFormHostingEdit } from 'hooks/tenant/useFormHostingEdit';
import { useFormHostingInfo } from 'hooks/tenant/useFormHostingInfo';
import { useUserInfo } from 'hooks/useUserInfo';
import { useUserTenantId } from 'hooks/user/useUserTenantId';
import { FC, memo, useCallback } from 'react';
import { FormProvider, useForm } from 'react-hook-form';

const defaultValues: FormHostingType = {
  header: '',
  body: '',
  hostingUrl: '',
};

export const FormSettingEdit: FC = memo(() => {
  const tenantId = useUserTenantId();
  const { isOpen, onClose, onOpen } = useDisclosure();
  const {
    isOpen: isOpenDialog,
    onClose: onCloseDialog,
    onOpen: onOpenDialog,
  } = useDisclosure();
  const { isEditableTarget, isEnabledFormHosting } = useUserInfo();
  const methods = useForm<FormHostingType>({
    mode: 'onBlur',
    defaultValues,
  });
  const { getValues, setValue, handleSubmit, clearErrors, register } = methods;
  const formHosting = useFormHostingInfo({ tenantId });

  const { onSubmit, isLoading } = useFormHostingEdit({ tenantId });
  const formId = 'formSetting-form';

  // ドロワーを開く時
  const openDrawerHandler = useCallback(() => {
    onOpen();
    setValue('header', formHosting.header ?? '');
    setValue('body', formHosting.body ?? '');
  }, [formHosting.body, formHosting.header, onOpen, setValue]);

  // 編集ドロワーを閉じる処理
  const closeBtnHandler = useCallback(() => {
    onClose();
    clearErrors();
  }, [onClose, clearErrors]);

  // ドロワーフッターの「保存」ボタンを押した時
  const submitBtnHandler = useCallback(() => {
    // 二重確認ダイアログを開く
    onOpenDialog();
  }, [onOpenDialog]);

  // 確認つきダイアログの実行ボタンを押した時の処理
  const submitDialogBtnHandler = useCallback(() => {
    onSubmit({
      header: getValues('header') || '',
      body: getValues('body') || '',
    });

    onCloseDialog();
    onClose();
  }, [onSubmit, getValues, onCloseDialog, onClose]);

  return (
    <Box display="flex" alignItems="center">
      <Button
        disabled={
          !isEditableTarget(PERMISSION_DIRECTOR) || !isEnabledFormHosting()
        }
        onClick={openDrawerHandler}
        m={0}
        p={0}
        size="md"
        sx={{
          bg: 'inherit',
          color: '#3062D4',
          _hover: {
            bg: 'inherit',
            color: 'blue.300',
            cursor: 'pointer',
          },
          _active: {
            bg: 'inherit',
            color: 'blue.300',
          },
          _focus: {
            boxShadow: 'none !important',
            outlineColor: 'inherit',
            outlineWidth: 'initial',
          },
          textDecoration: 'underline',
        }}
      >
        ・フォームページに埋め込むタグの設定（ホスティング型限定）
      </Button>
      <FormProvider {...methods}>
        <DrawerForm
          title={<>フォームページに埋め込むタグの設定（ホスティング型限定）</>}
          subInfo={
            <Box
              m={0}
              p={0}
              sx={{
                '.drawer-list-info li::before': {
                  content: '"・"',
                  marginLeft: '-1em',
                },
              }}
            >
              <List
                fontWeight="normal"
                fontSize="14px"
                pl="1rem"
                className="drawer-list-info"
              >
                <ListItem>
                  本設定は、ホスティング型のフォーム専用です。
                </ListItem>
                <ListItem>
                  モーダル型フォームの場合には適用されませんので、ご注意ください。
                </ListItem>
                <ListItem>
                  タグマネージャーをはじめとする外部サービスのタグを指定し、編集することができます。
                </ListItem>
                <ListItem>
                  タグの種類に応じて、Head用タグはHTMLのHead部分に、Body用タグはBody部分に自動で配置されます。
                </ListItem>
                <ListItem>
                  Head用タグには&lt;head&gt;&lt;/head&gt;で囲まれた中身のみ記載してください。「&lt;head&gt;」自体は不要です。また、Body用タグも同様です。
                </ListItem>
              </List>
            </Box>
          }
          openBtnChildNode={null}
          openBtnProps={{ display: 'none' }}
          cancelBtnTitle="キャンセル"
          cancelBtnHandelr={closeBtnHandler}
          cancelBtnProps={{
            width: '104px',
            padding: '8px 16px',
            disabled: isLoading,
          }}
          submitBtnTitle="保存"
          submitBtnProps={{
            width: '104px',
            padding: '8px 16px',
            isLoading,
            ...ColorSet.BLUE,
          }}
          isOpen={isOpen}
          onOpen={openDrawerHandler}
          onClose={onClose}
          closeOnOverlayClick={false}
          closeOnEsc={false}
          autoFocus={false}
          size="lg"
          formId={formId}
          drawerCloseButtonProps={{ disabled: isLoading }}
          drawerFooterJustify="right"
        >
          <form id={formId} onSubmit={handleSubmit(submitBtnHandler)}>
            <Stack spacing={8} mt={4}>
              <TextareaForm<FormHostingType>
                name="header"
                labelElement={
                  <HStack>
                    <Text as="span" fontSize="16px" fontWeight="700">
                      Head用タグ
                    </Text>
                  </HStack>
                }
                value={getValues('header') || ''}
                rows={10}
                register={register}
              />
              <TextareaForm<FormHostingType>
                name="body"
                labelElement={
                  <HStack>
                    <Text as="span" fontSize="16px" fontWeight="700">
                      Body用タグ
                    </Text>
                  </HStack>
                }
                value={getValues('body') || ''}
                rows={10}
                register={register}
              />
            </Stack>
          </form>
        </DrawerForm>
      </FormProvider>
      <DoubleConfirmDialog
        title="変更についての確認"
        submitBtnTitle="実行"
        submitBtnHandler={submitDialogBtnHandler}
        cancelBtnTitle="キャンセル"
        warningTexts={[
          '埋め込むタグの設定が変更されました。',
          '表示内容を確認し、問題がないかご確認ください。',
        ]}
        isLoading={isLoading}
        isOpen={isOpenDialog}
        onClose={onCloseDialog}
        descriptionPrefix="実行"
      >
        <Text mt={4}>
          <Text as="span" fontWeight="bold">
            タグマネージャー
          </Text>
          &nbsp;の変更を実行しますか？
        </Text>
      </DoubleConfirmDialog>
    </Box>
  );
});
