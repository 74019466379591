import { VStack } from '@chakra-ui/react';
import { DetailMemoAuthor } from 'components/paywall/features/Detail/DetailMemo/DetailMemoAuthor';
import { DetailMemoDate } from 'components/paywall/features/Detail/DetailMemo/DetailMemoDate';
import { DetailMemoText } from 'components/paywall/features/Detail/DetailMemo/DetailMemoText';
import { useFormatDate } from 'hooks/useFormatDate';
import { FC, memo } from 'react';

type Props = {
  date: string;
  text: string;
  author: string;
}

export const DetailMemo: FC<Props> = memo(({date, text, author}) => {
  const { dateTimeFormat } = useFormatDate();

  return (
    <VStack spacing="8px">
      <DetailMemoDate date={dateTimeFormat(date)}/>
      <DetailMemoText text={text}/>
      <DetailMemoAuthor author={author}/>
    </VStack>
  )
});
