import { Drawer, useDisclosure } from '@chakra-ui/react';
import { TenantUsersInviteDrawer } from 'admin/components/tenantUsers/tenantUsersInviteDrawer';
import { TenantUsersTitleComponent } from 'admin/components/tenantUsers/tenantUsersTitle/TenantUsersTitleComponent';
import { FC, memo } from 'react';

export const TenantUsersTitle: FC = memo(() => {
  const { isOpen, onOpen, onClose } = useDisclosure();

  return (
    <>
      <TenantUsersTitleComponent onOpen={onOpen}/>
      <Drawer
        isOpen={isOpen}
        placement="right"
        size="sm"
        onClose={onClose}
        closeOnOverlayClick={false}
        closeOnEsc={false}
        autoFocus={false}
      >
        <TenantUsersInviteDrawer onClose={onClose}/>
      </Drawer>
    </>
  )
});
