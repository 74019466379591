import { ExternalLinkIcon } from '@chakra-ui/icons';
import {
  Box, Icon, Image, Link, ListItem, Text, UnorderedList, useDisclosure
} from '@chakra-ui/react';
import { Dialog } from 'components/common/atoms';
import { memo, VFC } from 'react';
import { FiHelpCircle } from 'react-icons/fi';

type MailMagazineConfirmationHelpType = {
  isDisabled?: boolean;
};

export const MailMagazineHelp: VFC<MailMagazineConfirmationHelpType> = memo(
  ({ isDisabled = false }: MailMagazineConfirmationHelpType) => {
    const { isOpen, onOpen, onClose } = useDisclosure();

    return (
      <Dialog
        title="メルマガ配信の確認方法について"
        isOpen={isOpen}
        onOpen={onOpen}
        onClose={onClose}
        openBtnChildNode={
          <>
            <Icon as={FiHelpCircle} fontSize={16} />
          </>
        }
        openBtnProps={{
          variant: 'ghost',
          color: '#536DB9',
          disabled: isDisabled,
          _hover: { bg: 'transparent' },
          _active: { bg: 'transparent' },
          _disabled: { pointerEvents: 'none' },
        }}
        submitBtnProps={{ colorScheme: 'gray' }}
        submitBtnTitle="閉じる"
        submitBtnHandelr={onClose}
        size="2xl"
      >
        <Box position="absolute" top="20px" left="360px">
          <Link
            href="https://uniikey.notion.site/eb48b4f8f27c4032a38da2a59af4b732"
            fontSize="md"
            fontWeight="bold"
            color="blue"
            isExternal
          >
            マニュアルページへ
            <ExternalLinkIcon mx="2px" />
          </Link>
        </Box>
        <UnorderedList maxH="400px" overflowY="auto" pl="1.5rem" pr="1rem">
          <ListItem mb={4}>
            <Text fontWeight="bold" mb={2}>
              配信希望のみ確認
            </Text>
            <Box>
              アンケートフォームではチェックボックス形式で配信希望を確認する「
              <Text as="span" fontWeight="bold">
                はい
              </Text>
              」が表示され、選択した方のみ配信対象として記録されます。
              既に購読済みの方で
              <Text as="span" fontWeight="bold">
                はい
              </Text>
              を選択しない場合においては配信停止にはなりません。
            </Box>
            <Box border="1px solid #CCC" mt={2}>
              <Image
                boxSize="100%"
                objectFit="contain"
                src="/static/mail_magazine_1_chk.png"
                alt="static/mail_magazine_1_chk.png"
              />
            </Box>
          </ListItem>
          <ListItem mb={4}>
            <Text fontWeight="bold" mb={2}>
              配信希望・配信停止を選択
            </Text>
            <Box>
              アンケートフォームではプルダウン形式で配信希望を確認する「
              <Text as="span" fontWeight="bold">
                はい／いいえ
              </Text>
              」が表示されます。
              <Text as="span" fontWeight="bold">
                いいえ
              </Text>
              を選択した場合においては配信停止となります。
              <Text>配信希望を募りつつ、停止も行うことができます。</Text>
            </Box>
            <Box border="1px solid #CCC" mt={2}>
              <Image
                boxSize="100%"
                objectFit="contain"
                src="/static/mail_magazine_2_select.png"
                alt="static/mail_magazine_2_select.png"
              />
            </Box>
          </ListItem>
        </UnorderedList>
      </Dialog>
    );
  },
);
