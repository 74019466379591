import { zodResolver } from '@hookform/resolvers/zod';
import { withSuspenseAndErrorBoundary } from 'admin/components/Error/WithErrorBoundary';
import { AutoAppendFormComponent } from 'admin/components/idPoolConsumer/attribute/autoAppendForm/AutoAppendFormComponent';
import { LoadingLayer } from 'admin/components/ui/loadingLayer';
import { useIdPoolConsumerId } from 'admin/hooks/useIdPoolConsumerId';
import { useChangeAttributeAutoAppend } from 'admin/hooks/userPool/attribute/useChangeAttributeAutoAppend';
import { confirmSubmitFormSchema } from 'admin/schema/util';
import { IdPoolConsumerAttributeType } from 'admin/types/userPool/attribute';
import { FC, memo, useCallback } from 'react';
import { FormProvider, useForm } from 'react-hook-form';

export type Props = {
  target: IdPoolConsumerAttributeType | undefined;
  onClose: () => void;
};

export const autoAppendForm: FC<Props> = memo(({ onClose, target }) => {
  const formId = 'autoAppendForm';
  const idPoolConsumerId = useIdPoolConsumerId();

  const { mutate, isLoading } = useChangeAttributeAutoAppend({
    id: target?.id ?? '',
    userPoolId: idPoolConsumerId,
  });

  const methods = useForm({
    defaultValues: {
      word: '',
    },
    mode: 'onBlur',
    resolver: zodResolver(confirmSubmitFormSchema),
  });

  const onSubmit = useCallback(async () => {
    await mutate();
    onClose();
  }, [mutate, onClose]);

  return (
    <FormProvider {...methods}>
      {isLoading && <LoadingLayer />}
      <AutoAppendFormComponent
        formId={formId}
        isLoading={isLoading}
        onClose={onClose}
        onSubmit={onSubmit}
      />
    </FormProvider>
  );
});

export const AutoAppendForm = withSuspenseAndErrorBoundary(autoAppendForm);
