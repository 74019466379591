import { CustomError } from 'api/error/CustomError';
import { LOCAL_STORAGE_NAME } from 'define';
import { useCallback } from 'react';
import createPersistedSstate from 'use-persisted-state';

const useLocalStorageState = createPersistedSstate(LOCAL_STORAGE_NAME);

export type LocalStorageType = Record<string, string>;
const isLocalStorageType = (data: unknown): data is LocalStorageType => {
  const d = data as LocalStorageType | null;
  // localStorageDataがnullのケースが存在するための考慮
  if (d === null) return true;

  return Object.keys(d).every((k) => {
    const val = d[k];

    return (
      (typeof k === 'string' && typeof val === 'string') ||
      (Array.isArray(val) && val.every((v) => typeof v === 'string')) ||
      Object.keys(val).every((_, idx) => typeof val[idx] === 'string')
    );
  });
};

/**
 * ローカルストレージ
 */
export const useLocalstorage = (): {
  getLocalStorage: ({ key: k }: { key: string }) => string;
  getAllLocalStorage: () => LocalStorageType;
  setLocalStorage: (values: LocalStorageType) => void;
  deleteLocalStorage: () => void;
  isLocalStorage: () => boolean;
} => {
  const [localStorageValue, setLocalStorageValue] = useLocalStorageState({});

  /**
   * ローカルストレージ取得
   * @param key 取得したいデータのkey
   * @returns ローカルストレージデータ
   */
  const getLocalStorage = useCallback(
    ({ key: k }: { key: string }) => {
      let storageVal = '';
      if (isLocalStorageType(localStorageValue)) {
        // localStorageDataがnullのケースが存在するための考慮
        if (
          localStorageValue !== null &&
          Object.keys(localStorageValue).length > 0
        )
          storageVal = localStorageValue[k];
      }

      return storageVal;
    },
    [localStorageValue],
  );

  /**
   * ローカルストレージから全データを取得
   * @returns ローカルストレージに保存されている全データ
   */
  const getAllLocalStorage = useCallback(() => {
    if (isLocalStorageType(localStorageValue)) {
      // localStorageDataがnullのケースが存在するための考慮
      return localStorageValue || {};
    }

    // localStorageValueがLocalStorageType型でない場合、空のオブジェクトを返す
    return {};
  }, [localStorageValue]);

  /**
   * ローカルストレージ保存
   * @param key 保存したいデータのkey
   * @param val 保存したいデータ
   */
  const setLocalStorage = (values: LocalStorageType): void => {
    if (isLocalStorageType(localStorageValue)) {
      // 現在のlocalStorageValueと新しい値をマージ
      const updatedValue: LocalStorageType = {
        ...localStorageValue,
        ...values,
      };
      setLocalStorageValue(updatedValue);
    } else {
      throw new CustomError(
        'localStorageValue is not a valid LocalStorageType',
      );
    }
  };

  const deleteLocalStorage = () => {
    setLocalStorageValue({});
    localStorage.removeItem(LOCAL_STORAGE_NAME);
  };

  /**
   * ローカルストレージにcdmCloudが存在するかどうか
   * @returns boolean
   */
  const isLocalStorage = (): boolean =>
    localStorage.getItem(LOCAL_STORAGE_NAME) !== null;

  return {
    getLocalStorage,
    getAllLocalStorage,
    setLocalStorage,
    deleteLocalStorage,
    isLocalStorage,
  };
};
