import { Box } from '@chakra-ui/react';
import { CircleIcon } from 'admin/components/ui/icon/circleIcon';
import { RemoveIcon } from 'admin/components/ui/icon/removeIcon';
import { Colors } from 'admin/define/colors';
import { MEMBER_STATUS_TYPE_INVALID, MEMBER_STATUS_TYPE_VALID } from 'define';
import { FC, memo, useMemo } from 'react';

type StatusBadgeType = {
  color: string;
  bgColor: string;
  icon: JSX.Element;
};

type Props = {
  status: number;
  label?: string;
};

export const StatusBadge: FC<Props> = memo(({ status, label }) => {
  const statusDetail = useMemo((): StatusBadgeType => {
    if (status === MEMBER_STATUS_TYPE_VALID) {
      return {
        color: Colors.BLUE_00,
        bgColor: Colors.BLUE_30,
        icon: <CircleIcon boxSize={3} color={Colors.BLUE_00} mr={2} />,
      };
    }
    if (status === MEMBER_STATUS_TYPE_INVALID) {
      return {
        color: Colors.GRAY_03,
        bgColor: Colors.GRAY_40,
        icon: <CircleIcon boxSize={3} color={Colors.GRAY_03} mr={2} solid />,
      };
    }

    return {
      color: Colors.GRAY_03,
      bgColor: Colors.GRAY_40,
      icon: <RemoveIcon mr={2} />,
    };
  }, [status]);

  return (
    <Box minWidth="60px" display="inline-block">
      <Box
        height="24px"
        p="6px 8px"
        fontSize="12px"
        fontWeight={500}
        borderRadius={4}
        color={statusDetail.color}
        bg={statusDetail.bgColor}
        display="flex"
        alignItems="center"
        justifyContent="center"
      >
        {statusDetail.icon}
        {label}
      </Box>
    </Box>
  );
});
