import { getServices } from 'admin/api/service/getServices';
import { CustomError } from 'admin/error/CustomError';
import { useUserCorporationId } from 'admin/hooks/user/useUserCorporationId';
import { logger } from 'api/logger';
import { useCustomToast } from 'hooks/useCustomToast';
import { useQuery } from 'react-query';
import { errorToast } from 'utils/toast';
import { queryServiceKey } from './queryServiceKey';

export const useServices = () => {
  const corporationId = useUserCorporationId();
  const toast = useCustomToast();
  const { data } = useQuery({
    queryKey: queryServiceKey.getServices(corporationId),
    queryFn: () => getServices(corporationId),
    onError: async (error) => {
      if (error instanceof CustomError) {
        await logger({
          loglevel: error.logLevel,
          data: JSON.stringify(error.cause),
          message: error.message,
        });
      }
      toast({
        ...errorToast,
        duration: 4000,
        title: 'テナント管理一覧の取得に失敗しました',
        position: 'bottom',
        variant: 'solid',
      });
    },
  });

  return { data: data || [] };
};
