import { getUser } from 'api/user/getUser';
import { Corporation, User, UserTenant } from 'api/user/types';
import { atom, selector } from 'recoil';

export const userState = atom<User | null>({
  key: 'userState',
  default: selector({
    key: 'userStateDefault',
    get: async ({ get: _ }) => {
      try {
        const user = await getUser();

        return user;
      } catch (e: unknown) {
        return null;
      }
    },
  }),
});

export const userTenantIdState = atom<string | null>({
  key: 'userTenantIdState',
  default: selector({
    key: 'userTenantIdStateDefault',
    get: ({ get }) => {
      const user = get(userState);

      if (user === null) {
        return null;
      }
      // ローカルストレージからテナントIDを取得する
      const storage = localStorage.getItem('cdmCloud') || '{}';
      const cdmCloud = JSON.parse(storage) as { tenantId: string };
      let { tenantId } = cdmCloud;
      let tenant: UserTenant | null = null;

      if (tenantId === '') {
        if (Array.isArray(user?.tenant)) {
          if (user?.tenant.length > 0) {
            // 初期取得時はテナントの最初の情報のIDを取得する
            tenant = user?.tenant[0];
          }
        } else if (user?.tenant) {
          tenant = user?.tenant;
        }

        if (tenant === null) {
          return null;
        }

        tenantId = tenant.id;
      }

      return tenantId;
    },
  }),
});

export const userTenantState = atom({
  key: 'userTenantState',
  default: selector({
    key: 'userTenantStateDefault',
    get: ({ get }) => {
      const user = get(userState);
      const tenantId = get(userTenantIdState);

      if (user === null) {
        return null;
      }

      let tenant: UserTenant | null = null;

      if (Array.isArray(user?.tenant)) {
        if (user?.tenant.length > 0) {
          if (tenantId === null) {
            tenant = user?.tenant[0];
          } else {
            const target = user?.tenant.filter((x) => x.id === tenantId)[0];
            tenant = target;
          }
        }
      } else if (user?.tenant) {
        tenant = user?.tenant;
      }

      return tenant;
    },
  }),
});

// 法人ID recoil情報
export const corporationIdState = atom<string|null>({
  key: 'corporationIdState',
  default: selector({
    key: 'corporationIdStateDefault',
    get: () => {
      const storage = localStorage.getItem('cdmCloud') || '{}';
      const cdmCloud = JSON.parse(storage) as { corporationId: string };
      const { corporationId } = cdmCloud;

      return corporationId;
    }
  })
})

// 法人アカウント情報(現在の法人) recoil
export const userCorporationState = atom({
  key: 'userCorporationState',
  default: selector({
    key: 'userCorporationStateDefault',
    get: ({ get }) => {
      const user = get(userState);
      const corporationId = get(corporationIdState);

      if (user === null) {
        return null;
      }

      let corporation: Corporation | null = null;

      if (user?.corporations.length > 0) {
        if (corporationId === null) {
          corporation = user?.corporations[0];
        } else {
          const target = user?.corporations.filter((x) => x.id === corporationId)[0];
          corporation = target;
        }
      }

      return corporation;
    },
  }),
});

// 法人アカウント情報（全て） recoil
export const userCorporationsState = atom({
  key: 'userCorporationsState',
  default: selector({
    key: 'userCorporationsStateDefault',
    get: ({ get }) => {
      const user = get(userState);

      return user?.corporations || [];
    },
  }),
});
