import { createFormGroupDetail } from 'admin/api/userPool/formGroup/createFormGroupDetail';
import { CustomError } from 'admin/error/CustomError';
import { useIdPoolConsumerId } from 'admin/hooks/useIdPoolConsumerId';
import { FormGroupDetailType } from 'admin/types/userPool/formGroup';
import { logger } from 'api/logger';
import { isResponseError } from 'api/types';
import { useCustomToast } from 'hooks/useCustomToast';
import { FieldPath, UseFormSetError } from 'react-hook-form';
import {
  useMutation,
  useQueryClient
} from 'react-query';
import { useNavigate } from 'react-router-dom';
import {
  getValidationError,
  isGetNestedValidationError,
  toMultiError
} from 'utils/form';
import { errorToast, successToast } from 'utils/toast';
import { queryFormGroupKey } from './queryFormGroupKey';

export const useCreateFormGroupDetail = ({
  setError,
}: {
  setError: UseFormSetError<FormGroupDetailType>;
}) => {
  const toast = useCustomToast();
  const queryClient = useQueryClient();
  const userPoolId = useIdPoolConsumerId();
  const navigate = useNavigate();
  const {
    mutateAsync,
    isLoading
  } = useMutation({
    mutationFn: (data: FormGroupDetailType) => createFormGroupDetail({data, userPoolId}),
    onSuccess: () => {
      void queryClient.invalidateQueries(
        queryFormGroupKey.getFormAllGroups(userPoolId),
      );
      navigate(`/admin/idPoolConsumer/${userPoolId}/formGroup/`);
      toast({
        ...successToast,
        duration: 4000,
        title: 'フォーム設問グループを作成しました',
        position: 'bottom',
        variant: 'solid',
      });
    },
    onError: async (error, valiables: FormGroupDetailType) => {
      if (error instanceof CustomError) {
        await logger({
          loglevel: error.logLevel,
          data: JSON.stringify(error.cause),
          message: error.message,
        });
        const { errors } = error.cause;
        if (
          isGetNestedValidationError<FormGroupDetailType>(
            errors,
            Object.keys(valiables),
          )
        ) {
          if (errors?.others) {
            const othersMsgs = Array.isArray(errors?.others)
              ? errors?.others
              : [errors?.others];

            toast({
              ...errorToast,
              duration: 4000,
              title: 'フォーム設問グループの作成に失敗しました',
              description: othersMsgs,
              position: 'bottom',
              variant: 'solid',
            });
          } else {
            Object.keys(errors).forEach((k) => {
              const key = k as keyof FormGroupDetailType;
              const errMsgs = errors?.[key];
              if (
                errMsgs &&
                Array.isArray(errMsgs) &&
                errMsgs.every((v) => typeof v === 'string')
              ) {
                setError(key, {
                  types: toMultiError(errMsgs),
                });
              } else {
                errMsgs?.forEach((err, idx) => {
                  if (isResponseError(err)) {
                    const nestErrors = getValidationError<FormGroupDetailType>({
                      formData: valiables,
                      response: err,
                      replaceKeys: {
                        nonFieldErrors: 'typeOrUserAttrId',
                      },
                    });
                    Object.keys(nestErrors).forEach((typeKey) => {
                      const formGroupTypeKey = typeKey as keyof FormGroupDetailType;
                      const errNestMsgs = nestErrors?.[formGroupTypeKey];
                      const errorKey: FieldPath<FormGroupDetailType> = `groupUnits.${idx}.typeOrUserAttrId`;
                      setError(errorKey, {
                        types: toMultiError(errNestMsgs || []),
                      });
                    });
                  }
                });
                toast({
                  ...errorToast,
                  position: 'bottom',
                  duration: 4000,
                  isClosable: true,
                  title: '入力エラーがあります。',
                  description: 'エラーを確認してください。',
                });
              }
            });
          }
        }
      } else {
        toast({
          ...errorToast,
          duration: 4000,
          title: 'フォーム設問グループの作成に失敗しました',
          position: 'bottom',
          variant: 'solid',
        });
      }
    },
  });

  return {
    mutate: mutateAsync,
    isLoading
  };
};
