import { request } from 'admin/api/Request';
import { API_MESSAGES } from 'admin/define/apiMessages';
import { CustomError } from 'admin/error/CustomError';
import { serviceAttributeGroupFormSchema } from 'admin/schema/service/attribute';
import { ServiceAttributeChildType } from 'admin/types/service/attribute';
import { ServiceAttributeGroupFormType } from 'admin/types/service/attribute/form';
import { Options } from 'ky';
import { ZodError } from 'zod';

export const changeServiceFormAttrGroup = async ({
  userPoolChildId,
  userPoolChildAttrId,
  options,
  data,
}: {
  userPoolChildId: string;
  userPoolChildAttrId: ServiceAttributeChildType['id'];
  options?: Options;
  data: ServiceAttributeGroupFormType;
}): Promise<ServiceAttributeGroupFormType> => {
  const params = data;
  const path = `uniikey/${userPoolChildId}/child/attribute/${userPoolChildAttrId}/`;

  const response = await request<ServiceAttributeGroupFormType>({
    path,
    method: 'patch',
    body: params,
    options,
  });

  if (response.hasError) {
    if (response.status === 404) {
      throw new CustomError(
        API_MESSAGES.SERVICE.CHANGE_SEVICE_ATTRIBUTE_AUTO_APPEND.FAILED,
        {
          path,
          params,
          status: response.status,
        },
      );
    } else {
      throw new CustomError(
        API_MESSAGES.SERVICE.CHANGE_SEVICE_ATTRIBUTE_AUTO_APPEND.ERROR,
        {
          path,
          params,
          status: response.status,
          logLevel: response.status === 400 ? 'warning' : 'error',
        },
      );
    }
  }
  try {
    serviceAttributeGroupFormSchema.parse(response.data);
  } catch (error) {
    if (error instanceof ZodError) {
      throw new CustomError(error.message, {
        path,
        params,
        status: response.status,
        logLevel: 'error',
      });
    }
  }

  return response.data;
};
