import {
  Button,
  HStack,
  ModalBody,
  ModalCloseButton,
  ModalContent,
  ModalFooter,
  ModalHeader,
  ModalOverlay,
  Text,
} from '@chakra-ui/react';
import { LoadingLayer } from 'admin/components/ui/loadingLayer';
import { Colors, ColorSet } from 'admin/define/colors';
import { FC, memo } from 'react';

type Props = {
  isLoading: boolean;
  status: boolean;
  onClose: () => void;
  onSubmit: () => void;
};

export const DeliveryAvailableModal: FC<Props> = memo(
  ({ isLoading, status, onClose, onSubmit }) => (
    <>
      {isLoading && <LoadingLayer />}
      <ModalOverlay />
      <ModalContent fontSize="md" zIndex={100} color={Colors.GRAY_03}>
        <ModalHeader fontSize="lg" fontWeight="bold" pt="24px">
          配信利用の{!status ? '有効' : '無効'}化
        </ModalHeader>
        <ModalBody>
          {!status ? (
            <Text>
              配信利用の設定を
              <Text as="span" fontWeight="bold">
                有効
              </Text>
              にします。 <br />
              有効にすると、テナント管理画面のメール配信設定で利用できるようになります。
            </Text>
          ) : (
            <Text>
              配信利用の設定を
              <Text as="span" fontWeight="bold">
                無効
              </Text>
              にします。 <br />
              無効にすると、テナント管理画面のメール配信設定で利用できなくなります。
              <br />
              すでに設定済みのものには影響はありません。
            </Text>
          )}
        </ModalBody>
        <ModalFooter pb="24px">
          <HStack spacing={4}>
            <Button
              variant="solid"
              {...ColorSet.Default}
              onClick={onClose}
              w="118px"
              h="40px"
            >
              キャンセル
            </Button>
            <Button
              colorScheme="primary"
              mr={3}
              onClick={() => onSubmit()}
              w="118px"
              h="40px"
            >
              実行
            </Button>
          </HStack>
        </ModalFooter>
        <ModalCloseButton top="22px" right="24px" />
      </ModalContent>
    </>
  ),
);
