import { Options } from 'ky';
import { request } from 'api/request';
import { logger } from 'api/logger';
import produce from 'immer';
import { ResponseError, isResponseError } from 'api/types';
import { UserTenantDelete, UserTenantWarningDelete } from 'api/user/types';
import { getValidationError, ValidationError } from 'utils/form';

import { messages } from './messages';

const DEFAULT_ERR_MESSAGE: ValidationError<UserTenantDelete> = {
  others: [messages.putUserTenantDelete.customer.error],
};
const DEFAULT_SYSTEM_ERR_MESSAGE: ValidationError<UserTenantDelete> = {
  others: [messages.putUserTenantDelete.customer.failed],
};

/**
 * テナント内のユーザーを削除する際のアラート表示
 *
 * 状態により以下のレスポンスを返却します
 * 正常時: true
 * エラー: ResponseError
 *
 */
export const postUserTenantDelete = async (
  formParam: UserTenantDelete,
  options?: Options,
): Promise<ResponseError | UserTenantWarningDelete> => {
  const { tenantId, users } = formParam;
  const path = `user/tenant/${tenantId}/delete/`;
  const postParam = { users };

  const mergedOptions = options
    ? produce(options, (draft) => {
        draft.json = { ...postParam };
      })
    : { json: postParam };

  const response = await request({
    path,
    method: 'post',
    options: mergedOptions,
  });

  const responseData = response.data as UserTenantWarningDelete;

  if (response.status >= 500) {
    return DEFAULT_ERR_MESSAGE;
  }

  const { error } = response;
  if (response.hasError) {
    // APIにて返却されたエラー形式
    if (isResponseError(error)) {
      const formError = getValidationError<UserTenantDelete>({
        formData: formParam,
        response: error,
      });

      return formError;
    }
    // 予期せぬエラーなので
    // バックエンドに状態を通知
    await logger({
      loglevel:
        response.status === 404 || response.status === 450
          ? 'warning'
          : 'error',
      data: error,
      message: messages.putUserTenantDelete.system.typeError,
    });

    // 登録できていない旨を通知
    return DEFAULT_SYSTEM_ERR_MESSAGE;
  }

  return responseData;
};
