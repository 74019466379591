import { changeAttributeGroupStatus } from 'admin/api/userPool/attributeGroup/changeAttributeGroupStatus';
import { CustomError } from 'admin/error/CustomError';
import { useResetAllAttributeGroups } from 'admin/hooks/userPool/attributeGroup/useResetAllAttributeGroups';
import { UserPool } from 'admin/types/userPool';
import { AttributeGroupType } from 'admin/types/userPool/attributeGroup/index';
import { logger } from 'api/logger';
import { useCustomToast } from 'hooks/useCustomToast';
import { useMutation, useQueryClient } from 'react-query';
import { errorToast, successToast } from 'utils/toast';
import { queryAttributeGroupKey } from './queryAttributeGroupKey';

export const useAttributeGroupStatusChange = ({
  id,
  attributeGroupId,
}: {
  id: UserPool['id'];
  attributeGroupId: AttributeGroupType['id'];
}) => {
  const toast = useCustomToast();
  const queryClient = useQueryClient();
  const resetAttributeGroups = useResetAllAttributeGroups(id);

  const { mutateAsync, isLoading } = useMutation({
    mutationFn: () => changeAttributeGroupStatus({ attributeGroupId, id }),
    onError: async (error) => {
      if (error instanceof CustomError) {
        if (error.cause.errors) {
          await logger({
            loglevel: error.logLevel,
            data: JSON.stringify(error.cause),
            message: error.message,
          });
        }
      }
      toast({
        ...errorToast,
        duration: 4000,
        title: '属性グループのステータスの変更に失敗しました',
        position: 'bottom',
        variant: 'solid',
      });
    },
    onSuccess: () => {
      void queryClient.invalidateQueries(
        queryAttributeGroupKey.getAttributeGroupDetail(id, attributeGroupId),
      );
      resetAttributeGroups();
      toast({
        ...successToast,
        duration: 4000,
        title: '属性グループのステータスを「確定」に変更しました',
        position: 'bottom',
        variant: 'solid',
      });
    },
  });

  return { mutate: mutateAsync, isLoading };
};
