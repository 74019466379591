import { CustomError } from 'admin/error/CustomError';
import camelcaseKeys from 'camelcase-keys';
import {
  AUTHORIZED_EXCLUDED_URLS,
  HTTP_STATUS_FORBIDDEN,
  HTTP_STATUS_FORCE_LOGOUT,
  HTTP_STATUS_TENANT_NOT_AVAILABLE,
  HTTP_STATUS_UNAUTHORIZED,
} from 'define';
import { showErrorModal } from 'helper/showErrorModalHelper';
import ky, { HTTPError, Options } from 'ky';
import { getCookieValue } from 'utils/cookie';

const API_HOST = process.env.REACT_APP_API_HOST;
const ERROR_FORBIDDEN_MESSAGE =
  '権限が不足していますので恐れ入りますがリロードしてください';
const ERROR_NOT_AVAILABLE_MESSAGE =
  'その機能は利用できません。恐れ入りますがリロードしてください';

/**
 * 認証エラー時のレスポンス
 */
type ResponseAuthError = {
  detail: string;
};
const isResponseAuthError = (data: unknown): data is ResponseAuthError => {
  const m = data as ResponseAuthError;

  return typeof m?.detail === 'string';
};

export const DEFAULT_API_OPTIONS: Options = {
  prefixUrl: API_HOST,
  timeout: 1000 * 60,
  retry: 1,
  mode: 'cors',
  credentials: 'include',
  hooks: {
    beforeRequest: [
      (request: Request): void => {
        if (!request.headers.get('Content-Type')) {
          request.headers.set('Content-Type', 'application/json');
        }

        if (request.method.toLowerCase() === 'get') {
          return;
        }

        // CSRF対策用トークン
        const token = getCookieValue('csrftoken');
        if (token === undefined) {
          return;
        }

        request.headers.set('X-CSRFToken', token);
      },
    ],
  },
};

export type RequestType = {
  path: string;
  method?: string;
  options?: Options;
};

export type ReturnType = {
  errorMessage: string;
  error: unknown;
  hasError: boolean;
  responseType: ResponseType;
  status: number;
  url: string;
  data: unknown;
};

export const request = async ({
  path,
  method = 'get',
  options,
}: RequestType): Promise<ReturnType> => {
  const mergedOptions = {
    ...DEFAULT_API_OPTIONS,
    ...options,
    method,
  };

  let url = '';
  if (mergedOptions.prefixUrl) {
    if (mergedOptions.prefixUrl instanceof URL) {
      const tmpUrl = new URL(mergedOptions.prefixUrl.pathname.toString());
      tmpUrl.pathname = path;
      url = tmpUrl.toString();
    } else {
      const tmpUrl = new URL(mergedOptions.prefixUrl);
      tmpUrl.pathname = path;
      url = tmpUrl.toString();
    }
  } else {
    url = path;
  }

  try {
    const response = await ky(path, mergedOptions);
    let data: unknown = {};

    // レスポンスデータがJSONでない場合(空の場合)は
    // 空オブジェクトをセット
    try {
      data = camelcaseKeys(await response.json(), { deep: true });
    } catch (e) {
      data = {};
    }

    return {
      errorMessage: '',
      error: null,
      responseType: response.type,
      hasError: false,
      status: response.status,
      url,
      data,
    };
  } catch (error) {
    // サーバー側で何らかの処理が行われレスポンスが存在する場合
    if (error instanceof HTTPError) {
      let errorData: ReturnType['error'] = null;

      // レスポンス内容はJSONの場合と
      // テキストの場合が存在する
      try {
        errorData = camelcaseKeys(await error.response.json(), { deep: true });
      } catch (e) {
        errorData = error.response.statusText;
      }

      let isAuthError = false;
      if (isResponseAuthError(errorData)) {
        if (errorData.detail.indexOf('CSRF Failed') !== -1) {
          isAuthError = true;
        } else if (
          errorData.detail.indexOf('認証情報が含まれていません') !== -1
        ) {
          isAuthError = true;
        }
      }

      // 権限エラーかどうか
      const isForbidden = error.response.status === HTTP_STATUS_FORBIDDEN;
      // 認証エラーかどうか
      const isUnauthorized = error.response.status === HTTP_STATUS_UNAUTHORIZED;
      // 機能利用権限があるかどうか
      const isNotAvailable =
        error.response.status === HTTP_STATUS_TENANT_NOT_AVAILABLE;

      // 現在（リクエストを行う前）のURL取得
      const currentURI = document.documentURI;
      // 認証判定エラー除外URL生成
      const safePattern = AUTHORIZED_EXCLUDED_URLS.map((pattern) =>
        pattern.replace(/\//g, '\\/'),
      ).join('|');
      const excludedURL = new RegExp(safePattern);
      /**
       * 現在のURLが除外URL以外で以下認証エラー処理を行う
       * ※除外URLは認証前URLの為認証エラーが起こりえない為除外を行う
       */
      if (!excludedURL.test(currentURI)) {
        if (isForbidden || isNotAvailable) {
          const errorMessage = isForbidden
            ? ERROR_FORBIDDEN_MESSAGE
            : ERROR_NOT_AVAILABLE_MESSAGE;
          showErrorModal('権限エラー', errorMessage);
          throw new CustomError(errorMessage, {
            path: currentURI,
            status: error.response.status,
          });
        }

        // 認証エラー時は強制ログアウト
        if (isUnauthorized || isAuthError) {
          window.location.href = `/logout?status=${HTTP_STATUS_FORCE_LOGOUT}`;
        }
      }

      return {
        errorMessage: error.message,
        error: errorData,
        responseType: error.response.type,
        hasError: true,
        status: error.response.status,
        url,
        data: {},
      };
    }

    // サーバーダウンなどサーバーにリクエストが届かず
    // レスポンスが存在しないエラーケース
    return {
      errorMessage: String(error),
      error: [
        '申し訳ございません。通信トラブルが発生しました。恐れ入りますがしばらく経ってから再操作お願い致します。',
      ],
      responseType: 'error',
      hasError: true,
      status: -1,
      url,
      data: {},
    };
  }
};
