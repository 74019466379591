import { Box, Flex, Text } from '@chakra-ui/react';
import { Link } from 'components/paywall/atoms/Link';
import { Colors } from 'components/paywall/config';
import { SubscriptionDetailInner } from 'components/paywall/pages/SubscriptionDetail/SubscriptionDetailInner';
import { Container } from 'components/paywall/templates/Container';
import { FC, memo, useState } from 'react';
import { useParams } from 'react-router-dom';

export const SubscriptionDetail: FC = memo(() => {
  const params = useParams();
  const [orderId] = useState<string | undefined>(params.id);

  return (
    <>
      <Container
        title={
          <Flex>
            <Link to="/paywall/subscriptionList">
              <Flex>
                <Text
                  fontSize="14px"
                  color={Colors.GRAY_20}
                  fontWeight="700"
                  cursor="pointer"
                >
                  サブスクリプション注文一覧
                </Text>
              </Flex>
            </Link>
            <Text as="span" color={Colors.BLACK} fontWeight="700">
              &nbsp;/&nbsp;
            </Text>
            <Text fontSize="14px" color={Colors.BLACK} fontWeight="700">
              サブスクリプション注文詳細
            </Text>
          </Flex>
        }
        px="0px"
        contentsPl="24px"
      >
        {orderId && (
          <Box overflow="auto">
            <Box
              minW="923px"
              className="PaywallSubscriptionDetail"
              mt="32px"
              mb="46px"
            >
              <SubscriptionDetailInner orderId={orderId} />
            </Box>
          </Box>
        )}
      </Container>
    </>
  );
});
