import {
  Box,
  Button,
  DrawerBody,
  DrawerFooter,
  Flex,
  FormControl,
  FormErrorMessage,
  Heading,
  VStack,
} from '@chakra-ui/react';
import { RhfSelect } from 'admin/components/form/RhfSelect';
import { RequiredIcon } from 'admin/components/ui/icon/requiredIcon';
import { LoadingLayer } from 'admin/components/ui/loadingLayer';
import { Colors, ColorSet } from 'admin/define/colors';
import { TenantMailList } from 'api/tenant/types';
import { FirstLoginMailSettingType } from 'api/transactionMail/type';
import { useTransactionMailPermission } from 'hooks/transactionMail/useTransactionMailPermission';
import { FC } from 'react';
import { useFormContext, useFormState } from 'react-hook-form';

type Props = {
  formId: string;
  isLoading: boolean;
  onClose: () => void;
  onSubmit: (value: FirstLoginMailSettingType) => void;
  mailFromAddressList: TenantMailList[];
  mailReplyAddressList: TenantMailList[];
};

export const MailSettingFormComponent: FC<Props> = ({
  formId,
  onClose,
  isLoading,
  mailFromAddressList,
  mailReplyAddressList,
  onSubmit,
}) => {
  const { isEditable } = useTransactionMailPermission();
  const { handleSubmit } = useFormContext<FirstLoginMailSettingType>();
  const { errors } = useFormState<FirstLoginMailSettingType>();

  return (
    <>
      {isLoading && <LoadingLayer />}
      <DrawerBody pt="24px" pb="16px">
        <form onSubmit={handleSubmit(onSubmit)} id={formId}>
          <VStack spacing={6}>
            <Box w="100%">
              <Heading as="h3" size="sm" mb="8px">
                <Flex justifyContent="left" alignItems="start">
                  差出人メールアドレス <RequiredIcon ml="8px" />
                </Flex>
              </Heading>
              <Box>
                <FormControl isInvalid={Boolean(errors.fromAddress?.message)}>
                  <RhfSelect<FirstLoginMailSettingType>
                    name="fromAddress"
                    placeholder="選択してください"
                    bgColor={Colors.WHITE}
                    isDisabled={!isEditable}
                  >
                    {mailFromAddressList.map((mail) => (
                      <option key={mail.id} value={mail.name}>
                        {mail.name}
                      </option>
                    ))}
                  </RhfSelect>
                  <FormErrorMessage>
                    {errors.fromAddress?.message}
                  </FormErrorMessage>
                </FormControl>
              </Box>
            </Box>
            <Box w="100%">
              <Heading as="h3" size="sm" mb="8px">
                <Flex justifyContent="left" alignItems="start">
                  返信用メールアドレス
                </Flex>
              </Heading>
              <FormControl isInvalid={Boolean(errors.replyAddress?.message)}>
                <RhfSelect<FirstLoginMailSettingType>
                  name="replyAddress"
                  placeholder="選択してください"
                  bgColor={Colors.WHITE}
                  isDisabled={!isEditable}
                >
                  {mailReplyAddressList.map((mail) => (
                    <option key={mail.id} value={mail.name}>
                      {mail.name}
                    </option>
                  ))}
                </RhfSelect>
                <FormErrorMessage>
                  {errors.replyAddress?.message}
                </FormErrorMessage>
              </FormControl>
            </Box>
          </VStack>
        </form>
      </DrawerBody>
      <DrawerFooter borderWidth="1px">
        <Button onClick={onClose} mr={3} {...ColorSet.Default}>
          キャンセル
        </Button>
        <Button
          type="submit"
          variant="primary"
          form={formId}
          isDisabled={!isEditable}
        >
          保存
        </Button>
      </DrawerFooter>
    </>
  );
};
