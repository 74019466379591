import { VFC, useCallback } from 'react';
import { useForm, FormProvider } from 'react-hook-form';
import { useDisclosure, ButtonProps } from '@chakra-ui/react';

import { DrawerForm } from 'components/common/atoms';
import { ContentsDbTblFormType } from 'api/contents/types';

export type TableFormChildProps = {
  formId: string;
  tableId?: string;
  closeHandler: () => void;
};

type TblSchemaEditFormInnerProps = {
  // Drawerのタイトル
  title: string;
  // 編集対象のテーブルID
  tableId?: string;
  // Drawerに表示するフォームコンポーネント
  TableFormChild: React.VFC<TableFormChildProps>;
  // Drawerを開くButtonの中に配置するコンポーネント
  OpenBtnChild: React.VFC;
  // Drawerを開くButton Props
  OpenBtnProps?: ButtonProps;
};

const defaultValues: ContentsDbTblFormType = {
  name: '',
  title: '',
  group: '',
  memo: '',
  tblSchema: {
    0: {
      pk: '',
      column: '',
      displayName: '',
      dataType: '',
      notNull: '',
      isSort: '',
      rule: '',
    },
  },
};

export const TableForm: VFC<TblSchemaEditFormInnerProps> = ({
  title,
  tableId = '',
  TableFormChild,
  OpenBtnChild,
  OpenBtnProps = {},
}) => {
  const formId = 'tbl-form';
  const { isOpen, onOpen, onClose } = useDisclosure();
  const methods = useForm<ContentsDbTblFormType>({
    mode: 'onBlur',
    defaultValues,
  });
  const {
    reset,
    // setError,
    clearErrors,
    formState: { isSubmitting },
  } = methods;

  const cancelBtnHandelr = useCallback(() => {
    reset();
    onClose();
  }, [reset, onClose]);

  // 前回のエラーが残ったままだと
  // submitHandler が実行されないので
  // サブミット処理とは別で
  // エラーを手動クリア
  const clickHandler = useCallback(() => {
    clearErrors('tblSchema');
  }, [clearErrors]);

  return (
    <FormProvider {...methods}>
      <DrawerForm
        title={title}
        openBtnChildNode={<OpenBtnChild />}
        openBtnProps={{ ...OpenBtnProps }}
        cancelBtnTitle="キャンセル"
        cancelBtnHandelr={cancelBtnHandelr}
        cancelBtnProps={{ disabled: isSubmitting }}
        submitBtnTitle="登録"
        submitBtnHandelr={clickHandler}
        submitBtnProps={{
          variant: 'primary',
          disabled: isSubmitting,
          isLoading: isSubmitting,
        }}
        isOpen={isOpen}
        onOpen={onOpen}
        onClose={onClose}
        closeOnOverlayClick={false}
        closeOnEsc={false}
        autoFocus={false}
        size="lg"
        formId={formId}
        drawerCloseButtonProps={{ disabled: isSubmitting }}
      >
        <TableFormChild
          formId={formId}
          closeHandler={cancelBtnHandelr}
          tableId={tableId}
        />
      </DrawerForm>
    </FormProvider>
  );
};
