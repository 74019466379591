import { request } from 'admin/api/Request';
import { CustomError } from 'admin/error/CustomError';
import { messages } from 'api/transactionMail/messages';
import { FirstLoginMailSettingType } from 'api/transactionMail/type';
import { isResponseError } from 'api/types';
import { FirstLoginMailSettingSchema } from 'schema/transactionMail/index';
import { getValidationError } from 'utils/form';
import { ZodError } from 'zod';

export const editMailSetting = async ({
  tenantId,
  params,
}: {
  tenantId: string;
  params: FirstLoginMailSettingType;
}): Promise<FirstLoginMailSettingType> => {
  const path = `tenant/${tenantId}/transaction_mail_commons/`;

  const response = await request<FirstLoginMailSettingType>({
    path,
    body: params,
    method: 'patch',
  });

  if (response.hasError) {
    if (response.status === 404) {
      throw new CustomError(messages.editMailSetting.customer.failed, {
        path,
        status: response.status,
      });
    } else if (isResponseError(response.error)) {
      const customError = response.error;

      const formError = getValidationError<FirstLoginMailSettingType>({
        formData: params,
        response: customError,
      });

      throw new CustomError(messages.editMailSetting.customer.error, {
        path,
        params,
        errors: formError,
        status: response.status,
        logLevel: response.status === 400 ? 'warning' : 'error',
      });
    } else {
      throw new CustomError(messages.editMailSetting.customer.error, {
        path,
        status: response.status,
      });
    }
  }
  try {
    FirstLoginMailSettingSchema.parse(response.data);
  } catch (error) {
    if (error instanceof ZodError) {
      throw new CustomError(error.message, {
        path,
        status: response.status,
        logLevel: 'error',
      });
    }
  }

  return response.data;
};
