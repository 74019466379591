import { updateRedirectUri } from 'admin/api/service/auth/updateRedirectUri';
import { CustomError } from 'admin/error/CustomError';
import { queryAuthKey } from 'admin/hooks/service/auth/queryAuthKey';
import { useUserPoolChildId } from 'admin/hooks/useUserPoolChildId';
import {
  AuthFormInputType,
  AuthFormOutputType,
} from 'admin/types/service/auth/form';
import { logger } from 'api/logger';
import { useCustomToast } from 'hooks/useCustomToast';
import { Dispatch, SetStateAction } from 'react';
import { UseFormSetError } from 'react-hook-form';
import { useMutation, useQueryClient } from 'react-query';
import { toMultiError } from 'utils/form';
import { errorToast, successToast } from 'utils/toast';

export const useUpdateCallbackUrl = (
  setError: UseFormSetError<AuthFormInputType>,
  setGlobalErrors: Dispatch<SetStateAction<string[]>>,
) => {
  const userPoolChildId = useUserPoolChildId();
  const queryClient = useQueryClient();

  const toast = useCustomToast();
  const { mutateAsync, isLoading } = useMutation({
    mutationFn: (data: AuthFormOutputType) =>
      updateRedirectUri({
        userPoolChildId,
        data,
      }),
    onError: async (error, variables: AuthFormOutputType) => {
      if (error instanceof CustomError) {
        if (error.cause.errors?.others) {
          const othersMsgs = Array.isArray(error.cause.errors?.others)
            ? error.cause.errors?.others
            : [error.cause.errors?.others];

          setGlobalErrors([othersMsgs.join('')]);
        } else {
          Object.keys(variables).forEach((k) => {
            const key = k as keyof AuthFormOutputType;
            const errMsgs = error.cause.errors?.[key];
            if (errMsgs && Array.isArray(errMsgs)) {
              setError(key, { types: toMultiError(errMsgs) });
            }
          });
        }

        if (error.cause.errors) {
          await logger({
            loglevel: error.logLevel,
            data: JSON.stringify(error.cause),
            message: error.message,
          });
        }
      }
      toast({
        ...errorToast,
        duration: 4000,
        title: 'コールバックURL設定の更新に失敗しました',
        position: 'bottom',
        variant: 'solid',
      });
    },
    onSuccess: () => {
      void queryClient.invalidateQueries(queryAuthKey.getAuth(userPoolChildId));

      toast({
        ...successToast,
        duration: 4000,
        title: 'コールバックURL設定を更新しました',
        position: 'bottom',
        variant: 'solid',
      });
    },
  });

  return { mutate: mutateAsync, isLoading };
};
