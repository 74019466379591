import { Box, Button, Flex, IconButton } from '@chakra-ui/react';
import { Page } from 'api/common/types';
import { memo, useCallback, useEffect, useState } from 'react';
import { GrNext, GrPrevious } from 'react-icons/gr';
import { useLocation, useNavigate } from 'react-router-dom';

type PagerBlock = {
  id: number;
  link: string;
  text: string;
};

type PaginatorProps = {
  pageName: string;
  config: Page;
  cb?: (page: number) => void;
};

export const Paginator = memo(({ pageName, config, cb }: PaginatorProps) => {
  const navigate = useNavigate();
  const query = useLocation().search;
  const [currentPage, setCurrentPage] = useState(1);
  const [frontPageList, setFrontPageList] = useState<PagerBlock[]>([]);
  const [middlePageList, setMiddlePageList] = useState<PagerBlock[]>([]);
  const [endPageList, setEndPageList] = useState<PagerBlock[]>([]);
  const [frontDot, setFrontDot] = useState(false);
  const [endDot, setEndDot] = useState(false);
  const paginationRange = 5; // 中央に表示するボタンの数
  const limitRange = 2; // 左右の端から何個までボタンを表示するか

  // 実際に表示するページの数を取得
  const calRange = useCallback(
    (start: number, end: number) => {
      const range: PagerBlock[] = [];
      for (let i = start; i <= end; i += 1) {
        range.push({
          id: i,
          link: `${pageName}?page=${i}`,
          text: String(i),
        });
      }

      return range;
    },
    [pageName],
  );

  // ページサイズのチェック
  const pageSizeCheck = useCallback(() => {
    if (config.pageCount <= paginationRange + limitRange * 2) {
      return false;
    }

    return true;
  }, [config.pageCount]);

  // 最初から{limitRange}ページ分のボタン配列作成
  const frontPageRange = useCallback(() => {
    if (!pageSizeCheck()) {
      setFrontDot(false);
      setEndDot(false);

      return calRange(1, config.pageCount);
    }

    return calRange(1, limitRange);
  }, [calRange, pageSizeCheck, config.pageCount]);

  // 中央から前後range分のページボタン配列作成
  const middlePageRange = useCallback(() => {
    let start = 0;
    let end = 0;
    if (!pageSizeCheck()) return [];
    if (currentPage <= paginationRange) {
      start = 3;
      end = paginationRange + 2;
      setFrontDot(false);
      setEndDot(true);
    } else if (currentPage > config.pageCount - paginationRange) {
      start = config.pageCount - paginationRange - 1;
      end = config.pageCount - 2;
      setFrontDot(true);
      setEndDot(false);
    } else {
      start = currentPage - Math.floor(paginationRange / 2);
      end = currentPage + Math.floor(paginationRange / 2);
      setFrontDot(true);
      setEndDot(true);
    }

    return calRange(start, end);
  }, [calRange, config.pageCount, currentPage, pageSizeCheck]);

  // 最後から{limitRange}ページ分のボタン配列作成
  const endPageRange = useCallback(() => {
    if (!pageSizeCheck()) return [];

    return calRange(config.pageCount - (limitRange - 1), config.pageCount);
  }, [calRange, config.pageCount, pageSizeCheck]);

  // 初期設定
  useEffect(() => {
    setFrontPageList(frontPageRange());
    setMiddlePageList(middlePageRange());
    setEndPageList(endPageRange());
    setCurrentPage(config.currentPage);
  }, [config, pageName, frontPageRange, middlePageRange, endPageRange]);

  const getUrl = useCallback(
    ({ pageId = 1 }: { pageId?: number }): string => {
      const queryList = query.replace('?', '').split('&');
      const queryObjects = {};
      queryList.forEach((item) => {
        const queryStr = item.split('=');
        Object.assign(queryObjects, { [queryStr[0]]: queryStr[1] });
      });
      Object.assign(queryObjects, {
        ...queryObjects,
        ...{ page: pageId },
      });

      let queryParamStr = '';
      Object.entries(queryObjects).forEach((item) => {
        if (queryParamStr === '') {
          queryParamStr += '?';
        } else {
          queryParamStr += '&';
        }
        queryParamStr += item.join('=');
      });

      return `${pageName}${queryParamStr}`;
    },
    [pageName, query],
  );

  const onChangePage = useCallback(
    (pageId: number) => {
      if (cb !== undefined) {
        cb(pageId);
      } else {
        navigate(getUrl({ pageId }));
      }
    },
    [navigate, getUrl, cb],
  );

  const pageButton = (page: PagerBlock) => (
    <Box
      key={page.id}
      style={{ margin: '0 10px' }}
      onClick={() => onChangePage(page.id)}
    >
      <Button
        bgColor={currentPage === page.id ? 'gray.400' : 'gray.300'}
        color={currentPage === page.id ? 'white' : ''}
      >
        {page.text}
      </Button>
    </Box>
  );

  const pageDotButton = () => (
    <Box style={{ margin: '0 10px' }}>
      <Button bgColor="gray.300" color="" cursor="not-allowed" disabled>
        ...
      </Button>
    </Box>
  );

  return (
    <Box
      display="flex"
      alignItems="center"
      justifyContent="space-between"
      m="0 auto"
      mt={2}
    >
      <IconButton
        aria-label="前ページ"
        icon={<GrPrevious />}
        onClick={() => onChangePage(currentPage - 1)}
        disabled={config.currentPage === 1}
        mr={4}
      />
      <Flex>
        {frontPageList.map((item) => pageButton(item))}
        {frontDot && pageDotButton()}
        {middlePageList.map((item) => pageButton(item))}
        {endDot && pageDotButton()}
        {endPageList.map((item) => pageButton(item))}
      </Flex>
      <IconButton
        aria-label="前ページ"
        icon={<GrNext />}
        onClick={() => onChangePage(currentPage + 1)}
        disabled={config.currentPage === config.pageCount}
        ml={4}
      />
    </Box>
  );
});
