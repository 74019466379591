import { VFC } from 'react';
import { Box } from '@chakra-ui/react';
import { AlertBar } from 'components/common/atoms';

type ListErrorProps = {
  message: string;
};

export const ListError: VFC<ListErrorProps> = ({ message }: ListErrorProps) => (
  <Box w="100%">
    <AlertBar status="error" message={message} />
  </Box>
);
