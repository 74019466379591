import { Box, Flex, Text } from '@chakra-ui/react';
import { Colors } from 'admin/define/colors';
import { FC, memo } from 'react';

type Props = {
  label: string;
};

export const NewsletterTitleComponent: FC<Props> = memo(({ label }) => (
  <Box mt="40px">
    {label && (
      <Box
        bgColor={Colors.WHITE}
        borderRadius="4px"
        display="inline-block"
        p="2px 4px"
      >
        <Text>{label}</Text>
      </Box>
    )}
    <Flex justifyContent="flex-end" mt="8px">
      <Box mr="auto">
        <Text
          fontFamily="Hiragino Sans"
          fontSize="18px"
          fontWeight="700"
          lineHeight="170%"
        >
          メールマガジン購読設定
        </Text>
      </Box>
    </Flex>
    <Box mt="16px">
      <Text
        fontWeight="400"
        fontSize="14px"
        lineHeight="170%"
        fontFamily="Hiragino Sans"
        color={Colors.GRAY_00}
      >
        テナントで利用可能なメールマガジン購読設定の利用状況の管理を行います。
      </Text>
    </Box>
  </Box>
));
