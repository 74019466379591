import { Icon, IconProps } from '@chakra-ui/react';

type CustomIconType = (props: IconProps) => JSX.Element;
export const ErrorIcon: CustomIconType = (props: IconProps) => (
  <Icon xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" fill="none" {...props}>
    <mask x="0" y="0" width="24" height="24">
      <rect width="24" height="24" fill="#D9D9D9"/>
    </mask>
    <g>
      <path d="M11.9947 17.8484C12.4818 17.8484 12.8921 17.6837 13.2254 17.3541C13.5587 17.0246 13.7254 16.6163 13.7254 16.1291C13.7254 15.642 13.5606 15.2276 13.2311 14.8859C12.9016 14.5443 12.4932 14.3734 12.0061 14.3734C11.519 14.3734 11.1087 14.5443 10.7754 14.8859C10.4421 15.2276 10.2754 15.642 10.2754 16.1291C10.2754 16.6163 10.4402 17.0246 10.7697 17.3541C11.0992 17.6837 11.5076 17.8484 11.9947 17.8484ZM10.4254 13.0484H13.5754V6.47344H10.4254V13.0484ZM12.0004 22.8484C10.4837 22.8484 9.06717 22.5644 7.75072 21.9963C6.43425 21.4283 5.28911 20.6574 4.31529 19.6835C3.34147 18.7097 2.57054 17.5646 2.00249 16.2481C1.43442 14.9317 1.15039 13.5151 1.15039 11.9984C1.15039 10.4818 1.43442 9.06521 2.00249 7.74876C2.57054 6.4323 3.34147 5.28715 4.31529 4.31334C5.28911 3.33952 6.43425 2.56859 7.75072 2.00054C9.06717 1.43247 10.4837 1.14844 12.0004 1.14844C13.5171 1.14844 14.9336 1.43247 16.2501 2.00054C17.5665 2.56859 18.7117 3.33952 19.6855 4.31334C20.6593 5.28715 21.4302 6.4323 21.9983 7.74876C22.5664 9.06521 22.8504 10.4818 22.8504 11.9984C22.8504 13.5151 22.5664 14.9317 21.9983 16.2481C21.4302 17.5646 20.6593 18.7097 19.6855 19.6835C18.7117 20.6574 17.5665 21.4283 16.2501 21.9963C14.9336 22.5644 13.5171 22.8484 12.0004 22.8484Z" fill="currentColor"/>
    </g>
  </Icon>
)
