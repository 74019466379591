import { request } from 'admin/api/Request';
import { API_MESSAGES } from 'admin/define/apiMessages';
import { CustomError } from 'admin/error/CustomError';
import { attributeGroupSchema } from 'admin/schema/idPoolConsumer/attributeGroup';
import { AttributeGroupType } from 'admin/types/userPool/attributeGroup/index';
import { UserPool } from 'admin/types/userPool/index';
import { Options } from 'ky';
import { ZodError } from 'zod';

export const getAttributeGroupDetail = async ({
  id,
  attributeGroupId,
  options,
}: {
  id: UserPool['id'];
  attributeGroupId: AttributeGroupType['id'];
  options?: Options;
}): Promise<AttributeGroupType> => {
  const path = `uniikey/${id}/attribute_group/${attributeGroupId}`;
  const response = await request<AttributeGroupType>({
    path,
    method: 'get',
    options,
  });

  if (response.hasError) {
    if (response.status === 404) {
      throw new CustomError(
        API_MESSAGES.USER_POOL.GET_ID_POOL_CONSUMER_ATTRIBUTE_GROUPS.NOT_FOUND,
        {
          path,
          status: response.status,
        },
      );
    } else {
      throw new CustomError(
        API_MESSAGES.USER_POOL.GET_ID_POOL_CONSUMER_ATTRIBUTE_GROUPS.ERROR,
        {
          path,
          status: response.status,
          logLevel: response.status === 400 ? 'warning' : 'error',
        },
      );
    }
  }

  try {
    attributeGroupSchema.parse(response.data);
  } catch (error) {
    if (error instanceof ZodError) {
      throw new CustomError(error.message, {
        path,
        options,
        status: response.status,
        logLevel: 'error',
      });
    }
  }

  return response.data;
};
