import { Options } from 'ky';
import { request } from 'api/request';
import { logger } from 'api/logger';
import { CustomError } from 'api/error/CustomError';
import { Skin, isSkin } from './types';
import { messages } from './messages';

// デザインスキン詳細取得
export const getEnqueteSkinId = async (
  tenantId: string,
  skinId: string,
  options?: Options,
) => {
  const target = 'enquete';
  const path = `${target}/${tenantId}/skin/${skinId}/`;

  const response = await request({
    path,
    method: 'get',
    options,
  });

  const responseData = (await response.data) as unknown[] & Skin;

  if (response.hasError) {
    if (response.status === 404) {
      throw new CustomError(
        messages.getEnqueteSkinId.customer.notFound,
        response.status,
      );
    } else {
      throw new CustomError(
        messages.getEnqueteSkinId.customer.error,
        response.status,
      );
    }
  }

  if (!isSkin(responseData)) {
    await logger({
      loglevel:
        response.status === 404 || response.status === 450
          ? 'warning'
          : 'error',
      data: responseData,
      message: messages.getEnqueteSkinId.system.typeError,
    });

    throw new CustomError(
      messages.getEnqueteSkinId.customer.failed,
      response.status,
    );
  }

  return responseData;
};
