import { withSuspenseAndErrorBoundary } from 'admin/components/Error/WithErrorBoundary';
import { MemberContentsComponent } from 'admin/components/member/detail/memberContents/MemberContentsComponent';
// import { MemberType } from 'admin/types/member';
import { ErrorContents } from 'components/common/atoms';
import { FC, memo } from 'react';

const memberContents: FC = memo(() => (
  // TODO: 9月末のリリース時点では必要では無い為、コメントアウト
  // const [member, setMember] = useState<MemberType>();

  <MemberContentsComponent
  // TODO: 9月末のリリース時点では必要では無い為、コメントアウト
  // member={member}
  // setMember={setMember}
  />
));

export const MemberContents = withSuspenseAndErrorBoundary(memberContents, {
  ErrorComponent: <ErrorContents name="会員詳細情報" />,
});
