import { getAllCodeGroups } from 'admin/api/userPool/master/getAllCodeGroups';
import { CustomError } from 'admin/error/CustomError';
import { UserPool } from 'admin/types/userPool';
import { logger } from 'api/logger';
import { useQuery } from 'react-query';
import { queryKeys } from './queryKeys';

export const useAllCodeGroups = ({
  id,
  isEnabled,
}: {
  id: UserPool['id'];
  isEnabled?: boolean;
}) => {
  const { data, refetch, isLoading } = useQuery({
    queryKey: queryKeys.getAllMasterSettings(id, isEnabled),
    queryFn: () => getAllCodeGroups({ id, isEnabled }),
    onError: async (error) => {
      if (error instanceof CustomError) {
        await logger({
          loglevel: error.logLevel,
          data: JSON.stringify(error.cause),
          message: error.message,
        });
      }
    },
  });

  return {
    data,
    refetch,
    isLoading,
  };
};
