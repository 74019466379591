import {
  Textarea as ChakraTextarea,
  TextareaProps,
  forwardRef,
} from '@chakra-ui/react';
import { Ref } from 'react-hook-form';

export type Props = {
  ref?: Ref;
} & Omit<TextareaProps, ''>;

/**
 * @see 汎用テキストエリア
 * @param ref ReactHookFormのregisterへのref
 */
export const Textarea = forwardRef<Props, 'input'>(
  ({ ...props }: Omit<Props, 'ref'>, ref) => (
    <ChakraTextarea {...props} {...ref} />
  ),
);
