import { useSearch } from 'components/opt/hooks/useSearch';
import { MailSettingStatuses } from 'components/paywall/pages/MailSetting/typed';
import { queryPaywallKey } from 'hooks/paywall/queryPaywallKey';
import { useUserTenantId } from 'hooks/user/useUserTenantId';
import { useEffect, useState } from 'react';
import { useQueryClient } from 'react-query';
import { useParams, useSearchParams } from 'react-router-dom';

export const usePaywallUtil = (): {
  paywallListRefetchQueries: () => void;
  paywallDesignListRefetchQueries: () => void;
  paywallCourseListRefetchQueries: () => void;
  paywallPromotionListRefetchQueries: (param: string) => void;
  paywallWallItemListRefetchQueries: () => void;
  paywallTermsRefetchQueries: () => void;
  paywallMailSettingCommonRefetchQueries: () => void;
  paywallMailSettingPersonalRefetchQueries: (
    type: keyof MailSettingStatuses,
  ) => void;
  paywallOrderListRefetchQueries: (param: string) => void;
  paywallOrderDetailRefetchQueries: (orderId: string) => void;
  paywallCustomerListRefetchQueries: (param: string) => void;
  paywallCustomerDetailRefetchQueries: (customerUid: string) => void;
  paywalSubscriptionListRefetchQueries: (param: string) => void;
  paywalSubscriptionDetailRefetchQueries: (orderId: string) => void;
} => {
  const params = useParams();
  const tenantId = useUserTenantId();
  const queryClient = useQueryClient();
  const [searchParams] = useSearchParams();
  const [queryFilter, setQueryFilter] = useState(
    searchParams.get('filter') || '',
  );
  const [queryPage, setQueryPage] = useState(searchParams.get('page') || '');
  const [queryPerPage, setQueryPerPage] = useState(
    searchParams.get('perPage') || '',
  );
  const { makeApiQuery } = useSearch();
  const queryParam = makeApiQuery();

  useEffect(() => {
    setQueryFilter(searchParams.get('filter') || '');
    setQueryPage(searchParams.get('page') || '');
    setQueryPerPage(searchParams.get('perPage') || '50');
  }, [searchParams]);

  /**
   * ペイウォール一覧 refetch処理
   */
  const paywallListRefetchQueries = () => {
    void queryClient.refetchQueries(queryPaywallKey.getWallList({ tenantId }));
  };

  /**
   * ペイウォール画面一覧 refetch処理
   */
  const paywallDesignListRefetchQueries = () => {
    void queryClient.refetchQueries(
      queryPaywallKey.getWallDesignList({
        tenantId,
        wallId: params.wallId || '',
        param: queryParam,
      }),
    );
  };

  /**
   * ペイウォールコース一覧 refetch処理
   */
  const paywallCourseListRefetchQueries = () => {
    void queryClient.refetchQueries(
      queryPaywallKey.getCourseList({
        tenantId,
        wallId: params.wallId || '',
      }),
    );
    // エディタで親子関係があるコースリスト取得するために以下のrefetchを行う
    void queryClient.refetchQueries(
      queryPaywallKey.getCourseList({
        tenantId,
        wallId: params.wallId || '',
        isIncludeParent: true,
      }),
    );
  };

  /**
   * ペイウォールプロモーション一覧 refetch処理
   */
  const paywallPromotionListRefetchQueries = (param: string) => {
    void queryClient.refetchQueries(
      queryPaywallKey.getPromotionList({
        tenantId,
        courseId: params.courseId || '',
        param,
      }),
    );
  };

  /**
   * ペイウォール単品商品一覧 refetch処理
   */
  const paywallWallItemListRefetchQueries = () => {
    void queryClient.refetchQueries(
      queryPaywallKey.getWallItemList({
        tenantId,
        wallId: params.wallId || '',
        filter: queryFilter,
        page: queryPage,
        perPage: queryPerPage,
      }),
    );
  };

  /**
   * 規約情報 refetch処理
   */
  const paywallTermsRefetchQueries = () => {
    void queryClient.refetchQueries(queryPaywallKey.getTerm({ tenantId }));
  };

  /**
   * メール共通 refetch処理
   */
  const paywallMailSettingCommonRefetchQueries = () => {
    void queryClient.refetchQueries(
      queryPaywallKey.getMailSettingCommon({ tenantId }),
    );
  };

  /**
   * メール個別 refetch処理
   */
  const paywallMailSettingPersonalRefetchQueries = (
    type: keyof MailSettingStatuses,
  ) => {
    void queryClient.refetchQueries(
      queryPaywallKey.getMailSettingPersonal({ tenantId, type }),
    );
  };

  /**
   * オーダー一覧 refetch処理
   */
  const paywallOrderListRefetchQueries = (param: string) => {
    void queryClient.refetchQueries(
      queryPaywallKey.getOrderList({ tenantId, param }),
    );
  };

  /**
   * オーダー詳細 refetch処理
   */
  const paywallOrderDetailRefetchQueries = (id: string) => {
    void queryClient.refetchQueries(
      queryPaywallKey.getOrderDetail({ tenantId, id }),
    );
  };

  /**
   * 顧客一覧 refetch処理
   */
  const paywallCustomerListRefetchQueries = (param: string) => {
    void queryClient.refetchQueries(
      queryPaywallKey.getCustomerList({ tenantId, param }),
    );
  };

  /**
   * 顧客詳細 refetch処理
   */
  const paywallCustomerDetailRefetchQueries = (id: string) => {
    void queryClient.refetchQueries(
      queryPaywallKey.getCustomerDetail({ tenantId, id }),
    );
  };

  /**
   * サブスクリプション注文一覧 refetch処理
   */
  const paywalSubscriptionListRefetchQueries = (param: string) => {
    void queryClient.refetchQueries(
      queryPaywallKey.getSubscriptionList({ tenantId, param }),
    );
  };

  /**
   * サブスクリプション注文詳細 refetch処理
   */
  const paywalSubscriptionDetailRefetchQueries = (id: string) => {
    void queryClient.refetchQueries(
      queryPaywallKey.getSubscriptionDetail({ tenantId, id }),
    );
  };

  return {
    paywallListRefetchQueries,
    paywallDesignListRefetchQueries,
    paywallCourseListRefetchQueries,
    paywallPromotionListRefetchQueries,
    paywallWallItemListRefetchQueries,
    paywallTermsRefetchQueries,
    paywallMailSettingCommonRefetchQueries,
    paywallMailSettingPersonalRefetchQueries,
    paywallOrderListRefetchQueries,
    paywallOrderDetailRefetchQueries,
    paywallCustomerListRefetchQueries,
    paywallCustomerDetailRefetchQueries,
    paywalSubscriptionListRefetchQueries,
    paywalSubscriptionDetailRefetchQueries,
  };
};
