import {
  Box,
  DrawerBody,
  Flex,
  FormControl,
  FormErrorMessage,
  Heading,
  VStack,
} from '@chakra-ui/react';
import { RhfInput } from 'admin/components/form/RhfInput';
import { RequiredIcon } from 'admin/components/ui/icon/requiredIcon';
import { LoadingLayer } from 'admin/components/ui/loadingLayer';
import { Colors } from 'admin/define/colors';
import { TermFormType } from 'admin/types/userPool/terms/form';
import { ChangeEvent, FC, memo } from 'react';
import { useFormContext, useFormState } from 'react-hook-form';
import { toErrMsgList } from 'utils/form';

type Props = {
  formId: string;
  onSubmit: (value: TermFormType) => void;
  isLoading: boolean;
  term?: TermFormType;
};
export const TermsCreateFormComponent: FC<Props> = memo(
  ({ onSubmit, formId, isLoading, term }) => {
    const { handleSubmit, clearErrors } = useFormContext<TermFormType>();
    const { errors } = useFormState<TermFormType>();

    const handleOnBlur = (e: ChangeEvent<HTMLInputElement>) => {
      if (e.target.value !== '') {
        clearErrors(['displayNameJa', 'displayNameForeign']);
      }
    };

    return (
      <>
        {isLoading && <LoadingLayer />}
        <DrawerBody py="24px" px="0" borderTopWidth="1px" mx="24px">
          <form onSubmit={handleSubmit(onSubmit)} id={formId}>
            <VStack spacing={6}>
              <Box w="100%">
                <Heading as="h3" fontSize="14px" mb={2}>
                  <Box display="flex" alignItems="bottom">
                    表示名
                  </Box>
                </Heading>
                <Box bg="#F7FAFC" p="16px" borderWidth="1px" borderRadius="4px">
                  <Box w="100%">
                    <Heading as="h3" fontSize="14px" mb={2}>
                      <Flex alignItems="center">
                        日本語
                        <RequiredIcon bg="white" ml="8px" />
                      </Flex>
                    </Heading>
                    <Box>
                      <FormControl
                        isInvalid={
                          Boolean(errors.displayNameJa?.message) ||
                          toErrMsgList(errors, 'displayNameJa').length > 0
                        }
                      >
                        <Box>
                          <RhfInput<TermFormType>
                            name="displayNameJa"
                            bgColor={Colors.WHITE}
                            onBlur={handleOnBlur}
                          />
                        </Box>

                        {toErrMsgList(errors, 'displayNameJa').map((err) => (
                          <FormErrorMessage key={`displayNameJa${err}`}>
                            {err}
                          </FormErrorMessage>
                        ))}
                      </FormControl>
                    </Box>
                  </Box>
                  <Box w="100%" mt={4}>
                    <Heading as="h3" fontSize="14px" mb={2}>
                      外国語
                    </Heading>
                    <Box>
                      <FormControl
                        isInvalid={
                          Boolean(errors.displayNameForeign?.message) ||
                          toErrMsgList(errors, 'displayNameForeign').length > 0
                        }
                      >
                        <Box>
                          <RhfInput<TermFormType>
                            name="displayNameForeign"
                            bgColor={Colors.WHITE}
                            onBlur={handleOnBlur}
                          />
                        </Box>

                        {toErrMsgList(errors, 'displayNameForeign').map(
                          (err) => (
                            <FormErrorMessage key={`displayNameForeign${err}`}>
                              {err}
                            </FormErrorMessage>
                          ),
                        )}
                      </FormControl>
                    </Box>
                  </Box>
                </Box>
              </Box>

              <Box w="100%">
                <FormControl isInvalid={Boolean(errors.fieldName?.message)}>
                  <Heading as="h3" fontSize="14px" mb={2}>
                    <Flex alignItems="center">
                      フィールド名
                      <RequiredIcon ml="8px" />
                    </Flex>
                  </Heading>
                  <Box>
                    <FormControl
                      isInvalid={
                        Boolean(errors.fieldName?.message) ||
                        toErrMsgList(errors, 'fieldName').length > 0
                      }
                    >
                      <Box>
                        <RhfInput<TermFormType>
                          isDisabled={term?.status}
                          name="fieldName"
                          bgColor={Colors.WHITE}
                        />
                      </Box>

                      {toErrMsgList(errors, 'fieldName').map((err) => (
                        <FormErrorMessage key={`fieldName${err}`}>
                          {err}
                        </FormErrorMessage>
                      ))}
                    </FormControl>
                  </Box>
                </FormControl>
              </Box>
              <Box w="100%">
                <FormControl isInvalid={Boolean(errors.url?.message)}>
                  <Heading as="h3" fontSize="14px" mb={2}>
                    <Flex alignItems="center">
                      規約ページのURL
                      <RequiredIcon ml="8px" />
                    </Flex>
                  </Heading>
                  <Box>
                    <FormControl
                      isInvalid={
                        Boolean(errors.url?.message) ||
                        toErrMsgList(errors, 'url').length > 0
                      }
                    >
                      <Box>
                        <RhfInput<TermFormType>
                          name="url"
                          bgColor={Colors.WHITE}
                        />
                      </Box>

                      {toErrMsgList(errors, 'url').map((err) => (
                        <FormErrorMessage key={`url${err}`}>
                          {err}
                        </FormErrorMessage>
                      ))}
                    </FormControl>
                  </Box>
                </FormControl>
              </Box>
              <Box w="100%">
                <FormControl isInvalid={Boolean(errors.displayOrder?.message)}>
                  <Heading as="h3" fontSize="14px" mb={2}>
                    並び順
                  </Heading>
                  <Box>
                    <FormControl
                      isInvalid={
                        Boolean(errors.displayOrder?.message) ||
                        toErrMsgList(errors, 'displayOrder').length > 0
                      }
                    >
                      <Box>
                        <RhfInput<TermFormType>
                          name="displayOrder"
                          bgColor={Colors.WHITE}
                        />
                      </Box>

                      {toErrMsgList(errors, 'displayOrder').map((err) => (
                        <FormErrorMessage key={`displayOrder${err}`}>
                          {err}
                        </FormErrorMessage>
                      ))}
                    </FormControl>
                  </Box>
                </FormControl>
              </Box>
            </VStack>
          </form>
        </DrawerBody>
      </>
    );
  },
);
