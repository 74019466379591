import { Alert, AlertIcon, Box, Checkbox, Flex, Tag, Text, useDisclosure, VStack } from '@chakra-ui/react';
import { Colors } from 'components/paywall/config';
import { Detail, DetailBlock, DetailFlex, DetailItem, DetailItems } from 'components/paywall/features/Detail';
import { DetailCustomerLinkText } from 'components/paywall/features/Detail/DetailCustomer';
import { DetailOrderChargeBackDialog } from 'components/paywall/features/Detail/DetailDialog/DetailOrderChargeBackDialog';
import { DetailOrderChargeBackResponseDialog } from 'components/paywall/features/Detail/DetailDialog/DetailOrderChargeBackResponseDialog';
import { StatusTag } from 'components/paywall/features/StatusTag';
import { OrderChargeBackResponseType, OrderDetailResult } from 'components/paywall/pages/OrderList/typed';
import { useFormatDate } from 'hooks/useFormatDate';
import { FC, Fragment, memo, useCallback, useState } from 'react';

const orderChargeBackResponseTypeDefaultValue: OrderChargeBackResponseType = {
  gmoStatus: false,
  appStatus: false,
  messageApp: '',
  messageGmo: '',
  transaction: [],
  detail: ''
}

type Props = {
  orderDetail: OrderDetailResult;
}

export const OrderDetailInnerInfo: FC<Props> = memo(({orderDetail}: Props) => {
  const { isOpen, onOpen, onClose } = useDisclosure();
  const [isShowButton, setIsShowButton] = useState<boolean>(false);
  const tagStringToArray = (tags: string | null): string[] => tags !== null ? tags.split('\n') : [];
  const [chargeBackResponseModalData, setChargeBackResponseModalData] = useState<OrderChargeBackResponseType>(orderChargeBackResponseTypeDefaultValue);
  const { dateTimeFormat } = useFormatDate();

  // サブスクリプション自動配信停止ボタンを表示用チェックボックス押下時処理
  const onClickCheckBox = useCallback((isCheck: boolean) => {
    setIsShowButton(isCheck);
  }, []);

  // 注文ID表示内容を生成
  // コースが存在しているものはリンク付き
  // 単品商品系（コース無し）はリンクなしの文字列を返却する
  const orderIdText = (): string | JSX.Element => {
    if(orderDetail?.wallOrder.course) {
      return <DetailCustomerLinkText path={`/paywall/${orderDetail?.wallOrder.id}/subscriptionDetail/`} text={orderDetail?.wallOrder.id} />
    };

    return orderDetail?.wallOrder.id;
  }

  // 決済ステータス
  const tranStatusType = [
    {code: 1, tranStatus: 1, name:'支払い済み'},
    {code: 3, tranStatus: 5, name:'購入取り消し'},
    {code: 5, tranStatus: 9, name:'決済エラー'},
    {code: 1, tranStatus: 0, name:'その他'},
    {code: 1, tranStatus: 6, name:'返金済み'},
  ]

  // 決済詳細情報 > 決済ステータス用ステータスコード取得
  const getTranStatus = (tranStatusCode: number) => {
    const tranStatusResult = tranStatusType.filter((status) => status.tranStatus === tranStatusCode)

    if(tranStatusResult.length === 0) return{
      code: 0,
      name: '',
      tranStatus: 0
    }

    return tranStatusResult[0];
  }

  // チャージバック後のモーダルopen処理
  const onOpenCbResponseModal = (orderChargeBackResponse: OrderChargeBackResponseType) => {
    setChargeBackResponseModalData(orderChargeBackResponse);
    onOpen();
  };

  return (
    <>
      <Detail type={2} title="決済詳細情報">
        <DetailBlock space={24}>
          <DetailFlex>
            <DetailItems spacing="8px">
              <DetailItem label="決済ID" text={orderDetail?.id} labelWidth="112px" spacing="16px" />
              <DetailItem label="プラン名称" text={orderDetail?.wallOrder.itemName ? orderDetail?.wallOrder.itemName : orderDetail?.wallOrder.courseName} labelWidth="112px" spacing="16px" />
              <DetailItem label="プロモーション名" text={orderDetail?.wallOrder.promotionName} labelWidth="112px" spacing="16px" />
              <DetailItem label="決済日時" text={dateTimeFormat(orderDetail?.execTranDate)} labelWidth="112px" spacing="16px" />
              <DetailItem label="システム番号" text={orderDetail?.wallOrder.systemCode} labelWidth="112px" spacing="16px" />
              <DetailItem
                label="決済ステータス"
                text={
                  <Box>
                    <StatusTag type={getTranStatus(orderDetail?.tranStatus).code} status={getTranStatus(orderDetail?.tranStatus).name}/>
                    {orderDetail?.cardErrorInfo.map((info, idx) => (
                      <Fragment key={`${info.code}_${String(idx)}`}>
                        <Box fontWeight="700" fontSize="12px">{info.code}</Box>
                        <Box fontWeight="400" fontSize="12px">{info.message}</Box>
                      </Fragment>
                    ))}
                  </Box>
                }
                labelWidth="112px"
                spacing="16px"
                />
            </DetailItems>
            <DetailItems spacing="8px">
              <DetailItem label="注文ID" text={orderIdText()} labelWidth="113px" spacing="16px" />
              <DetailItem label="商品ID" text={orderDetail?.wallOrder.item ? orderDetail?.wallOrder.item : orderDetail?.wallOrder.course} labelWidth="113px" spacing="16px" />
              <DetailItem label="プロモーションID" text={orderDetail?.wallOrder.promotion} labelWidth="113px" spacing="16px" />
              <DetailItem label="決済金額" text={`${orderDetail?.price}円`} labelWidth="113px" spacing="16px" />
              <DetailItem
                label="タグ"
                text={(
                  <Flex flexWrap="wrap" style={{gap: '4px'}}>
                    {tagStringToArray(orderDetail?.wallOrder.tags).map((tag, idx) => (
                      <Tag px="8px" key={`${tag}-${String(idx)}`} bgColor={Colors.BLUE_LIGHT_01} color={Colors.GRAY_03}>
                        {tag}
                      </Tag>
                    ))}
                  </Flex>
                )}
                labelWidth="113px"
                spacing="16px"
                />
            </DetailItems>
          </DetailFlex>
        </DetailBlock>
        {/* 購入取り消しボタンの表示制御を再度確認する */}
        <DetailBlock space={24}>
          {/* 購入取り消しボタンがある時 */}
          {!orderDetail?.wallOrder.chargebackFlag && (
            <VStack spacing="8px" alignItems="flex-end">
              <Box flex="auto">
                <Alert padding="0px" bgColor="transparent" textAlign="right">
                  <AlertIcon color={Colors.GRAY_03}/>
                  <Text fontSize="14px" fontWeight="700">「購入取り消し」をすることで、ユーザーの購入および閲覧権利を取り消すことができます。</Text>
                </Alert>
              </Box>
              <Checkbox
                isChecked={isShowButton}
                onChange={() => onClickCheckBox(!isShowButton)}
              >
                <Text fontSize="14px" fontWeight="400">購入取り消し実行の確認ボタンを表示</Text>
              </Checkbox>
              <DetailOrderChargeBackDialog orderId={orderDetail?.id} isShowButton={isShowButton} onOpenCbResponseModal={onOpenCbResponseModal} />
            </VStack>
          )}

          {/* 購入取り消し済みの場合 */}
          {orderDetail?.wallOrder.chargebackFlag && (
            <VStack spacing="24px" alignItems="flex-end">
              {/* 返金処理エラーが発生した時のみ表示する予定、判定フラグは要確認 */}
              {/* 2023.08.25 エラー時のメッセージは一旦非表示とする（野田さんより連絡が過去にあった） */}
              {/* <AlertInfo fontSize="14px" fontWeight="700" status="error" message='顧客への返金処理が正常に完了できませんでした。決済代行の管理画面から詳細を確認してください。これ以前に返金済みの場合には問題ありません。' /> */}
              <Text
                padding="8px 16px"
                fontSize="14px"
                minWidth="100px"
                borderRadius="6px"
                bgColor={Colors.GRAY_00}
                color={Colors.WHITE}
                fontWeight="500"
                cursor="default"
              >
                購入取り消し済み {dateTimeFormat(orderDetail?.cancelTranDate)}
              </Text>
            </VStack>
          )}

          {/* チャージバック後のレスポンス表示用モーダル */}
          <DetailOrderChargeBackResponseDialog
            chargeBackResponse={chargeBackResponseModalData}
            isOpen={isOpen}
            onOpen={onOpen}
            onClose={onClose}
          />
        </DetailBlock>
      </Detail>
    </>
  )
});
