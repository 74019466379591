import { VFC, memo, useState, useEffect } from 'react';
import { useFormContext } from 'react-hook-form';
import { Box, Flex, Text, Stack } from '@chakra-ui/react';
import { InputTextForm, ColorPicker } from 'components/common/atoms';
import { SkinItemPreview } from 'components/enquete/atoms/SkinItemPreview';
import { Skin } from 'api/enquete/types';
import { SkinDefaultValues } from 'components/enquete/EnqueteSkin';

export const SkinSelectButton: VFC = memo(() => {
  const [selectButtonTextColor, setSelectButtonTextColor] = useState(
    SkinDefaultValues.selectButtonTextColor,
  );
  const [selectButtonBgColor, setSelectButtonBgColor] = useState(
    SkinDefaultValues.selectButtonBgColor,
  );
  const { getValues, watch } = useFormContext<Skin>();

  useEffect(() => {
    const skinViewData = watch((value, { name }) => {
      if (name === 'selectButtonTextColor') {
        setSelectButtonTextColor(value[name] || '');
      }
      if (name === 'selectButtonBgColor') {
        setSelectButtonBgColor(value[name] || '');
      }
    });

    return () => skinViewData.unsubscribe();
  }, [watch]);

  return (
    <Flex alignItems="stretch" mb={4} mt={4}>
      <Box minW="220px" mt="2px">
        <Text as="label">選択肢ボタン</Text>
      </Box>
      <Stack minW="250px">
        <Flex alignItems="flex-end">
          <InputTextForm<Skin>
            label="背景色"
            labelCaption="背景色"
            name="selectButtonBgColor"
            value={getValues('selectButtonBgColor')}
            placeholder="#FFFFFF"
            mr={4}
          />
          <ColorPicker<Skin>
            name="selectButtonBgColor"
            value={getValues('selectButtonBgColor') || '#FFFFFF'}
            width="40px"
            height="40px"
          />
        </Flex>
        <Flex alignItems="flex-end">
          <InputTextForm<Skin>
            label="文字色"
            labelCaption="文字色"
            name="selectButtonTextColor"
            value={getValues('selectButtonTextColor')}
            placeholder="#FFFFFF"
            mr={4}
          />
          <ColorPicker<Skin>
            name="selectButtonTextColor"
            value={getValues('selectButtonTextColor') || '#FFFFFF'}
            width="40px"
            height="40px"
          />
        </Flex>
      </Stack>
      <SkinItemPreview
        element={
          <Box
            borderRadius="6px"
            fontSize="14px"
            p="12px 10px"
            textAlign="center"
            color={selectButtonTextColor}
            bgColor={selectButtonBgColor}
          >
            郵便番号から住所を探す
          </Box>
        }
        width="auto"
      />
    </Flex>
  );
});
