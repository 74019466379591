import { Options } from 'ky';
import { request } from 'api/request';
import { logger } from 'api/logger';
import { CustomError } from 'api/error/CustomError';
import { MailInfo, isMailInfo } from './types';
import { messages } from './messages';

// メールコピー処理
export const getMailCopy = async (
  tenantId: string,
  mailId: string,
  options?: Options,
): Promise<MailInfo> => {
  const path = `mail/${tenantId}/${mailId}/copy/`;

  const response = await request({
    path,
    method: 'get',
    options,
  });

  const responseData = (await response.data) as unknown[];

  if (response.hasError) {
    throw new CustomError(messages.getMailCopy.customer.error);
  }

  if (!isMailInfo(responseData)) {
    await logger({
      loglevel:
        response.status === 404 || response.status === 450
          ? 'warning'
          : 'error',
      data: responseData,
      message: messages.getMailCopy.system.typeError,
    });

    throw new CustomError(messages.getMailCopy.customer.failed);
  }

  return responseData;
};
