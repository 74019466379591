import { FormControl, FormHelperText } from '@chakra-ui/react';
import { FC } from 'react';
import { Link } from 'react-router-dom';

export const PasswordReminderLink: FC = () => (
  <FormControl>
    <FormHelperText textAlign="right" mt="0px" textDecoration="underline">
      <Link to="/password_forget/">パスワードを忘れた方</Link>
    </FormHelperText>
  </FormControl>
);
