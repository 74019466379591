import { CopyIcon, InfoOutlineIcon } from '@chakra-ui/icons';
import {
  Accordion,
  AccordionButton,
  AccordionIcon,
  AccordionItem,
  AccordionPanel,
  Box,
  Button,
  ButtonGroup,
  Flex,
  FormControl,
  FormErrorMessage,
  Icon,
  Text,
  VStack,
} from '@chakra-ui/react';
import { RhfInput } from 'admin/components/form/RhfInput';
import { TestMailPopover } from 'admin/components/idPoolConsumer/transactionMail/testMailPopover';
import { RequiredIcon } from 'admin/components/ui/icon';
import { Colors } from 'admin/define/colors';
import {
  TransactionMailCategoryType,
  TransactionMailFormType,
  TransactionMailInsertWord,
} from 'admin/types/userPool/transactionMail';
import { FC, memo, RefObject, useCallback } from 'react';
import EmailEditor from 'react-email-editor';
import { useFormContext, useFormState } from 'react-hook-form';
import { BsArrowLeft } from 'react-icons/bs';
import { toErrMsgList } from 'utils/form';

type Props = {
  editor: RefObject<EmailEditor>;
  transactionMailType: TransactionMailCategoryType;
  backUrl: string;
  onSave: () => void;
  isLoading: boolean;
  embededWords: TransactionMailInsertWord[];
  onWordClick: (tag: string) => void;
  navigate: (path: string) => void;
};

export const TransactionMailEditorHeaderComponent: FC<Props> = memo(
  ({
    editor,
    transactionMailType,
    backUrl,
    onSave,
    isLoading,
    embededWords,
    onWordClick,
    navigate,
  }) => {
    const { errors } = useFormState<TransactionMailFormType>();
    const { getValues } = useFormContext<TransactionMailFormType>();

    const onBackPage = useCallback(() => {
      navigate(backUrl);
    }, [backUrl, navigate]);

    return (
      <>
        <Box
          w="100%"
          mb="24px"
          display="flex"
          justifyContent="space-between"
          alignItems="center"
          gridGap={2}
        >
          <Box>
            <Button
              variant="link"
              _focus={{ focus: 'none' }}
              _active={{ active: 'none' }}
              onClick={onBackPage}
              color={Colors.BLUE_20}
              pl="4px"
            >
              <Icon as={BsArrowLeft} size="20px" mr="8px" />
              <Text textDecoration="underline">一覧に戻る</Text>
            </Button>
          </Box>
        </Box>
        <Box
          w="100%"
          mb={4}
          display="flex"
          justifyContent="space-between"
          alignItems="flex-start"
          gridGap={2}
        >
          <Flex
            justifyContent="left"
            alignItems="left"
            minWidth="300px"
            width="800px"
            flexFlow="column"
          >
            <VStack>
              <Box width="100%">
                <Text
                  as="span"
                  fontWeight={700}
                  fontSize="14px"
                  mr="10px"
                  mb={2}
                  whiteSpace="nowrap"
                  display="flex"
                >
                  件名
                  <RequiredIcon ml="8px" />
                </Text>
                <Box maxW="584px">
                  <FormControl
                    isInvalid={
                      Boolean(errors.subject?.message) ||
                      toErrMsgList(errors, 'subject').length > 0
                    }
                  >
                    <Box>
                      <RhfInput<TransactionMailFormType>
                        name="subject"
                        bgColor={Colors.WHITE}
                        placeholder="例：【重要】○○の登録完了のお知らせ"
                        width="100%"
                      />
                    </Box>
                    {toErrMsgList(errors, 'subject').map((err) => (
                      <FormErrorMessage key={`subject_${err}`}>
                        {err}
                      </FormErrorMessage>
                    ))}
                  </FormControl>
                </Box>
              </Box>
              <Box width="100%">
                <Text
                  as="span"
                  fontWeight={700}
                  fontSize="14px"
                  mr="10px"
                  mb={2}
                  whiteSpace="nowrap"
                  display="flex"
                >
                  設定名
                  <RequiredIcon ml="8px" />
                </Text>
                <Box width="100%" maxW="584px">
                  <FormControl
                    isInvalid={
                      Boolean(errors.settingTitle?.message) ||
                      toErrMsgList(errors, 'settingTitle').length > 0
                    }
                  >
                    <Box>
                      <RhfInput<TransactionMailFormType>
                        name="settingTitle"
                        bgColor={Colors.WHITE}
                        placeholder="例：メール設定１"
                        width="100%"
                      />
                    </Box>
                    {toErrMsgList(errors, 'settingTitle').map((err) => (
                      <FormErrorMessage key={`settingTitle_${err}`}>
                        {err}
                      </FormErrorMessage>
                    ))}
                  </FormControl>
                </Box>
              </Box>
            </VStack>
          </Flex>
          <ButtonGroup variant="outline" spacing="2" marginTop="28px">
            <TestMailPopover
              editor={editor}
              transactionMailType={transactionMailType}
              isLoading={isLoading}
              getValues={getValues}
            />
            <Button
              variant="solid"
              type="button"
              p="16px 24px"
              w="76px"
              h="40px"
              fontSize="14px"
              colorScheme="primary"
              onClick={onSave}
              disabled={isLoading}
              fontWeight={700}
            >
              保存
            </Button>
          </ButtonGroup>
        </Box>
        {embededWords.length > 0 && (
          <Box w="100%" mt={2} mb="16px">
            <Accordion
              allowMultiple
              defaultIndex={[0]}
              border="1px"
              borderColor={Colors.GRAY_30}
              borderRadius="6px"
              borderWidth="1px"
              boxSizing="border-box"
              bg={Colors.GRAY_70}
            >
              <AccordionItem borderStyle="none">
                <AccordionButton role="button">
                  <Box
                    flex="1"
                    textAlign="left"
                    fontSize="14px"
                    color={Colors.GRAY_00}
                  >
                    <InfoOutlineIcon mr={2} boxSize="16px" />
                    差し込みワードについて
                    <AccordionIcon ml={2} boxSize="20px" />
                  </Box>
                </AccordionButton>
                <AccordionPanel
                  p="16px"
                  bg={Colors.WHITE}
                  borderBottomRadius="6px"
                  boxSizing="border-box"
                >
                  <Text
                    role="note"
                    fontSize="14px"
                    color={Colors.GRAY_00}
                    lineHeight="21px"
                    mb="8px"
                  >
                    メール本文・件名に下記の項目を差し込むことができます。ラベルをクリックして差し込みワードをコピーし、表示したい箇所に貼り付けてください。
                    <br />
                    尚、メール本文への差し込みワードの挿入は必須設定です。
                  </Text>
                  <Flex
                    flexWrap="wrap"
                    justifyContent="flex-start"
                    gridGap="8px"
                  >
                    {embededWords?.map((word) => (
                      <Button
                        role="option"
                        colorScheme="gray"
                        size="xs"
                        onClick={() => onWordClick(word.code)}
                        key={`${word.mailType}_${word.text}_${word.code}`}
                        px="8px"
                        py="4px"
                        fontWeight="700"
                        bg={Colors.GRAY_73}
                        color={Colors.GRAY_03}
                      >
                        {word.text}
                        <CopyIcon ml={1} />
                      </Button>
                    ))}
                  </Flex>
                </AccordionPanel>
              </AccordionItem>
            </Accordion>
          </Box>
        )}
      </>
    );
  },
);
