import { zodResolver } from '@hookform/resolvers/zod';
import { withSuspenseAndErrorBoundary } from 'admin/components/Error/WithErrorBoundary';
import { FormAttrFormComponent } from 'admin/components/service/attribute/attributeForm/formAttrForm/FormAttrFormComponent';
import { LoadingLayer } from 'admin/components/ui/loadingLayer';
import { useChangeServiceFormAttr } from 'admin/hooks/service/attribute/useChangeServiceFormAttr';
import { confirmSubmitFormSchema } from 'admin/schema/util';
import {
  ServiceAttributeType,
  UserPoolChildAttributeType,
} from 'admin/types/service/attribute';
import { FC, memo, useCallback } from 'react';
import { FormProvider, useForm } from 'react-hook-form';

export type Props = {
  target: ServiceAttributeType | null;
  id: UserPoolChildAttributeType['id'];
  onClose: () => void;
};

export const formAttrForm: FC<Props> = memo(({ onClose, target }) => {
  const formId = 'formAttrForm';

  const { mutate, isLoading } = useChangeServiceFormAttr(target);

  const methods = useForm({
    defaultValues: {
      word: '',
    },
    mode: 'onBlur',
    resolver: zodResolver(confirmSubmitFormSchema),
  });

  const onSubmit = useCallback(async () => {
    await mutate();
    onClose();
  }, [mutate, onClose]);

  return (
    <FormProvider {...methods}>
      {isLoading && <LoadingLayer />}
      <FormAttrFormComponent
        formId={formId}
        isLoading={isLoading}
        onClose={onClose}
        onSubmit={onSubmit}
        target={target}
      />
    </FormProvider>
  );
});

export const FormAttrForm = withSuspenseAndErrorBoundary(formAttrForm);
