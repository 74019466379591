import { useEffect, useState } from 'react';
import { useQuery } from 'react-query';
import { getGroupList } from 'api/tenant/getGroupList';
import { queryTenantKey } from 'hooks/tenant/queryTenantKey';
import { useCustomToast } from 'hooks/useCustomToast';

export type IndentGroupListType = {
  // グループID
  id: string;
  // グループ名
  name: string;
};

/**
 * グループ名をインデント化したリストを取得
 *
 * インデントリスト例:
 * [0] 東京
 * [1]  品川
 * [2]    五反田
 * [3]  渋谷
 * [4]    松濤
 * [5] 神奈川
 * [6]  横浜
 *
 * @param tenantId テナントID
 * @returns インデントリスト
 */
export const useIndentGroupList = (tenantId: string): IndentGroupListType[] => {
  const [indentGroupList, setIndentGroupList] = useState<IndentGroupListType[]>(
    [],
  );
  const toast = useCustomToast();

  const response = useQuery(
    queryTenantKey.tenantGroupList({ tenantId }),
    () => getGroupList(tenantId),
    {
      onError: (err: unknown) => {
        if (err instanceof Error) {
          toast.closeAll();
          toast({
            title: err.message,
            status: 'error',
          });
        }
      },
    },
  );

  const { data: groupList } = response;

  useEffect(() => {
    if (!groupList) {
      setIndentGroupList([]);

      return;
    }

    // key: グループID, val: 階層レベル(ルートレベル = 0)
    const treeLevel: { [key: string]: number } = {};

    const setTreeLevel = (parentId = '', level = 0) => {
      const filterList = groupList.filter((grp) =>
        parentId ? grp.parentId === parentId : !grp.parentId,
      );

      filterList.forEach((grp) => {
        treeLevel[grp.id] = level;
        setTreeLevel(grp.id, level + 1);
      });
    };
    setTreeLevel();

    const workGroupList: IndentGroupListType[] = groupList.map((grp) => {
      const level = treeLevel?.[grp.id] || 0;
      const indent = '　'.repeat(level * 1);

      return {
        id: grp.id,
        name: `${indent}${grp.name}`,
      };
    });

    setIndentGroupList(workGroupList);
  }, [groupList]);

  return indentGroupList;
};
