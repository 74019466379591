import { PromotionDefaultValues } from 'components/paywall/pages/PromotionList/initial';
import { PromotionFormType } from 'components/paywall/pages/PromotionList/typed';
import { useAddPromotion } from 'hooks/paywall/useAddPromotion';
import { useEditPromotion } from 'hooks/paywall/useEditPromotion';
import { usePromotionPublish } from 'hooks/paywall/usePromotionPublish';
import { useUserTenantId } from 'hooks/user/useUserTenantId';
import { Dispatch, SetStateAction, useCallback, useEffect } from 'react';
import { UseFormGetValues, UseFormSetError } from 'react-hook-form';
import { useParams } from 'react-router-dom';

type Props = {
  getValues?: UseFormGetValues<PromotionFormType>;
  isAdd?: boolean;
  setError?: UseFormSetError<PromotionFormType>;
  onClose?: () => void;
  reset?: () => void;
  setLoading?: Dispatch<SetStateAction<boolean>>;
};

/**
 * ペイウォールプロモーションhooks
 * @param {UseFormGetValues<PromotionFormType>} getValues T型 react query getValues
 * @param {boolean} isAdd 新規作成or編集
 */
export const usePaywallPromotion = ({
  getValues,
  isAdd,
  setError,
  onClose,
  reset,
  setLoading,
}: Props) => {
  const tenantId = useUserTenantId();
  const params = useParams();
  const { onSubmit: addPromotionSubmit, isLoading: isAddLoading } = useAddPromotion({
    setError,
    close: onClose,
    reset,
  });
  const { onSubmit: editPromotionSubmit, isLoading: isEditLoading } = useEditPromotion({
    setError,
    close: onClose,
    reset,
  });
  const { onSubmit: publishSubmit } = usePromotionPublish();

  useEffect(() => {
    setLoading?.(isAddLoading || isEditLoading)
  }, [setLoading, isAddLoading, isEditLoading])

  /**
   * プロモーション新規作成：編集
   * @param {function(): void} onClose close Function
   * @param {function(): void} reset reset Function
   */
  const savePromotion = useCallback(() => {
    setLoading?.(true);
    const fdata: PromotionFormType = {
      ...(getValues?.() || PromotionDefaultValues),
      status: true,
    };
    // 新規作成時
    if (isAdd) {
      addPromotionSubmit({
        tenantId,
        courseId: params.courseId || '',
        promotionForm: fdata,
      });
    } else {
      editPromotionSubmit({
        tenantId,
        courseId: params.courseId || '',
        promotionForm: fdata,
      });
    }
  }, [
    getValues,
    addPromotionSubmit,
    editPromotionSubmit,
    setLoading,
    isAdd,
    tenantId,
    params,
  ]);

  const onDrawerClose = useCallback(() => {
    onClose?.();
    reset?.();
  }, [onClose, reset]);

  /**
   * ステータス変更処理
   * @param {string} id 該当ID
   * @param {boolean} status ステータス
   */
  const onActivate = useCallback(
    (id: string, status: boolean) => {
      publishSubmit({
        id,
        tenantId,
        courseId: params.courseId || '',
        status: !status,
      });
    },
    [publishSubmit, tenantId, params],
  );

  return { savePromotion, onDrawerClose, onActivate };
};
