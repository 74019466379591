import { FC } from 'react';

type Props = {
  color?: string;
  size?: string;
};
export const EditIcon: FC<Props> = ({ color, size }) => (
  <svg
    width={size}
    height={size}
    viewBox="0 0 14 14"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      d="M1.5 12.5H2.5625L10.375 4.6875L9.3125 3.625L1.5 11.4375V12.5ZM0 14V10.8125L10.375 0.4375C10.5278 0.284722 10.6933 0.173611 10.8716 0.104167C11.0499 0.0347222 11.2374 0 11.4341 0C11.6308 0 11.8194 0.0347222 12 0.104167C12.1806 0.173611 12.3472 0.284722 12.5 0.4375L13.5625 1.5C13.7153 1.65278 13.8264 1.81944 13.8958 2C13.9653 2.18056 14 2.36487 14 2.55296C14 2.75357 13.9651 2.94478 13.8954 3.12658C13.8256 3.3084 13.7147 3.47454 13.5625 3.625L3.1875 14H0ZM9.83444 4.16556L9.3125 3.625L10.375 4.6875L9.83444 4.16556Z"
      fill={color || 'currentColor'}
    />
  </svg>
);

EditIcon.defaultProps = {
  size: '14',
};
