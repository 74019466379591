export const queryEnqueteKey = {
  getEnqueteList: ({
    tenantId,
    ordering,
    page,
    openDrawer,
    perPage,
    reserve,
    status,
  }: {
    tenantId: string;
    ordering: string;
    page: string;
    openDrawer: string;
    perPage: string;
    reserve: string;
    status: string;
  }) => [
    'enquete/list',
    tenantId,
    ordering,
    page,
    openDrawer,
    perPage,
    reserve,
    status,
  ],
  getEnqueteId: ({
    tenantId,
    enqueteId,
  }: {
    tenantId: string;
    enqueteId: string;
  }) => ['enquete/getEnqueteId', tenantId, enqueteId],
  getEnqueteChart: ({
    tenantId,
    enqueteId,
    graphId,
  }: {
    tenantId: string;
    enqueteId: string;
    graphId: string;
  }) => ['enquete/getEnqueteChart', tenantId, enqueteId, graphId],
  getSkinList: ({ tenantId, page }: { tenantId: string; page: string }) => [
    'enquete/skin/list',
    tenantId,
    page,
  ],
  getEnqueteSkinId: ({
    tenantId,
    skinId,
  }: {
    tenantId: string;
    skinId: string;
  }) => ['enquete/skin/id', tenantId, skinId],
  getUserAttributes: ({ tenantId }: { tenantId: string }) => [
    'enquete/userAttributes',
    tenantId,
  ],
  getEnqueteContentGroups: ({ tenantId }: { tenantId: string }) => [
    'enquete/enqueteContentGroups',
    tenantId,
  ],
} as const;
