import { customErrorMessage, toZod } from 'admin/config/zod';
import { newsletterSchema } from 'admin/schema/idPoolConsumer/newsletter';
import { ServiceNewsletterChild } from 'admin/types/service/newsletter/index';
import { z } from 'zod';

// zodErrorの日本語化対応
z.setErrorMap(customErrorMessage);

export const serviceNewsletterChildSchema = z.object<
  toZod<ServiceNewsletterChild>
>({
  id: z.string(),
  userPoolChild: z.string(),
  newsletter: z.string(),
  isDeliveryAvailable: z.boolean(),
  isDisplayWhenRegistration: z.boolean(),
  isCheckWhenRegistration: z.boolean(),
});

export const serviceNewsletterSchema = newsletterSchema.extend({
  userPoolChildNewsletter: serviceNewsletterChildSchema.nullable(),
});

export const serviceNewslettersSchema = z.array(serviceNewsletterSchema);

export const serviceNewsletterCreateFormSchema =
  serviceNewsletterChildSchema.omit({
    id: true,
  });
