import { VStack } from '@chakra-ui/react';
import ErrorBoundary from 'api/ErrorBoundary';
import { useErrorBoundaryReset } from 'hooks/useErrorBoundaryReset';
import { useSetPageTitle } from 'hooks/useSetPageTitle';
import { FC, memo } from 'react';
import { FormSettingInner } from './FormSettingInner';

export const FormSetting: FC = memo(() => {
  const { ebKey, onError } = useErrorBoundaryReset();
  useSetPageTitle('フォーム関連設定');

  return (
    <VStack flex={1} alignItems="flex-start">
      <ErrorBoundary onError={onError} key={ebKey.current}>
        <FormSettingInner />
      </ErrorBoundary>
    </VStack>
  );
});
