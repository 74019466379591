import { Modal, ModalOverlay } from '@chakra-ui/react';
import { useEditAuthHub } from 'admin/hooks/authHub/useEditAuthHub';
import { AuthHubsResultType } from 'admin/types/authHub';
import { memo, useCallback } from 'react';
import { FormProvider, useForm } from 'react-hook-form';
import { QueryObserverResult } from 'react-query';
import { NameForm, NameFormType } from './NameForm';

export const EditModal = memo(
  ({
    userPoolId,
    tenantId,
    editTarget,
    setEditTarget,
    refetch,
  }: {
    userPoolId?: string;
    tenantId?: string;
    editTarget?: [string, string];
    setEditTarget: (_?: [string, string]) => void;
    refetch: () => Promise<QueryObserverResult<AuthHubsResultType, unknown>>;
  }) => {
    const onClose = useCallback(() => {
      setEditTarget(undefined);
    }, [setEditTarget]);
    const methods = useForm({
      mode: 'onBlur',
      defaultValues: {
        name: (editTarget && editTarget[1]) ?? '',
      },
    });
    const { reset } = methods;
    const { editMutate } = useEditAuthHub();
    const onSubmit = useCallback(
      async ({ name }: NameFormType) => {
        if ((userPoolId || tenantId) && editTarget) {
          const [id] = editTarget;
          await editMutate({ userPoolId, tenantId, id, name });
          await refetch();
        }
        setEditTarget(undefined);
        reset();
        onClose();
      },
      [
        editMutate,
        userPoolId,
        tenantId,
        editTarget,
        setEditTarget,
        onClose,
        refetch,
        reset,
      ],
    );

    return (
      <Modal isOpen={!!editTarget} size="lg" onClose={onClose} isCentered>
        <ModalOverlay />
        <FormProvider {...methods}>
          <NameForm
            title="設定名編集"
            saveButtonLabel="保存"
            onSubmit={onSubmit}
            onClose={onClose}
          />
        </FormProvider>
      </Modal>
    );
  },
);
