import { getTransactionMailCommon } from 'admin/api/userPool/transactionMail/getTransactionMailCommon';
import { useIdPoolConsumerId } from 'admin/hooks/useIdPoolConsumerId';
import { useCustomToast } from 'hooks/useCustomToast';
import { useQuery } from 'react-query';
import { errorToast } from 'utils/toast';
import { queryTransactionMailKey } from './queryTransactionMailKey';

export const useTransactionMailCommon = () => {
  const idPoolConsumerId = useIdPoolConsumerId();
  const toast = useCustomToast();
  const { data } = useQuery({
    queryKey:
      queryTransactionMailKey.getTransactionMailCommon(idPoolConsumerId),
    queryFn: () => getTransactionMailCommon(idPoolConsumerId),
    onError: () => {
      toast({
        ...errorToast,
        duration: 4000,
        title: 'トランザクションメール共通設定の取得に失敗しました',
        position: 'bottom',
        variant: 'solid',
      });
    },
  });

  return { data };
};
