import { Box, Tr as ChakraTr } from '@chakra-ui/react';
import { MemberAttributeTableBody } from 'admin/components/member/detail/memberAttribute/memberAttributeTableBody';
import { Table, Tbody, Th, Thead } from 'admin/components/ui/table';
import { MemberAttributesType } from 'admin/types/member';
import { FC, memo } from 'react';

type Props = {
  attributes: MemberAttributesType;
};

export const MemberAttributeTableComponent: FC<Props> = memo(
  ({ attributes }: Props) => (
    <Box mt={4}>
      <Table>
        <Thead>
          <ChakraTr>
            <Th p={4} fontSize="14px" maxWidth="120px" minWidth="120px">
              属性ID
            </Th>
            <Th p={4} fontSize="14px" maxWidth="180px" minWidth="180px">
              表示名（日本語）
            </Th>
            <Th p={4} fontSize="14px" maxWidth="180px" minWidth="180px">
              表示名（外国語）
            </Th>
            <Th p={4} fontSize="14px" maxWidth="180px" minWidth="180px">
              フィールド名
            </Th>
            <Th p={4} fontSize="14px" maxWidth="224px" minWidth="224px">
              入力値
            </Th>
          </ChakraTr>
        </Thead>
        <Tbody>
          {attributes.map((attribute) => (
            <MemberAttributeTableBody
              attribute={attribute}
              key={`member_attribute_${String(attribute.id)}`}
            />
          ))}
        </Tbody>
      </Table>
    </Box>
  ),
);
