import { VerifyUser } from 'api/user/types';
import { InviteFormComponent } from 'components/user/molecules/InviteForm/InviteFormComponent';
import { AUTH_CODE_TYPE_SAML } from 'define';
import { FC, memo, useMemo } from 'react';
import { RegisterOptions } from 'react-hook-form';

type Props = {
  createType: VerifyUser['authTypeCode'];
  inviteEmailAddress: VerifyUser['email'];
  password: React.MutableRefObject<unknown>;
}

export const InviteForm: FC<Props> = memo(({createType, inviteEmailAddress, password}: Props) => {
  const isSaml = useMemo<boolean>(() => createType === AUTH_CODE_TYPE_SAML ,[createType]);
  /**
   * 表示名入力用バリデーション
   */
  const nameValidateAttr: RegisterOptions = {
    required: '表示名を入力してください',
  };

  /**
   * パスワード入力用バリデーション
   */
  const passwordValidateAttr: RegisterOptions = {
    required: 'パスワードを入力してください',
    minLength: {
      value: 8,
      message: '8文字以上で入力してください',
    },
    maxLength: {
      value: 32,
      message: '32文字以下で入力してください',
    },
  };

  /**
   * パスワード再入力用バリデーション
   */
  const password2ValidateAttr: RegisterOptions = {
    validate: (val: string) =>
      isSaml || val === password.current || 'パスワードが一致しません',
  };


  return (
    <InviteFormComponent
      isSaml={isSaml}
      inviteEmailAddress={inviteEmailAddress}
      nameValidateAttr={nameValidateAttr}
      passwordValidateAttr={passwordValidateAttr}
      password2ValidateAttr={password2ValidateAttr}
    />
  )
});
