import { customErrorMessage, toZod } from 'admin/config/zod';
import {
  AuthHubType,
  RecaptchaType,
  SecuritySettings,
  TokenType,
} from 'admin/types/userPool/auth';
import { z } from 'zod';
import { isPositiveInteger } from '../util/index';

// zodErrorの日本語化対応
z.setErrorMap(customErrorMessage);

export const securitySettingsSchema = z.object<toZod<SecuritySettings>>({
  serviceId: z.string(),
  authHubDomain: z.string().nullable(),
  authHubDuration: z.number().nullable(),
  jwtDuration: z.number(),
  jwtIssuer: z.string().nullable(),
  accessTokenDuration: z.number(),
  refreshTokenDuration: z.number(),
  recaptchaSiteKey: z.string().nullable(),
  recaptchaSecretKey: z.string().nullable(),
  recaptchaLoginFailureLimit: z.number().nullable(),
});

export const authHubSchema = z.object<toZod<AuthHubType>>({
  domain: z.string().nullable(),
  duration: z.number(),
});
export const submitAuthHubSchema = z.object({
  id: z.string(),
  duration: z.preprocess(
    (val) => (typeof val === 'number' ? String(val) : val),
    z
      .string()
      .refine((val) => val !== '', {
        message: 'セッション有効期限は必須です。',
      })
      .refine((val) => isPositiveInteger(val), {
        message: '正数値のみが許可されます。小数点以下は含められません。',
      }),
  ),
});

export const tokenSchema = z.object<toZod<TokenType>>({
  jwtDuration: z.number(),
  jwtIssuer: z.string().nullable(),
  accessTokenDuration: z.number(),
  refreshTokenDuration: z.number(),
});
export const submitTokenSchema = z.object({
  id: z.string(),
  jwtDuration: z.preprocess(
    (val) => (typeof val === 'number' ? String(val) : val),
    z
      .string()
      .refine((val) => val !== '', {
        message: 'JWT 有効期限は必須です。',
      })
      .refine((val) => isPositiveInteger(val), {
        message: '正数値のみが許可されます。小数点以下は含められません。',
      }),
  ),
  jwtIssuer: z.string().refine((val) => val !== '', {
    message: 'issuerは必須です。',
  }),
  accessTokenDuration: z.preprocess(
    (val) => (typeof val === 'number' ? String(val) : val),
    z
      .string()
      .refine((val) => val !== '', {
        message: 'AccessToken 有効期限は必須です。',
      })
      .refine((val) => isPositiveInteger(val), {
        message: '正数値のみが許可されます。小数点以下は含められません。',
      }),
  ),
  refreshTokenDuration: z.preprocess(
    (val) => (typeof val === 'number' ? String(val) : val),
    z
      .string()
      .refine((val) => val !== '', {
        message: 'RefreshToken 有効期限は必須です。',
      })
      .refine((val) => isPositiveInteger(val), {
        message: '正数値のみが許可されます。小数点以下は含められません。',
      }),
  ),
});

export const recaptchaSchema = z.object<toZod<RecaptchaType>>({
  siteKey: z.string().nullable(),
  secretKey: z.string().nullable(),
  loginFailureLimit: z.number().nullable(),
});
export const submitRecaptchaSchema = z.object({
  id: z.string(),
  siteKey: z.string().refine((val) => val !== '', {
    message: 'サイトキーは必須です。',
  }),
  secretKey: z.string().refine((val) => val !== '', {
    message: 'シークレットキーは必須です。',
  }),
  loginFailureLimit: z.preprocess(
    (val) => (typeof val === 'number' ? String(val) : val),
    z
      .string()
      .refine((val) => val !== '', {
        message: 'ログイン試行回数は必須です。',
      })
      .refine((val) => isPositiveInteger(val), {
        message: '正数値のみが許可されます。小数点以下は含められません。',
      }),
  ),
});
