import { Box, Flex, Tbody, Text, Tooltip } from '@chakra-ui/react';
import { LoadingSkeleton } from 'components/common/atoms';
import { Button } from 'components/paywall/atoms/Button';
import { Link } from 'components/paywall/atoms/Link';
import { Colors } from 'components/paywall/config';
import { Pagination, Table, Thead } from 'components/paywall/features/Table';
import { usePaywall } from 'components/paywall/hooks/usePaywall';
import { usePaywallUtil } from 'components/paywall/hooks/usePaywallUtil';
import { CustomErrorBoundary } from 'components/paywall/molecules/CustomErrorBoundary';
import { WallDesignColumns } from 'components/paywall/pages/WallDesignList/WallDesignColums';
import { WallDesignRows } from 'components/paywall/pages/WallDesignList/WallDesignRows';
import { Container } from 'components/paywall/templates/Container';
import { FC, memo, Suspense } from 'react';

export const WallDesignList: FC = memo(() => {
  const { paywallMenuName, wallName, wallId, parentPath, currentWallType } =
    usePaywall({});
  const { formatText } = usePaywallUtil();
  const { isOperationAuth } = usePaywallUtil();

  return (
    <Container
      title={
        <Flex flexWrap="wrap">
          <Link to={parentPath}>
            <Flex color={Colors.GRAY_20}>
              <Text cursor="pointer">
                {formatText({ text: paywallMenuName }).trimText}
              </Text>
            </Flex>
          </Link>
          <Text as="span" color={Colors.BLACK} fontWeight="700">
            &nbsp;/&nbsp;
          </Text>
          <Flex>
            <Tooltip
              label={formatText({ text: wallName, trimCnt: 10 }).originText}
              bg="gray.100"
              color="black"
              isDisabled={
                formatText({ text: wallName, trimCnt: 10 }).isDisabled
              }
            >
              <Text>
                {formatText({ text: wallName, trimCnt: 10 }).trimText} -
                画面設定
              </Text>
            </Tooltip>
          </Flex>
        </Flex>
      }
      label={
        <Button
          colorScheme="blue"
          to={
            wallId
              ? `/paywall/${wallId}/WallEditor/form?type=${currentWallType}`
              : ''
          }
          disabled={!wallId || !isOperationAuth()}
        >
          新規作成
        </Button>
      }
    >
      <Box pt="40px">
        <Table>
          <Thead>
            <WallDesignColumns />
          </Thead>
          <Tbody>
            <CustomErrorBoundary pageTitle="画面一覧">
              <Suspense
                fallback={
                  <tr>
                    <td colSpan={4}>
                      <LoadingSkeleton />
                    </td>
                  </tr>
                }
              >
                <WallDesignRows />
              </Suspense>
            </CustomErrorBoundary>
          </Tbody>
        </Table>
      </Box>
      <Box pt="4px">
        <Pagination />
      </Box>
    </Container>
  );
});
