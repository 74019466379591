import {
  DrawerCloseButton,
  DrawerContent,
  DrawerHeader,
  DrawerOverlay
} from '@chakra-ui/react';
import ErrorBoundary from 'api/ErrorBoundary';
import { LoadingSpinner } from 'components/common/atoms';
import { ListError } from 'components/common/molecules';
import { LoginRedirectForm } from 'components/setting/loginRedirect/loginRedirectForm';
import { useLoginSetting } from 'hooks/tenant/useLoginSetting';
import { useErrorBoundaryReset } from 'hooks/useErrorBoundaryReset';
import { useUserTenantId } from 'hooks/user/useUserTenantId';
import { FC, memo, Suspense } from 'react';

type Props = {
  onClose: () => void;
}

const FetchLoginRedirectDrawer: FC<Props> = memo(({onClose}: Props) => {
  const tenantId = useUserTenantId();
  const loginSettingResponse = useLoginSetting({ tenantId });

  if (!loginSettingResponse) {
    return null;
  }

  return (
    <LoginRedirectForm loginSetting={loginSettingResponse} onClose={onClose} />
  )
})

export const LoginRedirectDrawer: FC<Props> = memo(({onClose}: Props) => {
  const { ebKey, onError } = useErrorBoundaryReset();

  return (
    <>
      <DrawerOverlay />
      <DrawerContent>
        <DrawerHeader borderBottomWidth="1px">ログインリダイレクト設定</DrawerHeader>
        <ErrorBoundary
          errorComponent={<ListError message="ログインリダイレクト設定を表示できません" />}
          onError={onError}
          key={ebKey.current}
        >
          <Suspense fallback={<LoadingSpinner width="100%" />}>
          <FetchLoginRedirectDrawer onClose={onClose} />
          </Suspense>
        </ErrorBoundary>
        <DrawerCloseButton />
      </DrawerContent>
    </>
  )
});
