import produce from 'immer';
import { Options } from 'ky';
import { request } from 'api/request';

/**
 * コンテンツシェルフファイルアップロードAPI
 *
 * 以下のレスポンスを返却します
 * 正常時: true, 異常時: false
 *
 */
export const fileUpload = async ({
  file,
  uploadId,
  tenantId,
  tableId,
  options,
}: {
  file: File;
  uploadId: number;
  tenantId: string;
  tableId: string;
  options?: Options;
}): Promise<boolean> => {
  const formData = new FormData();
  formData.append('file', file);
  const timeout = 1000 * 60 * 3;

  const mergedOptions = options
    ? produce(options, (draft) => {
        draft.body = formData;
        draft.timeout = timeout;
      })
    : { body: formData, timeout };

  const response = await request({
    path: `contentsdb/${tenantId}/${tableId}/upload/${uploadId}/`,
    method: 'put',
    options: mergedOptions,
  });

  return !response.hasError;
};
