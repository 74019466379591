import { AttributeGroupTableBodyComponent } from 'admin/components/service/attribute/attributeGroupTableBody/AttributeGroupTableBodyComponent';
import {
  ServiceAttributeGroupType,
  UserPoolChildAttributeType,
} from 'admin/types/service/attribute';
import { FC, memo } from 'react';

type DialogHandler = (
  id: ServiceAttributeGroupType['id'],
  userPoolChildAttributeId: UserPoolChildAttributeType['id'],
) => void;

export type AttributeGroupTableBodyProps = {
  attributeGroup: ServiceAttributeGroupType;
  onOpen: DialogHandler;
  onOpenFormAttrDialog: DialogHandler;
  onOpenChildAttrDialog: DialogHandler;
  onOpenUserAttrDialog: DialogHandler;
  onOpenUserAttrRequiredDialog: DialogHandler;
  hasUniikey: boolean;
};

export const AttributeGroupTableBody: FC<AttributeGroupTableBodyProps> = memo(
  (props: AttributeGroupTableBodyProps) => (
    <AttributeGroupTableBodyComponent {...props} />
  ),
);
