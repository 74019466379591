import { editNewsletter } from 'admin/api/userPool/newsletter/editNewsletter';
import { CustomError } from 'admin/error/CustomError';
import { queryNewsletterKeyNames as queryServiceNewsletterKeyNames } from 'admin/hooks/service/newsletter/queryNewsletterKey';
import {
  queryNewsletterKey,
  queryNewsletterKeyNames,
} from 'admin/hooks/userPool/newsletter/queryNewsletterKey';
import { IdPoolConsumerAttributeType } from 'admin/types/userPool/attribute';
import { NewsletterFormType } from 'admin/types/userPool/newsletter/form';
import { NewsletterType } from 'admin/types/userPool/newsletter/index';
import { logger } from 'api/logger';
import { useCustomToast } from 'hooks/useCustomToast';
import { Dispatch, SetStateAction } from 'react';
import { UseFormSetError } from 'react-hook-form';
import { useMutation, useQueryClient } from 'react-query';
import { toMultiError } from 'utils/form';
import { errorToast, successToast } from 'utils/toast';

export const useEditNewsletter = ({
  id,
  userPoolId,
  setError,
  setGlobalErrors,
}: {
  id: NewsletterType['id'];
  userPoolId: IdPoolConsumerAttributeType['userPool'];
  setError: UseFormSetError<NewsletterFormType>;
  setGlobalErrors: Dispatch<SetStateAction<string[]>>;
}) => {
  const toast = useCustomToast();
  const queryClient = useQueryClient();

  const { mutateAsync, isLoading } = useMutation({
    mutationFn: (data: NewsletterFormType) =>
      editNewsletter({ data, id, userPoolId }),
    onSuccess: () => {
      toast({
        ...successToast,
        duration: 4000,
        title: 'メールマガジン購読設定を更新しました',
        position: 'bottom',
        variant: 'solid',
      });
      void queryClient.invalidateQueries(
        queryNewsletterKey.getMailMagazineDetail(id),
      );
      void queryClient.invalidateQueries(queryNewsletterKeyNames.Newsletter);
      void queryClient.invalidateQueries(
        queryServiceNewsletterKeyNames.ServiceNewsletter,
      );
    },
    onError: async (error, variables: NewsletterFormType) => {
      if (error instanceof CustomError) {
        if (error.cause.errors?.others) {
          const othersMsgs = Array.isArray(error.cause.errors?.others)
            ? error.cause.errors?.others
            : [error.cause.errors?.others];

          setGlobalErrors(othersMsgs);
        } else {
          Object.keys(variables).forEach((k) => {
            const key = k as keyof NewsletterFormType;
            const errMsgs = error.cause.errors?.[key];
            if (errMsgs && Array.isArray(errMsgs)) {
              setError(key, { types: toMultiError(errMsgs) });
            }
          });
        }

        if (error.cause.errors) {
          await logger({
            loglevel: error.logLevel,
            data: JSON.stringify(error.cause),
            message: error.message,
          });
        }
      }
      toast({
        ...errorToast,
        duration: 4000,
        title: 'メールマガジン購読設定の更新に失敗しました',
        position: 'bottom',
        variant: 'solid',
      });
    },
  });

  return { mutate: mutateAsync, isLoading };
};
