import { Box, ChakraProvider, Flex, VStack } from '@chakra-ui/react';
import { Header } from 'admin/components/header';
import { SideMenu } from 'admin/components/sideMenu';
import { CustomContainerBox } from 'admin/templates/CustomContainerBox';
import { CustomContainerInnerBox } from 'admin/templates/CustomContainerInnerBox';
import { CustomHeader } from 'admin/templates/CustomHeader';
import theme from 'admin/theme/adminTheme';
import { CustomMenuBox } from 'components/chakra/sidemenu';
import { NotificationBar } from 'components/common/atoms';
import { FC } from 'react';
import { Helmet } from 'react-helmet-async';
import { Outlet } from 'react-router-dom';
import { useRecoilState, useRecoilValue } from 'recoil';
import { adminSidebarState } from 'store/adminSidebarState';
import { pageTitleState } from 'store/pageTitleState';

const DEPLOY_MODE = process.env.REACT_APP_DEPLOY_MODE;

/**
 * Adminページ用Template
 */
export const Template: FC = () => {
  const [isOpen] = useRecoilState(adminSidebarState);
  const title = useRecoilValue(pageTitleState);

  return (
    <ChakraProvider theme={theme}>
      <Helmet>
        <title>{title}</title>
      </Helmet>
      <VStack spacing={0}>
        {DEPLOY_MODE !== 'prd' && <NotificationBar />}
        <CustomHeader>
          <Header />
        </CustomHeader>
        <Box width="full" flexGrow={1}>
          <Flex position="relative">
            <CustomMenuBox isOpen={isOpen}>
              <SideMenu />
            </CustomMenuBox>
            <CustomContainerBox isActive={isOpen}>
              <CustomContainerInnerBox isActive={isOpen}>
                <Outlet />
              </CustomContainerInnerBox>
            </CustomContainerBox>
          </Flex>
        </Box>
      </VStack>
    </ChakraProvider>
  );
};
