import { HStack } from '@chakra-ui/react';
import { FC, memo } from 'react';

type Props = {
  children: React.ReactNode;
}

export const CardHeaderButtons: FC<Props> = memo(({children}: Props) => (
  <HStack spacing='8px'>{children}</HStack>
))
