import { request } from 'admin/api/Request';
import { API_MESSAGES } from 'admin/define/apiMessages';
import { CustomError } from 'admin/error/CustomError';
import { TransactionMailSchema } from 'admin/schema/idPoolConsumer/transactionMail';
import { TransactionMailFormType } from 'admin/types/userPool/transactionMail';
import { camelCase } from 'utils/str';
import { ZodError } from 'zod';

export const getTransactionMail = async (
  idPoolConsumerId: string,
  id: TransactionMailFormType['id'],
): Promise<TransactionMailFormType> => {
  const path = `uniikey/${idPoolConsumerId}/transaction_mails/${id}/`;
  const response = await request<TransactionMailFormType>({
    path,
    method: 'get',
  });

  if (response.hasError) {
    if (response.status === 404) {
      throw new CustomError(
        API_MESSAGES.USER_POOL.GET_TRANSACTION_MAIL.NOT_FOUND,
        {
          path,
          status: response.status,
        },
      );
    } else {
      throw new CustomError(API_MESSAGES.USER_POOL.GET_TRANSACTION_MAIL.ERROR, {
        path,
        status: response.status,
      });
    }
  }

  // メール情報に含まれるtypeのデータを修正する
  const transactionMailData = {
    ...response.data,
    type: camelCase(response.data.type),
  } as TransactionMailFormType;

  try {
    TransactionMailSchema.parse(transactionMailData);
  } catch (error) {
    if (error instanceof ZodError) {
      throw new CustomError(error.message, {
        path,
        status: response.status,
        logLevel: 'error',
      });
    }
  }

  return transactionMailData;
};
