import { request } from 'admin/api/Request';
import { API_MESSAGES } from 'admin/define/apiMessages';
import { CustomError } from 'admin/error/CustomError';
import { membersSchema } from 'admin/schema/member';
import { convertToPage, pageSchema } from 'admin/schema/page';
import { MembersType } from 'admin/types/member';
import { UserPool } from 'admin/types/userPool';
import { DataWithPage } from 'api/common/types';
import { Options } from 'ky';
import { ZodError } from 'zod';

export const getMembers = async ({
  userPoolId,
  // TODO: 検索機能実装時にeslintコメントを削除する
  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  keyword,
  options,
}: {
  userPoolId: UserPool['id'];
  keyword?: string;
  options?: Options;
}): Promise<DataWithPage<MembersType>> => {
  const path = `uniikey/${userPoolId}/users/`;
  const response = await request<DataWithPage<MembersType>>({
    path,
    method: 'get',
    options,
  });

  if (response.hasError) {
    if (response.status === 404) {
      throw new CustomError(API_MESSAGES.MEMBER.GET_MEMBERS.NOT_FOUND, {
        path,
        status: response.status,
      });
    } else {
      throw new CustomError(API_MESSAGES.MEMBER.GET_MEMBERS.ERROR, {
        path,
        status: response.status,
      });
    }
  }

  try {
    membersSchema.parse(response.data.results);
    pageSchema.parse(convertToPage<DataWithPage<MembersType>>(response.data));
  } catch (error) {
    if (error instanceof ZodError) {
      throw new CustomError(error.message, {
        path,
        status: response.status,
        logLevel: 'error',
      });
    }
  }

  return response.data;
};
