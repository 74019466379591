import {
  Box,
  Drawer,
  DrawerCloseButton,
  DrawerContent,
  DrawerHeader,
  DrawerOverlay,
  Text,
} from '@chakra-ui/react';
import { Colors } from 'admin/define/colors';
import { FC, memo } from 'react';
import { AuthHubEditForm } from '../authForm/authHubEditForm';

type AuthHubDrawerProps = {
  onClose: () => void;
  formId: string;
  isOpen: boolean;
};

export const AuthHubDrawer: FC<AuthHubDrawerProps> = memo(
  ({ onClose, formId, isOpen }) => (
    <Drawer
      isOpen={isOpen}
      placement="right"
      onClose={onClose}
      size="md"
      closeOnOverlayClick={false}
      closeOnEsc={false}
      autoFocus={false}
    >
      <DrawerOverlay />
      <DrawerContent>
        <DrawerHeader pb={0}>
          <Box
            m={0}
            px={0}
            pb="24px"
            borderBottomWidth="1px"
            color={Colors.GRAY_03}
          >
            <Text>Auth Hub設定</Text>
          </Box>
        </DrawerHeader>
        <AuthHubEditForm formId={formId} onClose={onClose} />
        <DrawerCloseButton top="16px" right="24px" />
      </DrawerContent>
    </Drawer>
  ),
);
