import { Text } from '@chakra-ui/react';
import styled from '@emotion/styled';
import { useAmountUtil } from 'hooks/useAmountUtil';
import { FC } from 'react';
import { FieldValues, Path, useFormContext } from 'react-hook-form';

const StyledAmmountTaxed = styled(Text)({
  fontSize: '20px',
  fontWeight: 'bold',
});
const StyledAmmountPrefix = styled(Text)({
  fontSize: '1rem',
  fontWeight: 'normal',
});

type Props<T> = {
  name: Path<T>;
};

/**
 * 税込み価格表示用エレメント
 * @param name T型のreact hook form キー
 * @returns JSX
 */
export const AmmountWithTax = <T extends FieldValues>({
  name,
}: Props<T>): ReturnType<FC> => {
  const { calcTaxAmount } = useAmountUtil();
  const { watch } = useFormContext<T>();

  return (
    <>
      <Text as="span">税込価格&nbsp;</Text>
      <StyledAmmountTaxed as="span">
        {calcTaxAmount(watch(name) || 0)}
      </StyledAmmountTaxed>
      <StyledAmmountPrefix as="span">円</StyledAmmountPrefix>
    </>
  );
};
