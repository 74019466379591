/**
 * カスタムツール作成
 */
export const useCustomTool = (): {
  createCustomTool: (name: string, label: string, icon: string) => string;
} => {

  const createCustomTool = (name: string, label: string, icon: string) => `
    name: '${name}',
    label: '${label}',
    icon: '${icon}',
    supportedDisplayModes: ['web', 'email'],
  `;

  return {createCustomTool};
}
