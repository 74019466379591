import { useUserPool } from 'admin/hooks/userPool/useUserPool';
import {
  AUTH_CODE_TYPE_GIDP,
  AUTH_CODE_TYPE_OUTER,
  AUTH_CODE_TYPE_SAML,
  AUTH_CODE_TYPE_UNIIKEY,
} from 'define';
import { useMemo } from 'react';
import { useParams } from 'react-router-dom';

export const useUserPoolAuthenticationType = (): {
  isUniikey: boolean;
  isOuter: boolean;
  isSaml: boolean;
  isGidp: boolean;
} => {
  const params = useParams();
  const { data: idPoolConsumer } = useUserPool(params.idPoolConsumerId ?? '');

  const isUniikey = useMemo(
    () => idPoolConsumer?.authenticationType === AUTH_CODE_TYPE_UNIIKEY,
    [idPoolConsumer],
  );

  const isOuter = useMemo(
    () => idPoolConsumer?.authenticationType === AUTH_CODE_TYPE_OUTER,
    [idPoolConsumer],
  );

  const isSaml = useMemo(
    () => idPoolConsumer?.authenticationType === AUTH_CODE_TYPE_SAML,
    [idPoolConsumer],
  );

  const isGidp = useMemo(
    () => idPoolConsumer?.authenticationType === AUTH_CODE_TYPE_GIDP,
    [idPoolConsumer],
  );

  return {
    isUniikey,
    isOuter,
    isSaml,
    isGidp,
  };
};
