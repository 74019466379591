export const messages = {
  // ユーザー取得
  getUser: {
    customer: {
      notFound: '存在しないユーザーです。既に削除された可能性があります。',
      error:
        '申し訳ございません。ユーザー情報取得できません。時間を置いて再表示をお願いします。',
      typeError: '申し訳ありません。正しいユーザー情報を得ることができません。',
    },
    system: {
      typeError:
        '[api.user.getUser] ユーザー取得APIのレスポンス型が予期せぬデータ型です。フロントの修正をしてください',
    },
  },
  // 招待ユーザー登録
  createUser: {
    customer: {
      error: 'アカウント登録できませんでした。',
      failed:
        'アカウント登録が失敗しました。恐れ入りますが時間をおいて再度登録してください。',
    },
    system: {
      typeError:
        '[api.createUser] ユーザー招待からの登録するAPIのエラー型が予期せぬデータ型です。フロントの修正をしてください',
    },
  },
  // 招待コードチェック
  getInvitation: {
    customer: {
      error: '招待コードが正しくありません。メールのリンクをご確認ください。',
      expired: '有効期限切れです。恐れ入りますが再度招待を受けてください。',
    },
    system: {
      error:
        '[api.user.getInvitation] 招待コードチェックで予期せぬエラーが発生しました。',
    },
  },
  // テナントユーザー招待
  postInvite: {
    customer: {
      error:
        '申し訳ございません。ユーザー招待に失敗しました。時間を置いて再表示をお願いします。',
      failed: '申し訳ありません。ユーザー招待に失敗しました。',
    },
    system: {
      typeError:
        '[api.user.postInvite] ユーザー招待APIのエラー型が予期せぬデータ型です。フロントの修正をしてください',
    },
  },
  // 招待法人ユーザー登録
  createCorporationUser: {
    customer: {
      error: 'アカウント登録できませんでした。',
      failed:
        'アカウント登録が失敗しました。恐れ入りますが時間をおいて再度登録してください。',
    },
    system: {
      typeError:
        '[api.createCorporationUser] ユーザー招待からの登録するAPIのエラー型が予期せぬデータ型です。フロントの修正をしてください',
    },
  },
  // 招待コードチェック(法人)
  getInviteCheckCorporation: {
    customer: {
      error: '招待コードが正しくありません。メールのリンクをご確認ください。',
      expired: '有効期限切れです。恐れ入りますが再度招待を受けてください。',
    },
    system: {
      error:
        '[api.user.getInviteCheckCorporation] 招待コードチェックで予期せぬエラーが発生しました。',
    },
  },
  // テナントのユーザー一覧取得
  getTenantUserList: {
    customer: {
      error:
        '申し訳ございません。ユーザー一覧情報取得できません。時間を置いて再表示をお願いします。',
      failed:
        '申し訳ありません。正しいユーザー一覧情報を得ることができません。',
    },
    system: {
      typeError:
        '[api.user.getTenantUserList] テナントのユーザー一覧取得APIのレスポンス型が予期せぬデータ型です。フロントの修正をしてください',
    },
  },
  // テナントのユーザー詳細取得
  getTenantUser: {
    customer: {
      notFound: 'ユーザーが存在しません。既に削除された可能性があります。',
      error:
        '申し訳ございません。ユーザー情報取得できません。時間を置いて再表示をお願いします。',
      failed: '申し訳ありません。正しいユーザー情報を得ることができません。',
    },
    system: {
      typeError:
        '[api.user.getTenantUser] テナントのユーザー取得APIのレスポンス型が予期せぬデータ型です。フロントの修正をしてください',
    },
  },
  // ユーザーテナント設定変更
  putUserTenantEdit: {
    customer: {
      error: '申し訳ございません。ユーザー変更登録に失敗しました。',
      failed:
        'ユーザー変更登録に失敗しました。恐れ入りますが時間をおいて再度登録してください。',
      notFound:
        'ユーザーが存在しないか見つからない為、変更登録に失敗しました。恐れ入りますが時間をおいて再度登録してください。',
    },
    system: {
      typeError:
        '[api.user.putUserTenantEdit] ユーザーテナント設定変更APIのレスポンス型が予期せぬデータ型です。フロントの修正をしてください',
    },
  },
  // ユーザーテナント設定変更
  putUserTenantDelete: {
    customer: {
      error: '申し訳ございません。ユーザー削除に失敗しました。',
      failed:
        'ユーザー削除に失敗しました。恐れ入りますが時間をおいて再度登録してください。',
    },
    system: {
      typeError:
        '[api.user.putUserTenantDelete] テナントのユーザー削除のレスポンス型が予期せぬデータ型です。フロントの修正をしてください',
    },
  },
  // パスワードリセット依頼
  postPasswordReset: {
    customer: {
      error: '申し訳ございません。パスワードリセットメール送信に失敗しました。',
      failed:
        'パスワードリセットメール送信に失敗しました。恐れ入りますが時間をおいて再度登録してください。',
    },
    system: {
      typeError: '[api.user.postPasswordReset] 予期せぬエラーが発生しました。',
    },
  },
  // パスワードリセット処理
  putPasswordReset: {
    customer: {
      error: '申し訳ございません。パスワードリセットに失敗しました。',
      failed:
        'パスワードリセットに失敗しました。恐れ入りますが時間をおいて再度登録してください。',
    },
    system: {
      typeError: '[api.user.putPasswordReset] 予期せぬエラーが発生しました。',
    },
  },
  // パスワード再登録処理
  postPasswordResetForget: {
    customer: {
      error: '申し訳ございません。パスワード再登録に失敗しました。',
      failed:
        'パスワード再登録に失敗しました。恐れ入りますが時間をおいて再度登録してください。',
    },
    system: {
      typeError:
        '[api.user.postUserPasswordForget] 予期せぬエラーが発生しました。',
    },
  },
  // グループにユーザーを登録
  postUserGroup: {
    customer: {
      error: '申し訳ございません。ユーザーの追加に失敗しました。',
      failed:
        'ユーザーの追加に失敗しました。恐れ入りますが時間をおいて再度登録してください。',
    },
    system: {
      typeError: '[api.user.postUserGroup] 予期せぬエラーが発生しました。',
    },
  },
  // ユーザー情報変更処理
  putUser: {
    customer: {
      error: '申し訳ございません。ユーザーの変更に失敗しました。',
      failed:
        'ユーザーの変更に失敗しました。恐れ入りますが時間をおいて再度登録してください。',
    },
    system: {
      typeError: '[api.user.putUser] 予期せぬエラーが発生しました。',
    },
  },
  // パスワード変更処理
  putPassword: {
    customer: {
      error: '申し訳ございません。パスワードの変更に失敗しました。',
      failed:
        'パスワードの変更に失敗しました。恐れ入りますが時間をおいて再度登録してください。',
    },
    system: {
      typeError: '[api.user.putPassword] 予期せぬエラーが発生しました。',
    },
  },
  // 認証タイプ判定
  postAuthType: {
    customer: {
      error:
        '申し訳ございません。ログイン認証に失敗しました。時間を置いて再表示をお願いします。',
      failed: '申し訳ありません。ログイン認証に失敗しました。',
    },
    system: {
      typeError:
        '[api.user.postAuthType] 認証タイプ判定APIのエラー型が予期せぬデータ型です。フロントの修正をしてください',
    },
  },
  // Samlログイン
  loginWithSaml: {
    customer: {
      error:
        '申し訳ございません。ログインに失敗しました。時間を置いて再表示をお願いします。',
      failed: '申し訳ありません。ログインに失敗しました。',
    },
    system: {
      typeError:
        '[api.user.postAuthType] SamlログインAPIのエラー型が予期せぬデータ型です。フロントの修正をしてください',
    },
  },
};
