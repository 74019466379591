import { useDisclosure } from '@chakra-ui/react';
import { zodResolver } from '@hookform/resolvers/zod';
import { withSuspenseAndErrorBoundary } from 'admin/components/Error/WithErrorBoundary';
import { SECURITY_SETTING_AUTH_HUB } from 'admin/define/idPoolConsumer/auth';
import { useIdPoolConsumerId } from 'admin/hooks/useIdPoolConsumerId';
import { useAuthHub } from 'admin/hooks/userPool/auth/useAuthHub';
import { useUpdateSecuritySettingsExcludeToken } from 'admin/hooks/userPool/auth/useUpdateSecuritySettingsExcludeToken';
import { submitAuthHubSchema } from 'admin/schema/idPoolConsumer/auth';
import {
  AuthHubFormType,
  SecuritySettingsFormType,
} from 'admin/types/userPool/auth/form';
import { FC, memo, useCallback, useState } from 'react';
import { FormProvider, useForm } from 'react-hook-form';
import { ConfirmModal } from '../../confirmModal';
import { AuthHubEditFormComponent } from './AuthHubEditFormComponent';

type Props = {
  onClose: () => void;
  formId: string;
};

const FetchAuthHubEditForm: FC<Props> = memo(({ onClose, formId }: Props) => {
  const idPoolConsumerId = useIdPoolConsumerId();
  const authHub = useAuthHub();
  const [modalPromise, setModalPromise] = useState<() => void>(() => null);
  const {
    isOpen: isModalOpen,
    onClose: onModalClose,
    onOpen: onModalOpen,
  } = useDisclosure();
  const { mutate, isLoading } = useUpdateSecuritySettingsExcludeToken(
    SECURITY_SETTING_AUTH_HUB,
  );

  const methods = useForm<AuthHubFormType>({
    defaultValues: {
      id: idPoolConsumerId,
      duration: authHub?.duration,
    },
    resolver: zodResolver(submitAuthHubSchema),
  });

  // モーダルの実行ボタンを押した時
  const onSubmitModal = useCallback(
    async (data: AuthHubFormType) => {
      if (isLoading) return;

      const submitData: SecuritySettingsFormType = {
        id: data.id,
        authHubDuration: data.duration,
      };
      const promise = new Promise<void>((resolve) => {
        setModalPromise(() => resolve);
      });

      onModalOpen();
      await promise;
      onModalClose();
      await mutate(submitData);
      onClose();
    },
    [isLoading, onModalOpen, onModalClose, mutate, onClose],
  );

  return (
    <FormProvider {...methods}>
      <AuthHubEditFormComponent
        formId={formId}
        isLoading={isLoading}
        onSubmit={onSubmitModal}
        onClose={onClose}
      />
      <ConfirmModal
        type={SECURITY_SETTING_AUTH_HUB}
        isOpen={isModalOpen}
        onClose={onModalClose}
        onSubmit={modalPromise}
      />
    </FormProvider>
  );
});

export const AuthHubEditForm =
  withSuspenseAndErrorBoundary(FetchAuthHubEditForm);
