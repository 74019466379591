import { Box, Text, Textarea } from '@chakra-ui/react';
import { Colors } from 'admin/define/colors';
import { UserPool } from 'admin/types/userPool';
import { FC, memo, useMemo } from 'react';

type Props = {
  userInfoDetail: UserPool | undefined;
};

export const UserInfoComponent: FC<Props> = memo(({ userInfoDetail }: Props) => {
  const userInfoResponse = useMemo(() => (userInfoDetail?.userInfoResponse || ''), [userInfoDetail?.userInfoResponse]);

  return (
    <Box>
      {!userInfoDetail?.userInfoResponse ? (
        <Box
          p="40px"
          border="1px solid"
          borderColor={Colors.GRAY_30}
          bgColor={Colors.WHITE}
          borderRadius="8px"
        >
          <Box textAlign="center">
            <Text
              fontWeight="700"
              fontSize="24px"
              lineHeight="170%"
              fontFamily="Hiragino Sans"
            >
              外部IDシステムJSON設定が未設定です。
            </Text>
          </Box>
        </Box>
      ) : (
        <Box w="100%">
          <Textarea
            value={userInfoResponse}
            borderColor={Colors.GRAY_30}
            bgColor={Colors.GRAY_50}
            color="#7E8C9A"
            size="sm"
            resize="none"
            borderRadius="md"
            rows={20}
            readOnly
            _focus={{
              borderColor: Colors.GRAY_30
            }}
          />
        </Box>
      )}
    </Box>
  )
});
