import { withSuspenseAndErrorBoundary } from 'admin/components/Error/WithErrorBoundary';
import { SamlSettingListComponent } from 'admin/components/saml/samlSettingList/SamlSettingListComponent';
import { useSamlSetting } from 'admin/hooks/saml/useSamlSetting';
import { ErrorContents, LoadingSpinner } from 'components/common/atoms';
import { FC, memo } from 'react';

const FetchSamlSettingList: FC = memo(() => {
  const { data } = useSamlSetting();

  if (!data) {
    return null;
  }

  return <SamlSettingListComponent samlSetting={data} />;
});

export const SamlSettingList = withSuspenseAndErrorBoundary(
  FetchSamlSettingList,
  {
    ErrorComponent: <ErrorContents name="SAML設定" />,
    LoadingComponent: <LoadingSpinner />,
  },
);
