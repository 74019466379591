import { VFC, memo, useState, useEffect } from 'react';
import { useFormContext } from 'react-hook-form';
import { Box, Flex, Text, Stack } from '@chakra-ui/react';
import { InputTextForm, ColorPicker } from 'components/common/atoms';
import { SkinItemPreview } from 'components/enquete/atoms/SkinItemPreview';
import { Skin } from 'api/enquete/types';
import { SkinDefaultValues } from 'components/enquete/EnqueteSkin';

export const SkinErrorText: VFC = memo(() => {
  const [errorTextColor, setErrorTextColor] = useState(
    SkinDefaultValues.errorTextColor,
  );
  const { getValues, watch } = useFormContext<Skin>();

  useEffect(() => {
    const skinViewData = watch((value, { name }) => {
      if (name === 'errorTextColor') {
        setErrorTextColor(value[name] || '');
      }
    });

    return () => skinViewData.unsubscribe();
  }, [watch]);

  return (
    <Flex alignItems="stretch" mb={4} mt={4}>
      <Box minW="220px" mt="2px">
        <Text as="label">エラーテキスト</Text>
      </Box>
      <Stack minW="250px">
        <Flex alignItems="flex-end">
          <InputTextForm<Skin>
            label="文字色"
            labelCaption="文字色"
            name="errorTextColor"
            value={getValues('errorTextColor')}
            placeholder="#FFFFFF"
            mr={4}
          />
          <ColorPicker<Skin>
            name="errorTextColor"
            value={getValues('errorTextColor') || '#FFFFFF'}
            width="40px"
            height="40px"
          />
        </Flex>
      </Stack>
      <SkinItemPreview
        element={
          <Text fontSize="14px" color={errorTextColor}>
            エラーテキスト
          </Text>
        }
        width="auto"
      />
    </Flex>
  );
});
