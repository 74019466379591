import { VFC, memo, useCallback, useRef } from 'react';
import { useNavigate } from 'react-router-dom';
import {
  Text,
  Box,
  Button,
  Flex,
  AlertDialog,
  AlertDialogOverlay,
  AlertDialogContent,
  AlertDialogHeader,
  AlertDialogBody,
  AlertDialogFooter,
} from '@chakra-ui/react';
import { InfoOutlineIcon } from '@chakra-ui/icons';

type MailSubmitDialogProps = {
  title: string;
  warningMsgs: {
    filename: string;
    placeholders: string;
    shortagePlaceholders: string;
    excessPlaceholders: string;
  };
  isOpen: boolean;
  next: string;
  onClose: () => void;
};

export const MailSubmitDialog: VFC<MailSubmitDialogProps> = memo(
  ({ title, warningMsgs, isOpen, next, onClose }: MailSubmitDialogProps) => {
    const navigate = useNavigate();
    const cancelRef = useRef<HTMLInputElement>(null);
    // ポップアップ クローズ処理
    const onCloseDialog = useCallback(() => {
      onClose();
      navigate(next);
    }, [onClose, navigate, next]);

    return (
      <AlertDialog
        isOpen={isOpen}
        onClose={onClose}
        leastDestructiveRef={cancelRef}
        closeOnOverlayClick={false}
      >
        <AlertDialogOverlay>
          <AlertDialogContent>
            <AlertDialogHeader
              fontSize="lg"
              fontWeight="bold"
              backgroundColor="yellow.400"
              borderRadius="0.375rem 0.375rem 0rem 0rem"
              color="white"
              letterSpacing="0.1em"
            >
              <Flex alignItems="center">
                <InfoOutlineIcon />
                <Text ml={2}>{title}</Text>
              </Flex>
            </AlertDialogHeader>

            <AlertDialogBody py={4}>
              {warningMsgs.placeholders && (
                <Box mt={4}>
                  <Text fontWeight="bold" color="#666">
                    CSVヘッダー行
                  </Text>
                  <Text p={2} bg="blackAlpha.50" fontWeight="bold">
                    {warningMsgs.placeholders}
                  </Text>
                </Box>
              )}
              {warningMsgs.filename && (
                <Box>
                  <Text fontWeight="bold" color="#666">
                    ファイル名
                  </Text>
                  <Text p={2} bg="blackAlpha.50" fontWeight="bold">
                    {warningMsgs.filename}
                  </Text>
                </Box>
              )}
              {warningMsgs.shortagePlaceholders && (
                <Box mt={4}>
                  <Text fontWeight="bold" color="#666">
                    CSVファイルのヘッダーに以下の列を追加してください
                  </Text>
                  <Text p={2} bg="blackAlpha.50" fontWeight="bold">
                    {warningMsgs.shortagePlaceholders}
                  </Text>
                </Box>
              )}
              {warningMsgs.excessPlaceholders && (
                <Box mt={4}>
                  <Text fontWeight="bold" color="#666">
                    CSVファイルのヘッダーから以下の列を削除してください
                  </Text>
                  <Text p={2} bg="blackAlpha.50" fontWeight="bold">
                    {warningMsgs.excessPlaceholders}
                  </Text>
                </Box>
              )}
            </AlertDialogBody>

            <AlertDialogFooter>
              <Button onClick={onCloseDialog} ml={3}>
                OK
              </Button>
            </AlertDialogFooter>
          </AlertDialogContent>
        </AlertDialogOverlay>
      </AlertDialog>
    );
  },
);
