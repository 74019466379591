import { Box, Flex, Text } from '@chakra-ui/react';
import { Colors } from 'admin/define/colors';
import { FC, memo } from 'react';

export const ServiceTitle: FC = memo(() => (
  <Flex justifyContent="flex-end">
    <Box display="block" mr="auto">
      <Text
        fontWeight="700"
        fontSize="18px"
        lineHeight="170%"
        fontFamily="Hiragino Sans"
      >
        テナント設定管理
      </Text>
      <Text
        fontWeight="400"
        fontSize="14px"
        lineHeight="170%"
        fontFamily="Hiragino Sans"
        color={Colors.GRAY_00}
      >
        テナントごとの詳細設定を行います。
      </Text>
    </Box>
  </Flex>
));
