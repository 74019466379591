import { AlertDialog, useDisclosure } from '@chakra-ui/react';
import { withSuspenseAndErrorBoundary } from 'admin/components/Error/WithErrorBoundary';
import { ServiceBlockComponent } from 'admin/components/service/serviceBlock/ServiceBlockComponent';
import { ServiceDialog } from 'admin/components/service/serviceDialog';
import { ServiceType } from 'admin/types/service';
import { ErrorContents, LoadingSpinner } from 'components/common/atoms';
import { FC, memo, useRef } from 'react';
import { useNavigate } from 'react-router-dom';

type Props = {
  service: ServiceType;
};

const serviceBlock: FC<Props> = memo(({ service }: Props) => {
  const { isOpen, onClose, onOpen } = useDisclosure();
  const cancelRef = useRef(null);
  const navigate = useNavigate();

  return (
    <>
      <ServiceBlockComponent
        service={service}
        onOpen={onOpen}
        navigate={navigate}
      />
      <AlertDialog
        isOpen={isOpen}
        onClose={onClose}
        leastDestructiveRef={cancelRef}
        isCentered
        closeOnOverlayClick={false}
        closeOnEsc={false}
        size="xl"
      >
        <ServiceDialog service={service} onClose={onClose} />
      </AlertDialog>
    </>
  );
});

export const ServiceBlock = memo(
  withSuspenseAndErrorBoundary(serviceBlock, {
    ErrorComponent: <ErrorContents name="テナント情報" />,
    LoadingComponent: <LoadingSpinner />,
  }),
);
