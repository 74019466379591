import { Box, Flex, Spacer, Tag } from '@chakra-ui/react';
import styled from '@emotion/styled';
import { Textarea } from 'components/paywall/atoms/Textarea';
import { NavLink } from 'react-router-dom';

/**
 * 2023年2月2日現在暫定的にstyled/globalに汎用styledを設置
 */

export const StyledWrapper = styled(Box)({
  display: 'flex',
  width: '100',
  verticalAlign: 'top',
  alignItems: 'start',
});

export const StyledTable = styled(Box)({
  width: '100%',
  marginTop: 45,
  table: {
    width: '100%',
    backgroundColor: '#ffffff',
  },
  th: {
    fontSize: 12,
    fontWeight: 'bold',
    color: '#444444',
  },
  tbody: {
    fontSize: 12,
    color: '#444444',
  },
});

export const StyledTableNavigation = styled(Box)({
  display: 'grid',
  gridTemplateColumns: '1fr 1fr 1fr',
});

export const StyledTableLoading = styled(Box)({
  display: 'flex',
  alignItems: 'center',
  justifyContent: 'center',
  width: 'calc(100% - 110px)',
  padding: 45,
  margin: 45,
  minHeight: '50vh',
});
export const StyledFlexEnd = styled(Flex)({
  gap: 5,
  justifyContent: 'end',
  width: '100%',
});

export const StyledPartition = styled(Spacer)({
  width: '100%',
  height: 10,
  borderBottom: '1px solid #6699cc',
  marginBottom: 10,
  opacity: 0.5,
});

export const StyledTag = styled(Tag)({
  fontSize: 10,
  color: '#ffffff',
  backgroundColor: '#6699cc',
});

export const StyledBox = styled(Box)({
  fontSize: 12,
  paddingTop: 5,
});

export const StyledBoldTr = styled('tr')({
  fontWeight: 'bold',
  color: '#777777',
});

export const StyledThinTr = styled('tr')({
  color: '#999',
  fontSize: 12,
  fontWeight: 'normal',
});

export const StyledFlex = styled(Box)({
  display: 'flex',
  alignItems: 'center',
});
export const StyledFlexAlignStart = styled(Box)({
  display: 'flex',
  alignItems: 'flex-start',
});


export const StyledFlexHover = styled(Box)({
  display: 'flex',
  alignItems: 'center',
  '&:hover': {
    cursor: 'pointer',
  },
});

export const StyledBold = styled(Box)({
  fontWeight: 'bold',
});

export const StyledIndent = styled(Box)({
  marginLeft: 20,
});

export const StyledNoResizeTextarea = styled(Textarea)({
  resize: 'none',
});

export const StyledNavLink = styled(NavLink)({
  '&:hover': {
    color: '#06f',
  },
});

export const StyledTableNavigationBox = styled(Box)({
  position: 'fixed',
  bottom: '10px',
  backgroundColor: '#fff',
  width: 'calc(100vw - 400px)',
});
