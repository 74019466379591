import { Flex, Text } from '@chakra-ui/react';
import { FC, memo } from 'react';
import { MdOutlineVisibility, MdOutlineVisibilityOff } from 'react-icons/md';

type Props = {
  viewText: string;
  isVisibility: boolean;
  onClickIsVisibility: () => void;
};

export const SettingMaskingTextComponent: FC<Props> = memo(
  ({ viewText, isVisibility, onClickIsVisibility }: Props) => (
    <Flex alignItems="center" justifyContent="space-between">
      <Text fontSize="16px">{viewText}</Text>
      {isVisibility ? (
        <MdOutlineVisibilityOff
          onClick={onClickIsVisibility}
          cursor="pointer"
        />
      ) : (
        <MdOutlineVisibility onClick={onClickIsVisibility} cursor="pointer" />
      )}
    </Flex>
  ),
);
