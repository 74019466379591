import {
  Button,
  HStack,
  Modal,
  ModalBody,
  ModalCloseButton,
  ModalContent,
  ModalFooter,
  ModalHeader,
  ModalOverlay,
  Text,
} from '@chakra-ui/react';
import { Colors, ColorSet } from 'admin/define/colors';
import { SECURITY_SETTING_TYPES_MAP } from 'admin/define/idPoolConsumer/auth';
import { SecuritySettingLabels } from 'admin/types/userPool/auth';
import { FC, memo } from 'react';

type Props = {
  type: SecuritySettingLabels;
  isOpen: boolean;
  onClose: () => void;
  onSubmit: () => void;
};

export const ConfirmModal: FC<Props> = memo(
  ({ type, isOpen, onClose, onSubmit }) => (
    <Modal isOpen={isOpen} onClose={onClose} isCentered size="lg">
      <ModalOverlay />
      <ModalContent fontSize="md" zIndex={100} color={Colors.GRAY_03}>
        <ModalHeader fontSize="lg" fontWeight="bold" pt="24px">
          {SECURITY_SETTING_TYPES_MAP.get(type)}設定変更の確認
        </ModalHeader>
        <ModalBody>
          <Text>
            {SECURITY_SETTING_TYPES_MAP.get(type)}設定を変更します。
            <br />
            変更内容を十分に確認した上で実行してください。
          </Text>
        </ModalBody>
        <ModalFooter pb="24px">
          <HStack spacing={4}>
            <Button
              variant="solid"
              {...ColorSet.Default}
              onClick={onClose}
              w="118px"
              h="40px"
            >
              キャンセル
            </Button>
            <Button
              colorScheme="primary"
              mr={3}
              w="118px"
              h="40px"
              onClick={() => onSubmit()}
            >
              実行
            </Button>
          </HStack>
        </ModalFooter>
        <ModalCloseButton top="22px" right="24px" />
      </ModalContent>
    </Modal>
  ),
);
