import { Box, useDisclosure } from '@chakra-ui/react';
import { zodResolver } from '@hookform/resolvers/zod';
import { TransactionMailEditorBody } from 'admin/components/idPoolConsumer/transactionMail/transactionMailEditorBody';
import { TransactionMailEditorHeader } from 'admin/components/idPoolConsumer/transactionMail/transactionMailEditorHeader';
import { useTransactionMail } from 'admin/hooks/userPool/transactionMail/useTransactionMail';
import { useTransactionMailEditor } from 'admin/hooks/userPool/transactionMail/useTransactionMailEditor';
import { TransactionmailFormSchema } from 'admin/schema/idPoolConsumer/transactionMail';
import {
  TransactionMailFormType,
  TransactionMailPersonalType,
} from 'admin/types/userPool/transactionMail';
import { ChangeLocationDialog } from 'components/common/atoms';
import { useBrowserBackControl } from 'hooks/useBrowserBackControl';
import { useCustomNavigate } from 'hooks/useCustomNavigate';
import { FC, memo, useEffect, useRef, useState } from 'react';
import EmailEditor from 'react-email-editor';
import { FormProvider, useForm } from 'react-hook-form';

type Props = {
  transactionMailType: TransactionMailPersonalType;
  id: TransactionMailFormType['id'];
  isEdit: boolean;
};

export const TransactionMailEditor: FC<Props> = memo(
  ({ transactionMailType, id, isEdit }) => {
    const editorRef = useRef<EmailEditor>(null);
    const [isLoading, setLoading] = useState<boolean>(false);
    const [isEditorLoading, setEditorLoading] = useState<boolean>(false);
    const { isOpen, onOpen, onClose } = useDisclosure();
    const { data } = useTransactionMail(id, isEdit);
    const methods = useForm<TransactionMailFormType>({
      mode: 'onBlur',
      defaultValues: {
        id: '',
        type: transactionMailType,
        settingTitle: '',
        subject: '',
        status: false,
        bodyHtml: '',
        bodyText: '',
        editorHtml: '',
        editorText: '',
      },
      resolver: zodResolver(TransactionmailFormSchema),
    });
    const {
      getValues,
      setValue,
      setError,
      formState: { isDirty },
    } = methods;
    const { init, saveEditor } = useTransactionMailEditor({
      editor: editorRef,
      getValues,
      setValue,
      setLoading,
      setError,
    });

    // 編集中かどうかをstateで管理
    const [isEdited, setIsEdited] = useState(false);
    // ページ遷移カスタムnavigate
    const { navigate } = useCustomNavigate({
      isEdited,
      // isDirty,
      callback: onOpen,
    });
    // ブラウザバック制御
    useBrowserBackControl({ isEdited, onOpen });

    /**
     * 修正が加えられたかどうか
     */
    useEffect(() => {
      setIsEdited(isDirty);
    }, [isDirty]);

    useEffect(() => {
      init(data);
    }, []);

    return (
      <>
        <Box w="100%" mt={2}>
          <FormProvider {...methods}>
            <form>
              <TransactionMailEditorHeader
                editor={editorRef}
                transactionMailType={transactionMailType}
                onSave={saveEditor}
                isLoading={isLoading || !isEditorLoading}
                navigate={navigate}
              />
              <TransactionMailEditorBody
                editor={editorRef}
                setIsLoading={setEditorLoading}
                isLoading={isEditorLoading}
              />
            </form>
          </FormProvider>
          <ChangeLocationDialog
            isOpen={isOpen}
            onClose={onClose}
            setIsEdited={setIsEdited}
          />
        </Box>
      </>
    );
  },
);
