import { useDisclosure } from '@chakra-ui/react';
import { zodResolver } from '@hookform/resolvers/zod';
import { withSuspenseAndErrorBoundary } from 'admin/components/Error/WithErrorBoundary';
import { useTestTransactionMail } from 'admin/hooks/userPool/transactionMail/useTestTransactionMail';
import { useTransactionMailCommon } from 'admin/hooks/userPool/transactionMail/useTransactionMailCommon';
import { TransactionMailTestMailSchema } from 'admin/schema/idPoolConsumer/transactionMail';
import {
  TransactionMailCategoryType,
  TransactionMailFormType,
} from 'admin/types/userPool/transactionMail';
import { TransactionMailTestMailFormType } from 'admin/types/userPool/transactionMail/form';
import { ErrorContents } from 'components/common/atoms';
import { useCustomToast } from 'hooks/useCustomToast';
import { useEmailEditor } from 'hooks/useEmailEditor';
import {
  FC,
  memo,
  RefObject,
  useCallback,
  useEffect,
  useMemo,
  useRef,
  useState,
} from 'react';
import EmailEditor from 'react-email-editor';
import { FormProvider, useForm, UseFormGetValues } from 'react-hook-form';
import { errorToast } from 'utils/toast';
import { TestMailPopoverComponent } from './TestMailPopoverComponent';

type TestMailPopoverProps = {
  editor: RefObject<EmailEditor>;
  transactionMailType: TransactionMailCategoryType;
  isLoading: boolean;
  getValues: UseFormGetValues<TransactionMailFormType>;
};

const testMailPopover: FC<TestMailPopoverProps> = memo(
  ({ editor, transactionMailType, isLoading, getValues }) => {
    const [mailAddress, setMailAddress] = useState('');
    const mailAddressRef = useRef<HTMLTextAreaElement>(null);
    const { isOpen, onClose, onOpen } = useDisclosure();
    const { data: transactionMailCommon } = useTransactionMailCommon();
    const hasFromAddress = useMemo<boolean>(
      () => !!transactionMailCommon?.fromAddress,
      [transactionMailCommon],
    );

    const { exportHtml } = useEmailEditor({
      editor,
      options: { user: { id: 0 } },
    });
    const methods = useForm<TransactionMailTestMailFormType>({
      defaultValues: {
        subject: '',
        bodyHtml: '',
        bodyText: '',
        type: transactionMailType,
        toAddress: '',
      },
      mode: 'onBlur',
      resolver: zodResolver(TransactionMailTestMailSchema),
    });
    const { setError } = methods;
    const { mutate, isLoading: isLoadingTestMail } = useTestTransactionMail({
      setError,
    });
    const toast = useCustomToast();

    const testMailValidator = useCallback(
      (data: TransactionMailTestMailFormType): string => {
        let errorMsg = '';
        if (mailAddress === '') {
          errorMsg = 'メールアドレスは必須です';
        } else if (data.subject === '') {
          errorMsg = '件名は必須です';
        } else if (data.bodyHtml === '') {
          errorMsg = 'HTML本文は必須です';
        } else if (data.bodyText === '') {
          errorMsg = 'テキスト本文は必須です';
        }

        return errorMsg;
      },
      [mailAddress],
    );

    const onTestMailSubmit = useCallback(async () => {
      await exportHtml().then(async ({ html }) => {
        const submitData: TransactionMailTestMailFormType = {
          toAddress: mailAddress,
          subject: getValues('subject'),
          bodyHtml: html,
          bodyText: getValues('editorText'),
          type: transactionMailType,
        };

        const errorMsg = testMailValidator(submitData);
        if (errorMsg !== '') {
          toast({
            ...errorToast,
            duration: 4000,
            title: errorMsg,
            position: 'bottom',
            variant: 'solid',
          });

          return;
        }

        await mutate(submitData);
        onClose();
      });
    }, [
      exportHtml,
      getValues,
      mailAddress,
      transactionMailType,
      testMailValidator,
      mutate,
      onClose,
      toast,
    ]);

    const onChangeTextMailAddress = useCallback((text: string) => {
      setMailAddress(text);
    }, []);

    const onOpenHandler = useCallback(() => {
      onOpen();
    }, [onOpen]);

    useEffect(() => {
      if (mailAddressRef !== null && mailAddressRef.current) {
        mailAddressRef.current.placeholder = `xxxxx@xxxxxx.com
yyyyy@yyyyy.com
zzzzz@zzzzz.com`;
      }
    }, [mailAddressRef]);

    return (
      <FormProvider {...methods}>
        <TestMailPopoverComponent
          onTestMailSubmit={onTestMailSubmit}
          mailAddress={mailAddress}
          onChangeTextMailAddress={onChangeTextMailAddress}
          mailAddressRef={mailAddressRef}
          hasFromAddress={hasFromAddress}
          isLoading={isLoading || isLoadingTestMail}
          isOpen={isOpen}
          onOpen={onOpenHandler}
          onClose={onClose}
        />
      </FormProvider>
    );
  },
);

export const TestMailPopover = memo(
  withSuspenseAndErrorBoundary(testMailPopover, {
    ErrorComponent: <ErrorContents name="テストメール送信" />,
  }),
);
