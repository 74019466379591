import {
  CardHeaderButton,
  CardHeaderButtons,
} from 'components/paywall/features/Card';
import { Td, Tr } from 'components/paywall/features/Table';
import { MembersOnlyDialog } from 'components/paywall/pages/MembersOnlyWallSetting/MembersOnlyDialog';
import { useWallList } from 'hooks/paywall/useWallList';
import { FC, memo } from 'react';

/* 会員限定ウォール設定の画面設定ボタンのステータスメッセージ */
const AlertStatusDesing: { [key: number]: string } = {
  0: '',
  1: 'ウォール画面を作成してください',
  2: 'いずれかのウォール画面のステータスを 有効 にしてください',
};

export const MembersOnlyWallSettingRows: FC = memo(() => {
  const { wallList, childWallList } = useWallList({ wallType: 'member' });

  return (
    <>
      {[...wallList, ...childWallList].map((obj) => (
        <Tr>
          <Td>{obj.id}</Td>
          <Td style={{ width: '100%' }}>
            <MembersOnlyDialog
              buttonMode="text"
              buttonName={obj.name}
              obj={obj}
            />
          </Td>
          <Td style={{ width: '240px' }}>
            <CardHeaderButtons>
              <CardHeaderButton
                path={`/paywall/${obj.id}/wallDesignList`}
                buttonText="画面設定"
                status={obj.alertStatusDesing}
                tooltipLabel={AlertStatusDesing[obj.alertStatusDesing]}
              />
            </CardHeaderButtons>
          </Td>
        </Tr>
      ))}
    </>
  );
});
