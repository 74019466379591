import { Box, Tab as ChakraTab, TabList, TabPanel, TabPanels, TabProps, Tabs } from '@chakra-ui/react';
import { AttributeGroupTable } from 'admin/components/service/attribute/attributeGroupTable';
import { AttributeTable } from 'admin/components/service/attribute/attributeTable';
import { Colors } from 'admin/define/colors';
import { FC, memo, ReactNode, useCallback, useMemo, useState } from 'react';

type CustomTabProps = {
  isActive: boolean;
  children: ReactNode;
} & TabProps

const Tab: FC<CustomTabProps> = memo(({ isActive, children, ...rest }: CustomTabProps) => {
  const tabStyle = useMemo(() => {
    const baseStyle = {
      _selected: {
        color: Colors.GRAY_03,
        bg: Colors.WHITE,
        fontSize:"14px",
        fontWeight: "700"
      },
      _active: {
        bg: Colors.WHITE,
      },
      color: Colors.GRAY_00,
      bg: Colors.GRAY_75,
      borderWidth: "1px",
      borderStyle: "solid",
      borderColor: Colors.GRAY_30,
      borderRadius: "6px 6px 0px 0px",
      fontSize: "14px",
      p: "8px 24px",
      transform: 'translateY(1px)'
    }

    if(!isActive) {
      return {
        ...baseStyle,
        _hover: {
          color: '#7E8C9A',
          bg: Colors.GRAY_30,
        },
      }
    }

    return {
      ...baseStyle,
      borderBottom: "none"
    };
  }, [isActive])

  return (
    <ChakraTab
      {...tabStyle}
      {...rest}
    >
      {children}
    </ChakraTab>
  )
})

export const AttributeBody: FC = memo(() => {
  const [tabIndex, setTabIndex] = useState(0);
  const onChangeTab = useCallback((idx: number) => {
    setTabIndex(idx);
  }, []);

  return (
    <Tabs mt="16px" variant="unstyled" onChange={(idx) => onChangeTab(idx)}>
      {/* レイアウトがfix後に調整を行う予定 */}
      <TabList style={{gap: '8px'}}>
        <Tab isActive={tabIndex === 0}>
          属性
        </Tab>
        <Tab isActive={tabIndex === 1}>
          属性グループ
        </Tab>
      </TabList>
      <Box
        bgColor={Colors.WHITE}
        p="24px"
        borderWidth="1px"
        borderStyle="solid"
        borderColor={Colors.GRAY_30}
        borderRadius="0px 8px 8px 8px"
      >
        <TabPanels>
          <TabPanel p={0}>
            <AttributeTable selectedTab={tabIndex} />
          </TabPanel>
          <TabPanel p={0}>
            <AttributeGroupTable selectedTab={tabIndex} />
          </TabPanel>
        </TabPanels>
      </Box>
    </Tabs>
  );
});
