import { request } from 'admin/api/Request';
import { API_MESSAGES } from 'admin/define/apiMessages';
import { CustomError } from 'admin/error/CustomError';
import { codeSettingsSchema } from 'admin/schema/idPoolConsumer/code';
import { convertToPage, pageSchema } from 'admin/schema/page';
import { CodeSettingsType } from 'admin/types/userPool/code/index';
import { CodeGroupType } from 'admin/types/userPool/master/index';
import { DataWithPage } from 'api/common/types';
import { Options } from 'ky';
import { ZodError } from 'zod';

export const getCodeSettings = async ({
  id,
  options,
}: {
  id: CodeGroupType['id'];
  options?: Options;
}): Promise<DataWithPage<CodeSettingsType>> => {
  const path = `uniikey/${id}/code/`;
  const response = await request<DataWithPage<CodeSettingsType>>({
    path,
    method: 'get',
    options,
  });
  if (response.hasError) {
    if (response.status === 404) {
      throw new CustomError(
        API_MESSAGES.USER_POOL.GET_CODE_SETTINGS.NOT_FOUND,
        {
          path,
          status: response.status,
        },
      );
    } else {
      throw new CustomError(API_MESSAGES.USER_POOL.GET_CODE_SETTINGS.ERROR, {
        path,
        status: response.status,
        logLevel: response.status === 400 ? 'warning' : 'error',
      });
    }
  }
  try {
    codeSettingsSchema.parse(response.data.results);
    pageSchema.parse(
      convertToPage<DataWithPage<CodeSettingsType>>(response.data),
    );
  } catch (error) {
    if (error instanceof ZodError) {
      throw new CustomError(error.message, {
        path: 'dummy',
        status: response.status,
        logLevel: 'error',
      });
    }
  }

  return response.data;
};
