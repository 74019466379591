import { request } from 'admin/api/Request';
import { API_MESSAGES } from 'admin/define/apiMessages';
import { CustomError } from 'admin/error/CustomError';
import { editCodeSchema } from 'admin/schema/idPoolConsumer/code';
import { CodeSettingType } from 'admin/types/userPool/code';
import { EditCodeFormType } from 'admin/types/userPool/code/form';
import { isResponseError } from 'api/types';
import { Options } from 'ky';
import { getValidationError } from 'utils/form';
import { ZodError } from 'zod';

export const editCodeSetting = async (
  data: EditCodeFormType,
  options?: Options,
): Promise<CodeSettingType> => {
  const { codeGroup, id, ...params } = data;
  const path = `uniikey/${codeGroup}/code/${id}/`;
  const response = await request<CodeSettingType>({
    path,
    options,
    body: params,
    method: 'patch',
  });

  if (response.hasError) {
    if (response.status === 404) {
      throw new CustomError(API_MESSAGES.USER_POOL.EDIT_CODE_SETTING.FAILED, {
        path,
        options,
        params,
        status: response.status,
      });
    } else if (isResponseError(response.error)) {
      const customError = response.error;

      const formError = getValidationError<EditCodeFormType>({
        formData: data,
        response: customError,
      });

      throw new CustomError(API_MESSAGES.USER_POOL.EDIT_CODE_SETTING.ERROR, {
        path,
        options,
        params,
        errors: formError,
        status: response.status,
        logLevel: response.status === 400 ? 'warning' : 'error',
      });
    } else {
      throw new CustomError(API_MESSAGES.USER_POOL.EDIT_CODE_SETTING.ERROR, {
        path,
        options,
        params,
        status: response.status,
      });
    }
  }
  try {
    editCodeSchema.parse(response.data);
  } catch (error) {
    if (error instanceof ZodError) {
      throw new CustomError(error.message, {
        path,
        options,
        params,
        status: response.status,
        logLevel: 'error',
      });
    }
  }

  return response.data;
};
