import { Tr } from '@chakra-ui/react';
import { Th } from 'components/paywall/features/Table';
import { FC, memo } from 'react';

export const CustomerColumns: FC = memo(() => (
  <Tr>
    <Th maxWidth="310px" minWidth="310px" name="顧客ID" columnFix targetKey="customerUid" isSort filterType="text" />
    <Th maxWidth="199px" minWidth="199px" name="メールアドレス" targetKey="email" filterType="text" />
    <Th maxWidth="233px" minWidth="233px" name="コース" targetKey="course" filterType="text" />
  </Tr>
));
