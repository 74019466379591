import { usePopperUtility } from 'components/opt/hooks/useGlobalUtilities';
import { useTableNavigation } from 'components/paywall/features/Table/TableNavigation';
import { useCallback, useEffect, useState } from 'react';
import { useForm } from 'react-hook-form';
import { useLocation, useParams } from 'react-router-dom';
import { atom, useRecoilState } from 'recoil';
import { snakeCase } from 'utils/str';
import { toNumber } from 'utils/toNumber';

type orderingValueProps = boolean | 'desc' | 'asc' | undefined;

export type SearchParam = {
  page: number;
  perPage: number;
  ordering: string;
  orderingValue: orderingValueProps;
  id: string;
  orderId: string;
  execTranDateFrom: string;
  execTranDateTo: string;
  productId: string;
  productIdType: string;
  productName: string;
  productNameType: string;
  priceFrom: number;
  priceTo: number;
  email: string;
  customerUid: string;
  courseId: string;
  course: string;
  courseName: string;
  courseBoth: string;
  status: string;
  courseStartFrom: string;
  courseStartTo: string;
  courseEndFrom: string;
  courseEndTo: string;
  cancelReason: string;
  systemCode: string;
  tags: string;
};

const initialSearchParam = {
  page: 1,
  perPage: 10,
  ordering: '',
  orderingValue: undefined,
  id: '',
  orderId: '',
  execTranDateFrom: '',
  execTranDateTo: '',
  productId: '',
  productIdType: '',
  productName: '',
  productNameType: '',
  priceFrom: 0,
  priceTo: 0,
  email: '',
  customerUid: '',
  courseId: '',
  course: '',
  courseName: '',
  courseBoth: '',
  status: '',
  courseStartFrom: '',
  courseStartTo: '',
  courseEndFrom: '',
  courseEndTo: '',
  cancelReason: '',
  systemCode: '',
  tags: '',
};

const searchPathnameContext = atom<string>({
  key: 'api/searchPathnameContext',
  default: '',
});

const searchParamContext = atom<SearchParam>({
  key: 'api/searchParamContext',
  default: initialSearchParam,
});

const searchedDataContext = atom<string[]>({
  key: 'api/searchedDataContext',
  default: [],
});
/*
const orderingKeyContext = atom({
  key: 'api/orderingKeyContext',
  default: '',
});

const orderingValueContext = atom<orderingValueProps>({
  key: 'api/orderingValueContext',
  default: 'asc',
});
*/

const isResetCalendarContext = atom({
  key: 'api/isResetCalendarContext',
  default: false,
});

export const useSearch = () => {
  const location = useLocation();
  const params = useParams();
  const { search } = useLocation();

  const [isCalendarReset, setIsCalendarReset] = useRecoilState(
    isResetCalendarContext,
  );
  const [searchPathname, setSearchPathname] = useRecoilState(
    searchPathnameContext,
  );
  const [searchParam, setSearchParam] = useRecoilState(searchParamContext);
  const [searchedData, setSearchedData] = useRecoilState(searchedDataContext);
  const [orderingKey, setOrderingKey] = useState('');
  const [orderingValue, setOrderingValue] = useState<orderingValueProps>('asc');

  const { page, perPage, setPage, initializeTableNavigation } =
    useTableNavigation();
  const { register, setValue, getValues, watch } = useForm();
  const { handleClosePopper } = usePopperUtility();

  const removeFilter = useCallback(
    (name: string) => {
      setPage(1);
      setSearchedData(searchedData.filter((order) => order !== name));
      if (name === 'period') {
        setValue('execTranDateFrom', '');
        setValue('execTranDateTo', '');
        setValue('courseStartFrom', '');
        setValue('courseStartTo', '');
        setValue('courseEndFrom', '');
        setValue('courseEndTo', '');
        setSearchParam({
          ...searchParam,
          execTranDateFrom: '',
          execTranDateTo: '',
          courseStartFrom: '',
          courseStartTo: '',
          courseEndFrom: '',
          courseEndTo: '',
        });
        setIsCalendarReset(true);
      } else if (name === 'price') {
        setValue('priceFrom', 0);
        setValue('priceTo', 0);
        setSearchParam({ ...searchParam, priceFrom: 0, priceTo: 0 });
      } else {
        setValue(name, '');
        setSearchParam({ ...searchParam, [name]: '' });
      }
    },
    [
      searchParam,
      searchedData,
      setIsCalendarReset,
      setPage,
      setSearchParam,
      setSearchedData,
      setValue,
    ],
  );

  const handleFilterSort = useCallback(
    (target: string, key: orderingValueProps): void => {
      setPage(1);
      setOrderingKey(target);
      setOrderingValue(key);
      setSearchParam({
        ...searchParam,
        ordering: target,
        orderingValue: key,
      });
    },
    [setPage, setOrderingKey, setOrderingValue, setSearchParam, searchParam],
  );

  const handleFilterPeriod = useCallback(
    (inputName: string): void => {
      setPage(1);
      setSearchParam({
        ...searchParam,
        page: 1,
        [`${inputName}From`]: getValues(`${inputName}From`)
          ? (getValues(`${inputName}From`) as string)
          : '',
        [`${inputName}To`]: getValues(`${inputName}To`)
          ? (getValues(`${inputName}To`) as string)
          : '',
      });

      const isSetDate = (val: string|null|undefined) => !!val;
      if (
        (getValues(`${inputName}From`) || getValues(`${inputName}To`)) &&
        !searchedData.find((d) => d === 'period')
      ) {
        setSearchedData([...searchedData, 'period']);
      }
      if (
        !isSetDate(getValues(`${inputName}From`) as string|null|undefined) &&
        !isSetDate(getValues(`${inputName}To`) as string|null|undefined)
      ) {
        removeFilter('period');
      }
      handleClosePopper();
    },
    [
      getValues,
      removeFilter,
      searchParam,
      searchedData,
      setPage,
      setSearchParam,
      setSearchedData,
      handleClosePopper,
    ],
  );

  const handleFilterPrice = useCallback((): void => {
    setPage(1);
    // TODO: react form使用するときに型指定をする
    // リファクタするまで、具体的な型を指定しない
    const from = toNumber(getValues('priceFrom') as number | string)
      ? toNumber(getValues('priceFrom') as number | string)
      : 0;
    const to = toNumber(getValues('priceTo') as number | string)
      ? toNumber(getValues('priceTo') as number | string)
      : 0;
    setSearchParam({
      ...searchParam,
      page: 1,
      priceFrom: from || 0,
      priceTo: to || 0,
    });
    if ((from || to) && !searchedData.find((d) => d === 'price')) {
      setSearchedData([...searchedData, 'price']);
    }
    if (!from && !to && searchedData.find((d) => d === 'price')) {
      removeFilter('price');
    }
    handleClosePopper();
  }, [
    getValues,
    removeFilter,
    searchParam,
    searchedData,
    setPage,
    setSearchParam,
    setSearchedData,
    handleClosePopper,
  ]);

  const handleFilterTextSearch = useCallback(
    (name): void => {
      setPage(1);
      setSearchParam({
        ...searchParam,
        page: 1,
        [name]: getValues(name) as string,
      });
      if (getValues(name) && !searchedData.find((d) => d === name)) {
        setSearchedData([...searchedData, name]);
      }
      if (!getValues(name) && searchedData.find((d) => d === name)) {
        setSearchedData(searchedData.filter((d) => d !== name));
      }
      handleClosePopper();
    },
    [
      getValues,
      searchParam,
      searchedData,
      setPage,
      setSearchParam,
      setSearchedData,
      handleClosePopper,
    ],
  );

  const handleFilterPlanSearch = useCallback(
    (name, radio): void => {
      setPage(1);
      setSearchParam({
        ...searchParam,
        page: 1,
        [name]: getValues(name) as string,
        [radio]: getValues(radio) as string,
      });
      if (getValues(name) && !searchedData.find((d) => d === name)) {
        setSearchedData([...searchedData, name]);
      }
      if (!getValues(name) && searchedData.find((d) => d === name)) {
        setSearchedData(searchedData.filter((d) => d !== name));
      }
      handleClosePopper();
    },
    [
      getValues,
      searchParam,
      searchedData,
      setPage,
      setSearchParam,
      setSearchedData,
      handleClosePopper,
    ],
  );

  const handleFilterRadioSearch = useCallback(
    (radio): void => {
      setPage(1);
      setSearchParam({
        ...searchParam,
        page: 1,
        [radio]: getValues(radio) as string[],
      });
      if (getValues(radio) && !searchedData.find((d) => d === radio)) {
        setSearchedData([...searchedData, radio]);
      }
      if (!getValues(radio) && searchedData.find((d) => d === radio)) {
        setSearchedData(searchedData.filter((d) => d !== radio));
      }
      handleClosePopper();
    },
    [
      getValues,
      searchParam,
      searchedData,
      setPage,
      setSearchParam,
      setSearchedData,
      handleClosePopper,
    ],
  );

  const makeApiQuery = useCallback(() => {
    if (searchPathname !== location.pathname) {
      /* ページ遷移 */
      return '';
    }

    const queryParams = {
      paging: '',
      id: '',
      ordering: '',
      orderId: '',
      execTranDateFrom: '',
      execTranDateTo: '',
      productId: '',
      productIdType: '',
      itemName: '',
      courseName: '',
      priceFrom: '',
      priceTo: '',
      email: '',
      customerUid: '',
      courseId: '',
      course: '',
      status: '',
      courseStartFrom: '',
      courseStartTo: '',
      courseEndFrom: '',
      courseEndTo: '',
      cancelReason: '',
      systemCode: '',
      tags: '',
    }

    const param = searchParam;

    queryParams.paging = `page=${param.page}&per_page=${param.perPage}`;

    if(param.ordering) {
      if(param.orderingValue === 'desc') {
        queryParams.ordering = `&ordering=-${snakeCase(param.ordering)}`;
      }else{
        queryParams.ordering = `&ordering=${snakeCase(param.ordering)}`;
      }
    }
    if(param.id) queryParams.id =  `&id=${param.id}`
    if(param.orderId) queryParams.orderId =  `&order_id=${param.orderId}`
    if(param.execTranDateFrom) queryParams.execTranDateFrom = `&exec_tran_date_from=${param.execTranDateFrom}`;
    if(param.execTranDateTo) queryParams.execTranDateTo = `&exec_tran_date_to=${param.execTranDateTo}`;
    if(param.courseStartFrom) queryParams.courseStartFrom = `&course_start_from=${param.courseStartFrom}`;
    if(param.courseStartTo) queryParams.courseStartTo = `&course_start_to=${param.courseStartTo}`;
    if(param.courseEndFrom) queryParams.courseEndFrom = `&course_end_from=${param.courseEndFrom}`;
    if(param.courseEndTo) queryParams.courseEndTo = `&course_end_to=${param.courseEndTo}`;
    if(param.productId) queryParams.productId = `&${param.productIdType}=${param.productId}`;
    if(param.productNameType === 'item_id' && param.productName) queryParams.itemName =`&item_name=${param.productName}`;
    if(param.productNameType === 'course_id' && param.productName) {
      queryParams.courseName = `&course_name=${param.productName}`;
    }else if(!param.productNameType && param.courseName) {
      queryParams.courseName = `&course_name=${param.courseName}`;
    }
    if(param.priceFrom && param.priceFrom !== 0) queryParams.priceFrom = `&price_from=${param.priceFrom}`;
    if(param.priceTo && param.priceTo !== 0) queryParams.priceTo = `&price_to=${param.priceTo}`;
    if(param.email) queryParams.email = `&email=${encodeURIComponent(param.email)}`;
    if(param.customerUid) queryParams.customerUid = `&customer_uid=${param.customerUid}`;
    if(param.courseId) queryParams.courseId = `&course_id=${param.courseId}`;
    if(param.courseBoth) queryParams.course = `&course=${param.courseBoth}`;
    if(param.course) queryParams.course = `&course=${param.course}`;

    // TODO: statusは複数セットされる場合がある（仕様変更）
    if(param.status?.includes('アクティブ')) queryParams.status +=  `&status=1`;
    if(param.status?.includes('終了済み')) queryParams.status +=  `&status=2`;

    // TODO: ラジオボタンのvalue値を修正するときに以下処理も合わせて修正を行う
    // ※将来的な修正に弱い為、value値に文字列を設定しない
    // TODO: cancelReasonは複数セットされる場合がある（仕様変更）
    if(param.cancelReason?.includes('ユーザーキャンセル')) queryParams.cancelReason += `&cancelReason=1`;
    if(param.cancelReason?.includes('管理者キャンセル')) queryParams.cancelReason += `&cancelReason=2`;
    if(param.cancelReason?.includes('決済エラー')) queryParams.cancelReason += `&cancelReason=3`;
    if(param.cancelReason?.includes('アップグレード')) queryParams.cancelReason += `&cancelReason=4`;
    if(param.cancelReason?.includes('退会')) queryParams.cancelReason += `&cancelReason=5`;

    // 「決済一覧画面に単品の「システム番号」「タグ」の追加」で対応(2023/08/21)
    if(param.systemCode) queryParams.systemCode = `&system_code=${param.systemCode}`;
    if(param.tags) queryParams.tags = `&tags=${param.tags}`;

    return (Object.values(queryParams).join(''));
  }, [location.pathname, searchParam, searchPathname]);

  useEffect(() => {
    if (searchPathname === location.pathname) return;
    setSearchPathname(location.pathname);
    setSearchParam(initialSearchParam);
    setSearchedData([]);
    initializeTableNavigation();
  }, [
    initializeTableNavigation,
    location.pathname,
    searchPathname,
    setSearchParam,
    setSearchPathname,
    setSearchedData,
  ]);

  useEffect(() => {
    if (searchParam.page === page && searchParam.perPage === perPage) return;
    setSearchParam({
      ...searchParam,
      page,
      perPage,
    });
  }, [page, perPage, searchParam, setSearchParam]);

  useEffect(() => {
    /* ながい（後修正） */
    if (!search) return;
    if (searchedData.length > 0) return;
    const url = new URL(window.location.href);
    const query = new URLSearchParams(search);
    if (
      location.pathname === '/paywall/orderList/' &&
      query.get('customer_uid')
    ) {
      const customerId = query.get('customer_uid');
      if (customerId) {
        setSearchParam({
          ...searchParam,
          customerUid: customerId || '',
        });
        setSearchedData(['customerUid']);
        url.searchParams.delete('customer_uid');
      }
    }
    if (location.pathname === '/paywall/orderList/' && query.get('order_id')) {
      const orderId = query.get('order_id');
      if (orderId) {
        setSearchParam({
          ...searchParam,
          orderId: orderId || '',
        });
        setSearchedData(['orderId']);
        url.searchParams.delete('order_id');
      }
    }
    if (
      location.pathname === '/paywall/subscriptionList/' &&
      query.get('customer_uid')
    ) {
      const customerId = query.get('customer_uid');
      if (customerId) {
        setSearchParam({
          ...searchParam,
          customerUid: customerId || '',
        });
        setSearchedData(['customerUid']);
        url.searchParams.delete('customer_uid');
      }
    }
  }, [
    location.pathname,
    params,
    search,
    searchParam,
    searchedData.length,
    setSearchParam,
    setSearchedData,
  ]);

  return {
    searchParam,
    setSearchParam,
    makeApiQuery,
    register,
    setValue,
    getValues,
    orderingKey,
    orderingValue,
    searchedData,
    removeFilter,
    isCalendarReset,
    setIsCalendarReset,
    handleFilterSort,
    handleFilterPeriod,
    handleFilterPrice,
    handleFilterTextSearch,
    handleFilterPlanSearch,
    handleFilterRadioSearch,
    watch
  };
};
