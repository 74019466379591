import { customErrorMessage, toZod } from 'admin/config/zod';
import { LoginSetting } from 'api/tenant/types';
import { z } from 'zod';

// zodErrorの日本語化対応
z.setErrorMap(customErrorMessage);

export const loginRedirectFormSchema = z.object<toZod<LoginSetting>>({
  loginUrl: z.string(),
  signupUrl: z.string(),
  loginParam: z.string(),
  loginOtherParam: z.string()
});

export const loginRedirectConfirmSchema = z.object({
  word: z.string().refine((val) => val === 'confirm', {
    message: '"confirm"と入力してください',
  }),
});
