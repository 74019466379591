// ----- fetch関連 -----
const GET_USERS = {
  ERROR:
    '申し訳ございません。法人ユーザー一覧情報の取得ができません。時間を置いて再表示をお願いします。',
  NOT_FOUND: '法人ユーザー一覧が存在しません。既に削除された可能性があります。',
} as const;
const GET_USER_DETAIL = {
  ERROR:
    '申し訳ございません。法人ユーザーの詳細情報の取得ができません。時間を置いて再表示をお願いします。',
  NOT_FOUND:
    '法人ユーザーの詳細情報が存在しません。既に削除された可能性があります。',
} as const;

// ----- Mutate関連 -----
const UPDATE_USER = {
  ERROR: '申し訳ございません。法人ユーザー情報の更新に失敗しました。',
  FAILED:
    '法人ユーザー情報の更新に失敗しました。恐れ入りますが時間をおいて再度実行してください。',
} as const;
const DELETE_USER = {
  ERROR: '申し訳ございません。法人ユーザー削除に失敗しました。',
  FAILED:
    '法人ユーザー削除に失敗しました。恐れ入りますが時間をおいて再度実行してください。',
} as const;
const INVITE_CORPORATION = {
  ERROR: '申し訳ございません。法人ユーザー招待に失敗しました。',
  FAILED:
    '法人ユーザー招待に失敗しました。恐れ入りますが時間をおいて再度実行してください。',
} as const;

export const USER = {
  USER: {
    GET_USERS,
    GET_USER_DETAIL,
    UPDATE_USER,
    DELETE_USER,
    INVITE_CORPORATION
  }
}
