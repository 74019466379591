import { Box, Icon, Stack, Text, useDisclosure } from '@chakra-ui/react';
import { User, UserSettingForm } from 'api/user/types';
import { DrawerForm } from 'components/common/atoms';
import { InputForm } from 'components/common/molecules';
import { useCustomToast } from 'hooks/useCustomToast';
import { useUserEdit } from 'hooks/user/useUserEdit';
import { VFC, memo, useCallback, useEffect, useState } from 'react';
import { FormProvider, useForm } from 'react-hook-form';
import { MdCreate } from 'react-icons/md';

type UserProfileProps = {
  selfData: User;
};

/**
 * 表示名入力用バリデーション
 */
const nameValidateAttr = {
  required: 'お名前を入力してください',
};

const defaultValues = {
  name: '',
  email: '',
};

export const UserProfileEdit: VFC<UserProfileProps> = memo(
  ({ selfData }: UserProfileProps) => {
    const { isOpen, onClose, onOpen } = useDisclosure();
    const [globalErrors, setGlobalErrors] = useState<string[]>([]);
    const methods = useForm<UserSettingForm>({
      mode: 'onBlur',
      defaultValues,
    });
    const {
      setValue,
      formState: { errors },
      setError,
      handleSubmit,
      clearErrors,
      getValues,
    } = methods;
    const toast = useCustomToast();
    const [isError, setIsError] = useState(false);
    const formId = 'userSetting-form';
    const closeBtnHandler = useCallback(() => {
      onClose();
      clearErrors();
    }, [onClose, clearErrors]);

    const { onSubmit, isLoading, isSuccess } = useUserEdit({
      setError,
      setGlobalErrors,
    });

    const isFailed = useCallback(
      (): boolean => Object.keys(errors).length > 0 || isError,
      [errors, isError],
    );

    // ドロワーを開くときの処理
    const onClickEdit = useCallback(() => {
      setValue('name', selfData?.name);
      setValue('email', selfData?.email);
      onOpen();
    }, [onOpen, setValue, selfData?.name, selfData?.email]);

    // フォーム送信する処理
    const formSubmit = useCallback(
      (data: UserSettingForm) => {
        onSubmit(data);
      },
      [onSubmit],
    );

    // エラーメッセージ用トースト
    const errorToast = useCallback((error: string) => {
      toast({
        status: 'error',
        position: 'bottom',
        isClosable: true,
        title: error,
      });
      // TODO:
      // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    useEffect(() => {
      const error = globalErrors.length > 0 ? globalErrors[0] : '';
      if (error !== '') errorToast(error);
      setIsError(error !== '');
    }, [globalErrors, errorToast]);

    useEffect(() => {
      if (isSuccess) onClose();
    }, [isSuccess, onClose]);

    return (
      <Box display="flex" alignItems="center">
        <Text fontSize="xl" mr={2}>
          ユーザー情報変更
        </Text>
        <FormProvider {...methods}>
          <DrawerForm
            title="ユーザー情報変更"
            openBtnChildNode={<Icon as={MdCreate} />}
            openBtnProps={{ variant: 'ghost' }}
            cancelBtnTitle="キャンセル"
            cancelBtnHandelr={closeBtnHandler}
            cancelBtnProps={{ disabled: isLoading }}
            submitBtnTitle="変更"
            submitBtnProps={{
              variant: 'primary',
              disabled: isFailed(),
              isLoading,
            }}
            isOpen={isOpen}
            onOpen={onClickEdit}
            onClose={closeBtnHandler}
            closeOnOverlayClick={false}
            closeOnEsc={false}
            autoFocus={false}
            size="md"
            formId={formId}
            drawerCloseButtonProps={{ disabled: isLoading }}
            drawerFooterJustify="space-between"
          >
            <form
              id={formId}
              onSubmit={handleSubmit((data) => formSubmit(data))}
            >
              <Stack spacing={8} mt={4}>
                <InputForm
                  name="name"
                  type="text"
                  label="お名前"
                  attr={nameValidateAttr}
                />
                <Box>
                  <Text mb={2}>メールアドレス</Text>
                  <Text px={4} fontSize="md">
                    {getValues('email')}
                  </Text>
                </Box>
              </Stack>
            </form>
          </DrawerForm>
        </FormProvider>
      </Box>
    );
  },
);
