/**
 * ファイルアップロード Type
 */
export type GcsFileUpload = {
  tenantId: string;
  file: File;
  filePath: string;
};
/**
 * ファイルアップロードレスポンス Type
 */
export type GcsFileUploadResponse = {
  ext: string;
  filepath: string;
  isPublic: boolean;
  publicUrl: string;
  size: number;
};
/**
 * CSVファイルアップロード Type
 */
export type CsvFileUpload = {
  mailId: string;
  tenantId: string;
  file: File;
  filePath: string;
};
/**
 * CSVファイルアップロードレスポンス Type
 */
export type CsvFileUploadResponse = {
  addressCount: number;
  ext: string;
  filepath: string;
  originFilename: string;
  size: number;
  error?: string;
};

export const isGcsFileUploadResponse = (
  data: unknown,
): data is GcsFileUploadResponse => {
  const gfu = data as GcsFileUploadResponse;

  return (
    typeof gfu?.ext === 'string' &&
    typeof gfu?.filepath === 'string' &&
    typeof gfu?.isPublic === 'boolean' &&
    typeof gfu?.publicUrl === 'string' &&
    typeof gfu?.size === 'number'
  );
};

export const isCsvFileUploadResponse = (
  data: unknown,
): data is CsvFileUploadResponse => {
  const gfu = data as CsvFileUploadResponse;

  return (
    typeof gfu?.addressCount === 'number' &&
    typeof gfu?.ext === 'string' &&
    typeof gfu?.filepath === 'string' &&
    typeof gfu?.originFilename === 'string' &&
    typeof gfu?.size === 'number'
  );
};

/**
 * ファイルアップロードエラーレスポンス Type
 */
export type GcsFileUploadErrorResponse = {
  file: string;
};

export const isGcsFileUploadErrorResponse = (
  data: unknown,
): data is GcsFileUploadErrorResponse => {
  const d = data as GcsFileUploadErrorResponse;

  return typeof d?.file === 'string';
};
