import { Box, Flex, Tbody, Text } from '@chakra-ui/react';
import { Colors } from 'components/paywall/config';
import { Pagination, Table, Thead } from 'components/paywall/features/Table';
import { SearchedTag } from 'components/paywall/features/Table/SearchedTag';
import { Container } from 'components/paywall/templates/Container';
import { FC, memo } from 'react';
import { CustomerColumns } from './CustomerColumns';
import { CustomerRows } from './CustomerRows';

export const CustomerList: FC = memo(() => (
  <Container
    title={
      <Flex>
        <Text fontSize="14px" color={Colors.BLACK} fontWeight="700">
          顧客一覧
        </Text>
      </Flex>
    }
    px="0px"
    contentsPl="45px"
  >
    <Box pb="16px" minH="45px">
      <SearchedTag />
    </Box>
    <Box className="PaywallCardList">
      <Table>
        <Thead>
          <CustomerColumns />
        </Thead>
        <Tbody>
          <CustomerRows />
        </Tbody>
      </Table>
      <Pagination />
    </Box>
  </Container>
));
