import { request } from 'admin/api/Request';
import { API_MESSAGES } from 'admin/define/apiMessages';
import { CustomError } from 'admin/error/CustomError';
import { TransactionMailCommonSchema } from 'admin/schema/idPoolConsumer/transactionMail';
import { TransactionMailCommonType } from 'admin/types/userPool/transactionMail';
import { ZodError } from 'zod';

export const getTransactionMailCommon = async (
  idPoolConsumerId: string,
): Promise<TransactionMailCommonType> => {
  const path = `uniikey/${idPoolConsumerId}/transaction_mail_commons/`;
  const response = await request<TransactionMailCommonType>({
    path,
    method: 'get',
  });

  if (response.hasError) {
    if (response.status === 404) {
      throw new CustomError(
        API_MESSAGES.USER_POOL.GET_TRANSACTION_MAIL_COMMON.NOT_FOUND,
        {
          path,
          status: response.status,
        },
      );
    } else {
      throw new CustomError(
        API_MESSAGES.USER_POOL.GET_TRANSACTION_MAIL_COMMON.ERROR,
        {
          path,
          status: response.status,
        },
      );
    }
  }
  try {
    TransactionMailCommonSchema.parse(response.data);
  } catch (error) {
    if (error instanceof ZodError) {
      throw new CustomError(error.message, {
        path,
        status: response.status,
        logLevel: 'error',
      });
    }
  }

  return response.data;
};
