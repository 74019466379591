export const messages = {
  //  メッセージボックス & メール通知取得処理
  getNotice: {
    customer: {
      error:
        '申し訳ございません。メッセージ情報を取得できません。時間を置いて再表示をお願いします。',
      typeError: '申し訳ございません。メッセージ情報表示できません',
      failed:
        '申し訳ありません。正しいメッセージ情報を取得することができません。',
    },
    system: {
      typeError:
        '[api.notice.getNotice] メッセージ情報取得APIのレスポンス型が予期せぬデータ型です。フロントの修正をしてください',
    },
  },
  // メッセージ既読更新
  putNotice: {
    customer: {
      error: '申し訳ございません。メッセージ既読更新に失敗しました。',
      failed:
        'メッセージ既読更新に失敗しました。恐れ入りますが時間をおいて再度登録してください。',
    },
    system: {
      typeError: '[api.user.putNotice] 予期せぬエラーが発生しました。',
    },
  },
};
