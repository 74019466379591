import { Tag, TagLabel, TagLeftIcon } from '@chakra-ui/react';
import { Colors } from 'components/paywall/config';
import { FC, memo, useEffect, useState } from 'react';
import { GoDotFill } from 'react-icons/go';

type StatusColor = {
  font: string;
  icon: string;
  bg: string;
};

type Props = {
  type: number;
  status: string;
};

export const StatusTag: FC<Props> = memo(({ type, status }: Props) => {
  const [colorInfo, setColorInfo] = useState<StatusColor>({
    font: 'transparent',
    icon: 'transparent',
    bg: 'transparent',
  });

  useEffect(() => {
    switch (type) {
      case 1:
        setColorInfo({
          font: Colors.GRAY_03,
          icon: Colors.GRAY_00,
          bg: Colors.GRAY_40,
        });
        break;
      case 2:
        setColorInfo({
          font: Colors.GREEN_00,
          icon: Colors.GREEN_10,
          bg: Colors.GREEN_20,
        });
        break;
      case 3:
        setColorInfo({
          font: Colors.ORANGE_00,
          icon: Colors.ORANGE_10,
          bg: Colors.ORANGE_20,
        });
        break;
      case 4:
        setColorInfo({
          font: Colors.BLUE_00,
          icon: Colors.BLUE_20,
          bg: Colors.BLUE_30,
        });
        break;
      case 5:
        setColorInfo({
          font: Colors.RED_00,
          icon: Colors.RED_20,
          bg: Colors.RED_30,
        });
        break;

      default:
        setColorInfo({
          font: 'transparent',
          icon: 'transparent',
          bg: 'transparent',
        });
    }
  }, [type]);

  return (
    <Tag
      size="sm"
      variant="subtle"
      pl="4px"
      backgroundColor={colorInfo.bg}
      cursor="default"
    >
      <TagLeftIcon
        boxSize="16px"
        mr="4px"
        as={GoDotFill}
        color={colorInfo.icon}
      />
      <TagLabel color={colorInfo.font}>{status}</TagLabel>
    </Tag>
  );
});
