import {
  Box,
  Button,
  FormControl,
  FormErrorMessage,
  Grid,
  GridItem,
  Text,
} from '@chakra-ui/react';
import { json } from '@codemirror/lang-json';
import { vscodeDark } from '@uiw/codemirror-theme-vscode';
import CodeMirror from '@uiw/react-codemirror';
import { RhfInput } from 'admin/components/form/RhfInput';
import { JsonEditorForm } from 'admin/types/jsonPath/form';
import jsonpath from 'jsonpath';
import React, { FC, useCallback, useState } from 'react';
import { Controller, FormProvider, useForm } from 'react-hook-form';

const DEFAULT_PATH = '$';
const JSON_FORMATTER_INDENT = 4;

type Props = {
  jsonData: string;
};

export const JsonEditor: FC<Props> = ({ jsonData }) => {
  const [parseData, setParseData] = useState<string | undefined>(
    jsonData || undefined,
  );

  const methods = useForm<JsonEditorForm>({
    mode: 'onBlur',
    reValidateMode: 'onBlur',
    defaultValues: {
      path: DEFAULT_PATH,
      jsonData,
    },
  });

  const handlePathChange = useCallback(
    (e: React.ChangeEvent<HTMLInputElement>) => {
      try {
        const result = jsonpath.query(
          JSON.parse(methods.getValues('jsonData')),
          e.target.value,
        );
        setParseData(JSON.stringify(result, null, JSON_FORMATTER_INDENT));
      } catch (error) {
        setParseData(methods.getValues('jsonData'));
      }
    },
    [methods],
  );

  const onReset = () => {
    methods.reset();
  };

  return (
    <FormProvider {...methods}>
      <Box>
        <form>
          <Text fontWeight="bold" mb={1}>
            JSONパス構文
          </Text>
          <Controller
            control={methods.control}
            name="path"
            render={() => (
              <FormControl>
                <RhfInput
                  bgColor="white"
                  name="path"
                  placeholder="Enter JSON path"
                  onChange={handlePathChange}
                />
                <FormErrorMessage>
                  {methods.formState.errors.path &&
                    methods.formState.errors.path.message}
                </FormErrorMessage>
              </FormControl>
            )}
          />

          <Button
            mt={2}
            onClick={onReset}
            colorScheme="primary"
            variant="outline"
            bg="white"
          >
            リセット
          </Button>
          <Grid
            templateColumns="50% 50%"
            templateRows="1fr"
            mt={4}
            gap={4}
            w="100%"
          >
            <GridItem>
              <Box position="relative">
                <Controller
                  control={methods.control}
                  name="jsonData"
                  render={({ field: { ...fieldProps } }) => (
                    <FormControl>
                      <Text fontWeight="bold" mb={2}>
                        JSON データ
                      </Text>
                      <CodeMirror
                        {...fieldProps}
                        basicSetup={{
                          autocompletion: true,
                          syntaxHighlighting: true,
                          highlightSelectionMatches: true,
                        }}
                        theme={vscodeDark}
                        minHeight="500px"
                        width="100%"
                        extensions={[json()]}
                      />
                      <FormErrorMessage>
                        {methods.formState.errors.jsonData &&
                          methods.formState.errors.jsonData.message}
                      </FormErrorMessage>
                    </FormControl>
                  )}
                />
              </Box>
            </GridItem>
            <GridItem>
              <Box>
                <Text fontWeight="bold" mb={2}>
                  解析結果
                </Text>
                <CodeMirror
                  editable={false}
                  value={parseData}
                  basicSetup={{
                    autocompletion: true,
                    syntaxHighlighting: true,
                  }}
                  theme={vscodeDark}
                  minHeight="500px"
                  width="100%"
                  extensions={[json()]}
                />
              </Box>
            </GridItem>
          </Grid>
        </form>
      </Box>
    </FormProvider>
  );
};
