import { getTenantUsers } from 'admin/api/tenantUsers/getTenantUsers';
import { CustomError } from 'admin/error/CustomError';
import { useUserCorporationId } from 'admin/hooks/user/useUserCorporationId';
import { logger } from 'api/logger';
import { useQuery } from 'react-query';
import { queryTenantUsersKey } from './queryTenantUsersKey';

export const useTenantUsers = () => {
  const corporationId = useUserCorporationId();
  const { data } = useQuery({
    queryKey: queryTenantUsersKey.getTenantUsers(corporationId),
    queryFn: () => getTenantUsers(corporationId),

    onError: async (error) => {
      if (error instanceof CustomError) {
        await logger({
          loglevel: error.logLevel,
          data: JSON.stringify(error.cause),
          message: error.message,
        });
      }
    },
  });

  return {
    data,
  };
};
