import { withSuspenseAndErrorBoundary } from 'admin/components/Error/WithErrorBoundary';
import { TransactionMailSettingBody } from 'admin/components/idPoolConsumer/transactionMail/transactionMailSettingBody';
import { TransactionMailSettingTitle } from 'admin/components/idPoolConsumer/transactionMail/transactionMailSettingTitle';
import { Breadcrumb } from 'admin/components/ui/breadcrumb';
import { Truncate } from 'admin/components/ui/truncate';
import { useUserPoolId } from 'admin/hooks/params/useUserPoolId';
import { useUserPool } from 'admin/hooks/userPool/useUserPool';
import { BreadcrumbType } from 'admin/types/ui/breadcrumb';
import { ErrorContents, LoadingSpinner } from 'components/common/atoms';
import { useSetPageTitle } from 'hooks/useSetPageTitle';
import { FC, memo } from 'react';

const transactionMailSetting: FC = memo(() => {
  useSetPageTitle('トランザクションメール設定');
  const userPoolId = useUserPoolId();
  const { data: userPool } = useUserPool(userPoolId);
  const breadcrumbItems: BreadcrumbType[] = [
    { url: '/admin', title: '組織管理TOP' },
    {
      url: '/admin/idPoolConsumer',
      title: 'IDシステム設定管理',
    },
    {
      url: '',
      title: <Truncate>{userPool?.name || ''}</Truncate>,
    },
    { url: '', title: 'トランザクションメール設定' },
  ];

  return (
    <>
      <Breadcrumb breadcrumbItems={breadcrumbItems} />
      <TransactionMailSettingTitle />
      <TransactionMailSettingBody />
    </>
  );
});

export const TransactionMailSetting = withSuspenseAndErrorBoundary(
  transactionMailSetting,
  {
    ErrorComponent: <ErrorContents name="トランザクションメール設定" />,
    LoadingComponent: <LoadingSpinner />,
  },
);
