import {
  Box,
  Button,
  Drawer,
  Flex,
  Grid,
  GridItem,
  Text,
  useDisclosure,
} from '@chakra-ui/react';
import { withSuspenseAndErrorBoundary } from 'admin/components/Error/WithErrorBoundary';
import { Breadcrumb } from 'admin/components/ui/breadcrumb';
import { BreadcrumbType } from 'admin/types/ui/breadcrumb';
import { LoadingSkeleton } from 'components/common/atoms';
import { ListError } from 'components/common/molecules';
import { Colors } from 'components/paywall/config';
import { MailSettingDrawer } from 'components/transactionMail/MailSettingDrawer';
import { useMailSetting } from 'hooks/transactionMail/useMailSetting';
import { useSetPageTitle } from 'hooks/useSetPageTitle';
import { FC } from 'react';
import { NavLink } from 'react-router-dom';

export const FetchTransactionMailSetting: FC = () => {
  useSetPageTitle('送信内容設定');
  const breadcrumbItems: BreadcrumbType[] = [
    { url: '/transactionMail', title: '初回ログイン時メール設定' },
  ];
  const { isOpen, onClose, onOpen } = useDisclosure();
  const { data: mailSetting } = useMailSetting();

  return (
    <Box w="100%" px="8px" marginTop={0}>
      <Flex w="100%" justifyContent="start" pt={3} mb={8}>
        <Breadcrumb breadcrumbItems={breadcrumbItems} />
      </Flex>
      <Flex w="100%" justifyContent="space-between" mb={2}>
        <Text fontSize="18px" fontWeight={700}>
          メール設定
        </Text>
        <Button onClick={onOpen} variant="primary" py="6px" px="20px">
          メール設定
        </Button>
      </Flex>
      <Grid
        w="100%"
        templateColumns="auto 1fr"
        templateRows="auto 1fr"
        gap="8px 16px"
        mb="16px"
        p="16px"
        borderRadius="8px"
        border={`solid 1px ${Colors.GRAY_40}`}
      >
        <GridItem color={Colors.GRAY_00}>差出人メールアドレス</GridItem>
        <GridItem>
          <Text fontWeight="bold">{mailSetting?.fromAddress || '-'}</Text>
        </GridItem>
        <GridItem color={Colors.GRAY_00}>返信用メールアドレス</GridItem>
        <GridItem>
          <Text fontWeight="bold">{mailSetting?.replyAddress || '-'}</Text>
        </GridItem>
      </Grid>
      <Flex
        w="100%"
        justifyContent="space-between"
        mb={4}
        pb={4}
        borderBottom={`solid 1px ${Colors.GRAY_40}`}
      >
        <Text fontSize="18px" fontWeight={700}>
          送信内容設定
        </Text>
        <NavLink to="./list">
          <Button variant="primary" py="6px" px="20px">
            送信内容設定
          </Button>
        </NavLink>
      </Flex>
      <Drawer
        isOpen={isOpen}
        placement="right"
        onClose={onClose}
        size="lg"
        closeOnOverlayClick={false}
        closeOnEsc={false}
        autoFocus={false}
      >
        <MailSettingDrawer onClose={onClose} />
      </Drawer>
    </Box>
  );
};

export const TransactionMailSetting = withSuspenseAndErrorBoundary(
  FetchTransactionMailSetting,
  {
    ErrorComponent: <ListError message="フォーム一覧を表示できません" />,
    LoadingComponent: <LoadingSkeleton width="100%" />,
  },
);
