import { getUsers } from 'admin/api/users/getUsers';
import { CustomError } from 'admin/error/CustomError';
import { useUserCorporationId } from 'admin/hooks/user/useUserCorporationId';
import { queryUsersKey } from 'admin/hooks/users/queryUsersKey';
import { UserPoolType } from 'admin/types/user';
import { logger } from 'api/logger';
import { useCustomToast } from 'hooks/useCustomToast';
import { useQuery } from 'react-query';
import { errorToast } from 'utils/toast';

/**
 * 組織アカウント一覧APIから取得したものから、ユーザープール情報を取り出すhooks
 * ※組織アカウント管理のドロワーのユーザープール権限表示の為に使用するため、配列のいずれかのユーザープール情報の取得が出来ればよい。
 * そのため配列の[0]の情報を使用する。
 *
 * なお、`UserListType[]`型は必ず1件以上データが存在することを前提としている
 */
export const useUserPoolPermissions = (): UserPoolType[] => {
  const toast = useCustomToast();
  const corporationId = useUserCorporationId();

  const { data } = useQuery({
    queryKey: queryUsersKey.getUsers(corporationId),
    queryFn: () => getUsers({id: corporationId}),
    onError: async (error) => {
      if (error instanceof CustomError) {
        await logger({
          loglevel: error.logLevel,
          data: JSON.stringify(error.cause),
          message: error.message,
        });
      }
      toast({
        ...errorToast,
        duration: 4000,
        title: 'ユーザー一覧取得に失敗しました',
        position: 'bottom',
        variant: 'solid',
      });
    },
  });

  if (data === undefined) return [];

  const userPoolTypes: UserPoolType[] = data[0].userPools.map(
    (userPool) => (
      { ...userPool, permission: [] }
    )
  );

  return userPoolTypes;
};
