import { logger } from 'api/logger';
import { request } from 'api/request';
import { isResponseError, ResponseError } from 'api/types';
import {
  isWallItem, WallItem, WallItemFormType, WallItemSubmitFormType
} from 'components/paywall/pages/WallItemList/typed';
import produce from 'immer';
import { Options } from 'ky';
import { getValidationError, ValidationError } from 'utils/form';
import { messages } from './messages';


const DEFAULT_ERR_MESSAGE: ValidationError<WallItemSubmitFormType> = {
  others: [messages.patchWallItem.customer.error],
};
const DEFAULT_SYSTEM_ERR_MESSAGE: ValidationError<WallItemSubmitFormType> = {
  others: [messages.patchWallItem.customer.failed],
};

/**
 * 単品商品変更 API
 * @param {WallItemSubmitFormType} formParam API送信パラメータ
 * @param {Options} options APIオプション
 * @return {Promise<WallItem | ResponseError>} 単品商品 | レスポンスエラー
 */
export const patchWallItem = async (
  formParam: WallItemSubmitFormType,
  options?: Options,
): Promise<WallItem | ResponseError> => {
  const { tenantId, wallId, wallItemForm } = formParam;
  const path = `paywall/${tenantId}/${wallId}/item/${wallItemForm.id || ''}/`;
  const postParam = wallItemForm;

  const mergedOptions = options
    ? produce(options, (draft) => {
        draft.json = { ...postParam };
      })
    : { json: postParam };

  const response = await request({
    path,
    method: 'patch',
    options: mergedOptions,
  });

  if (response.status >= 500) {
    return DEFAULT_ERR_MESSAGE;
  }

  const responseData = response.data;

  // 単品商品保存成功
  if (isWallItem(responseData)) {
    return responseData;
  }

  const { error } = response;
  if (response.hasError) {
    // APIにて返却されたエラー形式
    if (isResponseError(error)) {
      const formError = getValidationError<WallItemFormType>({
        formData: formParam.wallItemForm,
        response: error,
      });

      return formError;
    }
  }

  // 予期せぬエラーなので
  // バックエンドに状態を通知
  await logger({
    loglevel:
      response.status === 404 || response.status === 450 ? 'warning' : 'error',
    data: error,
    message: messages.patchWallItem.system.typeError,
  });

  // 登録できていない旨を通知
  return DEFAULT_SYSTEM_ERR_MESSAGE;
};
