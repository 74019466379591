import { Box } from '@chakra-ui/react';
import { Colors } from 'components/paywall/config';
import { FC, memo, useMemo } from 'react';
import { useSearchParams } from 'react-router-dom';

export const LoginErrorMessage: FC = memo(() => {
  const [searchParams] = useSearchParams();
  const errorMessage = useMemo(() => searchParams.get('message'), [searchParams]);

  if(!errorMessage) return <></>;

  return (
    <Box
      width="100%"
      padding="4px 2.3px"
      border="2px solid"
      borderColor={Colors.RED_20}
      backgroundColor={Colors.RED_40}
      color={Colors.RED_01}
      fontSize="xs"
      mt="0.5rem"
    >
      {errorMessage}
    </Box>
  )
});
