import { zodResolver } from '@hookform/resolvers/zod';
import { withSuspenseAndErrorBoundary } from 'admin/components/Error/WithErrorBoundary';
import { TenantAppendFormComponent } from 'admin/components/service/newsletter/tenantAppend/tenantAppendForm/TenantAppendFormComponent';
import { LoadingLayer } from 'admin/components/ui/loadingLayer';
import { useChangeServiceNewsletterTenantAppend } from 'admin/hooks/service/newsletter/useChangeServiceNewsletterTenantAppend';
import { useUserPoolChildId } from 'admin/hooks/useUserPoolChildId';
import { confirmSubmitFormSchema } from 'admin/schema/util';
import { ServiceNewsletterType } from 'admin/types/service/newsletter';
import { ServiceNewsletterFormType } from 'admin/types/service/newsletter/form';
import { FC, memo, useCallback } from 'react';
import { FormProvider, useForm } from 'react-hook-form';

export type Props = {
  newsletter: ServiceNewsletterType | undefined;
  onClose: () => void;
};

export const tenantAppendForm: FC<Props> = memo(({ newsletter, onClose }) => {
  const formId = 'tenantAppendForm';
  const userPoolChildId = useUserPoolChildId();

  const { mutate, isLoading } = useChangeServiceNewsletterTenantAppend();

  const methods = useForm({
    defaultValues: {
      word: '',
    },
    mode: 'onBlur',
    resolver: zodResolver(confirmSubmitFormSchema),
  });

  const onSubmit = useCallback(async () => {
    // 送信用データ生成
    const submitData: ServiceNewsletterFormType = {
      userPoolChild: userPoolChildId,
      newsletter: newsletter?.id || '',
      isDeliveryAvailable: false,
      isDisplayWhenRegistration: false,
      isCheckWhenRegistration: false,
    };
    await mutate(submitData);
    onClose();
  }, [mutate, onClose, newsletter]);

  return (
    <FormProvider {...methods}>
      {isLoading && <LoadingLayer />}
      <TenantAppendFormComponent
        formId={formId}
        isLoading={isLoading}
        onClose={onClose}
        onSubmit={onSubmit}
      />
    </FormProvider>
  );
});

export const TenantAppendForm = withSuspenseAndErrorBoundary(tenantAppendForm);
