import {
  Box,
  DrawerCloseButton,
  DrawerContent,
  DrawerHeader,
  DrawerOverlay,
  Text,
} from '@chakra-ui/react';
import { withSuspenseAndErrorBoundary } from 'admin/components/Error/WithErrorBoundary';
import {
  AttributeCreateForm,
  AttributeEditForm,
} from 'admin/components/idPoolConsumer/attribute/attributeForm';
import { useIdPoolConsumerId } from 'admin/hooks/useIdPoolConsumerId';
import { useIdPoolConsumerAttributeDetail } from 'admin/hooks/userPool/attribute/useIdPoolConsumerAttributeDetail';
import { ErrorContents, LoadingSpinner } from 'components/common/atoms';
import { FC, memo } from 'react';

type AttributeCreateDrawerProps = {
  onClose: () => void;
};

export const AttributeCreateDrawer: FC<AttributeCreateDrawerProps> = memo(
  ({ onClose }: AttributeCreateDrawerProps) => (
    <>
      <DrawerOverlay />
      <DrawerContent>
        <DrawerHeader pb={0}>
          <Box m={0} px={0} pb="24px" borderBottomWidth="1px">
            <Text>属性作成</Text>
          </Box>
        </DrawerHeader>
        <AttributeCreateForm onClose={onClose} />
        <DrawerCloseButton top="16px" right="24px" />
      </DrawerContent>
    </>
  ),
);

type AttributeEditDrawerProps = {
  id: string;
  onClose: () => void;
};

const fetchAttributeEditDrawer: FC<AttributeEditDrawerProps> = memo(
  ({ id, onClose }: AttributeEditDrawerProps) => {
    const userPoolId = useIdPoolConsumerId();

    const { data: attributes } = useIdPoolConsumerAttributeDetail(
      id,
      userPoolId,
    );

    if (!attributes) {
      return null;
    }

    return <AttributeEditForm attribute={attributes} onClose={onClose} />;
  },
);

export const FetchAttributeEditDrawer = withSuspenseAndErrorBoundary(
  fetchAttributeEditDrawer,
  {
    ErrorComponent: <ErrorContents name="組織レベル属性詳細" />,
    LoadingComponent: <LoadingSpinner />,
  },
);

export const AttributeEditDrawer: FC<AttributeEditDrawerProps> = memo(
  (props: AttributeEditDrawerProps) => (
    <>
      <DrawerOverlay />
      <DrawerContent>
        <DrawerHeader pb={0}>
          <Box m={0} px={0} pb="24px" borderBottomWidth="1px">
            <Text>属性編集</Text>
          </Box>
        </DrawerHeader>
        <FetchAttributeEditDrawer {...props} />
        <DrawerCloseButton top="16px" right="24px" />
      </DrawerContent>
    </>
  ),
);
