import {
  headTitleOptions,
  screenDescriptionOptions,
  screenNameOptions,
  buttonNameOptions,
  textareaElementOptions,
} from './options';

const groupName = 'passwordUnset';

// パスワード未設定画面
export default [
  // ロゴ	画像
  {
    name: `${groupName}.headTitle`,
    label: 'ブラウザタブ表示名',
    tooltip: '',
    type: 'Text',
    defaultValue: 'パスワード未設定',
    options: headTitleOptions,
    elementOptions: {},
  },
  {
    name: `${groupName}.screenName`,
    label: '画面名',
    tooltip: '',
    type: 'Text',
    defaultValue: 'パスワード未設定',
    options: screenNameOptions,
    elementOptions: {},
  },
  {
    name: `${groupName}.screenDescription`,
    label: '案内文 画面',
    tooltip: '',
    type: 'Textarea',
    defaultValue:
      'このアカウントではパスワードが設定されていません。\n\n「続ける」を押して、パスワードの設定を行ってください。',
    options: screenDescriptionOptions,
    elementOptions: textareaElementOptions,
  },
  {
    name: `${groupName}.continueButtonName`,
    label: 'ボタン名 次の画面に遷移',
    tooltip: '',
    type: 'Text',
    defaultValue: '続ける',
    options: buttonNameOptions,
    elementOptions: {},
  },
  {
    name: `${groupName}.backButtonName`,
    label: 'ボタン名 前の画面に遷移',
    tooltip: '',
    type: 'Text',
    defaultValue: '戻る',
    options: buttonNameOptions,
    elementOptions: {},
  },
] as const;
