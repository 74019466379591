import { request } from 'admin/api/Request';
import { API_MESSAGES } from 'admin/define/apiMessages';
import { CustomError } from 'admin/error/CustomError';
import { TransactionMailCommonSchema } from 'admin/schema/idPoolConsumer/transactionMail';
import { TransactionMailCommonType } from 'admin/types/userPool/transactionMail';
import { isResponseError } from 'api/types';
import { getValidationError } from 'utils/form';
import { ZodError } from 'zod';

export const updateTransactionMailCommon = async (
  idPoolConsumerId: string,
  data: TransactionMailCommonType,
): Promise<TransactionMailCommonType> => {
  const path = `uniikey/${idPoolConsumerId}/transaction_mail_commons/`;
  const response = await request<TransactionMailCommonType>({
    path,
    body: data,
    method: 'patch',
  });

  if (response.hasError) {
    if (response.status === 404) {
      throw new CustomError(
        API_MESSAGES.USER_POOL.UPDATE_TRANSACTION_MAIL_COMMON.FAILED,
        {
          path,
          status: response.status,
        },
      );
    } else if (isResponseError(response.error)) {
      const customError = response.error;

      const formError = getValidationError<TransactionMailCommonType>({
        formData: data,
        response: customError,
      });

      throw new CustomError(
        API_MESSAGES.USER_POOL.UPDATE_TRANSACTION_MAIL_COMMON.ERROR,
        {
          path,
          params: data,
          errors: formError,
          status: response.status,
          logLevel: response.status === 400 ? 'warning' : 'error',
        },
      );
    } else {
      throw new CustomError(
        API_MESSAGES.USER_POOL.UPDATE_TRANSACTION_MAIL_COMMON.ERROR,
        {
          path,
          status: response.status,
        },
      );
    }
  }
  try {
    TransactionMailCommonSchema.parse(response.data);
  } catch (error) {
    if (error instanceof ZodError) {
      throw new CustomError(error.message, {
        path,
        status: response.status,
        logLevel: 'error',
      });
    }
  }

  return response.data;
};
