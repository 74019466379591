import { VFC, memo, useState, useEffect } from 'react';
import { useFormContext } from 'react-hook-form';
import { Box, Flex, Text, Stack } from '@chakra-ui/react';
import { InputTextForm, ColorPicker } from 'components/common/atoms';
import { SkinItemPreview } from 'components/enquete/atoms/SkinItemPreview';
import { Skin } from 'api/enquete/types';
import { SkinDefaultValues } from 'components/enquete/EnqueteSkin';

export const SkinFinishedButton: VFC = memo(() => {
  const [finishedButtonTextColor, setFinishedButtonTextColor] = useState(
    SkinDefaultValues.finishedButtonTextColor,
  );
  const [finishedButtonBgColor, setFinishedButtonBgColor] = useState(
    SkinDefaultValues.finishedButtonBgColor,
  );
  const { getValues, watch } = useFormContext<Skin>();

  useEffect(() => {
    const skinViewData = watch((value, { name }) => {
      if (name === 'finishedButtonTextColor') {
        setFinishedButtonTextColor(value[name] || '');
      }
      if (name === 'finishedButtonBgColor') {
        setFinishedButtonBgColor(value[name] || '');
      }
    });

    return () => skinViewData.unsubscribe();
  }, [watch]);

  return (
    <Flex alignItems="stretch" mb={4} mt={4}>
      <Box minW="220px" mt="2px">
        <Text as="label">フォーム回答完了ページ ボタン</Text>
      </Box>
      <Stack minW="250px">
        <Flex alignItems="flex-end">
          <InputTextForm<Skin>
            label="背景色"
            labelCaption="背景色"
            name="finishedButtonBgColor"
            value={getValues('finishedButtonBgColor')}
            placeholder="#FFFFFF"
            mr={4}
          />
          <ColorPicker<Skin>
            name="finishedButtonBgColor"
            value={getValues('finishedButtonBgColor') || '#FFFFFF'}
            width="40px"
            height="40px"
          />
        </Flex>
        <Flex alignItems="flex-end">
          <InputTextForm<Skin>
            label="文字色"
            labelCaption="文字色"
            name="finishedButtonTextColor"
            value={getValues('finishedButtonTextColor')}
            placeholder="#FFFFFF"
            mr={4}
          />
          <ColorPicker<Skin>
            name="finishedButtonTextColor"
            value={getValues('finishedButtonTextColor') || '#FFFFFF'}
            width="40px"
            height="40px"
          />
        </Flex>
      </Stack>
      <SkinItemPreview
        element={
          <Box
            borderRadius="6px"
            fontSize="14px"
            p="5px 20px"
            textAlign="center"
            color={finishedButtonTextColor}
            bgColor={finishedButtonBgColor}
          >
            閉じる
          </Box>
        }
        width="auto"
      />
    </Flex>
  );
});
