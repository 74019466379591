import { Colors } from 'components/paywall/config';
import { Detail, DetailBlock, DetailFlex, DetailItem, DetailItems } from 'components/paywall/features/Detail';
import { DetailCustomerLinkText } from 'components/paywall/features/Detail/DetailCustomer';
import {
  SubscriptionDetail
} from 'components/paywall/pages/SubscriptionList/typed';
import { FC, memo } from 'react';

type Props = {
  detail: SubscriptionDetail | undefined;
}

export const SubscriptionDetailInnerCustomer: FC<Props> = memo(({detail} : Props) => (
  <>
    <Detail type={1} title="顧客情報" customerHelp>
      <DetailBlock space={16}>
        <DetailFlex>
          <DetailItems spacing="8px">
            <DetailItem
              label="顧客ID"
              text={<DetailCustomerLinkText path={`/paywall/${detail?.customerUid || ''}/CustomerDetail/`} text={detail?.customerUid || ''} />}
              labelWidth="56px"
              fontColor={Colors.BLUE_20}
              textDecoration="underline"
              spacing="12px"
              isCustomerActive={detail?.customer?.isActive}
            />
            <DetailItem label="姓名" text={`${detail?.customer?.lastName || ''} ${detail?.customer?.firstName || ''}`} labelWidth="56px" spacing="12px" />
          </DetailItems>
          <DetailItems spacing="8px">
            <DetailItem label="メールアドレス" text={detail?.email || ''} labelWidth="98px" spacing="12px" />
            <DetailItem label="電話番号" text={detail?.customer?.tel || ''} labelWidth="98px" spacing="12px" />
          </DetailItems>
        </DetailFlex>
      </DetailBlock>
    </Detail>
  </>
));
