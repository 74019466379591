import { useCallback } from 'react';
import { useParams } from 'react-router-dom';
import { useUserTenantId } from 'hooks/user/useUserTenantId';
import { usePaywallDesignPublish } from 'hooks/paywall/usePaywallDesignPublish';

/**
 * ペイウォール画面hooks
 */
export const usePaywallDesign = () => {
  const tenantId = useUserTenantId();
  const params = useParams();
  const { onSubmit } = usePaywallDesignPublish();

  /**
   * ステータス変更処理
   * @param {string} id 該当ID
   * @param {boolean} status ステータス
   */
  const onActivate = useCallback(
    (id: string, status: boolean) => {
      onSubmit({
        id,
        tenant: tenantId,
        wallId: params.wallId || '',
        status: !status,
      });
    },
    [tenantId, params, onSubmit],
  );

  return { onActivate };
};
