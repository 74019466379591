import { Box, Tag } from '@chakra-ui/react';
import styled from '@emotion/styled';
import { TextButton } from 'components/paywall/atoms/TextButton';
import { Colors } from 'components/paywall/config';
import {
  BiAt,
  BiCalendarAlt,
  BiChevronDown,
  BiChevronUp,
  BiPurchaseTagAlt,
  BiRadioCircleMarked,
  BiReset,
  BiSearchAlt,
  BiText,
  BiYen
} from 'react-icons/bi';
import { MdClose, MdOutlineCheck, MdOutlineError } from 'react-icons/md';

export const StyledPromotionTag = styled(Tag)({
  fontWeight: 'bold',
});

export const StyledTextButton = styled(TextButton)({
  color: '#06f',
  fontSize: 14,
  textDecoration: 'none',
  height: 30,
});

export const StyledBorderRight = styled(Box)({
  height: 'calc(100vh + 20px)',
  borderRight: '1px solid #6699cc',
  opacity: 0.5,
});

export const StyledPaddingBox = styled(Box)({
  paddingLeft: 45,
  paddingRight: 95,
});

export const StyledTimelineFlex = styled(Box)({
  display: 'flex',
  height: '100%',
});

export const StyledTimelineDot = styled(Box)({
  position: 'relative',
  marginLeft: -9,
  color: '#ccc',
});

export const StyledIconUp = styled(BiChevronUp)({
  width: 16,
  height: 16,
  backgroundColor: '#eee',
  borderRadius: '4px',
  color: '#999',
  '&:hover': { backgroundColor: '#ccc', cursor: 'pointer', color: '#666' },
});

export const StyledIconDown = styled(BiChevronDown)({
  width: 16,
  height: 16,
  backgroundColor: '#eee',
  borderRadius: '4px',
  color: '#999',
  '&:hover': { backgroundColor: '#ccc', cursor: 'pointer', color: '#666' },
});

export const StyledIconText = styled(BiText)({
  width: 16,
  height: 16,
  backgroundColor: '#eee',
  borderRadius: '4px',
  color: '#999',
  '&:hover': { backgroundColor: '#ccc', cursor: 'pointer', color: '#666' },
});

export const StyledIconCalendar = styled(BiCalendarAlt)({
  width: 16,
  height: 16,
  backgroundColor: '#eee',
  borderRadius: '4px',
  color: '#999',
  '&:hover': { backgroundColor: '#ccc', cursor: 'pointer', color: '#666' },
});

export const StyledIconPrice = styled(BiYen)({
  width: 16,
  height: 16,
  backgroundColor: '#eee',
  borderRadius: '4px',
  color: '#999',
  '&:hover': { backgroundColor: '#ccc', cursor: 'pointer', color: '#666' },
});

export const StyledIconMail = styled(BiAt)({
  width: 16,
  height: 16,
  backgroundColor: '#eee',
  borderRadius: '4px',
  color: '#999',
  '&:hover': { backgroundColor: '#ccc', cursor: 'pointer', color: '#666' },
});

export const StyledIconRadio = styled(BiRadioCircleMarked)({
  width: 16,
  height: 16,
  backgroundColor: '#eee',
  borderRadius: '4px',
  color: '#999',
  '&:hover': { backgroundColor: '#ccc', cursor: 'pointer', color: '#666' },
});

export const StyledIconItem = styled(BiPurchaseTagAlt)({
  width: 16,
  height: 16,
  backgroundColor: '#eee',
  borderRadius: '4px',
  color: '#999',
  '&:hover': { backgroundColor: '#ccc', cursor: 'pointer', color: '#666' },
});

export const StyledIconSearch = styled(BiSearchAlt)({
  padding: 4,
  width: 24,
  height: 24,
  backgroundColor: Colors.GRAY_50,
  borderRadius: '12px',
  '&:hover': { backgroundColor: '#09f', cursor: 'pointer', color: '#fff' },
});

export const StyledIconReset = styled(BiReset)({
  padding: 4,
  width: 24,
  height: 24,
  color: '#afafaf',
  backgroundColor: '#e2e2e2',
  borderRadius: '6px',
  '&:hover': { backgroundColor: '#09f', cursor: 'pointer', color: '#fff' },
});

export const StyledIconClose = styled(MdClose)({
  padding: 2,
  width: 16,
  height: 16,
  color: '#999',
  backgroundColor: '#ccc',
  borderRadius: '10px',
  '&:hover': { backgroundColor: '#666', cursor: 'pointer', color: '#fff' },
});

export const StyledIconClosePopper = styled(MdClose)({
  padding: 2,
  width: 20,
  height: 20,
  color: '#999',
  backgroundColor: '#ccc',
  borderRadius: '5px',
  '&:hover': { backgroundColor: '#666', cursor: 'pointer', color: '#fff' },
});

export const StyledIconError = styled(MdOutlineError)({
  padding: 2,
  width: 24,
  height: 24,
  color: '#f00',
});

export const StyledIconSuccess = styled(MdOutlineCheck)({
  padding: 2,
  width: 20,
  height: 20,
  color: '#BAD1EC',
});
