import { Select as ChakraSelect, SelectProps } from '@chakra-ui/react';
import { ChangeEvent, FC, memo } from 'react';

type Props = {
  defaultValue?: SelectProps['defaultValue'];
  selectedValue?: string | number;
  array: { val: string | number; name: string }[];
  onChange?: (e?: ChangeEvent<HTMLInputElement>) => void;
} & SelectProps;

/**
 * @see 汎用セレクト
 * @param defaultValue 初期表示内容
 * @param selectedValue 選択されている内容
 * @param array セレクトの項目配列
 * @param onChange セレクト変更時のイベントハンドラ
 */
export const Select: FC<Props> = memo(
  ({ defaultValue, selectedValue, array, onChange, ...props }) => (
    <ChakraSelect
      defaultValue={defaultValue}
      value={selectedValue}
      onChange={onChange}
      {...props}
    >
      {array.map((data) => (
        <option value={data.val} key={data.val}>
          {data.name}
        </option>
      ))}
    </ChakraSelect>
  ),
);
