import { List, VStack } from '@chakra-ui/react';
import { IdPoolConsumerBlock } from 'admin/components/idPoolConsumer/idPoolConsumerBlock';
import { IdPoolConsumerList } from 'admin/types/userPool';
import { FC, memo } from 'react';

type IdPoolListsComponentProps = {
  idPoolConsumersList: IdPoolConsumerList;
};

export const IdPoolConsumerListsComponent: FC<IdPoolListsComponentProps> = memo(
  ({ idPoolConsumersList }: IdPoolListsComponentProps) => (
    <List>
      <VStack spacing="8">
        {idPoolConsumersList.map((idPoolConsumer) => (
          <IdPoolConsumerBlock
            idPoolConsumer={idPoolConsumer}
            key={idPoolConsumer.id}
          />
        ))}
      </VStack>
    </List>
  ),
);
