import { useState, useEffect } from 'react';
import { useQuery } from 'react-query';
import { NodeModel } from '@minoru/react-dnd-treeview';
import { getContentsTableList } from 'api/contents/getContentsTableList';
import { ContentsTable } from 'api/contents/types';
import { getGroupList } from 'api/tenant/getGroupList';
import { useCustomToast } from 'hooks/useCustomToast';
import { contentsDbQueryKey } from './queryKey';

// ツリーの直下を示すノードID定義
export const ROOT_NODE = '';

export const useTableList = (tenantId: string): NodeModel<ContentsTable>[] => {
  const [treeData, setTreeData] = useState<NodeModel<ContentsTable>[]>([]);

  const toast = useCustomToast();
  const tblListresponse = useQuery(
    contentsDbQueryKey.tableList({ tenantId }),
    () => getContentsTableList(tenantId),
    {
      onError: (err: unknown) => {
        if (err instanceof Error) {
          toast.closeAll();
          toast({
            title: err.message,
            status: 'error',
          });
        }
      },
    },
  );
  const { data: contentsTableList } = tblListresponse;

  const grpListResponse = useQuery(
    ['tenant/group', tenantId],
    () => getGroupList(tenantId),
    {
      onError: (err: unknown) => {
        if (err instanceof Error) {
          toast.closeAll();
          toast({
            title: err.message,
            status: 'error',
          });
        }
      },
    },
  );

  const { data: groupList } = grpListResponse;

  useEffect(() => {
    if (contentsTableList === undefined) {
      setTreeData([]);

      return;
    }

    // グループをNode化
    const treeGroupList: NodeModel<ContentsTable>[] = groupList
      ? groupList.reduce((acc, current) => {
          // 既に登録済みの場合スキップ
          if (acc.some((t) => t.id === current.id)) {
            return acc;
          }

          // 親が存在するかどうかチェック
          const hasParent = (): boolean =>
            groupList.some(
              (u) =>
                u.id !== current.id &&
                current.parentId !== null &&
                u.id === current.parentId,
            );

          acc.push({
            id: current.id,
            parent:
              current.parentId && hasParent() ? current.parentId : ROOT_NODE,
            droppable: false,
            text: current.name,
          });

          return acc;
        }, [] as NodeModel<ContentsTable>[])
      : [];

    const tblList: NodeModel<ContentsTable>[] = contentsTableList.map(
      (tbl) => ({
        id: tbl.id,
        parent: tbl.group ? tbl.group : ROOT_NODE,
        droppable: false,
        text: tbl.title,
        data: tbl,
      }),
    );

    // グループを上位表示して、下位にテーブルを表示するため
    // スプレッド構文はグループを最初にしてます
    setTreeData([...treeGroupList, ...tblList]);
  }, [setTreeData, contentsTableList, groupList]);

  return treeData;
};
