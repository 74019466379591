import { useQuery } from 'react-query';
import { MailMagazine } from 'components/setting/mailMagazine/typed';
import { useCustomToast } from 'hooks/useCustomToast';
import { getMailMagazineList } from 'api/mail/getMailMagazineList';
import { useUserTenantId } from 'hooks/user/useUserTenantId';
import { queryMailKey } from './queryMailKey';

/**
 * メールマガジン一覧取得
 * @return {Promise<MailMagazine[]>} メールマガジン一覧
 */
export const useMailMagazineList = (type: 'all' | '' = ''): MailMagazine[] => {
  const toast = useCustomToast();
  const tenantId = useUserTenantId();
  const response = useQuery(
    queryMailKey.getMailMagazineList({ tenantId, type }),
    () => getMailMagazineList(tenantId, type),
    {
      onError: (err: unknown) => {
        if (err instanceof Error) {
          toast.closeAll();
          toast({
            title: err.message,
            status: 'error',
          });
        }
      },
    },
  );

  const { data } = response;

  return data as MailMagazine[];
};
