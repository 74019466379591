import {
  Box,
  DrawerCloseButton,
  DrawerContent,
  DrawerHeader,
  DrawerOverlay,
  Text,
} from '@chakra-ui/react';
import { withSuspenseAndErrorBoundary } from 'admin/components/Error/WithErrorBoundary';
import { CallbackUrlSettingForm } from 'admin/components/service/auth/callbackUrlSettingForm';
import { useAuth } from 'admin/hooks/service/auth/useAuth';
import { ErrorContents, LoadingSpinner } from 'components/common/atoms';
import { FC, memo } from 'react';

type Props = {
  onClose: () => void;
};

export const fetchCallbackUrlDrawer: FC<Props> = memo(({ onClose }: Props) => {
  const { data: authDetail } = useAuth();

  if (!authDetail) {
    return null;
  }

  return <CallbackUrlSettingForm authDetail={authDetail} onClose={onClose} />;
});

export const FetchApiWhiteListDrawer = memo(
  withSuspenseAndErrorBoundary(fetchCallbackUrlDrawer, {
    ErrorComponent: <ErrorContents name="コールバックURL設定ドロワー" />,
    LoadingComponent: <LoadingSpinner />,
  }),
);

export const CallbackUrlDrawer: FC<Props> = memo(({ onClose }: Props) => (
  <>
    <DrawerOverlay />
    <DrawerContent>
      <DrawerHeader pb={0}>
        <Box m={0} px={0} pb="24px" borderBottomWidth="1px">
          <Text>コールバックURL設定</Text>
        </Box>
      </DrawerHeader>
      <FetchApiWhiteListDrawer onClose={onClose} />
      <DrawerCloseButton top="16px" right="24px" />
    </DrawerContent>
  </>
));
