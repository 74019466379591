import { FC, memo } from 'react';
import { Box, Text, Link } from '@chakra-ui/react';
import { Container } from 'components/setting/apikey/templates/Container';

export const ApikeyIssue: FC = memo(() => (
  <Container text="APIキーの発行">
    <>
      <Box fontSize={13}>
        <Text>
          API・アクセス・シークレットキーは、各テナントからのご要望に応じて発行し提供いたします。
        </Text>
        <Text>
          有効化をご希望の場合は、Uniikeyzサポートデスクまでお問い合わせください。
        </Text>
        <Text mt={6}>&lt;Uniikeyzサポートデスク&gt;</Text>
        <Text>
          Email:{' '}
          <Link href="mailto:support@uniikey-cloud.com">
            support@uniikey-cloud.com
          </Link>
        </Text>
      </Box>
    </>
  </Container>
));
