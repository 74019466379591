import { SearchIcon } from '@chakra-ui/icons';
import { Box, Button, Input, Select } from '@chakra-ui/react';
import { Colors } from 'admin/define/colors';
import { useTenantList } from 'admin/hooks/tenantUsers/useTenantList';
import { FC, KeyboardEvent, memo, useState } from 'react';

type Props = {
  onSearch: (keyword: string, tenantId: string) => void;
};

export const TenantUsersFilter: FC<Props> = memo(({ onSearch }: Props) => {
  const [searchKeyword, setsearchKeyword] = useState('');
  const [tenantId, setTenantId] = useState('');
  const { data: tenantList } = useTenantList();

  const onChangeSelect = (e: React.ChangeEvent<HTMLSelectElement>) => {
    setTenantId(e.target.value);
  };

  const onKeyDown = (e: KeyboardEvent<HTMLInputElement>) => {
    if (e.key === 'Enter') {
      onSearch(searchKeyword, tenantId);
    }
  };

  if (!tenantList) {
    return null;
  }

  return (
    <Box
      display="grid"
      alignItems="center"
      gridTemplateColumns="1fr auto auto"
      gridGap={4}
    >
      <Box>
        <Input
          placeholder="ユーザー名・メールアドレスで検索"
          value={searchKeyword}
          onChange={(e) => setsearchKeyword(e.target.value)}
          bgColor="WHITE"
          onKeyDown={onKeyDown}
        />
      </Box>
      <Box>
        <Select
          bgColor={Colors.WHITE}
          value={tenantId}
          width="215px"
          maxWidth="215px"
          placeholder="テナント名で絞り込む"
          onChange={(e) => onChangeSelect(e)}
        >
          {tenantList.map((tenant) => (
            <option
              key={`tenantUsersServiceName${tenant.id}`}
              value={tenant.id}
            >
              {tenant.name}
            </option>
          ))}
        </Select>
      </Box>
      <Box>
        <Button
          variant="outline"
          colorScheme="primary"
          leftIcon={<SearchIcon />}
          onClick={() => onSearch(searchKeyword, tenantId)}
          h="37px"
          ml="8px"
          bgColor="WHITE"
        >
          検索
        </Button>
      </Box>
    </Box>
  );
});
