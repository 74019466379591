import { Box, Flex, Text, useDisclosure } from '@chakra-ui/react';
import ErrorBoundary from 'api/ErrorBoundary';
import { Link } from 'components/paywall/atoms/Link';
import { Colors } from 'components/paywall/config';
import { LoginRedirectContents } from 'components/setting/loginRedirect/loginRedirectContents';
import { LoginRedirectTitle } from 'components/setting/loginRedirect/loginRedirectTitle';
import { useErrorBoundaryReset } from 'hooks/useErrorBoundaryReset';
import { useSetPageTitle } from 'hooks/useSetPageTitle';
import { FC, memo } from 'react';

export const LoginRedirect:FC = memo(() => {
  const { ebKey, onError } = useErrorBoundaryReset();
  const { isOpen, onClose, onOpen } = useDisclosure();
  useSetPageTitle('ログインリダイレクト設定');

  return(
    <Box maxW="calc(100vw - 413px)">
      <Flex mt="20px">
        <Link to="/setting">
          <Flex>
            <Text fontSize="14px" color={Colors.GRAY_20} fontWeight="700" cursor='pointer'>設定TOP</Text>
            <Text as="span" color={Colors.BLACK} fontWeight="700">&nbsp;/&nbsp;</Text>
          </Flex>
        </Link>
        <Text fontSize="14px" color={Colors.BLACK} fontWeight="700">ログインリダイレクト設定</Text>
      </Flex>
      <LoginRedirectTitle onOpen={onOpen} />
      <ErrorBoundary onError={onError} key={ebKey.current}>
        <LoginRedirectContents isOpen={isOpen} onClose={onClose} />
      </ErrorBoundary>
    </Box>
  );
});
