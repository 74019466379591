import { FC, memo, ReactElement } from 'react';
import { Box } from '@chakra-ui/react';
import { Title } from 'components/setting/apikey/atoms/Title';

interface ContainerProps {
  text: string;
  children: ReactElement;
}

export const Container: FC<ContainerProps> = memo(
  ({ text, children }: ContainerProps) => (
    <Box mb={10}>
      <Title text={text} />
      {children}
    </Box>
  ),
);
