import { Box, Heading, useDisclosure } from '@chakra-ui/react';
import { withSuspenseAndErrorBoundary } from 'admin/components/Error/WithErrorBoundary';
import { MasterEditDrawer } from 'admin/components/idPoolConsumer/master/masterDrawer';
import { useIdPoolConsumerId } from 'admin/hooks/useIdPoolConsumerId';
import { useCodeGroups } from 'admin/hooks/userPool/master/useCodeGroups';
import { Page } from 'api/common/types';
import { ErrorContents } from 'components/common/atoms';
import { Dispatch, FC, memo, SetStateAction, useEffect, useState } from 'react';
import { MasterTableComponent } from './MasterTableComponent';

type Props = {
  currentPage: number;
  setPage: Dispatch<SetStateAction<Page>>;
};

const fetchRuleTable: FC<Props> = memo(({ setPage, currentPage }) => {
  const {
    isOpen: isDrawerOpen,
    onClose: onDrawerClose,
    onOpen: onDrawerOpen,
  } = useDisclosure();

  const [masterSettingId, setMasterSettingId] = useState<string>('');

  const formId = `masterSettingEditForm${masterSettingId}`;
  const idPoolConsumerId = useIdPoolConsumerId();
  const { data: masterSettings, page } = useCodeGroups(
    idPoolConsumerId,
    currentPage,
  );

  useEffect(() => {
    setPage(page);
  }, [setPage, page]);

  if (!masterSettings) {
    return null;
  }

  if (masterSettings.length === 0) {
    return (
      <Box
        w="100%"
        textAlign="center"
        bg="white"
        mt={6}
        borderRadius={4}
        padding={10}
      >
        <Heading as="h3" fontWeight="bold" fontSize="24px">
          選択肢マスタが未登録です。
        </Heading>
      </Box>
    );
  }

  return (
    <>
      <MasterTableComponent
        masterSettings={masterSettings}
        idPoolConsumerId={idPoolConsumerId}
        setMasterSettingId={setMasterSettingId}
        onDrawerOpen={onDrawerOpen}
      />
      <MasterEditDrawer
        masterSettingId={masterSettingId}
        onClose={onDrawerClose}
        isOpen={isDrawerOpen}
        formId={formId}
        currentPage={currentPage}
      />
    </>
  );
});

export const MasterTable = memo(
  withSuspenseAndErrorBoundary(fetchRuleTable, {
    ErrorComponent: <ErrorContents name="選択肢マスタ一覧" />,
  }),
);
