import { Box, Text, useDisclosure } from '@chakra-ui/react';
import { Switch } from 'components/paywall/atoms/Switch';
import { usePaywall } from 'components/paywall/hooks/usePaywall';
import { Dialog } from 'components/paywall/molecules/Dialog';
import { memo, useCallback, VFC } from 'react';

type Props = {
  id: string;
  msg: string;
  isEnable: boolean;
  isDisabled?: boolean;
  activate: (id: string, status: boolean) => void;
};

/**
 * @see 共通化したpaywall固有の確認ダイアログ
 * @param id ダイアログを発火するswitchに使用する使用id
 * @param msg ダイアログテキスト
 * @param isEnable 対象nameの状態
 * @param isDisabled 操作可否
 */
export const ActivateDialog: VFC<Props> = memo(
  ({ id, msg, isEnable, isDisabled=false, activate }) => {
    const { isOpen, onOpen, onClose } = useDisclosure();
    const { activateWall } = usePaywall({ onClose });

    const onOpenDialog = useCallback(() => {
      if(isDisabled) return;

      onOpen();
    }, [onOpen, isDisabled]);

    return (
      <>
        <Box onClick={onOpenDialog}>
          <Switch
            hasLabel
            id={id}
            defaultChecked={isEnable}
            isChecked={isEnable}
            isDisabled={isDisabled}
          />
        </Box>

        <Dialog
          isOpen={isOpen}
          onClose={onClose}
          showIcon
          onSubmit={() =>
            activateWall({
              id,
              status: isEnable,
              callback: activate,
            })
          }
        >
          <Text>{msg}</Text>
        </Dialog>
      </>
    );
  },
);
