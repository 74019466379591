import produce from 'immer';
import { Options } from 'ky';
import { logger } from 'api/logger';
import { request } from 'api/request';
import { ResponseError, isResponseError } from 'api/types';
import { isContentsTable } from 'api/contents/types';
import { messages } from 'api/contents/messages';

const DEFAULT_ERR_MESSAGE = {
  other: messages.updatePublish.customer.error,
};

export type UpdatePublish = {
  publishFlag: boolean;
  publishStart: string | null;
  publishEnd: string | null;
  isAutoImport: boolean;
  isDeleteInsert: boolean;
  tenantId: string;
  tableId: string;
  options?: Options;
};

/**
 * コンテンツシェルフ公開設定更新API
 *
 * 状態により以下のレスポンスを返却します
 * 正常時: true
 * エラー: ResponseError
 *
 */
export const updatePublish = async ({
  publishFlag,
  publishStart,
  publishEnd,
  isAutoImport,
  isDeleteInsert,
  tenantId,
  tableId,
  options,
}: UpdatePublish): Promise<ResponseError | boolean> => {
  const formData = {
    publishFlag,
    publishStart,
    publishEnd,
    isAutoImport,
    isDeleteInsert,
  };
  const mergedOptions = options
    ? produce(options, (draft) => {
        draft.json = formData;
      })
    : { json: formData };

  const response = await request({
    path: `contentsdb/${tenantId}/${tableId}/`,
    method: 'patch',
    options: mergedOptions,
  });

  const responseData = response.data;

  if (response.status >= 500 && response.status < 600) {
    return DEFAULT_ERR_MESSAGE;
  }

  if (response.hasError) {
    // APIにて返却されたエラー形式
    if (isResponseError(responseData)) {
      return responseData;
    }

    // 予期せぬエラーなので
    // バックエンドに状態を通知
    await logger({
      loglevel:
        response.status === 404 || response.status === 450
          ? 'warning'
          : 'error',
      data: response,
      message: messages.updatePublish.system.responseError,
    });

    // 登録できていない旨を通知
    return DEFAULT_ERR_MESSAGE;
  }

  // 更新成功時
  if (!isContentsTable(responseData)) {
    // 更新は成功したものの
    // レスポンスの型ガードでエラー
    await logger({
      loglevel:
        response.status === 404 || response.status === 450
          ? 'warning'
          : 'error',
      data: responseData,
      message: messages.updatePublish.system.typeError,
    });
  }

  return true;
};
