import { Box, Stack } from '@chakra-ui/react';
import { withSuspenseAndErrorBoundary } from 'admin/components/Error/WithErrorBoundary';
import { MemberInfo } from 'admin/components/member/detail/memberInfo';
import { MemberLogs } from 'admin/components/member/detail/memberLogs';
import { Colors } from 'admin/define/colors';
import { ErrorContents } from 'components/common/atoms';
import { FC, memo } from 'react';

export const memberDetailLogContents: FC = memo(() => (
  <Box backgroundColor={Colors.WHITE} p={6} mt="16px" borderRadius="8px">
    <Stack spacing={4}>
      <MemberInfo />
      <MemberLogs />
    </Stack>
  </Box>
));

export const MemberDetailLogContents = withSuspenseAndErrorBoundary(
  memberDetailLogContents,
  {
    ErrorComponent: <ErrorContents name="利用履歴一覧" />,
  },
);
