import { createAuthHub } from 'admin/api/authHub/createAuthHub';
import { CustomError } from 'admin/error/CustomError';
import { AuthHubType, AuthHubCreateFormType } from 'admin/types/authHub';
import { logger } from 'api/logger';
import { useCustomToast } from 'hooks/useCustomToast';
import { useMutation, useQueryClient } from 'react-query';
import { errorToast, successToast } from 'utils/toast';

type UseCreateAuthHubType = () => {
  createMutate: (data: AuthHubCreateFormType) => Promise<AuthHubType>;
  isLoading: boolean;
};

export const useCreateAuthHub: UseCreateAuthHubType = () => {
  const toast = useCustomToast();
  const _ = useQueryClient();
  const { mutateAsync, isLoading } = useMutation({
    mutationFn: (data: AuthHubCreateFormType) => createAuthHub(data),
    onSuccess: () => {
      // await queryClient.invalidateQueries(queryUsersKey.getUsers(corporationId))
      toast({
        ...successToast,
        duration: 4000,
        title: '設定を作成しました',
        position: 'bottom',
        variant: 'solid',
      });
    },
    onError: async (error) => {
      if (error instanceof CustomError) {
        await logger({
          loglevel: error.logLevel,
          data: JSON.stringify(error.cause),
          message: error.message,
        });
      }
      toast({
        ...errorToast,
        duration: 4000,
        title: '設定の作成に失敗しました',
        position: 'bottom',
        variant: 'solid',
      });
    },
  });

  return { createMutate: mutateAsync, isLoading };
};
