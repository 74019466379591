import { request } from 'admin/api/Request';
import { API_MESSAGES } from 'admin/define/apiMessages';
import { CustomError } from 'admin/error/CustomError';
import { newsletterSchema } from 'admin/schema/idPoolConsumer/newsletter';
import { UserPool } from 'admin/types/userPool';
import { NewsletterType } from 'admin/types/userPool/newsletter/index';
import { ZodError } from 'zod';

export const changeNewsletterDisplayMyPage = async ({
  userPoolId,
  id,
  status,
}: {
  userPoolId: UserPool['id'];
  id: NewsletterType['id'];
  status: NewsletterType['isDisplayAccountSettings'];
}): Promise<NewsletterType> => {
  const path = `uniikey/${userPoolId}/newsletter/${id}/`;
  const params = {
    isDisplayAccountSettings: status,
  };

  const response = await request<NewsletterType>({
    path,
    body: params,
    method: 'patch',
  });

  if (response.hasError) {
    if (response.status === 404) {
      throw new CustomError(
        API_MESSAGES.USER_POOL.NEWSLETTER_DISPLAY_MYPAGE_CHANGE.FAILED,
        {
          path,
          params,
          status: response.status,
        },
      );
    } else {
      throw new CustomError(
        API_MESSAGES.USER_POOL.NEWSLETTER_DISPLAY_MYPAGE_CHANGE.ERROR,
        {
          path,
          params,
          status: response.status,
          logLevel: response.status === 400 ? 'warning' : 'error',
        },
      );
    }
  }
  try {
    newsletterSchema.parse(response.data);
  } catch (error) {
    if (error instanceof ZodError) {
      throw new CustomError(error.message, {
        path,
        params,
        status: response.status,
        logLevel: 'error',
      });
    }
  }

  return response.data;
};
