import { Options } from 'ky';
import { request } from 'api/request';
import { logger } from 'api/logger';
import produce from 'immer';
import { ResponseError, isResponseError } from 'api/types';
import { SegmentDetail, isSegmentDetail } from 'api/segment/types';
import { getValidationError, ValidationError } from 'utils/form';
import { messages } from './messages';

const DEFAULT_ERR_MESSAGE: ValidationError<SegmentDetail> = {
  others: [messages.postSegment.customer.error],
};
const DEFAULT_SYSTEM_ERR_MESSAGE: ValidationError<SegmentDetail> = {
  others: [messages.postSegment.customer.failed],
};

/**
 * セグメント作成 API
 *
 * 状態により以下のレスポンスを返却します
 * 正常時: true
 * エラー: ResponseError
 *
 */
export const postSegment = async (
  formParam: SegmentDetail,
  options?: Options,
): Promise<SegmentDetail | ResponseError> => {
  const { tenantId, name, query } = formParam;
  const path = `segment/${tenantId}/`;
  const postParam = { name, query };

  const mergedOptions = options
    ? produce(options, (draft) => {
        draft.json = { ...postParam };
      })
    : { json: postParam };

  const response = await request({
    path,
    method: 'post',
    options: mergedOptions,
  });

  if (response.status >= 500) {
    return DEFAULT_ERR_MESSAGE;
  }

  if (response.status === 403) {
    return DEFAULT_SYSTEM_ERR_MESSAGE;
  }

  const responseData = response.data;

  // セグメント作成
  if (isSegmentDetail(responseData)) {
    return responseData;
  }

  const { error } = response;
  if (response.hasError) {
    // APIにて返却されたエラー形式
    if (isResponseError(error)) {
      const formError = getValidationError<SegmentDetail>({
        formData: formParam,
        response: error,
      });

      return formError;
    }
  }

  // 予期せぬエラーなので
  // バックエンドに状態を通知
  await logger({
    loglevel:
      response.status === 404 || response.status === 450 ? 'warning' : 'error',
    data: error,
    message: messages.postSegment.system.typeError,
  });

  // 登録できていない旨を通知
  return DEFAULT_SYSTEM_ERR_MESSAGE;
};
