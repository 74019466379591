
import {
  Box, Icon
} from '@chakra-ui/react';
import { usePopperUtility } from 'components/opt/hooks/useGlobalUtilities';
import { Colors } from 'components/paywall/config';
import { FilterCancelReason } from 'components/paywall/features/Table/ColumnFilters/FilterCancelReason';
import { FilterPeriod } from 'components/paywall/features/Table/ColumnFilters/FilterPeriod';
import { FilterPlan } from 'components/paywall/features/Table/ColumnFilters/FilterPlan';
import { FilterPrice } from 'components/paywall/features/Table/ColumnFilters/FilterPrice';
import { FilterStatus } from 'components/paywall/features/Table/ColumnFilters/FilterStatus';
import { FilterText } from 'components/paywall/features/Table/ColumnFilters/FilterText';
import { FC, memo, useCallback } from 'react';
import {
  MdSearch
} from 'react-icons/md';

type Props = {
  filterName: string;
  filterKey: string;
  filterType: 'text' | 'status' | 'price' | 'plan' | 'period' | 'cancel';
}
export const FilterButton: FC<Props> = memo(({filterName, filterKey, filterType}: Props) => {
  const { handleOpenPopper } = usePopperUtility();

  const onPopper = useCallback((e: React.MouseEvent<HTMLElement>) => {

    let popperElement: React.ReactElement;
    switch(filterType) {
      case 'text':
        popperElement = <FilterText title={filterName} inputName={filterKey} />
        break;
      case 'status':
        popperElement = <FilterStatus radioName="status" />
        break;
      case 'plan':
        popperElement = <FilterPlan radioName={`${filterKey}Type`} inputName={filterKey} />
        break;
      case 'price':
        popperElement = <FilterPrice title="半角数字" />
        break;
      case 'period':
        popperElement = <FilterPeriod inputName={filterKey} hasTime />
        break;
      case 'cancel':
        popperElement = <FilterCancelReason cancelReasonName="cancelReason" />
        break;
      default:
        popperElement = <FilterText title={filterName} inputName={filterKey} />
    }

    handleOpenPopper(e, popperElement)
  }, [handleOpenPopper, filterType, filterName, filterKey]);

  return (
    <Box
      onClick={onPopper}
      w="20px"
      minW="20px"
      h="20px"
      bgColor={Colors.GRAY_60}
      borderRadius="6px"
      cursor="pointer"
      _hover={{
        backgroundColor: Colors.GRAY_30
      }}
    >
      <Icon
        as={MdSearch}
        boxSize={5}
        color={Colors.GRAY_03}
      />
    </Box>
  )
})
