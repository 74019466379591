import { useQuery } from 'react-query';
import { isSegmentAttribute, SegmentAttributeList } from 'api/segment/types';
import { getSegmentField } from 'api/segment/getSegmentField';
import { useCustomToast } from 'hooks/useCustomToast';
import { useUserTenantId } from 'hooks/user/useUserTenantId';
import { querySegmentKey } from './querySegmentKey';

/**
 * セグメント条件フィールド取得 hooks
 */
export const useSegmentAttribute = (): SegmentAttributeList[] | undefined => {
  const toast = useCustomToast();
  const tenantId = useUserTenantId();

  const response = useQuery(
    querySegmentKey.getSegmentField,
    () => getSegmentField(tenantId),
    {
      onError: (err: unknown) => {
        if (err instanceof Error) {
          toast.closeAll();
          toast({
            title: err.message,
            status: 'error',
          });
        }
      },
    },
  );
  const segmentFieldList: SegmentAttributeList[] = [];
  if (isSegmentAttribute(response.data)) {
    segmentFieldList.push({
      id: 'user',
      name: 'ユーザー属性',
      fields: response.data.users,
    });
    const tempEnqFieldList = response.data.enquetes.map((x) => ({
      id: x.id,
      title: x.title,
      fields: x.data,
    }));
    segmentFieldList.push({
      id: 'enquete',
      name: 'フォーム属性',
      fieldList: tempEnqFieldList,
    });
    const tempContFieldList = response.data.contents.map((x) => ({
      id: x.id,
      title: x.title,
      fields: x.data,
    }));
    segmentFieldList.push({
      id: 'contents',
      name: 'コンテンツシェルフ',
      fieldList: tempContFieldList,
    });
  }

  return segmentFieldList;
};
