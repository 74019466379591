import { AuthConfigType, SettingAuth } from 'components/setting/config/SettingAuth';
import { useUserInfo } from 'hooks/useUserInfo';

export const useSettingAuth = (): {
  isAllowedPermission: (key: string) => boolean;
} => {
  const { userTenant, getPermissionKey } = useUserInfo();

  const hasAllowed = (key: string, permissionKey: keyof AuthConfigType) => SettingAuth[key][permissionKey];

  // ログイン中のユーザー権限のアクセスが許可されているかどうか判定
  const isAllowedPermission = (key: string): boolean => {
    const permissionKey = getPermissionKey(userTenant?.permission as number) as keyof AuthConfigType;

    return hasAllowed(key, permissionKey);
  }

  return {
    isAllowedPermission
  }
}
