import {
  AlertDialog,
  Box,
  Button,
  Flex,
  Text,
  useDisclosure,
} from '@chakra-ui/react';
import { withSuspenseAndErrorBoundary } from 'admin/components/Error/WithErrorBoundary';
import { Breadcrumb } from 'admin/components/ui/breadcrumb';
import { BreadcrumbType } from 'admin/types/ui/breadcrumb';
import { FirstLoginMailType } from 'api/transactionMail/type';
import { LoadingSkeleton } from 'components/common/atoms';
import { ListError } from 'components/common/molecules';
import { Colors } from 'components/paywall/config';
import { SendingContentsListTable } from 'components/transactionMail/SendingContentsListTable';
import { SettingContentStatusChangeDialog } from 'components/transactionMail/SettingContentStatusChangeDialog';
import { useSendingContentsList } from 'hooks/transactionMail/useSendingContentsList';
import { useUserTenantId } from 'hooks/user/useUserTenantId';
import { useSetPageTitle } from 'hooks/useSetPageTitle';
import { FC, useCallback, useRef, useState } from 'react';
import { NavLink } from 'react-router-dom';

const FetchSendingContentsList: FC = () => {
  useSetPageTitle('送信内容設定');
  const tenantId = useUserTenantId();
  const cancelRef = useRef(null);
  const [target, setTarget] = useState<FirstLoginMailType>();

  const breadcrumbItems: BreadcrumbType[] = [
    { url: '/transactionMail', title: '初回ログイン時メール設定' },
    {
      url: '/transactionMail/list',
      title: '送信内容設定',
    },
  ];

  const { data: sendingContentsList } = useSendingContentsList(tenantId);

  const {
    isOpen: isOpenStatusChangeDialog,
    onClose: onCloseStatusChangeDialog,
    onOpen: onOpenStatusChangeDialog,
  } = useDisclosure();

  const onOpenDialog = useCallback(
    (targetContent: FirstLoginMailType) => {
      setTarget(targetContent);
      onOpenStatusChangeDialog();
    },
    [onOpenStatusChangeDialog],
  );

  if (!sendingContentsList) {
    return null;
  }

  return (
    <Box w="100%" px="8px" marginTop={0}>
      <Flex w="100%" justifyContent="space-between" pt={2} mb={10}>
        <Breadcrumb breadcrumbItems={breadcrumbItems} />
        <NavLink to="/transactionMail/form/">
          <Button variant="primary" py="6px" px="20px">
            新規作成
          </Button>
        </NavLink>
      </Flex>
      {sendingContentsList.length === 0 ? (
        <Box
          mt="16px"
          p="40px"
          border="1px solid"
          borderColor={Colors.GRAY_30}
          bgColor={Colors.WHITE}
          borderRadius="8px"
        >
          <Box textAlign="center">
            <Text
              fontWeight="700"
              fontSize="24px"
              lineHeight="170%"
              fontFamily="Hiragino Sans"
            >
              メール設定が未作成です。
            </Text>
          </Box>
        </Box>
      ) : (
        <SendingContentsListTable
          sendingContentsList={sendingContentsList}
          onOpenStatusChangeDialog={onOpenDialog}
        />
      )}
      <AlertDialog
        isOpen={isOpenStatusChangeDialog}
        onClose={onCloseStatusChangeDialog}
        leastDestructiveRef={cancelRef}
        isCentered
        closeOnOverlayClick={false}
        closeOnEsc={false}
        size="xl"
      >
        <SettingContentStatusChangeDialog
          target={target}
          setTarget={setTarget}
          onClose={onCloseStatusChangeDialog}
        />
      </AlertDialog>
    </Box>
  );
};

export const SendingContentsList = withSuspenseAndErrorBoundary(
  FetchSendingContentsList,
  {
    ErrorComponent: <ListError message="送信内容設定を表示できません" />,
    LoadingComponent: <LoadingSkeleton width="100%" />,
  },
);
