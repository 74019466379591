import { Box, Flex, Text } from '@chakra-ui/react';
import { Colors } from 'components/paywall/config';
import { PaymentAgencyBody } from 'components/setting/paymentAgency/PaymentAgencyItem/PaymentAgencyBody';
import { PaymentAgencyEdit } from 'components/setting/paymentAgency/PaymentAgencyItem/PaymentAgencyEdit';
import { useGmoInfo } from 'hooks/tenant/useGmoInfo';
import { useUserTenantId } from 'hooks/user/useUserTenantId';
import { FC, memo } from 'react';

export const PaymentAgencyInner: FC = memo(() => {
  const tenantId = useUserTenantId();
  const gmoInfo = useGmoInfo({ tenantId });

  return (
    <>
      <Flex mt="20px">
        <Text fontSize="14px" color={Colors.BLACK} fontWeight="700">
          決済代行連携設定
        </Text>
      </Flex>
      <Flex>
        <Text mt="24px">
          ペイウォールなどで利用する決済代行のAPI情報を設定出来ます。
        </Text>
      </Flex>
      <Box borderWidth="1px" borderRadius="xl" p={4} w="100%">
        <PaymentAgencyEdit gmoInfo={gmoInfo} />
        <PaymentAgencyBody gmoInfo={gmoInfo} />
      </Box>
    </>
  );
});
