import { zodResolver } from '@hookform/resolvers/zod';
import { withSuspenseAndErrorBoundary } from 'admin/components/Error/WithErrorBoundary';
import { UsersInviteFormComponent } from 'admin/components/users/usersInviteForm/UsersInviteFormComponent';
import { useInviteCorporation } from 'admin/hooks/user/useInviteCorporation';
import { inviteCorporationFormSchema } from 'admin/schema/user';
import { PermissionFeatureKeyType } from 'admin/types/permission';
import { UserPoolType } from 'admin/types/user';
import { InviteCorporationSubmitForm } from 'admin/types/user/form';
import { CorporationUserPoolPermissionType } from 'api/user/types';
import { AlertBar } from 'components/common/atoms';
import { ALLOWED_AUTH_CODE_TYPES } from 'define';
import { FC, memo, useCallback, useEffect, useState } from 'react';
import { FormProvider, SubmitHandler, useForm } from 'react-hook-form';

export type Props = {
  userPools: UserPoolType[];
  onClose: () => void;
};

export const usersInviteForm: FC<Props> = memo(({ userPools, onClose }) => {
  const [globalErrors, setGlobalErrors] = useState<string[]>([]);
  const [userPermissionList, setUserPermissionList] = useState<
    PermissionFeatureKeyType[]
  >([]);
  const [userPoolPermissionList, setUserPoolPermissionList] = useState<
    UserPoolType[]
  >([]);
  const formId = 'userInviteForm';
  const methods = useForm<InviteCorporationSubmitForm>({
    defaultValues: {
      emailTo: '',
      permission: [],
      userPools: [],
    },
    mode: 'onBlur',
    resolver: zodResolver(inviteCorporationFormSchema),
  });
  const { setValue, setError } = methods;
  const { mutate } = useInviteCorporation(setError, setGlobalErrors);

  const generateFilterUserPools = useCallback(
    (up: UserPoolType[]) =>
      up.filter(
        (x) =>
          !!x.authenticationType &&
          ALLOWED_AUTH_CODE_TYPES.includes(x.authenticationType),
      ),
    [],
  );

  useEffect(() => {
    setUserPoolPermissionList(generateFilterUserPools(userPools));
    setValue(
      'userPools',
      generateFilterUserPools(userPools).map((userPool) => ({
        userPoolId: userPool.userPoolId,
        permission: userPool.permission,
      })),
    );
  }, [userPools, setValue, generateFilterUserPools]);

  const onSubmit: SubmitHandler<InviteCorporationSubmitForm> = async (
    data: InviteCorporationSubmitForm,
  ) => {
    await mutate(data);
    onClose();
  };

  const onChangePermissionCheck = useCallback(
    (permission: PermissionFeatureKeyType) => {
      let resultArray: PermissionFeatureKeyType[] = [];
      // 権限配列に既に存在している時
      if (userPermissionList.includes(permission)) {
        resultArray = [...userPermissionList.filter((x) => x !== permission)]; // 参照を切る
      } else {
        // 存在していないとき
        resultArray = [...userPermissionList]; // 参照を切る
        resultArray.push(permission);
      }
      setUserPermissionList(resultArray);
      setValue('permission', resultArray);
    },
    [setUserPermissionList, userPermissionList, setValue],
  );

  // ユーザープール権限設定
  const onChangeUserPoolPermissionCheck = useCallback(
    (index: number, userPoolKey: CorporationUserPoolPermissionType) => {
      const resultArray: UserPoolType[] = [...userPoolPermissionList];
      // 権限配列に既に存在している時
      if (userPoolPermissionList[index].permission.includes(userPoolKey)) {
        resultArray[index].permission = [
          ...userPoolPermissionList[index].permission.filter(
            (x) => x !== userPoolKey,
          ),
        ]; // 参照を切る
      } else {
        // 存在していないとき
        resultArray[index].permission = [
          ...userPoolPermissionList[index].permission,
        ]; // 参照を切る
        resultArray[index].permission.push(userPoolKey);
      }
      setUserPoolPermissionList(resultArray);
      setValue(`userPools.${index}.permission`, resultArray[index].permission);
    },
    [setUserPoolPermissionList, userPoolPermissionList, setValue],
  );

  return (
    <FormProvider {...methods}>
      {globalErrors.map((err, idx) => (
        <AlertBar
          key={`global-err-idx${String(idx)}`}
          message={err}
          status="error"
          mb={4}
        />
      ))}
      <UsersInviteFormComponent
        formId={formId}
        userPermissionList={userPermissionList}
        userPoolPermissionList={userPoolPermissionList}
        onChangePermissionCheck={onChangePermissionCheck}
        onChangeUserPoolPermissionCheck={onChangeUserPoolPermissionCheck}
        onClose={onClose}
        onSubmit={onSubmit}
      />
    </FormProvider>
  );
});

export const UsersInviteForm = withSuspenseAndErrorBoundary(usersInviteForm);
