import { Box, StackDivider, Text, VStack } from '@chakra-ui/react';
import ErrorBoundary from 'api/ErrorBoundary';
import { Colors } from 'components/paywall/config';
import { MailSettingPersonalItem } from 'components/paywall/pages/MailSetting/MailSettingPersonal/MailSettingPersonalItem';
import { MailSettingPersonalData } from 'components/paywall/pages/MailSetting/typed';
import { PERMISSION_MANAGER } from 'define';
import { useErrorBoundaryReset } from 'hooks/useErrorBoundaryReset';
import { useUserInfo } from 'hooks/useUserInfo';
import { FC, memo } from 'react';

type Props = {
  personalDataList: MailSettingPersonalData[];
};

export const MailSettingPersonal: FC<Props> = memo(({ personalDataList }) => {
  const { ebKey, onError } = useErrorBoundaryReset();
  const { isEditableTarget } = useUserInfo();

  return (
    <ErrorBoundary onError={onError} key={ebKey.current}>
      <Box w="100%">
        <Box fontFamily="Hiragino Sans">
          <Text fontSize={18} fontWeight="bold">
            個別メールの設定
          </Text>
          <Text fontSize={14} color={Colors.GRAY_00}>
            各アクションに応じて自動送信の有無、内容について設定できます。
          </Text>
        </Box>
        <Box w="100%" borderWidth="1px" borderRadius="md" p="16px" mt="8px">
          <VStack
            divider={<StackDivider borderColor="gray.200" />}
            spacing={4}
            alignItems="flex-start"
          >
            {personalDataList.map((d, index) => (
              <MailSettingPersonalItem
                type={d.type}
                checked={d.checked}
                message={d.message}
                isDisabled={
                  Boolean(d.message) || !isEditableTarget(PERMISSION_MANAGER)
                }
                key={index.toString()}
              />
            ))}
          </VStack>
        </Box>
      </Box>
    </ErrorBoundary>
  );
});
