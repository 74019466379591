import { queryKeyNames } from 'admin/hooks/service/attribute/querySerivceAttributeKey';
import { queryIdPoolConsumerAttributeKey } from 'admin/hooks/userPool/attribute/queryIdPoolConsumerAttributeKey';
import { queryAttributeGroupKey } from 'admin/hooks/userPool/attributeGroup/queryAttributeGroupKey';
import { UserPool } from 'admin/types/userPool';
import { useQueryClient } from 'react-query';

// 属性変更に伴い組織管理・テナント管理の属性/属性グループのキャッシュを破棄する
export const useResetAllAttributes = (id: UserPool['id']) => {
  const queryClient = useQueryClient();

  return () => {
    void queryClient.invalidateQueries(
      queryIdPoolConsumerAttributeKey.getAllAttributes(id),
    );
    void queryClient.invalidateQueries(
      queryIdPoolConsumerAttributeKey.getIdPoolConsumerAttributes(id),
    );
    void queryClient.invalidateQueries(
      queryAttributeGroupKey.getAttributeGroups(id),
    );

    // userPoolChildIdが組織管理画面で取得できないので全件キャッシュを破棄
    void queryClient.invalidateQueries(queryKeyNames.getSerivceAttributes);
    void queryClient.invalidateQueries(queryKeyNames.getSerivceAttributeGroups);
  };
};
