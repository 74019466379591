import { VFC, memo, useCallback, useEffect, useState, useRef } from 'react';
import { Text, Box, Icon, useDisclosure, Stack } from '@chakra-ui/react';
import { MdCreate } from 'react-icons/md';
import { useForm, FormProvider } from 'react-hook-form';
import { InputPassword } from 'components/user/atoms/InputPassword';
import { UserEditPasswordForm } from 'api/user/types';
import { DrawerForm } from 'components/common/atoms';
import { useUserPasswordEdit } from 'hooks/user/useUserPasswordEdit';

/**
 * パスワード入力用バリデーション
 */
const passwordValidateAttr = {
  required: 'パスワードを入力してください',
  minLength: {
    value: 8,
    message: '8文字以上で入力してください',
  },
  maxLength: {
    value: 32,
    message: '32文字以下で入力してください',
  },
};

export const UserProfilePasswordEdit: VFC = memo(() => {
  const { isOpen, onClose, onOpen } = useDisclosure();
  const [globalErrors, setGlobalErrors] = useState<string[]>([]);
  const password = useRef({});
  const methods = useForm<UserEditPasswordForm>({
    mode: 'onBlur',
  });
  const {
    formState: { errors },
    setError,
    handleSubmit,
    clearErrors,
    watch,
  } = methods;
  password.current = watch('password', '');
  const [isError, setIsError] = useState(false);
  const formId = 'userSetting-password-form';
  const closeBtnHandler = useCallback(() => {
    onClose();
    clearErrors();
  }, [onClose, clearErrors]);

  /**
   * パスワード再入力用バリデーション
   */
  const password2ValidateAttr = {
    validate: (val: string) =>
      val === password.current || 'パスワードが一致しません',
  };

  const { onSubmit, isLoading } = useUserPasswordEdit({
    setError,
    setGlobalErrors,
    onClose,
  });

  const isFailed = useCallback(
    (): boolean => Object.keys(errors).length > 0 || isError,
    [errors, isError],
  );

  // フォーム送信する処理
  const formSubmit = useCallback(
    (data: UserEditPasswordForm) => {
      onSubmit(data);
    },
    [onSubmit],
  );

  useEffect(() => {
    const error = globalErrors.length > 0 ? globalErrors[0] : '';
    setIsError(error !== '');
  }, [globalErrors]);

  return (
    <Box display="flex" alignItems="center">
      <Text fontSize="xl" mr={2}>
        パスワード変更
      </Text>
      <FormProvider {...methods}>
        <DrawerForm
          title="パスワード変更"
          openBtnChildNode={<Icon as={MdCreate} />}
          openBtnProps={{ variant: 'ghost' }}
          cancelBtnTitle="キャンセル"
          cancelBtnHandelr={closeBtnHandler}
          cancelBtnProps={{ disabled: isLoading }}
          submitBtnTitle="変更"
          submitBtnProps={{
            variant: 'primary',
            disabled: isFailed(),
            isLoading,
          }}
          isOpen={isOpen}
          onOpen={onOpen}
          onClose={closeBtnHandler}
          closeOnOverlayClick={false}
          closeOnEsc={false}
          autoFocus={false}
          size="md"
          formId={formId}
          drawerCloseButtonProps={{ disabled: isLoading }}
          drawerFooterJustify="space-between"
        >
          <form id={formId} onSubmit={handleSubmit((data) => formSubmit(data))}>
            <Stack spacing={8} mt={5}>
              <InputPassword
                name="password"
                placeholder="パスワード"
                attr={passwordValidateAttr}
              />

              <InputPassword
                name="passwordConfirm"
                placeholder="パスワード再入力"
                attr={password2ValidateAttr}
              />
            </Stack>
          </form>
        </DrawerForm>
      </FormProvider>
    </Box>
  );
});
