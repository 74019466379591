import {
  AlertDialogCloseButton,
  AlertDialogContent,
  AlertDialogHeader,
  AlertDialogOverlay,
  Box,
  Text,
} from '@chakra-ui/react';
import { FormAttrForm } from 'admin/components/service/attribute/attributeForm/formAttrForm';
import {
  ServiceAttributeType,
  UserPoolChildAttributeType,
} from 'admin/types/service/attribute';
import { FC, memo } from 'react';

type Props = {
  id: UserPoolChildAttributeType['id'];
  target: ServiceAttributeType | null;
  onClose: () => void;
};

export const FormAttrDialog: FC<Props> = memo((props) => (
  <AlertDialogOverlay>
    <AlertDialogContent>
      <AlertDialogHeader p={6}>
        <Box>
          <Text>フォームで利用の確認</Text>
        </Box>
      </AlertDialogHeader>
      <FormAttrForm {...props} />
      <AlertDialogCloseButton top="16px" right="24px" />
    </AlertDialogContent>
  </AlertDialogOverlay>
));
