import { CustomError } from 'admin/error/CustomError';
import { logger } from 'api/logger';
import { changeSendingContentStatus } from 'api/transactionMail/changeSendingContentStatus';
import { FirstLoginMailType } from 'api/transactionMail/type';
import { queryKeys } from 'hooks/transactionMail/queryKeys';
import { useCustomToast } from 'hooks/useCustomToast';
import { useUserTenantId } from 'hooks/user/useUserTenantId';
import { useMutation, useQueryClient } from 'react-query';
import { errorToast, successToast } from 'utils/toast';

export const useChangeSendingContentStatus = (id: FirstLoginMailType['id']) => {
  const toast = useCustomToast();
  const queryClient = useQueryClient();
  const tenantId = useUserTenantId();

  const { mutateAsync, isLoading } = useMutation({
    mutationFn: (status: boolean) =>
      changeSendingContentStatus({ tenantId, id, status }),
    onSuccess: (data) => {
      void queryClient.invalidateQueries(
        queryKeys.getSendingContentsList({ tenantId }),
      );

      const statusName = data.status ? '使用中' : '停止中';
      toast({
        ...successToast,
        duration: 4000,
        title: `使用設定を${statusName}に変更しました。`,
        position: 'bottom',
        variant: 'solid',
      });
    },
    onError: async (error) => {
      if (error instanceof CustomError) {
        if (error.cause.errors) {
          await logger({
            loglevel: error.logLevel,
            data: JSON.stringify(error.cause),
            message: error.message,
          });
        }
      }
      toast({
        ...errorToast,
        duration: 4000,
        title: '使用設定の変更に失敗しました',
        position: 'bottom',
        variant: 'solid',
      });
    },
  });

  return { mutate: mutateAsync, isLoading };
};
