import { request } from 'admin/api/Request';
import { API_MESSAGES } from 'admin/define/apiMessages';
import { CustomError } from 'admin/error/CustomError';
import { tenantListSchema } from 'admin/schema/tenantUsers';
import { TenantListType } from 'admin/types/tenantUsers';
import { ZodError } from 'zod';

export const getTenantList = async (
  corporationId: string,
): Promise<TenantListType> => {
  const path = `user/corporation/${corporationId}/tenants/`;

  const response = await request<TenantListType>({
    path,
    method: 'get',
  });

  if (response.hasError) {
    if (response.status === 404) {
      throw new CustomError(
        API_MESSAGES.TENANT_USERS.GET_TENANT_LIST.NOT_FOUND,
        {
          path,
          status: response.status,
        },
      );
    } else {
      throw new CustomError(API_MESSAGES.TENANT_USERS.GET_TENANT_LIST.ERROR, {
        path,
        status: response.status,
        logLevel: response.status === 400 ? 'warning' : 'error',
      });
    }
  }
  try {
    tenantListSchema.parse(response.data);
  } catch (error) {
    if (error instanceof ZodError) {
      throw new CustomError(error.message, {
        path,
        status: response.status,
        logLevel: 'error',
      });
    }
  }

  return response.data;
};
