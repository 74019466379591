import { TransactionMailsTableInnerComponent } from 'admin/components/idPoolConsumer/transactionMail/transactionMailsTableInner/TransactionMailsTableInnerComponent';
import { useIdPoolConsumerId } from 'admin/hooks/useIdPoolConsumerId';
import { TransactionMailType } from 'admin/types/userPool/transactionMail';
import { FC, memo } from 'react';

type Props = {
  transactionMail: TransactionMailType;
  transactionMailType: string;
  onOpenModal: (transactionMail: TransactionMailType) => void;
};

export const TransactionMailsTableInner: FC<Props> = memo(
  ({ transactionMail, transactionMailType, onOpenModal }: Props) => {
    const idPoolConsumerId = useIdPoolConsumerId();
    const onChangeStatus = () => {
      onOpenModal(transactionMail);
    };

    return (
      <TransactionMailsTableInnerComponent
        idPoolConsumerId={idPoolConsumerId}
        transactionMail={transactionMail}
        transactionMailType={transactionMailType}
        onChangeStatus={onChangeStatus}
      />
    );
  },
);
