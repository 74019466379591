import {
  Box,
  Button,
  Flex,
  ListItem,
  Switch,
  Text,
  Tr as ChakraTr,
  UnorderedList,
} from '@chakra-ui/react';
import { HelpTooltip } from 'admin/components/ui/helpTooltip';
import { EditIcon } from 'admin/components/ui/icon/editIcon';
import { VesuionUpIcon } from 'admin/components/ui/icon/versionup';
import { StatusLabel as Status } from 'admin/components/ui/status/StatusLabel';
import {
  Table,
  Tbody,
  Td,
  TdWithBorder,
  Th,
  Thead,
  ThWithBorder,
  Tr,
} from 'admin/components/ui/table';
import { TermsType, TermType } from 'admin/types/userPool/terms';
import { useCustomNavigate } from 'hooks/useCustomNavigate';
import { Dispatch, FC, memo, SetStateAction } from 'react';

type Props = {
  terms: TermsType;
  idPoolConsumerId: string;
  onDrawerOpen: () => void;
  onAgreeModalOpen: (term: TermType) => void;
  onAutoAppendModalOpen: (term: TermType) => void;
  setTermId: Dispatch<SetStateAction<string>>;
  onOpenStatusChangeModal: (term: TermType) => void;
  onEnabledModalOpen: (term: TermType) => void;
  onOpenVersionupModal: (term: TermType) => void;
};

export const TermsTableComponent: FC<Props> = memo(
  ({
    terms,
    idPoolConsumerId,
    onDrawerOpen,
    setTermId,
    onAgreeModalOpen,
    onAutoAppendModalOpen,
    onOpenStatusChangeModal,
    onEnabledModalOpen,
    onOpenVersionupModal,
  }: Props) => {
    const { navigate } = useCustomNavigate({});

    return (
      <Box mt="16px">
        <Table w="100%" maxHeight="calc(100vh - 250px)">
          <Thead>
            <ChakraTr>
              <Th maxWidth="130px" minWidth="130px">
                規約ID
              </Th>
              <Th maxWidth="180px" minWidth="180px">
                表示名（日本語）
              </Th>
              <Th maxWidth="180px" minWidth="180px">
                表示名（外国語）
              </Th>
              <Th maxWidth="120px" minWidth="120px">
                フィールド名
              </Th>
              <Th maxWidth="240px" minWidth="240px">
                URL
              </Th>
              <Th maxWidth="120px" minWidth="120px">
                ステータス
              </Th>
              <Th maxWidth="140px" minWidth="140px">
                <Flex alignItems="center">
                  有効無効設定
                  <HelpTooltip
                    label={
                      <UnorderedList>
                        <ListItem>
                          「ステータス」が「確定」の時、操作可能です。
                        </ListItem>
                        <ListItem>
                          「有効」にすると、対象の規約が有効化され、同意許諾の取得が可能になります。
                        </ListItem>
                        <ListItem>
                          「有効」から「無効」にすると、各テナントでの利用設定が連動してOFFになります。
                        </ListItem>
                      </UnorderedList>
                    }
                  />
                </Flex>
              </Th>
              <ThWithBorder maxWidth="154px" minWidth="154px">
                <Flex alignItems="center">
                  全テナント利用
                  <HelpTooltip
                    label={
                      <UnorderedList>
                        <ListItem>
                          「ステータス」が「確定」、かつ「有効無効設定」が「有効」の場合、操作可能です。
                        </ListItem>
                        <ListItem>
                          ONにすると、対象の規約が全テナントで利用可能になります。
                        </ListItem>
                        <ListItem>
                          「有効無効設定」が「無効」になった場合、連動してOFFになります。
                        </ListItem>
                      </UnorderedList>
                    }
                  />
                </Flex>
              </ThWithBorder>
              <Th maxWidth="110px" minWidth="110px">
                バージョン
              </Th>
              <ThWithBorder maxWidth="220px" minWidth="220px">
                <Flex alignItems="center">
                  バージョンアップ
                  <HelpTooltip
                    label={
                      <Box>
                        規約を新しいバージョンに更新できます。
                        <br />
                        バージョンアップすることで最新の内容に反映されます。
                      </Box>
                    }
                  />
                </Flex>
              </ThWithBorder>
              <Th maxWidth="120px" minWidth="120px">
                規約同意必須
                <br />
                バージョン
              </Th>
              <ThWithBorder maxWidth="168px" minWidth="168px">
                <Flex alignItems="center">
                  同意必須設定
                  <HelpTooltip
                    label={
                      <UnorderedList>
                        <ListItem>
                          ユーザーが現バージョンの規約に同意することを必須にする設定です。
                        </ListItem>
                        <ListItem>
                          この設定がONの場合、ユーザーは最新の規約に同意することで、サービス利用が可能になります。
                        </ListItem>
                      </UnorderedList>
                    }
                  />
                </Flex>
              </ThWithBorder>
              <Th maxWidth="100px" minWidth="100px">
                更新履歴
              </Th>
              <Th maxWidth="74px" minWidth="74px">
                並び順
              </Th>
            </ChakraTr>
          </Thead>
          <Tbody>
            {terms.map((term) => (
              <Tr key={`term${term.id}${term?.displayNameJa ?? ''}`}>
                <Td>
                  <Button
                    textDecoration="underline"
                    variant="link"
                    colorScheme="primary"
                    whiteSpace="break-spaces"
                    textAlign="left"
                    onClick={() => {
                      setTermId(term.id);
                      onDrawerOpen();
                    }}
                  >
                    {term.id}
                  </Button>
                </Td>
                <Td>{term.displayNameJa}</Td>
                <Td>{term.displayNameForeign || '-'}</Td>
                <Td>{term.fieldName}</Td>
                <Td>{term.url}</Td>
                <Td pr={0}>
                  <Flex alignItems="center">
                    <Status
                      status={!!term.status}
                      onLabel="確定"
                      offLabel="下書き"
                    />
                    <Button
                      variant="outline"
                      colorScheme="primary"
                      bgColor="WHITE"
                      w="32px"
                      h="32px"
                      p="0"
                      ml={term.status ? '16px' : '4px'}
                      isDisabled={term.status}
                      onClick={() => onOpenStatusChangeModal(term)}
                    >
                      <EditIcon />
                    </Button>
                  </Flex>
                </Td>
                <Td pr={0}>
                  <Flex>
                    <Switch
                      isChecked={term.isEnabled}
                      isDisabled={!term.status}
                      colorScheme="primary"
                      onChange={() => onEnabledModalOpen(term)}
                    />
                    <Text ml="5px" fontWeight={600}>
                      {term.isEnabled ? '有効' : '無効'}
                    </Text>
                  </Flex>
                </Td>
                <TdWithBorder pr={0}>
                  <Flex>
                    <Switch
                      isChecked={term.isAutoAppendToChild}
                      isDisabled={!term.status || !term.isEnabled}
                      colorScheme="primary"
                      onChange={() => onAutoAppendModalOpen(term)}
                    />
                    <Text ml="5px" fontWeight={600}>
                      {term.isAutoAppendToChild ? 'ON' : 'OFF'}
                    </Text>
                  </Flex>
                </TdWithBorder>
                <Td>{term.version}</Td>
                <TdWithBorder>
                  <Button
                    w="188px"
                    bg="white"
                    height="32px"
                    variant="outline"
                    colorScheme="primary"
                    onClick={() => onOpenVersionupModal(term)}
                    isDisabled={!term.status}
                    py={1}
                    textAlign="left"
                  >
                    <VesuionUpIcon w="20px" mr="1" />
                    バージョンアップする
                  </Button>
                </TdWithBorder>
                <Td>{term.consentRequiredVersion}</Td>
                <TdWithBorder py={2}>
                  <Button
                    height="auto"
                    variant="outline"
                    bg="white"
                    colorScheme="primary"
                    isDisabled={
                      !term.status ||
                      term?.consentRequiredVersion === term.version
                    }
                    onClick={() => onAgreeModalOpen(term)}
                    py={1}
                    textAlign="left"
                  >
                    現バージョンへの
                    <br />
                    同意を必須にする
                  </Button>
                </TdWithBorder>
                <Td>
                  <Button
                    fontWeight="normal"
                    textDecoration="underline"
                    variant="link"
                    colorScheme="primary"
                    py="4px"
                    onClick={() =>
                      navigate(
                        `/admin/idPoolConsumer/${idPoolConsumerId}/terms/${term.id}/log`,
                      )
                    }
                  >
                    更新履歴
                  </Button>
                </Td>
                <Td>{term.displayOrder}</Td>
              </Tr>
            ))}
          </Tbody>
        </Table>
      </Box>
    );
  },
);
