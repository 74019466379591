import { getWallList } from 'api/paywall/getWallList';
import { Wall } from 'components/paywall/pages/WallList/typed';
import { useCustomToast } from 'hooks/useCustomToast';
import { useUserTenantId } from 'hooks/user/useUserTenantId';
import { useQuery } from 'react-query';
import { queryPaywallKey } from './queryPaywallKey';

/**
 * ペイウォールリスト取得
 * @return {Wall[] | undefined} ペイウォールリスト
 */
export const useWallList = ({
  wallType,
}: {
  wallType: 'course' | 'item' | 'member' | 'all';
}): { wallList: Wall[]; childWallList: Wall[] } => {
  const toast = useCustomToast();
  const tenantId = useUserTenantId();
  const response = useQuery(
    queryPaywallKey.getWallList({ tenantId }),
    () => getWallList(tenantId),
    {
      onError: (err: unknown) => {
        if (err instanceof Error) {
          toast.closeAll();
          toast({
            title: err.message,
            status: 'error',
          });
        }
      },
    },
  );
  const { data } = response;

  const wallTypeResult = wallType === 'all'
  ? data
  : data?.filter((x) => x.wallType === wallType) || [];

  return {
    wallList: wallTypeResult?.filter((x) => x.parent === '' || x.parent === null )||[],
    childWallList: wallTypeResult?.filter((x) => x.parent !== '' && x.parent !== null)||[],
  }
};
