import { VFC } from 'react';
import { Text } from '@chakra-ui/react';
import { AddIcon } from '@chakra-ui/icons';

import { TableCreateForm } from 'components/contentsdb/organisms/TableCreateForm';
import { TableForm } from 'components/contentsdb/molecules/TableForm';

const OpenBtnChild: VFC = () => (
  <>
    <AddIcon mr={2} fontSize="xs" fontWeight="thin" />
    <Text as="span">新規作成</Text>
  </>
);

export const ContentsdbHeader: VFC = () => (
  <TableForm
    title="新規作成"
    TableFormChild={TableCreateForm}
    OpenBtnChild={OpenBtnChild}
    OpenBtnProps={{ variant: 'primary' }}
  />
);
