import { KeyboardEvent, ChangeEvent } from 'react';
import Select from 'react-select';
import { Select as ChakuraSelect } from '@chakra-ui/react';
import { SelectEditorProps, DataType } from './types';

export const SelectEditor = <T extends DataType>(
  props: SelectEditorProps<T>,
): JSX.Element => {
  const { onCommit, cell } = props;
  const { options, value: cellValue } = cell;
  const value = options?.find((o) => o.value === cellValue);

  return (
    <ChakuraSelect
      autoFocus
      size="xs"
      value={value?.value}
      onChange={(event: ChangeEvent<HTMLSelectElement>) => {
        const newValue = event?.target?.value || '';

        if (newValue) {
          onCommit(newValue ? String(newValue) : '');
        }
      }}
    >
      {options &&
        options.map((o) => (
          <option key={`option-${o.value}`} value={o.value}>
            {o.label}
          </option>
        ))}
    </ChakuraSelect>
  );
};

// react-select バージョン
// Selectボックスのサイズの調整が困難のため
// Chakra UIのSelectボックスをメイン利用。
// react-select の Selectは一時退避
export const SelectEditorBack = <T extends DataType>(
  props: SelectEditorProps<T>,
): JSX.Element => {
  const { onCommit, cell } = props;
  const { options, value: cellValue } = cell;
  const value = options?.find((o) => o.value === cellValue);

  return (
    <Select
      autoFocus
      value={value}
      options={options}
      onChange={(newValue) => {
        if (newValue) {
          onCommit(newValue.value ? String(newValue.value) : '');
        }
      }}
      onKeyDown={(event: KeyboardEvent<HTMLDivElement>) => {
        // イベントハンドラが呼び出された後に
        // オブジェクトのプロパティにアクセスする場合
        // persist を実行する
        event.persist();
      }}
    />
  );
};
