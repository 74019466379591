import { CONTENTSDB_FILE_UPLOAD_TYPE } from 'define';
import { Page } from 'api/common/types';
import { CustomRuleGroupType } from 'api/segment/types';
// {
//   "id": "tbl_3qj04ar3",
//   "tenant_name": "関東地方",
//   "group_name": "埼玉県",
//   "created_at": "2021-12-06T10:44:07.731766",
//   "updated_at": "2021-12-06T10:44:07.731855",
//   "name": "埼玉ラーメン屋",
//   "tenant": "tnt_kekqo917",
//   "group": "grp_s9c7es0a"
// },
// {
//   "id": "tbl_sdw0lzwd",
//   "tenant_name": "関東地方",
//   "group_name": "さいたま市",
//   "group_parent": "grp_s9c7es0a",
//   "created_at": "2021-12-06T10:44:52.374253",
//   "updated_at": "2021-12-06T10:44:52.374286",
//   "name": "さいたま市学校",
//   "tenant": "tnt_kekqo917",
//   "group": "grp_pftpwsj5"
// },

export type GroupTree = {
  // グループID
  id: string;
  // グループ名
  name: string;
  // 親グループID
  parent: string | null;
};

export type TableDataSearchList = {
  results: TableDataSearchType[];
  cols: TableDataSearchColType;
} & Page;
export type TableDataSearchType = Record<string, string>;
export type TableDataSearchColType = { [key: string]: string };

export const isGroupTree = (data: unknown): data is GroupTree => {
  const t = data as GroupTree;

  return (
    typeof t?.id === 'string' &&
    typeof t?.name === 'string' &&
    (typeof t?.parent === 'string' || t?.parent === null)
  );
};

export const isGroupTreeList = (datas: unknown[]): datas is GroupTree[] =>
  !datas.some((d) => !isGroupTree(d));

// {
//   "tenant": "テナントID",
//   "tenantName": "テナント名",
//   "group": "グループID"
//   "groupName": "グループ名",
//   "name": "テーブル名(物理)",
//   "title": "テーブル名(表示名)",
//   "pkCols": "主キー(カンマ区切り)",
//   "searchCols": null,
//   "tblSchema": "テーブル定義(JSON文字列)",
//   "memo": "メモ",
//   "publishStart": "公開日時",
//   "publishEnd": "公開終了日時",
//   "createUser": "作成者ID",
//   "createUserName": "作成者名",
//   "updateUser": "更新者ID",
//   "updateUserName": "更新者名",
// }

export const SCHEMA_COLUMNS = [
  'pk',
  'column',
  'displayName',
  'dataType',
  'notNull',
  'isSort',
  'rule',
] as const;

// データ型
export const SCHEMA_DATA_TYPE = [
  { label: '文字列', value: 'string' },
  { label: 'テキスト', value: 'zenbun' },
  { label: '整数', value: 'integer' },
  { label: '年月日', value: 'date' },
  { label: '日時', value: 'datetime' },
  { label: 'フラグ', value: 'boolean' },
];

// テーブル定義のデータ型(1行分)
export type SchemaRowType = {
  [key in typeof SCHEMA_COLUMNS[number]]: string;
};

export type SchemaType = {
  // キーは行番号を表す
  [key: number]: SchemaRowType;
  // エラーメッセージ格納
  message?: string;
};

export type ContentsDbTblFormType = {
  name: string;
  title: string;
  group: string;
  memo: string;
  tblSchema: SchemaType;
};

export type ContentsTable = {
  id: string;
  // テーブル名(物理名)
  name: string;
  // テーブル表示名
  title: string;
  // 主キーのカラム名(カンマ区切り)
  pkCols: string | null;
  // 全文検索対象カラム(カンマ区切り)
  searchCols: string | null;
  // テーブル定義(JSON文字列)
  tblSchema: SchemaRowType[];
  // メモ
  memo: string | null;
  // 公開フラグ
  publishFlag: boolean;
  // 公開開始日時
  publishStart: string | null;
  // 公開終了日時
  publishEnd: string | null;
  // 定期取り込みフラグ
  isAutoImport: boolean;
  // 洗い替え取り込みフラグ
  isDeleteInsert: boolean;
  // テナントID
  tenant: string;
  tenantName: string;
  // テナント直下に配置したテーブルの場合 null
  group?: string | null;
  // テナント直下に配置したテーブルの場合 キーなし
  groupName?: string;
  // テナント直下に配置したテーブルの場合 キーなし
  // ルートグループ(テナント直下のグループ)の場合は値が null
  groupParent?: string | null;
  // グループ階層
  // 最初の要素がルートグループ情報
  // グループに属さない場合(テナント直下)は空要素
  groupTree?: GroupTree[];
  // 作成日時
  createdAt?: string | null;
  // 作成者ユーザーID
  createUser?: string | null;
  // 作成者名
  createUserName?: string | null;
  // 更新日時
  updatedAt?: string | null;
  // 更新者ユーザーID
  updateUser?: string | null;
  // 更新者名
  updateUserName?: string | null;
};

export type CreateSearchFilterSort = {
  field: string;
  order: string;
};

export type CreateSearchFilter = {
  page?: number;
  limit?: number;
  sort?: CreateSearchFilterSort[];
  select?: string[];
  query?: CustomRuleGroupType;
};

export type SearchFilter = {
  page?: number;
  limit?: number;
  sort?: string[];
  select?: string[];
  filter?: CustomRuleGroupType;
};
export type TableDataSearchListForm = {
  tenantId: string;
  tableId: string;
  filter: SearchFilter;
};

export const isContentsTable = (data: unknown): data is ContentsTable => {
  const t = data as ContentsTable;

  return (
    typeof t?.id === 'string' &&
    typeof t?.name === 'string' &&
    typeof t?.title === 'string' &&
    typeof t?.tenant === 'string' &&
    typeof t?.tenantName === 'string' &&
    (typeof t?.group === 'string' || t?.group === null)
  );
};

export const isContentsTableList = (
  datas: unknown[],
): datas is ContentsTable[] => !datas.some((d) => !isContentsTable(d));

export type IsTblExits = {
  // テーブル名(物理名)
  name: string;
  // 存在するテーブル数
  cnt: number;
  // true: テーブル存在
  // false: 存在しない
  result: boolean;
};

export const isIsTblExits = (data: unknown): data is IsTblExits => {
  const t = data as IsTblExits;

  return (
    typeof t?.name === 'string' &&
    typeof t?.cnt === 'number' &&
    typeof t?.result === 'boolean'
  );
};

export type UploadHistoryList = {
  results: UploadHistory[];
} & Page;
// アップロード履歴
export type UploadHistory = {
  id: number;
  // ファイルの行数
  rowCount: number;
  // 取り込みした件数
  expectedCount: number;
  // エラー件数
  errorCount: number;
  // ユーザ向けのエラーメッセージ
  errorMessageCustomer: string | null;
  // エラーファイルパス
  errorFilePath: string | null;
  // アップロード種別(1: 登録, 2: 削除)
  uploadType: typeof CONTENTSDB_FILE_UPLOAD_TYPE[number];
  // ステータス
  uploadStatus: number;
  // アップロードファイル名
  uploadFileName: string;
  // BigQuery取り込み終了日時
  importEnd: string | null;
  // Elasticsearch取り込み終了日時
  esImportEnd: string | null;
};

export const isUploadHistory = (data: unknown): data is UploadHistory => {
  const t = data as UploadHistory;

  return (
    typeof t?.id === 'number' &&
    typeof t?.rowCount === 'number' &&
    typeof t?.expectedCount === 'number' &&
    typeof t?.errorCount === 'number' &&
    (typeof t?.errorMessageCustomer === 'string' ||
      t?.errorMessageCustomer === null) &&
    (typeof t?.errorFilePath === 'string' || t?.errorFilePath === null) &&
    CONTENTSDB_FILE_UPLOAD_TYPE.includes(t?.uploadType) &&
    typeof t?.uploadStatus === 'number' &&
    typeof t?.uploadFileName === 'string' &&
    (typeof t?.importEnd === 'string' || t?.importEnd === null) &&
    (typeof t?.esImportEnd === 'string' || t?.esImportEnd === null)
  );
};

export const isUploadHistoryList = (
  datas: unknown[],
): datas is UploadHistory[] => !datas.some((d) => !isUploadHistory(d));

export const isTableDataSearchList = (
  data: unknown,
): data is TableDataSearchList => {
  const d = data as TableDataSearchList;

  return (
    typeof d?.count === 'number' &&
    typeof d?.currentPage === 'number' &&
    typeof d?.countFrom === 'number' &&
    typeof d?.countTo === 'number' &&
    typeof d?.perPage === 'number' &&
    typeof d?.pageCount === 'number'
  );
};

export const isSearchFilter = (data: unknown): data is SearchFilter => {
  const d = data as SearchFilter;

  return (
    (d?.page !== undefined ? typeof d?.page === 'number' : true) &&
    (d?.limit !== undefined ? typeof d?.limit === 'number' : true) &&
    (d?.sort !== undefined ? Array.isArray(d?.sort) : true) &&
    (d?.select !== undefined ? Array.isArray(d?.select) : true)
  );
};
