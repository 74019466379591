import { useQuery } from 'react-query';
import { Segment } from 'api/segment/types';
import { getSegmentList } from 'api/segment/getSegmentList';
import { useCustomToast } from 'hooks/useCustomToast';
import { useUserTenantId } from 'hooks/user/useUserTenantId';
import { querySegmentKey } from './querySegmentKey';

/**
 * セグメント一覧取得 hooks
 */
export const useSegmentList = (): Segment[] | undefined => {
  const toast = useCustomToast();
  const tenantId = useUserTenantId();

  const response = useQuery(
    querySegmentKey.getSegmentList({ tenantId }),
    () => getSegmentList(tenantId),
    {
      onError: (err: unknown) => {
        if (err instanceof Error) {
          toast.closeAll();
          toast({
            title: err.message,
            status: 'error',
          });
        }
      },
    },
  );
  const { data: segmentList } = response;

  return segmentList;
};
