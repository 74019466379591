import { VFC, memo, useState, useCallback, useEffect } from 'react';
import { useFormContext } from 'react-hook-form';
import {
  Flex,
  Box,
  Table,
  Thead,
  Tbody,
  Tr,
  Th,
  Td,
  Text,
  Tooltip,
  Modal,
  ModalOverlay,
  ModalContent,
  ModalBody,
  ModalCloseButton,
  useDisclosure,
  Button,
} from '@chakra-ui/react';
import { Page } from 'api/common/types';
import {
  TableDataSearchType,
  TableDataSearchColType,
  SearchFilter,
  TableDataSearchList,
} from 'api/contents/types';
import { AlertBar, Paginator, LoadingSkeleton } from 'components/common/atoms';

type TableDataSearchProps = {
  searchList: TableDataSearchList | undefined;
  isSuccess: boolean;
  globalErrors: string[];
};

export const TableDataSearch: VFC<TableDataSearchProps> = memo(
  ({ searchList, isSuccess, globalErrors }) => {
    const { isOpen, onOpen, onClose } = useDisclosure();
    const [modalOriginText, setModalOriginText] = useState('');
    const {
      setValue,
      formState: { errors },
    } = useFormContext<SearchFilter>();
    const numberFormatter = new Intl.NumberFormat('ja-JP');
    const [list, setList] = useState<TableDataSearchType[]>([]);
    const [cols, setCols] = useState<TableDataSearchColType[]>([]);
    const [page, setPage] = useState<Page>({
      count: 0,
      currentPage: 0,
      countFrom: 0,
      countTo: 0,
      perPage: 0,
      pageCount: 0,
      next: '',
      previous: '',
    });

    /**
     * 省略されたテキストを表示するモーダル OPEN
     */
    const onOpenOriginTextView = useCallback(
      ({
        originText,
        isDisabled,
      }: {
        originText: string;
        isDisabled: boolean;
      }) => {
        if (isDisabled) return;
        setModalOriginText(originText);
        onOpen();
      },
      [onOpen],
    );

    /**
     * 省略されたテキストを表示するモーダル CLOSE
     */
    const onCloseOriginTextView = useCallback(() => {
      setModalOriginText('');
      onClose();
    }, [onClose]);

    // ページ変更時イベント
    const pageChange = useCallback(
      (pageId: number) => {
        setValue('page', pageId);
      },
      [setValue],
    );

    // 表示テキストの整形
    const formatText = useCallback(
      (
        data: string | number | boolean,
      ): {
        originText: string;
        trimText: string;
        isDisabled: boolean;
      } => {
        if (data === '' || data === null || data === undefined) {
          return {
            originText: '',
            trimText: '',
            isDisabled: true,
          };
        }

        const originText = String(data);
        const trimText =
          originText.length > 20
            ? `${originText.substring(0, 20)} ...`
            : originText;

        return {
          originText,
          trimText,
          isDisabled: originText.length <= 20,
        };
      },
      [],
    );

    // APIで取得したデータを表示用に整形する
    useEffect(() => {
      const tmpCols = searchList?.cols;
      const fields: TableDataSearchColType[] = Object.keys(tmpCols || {}).map(
        (key) => ({
          key,
          val: tmpCols ? tmpCols[key] : '',
        }),
      );

      const tmpList = searchList?.results || [];
      const tmpPage: Page = {
        count: searchList?.count || 0,
        currentPage: searchList?.currentPage || 0,
        countFrom: searchList?.countFrom || 0,
        countTo: searchList?.countTo || 0,
        perPage: searchList?.perPage || 0,
        pageCount: searchList?.pageCount || 0,
        next: searchList?.next || '',
        previous: searchList?.previous || '',
      };
      setCols(fields);
      setList(tmpList);
      setPage(tmpPage);
    }, [searchList]);

    // 検索ボタン押下時ページを1に変更する
    const submit = useCallback(() => {
      pageChange(1);
    }, [pageChange]);

    return (
      <Box textAlign="center">
        <Flex pl={2} justifyContent="space-between" alignItems="center">
          <Box>{numberFormatter.format(page.count || 0)} 件</Box>
          <Button
            variant="primary"
            type="submit"
            disabled={Object.keys(errors).length !== 0}
            onClick={() => submit()}
          >
            検索
          </Button>
        </Flex>
        {!isSuccess && <LoadingSkeleton />}
        {isSuccess &&
          globalErrors.length > 0 &&
          globalErrors.map((err, idx) => (
            <AlertBar
              key={`global-err-idx${String(idx)}`}
              message={err}
              status="error"
              borderRadius={8}
              mt={2}
              mb={4}
            />
          ))}
        {isSuccess && globalErrors.length === 0 && list.length === 0 && (
          <AlertBar
            status="info"
            message="データはありません。"
            bg="teal.50"
            borderRadius={8}
            mt={2}
            mb={4}
          />
        )}
        {isSuccess && list.length > 0 && (
          <>
            <Box
              width="calc(100vw - 413px)"
              maxHeight="260px"
              mt={2}
              position="relative"
              overflow="auto"
              border="1px"
              borderColor="teal.50"
            >
              <Table
                variant="simple"
                overflowX="scroll"
                width="100%"
                whiteSpace="nowrap"
              >
                <Thead position="sticky" zIndex="sticky" top={0}>
                  <Tr>
                    {cols.map((col, idx) => (
                      <Th
                        key={`data-search-th-${String(idx)}`}
                        bg="teal.50"
                        fontSize="sm"
                      >
                        {col.val}
                      </Th>
                    ))}
                  </Tr>
                </Thead>
                <Tbody>
                  {list &&
                    list.map((row, rowIdx) => (
                      <Tr key={`data-search-tr-${String(rowIdx)}`}>
                        {cols.map((col, idx) => (
                          <Td
                            key={`data-search-td-${String(rowIdx)}-${String(
                              idx,
                            )}`}
                          >
                            <Tooltip
                              label={formatText(row[col.key]).originText}
                              bg="gray.100"
                              color="black"
                              isDisabled={formatText(row[col.key]).isDisabled}
                            >
                              <Text
                                onClick={() =>
                                  onOpenOriginTextView(formatText(row[col.key]))
                                }
                                cursor={
                                  !formatText(row[col.key]).isDisabled
                                    ? 'pointer'
                                    : 'default'
                                }
                                textDecoration={
                                  !formatText(row[col.key]).isDisabled
                                    ? 'underline'
                                    : ''
                                }
                              >
                                {formatText(row[col.key]).trimText}
                              </Text>
                            </Tooltip>
                          </Td>
                        ))}
                      </Tr>
                    ))}
                  {list.length === 0 && (
                    <AlertBar
                      status="error"
                      message="データの取得ができません"
                    />
                  )}
                </Tbody>
              </Table>

              {/* 省略されたテキストを表示するモーダル */}
              <Modal
                isOpen={isOpen}
                size="lg"
                onClose={onCloseOriginTextView}
                isCentered
              >
                <ModalOverlay />
                <ModalContent bg="White">
                  <ModalBody p={4} mt={8}>
                    <Box maxH={300} overflow="auto">
                      {modalOriginText}
                    </Box>
                  </ModalBody>
                  <ModalCloseButton />
                </ModalContent>
              </Modal>
            </Box>
            <Box display="inline-block" mt={4}>
              <Paginator
                pageName="/contentsdb/search"
                config={page}
                cb={pageChange}
              />
            </Box>
          </>
        )}
      </Box>
    );
  },
);
