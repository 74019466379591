import { Drawer, useDisclosure } from '@chakra-ui/react';
import { withSuspenseAndErrorBoundary } from 'admin/components/Error/WithErrorBoundary';
import { Breadcrumb } from 'admin/components/ui/breadcrumb';
import { UsersBody } from 'admin/components/users/usersBody';
import { UsersInviteDrawer } from 'admin/components/users/usersInviteDrawer';
import { UsersTitle } from 'admin/components/users/usersTitle';
import { BreadcrumbType } from 'admin/types/ui/breadcrumb';
import {
  ErrorContents,
  LoadingSpinner
} from 'components/common/atoms';
import { useSetPageTitle } from 'hooks/useSetPageTitle';
import { FC, memo } from 'react';

const users: FC = memo(() => {
  useSetPageTitle('組織アカウント管理');
  const { isOpen, onClose, onOpen } = useDisclosure();

  const breadcrumbItems: BreadcrumbType[] = [
    { url: '/admin', title: '組織管理TOP' },
    { url: '', title: '組織アカウント管理' },
  ]

  return (
    <>
      <Breadcrumb breadcrumbItems={breadcrumbItems}/>
      <UsersTitle onOpen={onOpen} />
      <UsersBody />
      {/* ユーザー招待ドロワー */}
      <Drawer
        isOpen={isOpen}
        placement="right"
        size="sm"
        onClose={onClose}
        closeOnOverlayClick={false}
        closeOnEsc={false}
        autoFocus={false}
      >
        <UsersInviteDrawer
          onClose={onClose}
        />
      </Drawer>
    </>
  );
});

export const Users = withSuspenseAndErrorBoundary(users, {
  ErrorComponent: <ErrorContents name="組織アカウント管理" />,
  LoadingComponent: <LoadingSpinner />
});
