import {
  DrawerCloseButton,
  DrawerContent,
  DrawerHeader,
  DrawerOverlay
} from '@chakra-ui/react';
import { UsersInviteForm } from 'admin/components/users/usersInviteForm';
import { useUserPoolPermissions } from 'admin/hooks/user/useUserPoolPermissions';
import { FC, memo } from 'react';

type Props = {
  onClose: () => void;
};

export const FetchUsersInviteDrawer: FC<Props> = memo(({onClose}) => {
  const userPools = useUserPoolPermissions();

  return (
    <UsersInviteForm userPools={userPools} onClose={onClose} />
  )
})

export const UsersInviteDrawer: FC<Props> = memo((props) => (
  <>
    <DrawerOverlay />
    <DrawerContent>
      <DrawerHeader borderBottomWidth="1px">ユーザー招待</DrawerHeader>
      <FetchUsersInviteDrawer {...props} />
      <DrawerCloseButton />
    </DrawerContent>
  </>
));
