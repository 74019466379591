import { Options } from 'ky';
import { request } from 'api/request';
import { isResponseError } from 'api/types';

/**
 * グループからユーザー削除するAPI
 *
 * 状態により以下のレスポンスを返却します
 * 正常時: true, 異常時: エラーメッセージリスト
 *
 */
export const deleteGroupUser = async ({
  groupId,
  userId,
  options,
}: {
  groupId: string;
  userId: string;
  options?: Options;
}): Promise<boolean | string[]> => {
  const response = await request({
    path: `user/group/${groupId}/${userId}/`,
    method: 'delete',
    options,
  });

  if (response.error) {
    let errMessageList: string[] = [];
    if (isResponseError(response.error)) {
      if (Array.isArray(response.error?.nonFieldErrors)) {
        errMessageList = response.error.nonFieldErrors.map(
          (d: string): string => d,
        );
      }
    }
    if (!errMessageList.length) {
      errMessageList.push('グループからユーザーを削除できませんでした');
    }

    return errMessageList;
  }

  return !response.hasError;
};
