import { Box, ListItem, UnorderedList, VStack } from '@chakra-ui/react';
import { Colors, ColorSet } from 'components/paywall/config';
import { DetailDialog } from 'components/paywall/features/Detail';
import { FC, memo } from 'react';

type Props = {
  errors: string[];
  isOpen: boolean;
  onOpen: () => void;
  onClose: () => void;
  onDrawerClose: () => void;
}

export const PaymentAgencyResponseDialog: FC<Props> = memo(({
  errors,
  isOpen,
  onOpen,
  onClose,
  onDrawerClose,
}: Props) => (
  <DetailDialog
    title='決済代行連携情報の設定'
    isTitleWithIcon={false}
    isOpen={isOpen}
    onOpen={onOpen}
    onClose={onClose}
    cancelBtnTitle="閉じる"
    cancelBtnProps={{
      fontSize: '14px',
      minWidth: '114px',
      minHeight: '40px',
      ...ColorSet.BLUE
    }}
    cancelBtnHandelr={onDrawerClose}
  >
    <VStack spacing={4} alignItems="flex-start">
      {errors.length === 0 && (
        <Box w="100%">
          設定変更が完了しました。
        </Box>
      )}
      {errors.length > 0 && (
        <Box
          w="100%"
          backgroundColor={Colors.RED_40}
          px={4}
          py={4}
          borderRadius="8px"
        >
          <UnorderedList>
            {errors.map((error, idx) => (
              <ListItem key={`${error}_${String(idx)}`} fontSize="14px" fontWeight="700" color={Colors.RED_20}>{error}</ListItem>
            ))}
          </UnorderedList>
        </Box>
      )}
    </VStack>
  </DetailDialog>
));
