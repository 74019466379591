/* eslint-disable @typescript-eslint/no-unused-vars */
import { VFC, memo } from 'react';
import { MailMagazineList } from 'components/setting/mailMagazine/MailMagazineList';
import { Container } from 'components/setting/mailMagazine/templates/Container';
import { MailMagazineDrawer } from 'components/setting/mailMagazine/MailMagazineDrawer';
import { useUserInfo } from 'hooks/useUserInfo';
import { PERMISSION_DIRECTOR } from 'define';
import { QuestionIcon } from '@chakra-ui/icons';
import { Tooltip, Text } from '@chakra-ui/react';

export const MailMagazine: VFC = memo(() => {
  const { isEditableTarget } = useUserInfo();

  return (
    <Container
      title={
        <>
          メルマガ管理
          <Tooltip
            label={
              <Text fontSize="xs" textIndent="-1em" ml="1em">
                フォーム機能で配信許諾の取得・解除ができるメルマガを管理できます。作成したメルマガはメール配信設定で配信対象として選択できるようになります。
                <br />
                ※Uniikey会員登録時・Uniikeyラウンジで許諾を取得するメルマガとは別の機能になります。
              </Text>
            }
            bg="teal.50"
            color="gray.800"
            size="xs"
          >
            <QuestionIcon
              color="#536DB9"
              ml={4}
              style={{ cursor: 'pointer' }}
              size="md"
            />
          </Tooltip>
        </>
      }
      label={
        isEditableTarget(PERMISSION_DIRECTOR) && (
          <MailMagazineDrawer isAdd buttonName="新規作成" />
        )
      }
    >
      <MailMagazineList />
    </Container>
  );
});
