import {
  Box,
  Button,
  Divider,
  FormControl,
  FormErrorMessage,
  HStack,
  ModalBody,
  ModalCloseButton,
  ModalContent,
  ModalFooter,
  ModalHeader,
  ModalOverlay,
  Text,
} from '@chakra-ui/react';
import { RhfInput } from 'admin/components/form/RhfInput';
import { AlertInfoBar } from 'admin/components/ui/alertInfo';
import { ColorSet } from 'admin/define/colors';
import { TermType } from 'admin/types/userPool/terms';
import { AgreeFormType } from 'admin/types/userPool/terms/form';
import { FC } from 'react';
import { useFormContext, useFormState } from 'react-hook-form';

type Props = {
  onClose: () => void;
  onSubmit: (value: AgreeFormType) => void;
  term: TermType | null;
  isLoading: boolean;
};

export const AgreeModalComponent: FC<Props> = ({
  onClose,
  onSubmit,
  term,
  isLoading,
}) => {
  const { handleSubmit } = useFormContext<AgreeFormType>();
  const { errors, isValid } = useFormState<AgreeFormType>();
  const formId = 'agree';

  return (
    <>
      <ModalOverlay />
      <ModalContent fontSize="md" zIndex={100}>
        <ModalHeader fontSize="lg" fontWeight="bold" p="6">
          現バージョンへの同意を必須にする
        </ModalHeader>
        <form onSubmit={handleSubmit(onSubmit)} id={formId}>
          <ModalBody p="0 24px">
            <Box mb="6">
              <Text fontSize="sm" fontWeight="bold" mb="2">
                規約名
              </Text>
              <Text py="2" px="4">
                {term?.displayNameJa
                  ? term?.displayNameJa
                  : term?.displayNameForeign}
              </Text>
              <Divider />
            </Box>
            <Text fontWeight="bold" mb="1" mt="4">
              現在のバージョンの規約への同意を必須とする機能です。
            </Text>
            <Text mb="6">
              これ以前のバージョンに同意しているユーザーに対しても、最新のバージョンへの同意を強制的に取得します。
            </Text>
            <AlertInfoBar type="error" iconType="error">
              ・一度実行すると解除できないため注意してください
            </AlertInfoBar>
            <Text mt="6" mb="1" fontSize="sm" fontWeight="bold">
              実行する場合は「agree」と入力後に「実行ボタン」を押下してください。
            </Text>
            <FormControl isInvalid={Boolean(errors.message?.message)}>
              <RhfInput<AgreeFormType> name="message" type="text" />
              <FormErrorMessage>{errors.message?.message}</FormErrorMessage>
            </FormControl>
          </ModalBody>
        </form>
        <ModalFooter p="6">
          <HStack spacing={4}>
            <Button variant="solid" {...ColorSet.Default} onClick={onClose}>
              キャンセル
            </Button>
            <Button
              type="submit"
              form={formId}
              colorScheme="primary"
              mr={3}
              disabled={!isValid || isLoading}
              padding="8px 36px"
            >
              実行
            </Button>
          </HStack>
        </ModalFooter>
        <ModalCloseButton top="21px" right="21px" />
      </ModalContent>
    </>
  );
};
