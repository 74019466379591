/**
 * showErrorModalHelper.ts
 *
 * このモジュールは、エラーモーダルの表示を管理し、トリガーするための関数。
 * アプリケーションの任意の場所からエラーモーダルを表示するためのコールバック関数を設定し、
 * エラーメッセージを引数としてそのコールバックを呼び出す方法を定義。
 */

let showErrorModalCallback: ((title: string, message: string) => void) | null =
  null;

/**
 * エラーモーダルを表示するためのコールバック関数を設定します。
 *
 * @param callback - エラーモーダルを表示するために呼び出される関数。
 */
export const setShowErrorModal = (
  callback: (title: string, message: string) => void,
) => {
  showErrorModalCallback = callback;
};

/**
 * 指定されたメッセージで設定されたコールバック関数を呼び出してエラーモーダルを表示します。
 *
 * @param title - モーダルに表示されるタイトル。
 * @param message - モーダルに表示されるエラーメッセージ。
 */
export const showErrorModal = (title: string, message: string) => {
  if (showErrorModalCallback) {
    showErrorModalCallback(title, message);
  }
};
