import { useQuery } from 'react-query';
import { getWallDesign } from 'api/paywall/getWallDesign';
import { WallDesign } from 'components/paywall/pages/WallDesignList/typed';
import { useCustomToast } from 'hooks/useCustomToast';
import { useUserTenantId } from 'hooks/user/useUserTenantId';
import { WallDesignDefaultValues } from 'components/paywall/pages/WallDesignList/initial';
import { queryPaywallKey } from './queryPaywallKey';

/**
 * ウォールデザイン詳細取得 hooks
 * @param {string} wallId ウォールID
 * @param {string} designId デザインID
 * @return {WallDesign} ウォールデザイン
 */
export const usePaywallDesign = ({
  wallId,
  designId,
}: {
  wallId: string;
  designId: string;
}): WallDesign => {
  const toast = useCustomToast();
  const tenantId = useUserTenantId();

  const response = useQuery(
    queryPaywallKey.getWallDesign({ tenantId, wallId, designId }),
    () => getWallDesign(tenantId, wallId, designId),
    {
      cacheTime: 0,
      onError: (err: unknown) => {
        if (err instanceof Error) {
          toast.closeAll();
          toast({
            title: err.message,
            status: 'error',
          });
        }
      },
    },
  );
  const { data: wallDesign } = response;

  return wallDesign || WallDesignDefaultValues;
};
