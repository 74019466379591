/* eslint-disable @typescript-eslint/no-unused-vars */
/* eslint-disable @typescript-eslint/no-unsafe-member-access */
import {
  Box,
  Checkbox,
  CheckboxGroup,
  Icon
} from '@chakra-ui/react';
import styled from '@emotion/styled';
import { SearchParam, useSearch } from 'components/opt/hooks/useSearch';
import { Colors } from 'components/paywall/config';
import { StyledFlex } from 'components/paywall/styled/global';
import { FC, memo, useEffect, useState } from 'react';
import { MdSearch } from 'react-icons/md';

const StyledContainer = styled(Box)({});

const StyledCheckboxGroup = styled(CheckboxGroup)({
  display: 'flex',
  alignItems: 'center',
  color: '#444',
  fontSize: 12,
  gap: 5,
});


type Props = {
  cancelReasonName: string;
};
export const FilterCancelReason: FC<Props> = memo(({ cancelReasonName }) => {
  const { register, setValue, handleFilterRadioSearch
    // , watch
   } = useSearch();
  const { searchParam } = useSearch();
  const [selected, setSelected] = useState('item');
  useEffect(() => {
    const typeKey = cancelReasonName as keyof SearchParam;
    setSelected(searchParam[typeKey] as string);
  }, [searchParam, setValue, cancelReasonName]);

  // const watchAllFields = watch();

  return (
    <StyledContainer>
      <StyledFlex style={{ gap: 5 }}>
        <StyledCheckboxGroup>
          <Box>
            <Box>
              <Checkbox {...register(cancelReasonName)} value="ユーザーキャンセル">
                <span style={{ fontSize: 14 }}>ユーザーキャンセル</span>
              </Checkbox>
            </Box>
            <Box>
              <Checkbox {...register(cancelReasonName)} value="管理者キャンセル">
                <span style={{ fontSize: 14 }}>管理者キャンセル</span>
              </Checkbox>
            </Box>
            <Box>
              <Checkbox {...register(cancelReasonName)} value="決済エラー">
                <span style={{ fontSize: 14 }}>決済エラー</span>
              </Checkbox>
            </Box>
            <Box>
              <Checkbox {...register(cancelReasonName)} value="アップグレード">
                <span style={{ fontSize: 14 }}>アップグレード</span>
              </Checkbox>
            </Box>
            <Box>
              <Checkbox {...register(cancelReasonName)} value="退会">
                <span style={{ fontSize: 14 }}>退会</span>
              </Checkbox>
            </Box>
          </Box>
          <Box
            onClick={() => handleFilterRadioSearch(cancelReasonName)}
            w="24px"
            minW="24px"
            h="24px"
            bgColor={Colors.GRAY_60}
            borderRadius="50%"
            textAlign="center"
            cursor="pointer"
          >
            <Icon
              as={MdSearch}
              boxSize={5}
              color={Colors.GRAY_03}
              verticalAlign="bottom"
            />
          </Box>
        </StyledCheckboxGroup>
        {/* <p>送信するデータ: {JSON.stringify(watchAllFields)}</p> */}
        {/* <StyledRadioGroup
          onChange={setSelected}
          defaultValue={selected || ''}
          value={selected || ''}
          style={{ gap: 15 }}
        >
          <Box>
            <Box>
              <StyledRadio {...register(radioName)} value="ユーザーキャンセル">
                <span style={{ fontSize: 14 }}>ユーザーキャンセル</span>
              </StyledRadio>
            </Box>
            <Box>
              <StyledRadio {...register(radioName)} value="管理者キャンセル">
                <span style={{ fontSize: 14 }}>管理者キャンセル</span>
              </StyledRadio>
            </Box>
            <Box>
              <StyledRadio {...register(radioName)} value="決済エラー">
                <span style={{ fontSize: 14 }}>決済エラー</span>
              </StyledRadio>
            </Box>
            <Box>
              <StyledRadio {...register(radioName)} value="アップグレード">
                <span style={{ fontSize: 14 }}>アップグレード</span>
              </StyledRadio>
            </Box>
            <Box>
              <StyledRadio {...register(radioName)} value="退会">
                <span style={{ fontSize: 14 }}>退会</span>
              </StyledRadio>
            </Box>
          </Box>

          <TextButton
            icon={<StyledIconSearch />}
            onClick={() => {
              handleFilterRadioSearch(radioName);
            }}
          />
        </StyledRadioGroup> */}
      </StyledFlex>
    </StyledContainer>
  );
});
