import { Box } from '@chakra-ui/react';
import styled from '@emotion/styled';
import ErrorBoundary from 'api/ErrorBoundary';
import { ErrorStatusContents } from 'components/common/atoms';
import { useErrorBoundaryReset } from 'hooks/useErrorBoundaryReset';
import { FC, memo } from 'react';

const StyledContainer = styled(Box)({
  width: 'calc(100vw - 110px)',
  position: 'relative',
});
type Props = {
  children: React.ReactNode;
};

export const Layout: FC<Props> = memo(({ children }) => {
  const { ebKey, onError } = useErrorBoundaryReset();
  const renderErrorComponent = {
    404: <ErrorStatusContents code={404} redirectPath="/admin/authHub/" />,
    450: <ErrorStatusContents code={450} />,
  };

  return (
    <StyledContainer>
      <ErrorBoundary
        statusComponent={renderErrorComponent}
        onError={onError}
        key={ebKey.current}
      >
        {children}
      </ErrorBoundary>
    </StyledContainer>
  );
});
