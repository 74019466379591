import { memo } from 'react';
import { RegisterPage as Template } from 'view-template/dist/page';
import { TemplatePageProps, Wrap } from '../Page';
import { defaultTheme } from '../util';

export const RegisterPage = memo(({ watch, force }: TemplatePageProps) => {
  const dummyProps = {
    csrfToken: '',
    postPath: '',
    errorMessage: undefined,
    oauthRequest: {
      response_type: '',
      client_id: '',
      redirect_uri: '',
    },
    loginUrl: '',
    email: '',
  };

  return (
    <Wrap>
      <Template {...dummyProps} theme={defaultTheme(watch)} force={force} />
    </Wrap>
  );
});
