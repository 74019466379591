/* eslint-disable react/require-default-props */

import { Select, SelectProps, useStyleConfig } from '@chakra-ui/react';
import { FC, ReactNode } from 'react';
import {
  FieldValues,
  Path,
  PathValue,
  UnpackNestedValue,
  useController,
  useFormContext,
} from 'react-hook-form';

type RhfSelectProps<T> = {
  name: Path<T>;
  children: ReactNode;
  value?: UnpackNestedValue<PathValue<T, Path<T>>>;
} & Pick<
  SelectProps,
  | 'width'
  | 'variant'
  | 'bgColor'
  | 'isDisabled'
  | 'isRequired'
  | 'isReadOnly'
  | 'size'
  | 'placeholder'
  | 'onChange'
  | 'mb'
  | 'mt'
  | 'ml'
  | 'mr'
  | 'bgColor'
  | 'onBlur'
>;

export const RhfSelect = <T extends FieldValues>({
  name,
  children,
  value,
  ...rest
}: RhfSelectProps<T>): ReturnType<FC> => {
  const formContext = useFormContext<T>();
  const { control } = formContext;
  const { field } = useController<T>({
    name,
    control,
    defaultValue: value,
  });
  const styles = useStyleConfig('RhfSelect');

  return (
    <Select sx={styles} {...field} {...rest}>
      {children}
    </Select>
  );
};
