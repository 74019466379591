import { isPage, Page } from 'api/common/types';
import { AUTH_TYPE_KEYS, AUTH_TYPE_UNIIKEY } from 'define';
import { RawDraftEntity } from 'draft-js';

export type EnqueteListType = {
  id: string;
  title: string;
  enqueteContents: EnqueteFormType[];
  startDate: string | null;
  endDate: string | null;
  statusName: string;
  ansCnt: number;
  ansFileCnt: number;
  isPublic: boolean;
  previewCode: string;
  authType: typeof AUTH_TYPE_KEYS[number];
  isEnableCopy: boolean;
  isEnableOnlyMember: boolean;
};

export const EnqueteListDefaultValue: EnqueteListType = {
  id: '',
  title: '',
  enqueteContents: [],
  startDate: '',
  endDate: '',
  statusName: '',
  ansCnt: 0,
  ansFileCnt: 0,
  isPublic: true,
  previewCode: '',
  authType: AUTH_TYPE_UNIIKEY,
  isEnableCopy: false,
  isEnableOnlyMember: false,
};

export type EnqueteInfo = {
  results: EnqueteListType[];
} & Page;

export type EnqueteFormType = {
  id?: number | null;
  dndId: string;
  type: string;
  heading?: string | null;
  headingHtml?: string | null;
  bgHeading?: string | null;
  questionTitle?: string | null;
  descriptionTop?: string | null;
  descriptionTopHtml?: string | null;
  descriptionBottom?: string | null;
  descriptionBottomHtml?: string | null;
  wysiwyg?: string | null;
  wysiwygHtml?: string | null;
  imageUpload?: string | null;
  imageUploadAlign?: string | null;
  imageUploadSize?: number | null;
  freeRowCount?: string | null;
  freeTextMin?: string | null;
  freeTextMax?: string | null;
  freeFormat?: number | null;
  checkMax?: string | null;
  checkMin?: string | null;
  checkMatrixMax?: string | null;
  checkMatrixMin?: string | null;
  items?: string | null;
  itemsCol?: string | null;
  itemsRow?: string | null;
  fileUploadNum?: number | null;
  fileUploadSize?: number | null;
  fileUploadFormat?: number | null;
  clipstudioTextUrl?: string | null;
  clipstudioTextPassword?: string | null;
  formPresetType?: number | string | null;
  isEmailConfirm?: boolean | null;
  isHideWordLimit?: boolean | null;
  isSingleLineItems?: boolean | null;
  isTextScroll?: boolean | null;
  isCondFlg: boolean;
  condTitle: string | null;
  condItems: string | null;
  condContent?: number | null;
  required?: boolean;
  mailMagazineId?: string | null;
  confirmationMethod?: number | null;
  uniikeyUserAttributeSettingId?: string | null;
  typeOrUserAttrId?: string | null; // 実際には送信しない dummy 項目
  sortOrder?: number | null; // 設問グループの場合に使用する
};

export const EnqueteFormTypeDefaultValue: EnqueteFormType = {
  id: null,
  dndId: '',
  type: '',
  heading: '',
  headingHtml: '',
  bgHeading: '',
  questionTitle: '',
  descriptionTop: '',
  descriptionTopHtml: '',
  descriptionBottom: '',
  descriptionBottomHtml: '',
  wysiwyg: '',
  wysiwygHtml: '',
  imageUpload: '',
  imageUploadAlign: '',
  imageUploadSize: 0,
  freeRowCount: '',
  freeTextMin: '',
  freeTextMax: '',
  freeFormat: 0,
  checkMax: '',
  checkMin: '',
  checkMatrixMax: '',
  checkMatrixMin: '',
  items: '',
  itemsCol: '',
  itemsRow: '',
  fileUploadNum: 0,
  fileUploadSize: 0,
  fileUploadFormat: 0,
  clipstudioTextUrl: '',
  clipstudioTextPassword: '',
  formPresetType: 0,
  isEmailConfirm: false,
  isHideWordLimit: false,
  isSingleLineItems: false,
  isTextScroll: false,
  isCondFlg: false,
  condTitle: '',
  condItems: '',
  required: false,
  mailMagazineId: '',
  confirmationMethod: 0,
  uniikeyUserAttributeSettingId: '',
  typeOrUserAttrId: '',
  sortOrder: 0,
};

export type EnqueteSurveyForm = {
  id?: string;
  title: string;
  enqueteContents: EnqueteFormType[];
  thanks: string;
  thanksHtml: string;
  thanksButtonText: string;
  thanksButtonUrl: string;
  submitButtonText: string;
  uneditableState?: boolean;
  skin: string;
  authType: typeof AUTH_TYPE_KEYS[number];
};

export type EnqueteSurveyFormResponse = EnqueteSurveyForm & {
  previewCode: string;
};

export type EnqueteMail = {
  thanksMailFromAddress?: string;
  thanksMailReplyAddress?: string | null;
  thanksMailSubject?: string | null;
  thanksMailBodyText?: string | null;
};

export const EnqueteSurveyFormDefaultValue: EnqueteSurveyForm = {
  title: '',
  enqueteContents: [],
  thanks: '',
  thanksHtml: '',
  thanksButtonText: '',
  thanksButtonUrl: '',
  submitButtonText: '',
  skin: '',
  authType: AUTH_TYPE_UNIIKEY,
};

export type AddEnqueteSurveyForm = {
  tenantId: string;
  enqueteId?: string;
  enqueteSurverForm: EnqueteSurveyForm;
};

export type EnqueteMailForm = {
  tenantId: string;
  formId: string;
  enqueteMailSetting: EnqueteMail;
};

type userOption = {
  value: string;
  label: string;
};

export type SettingInputs = {
  id?: string;
  title?: string | null;
  startDate: string | null;
  endDate: string | null;
  answerPossibleNum?: number | null;
  answerErrMessage?: string | null;
  group: string | null;
  trigger: string | null;
  postTarget: number;
  positionPc?: number;
  positionSp?: number;
  positionTop?: number;
  positionLeft?: number;
  positionRight?: number;
  positionBottom?: number;
  scroll?: number;
  timer?: number;
  any?: string | null;
  passwordQuestion?: string | null;
  passwordAnswer?: string | null;
  bgColor?: string | null;
  buttonName?: string | null;
  buttonFontColor?: string | null;
  buttonBgColor?: string | null;
  buttonSize?: number;
  isPublic?: boolean;
  isSendThanksMail?: boolean | null;
  isNoticeMailFileDownloadUrl?: boolean | null;
  thanksMailFromAddress?: string | null;
  thanksMailReplyAddress?: string | null;
  thanksMailSubject?: string | null;
  thanksMailBodyText?: string | null;
  thanksMailFromAddressList?: string[] | [];
  thanksMailReplyAddressList?: string[] | [];
  notificationUsers?: string[] | userOption[] | [];
  enqueteContents?: EnqueteFormType[];
  authType: typeof AUTH_TYPE_KEYS[number];
  isEnableCopy: boolean;
  isEnableOnlyMember: boolean;
};

export const EnqueteSettingDefaultValue: SettingInputs = {
  startDate: null,
  endDate: null,
  group: '',
  trigger: '',
  postTarget: 1,
  buttonName: '',
  passwordQuestion: '',
  passwordAnswer: '',
  answerErrMessage: '',
  any: '',
  isSendThanksMail: false,
  isNoticeMailFileDownloadUrl: false,
  thanksMailFromAddress: '',
  thanksMailReplyAddress: '',
  thanksMailSubject: '',
  thanksMailBodyText: '',
  thanksMailFromAddressList: [],
  thanksMailReplyAddressList: [],
  enqueteContents: [],
  authType: AUTH_TYPE_UNIIKEY,
  isEnableCopy: false,
  isEnableOnlyMember: false,
};

export type EditEnqueteSettingForm = {
  tenantId: string;
  enqueteId?: string;
  enqueteSetting: SettingInputs;
};

export type EnquetePublish = {
  tenantId: string;
  enqueteId?: string;
  isPublic: boolean;
};

export type Enquete = {
  id: string;
  title: string;
  enqueteContents: EnqueteFormType[];
  thanks: string | null;
  thanksHtml: string | null;
  thanksButtonText: string | null;
  thanksButtonUrl: string | null;
  submitButtonText: string | null;
  startDate: string | null;
  endDate: string | null;
  answerPossibleNum?: number | null;
  answerErrMessage?: string | null;
  group: string | null;
  trigger: string | null;
  postTarget: number | null;
  positionPc?: number;
  positionSp?: number;
  positionTop?: number;
  positionLeft?: number;
  positionRight?: number;
  positionBottom?: number;
  scroll?: number;
  timer?: number;
  any?: string | null;
  passwordQuestion?: string | null;
  passwordAnswer?: string | null;
  bgColor?: string | null;
  buttonName?: string | null;
  buttonFontColor?: string | null;
  buttonBgColor?: string | null;
  buttonSize?: number;
  isPublic?: boolean;
  isSendThanksMail?: boolean | null;
  isNoticeMailFileDownloadUrl?: boolean | null;
  thanksMailFromAddress?: string | null;
  thanksMailReplyAddress?: string | null;
  thanksMailSubject?: string | null;
  thanksMailBodyText?: string | null;
  thanksMailFromAddressList?: string[];
  thanksMailReplyAddressList?: string[];
  notificationUsers?: string[] | userOption[];
  skin: string;
  previewCode: string;
  authType: typeof AUTH_TYPE_KEYS[number];
  isEnableCopy: boolean;
  isEnableOnlyMember: boolean;
};

export const EnquetDefaultValue: Enquete = {
  id: '',
  title: '',
  enqueteContents: [],
  thanks: '',
  thanksHtml: '',
  thanksButtonText: '',
  thanksButtonUrl: '',
  submitButtonText: '',
  startDate: null,
  endDate: null,
  group: '',
  trigger: '',
  postTarget: 1,
  isSendThanksMail: false,
  isNoticeMailFileDownloadUrl: false,
  thanksMailFromAddress: '',
  thanksMailReplyAddress: '',
  thanksMailSubject: '',
  thanksMailBodyText: '',
  thanksMailFromAddressList: [],
  thanksMailReplyAddressList: [],
  skin: '',
  previewCode: '',
  authType: AUTH_TYPE_UNIIKEY,
  isEnableCopy: false,
  isEnableOnlyMember: false,
};

export type EnquetePreviewForm = {
  redirectUrl: string;
  tenantId: string;
  enquete: Enquete;
};

/**
 * フォーム一覧 カラム用フィルター Type
 */
export type EnqueteColumnsFilter = {
  id: string;
  name: string;
};

/**
 * フォーム一覧 カラム Type
 */
export type EnqueteColumns = {
  id: string;
  key: string;
  name: string;
  isSort: boolean;
  isFilter: boolean;
  w: number;
};

export type editorType = {
  key: string;
  text: string;
  type: string;
  depth: number;
  inlineStyleRanges: [];
  entityRanges: [];
  data: Record<string, unknown>;
};

export type editorTypeInfo = {
  blocks: editorType[];
  entityMap: { [key: string]: RawDraftEntity };
};

export type fileDownloadRequestType = {
  tenantId: string;
  enqueteId: string;
  target: string;
  startDate: string;
  endDate: string;
  isRandom: boolean;
};

export type fileDownloadType = {
  url: string;
};

export type enqueteFormSelectOption = {
  id: string;
  value: string;
  name: string;
  isChart: boolean;
};

export type Skin = {
  id: string;
  tenant: string;
  name: string;
  titleBgColor: string;
  titleTextColor: string;
  ruledLineColor: string;
  submitButtonBgColor: string;
  submitButtonTextColor: string;
  labelBgColor: string;
  labelTextColor: string;
  selectButtonBgColor: string;
  selectButtonTextColor: string;
  radioButtonSelectedColor: string;
  checkboxSelectedColor: string;
  finishedButtonBgColor: string;
  finishedButtonTextColor: string;
  logoUrl: string;
  logoPosition: string;
  errorTextColor: string;
  createdAt?: string;
  updatedAt?: string;
};

export type SkinInfo = {
  results: Skin[];
} & Page;

export type SkinFormType = {
  id?: string;
  name: string;
  logoUrl: string;
  logoPosition: string;
  titleBgColor: string;
  titleTextColor: string;
  ruledLineColor: string;
  labelBgColor: string;
  labelTextColor: string;
  selectButtonBgColor: string;
  selectButtonTextColor: string;
  radioButtonSelectedColor: string;
  checkboxSelectedColor: string;
  errorTextColor: string;
  submitButtonBgColor: string;
  submitButtonTextColor: string;
  finishedButtonBgColor: string;
  finishedButtonTextColor: string;
  isPreview: boolean;
};

export type AddSkinForm = {
  tenantId: string;
  skinId?: string;
  skinForm: SkinFormType;
};

export type Code = {
  id: string;
  code: string;
  displayNameJa: string;
  displayNameForeign: string | null;
  displayOrder: number;
  codeGroup: string;
};
export type CodeGroup = {
  id: string;
  codes: Code[];
  displayNameJa: string;
  displayNameForeign: string | null;
  displayOrder: number;
  userPool: string;
};
export type EnqueteUserAttribute = {
  id: string;
  codeGroup: CodeGroup | string | null;
  displayNameJa: string;
  displayNameForeign: string | null;
  fieldName: string | null;
  dataType: string | null;
  uiType: string;
  isPersonalInformation: boolean;
  jsonPath: string | null;
  displayOrder: number;
  isAutoAppendToChild: boolean;
  userPool: string;
};
export type Body = {
  id: number;
  userAttributeSetting: EnqueteUserAttribute;
  attributeRole: string | null;
  attributeGroupHead: string;
};
export type EnqueteUserAttributeGroup = {
  id: string;
  bodies: Body[];
  isPersonalInformation: boolean;
  displayNameJa: string;
  displayNameForeign: string | null;
  displayOrder: number;
  displayType: string;
  isAutoAppendToChild: boolean;
  userPool: string;
};
export type UserAttribute = EnqueteUserAttribute | EnqueteUserAttributeGroup;
export type UserAttributes = UserAttribute[];

export type EnqueteContentGroup = {
  id: string; // 設問グループID
  name: string; // プルダウンに表示する選択肢の名前
  status: boolean; // API 側で true のものを取得する為 true が入る
  userPoolId: string;
  groupUnits: EnqueteFormType[]; // 設問グループの詳細
};
export type EnqueteContentGroups = EnqueteContentGroup[];

export const isEnqueteFormType = (data: unknown): data is EnqueteFormType => {
  const ef = data as EnqueteFormType;

  return (
    typeof ef?.id === 'number' &&
    typeof ef?.type === 'string' &&
    (ef?.heading !== null ? typeof ef?.heading === 'string' : true) &&
    (ef?.headingHtml !== null ? typeof ef?.headingHtml === 'string' : true) &&
    (ef?.bgHeading !== null ? typeof ef?.bgHeading === 'string' : true) &&
    (ef?.questionTitle !== null
      ? typeof ef?.questionTitle === 'string'
      : true) &&
    (ef?.descriptionTop !== null
      ? typeof ef?.descriptionTop === 'string'
      : true) &&
    (ef?.descriptionTopHtml !== null
      ? typeof ef?.descriptionTopHtml === 'string'
      : true) &&
    (ef?.descriptionBottom !== null
      ? typeof ef?.descriptionBottom === 'string'
      : true) &&
    (ef?.descriptionBottomHtml !== null
      ? typeof ef?.descriptionBottomHtml === 'string'
      : true) &&
    (ef?.wysiwyg !== null ? typeof ef?.wysiwyg === 'string' : true) &&
    (ef?.wysiwygHtml !== null ? typeof ef?.wysiwygHtml === 'string' : true) &&
    (ef?.imageUpload !== null ? typeof ef?.imageUpload === 'string' : true) &&
    (ef?.imageUploadAlign !== null
      ? typeof ef?.imageUploadAlign === 'string'
      : true) &&
    (ef?.imageUploadSize !== null
      ? typeof ef?.imageUploadSize === 'number'
      : true) &&
    (ef?.freeRowCount !== null ? typeof ef?.freeRowCount === 'string' : true) &&
    (ef?.freeTextMin !== null ? typeof ef?.freeTextMin === 'string' : true) &&
    (ef?.freeTextMax !== null ? typeof ef?.freeTextMax === 'string' : true) &&
    (ef?.freeFormat !== null ? typeof ef?.freeFormat === 'number' : true) &&
    (ef?.checkMax !== null ? typeof ef?.checkMax === 'string' : true) &&
    (ef?.checkMin !== null ? typeof ef?.checkMin === 'string' : true) &&
    (ef?.checkMatrixMax !== null
      ? typeof ef?.checkMatrixMax === 'number'
      : true) &&
    (ef?.checkMatrixMin !== null
      ? typeof ef?.checkMatrixMin === 'number'
      : true) &&
    (ef?.items !== null ? typeof ef?.items === 'string' : true) &&
    (ef?.itemsCol !== null ? typeof ef?.itemsCol === 'string' : true) &&
    (ef?.itemsRow !== null ? typeof ef?.itemsRow === 'string' : true) &&
    (ef?.fileUploadNum !== null
      ? typeof ef?.fileUploadNum === 'number'
      : true) &&
    (ef?.fileUploadSize !== null
      ? typeof ef?.fileUploadSize === 'number'
      : true) &&
    (ef?.fileUploadFormat !== null
      ? typeof ef?.fileUploadFormat === 'number'
      : true) &&
    (ef?.clipstudioTextUrl !== null
      ? typeof ef?.clipstudioTextUrl === 'string'
      : true) &&
    (ef?.clipstudioTextPassword !== null
      ? typeof ef?.clipstudioTextPassword === 'string'
      : true) &&
    (ef?.formPresetType !== null
      ? typeof ef?.formPresetType === 'number' ||
        typeof ef?.formPresetType === 'string'
      : true) &&
    (ef?.isEmailConfirm !== null
      ? typeof ef?.isEmailConfirm === 'boolean'
      : true) &&
    (ef?.isHideWordLimit !== null
      ? typeof ef?.isHideWordLimit === 'boolean'
      : true) &&
    (ef?.isSingleLineItems !== null
      ? typeof ef?.isSingleLineItems === 'boolean'
      : true) &&
    (ef?.isTextScroll !== null
      ? typeof ef?.isTextScroll === 'boolean'
      : true) &&
    (ef?.isCondFlg !== null ? typeof ef?.isCondFlg === 'boolean' : true) &&
    (ef?.condTitle !== null ? typeof ef?.condTitle === 'string' : true) &&
    (ef?.condItems !== null ? typeof ef?.condItems === 'string' : true) &&
    (ef?.condContent !== null ? typeof ef?.condContent === 'number' : true) &&
    typeof ef?.required === 'boolean' &&
    (ef?.mailMagazineId !== null
      ? typeof ef?.mailMagazineId === 'string'
      : true) &&
    (ef?.confirmationMethod !== null
      ? typeof ef?.confirmationMethod === 'number'
      : true) &&
    (ef?.uniikeyUserAttributeSettingId !== null
      ? typeof ef?.uniikeyUserAttributeSettingId === 'string'
      : true) &&
    (ef?.sortOrder !== null ? typeof ef?.sortOrder === 'number' : true)
  );
};

export const isEnqueteAddressType = (data: string) => typeof data === 'string';

export const isEnquete = (data: unknown): data is Enquete => {
  const e = data as Enquete;

  return (
    typeof e?.id === 'string' &&
    typeof e?.title === 'string' &&
    (e?.enqueteContents !== null && e?.enqueteContents.length > 0
      ? e?.enqueteContents.some((d) => isEnqueteFormType(d))
      : true) &&
    (e?.thanks !== null ? typeof e?.thanks === 'string' : true) &&
    (e?.thanksHtml !== null ? typeof e?.thanksHtml === 'string' : true) &&
    (e?.thanksButtonText !== null
      ? typeof e?.thanksButtonText === 'string'
      : true) &&
    (e?.thanksButtonUrl !== null
      ? typeof e?.thanksButtonUrl === 'string'
      : true) &&
    (e?.submitButtonText !== null
      ? typeof e?.submitButtonText === 'string'
      : true) &&
    (e?.startDate !== null ? typeof e?.startDate === 'string' : true) &&
    (e?.endDate !== null ? typeof e?.endDate === 'string' : true) &&
    (e?.answerPossibleNum !== null
      ? typeof e?.answerPossibleNum === 'number'
      : true) &&
    (e?.answerErrMessage !== null
      ? typeof e?.answerErrMessage === 'string'
      : true) &&
    (e?.group !== null ? typeof e?.group === 'string' : true) &&
    (e?.trigger !== null ? typeof e?.trigger === 'string' : true) &&
    (e?.postTarget !== null ? typeof e?.postTarget === 'number' : true) &&
    typeof e?.positionPc === 'number' &&
    typeof e?.positionSp === 'number' &&
    typeof e?.positionTop === 'number' &&
    typeof e?.positionLeft === 'number' &&
    typeof e?.positionRight === 'number' &&
    typeof e?.positionBottom === 'number' &&
    typeof e?.scroll === 'number' &&
    typeof e?.timer === 'number' &&
    (e?.any !== null ? typeof e?.any === 'string' : true) &&
    (e?.passwordQuestion !== null
      ? typeof e?.passwordQuestion === 'string'
      : true) &&
    (e?.passwordAnswer !== null
      ? typeof e?.passwordAnswer === 'string'
      : true) &&
    (e?.bgColor !== null ? typeof e?.bgColor === 'string' : true) &&
    (e?.buttonName !== null ? typeof e?.buttonName === 'string' : true) &&
    (e?.buttonFontColor !== null
      ? typeof e?.buttonFontColor === 'string'
      : true) &&
    (e?.buttonBgColor !== null ? typeof e?.buttonBgColor === 'string' : true) &&
    typeof e?.buttonSize === 'number' &&
    (e?.isSendThanksMail !== null
      ? typeof e?.isSendThanksMail === 'boolean'
      : true) &&
    (e?.isNoticeMailFileDownloadUrl != null
      ? typeof e?.isNoticeMailFileDownloadUrl === 'boolean'
      : true) &&
    (e?.thanksMailFromAddress !== null
      ? typeof e?.thanksMailFromAddress === 'string'
      : true) &&
    (e?.thanksMailReplyAddress !== null
      ? typeof e?.thanksMailReplyAddress === 'string'
      : true) &&
    (e?.thanksMailSubject !== null
      ? typeof e?.thanksMailSubject === 'string'
      : true) &&
    (e?.thanksMailBodyText !== null
      ? typeof e?.thanksMailBodyText === 'string'
      : true) &&
    (e?.thanksMailFromAddressList?.length &&
    e?.thanksMailFromAddressList?.length > 0
      ? e?.thanksMailFromAddressList?.some((d) => isEnqueteAddressType(d))
      : true) &&
    (e?.thanksMailReplyAddressList?.length &&
    e?.thanksMailReplyAddressList?.length > 0
      ? e?.thanksMailReplyAddressList?.some((d) => isEnqueteAddressType(d))
      : true) &&
    AUTH_TYPE_KEYS.includes(e?.authType) &&
    typeof e?.isEnableCopy === 'boolean' &&
    typeof e?.isEnableOnlyMember === 'boolean'
  );
};

export const isEnqueteListType = (data: unknown): data is EnqueteListType => {
  const el = data as EnqueteListType;

  return (
    typeof el?.id === 'string' &&
    typeof el?.title === 'string' &&
    (el?.startDate !== null ? typeof el?.startDate === 'string' : true) &&
    (el?.endDate !== null ? typeof el?.endDate === 'string' : true) &&
    typeof el?.statusName === 'string' &&
    typeof el?.ansCnt === 'number' &&
    typeof el?.ansFileCnt === 'number' &&
    typeof el?.isPublic === 'boolean' &&
    typeof el?.previewCode === 'string' &&
    AUTH_TYPE_KEYS.includes(el?.authType) &&
    typeof el?.isEnableCopy === 'boolean' &&
    typeof el?.isEnableOnlyMember === 'boolean'
  );
};

export const isEnqueteList = (datas: unknown[]): datas is EnqueteListType[] =>
  !datas.some((d) => !isEnqueteListType(d));

export const isEnqueteInfo = (data: unknown): data is EnqueteInfo => {
  const e = data as EnqueteInfo;

  return isPage(e) && isEnqueteList(e?.results);
};

export const isEnqueteSurveyForm = (
  data: unknown,
): data is EnqueteSurveyFormResponse => {
  const esf = data as EnqueteSurveyFormResponse;

  return (
    typeof esf?.title === 'string' &&
    esf?.enqueteContents.some((d) => isEnqueteFormType(d)) &&
    typeof esf?.thanks === 'string' &&
    typeof esf?.thanksHtml === 'string' &&
    typeof esf?.thanksButtonText === 'string' &&
    typeof esf?.thanksButtonUrl === 'string' &&
    typeof esf?.submitButtonText === 'string' &&
    AUTH_TYPE_KEYS.includes(esf?.authType)
  );
};

export const isSettingInputs = (data: unknown): data is SettingInputs => {
  const si = data as SettingInputs;

  return (
    typeof si?.id === 'string' &&
    (si?.startDate !== null ? typeof si?.startDate === 'string' : true) &&
    (si?.endDate !== null ? typeof si?.endDate === 'string' : true) &&
    (si?.answerPossibleNum !== null
      ? typeof si?.answerPossibleNum === 'number'
      : true) &&
    (si?.answerErrMessage !== null
      ? typeof si?.answerErrMessage === 'string'
      : true) &&
    (si?.group !== null ? typeof si?.group === 'string' : true) &&
    (si?.trigger !== null ? typeof si?.trigger === 'string' : true) &&
    (si?.postTarget !== null ? typeof si?.postTarget === 'number' : true) &&
    typeof si?.positionPc === 'number' &&
    typeof si?.positionSp === 'number' &&
    typeof si?.positionTop === 'number' &&
    typeof si?.positionLeft === 'number' &&
    typeof si?.positionRight === 'number' &&
    typeof si?.positionBottom === 'number' &&
    typeof si?.scroll === 'number' &&
    typeof si?.timer === 'number' &&
    (si?.any !== null ? typeof si?.any === 'string' : true) &&
    (si?.passwordQuestion !== null
      ? typeof si?.passwordQuestion === 'string'
      : true) &&
    (si?.passwordAnswer !== null
      ? typeof si?.passwordAnswer === 'string'
      : true) &&
    (si?.bgColor !== null ? typeof si?.bgColor === 'string' : true) &&
    (si?.buttonName !== null ? typeof si?.buttonName === 'string' : true) &&
    (si?.buttonFontColor !== null
      ? typeof si?.buttonFontColor === 'string'
      : true) &&
    (si?.buttonBgColor !== null
      ? typeof si?.buttonBgColor === 'string'
      : true) &&
    typeof si?.buttonSize === 'number' &&
    (si?.isSendThanksMail !== null
      ? typeof si?.isSendThanksMail === 'boolean'
      : true) &&
    (si?.isNoticeMailFileDownloadUrl !== null
      ? typeof si?.isNoticeMailFileDownloadUrl === 'boolean'
      : true) &&
    (si?.thanksMailFromAddress !== null
      ? typeof si?.thanksMailFromAddress === 'string'
      : true) &&
    (si?.thanksMailReplyAddress !== null
      ? typeof si?.thanksMailReplyAddress === 'string'
      : true) &&
    (si?.thanksMailSubject !== null
      ? typeof si?.thanksMailSubject === 'string'
      : true) &&
    (si?.thanksMailBodyText !== null
      ? typeof si?.thanksMailBodyText === 'string'
      : true) &&
    (si?.enqueteContents && si?.enqueteContents?.length > 0
      ? si?.enqueteContents.some((d) => isEnqueteFormType(d))
      : true) &&
    AUTH_TYPE_KEYS.includes(si?.authType) &&
    typeof si?.isEnableCopy === 'boolean' &&
    typeof si?.isEnableOnlyMember === 'boolean'
  );
};

export const isEnquetePublish = (data: unknown): data is EnquetePublish => {
  const ep = data as EnquetePublish;

  return typeof ep?.isPublic === 'boolean';
};

export const isFileDownloadType = (data: unknown): data is fileDownloadType => {
  const ep = data as fileDownloadType;

  return typeof ep?.url === 'string';
};

export const isSkin = (data: unknown): data is Skin => {
  const s = data as Skin;

  return (
    (s?.id !== null ? typeof s?.id === 'string' : true) &&
    (s?.tenant !== null ? typeof s?.tenant === 'string' : true) &&
    (s?.name !== null ? typeof s?.name === 'string' : true) &&
    (s?.titleBgColor !== null ? typeof s?.titleBgColor === 'string' : true) &&
    (s?.titleTextColor !== null
      ? typeof s?.titleTextColor === 'string'
      : true) &&
    (s?.ruledLineColor !== null
      ? typeof s?.ruledLineColor === 'string'
      : true) &&
    (s?.submitButtonBgColor !== null
      ? typeof s?.submitButtonBgColor === 'string'
      : true) &&
    (s?.submitButtonTextColor !== null
      ? typeof s?.submitButtonTextColor === 'string'
      : true) &&
    (s?.labelBgColor !== null ? typeof s?.labelBgColor === 'string' : true) &&
    (s?.labelTextColor !== null
      ? typeof s?.labelTextColor === 'string'
      : true) &&
    (s?.selectButtonBgColor !== null
      ? typeof s?.selectButtonBgColor === 'string'
      : true) &&
    (s?.selectButtonTextColor !== null
      ? typeof s?.selectButtonTextColor === 'string'
      : true) &&
    (s?.radioButtonSelectedColor !== null
      ? typeof s?.radioButtonSelectedColor === 'string'
      : true) &&
    (s?.checkboxSelectedColor !== null
      ? typeof s?.checkboxSelectedColor === 'string'
      : true) &&
    (s?.finishedButtonBgColor !== null
      ? typeof s?.finishedButtonBgColor === 'string'
      : true) &&
    (s?.finishedButtonTextColor !== null
      ? typeof s?.finishedButtonTextColor === 'string'
      : true) &&
    (s?.logoUrl !== null ? typeof s?.logoUrl === 'string' : true) &&
    (s?.logoPosition !== null ? typeof s?.logoPosition === 'string' : true) &&
    (s?.errorTextColor !== null
      ? typeof s?.errorTextColor === 'string'
      : true) &&
    (s?.createdAt !== null ? typeof s?.createdAt === 'string' : true) &&
    (s?.updatedAt !== null ? typeof s?.updatedAt === 'string' : true)
  );
};

export const isSkinFormList = (datas: unknown[]): datas is Skin[] =>
  !datas.some((d) => !isSkin(d));

export const isSkinInfo = (data: unknown): data is SkinInfo => {
  const s = data as SkinInfo;

  return isPage(s) && isSkinFormList(s?.results);
};

export const isCode = (data: unknown): data is Code => {
  const e = data as Code;

  return (
    typeof e?.id === 'string' &&
    typeof e?.code === 'string' &&
    typeof e?.displayNameJa === 'string' &&
    (e?.displayNameForeign === null
      ? true
      : typeof e?.displayNameForeign === 'string') &&
    typeof e?.displayOrder === 'number' &&
    typeof e?.codeGroup === 'string'
  );
};

export const isCodeGroup = (data: unknown): data is CodeGroup => {
  const e = data as CodeGroup;

  return (
    typeof e?.id === 'string' &&
    typeof Array.isArray(e?.codes) &&
    (Array.isArray(e?.codes) && e?.codes.length > 0
      ? e?.codes.every((d) => isCode(d))
      : true) &&
    typeof e?.displayNameJa === 'string' &&
    (e?.displayNameForeign === null
      ? true
      : typeof e?.displayNameForeign === 'string') &&
    typeof e?.displayOrder === 'number' &&
    typeof e?.userPool === 'string'
  );
};

export const isEnqueteUserAttribute = (
  data: unknown,
): data is EnqueteUserAttribute => {
  const e = data as EnqueteUserAttribute;

  return (
    typeof e?.id === 'string' &&
    (e?.codeGroup === null || typeof e?.codeGroup === 'string'
      ? true
      : isCodeGroup(e?.codeGroup)) &&
    typeof e?.displayNameJa === 'string' &&
    (e?.displayNameForeign === null
      ? true
      : typeof e?.displayNameForeign === 'string') &&
    (e?.fieldName === null ? true : typeof e?.fieldName === 'string') &&
    (e?.dataType === null ? true : typeof e?.dataType === 'string') &&
    typeof e?.uiType === 'string' &&
    typeof e?.isPersonalInformation === 'boolean' &&
    (e?.jsonPath === null ? true : typeof e?.jsonPath === 'string') &&
    typeof e?.displayOrder === 'number' &&
    typeof e?.isAutoAppendToChild === 'boolean' &&
    typeof e?.userPool === 'string'
  );
};

export const isBody = (data: unknown): data is Body => {
  const e = data as Body;

  return (
    typeof e?.id === 'number' &&
    isEnqueteUserAttribute(e?.userAttributeSetting) &&
    (e?.attributeRole === null ? true : typeof e?.attributeRole === 'string') &&
    typeof e?.attributeGroupHead === 'string'
  );
};

export const isBodies = (datas: unknown[]): datas is Body[] =>
  Array.isArray(datas) &&
  (datas.length > 0 ? datas.every((d) => isBody(d)) : true);

export const isEnqueteUserAttributeGroup = (
  data: unknown,
): data is EnqueteUserAttributeGroup => {
  const e = data as EnqueteUserAttributeGroup;

  return (
    typeof e?.id === 'string' &&
    isBodies(e?.bodies) &&
    typeof e?.isPersonalInformation === 'boolean' &&
    typeof e?.displayNameJa === 'string' &&
    (e?.displayNameForeign === null
      ? true
      : typeof e?.displayNameForeign === 'string') &&
    typeof e?.displayOrder === 'number' &&
    typeof e?.displayType === 'string' &&
    typeof e?.isAutoAppendToChild === 'boolean' &&
    typeof e?.userPool === 'string'
  );
};

export const isUserAttribute = (data: unknown): data is UserAttribute => {
  const e = data as UserAttribute;

  return isEnqueteUserAttribute(e) || isEnqueteUserAttributeGroup(e);
};

export const isUserAttributes = (datas: unknown[]): datas is UserAttributes =>
  Array.isArray(datas) &&
  (datas.length > 0 ? datas.every((d) => isUserAttribute(d)) : true);

export const isGroupUnits = (datas: unknown[]): datas is EnqueteFormType[] => {
  const result =
    Array.isArray(datas) &&
    (datas.length > 0 ? datas.every((d) => isEnqueteFormType(d)) : true);

  return result;
};

export const isEnqueteContentGroup = (
  data: unknown,
): data is EnqueteContentGroup => {
  const e = data as EnqueteContentGroup;

  return (
    typeof e?.id === 'string' &&
    typeof e?.name === 'string' &&
    typeof e?.status === 'boolean' &&
    typeof e?.userPoolId === 'string' &&
    isGroupUnits(e?.groupUnits)
  );
};

export const isEnqueteContentGroups = (
  datas: unknown[],
): datas is EnqueteContentGroups =>
  Array.isArray(datas) &&
  (datas.length > 0 ? datas.every((d) => isEnqueteContentGroup(d)) : true);
