import { Box, Stack, StackDivider } from '@chakra-ui/react';
import { MemberAttribute } from 'admin/components/member/detail/memberAttribute';
import { MemberInfo } from 'admin/components/member/detail/memberInfo';
// import { MemberLogs } from 'admin/components/member/detail/memberLogs';
// import { MemberRemove } from 'admin/components/member/detail/memberRemove';
// import { MemberStatusChange } from 'admin/components/member/detail/memberStatusChange';
import { Colors } from 'admin/define/colors';
// import { MemberType } from 'admin/types/member';
import { FC, memo } from 'react';

type Props = {
  // TODO: 9月末のリリース時点では必要では無い為、コメントアウト
  // member: MemberType | undefined;
  // setMember: Dispatch<SetStateAction<MemberType | undefined>>;
};

export const MemberContentsComponent: FC<Props> = memo(() => (
  // TODO: 9月末のリリース時点では必要では無い為、コメントアウト
  // {
  //   member,
  //   setMember,
  // }: Props
  <>
    <Box backgroundColor={Colors.WHITE} p={6} mt="16px" borderRadius="8px">
      <Stack
        spacing={10}
        divider={<StackDivider borderColor={Colors.GRAY_30} />}
      >
        {/* TODO: 9月末のリリース時点ではsetMemberが必要では無い為、削除（戻す時は下のMemberInfoを有効にしてください） */}
        <MemberInfo />
        {/* <MemberInfo setMember={setMember} /> */}
        <MemberAttribute />
        {/* TODO: 9月末のリリース時点では必要では無い為、コメントアウト */}
        {/* <MemberLogs hasPagination={false} /> */}
      </Stack>
    </Box>
    {/* TODO: 9月末のリリース時点では必要では無い為、コメントアウト */}
    {/* <Stack spacing={2} mt="40px">
        <MemberStatusChange member={member} />
        <MemberRemove member={member} />
      </Stack> */}
  </>
));
