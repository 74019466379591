import { Text, useDisclosure } from '@chakra-ui/react';
import { DoubleConfirmDialog } from 'components/common/molecules';
import { Button } from 'components/paywall/atoms/Button';
import { TextButton } from 'components/paywall/atoms/TextButton';
import { usePaywallPromotion } from 'components/paywall/hooks/usePaywallPromotion';
import { usePaywallUtil } from 'components/paywall/hooks/usePaywallUtil';
import { Drawer } from 'components/paywall/molecules/Drawer';
import { PromotionFormDefaultValues } from 'components/paywall/pages/PromotionList/initial';
import {
  Promotion,
  PromotionFormType,
} from 'components/paywall/pages/PromotionList/typed';
import { FC, memo, useCallback, useState } from 'react';
import { FormProvider, useForm } from 'react-hook-form';
import { Form } from './Form';

type Props = {
  isAdd?: boolean;
  buttonMode?: 'simple' | 'gray' | 'text';
  buttonName: string;
  obj?: Promotion;
};

/**
 * @see プロモーションの新規作成＆編集ドロワー
 * @param isAdd 新規作成か編集かの判定
 * @param buttonMode ボタンに渡すモード
 * @param buttonName ボタンの名前
 * @param Promotion プロモーションのオブジェクト
 */
export const PromotionDrawer: FC<Props> = memo(
  ({ isAdd, buttonMode, buttonName, obj }) => {
    const [isLoading, setLoading] = useState<boolean>(false);
    const [warningTexts, setWarningTexts] = useState<string[]>([]);
    const { isOperationAuth } = usePaywallUtil();
    const methods = useForm<PromotionFormType>({
      mode: 'onBlur',
      defaultValues: PromotionFormDefaultValues,
    });
    const {
      getValues,
      reset,
      setError,
      formState: { errors },
    } = methods;
    const { isOpen, onOpen, onClose } = useDisclosure();
    const {
      isOpen: isDialogOpen,
      onClose: onDialogClose,
      onOpen: onDialogOpen,
    } = useDisclosure();
    const { savePromotion, onDrawerClose } = usePaywallPromotion({
      getValues,
      isAdd,
      setError,
      onClose,
      reset,
      setLoading,
    });

    // 確認つきダイアログの実行ボタンを押した時の処理
    const submitDialogBtnHandler = useCallback(() => {
      savePromotion();
      onDialogClose();
    }, [savePromotion, onDialogClose]);

    const onSubmit = useCallback(() => {
      // 価格か適用回数が変更された場合は確認ダイアログを表示する
      const priceChanged =
        String(obj?.price || 0) !== String(getValues('price') || 0);
      const monthChanged =
        String(obj?.month || 0) !== String(getValues('month') || 0);

      if (!isAdd && (priceChanged || monthChanged)) {
        // ダイアログで表示するテキストを設定する
        const targetList = [];
        if (priceChanged) targetList.push('「価格」');
        if (monthChanged) targetList.push('「適用回数」');

        const target = targetList.join('、');

        setWarningTexts([
          `${target}が変更されます。`,
          '保存後、即時に反映されます。',
          '設定変更後に新規で購入する場合が対象になります。既に購入済みのユーザーの価格や適用回数には反映されません。購入時点の内容となります。',
        ]);

        onDialogOpen();
      } else {
        savePromotion();
      }
    }, [isAdd, getValues, obj, onDialogOpen, savePromotion]);

    return (
      <>
        <FormProvider {...methods}>
          {buttonMode !== 'text' && (
            <Button
              mode={buttonMode}
              onClick={onOpen}
              disabled={!isOperationAuth()}
            >
              {buttonName}
            </Button>
          )}
          {buttonMode === 'text' && (
            <TextButton
              onClick={onOpen}
              whiteSpace="pre-line"
              word-break="break-all"
              textAlign="left"
              height="auto"
              fontSize="18px"
              fontWeight="700"
              lineHeight="170%"
            >
              {buttonName}
            </TextButton>
          )}
          <Drawer<PromotionFormType>
            type="id"
            headerLabel="プロモーションID"
            title={isAdd ? 'プロモーション新規作成' : 'プロモーション編集'}
            isOpen={isOpen}
            disabled={Object.keys(errors).length > 0}
            onClose={onDrawerClose}
            onSubmit={onSubmit}
            isLoading={isLoading}
            isAdd={isAdd}
          >
            <Form isAdd={isAdd} obj={obj} />
          </Drawer>
        </FormProvider>
        <DoubleConfirmDialog
          title="変更についての確認"
          submitBtnTitle="実行"
          submitBtnHandler={submitDialogBtnHandler}
          cancelBtnTitle="キャンセル"
          descriptionPrefix="実行"
          warningTexts={warningTexts}
          isLoading={isLoading}
          isOpen={isDialogOpen}
          onClose={onDialogClose}
        >
          <Text mt={4}>
            <Text as="span" fontWeight="bold">
              {obj?.id}
            </Text>{' '}
            の変更を実行しますか？
          </Text>
        </DoubleConfirmDialog>
      </>
    );
  },
);
