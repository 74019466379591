import {
  Box,
  DrawerCloseButton,
  DrawerContent,
  DrawerHeader,
  DrawerOverlay,
  Text,
} from '@chakra-ui/react';
import { NewsletterCreateForm } from 'admin/components/idPoolConsumer/newsletter/newsletterForm';
import { FC, memo } from 'react';

type Props = {
  onClose: () => void;
};

export const NewsletterCreateDrawer: FC<Props> = memo(({ onClose }: Props) => (
  <>
    <DrawerOverlay />
    <DrawerContent>
      <DrawerHeader pb={0}>
        <Box m={0} px={0} pb="24px" borderBottomWidth="1px">
          <Text>メールマガジン購読設定 作成</Text>
        </Box>
      </DrawerHeader>
      <NewsletterCreateForm onClose={onClose} />
      <DrawerCloseButton top="16px" right="24px" />
    </DrawerContent>
  </>
));
