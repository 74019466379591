import { Box, VStack } from '@chakra-ui/react';
import { Layout } from 'admin/components/authHub/layout';
import ErrorBoundary from 'api/ErrorBoundary';
import { LoadingSkeleton } from 'components/common/atoms';
import { ListError } from 'components/common/molecules';
import { useErrorBoundaryReset } from 'hooks/useErrorBoundaryReset';
import { Suspense, memo } from 'react';
import { Outlet } from 'react-router-dom';

export const AuthHubInner = memo(() => {
  const { ebKey, onError } = useErrorBoundaryReset();

  return (
    <VStack w="100%" spacing={4}>
      <Box
        w="100%"
        gridGap={2}
        display="flex"
        justifyContent="space-between"
        alignItems="start"
        mr={2}
        mb={2}
      >
        <ErrorBoundary
          errorComponent={<ListError message="Auth Hub設定を表示できません" />}
          onError={onError}
          key={ebKey.current}
        >
          <Suspense fallback={<LoadingSkeleton width="100%" />}>
            <Layout>
              <Outlet />
            </Layout>
          </Suspense>
        </ErrorBoundary>
      </Box>
    </VStack>
  );
});
