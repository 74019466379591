/* eslint-disable @typescript-eslint/no-unsafe-member-access */
import { Box, Icon, Radio, RadioGroup } from '@chakra-ui/react';
import styled from '@emotion/styled';
import { SearchParam, useSearch } from 'components/opt/hooks/useSearch';
import { Input } from 'components/paywall/atoms/Input';
// import { TextButton } from 'components/paywall/atoms/TextButton';
import { Colors } from 'components/paywall/config';
import { StyledFlex } from 'components/paywall/styled/global';
import { FC, memo, useEffect, useState } from 'react';
import { MdSearch } from 'react-icons/md';

const StyledContainer = styled(Box)({});

const StyledRadioGroup = styled(RadioGroup)({
  display: 'flex',
  alignItems: 'center',
  color: '#444',
  fontSize: 12,
  gap: 5,
});

const StyledRadio = styled(Radio)({
  backgroundColor: '#ccc',
  borderColor: '#ccc',
});

type Props = {
  radioName: string;
  inputName: string;
};
export const FilterPlan: FC<Props> = memo(({ radioName, inputName }) => {
  const { register, setValue, handleFilterPlanSearch } = useSearch();
  const { searchParam } = useSearch();
  const [selected, setSelected] = useState('item_id');
  useEffect(() => {
    const inputKey = inputName as keyof SearchParam;
    setValue(inputName, searchParam[inputKey] as string);
    const typeKey = radioName as keyof SearchParam;
    setSelected(searchParam[typeKey] as string);
  }, [inputName, searchParam, setValue, radioName]);

  return (
    <StyledContainer>
      <StyledFlex style={{ gap: 5 }}>
        <StyledRadioGroup
          onChange={setSelected}
          defaultValue={selected || 'item_id'}
          value={selected || 'item_id'}
        >
          <StyledRadio {...register(radioName)} value="item_id">
            単品
          </StyledRadio>
          <StyledRadio {...register(radioName)} value="course_id">
            コース
          </StyledRadio>
          <Input
            size="sm"
            style={{ width: 150, backgroundColor: '#fff' }}
            ref={register(inputName)}
          />
          <Box
            onClick={() => handleFilterPlanSearch(inputName, radioName)}
            w="24px"
            minW="24px"
            h="24px"
            bgColor={Colors.GRAY_60}
            borderRadius="50%"
            textAlign="center"
            cursor="pointer"
          >
            <Icon
              as={MdSearch}
              boxSize={5}
              color={Colors.GRAY_03}
              verticalAlign="middle"
            />
          </Box>
        </StyledRadioGroup>
      </StyledFlex>
    </StyledContainer>
  );
});
