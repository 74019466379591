import { Box, Flex } from '@chakra-ui/react';
import styled from '@emotion/styled';
import ErrorBoundary from 'api/ErrorBoundary';
import { ErrorStatusContents } from 'components/common/atoms';
import { Sidebar } from 'components/paywall/organisms/Sidebar';
import { useErrorBoundaryReset } from 'hooks/useErrorBoundaryReset';
import { FC, memo } from 'react';
import { useRecoilState } from 'recoil';
import { paywallSidebarState } from 'store/paywallSidebarState';

const StyledContainer = styled(Box)({
  width: 'calc(100vw - 110px)',
  height: '100%',
});
const StyledSidebar = styled(Sidebar)({
  position: 'relative',
  width: '300px',
});
type Props = {
  children: React.ReactNode;
};
/**
 * @see Paywallのレイアウト構成コンポーネント
 * @param children 子要素
 */
export const Layout: FC<Props> = memo(({ children }) => {
  const [isOpen] = useRecoilState(paywallSidebarState);
  const { ebKey, onError } = useErrorBoundaryReset();
  const renderErrorComponent = {
    404: <ErrorStatusContents code={404} redirectPath='/paywall/' />,
    450: <ErrorStatusContents code={450} />,
  };

  return (
    <StyledContainer>
      <StyledSidebar />
      <Flex
        pl={isOpen ? "234px" : "0px"}
        transition='all .2s ease'
      >
        <ErrorBoundary
          statusComponent={renderErrorComponent}
          onError={onError}
          key={ebKey.current}
        >
          {children}
        </ErrorBoundary>
      </Flex>
    </StyledContainer>
  )
});

