import { Checkbox, Text, VStack } from '@chakra-ui/react';
import { Detail, DetailBlock, DetailFlex, DetailItem, DetailItems } from 'components/paywall/features/Detail';
import { DetailCustomerLinkText } from 'components/paywall/features/Detail/DetailCustomer';
import { DetailOrderCancelDialog } from 'components/paywall/features/Detail/DetailDialog/DetailOrderCancelDialog';
import { CustomerDetailOrder } from 'components/paywall/pages/CustomerList/typed';
import { useFormatDate } from 'hooks/useFormatDate';
import { FC, memo, useCallback, useState } from 'react';

type Props = {
  order: CustomerDetailOrder;
  customerUid: string;
  idx: number;
};

type StatusType = {
  status: number;
  name: string;
}

const cancelReasonTypes: StatusType[] = [
  {status: 1, name: 'ユーザーキャンセル'},
  {status: 1, name: '管理者キャンセル'},
  {status: 3, name: '決済エラー'},
  {status: 4, name: 'アップグレード'},
  {status: 5, name: '退会'},
]

export const CustomerDetailInnerOrdersItem: FC<Props> = memo(({ order, customerUid, idx }) => {
  const [isShowButton, setIsShowButton] = useState<boolean>(false);
  const { dateTimeFormat } = useFormatDate();

  // 顧客詳細情報 > 終了理由用ステータスコード取得
  const getCancelReasonType = (reasonCode: number) => {
    const target = cancelReasonTypes.filter((x) => x.status === reasonCode);
    if(target.length === 0) return {
      name: '',
      stauts: 0
    }

    return target[0];
  }

  // サブスクリプション自動配信停止ボタンを表示用チェックボックス押下時処理
  // ※次回決済の自動更新が無効の場合は処理を行わない
  const onClickCheckBox = useCallback((isCheck: boolean, nextTran: boolean) => {
    if(!nextTran) return;

    setIsShowButton(isCheck);
  }, []);

  // データ表示可否
  // 次回決済がない or データがnullの場合は「-」表示とする
  // return bool true データ表示 false - 表示
  const isShowData = (val: string|number|null) => order.isNextTran && val !== null;

  return(
    <Detail type={2} title={idx === 0 ? "有効なサブスクリプション注文" : ""} spacing={idx !== 0 ? '8px' : undefined} key={`${String(order.id)}_${String(idx)}`}>
      <DetailBlock space={24}>
        <DetailFlex>
          <DetailItems spacing="8px">
            <DetailItem label="注文ID" text={order.id} labelWidth="56px" spacing="16px" />
            <DetailItem label="決済ID" text={<DetailCustomerLinkText path={`/paywall/${order.tranId}/orderDetail`} text={order.tranId} />} labelWidth="56px" spacing="16px" />
            <DetailItem label="決済金額" text={`${order.price}円`} labelWidth="56px" spacing="16px" />
          </DetailItems>
          <DetailItems spacing="8px">
            <DetailItem label="コース" text={order.courseName} labelWidth="56px" spacing="16px" />
            <DetailItem label="決済日時" text={dateTimeFormat(order.execTranDate)} labelWidth="56px" spacing="16px" />
          </DetailItems>
        </DetailFlex>
      </DetailBlock>
      <DetailBlock space={24} spacerTitle="次回決済情報">
        <DetailBlock space={8} divider={false}>
          <DetailFlex>
            <DetailItems spacing="8px">
              <DetailItem label="次回決済日" text={isShowData(order.nextTranDate) ? dateTimeFormat(order.nextTranDate) : '-'} labelWidth="84px" spacing="16px" />
              <DetailItem label="次回決済金額" text={isShowData(order.nextPrice) ? `${order.nextPrice}円` : '-'} labelWidth="84px" spacing="16px" />
              <DetailItem label="権利有効期限" text={isShowData(order.rightsExpirationDate) ? order.rightsExpirationDate : '-'} labelWidth="84px" spacing="16px" />
            </DetailItems>
            <DetailItems spacing="8px">
              <DetailItem label="次回決済の自動更新" text={order.isNextTran ? '有効' : '-'} labelWidth="154px" spacing="16px" />
              <DetailItem label="次回プロモーション適用" text={isShowData(order.nextPromotionName) ? order.nextPromotionName : '-'} labelWidth="154px" spacing="16px" />
              <DetailItem label="終了理由" text={isShowData(order.cancelReason) ? getCancelReasonType(order.cancelReason).name : '-'} labelWidth="154px" spacing="16px" />
            </DetailItems>
          </DetailFlex>
        </DetailBlock>
      </DetailBlock>
      <DetailBlock space={24}>
        <VStack spacing="8px" alignItems="flex-end">
          <Checkbox
            isChecked={isShowButton}
            onChange={() => onClickCheckBox(!isShowButton, order.isNextTran)}
            disabled={!order.isNextTran}
          >
            <Text fontSize="14px" fontWeight="400" color={order.isNextTran ? 'inherit' : '#b7bbbe'}>サブスクリプションの自動配信停止ボタンを表示</Text>
          </Checkbox>
          <DetailOrderCancelDialog orderId={order.id} customerUid={customerUid} isShowButton={isShowButton} />
        </VStack>
      </DetailBlock>
    </Detail>
  );
});
