import { logger } from 'api/logger';
import { request } from 'api/request';
import { isResponseError, ResponseError } from 'api/types';
import {
  Course, CourseFormType, CourseSubmitFormType, isCourse
} from 'components/paywall/pages/CourseList/typed';
import produce from 'immer';
import { Options } from 'ky';
import { getValidationError, ValidationError } from 'utils/form';
import { messages } from './messages';

const DEFAULT_ERR_MESSAGE: ValidationError<CourseSubmitFormType> = {
  others: [messages.postCourse.customer.error],
};
const DEFAULT_SYSTEM_ERR_MESSAGE: ValidationError<CourseSubmitFormType> = {
  others: [messages.postCourse.customer.failed],
};

/**
 * コース作成 API
 * @param {CourseSubmitFormType} formParam API送信パラメータ
 * @param {Options} options APIオプション
 * @return {Promise<Course | ResponseError>} コース
 */
export const postCourse = async (
  formParam: CourseSubmitFormType,
  options?: Options,
): Promise<Course | ResponseError> => {
  const { tenantId, wallId, courseForm } = formParam;
  const path = `paywall/${tenantId}/${wallId}/course/`;
  const postParam = courseForm;

  const mergedOptions = options
    ? produce(options, (draft) => {
        draft.json = { ...postParam };
      })
    : { json: postParam };

  const response = await request({
    path,
    method: 'post',
    options: mergedOptions,
  });

  if (response.status >= 500) {
    return DEFAULT_ERR_MESSAGE;
  }

  if (response.status === 403) {
    return DEFAULT_SYSTEM_ERR_MESSAGE;
  }

  const responseData = response.data;

  // コース作成成功
  if (isCourse(responseData)) {
    return responseData;
  }

  const { error } = response;

  if (response.hasError) {
    // APIにて返却されたエラー形式
    if (isResponseError(error)) {
      const formError = getValidationError<CourseFormType>({
        formData: formParam.courseForm,
        response: error,
      });

      return formError;
    }
  }

  // 予期せぬエラーなので
  // バックエンドに状態を通知
  await logger({
    loglevel:
      response.status === 404 || response.status === 450 ? 'warning' : 'error',
    data: error,
    message: messages.postCourse.system.typeError,
  });

  // 登録できていない旨を通知
  return DEFAULT_SYSTEM_ERR_MESSAGE;
};
