import { withSuspenseAndErrorBoundary } from 'admin/components/Error/WithErrorBoundary';
import { IdPoolConsumerListsComponent } from 'admin/components/idPoolConsumer/idPoolConsumerLists/IdPoolConsumerListsComponent';
import { IdPoolConsumerNotCreated } from 'admin/components/idPoolConsumer/idPoolConsumerNotCreated';
import { useIdPoolConsumers } from 'admin/hooks/idPoolConsumers/useIdPoolConsumers';
import { ErrorContents, LoadingSpinner } from 'components/common/atoms';
import { FC, memo } from 'react';

const FetchIdPoolConsumerLists: FC = memo(() => {
  const { data: idPoolConsumersList } = useIdPoolConsumers();
  if (!idPoolConsumersList || idPoolConsumersList.length === 0) {
    return <IdPoolConsumerNotCreated />;
  }

  return (
    <IdPoolConsumerListsComponent idPoolConsumersList={idPoolConsumersList} />
  );
});

export const idPoolConsumerLists: FC = memo(() => <FetchIdPoolConsumerLists />);

export const IdPoolConsumerLists = withSuspenseAndErrorBoundary(
  idPoolConsumerLists,
  {
    ErrorComponent: <ErrorContents name="ユーザープール一覧画面" />,
    LoadingComponent: <LoadingSpinner />,
  },
);
