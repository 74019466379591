import { SideMenuOperationButton } from 'components/paywall/atoms/SideMenuOperationButton';
import { Layout } from 'components/paywall/templates/Layout';
import { useSetPageTitle } from 'hooks/useSetPageTitle';
import { FC } from 'react';
import { Outlet } from 'react-router-dom';


export const Paywall: FC = () => {
  useSetPageTitle('ペイウォール');

  return (
    <Layout>
      <Outlet />
      <SideMenuOperationButton />
    </Layout>
  );
};
