import { Box, Text } from '@chakra-ui/react';
import { ServiceTitleLabel } from 'admin/components/member/service/serviceTitleLabel';
import { FC, memo } from 'react';

export const ServiceTitle: FC = memo(() => (
  <Box mt="40px">
    <ServiceTitleLabel />
    <Box mt="8px">
      <Text
        fontFamily="Hiragino Sans"
        fontSize="18px"
        fontWeight="700"
        lineHeight="170%"
      >
        テナント詳細
      </Text>
    </Box>
  </Box>
));
