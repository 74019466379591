/**
 * ツリー(階層)形式のデータを表示するコンポーネント
 *
 * 参照: https://github.com/minop1205/react-dnd-treeview
 *
 * ----- 使い方 -----
 * type CustomData = {
 *   tblId: string;
 *   groupId: string;
 * };
 *
 * tree: NodeModel<CustomData>[] = [
 *   {
 *     id: 1,
 *     parent: 0,
 *     text: '商品マスタ',
 *     droppable: false,
 *     data: {
 *       tblId: 'tbl_abc',
 *       groupId: 'grp_abc',
 *     },
 *   },
 *   {
 *     id: 2,
 *     parent: 0,
 *     text: 'カテゴリマスタ',
 *     droppable: false,
 *     data: {
 *       tblId: 'tbl_opq',
 *       groupId: 'grp_opq',
 *     },
 *   },
 * ];
 *
 * export const SampleTree = () => {
 *   const [treeData] = useState<NodeModel<CustomData>[]>(tree);
 *
 *   return (
 *     <TreeView<CustomData>
 *       tree={treeData}
 *       rootId={0}
 *     />
 *   );
 * };
 */

import { VFC, useState, useCallback, Ref, forwardRef } from 'react';
import { Tree, TreeProps, NodeModel } from '@minoru/react-dnd-treeview';
import { List, ListProps, ListItem, ListItemProps } from '@chakra-ui/react';

import { TreeNode } from 'components/contentsdb/atoms/TreeNode';

const CustomNodeContainer: VFC<ListProps> = forwardRef(
  ({ children, ...props }, ref: Ref<HTMLUListElement>) => (
    <List ref={ref} {...props}>
      {children}
    </List>
  ),
);
const CustomNodeItem: VFC<ListItemProps> = forwardRef(
  ({ children, ...props }, ref: Ref<HTMLLIElement>) => (
    <ListItem ref={ref} {...props}>
      {children}
    </ListItem>
  ),
);

// rootId: そのデータが直下のデータであることを示すid
// tree: 階層データ
type TreeViewProps<T> = Pick<TreeProps<T>, 'rootId' | 'tree'>;

// memo化するとジェネリクスを付けて
// 呼び出せなくなるため注意
//
// 以下のように定義できない
// ↓↓↓ T: CustomData の場合 ↓↓↓
// <TreeView <CustomData> ... />
export const TreeView = <T,>({
  tree,
  rootId,
}: TreeViewProps<T>): JSX.Element => {
  const [selectedNode, setSelectedNode] = useState<NodeModel<T>>();
  // Drag & Drop は禁止(誤操作防止につき)するため以下実装なし
  const handleDrop = useCallback(() => undefined, []);
  const handleDragPreview = useCallback(() => <></>, []);

  const onSelect = useCallback(
    (node: NodeModel<T>) => setSelectedNode(node),
    [setSelectedNode],
  );

  return (
    <Tree
      tree={tree}
      rootId={rootId}
      listComponent={CustomNodeContainer}
      listItemComponent={CustomNodeItem}
      render={(node: NodeModel<T>, { depth, isOpen, onToggle }) => (
        <TreeNode
          node={node}
          isOpen={isOpen}
          onSelect={onSelect}
          onToggle={onToggle}
          isSelected={selectedNode ? node.id === selectedNode?.id : false}
          depth={depth}
        />
      )}
      // 標準だと tree のリスト順は
      // グループ → テーブル の順になる
      // ソートを行わないことで直下の
      // テーブル(グループ未所属)を末尾に配置しています
      sort={false}
      onDrop={handleDrop}
      dragPreviewRender={handleDragPreview}
    />
  );
};
