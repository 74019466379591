import { VFC, memo } from 'react';
import { Box, Tooltip } from '@chakra-ui/react';
import { WarningTwoIcon, RepeatIcon } from '@chakra-ui/icons';

type ErrorContentsType = {
  name: string;
};

export const ErrorContents: VFC<ErrorContentsType> = memo(
  ({ name }: ErrorContentsType) => (
    <Box display="flex" alignItems="center" pr={4}>
      <Box ml={1} display="flex" alignItems="center">
        <WarningTwoIcon color="yellow.600" />
        <Box ml={2}>{name}取得エラー</Box>
      </Box>
      <Tooltip label="リロード" fontSize="xs" bg="gray.600">
        <RepeatIcon
          cursor="pointer"
          marginLeft={1}
          onClick={() => window.location.reload()}
        />
      </Tooltip>
    </Box>
  ),
);
