import { Options } from 'ky';
import { request } from 'api/request';
import produce from 'immer';

export const logger = async ({
  loglevel = 'error',
  data = undefined,
  message = '',
  options,
}: {
  loglevel?: 'error' | 'warning' | 'info';
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  data?: any;
  message?: string;
  options?: Options;
}): Promise<void> => {
  const sendData = {
    // eslint-disable-next-line @typescript-eslint/no-unsafe-assignment
    extra: data,
    message,
  };

  const mergedOptions = options
    ? produce(options, (draft) => {
        draft.json = sendData;
      })
    : { json: sendData };

  const path = `applog/${loglevel.toLowerCase()}/`;

  await request({
    path,
    method: 'post',
    options: mergedOptions,
  });
};

export default logger;
