import { zodResolver } from '@hookform/resolvers/zod';
import { ServiceFormComponent } from 'admin/components/service/serviceForm/ServiceFormComponent';
import { useUpdateService } from 'admin/hooks/service/useUpdateService';
import { serviceFormSchema } from 'admin/schema/service';
import { ServiceType } from 'admin/types/service';
import { ServiceFormType } from 'admin/types/service/form';
import { IdPoolConsumerList } from 'admin/types/userPool';
import { AlertBar } from 'components/common/atoms';
import { FC, memo, useEffect, useState } from 'react';
import { FormProvider, SubmitHandler, useForm } from 'react-hook-form';

type Props = {
  service: ServiceType;
  serviceUserPoolList: IdPoolConsumerList;
  onClose: () => void;
};

export const ServiceForm: FC<Props> = memo(
  ({ service, serviceUserPoolList, onClose }: Props) => {
    const [globalErrors, setGlobalErrors] = useState<string[]>([]);
    const formId = `serviceForm_${service.id}`;
    const methods = useForm<ServiceFormType>({
      defaultValues: {
        id: '',
        userPoolId: '',
        word: '',
      },
      mode: 'onBlur',
      resolver: zodResolver(serviceFormSchema),
    });
    const { setValue, setError } = methods;
    const { mutate, isLoading } = useUpdateService(setError, setGlobalErrors);

    useEffect(() => {
      setValue('id', service.id);
      setValue('userPoolId', service.userPoolId || '');
    }, [setValue, service]);

    const onSubmit: SubmitHandler<ServiceFormType> = async (
      data: ServiceFormType,
    ) => {
      await mutate(data);
      onClose();
    };

    return (
      <FormProvider {...methods}>
        {globalErrors.map((err, idx) => (
          <AlertBar
            key={`global-err-idx${String(idx)}`}
            message={err}
            status="error"
            mb={4}
          />
        ))}
        <ServiceFormComponent
          service={service}
          serviceUserPoolList={serviceUserPoolList}
          onClose={onClose}
          onSubmit={onSubmit}
          isLoading={isLoading}
          formId={formId}
        />
      </FormProvider>
    );
  },
);
