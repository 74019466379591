import { Box, Flex } from '@chakra-ui/react';
import styled from '@emotion/styled';
import { FC, memo } from 'react';

const StyledContainer = styled(Box)({
  width: 'calc(100vw - 420px)',
  height: 'calc(100vh - 150px) ',
  overflowX: 'auto',
});

const StyledTitleBox = styled(Box)({
  fontSize: 14,
  fontWeight: 'bold',
  color: '#444444',
  width: '100%',
  height: 70,
  paddingLeft: 25,
  paddingTop: 20,
});

const StyledRightBox = styled(Box)({
  justifyContent: 'end',
  paddingRight: 25,
  paddingTop: 20,
});

type Props = {
  title?: React.ReactNode;
  label?: React.ReactNode;
  children: React.ReactNode;
};

/**
 * @see コンテンツ領域のコンテナコンポーネント
 * @param title 左上に配置されるElement（ページタイトル）
 * @param label 右上に配置されるElement（主にボタン）
 * @param children 子要素
 */

export const Container: FC<Props> = memo(({ title, label, children }) => (
  <StyledContainer className="settingMailMagazineContainer">
    <Flex>
      <StyledTitleBox>{title}</StyledTitleBox>
      <StyledRightBox>{label}</StyledRightBox>
    </Flex>
    <Box>{children}</Box>
  </StyledContainer>
));
