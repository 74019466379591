import { Stack } from '@chakra-ui/react';
import ErrorBoundary from 'api/ErrorBoundary';
import {
  ErrorContents,
  ErrorStatusContents, LoadingSkeleton
} from 'components/common/atoms';
import { EnqueteCreateForm } from 'components/enquete/template/EnqueteCreateForm';
import { EnqueteEditForm } from 'components/enquete/template/EnqueteEditForm';
import { useErrorBoundaryReset } from 'hooks/useErrorBoundaryReset';
import { useSetPageTitle } from 'hooks/useSetPageTitle';
import { memo, Suspense, VFC } from 'react';
import { Helmet } from 'react-helmet-async';
import { useSearchParams } from 'react-router-dom';

export const EnqueteForm: VFC = memo(() => {
  useSetPageTitle('フォーム作成');
  const { ebKey, onError } = useErrorBoundaryReset();
  const [searchParams] = useSearchParams();
  const formId = searchParams.get('formId') || '';
  const renderErrorComponent = {
    404: <ErrorStatusContents code={404} />,
    450: <ErrorStatusContents code={450} />,
  };

  return (
    <ErrorBoundary
      errorComponent={<ErrorContents name="フォーム" />}
      statusComponent={renderErrorComponent}
      onError={onError}
      key={ebKey.current}
    >
      <Suspense fallback={<LoadingSkeleton />}>
        <Stack spacing={0} w="100%" maxW="1000px" h="full">
          <Helmet>
            <title>フォーム作成</title>
          </Helmet>
          {formId === '' && <EnqueteCreateForm />}
          {formId !== '' && <EnqueteEditForm formId={formId} key={formId} />}
        </Stack>
      </Suspense>
    </ErrorBoundary>
  );
});
