import { Box, Button, Flex, Text } from '@chakra-ui/react';
import { AttributeGroupTableBodyProps } from 'admin/components/service/attribute/attributeGroupTableBody/index';
import { DisplayTypeIcon } from 'admin/components/ui/icon/displayTypeIcon';
import { EditIcon } from 'admin/components/ui/icon/editIcon';
import { StatusLabel as Status } from 'admin/components/ui/status/StatusLabel';
import { Td, Tr } from 'admin/components/ui/table';
import { Colors } from 'admin/define/colors';
import { DISPLAY_TYPE_LIST } from 'admin/define/field';
import { FC, memo, useMemo } from 'react';

type Props = AttributeGroupTableBodyProps;

export const AttributeGroupTableBodyComponent: FC<Props> = memo(
  ({
    attributeGroup,
    onOpen,
    onOpenFormAttrDialog,
    onOpenChildAttrDialog,
    onOpenUserAttrDialog,
    onOpenUserAttrRequiredDialog,
    hasUniikey,
  }: Props) => {
    const displayType = useMemo(
      () =>
        DISPLAY_TYPE_LIST.find(
          (item) => item.value === attributeGroup.displayType,
        ),
      [attributeGroup],
    );

    return (
      <Tr>
        <Td>
          <Text
            fontSize="14px"
            fontWeight="700"
            color={Colors.BLUE_20}
            _hover={{
              cursor: 'pointer',
              textDecoration: 'underline',
            }}
            onClick={() =>
              onOpen(
                attributeGroup.id,
                attributeGroup.userPoolChildAttribute?.id || '',
              )
            }
          >
            {attributeGroup.id}
          </Text>
        </Td>
        <Td fontSize="14px">{attributeGroup.displayNameJa}</Td>
        <Td fontSize="14px">{attributeGroup.displayNameForeign || '-'}</Td>
        <Td fontSize="14px" padding={0}>
          {displayType && (
            <Flex alignItems="center" ml={4}>
              <DisplayTypeIcon iconType={displayType.value} />
              <Box ml={1}>{displayType.name}</Box>
            </Flex>
          )}
        </Td>
        <Td hasBorder={!hasUniikey} fontSize="14px">
          {attributeGroup.attributeGroupBodyList.map((item, index) => (
            <Box key={`${item.id}${String(index)}`}>
              属性{index + 1}：
              {`${item.userAttributeSetting.displayNameJa} (${item.userAttributeSetting.id})`}
            </Box>
          ))}
        </Td>
        {hasUniikey && (
          <Td hasBorder fontSize="14px">
            <Status
              status={attributeGroup.isDisplayAccountSettings ?? false}
              onLabel="ON"
              offLabel="OFF"
            />
          </Td>
        )}
        <Td fontSize="14px">
          <Flex gridGap={2}>
            <Status
              status={!!attributeGroup.userPoolChildAttribute?.id}
              onLabel="ON"
              offLabel="OFF"
            />
            <Button
              variant="outline"
              colorScheme="primary"
              bgColor="WHITE"
              w="32px"
              h="32px"
              p="0"
              disabled={!!attributeGroup.userPoolChildAttribute?.id}
              onClick={() =>
                onOpenChildAttrDialog(
                  attributeGroup.id,
                  attributeGroup.userPoolChildAttribute?.id || '',
                )
              }
            >
              <EditIcon />
            </Button>
          </Flex>
        </Td>
        <Td hasBorder fontSize="14px">
          <Flex gridGap={2}>
            <Status
              status={!!attributeGroup.userPoolChildAttribute?.isFormAttr}
              onLabel="ON"
              offLabel="OFF"
            />
            <Button
              variant="outline"
              colorScheme="primary"
              bgColor="WHITE"
              w="32px"
              h="32px"
              p="0"
              disabled={!!attributeGroup.userPoolChildAttribute?.isFormAttr}
              onClick={() =>
                onOpenFormAttrDialog(
                  attributeGroup.id,
                  attributeGroup.userPoolChildAttribute?.id || '',
                )
              }
            >
              <EditIcon />
            </Button>
          </Flex>
        </Td>
        {hasUniikey && (
          <>
            <Td fontSize="14px">
              <Flex gridGap={2}>
                <Status
                  status={Boolean(
                    attributeGroup.userPoolChildAttribute?.isUserAttr,
                  )}
                  onLabel="ON"
                  offLabel="OFF"
                />
                <Button
                  variant="outline"
                  colorScheme="primary"
                  bgColor="WHITE"
                  w="32px"
                  h="32px"
                  p="0"
                  disabled={!attributeGroup.userPoolChildAttribute?.id}
                  onClick={() =>
                    onOpenUserAttrDialog(
                      attributeGroup.id,
                      attributeGroup.userPoolChildAttribute?.id || '',
                    )
                  }
                >
                  <EditIcon />
                </Button>
              </Flex>
            </Td>
            <Td hasBorder fontSize="14px">
              <Flex gridGap={2}>
                <Status
                  status={Boolean(
                    attributeGroup.userPoolChildAttribute?.isUserAttrRequired,
                  )}
                  onLabel="ON"
                  offLabel="OFF"
                />
                <Button
                  variant="outline"
                  colorScheme="primary"
                  bgColor="WHITE"
                  w="32px"
                  h="32px"
                  p="0"
                  disabled={!attributeGroup.userPoolChildAttribute?.id}
                  onClick={() =>
                    onOpenUserAttrRequiredDialog(
                      attributeGroup.id,
                      attributeGroup.userPoolChildAttribute?.id || '',
                    )
                  }
                >
                  <EditIcon />
                </Button>
              </Flex>
            </Td>
          </>
        )}
      </Tr>
    );
  },
);
