import { Table, Tbody, Td, Text, Tr, VStack } from '@chakra-ui/react';
import { CondElement } from 'components/enquete/atoms/CondElement';
import { QuestionTitleElement } from 'components/enquete/atoms/QuestionTitleElement';
import { RichTextElement } from 'components/enquete/atoms/RichTextElement';
import { Toggle } from 'components/enquete/atoms/Toggle';
import { Dispatch, FC, SetStateAction, useState } from 'react';
import { FieldValues, Path, useFormContext } from 'react-hook-form';

type EnqueteFormType<T> = {
  baseName: Path<T>;
  index: number;
  setIsEdited: Dispatch<SetStateAction<boolean>>;
  text: string;
};

export const DateElement = <T extends FieldValues>({ baseName, index, setIsEdited, text }: EnqueteFormType<T>): ReturnType<FC> => {
  const [toggle, setToggle] = useState(false);

  const { getValues } = useFormContext<T>();

  return (
    <>
      <VStack w="100%" display={toggle ? 'none' : 'block'}>
        <Table>
          <Tbody>
            <Tr>
              <QuestionTitleElement<T> baseName={baseName} width={140} colspan={2} index={index} />
            </Tr>
            <Tr>
              <Td w={140}>補足説明など</Td>
              <Td colSpan={2}>
                <RichTextElement<T>
                  baseName={baseName}
                  placeholder=""
                  index={index}
                  position="descriptionTop"
                  value={
                    getValues(`${baseName}.${index}.descriptionTop` as Path<T>) || ''
                  }
                  setIsEdited={setIsEdited}
                />
              </Td>
            </Tr>
            <Tr>
              <Td colSpan={2}>
                <Text w="100%" background="whitesmoke" p={5} textAlign="left">
                  フォーム表示時に{text}の選択欄を自動で挿入します
                </Text>
              </Td>
            </Tr>
            <Tr>
              <Td w={140}>補足説明など</Td>
              <Td colSpan={4}>
                <RichTextElement<T>
                  baseName={baseName}
                  placeholder=""
                  index={index}
                  position="descriptionBottom"
                  value={
                    getValues(`${baseName}.${index}.descriptionBottom` as Path<T>) ||
                    ''
                  }
                  setIsEdited={setIsEdited}
                />
              </Td>
            </Tr>
          </Tbody>
        </Table>
        <CondElement<T> baseName={baseName} index={index} />
      </VStack>
      <Toggle toggle={toggle} setToggle={setToggle} />
    </>
  );
};
