import { getCodeGroups } from 'admin/api/userPool/master/getCodeGroups';
import { CustomError } from 'admin/error/CustomError';
import { useCurrentPerPage } from 'admin/hooks/pagination/useCurrentPerPage';
import { convertToPage } from 'admin/schema/page';
import { UserPool } from 'admin/types/userPool';
import { CodeGroupsType } from 'admin/types/userPool/master/index';
import { DataWithPage, Page } from 'api/common/types';
import { logger } from 'api/logger';
import { Options } from 'ky';
import { useQuery } from 'react-query';
import { queryKeys } from './queryKeys';

export const useCodeGroups = (
  id: UserPool['id'],
  page: Page['currentPage'],
  options?: Options,
) => {
  const perPage = useCurrentPerPage();
  const { data, refetch, isLoading } = useQuery({
    queryKey: queryKeys.getMasterSettings(id, page, perPage),
    queryFn: () =>
      getCodeGroups({
        id,
        page,
        perPage,
        options,
      }),
    onError: async (error) => {
      if (error instanceof CustomError) {
        await logger({
          loglevel: error.logLevel,
          data: JSON.stringify(error.cause),
          message: error.message,
        });
      }
    },
  });

  return {
    data: data?.results,
    page: convertToPage<DataWithPage<CodeGroupsType>>(data),
    refetch,
    isLoading,
  };
};
