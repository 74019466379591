/**
 *フォーム作成時のプレビュー時のモーダル
 *@packageDocumentation
 *
 */

import { FC, memo, useCallback, useState } from 'react';
import { SubmitHandler, useForm } from 'react-hook-form';
import {
  Button,
  Modal,
  ModalOverlay,
  ModalContent,
  ModalHeader,
  ModalFooter,
  ModalBody,
  ModalCloseButton,
  Select,
  Stack,
  Radio,
  RadioGroup,
  Box,
  FormErrorMessage,
  FormControl,
  Flex,
  Text,
  Tooltip,
  Heading,
  Textarea,
  FormLabel,
} from '@chakra-ui/react';
import { MdContentCopy } from 'react-icons/md';
import { ExternalLinkIcon } from '@chakra-ui/icons';
import { useUserTenantId } from 'hooks/user/useUserTenantId';
import { useWebUrls } from 'hooks/tenant/useWebUrls';
import { useCopy } from 'hooks/useCopy';

type Props = {
  formId: string;
  title: string;
  isOpen: boolean;
  onClose: () => void;
};

const radioNamePattern = ['input', 'select'] as const;
type RadioSelectUrlType = typeof radioNamePattern[number];

type FormType = Record<string, 'selectWebUrl' | 'inputWebUrl'>;

const sentence = {
  MODAL_HEADER: '公開用URLの生成',
  FORM_ID: 'フォームID',
  FORM_TITLE: 'フォーム名',
  HEADING: {
    INPUT_URL: 'フォームを設置したいWebページのURLを指定してください。',
    GENERATED_URL:
      '生成されたURLをコピー、もしくは直接開いて確認してください。',
  },
  SELECT: {
    RADIO_CAPTION: '登録済みのWebページから選択',
    PLACEHOLDER: '選択してください',
  },
  INPUT: {
    RADIO_CAPTION: '任意のWebページURLを直接入力',
    PLACEHOLDER: '',
  },
  TEXT_CAPTION: 'フォーム公開用URL',
  GENERATE_BTN_CAPTION: '生成する',
  COPY_BTN_CAPTION: 'URLをコピー',
  OPEN_BTN_CAPTION: 'URLを開く',
} as const;

export const GeneratePublicUrlModal: FC<Props> = memo(
  ({ formId, title, isOpen, onClose }: Props) => {
    const tenantId = useUserTenantId();

    const websiteList = useWebUrls({ tenantId });

    const { copy } = useCopy();

    const [radio, setRadio] = useState<RadioSelectUrlType>('select');
    const [generatedUrl, setGeneratedUrl] = useState('');
    const [shouldShowTooltip, setShouldShowTooltip] = useState(true);
    const [refElem, setRefElem] = useState<HTMLElement | null>(null);

    const resetUrl = useCallback(() => {
      setGeneratedUrl('');
    }, [setGeneratedUrl]);

    const handleRadioChange = (nextValue: string) => {
      setRadio(nextValue as RadioSelectUrlType);
      resetUrl();
    };

    const {
      resetField,
      handleSubmit,
      register,
      clearErrors,
      formState: { errors, isSubmitting },
    } = useForm<FormType>({ mode: 'onSubmit' });

    const isSelectWebUrlInvalid = Boolean(errors.selectWebUrl);
    const isInputWebUrlInvalid = Boolean(errors.inputWebUrl);
    const isDisabledGeneratedUrl = generatedUrl === '';

    const radioElement = ({ value }: { value: RadioSelectUrlType }) => {
      const reverseDict = {
        select: 'input',
        input: 'select',
      };
      const webUrlKey = `${value}WebUrl`;
      const handleClick = () => {
        clearErrors(`${reverseDict[value]}WebUrl`);
      };
      // 強制的に大文字に変換した型定義を使用
      const forceConvertUpperCase =
        value.toUpperCase() as Uppercase<RadioSelectUrlType>;

      return {
        radio: (
          <Radio size="md" value={value} onClick={handleClick}>
            <Text onClick={handleClick} fontWeight="bold">
              {sentence[forceConvertUpperCase].RADIO_CAPTION}
            </Text>
          </Radio>
        ),
        errMsg: (
          <FormErrorMessage fontSize={12}>
            {errors[webUrlKey] && errors[webUrlKey].message}
          </FormErrorMessage>
        ),
      };
    };

    // 生成するボタンを押した時
    const onSubmit: SubmitHandler<FormType> = (v) => {
      const dict = {
        select: v.selectWebUrl,
        input: v.inputWebUrl,
      };
      const baseUrl = dict[radio];
      const param = `uecfcode=${formId}`;
      const conjunction = baseUrl.includes('?') ? '&' : '?';

      const pos = baseUrl.indexOf('#');
      if (pos !== -1) {
        const redirectUrl = `${baseUrl.slice(
          0,
          pos,
        )}${conjunction}${param}${baseUrl.slice(pos)}`;
        setGeneratedUrl(redirectUrl);
      } else {
        const redirectUrl = `${baseUrl}${conjunction}${param}`;
        setGeneratedUrl(redirectUrl);
      }
    };

    const onOpenUrl = useCallback(() => {
      if (generatedUrl === '') return;

      window.open(generatedUrl, '_blank');
    }, [generatedUrl]);

    const onCloseModal = useCallback(() => {
      onClose();

      setRadio('select');
      resetField('selectWebUrl');
      resetField('inputWebUrl');
      resetUrl();
      // ツールチップの reset
      setShouldShowTooltip(true);
      setRefElem(null);
    }, [onClose, resetField, resetUrl]);

    if (refElem !== null) {
      if (shouldShowTooltip && refElem.offsetHeight >= refElem.scrollHeight) {
        setShouldShowTooltip(false);
      }
    }

    return (
      <Modal
        isOpen={isOpen}
        onClose={onCloseModal}
        size="xl"
        scrollBehavior="inside"
      >
        <ModalOverlay />
        <ModalContent as="form" onSubmit={handleSubmit(onSubmit)}>
          <ModalHeader textAlign="center">{sentence.MODAL_HEADER}</ModalHeader>
          <ModalBody>
            <Box
              sx={{
                '.list-h2': {
                  counterReset: 'h2',
                },
                '.list-h2 h2': {
                  fontSize: 'md',
                  color: 'blue.400',
                  py: '4',
                },
                '.list-h2 h2::before': {
                  counterIncrement: 'h2',
                  content: 'counter(h2) "．"',
                },
              }}
              className="list-h2"
            >
              <Box p={2} mb={8} bg="gray.100">
                <Flex>
                  <Text fontWeight="bold">{sentence.FORM_ID} :&nbsp;</Text>
                  <Text flex="1">{formId}</Text>
                </Flex>
                <Flex>
                  <Text fontWeight="bold">{sentence.FORM_TITLE} :&nbsp;</Text>
                  {shouldShowTooltip && (
                    <Tooltip
                      label={title}
                      hasArrow
                      bg="gray.800"
                      borderRadius={4}
                      maxW="420px" // Safari で最初に表示する時にチラつくのを防ぐ為
                      width="420px" // width と maxW を両方設定しておく
                      // arrow の位置は左側
                      placement="bottom-start"
                      modifiers={[
                        {
                          name: 'arrow',
                          enabled: true,
                          phase: 'main',
                          fn: ({ state }) => {
                            if (refElem === null) {
                              setRefElem(
                                state.elements.reference as HTMLElement,
                              );
                            }
                          },
                        },
                      ]}
                    >
                      <Text
                        flex="1"
                        sx={{
                          display: '-webkit-box',
                          overflow: 'hidden',
                          WebkitBoxOrient: 'vertical',
                          WebkitLineClamp: '3',
                        }}
                      >
                        {title}
                      </Text>
                    </Tooltip>
                  )}
                  {!shouldShowTooltip && (
                    <Text
                      flex="1"
                      sx={{
                        display: '-webkit-box',
                        overflow: 'hidden',
                        WebkitBoxOrient: 'vertical',
                        WebkitLineClamp: '3',
                      }}
                    >
                      {title}
                    </Text>
                  )}
                </Flex>
              </Box>
              <Box className="list-h2">
                <Heading as="h2">{sentence.HEADING.INPUT_URL}</Heading>
                <RadioGroup
                  name="selectWebSiteUrl"
                  value={radio}
                  onChange={handleRadioChange}
                >
                  <Stack spacing={5} direction="column">
                    <Box>
                      {radioElement({ value: 'select' }).radio}
                      <FormControl isInvalid={isSelectWebUrlInvalid}>
                        <Select
                          isInvalid={isSelectWebUrlInvalid}
                          placeholder={sentence.SELECT.PLACEHOLDER}
                          {...register('selectWebUrl', {
                            required: {
                              value: radio === 'select',
                              message: '選択してください',
                            },
                            disabled: radio !== 'select',
                          })}
                        >
                          {websiteList?.map((url) => (
                            <option value={url}>{url}</option>
                          ))}
                        </Select>
                        {radioElement({ value: 'select' }).errMsg}
                      </FormControl>
                    </Box>
                    {radioElement({ value: 'input' }).radio}
                    <FormControl isInvalid={isInputWebUrlInvalid}>
                      <Textarea
                        isInvalid={isInputWebUrlInvalid}
                        type="url"
                        rows={1}
                        placeholder={sentence.INPUT.PLACEHOLDER}
                        {...register('inputWebUrl', {
                          required: {
                            value: radio === 'input',
                            message: 'URLを入力して下さい',
                          },
                          pattern: {
                            value:
                              /^https?:\/\/[\w/:%#\\$&\\?\\(\\)~\\.=\\+\\-]+$/,
                            message: 'URLが正しくありません',
                          },
                          validate: (v) => {
                            const exParam = /[\\?&]uecfcode($|=)/;
                            if (exParam.exec(v)) {
                              return 'URLに uecfcode パラメータを含めることはできません';
                            }

                            return true;
                          },
                          disabled: radio !== 'input',
                        })}
                      />
                      {radioElement({ value: 'input' }).errMsg}
                    </FormControl>
                    <Box />
                  </Stack>
                  <Button
                    fontSize="md"
                    w="full"
                    py={6}
                    variant="primary"
                    type="submit"
                  >
                    {sentence.GENERATE_BTN_CAPTION}
                  </Button>
                </RadioGroup>
                <FormControl isDisabled={isDisabledGeneratedUrl}>
                  <FormLabel>
                    <Heading as="h2">{sentence.HEADING.GENERATED_URL}</Heading>
                  </FormLabel>
                  <FormLabel>
                    <Text fontWeight="bold">{sentence.TEXT_CAPTION}</Text>
                  </FormLabel>
                  <Textarea
                    readOnly
                    variant={generatedUrl === '' ? 'outline' : 'filled'}
                    resize="none"
                    value={generatedUrl}
                  />
                </FormControl>
              </Box>
            </Box>
          </ModalBody>
          <ModalFooter>
            <Button
              onClick={() => copy(generatedUrl)}
              variant="outline"
              disabled={generatedUrl === ''}
            >
              {sentence.COPY_BTN_CAPTION}
              <Box ml={1} p={0}>
                <MdContentCopy />
              </Box>
            </Button>
            <Button
              isLoading={isSubmitting}
              variant="outline"
              ml={3}
              onClick={onOpenUrl}
              disabled={generatedUrl === ''}
            >
              {sentence.OPEN_BTN_CAPTION}
              <ExternalLinkIcon ml={1} />
            </Button>
          </ModalFooter>
          <ModalCloseButton />
        </ModalContent>
      </Modal>
    );
  },
);
