import { VFC } from 'react';
import { Spinner, SpinnerProps, VStack } from '@chakra-ui/react';

type LoadingSpinnerProps = {
  testId?: string;
} & SpinnerProps;

export const LoadingSpinner: VFC<LoadingSpinnerProps> = ({
  size = 'xl',
  emptyColor = 'gray.200',
  color = 'blue.400',
  speed = '0.65s',
  m = '8',
  testId = 'loading-spinner',
  ...rest
}) => (
  <VStack h="full" justifyContent="center" data-testid={testId}>
    <Spinner
      m={m}
      size={size}
      color={color}
      emptyColor={emptyColor}
      speed={speed}
      {...rest}
    />
  </VStack>
);
