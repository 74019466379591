import { VFC, useState } from 'react';
import { useFormContext } from 'react-hook-form';
import { useQueryClient } from 'react-query';
import { Stack, Box } from '@chakra-ui/react';
import { useUserTenantId } from 'hooks/user/useUserTenantId';
import { useTableRegist } from 'hooks/contentsdb/useTableRegist';
import { AlertBar } from 'components/common/atoms';
import { InputTableName } from 'components/contentsdb/atoms/InputTableName';
import { InputTableTitle } from 'components/contentsdb/atoms/InputTableTitle';
import { GroupSelect } from 'components/contentsdb/atoms/GroupSelect';
import { TextTableMemo } from 'components/contentsdb/atoms/TextTableMemo';
import { SegmentConnectInfo } from 'components/contentsdb/atoms/SegmentConnectInfo';
import { SchemaCreateForm } from 'components/contentsdb/molecules/SchemaCreateForm';
import { TableFormChildProps } from 'components/contentsdb/molecules/TableForm';
import { ContentsDbTblFormType } from 'api/contents/types';

export const TableCreateForm: VFC<TableFormChildProps> = ({
  formId,
  closeHandler,
}) => {
  const [globalError, setGlobalError] = useState<string[]>([]);
  const { handleSubmit } = useFormContext<ContentsDbTblFormType>();
  const tenantId = useUserTenantId();
  const queryClient = useQueryClient();
  const regist = useTableRegist({
    closeHandler,
    tenantId,
    setGlobalError,
  });

  const initGroupId = '';

  return (
    <form
      id={formId}
      onSubmit={handleSubmit((data) => regist(data, queryClient))}
    >
      <Stack spacing="24px">
        {globalError ? (
          <Box>
            <AlertBar status="error" message={globalError} />
          </Box>
        ) : null}
        <InputTableName />
        <InputTableTitle />
        <GroupSelect value={initGroupId} />
        <TextTableMemo />
        <SegmentConnectInfo />
        <SchemaCreateForm />
      </Stack>
    </form>
  );
};
