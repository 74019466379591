import { VFC, memo } from 'react';
import { useFormContext } from 'react-hook-form';
import { Box, Textarea } from '@chakra-ui/react';
import { MailEditorForm } from 'api/mail/types';

type TextEditorProps = {
  isEditable: boolean;
};

export const TextEditor: VFC<TextEditorProps> = memo(
  ({ isEditable }: TextEditorProps) => {
    const { register } = useFormContext<MailEditorForm>();

    return (
      <Box>
        <Textarea
          id="mailTextEditor"
          height="calc(100vh - 250px)"
          borderRadius="0px 0px 0.375rem 0.375rem"
          {...register('bodyText')}
          isDisabled={isEditable}
        />
      </Box>
    );
  },
);
