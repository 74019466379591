import {
  Box,
  Flex,
  ListIcon,
  ListItem,
  ListItemProps,
  Tooltip,
  useStyleConfig,
} from '@chakra-ui/react';
import { useCustomNavigate } from 'hooks/useCustomNavigate';
import { memo, useCallback, VFC } from 'react';
import { IconType } from 'react-icons';

type CustomMenuListItemProps = {
  icon: IconType;
  name: string;
  tLabel?: string;
  to?: string;
  // 選択中フラグ (true: 選択中, false: 非選択)
  isActive?: boolean;
  href?: string;
  onClick?: () => void;
  fontSize?: string;
  isOpen?: boolean;
} & ListItemProps;

export const CustomMenuListItem: VFC<CustomMenuListItemProps> = memo(
  ({
    icon,
    name,
    tLabel = '',
    to = '',
    isActive = false,
    href = '',
    onClick = () => undefined,
    fontSize = '',
    isOpen,
    ...rest
  }) => {
    const { navigate } = useCustomNavigate({});
    const props = { isActive, isOpen, ...rest };
    const styles = useStyleConfig('CustomMenuListItem', props);
    const textStyles = useStyleConfig('CustomMenuListItemText', props);

    const next = useCallback(() => {
      onClick();
      if (to !== '') navigate(to);
      if (href !== '') window.open(href, '_blank');
    }, [to, href, navigate, onClick]);

    return (
      <Tooltip
        label={tLabel !== '' ? tLabel : name}
        bgColor="white"
        color="black"
      >
        <ListItem sx={styles} {...rest} onClick={() => next()} cursor="pointer">
          <Flex alignItems="center" h="full">
            <ListIcon as={icon} fontSize={fontSize || '2xl'} />
            <Box sx={textStyles}>{name}</Box>
          </Flex>
        </ListItem>
      </Tooltip>
    );
  },
);
