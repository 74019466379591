import { Icon } from '@chakra-ui/react';
import { Colors } from 'admin/define/colors';
import { FC, memo } from 'react';
import { MdArrowBack } from "react-icons/md";
import { NavLink } from 'react-router-dom';

type Props = {
  path: string;
}

export const BackToFormGroupListButton: FC<Props> = memo(({path}: Props) => (
  <NavLink to={path} style={{ color: Colors.BLUE_20, fontWeight: 700, textDecoration: 'underline' }} >
    <Icon as={MdArrowBack} mr="4px"/>
    一覧に戻る
  </NavLink>
));
