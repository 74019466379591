import { Box, Flex, Text } from '@chakra-ui/react';
import { Colors } from 'components/paywall/config';
import { CustomErrorBoundary } from 'components/paywall/molecules/CustomErrorBoundary';
import { TermsSettingArea } from 'components/paywall/pages/TermsSetting/TermsSettingArea';
import { TermsSettingDrawer } from 'components/paywall/pages/TermsSetting/TermsSettingDrawer';
import { Container } from 'components/paywall/templates/Container';
import { useTerm } from 'hooks/paywall/useTerm';
import { FC, memo } from 'react';

export const TermsSetting: FC = memo(() => {
  const termData = useTerm();

  return (
    <CustomErrorBoundary pageTitle="規約設定">
      <Container
        title={
          <Flex>
            <Text
              fontSize="14px"
              color={Colors.GRAY_03}
              fontWeight="700"
              fontFamily="Hiragino Sans"
            >
              規約設定
            </Text>
          </Flex>
        }
        px="45px"
      >
        <Flex mt="40px">
          <Box fontFamily="Hiragino Sans">
            <Text fontSize="18px" color={Colors.GRAY_03} fontWeight="700">
              規約設定
            </Text>
            <Text fontSize="14px" color={Colors.GRAY_00} fontWeight="400">
              この画面で設定した規約・リンクは、ペイウォール購入時のモーダル下部に表示されます。
            </Text>
          </Box>
          <Box flex="auto">
            <TermsSettingDrawer termData={termData} />
          </Box>
        </Flex>
        <TermsSettingArea />
      </Container>
    </CustomErrorBoundary>
  );
});
