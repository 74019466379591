import {
  AlertDialog,
  Box,
  Button,
  Flex,
  HStack,
  Text,
  Tooltip,
  useDisclosure,
} from '@chakra-ui/react';
import { withSuspenseAndErrorBoundary } from 'admin/components/Error/WithErrorBoundary';
import { IdPoolConsumerCreateDialog } from 'admin/components/idPoolConsumer/idPoolConsumerDialog';
import { IdPoolConsumerLists } from 'admin/components/idPoolConsumer/idPoolConsumerLists';
import { IdPoolConsumerUserPoolsCreatedCount } from 'admin/components/idPoolConsumer/idPoolConsumerUserPoolsCreatedCount';
import { Breadcrumb } from 'admin/components/ui/breadcrumb';
import { HelpTooltip } from 'admin/components/ui/helpTooltip';
import { Colors } from 'admin/define/colors';
import { useUserPoolsCreatedCount } from 'admin/hooks/idPoolConsumers/useUserPoolsCreatedCount';
import { useUserCorporationInfo } from 'admin/hooks/user/useUserCorporationInfo';
import { BreadcrumbType } from 'admin/types/ui/breadcrumb';
import { ErrorContents, LoadingSpinner } from 'components/common/atoms';
import { useSetPageTitle } from 'hooks/useSetPageTitle';
import { FC, memo, useMemo, useRef } from 'react';
import { MdOutlineAdd } from 'react-icons/md';

export const idPoolConsumerIndex: FC = memo(() => {
  useSetPageTitle('IDシステム設定管理');
  const { isOpen, onClose, onOpen } = useDisclosure();
  const { hasPermissionUserPool } = useUserCorporationInfo();
  const cancelRef = useRef(null);
  const userPoolsCreatedCount = useUserPoolsCreatedCount();

  const isCanCreateUserPool = useMemo<boolean>(
    () => userPoolsCreatedCount.count < userPoolsCreatedCount.max,
    [userPoolsCreatedCount],
  );

  const breadcrumbItems: BreadcrumbType[] = [
    { url: '/admin', title: '組織管理TOP' },
    {
      url: '',
      title: 'IDシステム設定管理',
    },
  ];

  return (
    <>
      <Breadcrumb breadcrumbItems={breadcrumbItems} />

      <Box p="40px 0px">
        <Flex justifyContent="flex-end">
          <Box display="block" mr="auto">
            <Text
              fontWeight="700"
              fontSize="18px"
              lineHeight="170%"
              fontFamily="Hiragino Sans"
            >
              IDシステム設定管理
            </Text>
            <Text
              fontWeight="400"
              fontSize="14px"
              lineHeight="170%"
              fontFamily="Hiragino Sans"
              color={Colors.GRAY_00}
            >
              利用するIDシステムに対しての設定を行います。IDシステムとの接続には、ユーザープールが必要になります。
            </Text>
            <HStack>
              <Text
                fontWeight="400"
                fontSize="14px"
                lineHeight="170%"
                fontFamily="Hiragino Sans"
                color={Colors.GRAY_00}
              >
                ユーザープールとは
              </Text>
              <HelpTooltip
                p="8px"
                label="複数のエンドユーザーの、ユーザー情報をまとめて管理するための機能です。"
              />
            </HStack>
          </Box>
          {hasPermissionUserPool && (
            <Box>
              <Tooltip
                label="ユーザープールはこれ以上作成できません。"
                p="8px"
                fontSize="12px"
                orderRadius="4px"
                isDisabled={isCanCreateUserPool}
                hasArrow
                borderRadius="4px"
              >
                <Button
                  // Chakra-uiのButtonのisDisabledを使用するとtooltipが適用されなくなるため、
                  // isDisabledと同等のスタイルを付与するし、クリックイベントを無効にする
                  onClick={isCanCreateUserPool ? onOpen : () => undefined}
                  colorScheme="primary"
                  p="16px 32px"
                  style={
                    !isCanCreateUserPool
                      ? {
                          opacity: 0.4,
                          boxShadow: 'none',
                        }
                      : {}
                  }
                >
                  <MdOutlineAdd />
                  <Text>ユーザープール作成</Text>
                </Button>
              </Tooltip>
              {/*
                hasPermissionUserPoolはuserPoolの編集権限があるかどうかのフラグです。
                フラグの内容によって作成数の表示だし分けが必要な場合は現状のまま。
                上記だし分けが不要な場合はHTMLの構造を調整する必要がありそう
              */}
              <IdPoolConsumerUserPoolsCreatedCount />
            </Box>
          )}
        </Flex>
        <Box mt="16px">
          <IdPoolConsumerLists />
        </Box>
      </Box>

      {/* 新規作成のモーダル */}
      <AlertDialog
        size="xl"
        isOpen={isOpen}
        onClose={onClose}
        leastDestructiveRef={cancelRef}
        isCentered
        closeOnOverlayClick={false}
        closeOnEsc={false}
      >
        <IdPoolConsumerCreateDialog onClose={onClose} />
      </AlertDialog>
    </>
  );
});

export const IdPoolConsumerIndex = withSuspenseAndErrorBoundary(
  idPoolConsumerIndex,
  {
    ErrorComponent: <ErrorContents name="IDシステム設定管理" />,
    LoadingComponent: <LoadingSpinner />,
  },
);
