import { colorOptions } from './options';

const groupName = 'theme';

export default [
  // 画面全体の設定
  {
    name: `${groupName}.backgroundColor`,
    label: '画面全体の背景',
    tooltip: '',
    type: 'ColorPicker',
    defaultValue: '#F3F3F2',
    options: colorOptions,
    elementOptions: {},
  },
  {
    name: `${groupName}.contentBackgroundColor`,
    label: 'コンテンツエリアの背景',
    tooltip: '',
    type: 'ColorPicker',
    defaultValue: '#FFFFFF',
    options: colorOptions,
    elementOptions: {},
  },
  {
    name: `${groupName}.contentBorderColor`,
    label: 'コンテンツエリアの枠線',
    tooltip: '',
    type: 'ColorPicker',
    defaultValue: '#FFFFFF',
    options: colorOptions,
    elementOptions: {},
  },
  {
    name: `${groupName}.focusBaseColor`,
    label: 'フォーカスのベースカラー',
    tooltip: '',
    type: 'ColorPicker',
    defaultValue: '#0033CC',
    options: colorOptions,
    elementOptions: {},
  },
  {
    name: `${groupName}.hoverBaseColor`,
    label: 'ホバーのベースカラー',
    tooltip: '',
    type: 'ColorPicker',
    defaultValue: '#000000',
    options: colorOptions,
    elementOptions: {},
  },
  // テキスト設定
  {
    name: `${groupName}.titleTextColor`,
    label: 'タイトルテキスト',
    tooltip: '',
    type: 'ColorPicker',
    defaultValue: '#111827',
    options: colorOptions,
    elementOptions: {},
  },
  {
    name: `${groupName}.baseTextColor`,
    label: 'ベーステキスト',
    tooltip: '',
    type: 'ColorPicker',
    defaultValue: '#111827',
    options: colorOptions,
    elementOptions: {},
  },
  // インプットテキスト設定
  {
    name: `${groupName}.inputTextColor`,
    label: 'インプットテキスト',
    tooltip: '',
    type: 'ColorPicker',
    defaultValue: '#111827',
    options: colorOptions,
    elementOptions: {},
  },
  {
    name: `${groupName}.inputTextBorderColor`,
    label: 'インプットテキストの枠線',
    tooltip: '',
    type: 'ColorPicker',
    defaultValue: '#111827',
    options: colorOptions,
    elementOptions: {},
  },
  {
    name: `${groupName}.inputTextBackgroundColor`,
    label: 'インプットテキストの背景',
    tooltip: '',
    type: 'ColorPicker',
    defaultValue: '#FFFFFF',
    options: colorOptions,
    elementOptions: {},
  },
  // コンテンツエリア設定
  {
    name: `${groupName}.contentSeparatorColor`,
    label: 'コンテンツエリアの区切り線',
    tooltip: '',
    type: 'ColorPicker',
    defaultValue: '#111827',
    options: colorOptions,
    elementOptions: {},
  },
  // リンク設定
  {
    name: `${groupName}.linkTextColor`,
    label: 'リンクテキスト',
    tooltip: '',
    type: 'ColorPicker',
    defaultValue: '#1A0DAB',
    options: colorOptions,
    elementOptions: {},
  },
  // ボタン設定
  {
    name: `${groupName}.primaryButtonColor`,
    label: 'プライマリーボタン',
    tooltip: '',
    type: 'ColorPicker',
    defaultValue: '#0033CC',
    options: colorOptions,
    elementOptions: {},
  },
  {
    name: `${groupName}.primaryButtonTextColor`,
    label: 'プライマリーボタンのテキスト',
    tooltip: '',
    type: 'ColorPicker',
    defaultValue: '#FFFFFF',
    options: colorOptions,
    elementOptions: {},
  },
  {
    name: `${groupName}.secondaryButtonColor`,
    label: 'セカンダリーボタン',
    tooltip: '',
    type: 'ColorPicker',
    defaultValue: '#FFFFFF',
    options: colorOptions,
    elementOptions: {},
  },
  {
    name: `${groupName}.secondaryButtonTextColor`,
    label: 'セカンダリーボタンのテキスト',
    tooltip: '',
    type: 'ColorPicker',
    defaultValue: '#0033CC',
    options: colorOptions,
    elementOptions: {},
  },
  {
    name: `${groupName}.secondaryButtonBorderColor`,
    label: 'セカンダリーボタンの枠線',
    tooltip: '',
    type: 'ColorPicker',
    defaultValue: '#0033CC',
    options: colorOptions,
    elementOptions: {},
  },
  {
    name: `${groupName}.selectedColor`,
    label: 'チェックボックス選択肢 / ラジオボタン選択時',
    tooltip: '',
    type: 'ColorPicker',
    defaultValue: '#0033CC',
    options: colorOptions,
    elementOptions: {},
  },
  // その他
  {
    name: `${groupName}.requiredMarkColor`,
    label: '必須マーク',
    tooltip: '',
    type: 'ColorPicker',
    defaultValue: '#D32F2F',
    options: colorOptions,
    elementOptions: {},
  },
  {
    name: `${groupName}.successMessageColor`,
    label: '成功メッセージ',
    tooltip: '',
    type: 'ColorPicker',
    defaultValue: '#218756',
    options: colorOptions,
    elementOptions: {},
  },
  {
    name: `${groupName}.errorMessageColor`,
    label: 'エラーメッセージ',
    tooltip: '',
    type: 'ColorPicker',
    defaultValue: '#D32F2F',
    options: colorOptions,
    elementOptions: {},
  },
] as const;
