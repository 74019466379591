import { Box, Tab, TabList, TabPanel, TabPanels, Tabs } from '@chakra-ui/react';
import { MemberIdPoolPanel } from 'admin/components/member/memberIdPoolPanel';
import { Colors } from 'admin/define/colors';
import { MemberIdPoolConsumerType } from 'admin/types/member';
import { IdPoolConsumerList } from 'admin/types/userPool';
import { FC, memo, useCallback } from 'react';

type Props = {
  memberIdPoolConsumers: IdPoolConsumerList;
  selectedId: MemberIdPoolConsumerType['id'];
  onChangeTab: (idx: number) => void;
};

export const MemberIdPoolTabsComponent: FC<Props> = memo(
  ({ memberIdPoolConsumers, selectedId, onChangeTab }: Props) => {
    const onClickTab = useCallback(
      (idx: number) => {
        onChangeTab(idx);
      },
      [onChangeTab],
    );

    return (
      <Tabs onChange={(idx) => onClickTab(idx)} mt="12px">
        <Box
          overflowX="auto"
          css={{
            '&::-webkit-scrollbar': {
              display: 'none',
            },
          }}
        >
          <TabList borderBottomWidth="1px" borderColor={Colors.GRAY_30}>
            {memberIdPoolConsumers.map((idPoolConsumer) => (
              <Tab
                key={`member-idPool-tab_${idPoolConsumer.id}`}
                minW="260px"
                // 横に見切れてしまい、borderが途切れてしまうケースをフォロー
                // 下記`1px`だと見切れてしまうため`2px`としています
                borderBottom={`2px solid ${Colors.GRAY_30} !important`}
                _focus={{
                  'box-shadow': 'unset',
                }}
                color="#7E8C9A"
                sx={{
                  _selected: {
                    borderBottomWidth: '4px !important',
                    color: Colors.BLUE_20,
                    borderColor: `${Colors.BLUE_20} !important`,
                  },
                }}
              >
                {idPoolConsumer.name}
              </Tab>
            ))}
          </TabList>
        </Box>
        <TabPanels>
          {memberIdPoolConsumers.map((idPoolConsumer) => (
            <TabPanel
              px={0}
              key={`member-idPool-tab-panel_${idPoolConsumer.id}`}
            >
              <MemberIdPoolPanel
                idPoolConsumer={idPoolConsumer}
                selectedId={selectedId}
              />
            </TabPanel>
          ))}
        </TabPanels>
      </Tabs>
    );
  },
);
