import { getCodeSettings } from 'admin/api/userPool/code/getCodeSettings';
import { CustomError } from 'admin/error/CustomError';
import { useCurrentPerPage } from 'admin/hooks/pagination/useCurrentPerPage';
import { convertToPage } from 'admin/schema/page';
import { CodeSettingsType } from 'admin/types/userPool/code/index';
import { CodeGroupType } from 'admin/types/userPool/master';
import { DataWithPage } from 'api/common/types';
import { logger } from 'api/logger';
import { Options } from 'ky';
import { useQuery } from 'react-query';
import { queryKeys } from './queryKeys';

export const useCodeSettings = (value: {
  id: CodeGroupType['id'];
  page?: number;
  options?: Options;
}) => {
  const perPage = useCurrentPerPage();
  const { data, refetch, isLoading } = useQuery({
    queryKey: queryKeys.getCodeSettings(value.id, value.page, perPage),
    queryFn: () =>
      getCodeSettings({
        id: value.id,
        options: {
          searchParams: {
            page: value.page ?? 1,
            perPage,
          },
        },
      }),
    onError: async (error) => {
      if (error instanceof CustomError) {
        await logger({
          loglevel: error.logLevel,
          data: JSON.stringify(error.cause),
          message: error.message,
        });
      }
    },
  });

  return {
    data: data?.results,
    page: convertToPage<DataWithPage<CodeSettingsType>>(data),
    refetch,
    isLoading,
  };
};
