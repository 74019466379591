import {
  CardHeaderButton,
  CardHeaderButtons,
} from 'components/paywall/features/Card';
import { Td, Tr } from 'components/paywall/features/Table';
import { ItemDialog } from 'components/paywall/pages/ItemList/ItemDialog';
import { useWallList } from 'hooks/paywall/useWallList';
import { FC, memo } from 'react';

/* 単品ウォール設定の画面設定ボタンのステータスメッセージ */
const AlertStatusDesing: { [key: number]: string } = {
  0: '',
  1: 'ウォール画面を作成してください',
  2: 'いずれかのウォール画面のステータスを 有効 にしてください',
};

/* 単品ウォール設定の商品設定ボタンのステータスメッセージ */
const AlertStatusProduct: { [key: number]: string } = {
  0: '',
  1: '商品を作成してください',
};

export const ItemListRows: FC = memo(() => {
  const { wallList, childWallList } = useWallList({ wallType: 'item' });

  return (
    <>
      {[...wallList, ...childWallList].map((obj) => (
        <Tr key={obj.id}>
          <Td fontFamily="Hiragino sans">{obj.id}</Td>
          <Td style={{ width: '100%' }} fontFamily="Hiragino sans">
            <ItemDialog buttonMode="text" buttonName={obj.name} obj={obj} />
          </Td>
          <Td style={{ width: '240px' }}>
            <CardHeaderButtons>
              <CardHeaderButton
                path={`/paywall/${obj.id}/wallDesignList`}
                buttonText="画面設定"
                status={obj.alertStatusDesing}
                tooltipLabel={AlertStatusDesing[obj.alertStatusDesing]}
              />
              <CardHeaderButton
                path={`/paywall/${obj.id}/wallItemList`}
                buttonText="商品設定"
                status={obj.alertStatusProduct}
                tooltipLabel={AlertStatusProduct[obj.alertStatusProduct]}
              />
            </CardHeaderButtons>
          </Td>
        </Tr>
      ))}
    </>
  );
});
