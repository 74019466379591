import { Box } from '@chakra-ui/react';
import { useSearch } from 'components/opt/hooks/useSearch';
import { StyledFlex } from 'components/paywall/styled/global';
import { AnimatePresence, motion } from 'framer-motion';
import { FC, memo } from 'react';
import { Tag } from './Tag';

export const SearchedTag: FC = memo(() => {
  const { searchedData } = useSearch();

  return (
    <Box>
      <StyledFlex style={{ gap: 5 }}>
        <AnimatePresence>
          {searchedData.length >= 1 &&
            searchedData.map((d) => (
              <motion.div
                key={d}
                initial={{ x: -20, opacity: 0 }}
                animate={{ x: 0, opacity: 1 }}
                transition={{ ease: 'easeOut', duration: 0.2 }}
              >
                <Tag d={d} />
              </motion.div>
            ))}
        </AnimatePresence>
      </StyledFlex>
    </Box>
  );
});
