import { Box } from '@chakra-ui/react';
import styled from '@emotion/styled';
import { gcsFileUpload } from 'api/storage/gcsFileUpload';
import { isGcsFileUploadResponse } from 'api/storage/types';
import { useWallEditor } from 'components/paywall/hooks/useWallEditor';
import { WallDesign } from 'components/paywall/pages/WallDesignList/typed';
import 'components/paywall/pages/WallDesignList/WallEditor/Editor/index.css';
import { useCourseList } from 'hooks/paywall/useCourseList';
import { useCustomToast } from 'hooks/useCustomToast';
import { useEmailEditor } from 'hooks/useEmailEditor';
import { useUserTenantId } from 'hooks/user/useUserTenantId';
import { Dispatch, FC, memo, RefObject, SetStateAction, useCallback } from 'react';
import EmailEditor, { FileInfo, FileUploadDoneCallback } from 'react-email-editor';
import { useFormContext } from 'react-hook-form';
import { useParams, useSearchParams } from 'react-router-dom';
import { errorToast } from 'utils/toast';

const StyledLoadingOverlay = styled(Box)({
  position: 'absolute',
  width: 'calc(100% - 434px)',
  height: 'calc(100vh - 276px)',
  backgroundColor: '#EEEEEE99',
});

type EditorProps = {
  editor: RefObject<EmailEditor>;
  setIsLoading: Dispatch<SetStateAction<boolean>>;
  isLoading: boolean;
};

export const Editor: FC<EditorProps> = memo(
  ({ editor, setIsLoading, isLoading }: EditorProps) => {
    const tenantId = useUserTenantId();
    const params = useParams();
    const toast = useCustomToast();
    const [searchParams] = useSearchParams();
    const designType = searchParams.get('type') || 'course';
    const { setValue, getValues, setError } = useFormContext<WallDesign>();
    const { handleLoad } = useWallEditor({
      editor,
      getValues,
      setValue,
      setError,
    });
    const { courseList } = useCourseList({
      wallId: params.wallId || '',
      isIncludeParent: true
    });
    const {
      unlayerId,
      editorOptionsWithCustomTools,
      customPayWallButtonToolsConfig,
    } = useEmailEditor({
      editor,
      options: {
        user: { id: 0 },
      },
    });

    const onEditorReady = useCallback(() => {
      handleLoad({ setIsLoading });

      if (editor.current) {
        editor.current.registerCallback(
          'image',
          (file: FileInfo, done: FileUploadDoneCallback) => {
            const uploadPath = `public/${file.attachments[0].name}`;
            done({ progress: 50 });
            gcsFileUpload({
              file: file.attachments[0],
              filePath: uploadPath,
              tenantId,
            })
              .then((res) => {
                if (isGcsFileUploadResponse(res)) {
                  done({
                    progress: 100,
                    url: res.publicUrl,
                  });
                }
              })
              .catch((_) => {
                toast({
                  ...errorToast,
                  title: 'ファイルアップロードに失敗しました。',
                });
              });
          },
        );
      }
    }, [
      editor,
      handleLoad,
      setIsLoading,
      tenantId,
      toast
    ]);

    return (
      <Box>
        {!isLoading && <StyledLoadingOverlay />}
        <EmailEditor
          ref={editor}
          projectId={unlayerId}
          options={editorOptionsWithCustomTools({type: designType as 'course' | 'item' | 'member'})}
          tools={customPayWallButtonToolsConfig(courseList)}
          style={{
            minHeight: 'calc(100vh - 236px)',
          }}
          onReady={onEditorReady}
        />
      </Box>
    );
  },
);
