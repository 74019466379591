
import {
  Icon,
  VStack
} from '@chakra-ui/react';
import { useSearch } from 'components/opt/hooks/useSearch';
import { Colors } from 'components/paywall/config';
import { FC, memo, useCallback } from 'react';
import {
  MdArrowDropDown, MdArrowDropUp
} from 'react-icons/md';

type Props = {
  sortTarget: string;
}
export const SortButton: FC<Props> = memo(({sortTarget}: Props) => {
  const { orderingKey, orderingValue, handleFilterSort } = useSearch();

  const onSort = useCallback(() => {
    let sortType: 'asc' | 'desc' = 'asc';
    if(orderingKey === sortTarget && orderingValue === 'asc') {
      sortType = 'desc';
    }
    handleFilterSort(sortTarget, sortType)
  }, [handleFilterSort, orderingKey, orderingValue, sortTarget]);

  return (
    <VStack
      position="relative"
      w="20px"
      minW="20px"
      h="20px"
      alignItems="center"
      bgColor={Colors.GRAY_60}
      borderRadius="6px"
      cursor="pointer"
      onClick={onSort}
      _hover={{
        backgroundColor: Colors.GRAY_30
      }}
    >
      <Icon
        as={MdArrowDropUp}
        boxSize={4}
        position="absolute"
        top="-4%"
        left="50%"
        transform="translate(-50%)"
        color={orderingKey === sortTarget && orderingValue === 'asc' ? Colors.BLACK : Colors.GRAY_05}
      />
      <Icon
        as={MdArrowDropDown}
        boxSize={4}
        position="absolute"
        top="-16%"
        left="50%"
        transform="translate(-50%)"
        color={orderingKey === sortTarget && orderingValue === 'desc' ? Colors.BLACK : Colors.GRAY_05}
      />
    </VStack>
  )
})
