import { Options } from 'ky';
import { request } from 'api/request';
import { logger } from 'api/logger';
import { CustomError } from 'api/error/CustomError';
import { SettingInputs, isSettingInputs } from './types';
import { messages } from './messages';

// フォームフォーム設定情報取得
export const getEnqueteIdSetting = async (
  tenantId: string,
  enqueteId: string,
  options?: Options,
): Promise<SettingInputs> => {
  const path = `enquete/${tenantId}/${enqueteId}/`;

  const response = await request({
    path,
    method: 'get',
    options,
  });

  const responseData = (await response.data) as unknown[];

  if (response.hasError) {
    throw new CustomError(messages.getEnqueteIdSetting.customer.error);
  }

  if (!isSettingInputs(responseData)) {
    await logger({
      loglevel:
        response.status === 404 || response.status === 450
          ? 'warning'
          : 'error',
      data: responseData,
      message: messages.getEnqueteIdSetting.system.typeError,
    });

    throw new CustomError(messages.getEnqueteIdSetting.customer.failed);
  }

  return responseData;
};
