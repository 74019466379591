import { Box, Flex, Text } from '@chakra-ui/react';
import { HeaderAccount } from 'components/common/organisms';
import { useCustomNavigate } from 'hooks/useCustomNavigate';
import { FC, memo } from 'react';

export const Header: FC = memo(() => {
  const { navigate } = useCustomNavigate({});

  return (
    <Flex justifyContent="space-between" alignItems="center" h="full">
      <Flex w="calc(100% - 350px)" alignItems="center" flex={1}>
        <Box minW={45}>
          <Box onClick={() => navigate('/admin')} cursor="pointer">
            <img
              src={`${process.env.PUBLIC_URL}/static/UEC_favicon_180x180.png`}
              width="45px"
              alt="UEC"
            />
          </Box>
        </Box>
        <Text
          overflow="hidden"
          textOverflow="ellipsis"
          whiteSpace="nowrap"
          ml={4}
          fontWeight="bold"
        >
          組織管理画面
        </Text>
      </Flex>
      <Flex alignItems="center" gridGap={6} h="100%">
        <HeaderAccount isAdmin />
      </Flex>
    </Flex>
  );
});
