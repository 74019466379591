import {
  Checkbox,
  NumberDecrementStepper,
  NumberIncrementStepper,
  NumberInput,
  NumberInputField,
  NumberInputStepper,
  Select,
  Table,
  Tbody,
  Td,
  Text,
  Tr,
  VStack,
} from '@chakra-ui/react';
import { CondElement } from 'components/enquete/atoms/CondElement';
import { QuestionTitleElement } from 'components/enquete/atoms/QuestionTitleElement';
import { RichTextElement } from 'components/enquete/atoms/RichTextElement';
import { Toggle } from 'components/enquete/atoms/Toggle';
import { Dispatch, FC, SetStateAction, useState } from 'react';
import { FieldValues, Path, useFormContext } from 'react-hook-form';

type EnqueteFormType<T> = {
  baseName: Path<T>;
  index: number;
  setIsEdited: Dispatch<SetStateAction<boolean>>;
};

export const TextElement = <T extends FieldValues>({
  baseName,
  index,
  setIsEdited,
}: EnqueteFormType<T>): ReturnType<FC> => {
  const [toggle, setToggle] = useState(false);

  const EnqueteFormSelectOptions = [
    { id: 1, name: '制限なし' },
    { id: 2, name: '半角英数字' },
    { id: 3, name: '半角数字' },
    { id: 5, name: 'SNSアカウント(半角英数字 + 記号)' },
  ];

  const { register, getValues } = useFormContext<T>();

  return (
    <>
      <VStack w="100%" display={toggle ? 'none' : 'block'}>
        <Table>
          <Tbody>
            <Tr>
              <QuestionTitleElement<T>
                baseName={baseName}
                width={140}
                colspan={4}
                index={index}
              />
            </Tr>
            <Tr>
              <Td w={140}>補足説明など</Td>
              <Td colSpan={4}>
                <RichTextElement<T>
                  baseName={baseName}
                  placeholder=""
                  index={index}
                  position="descriptionTop"
                  value={
                    getValues(
                      `${baseName}.${index}.descriptionTop` as Path<T>,
                    ) || ''
                  }
                  setIsEdited={setIsEdited}
                />
              </Td>
            </Tr>
            <Tr>
              <Td w={140}>行数</Td>
              <Td w={200} pr={0}>
                <NumberInput
                  min={1}
                  defaultValue={1}
                  isDisabled={getValues('uneditableState' as Path<T>)}
                >
                  <NumberInputField
                    {...register(
                      `${baseName}.${index}.freeRowCount` as Path<T>,
                    )}
                    backgroundColor="#f9f9f9"
                    borderColor="#ccc"
                  />
                  <NumberInputStepper>
                    <NumberIncrementStepper />
                    <NumberDecrementStepper />
                  </NumberInputStepper>
                </NumberInput>
              </Td>
              <Td colSpan={3} w={100}>
                行
              </Td>
            </Tr>
            <Tr>
              <Td w={140} pr={0} rowSpan={2}>
                <Text>文字数制限</Text>
                <Text fontSize="xs">※改行は文字数にカウントされません。</Text>
              </Td>
              <Td w={200} pr={0}>
                <NumberInput
                  min={0}
                  defaultValue={0}
                  isDisabled={getValues('uneditableState' as Path<T>)}
                >
                  <NumberInputField
                    {...register(`${baseName}.${index}.freeTextMin` as Path<T>)}
                    backgroundColor="#f9f9f9"
                    borderColor="#ccc"
                  />
                  <NumberInputStepper>
                    <NumberIncrementStepper />
                    <NumberDecrementStepper />
                  </NumberInputStepper>
                </NumberInput>
              </Td>
              <Td w={100} pr={0}>
                文字以上
              </Td>
              <Td w={200} pr={0}>
                <NumberInput
                  min={0}
                  defaultValue={100}
                  isDisabled={getValues('uneditableState' as Path<T>)}
                >
                  <NumberInputField
                    {...register(`${baseName}.${index}.freeTextMax` as Path<T>)}
                    backgroundColor="#f9f9f9"
                    borderColor="#ccc"
                  />
                  <NumberInputStepper>
                    <NumberIncrementStepper />
                    <NumberDecrementStepper />
                  </NumberInputStepper>
                </NumberInput>
              </Td>
              <Td>文字以下</Td>
            </Tr>
            <Tr>
              <Td colSpan={4}>
                <Checkbox
                  {...register(
                    `${baseName}.${index}.isHideWordLimit` as Path<T>,
                  )}
                  isDisabled={getValues('uneditableState' as Path<T>)}
                >
                  <Text fontSize={14}>回答画面へ制限内容を表示しない</Text>
                </Checkbox>
              </Td>
            </Tr>
            <Tr>
              <Td>書式</Td>
              <Td colSpan={4}>
                <Select
                  w={300}
                  {...register(`${baseName}.${index}.freeFormat` as Path<T>)}
                  backgroundColor="#f9f9f9"
                  borderColor="#ccc"
                  isDisabled={getValues('uneditableState' as Path<T>)}
                >
                  {EnqueteFormSelectOptions.map((item) => (
                    <option key={item.id} value={item.id}>
                      {item.name}
                    </option>
                  ))}
                </Select>
              </Td>
            </Tr>
            <Tr>
              <Td w={140}>補足説明など</Td>
              <Td colSpan={4}>
                <RichTextElement<T>
                  baseName={baseName}
                  placeholder=""
                  index={index}
                  position="descriptionBottom"
                  value={
                    getValues(
                      `${baseName}.${index}.descriptionBottom` as Path<T>,
                    ) || ''
                  }
                  setIsEdited={setIsEdited}
                />
              </Td>
            </Tr>
          </Tbody>
        </Table>
        <CondElement<T> baseName={baseName} index={index} />
      </VStack>
      <Toggle toggle={toggle} setToggle={setToggle} />
    </>
  );
};
