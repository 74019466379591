import {
  Box,
  Button,
  CloseButton,
  DrawerBody,
  DrawerFooter,
  FormControl,
  FormErrorMessage,
  Heading,
  ListItem,
  Text,
  UnorderedList,
  VStack,
} from '@chakra-ui/react';
import { RhfInput } from 'admin/components/form/RhfInput';
import { RhfSelect } from 'admin/components/form/RhfSelect';
import { RhfTextarea } from 'admin/components/form/RhfTextarea';
import { AlertInfoBar } from 'admin/components/ui/alertInfo';
import { HelpTooltip } from 'admin/components/ui/helpTooltip';
import { RequiredIcon } from 'admin/components/ui/icon/requiredIcon';
import { LoadingLayer } from 'admin/components/ui/loadingLayer';
import { Colors, ColorSet } from 'admin/define/colors';
import { SamlSettingFormType, SamlSettingType } from 'admin/types/saml/index';
import { FC, memo, useCallback, useMemo } from 'react';
import {
  useFieldArray,
  useFormContext,
  useFormState,
  useWatch,
} from 'react-hook-form';
import { MdOutlineAdd } from 'react-icons/md';
import { toErrMsgList } from 'utils/form';

type SamlFormComponentProps = {
  formId: string;
  isLoading: boolean;
  domainList: SamlSettingType['domains'];
  defaultValues: SamlSettingFormType;
  isEdit: boolean;
  onSubmit: (value: SamlSettingFormType) => void;
  onClose: () => void;
};
export const SamlFormComponent: FC<SamlFormComponentProps> = memo(
  ({
    formId,
    isLoading,
    domainList,
    defaultValues,
    isEdit,
    onSubmit,
    onClose,
  }) => {
    const { handleSubmit, control } = useFormContext<SamlSettingFormType>();
    const { fields, append, remove } = useFieldArray<SamlSettingFormType>({
      control,
      name: 'domains',
    });
    const { errors } = useFormState<SamlSettingFormType>();
    const addDomainList = useCallback(() => {
      append({
        url: '',
      });
    }, [append]);

    const removeDomainList = useCallback(
      (index: number) => {
        remove(index);
      },
      [remove],
    );

    const domainValue = useWatch({
      control,
      name: 'domains',
    });

    const addDisable = useMemo(
      () => domainValue[domainValue.length - 1]?.url === '',
      [domainValue],
    );

    return (
      <>
        {isLoading && <LoadingLayer />}
        <DrawerBody pt="16px" pb="16px">
          <form onSubmit={handleSubmit(onSubmit)} id={formId}>
            <VStack spacing={6}>
              <Box w="100%">
                <Heading as="h3" size="sm" mb={2}>
                  <Box display="flex" alignItems="center">
                    対象ドメイン <RequiredIcon ml="8px" />
                  </Box>
                </Heading>
                <AlertInfoBar
                  type="error"
                  iconType="error"
                  border="none"
                  padding="8px 16px"
                >
                  <Text fontWeight={700}>
                    一度設定したドメインは変更できません。
                  </Text>
                </AlertInfoBar>
                <Box mt="8px">
                  <FormControl isInvalid={Boolean(errors.domains)}>
                    {fields.map((field, index) => (
                      <Box mb={2} key={field.id}>
                        <FormControl
                          isInvalid={Boolean(errors.domains?.[index]?.url)}
                        >
                          <Box display="flex" alignItems="center">
                            <RhfSelect<SamlSettingFormType>
                              key={`domainSelect${field.id}`}
                              name={`domains.${index}.url`}
                              placeholder="選択してください。"
                              bgColor={Colors.WHITE}
                              isDisabled={
                                Boolean(defaultValues.domains[index]?.url) &&
                                defaultValues.domains[index - 1]?.url !== ''
                              }
                            >
                              {domainList.map((domain) => (
                                <option
                                  value={domain}
                                  key={`domainOption_${domain}`}
                                >
                                  {domain}
                                </option>
                              ))}
                            </RhfSelect>
                            {!defaultValues.domains[index]?.url && (
                              <CloseButton
                                onClick={() => removeDomainList(index)}
                                ml="2"
                                size="md"
                                isDisabled={fields.length <= 1}
                              />
                            )}
                          </Box>
                          <FormErrorMessage key={`error${field.id}`}>
                            {errors.domains?.[index]?.url?.message}
                          </FormErrorMessage>
                        </FormControl>
                      </Box>
                    ))}
                    {toErrMsgList(errors, 'domains').map((err) => (
                      <FormErrorMessage key={`domains_${err}`} mb={2}>
                        {err}
                      </FormErrorMessage>
                    ))}
                  </FormControl>
                  <Box display="flex" justifyContent="center">
                    <Button
                      variant="outline"
                      onClick={addDomainList}
                      colorScheme="primary"
                      disabled={addDisable}
                      leftIcon={<MdOutlineAdd />}
                      fontWeight={700}
                    >
                      対象ドメインを追加
                    </Button>
                  </Box>
                </Box>
              </Box>
              <Box w="100%">
                <FormControl isInvalid={Boolean(errors.singleSignOnUrl)}>
                  <Heading as="h3" size="sm" mb={2}>
                    <Box display="flex" alignItems="center">
                      ログインURL <RequiredIcon ml="8px" />
                    </Box>
                  </Heading>
                  <Box>
                    <RhfInput<SamlSettingFormType>
                      name="singleSignOnUrl"
                      bgColor={Colors.WHITE}
                    />
                  </Box>
                  {toErrMsgList(errors, 'singleSignOnUrl').map((err) => (
                    <FormErrorMessage key={`singleSignOnUrl_${err}`}>
                      {err}
                    </FormErrorMessage>
                  ))}
                </FormControl>
              </Box>
              <Box w="100%">
                <FormControl isInvalid={Boolean(errors.entityId)}>
                  <Heading as="h3" size="sm" mb={2}>
                    <Box display="flex" alignItems="center">
                      識別子URL <RequiredIcon ml="8px" />
                    </Box>
                  </Heading>
                  <Box>
                    <RhfInput<SamlSettingFormType>
                      name="entityId"
                      bgColor={Colors.WHITE}
                    />
                  </Box>
                  {toErrMsgList(errors, 'entityId').map((err) => (
                    <FormErrorMessage key={`entityId_${err}`}>
                      {err}
                    </FormErrorMessage>
                  ))}
                </FormControl>
              </Box>
              <Box w="100%">
                <FormControl isInvalid={Boolean(errors.responseCertificate)}>
                  <Heading as="h3" size="sm" mb={2}>
                    <Box display="flex" alignItems="center">
                      X.509 証明書
                      <HelpTooltip
                        width="310px"
                        padding="8px"
                        label={
                          <Box>
                            <Text>
                              証明書の値は以下のように編集し貼付してください。
                            </Text>
                            <UnorderedList pl="8px">
                              <ListItem>
                                BEGIN CERTIFICATEDを含む行の削除
                              </ListItem>
                              <ListItem>
                                END CERTIFICATEDを含む行の削除
                              </ListItem>
                              <ListItem>
                                値が1行になるように全ての改行を削除
                              </ListItem>
                            </UnorderedList>
                          </Box>
                        }
                      />
                      <RequiredIcon ml="8px" />
                    </Box>
                  </Heading>
                  <Box>
                    <RhfTextarea<SamlSettingFormType>
                      name="responseCertificate"
                      bgColor={Colors.WHITE}
                      rows={10}
                    />
                  </Box>
                  {toErrMsgList(errors, 'responseCertificate').map((err) => (
                    <FormErrorMessage key={`responseCertificate_${err}`}>
                      {err}
                    </FormErrorMessage>
                  ))}
                </FormControl>
              </Box>
            </VStack>
          </form>
        </DrawerBody>
        <DrawerFooter display="flex" justifyContent="end" gridGap={4}>
          <Button
            variant="outline"
            onClick={onClose}
            lineHeight={5}
            p="8px 24px"
            h="auto"
            w="104px"
            {...ColorSet.Default}
          >
            キャンセル
          </Button>
          <Button
            type="submit"
            form={formId}
            lineHeight={5}
            p="8px 24px"
            h="auto"
            colorScheme="primary"
            w="104px"
          >
            {isEdit ? '保存' : '設定'}
          </Button>
        </DrawerFooter>
      </>
    );
  },
);
