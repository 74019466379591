import { VFC, memo, Suspense } from 'react';
import { Outlet } from 'react-router-dom';
import { VStack } from '@chakra-ui/react';

import { useSetPageTitle } from 'hooks/useSetPageTitle';
import ErrorBoundary from 'api/ErrorBoundary';
import { useErrorBoundaryReset } from 'hooks/useErrorBoundaryReset';
import { LoadingSkeleton, ErrorContents } from 'components/common/atoms';
import { SideMenyLayout } from 'components/common/templates/SideMenyLayout';
import { SegmentSide } from 'components/segment/SegmentSide';

export const Segment: VFC = memo(() => {
  useSetPageTitle('セグメント設定');
  const { ebKey, onError } = useErrorBoundaryReset();

  return (
    <VStack spacing={0} h="full">
      <SideMenyLayout
        sidemenu={
          <ErrorBoundary
            errorComponent={<ErrorContents name="セグメント一覧" />}
            onError={onError}
            key={ebKey.current}
          >
            <Suspense fallback={<LoadingSkeleton />}>
              <SegmentSide />
            </Suspense>
          </ErrorBoundary>
        }
        main={
          <ErrorBoundary onError={onError} key={ebKey.current}>
            <Suspense fallback={<LoadingSkeleton />}>
              <Outlet />
            </Suspense>
          </ErrorBoundary>
        }
      />
    </VStack>
  );
});
