import { zodResolver } from '@hookform/resolvers/zod';
import { withSuspenseAndErrorBoundary } from 'admin/components/Error/WithErrorBoundary';
import { useCreateTerm } from 'admin/hooks/userPool/terms/useCreateTerm';
import { termCreateFormSchema } from 'admin/schema/idPoolConsumer/terms';
import { TermFormType } from 'admin/types/userPool/terms/form';
import { AlertBar } from 'components/common/atoms';
import { FC, memo, useState } from 'react';
import { FormProvider, SubmitHandler, useForm } from 'react-hook-form';
import { TermsCreateFormComponent } from './TermsCreateFormComponent';

type Props = {
  onClose: () => void;
  formId: string;
};

const FetchTermsCreateForm: FC<Props> = memo(({ onClose, formId }: Props) => {
  const [globalErrors, setGlobalErrors] = useState<string[]>([]);
  const methods = useForm<TermFormType>({
    defaultValues: {
      fieldName: '',
      displayNameJa: '',
      displayNameForeign: '',
      url: '',
      displayOrder: undefined,
    },
    resolver: zodResolver(termCreateFormSchema),
  });
  const { setError } = methods;

  const { mutate, isLoading } = useCreateTerm(setError, setGlobalErrors);

  const onSubmit: SubmitHandler<TermFormType> = async (data: TermFormType) => {
    await mutate(data);
    onClose();
  };

  return (
    <FormProvider {...methods}>
      {globalErrors.map((err, idx) => (
        <AlertBar
          key={`global-err-idx${String(idx)}`}
          message={err}
          status="error"
          mb={4}
        />
      ))}
      <TermsCreateFormComponent
        onSubmit={onSubmit}
        formId={formId}
        isLoading={isLoading}
      />
    </FormProvider>
  );
});

export const TermsCreateForm =
  withSuspenseAndErrorBoundary(FetchTermsCreateForm);
