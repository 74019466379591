import { VFC, Suspense } from 'react';
import { useLocation, Link as RouterLink } from 'react-router-dom';
import { parseISO, format } from 'date-fns';
import { useUserTenantId } from 'hooks/user/useUserTenantId';
import { useTableId } from 'hooks/contentsdb/useTableId';
import {
  Table,
  Thead,
  Tbody,
  Tr,
  Th,
  Td,
  Box,
  Button,
  Text,
  Link,
  Flex,
} from '@chakra-ui/react';
import { IoIosRefresh } from 'react-icons/io';
import ErrorBoundary from 'api/ErrorBoundary';
import { errorFileDownload } from 'api/contents/errorFileDownload';
import { useUploadHistory } from 'hooks/contentsdb/useUploadHistory';
import { useUploadHistoryCacheClear } from 'hooks/contentsdb/useUploadHistoryCacheClear';
import { useErrorBoundaryReset } from 'hooks/useErrorBoundaryReset';
import { contentDbUpStatus } from 'define';
import { AlertBar } from 'components/common/atoms';

type ErrorDownloadLinkProps = {
  tableId: string;
  tenantId: string;
  errorCount: number;
  uploadRequestId: number;
};

const ErrorDownloadLink: VFC<ErrorDownloadLinkProps> = ({
  tableId,
  tenantId,
  errorCount,
  uploadRequestId,
}) => {
  if (errorCount === 0) {
    return <>{errorCount}</>;
  }

  const onClick = () => {
    errorFileDownload(tenantId, tableId, uploadRequestId);
  };

  return (
    <Text
      as="a"
      onClick={onClick}
      style={{ cursor: 'pointer' }}
      textDecoration="underline"
    >
      {errorCount}
    </Text>
  );
};

type TableUploadHistoryInnerProps = {
  tableId: string;
  tenantId: string;
  page: number;
};

const TableUploadHistoryInner: VFC<TableUploadHistoryInnerProps> = ({
  tableId,
  tenantId,
  page,
}) => {
  const clearCache = useUploadHistoryCacheClear({ tenantId, tableId });
  const { page: pager, uploadHistoryList } = useUploadHistory({
    tenantId,
    tableId,
    page,
  });

  if (!pager) {
    return <></>;
  }

  const onReload = () => {
    clearCache();
  };

  return (
    <>
      {pager.count === 0 && (
        <AlertBar
          status="info"
          message="アップロード履歴はございません。"
          mb={4}
          bg="teal.50"
          borderRadius={8}
        />
      )}
      <Box textAlign="right" mt={4}>
        <Button onClick={onReload}>
          <IoIosRefresh />
          <Text as="span" ml={2}>
            更新
          </Text>
        </Button>
      </Box>
      {pager.count > 0 && (
        <>
          <Box
            width="calc(100vw - 413px)"
            maxHeight="260px"
            mt={8}
            position="relative"
            overflow="auto"
            border="1px"
            borderColor="teal.50"
          >
            <Table
              variant="simple"
              overflowX="scroll"
              width="100%"
              whiteSpace="nowrap"
            >
              <Thead position="sticky" zIndex="sticky" top={0}>
                <Tr>
                  <Th
                    position="sticky"
                    top={0}
                    zIndex={1}
                    bg="teal.50"
                    fontSize="sm"
                  >
                    No.
                  </Th>
                  <Th
                    position="sticky"
                    top={0}
                    zIndex={1}
                    bg="teal.50"
                    fontSize="sm"
                  >
                    ファイル
                  </Th>
                  <Th
                    position="sticky"
                    top={0}
                    zIndex={1}
                    bg="teal.50"
                    fontSize="sm"
                  >
                    データ
                  </Th>
                  <Th
                    position="sticky"
                    top={0}
                    zIndex={1}
                    bg="teal.50"
                    fontSize="sm"
                  >
                    取込
                  </Th>
                  <Th
                    position="sticky"
                    top={0}
                    zIndex={1}
                    bg="teal.50"
                    fontSize="sm"
                  >
                    エラー
                  </Th>
                  {/* <Th position="sticky" top={0} fontSize="sm">開始</Th> */}
                  <Th
                    position="sticky"
                    top={0}
                    zIndex={1}
                    bg="teal.50"
                    fontSize="sm"
                  >
                    終了
                  </Th>
                  <Th
                    position="sticky"
                    top={0}
                    zIndex={1}
                    bg="teal.50"
                    fontSize="sm"
                  >
                    状況
                  </Th>
                </Tr>
              </Thead>
              <Tbody>
                {uploadHistoryList.map((row) => {
                  const status = contentDbUpStatus(row.uploadStatus);
                  const esEnd = row.esImportEnd;

                  let updateEnd = '';
                  // ErasticSearchの終了時刻を表示
                  if (esEnd) {
                    updateEnd = format(parseISO(esEnd), 'yyyy-MM-dd HH:mm');
                  }

                  return (
                    <Tr key={`upload-history-${String(row.id)}`}>
                      <Td>{row.id}</Td>
                      <Td>{row.uploadFileName}</Td>
                      <Td isNumeric>{row.rowCount}</Td>
                      <Td isNumeric>{row.expectedCount}</Td>
                      <Td isNumeric>
                        <ErrorDownloadLink
                          tenantId={tenantId}
                          tableId={tableId}
                          errorCount={row.errorCount}
                          uploadRequestId={row.id}
                        />
                      </Td>
                      <Td>{updateEnd}</Td>
                      <Td>{status}</Td>
                    </Tr>
                  );
                })}
              </Tbody>
            </Table>
          </Box>
          <Flex alignItems="center" justifyContent="space-between" mt={8}>
            {pager.previous ? (
              <Link
                to={`./?page=${page > 1 ? page - 1 : 1}`}
                as={RouterLink}
                _hover={{ textDecoration: 'none' }}
              >
                <Text fontSize="md" as="span" pl={2}>
                  &lt;
                </Text>
                <Text fontSize="sm" as="span" pl={2}>
                  前へ
                </Text>
              </Link>
            ) : (
              <div>&nbsp;</div>
            )}
            {pager.next ? (
              <Link
                to={`./?page=${page ? page + 1 : 2}`}
                as={RouterLink}
                _hover={{ textDecoration: 'none' }}
              >
                <Text fontSize="sm" as="span" pl={2}>
                  次へ
                </Text>
                <Text fontSize="md" as="span" pl={2}>
                  &gt;
                </Text>
              </Link>
            ) : (
              <></>
            )}
          </Flex>
        </>
      )}
    </>
  );
};

export const TableUploadHistory: VFC = () => {
  const { ebKey, onError } = useErrorBoundaryReset();
  const tableId = useTableId();
  const tenantId = useUserTenantId();
  const { search } = useLocation();
  const queryParams = new URLSearchParams(search);
  const pageParam = queryParams.get('page') || '';
  const page = /^[0-9]+$/.test(pageParam) ? Number(pageParam) : 0;

  return (
    <ErrorBoundary onError={onError} key={ebKey.current}>
      <Suspense fallback={<Box h={140}>&nbsp;</Box>}>
        <Box>
          {tableId && tenantId ? (
            <TableUploadHistoryInner
              tableId={tableId}
              tenantId={tenantId}
              page={page}
            />
          ) : (
            <></>
          )}
        </Box>
      </Suspense>
    </ErrorBoundary>
  );
};
