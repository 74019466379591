import { VFC, memo, useCallback, useEffect, useState } from 'react';
import { NavLink } from 'react-router-dom';
import {
  Flex,
  Box,
  Table,
  Thead,
  Tbody,
  Tr,
  Td,
  Tooltip,
} from '@chakra-ui/react';
import { WarningTwoIcon, InfoOutlineIcon } from '@chakra-ui/icons';

import { format } from 'date-fns';
import {
  MAIL_DELIVERY_TYPE_NOT_SET,
  MAIL_DELIVERY_TYPE_RESERVE,
  MAIL_DELIVERY_TO_ALL,
  MAIL_DELIVERY_TO_SEGMENT,
  MAIL_DELIVERY_TO_DIRECT,
  MAIL_DELIVERY_TO_INSERT,
} from 'define';
import {
  MailInfo,
  MailColumns,
  MailColumnsFilter,
  MailSubscribe,
} from 'api/mail/types';
import { getMailCopy } from 'api/mail/getMailCopy';
import { Segment } from 'api/segment/types';
import { successToast, errorToast } from 'utils/toast';
import { useCustomToast } from 'hooks/useCustomToast';
import { useUserTenantId } from 'hooks/user/useUserTenantId';
import { useMailList } from 'hooks/mail/useMailList';
import { useMailUtil } from 'hooks/mail/useMailUtil';
import { Paginator, DropDownMenu } from 'components/common/atoms';
import { MailListTableHeader } from 'components/mail/organisms/MailListTableHeader';
import { MailSettingDrawerForm } from 'components/mail/organisms/MailSettingDrawerForm';
import { MailReportDrawerForm } from 'components/mail/organisms/MailReportDrawerForm';
import { BsChevronDown } from 'react-icons/bs';
import 'styles/common/customNavlink.css';
import { getMailSubscribeList } from 'api/mail/getMailSubscribeList';

type MailListInnerProps = {
  columns: MailColumns[];
  statusList: MailColumnsFilter[];
  deliveryList: MailColumnsFilter[];
  segmentList: Segment[];
  onSort: (item: MailColumns) => void;
  onColumnFilter: (item: MailColumns, filterItem: MailColumnsFilter) => void;
};
const DATETIME_FORMAT = 'yyyy年MM月dd HH:mm';

export const MailListInner: VFC<MailListInnerProps> = memo(
  ({
    columns,
    statusList,
    segmentList,
    deliveryList,
    onSort,
    onColumnFilter,
  }: MailListInnerProps) => {
    const { ClearMailListCache } = useMailUtil();
    const toast = useCustomToast();
    const tenantId = useUserTenantId();
    const { page, mailList } = useMailList();
    const [subscribeList, setSubscribeList] = useState<MailSubscribe[]>([]);

    const onDuplicate = useCallback(
      async (item: MailInfo) => {
        await getMailCopy(tenantId, item.id);
        ClearMailListCache();
        toast({
          ...successToast,
          title: 'メールをコピーしました',
          position: 'top-right',
          variant: 'copy',
        });
      },
      [tenantId, toast, ClearMailListCache],
    );

    // 配信設定エラー判定
    const deliverySettingError = (item: MailInfo): string => {
      if (
        item.deliveryType === MAIL_DELIVERY_TYPE_NOT_SET ||
        (item.deliveryType === MAIL_DELIVERY_TYPE_RESERVE &&
          item.reserve === '') ||
        item.fromAddress === '' ||
        item.fromAddress === null
      ) {
        return '配信設定が未設定です';
      }
      if (item.bodyHtml === '' || item.bodyText === '') {
        return 'メール本文が未入力の為、メール送信ができません';
      }

      return '';
    };

    // CSVファイルエラー判定
    const replaceWordError = (item: MailInfo): string => {
      let resultMsg = '';
      if (item.deliveryTo === MAIL_DELIVERY_TO_INSERT) {
        if (!item.addressFileOrigin) {
          resultMsg = 'CSVファイルをアップロードしてください';
        } else if (item.isReplaceWordMismatch) {
          resultMsg = 'CSVファイルの修正が必要です';
        }
      }

      return resultMsg;
    };

    const getMailSubscript = useCallback(async () => {
      const subscript = await getMailSubscribeList(tenantId);
      setSubscribeList(subscript.subscriptions);
    }, [tenantId, setSubscribeList]);

    useEffect(() => {
      getMailSubscript().catch((_) => {
        toast({
          ...errorToast,
          title: '購読者一覧取得エラー',
          description: '購読者一覧の取得ができませんでした。',
        });
      });
    }, []);

    const getSegment = (item: MailInfo): string => {
      let segmentName = '-';
      switch (item.deliveryTo) {
        case MAIL_DELIVERY_TO_ALL:
          segmentName = 'メルマガ購読者全員への配信';
          if (item.mailMagazineId && item.mailMagazineTitle) {
            segmentName = item.mailMagazineTitle;
          } else if (subscribeList.length !== 0) {
            segmentName = subscribeList[0].name;
          }
          break;
        case MAIL_DELIVERY_TO_SEGMENT:
          segmentName = item.segmentName || '未設定';
          break;
        case MAIL_DELIVERY_TO_DIRECT:
          segmentName = 'メールアドレス直接指定';
          break;
        case MAIL_DELIVERY_TO_INSERT:
          segmentName = '差し込みメール配信';
          break;
        default:
          break;
      }

      return segmentName;
    };

    const menuItems = (item: MailInfo) => [
      {
        menuItemType: 'text',
        label: 'メールを複製',
        testId: 'mail-copy',
        onClick: () => {
          void onDuplicate(item);
        },
      },
    ];

    const viewValue = (
      item: MailInfo,
      val: number,
      prefix?: string,
    ): string | number => (item.status !== 40 ? '-' : `${val}${prefix || ''}`);

    return (
      <>
        <Box
          w="calc(100vw - 100px)"
          h="100vh"
          overflowY="auto"
          maxHeight="calc(100vh - 210px)"
        >
          <Table variant="striped">
            <Thead position="sticky" zIndex="sticky" top={0} bgColor="white">
              <MailListTableHeader
                columns={columns}
                statusList={statusList}
                deliveryList={deliveryList}
                onSort={onSort}
                onColumnFilter={onColumnFilter}
              />
            </Thead>
            <Tbody>
              {mailList?.map((item) => (
                <Tr key={item.id}>
                  <Td fontSize={12}>
                    <Flex alignItems="center">
                      {item.id}
                      <DropDownMenu
                        menuType="icon"
                        items={menuItems(item)}
                        icon={<BsChevronDown />}
                        variant="none"
                        width="100%"
                        zIndex="1500"
                      />
                    </Flex>
                  </Td>
                  <Td fontSize={12}>
                    <Box
                      display="flex"
                      justifyContent="space-between"
                      alignItems="center"
                    >
                      <NavLink
                        to={`/mail/form?formId=${item.id}`}
                        className="custom-nav-link--no-scroll-bar"
                      >
                        {item.subject}
                      </NavLink>
                      <Box position="relative" ml={4}>
                        <MailSettingDrawerForm
                          mailId={item.id}
                          buttonName="配信設定"
                          isError={deliverySettingError(item) !== ''}
                          segmentList={segmentList || []}
                        />
                        <Flex
                          alignItems="center"
                          position="absolute"
                          top="-10px"
                          left="-10px"
                        >
                          {/* 設定不備がある場合のエラーアイコン */}
                          {deliverySettingError(item) !== '' && (
                            <Tooltip
                              label={deliverySettingError(item)}
                              bgColor="red.400"
                              color="white"
                            >
                              <WarningTwoIcon
                                color="red"
                                fontSize={16}
                                mr={1}
                              />
                            </Tooltip>
                          )}
                          {/* メール差し込みファイルコンフリクト時エラーアイコン */}
                          {replaceWordError(item) !== '' && (
                            <Tooltip
                              label={replaceWordError(item)}
                              bgColor="yellow.500"
                              color="white"
                            >
                              <InfoOutlineIcon
                                color="yellow.500"
                                fontSize={16}
                                backgroundColor="white"
                                borderRadius="6px"
                              />
                            </Tooltip>
                          )}
                        </Flex>
                      </Box>
                    </Box>
                  </Td>
                  <Td fontSize={12}>
                    {item.reserve
                      ? format(new Date(item.reserve), DATETIME_FORMAT)
                      : '-'}
                  </Td>
                  <Td fontSize={12}>
                    {item.deliveryStart
                      ? format(new Date(item.deliveryStart), DATETIME_FORMAT)
                      : '-'}
                  </Td>
                  <Td fontSize={12}>{item.statusName || '-'}</Td>
                  <Td fontSize={12}>
                    {item.hasMailLink ? (
                      <MailReportDrawerForm
                        mailInfo={item}
                        buttonName="レポート"
                      />
                    ) : (
                      '計測対象リンクなし'
                    )}
                  </Td>
                  <Td fontSize={12}>{getSegment(item)}</Td>
                  <Td fontSize={12} textAlign="right">
                    {item.estimateCnt}
                  </Td>
                  <Td fontSize={12} textAlign="right">
                    {viewValue(item, item.deliveryCnt)}
                  </Td>
                  <Td fontSize={12} textAlign="right">
                    {viewValue(item, item.deliveryRate, '%')}
                  </Td>
                  <Td fontSize={12} textAlign="right">
                    {viewValue(item, item.openCnt)}
                  </Td>
                  <Td fontSize={12} textAlign="right">
                    {viewValue(item, item.openRate, '%')}
                  </Td>
                  <Td fontSize={12} textAlign="right">
                    {viewValue(item, item.linkClickCnt)}
                  </Td>
                  <Td fontSize={12} textAlign="right">
                    {viewValue(item, item.linkClickRate, '%')}
                  </Td>
                </Tr>
              ))}
            </Tbody>
          </Table>
        </Box>
        <Paginator pageName="/mail/" config={page} />
      </>
    );
  },
);
