import { customErrorMessage, toZod } from 'admin/config/zod';
import { CodeSettingType } from 'admin/types/userPool/code/index';
import { z } from 'zod';
import { isPositiveInteger } from '../util/index';

// zodErrorの日本語化対応
z.setErrorMap(customErrorMessage);

export const createCodeSchema = z.object<toZod<Omit<CodeSettingType, 'id'>>>({
  codeGroup: z.string(),
  displayNameJa: z.string().min(1, '日本語は必須です。'),
  displayNameForeign: z.string().optional(),
  code: z.string().min(1, 'コード値は必須です'),
  displayOrder: z
    .string()
    .min(1, '並び順は必須です')
    .refine((val) => isPositiveInteger(val), {
      message: '正数値のみが許可されます。小数点以下は含められません。',
    })
    .or(z.number()),
});

export const editCodeSchema = z.object<toZod<CodeSettingType>>({
  id: z.string(),
  codeGroup: z.string(),
  displayNameJa: z.string().min(1, '日本語は必須です。'),
  displayNameForeign: z.string().optional().nullable(),
  code: z.string().min(1, 'コード値は必須です'),
  displayOrder: z
    .string()
    .min(1, '並び順は必須です')
    .refine((val) => isPositiveInteger(val), {
      message: '正数値のみが許可されます。小数点以下は含められません。',
    })
    .or(z.number()),
});

export const codeSettingsSchema = z.array(editCodeSchema);

export const deleteCodeSettingSchema = z.object({
  id: z.string(),
  codeGroup: z.string(),
  word: z.string().refine((val) => val === 'delete', {
    message: '"delete"と入力してください',
  }),
});
