import { Box } from '@chakra-ui/react';
import { gcsFileUpload } from 'api/storage/gcsFileUpload';
import { isGcsFileUploadResponse } from 'api/storage/types';
import { FirstLoginMailFormType } from 'api/transactionMail/type';
import { useCustomToast } from 'hooks/useCustomToast';
import { useEmailEditor } from 'hooks/useEmailEditor';
import { useUserTenantId } from 'hooks/user/useUserTenantId';
import { FC, memo, RefObject, useCallback } from 'react';
import EmailEditor, {
  Design,
  FileInfo,
  FileUploadDoneCallback,
} from 'react-email-editor';
import { useFormContext } from 'react-hook-form';
import { errorToast, successToast } from 'utils/toast';

type Props = {
  editor: RefObject<EmailEditor>;
  isEditable: boolean;
  setIsHandleLoad: (bool: boolean) => void;
};

export const HtmlEditor: FC<Props> = memo(
  ({ editor, isEditable, setIsHandleLoad }: Props) => {
    const { setValue, getValues } = useFormContext<FirstLoginMailFormType>();
    const toast = useCustomToast();
    const tenantId = useUserTenantId();
    const {
      unlayerId,
      editorOptions,
      defaultDesignValue,
      loadDesign,
      editorReady,
      imageUploadFinish,
      editorUpdated,
    } = useEmailEditor({
      editor,
      options: { user: { id: 0 } },
    });

    const handleLoad = useCallback(async () => {
      const editorText = getValues('editorHtml');
      // JSONの型安全の為、editorTextが空文字の場合デフォルトのDesign型オブジェクトをセットする
      if (editorText === '') {
        loadDesign(defaultDesignValue);
      } else {
        const editorDesignData = JSON.parse(editorText) as Design;
        Object.assign(defaultDesignValue, { body: editorDesignData });
        loadDesign(defaultDesignValue);
      }
      await editorReady().then(({ isReady }) => {
        setIsHandleLoad(!isReady);
      });

      if (editor.current) {
        editor.current.registerCallback(
          'image',
          (file: FileInfo, done: FileUploadDoneCallback) => {
            const uploadPath = `public/${file.attachments[0].name}`;
            done({ progress: 50 });
            gcsFileUpload({
              file: file.attachments[0],
              filePath: uploadPath,
              tenantId,
            })
              .then((res) => {
                if (isGcsFileUploadResponse(res)) {
                  done({
                    progress: 100,
                    url: res.publicUrl,
                  });
                }
              })
              .catch((_) => {
                toast({
                  ...errorToast,
                  title: 'ファイルアップロードに失敗しました。',
                });
              });
          },
        );
      }
      await imageUploadFinish().then(({ message }) => {
        toast({
          ...successToast,
          title: message,
        });
      });
      // エディタの更新が発生する際に都度エクスポートする
      await editorUpdated().then(({ design, html }) => {
        setValue('editorHtml', JSON.stringify(design.body));
        setValue('bodyHtml', html);
      });
    }, [
      getValues,
      editorReady,
      editor,
      imageUploadFinish,
      editorUpdated,
      loadDesign,
      defaultDesignValue,
      // setIsEdited,
      setIsHandleLoad,
      tenantId,
      toast,
      setValue,
    ]);

    return (
      <Box w="100%" h="calc(100vh - 180px)">
        {isEditable && (
          <Box
            position="absolute"
            width="calc(100vw - 110px)"
            height="calc(100vh - 210px)"
            bgColor="#EEEEEE99"
          />
        )}
        <EmailEditor
          ref={editor}
          projectId={unlayerId}
          options={editorOptions()}
          style={{
            minHeight: 'calc(100vh - 210px)',
            minWidth: 'initial',
          }}
          onReady={handleLoad}
        />
      </Box>
    );
  },
);
