import { zodResolver } from '@hookform/resolvers/zod';
import { TenantUsersInviteFormComponent } from 'admin/components/tenantUsers/tenantUsersInviteForm/TenantUsersInviteFormComponent';
import { useTenantUsersInvite } from 'admin/hooks/tenantUsers/useInviteTenantUsers';
import { useTenantList } from 'admin/hooks/tenantUsers/useTenantList';
import { tenantUsersInviteSchema } from 'admin/schema/tenantUsers';
import { tenantUsersInviteFormType } from 'admin/types/tenantUsers/form';
import { AlertBar } from 'components/common/atoms';
import { FC, memo, useState } from 'react';
import { FormProvider, SubmitHandler, useForm } from 'react-hook-form';

type Props = {
  onClose: () => void;
};

export const TenantUsersInviteForm: FC<Props> = memo(({ onClose }: Props) => {
  const formId = 'tenantUsersInviteForm';
  const [globalErrors, setGlobalErrors] = useState<string[]>([]);

  const { data: tenantList } = useTenantList();

  const methods = useForm<tenantUsersInviteFormType>({
    defaultValues: {
      tenant: '',
      emailTo: '',
      permission: 90,
    },
    resolver: zodResolver(tenantUsersInviteSchema),
  });

  const { mutate, isLoading } = useTenantUsersInvite(
    methods.setError,
    setGlobalErrors,
  );

  const onSubmit: SubmitHandler<tenantUsersInviteFormType> = async (
    data: tenantUsersInviteFormType,
  ) => {
    await mutate(data);
    onClose();
  };

  if (!tenantList) {
    return null;
  }

  return (
    <FormProvider {...methods}>
      {globalErrors.map((err, idx) => (
        <AlertBar
          key={`global-err-idx${String(idx)}`}
          message={err}
          status="error"
          mb={4}
        />
      ))}
      <TenantUsersInviteFormComponent
        formId={formId}
        onSubmit={onSubmit}
        isLoading={isLoading}
        onClose={onClose}
        tenantList={tenantList}
      />
    </FormProvider>
  );
});
