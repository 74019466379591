import { Box, Button, DrawerBody, DrawerFooter, FormControl, FormErrorMessage, Heading } from '@chakra-ui/react';
import { RhfInput } from 'admin/components/form/RhfInput';
import { Colors, ColorSet } from 'admin/define/colors';
import { LoginSetting } from 'api/tenant/types';
import { FC, memo } from 'react';
import { useFormContext, useFormState } from 'react-hook-form';
import { toErrMsgList } from 'utils/form';

type Props = {
  formId: string;
  onSubmit: (value: LoginSetting) => void;
  onClose: () => void;
};

export const LoginRedirectFormComponent: FC<Props> = memo(({ formId, onSubmit, onClose }: Props) => {
  const { handleSubmit } = useFormContext<LoginSetting>();
  const { errors } = useFormState<LoginSetting>();

  return (
    <>
      <DrawerBody>
        <form onSubmit={handleSubmit(onSubmit)} id={formId}>
          <Box mt="24px">
            <FormControl isInvalid={Boolean(errors.loginUrl)}>
              <Heading as="h3" size="sm" mb="4px">
                <Box display="flex" alignItems="bottom">
                  ログインページURL
                </Box>
              </Heading>
              <Box>
                <RhfInput<LoginSetting>
                  name="loginUrl"
                  bgColor={Colors.WHITE}
                />
              </Box>
              {toErrMsgList(errors, 'loginUrl').map((err) => (
                <FormErrorMessage key={`loginUrl${err}`}>
                  {err}
                </FormErrorMessage>
              ))}
            </FormControl>
          </Box>
          <Box mt="24px">
            <FormControl isInvalid={Boolean(errors.signupUrl)}>
              <Heading as="h3" size="sm" mb="4px">
                <Box display="flex" alignItems="bottom">
                  会員登録ページURL
                </Box>
              </Heading>
              <Box>
                <RhfInput<LoginSetting>
                  name="signupUrl"
                  bgColor={Colors.WHITE}
                />
              </Box>
              {toErrMsgList(errors, 'signupUrl').map((err) => (
                <FormErrorMessage key={`signupUrl${err}`}>
                  {err}
                </FormErrorMessage>
              ))}
            </FormControl>
          </Box>
          <Box mt="24px">
            <FormControl isInvalid={Boolean(errors.loginParam)}>
              <Heading as="h3" size="sm" mb="4px">
                <Box display="flex" alignItems="bottom">
                  リダイレクト先指定クエリパラメータ
                </Box>
              </Heading>
              <Box>
                <RhfInput<LoginSetting>
                  name="loginParam"
                  bgColor={Colors.WHITE}
                />
              </Box>
              {toErrMsgList(errors, 'loginParam').map((err) => (
                <FormErrorMessage key={`loginParam${err}`}>
                  {err}
                </FormErrorMessage>
              ))}
            </FormControl>
          </Box>
          <Box mt="24px">
            <FormControl isInvalid={Boolean(errors.loginOtherParam)}>
              <Heading as="h3" size="sm" mb="4px">
                <Box display="flex" alignItems="bottom">
                  任意のクエリパラメータ
                </Box>
              </Heading>
              <Box>
                <RhfInput<LoginSetting>
                  name="loginOtherParam"
                  bgColor={Colors.WHITE}
                />
              </Box>
              {toErrMsgList(errors, 'loginOtherParam').map((err) => (
                <FormErrorMessage key={`loginOtherParam${err}`}>
                  {err}
                </FormErrorMessage>
              ))}
            </FormControl>
          </Box>
        </form>
      </DrawerBody>
      <DrawerFooter justifyContent="space-between">
        <Button
          variant="outline"
          mr={3}
          onClick={() => onClose()}
          p="16px 24px"
          w="120px"
          {...ColorSet.Default}
        >
          キャンセル
        </Button>
        <Button
          type="submit"
          form={formId}
          p="16px 24px"
          w="120px"
          {...ColorSet.Primary}
        >
          保存
        </Button>
      </DrawerFooter>
    </>
  );
});
