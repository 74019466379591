import { editCodeSetting } from 'admin/api/userPool/code/editCodeSetting';
import { CustomError } from 'admin/error/CustomError';
import { queryKeyNames } from 'admin/hooks/userPool/master/queryKeys';
import { EditCodeFormType } from 'admin/types/userPool/code/form';
import { logger } from 'api/logger';
import { useCustomToast } from 'hooks/useCustomToast';
import { Dispatch, SetStateAction } from 'react';
import { UseFormSetError } from 'react-hook-form';
import { useMutation, useQueryClient } from 'react-query';
import { toMultiError } from 'utils/form';
import { errorToast, successToast } from 'utils/toast';
import { keyNames, queryKeys } from './queryKeys';

export const useEditCodeSetting = (
  setError: UseFormSetError<EditCodeFormType>,
  setGlobalErrors: Dispatch<SetStateAction<string[]>>,
) => {
  const toast = useCustomToast();
  const queryClient = useQueryClient();

  const { mutateAsync, isLoading } = useMutation({
    mutationFn: (data: EditCodeFormType) => editCodeSetting(data),
    onError: async (error, variables: EditCodeFormType) => {
      if (error instanceof CustomError) {
        if (error.cause.errors?.others) {
          const othersMsgs = Array.isArray(error.cause.errors?.others)
            ? error.cause.errors?.others
            : [error.cause.errors?.others];

          setGlobalErrors(othersMsgs);
        } else {
          Object.keys(variables).forEach((k) => {
            const key = k as keyof EditCodeFormType;
            const errMsgs = error.cause.errors?.[key];
            if (errMsgs && Array.isArray(errMsgs)) {
              setError(key, { types: toMultiError(errMsgs) });
            }
          });
        }

        if (error.cause.errors) {
          await logger({
            loglevel: error.logLevel,
            data: JSON.stringify(error.cause),
            message: error.message,
          });
        }
      }
      toast({
        ...errorToast,
        duration: 4000,
        title: 'コードの変更に失敗しました',
        position: 'bottom',
        variant: 'solid',
      });
    },
    onSuccess: (response) => {
      void queryClient.invalidateQueries(keyNames.getCodeSettings);
      void queryClient.invalidateQueries(
        queryKeys.getCodeSetting(response.id, response.codeGroup),
      );
      void queryClient.invalidateQueries(queryKeyNames.getMasterSettings);
      void queryClient.invalidateQueries(queryKeyNames.getAllMasterSettings);
      toast({
        ...successToast,
        duration: 4000,
        title: 'コードを変更しました',
        position: 'bottom',
        variant: 'solid',
      });
    },
  });

  return { mutate: mutateAsync, isLoading };
};
