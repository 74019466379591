import { CustomerDetailInnerOrdersItem } from 'components/paywall/pages/CustomerDetail/CustomerDetailInner/CustomerDetailInnerOrdersItem';
import { CustomerDetailOrder } from 'components/paywall/pages/CustomerList/typed';
import { FC, memo } from 'react';

type Props = {
  orders: CustomerDetailOrder[];
  customerUid: string;
};

export const CustomerDetailInnerOrders: FC<Props> = memo(({ orders, customerUid }) => (
  <>
    {orders.map((order, idx) => (
      <CustomerDetailInnerOrdersItem order={order} customerUid={customerUid} idx={idx} key={`${order.id}_${String(idx)}`} />
    ))}
  </>
));
