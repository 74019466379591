import { ExternalLinkIcon } from '@chakra-ui/icons';
import {
  Box,
  Button,
  DrawerBody,
  DrawerFooter,
  FormControl,
  FormErrorMessage,
  Heading,
  Link,
  RadioGroup,
  Text,
  Textarea,
  VStack,
} from '@chakra-ui/react';
import { RhfCheckbox } from 'admin/components/form/RhfCheckbox';
import { RhfInput } from 'admin/components/form/RhfInput';
import { RhfRadio } from 'admin/components/form/RhfRadio';
import { RhfSelect } from 'admin/components/form/RhfSelect';
import { AttributeFormJsonAccordion } from 'admin/components/idPoolConsumer/attribute/attributeForm/attributeFormJsonAccordion';
import { HelpTooltip } from 'admin/components/ui/helpTooltip';
import { RequiredIcon } from 'admin/components/ui/icon/requiredIcon';
import { LoadingLayer } from 'admin/components/ui/loadingLayer';
import { ColorSet, Colors } from 'admin/define/colors';
import { JSON_PATH_VALUE_TYPE } from 'admin/define/field';
import {
  AttributeFieldType,
  AttributeFieldUiTypes,
  IdPoolConsumerAttributeType,
} from 'admin/types/userPool/attribute';
import {
  IdPoolConsumerAttributeCreateFormType,
  IdPoolConsumerAttributeUpdateFormType,
} from 'admin/types/userPool/attribute/form';
import { UserPool } from 'admin/types/userPool/index';
import { CodeGroupsType } from 'admin/types/userPool/master/index';
import jsonpath from 'jsonpath';
import { ChangeEvent, FC, memo, useCallback, useMemo } from 'react';
import { useFormContext, useFormState } from 'react-hook-form';
import { Link as ReactRouterLink } from 'react-router-dom';
import { toErrMsgList } from 'utils/form';

const JSON_FORMATTER_INDENT = 4;

type Props = {
  isExternalId: boolean;
  isEdit?: boolean;
  fieldUiTypeList: AttributeFieldUiTypes[];
  fieldTypeList: AttributeFieldType[];
  isShowMasterType: boolean;
  isLoading: boolean;
  formId: string;
  onClose: () => void;
  onSubmit: (value: IdPoolConsumerAttributeUpdateFormType) => void;
  codeGroups?: CodeGroupsType;
  userInfoResponse?: UserPool['userInfoResponse'];
  idPoolConsumerId: string;
  isPreset: boolean;
};

export const AttributeEditFormComponent: FC<Props> = memo(
  ({
    isExternalId,
    isEdit,
    fieldUiTypeList,
    fieldTypeList,
    isShowMasterType,
    isLoading,
    formId,
    onClose,
    onSubmit,
    codeGroups,
    userInfoResponse,
    idPoolConsumerId,
    isPreset,
  }: Props) => {
    const { handleSubmit, clearErrors, setValue, getValues, watch } =
      useFormContext<IdPoolConsumerAttributeCreateFormType>();
    const { errors } = useFormState<IdPoolConsumerAttributeCreateFormType>();
    const jsonPathValue = watch('jsonPath');

    const jsonPaseData = useMemo(() => {
      if (!userInfoResponse) {
        return '';
      }
      try {
        const result = jsonpath.query(
          JSON.parse(userInfoResponse ?? ''),
          jsonPathValue ?? '',
        );

        return JSON.stringify(result, null, JSON_FORMATTER_INDENT);
      } catch (error) {
        return userInfoResponse;
      }
    }, [userInfoResponse, jsonPathValue]);

    const handleOnBlur = (e: ChangeEvent<HTMLInputElement>) => {
      if (e.target.value !== '') {
        clearErrors(['displayNameJa', 'displayNameForeign']);
      }
    };

    const uiTypeValue = watch('uiType');

    const handleUiTypeChange = useCallback(
      (e: React.ChangeEvent<HTMLSelectElement>) => {
        setValue('uiType', e.target.value);
        setValue('dataType', '');
        setValue('codeGroup', '');

        clearErrors(['uiType']);
        clearErrors(['dataType']);
        clearErrors(['codeGroup']);
      },
      [setValue, clearErrors],
    );

    return (
      <>
        {isLoading && <LoadingLayer />}
        <DrawerBody pt="16px" pb="16px">
          <form onSubmit={handleSubmit(onSubmit)} id={formId}>
            <VStack spacing={6}>
              <Box w="100%">
                <Heading as="h3" display="flex" size="sm" mb={2}>
                  表示名
                </Heading>
                <Box bg="#F7FAFC" p={4} borderWidth="1px" borderRadius="4px">
                  <Box w="100%">
                    <Heading as="h3" display="flex" fontSize="14px" mb={2}>
                      日本語
                      <RequiredIcon ml="8px" />
                    </Heading>
                    <Box>
                      <FormControl
                        isInvalid={
                          Boolean(errors.displayNameJa?.message) ||
                          toErrMsgList(errors, 'displayNameJa').length > 0
                        }
                      >
                        <Box>
                          <RhfInput<IdPoolConsumerAttributeType>
                            name="displayNameJa"
                            bgColor={Colors.WHITE}
                            placeholder="例: 会社員"
                            onBlur={handleOnBlur}
                          />
                        </Box>

                        {toErrMsgList(errors, 'displayNameJa').map((err) => (
                          <FormErrorMessage key={`displayNameJa${err}`}>
                            {err}
                          </FormErrorMessage>
                        ))}
                      </FormControl>
                    </Box>
                  </Box>
                  <Box w="100%" mt={4}>
                    <Heading as="h3" fontSize="14px" mb={2}>
                      外国語
                    </Heading>
                    <Box>
                      <FormControl
                        isInvalid={
                          Boolean(errors.displayNameForeign?.message) ||
                          toErrMsgList(errors, 'displayNameForeign').length > 0
                        }
                      >
                        <Box>
                          <RhfInput<IdPoolConsumerAttributeType>
                            name="displayNameForeign"
                            bgColor={Colors.WHITE}
                            placeholder="例: Office Worker"
                            onBlur={handleOnBlur}
                          />
                        </Box>
                        {toErrMsgList(errors, 'displayNameForeign').map(
                          (err) => (
                            <FormErrorMessage key={`displayNameForeign${err}`}>
                              {err}
                            </FormErrorMessage>
                          ),
                        )}
                      </FormControl>
                    </Box>
                  </Box>
                </Box>
              </Box>
              <Box w="100%">
                <Heading
                  as="h3"
                  display="flex"
                  alignItems="center"
                  size="sm"
                  mb={2}
                >
                  フィールド名
                  <HelpTooltip label="この項目はデータベース上でのフィールド名です。" />
                  <RequiredIcon ml="8px" />
                </Heading>
                <Box>
                  <FormControl
                    isInvalid={
                      Boolean(errors.fieldName?.message) ||
                      toErrMsgList(errors, 'fieldName').length > 0
                    }
                  >
                    <RhfInput<IdPoolConsumerAttributeType>
                      name="fieldName"
                      placeholder="例：email"
                      bgColor={Colors.WHITE}
                      isDisabled={isEdit}
                    />
                    {toErrMsgList(errors, 'fieldName').map((err) => (
                      <FormErrorMessage key={`fieldName${err}`}>
                        {err}
                      </FormErrorMessage>
                    ))}
                  </FormControl>
                </Box>
              </Box>
              <Box w="100%">
                <Heading
                  as="h3"
                  display="flex"
                  alignItems="center"
                  size="sm"
                  mb={2}
                >
                  UIタイプ
                  <HelpTooltip label="属性の入力方式を指定します。" />
                  <RequiredIcon ml="8px" />
                </Heading>
                <Box w="50%">
                  <FormControl
                    isInvalid={
                      Boolean(errors.uiType?.message) ||
                      toErrMsgList(errors, 'uiType').length > 0
                    }
                  >
                    <RhfSelect<IdPoolConsumerAttributeType>
                      name="uiType"
                      placeholder="選択してください。"
                      bgColor={Colors.WHITE}
                      isDisabled={isEdit}
                      onChange={handleUiTypeChange}
                    >
                      {fieldUiTypeList.map((fieldUiType, idx) => (
                        <option
                          value={fieldUiType.value}
                          key={`field-ui-type-${String(idx)}`}
                        >
                          {fieldUiType.name}
                        </option>
                      ))}
                    </RhfSelect>
                    {toErrMsgList(errors, 'uiType').map((err) => (
                      <FormErrorMessage key={`uiType${err}`}>
                        {err}
                      </FormErrorMessage>
                    ))}
                  </FormControl>
                </Box>
              </Box>
              <Box w="100%">
                <Heading as="h3" display="flex" alignItems="center" size="sm">
                  データ型
                  <HelpTooltip label="属性のデータの型を設定します。" />
                  <RequiredIcon ml="8px" />
                </Heading>
                {uiTypeValue === '' && (
                  <Box w="50%" mt={1}>
                    <Text color={Colors.GRAY_00} fontSize="12px">
                      「UIタイプ」を選択することで、「データ型」の選択が可能になります。
                    </Text>
                  </Box>
                )}
                <Box w="50%" mt={2}>
                  <FormControl
                    isInvalid={
                      Boolean(errors.dataType?.message) ||
                      toErrMsgList(errors, 'dataType').length > 0
                    }
                  >
                    <RhfSelect<IdPoolConsumerAttributeType>
                      name="dataType"
                      placeholder="選択してください。"
                      bgColor={Colors.WHITE}
                      isDisabled={isEdit || uiTypeValue === ''}
                    >
                      {fieldTypeList.map((fieldType, idx) => (
                        <option
                          value={fieldType.value}
                          key={`field-type^${String(idx)}`}
                        >
                          {fieldType.name}
                        </option>
                      ))}
                    </RhfSelect>
                    {toErrMsgList(errors, 'dataType').map((err) => (
                      <FormErrorMessage key={`dataType${err}`}>
                        {err}
                      </FormErrorMessage>
                    ))}
                  </FormControl>
                </Box>
              </Box>
              {isShowMasterType && (
                <Box w="100%">
                  <Heading
                    as="h3"
                    display="flex"
                    alignItems="center"
                    size="sm"
                    mb={2}
                  >
                    選択肢マスタ
                    <HelpTooltip label="選択肢マスタのみ、設定した属性コードとの紐づけを行います。コード登録があるマスタのみ表示されます。" />
                    <RequiredIcon ml="8px" />
                  </Heading>
                  <Box w="50%">
                    <FormControl
                      isInvalid={
                        Boolean(errors.codeGroup?.message) ||
                        toErrMsgList(errors, 'codeGroup').length > 0
                      }
                    >
                      <RhfSelect<IdPoolConsumerAttributeType>
                        name="codeGroup"
                        placeholder="選択してください。"
                        bgColor={Colors.WHITE}
                        isDisabled={isEdit}
                        onChange={(e) => {
                          setValue('codeGroup', e.target.value);
                        }}
                      >
                        {codeGroups?.map((codeGroup) => (
                          <option value={codeGroup.id} key={codeGroup.id}>
                            {codeGroup.displayNameJa}
                          </option>
                        ))}
                      </RhfSelect>
                      {toErrMsgList(errors, 'codeGroup').map((err) => (
                        <FormErrorMessage key={`codeGroup${err}`}>
                          {err}
                        </FormErrorMessage>
                      ))}
                    </FormControl>
                  </Box>
                </Box>
              )}
              <Box w="100%">
                <Heading as="h3" size="sm" mb={2}>
                  閲覧制御
                </Heading>
                <Box>
                  <RhfCheckbox<IdPoolConsumerAttributeType>
                    name="isPersonalInformation"
                    isDisabled={isPreset}
                  >
                    個人情報として扱う
                    <HelpTooltip label="「個人情報として扱う」に設定すると、利用者の権限に基づき閲覧制限が行われます。" />
                  </RhfCheckbox>
                </Box>
              </Box>
              <Box w="100%">
                <Heading
                  as="h3"
                  display="flex"
                  alignItems="center"
                  size="sm"
                  mb={2}
                >
                  並び順
                </Heading>
                <Box>
                  <FormControl
                    isInvalid={
                      Boolean(errors.displayOrder?.message) ||
                      toErrMsgList(errors, 'displayOrder').length > 0
                    }
                  >
                    <RhfInput<IdPoolConsumerAttributeType>
                      name="displayOrder"
                      bgColor={Colors.WHITE}
                    />
                    {toErrMsgList(errors, 'displayOrder').map((err) => (
                      <FormErrorMessage key={`displayOrder${err}`}>
                        {err}
                      </FormErrorMessage>
                    ))}
                  </FormControl>
                </Box>
              </Box>
              {/* 以下は外部ID時のみ表示を行う */}
              {isExternalId && (
                <>
                  <Box w="100%">
                    <FormControl
                      isInvalid={
                        Boolean(errors.jsonPath?.message) ||
                        toErrMsgList(errors, 'jsonPath').length > 0
                      }
                    >
                      <Heading as="h3" display="flex" size="sm" mb="6px">
                        ユーザー情報レスポンスの参照フィールド（JSONパス）
                      </Heading>
                      <Box>
                        <AttributeFormJsonAccordion
                          idPoolConsumerId={idPoolConsumerId}
                        />
                        <Text
                          fontSize="12px"
                          fontWeight="400"
                          color={Colors.GRAY_00}
                        >
                          入力したパスによって絞り込まれた結果が表示されます
                        </Text>
                        <RhfInput<IdPoolConsumerAttributeType>
                          name="jsonPath"
                          bgColor={Colors.WHITE}
                        />
                      </Box>
                      {toErrMsgList(errors, 'jsonPath').map((err) => (
                        <FormErrorMessage key={`jsonPath${err}`}>
                          {err}
                        </FormErrorMessage>
                      ))}
                    </FormControl>
                  </Box>
                  <Box w="100%">
                    <Box display="flex" justifyContent="space-between" mb="8px">
                      <Heading as="h3" size="sm" mb={2}>
                        参照されているフィールド
                      </Heading>
                      <Link
                        as={ReactRouterLink}
                        to={`/admin/idPoolConsumer/${idPoolConsumerId}/jsonPath/`}
                        color={Colors.BLUE_20}
                        textDecoration="underline"
                        isExternal
                        mb="2px"
                      >
                        JSONパス解析ツール <ExternalLinkIcon mx="2px" />
                      </Link>
                    </Box>
                    <Box>
                      <Textarea
                        value={jsonPaseData}
                        borderColor="gray.200"
                        bgColor={Colors.GRAY_50}
                        size="sm"
                        resize="none"
                        borderRadius="md"
                        rows={12}
                        readOnly
                      />
                    </Box>
                  </Box>

                  {isShowMasterType && (
                    <Box w="100%">
                      <Heading as="h3" size="sm" mb={2}>
                        参照フィールドの値が選択肢マスタで登録されているコード値のどれに当たるか
                      </Heading>
                      <Box>
                        <FormControl
                          isInvalid={
                            Boolean(errors?.jsonPathValueType?.message) ||
                            toErrMsgList(errors, 'jsonPathValueType').length > 0
                          }
                        >
                          <RadioGroup
                            defaultValue={String(
                              getValues('jsonPathValueType') || '2',
                            )}
                          >
                            <Box display="flex" flexDirection="column">
                              <RhfRadio<IdPoolConsumerAttributeType>
                                name="jsonPathValueType"
                                value={JSON_PATH_VALUE_TYPE.displayName}
                              >
                                表示名
                              </RhfRadio>
                              <RhfRadio<IdPoolConsumerAttributeType>
                                name="jsonPathValueType"
                                value={JSON_PATH_VALUE_TYPE.codeValue}
                              >
                                コード値
                              </RhfRadio>
                            </Box>
                          </RadioGroup>
                          {toErrMsgList(errors, 'jsonPathValueType').map(
                            (err) => (
                              <FormErrorMessage key={`jsonPathValueType${err}`}>
                                {err}
                              </FormErrorMessage>
                            ),
                          )}
                        </FormControl>
                      </Box>
                    </Box>
                  )}
                </>
              )}
            </VStack>
          </form>
        </DrawerBody>

        <DrawerFooter borderWidth="1px">
          <Button
            variant="outline"
            mr={3}
            p="16px 24px"
            w="120px"
            onClick={onClose}
            {...ColorSet.Default}
          >
            キャンセル
          </Button>
          <Button
            type="submit"
            form={formId}
            p="16px 24px"
            w="120px"
            colorScheme="primary"
          >
            保存
          </Button>
        </DrawerFooter>
      </>
    );
  },
);

AttributeEditFormComponent.defaultProps = {
  isEdit: false,
  codeGroups: [],
  userInfoResponse: undefined,
};
