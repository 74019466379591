import { useCustomToast } from 'hooks/useCustomToast';
import { successToast, errorToast } from 'utils/toast';
import { useTableId } from 'hooks/contentsdb/useTableId';
import { useUserTenantId } from 'hooks/user/useUserTenantId';
import { useUploadHistoryCacheClear } from 'hooks/contentsdb/useUploadHistoryCacheClear';
import { createUploadData } from 'api/contents/createUploadData';
import { fileUpload } from 'api/contents/fileUpload';
import { CONTENTSDB_FILE_UPLOAD_TYPE } from 'define';

export const useUploadRequest = (
  uploadType: typeof CONTENTSDB_FILE_UPLOAD_TYPE[number],
): ((files: File[]) => void) => {
  const toast = useCustomToast();
  const tableId = useTableId();
  const tenantId = useUserTenantId();
  const clearCache = useUploadHistoryCacheClear({ tableId, tenantId });

  const upload = (file: File): void => {
    if (!tenantId) {
      toast({
        ...errorToast,
        title: 'アップロードエラー',
        description:
          '認証エラーが発生しました。一度ログアウト頂き再度ログインし操作ください',
      });

      return;
    }

    if (!tableId) {
      toast({
        ...errorToast,
        title: 'アップロードエラー',
        description:
          'アップロード対象のテーブル情報が取得できません。画面の再読み込みをお願いします',
      });

      return;
    }

    if (!file.size) {
      toast({
        ...errorToast,
        title: 'アップロードエラー',
        description:
          'ファイルサイズが 0 です。内容記載の上アップロードしてください',
      });

      return;
    }

    const ext = file.name.split('.').pop();

    if (ext !== 'csv' && ext !== 'tsv') {
      toast({
        ...errorToast,
        title: 'アップロードエラー',
        description: 'CSV 又は TSVファイルのみアップロード可能です',
      });

      return;
    }

    void (async () => {
      try {
        const uploadData = await createUploadData({
          fileName: file.name,
          uploadType,
          tableId,
          tenantId,
        });

        // ファイルサイズが大きいと1分以上待つので
        // バックグランドで処理
        void fileUpload({ file, uploadId: uploadData.id, tableId, tenantId });
        toast({
          ...successToast,
          duration: 5000,
          title: 'アップロードを受け付けました',
          description: 'アップロード状況は履歴より確認ください',
        });
        clearCache();
      } catch (e) {
        const err = e as Error;
        toast({
          ...errorToast,
          title: 'アップロードエラー',
          description: err.message,
        });
      }
    })();
  };

  const uploads = (files: File[]) => {
    files.forEach((f) => upload(f));
  };

  return uploads;
};
