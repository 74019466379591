import { VFC, memo, useRef, useState } from 'react';
import { useForm, FormProvider } from 'react-hook-form';
import { Stack, Button, Box, Text } from '@chakra-ui/react';
import { PasswordResetForm } from 'api/user/types';
import { usePasswordResetId } from 'hooks/user/usePasswordResetId';
import { AlertBar } from 'components/common/atoms';
import { InputPassword } from 'components/user/atoms/InputPassword';

/**
 * パスワード入力用バリデーション
 */
const passwordValidateAttr = {
  required: 'パスワードを入力してください',
  minLength: {
    value: 8,
    message: '8文字以上で入力してください',
  },
  maxLength: {
    value: 32,
    message: '32文字以下で入力してください',
  },
};

export const ResetPasswordForm: VFC = memo(() => {
  const [globalErrors, setGlobalErrors] = useState<string[]>([]);
  const password = useRef({});

  const methods = useForm<PasswordResetForm>({
    mode: 'onBlur',
  });
  const {
    setError,
    formState: { errors },
    watch,
    handleSubmit,
  } = methods;
  password.current = watch('password', '');
  const { onSubmit, isLoading } = usePasswordResetId({
    setError,
    setGlobalErrors,
  });

  /**
   * パスワード再入力用バリデーション
   */
  const password2ValidateAttr = {
    validate: (val: string) =>
      val === password.current || 'パスワードが一致しません',
  };

  return (
    <>
      <Box>
        <Text fontSize="xl" align="center">
          パスワード再設定
        </Text>
      </Box>
      <Box minW={{ base: '90%', md: '368px' }} mt={4}>
        {globalErrors.map((err, idx) => (
          <AlertBar
            key={`global-err-idx${String(idx)}`}
            message={err}
            status="error"
            mb={4}
          />
        ))}
        <FormProvider {...methods}>
          <form onSubmit={handleSubmit((data) => onSubmit(data))}>
            <Stack
              spacing={8}
              p="1rem"
              backgroundColor="whiteAlpha.900"
              boxShadow="md"
            >
              <Stack spacing={8}>
                {/* パスワード */}
                <InputPassword
                  name="password"
                  placeholder="新しいパスワード"
                  attr={passwordValidateAttr}
                />

                {/* パスワード 再度入力 */}
                <InputPassword
                  name="passwordConfirm"
                  placeholder="パスワード再入力"
                  attr={password2ValidateAttr}
                />
              </Stack>
              <Button
                variant="primary"
                type="submit"
                disabled={isLoading || Object.keys(errors).length > 0}
                isLoading={isLoading}
              >
                登録
              </Button>
            </Stack>
          </form>
        </FormProvider>
      </Box>
    </>
  );
});
