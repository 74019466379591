import {
  AlertDialog,
  AlertDialogBody,
  AlertDialogCloseButton,
  AlertDialogContent,
  AlertDialogFooter,
  AlertDialogHeader,
  AlertDialogOverlay,
  Box,
  ButtonProps,
  Flex,
  Spacer
} from '@chakra-ui/react';
import { Button } from 'components/paywall/atoms/Button';
import { usePaywallUtil } from 'components/paywall/hooks/usePaywallUtil';
import { memo, useRef, VFC } from 'react';
import { AiFillInfoCircle } from 'react-icons/ai';

type Props = {
  icon?: React.ReactElement;
  showIcon?: boolean;
  title?: React.ReactNode;
  children: React.ReactNode;
  disabled?: boolean;
  isLoading?: boolean;
  submitButtonName?: string;
  cancelButtonName?: string;
  cancelButtonColorScheme?: ButtonProps['colorScheme'];
  submitButtonColorScheme?: ButtonProps['colorScheme'];
  isCentered?: boolean;
  size?: string;
  isOpen: boolean;
  onClose: () => void;
  onSubmit: () => void;
};
/**
 * @see 汎用ダイアログ
 * @param icon タイトル横のアイコン
 * @param showIcon アイコンを表示させるかどうか
 * @param title ダイアログのタイトル
 * @param children ダイアログの中身
 * @param disabled ボタン実行の可否
 * @param isLoading ボタンLoading
 * @param submitButtonName 実行ボタンの名前
 * @param cancelButtonName 実行ボタンの名前
 * @param isCentered ダイアログのセンタリングの有無
 * @param size ダイアログの幅サイズ
 * @param isOpen ダイアログの開閉状態
 * @param onClose ダイアログを閉じるイベントハンドラ
 * @param onSubmit ダイアログのボタンを実行するイベントハンドラ
 */
export const Dialog: VFC<Props> = memo(
  ({
    icon,
    title,
    children,
    disabled,
    isLoading,
    submitButtonName = '保存',
    cancelButtonName = 'キャンセル',
    submitButtonColorScheme = 'blue',
    cancelButtonColorScheme = 'gray',
    isOpen,
    showIcon = false,
    isCentered = true,
    size = 'md',
    onClose,
    onSubmit,
  }) => {
    const cancelRef = useRef(null);
    const { isOperationAuth } = usePaywallUtil();

    return (
      <>
        <AlertDialog
          isOpen={isOpen}
          leastDestructiveRef={cancelRef}
          onClose={onClose}
          isCentered={isCentered}
          size={size}
          closeOnOverlayClick={false}
          closeOnEsc={false}
        >
          <AlertDialogOverlay>
            <AlertDialogContent>
              <AlertDialogHeader fontSize="lg" fontWeight="bold">
                <Flex alignItems="center" style={{ color: '#444', gap: 5 }}>
                  {showIcon && icon ? (
                    <Box>{icon}</Box>
                  ) : (
                    <Box style={{ height: 24 }}>
                      {title && showIcon && (
                        <AiFillInfoCircle style={{ width: 24, height: 24 }} />
                      )}
                    </Box>
                  )}
                  {title}
                  <Box>
                    <AlertDialogCloseButton />
                  </Box>
                </Flex>
              </AlertDialogHeader>

              <AlertDialogBody maxH="400px" overflowY="auto">{children}</AlertDialogBody>

              <AlertDialogFooter>
                {onSubmit && (
                  <Flex>
                    <Button
                      mode="cancel"
                      colorScheme={cancelButtonColorScheme}
                      onClick={onClose}
                    >
                      {cancelButtonName}
                    </Button>
                    <Spacer width={3} />
                    <Button
                      disabled={disabled || isLoading || !isOperationAuth()}
                      colorScheme={submitButtonColorScheme}
                      onClick={onSubmit}
                      isLoading={isLoading}
                    >
                      {submitButtonName}
                    </Button>
                  </Flex>
                )}
              </AlertDialogFooter>
            </AlertDialogContent>
          </AlertDialogOverlay>
        </AlertDialog>
      </>
    );
  },
);
