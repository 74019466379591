import {
  DrawerCloseButton,
  DrawerContent,
  DrawerHeader,
  DrawerOverlay
} from '@chakra-ui/react';
import { withSuspenseAndErrorBoundary } from 'admin/components/Error/WithErrorBoundary';
import { useUserDetail } from 'admin/hooks/user/useUserDetail';
import {
  ErrorContents, LoadingSpinner
} from 'components/common/atoms';
import { FC, memo } from 'react';
import { UsersEditForm } from '../usersEditForm';

type UsersEditDrawerProps = {
  onClose: () => void;
  userId: string;
};

const fetchUserEditDrawer: FC<UsersEditDrawerProps> = memo(
  ({ userId, onClose }) => {
    const { data: user } = useUserDetail(userId);

    if (!user) {
      return null;
    }

    return (
      <UsersEditForm userId={userId} user={user} onClose={onClose} />
    )
  }
);

export const FetchUserEditDrawer = withSuspenseAndErrorBoundary(fetchUserEditDrawer, {
  ErrorComponent: <ErrorContents name="ユーザー情報詳細" />,
  LoadingComponent: <LoadingSpinner />
});

export const UsersEditDrawer: FC<UsersEditDrawerProps> = memo(
  ({ userId, onClose }) => (
    <>
      <DrawerOverlay />
      <DrawerContent>
        <DrawerHeader borderBottomWidth="1px">ユーザー情報編集</DrawerHeader>
        <FetchUserEditDrawer userId={userId} onClose={onClose} />
        <DrawerCloseButton />
      </DrawerContent>
    </>
  ),
);
