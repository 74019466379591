import {
  Box,
  Button,
  DrawerBody,
  DrawerFooter, FormControl,
  FormErrorMessage, Text
} from '@chakra-ui/react';
import { RhfTextarea } from 'admin/components/form/RhfTextarea';
import { LoadingLayer } from 'admin/components/ui/loadingLayer';
import { Colors, ColorSet } from 'admin/define/colors';
import { UserPool } from 'admin/types/userPool';
import { IdPoolConsumerUserInfoFormType } from 'admin/types/userPool/userInfo/form';
import { FC, memo, useMemo } from 'react';
import { useFormContext, useFormState } from 'react-hook-form';
import { toErrMsgList } from 'utils/form';

type Props = {
  formId: string;
  isLoading: boolean;
  userInfoDetail: UserPool | undefined;
  onClose: () => void;
  onSubmit: (value: IdPoolConsumerUserInfoFormType) => void;
};

export const UserInfoFormComponent: FC<Props> = memo(({ formId, isLoading, userInfoDetail, onClose, onSubmit }: Props) => {
  const { handleSubmit } = useFormContext<IdPoolConsumerUserInfoFormType>();
  const { errors } = useFormState<IdPoolConsumerUserInfoFormType>();

  const isEdit = useMemo<boolean>(() => {
    if (!userInfoDetail?.userInfoResponse) return false;

    return true;
  }, [userInfoDetail?.userInfoResponse]);

  return (
    <>
      {isLoading && <LoadingLayer />}
      <DrawerBody pt="16px" pb="16px">
        <form onSubmit={handleSubmit(onSubmit)} id={formId} >
          <Box w="100%">
            <Text as="h3" fontWeight="700" size="sm" mb="4px">
              ユーザー情報のレスポンスボディを以下に貼り付けてください。
            </Text>
            <FormControl isInvalid={Boolean(errors.userInfoResponse)}>
              <Box>
                <RhfTextarea
                  name="userInfoResponse"
                  border="1px solid red"
                  borderRadius="md"
                  bgColor={Colors.WHITE}
                  size="sm"
                  rows={20}
                />
              </Box>
              {toErrMsgList(errors, 'userInfoResponse').map((err) => (
                <FormErrorMessage key={`userInfoResponse${err}`}>
                  {err}
                </FormErrorMessage>
              ))}
            </FormControl>
          </Box>
        </form>
      </DrawerBody>
      <DrawerFooter borderWidth="1px">
        <Button
          variant="outline"
          mr={3}
          onClick={onClose}
          p="16px 24px"
          w="120px"
          {...ColorSet.Default}
        >
          キャンセル
        </Button>
        <Button
          type="submit"
          form={formId}
          colorScheme="primary"
          p="16px 24px"
          w="120px"
        >
          {isEdit ? '保存' : '設定'}
        </Button>
      </DrawerFooter>
    </>
  )
});
