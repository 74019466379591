import { Options } from 'ky';
import { getLogLevel } from 'utils/requests';
import { CustomError } from './CustomError';

type Cause = {
  path: string;
  options?: Options;
  status: number;
  logLevel?: 'info' | 'warning' | 'error';
  title?: string;
  response?: any;
};

// passwordとlogin/mailFormでエラーが発生した場合はoptionからは外すように設定する必要がある
export class SystemError extends CustomError {
  public cause: Cause;
  public logLevel: 'info' | 'warning' | 'error';
  public title?: string;

  constructor(message: string, cause: Cause) {
    const logLevel = cause.logLevel
      ? cause.logLevel
      : getLogLevel(cause.status);
    super(message);
    this.name = this.constructor.name;
    this.cause = cause;
    this.logLevel = logLevel;
    this.title = cause.title;
  }
}
