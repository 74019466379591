import { useMemo } from 'react';
import { useParams } from 'react-router-dom';

export const useIdPoolConsumerId = () => {
  const params = useParams();

  const idPoolConsumerId = useMemo(
    () => params.idPoolConsumerId ?? '',
    [params],
  );

  return idPoolConsumerId;
};
