export type AuthConfigType = {
  ASSISTANT: boolean,
  STAFF: boolean,
  DIRECTOR: boolean,
  MANAGER: boolean,
  OWNER: boolean
}

// 各画面表示可否
export const PaywallAuth: {[key: string]: AuthConfigType} = {
  // top
  top: {
    ASSISTANT: false,
    STAFF: false,
    DIRECTOR: false,
    MANAGER: true,
    OWNER: true
  },
  // dashboard
  dashboard: {
    ASSISTANT: false,
    STAFF: false,
    DIRECTOR: false,
    MANAGER: true,
    OWNER: true
  },
  // サブスクリプション設定
  wallList: {
    ASSISTANT: false,
    STAFF: false,
    DIRECTOR: false,
    MANAGER: true,
    OWNER: true
  },
  // 単品ウォール設定
  itemList: {
    ASSISTANT: false,
    STAFF: false,
    DIRECTOR: false,
    MANAGER: true,
    OWNER: true
  },
  // 会員限定ウォール設定
  membersOnlyWallSetting: {
    ASSISTANT: false,
    STAFF: false,
    DIRECTOR: false,
    MANAGER: true,
    OWNER: true
  },
  // メール設定
  mail: {
    ASSISTANT: false,
    STAFF: false,
    DIRECTOR: false,
    MANAGER: true,
    OWNER: true
  },
  // 顧客一覧
  customerList: {
    ASSISTANT: false,
    STAFF: false,
    DIRECTOR: false,
    MANAGER: true,
    OWNER: true
  },
  // 決済一覧
  orderList: {
    ASSISTANT: false,
    STAFF: false,
    DIRECTOR: false,
    MANAGER: true,
    OWNER: true
  },
  // サブスクリプション注文一覧
  subscriptionList: {
    ASSISTANT: false,
    STAFF: false,
    DIRECTOR: false,
    MANAGER: true,
    OWNER: true
  },
  // 規約設定
  terms: {
    ASSISTANT: false,
    STAFF: false,
    DIRECTOR: false,
    MANAGER: true,
    OWNER: true
  }
}
