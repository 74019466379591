import { withSuspenseAndErrorBoundary } from 'admin/components/Error/WithErrorBoundary';
import {
  UserAttrRequiredFormComponent,
  USER_ATTR_GROUP_REQUIRED_FROM_TYPE,
} from 'admin/components/service/attribute/attributeForm/userAttrRequiredForm/UserAttrRequiredFormComponent';
import { LoadingLayer } from 'admin/components/ui/loadingLayer';
import { useChangeServiceUserAttrRequiredGroup } from 'admin/hooks/service/attribute/useChangeServiceUserAttrRequiredGroup';
import {
  ServiceAttributeGroupType,
  UserPoolChildAttributeType,
} from 'admin/types/service/attribute';
import { FC, memo, useCallback } from 'react';

export type Props = {
  target: ServiceAttributeGroupType | null;
  id: UserPoolChildAttributeType['id'];
  onClose: () => void;
};

export const userAttrRequiredForm: FC<Props> = memo(({ onClose, target }) => {
  const { mutate, isLoading } = useChangeServiceUserAttrRequiredGroup(target);

  const onSubmit = useCallback(async () => {
    await mutate();
    onClose();
  }, [mutate, onClose]);

  return (
    <>
      {isLoading && <LoadingLayer />}
      <UserAttrRequiredFormComponent
        userAttrRequiredFormType={USER_ATTR_GROUP_REQUIRED_FROM_TYPE}
        isLoading={isLoading}
        onClose={onClose}
        onSubmit={onSubmit}
        target={target}
      />
    </>
  );
});

export const UserAttrRequiredForm =
  withSuspenseAndErrorBoundary(userAttrRequiredForm);
