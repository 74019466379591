import { getServiceAttributeGroups } from 'admin/api/service/attribute/getServiceAttributeGroups';
import { CustomError } from 'admin/error/CustomError';
import { useUserPoolChildId } from 'admin/hooks/useUserPoolChildId';
import { convertToPage } from 'admin/schema/page';
import { ServiceAttributeGroupsType } from 'admin/types/service/attribute';
import { DataWithPage, Page } from 'api/common/types';
import { logger } from 'api/logger';
import { useCustomToast } from 'hooks/useCustomToast';
import { useQuery } from 'react-query';
import { errorToast } from 'utils/toast';
import { querySerivceAttributeKey } from './querySerivceAttributeKey';

export const useServiceAttributeGroups = (
  page: Page['currentPage'],
  perPage: Page['perPage'],
) => {
  const userPoolChildId = useUserPoolChildId();
  const toast = useCustomToast();
  const { data } = useQuery({
    queryKey: querySerivceAttributeKey.getSerivceAttributeGroups(
      userPoolChildId,
      page,
      perPage,
    ),
    queryFn: () =>
      getServiceAttributeGroups({
        userPoolChildId,
        options: {
          searchParams: {
            page,
            perPage,
          },
        },
      }),
    onError: async (error) => {
      if (error instanceof CustomError) {
        await logger({
          loglevel: error.logLevel,
          data: JSON.stringify(error.cause),
          message: error.message,
        });
      }
      toast({
        ...errorToast,
        duration: 4000,
        title: '属性グループ一覧の取得に失敗しました',
        position: 'bottom',
        variant: 'solid',
      });
    },
  });

  return {
    data: data?.results,
    page: convertToPage<DataWithPage<ServiceAttributeGroupsType>>(data),
  };
};
