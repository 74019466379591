import { VFC, Suspense } from 'react';
import {
  Box,
  Table,
  Thead,
  Tbody,
  Tr,
  Th,
  Td,
  Heading,
  Text,
} from '@chakra-ui/react';
import { AiOutlineCheck } from 'react-icons/ai';

import { LoadingSkeleton } from 'components/common/atoms';
import { SCHEMA_DATA_TYPE } from 'api/contents/types';
import ErrorBoundary from 'api/ErrorBoundary';
import { useUserTenantId } from 'hooks/user/useUserTenantId';
import { useTableId } from 'hooks/contentsdb/useTableId';
import { useTableData } from 'hooks/contentsdb/useTableData';
import { useErrorBoundaryReset } from 'hooks/useErrorBoundaryReset';

const TableDetailInner: VFC = () => {
  const tableId = useTableId();
  const tenantId = useUserTenantId();
  const contentsTable = useTableData({ tenantId, tblId: tableId });

  if (!contentsTable) {
    return <></>;
  }

  return (
    <Box>
      <Table variant="simple">
        <Thead>
          <Tr>
            <Th fontSize="sm" w={24}>
              主キー
            </Th>
            <Th fontSize="sm">カラム</Th>
            <Th fontSize="sm" w={48}>
              データ型
            </Th>
            <Th fontSize="sm">表示名</Th>
          </Tr>
        </Thead>
        <Tbody>
          {contentsTable.tblSchema.map((schema, idx) => (
            <Tr key={`tbl-schema-row-${String(idx)}`}>
              <Td>{schema.pk && <AiOutlineCheck />}</Td>
              <Td>{schema.column}</Td>
              <Td>
                {
                  SCHEMA_DATA_TYPE.find((t) => t.value === schema.dataType)
                    ?.label
                }
              </Td>
              <Td>{schema.displayName}</Td>
            </Tr>
          ))}
        </Tbody>
      </Table>
      <Box>
        {contentsTable.memo ? (
          <Box bg="gray.50" mt={8} p={4} borderRadius={8} boxShadow="sm">
            <Heading as="h6" size="xs">
              <Text as="span">メモ</Text>
            </Heading>
            <Box mt={4}>
              {contentsTable.memo.split('\n').map((txt, idx) => (
                <Text key={`memo-${String(idx)}`}>{txt}</Text>
              ))}
            </Box>
          </Box>
        ) : (
          <></>
        )}
      </Box>
    </Box>
  );
};

export const TableDetail: VFC = () => {
  const { ebKey, onError } = useErrorBoundaryReset();

  return (
    <ErrorBoundary onError={onError} key={ebKey.current}>
      <Suspense fallback={<LoadingSkeleton />}>
        <Box m={4}>
          <TableDetailInner />
        </Box>
      </Suspense>
    </ErrorBoundary>
  );
};
