/**
 *フォーム一覧画面、またフォーム編集画面から表示したプレビュー時のモーダル
 *@packageDocumentation
 *
 */

import { FC, memo, useState } from 'react';
import { SubmitHandler, useForm } from 'react-hook-form';
import {
  Input,
  Button,
  Modal,
  ModalOverlay,
  ModalContent,
  ModalHeader,
  ModalFooter,
  ModalBody,
  ModalCloseButton,
  Select,
  Stack,
  Radio,
  RadioGroup,
  Box,
  FormErrorMessage,
  FormControl,
  Text,
} from '@chakra-ui/react';
import { useUserTenantId } from 'hooks/user/useUserTenantId';
import { useWebUrls } from 'hooks/tenant/useWebUrls';

type Props = {
  isOpen: boolean;
  onClose: () => void;
  enqueteFormId: string;
  previewCode: string;
};

const radioNamePattern = ['input', 'select'] as const;

type RadioSelectUrlType = typeof radioNamePattern[number];

type FormType = Record<string, 'selectWebUrl' | 'inputWebUrl'>;

const sentence = {
  MODAL_HEADER: 'プレビュー設定',
  SELECT: {
    RADIO_CAPTION: '登録済みのWebページ上でプレビュー',
    PLACEHOLDER: 'プルダウンからお選び下さい',
  },
  INPUT: {
    RADIO_CAPTION: '任意のWebページURLを入力してプレビュー',
    PLACEHOLDER: 'フォーム表示用タグを設置したページのURLを入力してください',
  },
  CLOSE_BTN_CAPTION: '閉じる',
  PREVIEW_BTN_CAPTION: 'プレビュー',
} as const;

export const EnquetePreviewModal: FC<Props> = memo(
  ({ isOpen, onClose, enqueteFormId, previewCode }: Props) => {
    const tenantId = useUserTenantId();
    const websiteList = useWebUrls({ tenantId });

    const [radio, setRadio] = useState<RadioSelectUrlType>('select');

    const handleRadioChange = (nextValue: string) => {
      setRadio(nextValue as RadioSelectUrlType);
    };

    const {
      handleSubmit,
      register,
      clearErrors,
      formState: { errors, isSubmitting },
    } = useForm<FormType>({ mode: 'onSubmit' });

    const isSelectWebUrlInvalid = Boolean(errors.selectWebUrl);
    const isInputWebUrlInvalid = Boolean(errors.inputWebUrl);

    const element = ({ value }: { value: RadioSelectUrlType }) => {
      const reverseDict = {
        select: 'input',
        input: 'select',
      };
      const webUrlKey = `${value}WebUrl`;
      const handleClick = () => {
        clearErrors(`${reverseDict[value]}WebUrl`);
      };
      // 強制的に大文字に変換した型定義を使用
      const forceConvertUpperCase =
        value.toUpperCase() as Uppercase<RadioSelectUrlType>;

      return {
        radio: (
          <Radio size="md" value={value} onClick={handleClick}>
            <Text onClick={handleClick}>
              {sentence[forceConvertUpperCase].RADIO_CAPTION}
            </Text>
          </Radio>
        ),
        errMsg: (
          <FormErrorMessage fontSize={12}>
            {errors[webUrlKey] && errors[webUrlKey].message}
          </FormErrorMessage>
        ),
      };
    };

    const onSubmit: SubmitHandler<FormType> = (v) => {
      const dict = {
        select: v.selectWebUrl,
        input: v.inputWebUrl,
      };
      const redirectUrl = dict[radio];
      const conjunction = redirectUrl.includes('?') ? '&' : '?';
      window.open(
        `${redirectUrl}${conjunction}uecfcode=${enqueteFormId}&uec_preview_code=${previewCode}`,
        '_blank',
      );
      onClose();
    };

    return (
      <Modal isOpen={isOpen} onClose={onClose} isCentered>
        <ModalOverlay />
        <ModalContent as="form" onSubmit={handleSubmit(onSubmit)}>
          <ModalHeader>{sentence.MODAL_HEADER}</ModalHeader>
          <ModalBody>
            <RadioGroup
              name="selectInputMethod"
              value={radio}
              onChange={handleRadioChange}
            >
              <Stack spacing={5} direction="column">
                <Box>
                  {element({ value: 'select' }).radio}
                  <FormControl isInvalid={isSelectWebUrlInvalid}>
                    <Select
                      fontSize={12.5}
                      isInvalid={isSelectWebUrlInvalid}
                      placeholder={sentence.SELECT.PLACEHOLDER}
                      {...register('selectWebUrl', {
                        required: {
                          value: radio === 'select',
                          message: '選択して下さい',
                        },
                        disabled: radio !== 'select',
                      })}
                    >
                      {websiteList?.map((url) => (
                        <option value={url} key={url}>{url}</option>
                      ))}
                    </Select>
                    {element({ value: 'select' }).errMsg}
                  </FormControl>
                </Box>

                <Box>
                  {element({ value: 'input' }).radio}
                  <FormControl isInvalid={isInputWebUrlInvalid}>
                    <Input
                      isInvalid={isInputWebUrlInvalid}
                      fontSize={12.5}
                      type="url"
                      placeholder={sentence.INPUT.PLACEHOLDER}
                      {...register('inputWebUrl', {
                        required: {
                          value: radio === 'input',
                          message: 'URLを入力して下さい',
                        },
                        pattern: {
                          value:
                            /^https?:\/\/[\w/:%#\\$&\\?\\(\\)~\\.=\\+\\-]+$/,
                          message: 'URLが正しくありません',
                        },
                        disabled: radio !== 'input',
                      })}
                    />
                    {element({ value: 'input' }).errMsg}
                  </FormControl>
                </Box>
              </Stack>
            </RadioGroup>
          </ModalBody>
          <ModalFooter>
            <Button variant="secondary" type="button" onClick={onClose}>
              {sentence.CLOSE_BTN_CAPTION}
            </Button>
            <Button
              isLoading={isSubmitting}
              variant="primary"
              ml={3}
              type="submit"
            >
              {sentence.PREVIEW_BTN_CAPTION}
            </Button>
          </ModalFooter>
          <ModalCloseButton />
        </ModalContent>
      </Modal>
    );
  },
);
