import { getEnqueteUserAttributes } from 'api/enquete/getEnqueteUserAttributes';
import { UserAttributes } from 'api/enquete/types';
import { useCustomToast } from 'hooks/useCustomToast';
import { useUserTenantId } from 'hooks/user/useUserTenantId';
import { useQuery } from 'react-query';
import { queryEnqueteKey } from './queryEnqueteKey';

const defaultValues: UserAttributes = [
  {
    id: '',
    codeGroup: null,
    displayNameJa: '',
    displayNameForeign: '',
    fieldName: '',
    dataType: '',
    uiType: '',
    isPersonalInformation: false,
    jsonPath: null,
    displayOrder: 0,
    isAutoAppendToChild: false,
    userPool: '',
  },
];

/**
 * ユーザー定義属性取得 hooks
 */
export const useEnqueteUserAttributes = ({
  enabled = true,
}: {
  enabled?: boolean;
}): UserAttributes => {
  const toast = useCustomToast();
  const tenantId = useUserTenantId();

  const response = useQuery(
    queryEnqueteKey.getUserAttributes({ tenantId }),
    () => getEnqueteUserAttributes(tenantId),
    {
      enabled,
      cacheTime: 0,
      onError: (err: unknown) => {
        if (err instanceof Error) {
          toast.closeAll();
          toast({
            title: err.message,
            status: 'error',
          });
        }
      },
    },
  );
  if (!enabled) return [] as unknown as UserAttributes;

  return response.data || defaultValues;
};
