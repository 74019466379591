import { Box, Flex } from '@chakra-ui/react';
import { FC } from 'react';
import { InputItem } from 'admin/components/authHub/inputItem';
import { Settings } from 'admin/components/authHub/settings';
import { ImageUploader } from 'admin/components/authHub/imageUploader';
import { useAuthHubContext } from 'admin/components/authHub/authHubContext';
import { CustomError } from 'admin/error/CustomError';
import { TargetType } from 'admin/types/authHub';
import { uploadLogo } from 'admin/api/authHub/uploadLogo';
import { useUserTenantId } from 'hooks/user/useUserTenantId';
import { SettingType } from './type';

type Props = {
  settings: Settings;
  settingType: SettingType;
  target: TargetType;
};

export const Controls: FC<Props> = ({ settings, settingType, target }) => {
  const { form, userPoolId, authHubId } = useAuthHubContext();
  const {
    register,
    getValues,
    setValue,
    setError,
    clearErrors,
    formState: { errors },
  } = form;
  const ss = settings[target];
  const tenantId = useUserTenantId();

  if (authHubId === undefined) {
    return null;
  }

  return (
    <Box mb={2} mr={2}>
      {ss.map((setting) => {
        // 組織側のフローでログイン画面から新規登録画面へのフローを消すための暫定的な対応
        if (
          settingType === 'organization' &&
          (setting.name === 'login.signupButtonName' ||
            setting.name === 'login.unregisteredUserGuide')
        ) {
          return null;
        }

        if (setting.name === 'header.logo') {
          const doUpload = async (file: File) => {
            try {
              const result = await uploadLogo({
                userPoolId,
                tenantId,
                authHubId,
                file,
              });
              const { url } = result;
              setValue(setting.name, url, { shouldDirty: true });
              clearErrors(setting.name);

              return url;
            } catch (error) {
              if (error instanceof CustomError) {
                const message = error.cause.errors?.file[0];
                setError(setting.name, {
                  type: 'custom',
                  message,
                });
              }

              throw error;
            }
          };

          return (
            <Flex key={setting.name} alignItems="flex-end">
              <ImageUploader
                label="ロゴ画像"
                name={setting.name}
                register={register}
                errors={errors}
                doUpload={doUpload}
              />
            </Flex>
          );
        }

        return (
          <InputItem
            key={setting.name}
            register={register}
            setting={setting}
            errors={errors}
            getValues={getValues}
          />
        );
      })}
    </Box>
  );
};
