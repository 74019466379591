import { useQuery } from 'react-query';
import { Tenant } from 'api/tenant/types';
import { useCustomToast } from 'hooks/useCustomToast';
import { getTenant } from 'api/tenant/getTenant';
import { queryTenantKey } from './queryTenantKey';

/**
 * テナント取得 hooks
 */
export const useTenant = ({
  tenantId,
}: {
  tenantId: string;
}): Tenant | undefined => {
  const toast = useCustomToast();
  const response = useQuery(
    queryTenantKey.getTenant({ tenantId }),
    () => getTenant(tenantId),
    {
      onError: (err: unknown) => {
        if (err instanceof Error) {
          toast.closeAll();
          toast({
            title: err.message,
            status: 'error',
          });
        }
      },
    },
  );
  const { data: tenant } = response;

  return tenant;
};
