export const messages = {
  // メール一覧取得
  getMailList: {
    customer: {
      notFound: 'メール一覧が存在しません。既に削除された可能性があります。',
      error:
        '申し訳ございません。メール一覧情報取得できません。時間を置いて再表示をお願いします。',
      failed: '申し訳ありません。正しいメール一覧情報を得ることができません。',
    },
    system: {
      typeError:
        '[api.mail.getMailList] メール一覧取得APIのレスポンス型が予期せぬデータ型です。フロントの修正をしてください',
    },
  },
  // メール詳細情報取得
  getMailId: {
    customer: {
      notFound: 'メールが存在しません。既に削除された可能性があります。',
      error:
        '申し訳ございません。メール詳細情報取得できません。時間を置いて再表示をお願いします。',
      failed: '申し訳ありません。正しいメール詳細情報を得ることができません。',
    },
    system: {
      typeError:
        '[api.mail.getMailId] メール詳細情報取得APIのレスポンス型が予期せぬデータ型です。フロントの修正をしてください',
    },
  },
  // メールレポート一覧取得
  getMailReportList: {
    customer: {
      error:
        '申し訳ございません。メールレポート一覧取得できません。時間を置いて再表示をお願いします。',
      failed:
        '申し訳ありません。正しいメールレポート一覧を得ることができません。',
    },
    system: {
      typeError:
        '[api.mail.getMailReportList] メールレポート一覧取得APIのレスポンス型が予期せぬデータ型です。フロントの修正をしてください',
    },
  },
  // メール配信設定情報取得
  getMailSetting: {
    customer: {
      error:
        '申し訳ございません。メール配信設定情報取得できません。時間を置いて再表示をお願いします。',
      failed:
        '申し訳ありません。正しいメール配信設定情報を得ることができません。',
    },
    system: {
      typeError:
        '[api.mail.getMailSetting] メール配信設定情報取得APIのレスポンス型が予期せぬデータ型です。フロントの修正をしてください',
    },
  },
  // メール複製
  postMailDuplicate: {
    customer: {
      error:
        '申し訳ございません。メール複製に失敗しました。時間を置いて再度実行してください。',
      failed: '申し訳ありません。メール複製に失敗しました',
    },
    system: {
      typeError:
        '[api.mail.postMailDuplicate] メール複製APIのレスポンス型が予期せぬデータ型です。フロントの修正をしてください',
    },
  },
  // メール購読対象一覧取得API
  getMailSubscribeList: {
    customer: {
      error:
        '申し訳ございません。メール購読対象一覧取得に失敗しました。時間を置いて再度実行してください。',
      failed:
        '申し訳ありません。正しいメール購読対象一覧情報を得ることができません。',
    },
    system: {
      typeError:
        '[api.mail.getMailSubscribeList] メール購読対象一覧取得APIのレスポンス型が予期せぬデータ型です。フロントの修正をしてください',
    },
  },
  // メール作成
  postMail: {
    customer: {
      error: '申し訳ございません。メール作成に失敗しました。',
      failed:
        'メール作成に失敗しました。恐れ入りますが時間をおいて再度登録してください。',
    },
    system: {
      typeError: '[api.mail.postMail] 予期せぬエラーが発生しました。',
    },
  },
  // メール配信対象件数取得
  getMailTargetCnt: {
    customer: {
      error:
        '申し訳ございません。メール配信対象件数取得できません。時間を置いて再表示をお願いします。',
      failed:
        '申し訳ありません。正しいメール配信対象件数取得を得ることができません。',
    },
    system: {
      typeError:
        '[api.mail.getMailTargetCnt] メール配信対象件数取得APIのレスポンス型が予期せぬデータ型です。フロントの修正をしてください',
    },
  },
  // メールテンプレートを修正
  putMail: {
    customer: {
      error: '申し訳ございません。メールテンプレートの保存に失敗しました。',
      failed:
        'メールテンプレートの保存に失敗しました。恐れ入りますが時間をおいて再度登録してください。',
    },
    system: {
      typeError: '[api.mail.putMail] 予期せぬエラーが発生しました。',
    },
  },
  // メール設定情報を修正
  putMailSetting: {
    customer: {
      error: '申し訳ございません。メール設定情報の保存に失敗しました。',
      failed:
        'メール設定情報の保存に失敗しました。恐れ入りますが時間をおいて再度登録してください。',
    },
    system: {
      typeError: '[api.mail.putMailSetting] 予期せぬエラーが発生しました。',
    },
  },
  // メール情報コピー
  getMailCopy: {
    customer: {
      error:
        '申し訳ございません。メール情報をコピーできません。時間を置いて再度コピーをお願いします。',
      failed:
        'メール情報コピーに失敗しました。恐れ入りますが、時間をおいて再度コピーをしてください。',
    },
    system: {
      typeError:
        '[api.mail.getMailCopy] メール情報コピーAPIのレスポンス型が予期せぬデータ型です。フロントの修正をしてください',
    },
  },
  // CSVファイルアップロード
  csvFileUpload: {
    customer: {
      error:
        '申し訳ございません。CSVファイルのアップロードに失敗しました。時間を置いて再度実行してください。',
      failed: '申し訳ございません。CSVファイルのアップロードに失敗しました',
    },
    system: {
      typeError:
        '[api.mail.csvFileUpload] CSVファイルアップロードAPIのレスポンス型が予期せぬデータ型です。フロントの修正をしてください',
    },
  },
  // メールマガジン一覧取得
  getMailMagazineList: {
    customer: {
      notFound:
        'メールマガジン一覧が存在しません。既に削除された可能性があります。',
      error:
        '申し訳ございません。メールマガジン一覧情報取得できません。時間を置いて再表示をお願いします。',
      failed:
        '申し訳ありません。正しいメールマガジン一覧情報を得ることができません。',
    },
    system: {
      typeError:
        '[api.mail.getMailMagazineList] メールマガジン一覧取得APIのレスポンス型が予期せぬデータ型です。フロントの修正をしてください',
    },
  },
  // メールマガジン作成
  postMailMagazine: {
    customer: {
      error: '申し訳ございません。メールマガジンの作成に失敗しました。',
      failed:
        'メールマガジンの作成に失敗しました。恐れ入りますが時間をおいて再度登録してください。',
    },
    system: {
      typeError: '[api.mail.postMailMagazine] 予期せぬエラーが発生しました。',
    },
  },
  // メールマガジン詳細情報取得
  getMailMagazineId: {
    customer: {
      notFound:
        'メールマガジンが存在しません。既に削除された可能性があります。',
      error:
        '申し訳ございません。メールマガジン詳細情報取得できません。時間を置いて再表示をお願いします。',
      failed:
        '申し訳ありません。正しいメールマガジン詳細情報を得ることができません。',
    },
    system: {
      typeError:
        '[api.mail.getMailMagazineId] メールマガジン詳細情報取得APIのレスポンス型が予期せぬデータ型です。フロントの修正をしてください',
    },
  },
  // メールマガジン修正
  patchMailMagazine: {
    customer: {
      error: '申し訳ございません。メールマガジンの保存に失敗しました。',
      failed:
        'メールマガジンの保存に失敗しました。恐れ入りますが時間をおいて再度登録してください。',
    },
    system: {
      typeError: '[api.mail.patchMailMagazine] 予期せぬエラーが発生しました。',
    },
  },
  // メールマガジンステータス変更
  patchMailMagazinePublish: {
    customer: {
      error:
        '申し訳ございません。メールマガジンステータスの更新に失敗しました。',
      failed:
        'メールマガジンステータスの更新に失敗しました。恐れ入りますが時間をおいて再度登録してください。',
    },
    system: {
      typeError:
        '[api.mail.patchMailMagazinePublish] 予期せぬエラーが発生しました。',
    },
  },
  // メール配信予約解除
  deleteMailReserve: {
    customer: {
      error:
        '申し訳ございません。メール配信予約解除に失敗しました。時間を置いて再度実行してください。',
      failed: '申し訳ありません。メール配信予約解除に失敗しました',
    },
    system: {
      typeError:
        '[api.mail.deleteMailReserve] メール配信予約解除のレスポンス型が予期せぬデータ型です。フロントの修正をしてください',
    },
  },
};
