import { VFC, memo, Suspense } from 'react';
import ErrorBoundary from 'api/ErrorBoundary';
import { GroupFlat } from 'api/tenant/types';
import { useErrorBoundaryReset } from 'hooks/useErrorBoundaryReset';
import { useGroupList, ROOT_NODE } from 'hooks/tenant/useGroupList';
import { useUserTenantId } from 'hooks/user/useUserTenantId';
import { LoadingSkeleton, ErrorContents } from 'components/common/atoms';
import { GroupTreeView } from 'components/setting/groups/molecules/GroupTreeView';

type GroupTreeInnerProps = {
  tenantId: string;
};

const GroupTreeInner: VFC<GroupTreeInnerProps> = ({ tenantId }) => {
  const treeData = useGroupList(tenantId);

  return <GroupTreeView<GroupFlat> tree={treeData} rootId={ROOT_NODE} />;
};

export const GroupTree: VFC = memo(() => {
  const tenantId = useUserTenantId();
  const { ebKey, onError } = useErrorBoundaryReset();

  return (
    <ErrorBoundary
      errorComponent={<ErrorContents name="グループ一覧" />}
      onError={onError}
      key={ebKey.current}
    >
      <Suspense fallback={<LoadingSkeleton />}>
        {tenantId ? <GroupTreeInner tenantId={tenantId} /> : <></>}
      </Suspense>
    </ErrorBoundary>
  );
});
