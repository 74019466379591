import {
  Box,
  Switch,
  Table,
  Tbody,
  Td,
  Text,
  Th,
  Thead,
  Tr,
} from '@chakra-ui/react';
import { FirstLoginMailType } from 'api/transactionMail/type';
import { Colors } from 'components/paywall/config';
import { useTransactionMailPermission } from 'hooks/transactionMail/useTransactionMailPermission';
import { FC } from 'react';
import { NavLink } from 'react-router-dom';

type Props = {
  sendingContentsList: FirstLoginMailType[];
  onOpenStatusChangeDialog: (target: FirstLoginMailType) => void;
};

export const SendingContentsListTable: FC<Props> = ({
  sendingContentsList,
  onOpenStatusChangeDialog,
}) => {
  const { isEditable } = useTransactionMailPermission();

  return (
    <Box h="100vh" overflowY="auto" maxHeight="calc(100vh - 240px)">
      <Table border={`solid 1px ${Colors.GRAY_70}`}>
        <Thead
          px="16px"
          position="sticky"
          zIndex="sticky"
          top={0}
          bgColor={Colors.GRAY_70}
        >
          <Tr h="52px">
            <Th
              maxWidth="122px"
              minWidth="122px"
              fontSize="12px"
              fontWeight="600"
              p="10px 16px"
              color={Colors.GRAY_03}
            >
              画面ID
            </Th>
            <Th
              maxWidth="200px"
              minWidth="200px"
              fontSize="12px"
              fontWeight="600"
              p="10px 16px"
              color={Colors.GRAY_03}
            >
              件名
            </Th>
            <Th
              w="100%"
              fontSize="12px"
              fontWeight="600"
              p="10px 16px"
              color={Colors.GRAY_03}
            >
              設定名
            </Th>
            <Th
              maxWidth="108px"
              minWidth="108px"
              fontSize="12px"
              fontWeight="600"
              p="10px 16px"
              color={Colors.GRAY_03}
            >
              使用設定
            </Th>
          </Tr>
        </Thead>
        <Tbody>
          {sendingContentsList.map((item) => (
            <Tr key={item.id} h="52px">
              <Td fontSize="12px" fontWeight="600" p="10px 16px">
                <Box textDecoration="underline">
                  <NavLink to={`/transactionMail/form/${item.id}`}>
                    {item.id}
                  </NavLink>
                </Box>
              </Td>
              <Td fontSize="12px" p="10px 16px">
                {item.subject}
              </Td>
              <Td w="100%" fontSize="12px" p="10px 16px">
                {item.settingTitle}
              </Td>
              <Td fontSize="12px" p="10px 16px">
                <Box>
                  <Switch
                    isChecked={item?.status}
                    isDisabled={!isEditable}
                    onChange={() => onOpenStatusChangeDialog(item)}
                  />
                  <Text as="span" ml="5px">
                    {item?.status ? '使用中' : '停止中'}
                  </Text>
                </Box>
              </Td>
            </Tr>
          ))}
        </Tbody>
      </Table>
    </Box>
  );
};
