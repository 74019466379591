import {
  VFC,
  memo,
  useCallback,
  useState,
  useEffect,
  ChangeEvent,
  Suspense,
} from 'react';
import { useNavigate, useSearchParams } from 'react-router-dom';
import {
  Box,
  Text,
  Button,
  ButtonGroup,
  VStack,
  Tag,
  TagLabel,
  TagCloseButton,
  Select,
} from '@chakra-ui/react';

import {
  MAIL_DELIVERY_TO_ALL,
  MAIL_DELIVERY_TO_SEGMENT,
  MAIL_DELIVERY_TO_DIRECT,
  MAIL_DELIVERY_TO_INSERT,
} from 'define';
import ErrorBoundary from 'api/ErrorBoundary';
import { useErrorBoundaryReset } from 'hooks/useErrorBoundaryReset';
import { useSetPageTitle } from 'hooks/useSetPageTitle';
import { MailColumns, MailColumnsFilter } from 'api/mail/types';
import { allMailFileDownload } from 'api/mail/allMailFileDownload';
import { useUserTenantId } from 'hooks/user/useUserTenantId';
import { useSegmentList } from 'hooks/segment/useSegmentList';
import { LoadingSkeleton } from 'components/common/atoms';
import { ListError } from 'components/common/molecules';
import { MailListInner } from 'components/mail/templates/MailListInner';

type filterTag = {
  item: MailColumns;
  filterItem: MailColumnsFilter;
};

type customQueryParams = {
  ordering?: string;
  status?: string;
  deliveryTo?: string;
  segmentId?: string;
  reserve?: string;
  pageNumber?: string;
  perPage?: string;
};

// 表示件数
const perPageList = [
  { id: 1, text: '50', value: '50' },
  { id: 2, text: '100', value: '100' },
];

/**
 * 公開設定フィルター
 */
const statusList: MailColumnsFilter[] = [
  { id: 10, name: '処理中' },
  { id: 11, name: 'チェック中' },
  { id: 20, name: '予約中' },
  { id: 30, name: '配信中' },
  { id: 40, name: '完了' },
  { id: -1, name: 'エラー' },
];

/**
 * 配信先フィルター
 */
const deliveryList: MailColumnsFilter[] = [
  {
    id: String(MAIL_DELIVERY_TO_ALL),
    name: 'メルマガ購読者全員への配信',
  },
  {
    id: String(MAIL_DELIVERY_TO_SEGMENT),
    name: 'セグメントへの配信',
  },
  {
    id: String(MAIL_DELIVERY_TO_DIRECT),
    name: 'メールアドレス直接指定',
  },
  {
    id: String(MAIL_DELIVERY_TO_INSERT),
    name: '差し込みメール配信',
  },
];

const columns: MailColumns[] = [
  {
    id: '1',
    key: 'id',
    name: '配信ID',
    isSort: true,
    isFilter: false,
    w: 170,
  },
  {
    id: '2',
    key: 'mailTitle',
    name: '件名',
    isSort: false,
    isFilter: false,
    w: 450,
  },
  {
    id: '3',
    key: 'reserve',
    name: '予約日時',
    isSort: false,
    isFilter: true,
    w: 140,
  },
  {
    id: '4',
    key: 'delivery',
    name: '配信日時',
    isSort: false,
    isFilter: false,
    w: 140,
  },
  {
    id: '5',
    key: 'status',
    name: 'ステータス',
    isSort: false,
    isFilter: true,
    w: 130,
  },
  {
    id: '6',
    key: 'report',
    name: 'レポート',
    isSort: false,
    isFilter: false,
    w: 108,
  },
  {
    id: '7',
    key: 'deliveryTo',
    name: '配信先',
    isSort: false,
    isFilter: true,
    w: 120,
  },
  {
    id: '8',
    key: 'estimateCnt',
    name: '配信数・配信予定数',
    isSort: true,
    isFilter: false,
    w: 172,
  },
  {
    id: '9',
    key: 'deliveryCnt',
    name: '配信成功数',
    isSort: true,
    isFilter: false,
    w: 122,
  },
  {
    id: '10',
    key: 'deliveryRate',
    name: '配信成功率',
    isSort: true,
    isFilter: false,
    w: 122,
  },
  {
    id: '11',
    key: 'openCnt',
    name: '開封数',
    isSort: true,
    isFilter: false,
    w: 97,
  },
  {
    id: '12',
    key: 'openRate',
    name: '開封率',
    isSort: true,
    isFilter: false,
    w: 97,
  },
  {
    id: '14',
    key: 'linkClickCnt',
    name: 'クリック数',
    isSort: false,
    isFilter: false,
    w: 122,
  },
  {
    id: '15',
    key: 'linkClickRate',
    name: 'クリック率',
    isSort: false,
    isFilter: false,
    w: 122,
  },
];

export const MailList: VFC = memo(() => {
  const { ebKey, onError } = useErrorBoundaryReset();
  useSetPageTitle('メール一覧');
  const navigate = useNavigate();
  const [searchParams, setSearchParams] = useSearchParams();
  const tenantId = useUserTenantId();
  const [filterTags, setFilterTags] = useState<filterTag[]>([]);
  const [currentOrder, setCurrentOrder] = useState('');
  const [currentPerPage, setCurrentPerPage] = useState('50');
  const segmentList = useSegmentList();
  /**
   * クエリパラメータ設定
   */
  const setQueryParameters = useCallback(
    ({
      ordering = '',
      status = '',
      deliveryTo = '',
      reserve = '',
      pageNumber = '',
      perPage = '',
    }: customQueryParams) => {
      // 現在のクエリパラメータ情報取得
      const openDrawer = searchParams.get('openDrawer');
      let queryOrdering = searchParams.get('ordering');
      let queryStatus = searchParams.get('status');
      let queryDeliveryTo = searchParams.get('deliveryTo');
      let queryReserve = searchParams.get('reserve');
      let queryPage = searchParams.get('page');
      let queryPerPage = searchParams.get('perPage');
      if (ordering !== '') queryOrdering = ordering;
      if (status !== '') queryStatus = status;
      if (deliveryTo !== '') queryDeliveryTo = deliveryTo;
      if (reserve !== '') queryReserve = reserve;
      if (pageNumber !== '') {
        queryPage = pageNumber;
      } else {
        queryPage = '1';
      }
      if (perPage !== '') queryPerPage = perPage;
      const queryParams = {};
      if (openDrawer) Object.assign(queryParams, { openDrawer });
      if (queryOrdering)
        Object.assign(queryParams, { ordering: queryOrdering });
      if (queryStatus) Object.assign(queryParams, { status: queryStatus });
      if (queryDeliveryTo) {
        Object.assign(queryParams, { deliveryTo: queryDeliveryTo });
      } else if (typeof queryDeliveryTo === 'number') {
        // 配信先：全配信用
        Object.assign(queryParams, { deliveryTo: 0 });
      }
      if (queryReserve) Object.assign(queryParams, { reserve: queryReserve });
      if (queryPerPage) Object.assign(queryParams, { perPage: queryPerPage });
      Object.assign(queryParams, { page: queryPage });
      setSearchParams(queryParams);
    },
    [searchParams, setSearchParams],
  );

  /**
   * カラムフィルター 追加処理
   */
  const onColumnFilter = useCallback(
    (item: MailColumns, filterItem: MailColumnsFilter) => {
      const obj = filterTags.filter((tag) => tag.item.id !== item.id);
      obj.push({
        item,
        filterItem,
      });
      setFilterTags(obj);
      // クエリパラメータ処理
      setQueryParameters({ [item.key]: filterItem.id });
    },
    [filterTags, setQueryParameters],
  );

  /**
   * カラムフィルター 削除処理
   */
  const onDeleteFilterTag = useCallback(
    (item: MailColumns, filterItemId: number | string): void => {
      const obj = filterTags.filter(
        (tag) => tag.item.id !== item.id && tag.filterItem.id !== filterItemId,
      );
      setFilterTags(obj);

      // クエリパラメータ処理
      const params = {};
      if (searchParams.get('ordering'))
        Object.assign(params, { ordering: searchParams.get('ordering') });
      if (searchParams.get('status') && item.key !== 'status')
        Object.assign(params, { status: searchParams.get('status') });
      if (searchParams.get('deliveryTo') && item.key !== 'deliveryTo')
        Object.assign(params, { deliveryTo: searchParams.get('deliveryTo') });
      if (searchParams.get('reserve') && item.key !== 'reserve')
        Object.assign(params, { reserve: searchParams.get('reserve') });
      if (searchParams.get('page') && item.key !== 'page')
        Object.assign(params, { page: searchParams.get('page') });
      setSearchParams(params);
    },
    [filterTags, searchParams, setSearchParams],
  );

  /**
   * ソート処理
   */
  const onSort = useCallback(
    (item: MailColumns) => {
      if (!item.isSort) return;
      let ordering = item.key;
      if (currentOrder.includes(item.key)) {
        ordering = currentOrder.startsWith('-', 0) ? item.key : `-${item.key}`;
      }

      setQueryParameters({
        ordering,
      });
    },
    [currentOrder, setQueryParameters],
  );

  const onPerPageChange = useCallback(
    (event: ChangeEvent<HTMLSelectElement>) => {
      setCurrentPerPage(event.target.value);
      setQueryParameters({ perPage: event.target.value });
    },
    [setQueryParameters],
  );

  /**
   * 初期処理
   */
  useEffect(() => {
    setCurrentOrder(searchParams.get('ordering') || '');
    setCurrentPerPage(searchParams.get('perPage') || '50');

    const tags: filterTag[] = [];
    // ステータスフィルターが設定されている場合
    const statusFilter =
      searchParams.get('status') !== null
        ? Number(searchParams.get('status'))
        : null;
    if (statusFilter !== null) {
      const statusItem = columns.filter((x) => x.key === 'status');
      const statusFilterItem = statusList.filter((x) => x.id === statusFilter);
      tags.push({
        item: statusItem[0],
        filterItem: statusFilterItem[0],
      });
    }

    // 配信先フィルターが設定されている場合
    const deliveryFilter =
      searchParams.get('deliveryTo') !== null
        ? searchParams.get('deliveryTo')
        : null;
    if (deliveryFilter !== null) {
      const deliveryItem = columns.filter((x) => x.key === 'deliveryTo');
      const deliveryFilterItem = (deliveryList || []).filter(
        (x) => x.id === deliveryFilter,
      );
      tags.push({
        item: deliveryItem[0],
        filterItem: deliveryFilterItem[0],
      });
    }

    // 配信日時フィルターが設定されている場合
    const reserveFilter =
      searchParams.get('reserve') !== null ? searchParams.get('reserve') : null;

    if (reserveFilter !== null) {
      const reserveItem = columns.filter((x) => x.key === 'reserve');
      tags.push({
        item: reserveItem[0],
        filterItem: {
          id: reserveFilter,
          name: reserveFilter,
        },
      });
    }

    if (tags.length !== 0) {
      setFilterTags(tags);
    }
    if (!searchParams.get('page')) setQueryParameters({ pageNumber: '1' });
  }, [searchParams, setQueryParameters]);

  // メール一括ダウンロード
  const onAllDownload = useCallback(() => {
    allMailFileDownload(tenantId);
  }, [tenantId]);

  return (
    <VStack w="100%" spacing={4}>
      <Box
        w="100%"
        gridGap={2}
        display="flex"
        justifyContent="space-between"
        alignItems="center"
        mr={2}
        mb={2}
      >
        <Box w="100%" display="flex">
          {filterTags.map((tag) => (
            <Tag
              key={`${tag.item.id}-${tag.filterItem.id}`}
              size="md"
              variant="solid"
              color="black"
              fontWeight="normal"
              bgColor="gray.200"
              ml={2}
            >
              <TagLabel>{`${tag.item.name}：${tag.filterItem.name}`}</TagLabel>
              <TagCloseButton
                onClick={() => onDeleteFilterTag(tag.item, tag.filterItem.id)}
              />
            </Tag>
          ))}
        </Box>
        <Box>
          <Select
            w={100}
            onChange={onPerPageChange}
            value={currentPerPage}
            style={{
              height: 'auto',
              border: '1px solid #eee',
              borderRadius: '5px',
              padding: '6px 12px',
            }}
          >
            {perPageList.map((item) => (
              <option key={item.id} value={item.value}>
                {item.text}件
              </option>
            ))}
          </Select>
        </Box>
        <ButtonGroup variant="outline" spacing="2">
          {/* <Button onClick={() => navigate('/mail/segment')}>
              <Text as="span">セグメント設定</Text>
            </Button> */}
          <Button onClick={onAllDownload}>
            <Text as="span">一括ダウンロード</Text>
          </Button>
          <Button variant="primary" onClick={() => navigate('/mail/form')}>
            <Text as="span">新規作成</Text>
          </Button>
        </ButtonGroup>
      </Box>
      <ErrorBoundary
        errorComponent={<ListError message="メール一覧を表示できません" />}
        onError={onError}
        key={ebKey.current}
      >
        <Suspense fallback={<LoadingSkeleton width="100%" />}>
          <MailListInner
            columns={columns}
            statusList={statusList}
            segmentList={segmentList || []}
            deliveryList={deliveryList}
            onSort={onSort}
            onColumnFilter={onColumnFilter}
          />
        </Suspense>
      </ErrorBoundary>
    </VStack>
  );
});
