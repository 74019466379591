import { useState } from 'react';
import { useCustomToast } from 'hooks/useCustomToast';
import { successToast, errorToast } from 'utils/toast';
import { useUserTenantId } from 'hooks/user/useUserTenantId';
import { csvFileUpload } from 'api/mail/csvFileUpload';
import {
  isCsvFileUploadResponse,
  isGcsFileUploadErrorResponse,
  CsvFileUploadResponse,
} from 'api/storage/types';
import { isGetValidationError } from 'utils/form';

const defaultCsvFileUploadResponse = {
  addressCount: 0,
  ext: '.csv',
  filepath: '',
  originFilename: '',
  size: 0,
  error: '',
};

export const useCsvGcsUpload = ({
  mailId,
}: {
  mailId: string;
}): ((
  file: File,
  filePath: string,
) => Promise<CsvFileUploadResponse | null>) => {
  const toast = useCustomToast();
  const tenantId = useUserTenantId();
  const [result, setResult] = useState<CsvFileUploadResponse>(
    defaultCsvFileUploadResponse,
  );

  const upload = async (
    file: File,
    filePath: string,
  ): Promise<CsvFileUploadResponse | null> => {
    if (!tenantId) {
      toast({
        ...errorToast,
        title: 'アップロードエラー',
        description:
          '認証エラーが発生しました。一度ログアウト頂き再度ログインし操作ください',
      });

      return null;
    }

    if (!file.size) {
      toast({
        ...errorToast,
        title: 'アップロードエラー',
        description:
          'ファイルサイズが 0 です。内容記載の上アップロードしてください',
      });

      return null;
    }

    try {
      setResult(
        Object.assign(defaultCsvFileUploadResponse, {
          error: '',
        }),
      );
      // ファイルサイズが大きいと1分以上待つので
      // バックグランドで処理
      const uploadResult = await csvFileUpload({
        file,
        filePath,
        tenantId,
        mailId,
      });

      if (isCsvFileUploadResponse(uploadResult)) {
        toast({
          ...successToast,
          duration: 4000,
          title: 'アップロードしました',
        });

        return uploadResult;
      }

      if (isGcsFileUploadErrorResponse(uploadResult)) {
        const tmpRes = Object.assign(defaultCsvFileUploadResponse, {
          error: uploadResult.file,
        });

        setResult(tmpRes);
        toast({
          ...errorToast,
          title: 'アップロードエラー',
          description: 'CSVファイルアップロードに失敗しました',
          duration: 4000,
        });
      } else if (
        isGetValidationError<CsvFileUploadResponse>(
          uploadResult,
          Object.keys(defaultCsvFileUploadResponse),
        )
      ) {
        if (uploadResult?.others) {
          const tmpRes = Object.assign(defaultCsvFileUploadResponse, {
            error: uploadResult.others,
          });

          setResult(tmpRes);
        }
        toast({
          ...errorToast,
          title: 'アップロードエラー',
          description: 'CSVファイルアップロードに失敗しました',
          duration: 4000,
        });
      } else {
        toast({
          ...errorToast,
          title: 'アップロードエラー',
          description: 'CSVファイルアップロードに失敗しました',
          duration: 4000,
        });
      }
    } catch (e) {
      const err = e as Error;
      toast({
        ...errorToast,
        title: 'アップロードエラー',
        description: err.message,
      });
    }

    return result;
  };

  return upload;
};
