import {
  VFC,
  memo,
  useCallback,
  useState,
  useEffect,
  ChangeEvent,
  SetStateAction,
  Dispatch,
} from 'react';
import { useForm, FormProvider } from 'react-hook-form';
import {
  Box,
  Flex,
  Text,
  Stack,
  Checkbox,
  Button,
  Modal,
  ModalOverlay,
  ModalContent,
  ModalHeader,
  ModalFooter,
  ModalBody,
  ModalCloseButton,
  useDisclosure,
  Tooltip,
} from '@chakra-ui/react';
import { FiHelpCircle } from 'react-icons/fi';
import { format } from 'date-fns';
import { EnqueteListType, fileDownloadRequestType } from 'api/enquete/types';
import { DatePicker } from 'components/common/atoms';
import { useUserTenantId } from 'hooks/user/useUserTenantId';
import { useEnqueteFormDownload } from 'hooks/enquete/useEnqueteFormDownload';

const DATETIME_FORMAT = 'yyyy/MM/dd';

const defaultValues: fileDownloadRequestType = {
  tenantId: '',
  enqueteId: '',
  target: '',
  startDate: '',
  endDate: '',
  isRandom: false,
};

type Props = {
  data: EnqueteListType;
};

export const DownloadContents: VFC<Props> = memo(({ data }: Props) => {
  const tenantId = useUserTenantId();
  const { isOpen, onOpen, onClose } = useDisclosure();
  const [isAnswerCsv, setIsAnswerCsv] = useState<boolean>(true); // 回答CSV
  const [isAttachment, setIsAttachment] = useState<boolean>(true); // 添付ファイル
  const [isRandomRank, setIsRandomRank] = useState<boolean>(false); // ランダム順位
  const [periodStartDate, setPeriodStartDate] = useState<Date | null>(null);
  const [periodEndDate, setPeriodEndDate] = useState<Date | null>(null);

  const methods = useForm<fileDownloadRequestType>({
    mode: 'onBlur',
    // エラーのある入力が再度バリデーションされるタイミングを変更(default: onChange)
    reValidateMode: 'onBlur',
    defaultValues,
  });
  const { setValue, getValues } = methods;
  const formId = 'enquete-form-download';
  const { onSubmit, isLoading } = useEnqueteFormDownload();

  /**
   * モーダルOPEN処理
   */
  const onOpenDownloadModal = useCallback(() => {
    // モーダルOPEN時の初期化処理
    setValue('tenantId', tenantId);
    setValue('enqueteId', data.id);
    setValue('startDate', '');
    setValue('endDate', '');
    setIsAnswerCsv(true);
    setIsRandomRank(false);
    setPeriodStartDate(null);
    setPeriodEndDate(null);
    setIsAttachment(data.ansFileCnt > 0);
    onOpen();
  }, [data, onOpen, setValue, tenantId]);

  // チェックボックス変更時処理
  const onCheckEvent = useCallback(
    (
      event: ChangeEvent<HTMLInputElement>,
      setFunc: Dispatch<SetStateAction<boolean>>,
    ) => {
      setFunc(event.target.checked);
    },
    [],
  );

  // 回答期間取得
  const getAnswerPeriod = () => {
    const start = data.startDate
      ? format(new Date(data.startDate), DATETIME_FORMAT)
      : '';
    const end = data.endDate
      ? format(new Date(data.endDate), DATETIME_FORMAT)
      : '';
    if (start === '' && end === '') return '未設定';
    if (end === '') return `${start} - 指定なし`;

    return `${start} - ${end}`;
  };

  // 期間FROM変更時イベント
  const onStartDateChange = useCallback(
    (date: Date | null) => {
      setPeriodStartDate(date);
      setValue('startDate', date ? format(date, DATETIME_FORMAT) : '');
    },
    [setValue],
  );

  // 期間To変更時イベント
  const onEndDateChange = useCallback(
    (date: Date | null) => {
      setPeriodEndDate(date);
      setValue('endDate', date ? format(date, DATETIME_FORMAT) : '');
    },
    [setValue],
  );

  // ファイルダウンロード処理
  const onFileDownload = useCallback(() => {
    // 回答CSVのみダウンロード
    let targetType = 'answers';
    // CSV、添付ファイル両方ダウンロード
    if (isAnswerCsv && isAttachment) {
      targetType = 'file_csv';
      // 添付ファイルのみダウンロード
    } else if (isAttachment) {
      targetType = 'files';
    }

    setValue('target', targetType);
    setValue('isRandom', isRandomRank);
    onSubmit(getValues());
    onClose();
  }, [
    isAnswerCsv,
    isAttachment,
    setValue,
    getValues,
    isRandomRank,
    onSubmit,
    onClose,
  ]);

  useEffect(() => {
    if (!isAnswerCsv) {
      setIsRandomRank(false);
    }
  }, [isAnswerCsv]);

  return (
    <>
      <Text
        as="a"
        onClick={onOpenDownloadModal}
        cursor="pointer"
        style={{
          textDecoration: 'underline',
          display: 'block',
          margin: '2px 0',
        }}
      >
        ・ダウンロード
      </Text>
      <Modal isOpen={isOpen} size="lg" onClose={onClose} isCentered>
        <ModalOverlay />
        <ModalContent>
          <ModalHeader textAlign="center">ダウンロード指定</ModalHeader>
          <ModalBody>
            <form id={formId}>
              <FormProvider {...methods}>
                <Stack>
                  <Stack borderBottom="1px" borderBottomColor="gray.200" pb={4}>
                    <Flex>
                      <Text as="span" minW="90px">
                        フォームID：
                      </Text>
                      <Text>{data.id}</Text>
                    </Flex>
                    <Flex>
                      <Text as="span" minW="90px">
                        フォーム名：
                      </Text>
                      <Text>{data.title}</Text>
                    </Flex>
                    <Flex>
                      <Text as="span" minW="90px">
                        公開日時：
                      </Text>
                      <Text>{getAnswerPeriod()}</Text>
                    </Flex>
                  </Stack>
                  <Box style={{ margin: '1rem auto' }}>
                    <Flex alignItems="flex-start" mb={4}>
                      <Box w="100px">
                        <Text as="label">対象</Text>
                      </Box>
                      <Box>
                        <Stack>
                          <Checkbox
                            isChecked={isAnswerCsv}
                            onChange={(e) => onCheckEvent(e, setIsAnswerCsv)}
                          >
                            回答CSV
                          </Checkbox>
                          <Checkbox
                            isChecked={isAttachment}
                            onChange={(e) => onCheckEvent(e, setIsAttachment)}
                            isDisabled={data.ansFileCnt === 0}
                          >
                            添付ファイル
                          </Checkbox>
                        </Stack>
                      </Box>
                    </Flex>
                    <Flex alignItems="flex-start" mb={4}>
                      <Box w="100px">
                        <Text as="label">オプション</Text>
                      </Box>
                      <Box>
                        <Stack position="relative">
                          <Checkbox
                            isChecked={isRandomRank}
                            onChange={(e) => onCheckEvent(e, setIsRandomRank)}
                            isDisabled={!isAnswerCsv}
                          >
                            ランダム順位
                          </Checkbox>
                          <Box
                            position="absolute"
                            top="50%"
                            right="-20px"
                            transform="translateY(-100%)"
                          >
                            <Tooltip label="ランダム順位をオンにすると、回答に対してランダムに順位が振られます。抽選や順位付けなどご利用可能です。">
                              <Box>
                                <FiHelpCircle />
                              </Box>
                            </Tooltip>
                          </Box>
                        </Stack>
                      </Box>
                    </Flex>
                    <Flex alignItems="center">
                      <Box w="100px">
                        <Text as="label">期間指定</Text>
                      </Box>

                      <Box>
                        <Flex alignItems="center">
                          <DatePicker
                            startDate={
                              periodStartDate ? new Date(periodStartDate) : null
                            }
                            width={40}
                            onChange={onStartDateChange}
                            dateFormat="yyyy-MM-dd"
                          />
                          <span style={{ margin: '0 10px' }}>〜</span>
                          <DatePicker
                            endDate={
                              periodEndDate ? new Date(periodEndDate) : null
                            }
                            width={40}
                            onChange={onEndDateChange}
                            dateFormat="yyyy-MM-dd"
                          />
                        </Flex>
                        <Text fontSize="12px">
                          ※期間の入力がない場合には、全件ダウンロードします。
                        </Text>
                      </Box>
                    </Flex>
                  </Box>
                </Stack>
              </FormProvider>
            </form>
          </ModalBody>
          <ModalFooter justifyContent="center">
            <Button
              variant="primary"
              isDisabled={(!isAnswerCsv && !isAttachment) || isLoading}
              onClick={onFileDownload}
              isLoading={isLoading}
            >
              ダウンロード
            </Button>
          </ModalFooter>
          <ModalCloseButton />
        </ModalContent>
      </Modal>
    </>
  );
});
