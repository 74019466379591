import { getTransactionMailTypeStatuses } from 'admin/api/userPool/transactionMail/getTransactionMailTypeStatuses';
import { useIdPoolConsumerId } from 'admin/hooks/useIdPoolConsumerId';
import { useCustomToast } from 'hooks/useCustomToast';
import { useQuery } from 'react-query';
import { errorToast } from 'utils/toast';
import { queryTransactionMailKey } from './queryTransactionMailKey';

export const useTransactionMailTypeStatuses = () => {
  const idPoolConsumerId = useIdPoolConsumerId();
  const toast = useCustomToast();
  const { data } = useQuery({
    queryKey:
      queryTransactionMailKey.getTransactionMailTypeStatuses(idPoolConsumerId),
    queryFn: () => getTransactionMailTypeStatuses(idPoolConsumerId),
    onError: () => {
      toast({
        ...errorToast,
        duration: 4000,
        title: 'トランザクションメールの個別設定情報の取得に失敗しました',
        position: 'bottom',
        variant: 'solid',
      });
    },
  });

  return { data };
};
