import {
  Button,
  DrawerBody,
  DrawerFooter,
  FormControl,
  FormErrorMessage,
  HStack,
  ListItem,
  Text,
} from '@chakra-ui/react';
import { RhfInput } from 'admin/components/form/RhfInput';
import { AlertInfoBar } from 'admin/components/ui/alertInfo';
import { Colors, ColorSet } from 'admin/define/colors';
import { UserType } from 'admin/types/user';
import { UserDeleteSubmitFormType } from 'admin/types/users';
import { FC, memo } from 'react';
import { useFormContext, useFormState } from 'react-hook-form';

export type StatusChangeFormComponentProps = {
  formId: string;
  isLoading: boolean;
  onClose: () => void;
  onSubmit: () => void;
};

export const StatusChangeFormComponent: FC<StatusChangeFormComponentProps> =
  memo(({ formId, isLoading, onClose, onSubmit }) => {
    const { handleSubmit } = useFormContext<UserType>();
    const { errors, isValid } = useFormState<UserDeleteSubmitFormType>();

    return (
      <>
        <DrawerBody fontSize="16px" py={0}>
          <Text mb={6} lineHeight="27px" fontSize="16px">
            属性のステータスを「下書き」から「確定」へ変更します。
            <br />
            <Text as="span" fontWeight="700" color={Colors.RED_20}>
              一度「確定」状態にした属性は、下書きに戻すことができません。
              <br />
            </Text>
            また、次の変更が不可能になります。
          </Text>
          <ListItem fontWeight="bold">画面上の配置</ListItem>
          <ListItem mb={6} fontWeight="bold">
            使用する属性
          </ListItem>

          <AlertInfoBar type="error" iconType="error">
            ・一度実行すると元の状態に戻せないため注意してください
          </AlertInfoBar>
          <form onSubmit={handleSubmit(onSubmit)} id={formId}>
            <Text mt={6} mb={1} fontSize="sm" fontWeight="bold">
              実行する場合は「confirm」と入力後に「実行ボタン」を押下してください。
            </Text>
            <FormControl isInvalid={Boolean(errors.word?.message)}>
              <RhfInput<UserDeleteSubmitFormType> name="word" type="text" />
              <FormErrorMessage>{errors.word?.message}</FormErrorMessage>
            </FormControl>
          </form>
        </DrawerBody>
        <DrawerFooter>
          <HStack spacing={4}>
            <Button
              variant="outline"
              onClick={() => onClose()}
              {...ColorSet.Default}
              padding="8px 16px"
            >
              キャンセル
            </Button>
            <Button
              colorScheme="primary"
              type="submit"
              mr={3}
              form={formId}
              disabled={!isValid || isLoading}
              padding="8px 36px"
            >
              実行
            </Button>
          </HStack>
        </DrawerFooter>
      </>
    );
  });
