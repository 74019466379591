import {
  DrawerCloseButton,
  DrawerContent,
  DrawerHeader,
  DrawerOverlay
} from '@chakra-ui/react';
import { withSuspenseAndErrorBoundary } from 'admin/components/Error/WithErrorBoundary';
import { TenantUsersInviteForm } from 'admin/components/tenantUsers/tenantUsersInviteForm';
import {
  ErrorContents, LoadingSpinner
} from 'components/common/atoms';
import { FC, memo } from 'react';

type Props = {
  onClose: () => void;
}

const tenantUsersInviteDrawer: FC<Props> = memo(({ onClose }: Props) => (
  <>
    <DrawerOverlay />
    <DrawerContent>
      <DrawerHeader borderBottomWidth="1px">ユーザー招待</DrawerHeader>
      <TenantUsersInviteForm onClose={onClose}/>
      <DrawerCloseButton />
    </DrawerContent>
  </>
));

export const TenantUsersInviteDrawer = withSuspenseAndErrorBoundary(tenantUsersInviteDrawer, {
  ErrorComponent: <ErrorContents name="ユーザー招待" />,
  LoadingComponent: <LoadingSpinner />
});
