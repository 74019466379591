import { deleteUser } from 'admin/api/user/deleteUser';
import { CustomError } from 'admin/error/CustomError';
import { useUserCorporationId } from 'admin/hooks/user/useUserCorporationId';
import { queryUsersKey } from 'admin/hooks/users/queryUsersKey';
import { UserDeleteFormType } from 'admin/types/users';
import { logger } from 'api/logger';
import { useCustomToast } from 'hooks/useCustomToast';
import { useMutation, useQueryClient } from 'react-query';
import { errorToast, successToast } from 'utils/toast';

export const useDeleteUser = () => {
  const corporationId = useUserCorporationId();
  const toast = useCustomToast();
  const queryClient = useQueryClient();
  const { mutateAsync, isLoading } = useMutation({
    mutationFn: (data: UserDeleteFormType) => deleteUser(data, corporationId),
    onSuccess: async () => {
      await queryClient.invalidateQueries(queryUsersKey.getUsers(corporationId))
      toast({
        ...successToast,
        duration: 4000,
        title: 'ユーザーを削除しました',
        position: 'bottom',
        variant: 'solid',
      });
    },
    onError: async (error) => {
      if (error instanceof CustomError) {
        await logger({
          loglevel: error.logLevel,
          data: JSON.stringify(error.cause),
          message: error.message,
        });
      }
      toast({
        ...errorToast,
        duration: 4000,
        title: 'ユーザー削除に失敗しました',
        position: 'bottom',
        variant: 'solid',
      });
    },
  });

  return { mutate: mutateAsync, isLoading };
};
