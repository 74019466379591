import { zodResolver } from '@hookform/resolvers/zod';
import { withSuspenseAndErrorBoundary } from 'admin/components/Error/WithErrorBoundary';
import { LoadingLayer } from 'admin/components/ui/loadingLayer';
import { useChangeVersion } from 'admin/hooks/userPool/terms/useChangeVersion';
import { versionupFormSchema } from 'admin/schema/idPoolConsumer/terms';
import { TermType } from 'admin/types/userPool/terms';
import { VersionupFormType } from 'admin/types/userPool/terms/form';
import { FC, memo } from 'react';
import { FormProvider, SubmitHandler, useForm } from 'react-hook-form';
import { VersionupModalComponent } from './VersionupModalComponent';

type Props = {
  onClose: () => void;
  term: TermType | null;
};

const FetchVersionupModal: FC<Props> = memo(({ onClose, term }) => {
  const { mutate, isLoading } = useChangeVersion(term?.id ?? '');
  const methods = useForm<VersionupFormType>({
    defaultValues: {
      message: '',
      memo: '',
    },
    mode: 'onBlur',
    resolver: zodResolver(versionupFormSchema),
  });

  const onSubmit: SubmitHandler<VersionupFormType> = async (
    value: VersionupFormType,
  ) => {
    await mutate({
      version: typeof term?.version === 'number' ? term?.version : 1,
      memo: value.memo,
    });
    onClose();
  };

  return (
    <FormProvider {...methods}>
      {isLoading && <LoadingLayer />}
      <VersionupModalComponent
        onClose={onClose}
        onSubmit={onSubmit}
        term={term}
        isLoading={isLoading}
      />
    </FormProvider>
  );
});

export const VersionupModal = withSuspenseAndErrorBoundary(FetchVersionupModal);
