import { CustomMenuList, CustomMenuListItem } from 'components/chakra/sidemenu';
import { usePaywallAuth } from 'components/paywall/hooks/usePaywallAuth';
import { TransactionMailIcon } from 'components/transactionMail/TransactionMailIcon';
import {
  AUTH_CODE_TYPE_UNIIKEY,
  CONTENTS_SHELF,
  FEATURE_DASHBOARD,
  FEATURE_ENQUETE,
  FEATURE_MAIL,
  FEATURE_PAYWALL,
  FEATURE_SEGMENT,
  PERMISSION_DIRECTOR,
} from 'define';
import { useUserInfo } from 'hooks/useUserInfo';
import { FC, memo } from 'react';
import { IconType } from 'react-icons';
import { AiOutlineDashboard } from 'react-icons/ai';
import { FiHelpCircle, FiSettings } from 'react-icons/fi';
import { IoClipboardOutline, IoMailOutline } from 'react-icons/io5';
import {
  MdOutlineAccountTree,
  MdOutlineAccountBalanceWallet,
  MdSegment,
} from 'react-icons/md';
import { VscBook } from 'react-icons/vsc';
import { useLocation } from 'react-router-dom';
import { useRecoilState } from 'recoil';
import { paywallSidebarState } from 'store/paywallSidebarState';

export const SideMenuMain: FC = memo(() => {
  const location = useLocation();
  const { hasFeature, userTenant } = useUserInfo();
  const [_, setPaywallMenuOpen] = useRecoilState(paywallSidebarState);
  const { isAllowedPermission } = usePaywallAuth();

  return (
    <CustomMenuList flexGrow={1}>
      {hasFeature(FEATURE_DASHBOARD) && (
        <CustomMenuListItem
          to="/"
          name="ダッシュボード"
          icon={AiOutlineDashboard}
          isActive={
            !location.pathname.includes('/contentsdb') &&
            !location.pathname.includes('/enquete') &&
            !location.pathname.includes('/mail') &&
            !location.pathname.includes('/segment') &&
            !location.pathname.includes('/setting') &&
            !location.pathname.includes('/paywall') &&
            !location.pathname.includes('/authHub')
          }
        />
      )}
      {hasFeature(CONTENTS_SHELF) && (
        <CustomMenuListItem
          to="/contentsdb/"
          name="コンテンツシェルフ"
          icon={VscBook}
          isActive={location.pathname.includes('/contentsdb')}
        />
      )}
      {hasFeature(FEATURE_ENQUETE) && (
        <CustomMenuListItem
          to="/enquete/?page=1"
          name="フォーム"
          icon={IoClipboardOutline}
          isActive={location.pathname.includes('/enquete')}
        />
      )}
      {hasFeature(FEATURE_MAIL) && (
        <CustomMenuListItem
          to="/mail/?page=1"
          name="メール一覧"
          icon={IoMailOutline}
          isActive={
            location.pathname.includes('/mail') &&
            !location.pathname.includes('/mailMagazine') &&
            !location.pathname.includes('/paywall/mail')
          }
        />
      )}
      {hasFeature(FEATURE_SEGMENT) && (
        <CustomMenuListItem
          to="/segment/"
          name="セグメント設定"
          icon={MdSegment}
          isActive={location.pathname.includes('/segment')}
        />
      )}
      {hasFeature(FEATURE_PAYWALL) && isAllowedPermission('top') && (
        <CustomMenuListItem
          to="/paywall/dashboard/"
          name="ペイウォール設定"
          icon={MdOutlineAccountBalanceWallet}
          isActive={location.pathname.includes('/paywall')}
          onClick={() => setPaywallMenuOpen(true)}
        />
      )}
      {userTenant?.permission &&
        userTenant?.permission >= PERMISSION_DIRECTOR &&
        userTenant?.userPoolAuthenticationType === AUTH_CODE_TYPE_UNIIKEY && (
          <CustomMenuListItem
            to="/transactionMail/"
            name="初回ログイン時メール設定"
            icon={TransactionMailIcon as IconType}
            isActive={location.pathname.includes('/transactionMail')}
          />
        )}
      {userTenant?.permission &&
        userTenant?.permission >= PERMISSION_DIRECTOR &&
        userTenant?.userPoolAuthenticationType === AUTH_CODE_TYPE_UNIIKEY && (
          <CustomMenuListItem
            to="/authHub/"
            name="Auth Hub設定"
            icon={MdOutlineAccountTree}
            isActive={location.pathname.includes('/authHub')}
          />
        )}
    </CustomMenuList>
  );
});

export const SideMenuBottom: FC = memo(() => {
  const location = useLocation();

  return (
    <CustomMenuList>
      <CustomMenuListItem
        href="https://uniikey.notion.site/Uniikey-Experience-Cloud-da7a975922ae43aa8372a703b3ec3e27"
        name="ヘルプ"
        tLabel="ヘルプ：利用マニュアル・問い合わせ（別タブで開く）"
        icon={FiHelpCircle}
        isActive={false}
      />
      <CustomMenuListItem
        to="/setting/"
        name="設定"
        icon={FiSettings}
        isActive={location.pathname.includes('/setting')}
      />
    </CustomMenuList>
  );
});
