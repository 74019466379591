import { VFC, useEffect } from 'react';
import { useFormContext } from 'react-hook-form';
import { FormLabel, Textarea, Box } from '@chakra-ui/react';
import { ContentsDbTblFormType } from 'api/contents/types';

export const TextTableMemo: VFC<{ value?: string }> = ({ value = '' }) => {
  const { register, setValue } = useFormContext<ContentsDbTblFormType>();
  const name = 'memo';

  useEffect(() => {
    if (value) {
      setValue(name, value);
    }
  }, [setValue, name, value]);

  return (
    <Box>
      <FormLabel htmlFor="desc">メモ</FormLabel>
      <Textarea id="desc" {...register(name)} />
    </Box>
  );
};
