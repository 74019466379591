import { zodResolver } from '@hookform/resolvers/zod';
import { withSuspenseAndErrorBoundary } from 'admin/components/Error/WithErrorBoundary';
import { CodeCreateFormComponent } from 'admin/components/idPoolConsumer/code/codeForm/codeCreateForm/CodeCreateFormComponent';
import { useCreateCodeSetting } from 'admin/hooks/userPool/code/useCreateCodeSetting';
import { createCodeSchema } from 'admin/schema/idPoolConsumer/code';
import { CreateCodeFormType } from 'admin/types/userPool/code/form';
import { AlertBar } from 'components/common/atoms';
import { FC, memo, useMemo, useState } from 'react';
import { FormProvider, SubmitHandler, useForm } from 'react-hook-form';
import { useParams } from 'react-router-dom';

type Props = {
  onClose: () => void;
  formId: string;
};

const FetchCodeCreateForm: FC<Props> = memo(({ onClose, formId }: Props) => {
  const params = useParams();
  const codeGroupId = useMemo(() => params.masterId ?? '', [params]);

  const methods = useForm<CreateCodeFormType>({
    defaultValues: {
      codeGroup: codeGroupId,
      displayNameJa: '',
      displayNameForeign: '',
      code: '',
      displayOrder: '',
    },
    resolver: zodResolver(createCodeSchema),
  });
  const [globalErrors, setGlobalErrors] = useState<string[]>([]);

  const { mutate, isLoading } = useCreateCodeSetting(
    methods.setError,
    setGlobalErrors,
  );

  const onSubmit: SubmitHandler<CreateCodeFormType> = async (
    data: CreateCodeFormType,
  ) => {
    if (!isLoading) {
      await mutate(data);
    }
    methods.reset();
    onClose();
  };

  return (
    <FormProvider {...methods}>
      {globalErrors.map((err, idx) => (
        <AlertBar
          key={`global-err-idx${String(idx)}`}
          message={err}
          status="error"
          mb={4}
        />
      ))}
      <CodeCreateFormComponent
        onSubmit={onSubmit}
        formId={formId}
        isLoading={isLoading}
      />
    </FormProvider>
  );
});

export const CodeCreateForm = withSuspenseAndErrorBoundary(FetchCodeCreateForm);
