import { FC, memo } from 'react';
import { Box } from '@chakra-ui/react';
import { useUserTenantId } from 'hooks/user/useUserTenantId';
import { useApiKey } from 'hooks/tenant/useApiKey';
import { Apikey } from 'components/setting/apikey/organisms/Apikey';
import { AccessSecretKey } from 'components/setting/apikey/organisms/AccessSecretKey';
import { ApikeyIssue } from 'components/setting/apikey/organisms/ApikeyIssue';

export const ApiKeyBody: FC = memo(() => {
  const tenantId = useUserTenantId();
  const tenantApiKey = useApiKey({ tenantId });

  return (
    <>
      <Box w="100%" p={4}>
        <Apikey tenantApiKey={tenantApiKey} />
        <AccessSecretKey tenantApiKey={tenantApiKey} />
        <ApikeyIssue />
      </Box>
    </>
  );
});
