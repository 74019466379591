import { FC, memo, useCallback, useEffect, useState } from 'react';
import { useFormContext } from 'react-hook-form';
import {
  Box,
  Flex,
  Grid,
  Text,
  Stack,
  Textarea,
  Tooltip,
} from '@chakra-ui/react';
import { QuestionIcon } from '@chakra-ui/icons';
import { FEATURE_GIGYA } from 'define';
import { useUserInfo } from 'hooks/useUserInfo';
import { ColorPicker, ErrorTextMsg } from 'components/common/atoms';
import { InputForm, RadioForm } from 'components/common/molecules';
import { MailMagazineForm } from 'components/setting/mailMagazine/typed';
import { toErrMsgList } from 'utils/form';

type Props = {
  formId: string;
  submit: (data: MailMagazineForm) => void;
  isAdd: boolean | undefined;
};

/**
 * @see メルマガの新規作成＆編集フォーム
 * @param {string} formId 新規作成 or 編集
 * @param {function} submit 新規作成API or 編集API
 */
export const Form: FC<Props> = memo(({ formId, submit, isAdd }) => {
  const [description, setDescription] = useState('');
  const { hasFeature } = useUserInfo();
  const [disabled, setDisabled] = useState(false);
  const {
    setValue,
    getValues,
    handleSubmit,
    clearErrors,
    formState: { errors },
  } = useFormContext<MailMagazineForm>();

  useEffect(() => {
    setDescription(getValues('description') || '');
  }, [setDescription, getValues]);

  const onChangeDescription = useCallback(
    (val: string) => {
      clearErrors('description');
      setDescription(val);
      setValue('description', val);
    },
    [setValue, setDescription, clearErrors],
  );

  // メルマガ種別
  const mailMagazineTypeRadioList = useCallback(() => {
    const list = [];
    if (hasFeature(FEATURE_GIGYA)) {
      list.push({
        id: 1,
        value: 1,
        element: <Text>会員ID</Text>,
        isDisabled: !isAdd,
      });
    }
    list.push({
      id: 2,
      value: 2,
      element: <Text>メールアドレス</Text>,
      isDisabled: !isAdd,
    });

    return list;
  }, [hasFeature, isAdd]);

  const onChangeRadio = useCallback(
    (val) => {
      setDisabled(val === 2);
    },
    [setDisabled],
  );

  return (
    <form id={formId} onSubmit={handleSubmit((data) => submit(data))}>
      <Grid gap={5}>
        <Stack spacing={8} mt={4}>
          <InputForm<MailMagazineForm>
            name="title"
            type="text"
            label="メルマガ名"
            labelElement={
              <Text my={2} fontSize="xs">
                フォーム機能で配信許諾の取得・解除ができるメルマガを管理できます。作成したメルマガはメール配信設定で配信対象として選択できるようになります。
                <br />
                ※Uniikey会員登録時・Uniikeyラウンジで許諾を取得するメルマガとは別の機能になります。
              </Text>
            }
          />

          <Box>
            <Text mb={2}>種別</Text>
            <Text mb={2} fontSize="xs">
              種別は一度保存すると後から修正ができません。会員IDの場合はオープンフォームでの購読取得ができません。
            </Text>
            <RadioForm<MailMagazineForm>
              name="targetType"
              attr={{
                required: '',
              }}
              radioList={mailMagazineTypeRadioList()}
              contains
              onChangeRadio={onChangeRadio}
            />
            {toErrMsgList({ message: errors.targetType }, 'targetType').map(
              (err, idx) => (
                <ErrorTextMsg
                  key={`error-mail-magazine-targetType-${String(idx)}`}
                  msg={err}
                />
              ),
            )}
          </Box>

          <InputForm<MailMagazineForm>
            name="sort"
            type="text"
            min="0"
            label="表示順"
            labelElement={
              <Tooltip
                label={
                  <Text fontSize="xs" textIndent="-1em" ml="1em">
                    メルマガマイページ機能を利用する場合や管理画面上で、この数字が小さい順に表示されます。
                    数字は連番でなくて10,20といった連続しない値で設定可能です。
                  </Text>
                }
                bg="teal.50"
                color="gray.800"
                size="xs"
              >
                <QuestionIcon
                  color="#536DB9"
                  ml={4}
                  style={{ cursor: 'pointer' }}
                  size="md"
                />
              </Tooltip>
            }
          />

          <Flex alignItems="flex-end">
            <InputForm<MailMagazineForm>
              label="トグルボタン色"
              name="color"
              placeholder="#333333"
              mr={4}
              isDisabled={disabled}
              labelElement={
                <Tooltip
                  label={
                    <Text fontSize="xs" textIndent="-1em" ml="1em">
                      トグルボタンの背景色になるため、濃い色を指定ください。白や薄いグレー系はON/OFFの判別がつかなくなるので避けてください。
                    </Text>
                  }
                  bg="teal.50"
                  color="gray.800"
                  size="xs"
                >
                  <QuestionIcon
                    color="#536DB9"
                    ml={4}
                    style={{ cursor: 'pointer' }}
                    size="md"
                  />
                </Tooltip>
              }
            />
            <ColorPicker<MailMagazineForm>
              name="color"
              value={getValues('color') || '#333333'}
              width="40px"
              height="40px"
              isDisabled={disabled}
            />
          </Flex>

          <Box>
            <Text mb={2}>
              メルマガの説明文章
              <Tooltip
                label={
                  <Text fontSize="xs" textIndent="-1em" ml="1em">
                    会員IDメールで、メルマガマイページ機能を利用する場合にこの文章が表示されます。利用しない場合には入力不要です。
                  </Text>
                }
                bg="teal.50"
                color="gray.800"
                size="xs"
              >
                <QuestionIcon
                  color="#536DB9"
                  ml={4}
                  style={{ cursor: 'pointer' }}
                  size="md"
                />
              </Tooltip>
            </Text>
            <Textarea
              value={description}
              border={`${errors.description ? '2px' : '1px'} solid`}
              borderColor={errors.description ? '#E53E3E' : 'gray.200'}
              onChange={(e) => onChangeDescription(e.target.value)}
              size="sm"
              resize="none"
              borderRadius="md"
              minH="150px"
              isDisabled={disabled}
            />
            {toErrMsgList(errors, 'description').map((err, idx) => (
              <ErrorTextMsg
                key={`error-mail-magazine-description-${String(idx)}`}
                msg={err}
              />
            ))}
          </Box>
        </Stack>
      </Grid>
    </form>
  );
});
