import { withSuspenseAndErrorBoundary } from 'admin/components/Error/WithErrorBoundary';
import { TransactionMailStatusChangeFormComponent } from 'admin/components/idPoolConsumer/transactionMail/statusChangeForm/transactionMailStatusChangeForm/TransactionMailStatusChangeFormComponent';
import { LoadingLayer } from 'admin/components/ui/loadingLayer';
import { useStatusChangeMail } from 'admin/hooks/userPool/transactionMail/useStatusChangeMail';
import { TransactionMailType } from 'admin/types/userPool/transactionMail';
import { TransactionMailEditFormType } from 'admin/types/userPool/transactionMail/form';
import { FC, memo } from 'react';
import { FormProvider, SubmitHandler, useForm } from 'react-hook-form';

type Props = {
  transactionMail: TransactionMailType;
  onClose: () => void;
};

export const transactionMailStatusChangeForm: FC<Props> = memo(
  ({ transactionMail, onClose }) => {
    const formId = 'transactionMailStatusChangeForm';
    const { mutate, isLoading } = useStatusChangeMail(transactionMail.type);

    const methods = useForm<TransactionMailEditFormType>({
      defaultValues: {
        ...transactionMail,
        status: !transactionMail.status,
      },
      mode: 'onBlur',
    });

    const onSubmit: SubmitHandler<TransactionMailEditFormType> = async (
      data: TransactionMailEditFormType,
    ) => {
      await mutate(data);
      onClose();
    };

    return (
      <FormProvider {...methods}>
        {isLoading && <LoadingLayer />}
        <TransactionMailStatusChangeFormComponent
          formId={formId}
          isLoading={isLoading}
          onClose={onClose}
          onSubmit={onSubmit}
        />
      </FormProvider>
    );
  },
);

export const TransactionMailStatusChangeForm = withSuspenseAndErrorBoundary(
  transactionMailStatusChangeForm,
);
