import { CodeSettingType } from 'admin/types/userPool/code';
import { CodeGroupType } from 'admin/types/userPool/master';

export const keyNames = {
  getCodeSettings: 'getCodeSettings',
  getCodeSetting: 'getCodeSetting',
};

export const queryKeys = {
  getCodeSettings: (
    codeGroupId: CodeGroupType['id'],
    page?: number,
    perPage?: number,
  ) => [keyNames.getCodeSettings, codeGroupId, page, perPage],
  getCodeSetting: (
    id: CodeSettingType['id'],
    codeGroupId: CodeGroupType['id'],
  ) => [keyNames.getCodeSetting, id, codeGroupId],
} as const;
