import {
  Box,
  Button,
  DrawerBody,
  DrawerFooter,
  FormControl,
  FormErrorMessage,
  Heading,
  Text,
  VStack,
} from '@chakra-ui/react';
import { RhfCheckbox } from 'admin/components/form/RhfCheckbox';
import { LoadingLayer } from 'admin/components/ui/loadingLayer';
import { Colors, ColorSet } from 'admin/define/colors';
import { useHasUniikey } from 'admin/hooks/service/useHasUniikey';
import { ServiceAttributeGroupChildType } from 'admin/types/service/attribute';
import { ServiceAttributeGroupFormType } from 'admin/types/service/attribute/form';
import { FC, memo } from 'react';
import { useFormContext, useFormState } from 'react-hook-form';
import { toErrMsgList } from 'utils/form';

type Props = {
  attributeGroup: ServiceAttributeGroupChildType;
  formId: string;
  isLoading: boolean;
  onClose: () => void;
  onSubmit: (value: ServiceAttributeGroupFormType) => void;
};

export const AttributeGroupEditFormComponent: FC<Props> = memo(
  ({ attributeGroup, formId, isLoading, onClose, onSubmit }: Props) => {
    const { handleSubmit } = useFormContext<ServiceAttributeGroupFormType>();
    const { errors } = useFormState<ServiceAttributeGroupFormType>();
    const { hasUniikey } = useHasUniikey();

    return (
      <>
        {isLoading && <LoadingLayer />}
        <DrawerBody pt="16px" pb="16px">
          <form onSubmit={handleSubmit(onSubmit)} id={formId}>
            <VStack spacing={6}>
              <Box w="100%">
                <Heading as="h3" fontSize="14px" mb="8px">
                  属性グループID
                </Heading>
                <Text
                  fontSize="16px"
                  color={Colors.GRAY_03}
                  pb="8px"
                  borderBottom="1px solid"
                  borderColor={Colors.GRAY_30}
                >
                  {attributeGroup?.attributeGroupHead.id}
                </Text>
              </Box>
              <Box w="100%">
                <Heading as="h3" fontSize="14px" mb="8px">
                  表示名
                </Heading>
                <Box
                  borderBottom="1px solid"
                  borderColor={Colors.GRAY_30}
                  pb="8px"
                >
                  <Box mb="8px">
                    <Text fontSize="12px" color={Colors.GRAY_00} mb="4px">
                      日本語
                    </Text>
                    <Text fontSize="16px">
                      {attributeGroup.attributeGroupHead.displayNameJa}
                    </Text>
                  </Box>
                  <Box>
                    <Text fontSize="12px" color={Colors.GRAY_00} mb="4px">
                      外国語
                    </Text>
                    <Text fontSize="16px">
                      {attributeGroup.attributeGroupHead.displayNameForeign}
                    </Text>
                  </Box>
                </Box>
              </Box>
              <Box w="100%">
                <Heading as="h3" fontSize="14px" mb="8px">
                  属性グループ利用設定
                </Heading>
                <Box mb={2}>
                  <RhfCheckbox
                    // ドロワーから内容を保存しないので暫定のUI対応
                    // 今後ドロワーで編集する対応が入った際に、テナント割当のformSchemaを含め修正が必要
                    name="attributeGroup.userPoolChildAttribute?.id"
                    isChecked={!!attributeGroup?.id}
                    isDisabled
                  >
                    <Text fontSize="14px">テナント割当</Text>
                  </RhfCheckbox>
                </Box>
                <Box mb={2}>
                  <FormControl isInvalid={Boolean(errors.isFormAttr)}>
                    <RhfCheckbox<ServiceAttributeGroupFormType>
                      name="isFormAttr"
                      isDisabled
                    >
                      <Text fontSize="14px">フォームで利用する</Text>
                    </RhfCheckbox>
                    {toErrMsgList(errors, 'isFormAttr').map((err) => (
                      <FormErrorMessage key={`isFormAttr_${err}`}>
                        {err}
                      </FormErrorMessage>
                    ))}
                  </FormControl>
                </Box>
                {hasUniikey && (
                  <Box mb={2}>
                    <FormControl isInvalid={Boolean(errors.isUserAttr)}>
                      <Box>
                        <RhfCheckbox<ServiceAttributeGroupFormType>
                          name="isUserAttr"
                          isDisabled
                        >
                          <Text fontSize="14px">会員属性として利用する</Text>
                        </RhfCheckbox>
                        <FormErrorMessage>{errors.isUserAttr}</FormErrorMessage>
                      </Box>
                    </FormControl>
                    <FormControl isInvalid={Boolean(errors.isUserAttrRequired)}>
                      <Box mt={2} ml={6}>
                        <RhfCheckbox<ServiceAttributeGroupFormType>
                          name="isUserAttrRequired"
                          isDisabled
                        >
                          <Text fontSize="14px">必須</Text>
                        </RhfCheckbox>
                        <FormErrorMessage>
                          {errors.isUserAttrRequired}
                        </FormErrorMessage>
                      </Box>
                    </FormControl>
                  </Box>
                )}
              </Box>
              {/* TODO: 24/04/11時点では必要がないため、コメントアウト */}
              {/* <Box w="100%">
              <FormControl isInvalid={Boolean(errors.sort?.message)}>
                <Heading as="h3" fontSize="14px" mb="8px">
                  並び順
                </Heading>
                <Box>
                  <RhfInput<ServiceAttributeGroupFormType>
                    name="sort"
                    bgColor={Colors.WHITE}
                  />
                  <FormErrorMessage>
                    {errors.sort?.message}
                  </FormErrorMessage>
                </Box>
              </FormControl>
            </Box> */}
            </VStack>
          </form>
        </DrawerBody>

        <DrawerFooter borderWidth="1px">
          <Button
            variant="outline"
            mr={3}
            onClick={onClose}
            p="16px 24px"
            w="120px"
            {...ColorSet.Default}
          >
            閉じる
          </Button>
          {/* <Button
            colorScheme="primary"
            type="submit"
            form={formId}
            p="16px 24px"
            w="120px"
          >
            保存
          </Button> */}
        </DrawerFooter>
      </>
    );
  },
);
