export const getCookieValue = (key: string): string | undefined => {
  if (typeof document.cookie !== 'string') {
    return undefined;
  }
  const ary: string[] = document.cookie.split(';');

  const searchResult = ary.find((cookie) => {
    const [k, _] = cookie.split('=');

    return k.trim().toLowerCase() === key.toLowerCase();
  });

  if (searchResult === undefined) {
    return undefined;
  }

  const [_, value] = searchResult.split('=');

  return value;
};

export const deleteCookieAll = () => {
  const ary: string[] = document.cookie.split(';');

  ary.forEach((cookie) => {
    const [k, _] = cookie.split('=');
    document.cookie = `${k.trim().toLowerCase()}=; max-age=0`;
  });
};
