import { Box, Tr as ChakraTr } from '@chakra-ui/react';
import { TransactionMailsTableInner } from 'admin/components/idPoolConsumer/transactionMail/transactionMailsTableInner';
import { Table, Tbody, Th, Thead } from 'admin/components/ui/table';
import {
  TransactionMailsType,
  TransactionMailType,
} from 'admin/types/userPool/transactionMail';
import { FC, memo } from 'react';

type Props = {
  transactionMails: TransactionMailsType;
  transactionMailType: string;
  onOpenModal: (transactionMail: TransactionMailType) => void;
};

export const TransactionMailsTableComponent: FC<Props> = memo(
  ({ transactionMails, transactionMailType, onOpenModal }) => (
    <Box mt="16px">
      <Table w="100%">
        <Thead h="53px">
          <ChakraTr>
            <Th style={{ minWidth: '120px', width: '120px' }}>画面ID</Th>
            <Th style={{ minWidth: '200px', width: 'auto' }}>件名</Th>
            <Th style={{ minWidth: '200px', width: 'auto' }}>設定名</Th>
            <Th
              style={{
                minWidth: '110px',
                width: '110px',
                textAlign: 'left',
              }}
            >
              使用設定
            </Th>
          </ChakraTr>
        </Thead>
        <Tbody>
          {transactionMails.map((transactionMail, idx) => (
            <TransactionMailsTableInner
              transactionMail={transactionMail}
              transactionMailType={transactionMailType}
              key={`${transactionMail.id}_${String(idx)}`}
              onOpenModal={onOpenModal}
            />
          ))}
        </Tbody>
      </Table>
    </Box>
  ),
);
