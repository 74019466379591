/* eslint-disable react/require-default-props */
import {
  BoxProps, Tr as ChakraTr
} from '@chakra-ui/react';
import { Colors } from 'components/paywall/config';
import { FC, memo } from 'react';


type Props = {
  children?: React.ReactNode
} & BoxProps;

export const Tr: FC<Props> = memo(({children, ...rest}: Props) => (
  <ChakraTr
    px="16px"
    backgroundColor={Colors.WHITE}
    _hover={{
      backgroundColor: Colors.GRAY_80
    }}
    {...rest}
  >
    {children}
  </ChakraTr>

))
