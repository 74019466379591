import {
  Box,
  DrawerCloseButton,
  DrawerContent,
  DrawerHeader,
  DrawerOverlay,
  Text,
} from '@chakra-ui/react';
import { withSuspenseAndErrorBoundary } from 'admin/components/Error/WithErrorBoundary';
import {
  AttributeGroupCreateForm,
  AttributeGroupEditForm,
} from 'admin/components/service/attribute/attributeGroupForm';
import { useServiceAttributeGroupDetail } from 'admin/hooks/service/attribute/useServiceAttributeGroupDetail';
import {
  ServiceAttributeGroupType,
  UserPoolChildAttributeType,
} from 'admin/types/service/attribute';
import { ErrorContents, LoadingSpinner } from 'components/common/atoms';
import { FC, memo } from 'react';

type FetchAttributeGroupProps = {
  id: UserPoolChildAttributeType['id'];
  onClose: () => void;
};

const fetchAttributeGroupDrawer: FC<FetchAttributeGroupProps> = memo(
  ({ id, onClose }: FetchAttributeGroupProps) => {
    const { data: attributeGroup } = useServiceAttributeGroupDetail(id);

    if (!attributeGroup) {
      return null;
    }

    return (
      <AttributeGroupEditForm
        attributeGroup={attributeGroup}
        onClose={onClose}
      />
    );
  },
);

const FetchAttributeGroupDrawer = withSuspenseAndErrorBoundary(
  fetchAttributeGroupDrawer,
  {
    ErrorComponent: <ErrorContents name="属性グループ利用設定" />,
    LoadingComponent: <LoadingSpinner />,
  },
);

type Props = {
  serviceAttributeGroup: ServiceAttributeGroupType | null;
  id: UserPoolChildAttributeType['id'];
  onClose: () => void;
};

const AttributeGroupFormWrap: FC<Props> = memo(
  ({ serviceAttributeGroup, id, onClose }: Props) => {
    // 未割当時（テナント未紐づけ）
    if (!id) {
      return (
        <AttributeGroupCreateForm
          serviceAttributeGroup={serviceAttributeGroup}
          onClose={onClose}
        />
      );
    }

    // 割当済み時（テナント紐づけ済み）
    return <FetchAttributeGroupDrawer id={id} onClose={onClose} />;
  },
);

export const AttributeGroupDrawer: FC<Props> = memo(
  ({ serviceAttributeGroup, id, onClose }: Props) => (
    <>
      <DrawerOverlay />
      <DrawerContent>
        <DrawerHeader pb={0}>
          <Box m={0} px={0} pb="24px" borderBottomWidth="1px">
            <Text>属性グループ利用設定</Text>
          </Box>
        </DrawerHeader>
        <AttributeGroupFormWrap
          serviceAttributeGroup={serviceAttributeGroup}
          id={id}
          onClose={onClose}
        />
        <DrawerCloseButton top="16px" right="24px" />
      </DrawerContent>
    </>
  ),
);
