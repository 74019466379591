import { Box } from '@chakra-ui/react';
import { PaginationComponent } from 'admin/components/ui/pagination/PaginationComponent';
import { INITIAL_CURRENT_PER_PAGE, useCurrentPerPage } from 'admin/hooks/pagination/useCurrentPerPage';
import { usePaginationQuery } from 'admin/hooks/pagination/usePaginationQuery';
import { Page } from 'api/common/types';
import {
  usePaginator
} from 'chakra-paginator';
import { ChangeEvent, FC, memo, useCallback, useEffect, useMemo } from 'react';

const defaultPerPageList: number[] = [50, 100];

type Props = {
  page: Page;
  perPageList?: number[];
  defaultPerPage?: number;
};

export const Pagination: FC<Props> = memo(({
  page,
  perPageList = defaultPerPageList,
  defaultPerPage = INITIAL_CURRENT_PER_PAGE,
}: Props) => {
  const {query, onPageChange, onChangePerPage} = usePaginationQuery({page});
  const perPage = useCurrentPerPage(defaultPerPage);
  const { pagesQuantity, currentPage, setCurrentPage } = usePaginator({
    total: page.count,
    initialState: {
      pageSize: page.perPage,
      currentPage: query.currentPage,
    },
  });

  const totalPage = useMemo(() => {
    if(!page || !page.pageCount) return pagesQuantity

    return page.pageCount
  }, [page, pagesQuantity]);

  const onPageChangeHandler = useCallback(
    (nextPage: number) => {
      onPageChange(nextPage);
    },
    [onPageChange]
  );

  const onPerPageChangeHandler = useCallback(
    (event: ChangeEvent<HTMLSelectElement>) => {
      onChangePerPage(Number(event.target.value));
      if (query.currentPage !== 1) {
        onPageChangeHandler(1);
      }
    },
    [onChangePerPage, onPageChangeHandler, query.currentPage],
  );

  useEffect(() => {
    setCurrentPage(query.currentPage);
  }, [query.currentPage, setCurrentPage]);

  return (
    <Box w="100%" textAlign="center" mt={4}>
      <PaginationComponent
        currentPage={currentPage}
        totalPage={totalPage}
        currentPerPage={perPage}
        perPageList={perPageList}
        onPageChangeHandler={onPageChangeHandler}
        onPerPageChangeHandler={onPerPageChangeHandler}
      />
    </Box>
  )
});

Pagination.defaultProps = {
  perPageList: defaultPerPageList,
  defaultPerPage: INITIAL_CURRENT_PER_PAGE,
}
