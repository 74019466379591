import { Badge, Box, HStack, Icon, Stack, Text, useDisclosure } from '@chakra-ui/react';
import { GmoInfo } from 'api/tenant/types';
import { DrawerForm } from 'components/common/atoms';
import { DoubleConfirmDialog, InputForm } from 'components/common/molecules';
import { Colors, ColorSet } from 'components/paywall/config';
import { PaymentAgencyResponseDialog } from 'components/setting/paymentAgency/PaymentAgencyResponseDialog';
import { useGmoEdit } from 'hooks/tenant/useGmoEdit';
import { useUserTenantId } from 'hooks/user/useUserTenantId';
import { FC, memo, useCallback, useState } from 'react';
import { FormProvider, useForm } from 'react-hook-form';
import { MdCreate } from 'react-icons/md';

type Props = {
  gmoInfo: GmoInfo;
};

/**
 * 表示名入力用バリデーション
 */
const ShopIdValidateAttr = {
  required: 'ショップIDを入力してください',
};
const ShopPassValidateAttr = {
  required: 'ショップパスワードを入力してください',
};
const SiteIdValidateAttr = {
  required: 'サイトIDを入力してください',
};
const SitePassValidateAttr = {
  required: 'サイトパスワードを入力してください',
};

const defaultValues: GmoInfo = {
  gmoShopId: '',
  gmoShopPass: '',
  gmoSiteId: '',
  gmoSitePass: '',
};

// 必須表示
export const RequiredBadge: FC = () => (
  <Badge
    border="1px solid #EC0000"
    bgColor={Colors.WHITE}
    color={Colors.RED_60}
    borderRadius="4px"
    px="8px"
    minW="56px"
    textAlign="center"
  >
    必須
  </Badge>
)

export const PaymentAgencyEdit: FC<Props> = memo(
  ({ gmoInfo }: Props) => {
    const tenantId = useUserTenantId();
    const { isOpen, onClose, onOpen } = useDisclosure();
    const { isOpen: isDialogOpen, onClose: onDialogClose, onOpen: onDialogOpen } = useDisclosure();
    const { isOpen: isCbDialogOpen, onClose: onCbDialogClose, onOpen: onCbDialogOpen } = useDisclosure();
    const [dialogErrors, setDialogErrors] = useState<string[]>([]);
    const [submitGmoData, setSubmitGmoData] = useState<GmoInfo>(defaultValues);
    const methods = useForm<GmoInfo>({
      mode: 'onBlur',
      defaultValues,
    });
    const {
      setValue,
      formState: { errors },
      setError,
      handleSubmit,
      clearErrors,
      reset
    } = methods;
    const formId = 'paymentAgencySetting-form';

    // 編集ドロワーを閉じる処理
    const closeBtnHandler = useCallback(() => {
      onClose();
      clearErrors();
      reset();
    }, [onClose, clearErrors, reset]);

    // APIレスポンス表示ダイアログを閉じる処理
    const cbCloseBtnHandler = useCallback(() => {
      if(dialogErrors.length === 0) {
        onClose();
        clearErrors();
        reset();
      }
    }, [onClose, clearErrors, reset, dialogErrors]);

    // APIリクエストが終了後に表示するモーダル
    const onOpenCbResponseModal = () => {
      onCbDialogOpen();
    }

    // gmo情報を登録
    const { onSubmit, isLoading } = useGmoEdit({
      tenantId,
      setError,
      setDialogErrors,
      onDialogClose,
      onOpenCbResponseModal
    });

    const isFailed = useCallback(
      (): boolean => Object.keys(errors).length > 0,
      [errors],
    );

    // ドロワーを開くときの処理
    const onClickEdit = useCallback(() => {
      setValue('gmoShopId', gmoInfo.gmoShopId);
      setValue('gmoSiteId', gmoInfo.gmoSiteId);
      onOpen();
    }, [onOpen, setValue, gmoInfo.gmoShopId, gmoInfo.gmoSiteId]);

    // フォーム送信する処理
    const formSubmit = useCallback(
      () => {
        onSubmit(submitGmoData);
      },
      [submitGmoData, onSubmit],
    );

    // ドロワーの保存・変更ボタン押下処理
    // 確認ダイアログを開いた後にダイアログ側でsubmitを行う
    const onOpenBeforeSubmitDialog = useCallback(
      (data: GmoInfo) => {
        setSubmitGmoData(data);
        onDialogOpen();
      },
      [onDialogOpen],
    );

    return (
      <Box display="flex" alignItems="center">
        <Text fontSize="xl" mr={1}>
          GMO-PG API設定
        </Text>
        <FormProvider {...methods}>
          <DrawerForm
            title="GMO-PG API設定"
            openBtnChildNode={<Icon as={MdCreate} />}
            openBtnProps={{ variant: 'ghost' }}
            cancelBtnTitle="キャンセル"
            cancelBtnHandelr={closeBtnHandler}
            cancelBtnProps={{
              padding: "8px 16px",
              disabled: isLoading
            }}
            submitBtnTitle='保存・変更'
            submitBtnProps={{
              padding: "8px 16px",
              disabled: isFailed(),
              isLoading,
              ...ColorSet.BLUE
            }}
            isOpen={isOpen}
            onOpen={onClickEdit}
            onClose={closeBtnHandler}
            closeOnOverlayClick={false}
            closeOnEsc={false}
            autoFocus={false}
            size="md"
            formId={formId}
            drawerCloseButtonProps={{ disabled: isLoading }}
            drawerFooterJustify="space-between"
          >
            <form
              id={formId}
              onSubmit={handleSubmit((data) => onOpenBeforeSubmitDialog(data))}
            >
              <HStack alignItems="flex-start" color={Colors.GRAY_00}>
                <Text>※</Text>
                <Text>
                  サイトパスワード、ショップパスワードはGMO-PG管理画面で「サイト（ショップ）管理 ＞ サイトパスワード」で表示されるものを記入してください。GMO管理画面のログインするためのパスワードとは別のものです。
                  <br/>
                  <br/>
                  セキュリティ上、パスワードは表示していません。新規登録・更新が必要な場合にのみ入力のうえ、保存してください。正常に疎通確認できた内容のみ保存できます。
                </Text>
              </HStack>
              <Stack spacing={8} mt={4}>
                <InputForm<GmoInfo>
                  name="gmoShopId"
                  type="text"
                  labelElement={
                    <HStack>
                      <Text as="span">ショップID</Text>
                      <RequiredBadge />
                    </HStack>
                  }
                  attr={ShopIdValidateAttr}
                />
                <InputForm<GmoInfo>
                  name="gmoShopPass"
                  type="password"
                  labelElement={
                    <HStack>
                      <Text as="span">ショップパスワード</Text>
                      <RequiredBadge />
                    </HStack>
                  }
                  attr={ShopPassValidateAttr}
                />
                <InputForm<GmoInfo>
                  name="gmoSiteId"
                  type="text"
                  labelElement={
                    <HStack>
                      <Text as="span">サイトID</Text>
                      <RequiredBadge />
                    </HStack>
                  }
                  attr={SiteIdValidateAttr}
                />
                <InputForm<GmoInfo>
                  name="gmoSitePass"
                  type="password"
                  labelElement={
                    <HStack>
                      <Text as="span">サイトパスワード</Text>
                      <RequiredBadge />
                    </HStack>
                  }
                  attr={SitePassValidateAttr}
                />
              </Stack>
            </form>
          </DrawerForm>
        </FormProvider>
        <DoubleConfirmDialog
          title="決済代行連携情報の設定"
          submitBtnTitle="保存・変更"
          cancelBtnTitle="キャンセル"
          descriptionPrefix="保存"
          warningTexts={[
            'IDやパスワードを変更すると、即時に切り替わります。',
            'すでに設定済みの場合には、必要がない限りは変更しないでください。',
            '入力した内容で決済代行側と通信できるか確認します。疎通確認が失敗した場合には設定は保存されません。',
          ]}
          isLoading={isLoading}
          isOpen={isDialogOpen}
          onClose={onDialogClose}
          submitBtnHandler={formSubmit}
        />
        <PaymentAgencyResponseDialog
          errors={dialogErrors}
          isOpen={isCbDialogOpen}
          onOpen={onCbDialogOpen}
          onClose={onCbDialogClose}
          onDrawerClose={cbCloseBtnHandler}
        />
      </Box>
    );
  },
);
