import {
  AlertDialogCloseButton,
  AlertDialogContent,
  AlertDialogHeader,
  AlertDialogOverlay,
  Box,
  Text,
} from '@chakra-ui/react';
import { UserAttrRequiredForm } from 'admin/components/service/attribute/attributeForm/userAttrRequiredForm';
import {
  ServiceAttributeType,
  UserPoolChildAttributeType,
} from 'admin/types/service/attribute';
import { FC, memo } from 'react';

type Props = {
  id: UserPoolChildAttributeType['id'];
  target: ServiceAttributeType | null;
  onClose: () => void;
};

export const UserAttrRequiredDialog: FC<Props> = memo((props) => (
  <AlertDialogOverlay>
    <AlertDialogContent>
      <AlertDialogHeader p={6}>
        <Box>
          <Text>
            会員登録時必須の
            {!props.target?.userPoolChildAttribute?.isUserAttrRequired
              ? '有効化'
              : '無効化'}
          </Text>
        </Box>
      </AlertDialogHeader>
      <UserAttrRequiredForm {...props} />
      <AlertDialogCloseButton top="16px" right="24px" />
    </AlertDialogContent>
  </AlertDialogOverlay>
));
