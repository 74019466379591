import { editTransactionMail } from 'admin/api/userPool/transactionMail/editTransactionMail';
import { CustomError } from 'admin/error/CustomError';
import { useIdPoolConsumerId } from 'admin/hooks/useIdPoolConsumerId';
import { TransactionMailCategoryType } from 'admin/types/userPool/transactionMail';
import { TransactionMailEditFormType } from 'admin/types/userPool/transactionMail/form';
import { logger } from 'api/logger';
import { useCustomToast } from 'hooks/useCustomToast';
import { useMutation, useQueryClient } from 'react-query';
import { errorToast, successToast } from 'utils/toast';
import { queryTransactionMailKey } from './queryTransactionMailKey';

export const useStatusChangeMail = (
  transactionMailType: TransactionMailCategoryType,
) => {
  const toast = useCustomToast();
  const queryClient = useQueryClient();
  const idPoolConsumerId = useIdPoolConsumerId();

  const { mutateAsync, isLoading } = useMutation({
    mutationFn: (data: TransactionMailEditFormType) =>
      editTransactionMail(idPoolConsumerId, data),
    onSuccess: (data) => {
      void queryClient.invalidateQueries(
        queryTransactionMailKey.getTransactionMails(
          idPoolConsumerId,
          transactionMailType,
        ),
      );
      void queryClient.invalidateQueries(
        queryTransactionMailKey.getTransactionMail(idPoolConsumerId, data.id),
      );
      void queryClient.invalidateQueries(
        queryTransactionMailKey.getTransactionMailTypeStatuses(
          idPoolConsumerId,
        ),
      );
      const statusName = data.status ? '使用中' : '停止中';
      toast({
        ...successToast,
        duration: 4000,
        title: `使用設定を${statusName}に変更しました。`,
        position: 'bottom',
        variant: 'solid',
      });
    },
    onError: async (error) => {
      if (error instanceof CustomError) {
        await logger({
          loglevel: error.logLevel,
          data: JSON.stringify(error.cause),
          message: error.message,
        });
      }
      toast({
        ...errorToast,
        duration: 4000,
        title: '使用設定の変更に失敗しました',
        position: 'bottom',
        variant: 'solid',
      });
    },
  });

  return { mutate: mutateAsync, isLoading };
};
