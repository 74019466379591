import { request } from 'admin/api/Request';
import { CustomError } from 'admin/error/CustomError';
import { messages } from 'api/transactionMail/messages';
import { SendFirstLoginTestMailFormType } from 'api/transactionMail/type';
import { isResponseError } from 'api/types';
import { getValidationError } from 'utils/form';
import { snakeCase } from 'utils/str';

export const sendTestMail = async ({
  tenantId,
  params,
}: {
  tenantId: string;
  params: SendFirstLoginTestMailFormType;
}): Promise<SendFirstLoginTestMailFormType> => {
  const path = `tenant/${tenantId}/transaction_mails/test/`;

  const response = await request<SendFirstLoginTestMailFormType>({
    path,
    body: {
      ...params,
      type: snakeCase(params.type),
    },
    method: 'post',
  });

  if (response.hasError) {
    if (response.status === 404) {
      throw new CustomError(messages.sendTestMail.customer.failed, {
        path,
        status: response.status,
      });
    } else if (isResponseError(response.error)) {
      const customError = response.error;

      const formError = getValidationError<SendFirstLoginTestMailFormType>({
        formData: params,
        response: customError,
      });

      throw new CustomError(messages.sendTestMail.customer.error, {
        path,
        params,
        errors: formError,
        status: response.status,
        logLevel: response.status === 400 ? 'warning' : 'error',
      });
    } else {
      throw new CustomError(messages.sendTestMail.customer.error, {
        path,
        status: response.status,
      });
    }
  }

  return params;
};
