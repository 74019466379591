import { VFC, memo } from 'react';
import { Box, Stack, Divider } from '@chakra-ui/react';
import { SkinTitle } from 'components/enquete/atoms/SkinTitle';
import { SkinBorder } from 'components/enquete/atoms/SkinBorder';
import { SkinSubmitButton } from 'components/enquete/atoms/SkinSubmitButton';
import { SkinRequireLabel } from 'components/enquete/atoms/SkinRequireLabel';
import { SkinSelectButton } from 'components/enquete/atoms/SkinSelectButton';
import { SkinFinishedButton } from 'components/enquete/atoms/SkinFinishedButton';
import { SkinErrorText } from 'components/enquete/atoms/SkinErrorText';
import { SkinRadioButton } from 'components/enquete/atoms/SkinRadioButton';
import { SkinCheckbox } from 'components/enquete/atoms/SkinCheckbox';

export const EnqueteSkinFormInner: VFC = memo(() => (
  <Box w="100%" h="calc(100vh - 189px)" overflow="auto">
    <Stack>
      <Box m={4}>
        {/* タイトル・ロゴ画像 */}
        <SkinTitle />
        <Divider />
        {/* ボーダー */}
        <SkinBorder />
        <Divider />
        {/* 必須ラベル */}
        <SkinRequireLabel />
        <Divider />
        {/* 選択ボタン */}
        <SkinSelectButton />
        <Divider />
        {/* ラジオボタン */}
        <SkinRadioButton />
        <Divider />
        {/* チェックボックス */}
        <SkinCheckbox />
        <Divider />
        {/* エラーテキスト */}
        <SkinErrorText />
        <Divider />
        {/* 送信ボタン */}
        <SkinSubmitButton />
        <Divider />
        {/* 閉じるボタン */}
        <SkinFinishedButton />
      </Box>
    </Stack>
  </Box>
));
