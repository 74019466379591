import { VFC, Suspense } from 'react';
import { Breadcrumb, BreadcrumbItem, Box } from '@chakra-ui/react';
import { ChevronRightIcon } from '@chakra-ui/icons';

import ErrorBoundary from 'api/ErrorBoundary';
import { useErrorBoundaryReset } from 'hooks/useErrorBoundaryReset';
import { useUserTenantId } from 'hooks/user/useUserTenantId';
import { useTableData } from 'hooks/contentsdb/useTableData';
import { useTableId } from 'hooks/contentsdb/useTableId';
import { LoadingSkeleton } from 'components/common/atoms';

const TableHeaderInner: VFC = () => {
  const tblId = useTableId();
  const tenantId = useUserTenantId();
  const contentsTable = useTableData({ tenantId, tblId });

  if (!contentsTable) {
    return <></>;
  }

  return (
    <Breadcrumb spacing="4px" separator={<ChevronRightIcon color="gray.500" />}>
      {contentsTable?.groupTree &&
        contentsTable.groupTree.map((grp) => (
          <BreadcrumbItem key={grp.id}>
            <span>{grp.name}</span>
          </BreadcrumbItem>
        ))}
      <BreadcrumbItem>
        <span>{contentsTable.title}</span>
      </BreadcrumbItem>
    </Breadcrumb>
  );
};

export const TableHeader: VFC = () => {
  const { ebKey, onError } = useErrorBoundaryReset();

  return (
    <ErrorBoundary onError={onError} key={ebKey.current} errorComponent={<></>}>
      <Suspense fallback={<LoadingSkeleton line={1} w="50%" />}>
        <Box m={4}>
          <TableHeaderInner />
        </Box>
      </Suspense>
    </ErrorBoundary>
  );
};
