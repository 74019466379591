/**
 * メール共通設定タイプ
 */
export type MailSettingCommon = {
  id: number;
  tenant: string;
  status: MailSettingStatuses;
  fromAddressList: string[];
  replyAddressList: string[];
  header: string;
  footer: string;
  fromAddress: string;
  replyAddress: string;
};
/**
 * メール共通設定Formタイプ
 */
export type MailSettingCommonForm = {
  header: string;
  footer: string;
  fromAddress: string;
  replyAddress: string;
};

/**
 * メール個別設定タイプ
 */
export type MailSettingPersonal = {
  id: number;
  tenant: string;
  type: string;
  subject: string;
  bodyText: string;
  status: boolean;
};
/**
 * メール個別設定Formタイプ
 */
export type MailSettingPersonalForm = {
  subject: string;
  bodyText: string;
  status?: boolean;
};

export type MailSettingStatus = {
  checked: boolean;
  warningMessage: string;
};

export type MailSettingStatuses = {
  createPaymentInfo: MailSettingStatus;
  completeCoursePayment: MailSettingStatus;
  completeItemPayment: MailSettingStatus;
  completeContinuePayment: MailSettingStatus;
  failContinuePayment: MailSettingStatus;
  completeStopPayment: MailSettingStatus;
  completeRestartPayment: MailSettingStatus;
  cancelPurchase: MailSettingStatus;
};

export type MailSettingPersonalData = {
  type: keyof MailSettingStatuses;
  checked: boolean;
  message: string;
  sort: number;
};

export type MailInsertWordPattern = Record<
  | 'purchaseDate'
  | 'productName'
  | 'productUrl'
  | 'price'
  | 'taxRate'
  | 'taxPrice'
  | 'priceWithoutTax'
  | 'itemEndDate'
  | 'courseEndDate'
  | 'period'
  | 'nextPaymentDate'
  | 'error',
  boolean
>;

export type MailInsertWordKeyPattern = keyof MailInsertWordPattern;

export const isMailSettingStatus = (
  data: unknown,
): data is MailSettingStatus => {
  const dt = data as MailSettingStatus;

  return (
    typeof dt?.checked === 'boolean' && typeof dt?.warningMessage === 'string'
  );
};

export const isMailSettingStatuses = (
  data: unknown,
): data is MailSettingStatuses => {
  const dt = data as MailSettingStatuses;

  return (
    isMailSettingStatus(dt?.createPaymentInfo) &&
    isMailSettingStatus(dt?.completeCoursePayment) &&
    isMailSettingStatus(dt?.completeItemPayment) &&
    isMailSettingStatus(dt?.completeContinuePayment) &&
    isMailSettingStatus(dt?.failContinuePayment) &&
    isMailSettingStatus(dt?.completeStopPayment) &&
    isMailSettingStatus(dt?.completeRestartPayment)
  );
};

export const isMailSettingCommon = (
  data: unknown,
): data is MailSettingCommon => {
  const dt = data as MailSettingCommon;

  return (
    (dt?.id !== null ? typeof dt?.id === 'number' : true) &&
    typeof dt?.tenant === 'string' &&
    isMailSettingStatuses(dt?.status) &&
    Array.isArray(dt?.fromAddressList) &&
    Array.isArray(dt?.replyAddressList) &&
    typeof dt?.header === 'string' &&
    typeof dt?.footer === 'string' &&
    (dt?.fromAddress !== null ? typeof dt?.fromAddress === 'string' : true) &&
    (dt?.replyAddress !== null ? typeof dt?.replyAddress === 'string' : true)
  );
};

export const isMailSettingPersonal = (
  data: unknown,
): data is MailSettingPersonal => {
  const dt = data as MailSettingPersonal;

  return (
    (dt?.id !== null ? typeof dt?.id === 'number' : true) &&
    typeof dt?.tenant === 'string' &&
    typeof dt?.type === 'string' &&
    typeof dt?.subject === 'string' &&
    typeof dt?.bodyText === 'string' &&
    typeof dt?.status === 'boolean'
  );
};

export const isSendTestMailSuccess = (data: unknown): data is null => {
  const dt = data as Record<string, unknown>;

  return Object.keys(dt).length === 0;
};
