import { atom, selector } from 'recoil';

import { PRODUCT_NAME } from 'define';

export const pageTitleState = atom({
  key: 'pageTitleState',
  default: PRODUCT_NAME,
});

export const pageTitleStateSelector = selector<string>({
  key: 'pageTitleStateSelector',
  get: ({ get }) => get(pageTitleState),
  set: ({ set }, newValue) => {
    set(pageTitleState, newValue ?? PRODUCT_NAME);
  },
});
