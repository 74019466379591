import { Box, Flex, Tbody, Text } from '@chakra-ui/react';
import { Colors } from 'components/paywall/config';
import { Pagination, Table, Thead } from 'components/paywall/features/Table';
import { SearchedTag } from 'components/paywall/features/Table/SearchedTag';
import { SubscriptionColumns } from 'components/paywall/pages/SubscriptionList/SubscriptionColumns';
import { SubscriptionRows } from 'components/paywall/pages/SubscriptionList/SubscriptionRows';
import { Container } from 'components/paywall/templates/Container';
import { FC, memo } from 'react';

export const SubscriptionList: FC = memo(() => (
  <Container
    title={
      <Flex>
        <Text fontSize="14px" color={Colors.BLACK} fontWeight="700">
          サブスクリプション注文一覧
        </Text>
      </Flex>
    }
    px="0px"
    contentsPl="45px"
  >
    <Box pb="16px" minH="45px">
      <SearchedTag />
    </Box>
    <Box className="PaywallCardList">
      <Table>
        <Thead>
          <SubscriptionColumns />
        </Thead>
        <Tbody>
          <SubscriptionRows />
        </Tbody>
      </Table>
      <Pagination />
    </Box>
  </Container>
));
