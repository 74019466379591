import { Box, FormControl, FormLabel, Text } from '@chakra-ui/react';
import { ErrorTextMsg } from 'components/common/atoms';
import { InputForm } from 'components/common/molecules';
import { Colors } from 'components/paywall/config';
import { EmbeddedWrapper } from 'components/paywall/molecules/EmbeddedWrapper';
import { personalSettingWords } from 'components/paywall/pages/MailSetting/constants';
import {
  MailSettingPersonal,
  MailSettingPersonalForm,
  MailSettingStatuses,
} from 'components/paywall/pages/MailSetting/typed';
import { convertInsertWordArray } from 'components/paywall/pages/MailSetting/utils';
import { StyledNoResizeTextarea } from 'components/paywall/styled/global';
import { useMailSettingCommon } from 'hooks/paywall/useMailSettingCommon';
import { FC, memo, useEffect } from 'react';
import { useFormContext } from 'react-hook-form';
import { toErrMsgList } from 'utils/form';

type Props = {
  type: keyof MailSettingStatuses;
  checked: boolean;
  obj: MailSettingPersonal;
};

export const Form: FC<Props> = memo(({ type, checked, obj }) => {
  const { commonData } = useMailSettingCommon();
  const {
    register,
    setValue,
    formState: { errors },
  } = useFormContext<MailSettingPersonalForm>();

  useEffect(() => {
    setValue('subject', obj.subject);
    setValue('bodyText', obj.bodyText);
  }, [setValue, obj]);

  return (
    <Box fontFamily="Hiragino Sans">
      <FormControl>
        <EmbeddedWrapper
          subject={personalSettingWords.subject}
          tagExample="下記の項目"
          items={convertInsertWordArray(type)}
        >
          <InputForm<MailSettingPersonalForm>
            name="subject"
            type="text"
            attr={{
              required: {
                value: checked,
                message: '有効なメールの件名は削除出来ません',
              },
            }}
          />
        </EmbeddedWrapper>
      </FormControl>
      <FormControl my={10}>
        <FormLabel>
          <Text fontSize="14px" fontWeight="700">
            {personalSettingWords.header}
          </Text>
          <Text
            fontSize="12px"
            fontWeight="400"
            color={Colors.GRAY_00}
            lineHeight="170%"
          >
            ※内容の変更は「共通要素の設定」から行えます。
          </Text>
        </FormLabel>
        <StyledNoResizeTextarea
          width="100%"
          height="107px"
          isReadOnly
          disabled
          value={commonData.header}
          borderColor={Colors.GRAY_30}
        />
      </FormControl>
      <FormControl my={10}>
        <EmbeddedWrapper
          subject={personalSettingWords.bodyText}
          tagExample="下記の項目"
          items={convertInsertWordArray(type)}
        >
          <>
            <StyledNoResizeTextarea
              height="282px"
              border="1px"
              borderColor={errors.bodyText ? '#E53E3E' : 'gray.200'}
              boxShadow={errors.bodyText ? '0 0 0 1px #E53E3E' : 'inherit'}
              borderStyle="solid"
              ref={register('bodyText', {
                required: {
                  value: checked,
                  message: '有効なメールの本文は削除出来ません',
                },
              })}
            />
            {toErrMsgList({ bodyText: errors.bodyText }, 'bodyText').map(
              (err, idx) => (
                <ErrorTextMsg key={`error-bodyText-${String(idx)}`} msg={err} />
              ),
            )}
          </>
        </EmbeddedWrapper>
      </FormControl>
      <FormControl>
        <FormLabel>
          <Text fontSize="14px" fontWeight="700">
            {personalSettingWords.footer}
          </Text>
          <Text
            fontSize="12px"
            fontWeight="400"
            color={Colors.GRAY_00}
            lineHeight="170%"
          >
            ※内容の変更は「共通要素の設定」から行えます。
          </Text>
        </FormLabel>
        <StyledNoResizeTextarea
          height="238px"
          isReadOnly
          width="100%"
          disabled
          value={commonData.footer}
          borderColor={Colors.GRAY_30}
        />
      </FormControl>
    </Box>
  );
});
