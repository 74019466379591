import { Drawer, useDisclosure } from '@chakra-ui/react';
import { withSuspenseAndErrorBoundary } from 'admin/components/Error/WithErrorBoundary';
import { TransactionMailCommonComponent } from 'admin/components/idPoolConsumer/transactionMail/transactionMailCommon/TransactionMailCommonComponent';
import { TransactionMailCommonDrawer } from 'admin/components/idPoolConsumer/transactionMail/transactionMailCommonDrawer';
import { useTransactionMailCommon } from 'admin/hooks/userPool/transactionMail/useTransactionMailCommon';
import { ErrorContents, LoadingSpinner } from 'components/common/atoms';
import { FC, memo } from 'react';

const transactionMailCommon: FC = memo(() => {
  const { isOpen, onClose, onOpen } = useDisclosure();
  const formId = `transactionMailCommon`;

  const { data: Common } = useTransactionMailCommon();

  if (!Common) {
    return null;
  }

  return (
    <>
      <TransactionMailCommonComponent
        fromAddress={Common.fromAddress || '-'}
        replyAddress={Common.replyAddress || '-'}
        onOpen={onOpen}
      />
      <Drawer
        isOpen={isOpen}
        placement="right"
        onClose={onClose}
        size="md"
        closeOnOverlayClick={false}
        closeOnEsc={false}
        autoFocus={false}
      >
        <TransactionMailCommonDrawer
          formId={formId}
          onClose={onClose}
          mailCommon={Common}
        />
      </Drawer>
    </>
  );
});

export const TransactionMailCommon = withSuspenseAndErrorBoundary(
  transactionMailCommon,
  {
    ErrorComponent: <ErrorContents name="トランザクションメール共通設定" />,
    LoadingComponent: <LoadingSpinner />,
  },
);
