import { Options } from 'ky';
import { request } from 'api/request';
import { logger } from 'api/logger';
import { CustomError } from 'api/error/CustomError';
import { ChartTextList, isChartTextList } from 'api/chart/types';
import { messages } from './messages';

// フォームグラフ フリーテキストリストAPI
export const getEnqueteTextList = async (
  tenantId: string,
  enqueteId: string,
  graphId: string,
  pageParam?: number,
  options?: Options,
): Promise<ChartTextList> => {
  const queryParam = [];
  if (pageParam) {
    queryParam.push(`page=${pageParam}`);
  }

  const param = queryParam.length > 0 ? `?${queryParam.join('&')}` : '';
  const path = `enquete/${tenantId}/${enqueteId}/graph/${graphId}/${param}`;

  const response = await request({
    path,
    method: 'get',
    options,
  });

  const responseData = (await response.data) as unknown[];

  if (response.hasError) {
    throw new CustomError(messages.getEnqueteTextList.customer.error);
  }

  if (!isChartTextList(responseData)) {
    await logger({
      loglevel:
        response.status === 404 || response.status === 450
          ? 'warning'
          : 'error',
      data: responseData,
      message: messages.getEnqueteTextList.system.typeError,
    });

    throw new CustomError(messages.getEnqueteTextList.customer.failed);
  }

  return responseData;
};
