/**
 * toNumber関数
 * lodashのtoNumber関数を使用しいた内容を置き換える
 */
export const toNumber = (value: number | string): number => {
  if (typeof value === 'number') {
    return value;
  }

  if (typeof value === 'string') {
    const parsed = parseFloat(value);

    return Number.isNaN(parsed) ? 0 : parsed;
  }

  return 0;
};
