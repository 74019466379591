import { VFC, memo } from 'react';

import ErrorBoundary from 'api/ErrorBoundary';
import { useErrorBoundaryReset } from 'hooks/useErrorBoundaryReset';
import { User, UserDeleteState } from 'api/user/types';
import { AlertBar, LoadingSkeleton } from 'components/common/atoms';
import { UsersBodyInner } from 'components/setting/users/organisms/UsersBodyInner';

type UsersBodyProps = {
  list: User[];
  isError?: boolean;
  isLoading?: boolean;
  errorMessage?: string;
  delStateList: UserDeleteState[];
  isAllChecked: boolean;
  setTargetCheck: (id: string) => void;
  setAllCheck: () => void;
  refetch: () => void;
};

export const UsersBody: VFC<UsersBodyProps> = memo(
  ({
    list,
    isError = false,
    isLoading = false,
    errorMessage = '',
    delStateList,
    isAllChecked,
    setTargetCheck,
    setAllCheck,
    refetch,
  }) => {
    const { ebKey, onError } = useErrorBoundaryReset();

    if (isLoading) {
      return <LoadingSkeleton w="100%" />;
    }

    if (isError) {
      return (
        <AlertBar message={errorMessage || 'ユーザー一覧取得できません'} />
      );
    }

    return (
      <ErrorBoundary onError={onError} key={ebKey.current}>
        <UsersBodyInner
          list={list}
          delStateList={delStateList}
          isAllChecked={isAllChecked}
          setAllCheck={setAllCheck}
          setTargetCheck={setTargetCheck}
          refetch={refetch}
        />
      </ErrorBoundary>
    );
  },
);
