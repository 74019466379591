import { useQuery } from 'react-query';
import { ContentsTable } from 'api/contents/types';
import { getContentsTable } from 'api/contents/getContentsTable';
import { useCustomToast } from 'hooks/useCustomToast';
import { contentsDbQueryKey } from './queryKey';

export const useTableData = ({
  tenantId,
  tblId,
}: {
  tenantId: string;
  tblId: string;
}): ContentsTable | undefined => {
  const toast = useCustomToast();
  const response = useQuery(
    contentsDbQueryKey.tableDetail({ tenantId, tableId: tblId }),
    () => getContentsTable(tblId, tenantId),
    {
      onError: (err: unknown) => {
        if (err instanceof Error) {
          toast.closeAll();
          toast({
            title: err.message,
            status: 'error',
          });
        }
      },
    },
  );
  const { data: contentsTable } = response;

  return contentsTable;
};
