import { CourseFormDefaultValues } from 'components/paywall/pages/CourseList/initial';
import {
  Course,
  CourseFormType
} from 'components/paywall/pages/CourseList/typed';
import { format } from 'date-fns';
import { useAddCourse } from 'hooks/paywall/useAddCourse';
import { useCourseList } from 'hooks/paywall/useCourseList';
import { useEditCourse } from 'hooks/paywall/useEditCourse';
import { useUserTenantId } from 'hooks/user/useUserTenantId';
import { Dispatch, SetStateAction, useCallback, useEffect, useState } from 'react';
import { UseFormGetValues, UseFormSetError } from 'react-hook-form';
import { useParams } from 'react-router-dom';

const DATETIME_FORMAT = 'yyyy年MM月dd HH:mm';

type Props = {
  getValues?: UseFormGetValues<CourseFormType>;
  isAdd?: boolean;
  setError?: UseFormSetError<CourseFormType>;
  onClose?: () => void;
  reset?: () => void;
  setLoading?: Dispatch<SetStateAction<boolean>>;
};

/**
 * ペイウォールコースhooks
 * @param {UseFormGetValues<CourseFormType>} getValues T型 react query getValues
 * @param {boolean} isAdd 新規作成or編集
 */
export const usePaywallCourse = ({
  getValues,
  isAdd,
  setError,
  onClose,
  reset,
  setLoading,
}: Props) => {
  const [courseName, setCourseName] = useState('');
  const [courseId, setCourseId] = useState('');
  const tenantId = useUserTenantId();
  const params = useParams();
  const { courseList } = useCourseList({
    wallId: params.wallId || '',
  });

  const onDrawerClose = useCallback(() => {
    onClose?.();
    reset?.();
  }, [onClose, reset]);

  const { onSubmit: addCourseSubmit, isLoading: isAddLoading } = useAddCourse({
    setError,
    close: onDrawerClose,
  });
  const { onSubmit: editCourseSubmit, isLoading: isEditLoading } = useEditCourse({
    setError,
    close: onClose,
  });

  useEffect(() => {
    const target = courseList?.filter(
      (x) => x.id === params.courseId,
    );
    // コース名セット
    setCourseName(target && target?.length > 0 ? target[0].name : '');
    // コースIDセット
    setCourseId(params.courseId || '');
  }, [courseList, params]);

  useEffect(() => {
    setLoading?.(isAddLoading || isEditLoading)
  }, [setLoading, isAddLoading, isEditLoading])

  /**
   * コース新規作成：編集
   * @param {function(): void} onClose close Function
   * @param {function(): void} reset reset Function
   */
  const saveCourse = useCallback(() => {
    setLoading?.(true);
    const fdata: CourseFormType = {
      ...(getValues?.() || CourseFormDefaultValues),
    };
    // 新規作成時
    if (isAdd) {
      addCourseSubmit({
        tenantId,
        wallId: params.wallId || '',
        courseForm: fdata,
      });
    } else {
      editCourseSubmit({
        tenantId,
        wallId: params.wallId || '',
        courseForm: fdata,
      });
    }
  }, [getValues, addCourseSubmit, editCourseSubmit, setLoading, isAdd, tenantId, params]);

  /**
   * プロモーション期間表示整形
   * @param {Course} obj コース
   * @returns {string} フォーマットされた日時期間表示用文字列
   */
  const periodViewStr = (obj: Course) => {
    let periodStr = '';
    if (obj.promotions.startDate) {
      periodStr += format(new Date(obj.promotions.startDate), DATETIME_FORMAT);
      periodStr += '〜';
    }
    if (obj.promotions.endDate !== null) {
      if (periodStr === '') periodStr += '〜';
      periodStr += format(new Date(obj.promotions.endDate), DATETIME_FORMAT);
    }

    if (periodStr !== '') periodStr = `（${periodStr}）`;

    return periodStr;
  };

  return {
    courseName,
    courseId,
    saveCourse,
    onDrawerClose,
    periodViewStr,
  };
};
