import { customErrorMessage, toZod } from 'admin/config/zod';
import {
  PERMISSION_ADMIN_DATA_OPERATOR,
  PERMISSION_ADMIN_ID_MANAGER,
  PERMISSION_ADMIN_INDIVIDUAL_SERVICE,
  PERMISSION_ADMIN_OWNER,
  PERMISSION_FEATURE_ADMIN_DASHBOARD, PERMISSION_FEATURE_ADMIN_SAML, PERMISSION_FEATURE_ADMIN_SERVICE, PERMISSION_FEATURE_ADMIN_SETTING, PERMISSION_FEATURE_ADMIN_USER, PERMISSION_FEATURE_ADMIN_USER_POOL
} from 'admin/define/permissions';
import { CorporationUserPoolPermissionTypeSchema } from 'admin/schema/users';
import { InviteUserCorporationResponseType, UserDetailType } from 'admin/types/user';
import { InviteCorporationSubmitForm, UserEditFormUserPoolsType, UserEditFormValues } from 'admin/types/user/form';
import { z } from 'zod';

// zodErrorの日本語化対応
z.setErrorMap(customErrorMessage);

export const permissionSchema = z.union([
  z.literal(PERMISSION_ADMIN_DATA_OPERATOR),
  z.literal(PERMISSION_ADMIN_INDIVIDUAL_SERVICE),
  z.literal(PERMISSION_ADMIN_ID_MANAGER),
  z.literal(PERMISSION_ADMIN_OWNER),
]);

export const permissionFeatureSchema = z.union([
  z.literal(PERMISSION_FEATURE_ADMIN_DASHBOARD),
  z.literal(PERMISSION_FEATURE_ADMIN_USER),
  z.literal(PERMISSION_FEATURE_ADMIN_SERVICE),
  z.literal(PERMISSION_FEATURE_ADMIN_SAML),
  z.literal(PERMISSION_FEATURE_ADMIN_SETTING),
  z.literal(PERMISSION_FEATURE_ADMIN_USER_POOL),
]);


export const userDetailSchema = z.object<toZod<UserDetailType>>({
  name: z.string(),
  permission: z.array(permissionFeatureSchema),
});

export const userEditFormUserPoolsTypeSchema = z.object<toZod<UserEditFormUserPoolsType>>({
  userPoolId: z.string(),
  permission: z.array(CorporationUserPoolPermissionTypeSchema),
})

export const userEditFormSchema = z.object<toZod<UserEditFormValues>>({
  permission: z.array(permissionFeatureSchema),
  userPools: z.array(userEditFormUserPoolsTypeSchema)
});

export const inviteCorporationFormSchema = z.object<toZod<InviteCorporationSubmitForm>>({
  emailTo: z.string().min(1, 'メールアドレスは必須です'),
  permission: z.array(permissionFeatureSchema),
  userPools: z.array(userEditFormUserPoolsTypeSchema)
});

export const userInviteCorporationSchema = z.object<toZod<InviteUserCorporationResponseType>>({
  invitationCode: z.string(),
  user: z.string(),
  corporation: z.string(),
  emailFrom: z.string(),
  emailTo: z.string(),
  expiry: z.string(),
  isSystemInvite: z.boolean(),
  permission: z.array(permissionFeatureSchema),
});
