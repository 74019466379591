import { VFC, memo, useState } from 'react';
import { Box, Text } from '@chakra-ui/react';
import ErrorBoundary from 'api/ErrorBoundary';
import { useErrorBoundaryReset } from 'hooks/useErrorBoundaryReset';
import { getEnqueteTextList } from 'api/enquete/getEnqueteTextList';
import { InfinityScroller } from 'components/common/atoms';
import { ENQUETE_CHART_FREETEXT_LIMIT } from 'define';

type EnqueteChartTextListProps = {
  tenantId: string;
  enqueteId: string;
  chartId: string;
};

export const EnqueteChartTextList: VFC<EnqueteChartTextListProps> = memo(
  ({ tenantId, enqueteId, chartId }) => {
    const { ebKey, onError } = useErrorBoundaryReset();
    const [total, setTotal] = useState<number>(0);

    // 取得したアンケートのコンテンツIDより対象のフォームグラフ フリーテキストリスト情報を取得する
    const fetchTextList = async (page: number) => {
      const result = await getEnqueteTextList(
        tenantId,
        enqueteId,
        chartId,
        page,
      );
      if (page === 1) {
        setTotal(result.count);
      }

      return {
        isNextPage: result.next !== null,
        results: result.results,
      };
    };

    return (
      <Box>
        <Text mb={2}>{total} 件の回答</Text>
        <ErrorBoundary onError={onError} key={ebKey.current}>
          <InfinityScroller
            maxH="415px"
            uid={chartId}
            fetchData={fetchTextList}
            total={total}
            limit={ENQUETE_CHART_FREETEXT_LIMIT}
          />
        </ErrorBoundary>
      </Box>
    );
  },
);
