import { DEFAULT_API_OPTIONS } from 'api/request';

export const errorFileDownload = (
  tenantId: string,
  tableId: string,
  uploadRequestId: number,
): void => {
  const backendUrl = (DEFAULT_API_OPTIONS.prefixUrl as string) || '';

  // eslint-disable-next-line no-restricted-globals
  location.href = `${backendUrl}/contentsdb/${tenantId}/${tableId}/upload/${uploadRequestId}/`;
};
