import { Box, Flex } from '@chakra-ui/react';
import { FC, memo } from 'react';

type Props = {
  title?: React.ReactNode;
  label?: React.ReactNode;
  children: React.ReactNode;
  py?: string;
  px?: string;
  contentsPl?: string;
};

/**
 * @see Paywallのコンテンツ領域のコンテナコンポーネント
 * @param title 左上に配置されるElement（ページタイトル）
 * @param label 右上に配置されるElement（主にボタン）
 * @param children 子要素
 */

export const Container: FC<Props> = memo(({ title, label, children, py = "20px", px = "45px", contentsPl = "0px" }) => (
  <Box
    className="paywallContainer"
    width="calc(100vw - 110px)"
    overflow="hidden"
    py={py}
    px={px}
  >
    <Flex justifyContent="space-between" alignItems="flex-start" pl={contentsPl} minWidth="784px" style={{gap: '40px'}}>
      <Box
        fontSize={14}
        fontWeight="bold"
        color="#444444"
        width="100%"
      >
        {title}
      </Box>
      <Box>{label}</Box>
    </Flex>
    <Box overflowX="auto">
      <Box pl={contentsPl}>
        {children}
      </Box>
    </Box>
  </Box>
));
