import { VFC, useCallback, useEffect, useState } from 'react';
import { useFormContext } from 'react-hook-form';
import { useNavigate } from 'react-router-dom';
import {
  FormControl,
  FormLabel,
  Icon,
  Text,
  useDisclosure,
} from '@chakra-ui/react';
import { WarningIcon } from '@chakra-ui/icons';
import { BsTrash } from 'react-icons/bs';
import { DeleteGroupFormType } from 'api/tenant/types';
import { getGroupDeleteCheck } from 'api/tenant/getGroupDeleteCheck';
import { InputTextForm, Dialog } from 'components/common/atoms';
import { useDeleteGroup } from 'hooks/tenant/useDeleteGroup';

/**
 * DeleteGroupDialogProps
 */
type DeleteGroupDialogProps = {
  formId: string;
  groupId: string;
  tenantId: string;
  openBtnElemType?: 'button' | 'text';
};

export const DeleteGroupDialog: VFC<DeleteGroupDialogProps> = ({
  formId,
  groupId,
  tenantId,
  openBtnElemType = 'button',
}) => {
  const navigate = useNavigate();
  const { isOpen, onOpen, onClose } = useDisclosure();
  const [warningText, setWarningText] = useState<string>('');
  const [errorText, setErrorText] = useState<string>('');
  const [alertSize, setAlertSize] = useState<string>('md');
  const [isDisabled, setDisabled] = useState<boolean>(true);
  const {
    reset,
    handleSubmit,
    watch,
    formState: { errors },
  } = useFormContext<DeleteGroupFormType>();
  const { onDelete, isLoading, isSuccess } = useDeleteGroup({
    tenantId,
    groupId,
  });

  // グループ削除ポップアップ表示
  const deleteGroupBtnHandler = useCallback(async () => {
    setWarningText('');
    setErrorText('');
    const deleteAlert = await getGroupDeleteCheck(tenantId, groupId);

    if (deleteAlert.warning) {
      setWarningText(deleteAlert.warning);
      setAlertSize('2xl');
    }
    if (deleteAlert.error) {
      setErrorText(deleteAlert.error);
      setAlertSize('2xl');
    }
    if (deleteAlert.warning === '' && deleteAlert.error === '') {
      setWarningText('');
      setErrorText('');
      setAlertSize('md');
    }
    onOpen();
  }, [onOpen, tenantId, groupId]);

  // グループ削除処理
  const onDeleteGroupSubmit = useCallback((): void => {
    // 削除処理
    onDelete();
    // 設定 > グループ へ遷移
    navigate('/setting/groups/');
  }, [onDelete, navigate]);

  // closeボタン押下時
  const closeBtnHandler = useCallback(() => {
    reset();
    onClose();
  }, [reset, onClose]);

  useEffect(() => {
    if (isSuccess) {
      // ポップアップ非表示
      onClose();
    }
  }, [isSuccess, onClose]);

  // フォームエラーの存在チェック
  const isFailed = useCallback(
    (): boolean => Object.keys(errors).length > 0,
    [errors],
  );

  useEffect(() => {
    const subscription = watch((value) => {
      setDisabled(value.deleteText !== 'DELETE');
    });

    return () => subscription.unsubscribe();
  }, [watch]);

  /**
   * 入力用バリデーション
   */
  const validateAttr = {
    required: '「DELETE」を入力してください',
    validate: (val: string) =>
      val !== 'DELETE' ? '「DELETE」を入力してください' : true,
  };

  return (
    <>
      <form
        id={`${formId}-${groupId}`}
        onSubmit={handleSubmit(() => onDeleteGroupSubmit())}
      >
        <Dialog
          title="グループ削除"
          isOpen={isOpen}
          onOpen={deleteGroupBtnHandler}
          onClose={closeBtnHandler}
          openBtnElemType={openBtnElemType}
          openBtnChildNode={
            <>
              <Icon as={BsTrash} fontSize={20} mr={2} />
              <Text as="span">グループ削除</Text>
            </>
          }
          openBtnProps={{
            variant: 'ghost',
            color: 'blue.400',
          }}
          submitBtnTitle={errorText === '' ? '削除' : ''}
          submitBtnProps={{
            colorScheme: 'red',
            type: 'submit',
            form: `${formId}-${groupId}`,
            disabled: isFailed() || isDisabled,
            isLoading: isLoading === true,
          }}
          size={alertSize}
        >
          {errorText && (
            <Text
              backgroundColor="#edf2f6"
              p={4}
              mb={4}
              whiteSpace="pre-wrap"
              maxH={400}
              overflowY="auto"
            >
              <WarningIcon color="#e53e3e" mt={-1} mr={2} />
              {errorText}
            </Text>
          )}
          {warningText && (
            <Text
              backgroundColor="#edf2f6"
              p={4}
              mb={4}
              whiteSpace="pre-wrap"
              maxH={400}
              overflowY="auto"
            >
              <WarningIcon color="#e53e3e" mt={-1} mr={2} />
              {warningText}
            </Text>
          )}
          {errorText === '' && (
            <FormControl>
              <FormLabel htmlFor="deleteText" fontSize="sm">
                削除する場合は DELETE と入力してください
              </FormLabel>
              <InputTextForm<DeleteGroupFormType>
                name="deleteText"
                type="text"
                placeholder=""
                value=""
                attr={validateAttr}
              />
            </FormControl>
          )}
        </Dialog>
      </form>
    </>
  );
};
