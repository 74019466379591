import { request } from 'admin/api/Request';
import { API_MESSAGES } from 'admin/define/apiMessages';
import { CustomError } from 'admin/error/CustomError';
import { idPoolConsumerUserInfoSchema } from 'admin/schema/idPoolConsumer/userInfo';
import { UserPool } from 'admin/types/userPool';
import { IdPoolConsumerUserInfoFormType } from 'admin/types/userPool/userInfo/form';
import { isResponseError } from 'api/types';
import { Options } from 'ky';
import { getValidationError } from 'utils/form';
import { ZodError } from 'zod';

export const updateIdPoolConsumerUserInfo = async ({
  data,
  userPoolGroupId,
  options,
}: {
  data: IdPoolConsumerUserInfoFormType;
  userPoolGroupId: string;
  options?: Options;
}): Promise<IdPoolConsumerUserInfoFormType> => {
  const params = { userInfoResponse: data.userInfoResponse };
  const path = `uniikey/${userPoolGroupId}/user_pool/${data.id}/`;

  const response = await request<UserPool>({
    path,
    method: 'patch',
    body: params,
    options,
  });

  if (response.hasError) {
    if (response.status === 404) {
      throw new CustomError(
        API_MESSAGES.USER_POOL.SETTING_ID_POOL_CONSUMER_USER_INFO.FAILED,
        {
          path,
          status: response.status,
        },
      );
    } else if (isResponseError(response.error)) {
      const customError = response.error;
      const formError = getValidationError<IdPoolConsumerUserInfoFormType>({
        formData: data,
        response: customError,
      });

      throw new CustomError(
        API_MESSAGES.USER_POOL.SETTING_ID_POOL_CONSUMER_USER_INFO.ERROR,
        {
          path,
          options,
          params,
          errors: formError,
          status: response.status,
          logLevel: response.status === 400 ? 'warning' : 'error',
        },
      );
    } else {
      throw new CustomError(
        API_MESSAGES.USER_POOL.SETTING_ID_POOL_CONSUMER_USER_INFO.ERROR,
        {
          path,
          status: response.status,
        },
      );
    }
  }
  try {
    idPoolConsumerUserInfoSchema.parse(response.data);
  } catch (error) {
    if (error instanceof ZodError) {
      throw new CustomError(error.message, {
        path: 'dummy',
        status: response.status,
        logLevel: 'error',
      });
    }
  }

  return response.data;
};
