import { changeAttributeStatus } from 'admin/api/userPool/attribute/changeAttributeStatus';
import { CustomError } from 'admin/error/CustomError';
import { useResetAllAttributes } from 'admin/hooks/userPool/attribute/useResetAllAttributes';
import { IdPoolConsumerAttributeType } from 'admin/types/userPool/attribute';
import { logger } from 'api/logger';
import { useCustomToast } from 'hooks/useCustomToast';
import { useMutation, useQueryClient } from 'react-query';
import { errorToast, successToast } from 'utils/toast';
import { queryIdPoolConsumerAttributeKey } from './queryIdPoolConsumerAttributeKey';

export const useChangeAttributeStatus = ({
  id,
  userPoolId,
}: {
  id: IdPoolConsumerAttributeType['id'];
  userPoolId: IdPoolConsumerAttributeType['userPool'];
}) => {
  const toast = useCustomToast();
  const queryClient = useQueryClient();
  const resetAttribute = useResetAllAttributes(userPoolId);

  const { mutateAsync, isLoading } = useMutation({
    mutationFn: () => changeAttributeStatus({ userPoolId, id }),
    onError: async (error) => {
      if (error instanceof CustomError) {
        if (error.cause.errors) {
          await logger({
            loglevel: error.logLevel,
            data: JSON.stringify(error.cause),
            message: error.message,
          });
        }
      }
      toast({
        ...errorToast,
        duration: 4000,
        title: 'ステータスの変更に失敗しました',
        position: 'bottom',
        variant: 'solid',
      });
    },
    onSuccess: () => {
      void queryClient.invalidateQueries(
        queryIdPoolConsumerAttributeKey.getIdPoolConsumerAttributesDetail(
          id,
          userPoolId,
        ),
      );
      resetAttribute();

      toast({
        ...successToast,
        duration: 4000,
        title: 'ステータスを「確定」に変更しました',
        position: 'bottom',
        variant: 'solid',
      });
    },
  });

  return { mutate: mutateAsync, isLoading };
};
