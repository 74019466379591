import { ServiceType } from 'admin/types/service';
import { Page } from 'api/common/types';

export const queryKeyNames = {
  getServiceTerms: 'getServiceTerms',
  getServiceTerm: 'getServiceTerm',
};

export const queryKeys = {
  getServiceTerms: (
    id: ServiceType['userPoolChildId'],
    currentPage?: Page['currentPage'],
    perPage?: Page['perPage'],
  ) => ['getServiceTerms', id, currentPage, perPage],
} as const;
