import { format } from 'date-fns';

const DATE_FORMAT = 'yyyy/M/dd';
const DATETIME_FORMAT = 'yyyy/M/dd HH:mm';
/**
 * 日付系のUtil関数
 * @returns Object {dateFormat, dateTimeFormat}
 */
export const useFormatDate = (): {
  dateFormat: (date: string) => string;
  dateTimeFormat: (date: string) => string;
} => {
  /**
   * DateTimeのフォーマット
   */
  const dateFormat = (date: string | null): string => date ? format(new Date(date), DATE_FORMAT): '';

  /**
   * DateTimeのフォーマット
   */
  const dateTimeFormat = (date: string | null): string => date ? format(new Date(date), DATETIME_FORMAT): '';

  return { dateFormat, dateTimeFormat };
};
