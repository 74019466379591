import { request } from 'admin/api/Request';
import { API_MESSAGES } from 'admin/define/apiMessages';
import { CustomError } from 'admin/error/CustomError';
import { attributeAndGroupsSchema } from 'admin/schema/idPoolConsumer/attribute';
import { AttributeAndGroupsType } from 'admin/types/userPool/attribute/index';
import { UserPool } from 'admin/types/userPool/index';
import { ZodError } from 'zod';

// 属性 + 属性グループの全一覧を取得するAPI
// 使用箇所：フォーム設問グループ作成・編集ページ
export const getAllAtributeAndGroups = async (
  id: UserPool['id'],
): Promise<AttributeAndGroupsType> => {
  const path = `uniikey/${id}/user_attribute_and_group/?is_form_attr=true`;
  const response = await request<AttributeAndGroupsType>({
    path,
    method: 'get',
  });

  if (response.hasError) {
    // 404エラー時は空配列を返却する
    if (response.status === 404) {
      return [];
    }
    throw new CustomError(
      API_MESSAGES.USER_POOL.GET_ID_POOL_CONSUMER_ATTRIBUTE_AND_GROUPS.ERROR,
      {
        path,
        status: response.status,
        logLevel: response.status === 400 ? 'warning' : 'error',
      },
    );
  }
  try {
    attributeAndGroupsSchema.parse(response.data);
  } catch (error) {
    if (error instanceof ZodError) {
      throw new CustomError(error.message, {
        path,
        status: response.status,
        logLevel: 'error',
      });
    }
  }

  return response.data;
};
