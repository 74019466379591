import { Options } from 'ky';
import { request } from 'api/request';
import { logger } from 'api/logger';
import produce from 'immer';
import {
  Notice,
  updateNoticeType,
  putNoticeType,
  isNotice,
} from 'api/notice/types';
import { ResponseError, isResponseError } from 'api/types';
import { getValidationError, ValidationError } from 'utils/form';
import { messages } from './messages';

const DEFAULT_ERR_MESSAGE: ValidationError<Notice> = {
  others: [messages.putNotice.customer.error],
};
const DEFAULT_SYSTEM_ERR_MESSAGE: ValidationError<Notice> = {
  others: [messages.putNotice.customer.failed],
};
/**
 * メッセージ既読更新
 *
 * 状態により以下のレスポンスを返却します
 * 正常時: true
 * エラー: ResponseError
 *
 */
export const putNotice = async (
  formParam: updateNoticeType,
  options?: Options,
): Promise<putNoticeType | ResponseError> => {
  const { id, tenantId, isReaded, page = 1 } = formParam;
  const path = `notice/${tenantId}/${id}/`;
  const postParam = { isReaded };

  const mergedOptions = options
    ? produce(options, (draft) => {
        draft.json = { ...postParam };
      })
    : { json: postParam };

  const response = await request({
    path,
    method: 'put',
    options: mergedOptions,
  });

  if (response.status >= 500) {
    return DEFAULT_ERR_MESSAGE;
  }

  const responseData = response.data;

  // メール修正成功
  if (isNotice(responseData)) {
    return {
      ...responseData,
      page,
    };
  }

  const { error } = response;
  if (response.hasError) {
    // APIにて返却されたエラー形式
    if (isResponseError(error)) {
      const formError = getValidationError<updateNoticeType>({
        formData: formParam,
        response: error,
      });

      return formError;
    }
  }

  // 予期せぬエラーなので
  // バックエンドに状態を通知
  await logger({
    loglevel: 'error',
    data: error,
    message: messages.putNotice.system.typeError,
  });

  // 登録できていない旨を通知
  return DEFAULT_SYSTEM_ERR_MESSAGE;
};
