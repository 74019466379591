import { Flex, Switch, Text } from '@chakra-ui/react';
import { Td, Tr } from 'admin/components/ui/table';
import { TransactionMailType } from 'admin/types/userPool/transactionMail';
import { ChangeEvent, FC, memo } from 'react';
import { NavLink } from 'react-router-dom';

type Props = {
  idPoolConsumerId: string;
  transactionMail: TransactionMailType;
  transactionMailType: string;
  onChangeStatus: (event: ChangeEvent<HTMLInputElement>) => void;
};

export const TransactionMailsTableInnerComponent: FC<Props> = memo(
  ({
    idPoolConsumerId,
    transactionMail,
    transactionMailType,
    onChangeStatus,
  }: Props) => (
    <Tr>
      <Td>
        <NavLink
          to={`/admin/idPoolConsumer/${idPoolConsumerId}/transactionMail/${transactionMailType}/wallEditor/${transactionMail.id}`}
          style={{ textDecoration: 'underline' }}
        >
          {transactionMail.id}
        </NavLink>
      </Td>
      <Td>{transactionMail.subject}</Td>
      <Td>{transactionMail.settingTitle}</Td>
      <Td>
        <Flex justifyContent="left" style={{ gap: '5px' }}>
          <Switch
            isChecked={transactionMail.status}
            onChange={onChangeStatus}
            colorScheme="primary"
          />
          <Text fontWeight={700}>
            {transactionMail.status ? '使用中' : '停止中'}
          </Text>
        </Flex>
      </Td>
    </Tr>
  ),
);
