import { request } from 'admin/api/Request';
import { API_MESSAGES } from 'admin/define/apiMessages';
import { CustomError } from 'admin/error/CustomError';
import { CustomZodError } from 'admin/error/CustomZodError';
import { codeGroupsSchema } from 'admin/schema/idPoolConsumer/master';
import { convertToPage, pageSchema } from 'admin/schema/page';
import { CodeGroupsType } from 'admin/types/userPool/master/index';
import { DataWithPage } from 'api/common/types';
import { Options } from 'ky';
import { ZodError } from 'zod';

export const getCodeGroups = async ({
  id,
  page,
  perPage,
  options,
}: {
  id: string;
  page?: number;
  perPage: number;
  options?: Options;
}): Promise<DataWithPage<CodeGroupsType>> => {
  const queryParam = [];
  if (page) {
    queryParam.push(`page=${page}`);
  }
  if (perPage) {
    queryParam.push(`perPage=${perPage}`);
  }
  const param = queryParam.length > 0 ? `?${queryParam.join('&')}` : '';

  // TODO: APIの繋ぎ込みの際に実装する
  const path = `uniikey/${id}/code_group/${param}`;
  const response = await request<DataWithPage<CodeGroupsType>>({
    path,
    method: 'get',
    options,
  });

  if (response.hasError) {
    if (response.status === 404) {
      throw new CustomError(
        API_MESSAGES.USER_POOL.GET_MASTER_SETTINGS.NOT_FOUND,
        {
          path,
          status: response.status,
        },
      );
    } else {
      throw new CustomError(API_MESSAGES.USER_POOL.GET_MASTER_SETTINGS.ERROR, {
        path,
        status: response.status,
        logLevel: response.status === 400 ? 'warning' : 'error',
      });
    }
  }
  try {
    codeGroupsSchema.parse(response.data.results);
    pageSchema.parse(
      convertToPage<DataWithPage<CodeGroupsType>>(response.data),
    );
  } catch (error) {
    if (error instanceof ZodError) {
      throw new CustomZodError(error.message, {
        path,
        options,
        status: response.status,
        logLevel: 'error',
      });
    }
  }

  return response.data;
};
