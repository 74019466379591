import { changeServiceAttributeGroupStatus } from 'admin/api/service/attribute/changeServiceAttributeGroupStatus';
import { createServiceAttributeGroup } from 'admin/api/service/attribute/createServiceAttributeGroup';
import { API_MESSAGES } from 'admin/define/apiMessages';
import { CustomError } from 'admin/error/CustomError';
import { useUserPoolChildId } from 'admin/hooks/useUserPoolChildId';
import { ServiceAttributeGroupType } from 'admin/types/service/attribute';
import { logger } from 'api/logger';
import { useCustomToast } from 'hooks/useCustomToast';
import { useMutation, useQueryClient } from 'react-query';
import { errorToast, successToast } from 'utils/toast';
import {
  queryKeyNames,
  querySerivceAttributeKey,
} from './querySerivceAttributeKey';

export const useChangeServiceUserAttrRequiredGroup = (
  target: ServiceAttributeGroupType | null,
) => {
  const userPoolChildId = useUserPoolChildId();
  const toast = useCustomToast();
  const queryClient = useQueryClient();
  const { mutateAsync, isLoading } = useMutation({
    mutationFn: () => {
      if (target?.userPoolChildAttribute?.id) {
        const requestBody = {
          userAttributeSetting:
            target.userPoolChildAttribute.userAttributeSetting ?? '',
          attributeGroupHead:
            target.userPoolChildAttribute.attributeGroupHead ?? '',
          isUserAttrRequired: !target.userPoolChildAttribute.isUserAttrRequired,
        };
        // 会員登録時利用フラグ: OFF かつ 会員登録時必須フラグ: OFF
        // の時会員登録時必須フラグをONにする際、会員登録時利用フラグもONにする
        if (
          !target?.userPoolChildAttribute.isUserAttrRequired &&
          !target.userPoolChildAttribute.isUserAttr
        ) {
          Object.assign(requestBody, {
            isUserAttr: !target.userPoolChildAttribute.isUserAttr,
          });
        }

        return changeServiceAttributeGroupStatus({
          userPoolChildId,
          userPoolChildAttrId: target?.userPoolChildAttribute?.id,
          data: requestBody,
        });
      }

      return createServiceAttributeGroup({
        userPoolChildId,
        data: {
          userAttributeSetting: null,
          attributeGroupHead: target?.id ?? '',
          isUserAttr: true,
          isUserAttrRequired: true,
        },
      });
    },
    onSuccess: () => {
      void queryClient.invalidateQueries(
        queryKeyNames.getSerivceAttributeGroups,
      );
      void queryClient.invalidateQueries(
        querySerivceAttributeKey.getSerivceAttributeGroupDetail(
          userPoolChildId,
          target?.userPoolChildAttribute?.id ?? '',
        ),
      );
      toast({
        ...successToast,
        duration: 4000,
        title: '会員登録時利用 必須を更新しました',
        position: 'bottom',
        variant: 'solid',
      });
    },
    onError: async (error) => {
      if (error instanceof CustomError) {
        if (error.cause.errors) {
          const message = 404
            ? API_MESSAGES.SERVICE.CHANGE_SEVICE_ATTRIBUTE_USER_ATTR.FAILED
            : API_MESSAGES.SERVICE.CHANGE_SEVICE_ATTRIBUTE_USER_ATTR.ERROR;

          await logger({
            loglevel: error.logLevel,
            data: JSON.stringify(error.cause),
            message,
          });
        }
      }
      toast({
        ...errorToast,
        duration: 4000,
        title: '会員登録時利用 必須の更新に失敗しました',
        position: 'bottom',
        variant: 'solid',
      });
    },
  });

  return { mutate: mutateAsync, isLoading };
};
