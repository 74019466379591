import { patchWallPublish } from 'api/paywall/patchWallPublish';
import { ResponseError } from 'api/types';
import { WallPublishDefaultValues } from 'components/paywall/pages/WallList/initial';
import {
  isWall, Wall,
  WallPublish
} from 'components/paywall/pages/WallList/typed';
import { usePaywallUtil } from 'hooks/paywall/usePaywallUtil';
import { useCustomToast } from 'hooks/useCustomToast';
import { UseMutateFunction, useMutation } from 'react-query';
import { isGetValidationError } from 'utils/form';

type UnPromisify<T> = T extends Promise<infer U> ? U : T;

/**
 * ウォールステータス変更hooks
 * @returns {Object} { onSubmit, isLoading }
 */
export const usePaywallPublish = (): {
  onSubmit: UseMutateFunction<
    UnPromisify<ReturnType<typeof patchWallPublish>>,
    unknown,
    WallPublish,
    unknown
  >;
  isLoading: boolean;
} => {
  const { paywallListRefetchQueries } = usePaywallUtil();
  const toast = useCustomToast();

  const { mutate: onSubmit, isLoading } = useMutation(
    (WallData: WallPublish) => patchWallPublish(WallData),
    {
      onSuccess: (result: Wall | ResponseError) => {
        paywallListRefetchQueries();
        if (isWall(result)) {
          const isPublicStr = result.status ? '公開' : '非公開';
          toast({
            status: 'success',
            position: 'bottom',
            duration: 2000,
            isClosable: true,
            title: `ウォール「${result.name}」を${isPublicStr}に設定しました`,
          });
        }

        if (
          isGetValidationError<WallPublish>(
            result,
            Object.keys(WallPublishDefaultValues),
          )
        ) {
          const errorMsg = Object.entries(result).map(([_, value]) => value);
          toast({
            status: 'error',
            position: 'bottom',
            duration: 4000,
            isClosable: true,
            title: errorMsg[0],
          });
        }
      },
    },
  );

  return { onSubmit, isLoading };
};
