import { customErrorMessage, toZod } from 'admin/config/zod';
import { TenantType, TenantUsersType } from 'admin/types/tenantUsers';
import { z } from 'zod';

// zodErrorの日本語化対応
z.setErrorMap(customErrorMessage);

export const filterFormSchema = z.object({
  filterWord: z.string().max(30).nullable(),
  filterServiceName: z.union([z.string(), z.number()]),
});

export const tenantUsersInviteSchema = z.object({
  tenant: z.string().min(1, '担当テナントは必須です'),
  emailTo: z.string().min(1, 'メールアドレスは必須です'),
  permission: z.string().min(1, '権限は必須です').or(z.number()),
});

export const tenantUsersUpdateSchema = z.object({
  id: z.string(),
  tenantId: z.string(),
  permission: z.string().min(1, '権限は必須です').or(z.number()),
  groups: z.array(z.string()),
});

const deleteUserSchema = z.object({
  userId: z.string(),
  tenantId: z.string(),
});

export const deleteTenantUsersSchema = z.object({
  users: z.array(deleteUserSchema),
  word: z.string().refine((val) => val === 'delete', {
    message: '"delete"と入力してください',
  }),
});

export const tenantUsersSchema = z.object<toZod<TenantUsersType>>({
  id: z.string(),
  name: z.string(),
  email: z.string(),
  tenant: z.object({
    id: z.string(),
    name: z.string(),
    permission: z.string().or(z.number()),
    groups: z.array(z.string()).optional(),
  }),
});

export const tenantUsersListSchema = z.array(tenantUsersSchema);

export const tenantSchema = z.object<toZod<TenantType>>({
  id: z.string(),
  name: z.string(),
});

export const tenantListSchema = z.array(tenantSchema);
