import { getEnqueteContentGroups } from 'api/enquete/getEnqueteContentGroups';
import { EnqueteContentGroups } from 'api/enquete/types';
import { useCustomToast } from 'hooks/useCustomToast';
import { useUserTenantId } from 'hooks/user/useUserTenantId';
import { useQuery } from 'react-query';
import { queryEnqueteKey } from './queryEnqueteKey';

/**
 * フォーム設問グループ取得 hooks
 */
export const useEnqueteContentGroups = ({
  enabled = true,
}: {
  enabled?: boolean;
}): EnqueteContentGroups => {
  const toast = useCustomToast();
  const tenantId = useUserTenantId();

  const response = useQuery(
    queryEnqueteKey.getEnqueteContentGroups({ tenantId }),
    () => getEnqueteContentGroups(tenantId),
    {
      enabled,
      cacheTime: 0,
      onError: (err: unknown) => {
        if (err instanceof Error) {
          toast.closeAll();
          toast({
            title: err.message,
            status: 'error',
          });
        }
      },
    },
  );
  if (!enabled) return [] as unknown as EnqueteContentGroups;

  return response.data || [];
};
