import { request } from 'admin/api/Request';
import { API_MESSAGES } from 'admin/define/apiMessages';
import { CustomError } from 'admin/error/CustomError';
import { newslettersSchema } from 'admin/schema/idPoolConsumer/newsletter';
import { convertToPage, pageSchema } from 'admin/schema/page';
import { UserPool } from 'admin/types/userPool';
import { NewslettersType } from 'admin/types/userPool/newsletter';
import { DataWithPage } from 'api/common/types';
import { Options } from 'ky';
import { ZodError } from 'zod';

export const getNewsletter = async ({
  userpoolId,
  options,
}: {
  userpoolId: UserPool['id'];
  options?: Options;
}): Promise<DataWithPage<NewslettersType>> => {
  const path = `uniikey/${userpoolId}/newsletter/`;
  const response = await request<DataWithPage<NewslettersType>>({
    path,
    method: 'get',
    options,
  });

  if (response.hasError) {
    if (response.status === 404) {
      throw new CustomError(API_MESSAGES.USER_POOL.GET_NEWSLETTER.NOT_FOUND, {
        path,
        status: response.status,
        params: {
          userpoolId,
        },
        options,
      });
    } else {
      throw new CustomError(API_MESSAGES.USER_POOL.GET_NEWSLETTER.ERROR, {
        path,
        status: response.status,
        params: {
          userpoolId,
        },
        options,
      });
    }
  }
  try {
    newslettersSchema.parse(response.data.results);
    pageSchema.parse(
      convertToPage<DataWithPage<NewslettersType>>(response.data),
    );
  } catch (error) {
    if (error instanceof ZodError) {
      throw new CustomError(error.message, {
        path,
        status: response.status,
        params: {
          userpoolId,
        },
        options,
        logLevel: 'error',
      });
    }
  }

  return response.data;
};
