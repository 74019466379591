/* eslint-disable react/require-default-props */
import { Box, Text } from '@chakra-ui/react';
import { Colors } from 'components/paywall/config';
import { FC, memo } from 'react';

type Props = {
  isChild?: boolean;
}
export const CardBadge: FC<Props> = memo(({isChild=false}: Props) => (
  <Box
    className="paywall-card-badge"
    as="span"
    fontSize="1rem"
    fontWeight="bold"
    borderRadius="50%"
    width="24px"
    height="24px"
    display="inline-block"
    lineHeight="120%"
    textAlign="center"
    color= {Colors.WHITE}
    bg={isChild ? Colors.GRAY_10 : Colors.BLACK}
    position="absolute"
    top="8px"
    left="0px"
    transform="translateX(-50%)"
  >
    <Text as="span" fontSize="11px">{isChild ? "子" : "親"}</Text>
  </Box>
));
