import { useQuery } from 'react-query';
import { contentsDbQueryKey } from 'hooks/contentsdb/queryKey';
import { UploadHistory, UploadHistoryList } from 'api/contents/types';
import { getUploadHistory } from 'api/contents/getUploadHistory';
import { Page } from 'api/common/types';

type UseUploadHistory = {
  tenantId: string;
  tableId: string;
  page?: number;
};

export const useUploadHistory = ({
  tenantId,
  tableId,
  page = 1,
}: UseUploadHistory): {
  page: Page;
  uploadHistoryList: UploadHistory[];
} => {
  const uploadParams = { tenantId, tableId, page };
  const key = contentsDbQueryKey.uploadHistory(uploadParams);
  const response = useQuery(key, () => getUploadHistory(uploadParams));

  const result = response.data as UploadHistoryList;
  const pager: Page = {
    count: result.count,
    countFrom: result.countFrom,
    countTo: result.countTo,
    currentPage: result.currentPage,
    next: result.next,
    pageCount: result.pageCount,
    perPage: result.perPage,
    previous: result.previous,
  };
  const { results } = result;

  return {
    page: pager,
    uploadHistoryList: results,
  };
};
