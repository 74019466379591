import { Box, Text } from '@chakra-ui/react';
import { Colors } from 'admin/define/colors';
import { FC, memo } from 'react';

type Props = {
  tenantName: string;
};

export const TermsTitle: FC<Props> = memo(({ tenantName }: Props) => (
  <Box mt="40px">
    {tenantName && (
      <Box
        bgColor={Colors.WHITE}
        borderRadius="4px"
        display="inline-block"
        p="2px 4px"
      >
        <Text>{tenantName}</Text>
      </Box>
    )}
    <Box mt="8px">
      <Text
        fontFamily="Hiragino Sans"
        fontSize="18px"
        fontWeight="700"
        lineHeight="170%"
      >
        規約設定
      </Text>
      <Text
        fontWeight="400"
        fontSize="14px"
        lineHeight="170%"
        fontFamily="Hiragino Sans"
        color={Colors.GRAY_00}
      >
        テナントで適用可能な規約の利用状況の管理を行います。
      </Text>
    </Box>
  </Box>
));
