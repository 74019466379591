import { createIdPoolConsumerAttribute } from 'admin/api/userPool/attribute/createIdPoolConsumerAttribute';
import { CustomError } from 'admin/error/CustomError';
import { useIdPoolConsumerId } from 'admin/hooks/useIdPoolConsumerId';
import { useResetAllAttributes } from 'admin/hooks/userPool/attribute/useResetAllAttributes';
import { useUserPoolAuthenticationType } from 'admin/hooks/userPool/useUserPoolAuthenticationType';
import { IdPoolConsumerAttributeType } from 'admin/types/userPool/attribute';
import { IdPoolConsumerAttributeCreateFormType } from 'admin/types/userPool/attribute/form';
import { logger } from 'api/logger';
import { useCustomToast } from 'hooks/useCustomToast';
import { Dispatch, SetStateAction } from 'react';
import { UseFormSetError } from 'react-hook-form';
import { useMutation } from 'react-query';
import { toMultiError } from 'utils/form';
import { errorToast, successToast } from 'utils/toast';

export const useCreateIdPoolConsumerAttribute = (
  id: IdPoolConsumerAttributeType['userPool'],
  setError: UseFormSetError<IdPoolConsumerAttributeCreateFormType>,
  setGlobalErrors: Dispatch<SetStateAction<string[]>>,
) => {
  const toast = useCustomToast();
  const idPoolConsumerId = useIdPoolConsumerId();
  const resetAttribute = useResetAllAttributes(id);
  const { isOuter } = useUserPoolAuthenticationType();

  const { mutateAsync, isLoading } = useMutation({
    mutationFn: (data: IdPoolConsumerAttributeCreateFormType) => {
      const params = data;
      if (!isOuter) {
        delete params.jsonPathValueType;
      }

      return createIdPoolConsumerAttribute(params, idPoolConsumerId);
    },
    onError: async (
      error,
      variables: IdPoolConsumerAttributeCreateFormType,
    ) => {
      if (error instanceof CustomError) {
        if (error.cause.errors?.others) {
          const othersMsgs = Array.isArray(error.cause.errors?.others)
            ? error.cause.errors?.others
            : [error.cause.errors?.others];

          setGlobalErrors(othersMsgs);
        } else {
          Object.keys(variables).forEach((k) => {
            const key = k as keyof IdPoolConsumerAttributeCreateFormType;
            const errMsgs = error.cause.errors?.[key];
            if (errMsgs && Array.isArray(errMsgs)) {
              setError(key, { types: toMultiError(errMsgs) });
            }
          });
        }

        if (error.cause.errors) {
          await logger({
            loglevel: error.logLevel,
            data: JSON.stringify(error.cause),
            message: error.message,
          });
        }
      }
      toast({
        ...errorToast,
        duration: 4000,
        title: '組織レベル属性の作成に失敗しました',
        position: 'bottom',
        variant: 'solid',
      });
    },
    onSuccess: () => {
      resetAttribute();

      toast({
        ...successToast,
        duration: 4000,
        title: '組織レベル属性を作成しました',
        position: 'bottom',
        variant: 'solid',
      });
    },
  });

  return { mutate: mutateAsync, isLoading };
};
