import {
  Box,
  Button,
  Drawer,
  Flex,
  Text,
  useDisclosure,
} from '@chakra-ui/react';
import { withSuspenseAndErrorBoundary } from 'admin/components/Error/WithErrorBoundary';
import { UserInfoDrawer } from 'admin/components/idPoolConsumer/userInfo/userInfoDrawer';
import { UserInfoComponent } from 'admin/components/idPoolConsumer/userInfo/userInfoInner/UserInfoComponent';
import { Colors } from 'admin/define/colors';
import { useIdPoolConsumerUserInfo } from 'admin/hooks/userPool/userInfo/useIdPoolConsumerUserInfo';
import { ErrorContents, LoadingSpinner } from 'components/common/atoms';
import { FC, memo } from 'react';
import { MdOutlineAdd } from 'react-icons/md';

type FetchUserInfoType = {
  isOpen: boolean;
  onClose: () => void;
};

const fetchUserInfo: FC<FetchUserInfoType> = memo(
  ({ isOpen, onClose }: FetchUserInfoType) => {
    const { data: userInfoDetail } = useIdPoolConsumerUserInfo();

    return (
      <>
        <UserInfoComponent userInfoDetail={userInfoDetail} />
        <Drawer
          isOpen={isOpen}
          placement="right"
          onClose={onClose}
          size="lg"
          closeOnOverlayClick={false}
          closeOnEsc={false}
          autoFocus={false}
        >
          <UserInfoDrawer userInfoDetail={userInfoDetail} onClose={onClose} />
        </Drawer>
      </>
    );
  },
);

export const FetchUserInfo = withSuspenseAndErrorBoundary(fetchUserInfo, {
  ErrorComponent: <ErrorContents name="外部IDシステムJSON設定画面" />,
  LoadingComponent: <LoadingSpinner />,
});

export const UserInfo: FC = memo(() => {
  const { isOpen, onClose, onOpen } = useDisclosure();

  return (
    <Box pt="40px">
      <Flex justifyContent="flex-end" style={{ gap: '24px' }}>
        <Box display="block" mr="auto">
          <Text
            fontWeight="700"
            fontSize="18px"
            lineHeight="170%"
            fontFamily="Hiragino Sans"
          >
            外部IDシステムJSON設定
          </Text>
          <Text
            fontWeight="400"
            fontSize="14px"
            lineHeight="170%"
            fontFamily="Hiragino Sans"
            color={Colors.GRAY_00}
          >
            ご利用中のIDシステムの利用者管理方法をJSON形式で登録します。取り込んだ情報は属性データと紐づける際に必要になります。
          </Text>
        </Box>
        <Button
          colorScheme="primary"
          p="8px 24px"
          minW="200px"
          onClick={onOpen}
          leftIcon={<MdOutlineAdd />}
        >
          <Text>外部IDシステムJSON設定</Text>
        </Button>
      </Flex>
      <Box mt="16px">
        <FetchUserInfo isOpen={isOpen} onClose={onClose} />
      </Box>
    </Box>
  );
});
