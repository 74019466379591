import { List, ListProps } from '@chakra-ui/react';
import { FC, memo } from 'react';

type Props = {
  children: React.ReactNode;
} & ListProps;

export const CardItemList: FC<Props> = memo(({ children, ...rest }: Props) => (
  <List width="calc(100% - 272px)" {...rest}>
    {children}
  </List>
));
