import { Checkbox, Text, Tr as ChakraTr } from '@chakra-ui/react';
import { Table, Tbody, Td, Th, Thead, Tr } from 'admin/components/ui/table';
import {
  TenantUserDeleteStateType,
  TenantUsersType
} from 'admin/types/tenantUsers';
import { useUserInfo } from 'hooks/useUserInfo';
import { FC, memo } from 'react';
// import { MdOutlineCreate } from 'react-icons/md';

export type Props = {
  isAllChecked: boolean;
  tenantUsersList: TenantUserDeleteStateType[];
  onOpen: (id: string, tenantId: string) => void;
  setAllCheck: () => void;
  setTargetCheck: (id: TenantUsersType) => void;
};

export const TenantUsersListComponent: FC<Props> = memo(
  ({
    isAllChecked,
    tenantUsersList,
    // onOpen,
    setAllCheck,
    setTargetCheck,
  }: Props) => {
    const { getPermissionName } = useUserInfo();

    return (
      <Table>
        <Thead position="sticky" zIndex="sticky" top={0}>
          <ChakraTr>
            <Th w="40px">
              <Checkbox
                isChecked={isAllChecked}
                onChange={() => setAllCheck()}
              />
            </Th>
            <Th w="198px">テナント名</Th>
            <Th w="198px">ユーザー名</Th>
            <Th w="198px">メールアドレス</Th>
            <Th w="198px">権限</Th>
            <Th> </Th>
          </ChakraTr>
        </Thead>
        <Tbody>
          {tenantUsersList.map((tenantUsers: TenantUserDeleteStateType) => (
            <Tr key={tenantUsers.id + tenantUsers.tenant.id}>
              <Td>
                <Checkbox
                  isChecked={tenantUsers.state}
                  onChange={() => setTargetCheck(tenantUsers)}
                />
              </Td>
              <Td>
                <Text>{tenantUsers.tenant.name}</Text>
              </Td>
              <Td>
                <Text>{tenantUsers.name}</Text>
              </Td>
              <Td>
                <Text>{tenantUsers.email}</Text>
              </Td>
              <Td>
                <Text>
                  {getPermissionName(Number(tenantUsers.tenant.permission))}
                </Text>
              </Td>
              <Td textAlign="right">
                {/*
                TODO: 4月以降対応
                <Button
                  variant="outline"
                  colorScheme="primary"
                  bg="WHITE"
                  leftIcon={<MdOutlineCreate />}
                  onClick={() => onOpen(tenantUsers.id, tenantUsers.tenant.id)}
                >
                  編集
                </Button> */}
              </Td>
            </Tr>
          ))}
        </Tbody>
      </Table>
    );
  },
);
