import { getIdPoolConsumerAttributes } from 'admin/api/userPool/attribute/getIdPoolConsumerAttributes';
import { CustomError } from 'admin/error/CustomError';
import { useCurrentPerPage } from 'admin/hooks/pagination/useCurrentPerPage';
import { convertToPage } from 'admin/schema/page';
import {
  IdPoolConsumerAttributesType,
  IdPoolConsumerAttributeType,
} from 'admin/types/userPool/attribute';
import { DataWithPage, Page } from 'api/common/types';
import { logger } from 'api/logger';
import { useQuery } from 'react-query';
import { queryIdPoolConsumerAttributeKey } from './queryIdPoolConsumerAttributeKey';

export const useIdPoolConsumerAttributes = (
  id: IdPoolConsumerAttributeType['userPool'],
  page: Page['currentPage'],
) => {
  const perPage = useCurrentPerPage();
  const { data, refetch, isLoading } = useQuery({
    queryKey: queryIdPoolConsumerAttributeKey.getIdPoolConsumerAttributes(
      id,
      page,
      perPage,
    ),
    queryFn: () => getIdPoolConsumerAttributes({ id, page, perPage }),
    onError: async (error) => {
      if (error instanceof CustomError) {
        await logger({
          loglevel: error.logLevel,
          data: JSON.stringify(error.cause),
          message: error.message,
        });
      }
    },
  });

  return {
    data: data?.results,
    page: convertToPage<DataWithPage<IdPoolConsumerAttributesType>>(data),
    refetch,
    isLoading,
  };
};
