import { zodResolver } from '@hookform/resolvers/zod';
import { withSuspenseAndErrorBoundary } from 'admin/components/Error/WithErrorBoundary';
import { AttributeCreateFormComponent } from 'admin/components/idPoolConsumer/attribute/attributeForm/attributeCreateForm/AttributeCreateFormComponent';
import { FIELD_UI_TYPE_LIST, JSON_PATH_VALUE_TYPE } from 'admin/define/field';
import { useIdPoolConsumerId } from 'admin/hooks/useIdPoolConsumerId';
import { useCreateIdPoolConsumerAttribute } from 'admin/hooks/userPool/attribute/useCreateIdPoolConsumerAttribute';
import { useFilterFieldTypeList } from 'admin/hooks/userPool/attribute/useFilterFieldTypeList';
import { useIsShowMasterType } from 'admin/hooks/userPool/attribute/useIsShowMasterType';
import { useAllCodeGroups } from 'admin/hooks/userPool/master/useAllCodeGroups';
import { useUserPool } from 'admin/hooks/userPool/useUserPool';
import { useUserPoolAuthenticationType } from 'admin/hooks/userPool/useUserPoolAuthenticationType';
import { idPoolConsumerAttributeFormSchema } from 'admin/schema/idPoolConsumer/attribute';
import { IdPoolConsumerAttributeType } from 'admin/types/userPool/attribute';
import { IdPoolConsumerAttributeCreateFormType } from 'admin/types/userPool/attribute/form';
import {
  AlertBar,
  ErrorContents,
  LoadingSpinner,
} from 'components/common/atoms';
import { FC, memo, useEffect, useMemo, useState } from 'react';
import {
  FormProvider,
  SubmitHandler,
  useForm,
  useWatch,
} from 'react-hook-form';

type Props = {
  attribute?: IdPoolConsumerAttributeType | undefined;
  onClose: () => void;
};

const attributeCreateForm: FC<Props> = memo(({ attribute, onClose }: Props) => {
  const formId = 'attributeForm';
  const idPoolConsumerId = useIdPoolConsumerId();

  const { data: codeGroups, isLoading: isLoadingCodeGroups } = useAllCodeGroups(
    { id: idPoolConsumerId, isEnabled: true },
  );

  const { isOuter } = useUserPoolAuthenticationType();
  const { data: userPool, isLoading: isLoadingUserPool } =
    useUserPool(idPoolConsumerId);

  const methods = useForm<IdPoolConsumerAttributeCreateFormType>({
    defaultValues: {
      displayNameJa: '',
      displayNameForeign: '',
      fieldName: '',
      dataType: '',
      uiType: '',
      codeGroup: '',
      jsonPathValueType: '',
      isPersonalInformation: false,
      jsonPath: userPool?.userInfoResponse ? '$' : '',
      displayOrder: '',
    },
    resolver: zodResolver(idPoolConsumerAttributeFormSchema),
  });
  const [globalErrors, setGlobalErrors] = useState<string[]>([]);
  const { control, setError, setValue, reset } = methods;

  const { mutate, isLoading } = useCreateIdPoolConsumerAttribute(
    idPoolConsumerId,
    setError,
    setGlobalErrors,
  );

  const selectedFieldUiType = useWatch({
    control,
    name: 'uiType',
  });

  const filterFieldTypeList = useFilterFieldTypeList(selectedFieldUiType);
  const isShowMasterType = useIsShowMasterType(selectedFieldUiType);

  const isPreset = useMemo<boolean>(() => {
    if (!attribute) return false;

    return attribute.fieldName === 'email' || attribute.fieldName === 'tel';
  }, [attribute]);

  const isEdit = useMemo<boolean>(() => {
    if (!attribute) return false;

    return true;
  }, [attribute]);

  const onSubmit: SubmitHandler<IdPoolConsumerAttributeCreateFormType> = async (
    data: IdPoolConsumerAttributeCreateFormType,
  ) => {
    if (!isLoading && !isLoadingUserPool) {
      await mutate(data);
    }
    reset();
    onClose();
  };

  // isShowMasterTypeがtrueの時、ドロワー最下部に表示される
  // 「コード値」選択ラジオボタンの初期値を設定する
  useEffect(() => {
    if (isShowMasterType) {
      setValue('jsonPathValueType', JSON_PATH_VALUE_TYPE.displayName);
    } else {
      setValue('jsonPathValueType', '');
    }
  }, [isShowMasterType, setValue]);

  return (
    <FormProvider {...methods}>
      {globalErrors.map((err, idx) => (
        <AlertBar
          key={`global-err-idx${String(idx)}`}
          message={err}
          status="error"
          mb={4}
        />
      ))}
      <AttributeCreateFormComponent
        isExternalId={isOuter}
        isPreset={isPreset}
        isEdit={isEdit}
        fieldUiTypeList={FIELD_UI_TYPE_LIST}
        fieldTypeList={filterFieldTypeList}
        isShowMasterType={isShowMasterType}
        onClose={onClose}
        onSubmit={onSubmit}
        isLoading={isLoading || isLoadingCodeGroups || isLoadingUserPool}
        formId={formId}
        codeGroups={codeGroups}
        userInfoResponse={userPool?.userInfoResponse}
        idPoolConsumerId={idPoolConsumerId}
      />
    </FormProvider>
  );
});

export const AttributeCreateForm = withSuspenseAndErrorBoundary(
  attributeCreateForm,
  {
    ErrorComponent: <ErrorContents name="組織レベル属性詳細" />,
    LoadingComponent: <LoadingSpinner />,
  },
);

attributeCreateForm.defaultProps = {
  attribute: undefined,
};
