import {
  AlertDialogCloseButton,
  AlertDialogContent,
  AlertDialogHeader,
  AlertDialogOverlay,
  Box,
  Text,
} from '@chakra-ui/react';
import { FormAttrForm } from 'admin/components/idPoolConsumer/attribute/formAttrForm';
import { IdPoolConsumerAttributeType } from 'admin/types/userPool/attribute';
import { Page } from 'api/common/types';
import { FC, memo } from 'react';

type Props = {
  target: IdPoolConsumerAttributeType | undefined;
  onClose: () => void;
  currentPage: Page['currentPage'];
};

export const FormAttrDialog: FC<Props> = memo((props) => (
  <AlertDialogOverlay>
    <AlertDialogContent>
      <AlertDialogHeader p={6}>
        <Box>
          <Text>全フォーム利用の確認</Text>
        </Box>
      </AlertDialogHeader>
      <FormAttrForm {...props} />
      <AlertDialogCloseButton top="16px" right="24px" />
    </AlertDialogContent>
  </AlertDialogOverlay>
));
