import {
  Box,
  DrawerCloseButton,
  DrawerContent,
  DrawerHeader,
  DrawerOverlay,
  Text,
} from '@chakra-ui/react';
import { withSuspenseAndErrorBoundary } from 'admin/components/Error/WithErrorBoundary';
import {
  AttriburteCreateForm,
  AttributeEditForm,
} from 'admin/components/service/attribute/attributeForm/';
import { useServiceAttributeDetail } from 'admin/hooks/service/attribute/useServiceAttributeDetail';
import {
  ServiceAttributeType,
  UserPoolChildAttributeType,
} from 'admin/types/service/attribute';
import { ErrorContents, LoadingSpinner } from 'components/common/atoms';
import { FC, memo } from 'react';

type FetchAttributeProps = {
  id: UserPoolChildAttributeType['id'];
  onClose: () => void;
};

const fetchAttributeDrawer: FC<FetchAttributeProps> = memo(
  ({ id, onClose }: FetchAttributeProps) => {
    const { data: attribute } = useServiceAttributeDetail(id);

    if (!attribute) {
      return null;
    }

    return <AttributeEditForm attribute={attribute} onClose={onClose} />;
  },
);

const FetchAttributeDrawer = withSuspenseAndErrorBoundary(
  fetchAttributeDrawer,
  {
    ErrorComponent: <ErrorContents name="属性利用設定" />,
    LoadingComponent: <LoadingSpinner />,
  },
);

type Props = {
  serviceAttribute: ServiceAttributeType | null;
  id: UserPoolChildAttributeType['id'];
  onClose: () => void;
};

const AttributeFormWrap: FC<Props> = memo(
  ({ serviceAttribute, id, onClose }: Props) => {
    // 未割当時（テナント未紐づけ）
    if (!id) {
      return (
        <AttriburteCreateForm
          serviceAttribute={serviceAttribute}
          onClose={onClose}
        />
      );
    }

    // 割当済み時（テナント紐づけ済み）
    return <FetchAttributeDrawer id={id} onClose={onClose} />;
  },
);

export const AttributeDrawer: FC<Props> = memo(
  ({ serviceAttribute, id, onClose }: Props) => (
    <>
      <DrawerOverlay />
      <DrawerContent>
        <DrawerHeader pb={0}>
          <Box m={0} px={0} pb="24px" borderBottomWidth="1px">
            <Text>属性利用設定</Text>
          </Box>
        </DrawerHeader>
        <AttributeFormWrap
          serviceAttribute={serviceAttribute}
          id={id}
          onClose={onClose}
        />
        <DrawerCloseButton top="16px" right="24px" />
      </DrawerContent>
    </>
  ),
);
