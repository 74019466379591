import { Button, Flex, Text } from '@chakra-ui/react';
import { AttributeTableBodyProps } from 'admin/components/service/attribute/attributeTableBody/index';
import { EditIcon } from 'admin/components/ui/icon/editIcon';
import { StatusLabel as Status } from 'admin/components/ui/status/StatusLabel';
import { Td, Tr } from 'admin/components/ui/table';
import { Colors } from 'admin/define/colors';
import {
  getDataTypeNameByValue,
  getUiTypeNameByValue,
} from 'admin/hooks/userPool/attribute/useFilterFieldTypeList';
import { FC, memo, useMemo } from 'react';

type Props = AttributeTableBodyProps & {
  codeGroupName: (id: string | null | undefined) => string | null | undefined;
};

export const AttributeTableBodyComponent: FC<Props> = memo(
  ({
    attribute,
    onOpen,
    onOpenFormAttrDialog,
    onOpenChildAttrDialog,
    onOpenUserAttrDialog,
    onOpenUserAttrRequiredDialog,
    codeGroupName,
    hasUniikey,
  }: Props) => {
    const isPresetEmail = useMemo(
      () => attribute.fieldName === 'email',
      [attribute],
    );

    return (
      <Tr>
        <Td>
          <Text
            fontSize="14px"
            fontWeight="700"
            color={Colors.BLUE_20}
            _hover={{
              cursor: 'pointer',
              textDecoration: 'underline',
            }}
            onClick={() =>
              onOpen(attribute.id, attribute.userPoolChildAttribute?.id || '')
            }
          >
            {attribute.id}
          </Text>
        </Td>
        <Td fontSize="14px">{attribute.displayNameJa}</Td>
        <Td fontSize="14px">{attribute.displayNameForeign || '-'}</Td>
        <Td fontSize="14px">{attribute.fieldName}</Td>
        <Td fontSize="14px">{getUiTypeNameByValue(attribute.uiType)}</Td>
        <Td fontSize="14px">{getDataTypeNameByValue(attribute.dataType)}</Td>
        <Td fontSize="14px">
          {codeGroupName(
            typeof attribute.codeGroup === 'object'
              ? attribute?.codeGroup?.id
              : attribute.codeGroup,
          ) || '-'}
        </Td>
        <Td fontSize="14px">
          <Status
            status={attribute.isPersonalInformation}
            onLabel="ON"
            offLabel="OFF"
          />
        </Td>
        {hasUniikey && (
          <Td hasBorder fontSize="14px">
            <Status
              status={attribute.isDisplayAccountSettings}
              onLabel="ON"
              offLabel="OFF"
            />
          </Td>
        )}
        {!hasUniikey && (
          <Td hasBorder fontSize="14px">
            {attribute.jsonPath || '-'}
          </Td>
        )}
        <Td fontSize="14px">
          <Flex gridGap={2}>
            <Status
              status={!!attribute.userPoolChildAttribute?.id}
              onLabel="ON"
              offLabel="OFF"
            />
            <Button
              variant="outline"
              colorScheme="primary"
              bgColor="WHITE"
              w="32px"
              h="32px"
              p="0"
              disabled={!!attribute.userPoolChildAttribute?.id}
              onClick={() =>
                onOpenChildAttrDialog(
                  attribute.id,
                  attribute.userPoolChildAttribute?.id || '',
                )
              }
            >
              <EditIcon />
            </Button>
          </Flex>
        </Td>
        <Td hasBorder fontSize="14px">
          <Flex gridGap={2}>
            <Status
              status={!!attribute.userPoolChildAttribute?.isFormAttr}
              onLabel="ON"
              offLabel="OFF"
            />
            <Button
              variant="outline"
              colorScheme="primary"
              bgColor="WHITE"
              w="32px"
              h="32px"
              p="0"
              disabled={!!attribute.userPoolChildAttribute?.isFormAttr}
              onClick={() =>
                onOpenFormAttrDialog(
                  attribute.id,
                  attribute.userPoolChildAttribute?.id || '',
                )
              }
            >
              <EditIcon />
            </Button>
          </Flex>
        </Td>
        {hasUniikey && (
          <>
            <Td fontSize="14px">
              <Flex gridGap={2}>
                <Status
                  status={Boolean(attribute.userPoolChildAttribute?.isUserAttr)}
                  onLabel="ON"
                  offLabel="OFF"
                />
                <Button
                  variant="outline"
                  colorScheme="primary"
                  bgColor="WHITE"
                  w="32px"
                  h="32px"
                  p="0"
                  disabled={
                    !attribute.userPoolChildAttribute?.id || isPresetEmail
                  }
                  onClick={() =>
                    onOpenUserAttrDialog(
                      attribute.id,
                      attribute.userPoolChildAttribute?.id || '',
                    )
                  }
                >
                  <EditIcon />
                </Button>
              </Flex>
            </Td>
            <Td hasBorder fontSize="14px">
              <Flex gridGap={2}>
                <Status
                  status={Boolean(
                    attribute.userPoolChildAttribute?.isUserAttrRequired,
                  )}
                  onLabel="ON"
                  offLabel="OFF"
                />
                <Button
                  variant="outline"
                  colorScheme="primary"
                  bgColor="WHITE"
                  w="32px"
                  h="32px"
                  p="0"
                  disabled={
                    !attribute.userPoolChildAttribute?.id || isPresetEmail
                  }
                  onClick={() =>
                    onOpenUserAttrRequiredDialog(
                      attribute.id,
                      attribute.userPoolChildAttribute?.id || '',
                    )
                  }
                >
                  <EditIcon />
                </Button>
              </Flex>
            </Td>
          </>
        )}
      </Tr>
    );
  },
);
