import { Box, VStack } from '@chakra-ui/react';
import { PaywallCard } from 'components/paywall//pages/WallList/PaywallCardList/PaywallCard';
import { Wall } from 'components/paywall//pages/WallList/typed';
import { useWallList } from 'hooks/paywall/useWallList';
import { FC, Fragment, memo } from 'react';

export const PaywallCardList: FC = memo(() => {
  const {wallList, childWallList} = useWallList({ wallType: 'course' });

  // 親のIDを持つ子wallListをフィルタする
  const filterChildWallList = (parentId: string): Wall[] => childWallList.filter((child) => child.parent === parentId)

  return (
    <Box my="45px" minW="855px" className="PaywallCardList">
      <VStack spacing='56px' alignItems="flex-end">
        {wallList.map((parent) => (
          <Fragment key={parent.id}>
            <PaywallCard wall={parent} />
            {filterChildWallList(parent.id).map((child) => (
              <PaywallCard key={child.id} wall={child} isChild />
            ))}
          </Fragment>
        ))}
      </VStack>
    </Box>
  )
})
