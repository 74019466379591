import { atom } from 'recoil';

export const navigateState = atom<string>({
  key: 'navigateState',
  default: '',
});

export const isEditState = atom<boolean>({
  key: 'isEditState',
  default: false,
});

export const editCallbackState = atom<() => void>({
  key: 'editCallbackState',
  default: () => undefined,
});
