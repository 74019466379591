import { AlertDialog, useDisclosure } from '@chakra-ui/react';
import { withSuspenseAndErrorBoundary } from 'admin/components/Error/WithErrorBoundary';
import { IdPoolConsumerBlockComponent } from 'admin/components/idPoolConsumer/idPoolConsumerBlock/IdPoolConsumerBlockComponent';
import { IdPoolConsumerEditDialog } from 'admin/components/idPoolConsumer/idPoolConsumerDialog';
import { useUserCorporationInfo } from 'admin/hooks/user/useUserCorporationInfo';
import { IdPoolConsumerListItem } from 'admin/types/userPool';
import { ErrorContents, LoadingSpinner } from 'components/common/atoms';
import { FC, memo, useRef } from 'react';
import { useNavigate } from 'react-router-dom';

type Props = {
  idPoolConsumer: IdPoolConsumerListItem;
};

export const idPoolConsumerBlock: FC<Props> = memo(
  ({ idPoolConsumer }: Props) => {
    const { isOpen, onClose, onOpen } = useDisclosure();
    const cancelRef = useRef(null);
    const navigate = useNavigate();
    const { hasPermissionUserPool, isShowUserPool } = useUserCorporationInfo();

    if (!hasPermissionUserPool && !isShowUserPool(idPoolConsumer.id)) return null;

    return (
      <>
        <IdPoolConsumerBlockComponent
          idPoolConsumer={idPoolConsumer}
          onOpen={onOpen}
          navigate={navigate}
        />

        {/* 編集時のモーダル */}
        <AlertDialog
          isOpen={isOpen}
          onClose={onClose}
          leastDestructiveRef={cancelRef}
          isCentered
          closeOnOverlayClick={false}
          closeOnEsc={false}
          size="xl"
        >
          <IdPoolConsumerEditDialog id={idPoolConsumer.id} onClose={onClose} />
        </AlertDialog>
      </>
    );
  },
);

export const IdPoolConsumerBlock = withSuspenseAndErrorBoundary(
  idPoolConsumerBlock,
  {
    ErrorComponent: <ErrorContents name="ユーザープール情報" />,
    LoadingComponent: <LoadingSpinner />,
  },
);
