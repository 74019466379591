import { QuestionIcon } from '@chakra-ui/icons';
import { HStack, ListItem, Text, Tooltip, UnorderedList } from '@chakra-ui/react';
import { Colors } from 'components/paywall/config';
import { FC, memo } from 'react';

type Props = {
  title?: string;
  customerHelp?: boolean;
}
export const DetailHeader: FC<Props> = memo(({title = '', customerHelp = false}) => {

  if(!title) return <></>;

  return (
    <HStack spacing="8px" mb="4px">
      <Text
        as="h4"
        fontSize="18px"
        fontWeight="700"
        lineHeight="170%"
      >
        {title}
      </Text>
      {customerHelp && (
        <Tooltip
          hasArrow
          placement='right'
          maxWidth="610px"
          bgColor={Colors.GRAY_03}
          label={
            <UnorderedList style={{ paddingTop: "4px", paddingLeft: "8px", paddingRight: "8px", paddingBottom: "4px" }}>
              <ListItem fontSize="12px" fontWeight="600">前日時点での情報になります。本日新規で登録した会員はメールアドレスなどは情報は表示されません。</ListItem>
              <ListItem fontSize="12px" fontWeight="600">姓名、電話番号、住所のうち会員登録時に取得していない場合には表示されません。</ListItem>
            </UnorderedList>
          }
        >
          <QuestionIcon />
        </Tooltip>
      )}
    </HStack>
  )
});
