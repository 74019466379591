import { zodResolver } from '@hookform/resolvers/zod';
import { withSuspenseAndErrorBoundary } from 'admin/components/Error/WithErrorBoundary';
import { AttributeGroupCreateFormComponent } from 'admin/components/service/attribute/attributeGroupForm/attributeGroupCreateForm/AttributeGroupCreateFormComponent';
import { useCreateServiceAttributeGroup } from 'admin/hooks/service/attribute/useCreateServiceAttributeGroup';
import { serviceAttributeGroupFormSchema } from 'admin/schema/service/attribute';
import { ServiceAttributeGroupType } from 'admin/types/service/attribute';
import { ServiceAttributeGroupFormType } from 'admin/types/service/attribute/form';
import {
  AlertBar,
  ErrorContents,
  LoadingSpinner,
} from 'components/common/atoms';
import { FC, memo, useState } from 'react';
import { FormProvider, SubmitHandler, useForm } from 'react-hook-form';

type Props = {
  serviceAttributeGroup: ServiceAttributeGroupType | null;
  onClose: () => void;
};

const attributeGroupCreateForm: FC<Props> = memo(
  ({ serviceAttributeGroup, onClose }: Props) => {
    const [globalErrors, setGlobalErrors] = useState<string[]>([]);
    const formId = `serviceAttributeCreateGroupForm`;
    const methods = useForm<ServiceAttributeGroupFormType>({
      defaultValues: {
        userAttributeSetting: null,
        attributeGroupHead: serviceAttributeGroup?.id,
        isFormAttr: false,
        isUserAttr: false,
        isUserAttrRequired: false,
      },
      resolver: zodResolver(serviceAttributeGroupFormSchema),
    });
    const { setError } = methods;
    const { mutate, isLoading } = useCreateServiceAttributeGroup(
      setError,
      setGlobalErrors,
    );

    const onSubmit: SubmitHandler<ServiceAttributeGroupFormType> = async (
      data: ServiceAttributeGroupFormType,
    ) => {
      await mutate(data);
      onClose();
    };

    return (
      <FormProvider {...methods}>
        {globalErrors.map((err, idx) => (
          <AlertBar
            key={`global-err-idx${String(idx)}`}
            message={err}
            status="error"
            mb={4}
          />
        ))}
        <AttributeGroupCreateFormComponent
          serviceAttributeGroup={serviceAttributeGroup}
          formId={formId}
          isLoading={isLoading}
          onClose={onClose}
          onSubmit={onSubmit}
        />
      </FormProvider>
    );
  },
);

export const AttributeGroupCreateForm = withSuspenseAndErrorBoundary(
  attributeGroupCreateForm,
  {
    ErrorComponent: <ErrorContents name="組織レベル属性詳細" />,
    LoadingComponent: <LoadingSpinner />,
  },
);
