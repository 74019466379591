import { useEffect, useState } from 'react';
import { useSearchParams } from 'react-router-dom';
import { useQueryClient } from 'react-query';
import { useUserTenantId } from 'hooks/user/useUserTenantId';
import { queryEnqueteKey } from 'hooks/enquete/queryEnqueteKey';

export const useEnqueteUtil = (): {
  ClearEnqueteListCache: () => void;
  EnqueteListRefetchQueries: () => void;
  EnqueteFormRefetchListQueries: () => void;
  ClearEnqueteSkinListCache: () => void;
  EnqueteSkinRefetchListQueries: () => void;
} => {
  const [searchParams] = useSearchParams();
  const queryClient = useQueryClient();
  const tenantId = useUserTenantId();

  const [queryOrdering, setQueryOrdering] = useState(
    searchParams.get('ordering') || '',
  );
  const [queryPage, setQueryPage] = useState(searchParams.get('page') || '');
  const [queryOpenDrawer, setQueryOpenDrawer] = useState(
    searchParams.get('openDrawer') || '',
  );
  const [queryPerPage, setQueryPerPage] = useState(
    searchParams.get('perPage') || '',
  );
  const [queryReserve, setQueryReserve] = useState(
    searchParams.get('reserve') || '',
  );
  const [queryStatus, setQueryStatus] = useState(
    searchParams.get('status') || '',
  );

  useEffect(() => {
    setQueryOrdering(searchParams.get('ordering') || '');
    setQueryPage(searchParams.get('page') || '');
    setQueryOpenDrawer(searchParams.get('openDrawer') || '');
    setQueryPerPage(searchParams.get('perPage') || '');
    setQueryReserve(searchParams.get('reserve') || '');
    setQueryStatus(searchParams.get('status') || '');
  }, [searchParams]);

  /**
   * アンケートリストのキャッシュクリア
   */
  const ClearEnqueteListCache = () => {
    void queryClient.invalidateQueries(
      queryEnqueteKey.getEnqueteList({
        tenantId,
        ordering: queryOrdering,
        page: queryPage,
        openDrawer: queryOpenDrawer,
        perPage: queryPerPage,
        reserve: queryReserve,
        status: queryStatus,
      }),
    );
  };

  const EnqueteListRefetchQueries = () => {
    void queryClient.refetchQueries(
      queryEnqueteKey.getEnqueteList({
        tenantId,
        ordering: queryOrdering,
        page: queryPage,
        openDrawer: '',
        perPage: queryPerPage,
        reserve: queryReserve,
        status: queryStatus,
      }),
    );
  };

  const EnqueteFormRefetchListQueries = () => {
    void queryClient.refetchQueries(
      queryEnqueteKey.getEnqueteList({
        tenantId,
        ordering: '',
        page: '1',
        openDrawer: '',
        perPage: '',
        reserve: '',
        status: '',
      }),
    );
  };

  /**
   * デザインスキンリストのキャッシュクリア
   */
  const ClearEnqueteSkinListCache = () => {
    void queryClient.invalidateQueries(
      queryEnqueteKey.getSkinList({
        tenantId,
        page: queryPage,
      }),
    );
  };

  const EnqueteSkinRefetchListQueries = () => {
    void queryClient.refetchQueries(
      queryEnqueteKey.getSkinList({
        tenantId,
        page: '1',
      }),
    );
  };

  return {
    ClearEnqueteListCache,
    EnqueteListRefetchQueries,
    EnqueteFormRefetchListQueries,
    ClearEnqueteSkinListCache,
    EnqueteSkinRefetchListQueries,
  };
};
