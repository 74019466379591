import {
  Button,
  ButtonGroup,
  Modal,
  ModalOverlay,
  ModalContent,
  ModalHeader,
  ModalBody,
  ModalFooter,
  Text,
} from '@chakra-ui/react';
import { memo } from 'react';

export const NotSavedConfirmModal = memo(
  ({
    isOpen,
    onClose,
    onNext,
  }: {
    isOpen: boolean;
    onClose: () => void;
    onNext: () => void;
  }) => (
    <Modal isOpen={isOpen} size="lg" onClose={onClose} isCentered>
      <ModalOverlay />
      <ModalContent bg="White">
        <ModalHeader>
          <Text as="b">変更内容が保存されていません</Text>
        </ModalHeader>
        <ModalBody>
          <Text>保存されていない変更は失われますが、よろしいですか？</Text>
        </ModalBody>
        <ModalFooter>
          <ButtonGroup spacing="4">
            <Button variant="outline" size="md" w="full" onClick={onClose}>
              <Text as="b">キャンセル</Text>
            </Button>
            <Button colorScheme="blue" size="md" w="full" onClick={onNext}>
              <Text as="b">保存せずに進む</Text>
            </Button>
          </ButtonGroup>
        </ModalFooter>
      </ModalContent>
    </Modal>
  ),
);
