import { Box, Button, Flex, Text, Tr as ChakraTr } from '@chakra-ui/react';
import { Table, Tbody, Td, Th, Thead, Tr } from 'admin/components/ui/table';
import { ColorSet } from 'admin/define/colors';
import { MemberLogsType } from 'admin/types/member/';
import { FC, memo } from 'react';
import { NavigateFunction } from 'react-router-dom';

type Props = {
  memberId: string;
  memberLogs: MemberLogsType;
  navigate: NavigateFunction;
  hasTitle: boolean;
};

export const MemberLogTableComponent: FC<Props> = memo(
  ({ memberId, memberLogs, navigate, hasTitle }: Props) => (
    <Box>
      {hasTitle && (
        <Flex alignItems="center" justifyContent="space-between">
          <Text fontWeight="700">利用履歴</Text>
          <Button
            {...ColorSet.Default}
            onClick={() => navigate(`/admin/member/${memberId}/log`)}
          >
            利用履歴 一覧
          </Button>
        </Flex>
      )}
      <Box mt={4}>
        <Table tableHeight="100%">
          <Thead>
            <ChakraTr>
              <Th width="170px">日時</Th>
              <Th>内容</Th>
            </ChakraTr>
          </Thead>
          <Tbody>
            {memberLogs.map((memberLog, idx) => (
              <Tr key={`memberLog_${String(idx)}`}>
                <Td>{memberLog.historyDate}</Td>
                <Td>{memberLog.content}</Td>
              </Tr>
            ))}
          </Tbody>
        </Table>
      </Box>
    </Box>
  ),
);
