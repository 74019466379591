import {
  Flex,
  FormControl,
  FormErrorMessage,
  FormLabel,
  Input,
  // Switch,
  Text,
  Textarea,
  Tooltip,
} from '@chakra-ui/react';
import { FC, Ref } from 'react';
import { QuestionIcon } from '@chakra-ui/icons';
import { Settings } from 'admin/components/authHub/settings';
import { ColorPicker } from 'components/common/atoms';
import { ChangeHandler, RegisterOptions, FieldErrors } from 'react-hook-form';
import { FormType } from 'admin/types/authHub';

type Temp = keyof {
  [J in Settings[keyof Settings][number]['name']]: string;
};

type SS = Settings[keyof Settings][number];

type InputItemProps = {
  register: (
    name: string,
    options?: RegisterOptions,
  ) => {
    onChange: ChangeHandler;
    onBlur: ChangeHandler;
    name: string;
    /* eslint-disable-next-line */
    ref: Ref<any>;
  };
  errors: FieldErrors<FormType>;
  setting: SS;
  getValues: (name: Temp) => string;
};

type Extract<V extends string, T extends readonly { name: string }[]> = {
  [K in T[number]['name'] as K extends `${V}.${infer U}` ? U : never]: string;
};

type S = {
  [K in keyof Settings]: Extract<K, Settings[K]>;
};

export const InputItem: FC<InputItemProps> = ({
  register,
  setting,
  getValues,
  errors,
}) => {
  const { name, label, options } = setting;
  const path = name.split('.');
  const error: FieldErrors<FormType> = errors[path[0]];

  if (setting.type === 'ColorPicker') {
    return (
      <Flex alignItems="flex-end" mb={4}>
        <FormControl isInvalid={!!(error && error[path[1]])} mr={4}>
          <FormLabel htmlFor={name}>
            <Text as="b" fontSize="sm">
              {label}
            </Text>
          </FormLabel>
          <Input
            id={name}
            placeholder="#FFFFFF"
            backgroundColor="#ffffff"
            mr={4}
            {...register(name, options)}
            value={getValues(name)}
          />
          <FormErrorMessage>
            {error && error[path[1]] && error[path[1]]?.message}
          </FormErrorMessage>
        </FormControl>
        <ColorPicker<S>
          name={name}
          value={getValues(name)}
          width="40px"
          height="40px"
        />
      </Flex>
    );
  }
  if (setting.type === 'Text') {
    return (
      <Flex alignItems="flex-end" mb={4}>
        <FormControl isInvalid={!!(error && error[path[1]])}>
          <FormLabel htmlFor={name}>
            <Text as="span" fontSize="xs" color="teal.800" mr={2}>
              {label}
            </Text>
            {setting.tooltip && (
              <Tooltip label={setting.tooltip} whiteSpace="pre-wrap">
                <QuestionIcon verticalAlign="middle" />
              </Tooltip>
            )}
          </FormLabel>
          <Input
            id={name}
            backgroundColor="#ffffff"
            {...register(name, options)}
          />
          <FormErrorMessage>
            {error && error[path[1]] && error[path[1]]?.message}
          </FormErrorMessage>
        </FormControl>
      </Flex>
    );
  }
  if (setting.type === 'Textarea') {
    return (
      <Flex alignItems="flex-end" mb={4}>
        <FormControl isInvalid={!!(error && error[path[1]])}>
          <FormLabel htmlFor={name}>
            <Text as="span" fontSize="xs" color="teal.800">
              {label}
            </Text>
          </FormLabel>
          <Textarea
            id={name}
            bg="#ffffff"
            {...(setting.elementOptions || {})}
            {...register(name, options)}
          />
          <FormErrorMessage>
            {error && error[path[1]] && error[path[1]]?.message}
          </FormErrorMessage>
        </FormControl>
      </Flex>
    );
  }
  // if (setting.type === 'Toggle') {
  //   return (
  //     <Flex alignItems="flex-end">
  //       <FormControl display="flex" alignItems="center">
  //         <FormLabel htmlFor={name} mb="0">
  //           {label}
  //         </FormLabel>
  //         <Controller
  //           name={name}
  //           control={control}
  //           defaultValue={getValues(name)}
  //           render={({ field }) => (
  //             <Switch
  //               {...field}
  //               onChange={(e) => field.onChange(e.target.checked)}
  //               isChecked={!!field.value}
  //             />
  //           )}
  //         />
  //       </FormControl>
  //     </Flex>
  //   );
  // }

  return null;
};
