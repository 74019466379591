import { Stack } from '@chakra-ui/react';
import ErrorBoundary from 'api/ErrorBoundary';
import {
  ErrorContents,
  ErrorStatusContents,
  LoadingSpinner,
} from 'components/common/atoms';
import { MailCreateForm } from 'components/transactionMail/SendingContentForm/MailCreateForm';
import { MailEditForm } from 'components/transactionMail/SendingContentForm/MailEditForm';
import { useErrorBoundaryReset } from 'hooks/useErrorBoundaryReset';
import { useSetPageTitle } from 'hooks/useSetPageTitle';
import { memo, Suspense, useMemo, VFC } from 'react';
import { useParams } from 'react-router-dom';

export const SendingContentForm: VFC = memo(() => {
  useSetPageTitle('送信内容設定');
  const { ebKey, onError } = useErrorBoundaryReset();

  const params = useParams();
  const transactionMailId = useMemo(
    () => params.transactionMailId ?? '',
    [params],
  );

  const renderErrorComponent = {
    404: <ErrorStatusContents code={404} />,
    450: <ErrorStatusContents code={450} />,
  };

  return (
    <ErrorBoundary
      errorComponent={<ErrorContents name="送信内容設定" />}
      statusComponent={renderErrorComponent}
      onError={onError}
      key={ebKey.current}
    >
      <Suspense fallback={<LoadingSpinner />}>
        <Stack spacing={0} w="100%" h="full">
          {transactionMailId === '' && <MailCreateForm />}
          {transactionMailId !== '' && <MailEditForm id={transactionMailId} />}
        </Stack>
      </Suspense>
    </ErrorBoundary>
  );
});
