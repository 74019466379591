import { VFC, memo } from 'react';
import { useFormContext } from 'react-hook-form';
import { Box, Heading } from '@chakra-ui/react';
import { EnqueteSurveyForm } from 'api/enquete/types';
import { InputForm } from 'components/common/molecules';

export const EnqueteTitle: VFC = memo(() => {
  const { getValues } = useFormContext<EnqueteSurveyForm>();

  return (
    <Box w="100%" display="flex" alignItems="center" flexWrap="nowrap">
      <Heading
        as="h2"
        size="md"
        display="inline"
        whiteSpace="nowrap"
      >
        タイトル
      </Heading>
      <Box display="inline" w="full" whiteSpace="nowrap">
        <InputForm<EnqueteSurveyForm>
          horizontal
          name="title"
          type="text"
          placeholder="タイトルを入力してください"
          backgroundColor="#f9f9f9"
          borderColor="#ccc"
          attr={{
            required: 'タイトルを入力してください',
          }}
          isDisabled={getValues('uneditableState')}
        />
      </Box>
    </Box>
  );
});
