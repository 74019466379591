import {
  Field,
  NameLabelPair,
  OptionGroup,
  RuleType,
} from 'react-querybuilder';

/**
 * セグメント追加 Type
 */
export type AddSegmentFormType = {
  name: string;
  tenantId: string;
  parentId?: string;
};

/**
 * セグメント Type
 */
export type Segment = {
  id: string;
  name: string;
};

/**
 * 配信対象件数取得 type
 */
export type SegmentTargetCnt = {
  targetCnt: number;
};

/**
 * セグメントデータ属性 type
 */
export type SegmentAttributes = {
  id: string;
  name: string;
  fields: Field[];
};

/**
 * セグメント条件フィールドデータ type
 */
export type SegmentAttributeItem = {
  name: string;
  label: string;
  inputType: string;
  valueEditorType: string;
  defaultOperator: string;
  defaultValue: string;
  values: Field[];
  operators: NameLabelPair[] | OptionGroup<NameLabelPair>[];
};

export type SegmentAttributeParent = {
  id: string;
  title: string;
  data: SegmentAttributeItem[];
};

/**
 * セグメント条件フィールド type
 */
export type SegmentAttribute = {
  id: string;
  users: SegmentAttributeItem[];
  enquetes: SegmentAttributeParent[];
  contents: SegmentAttributeParent[];
};

/**
 * セグメントデータ属性 user type
 */
export type SegmentUserAttribute = {
  id: string;
  name: string;
  fields: SegmentAttributeItem[];
};

export type SegmentChildAttrList = {
  id: string;
  title: string;
  fields: SegmentAttributeItem[];
};

export type SegmentChildAttribute = {
  id: string;
  name: string;
  fieldList: SegmentChildAttrList[];
};

export type SegmentAttributeList = SegmentUserAttribute | SegmentChildAttribute;

export type CustomRuleChildType =
  | {
      path?: number[];
      id?: string;
      disabled?: boolean;
      combinator: string;
      rules: RuleType[];
      not?: boolean;
    }
  | RuleType;

export type CustomRuleTemporaryType = {
  user: CustomRuleChildType[];
  enquete: {
    id: string;
    rules: CustomRuleChildType[];
  };
  contents: {
    id: string;
    rules: CustomRuleChildType[];
  };
};

export type CustomRuleType = {
  customeGroupName?: string;
  customeGroupType?: keyof CustomRuleTemporaryType;
  customeGroupId?: string;
  fieldId?: string;
  path?: number[];
  id?: string;
  disabled?: boolean;
  combinator: string;
  rules: CustomRuleChildType[];
  not?: boolean;
};

export type CustomRuleGroupType = {
  // ユーザー属性 | アンケート属性
  customeGroupName?: string;
  customeGroupType?: keyof CustomRuleTemporaryType;
  customeGroupId?: string;
  fieldId?: string;
  path?: number[];
  id?: string;
  disabled?: boolean;
  combinator: string;
  rules: CustomRuleType[]; // クエリ
  not?: boolean;
};

/**
 * セグメント情報 type
 */
export type SegmentDetail = {
  tenantId: string;
  id: string;
  name: string;
  query: CustomRuleGroupType;
};

/**
 * セグメントフィールド type
 */
export type SegmentField = Field;

/**
 * セグメント削除 type
 */
export type SegmentDeleteType = {
  segmentId: string;
};

export const isSegment = (data: unknown): data is Segment => {
  const s = data as Segment;

  return typeof s?.id === 'string' && typeof s?.name === 'string';
};

export const isSegmentList = (datas: unknown[]): datas is Segment[] =>
  !datas.some((d) => !isSegment(d));

export const isSegmentTargetCnt = (data: unknown): data is SegmentTargetCnt => {
  const st = data as SegmentTargetCnt;

  return typeof st?.targetCnt === 'number';
};

export const isSegmentAttributeItem = (
  data: unknown,
): data is SegmentAttributeItem => {
  const sfi = data as SegmentAttributeItem;

  return typeof sfi?.name === 'string' && typeof sfi?.label === 'string';
};

export const isSegmentUserAttribute = (
  data: unknown,
): data is SegmentUserAttribute => {
  const dt = data as SegmentUserAttribute;

  return (
    typeof dt?.id === 'string' && typeof dt?.name === 'string' && 'fields' in dt
  );
};

export const isSegmentAttributeFieldItems = (
  datas: unknown[],
): datas is SegmentAttributeItem[] =>
  !datas.some((d) => !isSegmentAttributeItem(d));

export const isSegmentChildAttribute = (
  data: unknown,
): data is SegmentChildAttribute => {
  const dt = data as SegmentChildAttribute;

  return (
    typeof dt?.id === 'string' &&
    typeof dt?.name === 'string' &&
    'fieldList' in dt
  );
};

export const isSegmentAttributeParent = (
  data: unknown,
): data is SegmentAttributeParent => {
  const sap = data as SegmentAttributeParent;

  return (
    typeof sap?.id === 'string' &&
    typeof sap?.title === 'string' &&
    isSegmentAttributeFieldItems(sap?.data)
  );
};

export const isSegmentAttributeParents = (
  datas: unknown[],
): datas is SegmentAttributeParent[] =>
  !datas.some((d) => !isSegmentAttributeParent(d));

export const isSegmentAttribute = (data: unknown): data is SegmentAttribute => {
  const sf = data as SegmentAttribute;

  return (
    typeof sf?.id === 'string' &&
    isSegmentAttributeFieldItems(sf?.users) &&
    isSegmentAttributeParents(sf?.enquetes) &&
    isSegmentAttributeParents(sf?.contents)
  );
};

export const isCustomRuleGroupType = (
  data: unknown,
): data is CustomRuleGroupType => {
  const crg = data as CustomRuleGroupType;

  return typeof crg?.combinator === 'string' && Array.isArray(crg?.rules);
};

export const isSegmentDetail = (data: unknown): data is SegmentDetail => {
  const sd = data as SegmentDetail;

  return (
    typeof sd?.id === 'string' &&
    typeof sd?.name === 'string' &&
    isCustomRuleGroupType(sd?.query)
  );
};
