import { UseMutateFunction, useMutation, useQueryClient } from 'react-query';
import { UseFormSetError } from 'react-hook-form';
import { useNavigate } from 'react-router-dom';
import { putSegment } from 'api/segment/putSegment';
import { ResponseError } from 'api/types';
import { SegmentDetail, isSegmentDetail } from 'api/segment/types';
import { isGetValidationError, toMultiError } from 'utils/form';
import { useCustomToast } from 'hooks/useCustomToast';
import { useMailUtil } from 'hooks/mail/useMailUtil';
import { useUserTenantId } from 'hooks/user/useUserTenantId';
import { querySegmentKey } from 'hooks/segment/querySegmentKey';

type UnPromisify<T> = T extends Promise<infer U> ? U : T;

const formData: SegmentDetail = {
  id: '',
  tenantId: '',
  name: '',
  query: {
    combinator: 'and',
    rules: [],
  },
};

/**
 * セグメント修正 hooks
 * @param {Object} UseFormSetError setError
 * @param {setGlobalErrors} callback setGlobalErrors
 * @returns {Object} { onSubmit, isLoading }
 */
export const useEditSegment = ({
  setError,
}: {
  setError: UseFormSetError<SegmentDetail>;
}): {
  onSubmit: UseMutateFunction<
    UnPromisify<ReturnType<typeof putSegment>>,
    unknown,
    SegmentDetail,
    unknown
  >;
  isLoading: boolean;
} => {
  const { MailFormRefetchListQueries } = useMailUtil();
  const toast = useCustomToast();
  const navigate = useNavigate();
  const tenantId = useUserTenantId();
  const queryClient = useQueryClient();

  const { mutate: onSubmit, isLoading } = useMutation(
    (segmentDetailData: SegmentDetail) =>
      putSegment({
        ...segmentDetailData,
        tenantId,
      }),
    {
      onSuccess: (result: SegmentDetail | ResponseError) => {
        MailFormRefetchListQueries();

        if (isSegmentDetail(result)) {
          toast({
            status: 'success',
            position: 'bottom',
            duration: 2000,
            isClosable: true,
            title: 'セグメントを修正しました',
          });
          void queryClient.invalidateQueries(
            querySegmentKey.getSegmentList({ tenantId }),
          );
          void queryClient.invalidateQueries(
            querySegmentKey.getSegmentId({ tenantId, segmentId: result.id }),
          );
          navigate(`/segment/`);
        }

        if (
          isGetValidationError<SegmentDetail>(result, Object.keys(formData))
        ) {
          if (result?.others) {
            const errorMsg = Object.entries(result).map(([_, value]) => value);
            toast({
              status: 'error',
              position: 'bottom',
              duration: 4000,
              isClosable: true,
              title: errorMsg[0],
            });
          } else {
            Object.keys(result).forEach((k) => {
              const key = k as keyof SegmentDetail;
              const errMsgs = result?.[key];
              if (errMsgs) {
                setError(key, { types: toMultiError(errMsgs) });
              }
            });
          }
        }
      },
    },
  );

  return { onSubmit, isLoading };
};
