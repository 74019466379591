import {
  Button,
  DrawerCloseButton,
  DrawerContent,
  DrawerFooter,
  DrawerHeader,
  DrawerOverlay,
  Text
} from '@chakra-ui/react';
import { CodeCreateForm } from 'admin/components/idPoolConsumer/code/codeForm';
import { ColorSet } from 'admin/define/colors';
import { FC, memo } from 'react';

type CodeCreateDrawerProps = {
  onClose: () => void;
  formId: string;
  isOpen: boolean;
};

export const CodeCreateDrawer: FC<CodeCreateDrawerProps> = memo(
  ({ onClose, formId }) => (
    <>
      <DrawerOverlay />
      <DrawerContent>
        <DrawerHeader borderWidth="1px" pb="24px">
          <Text>コード登録</Text>
        </DrawerHeader>
        <CodeCreateForm formId={formId} onClose={onClose} />
        <DrawerFooter>
          <Button
            variant="outline"
            mr={3}
            onClick={onClose}
            p="16px 24px"
            w="120px"
            {...ColorSet.Default}
          >
            キャンセル
          </Button>
          <Button
            type="submit"
            form={formId}
            p="16px 24px"
            w="120px"
            colorScheme="primary"
          >
            登録
          </Button>
        </DrawerFooter>
        <DrawerCloseButton />
      </DrawerContent>
    </>
  ),
);
