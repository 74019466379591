export type DummyResponseType<T> = Pick<
  Response,
  'ok' | 'status' | 'headers'
> & { data: T; hasError: boolean };

export const getDummySuccessResponse = <T>(
  data: T,
): Promise<DummyResponseType<T>> => {
  // Responseオブジェクトを模したダミーデータを作成
  const dummyResponse: DummyResponseType<T> = {
    ok: true,
    status: 200,
    hasError: false,
    headers: new Headers(),
    data,
  };

  return new Promise<DummyResponseType<T>>((resolve) => resolve(dummyResponse));
};

export const getDummyErrorResponse = <T>(
  data: T,
): Promise<DummyResponseType<T>> => {
  // Responseオブジェクトを模したダミーデータを作成
  const dummyResponse: DummyResponseType<T> = {
    ok: false,
    status: 404,
    hasError: true,
    headers: new Headers(),
    data,
  };

  return new Promise<DummyResponseType<T>>((resolve) => resolve(dummyResponse));
};
