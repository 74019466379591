import { FC, memo } from 'react';
import { VStack } from '@chakra-ui/react';
import { useErrorBoundaryReset } from 'hooks/useErrorBoundaryReset';
import { useSetPageTitle } from 'hooks/useSetPageTitle';
import { ApiKeyBody } from 'components/setting/apikey/ApiKeyBody';
import ErrorBoundary from 'api/ErrorBoundary';

export const Apikey: FC = memo(() => {
  const { ebKey, onError } = useErrorBoundaryReset();
  useSetPageTitle('APIキー管理');

  return (
    <VStack spacing={4} flex={1} alignItems="flex-start">
      <ErrorBoundary onError={onError} key={ebKey.current}>
        <ApiKeyBody />
      </ErrorBoundary>
    </VStack>
  );
});
