import { Options } from 'ky';
import { request } from 'api/request';
import { logger } from 'api/logger';
import { CustomError } from 'api/error/CustomError';
import { TenantApiKey, isTenantApiKey } from 'api/tenant/types';
import { messages } from 'api/tenant/messages';

/**
 * APIキー取得API
 *
 * 状態により以下のレスポンスを返却します
 * 正常時: true, 異常時: false
 *
 */
export const getApiKey = async (
  tenantId: string,
  options?: Options,
): Promise<TenantApiKey> => {
  const response = await request({
    path: `tenant/${tenantId}/apikey/`,
    method: 'get',
    options,
  });

  const responseData = (await response.data) as unknown[];

  if (response.hasError) {
    throw new CustomError(messages.getApiKey.customer.error);
  }

  if (!isTenantApiKey(responseData)) {
    void logger({
      loglevel:
        response.status === 404 || response.status === 450
          ? 'warning'
          : 'error',
      data: responseData,
      message: messages.getApiKey.system.typeError,
    });

    throw new CustomError(messages.getApiKey.customer.failed);
  }

  return responseData;
};
