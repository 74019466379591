import { QuestionIcon } from '@chakra-ui/icons';
import {
  Accordion,
  AccordionButton,
  AccordionIcon,
  AccordionItem,
  AccordionPanel,
  Box,
  Flex,
  FormControl,
  HStack,
  Stack,
  Text,
  Tooltip,
  useDisclosure,
} from '@chakra-ui/react';
import styled from '@emotion/styled';
import { ErrorTextMsg } from 'components/common/atoms';
import {
  DoubleConfirmDialog,
  InputForm,
  SelectForm,
} from 'components/common/molecules';
import { TextareaForm } from 'components/common/molecules/TextareaForm';
import { Button } from 'components/paywall/atoms/Button';
import { Input } from 'components/paywall/atoms/Input';
import { TextButton } from 'components/paywall/atoms/TextButton';
import { AmmountWithTax } from 'components/paywall/features/AmmountWithTax';
import { usePaywallCourse } from 'components/paywall/hooks/usePaywallCourse';
import { usePaywallUtil } from 'components/paywall/hooks/usePaywallUtil';
import { Drawer } from 'components/paywall/molecules/Drawer';
import { CourseFormDefaultValues } from 'components/paywall/pages/CourseList/initial';
import {
  Course,
  CourseFormType,
} from 'components/paywall/pages/CourseList/typed';
import { RequiredBadge } from 'components/setting/paymentAgency/PaymentAgencyItem/PaymentAgencyEdit';
import { FC, memo, useCallback, useEffect, useState } from 'react';
import { FormProvider, useForm } from 'react-hook-form';
import { AiOutlineInfoCircle } from 'react-icons/ai';
import { toErrMsgList } from 'utils/form';

type Props = {
  isAdd?: boolean;
  buttonMode?: 'simple' | 'gray' | 'text';
  buttonName: string;
  obj?: Course;
};

/**
 * 表示名入力用バリデーション
 */
const CourseNameValidateAttr = {
  required: 'コース名を入力してください',
};
const CourseMonthValidateAttr = {
  required: '期間を選択してください',
};

const StyledFormFlex = styled(Flex)({
  alignItems: 'center',
  justifyContent: 'space-between',
  gap: '1rem',
  textAlign: 'center',
});
const StyledFormFlexInner = styled(Box)({
  width: '50%',
});
const StyledTooltip = styled(Tooltip)({
  size: 'xs',
  borderRadius: '4px',
});

/**
 * @see コースの新規作成＆編集ドロワー
 * @param isAdd 新規作成か編集かの判定
 * @param buttonMode ボタンに渡すモード
 * @param buttonName ボタンの名前
 * @param Promotion プロモーションのオブジェクト
 */
export const CourseDrawer: FC<Props> = memo(
  ({ isAdd, buttonMode, buttonName, obj }: Props) => {
    const [isLoading, setLoading] = useState<boolean>(false);
    const [warningTexts, setWarningTexts] = useState<string[]>([]);
    const { isOpen, onClose, onOpen } = useDisclosure();
    const { isOperationAuth } = usePaywallUtil();
    const {
      isOpen: isDialogOpen,
      onClose: onDialogClose,
      onOpen: onDialogOpen,
    } = useDisclosure();
    const methods = useForm<CourseFormType>({
      mode: 'onBlur',
      defaultValues: CourseFormDefaultValues,
    });
    const {
      getValues,
      setValue,
      formState: { errors },
      setError,
      reset,
      register,
    } = methods;
    const { saveCourse, onDrawerClose } = usePaywallCourse({
      getValues,
      isAdd,
      setError,
      onClose,
      reset,
      setLoading,
    });

    // ドロワーを開くときの処理
    const onOpenDrawer = useCallback(() => {
      if (!isAdd) {
        setValue('id', obj?.id);
        setValue('name', obj?.name || CourseFormDefaultValues.name);
        setValue('month', obj?.month || CourseFormDefaultValues.month);
        setValue('price', obj?.price || CourseFormDefaultValues.price);
        setValue(
          'systemCode',
          obj?.systemCode || CourseFormDefaultValues.systemCode,
        );
        setValue('tags', obj?.tags || CourseFormDefaultValues.tags);
        setValue('memo', obj?.memo || CourseFormDefaultValues.memo);
      }
      onOpen();
    }, [onOpen, setValue, isAdd, obj]);

    // 確認つきダイアログの実行ボタンを押した時の処理
    const submitDialogBtnHandler = useCallback(() => {
      saveCourse();
      onDialogClose();
    }, [saveCourse, onDialogClose]);

    useEffect(() => {
      if (!isAdd) {
        setValue('id', obj?.id);
        setValue('name', obj?.name || CourseFormDefaultValues.name);
        setValue('month', obj?.month || CourseFormDefaultValues.month || 0);
        setValue('price', obj?.price || CourseFormDefaultValues.price);
        setValue(
          'systemCode',
          obj?.systemCode || CourseFormDefaultValues.systemCode,
        );
        setValue('tags', obj?.tags || CourseFormDefaultValues.tags);
        setValue('memo', obj?.memo || CourseFormDefaultValues.memo);
      }
    }, [isAdd, setValue, obj]);

    // ドロワーの保存ボタンが押された時の処理
    const submitBtnHandler = useCallback(() => {
      // 税抜価格が変更された場合は確認のダイアログを表示する
      const priceChanged =
        String(obj?.price || 0) !== String(getValues('price') || 0);

      if (!isAdd && priceChanged) {
        // ダイアログで表示するテキストを設定する
        setWarningTexts([
          `「価格」が変更されます。`,
          '新規で購入する場合には、設定変更後すぐに新しい価格で決済されます。',
          '継続時の自動更新の場合には、設定変更後１日程度は古い価格、それ以降は新しい価格で決済されます。',
          'また、変更以前に既に完了している決済については変更されません。',
        ]);

        onDialogOpen();
      } else {
        // どちらも変更されていない場合はダイアログを挟まずに保存する
        submitDialogBtnHandler();
      }
    }, [isAdd, obj?.price, getValues, onDialogOpen, submitDialogBtnHandler]);

    return (
      <Box display="flex" alignItems="center">
        <FormProvider {...methods}>
          {buttonMode !== 'text' && (
            <Button
              mode={buttonMode}
              onClick={onOpenDrawer}
              disabled={!isOperationAuth()}
            >
              {buttonName}
            </Button>
          )}
          {buttonMode === 'text' && (
            <TextButton
              variant="ghost"
              padding={0}
              onClick={onOpenDrawer}
              whiteSpace="pre-line"
              word-break="break-all"
              textAlign="left"
              height="auto"
              fontSize="18px"
              fontWeight="700"
              lineHeight="170%"
            >
              {buttonName}
            </TextButton>
          )}
          <Drawer<CourseFormType>
            type="id"
            headerLabel="コースID"
            title={isAdd ? 'コース新規作成' : 'コース編集'}
            isOpen={isOpen}
            disabled={Object.keys(errors).length > 0}
            onClose={onDrawerClose}
            onSubmit={submitBtnHandler}
            size="md"
            isAdd={isAdd}
          >
            <Stack spacing={6} mt={2}>
              <Box>
                {isAdd ? (
                  <>コースの新規作成を行います</>
                ) : (
                  <>コースの編集を行います</>
                )}
              </Box>

              <InputForm<Course>
                name="name"
                type="text"
                labelElement={
                  <HStack>
                    <Text as="span" fontWeight="bold">
                      名前
                    </Text>
                    <StyledTooltip
                      label={
                        <Text fontSize="xs" py={1}>
                          商品名です。ユーザーにも表示されます。
                        </Text>
                      }
                    >
                      <QuestionIcon
                        style={{ cursor: 'pointer', marginRight: '0.5rem' }}
                      />
                    </StyledTooltip>
                    <RequiredBadge />
                  </HStack>
                }
                attr={CourseNameValidateAttr}
              />

              <SelectForm<Course>
                name="month"
                labelElement={
                  <HStack>
                    <Text as="span" fontWeight="bold">
                      期間
                    </Text>
                    <StyledTooltip
                      label={
                        <Text fontSize="xs" py={1}>
                          更新間隔の期間です。はじめに一度設定するとその後は変更できません。月額のコースであれば１ヶ月を設定してください。
                        </Text>
                      }
                    >
                      <QuestionIcon
                        style={{ cursor: 'pointer', marginRight: '0.5rem' }}
                      />
                    </StyledTooltip>
                    <RequiredBadge />
                  </HStack>
                }
                placeholder="選択してください"
                attr={CourseMonthValidateAttr}
                isDisabled={!isAdd}
                optionList={[
                  { val: 1, name: '1ヶ月' },
                  { val: 2, name: '2ヶ月' },
                  { val: 3, name: '3ヶ月' },
                  { val: 4, name: '4ヶ月' },
                  { val: 5, name: '5ヶ月' },
                  { val: 6, name: '6ヶ月' },
                  { val: 12, name: '12ヶ月' },
                ].map((item) => (
                  <option key={`course_month_${item.val}`} value={item.val}>
                    {item.name}
                  </option>
                ))}
              />

              <HStack>
                <Text as="span" fontWeight="bold">
                  税抜価格
                </Text>
                <RequiredBadge />
              </HStack>
              {!isAdd && (
                <Accordion allowMultiple>
                  <AccordionItem>
                    <h2>
                      <AccordionButton bg="gray.50" fontSize="sm">
                        <Flex alignItems="center">
                          <AiOutlineInfoCircle />
                          <Text ml={4}>税抜価格について</Text>
                        </Flex>
                        <AccordionIcon />
                      </AccordionButton>
                    </h2>
                    <AccordionPanel pt={4} pb={4} fontSize="xs">
                      <Text>
                        契約頂いた方の次回の決済から新しい価格で決済されます
                      </Text>
                    </AccordionPanel>
                  </AccordionItem>
                </Accordion>
              )}
              <StyledFormFlex>
                <StyledFormFlexInner>
                  <FormControl isInvalid={Boolean(errors.price)}>
                    <Input
                      type="text"
                      ref={register('price', {
                        required: '税抜価格を入力してください',
                        valueAsNumber: true,
                        validate: (value) => {
                          if (Number.isNaN(value)) {
                            return '税抜価格は半角数字で入力してください';
                          }

                          if ((value as number) <= 0) {
                            return '税抜価格は1円以上で入力してください';
                          }

                          if ((value as number) > 999999) {
                            return '税抜価格は999,999円以下で入力してください';
                          }

                          return undefined;
                        },
                      })}
                    />
                  </FormControl>
                </StyledFormFlexInner>
                <StyledFormFlexInner>
                  <AmmountWithTax<CourseFormType> name="price" />
                </StyledFormFlexInner>
              </StyledFormFlex>
              {toErrMsgList(errors, 'price').map((err, idx) => (
                <ErrorTextMsg
                  key={`error-paywall-course-${String(idx)}`}
                  msg={err}
                />
              ))}

              <InputForm<Course>
                name="systemCode"
                type="text"
                labelElement={
                  <HStack>
                    <Text as="span" fontWeight="bold">
                      システム番号
                    </Text>
                    <StyledTooltip
                      label={
                        <Text fontSize="xs" py={1}>
                          決済一覧画面でこの商品の決済をフィルターとして利用できます。
                          例えば、基幹システムで発行した「商品ID」などの値を設定可能です。
                          ユーザーには表示されません。
                        </Text>
                      }
                    >
                      <QuestionIcon style={{ cursor: 'pointer' }} />
                    </StyledTooltip>
                  </HStack>
                }
              />

              <TextareaForm<CourseFormType>
                name="tags"
                value={getValues('tags') || ''}
                register={register}
                labelElement={
                  <HStack>
                    <Text as="span" fontWeight="bold">
                      タグ
                    </Text>
                    <StyledTooltip
                      label={
                        <Text fontSize="xs" py={1}>
                          決済一覧画面で該当の記事カテゴリ等の決済をフィルターとして利用できます。
                          改行区切りで複数のタグが設定できます。ユーザーには表示されません。
                        </Text>
                      }
                    >
                      <QuestionIcon style={{ cursor: 'pointer' }} />
                    </StyledTooltip>
                  </HStack>
                }
              />

              <TextareaForm<CourseFormType>
                name="memo"
                value={getValues('memo') || ''}
                register={register}
                labelElement={
                  <HStack>
                    <Text as="span" fontWeight="bold">
                      メモ
                    </Text>
                    <StyledTooltip
                      label={
                        <Text fontSize="xs" py={1}>
                          このモーダル上でのみ表示できるメモ欄です。
                          ユーザーには表示されません。
                        </Text>
                      }
                    >
                      <QuestionIcon style={{ cursor: 'pointer' }} />
                    </StyledTooltip>
                  </HStack>
                }
              />
            </Stack>
          </Drawer>
        </FormProvider>
        <DoubleConfirmDialog
          title="変更についての確認"
          submitBtnTitle="実行"
          submitBtnHandler={submitDialogBtnHandler}
          cancelBtnTitle="キャンセル"
          descriptionPrefix="実行"
          warningTexts={warningTexts}
          isLoading={isLoading}
          isOpen={isDialogOpen}
          onClose={onDialogClose}
        >
          <Text mt={4}>
            <Text as="span" fontWeight="bold">
              {obj?.id}
            </Text>{' '}
            の変更を実行しますか？
          </Text>
        </DoubleConfirmDialog>
      </Box>
    );
  },
);
