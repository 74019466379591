import { request } from 'admin/api/Request';
import { API_MESSAGES } from 'admin/define/apiMessages';
import { CustomError } from 'admin/error/CustomError';
import { tokenSchema } from 'admin/schema/idPoolConsumer/auth';
import { SecuritySettings } from 'admin/types/userPool/auth';
import { TokenFormType } from 'admin/types/userPool/auth/form';
import { ZodError } from 'zod';

export const updateToken = async (
  data: TokenFormType,
): Promise<SecuritySettings> => {
  const { id, ...params } = data;
  const path = `uniikey/${id}/security_settings/tokens/`;
  const response = await request<SecuritySettings>({
    path,
    body: params,
    method: 'patch',
  });

  if (response.hasError) {
    if (response.status === 404) {
      throw new CustomError(API_MESSAGES.USER_POOL.EDIT_TOKEN.FAILED, {
        path,
        status: response.status,
      });
    } else {
      throw new CustomError(API_MESSAGES.USER_POOL.EDIT_TOKEN.ERROR, {
        path,
        status: response.status,
      });
    }
  }
  try {
    tokenSchema.parse(response.data);
  } catch (error) {
    if (error instanceof ZodError) {
      throw new CustomError(error.message, {
        path,
        status: response.status,
        logLevel: 'error',
      });
    }
  }

  return response.data;
};
