import { Drawer, useDisclosure } from '@chakra-ui/react';
import { withSuspenseAndErrorBoundary } from 'admin/components/Error/WithErrorBoundary';
import { AttributeCreateDrawer } from 'admin/components/idPoolConsumer/attribute/attributeDrawer';
import { AttributeTitleComponent } from 'admin/components/idPoolConsumer/attribute/attributeTitle/AttributeTitleComponent';
import { ErrorContents } from 'components/common/atoms';
import { FC, memo } from 'react';
import { useNavigate } from 'react-router-dom';

export const attributeTitle: FC = memo(() => {
  const { isOpen, onClose, onOpen } = useDisclosure();
  const navigate = useNavigate();

  return (
    <>
      <AttributeTitleComponent onOpen={onOpen} navigate={navigate} />
      {/* ドロワー */}
      <Drawer
        isOpen={isOpen}
        placement="right"
        onClose={onClose}
        size="lg"
        closeOnOverlayClick={false}
        closeOnEsc={false}
        autoFocus={false}
      >
        <AttributeCreateDrawer onClose={onClose} />
      </Drawer>
    </>
  );
});

export const AttributeTitle = withSuspenseAndErrorBoundary(attributeTitle, {
  ErrorComponent: <ErrorContents name="組織レベル属性設定タイトル" />,
});
