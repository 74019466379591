import { isPage, Page } from 'api/common/types';

/**
 * ウォールデザイン一覧情報 Type
 */
export type WallDesignListType = {
  results: WallDesign[];
} & Page;

/**
 * ウォールデザインデータ型
 */
export type WallDesign = {
  id: string;
  wall: string;
  name: string;
  source: string;
  html: string;
  status: boolean;
  createdAt: string;
  updatedAt: string;
  hasPaywallButton: boolean;
  hasLoginButton: boolean;
  hasMemberRegistButton: boolean;
};

export type WallDesignFormType = {
  id?: string;
  tenant: string;
  wallId: string;
  name: string;
  source: string;
  html: string;
};

export type WallDesignPublish = {
  id: string;
  tenant: string;
  wallId: string;
  status: boolean;
};

export const isWallDesign = (data: unknown): data is WallDesign => {
  const dt = data as WallDesign;

  return (
    typeof dt?.id === 'string' &&
    typeof dt?.wall === 'string' &&
    typeof dt?.name === 'string' &&
    typeof dt?.source === 'string' &&
    typeof dt?.html === 'string' &&
    typeof dt?.status === 'boolean' &&
    typeof dt?.hasPaywallButton === 'boolean' &&
    typeof dt?.hasLoginButton === 'boolean' &&
    typeof dt?.hasMemberRegistButton === 'boolean' &&
    typeof dt?.createdAt === 'string' &&
    typeof dt?.updatedAt === 'string'
  );
};

export const isWallDesignList = (data: unknown): data is WallDesignListType => {
  const dt = data as WallDesignListType;

  return isPage(dt) && dt?.results.length !== 0
    ? dt?.results.some((d) => isWallDesign(d))
    : true;
};
