import { useCorporation } from 'admin/hooks/corporation/useCorporation';
import { AdminCorporationMailListType } from 'admin/types/corporation';

export const useTransactionMailAddressList = (): {
  transactionMailFromAddressList: AdminCorporationMailListType;
  transactionMailReplyAddressList: AdminCorporationMailListType;
} => {
  const corporation = useCorporation();

  // 差出人メールアドレスリスト作成
  const transactionMailFromList =
    corporation?.transactionMailFromAddress !== null
      ? corporation?.transactionMailFromAddress.split('\n') || []
      : [];
  const transactionMailFromAddressList: AdminCorporationMailListType = [];
  transactionMailFromList.forEach((item, idx) =>
    transactionMailFromAddressList.push({
      id: idx,
      name: item,
    }),
  );

  // 返信用メールアドレスリスト作成
  const transactionMailReplyList =
    corporation?.transactionMailReplyAddress !== null
      ? corporation?.transactionMailReplyAddress.split('\n') || []
      : [];
  const transactionMailReplyAddressList: AdminCorporationMailListType = [];
  transactionMailReplyList.forEach((item, idx) =>
    transactionMailReplyAddressList.push({
      id: idx,
      name: item,
    }),
  );

  return {
    transactionMailFromAddressList,
    transactionMailReplyAddressList,
  };
};
