import { memo, VFC } from 'react';
import { NavLink } from 'react-router-dom';

type Props = {
  children: React.ReactNode;
  to: string;
  style?: React.CSSProperties | undefined
};

/**
 * @see 汎用リンク
 * @param children リンクの子要素
 * @param to ジャンプ先のパス
 */
export const Link: VFC<Props> = memo(({ children, to, style }) => (
  <NavLink to={to} style={style}>{children}</NavLink>
));
