import { Box, Button, Text } from '@chakra-ui/react';
import {
  CustomRuleChildType,
  SegmentChildAttribute,
  SegmentDetail,
} from 'api/segment/types';
import { SegmentInner } from 'components/segment/molecules/SegmentInner';
import { useCreateSegment } from 'hooks/segment/useCreateSegment';
import { useSegmentAttribute } from 'hooks/segment/useSegmentAttribute';
import { memo, VFC } from 'react';
import { FormProvider, useForm } from 'react-hook-form';
import { RuleType } from 'react-querybuilder';

const defaultValues: SegmentDetail = {
  id: '',
  tenantId: '',
  name: '',
  query: {
    combinator: 'and',
    rules: [],
  },
};

export const SegmentCreateBody: VFC = memo(() => {
  const attributeList = useSegmentAttribute();
  const methods = useForm<SegmentDetail>({
    mode: 'onBlur',
    // エラーのある入力が再度バリデーションされるタイミングを変更(default: onChange)
    reValidateMode: 'onBlur',
    defaultValues,
  });
  const { setError, handleSubmit } = methods;
  const formId = 'mailSegment-form';
  const { onSubmit, isLoading } = useCreateSegment({ setError });

  const correctRule = (
    rules: CustomRuleChildType,
    cb: (rule: RuleType) => RuleType,
  ) => {
    const result = { ...rules };

    if ('rules' in result && Array.isArray(result.rules)) {
      const resRules = result.rules.map((r) => correctRule(r, cb));

      result.rules = resRules as RuleType[];

      return result;
    }

    return cb(result as RuleType);
  };

  const correctFormData = (data: SegmentDetail) => {
    const enqueteAttribute = attributeList?.find((v) => v.id === 'enquete');
    if (!enqueteAttribute) {
      return data;
    }

    const enqueteList = (enqueteAttribute as SegmentChildAttribute).fieldList;
    const formData = data;

    formData.query.rules = formData.query.rules.map((fields) => {
      const tmpFields = fields;

      if (tmpFields.customeGroupType === 'enquete') {
        const enquete = enqueteList.find(
          (v) => v.id === tmpFields.customeGroupId,
        );

        if (enquete) {
          tmpFields.rules = tmpFields.rules.map((rule) => {
            const result = correctRule(rule, (r: RuleType) => {
              const res = { ...r };

              // プルダウンかラジオボタンで value が '' の場合に選択肢の1番目の値を入れる
              const fld = enquete.fields.find((v) => v.name === res.field);
              if (
                fld &&
                (fld.valueEditorType === 'select' ||
                  fld.valueEditorType === 'radio') &&
                res.value === '' &&
                fld.values.length > 0 &&
                !fld.values.find((v) => v.name === '')
              ) {
                res.value = fld.values[0].name;
              }

              return res;
            });

            return result;
          });
        }
      }

      return tmpFields;
    });

    return formData;
  };

  const formSubmit = (data: SegmentDetail) => {
    const formData = correctFormData(data);
    onSubmit(formData);
  };

  return (
    <>
      <FormProvider {...methods}>
        <form id={formId} onSubmit={handleSubmit((data) => formSubmit(data))}>
          <Box display="flex" justifyContent="space-between">
            <Text mb={4}>データを選択</Text>
            <Button variant="primary" type="submit" isLoading={isLoading}>
              保存
            </Button>
          </Box>
          <SegmentInner
            attributeList={attributeList || []}
            segmentDetail={defaultValues}
          />
        </form>
      </FormProvider>
    </>
  );
});
