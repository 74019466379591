import { Text } from '@chakra-ui/react';
import { withSuspenseAndErrorBoundary } from 'admin/components/Error/WithErrorBoundary';
import { MemberListTableComponent } from 'admin/components/member/memberListTable/MemberListTableComponent';
import { useMembers } from 'admin/hooks/member/useMembers';
import { MemberIdPoolConsumerType } from 'admin/types/member';
import { Page } from 'api/common/types';
import { ErrorContents } from 'components/common/atoms';
import { Dispatch, FC, memo, SetStateAction, useEffect } from 'react';

type Props = {
  selectedId: MemberIdPoolConsumerType['id'];
  currentPage: number;
  perPage: number;
  setPage: Dispatch<SetStateAction<Page>>;
  setHasMemberList: Dispatch<SetStateAction<boolean>>;
  // TODO: 9月末のリリース時点では必要では無い為、コメントアウト
  // searchKeyword: string;
  // setRefetchFunc: Dispatch<SetStateAction<() => void>>;
};

export const memberListTable: FC<Props> = memo(
  ({
    selectedId,
    currentPage,
    perPage,
    // TODO: 9月末のリリース時点では必要では無い為、コメントアウト
    // searchKeyword,
    // setRefetchFunc,
    setPage,
    setHasMemberList,
  }: Props) => {
    // TODO: 9月末のリリース時点では必要では無い為、コメントアウト
    // const [isLoading, setLoading] = useState<boolean>(false);
    const {
      data: members,
      page,
      // TODO: 9月末のリリース時点では必要では無い為、コメントアウト
      // refetch,
    } = useMembers(
      selectedId,
      currentPage,
      perPage,
      // TODO: 9月末のリリース時点では必要では無い為、コメントアウト
      // searchKeyword
    );

    useEffect(() => {
      setPage(page);
    }, [setPage, page]);

    useEffect(() => {
      setHasMemberList(members.length > 0);
    }, [members, setHasMemberList]);

    // ===========================================
    // TODO: 9月末のリリース時点では必要では無い為、コメントアウト
    // useCallbackの中にさらに無名関数をセット（非同期処理の兼ね合いで）
    // const onRefetch = useCallback(
    //   () => () => {
    //     setLoading(true);
    //     refetch().finally(() => setLoading(false));
    //   },
    //   [refetch],
    // );

    // useEffect(() => {
    //   setRefetchFunc(onRefetch);
    // });
    // ===========================================
    if (!members || members.length === 0) {
      return <Text>会員データがありません。</Text>;
    }

    // TODO: 9月末のリリース時点では必要では無い為、コメントアウト
    // if (isLoading) {
    //   return <LoadingSkeleton />;
    // }

    return <MemberListTableComponent members={members} />;
  },
);

export const MemberListTable = withSuspenseAndErrorBoundary(memberListTable, {
  ErrorComponent: <ErrorContents name="会員一覧情報" />,
});
