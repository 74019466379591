import {
  maxLength,
  headTitleOptions,
  screenNameOptions,
  screenDescriptionOptions,
  buttonNameOptions,
  itemNameOptions,
  textareaElementOptions,
} from './options';

const groupName = 'passwordReset';

// パスワードリセット画面
export default [
  // ロゴ	画像
  {
    name: `${groupName}.headTitle`,
    label: 'ブラウザタブ表示名',
    tooltip: '',
    type: 'Text',
    defaultValue: 'パスワードリセット',
    options: headTitleOptions,
    elementOptions: {},
  },
  {
    name: `${groupName}.screenName`,
    label: '画面名',
    tooltip: '',
    type: 'Text',
    defaultValue: 'パスワードリセット',
    options: screenNameOptions,
    elementOptions: {},
  },
  {
    name: `${groupName}.screenDescription`,
    label: '案内文 画面',
    tooltip: '',
    type: 'Textarea',
    defaultValue:
      'ご登録中のメールアドレスを宛に、パスワード変更用のURLが添付されたメールが送られます。',
    options: screenDescriptionOptions,
    elementOptions: textareaElementOptions,
  },
  {
    name: `${groupName}.emailItemName`,
    label: '入力欄名 メールアドレス',
    tooltip: '',
    type: 'Text',
    defaultValue: 'メールアドレス',
    options: itemNameOptions,
    elementOptions: {},
  },
  // captcha	-
  {
    name: `${groupName}.continueButtonName`,
    label: 'ボタン名 パスワードリセットメールの送信',
    tooltip: '',
    type: 'Text',
    defaultValue: 'パスワードリセットメールの送信',
    options: buttonNameOptions,
    elementOptions: {},
  },
  {
    name: `${groupName}.backButtonName`,
    label: 'ボタン名 前の画面に遷移',
    tooltip: '',
    type: 'Text',
    defaultValue: '戻る',
    options: buttonNameOptions,
    elementOptions: {},
  },
  {
    name: `${groupName}.importantNotice`,
    label: '案内文 メール送信の留意事項',
    tooltip: '',
    type: 'Textarea',
    defaultValue:
      'メール送信には時間がかかる場合があるため、連続してボタンを押さないようご注意ください。',
    options: {
      maxLength: maxLength(50),
    },
    elementOptions: textareaElementOptions,
  },
] as const;
