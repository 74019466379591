import { Wall } from 'components/paywall//pages/WallList/typed';
import { WallDialog } from 'components/paywall//pages/WallList/WallDialog';
import {
  CardHeaderButton,
  CardHeaderButtons,
  CardHeaderTitle,
} from 'components/paywall/features/Card';
import { FC, memo } from 'react';

/* サブスクリプション設定の画面設定ボタンのステータスメッセージ */
const AlertStatusDesing: { [key: number]: string } = {
  0: '',
  1: 'ウォール画面を作成してください',
  2: 'いずれかのウォール画面のステータスを 有効 にしてください',
};

/* サブスクリプション設定のコース設定ボタンのステータスメッセージ */
const AlertStatusProduct: { [key: number]: string } = {
  0: '',
  1: 'コースを作成してください',
};

type Props = {
  wall: Wall;
};
export const PaywallCardHeader: FC<Props> = memo(({ wall }: Props) => (
  <>
    <CardHeaderTitle
      id={wall.id}
      idName="サブスクリプションウォールID："
      mr="16px"
      maxW="calc(100% - 204px)"
      type="wall"
    >
      <WallDialog buttonMode="text" buttonName={wall.name} obj={wall} />
    </CardHeaderTitle>
    <CardHeaderButtons>
      <CardHeaderButton
        path={`/paywall/${wall.id}/wallDesignList`}
        buttonText="画面設定"
        status={wall.alertStatusDesing}
        tooltipLabel={AlertStatusDesing[wall.alertStatusDesing]}
      />
      <CardHeaderButton
        path={`/paywall/${wall.id}/courseList`}
        buttonText="コース設定"
        status={wall.alertStatusProduct}
        tooltipLabel={AlertStatusProduct[wall.alertStatusProduct]}
      />
    </CardHeaderButtons>
  </>
));
