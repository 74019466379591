import { Box, DrawerBody, Heading, Text } from '@chakra-ui/react';
import { LoadingLayer } from 'admin/components/ui/loadingLayer';
import { TenantUsersType } from 'admin/types/tenantUsers';
import { tenantUsersUpdateFormType } from 'admin/types/tenantUsers/form';
import { useUserInfo } from 'hooks/useUserInfo';
import { FC, memo } from 'react';
import { useFormContext } from 'react-hook-form';

type Props = {
  tenantUsersDetail: TenantUsersType;
  formId: string;
  isLoading: boolean;
  onClose: () => void;
  onSubmit: (value: tenantUsersUpdateFormType) => void;
};

export const TenantUsersEditFormComponent: FC<Props> = memo(
  ({
    tenantUsersDetail,
    formId,
    isLoading,
    onClose: _onClose,
    onSubmit,
  }: Props) => {
    const { handleSubmit } = useFormContext<tenantUsersUpdateFormType>();
    // TODO: 4月以降対応 const { errors } = useFormState<tenantUsersUpdateFormType>();
    const { getPermissionName } = useUserInfo();

    return (
      <>
        {isLoading && <LoadingLayer />}
        <DrawerBody>
          <form onSubmit={handleSubmit(onSubmit)} id={formId}>
            <Box mt="24px">
              <Heading as="h3" size="sm" mb="4px">
                ユーザー名
              </Heading>
              <Text>{tenantUsersDetail.name}</Text>
            </Box>
            <Box mt="24px">
              <Heading as="h3" size="sm" mb="4px">
                メールアドレス
              </Heading>
              <Text>{tenantUsersDetail.email}</Text>
            </Box>
            <Box mt="24px">
              <Heading as="h3" size="sm" mb="4px">
                担当テナント
              </Heading>
              <Text>
                {tenantUsersDetail.tenant.name
                  ? tenantUsersDetail.tenant.name
                  : 'なし'}
              </Text>
            </Box>

            <Box mt="24px">
              <Heading as="h3" size="sm" mb="4px">
                <Box>権限</Box>
              </Heading>

              <Box>
                {getPermissionName(Number(tenantUsersDetail.tenant.permission))}
              </Box>
              {/*
              TODO: 4月以降対応
              <FormControl
                isInvalid={
                  Boolean(errors.permission?.message) ||
                  toErrMsgList(errors, 'permission').length > 0
                }
              >
                <Heading as="h3" size="sm" mb="4px">
                  <Box display="flex" alignItems="bottom">
                    権限 <RequiredIcon ml="8px" />
                  </Box>
                </Heading>
                <Box>
                  <RhfSelect<tenantUsersUpdateFormType>
                    name="permission"
                    placeholder="選択してください。"
                    bgColor={Colors.WHITE}
                    width="312px"
                  >
                    {TENANT_PERMISSION_LIST.map((item) => (
                      <option
                        value={item.value}
                        key={`tenantUsersPermission${item.text}`}
                      >
                        {item.label}
                      </option>
                    ))}
                  </RhfSelect>
                </Box>
                {toErrMsgList(errors, 'permission').map((err) => (
                  <FormErrorMessage key={`permission${err}`}>
                    {err}
                  </FormErrorMessage>
                ))}
              </FormControl> */}
            </Box>
          </form>
        </DrawerBody>
        {/*
        TODO: 4月以降対応
        <DrawerFooter justifyContent="space-between">
          <Button
            variant="outline"
            mr={3}
            onClick={() => onClose()}
            p="16px 24px"
            w="120px"
            {...ColorSet.Default}
          >
            キャンセル
          </Button>
          <Button
            colorScheme="primary"
            type="submit"
            form={formId}
            p="16px 24px"
            w="120px"
          >
            保存
          </Button>
        </DrawerFooter>
        */}
      </>
    );
  },
);
