import { VStack } from '@chakra-ui/react';
import { FC, memo } from 'react';

type Props = {
  spacing: string;
  children: React.ReactNode;
}
export const DetailItems: FC<Props> = memo(({spacing, children}: Props) => (
  <VStack align="flex-start" w="calc((100% - 112px) / 2)" minW="400px" spacing={spacing}>
    {children}
  </VStack>
));
