import { CustomError } from 'admin/error/CustomError';
import { logger } from 'api/logger';
import { createMailSetting } from 'api/transactionMail/createMailSetting';
import { FirstLoginMailSettingType } from 'api/transactionMail/type';
import { useCustomToast } from 'hooks/useCustomToast';
import { useUserTenantId } from 'hooks/user/useUserTenantId';
import { Dispatch, SetStateAction } from 'react';
import { UseFormSetError } from 'react-hook-form';
import { useMutation, useQueryClient } from 'react-query';
import { toMultiError } from 'utils/form';
import { errorToast, successToast } from 'utils/toast';
import { queryKeys } from './queryKeys';

export const useCreateMailSetting = (
  setError: UseFormSetError<FirstLoginMailSettingType>,
  setGlobalErrors: Dispatch<SetStateAction<string[]>>,
) => {
  const tenantId = useUserTenantId();
  const toast = useCustomToast();
  const queryClient = useQueryClient();
  const { mutateAsync, isLoading } = useMutation({
    mutationFn: (data: FirstLoginMailSettingType) =>
      createMailSetting({ tenantId, data }),
    onSuccess: () => {
      void queryClient.invalidateQueries(
        queryKeys.getMailSetting({ tenantId }),
      );
      toast({
        ...successToast,
        duration: 4000,
        title: 'メール設定を登録しました',
        position: 'bottom',
        variant: 'solid',
      });
    },
    onError: async (error, variables: FirstLoginMailSettingType) => {
      if (error instanceof CustomError) {
        if (error.cause.errors?.others) {
          const othersMsgs = Array.isArray(error.cause.errors?.others)
            ? error.cause.errors?.others
            : [error.cause.errors?.others];

          setGlobalErrors(othersMsgs);
        } else {
          Object.keys(variables).forEach((k) => {
            const key = k as keyof FirstLoginMailSettingType;
            const errMsgs = error.cause.errors?.[key];
            if (errMsgs && Array.isArray(errMsgs)) {
              setError(key, { types: toMultiError(errMsgs) });
            }
          });
        }

        if (error.cause.errors) {
          await logger({
            loglevel: error.logLevel,
            data: JSON.stringify(error.cause),
            message: error.message,
          });
        }
      }
      toast({
        ...errorToast,
        duration: 4000,
        title: 'メール設定の登録に失敗しました',
        position: 'bottom',
        variant: 'solid',
      });
    },
  });

  return { mutate: mutateAsync, isLoading };
};
