import { Detail, DetailBlock, DetailFlex, DetailItem, DetailItems } from 'components/paywall/features/Detail';
import { DetailCustomerLink, DetailCustomerLinkText } from 'components/paywall/features/Detail/DetailCustomer';
import {
  CustomerDetail
} from 'components/paywall/pages/CustomerList/typed';
import { FC, memo } from 'react';

type Props = {
  customerDetail: CustomerDetail | undefined;
};

export const CustomerDetailInnerInfo: FC<Props> = memo(({customerDetail}: Props) => (
  <>
    <Detail type={1} title="顧客情報" customerHelp>
      <DetailBlock space={16}>
        <DetailFlex>
          <DetailItems spacing="8px">
            <DetailItem
              label="顧客ID"
              text={customerDetail?.customerUid||''}
              labelWidth="56px"
              spacing="12px"
              isCustomerActive={customerDetail?.customer?.isActive}
            />
            <DetailItem label="姓名" text={`${customerDetail?.customer?.lastName || ''} ${customerDetail?.customer?.firstName || ''}`} labelWidth="56px" spacing="12px" />
            <DetailItem label="郵便番号" text={customerDetail?.customer?.zip||''} labelWidth="56px" spacing="12px" />
            <DetailItem label="住所" text={customerDetail?.customer?.address||''} labelWidth="56px" spacing="12px" />
          </DetailItems>
          <DetailItems spacing="8px">
            <DetailItem label="メールアドレス" text={customerDetail?.email||''} labelWidth="98px" spacing="12px" />
            <DetailItem label="電話番号" text={customerDetail?.customer?.tel||''} labelWidth="98px" spacing="12px" />
            <DetailItem label="都道府県" text={customerDetail?.customer?.prefName||''} labelWidth="98px" spacing="12px" />
          </DetailItems>
        </DetailFlex>
      </DetailBlock>
      <DetailBlock space={16}>
        <DetailCustomerLink spacing="8px">
          <DetailCustomerLinkText path={`/paywall/orderList/?customer_uid=${customerDetail?.customerUid||''}`} text="このユーザーの顧客決済一覧" withIcon />
          <DetailCustomerLinkText path={`/paywall/subscriptionList/?customer_uid=${customerDetail?.customerUid||''}`} text="このユーザーのサブスクリプション注文一覧" withIcon />
        </DetailCustomerLink>
      </DetailBlock>
    </Detail>
  </>
));
