import { SubscriptionDetailOrder } from 'components/paywall/pages/SubscriptionList/typed';

export const defaultSubscriptionDetailOrder: SubscriptionDetailOrder = {
  id: '',
  courseId: '',
  courseName: '',
  tranErrorInfo: '',
  tranErrorMessageCustomer: '',
  tranId: '',
  execTranDate: '',
  price: 0,
  nextTranDate: '',
  isNextTran: false,
  nextPrice: 0,
  nextPromotionName: '',
  rightsExpirationDate: '',
  cancelReason: 0,
  courseStart: '',
  courseEnd: '',
  status: 0,
  cardErrorInfo: [],
}
