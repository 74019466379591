import { Box } from '@chakra-ui/react';
import { Colors } from 'admin/define/colors';
import { FC, memo } from 'react';

type Props = {
  status: boolean;
  onLabel?: string;
  offLabel?: string;
};

export const StatusLabel: FC<Props> = memo(({ status, onLabel, offLabel }) => {
  if (status) {
    return (
      <Box
        py={1}
        px={2}
        borderRadius={4}
        color={Colors.BLUE_00}
        bg={Colors.BLUE_30}
        display="inline-flex"
        alignItems="center"
        justifyContent="center"
        fontSize="12px"
      >
        {onLabel || 'ON'}
      </Box>
    );
  }

  return (
    <Box
      py={1}
      px={2}
      borderRadius={4}
      color="black"
      bg={Colors.GRAY_40}
      display="inline-flex"
      alignItems="center"
      justifyContent="center"
      fontSize="12px"
    >
      {offLabel || 'OFF'}
    </Box>
  );
});
