import { Box, ListItem, Tr as ChakraTr, UnorderedList } from '@chakra-ui/react';
import { NewsletterTableBody } from 'admin/components/idPoolConsumer/newsletter/newsletterTableBody';
import { HelpTooltip } from 'admin/components/ui/helpTooltip';
import { Table, Tbody, Th, Thead } from 'admin/components/ui/table';
import {
  NewslettersType,
  NewsletterType,
} from 'admin/types/userPool/newsletter';
import { Dispatch, FC, memo, SetStateAction } from 'react';

type Props = {
  newsletters: NewslettersType;
  onOpen: (id: NewsletterType['id']) => void;
  onOpenStatusDialog: () => void;
  setTargetNewsletter: Dispatch<SetStateAction<NewsletterType | undefined>>;
  onOpenMypageModalHandler: (
    id: NewsletterType['isDisplayAccountSettings'],
  ) => void;
};

export const NewsletterTableComponent: FC<Props> = memo(
  ({
    newsletters,
    onOpen,
    onOpenStatusDialog,
    setTargetNewsletter,
    onOpenMypageModalHandler,
  }: Props) => (
    <Box mt="24px">
      <Table w="100%" maxHeight="calc(100vh - 300px)">
        <Thead h="54px">
          <ChakraTr>
            <Th maxWidth="150px" minWidth="150px" fontSize="14px">
              メールマガジンID
            </Th>
            <Th maxWidth="180px" minWidth="180px" fontSize="14px">
              表示名（日本語）
            </Th>
            <Th maxWidth="180px" minWidth="180px" fontSize="14px">
              表示名（外国語）
            </Th>
            <Th maxWidth="120px" minWidth="120px" fontSize="14px">
              フィールド名
            </Th>
            <Th maxWidth="240px" minWidth="240px" fontSize="14px">
              メモ
            </Th>
            <Th maxWidth="140px" minWidth="140px" fontSize="14px">
              ステータス
            </Th>
            <Th maxWidth="170px" minWidth="170px" fontSize="14px" pr="0">
              共通マイページ表示
              <HelpTooltip
                label={
                  <UnorderedList>
                    <ListItem>
                      ONにすると、該当のメールマガジン購読設定がAuth
                      Hubで作成するマイページ画面上に表示され、エンドユーザーは購読の設定を参照・変更することができるようになります。
                    </ListItem>
                  </UnorderedList>
                }
              />
            </Th>
            <Th maxWidth="74px" minWidth="74px" fontSize="14px" pr="0">
              並び順
            </Th>
          </ChakraTr>
        </Thead>
        <Tbody>
          {newsletters.map((newsletter) => (
            <NewsletterTableBody
              newsletter={newsletter}
              onOpen={onOpen}
              onOpenStatusDialog={onOpenStatusDialog}
              setTargetNewsletter={setTargetNewsletter}
              onOpenMypageModalHandler={onOpenMypageModalHandler}
              key={`newsletter_${newsletter.id}}`}
            />
          ))}
        </Tbody>
      </Table>
    </Box>
  ),
);
