import {
  AlertDialog,
  Box,
  Drawer,
  Heading,
  Modal,
  useDisclosure,
} from '@chakra-ui/react';
import { withSuspenseAndErrorBoundary } from 'admin/components/Error/WithErrorBoundary';
import { CommonMypageChangeModal } from 'admin/components/idPoolConsumer/newsletter/commonMypageChangeModal';
import { NewsletterEditDrawer } from 'admin/components/idPoolConsumer/newsletter/newsletterDrawer';
import { NewsletterTableComponent } from 'admin/components/idPoolConsumer/newsletter/newsletterTable/NewsletterTableComponent';
import { StatusChangeDialog } from 'admin/components/idPoolConsumer/newsletter/statusChangeDialog';
import { Pagination } from 'admin/components/ui/pagination';
import { useIdPoolConsumerId } from 'admin/hooks/useIdPoolConsumerId';
import { useChangeNewsletterDisplayMyPage } from 'admin/hooks/userPool/newsletter/useChangeNewsletterDisplayMyPage';
import { useNewsletter } from 'admin/hooks/userPool/newsletter/useNewsletter';
import { NewsletterType } from 'admin/types/userPool/newsletter';
import { Page } from 'api/common/types';
import { ErrorContents } from 'components/common/atoms';
import {
  Dispatch,
  FC,
  memo,
  SetStateAction,
  useCallback,
  useEffect,
  useRef,
  useState,
} from 'react';

type Props = {
  currentPage: number;
  perPage: number;
  setPage: Dispatch<SetStateAction<Page>>;
};

export const fetchNewsletterTable: FC<Props> = memo(
  ({ currentPage, perPage, setPage }: Props) => {
    const [newsletterId, setNewsletterId] = useState<NewsletterType['id']>('');
    const [targetNewsletter, setTargetNewsletter] = useState<NewsletterType>();
    const [displayMyPageStatus, setDisplayMyPageStatus] =
      useState<boolean>(false);
    const { isOpen, onClose, onOpen } = useDisclosure();
    const idPoolConsumerId = useIdPoolConsumerId();
    const { data: newsletters, page } = useNewsletter(
      idPoolConsumerId,
      currentPage,
      perPage,
    );
    const cancelRef = useRef(null);

    const {
      isOpen: isOpenStatusDialog,
      onClose: onCloseStatusDialog,
      onOpen: onOpenStatusDialog,
    } = useDisclosure();

    const {
      isOpen: isOpenDisplayMypageModal,
      onClose: onCloseDisplayMypageModal,
      onOpen: onOpenDisplayMypageModal,
    } = useDisclosure();

    const { mutate, isLoading } = useChangeNewsletterDisplayMyPage({
      id: targetNewsletter?.id ?? '',
      userPoolId: idPoolConsumerId,
      status: !targetNewsletter?.isDisplayAccountSettings,
    });

    const onOpenDrawer = useCallback((id: NewsletterType['id']) => {
      setNewsletterId(id);
      onOpen();
    }, []);

    const onOpenMypageModalHandler = useCallback(
      (status: NewsletterType['isDisplayAccountSettings']) => {
        setDisplayMyPageStatus(status);
        onOpenDisplayMypageModal();
      },
      [onOpenDisplayMypageModal],
    );

    const onSubmitDisplayMyPageStatus = useCallback(async () => {
      await mutate();
      onCloseDisplayMypageModal();
    }, [mutate, onCloseDisplayMypageModal]);

    useEffect(() => {
      setPage(page);
    }, [page, setPage]);

    if (!newsletters) {
      return null;
    }

    if (newsletters.length === 0) {
      return (
        <Box
          w="100%"
          textAlign="center"
          bg="white"
          mt={6}
          borderRadius={4}
          padding={10}
        >
          <Heading as="h3" fontWeight="bold" fontSize="24px">
            メールマガジン購読設定が未作成です。
          </Heading>
        </Box>
      );
    }

    return (
      <>
        <NewsletterTableComponent
          newsletters={newsletters}
          onOpen={onOpenDrawer}
          onOpenStatusDialog={onOpenStatusDialog}
          onOpenMypageModalHandler={onOpenMypageModalHandler}
          setTargetNewsletter={setTargetNewsletter}
        />
        <Pagination page={page} />

        <Drawer
          isOpen={isOpen}
          placement="right"
          onClose={onClose}
          size="md"
          closeOnOverlayClick={false}
          closeOnEsc={false}
          autoFocus={false}
        >
          <NewsletterEditDrawer newsletterId={newsletterId} onClose={onClose} />
        </Drawer>
        <AlertDialog
          isOpen={isOpenStatusDialog}
          onClose={onCloseStatusDialog}
          leastDestructiveRef={cancelRef}
          isCentered
          closeOnOverlayClick={false}
          closeOnEsc={false}
          size="xl"
        >
          <StatusChangeDialog
            idPoolConsumerId={idPoolConsumerId}
            onClose={onCloseStatusDialog}
            target={targetNewsletter}
          />
        </AlertDialog>
        <Modal
          isOpen={isOpenDisplayMypageModal}
          onClose={onCloseDisplayMypageModal}
          isCentered
          size="xl"
        >
          <CommonMypageChangeModal
            isLoading={isLoading}
            onClose={onCloseDisplayMypageModal}
            onSubmit={onSubmitDisplayMyPageStatus}
            status={displayMyPageStatus}
          />
        </Modal>
      </>
    );
  },
);

export const NewsletterTable = memo(
  withSuspenseAndErrorBoundary(fetchNewsletterTable, {
    ErrorComponent: <ErrorContents name="メールマガジン購読設定一覧" />,
  }),
);
