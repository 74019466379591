import { Box, Flex, Text } from '@chakra-ui/react';
import { Link } from 'components/paywall/atoms/Link';
import { Colors } from 'components/paywall/config';
import { CustomerDetailInner } from 'components/paywall/pages/CustomerDetail/CustomerDetailInner';
import { Container } from 'components/paywall/templates/Container';
import { useUserTenantId } from 'hooks/user/useUserTenantId';
import { FC, memo, useState } from 'react';
import { useParams } from 'react-router-dom';

export const CustomerDetail: FC = memo(() => {
  const params = useParams();
  const [customerUid] = useState<string | undefined>(params.id);
  const tenantId = useUserTenantId();

  return (
    <>
      <Container
        title={
          <Flex>
            <Link to="/paywall/customerList">
              <Flex>
                <Text
                  fontSize="14px"
                  color={Colors.GRAY_20}
                  fontWeight="700"
                  cursor="pointer"
                >
                  顧客一覧
                </Text>
              </Flex>
            </Link>
            <Text as="span" color={Colors.BLACK} fontWeight="700">
              &nbsp;/&nbsp;
            </Text>
            <Text fontSize="14px" color={Colors.BLACK} fontWeight="700">
              顧客詳細
            </Text>
          </Flex>
        }
        px="0px"
        contentsPl="24px"
      >
        {tenantId && customerUid && (
          <Box
            minW="923px"
            className="PaywallSubscriptionDetail"
            mt="32px"
            mb="46px"
          >
            <CustomerDetailInner
              tenantId={tenantId}
              customerUid={customerUid}
            />
          </Box>
        )}
      </Container>
    </>
  );
});
