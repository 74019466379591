import { UseMutateFunction, useMutation } from 'react-query';
import { postTestMail } from 'api/mail/postTestMail';
import { ResponseError } from 'api/types';
import { SendMailForm, isSendMailForm } from 'api/mail/types';
import { isGetValidationError } from 'utils/form';
import { useCustomToast } from 'hooks/useCustomToast';
import { useMailUtil } from 'hooks/mail/useMailUtil';

type UnPromisify<T> = T extends Promise<infer U> ? U : T;

const formData: SendMailForm = {
  id: '',
  tenant: '',
  subject: '',
  bodyHtml: '',
  bodyText: '',
  fromAddress: '',
  toAddress: '',
};

/**
 * メール作成 hooks
 * @returns {Object} { onSubmit, isLoading }
 */
export const useSendTestMail = ({
  closeHandler,
}: {
  closeHandler: () => void;
}): {
  onSubmit: UseMutateFunction<
    UnPromisify<ReturnType<typeof postTestMail>>,
    unknown,
    SendMailForm,
    unknown
  >;
  isLoading: boolean;
  isSuccess: boolean;
} => {
  const { ClearMailListCache } = useMailUtil();
  const toast = useCustomToast();

  const {
    mutate: onSubmit,
    isLoading,
    isSuccess,
  } = useMutation((sendMailData: SendMailForm) => postTestMail(sendMailData), {
    onSuccess: (result: SendMailForm | boolean | ResponseError) => {
      ClearMailListCache();
      if (isSendMailForm(result)) {
        toast({
          status: 'success',
          position: 'bottom',
          duration: 2000,
          isClosable: true,
          title: 'メールを送信しました',
        });
        closeHandler();
      }

      if (isGetValidationError<SendMailForm>(result, Object.keys(formData))) {
        const errorMsg = Object.entries(result).map(([_, value]) => value);
        toast({
          status: 'error',
          position: 'bottom',
          duration: 4000,
          isClosable: true,
          title: 'テストメール配信に失敗しました',
          description: errorMsg[0],
        });
      }
    },
  });

  return { onSubmit, isLoading, isSuccess };
};
