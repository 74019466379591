import { useState } from 'react';
import { UseMutateFunction, useMutation } from 'react-query';
import { UseFormSetError } from 'react-hook-form';
import { useNavigate } from 'react-router-dom';
import { postMail } from 'api/mail/postMail';
import { ResponseError } from 'api/types';
import {
  CreateMailForm,
  MailEditorForm,
  MailEditorFormResponse,
  isMailSetting,
} from 'api/mail/types';
import { isGetValidationError, toMultiError } from 'utils/form';
import { useCustomToast } from 'hooks/useCustomToast';
import { useMailUtil } from 'hooks/mail/useMailUtil';

type UnPromisify<T> = T extends Promise<infer U> ? U : T;

const formData: MailEditorForm = {
  id: '',
  tenant: '',
  subject: '',
  editorHtml: '',
  editorText: '',
  bodyHtml: '',
  bodyText: '',
};

/**
 * メール作成 hooks
 * @returns {Object} { onSubmit, isLoading }
 */
export const useCreateMail = ({
  setError,
  setNextPath,
}: {
  setError: UseFormSetError<MailEditorForm>;
  setNextPath: (url: string) => void;
}): {
  onSubmit: UseMutateFunction<
    UnPromisify<ReturnType<typeof postMail>>,
    unknown,
    CreateMailForm,
    unknown
  >;
  isLoading: boolean;
  isWarning: boolean;
  warningMsg: {
    filename: string;
    placeholders: string;
    shortagePlaceholders: string;
    excessPlaceholders: string;
  };
} => {
  const { MailFormRefetchListQueries } = useMailUtil();
  const toast = useCustomToast();
  const navigate = useNavigate();
  const [isWarning, setIsWarning] = useState(false);
  const [warningMsg, setWarningMsg] = useState({
    filename: '',
    placeholders: '',
    shortagePlaceholders: '',
    excessPlaceholders: '',
  });

  const { mutate: onSubmit, isLoading } = useMutation(
    (CreateMailFormData: CreateMailForm) => postMail(CreateMailFormData),
    {
      onSuccess: (result: MailEditorFormResponse | boolean | ResponseError) => {
        MailFormRefetchListQueries();

        if (isMailSetting(result)) {
          toast({
            status: 'success',
            position: 'bottom',
            duration: 4000,
            isClosable: true,
            title: 'メールを作成しました',
          });
          const mailId = result.id || '';
          const url = `/mail/?openDrawer=${mailId}`;
          // この時、バックエンド側から注意文が帰ってきた時
          // /mailへ遷移せず、Dialogを表示してから遷移を行う
          // 遷移処理についてはMailFormInner側で実行する
          const { placeholders, isReplaceWord } = result;
          if (isReplaceWord) {
            setIsWarning(true);
            setWarningMsg({
              filename: '',
              placeholders: placeholders || '',
              shortagePlaceholders: '',
              excessPlaceholders: '',
            });
            setNextPath(url);

            // 注意文が返却されなかった時は、今まで通りの処理を行う
          } else {
            navigate(url);
          }
        }

        if (
          isGetValidationError<CreateMailForm>(result, Object.keys(formData))
        ) {
          if (result?.others) {
            const errorMsg = Object.entries(result).map(([_, value]) => value);
            toast({
              status: 'error',
              position: 'bottom',
              duration: 4000,
              isClosable: true,
              description: errorMsg[0],
            });
          } else {
            Object.keys(result).forEach((k) => {
              const key = k as keyof CreateMailForm;
              const errMsgs = result?.[key];
              if (errMsgs) {
                setError(key, { types: toMultiError(errMsgs) });
              }
            });
          }
        }
      },
    },
  );

  return { onSubmit, isLoading, isWarning, warningMsg };
};
