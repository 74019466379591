import { withSuspenseAndErrorBoundary } from 'admin/components/Error/WithErrorBoundary';
import { TenantUsersBody } from 'admin/components/tenantUsers/tenantUsersBody';
import { TenantUsersTitle } from 'admin/components/tenantUsers/tenantUsersTitle';
import { Breadcrumb } from 'admin/components/ui/breadcrumb';
import { BreadcrumbType } from 'admin/types/ui/breadcrumb';
import { ErrorContents, LoadingSpinner } from 'components/common/atoms';
import { useSetPageTitle } from 'hooks/useSetPageTitle';
import { FC, memo } from 'react';

const tenantUsers: FC = memo(() => {
  useSetPageTitle('テナント アカウント管理');

  const breadcrumbItems: BreadcrumbType[] = [
    { url: '/admin', title: '組織管理TOP' },
    { url: '', title: 'テナント アカウント管理' },
  ];

  return (
    <>
      <Breadcrumb breadcrumbItems={breadcrumbItems} />
      <TenantUsersTitle />
      <TenantUsersBody />
    </>
  );
});

export const TenantUsers = withSuspenseAndErrorBoundary(tenantUsers, {
  ErrorComponent: <ErrorContents name="テナント アカウント管理" />,
  LoadingComponent: <LoadingSpinner />,
});
