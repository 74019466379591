import { withSuspenseAndErrorBoundary } from 'admin/components/Error/WithErrorBoundary';
import { AttributeTitleComponent } from 'admin/components/service/attribute/attributeTitle/AttributeTitleComponent';
import { useServices } from 'admin/hooks/service/useServices';
import { ErrorContents } from 'components/common/atoms';
import { FC, memo, useMemo } from 'react';
import { useParams } from 'react-router-dom';

const attributeTitle: FC = memo(() => {
  const params = useParams();
  const tenantId = useMemo(() => params.tenantId ?? '', [params]);
  const { data: tenants } = useServices();

  const headerLabel = useMemo(() => {
    if (!tenants) {
      return '';
    }

    return tenants.find((tenant) => tenant.id === tenantId)?.name ?? '';
  }, [tenants, tenantId]);

  return <AttributeTitleComponent label={headerLabel} />;
});

export const AttributeTitle = memo(
  withSuspenseAndErrorBoundary(attributeTitle, {
    ErrorComponent: <ErrorContents name="属性設定タイトル" />,
  }),
);
