import { VFC, memo, useCallback } from 'react';
import { useFormContext } from 'react-hook-form';
import { useDisclosure } from '@chakra-ui/react';
import { Skin } from 'api/enquete/types';
import { useUserTenantId } from 'hooks/user/useUserTenantId';
import { SkinDefaultValues } from 'components/enquete/EnqueteSkin';
import { Dialog } from 'components/common/atoms';

/**
 * フォームデザインスキンデフォルト設定用ボタン
 */
export const EnqueteSkinDefaultButton: VFC = memo(() => {
  const { isOpen, onOpen, onClose } = useDisclosure();
  const tenantId = useUserTenantId();
  const { setValue } = useFormContext<Skin>();

  const onDefaultSettingChange = useCallback(() => {
    setValue('tenant', tenantId);
    setValue('titleBgColor', SkinDefaultValues.titleBgColor);
    setValue('titleTextColor', SkinDefaultValues.titleTextColor);
    setValue('ruledLineColor', SkinDefaultValues.ruledLineColor);
    setValue('submitButtonBgColor', SkinDefaultValues.submitButtonBgColor);
    setValue('submitButtonTextColor', SkinDefaultValues.submitButtonTextColor);
    setValue('labelBgColor', SkinDefaultValues.labelBgColor);
    setValue('labelTextColor', SkinDefaultValues.labelTextColor);
    setValue('selectButtonBgColor', SkinDefaultValues.selectButtonBgColor);
    setValue('selectButtonTextColor', SkinDefaultValues.selectButtonTextColor);
    setValue(
      'radioButtonSelectedColor',
      SkinDefaultValues.radioButtonSelectedColor,
    );
    setValue('checkboxSelectedColor', SkinDefaultValues.checkboxSelectedColor);
    setValue('finishedButtonBgColor', SkinDefaultValues.finishedButtonBgColor);
    setValue(
      'finishedButtonTextColor',
      SkinDefaultValues.finishedButtonTextColor,
    );
    setValue('logoUrl', SkinDefaultValues.logoUrl);
    setValue('logoPosition', SkinDefaultValues.logoPosition);
    setValue('errorTextColor', SkinDefaultValues.errorTextColor);
    onClose();
  }, [setValue, tenantId, onClose]);

  return (
    <Dialog
      title="デフォルトの設定に戻す"
      isOpen={isOpen}
      onOpen={onOpen}
      onClose={onClose}
      openBtnChildNode="デフォルトの設定に戻す"
      openBtnProps={{ ml: 2, variant: 'outline' }}
      cancelBtnTitle="キャンセル"
      submitBtnTitle="OK"
      submitBtnHandelr={onDefaultSettingChange}
    >
      現在設定されているスキン設定をデフォルトのスキン設定へ戻します。
    </Dialog>
  );
});
