import { Box, Icon } from '@chakra-ui/react';
import styled from '@emotion/styled';
import { useSearch } from 'components/opt/hooks/useSearch';
import { Input } from 'components/paywall/atoms/Input';
import { Colors } from 'components/paywall/config';
import { StyledFlex } from 'components/paywall/styled/global';
import { FC, memo, useEffect } from 'react';
import { MdSearch } from 'react-icons/md';

const StyledContainer = styled(Box)({});

type Props = {
  title?: string;
  explain?: string;
};
export const FilterPrice: FC<Props> = memo(({ title = '', explain }) => {
  const { register, setValue, handleFilterPrice } = useSearch();
  const { searchParam, removeFilter } = useSearch();

  useEffect(() => {
    setValue('priceFrom', searchParam.priceFrom || '');
    setValue('priceTo', searchParam.priceTo || '');
  }, [searchParam.priceFrom, searchParam.priceTo, setValue, removeFilter]);

  return (
    <StyledContainer>
      <StyledFlex style={{ gap: 5 }}>
        {title}
        <Input
          size="sm"
          style={{ width: 75, backgroundColor: '#fff' }}
          ref={register('priceFrom')}
        />
        〜
        <Input
          size="sm"
          style={{ width: 75, backgroundColor: '#fff' }}
          ref={register('priceTo')}
        />
        <Box
          onClick={() => handleFilterPrice()}
          w="24px"
          minW="24px"
          h="24px"
          bgColor={Colors.GRAY_60}
          borderRadius="50%"
          textAlign="center"
          cursor="pointer"
        >
          <Icon
            as={MdSearch}
            boxSize={5}
            color={Colors.GRAY_03}
            verticalAlign="bottom"
          />
        </Box>
        {explain && <>{explain}</>}
      </StyledFlex>
    </StyledContainer>
  );
});
