import { Modal, ModalOverlay } from '@chakra-ui/react';
import { useCopyAuthHub } from 'admin/hooks/authHub/useCopyAuthHub';
import { AuthHubsResultType } from 'admin/types/authHub';
import { memo, useCallback } from 'react';
import { FormProvider, useForm } from 'react-hook-form';
import { QueryObserverResult } from 'react-query';
import { NameForm, NameFormType } from './NameForm';

export const CopyModal = memo(
  ({
    userPoolId,
    tenantId,
    copyTargetId,
    setCopyTargetId,
    refetch,
  }: {
    userPoolId?: string;
    tenantId?: string;
    copyTargetId?: string;
    setCopyTargetId: (_?: string) => void;
    refetch: () => Promise<QueryObserverResult<AuthHubsResultType, unknown>>;
  }) => {
    const onClose = useCallback(() => {
      setCopyTargetId(undefined);
    }, [setCopyTargetId]);
    const methods = useForm({
      mode: 'onBlur',
      defaultValues: {
        name: '',
      },
    });
    const { reset } = methods;
    const { copyMutate } = useCopyAuthHub();
    const onSubmit = useCallback(
      async ({ name }: NameFormType) => {
        if ((userPoolId || tenantId) && copyTargetId) {
          const id = copyTargetId;
          await copyMutate({ userPoolId, tenantId, id, name });
          await refetch();
        }
        setCopyTargetId(undefined);
        reset();
        onClose();
      },
      [
        userPoolId,
        tenantId,
        copyTargetId,
        onClose,
        reset,
        refetch,
        setCopyTargetId,
        copyMutate,
      ],
    );

    return (
      <Modal isOpen={!!copyTargetId} size="lg" onClose={onClose} isCentered>
        <ModalOverlay />
        <FormProvider {...methods}>
          <NameForm
            title="複製"
            saveButtonLabel="複製"
            onSubmit={onSubmit}
            onClose={onClose}
          />
        </FormProvider>
      </Modal>
    );
  },
);
