import {
  TransactionMailCategoryType,
  TransactionMailType,
} from 'admin/types/userPool/transactionMail';

export const queryTransactionMailKey = {
  getTransactionMailCommon: (idPoolConsumerId: string) => [
    'transactionMailCommonSetting',
    idPoolConsumerId,
  ],
  getTransactionMailTypeStatuses: (idPoolConsumerId: string) => [
    'transactionMailTypeStatuses',
    idPoolConsumerId,
  ],
  getTransactionMails: (id: string, mailType: TransactionMailCategoryType) => [
    'transactionMails',
    id,
    mailType,
  ],
  getTransactionMail: (
    idPoolConsumerId: string,
    id: TransactionMailType['id'],
  ) => ['getTransactionMail', idPoolConsumerId, id],
} as const;
