import { forwardRef, Input as ChakraInput, InputProps } from '@chakra-ui/react';
import { KeyboardEvent, useCallback } from 'react';
import { Ref } from 'react-hook-form';

export type Props = {
  ref?: Ref;
} & Omit<InputProps, ''>;

/**
 * @see 汎用インプット
 * @param ref ReactHookFormのregisterへのref
 */
export const Input = forwardRef<Props, 'input'>(
  ({ ...props }: Omit<Props, 'ref'>, ref) => {

    const onKeyDown = useCallback((e: KeyboardEvent<HTMLInputElement>) => {
      // エンターボタン押下で想定外のsubmitを行わない
      if (e.key === 'Enter') {
        e.preventDefault();
      }
    }, []);

    return (
      <ChakraInput {...props} {...ref} onKeyDown={onKeyDown} />
    )
  }
);
