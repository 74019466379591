import { EmailIcon } from '@chakra-ui/icons';
import { Box, Button, Stack, Text } from '@chakra-ui/react';
import { PasswordResetFormForget } from 'api/user/types';
import { AlertBar } from 'components/common/atoms';
import { InputForm } from 'components/common/molecules';
import { useUserPasswordForget } from 'hooks/user/useUserPasswordForget';
import { memo, useState, VFC } from 'react';
import { FormProvider, useForm } from 'react-hook-form';

/**
 * パスワード入力用バリデーション
 */
const emailValidateAttr = {
  required: 'メールアドレスを入力してください',
};

export const ResetPasswordFormForget: VFC = memo(() => {
  const [globalErrors, setGlobalErrors] = useState<string[]>([]);

  const methods = useForm<PasswordResetFormForget>({
    mode: 'onBlur',
  });
  const {
    setError,
    formState: { errors },
    handleSubmit,
  } = methods;
  const { onSubmit, isLoading } = useUserPasswordForget({
    setError,
    setGlobalErrors,
  });

  return (
    <>
      <Box>
        <Text fontSize="20px" align="center" fontWeight="700">
          パスワード再登録
        </Text>
      </Box>
      <Box minW={{ base: '90%', md: '368px' }} maxWidth="368px" mt="32px">
        {globalErrors.map((err, idx) => (
          <AlertBar
            key={`global-err-idx${String(idx)}`}
            message={err}
            status="error"
            mb={4}
          />
        ))}
        <FormProvider {...methods}>
          <form onSubmit={handleSubmit((data) => onSubmit(data))}>
            <Stack
              spacing={4}
              p="1rem"
              backgroundColor="whiteAlpha.900"
              boxShadow="md"
            >
              <Text fontSize="12px">
                UECに登録されているメールアドレスを入力して、パスワードを再登録してください。
              </Text>
              <Stack spacing={4}>
                {/* メールアドレス */}
                <InputForm
                  name="email"
                  type="email"
                  labelElement={<Text fontSize={12} fontWeight={700}>メールアドレス</Text>}
                  attr={emailValidateAttr}
                  icon={EmailIcon}
                />
              </Stack>
              <Button
                variant="primary"
                type="submit"
                disabled={isLoading || Object.keys(errors).length > 0}
                isLoading={isLoading}
              >
                再登録
              </Button>
            </Stack>
          </form>
        </FormProvider>
      </Box>
    </>
  );
});
