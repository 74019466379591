import { request } from 'admin/api/Request';
import { API_MESSAGES } from 'admin/define/apiMessages';
import { CustomError } from 'admin/error/CustomError';
import { samlSettingResponseSchema } from 'admin/schema/saml';
import { SamlSettingFormType, SamlSettingType } from 'admin/types/saml/index';
import { isResponseError } from 'api/types';
import { Options } from 'ky';
import { getValidationError } from 'utils/form';
import { ZodError } from 'zod';

export const createSamlSetting = async (
  id: string,
  data: SamlSettingFormType,
  options?: Options,
): Promise<SamlSettingType> => {
  const params: Omit<SamlSettingType, 'info'> = {
    ...data,
    domains: [...data.domains.map((x) => x.url)],
  };
  const path = `uniikey/${id}/saml_setting/`;

  const response = await request<SamlSettingType>({
    path,
    method: 'post',
    body: params,
    options,
  });

  if (response.hasError) {
    if (response.status === 404) {
      throw new CustomError(API_MESSAGES.SAML.SAVE_SAML.FAILED, {
        path,
        status: response.status,
      });
    } else if (isResponseError(response.error)) {
      const customError = response.error;

      const formError = getValidationError<Omit<SamlSettingType, 'info'>>({
        formData: params,
        response: customError,
      });

      throw new CustomError(API_MESSAGES.SAML.SAVE_SAML.ERROR, {
        path,
        options,
        params,
        errors: formError,
        status: response.status,
        logLevel: response.status === 400 ? 'warning' : 'error',
      });
    } else {
      throw new CustomError(API_MESSAGES.SAML.SAVE_SAML.ERROR, {
        path,
        status: response.status,
      });
    }
  }
  try {
    samlSettingResponseSchema.parse(response.data);
  } catch (error) {
    if (error instanceof ZodError) {
      throw new CustomError(error.message, {
        path,
        status: response.status,
        logLevel: 'error',
      });
    }
  }

  return response.data;
};
