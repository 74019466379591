import { Text } from '@chakra-ui/react';
import { withSuspenseAndErrorBoundary } from 'admin/components/Error/WithErrorBoundary';
import { MemberInfoComponent } from 'admin/components/member/detail/memberInfo/MemberInfoComponent';
import { useMember } from 'admin/hooks/member/useMember';
import { useUserPoolId } from 'admin/hooks/params/useUserPoolId';
import { MemberType } from 'admin/types/member';
import { ErrorContents } from 'components/common/atoms';
import { Dispatch, FC, memo, SetStateAction, useEffect, useMemo } from 'react';
import { useParams } from 'react-router-dom';

type Props = {
  setMember?: Dispatch<SetStateAction<MemberType | undefined>>;
};

const memberInfo: FC<Props> = memo(({ setMember }: Props) => {
  const userPoolId = useUserPoolId();
  const params = useParams();
  const memberId = useMemo(() => params.memberId ?? '', [params.memberId]);
  const { data: member } = useMember(userPoolId, memberId);

  useEffect(() => {
    if (member) {
      setMember?.(member);
    }
  }, [member, setMember]);

  if (!member) {
    return <Text>会員詳細情報がありません。</Text>;
  }

  return <MemberInfoComponent member={member} />;
});

memberInfo.defaultProps = {
  setMember: undefined,
};

export const MemberInfo = withSuspenseAndErrorBoundary(memberInfo, {
  ErrorComponent: <ErrorContents name="会員詳細情報" />,
});
