import { FormGroupDetailType, FormGroupType } from 'admin/types/userPool/formGroup';
import { Page } from 'api/common/types';

export const queryFormGroupKey = {
  getFormGroups: (
    id: FormGroupType['userPoolId'],
    currentPage: Page['currentPage'],
    perPage: Page['perPage'],
  ) => ['formGroup', id, currentPage, perPage],
  // ページング関係なしに全て更新対象となるcache query key
  getFormAllGroups: (
    id: FormGroupType['userPoolId'],
  ) => ['formGroup', id],
  getFormGroupsDetail: (
    id: FormGroupDetailType['id'],
    userPoolId: FormGroupDetailType['userPoolId'],
  ) => ['formGroupsDetailDetail', id, userPoolId],
} as const;
