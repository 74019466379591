import { request } from 'admin/api/Request';
import { API_MESSAGES } from 'admin/define/apiMessages';
import { CustomError } from 'admin/error/CustomError';
import { termFormSchema } from 'admin/schema/idPoolConsumer/terms';
import { UserPool } from 'admin/types/userPool/index';
import { TermType } from 'admin/types/userPool/terms';
import { TermFormType } from 'admin/types/userPool/terms/form';
import { isResponseError } from 'api/types';
import { getValidationError } from 'utils/form';
import { ZodError } from 'zod';

export const editTerm = async ({
  id,
  userPoolId,
  data,
}: {
  id: TermType['id'];
  userPoolId: UserPool['id'];
  data: Partial<TermFormType>;
}): Promise<TermType> => {
  const params = { ...data };

  if (params.displayOrder === '') {
    delete params.displayOrder;
  }

  const path = `uniikey/${userPoolId}/terms/${id}/`;

  const response = await request<TermType>({
    path,
    body: params,
    method: 'patch',
  });

  if (response.hasError) {
    if (response.status === 404) {
      throw new CustomError(API_MESSAGES.USER_POOL.EDIT_TERM.FAILED, {
        path,
        params,
        status: response.status,
      });
    } else if (isResponseError(response.error)) {
      const customError = response.error;

      const formError = getValidationError<Partial<TermFormType>>({
        formData: data,
        response: customError,
      });

      throw new CustomError(
        API_MESSAGES.USER_POOL.CREATE_ID_POOL_CONSUMER_ATTRIBUTE.ERROR,
        {
          path,
          params: data,
          errors: formError,
          status: response.status,
          logLevel: response.status === 400 ? 'warning' : 'error',
        },
      );
    } else {
      throw new CustomError(API_MESSAGES.USER_POOL.EDIT_TERM.ERROR, {
        path,
        params,
        status: response.status,
      });
    }
  }
  try {
    termFormSchema.parse(response.data);
  } catch (error) {
    if (error instanceof ZodError) {
      throw new CustomError(error.message, {
        path,
        params,
        status: response.status,
        logLevel: 'error',
      });
    }
  }

  return response.data;
};
