import { Box, Flex, Text } from '@chakra-ui/react';
import { Colors } from 'components/paywall/config';
import {
  CardItem,
  CardItemBlock,
  CardItemList,
  CardItemListItem,
} from 'components/paywall/features/Card';
import { StatusTag } from 'components/paywall/features/StatusTag';
import { Course } from 'components/paywall/pages/CourseList/typed';
import { format } from 'date-fns';
import { useAmountUtil } from 'hooks/useAmountUtil';
import { FC, memo } from 'react';

type Props = {
  course: Course;
};
type StatusType = {
  code: number;
  name: string;
  status: number;
};

const statusTypes: StatusType[] = [
  { code: 1, name: '開始前', status: 1 },
  { code: 2, name: '実施中', status: 2 },
  { code: 1, name: '終了済み', status: 3 },
];

const DATETIME_FORMAT = 'yyyy/MM/dd HH:mm';

export const PaywallCardItem: FC<Props> = memo(({ course }: Props) => {
  const { amountFormat, calcTaxAmount } = useAmountUtil();

  const formatDate = (date: string | null): string =>
    date ? format(new Date(date), DATETIME_FORMAT) : '';

  const getStatus = (statusCode: number) => {
    const result = statusTypes.filter((item) => item.status === statusCode);

    // ステータスが見つからなかった場合はdefaultを返却する
    if (result.length === 0)
      return {
        code: 0,
        name: '',
        status: 0,
      };

    return result[0];
  };

  return (
    <CardItem>
      <CardItemBlock>
        <Text as="h3" fontSize="14px" fontWeight="700" lineHeight="140%">
          {course.name}
        </Text>
        <Text fontSize="11px" lineHeight="140%">
          ID：{course.id}
        </Text>
      </CardItemBlock>

      <CardItemList>
        {/* コース有効期限 */}
        <CardItemListItem label="有効期限" text={`${course.month || 0}ヶ月`} />

        {/* プロモーション情報 */}
        <CardItemListItem
          label="有効なプロモーション"
          text={course.promotions !== null ? course.promotions.name : 'なし'}
          isSpace
        />
        {course.promotions !== null && (
          <CardItemListItem
            label="プロモーションの期間"
            text={
              <Flex style={{ gap: '4px' }}>
                <Box>
                  <StatusTag
                    type={getStatus(course.promotions.sellStatus).code}
                    status={getStatus(course.promotions.sellStatus).name}
                  />
                </Box>
                <Box>
                  {formatDate(course.promotions.startDate)} ～<br />
                  {formatDate(course.promotions.endDate)}
                </Box>
              </Flex>
            }
            isSpace
          />
        )}
        <CardItemListItem isSpace>
          <Box width="140px" minW="140px">
            <Text color={Colors.GRAY_10}>通常価格</Text>
            {course.promotions !== null && (
              <Text color={Colors.GRAY_10}>プロモーション価格</Text>
            )}
          </Box>
          <Box display="flex" alignItems="center">
            <Box w="72px">
              <Text display="block" color={Colors.GRAY_10}>
                税抜価格：
              </Text>
              {course.promotions !== null && (
                <Text display="block" color={Colors.GRAY_10}>
                  税抜価格：
                </Text>
              )}
            </Box>
            <Box textAlign="right">
              <Text
                display="block"
                fontSize="14px"
                fontWeight="400"
                color={Colors.GRAY_03}
              >
                {amountFormat(course.price || 0)}円
              </Text>
              {course.promotions !== null && (
                <Text
                  display="block"
                  fontSize="14px"
                  fontWeight="400"
                  color={Colors.GRAY_03}
                >
                  {amountFormat(course.promotions.price || 0)}円
                </Text>
              )}
            </Box>
            <Box ml="24px" w="72px">
              <Text display="block" color={Colors.GRAY_10}>
                税込価格：
              </Text>
              {course.promotions !== null && (
                <Text display="block" color={Colors.GRAY_10}>
                  税込価格：
                </Text>
              )}
            </Box>
            <Box textAlign="right">
              <Text
                display="block"
                fontSize="14px"
                fontWeight="400"
                color={Colors.GRAY_03}
              >
                {calcTaxAmount(course.price || 0)}円
              </Text>
              {course.promotions !== null && (
                <Text
                  display="block"
                  fontSize="14px"
                  fontWeight="400"
                  color={Colors.GRAY_03}
                >
                  {calcTaxAmount(course.promotions.price || 0)}円
                </Text>
              )}
            </Box>
          </Box>
        </CardItemListItem>
      </CardItemList>
    </CardItem>
  );
});
