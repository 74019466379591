import { CustomError } from 'api/error/CustomError';
import { getUser } from 'api/user/getUser';
import { logout } from 'api/user/logout';
import { AuthStatusType } from 'api/user/types';
import { AUTHORIZED_EXCLUDED_URLS } from 'define';
import { useAuth } from 'hooks/useAuth';
import { useLocalstorage } from 'hooks/useLocalstorage';
import {
  createContext,
  ReactNode,
  useContext,
  useEffect,
  useState,
} from 'react';
import { useQueryClient } from 'react-query';
import { useNavigate } from 'react-router-dom';
import { deleteCookieAll } from 'utils/cookie';

type AuthContextType = {
  authStatus: AuthStatusType;
  isLoading: boolean;
  duringAuthentication: boolean;
  onLogin: (email: string, password: string) => Promise<void>;
  onLogout: (msg?: string | undefined) => void;
};

const AuthContext = createContext<AuthContextType | undefined>(undefined);

type AuthProviderProps = {
  children: ReactNode;
};

export const AuthProvider = ({ children }: AuthProviderProps) => {
  const queryClient = useQueryClient();
  const queryCache = queryClient.getQueryCache();
  // 認証処理中かどうかを判定するフラグ
  const [duringAuthentication, setDuringAuthentication] = useState(true);
  const [authStatus, setAuthStatus] = useState<AuthStatusType>({
    hasAuthTenant: false,
    hasAuthCorporation: false,
    hasAuthBoth: false,
  });
  const { getAllLocalStorage, deleteLocalStorage } = useLocalstorage();
  const { onAuthLogin, setRecoilAndLocalStorage, isLoading } = useAuth();
  const navigate = useNavigate();

  /**
   * 認証チェック処理
   */
  const checkAuthData = async () => {
    const tmpAuthStatus: AuthStatusType = {
      hasAuthTenant: false,
      hasAuthCorporation: false,
      hasAuthBoth: false,
    };

    const localStorage = getAllLocalStorage();

    // 既にlocalstorageにIDが存在しない場合のみ
    if (Object.keys(localStorage).length === 0) {
      // 認証判定エラー除外URL生成
      const safePattern = AUTHORIZED_EXCLUDED_URLS.map((pattern) =>
        pattern.replace(/\//g, '\\/'),
      ).join('|');
      const excludedURL = new RegExp(safePattern);
      const currentURI = document.documentURI;

      /**
       * 下記条件の時は、認証処理自体を行わずにloginページへ強制的に遷移させる
       * - localStorageにログイン情報が含まれていない場合
       *     - ※ここの処理に入っている時点で上記条件は含まれている
       * - 認証処理除外URLリスト外のリクエストが発生した場合
       */
      // ======================================
      // この処理を使うとSAMLログインができなくなるので
      // コメントアウトしています
      // ======================================
      // if (!excludedURL.test(currentURI)) {
      //   setDuringAuthentication(false);

      //   return;
      // }

      try {
        const user = await getUser();
        setRecoilAndLocalStorage(user);

        // テナントIDを取得
        let tenantId = '';
        if (Array.isArray(user.tenant)) {
          if (user.tenant.length > 0) {
            tenantId = user.tenant[0].id;
          }
        } else {
          tenantId = user.tenant.id;
        }
        // 法人IDを取得
        const corporationId = user.corporations[0]?.id;
        tmpAuthStatus.hasAuthTenant = Boolean(tenantId);
        tmpAuthStatus.hasAuthCorporation = Boolean(corporationId);
        tmpAuthStatus.hasAuthBoth = Boolean(tenantId) || Boolean(corporationId);
        setDuringAuthentication(false); // 認証処理が完了したことを示す
      } catch (e) {
        setDuringAuthentication(false); // 認証処理が完了したことを示す
        if (!excludedURL.test(currentURI)) {
          throw new CustomError('認証に失敗しました。');
        }
      }
    } else {
      tmpAuthStatus.hasAuthTenant = Boolean(localStorage.tenantId);
      tmpAuthStatus.hasAuthCorporation = Boolean(localStorage.corporationId);
      tmpAuthStatus.hasAuthBoth =
        Boolean(localStorage.tenantId) || Boolean(localStorage.corporationId);
      setDuringAuthentication(false); // 認証処理が完了したことを示す
    }

    setAuthStatus(tmpAuthStatus);
  };

  /**
   * 既存ログイン処理をラップする
   */
  const onLogin = async (email: string, password: string) => {
    await onAuthLogin(email, password);
    void checkAuthData();
  };

  /**
   * 既存ログアウト処理をラップする
   */
  const onLogout = async (msg?: string | undefined) => {
    await logout({});
    deleteLocalStorage();
    deleteCookieAll();
    queryCache.clear();
    setAuthStatus({
      hasAuthTenant: false,
      hasAuthCorporation: false,
      hasAuthBoth: false,
    });

    let loginPath = '/login';
    if (msg) {
      loginPath += `?message=${msg}`;
    }
    navigate(loginPath);
  };

  /**
   * ページアクセス時に認証チェック処理を行う
   */
  useEffect(() => {
    void checkAuthData();
  }, []);

  return (
    <AuthContext.Provider
      value={{
        authStatus,
        isLoading,
        duringAuthentication,
        onLogin,
        onLogout,
      }}
    >
      {children}
    </AuthContext.Provider>
  );
};

export const useRouteAuth = () => {
  const context = useContext(AuthContext);
  if (context === undefined) {
    throw new Error('useAuth must be used within an AuthProvider');
  }

  return context;
};
