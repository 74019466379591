import { updateSecuritySettingsExcludeToken } from 'admin/api/userPool/auth/updateSecuritySettingsExcludeToken';
import { SECURITY_SETTING_TYPES_MAP } from 'admin/define/idPoolConsumer/auth';
import { CustomError } from 'admin/error/CustomError';
import { useIdPoolConsumerId } from 'admin/hooks/useIdPoolConsumerId';
import { useUserCorporationInfo } from 'admin/hooks/user/useUserCorporationInfo';
import { SecuritySettingLabelsExcludeToken } from 'admin/types/userPool/auth';
import { SecuritySettingsFormType } from 'admin/types/userPool/auth/form';
import { logger } from 'api/logger';
import { useCustomToast } from 'hooks/useCustomToast';
import { useMutation, useQueryClient } from 'react-query';
import { errorToast, successToast } from 'utils/toast';
import { queryKeys } from './queryKeys';

export const useUpdateSecuritySettingsExcludeToken = (
  settingType: SecuritySettingLabelsExcludeToken,
) => {
  const { currentUserPoolGroupId } = useUserCorporationInfo();
  const idPoolConsumerId = useIdPoolConsumerId();
  const queryClient = useQueryClient();
  const settingLabel = SECURITY_SETTING_TYPES_MAP.get(settingType) || '';
  const toast = useCustomToast();
  const { mutateAsync, isLoading } = useMutation({
    mutationFn: (data: SecuritySettingsFormType) =>
      updateSecuritySettingsExcludeToken(
        currentUserPoolGroupId || '',
        data,
        settingType,
      ),
    onError: async (error) => {
      if (error instanceof CustomError) {
        await logger({
          loglevel: error.logLevel,
          data: JSON.stringify(error.cause),
          message: error.message,
        });
      }
      toast({
        ...errorToast,
        duration: 4000,
        title: `${settingLabel}設定の更新に失敗しました`,
        position: 'bottom',
        variant: 'solid',
      });
    },
    onSuccess: () => {
      void queryClient.invalidateQueries(
        queryKeys.getSecuritySettings(idPoolConsumerId),
      );
      toast({
        ...successToast,
        duration: 4000,
        title: `${settingLabel}設定を更新しました`,
        position: 'bottom',
        variant: 'solid',
      });
    },
  });

  return { mutate: mutateAsync, isLoading };
};
