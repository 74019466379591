import { postFormHosting } from 'api/tenant/postFormHosting';
import { PostFormHostingType, isFormHostingType } from 'api/tenant/types';
import { ResponseError } from 'api/types';
import { queryTenantKey } from 'hooks/tenant/queryTenantKey';
import { useCustomToast } from 'hooks/useCustomToast';
import { UseMutateFunction, useMutation, useQueryClient } from 'react-query';
import { isGetValidationError } from 'utils/form';

type UnPromisify<T> = T extends Promise<infer U> ? U : T;

const postFormHostingData: PostFormHostingType = {
  header: '',
  body: '',
};

/**
 * フォーム関連設定更新 hooks
 * @returns {Object} { onSubmit, isLoading }
 */
export const useFormHostingEdit = (
  // tenantId: string,
  { tenantId }: { tenantId: string },
): {
  onSubmit: UseMutateFunction<
    UnPromisify<ReturnType<typeof postFormHosting>>,
    unknown,
    PostFormHostingType,
    unknown
  >;
  isLoading: boolean;
  isSuccess: boolean;
} => {
  const queryClient = useQueryClient();
  const toast = useCustomToast();

  const {
    mutate: onSubmit,
    isLoading,
    isSuccess,
  } = useMutation(
    ({ header = '', body = '' }: PostFormHostingType) =>
      postFormHosting(tenantId, { body, header }),
    {
      onSuccess: (result: PostFormHostingType | boolean | ResponseError) => {
        void queryClient.invalidateQueries(queryTenantKey.formHosting);

        if (isFormHostingType(result)) {
          toast({
            status: 'success',
            position: 'bottom',
            duration: 2000,
            isClosable: true,
            title: '登録しました',
          });

          void queryClient.invalidateQueries(queryTenantKey.formHosting);

          return;
        }

        if (
          isGetValidationError<PostFormHostingType>(
            result,
            Object.keys(postFormHostingData),
          )
        ) {
          const errorMsg = Object.entries(result).map(([_, value]) => value);
          toast({
            status: 'error',
            position: 'bottom',
            duration: 4000,
            isClosable: true,
            title: errorMsg[0],
          });
        }
      },
    },
  );

  return { onSubmit, isLoading, isSuccess };
};
