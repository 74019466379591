import { updateServiceAttributeGroup } from 'admin/api/service/attribute/updateServiceAttributeGroup';
import { CustomError } from 'admin/error/CustomError';
import { useUserPoolChildId } from 'admin/hooks/useUserPoolChildId';
import { ServiceAttributeGroupChildType } from 'admin/types/service/attribute';
import { ServiceAttributeGroupFormType } from 'admin/types/service/attribute/form';
import { logger } from 'api/logger';
import { useCustomToast } from 'hooks/useCustomToast';
import { Dispatch, SetStateAction } from 'react';
import { UseFormSetError } from 'react-hook-form';
import { useMutation, useQueryClient } from 'react-query';
import { toMultiError } from 'utils/form';
import { errorToast, successToast } from 'utils/toast';
import {
  queryKeyNames,
  querySerivceAttributeKey,
} from './querySerivceAttributeKey';

export const useUpdateServiceAttributeGroup = (
  userPoolChildAttrId: ServiceAttributeGroupChildType['id'],
  setError: UseFormSetError<ServiceAttributeGroupFormType>,
  setGlobalErrors: Dispatch<SetStateAction<string[]>>,
) => {
  const userPoolChildId = useUserPoolChildId();
  const toast = useCustomToast();
  const queryClient = useQueryClient();
  const { mutateAsync, isLoading } = useMutation({
    mutationFn: (data: ServiceAttributeGroupFormType) =>
      updateServiceAttributeGroup({
        userPoolChildId,
        userPoolChildAttrId,
        data,
      }),
    onSuccess: () => {
      void queryClient.invalidateQueries(
        queryKeyNames.getSerivceAttributeGroups,
      );
      void queryClient.invalidateQueries(
        querySerivceAttributeKey.getSerivceAttributeGroupDetail(
          userPoolChildAttrId,
          userPoolChildId,
        ),
      );
      toast({
        ...successToast,
        duration: 4000,
        title: '属性グループ利用設定を更新しました',
        position: 'bottom',
        variant: 'solid',
      });
    },
    onError: async (error, variables: ServiceAttributeGroupFormType) => {
      if (error instanceof CustomError) {
        if (error.cause.errors?.others) {
          const othersMsgs = Array.isArray(error.cause.errors?.others)
            ? error.cause.errors?.others
            : [error.cause.errors?.others];

          setGlobalErrors([othersMsgs.join('')]);
        } else {
          Object.keys(variables).forEach((k) => {
            const key = k as keyof ServiceAttributeGroupFormType;
            const errMsgs = error.cause.errors?.[key];
            if (errMsgs && Array.isArray(errMsgs)) {
              setError(key, { types: toMultiError(errMsgs) });
            }
          });
        }

        if (error.cause.errors) {
          await logger({
            loglevel: error.logLevel,
            data: JSON.stringify(error.cause),
            message: error.message,
          });
        }
      }
      toast({
        ...errorToast,
        duration: 4000,
        title: '属性グループ利用設定の更新に失敗しました',
        position: 'bottom',
        variant: 'solid',
      });
    },
  });

  return { mutate: mutateAsync, isLoading };
};
