import { NewsletterTable } from 'admin/components/idPoolConsumer/newsletter/newsletterTable';
import { usePaginationQuery } from 'admin/hooks/pagination/usePaginationQuery';
import { Page } from 'api/common/types';
import { FC, memo, useMemo, useState } from 'react';

export const NewsletterBody: FC = memo(() => {
  const [page, setPage] = useState<Page>({
    count: 0,
    currentPage: 1,
    countFrom: 0,
    countTo: 0,
    perPage: 50,
    pageCount: 0,
    next: '',
    previous: '',
  });
  const { query } = usePaginationQuery({ page });
  const currentPage = useMemo(() => query.currentPage, [query.currentPage]);
  const perPage = useMemo(() => query.currentPerPage, [query.currentPerPage]);

  return (
    <>
      <NewsletterTable
        currentPage={currentPage}
        perPage={perPage}
        setPage={setPage}
      />
    </>
  );
});
