import { toZod } from 'admin/config/zod';
import { Page } from 'api/common/types';
import { z } from 'zod';

export const pageSchema = z.object<toZod<Page>>({
  count: z.number(),
  currentPage: z.number(),
  countFrom: z.number(),
  countTo: z.number(),
  perPage: z.number(),
  pageCount: z.number(),
  next: z.string(),
  previous: z.string(),
});

export const convertToPage = <T extends Page>(data: T | undefined): Page => ({
  count: data?.count || 0,
  currentPage: data?.currentPage || 0,
  countFrom: data?.countFrom || 0,
  countTo: data?.countTo || 0,
  perPage: data?.perPage || 0,
  pageCount: data?.pageCount || 0,
  next: data?.next || '',
  previous: data?.previous || '',
});
