import { ErrorInfo, PureComponent, ReactNode } from 'react';
import { Alert, AlertIcon } from '@chakra-ui/react';
import { logger } from 'api/logger';
import { CustomError } from 'api/error/CustomError';
import { getLogLevel } from 'utils/requests';

type Props = {
  statusMessages?: {
    [status: number]: string;
  };
  statusComponent?: {
    [status: number]: ReactNode;
  };
  errorComponent?: ReactNode;
  onError: () => void;
};

type State = {
  hasError: boolean;
  error: CustomError | null;
};

const DEFAULT_MESSAGE =
  '申し訳ございません。時間を置いてから再度操作をお願いします。';

class ErrorBoundary extends PureComponent<Props, State> {
  constructor(props: Props) {
    super(props);
    this.state = { hasError: false, error: null };
  }

  static getDerivedStateFromError = (error: CustomError): State => ({
    hasError: true,
    error,
  });

  componentDidCatch = (error: CustomError, info: ErrorInfo): void => {
    const { onError } = this.props;
    onError();

    let msg = '[ErrorBoundary.componentDidCatch]';
    if (error) {
      msg += ` ${error.message}`;
    }

    // @TODO kashima スタックトレース意味ないので有益な情報を出力する方法検討
    let componentStackList: string[] = [];

    if (info && 'componentStack' in info && info.componentStack) {
      componentStackList = info.componentStack
        .split('\n')
        .map((row) => row.replace(/^\s+/g, ''));
    }
    const logLevel = getLogLevel(error.statusCode);

    void logger({
      loglevel: logLevel,
      message: msg,
      data: componentStackList,
    });
  };

  render = (): ReactNode => {
    const { children, errorComponent, statusMessages, statusComponent } =
      this.props;
    const { hasError, error } = this.state;
    const statusCode = error?.statusCode || -1;
    const message =
      (statusMessages && statusMessages[statusCode]) ||
      error?.message ||
      DEFAULT_MESSAGE;
    const renderComponent =
      (statusComponent && statusComponent[statusCode]) || errorComponent;

    if (hasError) {
      if (renderComponent) {
        return renderComponent;
      }

      return (
        <Alert status="error" fontSize="xs" borderRadius="base">
          <AlertIcon />
          {message}
        </Alert>
      );
    }

    return children;
  };
}
export default ErrorBoundary;
