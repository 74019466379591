import { Box, Flex, Switch, Text, Tr as ChakraTr } from '@chakra-ui/react';
import { Table, Tbody, Td, Th, Thead, Tr } from 'admin/components/ui/table';
import { Colors } from 'admin/define/colors';
import { FormGroupsType, FormGroupType } from 'admin/types/userPool/formGroup';
import { FC, memo } from 'react';
import { NavLink } from 'react-router-dom';

type Props = {
  formGroupDatas: FormGroupsType,
  idPoolConsumerId: string,
  onChangeStatus: (data: FormGroupType) => Promise<void>
}

export const FormGroupTableComponent: FC<Props> = memo(({
  formGroupDatas,
  idPoolConsumerId,
  onChangeStatus,
}: Props) => (
  <Box mt="24px">
    <Table maxHeight="calc(100vh - 357px)">
      <Thead h="53px">
        <ChakraTr>
          <Th maxWidth="120px" minWidth="120px" fontSize="14px">設問グループID</Th>
          <Th maxWidth="708px" minWidth="708px" fontSize="14px">設定名</Th>
          <Th  maxWidth="110px" minWidth="110px" textAlign="end" pr="18px" fontSize="14px">ステータス</Th>
        </ChakraTr>
      </Thead>
      <Tbody>
        {formGroupDatas.map((formGroupData, idx) => (
          <Tr key={String(idx)}>
            <Td fontSize="14px">
              <Text
                color={Colors.BLUE_20}
                cursor="pointer"
                textDecoration="underline"
                fontWeight="bold"
              >
                <NavLink
                  to={`/admin/idPoolConsumer/${idPoolConsumerId}/formGroup/detail/${formGroupData.id}`}
                >
                  {formGroupData.id}
                </NavLink>
              </Text>
            </Td>
            <Td fontSize="14px">{formGroupData.name}</Td>
            <Td fontSize="14px" pr="18px">
              <Flex justifyContent="flex-end">
                <Switch
                  isChecked={formGroupData.status}
                  colorScheme="primary"
                  onChange={() =>
                    onChangeStatus(formGroupData)
                  }
                />
                <Text ml="5px" fontWeight={600}>
                  {formGroupData.status ? '有効' : '無効'}
                </Text>
              </Flex>
            </Td>
          </Tr>
        ))}
      </Tbody>
    </Table>
  </Box>
));
