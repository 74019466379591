import { FormControl, FormLabel, Text } from '@chakra-ui/react';
import { SelectForm } from 'components/common/molecules';
import {
  commonSettingFormWords,
  commonSettingWords,
} from 'components/paywall/pages/MailSetting/constants';
import { mailSettingCommonDefaultValue } from 'components/paywall/pages/MailSetting/initial';
import {
  MailSettingCommon,
  MailSettingCommonForm,
} from 'components/paywall/pages/MailSetting/typed';
import { StyledNoResizeTextarea } from 'components/paywall/styled/global';
import { FC, memo, useEffect } from 'react';
import { useFormContext } from 'react-hook-form';

/**
 * noResizeTextareaSxの高さ
 */
const noResizeTextareaSx = {
  height: 'var(--chakra-sizes-40)',
};

type Props = {
  obj: MailSettingCommon;
};

export const Form: FC<Props> = memo(({ obj }) => {
  const { register, setValue } = useFormContext<MailSettingCommonForm>();

  useEffect(() => {
    setValue('header', obj.header);
    setValue('footer', obj.footer);
    setValue('fromAddress', obj.fromAddress);
    setValue('replyAddress', obj.replyAddress);
  }, [setValue, obj]);

  return (
    <form>
      <FormControl fontFamily="Hiragino Sans">
        <FormLabel>
          <Text
            fontSize="14px"
            fontWeight="700"
            lineHeight="125%"
            whiteSpace="nowrap"
          >
            {commonSettingWords.fromAddress}
          </Text>
        </FormLabel>
        <SelectForm<MailSettingCommonForm>
          name="fromAddress"
          placeholder={commonSettingFormWords.fromAddress.placeholder}
          attr={{
            required: '差出人メールアドレスは必須です',
          }}
          optionList={obj.fromAddressList.map((address) => (
            <option key={`mail_setting_fromAddress_${address}`} value={address}>
              {address}
            </option>
          ))}
        />
      </FormControl>
      <FormControl marginTop={6} fontFamily="Hiragino Sans">
        <FormLabel>
          <Text
            fontSize="14px"
            fontWeight="700"
            lineHeight="125%"
            whiteSpace="nowrap"
          >
            {commonSettingWords.replyAddress}
          </Text>
        </FormLabel>
        <SelectForm<MailSettingCommonForm>
          name="replyAddress"
          placeholder={commonSettingFormWords.replyAddress.placeholder}
          attr={{
            required: '',
          }}
          optionList={obj.replyAddressList.map((address) => (
            <option key={`mail_setting_fromAddress_${address}`} value={address}>
              {address}
            </option>
          ))}
        />
      </FormControl>
      <FormControl marginTop={6} fontFamily="Hiragino Sans">
        <FormLabel>
          <Text fontSize="14px" fontWeight="700" lineHeight="125%">
            {commonSettingWords.header}
          </Text>
        </FormLabel>
        <StyledNoResizeTextarea
          sx={noResizeTextareaSx}
          defaultValue={mailSettingCommonDefaultValue.header}
          placeholder={commonSettingFormWords.header.placeholder}
          ref={register('header')}
          minH="256px"
        />
      </FormControl>
      <FormControl marginTop={6} fontFamily="Hiragino Sans">
        <FormLabel>
          <Text fontSize="14px" fontWeight="700" lineHeight="125%">
            {commonSettingWords.footer}
          </Text>
        </FormLabel>
        <StyledNoResizeTextarea
          sx={noResizeTextareaSx}
          defaultValue={mailSettingCommonDefaultValue.footer}
          placeholder={commonSettingFormWords.footer.placeholder}
          ref={register('footer')}
          minH="256px"
        />
      </FormControl>
    </form>
  );
});
