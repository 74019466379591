import { withSuspenseAndErrorBoundary } from 'admin/components/Error/WithErrorBoundary';
import { ServiceBody } from 'admin/components/member/service/serviceBody';
import { ServiceTitle } from 'admin/components/member/service/serviceTitle';
import { Breadcrumb } from 'admin/components/ui/breadcrumb';
import { BreadcrumbType } from 'admin/types/ui/breadcrumb';
import { ErrorContents, LoadingSpinner } from 'components/common/atoms';
import { useSetPageTitle } from 'hooks/useSetPageTitle';
import { FC, memo } from 'react';

export const service: FC = memo(() => {
  useSetPageTitle('テナント詳細');

  const breadcrumbItems: BreadcrumbType[] = [
    { url: '/admin', title: '組織管理TOP' },
    { url: '/admin/member', title: '会員管理' },
    { url: '', title: 'テナント詳細' },
  ];

  return (
    <>
      <Breadcrumb breadcrumbItems={breadcrumbItems} />
      <ServiceTitle />
      <ServiceBody />
    </>
  );
});

export const Service = memo(
  withSuspenseAndErrorBoundary(service, {
    ErrorComponent: <ErrorContents name="テナント詳細" />,
    LoadingComponent: <LoadingSpinner />,
  }),
);
