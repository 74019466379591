import {
  VFC,
  memo,
  useState,
  useEffect,
  useCallback,
  ChangeEvent,
  Suspense,
} from 'react';
import { useNavigate, useSearchParams } from 'react-router-dom';
import {
  Box,
  Text,
  Button,
  ButtonGroup,
  VStack,
  Tag,
  TagLabel,
  TagCloseButton,
  Select,
  Flex,
  Tooltip,
} from '@chakra-ui/react';
import { QuestionIcon } from '@chakra-ui/icons';
import ErrorBoundary from 'api/ErrorBoundary';
import { useErrorBoundaryReset } from 'hooks/useErrorBoundaryReset';
import { useSetPageTitle } from 'hooks/useSetPageTitle';
import { EnqueteColumns, EnqueteColumnsFilter } from 'api/enquete/types';
import { LoadingSkeleton } from 'components/common/atoms';
import { ListError } from 'components/common/molecules';
import { EnqueteListInner } from 'components/enquete/template/EnqueteListInner';

type filterTag = {
  item: EnqueteColumns;
  filterItem: EnqueteColumnsFilter;
};

type customQueryParams = {
  ordering?: string;
  status?: string;
  pageNumber?: string;
  perPage?: string;
  reserve?: string;
};

// 表示件数
const perPageList = [
  { id: 1, text: '50', value: '50' },
  { id: 2, text: '100', value: '100' },
];

const statusList: EnqueteColumnsFilter[] = [
  { id: '1', name: '配信済' },
  { id: '2', name: '未配信' },
];

const columns: EnqueteColumns[] = [
  {
    id: '1',
    key: 'id',
    name: 'フォームID',
    isSort: false,
    isFilter: false,
    w: 160,
  },
  {
    id: '2',
    key: 'title',
    name: 'フォーム名',
    isSort: false,
    isFilter: false,
    w: 250,
  },
  {
    id: '3',
    key: 'reserve',
    name: '公開期間',
    isSort: false,
    isFilter: false,
    w: 140,
  },
  {
    id: '4',
    key: 'status',
    name: '公開状況',
    isSort: false,
    isFilter: false,
    w: 100,
  },
  {
    id: '5',
    key: 'ans_cnt',
    name: '回答数',
    isSort: true,
    isFilter: false,
    w: 90,
  },
  {
    id: '6',
    key: '',
    name: '回答結果',
    isSort: false,
    isFilter: false,
    w: 120,
  },
  {
    id: '7',
    key: 'publish',
    name: '公開設定',
    isSort: false,
    isFilter: false,
    w: 100,
  },
  {
    id: '8',
    key: 'preview',
    name: '',
    isSort: false,
    isFilter: false,
    w: 100,
  },
];

export const EnqueteList: VFC = memo(() => {
  const { ebKey, onError } = useErrorBoundaryReset();
  const navigate = useNavigate();
  useSetPageTitle('フォーム一覧');
  const [searchParams, setSearchParams] = useSearchParams();
  const [filterTags, setFilterTags] = useState<filterTag[]>([]);
  const [currentOrder, setCurrentOrder] = useState('');
  const [currentPerPage, setCurrentPerPage] = useState('50');

  /**
   * クエリパラメータ設定
   */
  const setQueryParameters = useCallback(
    ({
      ordering = '',
      status = '',
      pageNumber = '',
      perPage = '',
      reserve = '',
    }: customQueryParams) => {
      // 現在のクエリパラメータ情報取得
      const openDrawer = searchParams.get('openDrawer');
      let queryOrder = searchParams.get('ordering');
      let queryStatus = searchParams.get('status');
      let queryPage = searchParams.get('page');
      let queryPerPage = searchParams.get('perPage');
      let queryReserve = searchParams.get('reserve');
      if (ordering !== '') queryOrder = ordering;
      if (status !== '') queryStatus = status;
      if (pageNumber !== '') {
        queryPage = pageNumber;
      } else {
        queryPage = '1';
      }
      if (perPage !== '') queryPerPage = perPage;
      if (reserve !== '') queryReserve = reserve;
      const queryParams = {};
      if (openDrawer) Object.assign(queryParams, { openDrawer });
      if (queryOrder) Object.assign(queryParams, { ordering: queryOrder });
      if (queryStatus) Object.assign(queryParams, { status: queryStatus });
      if (queryPerPage) Object.assign(queryParams, { perPage: queryPerPage });
      if (queryReserve) Object.assign(queryParams, { reserve: queryReserve });
      Object.assign(queryParams, { page: queryPage });
      setSearchParams(queryParams);
    },
    [searchParams, setSearchParams],
  );

  /**
   * カラムフィルター 追加処理
   */
  const onColumnFilter = useCallback(
    (item: EnqueteColumns, filterItem: EnqueteColumnsFilter) => {
      const obj = filterTags.filter((tag) => tag.item.id !== item.id);
      obj.push({
        item,
        filterItem,
      });
      setFilterTags(obj);
      // クエリパラメータ処理
      setQueryParameters({ [item.key]: filterItem.id });
    },
    [filterTags, setQueryParameters],
  );

  /**
   * カラムフィルター 削除処理
   */
  const onDeleteFilterTag = useCallback(
    (item: EnqueteColumns, filterItemId: string): void => {
      const obj = filterTags.filter(
        (tag) => tag.item.id !== item.id && tag.filterItem.id !== filterItemId,
      );
      setFilterTags(obj);

      // クエリパラメータ処理
      const params = {};
      if (searchParams.get('ordering'))
        Object.assign(params, { ordering: searchParams.get('ordering') });
      if (searchParams.get('sort'))
        Object.assign(params, { sort: searchParams.get('sort') });
      if (searchParams.get('status') && item.key !== 'status')
        Object.assign(params, { status: searchParams.get('status') });
      if (searchParams.get('page') && item.key !== 'page')
        Object.assign(params, { page: searchParams.get('page') });
      if (searchParams.get('reserve') && item.key !== 'reserve')
        Object.assign(params, { reserve: searchParams.get('reserve') });
      setSearchParams(params);
    },
    [filterTags, searchParams, setSearchParams],
  );

  /**
   * ソート処理
   */
  const onSort = useCallback(
    (item: EnqueteColumns) => {
      if (!item.isSort) return;
      let ordering = item.key;
      if (currentOrder.includes(item.key)) {
        ordering = currentOrder.startsWith('-', 0) ? item.key : `-${item.key}`;
      }

      setQueryParameters({
        ordering,
      });
    },
    [currentOrder, setQueryParameters],
  );

  const onPerPageChange = useCallback(
    (event: ChangeEvent<HTMLSelectElement>) => {
      setCurrentPerPage(event.target.value);
      setQueryParameters({ perPage: event.target.value });
    },
    [setQueryParameters],
  );

  /**
   * 初期処理
   */
  useEffect(() => {
    setCurrentOrder(searchParams.get('ordering') || '');

    const tags: filterTag[] = [];
    // ステータスフィルターが設定されている場合
    const statusFilter = searchParams.get('status');
    if (statusFilter !== null) {
      const statusItem = columns.filter((x) => x.key === 'status');
      const statusFilterItem = statusList.filter((x) => x.id === statusFilter);
      tags.push({
        item: statusItem[0],
        filterItem: statusFilterItem[0],
      });
    }

    if (tags.length !== 0) {
      setFilterTags(tags);
    }
    if (!searchParams.get('page')) setQueryParameters({ pageNumber: '1' });

    // 配信日時フィルターが設定されている場合
    const reserveFilter =
      searchParams.get('reserve') !== null ? searchParams.get('reserve') : null;

    if (reserveFilter !== null) {
      const reserveItem = columns.filter((x) => x.key === 'reserve');
      tags.push({
        item: reserveItem[0],
        filterItem: {
          id: reserveFilter,
          name: reserveFilter,
        },
      });
    }
  }, [searchParams, setQueryParameters]);

  const ToolTipUsageText: VFC = () => (
    <Text fontSize="xs" textIndent="-1em" marginLeft="1em">
      保存済みデザインスキン一覧の表示、新規デザインスキンの作成を行います。
      <br />
      作成したデザインスキンは、フォーム編集画面から選択し適用することができます。
    </Text>
  );

  return (
    <VStack w="100%" spacing={4} px={1}>
      <Box
        w="100%"
        gridGap={2}
        display="flex"
        justifyContent="space-between"
        alignItems="center"
        mt="0px !important"
      >
        <Box w="100%" display="flex">
          {filterTags.map((tag) => (
            <Tag
              key={`${tag.item.id}-${tag.filterItem.id}`}
              size="md"
              variant="solid"
              color="black"
              fontWeight="normal"
              bgColor="gray.200"
              ml={2}
            >
              <TagLabel>{`${tag.item.name}：${tag.filterItem.name}`}</TagLabel>
              <TagCloseButton
                onClick={() => onDeleteFilterTag(tag.item, tag.filterItem.id)}
              />
            </Tag>
          ))}
        </Box>
        <Flex alignItems="center">
          <Tooltip label={<ToolTipUsageText />}>
            <QuestionIcon
              ml={4}
              color="gray.600"
              fontSize="lg"
              style={{ cursor: 'pointer' }}
            />
          </Tooltip>
          <Button
            bgColor="white"
            borderWidth="1px"
            borderColor="gray.400"
            onClick={() => navigate('/enquete/skin')}
            ml={2}
          >
            <Text as="span">デザインスキン一覧</Text>
          </Button>
        </Flex>
        <Box>
          <Select
            w={100}
            onChange={onPerPageChange}
            value={currentPerPage}
            style={{
              height: 'auto',
              border: '1px solid #eee',
              borderRadius: '5px',
              padding: '6px 12px',
            }}
          >
            {perPageList.map((item) => (
              <option key={item.id} value={item.value}>
                {item.text}件
              </option>
            ))}
          </Select>
        </Box>
        <ButtonGroup variant="outline" display="block" ml="auto" spacing="2">
          <Button variant="primary" onClick={() => navigate('/enquete/form')}>
            <Text as="span">新規作成</Text>
          </Button>
        </ButtonGroup>
      </Box>
      <ErrorBoundary
        errorComponent={<ListError message="フォーム一覧を表示できません" />}
        onError={onError}
        key={ebKey.current}
      >
        <Suspense fallback={<LoadingSkeleton width="100%" />}>
          <EnqueteListInner
            columns={columns}
            statusList={statusList}
            onSort={onSort}
            onColumnFilter={onColumnFilter}
          />
        </Suspense>
      </ErrorBoundary>
    </VStack>
  );
});
