import { Box, Text } from '@chakra-ui/react';
import { Colors } from 'admin/define/colors';
import { FC, memo } from 'react';

export const IdPoolConsumerNotCreated: FC = memo(() => (
  <Box
    p="40px"
    border="1px solid"
    borderColor={Colors.GRAY_30}
    bgColor={Colors.WHITE}
    borderRadius="8px"
  >
    <Box textAlign="center">
      <Text
        fontWeight="700"
        fontSize="24px"
        lineHeight="170%"
        fontFamily="Hiragino Sans"
      >
        ユーザープールが未作成です。
      </Text>
    </Box>
  </Box>
));
