import { Box, VStack } from '@chakra-ui/react';
import { Paginator } from 'components/common/atoms';
import { usePaywall } from 'components/paywall/hooks/usePaywall';
import { PaywallCard } from 'components/paywall/pages/WallItemList/PaywallCardList/PaywallCard';
import { useWallItemList } from 'hooks/paywall/useWallItemList';
import { FC, Fragment, memo } from 'react';

export const PaywallCardList: FC = memo(() => {
  const { wallId } = usePaywall({});
  const { page, wallItemList } = useWallItemList({ wallId });

  return (
    <Box minW="855px" className="PaywallCardList">
      <VStack spacing='56px' alignItems="flex-end">
        {wallItemList.map((item) => (
          <Fragment key={item.id}>
            <PaywallCard wallItem={item} />
          </Fragment>
        ))}
      </VStack>

      <Paginator
        pageName={`/paywall/${wallId}/wallItemList`}
        config={page}
      />
    </Box>
  )
})
