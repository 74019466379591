import { CustomError } from 'api/error/CustomError';
import { logger } from 'api/logger';
import { request } from 'api/request';
import { isEnqueteResponseError, isResponseError, ResponseError } from 'api/types';
import { useSearch } from 'components/opt/hooks/useSearch';
import {
  ApiCustomerDetail,
  ApiCustomerList,
  isCustomerDetail,
  isCustomerList
} from 'components/paywall/pages/CustomerList/typed';
import {
  ApiOrderDetail,
  ApiOrderList, isOrderChargeBackResponseType, isOrderDetail,
  isOrderList,
  OrderChargeBackResponseType,
  OrderChargeBackType
} from 'components/paywall/pages/OrderList/typed';
import { DeleteOrderType } from 'components/paywall/pages/SubscriptionDetail/typed';
import {
  ApiSubscriptionDetail,
  ApiSubscriptionList,
  isSubscriptionDetail,
  isSubscriptionList
} from 'components/paywall/pages/SubscriptionList/typed';
import { queryPaywallKey } from 'hooks/paywall/queryPaywallKey';
import { useUserTenantId } from 'hooks/user/useUserTenantId';
import { useQuery } from 'react-query';
import { getValidationError } from 'utils/form';
import { messages } from './messages';

export const useReactQuery = () => {
  const useGet = ({
    queryKeyStrAry,
    path
  }: {
    queryKeyStrAry: string[],
    path: string
  }) =>
    useQuery(
      queryKeyStrAry,
      () =>
        request({
          path,
          method: 'get',
        }),
      {
        suspense: true,
        useErrorBoundary: true,
        refetchOnReconnect: false,
        refetchOnWindowFocus: false,
        retry: false,
      },
    );

  return {
    useGet,
  };
};

type ApiKind =
  | 'orderList'
  | 'orderDetail'
  | 'customerList'
  | 'customerDetail'
  | 'subscriptionList'
  | 'subscriptionDetail';

export const checkApiReturn = (
  data: unknown,
  typeGuard: (arg: unknown) => typeof arg,
  kind: ApiKind,
) => {
  // TODO: 2023.05.08 itsukaichi
  // 以下のネストした記述はNGとします。
  // 理由: 当プロジェクトの基本としてeslintのルールに則って実装を行う為です。
  // そのため、switch文に置き換えました
  // const apiReturn =
  //   kind === 'orderList'
  //     ? (data as ApiOrderList)
  //     : kind === 'orderDetail'
  //     ? (data as ApiOrderDetail)
  //     : kind === 'customerList'
  //     ? (data as ApiCustomerList)
  //     : kind === 'customerDetail'
  //     ? (data as ApiCustomerDetail)
  //     : kind === 'subscriptionList'
  //     ? (data as ApiSubscriptionList)
  //     : (data as ApiSubscriptionDetail);
  let apiReturn = null;
  switch (kind) {
    case 'orderList':
      apiReturn = data as ApiOrderList
      break;
    case 'orderDetail':
      apiReturn = data as ApiOrderDetail
      break;
    case 'customerList':
      apiReturn = data as ApiCustomerList
      break;
    case 'customerDetail':
      apiReturn = data as ApiCustomerDetail
      break;
    case 'subscriptionList':
      apiReturn = data as ApiSubscriptionList
      break;
    default:
      apiReturn = data as ApiSubscriptionDetail
      break;
  }

  if (!apiReturn || apiReturn.hasError) {
    if (apiReturn.status === 404) {
      throw new CustomError('404', 404);
    }
    throw new CustomError(`${apiReturn.status}`, 0);
  }
  // eslint-disable-next-line @typescript-eslint/no-unsafe-call
  if (!typeGuard(apiReturn.data)) {
    // console.log('logging');
  }
};

export const useApiOrderList = () => {
  const tenantId = useUserTenantId();
  const { useGet } = useReactQuery();
  const { makeApiQuery } = useSearch();
  const param = makeApiQuery();
  const path = `paywall/${tenantId}/order/?${param}`;

  const { data, isLoading, isFetching } = useGet({
    queryKeyStrAry: queryPaywallKey.getOrderList({tenantId, param}),
    path
  });

  checkApiReturn(data, isOrderList, 'orderList');

  return {
    data,
    isLoading,
    isFetching,
  };
};

export const useApiOrderDetail = (orderId: string) => {
  const tenantId = useUserTenantId();
  const { useGet } = useReactQuery();
  const path = `paywall/${tenantId}/order/${orderId}`;
  const { data, isLoading, isFetching } = useGet({
    queryKeyStrAry: queryPaywallKey.getOrderDetail({tenantId, id: orderId}),
    path
  });

  checkApiReturn(data, isOrderDetail, 'orderDetail');

  return {
    data,
    isLoading,
    isFetching,
  };
};

export const fetchApiOrderDetail = async (
  tenantId: string,
  orderId: string,
) => {
  const path = `paywall/${tenantId}/order/${orderId}`;
  const response = await request({
    path,
    method: 'get',
  });
  const { data } = response;

  return {
    data,
  };
};

export const fetchApiOrderDelete = async ({
  tenantId,
  wallOrderId
}: Omit<DeleteOrderType, 'deleteText'>): Promise<boolean | string | ResponseError> => {
  const path = `paywall/${tenantId}/order/${wallOrderId}/cancel`;
  const response = await request({
    path,
    method: 'delete',
  });

  // 注文キャンセル処理成功時
  if (response.status === 204) {
    return true;
  }
  // 注文キャンセル処理 サーバー側エラー
  if (response.status >= 500) {
    return messages.deleteOrder.customer.error;
  }
  // 権限が存在しない
  if (response.status === 403) {
    return messages.deleteOrder.customer.noAurthority;
  }
  // 対象の注文が見つからない場合
  if (response.status === 404) {
    return messages.deleteOrder.customer.notFound;
  }

  const { error } = response;

  // 既に購読停止済み
  if (response.status === 400) {
    // APIにて返却されたエラー形式
    if (isResponseError(error)) {
      const formError = getValidationError<Omit<DeleteOrderType, 'deleteText'>>({
        formData: {
          tenantId,
          wallOrderId
        },
        response: error,
      });

      return formError;
    }
  }

  // 予期せぬエラーなので
  // バックエンドに状態を通知
  await logger({
    loglevel:
      response.status === 404 || response.status === 450 ? 'warning' : 'error',
    data: error,
    message: messages.deleteOrder.system.typeError,
  });

  return messages.deleteOrder.customer.failed;
};

type OrderChargeBackErrorType = {
  detail: string
}

const orderChargeBackResponseTypeDefaultValue: OrderChargeBackResponseType = {
  gmoStatus: false,
  appStatus: false,
  messageApp: '',
  messageGmo: '',
  transaction: [],
  detail: ''
}
// チャージバック処理
export const fetchApiOrderChargeBack = async ({
  tenantId,
  orderId,
}: Pick<OrderChargeBackType, 'tenantId' | 'orderId'>): Promise<OrderChargeBackResponseType | string | ResponseError> => {
  const path = `paywall/${tenantId}/order/${orderId}/chargeback`;
  const response = await request({
    path,
    method: 'delete',
  });

  const { error } = response;

  // チャージバック処理 サーバー側エラー
  if (response.status > 500) {
    return messages.orderChargeBack.customer.error;
  }
  // 権限が存在しない
  if (response.status === 403 || response.status === 404 || response.status === 500) {
    const errorMessage = error as OrderChargeBackErrorType;

    return errorMessage.detail;
  }

  // チャージバック処理400エラー時
  if (response.status === 400) {
    // APIにて返却されたエラー形式
    if (isEnqueteResponseError(error)) {
      const formError = getValidationError<OrderChargeBackResponseType>({
        formData: orderChargeBackResponseTypeDefaultValue,
        response: error,
      });

      return formError;
    }
  }

  const responseData = response.data;
  // チャージバック処理成功時
  if (isOrderChargeBackResponseType(responseData)) {
    return responseData;
  }


  // 予期せぬエラーなので
  // バックエンドに状態を通知
  await logger({
    loglevel:
      response.status === 404 || response.status === 450 ? 'warning' : 'error',
    data: error,
    message: messages.orderChargeBack.system.typeError,
  });

  return messages.orderChargeBack.customer.failed;
};

export const useApiCustomerList = () => {
  const tenantId = useUserTenantId();
  const { useGet } = useReactQuery();
  const { makeApiQuery } = useSearch();
  const param = makeApiQuery();
  const path = `paywall/${tenantId}/customer/?${param}`;
  const { data, isLoading, isFetching } = useGet({
    queryKeyStrAry: queryPaywallKey.getCustomerList({tenantId, param}),
    path
  });
  checkApiReturn(data, isCustomerList, 'customerList');

  return {
    data,
    isLoading,
    isFetching,
  };
};

export const useApiCustomerDetail = (customerUid: string) => {
  const tenantId = useUserTenantId();
  const { useGet } = useReactQuery();
  const path = `paywall/${tenantId}/customer/${customerUid}`;
  const { data, isLoading, isFetching } = useGet({
    queryKeyStrAry: queryPaywallKey.getCustomerDetail({tenantId, id: customerUid}),
    path
  });

  checkApiReturn(data, isCustomerDetail, 'customerDetail');

  return {
    data,
    isLoading,
    isFetching,
  };
};

export const fetchApiCustomerMemo = async (
  tenantId: string,
  customerUid: string,
  orderId: string,
  memo: string,
) => {
  const query = {
    customerUid,
    orderId,
    memo,
  };

  const path = `paywall/${tenantId}/customer/memo/`;
  const response = await request({
    path,
    method: 'post',
    options: { json: query },
  });
  const { data } = response;

  return {
    data,
  };
};

export const useApiSubscriptionList = () => {
  const tenantId = useUserTenantId();
  const { useGet } = useReactQuery();
  const { makeApiQuery } = useSearch();
  const param = makeApiQuery();
  const path = `paywall/${tenantId}/subscription/?${param}`;
  const { data, isLoading, isFetching } = useGet({
    queryKeyStrAry: queryPaywallKey.getSubscriptionList({tenantId, param}),
    path
  });
  checkApiReturn(data, isSubscriptionList, 'subscriptionList');

  return {
    data,
    isLoading,
    isFetching,
  };
};

export const useApiSubscriptionDetail = (orderId: string) => {
  const tenantId = useUserTenantId();
  const { useGet } = useReactQuery();
  const path = `paywall/${tenantId}/subscription/${orderId}`;
  const { data, isLoading, isFetching } = useGet({
    queryKeyStrAry: queryPaywallKey.getSubscriptionDetail({tenantId,id: orderId}),
    path
  });

  checkApiReturn(data, isSubscriptionDetail, 'subscriptionDetail');

  return {
    data,
    isLoading,
    isFetching,
  };
};
