import { updateUserDetail } from 'admin/api/user/updateUserDetail';
import { CustomError } from 'admin/error/CustomError';
import { queryIdPoolConsumersKey } from 'admin/hooks/idPoolConsumers/queryIdPoolConsumersKey';
import { queryUserKey } from 'admin/hooks/user/queryUserKey';
import { useUserCorporationId } from 'admin/hooks/user/useUserCorporationId';
import { useUserCorporationInfo } from 'admin/hooks/user/useUserCorporationInfo';
import { queryUsersKey } from 'admin/hooks/users/queryUsersKey';
import { UserType } from 'admin/types/user';
import { UserEditFormValues } from 'admin/types/user/form';
import { logger } from 'api/logger';
import { useCustomToast } from 'hooks/useCustomToast';
import { useRefreshUserState } from 'hooks/user/useRefreshUserState';
import { useUserInfo } from 'hooks/useUserInfo';
import { Dispatch, SetStateAction } from 'react';
import { UseFormSetError } from 'react-hook-form';
import { useMutation, useQueryClient } from 'react-query';
import { toMultiError } from 'utils/form';
import { errorToast, successToast } from 'utils/toast';

export const useUpdateUserDetail = (
  userId: UserType['id'],
  setError: UseFormSetError<UserEditFormValues>,
  setGlobalErrors: Dispatch<SetStateAction<string[]>>,
) => {
  const { isMine } = useUserInfo();
  const corporationId = useUserCorporationId();
  const { currentUserPoolGroupId } = useUserCorporationInfo();
  const toast = useCustomToast();
  const queryClient = useQueryClient();
  const refreshUserState = useRefreshUserState();
  const { mutateAsync } = useMutation({
    mutationFn: (data: UserEditFormValues) =>
      updateUserDetail(corporationId, userId, data),
    onSuccess: async () => {
      await queryClient.invalidateQueries(
        queryUsersKey.getUsers(corporationId),
      );
      await queryClient.invalidateQueries(
        queryUserKey.getUserDetail({ id: userId, corporationId }),
      );
      await queryClient.invalidateQueries(
        queryIdPoolConsumersKey.getIdPoolConsumers(
          currentUserPoolGroupId || '',
        ),
      );
      // ログイン中のユーザーと同一ユーザーの情報を変更する場合
      if (isMine(userId)) {
        // ログインユーザーの userState recoilを更新する
        void refreshUserState();
      }
      toast({
        ...successToast,
        duration: 4000,
        title: 'ユーザーデータを更新しました',
        position: 'bottom',
        variant: 'solid',
      });
    },
    onError: async (error, variables: UserEditFormValues) => {
      if (error instanceof CustomError) {
        if (error.cause.errors?.others) {
          const othersMsgs = Array.isArray(error.cause.errors?.others)
            ? error.cause.errors?.others
            : [error.cause.errors?.others];

          setGlobalErrors(othersMsgs);
        } else {
          Object.keys(variables).forEach((k) => {
            const key = k as keyof UserEditFormValues;
            const errMsgs = error.cause.errors?.[key];
            if (errMsgs && Array.isArray(errMsgs)) {
              setError(key, { types: toMultiError(errMsgs) });
            }
          });
        }

        if (error.cause.errors) {
          await logger({
            loglevel: error.logLevel,
            data: JSON.stringify(error.cause),
            message: error.message,
          });
        }
      }
      toast({
        ...errorToast,
        duration: 4000,
        title: 'ユーザーデータの更新に失敗しました',
        position: 'bottom',
        variant: 'solid',
      });
      await queryClient.invalidateQueries(
        queryUserKey.getUserDetail({ id: userId, corporationId }),
      );
    },
  });

  return { mutate: mutateAsync };
};
