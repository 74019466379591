import { Options } from 'ky';
import { request } from 'api/request';
import { logger } from 'api/logger';
import { CustomError } from 'api/error/CustomError';
import { Wall, isWallList } from 'components/paywall/pages/WallList/typed';
import { messages } from './messages';

/**
 * ペイウォール一覧取得 API
 * @param {string} tenantId テナントID
 * @param {Options} options APIオプション
 * @return {Promise<Wall[]>} ペイウォール一覧
 */
export const getWallList = async (
  tenantId: string,
  options?: Options,
): Promise<Wall[]> => {
  const path = `paywall/${tenantId}/`;

  const response = await request({
    path,
    method: 'get',
    options,
  });

  const responseData = (await response.data) as unknown[];

  if (response.hasError) {
    if (response.status === 404) {
      throw new CustomError(
        messages.getWallList.customer.notFound,
        response.status,
      );
    } else {
      throw new CustomError(messages.getWallList.customer.error);
    }
  }

  if (!isWallList(responseData)) {
    await logger({
      loglevel:
        response.status === 404 || response.status === 450
          ? 'warning'
          : 'error',
      data: responseData,
      message: messages.getWallList.system.typeError,
    });

    throw new CustomError(messages.getWallList.customer.failed);
  }

  return responseData;
};
