import { useDisclosure } from '@chakra-ui/react';
import { withSuspenseAndErrorBoundary } from 'admin/components/Error/WithErrorBoundary';
import { useAuthHub } from 'admin/hooks/userPool/auth/useAuthHub';
import { ErrorContents } from 'components/common/atoms';
import { FC, memo } from 'react';
import { AuthHubDrawer } from '../authHubDrawer';
import { AuthHubSettingsComponent } from './AuthHubSettingsComponent';

const fetchAuthHubSettings: FC = memo(() => {
  const authHub = useAuthHub();
  const { isOpen, onClose, onOpen } = useDisclosure();
  const formId = `authHubForm`;

  return (
    <>
      <AuthHubSettingsComponent authHub={authHub} onDrawerOpen={onOpen} />
      <AuthHubDrawer isOpen={isOpen} onClose={onClose} formId={formId} />
    </>
  );
});

export const AuthHubSettings = memo(
  withSuspenseAndErrorBoundary(fetchAuthHubSettings, {
    ErrorComponent: (
      <ErrorContents name="認証認可とセキュリティ設定Auth Hub設定" />
    ),
  }),
);
