import { Box, BoxProps, useStyleConfig } from '@chakra-ui/react';
import { FC, memo } from 'react';

export const CustomHeader: FC<BoxProps> = memo((props) => {
  const styles = useStyleConfig('CustomAdminHeaderBox', props);

  return (
    <Box sx={styles} {...props}>
      <Box {...props} maxW="calc(100% - 15px)" />
    </Box>
  );
});
