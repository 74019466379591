import { UseMutateFunction, useMutation } from 'react-query';
import { UseFormSetError } from 'react-hook-form';
import { putMailSetting } from 'api/mail/putMailSetting';
import { ResponseError } from 'api/types';
import {
  MailSetting,
  defaultMailSetting,
  MailSettingForm,
  isMailSetting,
} from 'api/mail/types';
import { isGetValidationError, toMultiError } from 'utils/form';
import { useCustomToast } from 'hooks/useCustomToast';
import { useMailUtil } from 'hooks/mail/useMailUtil';

type UnPromisify<T> = T extends Promise<infer U> ? U : T;

const formData: MailSetting = defaultMailSetting;

/**
 * メール設定変更hooks
 * @param {Object} UseFormSetError setError
 * @param {setGlobalErrors} callback setGlobalErrors
 * @returns {Object} { onSubmit, isLoading }
 */
export const useEditMailSetting = ({
  setError,
  onDrawerClose,
}: {
  setError: UseFormSetError<MailSetting>;
  onDrawerClose: () => void;
}): {
  onSubmit: UseMutateFunction<
    UnPromisify<ReturnType<typeof putMailSetting>>,
    unknown,
    MailSettingForm,
    unknown
  >;
  isLoading: boolean;
} => {
  const { MailListRefetchQueries } = useMailUtil();
  const toast = useCustomToast();

  const { mutate: onSubmit, isLoading } = useMutation(
    ({ tenantId, mailSetting }: MailSettingForm) =>
      putMailSetting({ tenantId, mailSetting }),
    {
      onSuccess: (result: MailSetting | ResponseError) => {
        MailListRefetchQueries();
        if (isMailSetting(result)) {
          onDrawerClose();
          toast({
            status: 'success',
            position: 'bottom',
            duration: 2000,
            isClosable: true,
            title: 'メール設定を修正しました',
          });
        }

        if (isGetValidationError<MailSetting>(result, Object.keys(formData))) {
          if (result?.others || result.subscription) {
            const errorMsg = Object.entries(result).map(([_, value]) => value);
            const duration = result.subscription ? 6000 : 4000;
            toast({
              status: 'error',
              position: 'bottom',
              duration,
              isClosable: true,
              title: '入力内容にエラーがあります',
              description: errorMsg[0],
            });
          } else {
            Object.keys(result).forEach((k) => {
              const key = k as keyof MailSetting;
              const errMsgs = result?.[key];
              if (errMsgs) {
                setError(key, { types: toMultiError(errMsgs) });
              }
            });
          }
        }
      },
    },
  );

  return { onSubmit, isLoading };
};
