import { Box, ListItem, UnorderedList, VStack } from '@chakra-ui/react';
import { Colors, ColorSet } from 'components/paywall/config';
import { DetailDialog } from 'components/paywall/features/Detail';
import { OrderChargeBackResponseType } from 'components/paywall/pages/OrderList/typed';
import { FC, memo } from 'react';

type Props = {
  chargeBackResponse: OrderChargeBackResponseType;
  isOpen: boolean;
  onOpen: () => void;
  onClose: () => void;
}

export const DetailOrderChargeBackResponseDialog: FC<Props> = memo(({chargeBackResponse, isOpen, onOpen, onClose}) => (
  <DetailDialog
    title={chargeBackResponse.appStatus ? '購入取り消し処理の完了' : '購入取り消し処理のエラー'}
    isTitleWithIcon={!chargeBackResponse.appStatus}
    isOpen={isOpen}
    onOpen={onOpen}
    onClose={onClose}
    cancelBtnTitle="閉じる"
    cancelBtnProps={{
      fontSize: '14px',
      minWidth: '114px',
      minHeight: '40px',
      ...ColorSet.BLUE
    }}
  >
    <VStack spacing={4} alignItems="flex-start">
      {(
        (chargeBackResponse.appStatus && chargeBackResponse.messageApp !== "") ||
        (chargeBackResponse.gmoStatus && chargeBackResponse.messageGmo !== "")
      ) && (
        <Box w="100%" px={4}>
          <UnorderedList>
            {(chargeBackResponse.appStatus && chargeBackResponse.messageApp !== "") && (
              <ListItem fontSize="14px" fontWeight="700" color={Colors.GRAY_03}>{chargeBackResponse.messageApp}</ListItem>
            )}
            {(chargeBackResponse.gmoStatus && chargeBackResponse.messageGmo !== "") && (
              <ListItem fontSize="14px" fontWeight="700" color={Colors.GRAY_03}>{chargeBackResponse.messageGmo}</ListItem>
            )}
          </UnorderedList>
        </Box>
      )}
      {(
        (!chargeBackResponse.appStatus && chargeBackResponse.messageApp !== "") ||
        (!chargeBackResponse.gmoStatus && chargeBackResponse.messageGmo !== "")
      ) && (
        <Box
          w="100%"
          backgroundColor={Colors.RED_40}
          px={4}
          py={4}
          borderRadius="8px"
        >
          <UnorderedList>
            {(!chargeBackResponse.appStatus && chargeBackResponse.messageApp !== "") && (
              <ListItem fontSize="14px" fontWeight="700" color={Colors.RED_20}>{chargeBackResponse.messageApp}</ListItem>
            )}
            {(!chargeBackResponse.gmoStatus && chargeBackResponse.messageGmo !== "") && (
              <ListItem fontSize="14px" fontWeight="700" color={Colors.RED_20}>{chargeBackResponse.messageGmo}</ListItem>
            )}
          </UnorderedList>
        </Box>
      )}
    </VStack>
  </DetailDialog>
));
