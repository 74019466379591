import { IconButton, Text, Tooltip, useDisclosure } from '@chakra-ui/react';
import { Dialog } from 'components/common/atoms';
import { FC, useCallback } from 'react';
import { FieldValues, Path, useFormContext } from 'react-hook-form';
import { BsTrash } from 'react-icons/bs';

type Props = {
  onDelete: (index: number) => void;
  index: number;
  isAdmin: boolean;
};

export const DeleteContentsDialog = <T extends FieldValues>({ onDelete, index, isAdmin }: Props): ReturnType<FC> => {
  const { isOpen, onOpen, onClose } = useDisclosure();
  const { getValues } = useFormContext<T>();

  const submitBtnHandler = useCallback(() => {
    onDelete(index);
  }, [onDelete, index]);

  // 削除キャンセル
  const cancelBtnHandler = useCallback(() => {
    onClose();
  }, [onClose]);

  return (
    <Dialog
      title="項目削除"
      isOpen={isOpen}
      onOpen={onOpen}
      onClose={onClose}
      openBtnElemType="text"
      openBtnChildNode={
        <>
          <Tooltip label="削除" bg="gray.300" color="black">
            <IconButton
              variant="link"
              aria-label="EnqueteBlockDelete"
              icon={<BsTrash />}
              fontSize={16}
              padding="6px"
              isDisabled={getValues('uneditableState' as Path<T>)}
            />
          </Tooltip>
        </>
      }
      openBtnProps={{
        variant: 'ghost',
        color: 'gray.500',
        size: 'xs',
      }}
      cancelBtnTitle="キャンセル"
      cancelBtnHandelr={cancelBtnHandler}
      submitBtnTitle="削除"
      submitBtnHandelr={submitBtnHandler}
      submitBtnProps={{
        colorScheme: 'red',
      }}
    >
      <Text as="p" mb={2}>
        項目を削除します。よろしいですか？
      </Text>
      {!isAdmin && (
        <Text color="red.600">
          ※設問パーツ・テンプレートを削除する場合は、これまでに受け付けた回答データも共に削除されます。
        </Text>
      )}
    </Dialog>
  );
};
