import { customErrorMessage, toZod } from 'admin/config/zod';
import { ServiceTermResponseType } from 'admin/types/service/terms/index';
import { z } from 'zod';

// zodErrorの日本語化対応
z.setErrorMap(customErrorMessage);

export const serviceTermSchema = z.object<toZod<ServiceTermResponseType>>({
  id: z.string(),
  displayNameJa: z.string(),
  displayNameForeign: z.string().optional(),
  fieldName: z.string(),
  version: z.number(),
  url: z.string(),
  consentRequiredVersion: z.number(),
  isPublic: z.boolean(),
  displayOrder: z.number(),
  isAutoAppendToChild: z.boolean(),
});

export const serviceTermsSchema = z.array(serviceTermSchema);
