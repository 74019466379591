import { getAuthHubs } from 'admin/api/authHub/getAuthHubs';
import { CustomError } from 'admin/error/CustomError';
import { logger } from 'api/logger';
import { useCustomToast } from 'hooks/useCustomToast';
import { useQuery, QueryObserverResult } from 'react-query';
import { errorToast } from 'utils/toast';
import { AuthHubsResultType } from 'admin/types/authHub';
import { queryAuthHubKey } from './queryAuthHubKey';

type UseAuthHubsType = ({
  userPoolId,
  tenantId,
}: {
  userPoolId?: string;
  tenantId?: string;
}) => {
  data?: AuthHubsResultType;
  refetch: (options?: {
    throwOnError: boolean;
    cancelRefetch: boolean;
  }) => Promise<QueryObserverResult<AuthHubsResultType, unknown>>;
};

export const useAuthHubs: UseAuthHubsType = ({ userPoolId, tenantId }) => {
  const toast = useCustomToast();
  const options = {};

  const { data, refetch } = useQuery({
    queryKey: queryAuthHubKey.getAuthHubs({ userPoolId, tenantId }),
    queryFn: () => getAuthHubs({ userPoolId, tenantId, options }),
    refetchOnMount: 'always',
    onError: async (error) => {
      if (error instanceof CustomError) {
        await logger({
          loglevel: error.logLevel,
          data: JSON.stringify(error.cause),
          message: error.message,
        });
      }
      toast({
        ...errorToast,
        duration: 4000,
        title: 'Auth Hub設定情報の取得に失敗しました',
        position: 'bottom',
        variant: 'solid',
      });
    },
  });

  return { data, refetch };
};
