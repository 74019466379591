import { RouteObject } from 'react-router-dom';
import { MailList } from 'components/mail/MailList';
import { MailForm } from 'components/mail/MailForm';

export const MailRoutes: RouteObject[] = [
  {
    path: '',
    element: <MailList />,
  },
  {
    path: 'form/',
    element: <MailForm />,
  },
];
