import { updateTransactionMailCommon } from 'admin/api/userPool/transactionMail/updateTransactionMailCommon';
import { CustomError } from 'admin/error/CustomError';
import { useIdPoolConsumerId } from 'admin/hooks/useIdPoolConsumerId';
import { TransactionMailCommonType } from 'admin/types/userPool/transactionMail';
import { logger } from 'api/logger';
import { useCustomToast } from 'hooks/useCustomToast';
import { Dispatch, SetStateAction } from 'react';
import { UseFormSetError } from 'react-hook-form';
import { useMutation, useQueryClient } from 'react-query';
import { toMultiError } from 'utils/form';
import { errorToast, successToast } from 'utils/toast';
import { queryTransactionMailKey } from './queryTransactionMailKey';

export const useUpdateTransactionMailCommon = ({
  setError,
  setGlobalErrors,
}: {
  setError: UseFormSetError<TransactionMailCommonType>;
  setGlobalErrors: Dispatch<SetStateAction<string[]>>;
}) => {
  const idPoolConsumerId = useIdPoolConsumerId();
  const toast = useCustomToast();
  const queryClient = useQueryClient();
  const { mutateAsync, isLoading } = useMutation({
    mutationFn: (data: TransactionMailCommonType) =>
      updateTransactionMailCommon(idPoolConsumerId, data),
    onSuccess: () => {
      void queryClient.invalidateQueries(
        queryTransactionMailKey.getTransactionMailCommon(idPoolConsumerId),
      );
      toast({
        ...successToast,
        duration: 4000,
        title: 'トランザクションメール共通設定を更新しました',
        position: 'bottom',
        variant: 'solid',
      });
    },
    onError: async (error, variables: TransactionMailCommonType) => {
      if (error instanceof CustomError) {
        if (error.cause.errors?.others) {
          const othersMsgs = Array.isArray(error.cause.errors?.others)
            ? error.cause.errors?.others
            : [error.cause.errors?.others];

          setGlobalErrors(othersMsgs);
        } else {
          Object.keys(variables).forEach((k) => {
            const key = k as keyof TransactionMailCommonType;
            const errMsgs = error.cause.errors?.[key];
            if (errMsgs && Array.isArray(errMsgs)) {
              setError?.(key, { types: toMultiError(errMsgs) });
            }
          });
        }

        if (error.cause.errors) {
          await logger({
            loglevel: error.logLevel,
            data: JSON.stringify(error.cause),
            message: error.message,
          });
        }
      }
      toast({
        ...errorToast,
        duration: 4000,
        title: 'トランザクションメール共通設定の更新に失敗しました',
        position: 'bottom',
        variant: 'solid',
      });
    },
  });

  return { mutate: mutateAsync, isLoading };
};
