import { getFormGroupsDetail } from 'admin/api/userPool/formGroup/getFormGroupDetail';
import { CustomError } from 'admin/error/CustomError';
import { useIdPoolConsumerId } from 'admin/hooks/useIdPoolConsumerId';
import { FormGroupDetailType } from 'admin/types/userPool/formGroup';
import { logger } from 'api/logger';
import { useQuery } from 'react-query';
import { queryFormGroupKey } from './queryFormGroupKey';

export const useFormGroupsDetail = (
  id: FormGroupDetailType['id'],
  enabled = true,
) => {
  const userPoolId = useIdPoolConsumerId();
  const { data } = useQuery({
    queryKey: queryFormGroupKey.getFormGroupsDetail(
      id,
      userPoolId,
    ),
    queryFn: () => getFormGroupsDetail({id, userPoolId}),
    onError: async (error) => {
      if (error instanceof CustomError) {
        await logger({
          loglevel: error.logLevel,
          data: JSON.stringify(error.cause),
          message: error.message,
        });
      }
    },
    enabled,
    cacheTime: 0,
  });

  return { data };
};
