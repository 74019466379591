import {
  Box,
  DrawerCloseButton,
  DrawerContent,
  DrawerHeader,
  DrawerOverlay,
  Text,
} from '@chakra-ui/react';
import { withSuspenseAndErrorBoundary } from 'admin/components/Error/WithErrorBoundary';
import { SamlForm } from 'admin/components/saml/samlForm';
import { useDomainList } from 'admin/hooks/saml/useDomainList';
import { useSamlSetting } from 'admin/hooks/saml/useSamlSetting';
import { ErrorContents, LoadingSpinner } from 'components/common/atoms';
import { FC, memo } from 'react';

type Props = {
  onClose: () => void;
};

const fetchSamlDrawer: FC<Props> = memo(({ onClose }) => {
  const { data: domainList } = useDomainList();
  const { data: samlSetting } = useSamlSetting();

  if (!samlSetting) {
    return null;
  }

  return (
    <SamlForm
      domainList={domainList || []}
      samlSetting={samlSetting}
      onClose={onClose}
    />
  );
});

export const FetchUserEditDrawer = withSuspenseAndErrorBoundary(
  fetchSamlDrawer,
  {
    ErrorComponent: <ErrorContents name="SAML情報詳細" />,
    LoadingComponent: <LoadingSpinner />,
  },
);

export const SamlDrawer: FC<Props> = memo(({ onClose }) => (
  <>
    <DrawerOverlay />
    <DrawerContent>
      <DrawerCloseButton top="16px" right="24px" />
      <DrawerHeader pb={0}>
        <Box m={0} px={0} pb="24px" borderBottomWidth="1px">
          <Text>SAML設定</Text>
        </Box>
      </DrawerHeader>
      <FetchUserEditDrawer onClose={onClose} />
      <DrawerCloseButton top="16px" right="24px" />
    </DrawerContent>
  </>
));
