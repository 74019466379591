import { FC, memo } from 'react';
import { Box, Text, Flex } from '@chakra-ui/react';
import { CodeDisplay } from 'components/setting/apikey/atoms/CodeDisplay';
import { Container } from 'components/setting/apikey/templates/Container';
import { TenantApiKey } from 'api/tenant/types';

interface ApiKeyProps {
  tenantApiKey: TenantApiKey | undefined;
}

export const AccessSecretKey: FC<ApiKeyProps> = memo(
  ({ tenantApiKey }: ApiKeyProps) => (
    <Container text="アクセス・シークレット キー">
      <>
        <Box fontSize={13}>
          <Text>
            個人情報などを含む、厳重な取り扱いが必要なデータに利用します。
          </Text>
          <Text>
            このキーは外部へ公開されることがないよう、お取り扱いに十分ご注意ください。
          </Text>
          <Flex mt={2}>
            <Text w={14}>利用環境</Text>:&nbsp;
            <Text>サーバー</Text>
          </Flex>
          <Flex>
            <Text w={14}>用途</Text>:&nbsp;
            <Text>テナントの識別</Text>
          </Flex>
        </Box>
        <CodeDisplay>
          <>
            <Flex>
              <Text w={120}>アクセスキー</Text>:&nbsp;
              <Text>
                {tenantApiKey?.accessKey || '{ 発行されるアクセスキー }'}
              </Text>
            </Flex>
            <Flex>
              <Text w={120}>シークレットキー</Text>:&nbsp;
              <Text>
                {tenantApiKey?.secretKey || '{ 発行されるシークレットキー }'}
              </Text>
            </Flex>
          </>
        </CodeDisplay>
        <Text fontSize={12}>
          リクエストヘッダーに以下を設定しアクセスしてください。
        </Text>
        <CodeDisplay>
          <>
            <Text>
              Content-Type:&nbsp;application/json;
              <br />
              Authorization:&nbsp;Bearer&nbsp;
              {tenantApiKey?.token || '{ 発行されるトークン }'}
            </Text>
          </>
        </CodeDisplay>
      </>
    </Container>
  ),
);
