import { Box, Tbody } from '@chakra-ui/react';
import { LoadingSkeleton } from 'components/common/atoms';
import { Table, Thead } from 'components/paywall/features/Table';
import { CustomErrorBoundary } from 'components/paywall/molecules/CustomErrorBoundary';
import { ItemDialog } from 'components/paywall/pages/ItemList/ItemDialog';
import { ItemListColumns } from 'components/paywall/pages/ItemList/ItemListColumns';
import { ItemListRows } from 'components/paywall/pages/ItemList/ItemListRows';
import { Container } from 'components/paywall/templates/Container';
import { FC, memo, Suspense } from 'react';

export const ItemList: FC = memo(() => (
  <Container
    title={<>単品ウォール一覧</>}
    label={<ItemDialog isAdd buttonName="新規作成" />}
  >
    <Box mt="40px">
      <Table>
        <Thead>
          <ItemListColumns />
        </Thead>
        <Tbody>
          <CustomErrorBoundary pageTitle="画面一覧">
            <Suspense
              fallback={
                <tr>
                  <td colSpan={4}>
                    <LoadingSkeleton />
                  </td>
                </tr>
              }
            >
              <ItemListRows />
            </Suspense>
          </CustomErrorBoundary>
        </Tbody>
      </Table>
    </Box>
  </Container>
));
