/* eslint-disable @typescript-eslint/no-unused-vars */
import { UseMutateFunction, useMutation } from 'react-query';
import { UseFormSetError } from 'react-hook-form';
import { postEnqueteMail } from 'api/enquete/postEnqueteMail';
import { ResponseError } from 'api/types';
import { SettingInputs, EnqueteMail, EnqueteMailForm } from 'api/enquete/types';
import { isGetValidationError, toMultiError } from 'utils/form';
import { useCustomToast } from 'hooks/useCustomToast';

type UnPromisify<T> = T extends Promise<infer U> ? U : T;

const formData: EnqueteMail = {
  thanksMailFromAddress: '',
  thanksMailReplyAddress: '',
  thanksMailSubject: '',
  thanksMailBodyText: '',
};

/**
 * アンケート設定保存 hooks
 * @param {Object} UseFormSetError setError
 * @param {setGlobalErrors} callback setGlobalErrors
 * @returns {Object} { onSubmit, isLoading }
 */
export const useEnqueteMail = ({
  setError,
}: {
  setError: UseFormSetError<SettingInputs>;
}): {
  onSubmit: UseMutateFunction<
    UnPromisify<ReturnType<typeof postEnqueteMail>>,
    unknown,
    EnqueteMailForm
  >;
  isLoading: boolean;
} => {
  const toast = useCustomToast();

  const { mutate: onSubmit, isLoading } = useMutation(
    ({ tenantId, formId, enqueteMailSetting }: EnqueteMailForm) =>
      postEnqueteMail({ tenantId, formId, enqueteMailSetting }),
    {
      onSuccess: (result: EnqueteMail | boolean | ResponseError) => {
        if (result === true) {
          toast({
            status: 'success',
            position: 'bottom',
            duration: 2000,
            isClosable: true,
            title: 'テストメールを送信いたしました',
          });
        }

        if (isGetValidationError<EnqueteMail>(result, Object.keys(formData))) {
          if (result?.others) {
            const errorMsg = Object.entries(result).map(([_, value]) => value);
            toast({
              status: 'error',
              position: 'bottom',
              duration: 4000,
              isClosable: true,
              title: errorMsg[0],
            });
          } else {
            Object.keys(result).forEach((k) => {
              const key = k as keyof EnqueteMail;
              const errMsgs = result?.[key];
              if (errMsgs) {
                setError(key, { types: toMultiError(errMsgs) });
              }
            });
          }
        }
      },
    },
  );

  return { onSubmit, isLoading };
};
