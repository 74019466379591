import {
  Button,
  FormControl,
  FormErrorMessage,
  HStack,
  ListItem,
  ModalBody,
  ModalCloseButton,
  ModalContent,
  ModalFooter,
  ModalHeader,
  ModalOverlay,
  Text,
} from '@chakra-ui/react';
import { RhfInput } from 'admin/components/form/RhfInput';
import { AlertInfoBar } from 'admin/components/ui/alertInfo';
import { ColorSet } from 'admin/define/colors';
import { TermType } from 'admin/types/userPool/terms';
import { ConfirmSubmitFormType } from 'admin/types/utils/index';
import { FC, memo } from 'react';
import { useFormContext, useFormState } from 'react-hook-form';

export type EnableChangeFormProps = {
  formId: string;
  target: TermType | null;
  isLoading: boolean;
  onClose: () => void;
  onSubmit: () => void;
};

export const EnableChangeFormComponent: FC<EnableChangeFormProps> = memo(
  ({ formId, target, isLoading, onClose, onSubmit }) => {
    const { handleSubmit } = useFormContext();
    const { errors, isValid } = useFormState<ConfirmSubmitFormType>();

    return (
      <>
        <ModalOverlay />
        <ModalContent fontSize="md" zIndex={100}>
          <form onSubmit={handleSubmit(onSubmit)} id={formId}>
            <ModalHeader fontSize="lg" fontWeight="bold" p="6">
              この規約を
              {target?.isEnabled ? '無効' : '有効'}
              にする
            </ModalHeader>
            <ModalBody p="0 24px">
              <Text fontSize="14px" fontWeight="bold">
                規約名
              </Text>

              <Text mt="2" mb="6" p="8px 16px" borderBottom="solid 1px #CFD6DD">
                {target?.displayNameJa
                  ? target?.displayNameJa
                  : target?.displayNameForeign}
              </Text>
              <Text mb={6} lineHeight="27px">
                この規約を
                <Text as="span" fontWeight="bold">
                  {target?.isEnabled ? '無効' : '有効'}化
                </Text>
                します。
                <br />
                {target?.isEnabled
                  ? '無効にすると、以下の設定が変更されます。'
                  : '有効にすると、同意許諾の取得が可能になります。'}
              </Text>
              {target?.isEnabled && (
                <>
                  <AlertInfoBar type="error" iconType="error">
                    <ListItem fontWeight="bold">
                      「全テナント利用」が「OFF」になります。
                    </ListItem>
                    <ListItem fontWeight="bold">
                      各テナントの「利用設定」が「OFF」になります。
                    </ListItem>
                  </AlertInfoBar>
                  <Text mt={6} mb={1} fontSize="sm" fontWeight="bold">
                    実行する場合は「agree」と入力後に「実行ボタン」を押下してください。
                  </Text>
                  <FormControl isInvalid={Boolean(errors.word?.message)}>
                    <RhfInput<ConfirmSubmitFormType> name="word" type="text" />
                    <FormErrorMessage>{errors.word?.message}</FormErrorMessage>
                  </FormControl>
                </>
              )}
            </ModalBody>

            <ModalFooter p="6">
              <HStack spacing={4}>
                <Button
                  w="118px"
                  variant="outline"
                  onClick={() => onClose()}
                  {...ColorSet.Default}
                  padding="8px 24px"
                >
                  キャンセル
                </Button>
                <Button
                  w="118px"
                  colorScheme="primary"
                  type="submit"
                  mr={3}
                  form={formId}
                  disabled={!isValid || isLoading}
                  padding="8px 24px"
                >
                  実行
                </Button>
              </HStack>
            </ModalFooter>
            <ModalCloseButton top="21px" right="21px" />
          </form>
        </ModalContent>
      </>
    );
  },
);
