import {
  Box, FormControl, Radio, RadioGroup, RadioProps,
  useDisclosure
} from '@chakra-ui/react';
import { ErrorTextMsg, WarningModal } from 'components/common/atoms';
import { ChangeEvent, CSSProperties, useCallback, useEffect, VFC } from 'react';
import {
  FieldValues, Path, RegisterOptions, useController, useFormContext
} from 'react-hook-form';
import { toErrMsgList } from 'utils/form';

export type RadioElement = {
  id: number;
  value: string | number;
  element: JSX.Element;
  isEmail?: boolean;
  isDisabled?: boolean;
  onChangeRadio?: (value: string | number) => void;
};

/**
 * RadioFormProps
 */
type RadioFormProps<T> = {
  name: Path<T>;
  attr: RegisterOptions;
  radioList: RadioElement[];
  contains?: boolean;
  style?: CSSProperties;
  warningModalTitle?: string;
  warningModalDescription?: string;
  warningModalSubmitBtnName?: string;
  warningModalCancelBtnName?: string;
  isEmail?: boolean;
  onChangeRadio?: (value: string) => void;
} & Pick<RadioProps, 'isDisabled'>;

export const RadioForm = <T extends FieldValues>({
  name,
  attr,
  radioList,
  contains = false,
  isDisabled = false,
  style = {},
  warningModalTitle = '',
  warningModalDescription = '',
  warningModalSubmitBtnName = '',
  warningModalCancelBtnName = '',
  isEmail,
  onChangeRadio,
}: RadioFormProps<T>): ReturnType<VFC> => {
  const { control } = useFormContext<T>();
  const { isOpen, onOpen, onClose } = useDisclosure();

  const {
    field: { onChange, ...formRest },
    fieldState: { error },
  } = useController({
    name,
    control,
    rules: { ...attr },
  });

  const onRadioChange = useCallback(
    (event: ChangeEvent<HTMLInputElement>, radioName: string) => {
      const target = event.target as HTMLInputElement;
      if (typeof radioList[0].value === 'string')
        onChange(String(target.value));
      if (typeof radioList[0].value === 'number')
        onChange(Number(target.value));
      if (radioName === 'deliveryType' && target.value === '2') {
        onOpen();
      }

      if (onChangeRadio !== undefined) {
        onChangeRadio(target.value);
      }
    },
    [radioList, onChange, onOpen, onChangeRadio],
  );

  useEffect(() => {
    // ラジオボタンの初期化時にも呼ぶ (メルマガ編集の種別で「メールアドレス」が選択されていた場合の対処)
    if (onChangeRadio !== undefined) {
      onChangeRadio(formRest.value);
    }
  });

  return (
    <FormControl>
      <RadioGroup id={name} {...formRest} isDisabled={isDisabled} style={style}>
        {radioList.map((radio) => (
          <Box
            key={`${name}_${radio.id}`}
            display="flex"
            mb={'display' in style && style.display === 'flex' ? 0 : 2}
          >
            {contains && (
              <Radio
                mr={2}
                value={radio.value}
                onChange={(e) => onRadioChange(e, name)}
                isDisabled={(isEmail && radio.value === 2) || radio.isDisabled}
              >
                {radio.element}
              </Radio>
            )}
            {!contains && (
              <>
                <Radio
                  mr={2}
                  value={radio.value}
                  onChange={(e) => onRadioChange(e, name)}
                  isDisabled={radio.isDisabled}
                />
                {radio.element}
              </>
            )}
          </Box>
        ))}
      </RadioGroup>
      {toErrMsgList({ [name]: error }, name).map((err, idx) => (
        <ErrorTextMsg key={`error-${name}-${String(idx)}`} msg={err} />
      ))}
      <WarningModal
        title={warningModalTitle}
        description={warningModalDescription}
        submitBtnName={warningModalSubmitBtnName}
        cancelBtnName={warningModalCancelBtnName}
        isOpen={isOpen}
        onClose={onClose}
      />
    </FormControl>
  );
};
