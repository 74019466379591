import { CustomError } from 'api/error/CustomError';
import { ResponseError } from 'api/types';
import { getInviteCheckCorporation } from 'api/user/getInviteCheckCorporation';
import { VerifyUser } from 'api/user/types';
import { useQuery } from 'react-query';
import { queryUserKey } from './queryUserKey';

/**
 * 招待後のアカウント登録時招待コードチェック
 */
export const useInvitationCorporationVerifyCode = (
  verifyCode: string,
): VerifyUser | ResponseError => {

  const response = useQuery(
    queryUserKey.checkInviteCorporation({ verifyCode }),
    () => getInviteCheckCorporation(verifyCode)
  );

  const { data } = response;

  if (data === undefined) {
    throw new CustomError('招待コード確認エラー');
  }

  return data;
};
