import { request } from 'admin/api/Request';
import { API_MESSAGES } from 'admin/define/apiMessages';
import { CustomError } from 'admin/error/CustomError';
import { attributeGroupsSchema } from 'admin/schema/idPoolConsumer/attributeGroup';
import { convertToPage, pageSchema } from 'admin/schema/page';
import { AttributeGroupType } from 'admin/types/userPool/attributeGroup/index';
import { UserPool } from 'admin/types/userPool/index';
import { DataWithPage } from 'api/common/types';
import { Options } from 'ky';
import { ZodError } from 'zod';

export const getAttributeGroups = async ({
  id,
  page,
  perPage,
  options,
}: {
  id: UserPool['id'];
  page?: number;
  perPage?: number;
  options?: Options;
}): Promise<DataWithPage<AttributeGroupType[]>> => {
  const queryParam = [];
  if (page) {
    queryParam.push(`page=${page}`);
  }
  if (perPage) {
    queryParam.push(`perPage=${perPage}`);
  }
  const param = queryParam.length > 0 ? `?${queryParam.join('&')}` : '';

  // TODO: APIの繋ぎ込みの際に実装する
  const path = `uniikey/${id}/attribute_group/${param}`;
  const response = await request<DataWithPage<AttributeGroupType[]>>({
    path,
    method: 'get',
    options,
  });

  if (response.hasError) {
    if (response.status === 404) {
      throw new CustomError(
        API_MESSAGES.USER_POOL.GET_ID_POOL_CONSUMER_ATTRIBUTE_GROUPS.NOT_FOUND,
        {
          path,
          status: response.status,
        },
      );
    } else {
      throw new CustomError(
        API_MESSAGES.USER_POOL.GET_ID_POOL_CONSUMER_ATTRIBUTE_GROUPS.ERROR,
        {
          path,
          status: response.status,
          logLevel: response.status === 400 ? 'warning' : 'error',
        },
      );
    }
  }

  try {
    attributeGroupsSchema.parse(response.data.results);
    pageSchema.parse(
      convertToPage<DataWithPage<AttributeGroupType[]>>(response.data),
    );
  } catch (error) {
    if (error instanceof ZodError) {
      throw new CustomError(error.message, {
        path,
        options,
        status: response.status,
        logLevel: 'error',
      });
    }
  }

  return response.data;
};
