import { Course } from 'components/paywall/pages/CourseList/typed';

/**
 * ペイウォールデータ型
 */
export type Wall = {
  id: string;
  tenant: string;
  parent: string;
  courses: Course[];
  name: string;
  wallType: string;
  status: boolean;
  alertStatusDesing: number;
  alertStatusProduct: number;
  createdAt: string;
  updatedAt: string;
};

export type WallTypeEnum = 'course' | 'item' | 'member';

export type WallFormType = {
  id?: string;
  name: string;
  wallType: WallTypeEnum | '';
  status?: boolean;
  parent: string;
};

export type WallSubmitFormType = {
  tenantId: string;
  wallForm: WallFormType;
};

export type WallPublish = {
  id: string;
  tenantId: string;
  status: boolean;
};

export const isWall = (data: unknown): data is Wall => {
  const el = data as Wall;

  return (
    typeof el?.id === 'string' &&
    typeof el?.tenant === 'string' &&
    typeof el?.name === 'string' &&
    typeof el?.wallType === 'string' &&
    typeof el?.status === 'boolean' &&
    typeof el?.alertStatusDesing === 'number' &&
    typeof el?.alertStatusProduct === 'number' &&
    (el?.parent !== null ? typeof el?.parent === 'string' : true) &&
    (el?.createdAt !== null ? typeof el?.createdAt === 'string' : true) &&
    (el?.updatedAt !== null ? typeof el?.updatedAt === 'string' : true)
  );
};

export const isWallList = (datas: unknown[]): datas is Wall[] =>
  !datas.some((d) => !isWall(d));
