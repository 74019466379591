import { Box, Checkbox, Text, VStack } from '@chakra-ui/react';
import { Detail, DetailBlock, DetailFlex, DetailItem, DetailItems } from 'components/paywall/features/Detail';
import { DetailOrderCancelDialog } from 'components/paywall/features/Detail/DetailDialog/DetailOrderCancelDialog';
import { StatusTag } from 'components/paywall/features/StatusTag';
import { SubscriptionDetailOrder } from 'components/paywall/pages/SubscriptionList/typed';
import { useAmountUtil } from 'hooks/useAmountUtil';
import { useFormatDate } from 'hooks/useFormatDate';
import { FC, Fragment, memo, useCallback, useState } from 'react';

type StatusType = {
  code: number;
  name: string;
  status: number;
}

const statusTypes = [
  {code: 1, name: '終了済み', status: 2},
  {code: 2, name: 'アクティブ', status: 1},
]

const cancelReasonTypes: StatusType[] = [
  {code: 3, name: 'ユーザーキャンセル', status: 1},
  {code: 3, name: '管理者キャンセル', status: 2},
  {code: 5, name: '決済エラー', status: 3},
  {code: 4, name: 'アップグレード', status: 4},
  {code: 1, name: '退会', status: 5},
]

type Props = {
  order: SubscriptionDetailOrder;
}

export const SubscriptionDetailInnerInfo: FC<Props> = memo(({order}: Props) => {
  const [isShowButton, setIsShowButton] = useState<boolean>(false);
  const { amountFormat } = useAmountUtil();
  const { dateTimeFormat } = useFormatDate();

  const getStatus = (statusCode: number) => {
    const result = statusTypes.filter((item) => item.status === statusCode )

    // ステータスが見つからなかった場合はdefaultを返却する
    if(result.length === 0) return {
      code: 0,
      name: '',
      stauts: 0
    }

    return result[0];
  }

  // サブスクリプション情報 > 終了理由用ステータスコード取得
  const getCancelReasonType = (reasonCode: number) => {
    const target = cancelReasonTypes.filter((x) => x.status === reasonCode);
    if(target.length === 0) return {
      code: 0,
      name: '',
      stauts: 0
    }

    return target[0];
  }

  // サブスクリプション自動配信停止ボタンを表示用チェックボックス押下時処理
  const onClickCheckBox = useCallback((isCheck: boolean) => {
    setIsShowButton(isCheck);
  }, []);

  return (
    <Detail type={2} title="サブスクリプション情報">
      <DetailBlock space={24}>
        <DetailFlex>
          <DetailItems spacing="8px">
            <DetailItem label="注文ID" text={order.id || ''} labelWidth="84px" spacing="16px" />
            <DetailItem label="コースID" text={order.courseId || ''} labelWidth="84px" spacing="16px" />
            <DetailItem label="ステータス" text={<StatusTag type={getStatus(order.status).code} status={getStatus(order.status).name} />} labelWidth="84px" spacing="16px" />
            <DetailItem label="終了理由" text={<StatusTag type={getCancelReasonType(order.cancelReason).code} status={getCancelReasonType(order.cancelReason).name} />} labelWidth="84px" spacing="16px" />
            <DetailItem label="権利有効期限" text={dateTimeFormat(order.rightsExpirationDate) || ''} labelWidth="84px" spacing="16px" />
          </DetailItems>
          <DetailItems spacing="8px">
            <DetailItem label="コース" text={order.courseName || ''} labelWidth="70px" spacing="16px" />
            <DetailItem label="契約開始日" text={dateTimeFormat(order.courseStart) || ''} labelWidth="70px" spacing="16px" />
            <DetailItem label="契約終了日" text={dateTimeFormat(order.courseEnd) || ''} labelWidth="70px" spacing="16px" />
            <DetailItem
              label="決済エラー"
              text={(
                <>
                  {order.cardErrorInfo.map((info, idx) => (
                    <Fragment key={`order_${info.code}_${String(idx)}`}>
                      <Box>{info.code}: </Box>
                      <Box fontWeight="400">{info.message}</Box>
                    </Fragment>
                  ))}
                </>
              )}
              labelWidth="70px" spacing="16px"
            />
          </DetailItems>
        </DetailFlex>
      </DetailBlock>
      <DetailBlock space={24} spacerTitle="次回決済情報">
        <DetailBlock space={8} divider={false}>
          <DetailFlex>
            <DetailItems spacing="8px">
              <DetailItem label="次回決済の自動更新" text={order.isNextTran ? '有効' : '無効'} labelWidth="126px" spacing="16px" />
              <DetailItem label="次回決済金額" text={order.nextPrice ? `${amountFormat(order.nextPrice)}円` : ''} labelWidth="126px" spacing="16px" />
            </DetailItems>
            <DetailItems spacing="8px">
              <DetailItem label="次回決済日" text={order.nextTranDate || ''} labelWidth="154px" spacing="16px" />
              <DetailItem label="次回プロモーション適用" text={order.nextPromotionName || 'なし'} labelWidth="154px" spacing="16px" />
            </DetailItems>
          </DetailFlex>
        </DetailBlock>
      </DetailBlock>
      {!order.courseEnd && (
        <DetailBlock space={24}>
          <VStack spacing="8px" alignItems="flex-end">
            <Checkbox
              isChecked={isShowButton}
              onChange={() => onClickCheckBox(!isShowButton)}
            >
              <Text fontSize="14px" fontWeight="400">サブスクリプションの自動配信停止ボタンを表示</Text>
            </Checkbox>
            <DetailOrderCancelDialog orderId={order.id} isShowButton={isShowButton} />
          </VStack>
        </DetailBlock>
      )}
    </Detail>
  );
});
