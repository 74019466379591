import { AdminRoutes } from 'admin/router';
import { Template as AdminTemplate } from 'admin/templates';
import { useRouteAuth } from 'auth/authContext';
import { Template } from 'components/common/templates/Template';
import { Login } from 'components/login/Login';
import { Logout } from 'components/logout/Logout';
import { InviteCorporation } from 'components/user/InviteCorporation';
import { InviteUser } from 'components/user/InviteUser';
import { ResetPassword } from 'components/user/ResetPassword';
import { ResetPasswordForget } from 'components/user/ResetPasswordForget';
import { Navigate, RouteObject } from 'react-router-dom';
import { TenantRoutes } from './tenantRoutes';

/**
 * ログイン認証済みルーティング(テナント認証有り)
 */
const HasLoggedInRoute = ({ children }: { children: JSX.Element }) => {
  const { authStatus } = useRouteAuth();

  // 認証がない場合
  if (!authStatus.hasAuthBoth) {
    return <Navigate to="/login" replace />;
  }

  // テナント認証がない場合
  if (!authStatus.hasAuthTenant) {
    return <Navigate to="/admin" replace />;
  }

  return children;
};

/**
 * ログイン認証済みルーティング(テナント認証なし・法人認証有り)
 */
const HasLoggedInWithAdminRoute = ({ children }: { children: JSX.Element }) => {
  const { authStatus } = useRouteAuth();

  // 認証がない場合
  if (!authStatus.hasAuthBoth) {
    return <Navigate to="/login" replace />;
  }

  // 法人認証がない場合
  if (!authStatus.hasAuthCorporation) {
    return <Navigate to="/" replace />;
  }

  return children;
};

/**
 * ログイン認証なしルーティング
 */
const HasNotLoggedInRoute = ({ children }: { children: JSX.Element }) => {
  const { authStatus } = useRouteAuth();

  // 認証がある場合
  if (authStatus.hasAuthBoth) {
    if (!authStatus.hasAuthTenant) {
      return <Navigate to="/admin" replace />;
    }

    return <Navigate to="/" replace />;
  }

  return children;
};

export const routes = (): RouteObject[] => [
  {
    path: '/login',
    element: (
      <HasNotLoggedInRoute>
        <Login />
      </HasNotLoggedInRoute>
    )
  },
  {
    path: '/logout',
    element:  <Logout />
  },
  {
    path: '/user/invite/:verifyCode',
    element: (
      <HasNotLoggedInRoute>
        <InviteUser />
      </HasNotLoggedInRoute>
    )
  },
  {
    path: '/user/invite_corporation/:verifyCode',
    element: (
      <HasNotLoggedInRoute>
        <InviteCorporation />
      </HasNotLoggedInRoute>
    )
  },
  {
    path: '/user/password_reset/:uuid',
    element: (
      <HasNotLoggedInRoute>
        <ResetPassword />
      </HasNotLoggedInRoute>
    )
  },
  {
    path: '/password_forget/',
    element: (
      <HasNotLoggedInRoute>
        <ResetPasswordForget />
      </HasNotLoggedInRoute>
    )
  },
  {
    path: '/admin',
    element: (
      <HasLoggedInWithAdminRoute>
        <AdminTemplate />
      </HasLoggedInWithAdminRoute>
    ),
    children: [...AdminRoutes],
  },
  {
    path: '/',
    element: (
      <HasLoggedInRoute>
        <Template />
      </HasLoggedInRoute>
    ),
    children: [...TenantRoutes]
  },
  {
    path: '*',
    element: (
      <HasLoggedInRoute>
        <Template />
      </HasLoggedInRoute>
    ),
  },
];
