import { FC, memo } from 'react';
import { useRoutes } from 'react-router-dom';
import { ScrollToTop } from 'utils/scrollToTop';
import { routes } from './routes';

export const Router: FC = memo(() => {
  const routing = useRoutes(routes());
  // ページ遷移時、強制的にscrollTop:0とする
  ScrollToTop();

  return <>{routing}</>;
});
