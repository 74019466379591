import { Input, InputProps } from '@chakra-ui/react';
import { ChangeEvent, FC } from 'react';
import {
  FieldValues,
  Path,
  useController,
  useFormContext,
} from 'react-hook-form';

type RhfInputProps<T> = {
  name: Path<T>;
} & Pick<
  InputProps,
  | 'width'
  | 'placeholder'
  | 'border'
  | 'variant'
  | 'bgColor'
  | 'isDisabled'
  | 'type'
  | 'onBlur'
  | 'isDisabled'
  | 'onChange'
>;

export const RhfInput = <T extends FieldValues>({
  name,
  onChange,
  ...rest
}: RhfInputProps<T>): ReturnType<FC> => {
  const formContext = useFormContext<FieldValues>();
  const { control, register } = formContext;
  const { field } = useController({ name, control });

  return (
    <Input
      {...field}
      {...register(name)}
      onChange={(e: ChangeEvent<HTMLInputElement>) => {
        if (onChange) {
          onChange(e);
        }
        field.onChange(e);
      }}
      {...rest}
    />
  );
};
