import { VFC, useCallback } from 'react';
import { useDropzone } from 'react-dropzone';
import { Flex } from '@chakra-ui/react';
import { AiOutlineCloudUpload } from 'react-icons/ai';
import { useTableId } from 'hooks/contentsdb/useTableId';
import { useUploadRequest } from 'hooks/contentsdb/useUploadRequest';
import { useUserTenantId } from 'hooks/user/useUserTenantId';
import { CONTENTSDB_FILE_UPLOAD_TYPE } from 'define';

type TableFileUploadProps = {
  modalClose?: () => void;
  uploadType: typeof CONTENTSDB_FILE_UPLOAD_TYPE[number];
};

export const TableFileUpload: VFC<TableFileUploadProps> = ({
  modalClose = () => undefined,
  uploadType,
}) => {
  const uploadRequest = useUploadRequest(uploadType);
  const onDrop = useCallback(
    (files: File[]) => {
      modalClose();
      uploadRequest(files);
    },
    [modalClose, uploadRequest],
  );

  const { getRootProps, getInputProps } = useDropzone({
    onDrop,
  });
  const tableId = useTableId();
  const tenantId = useUserTenantId();

  if (!tableId || !tenantId) {
    return <></>;
  }

  return (
    <div className="container">
      <div {...getRootProps({ className: 'dropzone' })}>
        <input {...getInputProps()} />
        <Flex
          bg="teal.50"
          color="teal.200"
          w="full"
          h={48}
          _hover={{
            cursor: 'pointer',
            backgroundColor: 'gray.100',
          }}
          direction="column"
          alignItems="center"
          justifyContent="center"
          style={{
            transition: 'all 300ms 0s ease',
          }}
          borderRadius="md"
        >
          <AiOutlineCloudUpload size="4em" />
          <p>ファイルをドラッグ＆ドロップしてください</p>
        </Flex>
      </div>
    </div>
  );
};
