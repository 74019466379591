import { UserPool } from 'admin/types/userPool';
import { IdPoolConsumerAttributeType } from 'admin/types/userPool/attribute';
import { AttributeGroupType } from 'admin/types/userPool/attributeGroup';
import { Page } from 'api/common/types';

export const queryKeyNames = {
  getAttributeGroups: 'getAttributeGroups',
  getAttributeGroupDetail: 'getAttributeGroupDetail',
};

export const queryAttributeGroupKey = {
  getAttributeGroups: (
    id: UserPool['id'],
    currentPage?: Page['currentPage'],
    perPage?: Page['perPage'],
  ) =>
    [queryKeyNames.getAttributeGroups, id, currentPage, perPage].filter(
      (item) => item,
    ),
  getAttributeGroupDetail: (
    id: IdPoolConsumerAttributeType['id'],
    attributeGroupId: AttributeGroupType['id'],
  ) => [queryKeyNames.getAttributeGroupDetail, id, attributeGroupId],
} as const;
