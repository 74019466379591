import { Icon, IconProps } from '@chakra-ui/react';

type CustomIconType = (props: IconProps) => JSX.Element;
export const TransactionMailIcon: CustomIconType = (props: IconProps) => (
  <Icon
    xmlns="http://www.w3.org/2000/svg"
    width="24"
    height="24"
    viewBox="0 0 24 24"
    fill="none"
    {...props}
  >
    <svg
      width="24"
      height="24"
      viewBox="0 0 24 24"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <mask
        id="mask0_22834_49436"
        maskUnits="userSpaceOnUse"
        x="0"
        y="0"
        width="24"
        height="24"
      >
        <rect width="24" height="24" fill="#D9D9D9" />
      </mask>
      <g mask="url(#mask0_22834_49436)">
        <path
          d="M4 20C3.45 20 2.97917 19.8042 2.5875 19.4125C2.19583 19.0208 2 18.55 2 18V6C2 5.45 2.19583 4.97917 2.5875 4.5875C2.97917 4.19583 3.45 4 4 4H20C20.55 4 21.0208 4.19583 21.4125 4.5875C21.8042 4.97917 22 5.45 22 6V9.975H20V8L12 13L4 8V18H16V20H4ZM12 11L20 6H4L12 11ZM19 20C18.7167 20 18.4792 19.9042 18.2875 19.7125C18.0958 19.5208 18 19.2833 18 19V16C18 15.7167 18.0958 15.4792 18.2875 15.2875C18.4792 15.0958 18.7167 15 19 15V14C19 13.45 19.1958 12.9792 19.5875 12.5875C19.9792 12.1958 20.45 12 21 12C21.55 12 22.0208 12.1958 22.4125 12.5875C22.8042 12.9792 23 13.45 23 14V15C23.2833 15 23.5208 15.0958 23.7125 15.2875C23.9042 15.4792 24 15.7167 24 16V19C24 19.2833 23.9042 19.5208 23.7125 19.7125C23.5208 19.9042 23.2833 20 23 20H19ZM20 15H22V14C22 13.7167 21.9042 13.4792 21.7125 13.2875C21.5208 13.0958 21.2833 13 21 13C20.7167 13 20.4792 13.0958 20.2875 13.2875C20.0958 13.4792 20 13.7167 20 14V15Z"
          fill="#1A202C"
        />
      </g>
    </svg>
  </Icon>
);
