import { getCourseList } from 'api/paywall/getCourseList';
import { Course } from 'components/paywall/pages/CourseList/typed';
import { useCustomToast } from 'hooks/useCustomToast';
import { useUserTenantId } from 'hooks/user/useUserTenantId';
import { useQuery } from 'react-query';
import { queryPaywallKey } from './queryPaywallKey';

/**
 * コースリスト取得
 * @param {string} wallId ウォールID
 * @return {Object} courseList コースリスト
 */
export const useCourseList = ({
  wallId,
  isIncludeParent = false
}: {
  wallId: string;
  isIncludeParent?: boolean
}): { courseList: Course[] } => {
  const toast = useCustomToast();
  const tenantId = useUserTenantId();

  const response = useQuery(
    queryPaywallKey.getCourseList({ tenantId, wallId, isIncludeParent }),
    () => getCourseList(tenantId, wallId, isIncludeParent),
    {
      onError: (err: unknown) => {
        if (err instanceof Error) {
          toast.closeAll();
          toast({
            title: err.message,
            status: 'error',
          });
        }
      },
    },
  );
  const { data } = response;

  return {
    courseList: data||[],
  };
};
