import { Box, Flex } from '@chakra-ui/react';
import { memo, ReactElement, VFC } from 'react';

type SideMenyLayoutProps = {
  // サイドメニューに表示するコンポーネント
  sidemenu: ReactElement;
  // メインエリアに表示するコンポーネント
  main: ReactElement;
};

export const SideMenyLayout: VFC<SideMenyLayoutProps> = memo(
  ({ sidemenu, main }) => (
    <Flex w="full" flex={1}>
      <Box
        py={2}
        w={72}
        flexBasis={72}
        flexShrink={0}
        borderRight="1px"
        borderColor="teal.100"
        data-testid="table-tree"
      >
        {sidemenu}
      </Box>
      <Box ml={4} w="full">
        {main}
      </Box>
    </Flex>
  ),
);
