import { ChangeEvent, VFC } from 'react';
import { Table, Tr, Tbody, Td, Switch } from '@chakra-ui/react';

type Props = {
  id: string;
  isDisablePublish: boolean;
  isAutoImport: boolean;
  onSwitchAutoImportChange: (event: ChangeEvent<HTMLInputElement>) => void;
};

export const PublishAutoImport: VFC<Props> = ({
  id,
  isDisablePublish,
  isAutoImport,
  onSwitchAutoImportChange,
}) => (
  <Table size="sm">
    <Tbody>
      <Tr>
        <Td w={36}>定期更新</Td>
        <Td>
          <Switch
            id="email-alerts"
            size="sm"
            onChange={onSwitchAutoImportChange}
            isChecked={isAutoImport}
            isDisabled={!id || isDisablePublish}
          />
        </Td>
      </Tr>
    </Tbody>
  </Table>
);
