import { createServiceAttribute } from 'admin/api/service/attribute/createServiceAttribute';
import { CustomError } from 'admin/error/CustomError';
import { useUserPoolChildId } from 'admin/hooks/useUserPoolChildId';
import { ServiceAttributeType } from 'admin/types/service/attribute';
import { logger } from 'api/logger';
import { useCustomToast } from 'hooks/useCustomToast';
import { useMutation, useQueryClient } from 'react-query';
import { errorToast, successToast } from 'utils/toast';
import {
  queryKeyNames,
  querySerivceAttributeKey,
} from './querySerivceAttributeKey';

export const useChangeServiceChildAttr = (
  target: ServiceAttributeType | null,
) => {
  const userPoolChildId = useUserPoolChildId();
  const toast = useCustomToast();
  const queryClient = useQueryClient();
  const { mutateAsync, isLoading } = useMutation({
    mutationFn: () =>
      createServiceAttribute({
        userPoolChildId,
        data: {
          userAttributeSetting: target?.id ?? '',
          attributeGroupHead: null,
          isUserAttr: false,
          isUserAttrRequired: false,
          isFormAttr: false,
        },
      }),
    onSuccess: () => {
      void queryClient.invalidateQueries(queryKeyNames.getSerivceAttributes);
      void queryClient.invalidateQueries(
        queryKeyNames.getSerivceAttributeGroups,
      );
      void queryClient.invalidateQueries(
        querySerivceAttributeKey.getSerivceAttributeDetail(
          userPoolChildId,
          target?.userPoolChildAttribute?.id ?? '',
        ),
      );
      toast({
        ...successToast,
        duration: 4000,
        title: 'テナント割当設定を更新しました',
        position: 'bottom',
        variant: 'solid',
      });
    },
    onError: async (error) => {
      if (error instanceof CustomError) {
        if (error.cause.errors) {
          await logger({
            loglevel: error.logLevel,
            data: JSON.stringify(error.cause),
            message: error.message,
          });
        }
      }
      toast({
        ...errorToast,
        duration: 4000,
        title: 'テナント割当設定の更新に失敗しました',
        position: 'bottom',
        variant: 'solid',
      });
    },
  });

  return { mutate: mutateAsync, isLoading };
};
