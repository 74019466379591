import { useQuery } from 'react-query';
import { WebSiteListType } from 'api/tenant/types';
import { useCustomToast } from 'hooks/useCustomToast';
import { getWebUrls } from 'api/tenant/getWebUrls';
import { queryTenantKey } from './queryTenantKey';

/**
 * テナント取得 hooks
 */
export const useWebUrls = ({
  tenantId,
}: {
  tenantId: string;
}): WebSiteListType['websiteList'] | undefined => {
  const toast = useCustomToast();
  const response = useQuery(
    queryTenantKey.getWebUrls({ tenantId }),
    () => getWebUrls(tenantId),
    {
      onError: (err: unknown) => {
        if (err instanceof Error) {
          toast.closeAll();
          toast({
            title: err.message,
            status: 'error',
          });
        }
      },
    },
  );
  const { data: list } = response;

  return list?.websiteList;
};
