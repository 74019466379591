import { Box, VStack } from '@chakra-ui/react';
import { SideMenuBottom, SideMenuMain } from 'components/common/molecules';
import { memo, VFC } from 'react';

export const SideMenu: VFC = memo(() => (
  <VStack alignItems="flex-start" h="100%" w="100%">
    <SideMenuMain />
    <Box h="100px" w="full">
      <SideMenuBottom />
    </Box>
  </VStack>
));
