import {
  Box,
  Button,
  ButtonGroup,
  Flex,
  Icon,
  Tab,
  TabList,
  TabPanel,
  TabPanels,
  Tabs,
  Text,
  useDisclosure,
} from '@chakra-ui/react';
import {
  Mail,
  MailEditorForm,
  MailEditorFormDefaultValues,
  SendMailForm,
  SendMailReplaceType,
} from 'api/mail/types';
import { ChangeLocationDialog, ErrorTextMsg } from 'components/common/atoms';
import { InputForm } from 'components/common/molecules';
import { MailMergeDeliveryHelp } from 'components/mail/atoms/MailMergeDeliveryHelp';
import { MailSubmitDialog } from 'components/mail/atoms/MailSubmitDialog';
import { TestMailPopover } from 'components/mail/atoms/TestMailPopover';
import { HtmlEditor } from 'components/mail/molecules/HtmlEditor';
import { TextEditor } from 'components/mail/molecules/TextEditor';
import { PERMISSION_DIRECTOR } from 'define';
import { useCreateMail } from 'hooks/mail/useCreateMail';
import { useEditMail } from 'hooks/mail/useEditMail';
import { useSendTestMail } from 'hooks/mail/useSendTestMail';
import { useBrowserBackControl } from 'hooks/useBrowserBackControl';
import { useCustomNavigate } from 'hooks/useCustomNavigate';
import { useCustomToast } from 'hooks/useCustomToast';
import { useEmailEditor } from 'hooks/useEmailEditor';
import { useUserInfo } from 'hooks/useUserInfo';
import { FC, memo, useCallback, useEffect, useRef, useState } from 'react';
import EmailEditor from 'react-email-editor';
import { FormProvider, useForm } from 'react-hook-form';
import { MdArrowBackIos } from 'react-icons/md';
import { errorToast } from 'utils/toast';

const defaultValues: MailEditorForm = MailEditorFormDefaultValues;

type Props = {
  tenantId: string;
  mailForm: Mail;
  formIdParams?: string;
};

export const MailFormInner: FC<Props> = memo(
  ({ formIdParams = '', tenantId, mailForm }: Props) => {
    const editorRef = useRef<EmailEditor>(null);
    const { isOpen, onOpen, onClose } = useDisclosure();
    const {
      isOpen: isTestMailPopupOpen,
      onOpen: onTestMailPopupOpen,
      onClose: onTestMailPopupClose,
    } = useDisclosure();
    const {
      isOpen: isWarnPopupOpen,
      onOpen: onWarnPopupOpen,
      onClose: onWarnPopupClose,
    } = useDisclosure();
    const toast = useCustomToast();
    const { exportHtml } = useEmailEditor({ editor: editorRef });
    const { isEditableTarget } = useUserInfo();
    const [isCreate] = useState(formIdParams === '');
    const [uneditableMessage, setUneditableMessage] = useState('');
    const [isHandleLoad, setIsHandleLoad] = useState(true);
    const [submitWarnTitle, setSubmitWarnTitle] = useState('');
    const [submitWarningMsgs, setSubmitWarningMsgs] = useState({
      filename: '',
      placeholders: '',
      shortagePlaceholders: '',
      excessPlaceholders: '',
    });
    const [nextPath, setNextPath] = useState('');
    const methods = useForm<MailEditorForm>({
      mode: 'onBlur',
      // エラーのある入力が再度バリデーションされるタイミングを変更(default: onChange)
      reValidateMode: 'onBlur',
      defaultValues,
    });
    const {
      setValue,
      getValues,
      setError,
      formState: { isDirty },
    } = methods;
    const formId = 'mailEditor-form';
    const {
      onSubmit: onCreateSubmit,
      isLoading: isCreateLoading,
      isWarning: createIsWarning,
      warningMsg: crateWarningMsg,
    } = useCreateMail({ setError, setNextPath });
    const {
      onSubmit: onEditSubmit,
      isLoading: isEditLoading,
      isWarning: editIsWarning,
      warningMsg: editWarningMsg,
    } = useEditMail({
      setError,
      setNextPath,
    });
    const { onSubmit: onTestMailSubmit, isLoading: isTestMailLoading } =
      useSendTestMail({ closeHandler: onTestMailPopupClose });
    // 編集中かどうかをstateで管理
    const [isEdited, setIsEdited] = useState(false);
    // ページ遷移カスタムnavigate
    const { navigate, resetEditState } = useCustomNavigate({
      isEdited,
      // isDirty,
      callback: onOpen,
    });
    // ブラウザバック制御
    useBrowserBackControl({ isEdited, onOpen });

    /**
     * 修正が加えられたかどうか
     */
    useEffect(() => {
      if (isDirty) setIsEdited(true);
    }, [isDirty]);

    /**
     * 入力中のメールデータ取得
     */
    const getMailData = useCallback(
      (): MailEditorForm => ({
        id: getValues('id'),
        tenant: tenantId,
        subject: getValues('subject'),
        editorHtml: getValues('editorHtml'),
        bodyHtml: getValues('bodyHtml'),
        editorText: getValues('editorText'),
        bodyText: getValues('bodyText'),
      }),
      [getValues, tenantId],
    );

    /**
     * テストメール送信
     */
    const onSendMailFormSubmit = useCallback(
      (
        address: string,
        fromAddress: string,
        replayAddress: string,
        testMailPlaceholder: SendMailReplaceType,
      ) => {
        const submitData: SendMailForm = getMailData();
        Object.assign(submitData, { fromAddress });
        Object.assign(submitData, { replayAddress });
        Object.assign(submitData, { toAddress: address });
        Object.assign(submitData, { testMailPlaceholder });
        if (submitData.bodyText === '' || submitData.bodyHtml === '') {
          toast({
            ...errorToast,
            title: 'テストメールの送信ができません。',
            description: '本文が未入力の為テストメールの送信を中止しました。',
          });

          return;
        }
        onTestMailSubmit(submitData);
      },
      [getMailData, toast, onTestMailSubmit],
    );

    /**
     * テストメール送信用HTML生成
     */
    const onSendMail = useCallback(
      async (
        address: string,
        fromAddress: string,
        replayAddress: string,
        testMailPlaceholder: SendMailReplaceType,
      ) => {
        await exportHtml()
          .then(({ design, html }) => {
            setValue('editorHtml', JSON.stringify(design.body));
            setValue('bodyHtml', html);
            onSendMailFormSubmit(
              address,
              fromAddress,
              replayAddress,
              testMailPlaceholder,
            );
          })
          .catch((err) => {
            if (err instanceof Error) {
              toast.closeAll();
              toast({
                ...errorToast,
                title: 'HTMLエディタエラー',
                description: err.message,
              });
            }
          });
      },
      [exportHtml, setValue, onSendMailFormSubmit, toast],
    );

    /**
     * フォーム送信
     */
    const onMailFormSubmit = useCallback(() => {
      const submitData: MailEditorForm = getMailData();
      resetEditState();
      // 新規作成
      if (isCreate) {
        onCreateSubmit(submitData);
      }
      // 修正
      if (!isCreate) {
        onEditSubmit(submitData);
      }
    }, [getMailData, onCreateSubmit, onEditSubmit, isCreate, resetEditState]);

    /**
     * 保存処理
     */
    const onSave = useCallback(async () => {
      // テキストメールの本文が未入力の時は保存しない
      if (getValues('bodyText') === '') {
        toast({
          ...errorToast,
          description: 'テキストメールの本文が未入力です。記入お願いします。',
          duration: 4000,
        });

        return;
      }
      await exportHtml()
        .then(({ design, html }) => {
          setValue('editorHtml', JSON.stringify(design.body));
          setValue('bodyHtml', html);
          onMailFormSubmit();
        })
        .catch((err) => {
          if (err instanceof Error) {
            toast.closeAll();
            toast({
              ...errorToast,
              title: 'HTMLエディタエラー',
              description: err.message,
            });
          }
        });
    }, [getValues, exportHtml, toast, setValue, onMailFormSubmit]);

    /**
     * 現在の日付取得
     */
    const createDate = () => {
      const now = new Date();

      return `${now.getFullYear()}/${
        now.getMonth() + 1
      }/${now.getDate()} ${now.getHours()}:${now.getMinutes()}:${now.getSeconds()}`;
    };

    const onBackPage = useCallback(() => {
      navigate('/mail/');
    }, [navigate]);

    const setEditable = useCallback(
      (uneditableMess: string) => {
        if (mailForm.id !== '') {
          setUneditableMessage(mailForm.uneditableMessate || uneditableMess);
        } else if (!isEditableTarget(PERMISSION_DIRECTOR) && mailForm.reserve) {
          setUneditableMessage(
            '配信予約済みのため、編集することができません。編集する場合は配信予約を解除してください。',
          );
        }
      },
      [
        setUneditableMessage,
        isEditableTarget,
        mailForm.id,
        mailForm.reserve,
        mailForm.uneditableMessate,
      ],
    );

    /**
     * 初期処理
     */
    useEffect(() => {
      let subject = `新規作成（${createDate()}）`;
      if (formIdParams) {
        subject = mailForm.subject || '';
        setValue('id', formIdParams);
      }

      setValue('subject', subject);
      setValue('editorHtml', mailForm.editorHtml || '');
      setValue('bodyHtml', mailForm.bodyHtml || '');
      setValue('editorText', mailForm.editorText || '');
      setValue('bodyText', mailForm.bodyText || '');
    }, [formIdParams, mailForm, setValue]);

    useEffect(() => {
      setEditable('');
    }, [setEditable]);

    useEffect(() => {
      if (editIsWarning) {
        const submitTitle =
          editWarningMsg.filename === ''
            ? 'ファイルに以下を指定してください'
            : 'ファイルを修正してください';
        setSubmitWarnTitle(submitTitle);
        setSubmitWarningMsgs(editWarningMsg);
        onWarnPopupOpen();
      } else if (createIsWarning) {
        setSubmitWarnTitle('ファイルに以下を指定してください');
        setSubmitWarningMsgs(crateWarningMsg);
        onWarnPopupOpen();
      }
    }, [
      editIsWarning,
      editWarningMsg,
      createIsWarning,
      crateWarningMsg,
      onWarnPopupOpen,
    ]);

    return (
      <Box w="100%">
        <FormProvider {...methods}>
          <form id={formId}>
            <Box
              w="100%"
              mb={2}
              display="flex"
              justifyContent="space-between"
              alignItems="center"
              gridGap={2}
            >
              <Box>
                <Button
                  variant="ghost"
                  _focus={{ focus: 'none' }}
                  onClick={() => onBackPage()}
                  color="gray.500"
                >
                  <Icon as={MdArrowBackIos} />
                  一覧へ戻る
                </Button>
              </Box>
              <Box
                w="100%"
                minW="150px"
                display="flex"
                alignItems="center"
                gridGap={1}
              >
                <InputForm<MailEditorForm>
                  name="subject"
                  type="text"
                  labelElement={
                    <Text w="32px" fontWeight="bold" color="#999" mb={-2}>
                      件名
                    </Text>
                  }
                  isDisabled={isHandleLoad || uneditableMessage !== ''}
                  horizontal
                />
              </Box>
              <ButtonGroup variant="outline" spacing="2">
                {/* <TagPopover /> */}
                <Box position="relative" ml={4}>
                  <TestMailPopover
                    onSendMail={onSendMail}
                    isLoading={isHandleLoad || isTestMailLoading}
                    isOpen={isTestMailPopupOpen}
                    onOpen={onTestMailPopupOpen}
                    onClose={onTestMailPopupClose}
                  />
                </Box>
                <Button
                  ml={2}
                  variant="primary"
                  onClick={onSave}
                  isLoading={isHandleLoad || isCreateLoading || isEditLoading}
                  isDisabled={
                    uneditableMessage !== '' ||
                    isHandleLoad ||
                    isCreateLoading ||
                    isEditLoading
                  }
                >
                  保存
                </Button>
              </ButtonGroup>
            </Box>
            <Flex alignItems="center">
              <Text as="span">差し込みメール配信の設定について</Text>
              <MailMergeDeliveryHelp />
            </Flex>
            <Tabs w="100%">
              <TabList>
                <Tab isDisabled={isHandleLoad} _focus={{ focus: 'none' }}>
                  HTMLメールエディター
                </Tab>
                <Tab isDisabled={isHandleLoad} _focus={{ focus: 'none' }}>
                  テキストメールエディタ
                </Tab>
              </TabList>
              <TabPanels>
                <TabPanel p={0}>
                  {uneditableMessage !== '' && (
                    <ErrorTextMsg msg={uneditableMessage} />
                  )}
                  <HtmlEditor
                    editor={editorRef}
                    setIsHandleLoad={setIsHandleLoad}
                    setEditable={setEditable}
                    isEditable={uneditableMessage !== ''}
                    setIsEdited={setIsEdited}
                    mailForm={mailForm}
                  />
                </TabPanel>
                <TabPanel p={0}>
                  {uneditableMessage !== '' && (
                    <ErrorTextMsg msg={uneditableMessage} />
                  )}
                  <TextEditor isEditable={uneditableMessage !== ''} />
                </TabPanel>
              </TabPanels>
            </Tabs>
          </form>
        </FormProvider>
        <ChangeLocationDialog
          isOpen={isOpen}
          onClose={onClose}
          setIsEdited={setIsEdited}
        />
        <MailSubmitDialog
          title={submitWarnTitle}
          warningMsgs={submitWarningMsgs}
          isOpen={isWarnPopupOpen}
          next={nextPath}
          onClose={onWarnPopupClose}
        />
      </Box>
    );
  },
);
