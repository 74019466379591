import { useQuery } from 'react-query';
import { TenantApiKey } from 'api/tenant/types';
import { useCustomToast } from 'hooks/useCustomToast';
import { getApiKey } from 'api/tenant/getApiKey';
import { queryTenantKey } from './queryTenantKey';

/**
 * APIキー取得 hooks
 */
export const useApiKey = ({
  tenantId,
}: {
  tenantId: string;
}): TenantApiKey | undefined => {
  const toast = useCustomToast();
  const response = useQuery(queryTenantKey.apiKey, () => getApiKey(tenantId), {
    onError: (err: unknown) => {
      if (err instanceof Error) {
        toast.closeAll();
        toast({
          title: err.message,
          status: 'error',
        });
      }
    },
  });
  const { data: tenantApiKey } = response;

  return tenantApiKey;
};
