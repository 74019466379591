import { BaseError } from './BaseError';

// BaseErrorを継承して新しいErrorを作成する
export class CustomError extends BaseError {
  public statusCode: number;

  constructor(e?: string, private code: number = -1) {
    super(e);
    this.statusCode = code;
  }
}
