import { Select, Table, Tbody, Td, Text, Tr, VStack } from '@chakra-ui/react';
import { RadioForm } from 'components/common/molecules';
import { CondElement } from 'components/enquete/atoms/CondElement';
import { QuestionTitleElement } from 'components/enquete/atoms/QuestionTitleElement';
import { RichTextElement } from 'components/enquete/atoms/RichTextElement';
import { Toggle } from 'components/enquete/atoms/Toggle';
import {
  Dispatch, FC, SetStateAction,
  useEffect, useState
} from 'react';
import { FieldValues, Path, PathValue, UnpackNestedValue, useFormContext } from 'react-hook-form';

type EnqueteFormType<T> = {
  baseName: Path<T>;
  index: number;
  setIsEdited: Dispatch<SetStateAction<boolean>>;
};

export const UploadInfoElement = <T extends FieldValues>({ baseName, index, setIsEdited }: EnqueteFormType<T>): ReturnType<FC> => {
  const { register, getValues, setValue } =
    useFormContext<T>();
  const [toggle, setToggle] = useState(false);

  const fileSizeSelect = [
    { id: 5, name: '5MB' },
    { id: 10, name: '10MB' },
    { id: 50, name: '50MB' },
    { id: 100, name: '100MB' },
    { id: 200, name: '200MB' },
  ];

  useEffect(() => {
    setValue(`${baseName}.${index}.fileUploadFormat` as Path<T>, 0 as UnpackNestedValue<PathValue<T, Path<T>>>);
  }, [setValue, index]);

  return (
    <>
      <VStack w="100%" display={toggle ? 'none' : 'block'}>
        <Table>
          <Tbody>
            <Tr>
              <QuestionTitleElement<T> baseName={baseName} width={140} colspan={2} index={index} />
            </Tr>
            <Tr>
              <Td w={140}>補足説明など</Td>
              <Td colSpan={2}>
                <RichTextElement<T>
                  baseName={baseName}
                  placeholder=""
                  index={index}
                  position="descriptionTop"
                  value={
                    getValues(`${baseName}.${index}.descriptionTop` as Path<T>) || ''
                  }
                  setIsEdited={setIsEdited}
                />
              </Td>
            </Tr>
            <Tr>
              <Td w={40}>ファイル数</Td>
              <Td>
                <RadioForm<T>
                  name={`${baseName}.${index}.fileUploadNum` as Path<T>}
                  attr={{
                    required: '',
                  }}
                  isDisabled={getValues('uneditableState' as Path<T>)}
                  style={{
                    display: 'flex',
                    gap: '1rem',
                  }}
                  radioList={[
                    {
                      id: 1,
                      value: 1,
                      element: <Text>一つ</Text>,
                    },
                    {
                      id: 2,
                      value: 2,
                      element: <Text>複数</Text>,
                    },
                  ]}
                  contains
                />
              </Td>
            </Tr>
            <Tr>
              <Td w={40}>ファイルサイズ</Td>
              <Td>
                <Select
                  w={200}
                  {...register(`${baseName}.${index}.fileUploadSize` as Path<T>)}
                  style={{
                    backgroundColor: '#f9f9f9',
                    border: '1px solid #ccc',
                    borderRadius: '5px',
                    padding: '10px 12px',
                  }}
                  isDisabled={getValues('uneditableState' as Path<T>)}
                >
                  {fileSizeSelect.map((item) => (
                    <option key={item.id} value={item.id}>
                      {item.name}
                    </option>
                  ))}
                </Select>
              </Td>
            </Tr>
            <Tr>
              <Td w={140}>補足説明など</Td>
              <Td>
                <RichTextElement<T>
                  baseName={baseName}
                  placeholder=""
                  index={index}
                  position="descriptionBottom"
                  value={
                    getValues(`${baseName}.${index}.descriptionBottom` as Path<T>) ||
                    ''
                  }
                  setIsEdited={setIsEdited}
                />
              </Td>
            </Tr>
          </Tbody>
        </Table>
        <CondElement<T> baseName={baseName} index={index} />
      </VStack>
      <Toggle toggle={toggle} setToggle={setToggle} />
    </>
  );
};
