import { InvitationUser } from 'api/user/types';
import { InputTextForm } from 'components/common/atoms';
import { VFC } from 'react';
import { RegisterOptions } from 'react-hook-form';

/**
 * InputNameProps
 */
type InputNameProps = {
  attr: RegisterOptions;
};

export const InputName: VFC<InputNameProps> = ({ attr }: InputNameProps) => (
  <InputTextForm<InvitationUser>
    name="name"
    type="text"
    value=""
    attr={attr}
  />
);
