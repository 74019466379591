import { Textarea } from '@chakra-ui/react';
import { FC } from 'react';
import { FieldValues, Path, useFormContext } from 'react-hook-form';

type Props<T> = {
  baseName: Path<T>;
  index: number | -1;
};

export const TextareaElement = <T extends FieldValues>({ baseName, index }: Props<T>): ReturnType<FC> => {
  const { getValues, register } = useFormContext<T>();

  return (
    <Textarea
      {...register(`${baseName}.${index}.items` as Path<T>)}
      placeholder="選択肢を入力してください"
      backgroundColor="#f9f9f9"
      borderColor="#ccc"
      rows={8}
      isDisabled={getValues('uneditableState' as Path<T>)}
    />
  );
};
