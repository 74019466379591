export const queryMailKey = {
  getMailList: ({
    tenantId,
    ordering,
    page,
    openDrawer,
    perPage,
    status,
    deliveryTo,
    segment,
    reserve,
  }: {
    tenantId: string;
    ordering: string;
    page: string;
    openDrawer: string;
    perPage: string;
    status: string;
    deliveryTo: string;
    segment: string;
    reserve: string;
  }) => [
    'mail/list',
    tenantId,
    ordering,
    page,
    openDrawer,
    perPage,
    status,
    deliveryTo,
    segment,
    reserve,
  ],
  getMailReportList: ({
    tenantId,
    mailId,
  }: {
    tenantId: string;
    mailId: string;
  }) => ['mail/report/mailId', tenantId, mailId],
  getMailId: ({ tenantId, mailId }: { tenantId: string; mailId: string }) => [
    'mail/getMailId',
    tenantId,
    mailId,
  ],
  getMailSubscribeList: ['mail/SubscribeList'],
  getMailTargetCnt: ['mail/target'],
  getMailMagazineList: ({ tenantId, type }: { tenantId: string; type: 'all' | '' }) => [
    'mail/getMailMagazineList',
    tenantId,
    type,
  ],
  getMailMagazineId: ({ id, tenantId }: { id: string; tenantId: string }) => [
    'mail/getMailMagazineId',
    id,
    tenantId,
  ],
} as const;
