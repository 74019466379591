import { Options } from 'ky';
import { request } from 'api/request';
import { logger } from 'api/logger';
import { CustomError } from 'api/error/CustomError';
import { User, isUsers } from './types';
import { messages } from './messages';

// テナントユーザー一覧取得API
export const getTenantUserList = async (
  tenantId: string,
  options?: Options,
): Promise<User[]> => {
  const path = `user/tenant/${tenantId}/`;

  const response = await request({
    path,
    method: 'get',
    options,
  });

  const responseData = (await response.data) as unknown[];

  if (response.hasError) {
    throw new CustomError(messages.getTenantUserList.customer.error);
  }

  if (!isUsers(responseData)) {
    await logger({
      loglevel:
        response.status === 404 || response.status === 450
          ? 'warning'
          : 'error',
      data: responseData,
      message: messages.getTenantUserList.system.typeError,
    });

    throw new CustomError(messages.getTenantUserList.customer.failed);
  }

  return responseData;
};
