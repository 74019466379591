import {
  Box,
  Button,
  Divider,
  DrawerBody,
  DrawerFooter,
  Flex,
  FormControl,
  FormErrorMessage,
  Heading,
  Text,
  VStack,
} from '@chakra-ui/react';
import { RhfInput } from 'admin/components/form/RhfInput';
import { RequiredIcon } from 'admin/components/ui/icon';
import { LoadingLayer } from 'admin/components/ui/loadingLayer';
import { Colors, ColorSet } from 'admin/define/colors';
import { TokenFormType } from 'admin/types/userPool/auth/form';
import { FC, memo } from 'react';
import { useFormContext, useFormState } from 'react-hook-form';
import { toErrMsgList } from 'utils/form';

type TokenEditFormComponentProps = {
  formId: string;
  isLoading: boolean;
  onSubmit: (value: TokenFormType) => void;
  onClose: () => void;
};
export const TokenEditFormComponent: FC<TokenEditFormComponentProps> = memo(
  ({ formId, isLoading, onSubmit, onClose }) => {
    const { handleSubmit } = useFormContext<TokenFormType>();
    const { errors } = useFormState<TokenFormType>();

    return (
      <>
        {isLoading && <LoadingLayer />}
        <DrawerBody pt="24px" pb="16px" color={Colors.GRAY_03}>
          <form onSubmit={handleSubmit(onSubmit)} id={formId}>
            <VStack spacing={6}>
              <Box w="100%">
                <Heading as="h3" size="sm" mb={2} fontSize="14px">
                  JWT
                </Heading>
                <Heading as="h3" size="sm" my={2}>
                  <Flex alignItems="center" style={{ gap: '8px' }}>
                    <Text as="span" fontSize="14px">
                      有効期限
                    </Text>
                    <RequiredIcon />
                  </Flex>
                </Heading>
                <FormControl
                  isInvalid={
                    Boolean(errors.jwtDuration?.message) ||
                    toErrMsgList(errors, 'jwtDuration').length > 0
                  }
                >
                  <Flex gridGap={2} alignItems="center">
                    <RhfInput<TokenFormType>
                      name="jwtDuration"
                      bgColor={Colors.WHITE}
                      width="216px"
                    />
                    <Text as="span" fontSize="16px">
                      秒
                    </Text>
                  </Flex>

                  {toErrMsgList(errors, 'jwtDuration').map((err) => (
                    <FormErrorMessage key={`jwtDuration${err}`}>
                      {err}
                    </FormErrorMessage>
                  ))}
                </FormControl>
                <Heading as="h3" size="sm" my={2}>
                  <Flex alignItems="center" style={{ gap: '8px' }}>
                    <Text as="span" fontSize="14px">
                      issuer
                    </Text>
                    <RequiredIcon />
                  </Flex>
                </Heading>
                <Box>
                  <FormControl
                    isInvalid={
                      Boolean(errors.jwtIssuer?.message) ||
                      toErrMsgList(errors, 'jwtIssuer').length > 0
                    }
                  >
                    <RhfInput<TokenFormType>
                      name="jwtIssuer"
                      bgColor={Colors.WHITE}
                    />

                    {toErrMsgList(errors, 'jwtIssuer').map((err) => (
                      <FormErrorMessage key={`jwtIssuer${err}`}>
                        {err}
                      </FormErrorMessage>
                    ))}
                  </FormControl>
                </Box>
              </Box>
              <Divider />
              <Box w="100%">
                <Heading as="h3" size="sm" mb={2} fontSize="14px">
                  Access Token
                </Heading>
                <Heading as="h3" size="sm" my={2}>
                  <Flex alignItems="center" style={{ gap: '8px' }}>
                    <Text as="span" fontSize="14px">
                      有効期限
                    </Text>
                    <RequiredIcon />
                  </Flex>
                </Heading>
                <FormControl
                  isInvalid={
                    Boolean(errors.accessTokenDuration?.message) ||
                    toErrMsgList(errors, 'accessTokenDuration').length > 0
                  }
                >
                  <Flex gridGap={2} alignItems="center">
                    <RhfInput<TokenFormType>
                      name="accessTokenDuration"
                      bgColor={Colors.WHITE}
                      width="216px"
                    />
                    <Text as="span" fontSize="16px">
                      秒
                    </Text>
                  </Flex>

                  {toErrMsgList(errors, 'accessTokenDuration').map((err) => (
                    <FormErrorMessage key={`accessTokenDuration${err}`}>
                      {err}
                    </FormErrorMessage>
                  ))}
                </FormControl>
              </Box>
              <Divider />
              <Box w="100%">
                <Heading as="h3" size="sm" mb={2} fontSize="14px">
                  Refresh Token
                </Heading>
                <Heading as="h3" size="sm" my={2}>
                  <Flex alignItems="center" style={{ gap: '8px' }}>
                    <Text as="span" fontSize="14px">
                      有効期限
                    </Text>
                    <RequiredIcon />
                  </Flex>
                </Heading>
                <FormControl
                  isInvalid={
                    Boolean(errors.refreshTokenDuration?.message) ||
                    toErrMsgList(errors, 'refreshTokenDuration').length > 0
                  }
                >
                  <Flex gridGap={2} alignItems="center">
                    <RhfInput<TokenFormType>
                      name="refreshTokenDuration"
                      bgColor={Colors.WHITE}
                      width="216px"
                    />
                    <Text as="span" fontSize="16px">
                      秒
                    </Text>
                  </Flex>

                  {toErrMsgList(errors, 'refreshTokenDuration').map((err) => (
                    <FormErrorMessage key={`refreshTokenDuration${err}`}>
                      {err}
                    </FormErrorMessage>
                  ))}
                </FormControl>
              </Box>
            </VStack>
          </form>
        </DrawerBody>
        <DrawerFooter borderWidth="1px">
          <Button
            variant="outline"
            mr={4}
            onClick={onClose}
            p="8px 16px"
            w="104px"
            h="40px"
            fontWeight={700}
            {...ColorSet.Default}
          >
            キャンセル
          </Button>
          <Button
            type="submit"
            form={formId}
            p="8px 16px"
            w="104px"
            h="40px"
            fontWeight={700}
            colorScheme="primary"
          >
            保存
          </Button>
        </DrawerFooter>
      </>
    );
  },
);
