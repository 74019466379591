import { zodResolver } from '@hookform/resolvers/zod';
import { withSuspenseAndErrorBoundary } from 'admin/components/Error/WithErrorBoundary';
import { AttributeEditFormComponent } from 'admin/components/service/attribute/attributeForm/attriburteEditForm/AttributeEditFormComponent';
import { useUpdateServiceAttribute } from 'admin/hooks/service/attribute/useUpdateServiceAttribute';
import { serviceAttributeFormSchema } from 'admin/schema/service/attribute';
import { ServiceAttributeChildType } from 'admin/types/service/attribute';
import { ServiceAttributeFormType } from 'admin/types/service/attribute/form';
import {
  AlertBar,
  ErrorContents,
  LoadingSpinner,
} from 'components/common/atoms';
import { FC, memo, useEffect, useState } from 'react';
import { FormProvider, SubmitHandler, useForm } from 'react-hook-form';

type Props = {
  attribute: ServiceAttributeChildType;
  onClose: () => void;
};

const attributeEditForm: FC<Props> = memo(({ attribute, onClose }: Props) => {
  const [globalErrors, setGlobalErrors] = useState<string[]>([]);
  const formId = `serviceAttributeForm_${attribute.id}`;
  const methods = useForm<ServiceAttributeFormType>({
    defaultValues: {
      userAttributeSetting: '',
      attributeGroupHead: null,
      isFormAttr: false,
      isUserAttr: false,
      isUserAttrRequired: false,
    },
    resolver: zodResolver(serviceAttributeFormSchema),
  });

  const { setValue, setError } = methods;
  const { mutate, isLoading } = useUpdateServiceAttribute(
    attribute.id,
    setError,
    setGlobalErrors,
  );

  useEffect(() => {
    if (attribute) {
      setValue('userAttributeSetting', attribute.userAttributeSetting.id);
      setValue('isFormAttr', attribute?.isFormAttr);
      setValue('isUserAttr', attribute?.isUserAttr);
      setValue('isUserAttrRequired', attribute?.isUserAttrRequired);
    }
  }, [setValue, attribute]);

  const onSubmit: SubmitHandler<ServiceAttributeFormType> = async (
    data: ServiceAttributeFormType,
  ) => {
    await mutate(data);
    onClose();
  };

  return (
    <FormProvider {...methods}>
      {globalErrors.map((err, idx) => (
        <AlertBar
          key={`global-err-idx${String(idx)}`}
          message={err}
          status="error"
          mb={4}
        />
      ))}
      <AttributeEditFormComponent
        attribute={attribute}
        formId={formId}
        isLoading={isLoading}
        onClose={onClose}
        onSubmit={onSubmit}
      />
    </FormProvider>
  );
});

export const AttributeEditForm = withSuspenseAndErrorBoundary(
  attributeEditForm,
  {
    ErrorComponent: <ErrorContents name="組織レベル属性詳細" />,
    LoadingComponent: <LoadingSpinner />,
  },
);
