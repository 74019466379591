import { useApiOrderDetail } from 'api/paywall/useApi';
import { defaultWallOrderDetail } from 'components/paywall/pages/OrderDetail/initial';
import { OrderDetailInnerCustomer } from 'components/paywall/pages/OrderDetail/OrderDetailInner/OrderDetailInnerCustomer';
import { OrderDetailInnerInfo } from 'components/paywall/pages/OrderDetail/OrderDetailInner/OrderDetailInnerInfo';
import { OrderDetailInnerMemo } from 'components/paywall/pages/OrderDetail/OrderDetailInner/OrderDetailInnerMemo';
import { OrderDetailInnerMemos } from 'components/paywall/pages/OrderDetail/OrderDetailInner/OrderDetailInnerMemos';
import {
  ApiOrderDetail,
  OrderDetailResult
} from 'components/paywall/pages/OrderList/typed';
import { FC, memo, useEffect, useState } from 'react';

type Props = {
  orderId: string;
};

export const OrderDetailInner: FC<Props> = memo(({ orderId }) => {
  const [orderResultData, setOrderResultData] = useState<OrderDetailResult>();
  const { data, isLoading, isFetching } = useApiOrderDetail(orderId);

  useEffect(() => {
    if (isLoading || isFetching) return;
    if (!data) return;
    const apiReturn = data as unknown as ApiOrderDetail;
    if (!apiReturn.data) return;
    setOrderResultData(apiReturn.data as unknown as OrderDetailResult);
  }, [data, isFetching, isLoading]);

  return (
    <>
      {/* 決済詳細情報 */}
      <OrderDetailInnerInfo orderDetail={orderResultData||defaultWallOrderDetail} />
      {/* 顧客情報 */}
      <OrderDetailInnerCustomer customerDetail={orderResultData}/>
      {/* メモ */}
      <OrderDetailInnerMemos memos={orderResultData?.wallOrder.memos||[]}/>
      {/* 編集メモ入力欄 */}
      <OrderDetailInnerMemo orderId={orderResultData?.id||''} customerUid={orderResultData?.customerUid||''} />
    </>
  );
});
