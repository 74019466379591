import { useQuery } from 'react-query';
import { useSearchParams } from 'react-router-dom';
import { Page } from 'api/common/types';
import { Skin, SkinInfo } from 'api/enquete/types';
import { getSkinList } from 'api/enquete/getSkinList';
import { useCustomToast } from 'hooks/useCustomToast';
import { useUserTenantId } from 'hooks/user/useUserTenantId';
import { queryEnqueteKey } from './queryEnqueteKey';

type Props = {
  perPage?: string;
};

/**
 * デザインスキン一覧取得 hooks
 */
export const useSkinList = ({
  perPage = '',
}: Props): {
  page: Page;
  skinList: Skin[];
} => {
  const toast = useCustomToast();
  const tenantId = useUserTenantId();
  const [searchParams] = useSearchParams();

  const queryPage = searchParams.get('page') || '';
  const queryPerPage = perPage;

  const response = useQuery(
    queryEnqueteKey.getSkinList({
      tenantId,
      page: queryPage,
    }),
    () =>
      getSkinList({
        tenantId,
        page: queryPage,
        perPage: queryPerPage,
      }),
    {
      onError: (err: unknown) => {
        if (err instanceof Error) {
          toast.closeAll();
          toast({
            title: err.message,
            status: 'error',
          });
        }
      },
    },
  );

  const result = response.data as SkinInfo;
  const page: Page = {
    count: result.count,
    countFrom: result.countFrom,
    countTo: result.countTo,
    currentPage: result.currentPage,
    next: result.next,
    pageCount: result.pageCount,
    perPage: result.perPage,
    previous: result.previous,
  };
  const { results } = result;

  return {
    page,
    skinList: results,
  };
};
