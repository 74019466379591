import { withSuspenseAndErrorBoundary } from 'admin/components/Error/WithErrorBoundary';
import {
  UserAttrFormComponent,
  USER_ATTR_FROM_TYPE,
} from 'admin/components/service/attribute/attributeForm/userAttrForm/UserAttrFormComponent';
import { LoadingLayer } from 'admin/components/ui/loadingLayer';
import { useChangeServiceUserAttr } from 'admin/hooks/service/attribute/useChangeServiceUserAttr';
import {
  ServiceAttributeType,
  UserPoolChildAttributeType,
} from 'admin/types/service/attribute';
import { FC, memo, useCallback } from 'react';

export type Props = {
  target: ServiceAttributeType | null;
  id: UserPoolChildAttributeType['id'];
  onClose: () => void;
};

export const userAttrForm: FC<Props> = memo(({ onClose, target }) => {
  const { mutate, isLoading } = useChangeServiceUserAttr(target);

  const onSubmit = useCallback(async () => {
    await mutate();
    onClose();
  }, [mutate, onClose]);

  return (
    <>
      {isLoading && <LoadingLayer />}
      <UserAttrFormComponent
        userAttrFormType={USER_ATTR_FROM_TYPE}
        isLoading={isLoading}
        onClose={onClose}
        onSubmit={onSubmit}
        target={target}
      />
    </>
  );
});

export const UserAttrForm = withSuspenseAndErrorBoundary(userAttrForm);
