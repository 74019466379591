import { VFC } from 'react';
import {
  Accordion,
  AccordionItem,
  AccordionButton,
  AccordionPanel,
  AccordionIcon,
  Text,
  Flex,
} from '@chakra-ui/react';
import { AiOutlineInfoCircle } from 'react-icons/ai';

export const SegmentConnectInfo: VFC = () => (
  <Accordion allowMultiple>
    <AccordionItem>
      <h2>
        <AccordionButton bg="gray.50" fontSize="sm">
          <Flex alignItems="center">
            <AiOutlineInfoCircle />
            <Text ml={4}>
              ユーザーIDをテーブルに格納しセグメントで利用する方法
            </Text>
          </Flex>
          <AccordionIcon />
        </AccordionButton>
      </h2>
      <AccordionPanel pt={4} pb={4} fontSize="xs">
        セグメントで利用するためにはカラム名に{' '}
        <Text as="mark" bgColor="yellow.100" p={1}>
          uid
        </Text>
        を設定し、
        <Text as="span" fontWeight="bold">
          そのカラムにユーザーIDを格納してください。
        </Text>
      </AccordionPanel>
    </AccordionItem>
  </Accordion>
);
