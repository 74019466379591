import { Box, Text } from '@chakra-ui/react';
import { IdPoolConsumerUserPoolsCreatedCountComponent } from 'admin/components/idPoolConsumer/idPoolConsumerUserPoolsCreatedCount/IdPoolConsumerUserPoolsCreatedCountComponent';
import { Colors } from 'admin/define/colors';
import { FC, memo } from 'react';

export const IdPoolConsumerUserPoolsCreatedCount: FC = memo(() => (
  <Box
    border="1px solid"
    borderColor={Colors.GRAY_30}
    borderRadius="8px"
    bgColor={Colors.WHITE}
    mt="8px"
    p="8px"
  >
    <Box display="contents" textAlign="center">
      <Text fontWeight="700">ユーザープール作成数</Text>
      <IdPoolConsumerUserPoolsCreatedCountComponent />
    </Box>
  </Box>
));
