import { useMemo } from 'react';

export const filterFieldUiTypeWithFieldMasterType = [
  'radio',
  'checkbox',
  'selectbox',
];

export const useIsShowMasterType = (selectedFieldUiType: string | number) =>
  useMemo<boolean>(() => {
    if (
      filterFieldUiTypeWithFieldMasterType.includes(String(selectedFieldUiType))
    ) {
      return true;
    }

    return false;
  }, [selectedFieldUiType]);
