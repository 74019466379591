import {
  AlertDialogCloseButton,
  AlertDialogContent,
  AlertDialogHeader,
  AlertDialogOverlay,
  Text
} from '@chakra-ui/react';
import { zodResolver } from '@hookform/resolvers/zod';
import { withSuspenseAndErrorBoundary } from 'admin/components/Error/WithErrorBoundary';
import {
  IdPoolConsumerConfirmForm,
  IdPoolConsumerCreateForm
} from 'admin/components/idPoolConsumer/idPoolConsumerForm';
import { createIdPoolConsumerFormSchema } from 'admin/schema/idPoolConsumer';
import { CreateIdPoolConsumerFormType } from 'admin/types/userPool/form';
import { ErrorContents, LoadingSpinner } from 'components/common/atoms';
import { FC, memo, useCallback, useState } from 'react';
import { FormProvider, useForm } from 'react-hook-form';

type IdPoolConsumerCreateDialogProps = {
  onClose: () => void;
};

// ユーザープール新規作成時モーダル
export const idPoolConsumerCreateDialog: FC<IdPoolConsumerCreateDialogProps> =
  memo(({ onClose }: IdPoolConsumerCreateDialogProps) => {
    const formId = 'idPoolConsumerCreate';
    const [isConfirmed, setIsConfirmed] = useState(false);
    const onConfirmed = useCallback(() => {
      setIsConfirmed(true);
    }, [setIsConfirmed]);

    const onBack = useCallback(() => {
      setIsConfirmed(false);
    }, [setIsConfirmed]);

    const methods = useForm<CreateIdPoolConsumerFormType>({
      defaultValues: {
        name: '',
        fieldName: '',
        authenticationType: '',
        isSelfRegistration: false,
      },
      resolver: zodResolver(createIdPoolConsumerFormSchema),
    });

    const { setError, getValues } = methods;

    return (
      <FormProvider {...methods}>
        <AlertDialogOverlay>
          <AlertDialogContent>
            <AlertDialogHeader>
              <Text fontSize="18px">
                {isConfirmed
                  ? 'ユーザープール作成の確認'
                  : 'ユーザープール作成'}
              </Text>
            </AlertDialogHeader>
            {/* 入力用 */}
            {!isConfirmed && (
              <IdPoolConsumerCreateForm
                formId={formId}
                onClose={onClose}
                onConfirmed={onConfirmed}
              />
            )}
            {/* 確認用 */}
            {isConfirmed && (
              <IdPoolConsumerConfirmForm
                idPoolConsumer={getValues()}
                setError={setError}
                onBack={onBack}
                onClose={onClose}
              />
            )}
            <AlertDialogCloseButton />
          </AlertDialogContent>
        </AlertDialogOverlay>
      </FormProvider>
    );
  });

export const IdPoolConsumerCreateDialog = withSuspenseAndErrorBoundary(
  idPoolConsumerCreateDialog,
  {
    ErrorComponent: <ErrorContents name="ユーザープール新規作成ダイアログ" />,
    LoadingComponent: <LoadingSpinner />,
  },
);
