import { useQuery } from 'react-query';
import { SegmentDetail } from 'api/segment/types';
import { getSegmentId } from 'api/segment/getSegmentId';
import { useCustomToast } from 'hooks/useCustomToast';
import { useUserTenantId } from 'hooks/user/useUserTenantId';
import { querySegmentKey } from './querySegmentKey';

/**
 * セグメント詳細取得 hooks
 */
export const useSegmentId = ({
  segmentId,
}: {
  segmentId: string;
}): SegmentDetail | undefined => {
  const toast = useCustomToast();
  const tenantId = useUserTenantId();

  const response = useQuery(
    querySegmentKey.getSegmentId({ tenantId, segmentId }),
    () => getSegmentId(tenantId, segmentId),
    {
      onError: (err: unknown) => {
        if (err instanceof Error) {
          toast.closeAll();
          toast({
            title: err.message,
            status: 'error',
          });
        }
      },
    },
  );

  const { data: segmentDetail } = response;

  return segmentDetail;
};
