import { VFC, memo } from 'react';
import { useFormContext } from 'react-hook-form';
import { Table, Tbody, Tr, Td } from '@chakra-ui/react';
import { EnqueteSurveyForm } from 'api/enquete/types';
import { InputForm } from 'components/common/molecules';

type Props = {
  width: number;
  colspan: number;
};

export const CloseBtnElement: VFC<Props> = memo(({ width, colspan }: Props) => {
  const { getValues } = useFormContext<EnqueteSurveyForm>();

  return (
    <Table>
      <Tbody>
        <Tr>
          <Td w={width}>ボタン表示名</Td>
          <Td colSpan={colspan} paddingBottom={6}>
            <InputForm<EnqueteSurveyForm>
              name="thanksButtonText"
              type="text"
              placeholder="ボタン表示名を入力してください"
              backgroundColor="#f9f9f9"
              borderColor="#ccc"
              isDisabled={getValues('uneditableState')}
            />
          </Td>
        </Tr>
        <Tr>
          <Td w={width}>遷移先URL</Td>
          <Td colSpan={colspan} paddingBottom={6}>
            <InputForm<EnqueteSurveyForm>
              name="thanksButtonUrl"
              type="text"
              placeholder="遷移先URLを入力してください"
              backgroundColor="#f9f9f9"
              borderColor="#ccc"
              isDisabled={getValues('uneditableState')}
            />
          </Td>
        </Tr>
      </Tbody>
    </Table>
  );
});
