import { getMailSettingCommon } from 'api/paywall/getMailSettingCommon';
import { statusTitleObjectSort } from 'components/paywall/pages/MailSetting/constants';
import { MailSettingStatusesDefaultValue } from 'components/paywall/pages/MailSetting/initial';
import {
  MailSettingCommon,
  MailSettingPersonalData,
  MailSettingStatuses,
} from 'components/paywall/pages/MailSetting/typed';
import { useCustomToast } from 'hooks/useCustomToast';
import { useUserTenantId } from 'hooks/user/useUserTenantId';
import { useQuery } from 'react-query';
import { queryPaywallKey } from './queryPaywallKey';

/**
 * ペイウォールメール共通設定取得
 * @return {MailSettingCommon | undefined} ペイウォールメール共通設定
 */
export const useMailSettingCommon = (): {
  commonData: MailSettingCommon;
  personalDataList: MailSettingPersonalData[];
} => {
  const toast = useCustomToast();
  const tenantId = useUserTenantId();
  const response = useQuery(
    queryPaywallKey.getMailSettingCommon({ tenantId }),
    () => getMailSettingCommon(tenantId),
    {
      onError: (err: unknown) => {
        if (err instanceof Error) {
          toast.closeAll();
          toast({
            title: err.message,
            status: 'error',
          });
        }
      },
    },
  );
  const { data } = response;
  const commonData = data as MailSettingCommon;
  const statusObj = data ? data.status : MailSettingStatusesDefaultValue;
  const personalDataList: MailSettingPersonalData[] = (
    Object.keys(statusObj) as (keyof MailSettingStatuses)[]
  )
    .map((key) => {
      const { warningMessage, checked } = statusObj[key];

      return {
        type: key,
        checked,
        message: warningMessage,
        sort: statusTitleObjectSort[key],
      };
    })
    .sort((a, b) => a.sort - b.sort);

  return {
    commonData,
    personalDataList,
  };
};
