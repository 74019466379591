import { customErrorMessage, toZod } from 'admin/config/zod';
import { serviceSchema } from 'admin/schema/service';
import {
  MemberAttributeType,
  MemberIdPoolConsumerType,
  MemberLogType,
  MemberType,
} from 'admin/types/member/';
import {
  MEMBER_STATUS_TYPE_INVALID,
  MEMBER_STATUS_TYPE_VALID,
  MEMBER_STATUS_TYPE_WITHDRAWAL,
  MEMBER_STATUS_TYPE_WITHDRAWAL_COMPULSION,
} from 'define';
import { z } from 'zod';

// zodErrorの日本語化対応
z.setErrorMap(customErrorMessage);

export const memberStatusSchema = z.union([
  z.literal(MEMBER_STATUS_TYPE_VALID),
  z.literal(MEMBER_STATUS_TYPE_INVALID),
  z.literal(MEMBER_STATUS_TYPE_WITHDRAWAL),
  z.literal(MEMBER_STATUS_TYPE_WITHDRAWAL_COMPULSION),
]);

export const memberSchema = z.object<toZod<MemberType>>({
  uid: z.string(),
  email: z.string().nullable(),
  tel: z.string().nullable(),
  userPool: z.string(),
  userPoolChildren: z.array(
    z.object({
      id: z.string(),
      name: z.string(),
    }),
  ),
  createdAt: z.string(),
  lastLoginAt: z.string().nullable(),
  status: memberStatusSchema,
});

export const membersSchema = z.array(memberSchema);

export const memberIdPoolConsumerSchema = z.object<
  toZod<MemberIdPoolConsumerType>
>({
  id: z.string(),
  name: z.string(),
  authenticationType: z.number(),
  isSelfRegistration: z.boolean(),
  userPoolGroup: z.string(),
  userInfoResponse: z.string().nullable(),
  services: z.array(
    z.object({
      id: serviceSchema.shape.id,
      name: serviceSchema.shape.name,
    }),
  ),
});

export const memberIdPoolConsumersSchema = z.array(memberIdPoolConsumerSchema);

export const memberAttributeSchema = z.object<toZod<MemberAttributeType>>({
  id: z.string(),
  displayNameJa: z.string(),
  displayNameForeign: z.string().nullable(),
  fieldName: z.string(),
  displayOrder: z.number(),
  value: z.union([z.string(), z.array(z.string())]),
});

export const memberAttributesSchema = z.array(memberAttributeSchema);

export const memberLogSchema = z.object<toZod<MemberLogType>>({
  id: z.string(),
  memberId: memberSchema.shape.uid,
  historyDate: z.string(),
  content: z.string(),
});

export const memberLogsSchema = z.array(memberLogSchema);

// 会員有効・無効登録用
export const memberStatusChangeSchema = z.object<
  toZod<Pick<MemberType, 'uid' | 'status'>>
>({
  uid: z.string(),
  status: memberStatusSchema,
});
export const memberStatusChangeConfirmSchema = z.object({
  message: z.string().refine((val) => val === 'invalid', {
    message: '"invalid"と入力してください',
  }),
});

// 会員削除用
export const memberRemoveSchema = z.object<toZod<Pick<MemberType, 'uid'>>>({
  uid: z.string(),
});
export const memberRemoveConfirmSchema = z.object({
  message: z.string().refine((val) => val === 'delete', {
    message: '"delete"と入力してください',
  }),
});
