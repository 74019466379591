import { customErrorMessage, toZod } from 'admin/config/zod';
import {
  PERMISSION_FEATURE_ADMIN_DASHBOARD,
  PERMISSION_FEATURE_ADMIN_SAML,
  PERMISSION_FEATURE_ADMIN_SERVICE,
  PERMISSION_FEATURE_ADMIN_SETTING,
  PERMISSION_FEATURE_ADMIN_USER,
  PERMISSION_FEATURE_ADMIN_USER_POOL,
} from 'admin/define/permissions';
import { UserPoolType, UserType } from 'admin/types/user/index';
import {
  CORPORATION_USER_POOL_PERMISSION_AUTH_HUB,
  CORPORATION_USER_POOL_PERMISSION_MEMBER,
  CORPORATION_USER_POOL_PERMISSION_TENANT,
  CORPORATION_USER_POOL_PERMISSION_USER_POOL,
} from 'define';
import { z } from 'zod';

// zodErrorの日本語化対応
z.setErrorMap(customErrorMessage);

export const permissionFeatureSchema = z.union([
  z.literal(PERMISSION_FEATURE_ADMIN_DASHBOARD),
  z.literal(PERMISSION_FEATURE_ADMIN_USER),
  z.literal(PERMISSION_FEATURE_ADMIN_SERVICE),
  z.literal(PERMISSION_FEATURE_ADMIN_SAML),
  z.literal(PERMISSION_FEATURE_ADMIN_SETTING),
  z.literal(PERMISSION_FEATURE_ADMIN_USER_POOL),
]);

export const CorporationUserPoolPermissionTypeSchema = z.union([
  z.literal(CORPORATION_USER_POOL_PERMISSION_USER_POOL),
  z.literal(CORPORATION_USER_POOL_PERMISSION_TENANT),
  z.literal(CORPORATION_USER_POOL_PERMISSION_AUTH_HUB),
  z.literal(CORPORATION_USER_POOL_PERMISSION_MEMBER),
]);

export const userPoolListSchema = z.object<toZod<UserPoolType>>({
  userPoolId: z.string(),
  userPoolName: z.string().nullable().optional(),
  permission: z.array(CorporationUserPoolPermissionTypeSchema),
  authenticationType: z.number().nullable().optional(),
});

export const filterFormSchema = z.object({
  filterWord: z.string().max(30).nullable(),
  filterPermission: z.union([z.string(), z.number()]),
});

export const userDeleteSubmitFormSchema = z.object({
  word: z.string().refine((val) => val === 'delete', {
    message: '"delete"と入力してください',
  }),
});

export const userSchema = z.object<toZod<UserType>>({
  id: z.string(),
  name: z.string(),
  email: z.string(),
  permission: z.array(permissionFeatureSchema),
  userPools: z.array(userPoolListSchema),
});

export const usersSchema = z
  .array(userSchema)
  .nonempty('組織アカウントのユーザーが0件の為エラー');
