import { Box, Button, Flex, Text } from '@chakra-ui/react';
import { Colors } from 'admin/define/colors';
import { useUserCorporationInfo } from 'admin/hooks/user/useUserCorporationInfo';
import { FC, memo } from 'react';
import { MdOutlineAdd } from 'react-icons/md';

type Props = {
  onOpen: () => void;
};

export const UsersTitle: FC<Props> = memo(({ onOpen }: Props) => {
  const { hasPermissionUser } = useUserCorporationInfo();

  return (
    <Box mt="40px">
      <Flex justifyContent="flex-end">
        <Box mr="auto">
          <Text
            fontFamily="Hiragino Sans"
            fontSize="18px"
            fontWeight="700"
            lineHeight="170%"
          >
            組織アカウント管理
          </Text>
          <Text
            fontWeight="400"
            fontSize="14px"
            lineHeight="170%"
            fontFamily="Hiragino Sans"
            color={Colors.GRAY_00}
          >
            組織管理画面にアクセスできるユーザーを制御します。
          </Text>
        </Box>
        {hasPermissionUser && (
          <Button
            colorScheme="primary"
            minW="154px"
            p="8px 24px"
            onClick={onOpen}
            leftIcon={<MdOutlineAdd />}
          >
            ユーザー招待
          </Button>
        )}
      </Flex>
    </Box>
  );
});
