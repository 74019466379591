import { VFC, memo } from 'react';
import { Text, Box, Flex, List, ListItem } from '@chakra-ui/react';
import { useUserInfo } from 'hooks/useUserInfo';
import { User } from 'api/user/types';

type UserProfileProps = {
  selfData: User;
};

export const UserProfile: VFC<UserProfileProps> = memo(
  ({ selfData }: UserProfileProps) => {
    const { userTenant, getPermissionName } = useUserInfo();

    return (
      <Box mt={5}>
        <Text borderBottom="1px" borderColor="gray.200" pb={4} mb={4}>
          <Box as="span" w={150} display="inline-block">
            名前
          </Box>
          {selfData?.name}
        </Text>
        <Text borderBottom="1px" borderColor="gray.200" pb={4} mb={4}>
          <Box as="span" w={150} display="inline-block">
            メールアドレス
          </Box>
          {selfData?.email}
        </Text>
        <Text borderBottom="1px" borderColor="gray.200" pb={4} mb={4}>
          <Box as="span" w={150} display="inline-block">
            権限
          </Box>
          {getPermissionName(userTenant?.permission as number)}
        </Text>
        <Flex>
          <Text w={150} display="inline-block">
            所属グループ
          </Text>
          <List>
            {userTenant?.groups.map((item) => (
              <ListItem key={`userSetting-group-${item.id}`}>
                {item.name}
              </ListItem>
            ))}
          </List>
        </Flex>
      </Box>
    );
  },
);
