import { getAllAtributeAndGroups } from 'admin/api/userPool/attribute/getAllAtributeAndGroups';
import { CustomError } from 'admin/error/CustomError';
import { useUserPoolId } from 'admin/hooks/params/useUserPoolId';
import { logger } from 'api/logger';
import { useQuery } from 'react-query';
import { queryIdPoolConsumerAttributeKey } from './queryIdPoolConsumerAttributeKey';

// 組織管理画面 設問グループパーツ作成ページ用の全テナント属性一覧取得hooks
// isFormAttrがtrueのもののみを取得し返却する
export const useFilterAttributes = ({
  enabled = true,
}: {
  enabled?: boolean;
}) => {
  const userPoolId = useUserPoolId();
  const { data } = useQuery({
    queryKey: queryIdPoolConsumerAttributeKey.getAllAttributes(userPoolId),
    queryFn: () => getAllAtributeAndGroups(userPoolId),
    onError: async (error) => {
      if (error instanceof CustomError) {
        await logger({
          loglevel: error.logLevel,
          data: JSON.stringify(error.cause),
          message: error.message,
        });
      }
    },
    enabled,
    cacheTime: 0,
  });

  if (!enabled || !data) return [];

  return data;
};
