import { Box, Divider, Stack, Text } from '@chakra-ui/react';
import { VerifyUser } from 'api/user/types';
import { InputName } from 'components/user/atoms/InputName';
import { InputPassword } from 'components/user/atoms/InputPassword';
import { FC, memo } from 'react';
import { RegisterOptions } from 'react-hook-form';

type Props = {
  isSaml: boolean;
  inviteEmailAddress: VerifyUser['email'];
  nameValidateAttr: RegisterOptions;
  passwordValidateAttr: RegisterOptions;
  password2ValidateAttr: RegisterOptions;
}

export const InviteFormComponent: FC<Props> = memo(({
  isSaml,
  inviteEmailAddress,
  nameValidateAttr,
  passwordValidateAttr,
  password2ValidateAttr,
}: Props) => (
  <>
    <Box w="100%">
      <Text fontSize={12} fontWeight={700} mb="8px">メールアドレス</Text>
      <Text>{inviteEmailAddress}</Text>
    </Box>
    <Divider />
    <Stack spacing={4}>
      {/* 表示名 */}
      <Box>
        <Text fontSize={12} fontWeight={700} mb="8px">表示名</Text>
        <Text fontSize={12} mb="8px">アカウント管理画面でユーザーを識別するための名前です。</Text>
        <InputName attr={nameValidateAttr} />
      </Box>

      {/* パスワード */}
      {!isSaml && (
        <Box>
          <Text fontSize={12} fontWeight={700} mb="8px">パスワード</Text>
          <InputPassword
            name="password"
            placeholder=""
            attr={passwordValidateAttr}
          />
        </Box>
      )}

      {/* パスワード 再度入力 */}
      {!isSaml && (
        <Box>
          <Text fontSize={12} fontWeight={700} mb="8px">パスワード再入力</Text>
          <InputPassword
            name="passwordConfirm"
            placeholder=""
            attr={password2ValidateAttr}
          />
        </Box>
      )}
    </Stack>
  </>
));
