import { List, VStack } from '@chakra-ui/react';
import { ServiceBlock } from 'admin/components/service/serviceBlock';
import { ServicesType } from 'admin/types/service';
import { FC, memo } from 'react';

type ServiceListsComponentProps = {
  serviceList: ServicesType;
};

export const ServiceListsComponent: FC<ServiceListsComponentProps> = memo(
  ({ serviceList }: ServiceListsComponentProps) => (
    <List>
      <VStack spacing={4}>
        {serviceList.map((service, idx) => (
          <ServiceBlock service={service} key={`tenant_${service.id}_${String(idx)}`} />
        ))}
      </VStack>
    </List>
  ),
);
