import { Box, Button, Flex, Heading, Text } from '@chakra-ui/react';
import { Colors } from 'admin/define/colors';
import { AuthHubType } from 'admin/types/userPool/auth';
import { FC, memo } from 'react';

type Props = {
  authHub: AuthHubType;
  onDrawerOpen: () => void;
};

export const AuthHubSettingsComponent: FC<Props> = memo(
  ({ authHub, onDrawerOpen }: Props) => (
    <Box>
      <Flex justifyContent="space-between">
        <Box>
          <Text fontSize="16px" color={Colors.GRAY_03} fontWeight={700}>
            Auth Hub設定
          </Text>
          <Text fontSize="12px" color={Colors.GRAY_00}>
            Auth Hubの設定値は以下の通りです。
          </Text>
        </Box>
        <Button
          p="4px 24px"
          h="32px"
          fontWeight={700}
          variant="outline"
          colorScheme="primary"
          onClick={onDrawerOpen}
        >
          Auth Hub設定
        </Button>
      </Flex>
      <Flex
        my="16px"
        py="8px"
        px="16px"
        bg={Colors.GRAY_70}
        flexDirection="column"
        borderRadius="8px"
        minH="67px"
      >
        <Heading as="h3" size="sm" fontSize="14px" lineHeight="170%">
          ドメイン
        </Heading>
        <Text fontSize="16px" lineHeight="170%">
          {authHub.domain || '-'}
        </Text>
      </Flex>
      <Flex
        my="16px"
        py="8px"
        px="16px"
        bg={Colors.GRAY_70}
        flexDirection="column"
        borderRadius="8px"
        minH="67px"
      >
        <Heading as="h3" size="sm" fontSize="14px" lineHeight="170%">
          セッション有効期限
        </Heading>
        <Text fontSize="16px" lineHeight="170%">
          {authHub.duration !== null ? authHub.duration.toLocaleString() : '-'}
          秒
        </Text>
      </Flex>
    </Box>
  ),
);
