import { QuestionIcon } from '@chakra-ui/icons';
import {
  Accordion,
  AccordionButton,
  AccordionIcon,
  AccordionItem,
  AccordionPanel,
  Box,
  Flex,
  Grid,
  Text,
  Tooltip
} from '@chakra-ui/react';
import styled from '@emotion/styled';
import { ErrorTextMsg } from 'components/common/atoms';
import { Calendar } from 'components/paywall/atoms/Calendar';
import { Input } from 'components/paywall/atoms/Input';
import { Textarea } from 'components/paywall/atoms/Textarea';
import { AmmountWithTax } from 'components/paywall/features/AmmountWithTax';
import { PromotionFormDefaultValues } from 'components/paywall/pages/PromotionList/initial';
import {
  Promotion,
  PromotionFormType
} from 'components/paywall/pages/PromotionList/typed';
import { format } from 'date-fns';
import { FC, memo, useCallback, useEffect, useState } from 'react';
import { useFormContext } from 'react-hook-form';
import { AiOutlineInfoCircle } from 'react-icons/ai';
import { toErrMsgList } from 'utils/form';

const StyledFormFlex = styled(Flex)({
  alignItems: 'center',
  justifyContent: 'space-between',
  gap: '1rem',
  textAlign: 'center',
});
const StyledFormFlexInner = styled(Box)({
  width: '50%',
});
const StyledHeaderWithToolTip = styled(Box)({
  display: 'flex',
  alignItems: 'center',
});
const StyledToolTip = styled(Tooltip)({
  fontSize: '12px',
  borderRadius: '4px',
});
const StyledQuestionIcon = styled(QuestionIcon)({
  marginLeft: '0.5rem',
  cursor: 'default',
});

const DATETIME_FORMAT = 'yyyy-MM-dd HH:mm';

type Props = {
  isAdd?: boolean;
  obj?: Promotion;
};
export const Form: FC<Props> = memo(({ isAdd, obj }) => {
  const [startDate, setStartDate] = useState<Date | null>(null);
  const [endDate, setEndDate] = useState<Date | null>(null);
  const {
    setValue,
    getValues,
    register,
    clearErrors,
    formState: { errors },
  } = useFormContext<PromotionFormType>();

  /**
   * 初期処理
   */
  useEffect(() => {
    if (!isAdd) {
      setValue('id', obj?.id);
      setValue('name', obj?.name || PromotionFormDefaultValues.name);
      setValue('price', obj?.price || PromotionFormDefaultValues.price);
      setValue('month', obj?.month || PromotionFormDefaultValues.month);
      setValue(
        'startDate',
        obj?.startDate || PromotionFormDefaultValues.startDate,
      );
      setValue('endDate', obj?.endDate || PromotionFormDefaultValues.endDate);
      setValue('tags', obj?.tags || PromotionFormDefaultValues.tags);
      setValue('memo', obj?.memo || PromotionFormDefaultValues.memo);
    }
    const sDate =
      getValues('startDate') || PromotionFormDefaultValues.startDate;
    setStartDate(sDate ? new Date(sDate) : null);
    const eDate = getValues('endDate') || PromotionFormDefaultValues.endDate;
    setEndDate(eDate ? new Date(eDate) : null);
  }, [getValues, setValue, isAdd, obj]);

  const isPeriodCheck = (from: Date, to: Date): boolean =>
    from.getTime() <= to.getTime();

  const onStartDateChange = useCallback(
    (date: Date | null) => {
      setStartDate(date);
      setValue('startDate', date ? format(date, DATETIME_FORMAT) : null);
      // エラーが発生した際、入力された期間の関係をチェックして、エラーを解除するかどうか制御する
      clearErrors('endDate');
      if (date === null || (date && endDate && isPeriodCheck(date, endDate))) {
        clearErrors('startDate');
      }
    },
    [setValue, clearErrors, endDate],
  );

  const onEndDateChange = useCallback(
    (date: Date | null) => {
      setEndDate(date);
      setValue('endDate', date ? format(date, DATETIME_FORMAT) : null);
      // エラーが発生した際、入力された期間の関係をチェックして、エラーを解除するかどうか制御する
      clearErrors('startDate');
      if (date && startDate && isPeriodCheck(startDate, date)) {
        clearErrors('endDate');
      }
    },
    [setValue, clearErrors, startDate],
  );

  return (
    <form>
      <Grid gap={5} mt={2}>
        <Box>
          {isAdd ? (
            <>プロモーションの新規作成を行います</>
          ) : (
            <>プロモーションの編集を行います</>
          )}
        </Box>
        <Grid gap={2}>
          <Box>名前</Box>
          <Input
            defaultValue={isAdd ? PromotionFormDefaultValues.name : obj?.name}
            ref={register('name', {
              required: '名前を入力してください',
            })}
          />
          {toErrMsgList(errors, 'name').map((err, idx) => (
            <ErrorTextMsg
              key={`error-paywall-promotion-${String(idx)}`}
              msg={err}
            />
          ))}
        </Grid>
        <Grid gap={2}>
          <Box>税抜価格</Box>
          {!isAdd && (
            <Accordion allowMultiple>
              <AccordionItem>
                <h2>
                  <AccordionButton bg="gray.50" fontSize="sm">
                    <Flex alignItems="center">
                      <AiOutlineInfoCircle />
                      <Text ml={4}>税抜価格について</Text>
                    </Flex>
                    <AccordionIcon />
                  </AccordionButton>
                </h2>
                <AccordionPanel pt={4} pb={4} fontSize="xs">
                  <Text>
                    契約頂いた方の次回の決済から新しい価格で決済されます
                  </Text>
                </AccordionPanel>
              </AccordionItem>
            </Accordion>
          )}
          <StyledFormFlex>
            <StyledFormFlexInner>
              <Input
                type="text"
                ref={register('price', {
                  required: '税抜価格を入力してください',
                  valueAsNumber: true,
                  validate: (value) => {
                    if (Number.isNaN(value)) {
                      return '税抜価格は半角数字で入力してください';
                    }

                    if ((value as number) <= 0) {
                      return '税抜価格は1円以上で入力してください';
                    }

                    if ((value as number) > 999999) {
                      return '税抜価格は999,999円以下で入力してください';
                    }

                    return undefined;
                  },
                })}
              />
            </StyledFormFlexInner>
            <StyledFormFlexInner>
              <AmmountWithTax<Promotion> name="price" />
            </StyledFormFlexInner>
          </StyledFormFlex>
          {toErrMsgList(errors, 'price').map((err, idx) => (
            <ErrorTextMsg
              key={`error-paywall-promotion-${String(idx)}`}
              msg={err}
            />
          ))}
        </Grid>
        <Grid gap={2}>
          <StyledHeaderWithToolTip>
            適用回数
            <StyledToolTip label="プロモーションを適用する回数を設定します">
              <StyledQuestionIcon />
            </StyledToolTip>
          </StyledHeaderWithToolTip>
          {!isAdd && (
            <Accordion allowMultiple>
              <AccordionItem>
                <h2>
                  <AccordionButton bg="gray.50" fontSize="sm">
                    <Flex alignItems="center">
                      <AiOutlineInfoCircle />
                      <Text ml={4}>適用回数について</Text>
                    </Flex>
                    <AccordionIcon />
                  </AccordionButton>
                </h2>
                <AccordionPanel pt={4} pb={4} fontSize="xs">
                  <Text>
                    既に契約頂いた方の適用回数は変更されません。設定後に契約頂く方は新しい設定が適用されます
                  </Text>
                </AccordionPanel>
              </AccordionItem>
            </Accordion>
          )}
          <Input
            ref={register('month', {
              required: '適用回数を入力してください',
              valueAsNumber: true,
              validate: (value) => {
                // エラーになる値で実行される前にフロント側でも入力チェックしておく
                if (Number.isNaN(value)) {
                  return '適用回数は半角数字で入力してください';
                }

                if ((value as number) <= 0) {
                  return '適用回数は1以上で入力してください';
                }

                if ((value as number) > 96) {
                  return '適用回数は1以下で入力してください';
                }

                return undefined;
              },
            })}
          />
          {toErrMsgList(errors, 'month').map((err, idx) => (
            <ErrorTextMsg
              key={`error-paywall-promotion-${String(idx)}`}
              msg={err}
            />
          ))}
        </Grid>
        <Grid gap={2}>
          <Box>期間</Box>
          <Flex alignItems="center">
            <Calendar<PromotionFormType>
              selectedDate={startDate ? new Date(startDate) : null}
              fieldName="startDate"
              placeholder="期間 From"
              onChange={onStartDateChange}
              bgcolor="#fff"
              inputSize="sm"
              isShowTime
            />
            <span style={{ margin: '0 10px' }}>〜</span>
            <Calendar<PromotionFormType>
              selectedDate={endDate ? new Date(endDate) : null}
              fieldName="endDate"
              placeholder="期間 To"
              onChange={onEndDateChange}
              bgcolor="#fff"
              inputSize="sm"
              isShowTime
            />
          </Flex>
          {toErrMsgList({ startDate: errors.startDate }, 'startDate').map(
            (err, idx) => (
              <ErrorTextMsg
                key={`error-paywall-promotion-${String(idx)}`}
                msg={err}
              />
            ),
          )}
          {toErrMsgList({ endDate: errors.endDate }, 'endDate').map(
            (err, idx) => (
              <ErrorTextMsg
                key={`error-paywall-promotion-${String(idx)}`}
                msg={err}
              />
            ),
          )}
        </Grid>
        <Grid gap={2}>
          <Box>タグ</Box>
          <Textarea ref={register('tags')} />
          {toErrMsgList(errors, 'tags').map((err, idx) => (
            <ErrorTextMsg
              key={`error-paywall-promotion-${String(idx)}`}
              msg={err}
            />
          ))}
        </Grid>
        <Grid gap={2}>
          <Box>メモ</Box>
          <Textarea ref={register('memo')} />
          {toErrMsgList(errors, 'memo').map((err, idx) => (
            <ErrorTextMsg
              key={`error-paywall-promotion-${String(idx)}`}
              msg={err}
            />
          ))}
        </Grid>
      </Grid>
    </form>
  );
});
