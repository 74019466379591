import { Flex } from '@chakra-ui/react';
import { FC, memo } from 'react';

type Props = {
  children: React.ReactNode;
}
export const DetailFlex: FC<Props> = memo(({children}: Props) => (
  <Flex justifyContent="space-between">
    {children}
  </Flex>
));
