import { changeServiceNewsletterTenantAppend } from 'admin/api/service/newsletter/changeServiceNewsletterTenantAppend';
import { CustomError } from 'admin/error/CustomError';
import { queryNewsletterKeyNames } from 'admin/hooks/service/newsletter/queryNewsletterKey';
import { ServiceNewsletterFormType } from 'admin/types/service/newsletter/form';
import { logger } from 'api/logger';
import { useCustomToast } from 'hooks/useCustomToast';
import { useMutation, useQueryClient } from 'react-query';
import { errorToast, successToast } from 'utils/toast';

export const useChangeServiceNewsletterTenantAppend = () => {
  const toast = useCustomToast();
  const queryClient = useQueryClient();

  const { mutateAsync, isLoading } = useMutation({
    mutationFn: (data: ServiceNewsletterFormType) =>
      changeServiceNewsletterTenantAppend({ data }),
    onSuccess: () => {
      toast({
        ...successToast,
        duration: 4000,
        title: 'テナント割り当てを「有効」に変更しました',
        position: 'bottom',
        variant: 'solid',
      });
      void queryClient.invalidateQueries(
        queryNewsletterKeyNames.ServiceNewsletter,
      );
    },
    onError: async (error) => {
      if (error instanceof CustomError) {
        if (error.cause.errors) {
          await logger({
            loglevel: error.logLevel,
            data: JSON.stringify(error.cause),
            message: error.message,
          });
        }
      }
      toast({
        ...errorToast,
        duration: 4000,
        title: 'テナント割り当てを「無効」に変更しました',
        position: 'bottom',
        variant: 'solid',
      });
    },
  });

  return { mutate: mutateAsync, isLoading };
};
