import { Icon, IconProps } from '@chakra-ui/react';

type CustomIconType = (props: IconProps) => JSX.Element;

export const VesuionUpIcon: CustomIconType = (props: IconProps) => (
  <Icon xmlns="http://www.w3.org/2000/svg" {...props}>
    <svg viewBox="0 0 8 13" xmlns="http://www.w3.org/2000/svg">
      <path
        d="M0 13V11.5H8V13H0ZM3.25 10V2.875L1.0625 5.0625L0 4L4 0L8 4L6.9375 5.0625L4.75 2.875V10H3.25Z"
        fill="currentColor"
      />
    </svg>
  </Icon>
);
