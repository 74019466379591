import { ExternalLinkIcon } from '@chakra-ui/icons';
import {
  Accordion,
  AccordionButton,
  AccordionIcon,
  AccordionItem,
  AccordionPanel,
  Box,
  Link,
  ListItem,
  Text,
  UnorderedList,
} from '@chakra-ui/react';
import { Colors } from 'admin/define/colors';
import { FC, memo, useState } from 'react';
import { MdErrorOutline } from 'react-icons/md';
import { Link as ReactRouterLink } from 'react-router-dom';

type Props = {
  idPoolConsumerId: string;
};

export const AttributeFormJsonAccordion: FC<Props> = memo(
  ({ idPoolConsumerId }: Props) => {
    const [accordionFlg, setAccordionFlg] = useState<boolean>(false);

    // ユーザー情報レスポンス参照フィールドのアコーディオン内の各リンク
    const JSON_ATTR_DESCRIPTION_LINK = {
      attributeSetting: 'https://uniikey.notion.site/ID-470ed418c4d845beb12a32fc2734df97', // 外部ID利用時の属性設定
      jsonRegistration: `/admin/idPoolConsumer/${idPoolConsumerId}/userInfo`, // JSON登録画面
      jsonPathRule: '/admin/jsonPathExample/', // JSONパス構文の入力方法
    };

    return (
      <Accordion
        allowToggle
        mb="8px"
        onChange={() => setAccordionFlg(!accordionFlg)}
      >
        <AccordionItem
          border={`1px solid ${Colors.GRAY_30}`}
          borderRadius="6px"
        >
          <AccordionButton
            bgColor={Colors.GRAY_70}
            borderRadius={accordionFlg ? '6px 6px 0px 0px' : '6px'}
            _focus={{
              'box-shadow': 'none',
            }}
          >
            <MdErrorOutline fontSize="18px" color={Colors.GRAY_00} />
            <Box
              fontWeight="400"
              fontSize="14px"
              fontFamily="Hiragino Sans"
              lineHeight="21px"
              color={Colors.GRAY_00}
              ml="10px"
              mr="4px"
            >
              JSONによる属性情報の登録について
            </Box>
            <AccordionIcon color={Colors.GRAY_00} />
          </AccordionButton>
          <AccordionPanel p="16px">
            <UnorderedList ml="24px">
              <ListItem>
                フォーム設問に対し、現在ご利用中のIDシステムの属性情報を自動連携入力するために、JSONを登録し紐づける必要があります。
              </ListItem>
              <ListItem pb="24px">
                <Text>
                  外部ユーザ情報の登録から属性情報との紐づけについては以下マニュアルをご参照ください。
                </Text>
                <Link
                  href={JSON_ATTR_DESCRIPTION_LINK.attributeSetting}
                  color={Colors.BLUE_20}
                  textDecoration="underline"
                  isExternal
                  mb="2px"
                  fontWeight="700"
                >
                  外部ID利用時の属性設定{' '}
                  <ExternalLinkIcon fontSize="16px" mx="7px" mb="3px" />
                </Link>
              </ListItem>
              <ListItem pb="24px">
                <Text>
                  JSONによる外部IDのユーザ情報の登録は以下からご確認ください。
                </Text>
                <Link
                  as={ReactRouterLink}
                  to={JSON_ATTR_DESCRIPTION_LINK.jsonRegistration}
                  color={Colors.BLUE_20}
                  textDecoration="underline"
                  isExternal
                  mb="2px"
                  fontWeight="700"
                >
                  JSON登録画面{' '}
                  <ExternalLinkIcon fontSize="16px" mx="7px" mb="3px" />
                </Link>
              </ListItem>
              <ListItem>
                <Text>
                  登録済みJSONによる属性の紐づけは以下からご確認ください。
                </Text>
                <Link
                  as={ReactRouterLink}
                  to={JSON_ATTR_DESCRIPTION_LINK.jsonPathRule}
                  color={Colors.BLUE_20}
                  textDecoration="underline"
                  isExternal
                  mb="2px"
                  fontWeight="700"
                >
                  JSONパス構文の入力方法{' '}
                  <ExternalLinkIcon fontSize="16px" mx="7px" mb="3px" />
                </Link>
              </ListItem>
            </UnorderedList>
          </AccordionPanel>
        </AccordionItem>
      </Accordion>
    );
  },
);
