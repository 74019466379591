import { getFormGroups } from 'admin/api/userPool/formGroup/getFormGroups';
import { CustomError } from 'admin/error/CustomError';
import { useCurrentPerPage } from 'admin/hooks/pagination/useCurrentPerPage';
import { convertToPage } from 'admin/schema/page';
import { FormGroupsType, FormGroupType } from 'admin/types/userPool/formGroup';
import { DataWithPage, Page } from 'api/common/types';
import { logger } from 'api/logger';
import { useQuery } from 'react-query';
import { queryFormGroupKey } from './queryFormGroupKey';

export const useFormGroups = (
  id: FormGroupType['userPoolId'],
  page: Page['currentPage'],
) => {
  const perPage = useCurrentPerPage();
  const { data, refetch, isLoading } = useQuery({
    queryKey: queryFormGroupKey.getFormGroups(
      id,
      page,
      perPage,
    ),
    queryFn: () => getFormGroups({ id, page, perPage }),
    onError: async (error) => {
      if (error instanceof CustomError) {
        await logger({
          loglevel: error.logLevel,
          data: JSON.stringify(error.cause),
          message: error.message,
        });
      }
    },
  });

  return {
    data: data?.results,
    page: convertToPage<DataWithPage<FormGroupsType>>(data),
    refetch,
    isLoading,
  };

};
