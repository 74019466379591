import { Box, ButtonProps, ChakraProvider } from '@chakra-ui/react';
import {
  Container,
  Next,
  PageGroup,
  Paginator,
  Previous,
  usePaginator
} from 'chakra-paginator';
import { FC, useEffect, useState } from 'react';
import { BiChevronLeft, BiChevronRight } from 'react-icons/bi';

type Props = {
  count: number;
  perPage: number;
  page: number;
  primary: string;
  secondary: string;
  onChange: (arg: number) => void;
};

/**
 * @see ページネーションコンポーネント
 * @see https://github.com/niconiahi/chakra-paginator
 * @param count 表示する項目の総数
 * @param perPage 現在の表示件数
 * @param page 現在のページ
 * @param primary プライマリーカラー
 * @param secondary セカンダリーカラー
 * @param onChange ページングを行うイベントハンドラ
 */
export const Pagination: FC<Props> = ({
  count,
  perPage,
  page,
  primary = 'blue',
  secondary = 'gray',
  onChange,
}) => {
  // Paginatorconfig
  const paginatorConfig = {
    outerLimit: 3,
    innerLimit: 2,
  }
  const baseStyles: ButtonProps = {
    w: 10,
  };
  const normalStyles: ButtonProps = {
    ...baseStyles,
    _hover: { bg: `${primary}.300` },
    bg: `${secondary}.200`,
  };
  const activeStyles: ButtonProps = {
    ...baseStyles,
    _hover: { bg: `${primary}.300` },
    bg: `${primary}.100`,
  };
  const separatorStyles: ButtonProps = {
    ...baseStyles,
    pointerEvents: 'none'
  };
  const { pagesQuantity, currentPage, setCurrentPage } = usePaginator({
    total: count,
    initialState: {
      pageSize: perPage,
      currentPage: 1,
    },
  });
  const [pageAmount, setPageAmount] = useState(pagesQuantity);
  const onPageChange = (nextPage: number) => {
    setCurrentPage(nextPage);
    onChange(nextPage);
  };
  useEffect(() => {
    setPageAmount(Math.ceil(count / perPage));
    setCurrentPage(page);
  }, [perPage, count, page, setCurrentPage]);

  return (
    <ChakraProvider>
      <Paginator
        activeStyles={activeStyles}
        normalStyles={normalStyles}
        currentPage={currentPage}
        pagesQuantity={pageAmount}
        onPageChange={onPageChange}
        separatorStyles={separatorStyles}
        innerLimit={paginatorConfig.innerLimit}
        outerLimit={paginatorConfig.outerLimit}
      >
        <Container style={{ display: 'flex', gap: 10 }}>
          <Box>
            <Previous>
              <BiChevronLeft style={{ fontSize: 24 }} />
            </Previous>
          </Box>
          <PageGroup isInline align="center" />
          <Box>
            <Next>
              <BiChevronRight style={{ fontSize: 24 }} />
            </Next>
          </Box>
        </Container>
      </Paginator>
    </ChakraProvider>
  );
};
