import {
  AlertDialog,
  Box,
  Drawer,
  Heading,
  Modal,
  useDisclosure,
} from '@chakra-ui/react';
import { withSuspenseAndErrorBoundary } from 'admin/components/Error/WithErrorBoundary';
import { NewsletterEditDrawer } from 'admin/components/service/newsletter/newsletterDrawer';
import { NewsletterTableComponent } from 'admin/components/service/newsletter/newsletterTable/NewsletterTableComponent';
import { CheckWhenRegistrationModal } from 'admin/components/service/newsletter/statusChangeModal/checkWhenRegistrationModal';
import { DeliveryAvailableModal } from 'admin/components/service/newsletter/statusChangeModal/deliveryAvailableModal';
import { DisplayWhenRegistrationModal } from 'admin/components/service/newsletter/statusChangeModal/displayWhenRegistrationModal';
import { TenantAppendDialog } from 'admin/components/service/newsletter/tenantAppend/tenantAppendDialog';
import { Pagination } from 'admin/components/ui/pagination';
import { useChangeServiceNewsletterCheckWhenRegistraion } from 'admin/hooks/service/newsletter/useChangeServiceNewsletterCheckWhenRegistraion';
import { useChangeServiceNewsletterDeliveryAvailable } from 'admin/hooks/service/newsletter/useChangeServiceNewsletterDeliveryAvailable';
import { useChangeServiceNewsletterDisplayWhenRegistration } from 'admin/hooks/service/newsletter/useChangeServiceNewsletterDisplayWhenRegistration';
import { useServiceNewsletter } from 'admin/hooks/service/newsletter/useServiceNewsletter';
import {
  ServiceNewsletterChild,
  ServiceNewsletterType,
} from 'admin/types/service/newsletter';
import { Page } from 'api/common/types';
import { ErrorContents } from 'components/common/atoms';
import {
  Dispatch,
  FC,
  memo,
  SetStateAction,
  useCallback,
  useEffect,
  useRef,
  useState,
} from 'react';

type Props = {
  currentPage: number;
  perPage: number;
  setPage: Dispatch<SetStateAction<Page>>;
};

export const fetchNewsletterTable: FC<Props> = memo(
  ({ currentPage, perPage, setPage }: Props) => {
    const [newsletter, setNewsletter] = useState<ServiceNewsletterType>();
    const [targetServiceNewsletter, setTargetServiceNewsletter] =
      useState<ServiceNewsletterType>();
    const [deliveryAvailableStatus, setDeliveryAvailableStatus] =
      useState<boolean>(false);
    const [displayWhenRegistrationStatus, setDisplayWhenRegistrationStatus] =
      useState<boolean>(false);
    const [checkWhenRegistrationStatus, setCheckWhenRegistrationStatus] =
      useState<boolean>(false);
    const {
      isOpen: isOpenEditDrawer,
      onClose: onCloseEditDrawer,
      onOpen: onOpenEditDrawer,
    } = useDisclosure();
    const {
      isOpen: isOpenTenantAppendDialog,
      onClose: onCloseTenantAppendDialog,
      onOpen: onOpenTenantAppendDialog,
    } = useDisclosure();
    const {
      isOpen: isOpenDeliveryAvailableModal,
      onClose: onCloseDeliveryAvailableModal,
      onOpen: onOpenDeliveryAvailableModal,
    } = useDisclosure();
    const {
      isOpen: isOpenDisplayWhenRegistrationModal,
      onClose: onCloseDisplayWhenRegistrationModal,
      onOpen: onOpenDisplayWhenRegistrationModal,
    } = useDisclosure();
    const {
      isOpen: isOpenCheckWhenRegistrationModal,
      onClose: onCloseCheckWhenRegistrationModal,
      onOpen: onOpenCheckWhenRegistrationModal,
    } = useDisclosure();

    const { data: newsletters, page } = useServiceNewsletter(
      currentPage,
      perPage,
    );
    const cancelRef = useRef(null);

    const {
      mutate: deliveryAvailableMutate,
      isLoading: deliveryAvailableLoading,
    } = useChangeServiceNewsletterDeliveryAvailable({
      id: targetServiceNewsletter?.userPoolChildNewsletter?.id ?? '',
      status:
        !targetServiceNewsletter?.userPoolChildNewsletter?.isDeliveryAvailable,
    });

    const {
      mutate: displayWhenRegistrationMutate,
      isLoading: displayWhenRegistrationLoading,
    } = useChangeServiceNewsletterDisplayWhenRegistration({
      id: targetServiceNewsletter?.userPoolChildNewsletter?.id ?? '',
      status:
        !targetServiceNewsletter?.userPoolChildNewsletter
          ?.isDisplayWhenRegistration,
    });

    const {
      mutate: checkWhenRegistrationMutate,
      isLoading: checkWhenRegistrationLoading,
    } = useChangeServiceNewsletterCheckWhenRegistraion({
      id: targetServiceNewsletter?.userPoolChildNewsletter?.id ?? '',
      status:
        !targetServiceNewsletter?.userPoolChildNewsletter
          ?.isCheckWhenRegistration,
    });

    const onOpenEditDrawerHandler = useCallback(
      (target: ServiceNewsletterType) => {
        setNewsletter(target);
        onOpenEditDrawer();
      },
      [newsletters, onOpenEditDrawer],
    );

    const onOpenTenantAppendDialogHandler = useCallback(
      (target: ServiceNewsletterType) => {
        setTargetServiceNewsletter(target);
        onOpenTenantAppendDialog();
      },
      [onOpenTenantAppendDialog],
    );

    const onOpenDeliveryAvailableModalHandler = useCallback(
      (
        status: ServiceNewsletterChild['isDeliveryAvailable'],
        target: ServiceNewsletterType,
      ) => {
        setDeliveryAvailableStatus(status);
        setTargetServiceNewsletter(target);
        onOpenDeliveryAvailableModal();
      },
      [onOpenDeliveryAvailableModal],
    );

    const onOpenDisplayWhenRegistrationModalHandler = useCallback(
      (
        status: ServiceNewsletterChild['isDisplayWhenRegistration'],
        target: ServiceNewsletterType,
      ) => {
        setDisplayWhenRegistrationStatus(status);
        setTargetServiceNewsletter(target);
        onOpenDisplayWhenRegistrationModal();
      },
      [onOpenDisplayWhenRegistrationModal],
    );

    const onOpenCheckWhenRegistrationModalHandler = useCallback(
      (
        status: ServiceNewsletterChild['isCheckWhenRegistration'],
        target: ServiceNewsletterType,
      ) => {
        setCheckWhenRegistrationStatus(status);
        setTargetServiceNewsletter(target);
        onOpenCheckWhenRegistrationModal();
      },
      [onOpenCheckWhenRegistrationModal],
    );

    const onSubmitDeliveryAvailableStatus = useCallback(async () => {
      await deliveryAvailableMutate();
      onCloseDeliveryAvailableModal();
    }, [deliveryAvailableMutate, onCloseDeliveryAvailableModal]);

    const onSubmitDisplayWhenRegistrationStatus = useCallback(async () => {
      await displayWhenRegistrationMutate();
      onCloseDisplayWhenRegistrationModal();
    }, [displayWhenRegistrationMutate, onCloseDisplayWhenRegistrationModal]);

    const onSubmitCheckWhenRegistrationStatus = useCallback(async () => {
      await checkWhenRegistrationMutate();
      onCloseCheckWhenRegistrationModal();
    }, [checkWhenRegistrationMutate, onCloseCheckWhenRegistrationModal]);

    useEffect(() => {
      setPage(page);
    }, [page, setPage]);

    if (!newsletters) {
      return null;
    }

    if (newsletters.length === 0) {
      return (
        <Box
          w="100%"
          textAlign="center"
          bg="white"
          mt={6}
          borderRadius={4}
          padding={10}
        >
          <Heading as="h3" fontWeight="bold" fontSize="24px">
            メールマガジン購読設定が未作成です。
          </Heading>
        </Box>
      );
    }

    return (
      <>
        <NewsletterTableComponent
          newsletters={newsletters}
          onOpenEditDrawer={onOpenEditDrawerHandler}
          setTargetServiceNewsletter={setTargetServiceNewsletter}
          onOpenTenantAppendDialogHandler={onOpenTenantAppendDialogHandler}
          onOpenDeliveryAvailableModalHandler={
            onOpenDeliveryAvailableModalHandler
          }
          onOpenDisplayWhenRegistrationModalHandler={
            onOpenDisplayWhenRegistrationModalHandler
          }
          onOpenCheckWhenRegistrationModalHandler={
            onOpenCheckWhenRegistrationModalHandler
          }
        />
        <Pagination page={page} />

        <Drawer
          isOpen={isOpenEditDrawer}
          placement="right"
          onClose={onCloseEditDrawer}
          size="md"
          closeOnOverlayClick={false}
          closeOnEsc={false}
          autoFocus={false}
        >
          <NewsletterEditDrawer
            newsletter={newsletter}
            onClose={onCloseEditDrawer}
          />
        </Drawer>
        <AlertDialog
          isOpen={isOpenTenantAppendDialog}
          onClose={onCloseTenantAppendDialog}
          leastDestructiveRef={cancelRef}
          isCentered
          closeOnOverlayClick={false}
          closeOnEsc={false}
          size="xl"
        >
          <TenantAppendDialog
            onClose={onCloseTenantAppendDialog}
            currentPage={currentPage}
            newsletter={targetServiceNewsletter}
          />
        </AlertDialog>
        <Modal
          isOpen={isOpenDeliveryAvailableModal}
          onClose={onCloseDeliveryAvailableModal}
          isCentered
          size="xl"
        >
          <DeliveryAvailableModal
            isLoading={deliveryAvailableLoading}
            onClose={onCloseDeliveryAvailableModal}
            onSubmit={onSubmitDeliveryAvailableStatus}
            status={deliveryAvailableStatus}
          />
        </Modal>
        <Modal
          isOpen={isOpenDisplayWhenRegistrationModal}
          onClose={onCloseDisplayWhenRegistrationModal}
          isCentered
          size="xl"
        >
          <DisplayWhenRegistrationModal
            isLoading={displayWhenRegistrationLoading}
            onClose={onCloseDisplayWhenRegistrationModal}
            onSubmit={onSubmitDisplayWhenRegistrationStatus}
            status={displayWhenRegistrationStatus}
          />
        </Modal>
        <Modal
          isOpen={isOpenCheckWhenRegistrationModal}
          onClose={onCloseCheckWhenRegistrationModal}
          isCentered
          size="xl"
        >
          <CheckWhenRegistrationModal
            isLoading={checkWhenRegistrationLoading}
            onClose={onCloseCheckWhenRegistrationModal}
            onSubmit={onSubmitCheckWhenRegistrationStatus}
            status={checkWhenRegistrationStatus}
          />
        </Modal>
      </>
    );
  },
);

export const NewsletterTable = memo(
  withSuspenseAndErrorBoundary(fetchNewsletterTable, {
    ErrorComponent: <ErrorContents name="メールマガジン購読設定一覧" />,
  }),
);
