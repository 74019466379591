// ----- fetch関連 -----
const GET_MEMBER_TD_POOL_CONSUMER = {
  ERROR:
    '申し訳ございません。会員管理のユーザープール情報の取得ができません。時間を置いて再表示をお願いします。',
  NOT_FOUND:
    '会員管理のユーザープール情報が存在しません。既に削除された可能性があります。',
} as const;
const GET_MEMBERS = {
  ERROR:
    '申し訳ございません。会員管理一覧情報の取得ができません。時間を置いて再表示をお願いします。',
  NOT_FOUND: '会員管理一覧情報が存在しません。既に削除された可能性があります。',
} as const;
const GET_MEMBER = {
  ERROR:
    '申し訳ございません。会員管理詳細情報の取得ができません。時間を置いて再表示をお願いします。',
  NOT_FOUND: '会員管理詳細情報が存在しません。既に削除された可能性があります。',
} as const;
const GET_MEMBER_ATTRIBUTES = {
  ERROR:
    '申し訳ございません。会員管理詳細の属性情報一覧の取得ができません。時間を置いて再表示をお願いします。',
  NOT_FOUND: '会員管理詳細の属性情報一覧が存在しません。既に削除された可能性があります。',
} as const;
const GET_MEMBER_LOGS = {
  ERROR:
    '申し訳ございません。会員管理詳細の利用履歴情報の取得ができません。時間を置いて再表示をお願いします。',
  NOT_FOUND: '会員管理詳細の利用履歴情報が存在しません。既に削除された可能性があります。',
} as const;
const GET_MEMBER_SERVICES = {
  ERROR:
    '申し訳ございません。会員管理 テナント詳細の取得ができません。時間を置いて再表示をお願いします。',
  NOT_FOUND: '会員管理 テナント詳細が存在しません。既に削除された可能性があります。',
} as const;

// ----- Mutate関連 -----
const UPDATE_MEMBER_STATUS_CHANGE = {
  ERROR: '申し訳ございません。会員ステータス変更に失敗しました。',
  FAILED:
    '会員ステータス変更に失敗しました。恐れ入りますが時間をおいて再度実行してください。',
} as const;
const REMOVE_MEMBER = {
  ERROR: '申し訳ございません。会員削除に失敗しました。',
  FAILED:
    '会員削除に失敗しました。恐れ入りますが時間をおいて再度実行してください。',
} as const;

export const MEMBER = {
  MEMBER: {
    GET_MEMBER_TD_POOL_CONSUMER,
    GET_MEMBERS,
    GET_MEMBER,
    GET_MEMBER_ATTRIBUTES,
    GET_MEMBER_LOGS,
    GET_MEMBER_SERVICES,
    UPDATE_MEMBER_STATUS_CHANGE,
    REMOVE_MEMBER,
  },
};
