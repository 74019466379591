import { API_MESSAGES } from 'admin/define/apiMessages';
import { CustomError } from 'admin/error/CustomError';
import { serviceSchema } from 'admin/schema/service';
import { ServiceType } from 'admin/types/service';
import { getDummySuccessResponse } from 'admin/utils/getDummyData';
import { AUTH_CODE_TYPE_OUTER } from 'define';
import { ZodError } from 'zod';

const dummyData: ServiceType = {
  id: '',
  name: 'テナントA',
  userPoolChildId: '',
  userPoolId: '',
  userPoolName: '',
  authenticationType: AUTH_CODE_TYPE_OUTER,
};

export const getService = async (
  serviceId: ServiceType['id'],
): Promise<ServiceType> => {
  // TODO: APIの繋ぎ込みの際に実装する
  // const response = await request<getIdPoolConsumersType>({
  //   path,
  //   method: 'get',
  //   options,
  // });

  await new Promise<string>((resolve) => {
    setTimeout(() => {
      resolve('Timer done');
    }, 1000);
  });

  const response = await getDummySuccessResponse<ServiceType>({
    ...dummyData,
    id: serviceId,
  });

  if (response.hasError) {
    if (response.status === 404) {
      throw new CustomError(API_MESSAGES.SERVICE.GET_SERVICE.NOT_FOUND, {
        path: 'dummy',
        status: response.status,
      });
    } else {
      throw new CustomError(API_MESSAGES.SERVICE.GET_SERVICE.ERROR, {
        path: 'dummy',
        status: response.status,
      });
    }
  }
  try {
    serviceSchema.parse(response.data);
  } catch (error) {
    if (error instanceof ZodError) {
      throw new CustomError(error.message, {
        path: 'dummy',
        status: response.status,
        logLevel: 'error',
      });
    }
  }

  return response.data;
};
