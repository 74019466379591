import { CustomError } from 'api/error/CustomError';
import { logger } from 'api/logger';
import { request } from 'api/request';
import { messages } from 'api/tenant/messages';
import { FormHostingType, isFormHostingType } from 'api/tenant/types';
import { Options } from 'ky';

/**
 * フォームホスティング情報取得API
 */
export const getFormHosting = async (
  tenantId: string,
  options?: Options,
): Promise<FormHostingType> => {
  const response = await request({
    path: `enquete/${tenantId}/form_hosting/`,
    method: 'get',
    options,
  });

  const responseData = (await response.data) as unknown[];

  if (response.hasError) {
    throw new CustomError(messages.getFormHosting.customer.error);
  }

  if (!isFormHostingType(responseData)) {
    void logger({
      loglevel:
        response.status === 404 || response.status === 450
          ? 'warning'
          : 'error',
      data: responseData,
      message: messages.getFormHosting.system.typeError,
    });

    throw new CustomError(messages.getFormHosting.customer.failed);
  }

  return responseData;
};
