import { Box, Flex } from '@chakra-ui/react';
import { withSuspenseAndErrorBoundary } from 'admin/components/Error/WithErrorBoundary';
import { ServiceListsComponent } from 'admin/components/service/serviceLists/ServiceListsComponent';
import { ServiceSearch } from 'admin/components/service/serviceSearch';
import { ServiceTitle } from 'admin/components/service/serviceTitle';
import { useServices } from 'admin/hooks/service/useServices';
import { ServicesType } from 'admin/types/service';
import { ErrorContents, LoadingSpinner } from 'components/common/atoms';
import { FC, memo, useMemo, useState } from 'react';

const FetchServiceLists: FC = memo(() => {
  const { data: fetchServiceList } = useServices();
  const [searchKeyword, setSearchKeyword] = useState('');
  const serviceList = useMemo<ServicesType>(() => {
    if (!fetchServiceList) return [];
    if (!searchKeyword) return fetchServiceList;

    return fetchServiceList.filter(
      (service) => service.name.indexOf(searchKeyword) !== -1,
    );
  }, [fetchServiceList, searchKeyword]);

  const onSearch = (keyword: string) => {
    setSearchKeyword(keyword)
  };

  if (!serviceList) {
    return (
      <>
        <ServiceSearch onSearch={onSearch} />
      </>
    );
  }

  return (
    <>
      <Flex justifyContent="space-between">
        <ServiceTitle />
        <ServiceSearch onSearch={onSearch} />
      </Flex>
      <Box mt="16px">
        <ServiceListsComponent serviceList={serviceList} />
      </Box>
    </>
  );
});

const serviceLists: FC = memo(() => <FetchServiceLists />);

export const ServiceLists = memo(
  withSuspenseAndErrorBoundary(serviceLists, {
    ErrorComponent: <ErrorContents name="テナント一覧" />,
    LoadingComponent: <LoadingSpinner />,
  }),
);
