import { Box } from '@chakra-ui/react';
import { withSuspenseAndErrorBoundary } from 'admin/components/Error/WithErrorBoundary';
import { ServiceLists } from 'admin/components/service/serviceLists/';
import { Breadcrumb } from 'admin/components/ui/breadcrumb';
import { BreadcrumbType } from 'admin/types/ui/breadcrumb';
import { ErrorContents, LoadingSpinner } from 'components/common/atoms';
import { useSetPageTitle } from 'hooks/useSetPageTitle';
import { FC, memo } from 'react';

export const serviceIndex: FC = memo(() => {
  useSetPageTitle('テナント設定管理');

  const breadcrumbItems: BreadcrumbType[] = [
    { url: '/admin', title: '組織管理TOP' },
    { url: '', title: 'テナント設定管理' },
  ]

  return (
    <>
      <Breadcrumb breadcrumbItems={breadcrumbItems}/>
      <Box pt="40px">
        <ServiceLists />
      </Box>
    </>
  );
});

export const ServiceIndex = memo(
  withSuspenseAndErrorBoundary(serviceIndex, {
    ErrorComponent: <ErrorContents name="テナント設定管理" />,
    LoadingComponent: <LoadingSpinner />,
  }),
);
