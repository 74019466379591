/* eslint-disable react/require-default-props */
import { Box, BoxProps, Flex } from '@chakra-ui/react';
import { Colors } from 'components/paywall/config';
import { FC, memo } from 'react';

type Props = {
  children: React.ReactNode;
} & BoxProps;
export const CardHeader: FC<Props> = memo(({children, ...rest}: Props) => (
  <Box
    className="paywall-card-header"
    bg={Colors.GRAY_70}
    borderRadius="8px 8px 0 0"
    {...rest}
  >
    <Flex justifyContent="space-between" alignItems="center">
      {children}
    </Flex>
  </Box>
))
