import { CardHeaderTitle } from 'components/paywall/features/Card';
import { ItemDrawer } from 'components/paywall/pages/WallItemList/ItemDrawer';
import { WallItem } from 'components/paywall/pages/WallItemList/typed';
import { FC, memo } from 'react';

type Props = {
  wallItem: WallItem;
}
export const PaywallCardHeader: FC<Props> = memo(({wallItem}: Props) => (
  <CardHeaderTitle id={wallItem.id} idName="単品商品ID：" type="item">
    <ItemDrawer buttonMode="text" buttonName={wallItem.name} obj={wallItem} />
  </CardHeaderTitle>
))
