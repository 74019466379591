import {
  AlertDialog,
  Box,
  Drawer,
  Heading,
  Modal,
  useDisclosure,
} from '@chakra-ui/react';
import { withSuspenseAndErrorBoundary } from 'admin/components/Error/WithErrorBoundary';
import { AutoAppendDialog } from 'admin/components/idPoolConsumer/attributeGroup/attributeGroupDialog/autoAppendDialog';
import { FormAttrDialog } from 'admin/components/idPoolConsumer/attributeGroup/attributeGroupDialog/formAttrDialog';
import { StatusChangeDialog } from 'admin/components/idPoolConsumer/attributeGroup/attributeGroupDialog/statusChangeDialog';
import { AttributeGroupEditDrawer } from 'admin/components/idPoolConsumer/attributeGroup/attributeGroupDrawer/editDrawer';
import { AttributeGroupTableComponent } from 'admin/components/idPoolConsumer/attributeGroup/attributeGroupTable/AttributeGroupTableComponent';
import { CommonMypageChangeModal } from 'admin/components/idPoolConsumer/attributeGroup/commonMyPageModal';
import { useIdPoolConsumerId } from 'admin/hooks/useIdPoolConsumerId';
import { useAttributeGroups } from 'admin/hooks/userPool/attributeGroup/useAttributeGroups';
import { useChangeAttributeGroupDisplayMyPage } from 'admin/hooks/userPool/attributeGroup/useChangeAttributeGroupDisplayMyPage';
import { useUserPoolAuthenticationType } from 'admin/hooks/userPool/useUserPoolAuthenticationType';
import { AttributeGroupType } from 'admin/types/userPool/attributeGroup';
import { Page } from 'api/common/types';
import { ErrorContents } from 'components/common/atoms';
import {
  Dispatch,
  FC,
  memo,
  SetStateAction,
  useCallback,
  useEffect,
  useRef,
  useState,
} from 'react';

type Props = {
  currentPage: Page['currentPage'];
  setPage: Dispatch<SetStateAction<Page>>;
};

const atributeGroupTable: FC<Props> = memo(
  ({ currentPage, setPage }: Props) => {
    const [attributeGroupId, setAttributeGroupId] =
      useState<AttributeGroupType['id']>('');
    const [targetAttributeGroup, setTargetAttributeGroup] =
      useState<AttributeGroupType>();
    const [displayMyPageStatus, setDisplayMyPageStatus] =
      useState<boolean>(false);

    const { isUniikey } = useUserPoolAuthenticationType();
    const { isOpen, onClose, onOpen } = useDisclosure();

    const idPoolConsumerId = useIdPoolConsumerId();

    const { data: attributeGroups, page } = useAttributeGroups(
      idPoolConsumerId,
      currentPage,
    );
    const cancelRef = useRef(null);

    const {
      isOpen: isOpenStatusDialog,
      onClose: onCloseStatusDialog,
      onOpen: onOpenStatusDialog,
    } = useDisclosure();
    const {
      isOpen: isOpenAutoAppendDialog,
      onClose: onCloseAutoAppendDialog,
      onOpen: onOpenAutoAppendDialog,
    } = useDisclosure();
    const {
      isOpen: isOpenFormAttrDialog,
      onClose: onCloseFormAttrDialog,
      onOpen: onOpenFormAttrDialog,
    } = useDisclosure();
    const {
      isOpen: isOpenCommonMyPageModal,
      onClose: onCloseCommonMyPageModal,
      onOpen: onOpenCommonMyPageModal,
    } = useDisclosure();

    const { mutate, isLoading } = useChangeAttributeGroupDisplayMyPage({
      id: idPoolConsumerId,
      attributeGroupId: targetAttributeGroup?.id ?? '',
      status: targetAttributeGroup?.isDisplayAccountSettings ?? false,
    });

    const onOpenDrawer = useCallback(
      (id: AttributeGroupType['id']) => {
        setAttributeGroupId(id);
        onOpen();
      },
      [onOpen],
    );

    const onOpenCommonMyPageModalHandler = useCallback(
      (status: boolean) => {
        setDisplayMyPageStatus(status);
        onOpenCommonMyPageModal();
      },
      [onOpenCommonMyPageModal],
    );

    const onSubmitDisplayMyPageStatus = useCallback(async () => {
      await mutate();
      onCloseCommonMyPageModal();
    }, [mutate, onCloseCommonMyPageModal]);

    useEffect(() => {
      setPage(page);
    }, [page, setPage]);

    if (!attributeGroups) {
      return null;
    }

    if (attributeGroups.length === 0) {
      return (
        <Box
          w="100%"
          textAlign="center"
          bg="white"
          mt={4}
          borderRadius={4}
          padding={10}
        >
          <Heading as="h3" fontWeight="bold" fontSize="24px">
            属性グループが未登録です。
          </Heading>
        </Box>
      );
    }

    return (
      <>
        <AttributeGroupTableComponent
          attributeGroups={attributeGroups}
          isUniikey={isUniikey}
          onOpen={onOpenDrawer}
          setTargetAttributeGroup={setTargetAttributeGroup}
          onOpenStatusDialog={onOpenStatusDialog}
          onOpenAutoAppendDialog={onOpenAutoAppendDialog}
          onOpenFormAttrDialog={onOpenFormAttrDialog}
          onOpenCommonMyPageModalHandler={onOpenCommonMyPageModalHandler}
        />
        <Drawer
          isOpen={isOpen}
          placement="right"
          onClose={onClose}
          size="lg"
          closeOnOverlayClick={false}
          closeOnEsc={false}
          autoFocus={false}
        >
          <AttributeGroupEditDrawer id={attributeGroupId} onClose={onClose} />
        </Drawer>
        <AlertDialog
          isOpen={isOpenStatusDialog}
          onClose={onCloseStatusDialog}
          leastDestructiveRef={cancelRef}
          isCentered
          closeOnOverlayClick={false}
          closeOnEsc={false}
          size="xl"
        >
          <StatusChangeDialog
            onClose={onCloseStatusDialog}
            target={targetAttributeGroup}
          />
        </AlertDialog>
        <AlertDialog
          isOpen={isOpenAutoAppendDialog}
          onClose={onCloseAutoAppendDialog}
          leastDestructiveRef={cancelRef}
          isCentered
          closeOnOverlayClick={false}
          closeOnEsc={false}
          size="xl"
        >
          <AutoAppendDialog
            onClose={onCloseAutoAppendDialog}
            target={targetAttributeGroup}
          />
        </AlertDialog>
        <AlertDialog
          isOpen={isOpenFormAttrDialog}
          onClose={onCloseFormAttrDialog}
          leastDestructiveRef={cancelRef}
          isCentered
          closeOnOverlayClick={false}
          closeOnEsc={false}
          size="xl"
        >
          <FormAttrDialog
            onClose={onCloseFormAttrDialog}
            target={targetAttributeGroup}
          />
        </AlertDialog>
        <Modal
          isOpen={isOpenCommonMyPageModal}
          onClose={onCloseCommonMyPageModal}
          isCentered
          closeOnOverlayClick={false}
          closeOnEsc={false}
          size="xl"
        >
          <CommonMypageChangeModal
            isLoading={isLoading}
            status={displayMyPageStatus}
            onClose={onCloseCommonMyPageModal}
            onSubmit={onSubmitDisplayMyPageStatus}
          />
        </Modal>
      </>
    );
  },
);

export const AttributeGroupTable = memo(
  withSuspenseAndErrorBoundary(atributeGroupTable, {
    ErrorComponent: <ErrorContents name="属性グループ一覧" />,
  }),
);
