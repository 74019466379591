import { DEFAULT_TAX_CONFIG, TAX_CONFIG_LIST } from 'define';

/**
 * 金額系のUtil関数
 * @returns Object {amountFormat, calcTaxAmount}
 */
export const useAmountUtil = (): {
  amountFormat: (num: number) => string;
  calcTaxAmount: (num: number) => string;
} => {
  /**
   * 金額のフォーマット
   * @param {number} num 金額
   * @returns {string} フォーマットされた金額
   */
  const amountFormat = (num: number): string =>
    `${new Intl.NumberFormat().format(num)}`;

  /**
   * 税込金額計算
   */
  const calcTaxAmount = (num: number): string => {
    const current = new Date().getTime();
    const target =
      TAX_CONFIG_LIST.find((x) => {
        const expireFrom = new Date(x.EFFECTIVE_PERIOD.FROM).getTime();
        const expireTo = new Date(x.EFFECTIVE_PERIOD.TO).getTime();

        return expireFrom <= current && current <= expireTo;
      }) || DEFAULT_TAX_CONFIG;

      const resultAmount = amountFormat(Math.floor(num * ((target.TAX + 100) / 100)));

    return resultAmount === 'NaN' ? '---' : resultAmount;
  };

  return { amountFormat, calcTaxAmount };
};
