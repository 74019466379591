import { logger } from 'api/logger';
import { request } from 'api/request';
import { GmoInfo, isGmoInfo } from 'api/tenant/types';
import { isResponseError, ResponseError } from 'api/types';
import produce from 'immer';
import { Options } from 'ky';
import { getValidationError, ValidationError } from 'utils/form';
import { messages } from './messages';


const DEFAULT_ERR_MESSAGE: ValidationError<GmoInfo> = {
  others: [messages.patchGmoInfo.customer.error],
};
const DEFAULT_SYSTEM_ERR_MESSAGE: ValidationError<GmoInfo> = {
  others: [messages.patchGmoInfo.customer.failed],
};

/**
 * GMO情報更新API
 *
 * 状態により以下のレスポンスを返却します
 * 正常時: true
 * エラー: ResponseError
 *
 */
export const patchGmoInfo = async (
  tenantId: string,
  formParam: GmoInfo,
  options?: Options,
): Promise<GmoInfo | ResponseError | boolean> => {
  const path = `tenant/${tenantId}/gmo/`;

  const mergedOptions = options
    ? produce(options, (draft) => {
        draft.json = { ...formParam };
      })
    : { json: formParam };

  const response = await request({
    path,
    method: 'patch',
    options: mergedOptions,
  });

  if (response.status >= 500) {
    return DEFAULT_ERR_MESSAGE;
  }

  const responseData = response.data;

  // Gmo情報更新に成功
  if (isGmoInfo(responseData)) {
    return {
      ...responseData,
    };
  }

  const { error } = response;
  if (response.hasError) {
    // APIにて返却されたエラー形式
    if (isResponseError(error)) {
      const formError = getValidationError<GmoInfo>({
        formData: formParam,
        response: error,
      });

      return formError;
    }
  }

  // 予期せぬエラーなので
  // バックエンドに状態を通知
  await logger({
    loglevel:
      response.status === 404 || response.status === 450 ? 'warning' : 'error',
    data: error,
    message: messages.patchGmoInfo.system.typeError,
  });

  // 登録できていない旨を通知
  return DEFAULT_SYSTEM_ERR_MESSAGE;
};
