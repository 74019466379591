import { createNewsletter } from 'admin/api/userPool/newsletter/createNewsletter';
import { CustomError } from 'admin/error/CustomError';
import { queryNewsletterKeyNames } from 'admin/hooks/userPool/newsletter/queryNewsletterKey';
import { UserPool } from 'admin/types/userPool';
import { NewsletterFormType } from 'admin/types/userPool/newsletter/form';
import { logger } from 'api/logger';
import { useCustomToast } from 'hooks/useCustomToast';
import { Dispatch, SetStateAction } from 'react';
import { UseFormSetError } from 'react-hook-form';
import { useMutation, useQueryClient } from 'react-query';
import { toMultiError } from 'utils/form';
import { errorToast, successToast } from 'utils/toast';

export const useCreateNewsletter = (
  idPoolConsumerId: UserPool['id'],
  setError: UseFormSetError<NewsletterFormType>,
  setGlobalErrors: Dispatch<SetStateAction<string[]>>,
) => {
  const toast = useCustomToast();
  const queryClient = useQueryClient();

  const { mutateAsync, isLoading } = useMutation({
    mutationFn: (data: NewsletterFormType) =>
      createNewsletter({ data, userPoolId: idPoolConsumerId }),
    onSuccess: () => {
      toast({
        ...successToast,
        duration: 4000,
        title: 'メールマガジン購読設定を作成しました',
        position: 'bottom',
        variant: 'solid',
      });
      void queryClient.invalidateQueries(queryNewsletterKeyNames.Newsletter);
    },
    onError: async (error, variables: NewsletterFormType) => {
      if (error instanceof CustomError) {
        if (error.cause.errors?.others) {
          const othersMsgs = Array.isArray(error.cause.errors?.others)
            ? error.cause.errors?.others
            : [error.cause.errors?.others];

          setGlobalErrors(othersMsgs);
        } else {
          Object.keys(variables).forEach((k) => {
            const key = k as keyof NewsletterFormType;
            const errMsgs = error.cause.errors?.[key];
            if (errMsgs && Array.isArray(errMsgs)) {
              setError(key, { types: toMultiError(errMsgs) });
            }
          });
        }

        if (error.cause.errors) {
          await logger({
            loglevel: error.logLevel,
            data: JSON.stringify(error.cause),
            message: error.message,
          });
        }
      }
      toast({
        ...errorToast,
        duration: 4000,
        title: 'メールマガジン購読設定の作成に失敗しました',
        position: 'bottom',
        variant: 'solid',
      });
    },
  });

  return { mutate: mutateAsync, isLoading };
};
