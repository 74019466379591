import { getTerm } from 'admin/api/userPool/terms/getTerm';
import { CustomError } from 'admin/error/CustomError';
import { UserPool } from 'admin/types/userPool';
import { TermType } from 'admin/types/userPool/terms';
import { logger } from 'api/logger';
import { useQuery } from 'react-query';
import { queryKeys } from './queryKeys';

export const useTerm = ({
  id,
  userPoolId,
}: {
  id: TermType['id'];
  userPoolId: UserPool['id'];
}) => {
  const { data, isLoading } = useQuery({
    queryKey: queryKeys.getTerm(id, userPoolId),
    queryFn: () => getTerm({ id, userPoolId }),
    onError: async (error) => {
      if (error instanceof CustomError) {
        await logger({
          loglevel: error.logLevel,
          data: JSON.stringify(error.cause),
          message: error.message,
        });
      }
    },
  });

  return { data, isLoading };
};
