import { Box } from '@chakra-ui/react';
import { withSuspenseAndErrorBoundary } from 'admin/components/Error/WithErrorBoundary';
import { AuthBody } from 'admin/components/service/auth/authBody';
import { AuthTitle } from 'admin/components/service/auth/authTitle';
import { Breadcrumb } from 'admin/components/ui/breadcrumb';
import { Colors } from 'admin/define/colors';
import { BreadcrumbType } from 'admin/types/ui/breadcrumb';
import { ErrorContents, LoadingSpinner } from 'components/common/atoms';
import { useSetPageTitle } from 'hooks/useSetPageTitle';
import { FC, memo } from 'react';

export const auth: FC = memo(() => {
  useSetPageTitle('認証認可とセキュリティ設定');

  const breadcrumbItems: BreadcrumbType[] = [
    { url: '/admin', title: '組織管理TOP' },
    { url: '/admin/service', title: 'テナント設定管理' },
    { url: '', title: '認証認可とセキュリティ設定' },
  ];

  return (
    <>
      <Breadcrumb breadcrumbItems={breadcrumbItems} />
      <Box mt="40px">
        <Box>
          <AuthTitle />
        </Box>
        <Box bgColor={Colors.WHITE} p="24px" mt="16px" borderRadius="4px">
          <AuthBody />
        </Box>
      </Box>
    </>
  );
});

export const Auth = memo(
  withSuspenseAndErrorBoundary(auth, {
    ErrorComponent: <ErrorContents name="認証認可とセキュリティ設定" />,
    LoadingComponent: <LoadingSpinner />,
  }),
);
