import { zodResolver } from '@hookform/resolvers/zod';
import { withSuspenseAndErrorBoundary } from 'admin/components/Error/WithErrorBoundary';
import { StatusChangeFormComponent } from 'admin/components/idPoolConsumer/attribute/statusChangeForm/StatusChangeFormComponent';
import { LoadingLayer } from 'admin/components/ui/loadingLayer';
import { useChangeAttributeStatus } from 'admin/hooks/userPool/attribute/useChangeAttributeStatus';
import { confirmSubmitFormSchema } from 'admin/schema/util';
import { IdPoolConsumerAttributeType } from 'admin/types/userPool/attribute';
import { FC, memo, useCallback, useMemo } from 'react';
import { FormProvider, useForm } from 'react-hook-form';
import { useParams } from 'react-router-dom';

export type Props = {
  target: IdPoolConsumerAttributeType | undefined;
  onClose: () => void;
};

export const statusChangeForm: FC<Props> = memo(({ onClose, target }) => {
  const formId = 'statusChangeForm';
  const params = useParams();
  const idPoolConsumerId = useMemo(
    () => params.idPoolConsumerId ?? '',
    [params],
  );

  const { mutate, isLoading } = useChangeAttributeStatus({
    id: target?.id ?? '',
    userPoolId: idPoolConsumerId,
  });

  const methods = useForm({
    defaultValues: {
      word: '',
    },
    mode: 'onBlur',
    resolver: zodResolver(confirmSubmitFormSchema),
  });

  const onSubmit = useCallback(async () => {
    await mutate();
    onClose();
  }, [mutate, onClose]);

  return (
    <FormProvider {...methods}>
      {isLoading && <LoadingLayer />}
      <StatusChangeFormComponent
        formId={formId}
        isLoading={isLoading}
        onClose={onClose}
        onSubmit={onSubmit}
      />
    </FormProvider>
  );
});

export const StatusChangeForm = withSuspenseAndErrorBoundary(statusChangeForm);
