import { Box, FormControl, FormLabel, ListItem, Text, UnorderedList, useDisclosure } from '@chakra-ui/react';
import { InputText } from 'components/common/atoms';
import { Colors, ColorSet } from 'components/paywall/config';
import { DetailDialog } from 'components/paywall/features/Detail';
import { useDeleteSubscriptionOrder } from 'hooks/paywall/useDeleteSubscriptionOrder';
import { ChangeEvent, FC, memo, useCallback, useState } from 'react';

const dialogConfirmText = 'change';

type Props = {
  orderId: string;
  customerUid?: string;
  isShowButton: boolean;
}

export const DetailOrderCancelDialog: FC<Props> = memo(({orderId, customerUid = '', isShowButton}) => {
  const { isOpen, onOpen, onClose } = useDisclosure();
  const [confirmText, setConfirmText] = useState<string>('');

  // closeボタン押下時
  const closeBtnHandler = useCallback(() => {
    setConfirmText('');
    onClose();
  }, [
    setConfirmText,
    onClose
  ]);
  const { onOrderCancel, isLoading } = useDeleteSubscriptionOrder({wallOrderId: orderId, customerUid, onClose: closeBtnHandler});

  // サブスクリプション自動配信停止 実行ボタン押下処理
  const onStopAutoContractRenewal = useCallback(() => {
    onOrderCancel();
  }, [onOrderCancel]);

  const onChangeText = useCallback((e: ChangeEvent<HTMLInputElement>) => {
    setConfirmText(e.target.value);
  }, []);

  // フォームエラーの存在チェック
  const isFailed = useCallback(
    (): boolean => confirmText !== 'change',
    [confirmText],
  );

  return (
    <DetailDialog
      title="サブスクリプション自動更新停止の確認"
      isOpen={isOpen}
      onOpen={onOpen}
      onClose={onClose}
      openBtnElement="自動更新停止"
      openBtnProps={{
        fontSize: '14px',
        minWidth: '100px',
        disabled: !isShowButton,
        ...ColorSet.RED
      }}
      submitBtnTitle="実行"
      submitBtnProps={{
        colorScheme: 'red',
        disabled: isFailed() || isLoading,
        isLoading,
        minW: '100px',
      }}
      submitBtnHandelr={onStopAutoContractRenewal}
      cancelBtnTitle="キャンセル"
      cancelBtnProps={{
        minW: '100px',
      }}
    >
      <Box
        backgroundColor={Colors.RED_40}
        p={4}
        mb={4}
      >
        <UnorderedList>
          <ListItem fontSize="14px" fontWeight="700" color={Colors.RED_20}>自動更新を停止すると、次の更新タイミングで決済が行われず更新されなくなります。</ListItem>
          <ListItem fontSize="14px" fontWeight="700" color={Colors.RED_20}>既に支払い済みの決済による権利は引き続き有効で、返金も行われません。</ListItem>
        </UnorderedList>
      </Box>
      <Box mb={4}>
        <Text fontSize="14px" fontWeight="400">
          <Text as="span" fontWeight="700" mr={1}>{ orderId || '' }</Text>
          の自動更新を停止しますか？
        </Text>
        <Text fontSize="14px" fontWeight="700" color={Colors.RED_20}>この手続きは取り消しすることが出来ません。</Text>
      </Box>
      <FormControl>
        <FormLabel htmlFor="deleteText" fontSize="sm" fontWeight={700}>
          停止する場合は {dialogConfirmText} と入力してください
        </FormLabel>
        <InputText
          name="deleteText"
          onChange={(e) => onChangeText(e)}
        />
      </FormControl>
    </DetailDialog>
  )
});
