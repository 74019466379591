import { Box, Flex, Text } from '@chakra-ui/react';
import { AlertInfoButton } from 'admin/components/ui/alertInfo';
import { Td, Tr } from 'admin/components/ui/table';
import { Colors } from 'admin/define/colors';
import { TRANSACTION_MAIL_PERSONALS } from 'admin/define/idPoolConsumer/transactionMail';
import { useIdPoolConsumerId } from 'admin/hooks/useIdPoolConsumerId';
import {
  TransactionMailCategoryType,
  TransactionMailTypeStatusType,
} from 'admin/types/userPool/transactionMail';
import { FC, memo } from 'react';
import { useNavigate } from 'react-router-dom';

type Props = {
  personalType: TransactionMailCategoryType;
  transactionMailTypeStatus: TransactionMailTypeStatusType;
};

export const TransactionMailPersonalInnerComponent: FC<Props> = memo(
  ({ personalType, transactionMailTypeStatus }: Props) => {
    const idPoolConsumerId = useIdPoolConsumerId();
    const navigate = useNavigate();

    return (
      <Tr>
        <Td>
          <Box>
            <Text fontSize="14px" fontWeight={700} lineHeight="24px" mb="4px">
              {TRANSACTION_MAIL_PERSONALS.get(personalType)?.name}
            </Text>
            <Text fontSize="12px" lineHeight="20px" color={Colors.GRAY_00}>
              {TRANSACTION_MAIL_PERSONALS.get(personalType)?.description}
            </Text>
          </Box>
        </Td>
        <Td>
          <Flex justifyContent="flex-end" style={{ gap: '1rem' }}>
            <AlertInfoButton
              type="error"
              iconType="error"
              hasAlert={!transactionMailTypeStatus.isActivated}
              width="108px"
              onClick={() =>
                navigate(
                  `/admin/idPoolConsumer/${idPoolConsumerId}/transactionMail/${transactionMailTypeStatus.type}`,
                )
              }
            >
              <Text
                as="span"
                fontSize="14px"
                fontWeight="700"
                lineHeight="24px"
              >
                送信内容設定
              </Text>
            </AlertInfoButton>
          </Flex>
        </Td>
      </Tr>
    );
  },
);
