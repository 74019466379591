import { Alert, AlertIcon, ModalCloseButton, ModalContent, ModalHeader, ModalOverlay, Text } from '@chakra-ui/react';
import { zodResolver } from '@hookform/resolvers/zod';
import { Colors } from 'admin/define/colors';
import { LoginSettingConfirmType } from 'api/tenant/types';
import { loginRedirectConfirmSchema } from 'components/setting/loginRedirect/index.schema';
import { LoginRedirectModalComponent } from 'components/setting/loginRedirect/loginRedirectModal/LoginRedirectModalComponent';
import { FC, memo } from 'react';
import { FormProvider, useForm } from 'react-hook-form';

type Props = {
  isLoading: boolean;
  onSubmit: () => void;
  onClose: () => void;
}

export const LoginRedirectModal: FC<Props> = memo(
  ({
    isLoading,
    onSubmit,
    onClose
  }: Props) => {
  const formId = 'loginRedirectModalForm';

  const methods = useForm<LoginSettingConfirmType>({
    defaultValues: {
      word: '',
    },
    mode: 'onBlur',
    resolver: zodResolver(loginRedirectConfirmSchema),
  });

  return (
    <>
      <ModalOverlay />
      <ModalContent fontSize="md" zIndex={100}>
        <ModalHeader fontSize="lg" fontWeight="bold">
          <Alert padding="0px" bgColor="transparent" textAlign="right">
            <AlertIcon color={Colors.GRAY_03}/>
            <Text fontSize="18px" fontWeight="700">ログインリダイレクト設定</Text>
          </Alert>
        </ModalHeader>
          <FormProvider {...methods}>
            <LoginRedirectModalComponent
              isLoading={isLoading}
              formId={formId}
              onSubmit={onSubmit}
              onClose={onClose} />
          </FormProvider>
          <ModalCloseButton />
      </ModalContent>
    </>
  );
});
