import { withSuspenseAndErrorBoundary } from 'admin/components/Error/WithErrorBoundary';
import { ServiceTableBodyComponent } from 'admin/components/member/service/serviceTableBody/ServiceTableBodyComponent';
import { ServiceMemberType } from 'admin/types/service';
import { ErrorContents } from 'components/common/atoms';
import { FC, memo } from 'react';
import { useNavigate } from 'react-router-dom';

type Props = {
  member: ServiceMemberType;
};

const serviceTableBody: FC<Props> = memo(({ member }: Props) => {
  const navigate = useNavigate();

  return <ServiceTableBodyComponent member={member} navigate={navigate} />;
});

export const ServiceTableBody = withSuspenseAndErrorBoundary(serviceTableBody, {
  ErrorComponent: <ErrorContents name="会員一覧詳細情報" />,
});
