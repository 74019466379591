import { Box, Text } from '@chakra-ui/react';
import { ChartList } from 'api/chart/types';
import { getEnqueteChart } from 'api/enquete/getEnqueteChart';
import { enqueteFormSelectOption } from 'api/enquete/types';
import { BarChart } from 'components/chart/organisms/BarChart';
import { EnqueteChartTextList } from 'components/chart/organisms/EnqueteChartTextList';
import { LineChart } from 'components/chart/organisms/LineChart';
import { PieChart } from 'components/chart/organisms/PieChart';
import { LoadingSpinner } from 'components/common/atoms';
import {
  EnqueteFormOtherOptions,
  EnqueteFormPresetOptions,
  EnqueteFormPrivateOptions,
  EnqueteFormPrivatePresetOptions,
  EnqueteFormSelectDecoOptions,
  EnqueteFormSelectOptions,
} from 'components/enquete/molecules/EnqueteFormElement';
import { useUserTenantId } from 'hooks/user/useUserTenantId';
import { VFC, memo, useCallback, useEffect, useState } from 'react';

type ChartBlockProps = {
  enqueteId: string;
  chart: ChartList;
};

export const ChartBlock: VFC<ChartBlockProps> = memo(
  ({ enqueteId, chart }: ChartBlockProps) => {
    const [chartElement, setChartElement] = useState(<></>);
    const [isReady, setIsReady] = useState(false);
    const [isError, setIsError] = useState(false);
    const [title, setTitle] = useState('');
    const tenantId = useUserTenantId();

    // 取得したアンケートのコンテンツIDより対象のグラフ情報を取得する
    const enqueteChart = useCallback(async () => {
      const chartInfo = await getEnqueteChart(tenantId, enqueteId, chart.id);

      return chartInfo;
    }, [chart, enqueteId, tenantId]);

    // 取得したグラフ情報よりチャートエレメントを作成する
    const createChartElement = (chartData: ChartList): void => {
      switch (chartData.type) {
        case 1:
          setChartElement(
            <LineChart
              text={chartData.text}
              labels={chartData.labels}
              data={chartData.data}
            />,
          );
          break;

        case 2:
          setChartElement(
            <BarChart
              text={chartData.text}
              labels={chartData.labels}
              data={chartData.data}
            />,
          );
          break;

        case 3:
          setChartElement(
            <PieChart
              text={chartData.text}
              labels={chartData.labels}
              data={chartData.data}
            />,
          );
          break;

        default:
          setChartElement(<></>);
          break;
      }
    };

    useEffect(() => {
      // EnqueteFormElementにて設定されている全てのブロック情報を取得
      const enqueteBlockList: enqueteFormSelectOption[] = [
        ...EnqueteFormSelectDecoOptions,
        ...EnqueteFormSelectOptions,
        ...EnqueteFormPrivatePresetOptions,
        ...EnqueteFormPresetOptions,
        ...EnqueteFormPrivateOptions,
        ...EnqueteFormOtherOptions,
      ];
      // 表示制御を行うアンケートブロックを取得
      const target = enqueteBlockList.find((x) => x.value === chart.typeString);
      // 表示を行うかどうか
      const isShow = target === undefined ? true : target.isChart;

      // API呼び出しを行わない場合の処理
      if (target !== undefined && !isShow) {
        setTitle(chart.title || target.name);
        setIsError(true);
        setIsReady(true);

        return;
      }
      if (chart.typeString === 'text') {
        // フリーアンサー API呼び出しを行う場合の処理
        setChartElement(
          <EnqueteChartTextList
            tenantId={tenantId}
            enqueteId={enqueteId}
            chartId={chart.id}
          />,
        );
        setTitle(chart.title || target?.name || '');
        setIsReady(true);
      } else {
        // フリーアンサー以外 API呼び出しを行う場合の処理
        enqueteChart()
          .then((res) => {
            createChartElement(res);
          })
          .catch(() => {
            setIsError(true);
          })
          .finally(() => {
            setTitle(chart.title || target?.name || '');
            setIsReady(true);
          });
      }
    }, [enqueteChart, chart, enqueteId, tenantId]);

    if (!isReady) return <LoadingSpinner />;

    if (isError)
      return (
        <Box
          border="1px"
          borderColor="gray.200"
          height={150}
          boxShadow="md"
          px={16}
          py={5}
          position="relative"
          mb={4}
        >
          <Text
            fontSize="xl"
            fontWeight="bold"
            color="#999"
            mb="4"
            _after={{
              content: '""',
              display: 'block',
              height: '4px',
              background: [
                '-webkit-linear-gradient(to right, rgb(230, 90, 90), transparent)',
                'linear-gradient(to right, rgb(230, 90, 90), transparent)',
              ],
            }}
          >
            {title}
          </Text>
          <Text
            position="absolute"
            top="65%"
            left="50%"
            transform="translate(-50%, -50%)"
          >
            グラフ表示できない設問です
          </Text>
        </Box>
      );

    return (
      <Box
        border="1px"
        borderColor="gray.200"
        boxShadow="md"
        px={16}
        py={5}
        position="relative"
        mb={4}
        overflowX="scroll"
      >
        <Text
          fontSize="xl"
          fontWeight="bold"
          color="#999"
          mb="4"
          _after={{
            content: '""',
            display: 'block',
            height: '4px',
            background: [
              '-webkit-linear-gradient(to right, rgb(230, 90, 90), transparent)',
              'linear-gradient(to right, rgb(230, 90, 90), transparent)',
            ],
          }}
        >
          {title}
        </Text>
        {chartElement}
      </Box>
    );
  },
);
