import { VFC, useCallback, useEffect } from 'react';
import { useFormContext } from 'react-hook-form';
import {
  FormControl,
  FormLabel,
  Icon,
  Text,
  useDisclosure,
} from '@chakra-ui/react';
import { MdCreate } from 'react-icons/md';
import { EditGroupFormType } from 'api/tenant/types';
import { InputTextForm, Dialog } from 'components/common/atoms';
import { useEditGroup } from 'hooks/tenant/useEditGroup';

/**
 * EditGroupDialogProps
 */
type EditGroupDialogProps = {
  formId: string;
  groupId: string;
  tenantId: string;
  parentId: string;
  groupName: string;
  openBtnElemType?: 'button' | 'text';
};

export const EditGroupDialog: VFC<EditGroupDialogProps> = ({
  formId,
  groupId,
  tenantId,
  parentId,
  groupName,
  openBtnElemType = 'button',
}) => {
  const { isOpen, onOpen, onClose } = useDisclosure();
  const {
    reset,
    handleSubmit,
    setValue,
    formState: { errors },
  } = useFormContext<EditGroupFormType>();
  const { onSubmit, isLoading, isSuccess } = useEditGroup();

  // グループ情報編集ポップアップ表示
  const deleteGroupBtnHandler = useCallback(() => {
    setValue('name', groupName);
    setValue('tenantId', tenantId);
    setValue('groupId', groupId);
    setValue('parentId', parentId);
    onOpen();
  }, [groupName, tenantId, groupId, parentId, setValue, onOpen]);

  // グループ情報編集処理
  const onEditGroupSubmit = useCallback(
    (data: EditGroupFormType): void => {
      onSubmit(data);
    },
    [onSubmit],
  );

  // closeボタン押下時
  const closeBtnHandler = useCallback(() => {
    reset();
    onClose();
  }, [reset, onClose]);

  useEffect(() => {
    if (isSuccess) {
      // ポップアップ非表示
      onClose();
    }
  }, [isSuccess, onClose]);

  // フォームエラーの存在チェック
  const isFailed = useCallback(
    (): boolean => Object.keys(errors).length > 0,
    [errors],
  );

  /**
   * 入力用バリデーション
   */
  const validateAttr = {
    required: 'グループ名を入力してください',
  };

  return (
    <>
      <form
        id={`${formId}-${groupId}`}
        onSubmit={handleSubmit((data) => onEditGroupSubmit(data))}
      >
        <Dialog
          title="グループ名修正"
          isOpen={isOpen}
          onOpen={deleteGroupBtnHandler}
          onClose={closeBtnHandler}
          openBtnElemType={openBtnElemType}
          openBtnChildNode={
            <>
              <Icon as={MdCreate} fontSize={20} mr={2} />
              <Text as="span">グループ名修正</Text>
            </>
          }
          openBtnProps={{
            variant: 'ghost',
            color: 'blue.400',
          }}
          submitBtnTitle="登録"
          submitBtnProps={{
            colorScheme: 'blue',
            type: 'submit',
            form: `${formId}-${groupId}`,
            disabled: isFailed(),
            isLoading: isLoading === true,
          }}
        >
          <FormControl>
            <FormLabel htmlFor="name" fontSize="sm">
              グループ名
            </FormLabel>
            <InputTextForm<EditGroupFormType>
              name="name"
              type="text"
              placeholder=""
              value=""
              attr={validateAttr}
            />
          </FormControl>
        </Dialog>
      </form>
    </>
  );
};
