import { patchPromotionPublish } from 'api/paywall/patchPromotionPublish';
import { ResponseError } from 'api/types';
import { useSearch } from 'components/opt/hooks/useSearch';
import { PromotionPublishDefaultValues } from 'components/paywall/pages/PromotionList/initial';
import {
  isPromotion,
  Promotion,
  PromotionPublish,
} from 'components/paywall/pages/PromotionList/typed';
import { usePaywallUtil } from 'hooks/paywall/usePaywallUtil';
import { useCustomToast } from 'hooks/useCustomToast';
import { UseMutateFunction, useMutation } from 'react-query';
import { isGetValidationError } from 'utils/form';

type UnPromisify<T> = T extends Promise<infer U> ? U : T;

/**
 * プロモーションステータス変更hooks
 * @returns {Object} { onSubmit, isLoading }
 */
export const usePromotionPublish = (): {
  onSubmit: UseMutateFunction<
    UnPromisify<ReturnType<typeof patchPromotionPublish>>,
    unknown,
    PromotionPublish,
    unknown
  >;
  isLoading: boolean;
} => {
  const { makeApiQuery } = useSearch();
  const param = makeApiQuery();
  const {
    paywallCourseListRefetchQueries,
    paywallPromotionListRefetchQueries,
  } = usePaywallUtil();
  const toast = useCustomToast();

  const { mutate: onSubmit, isLoading } = useMutation(
    (promotionData: PromotionPublish) => patchPromotionPublish(promotionData),
    {
      onSuccess: (result: Promotion | ResponseError) => {
        paywallPromotionListRefetchQueries(param);
        paywallCourseListRefetchQueries();
        if (isPromotion(result)) {
          const isPublicStr = result.status ? '有効' : '無効';
          toast({
            status: 'success',
            position: 'bottom',
            duration: 2000,
            isClosable: true,
            title: `プロモーション「${result.name}」を${isPublicStr}に設定しました`,
          });
        }

        if (
          isGetValidationError<PromotionPublish>(
            result,
            Object.keys(PromotionPublishDefaultValues),
          )
        ) {
          const errorMsg = Object.entries(result).map(([_, value]) => value);
          toast({
            status: 'error',
            position: 'bottom',
            duration: 4000,
            isClosable: true,
            title: errorMsg[0],
          });
        }
      },
    },
  );

  return { onSubmit, isLoading };
};
