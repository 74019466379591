import { request } from 'admin/api/Request';
import { CustomError } from 'admin/error/CustomError';
import { messages } from 'api/transactionMail/messages';
import { FirstLoginMailType } from 'api/transactionMail/type';
import { FirstLoginMailSchema } from 'schema/transactionMail/index';
import { camelCase } from 'utils/str';
import { ZodError } from 'zod';

export const changeSendingContentStatus = async ({
  tenantId,
  id,
  status,
}: {
  tenantId: string;
  id: FirstLoginMailType['id'];
  status: FirstLoginMailType['status'];
}): Promise<FirstLoginMailType> => {
  const path = `tenant/${tenantId}/transaction_mails/${id}/`;
  const response = await request<FirstLoginMailType>({
    path,
    body: {
      status: !status,
    },
    method: 'patch',
  });

  if (response.hasError) {
    if (response.status === 404) {
      throw new CustomError(messages.changeMailSettingStatus.customer.failed, {
        path,
        status: response.status,
      });
    } else {
      throw new CustomError(messages.changeMailSettingStatus.customer.error, {
        path,
        status: response.status,
      });
    }
  }

  // メール情報に含まれるtypeのデータを修正する
  const firstLoginMailData = {
    ...response.data,
    type: camelCase(response.data.type),
  } as FirstLoginMailType;

  try {
    FirstLoginMailSchema.parse(firstLoginMailData);
  } catch (error) {
    if (error instanceof ZodError) {
      throw new CustomError(error.message, {
        path,
        status: response.status,
        logLevel: 'error',
      });
    }
  }

  return firstLoginMailData;
};
