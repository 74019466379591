import { request } from 'admin/api/Request';
import { API_MESSAGES } from 'admin/define/apiMessages';
import { CustomError } from 'admin/error/CustomError';
import { authSchema } from 'admin/schema/service/auth';
import { AuthType } from 'admin/types/service/auth';
import { AuthFormOutputType } from 'admin/types/service/auth/form';
import { isResponseError } from 'api/types';
import { getValidationError } from 'utils/form';
import { ZodError } from 'zod';

export const updateRedirectUri = async ({
  userPoolChildId,
  data,
}: {
  userPoolChildId: string;
  data: AuthFormOutputType;
}): Promise<AuthType> => {
  const path = `uniikey/${userPoolChildId}/child/security_settings/redirect_uri/`;
  const params = data;

  const response = await request<AuthType>({
    path,
    body: params,
    method: 'patch',
  });

  if (response.hasError) {
    if (response.status === 404) {
      throw new CustomError(
        API_MESSAGES.SERVICE.UPDATE_SETTING_REDIRECT_URI.FAILED,
        {
          path,
          status: response.status,
        },
      );
    } else if (isResponseError(response.error)) {
      const customError = response.error;
      const formError = getValidationError<AuthFormOutputType>({
        formData: data,
        response: customError,
      });

      throw new CustomError(
        API_MESSAGES.SERVICE.UPDATE_SETTING_REDIRECT_URI.ERROR,
        {
          path,
          params,
          errors: formError,
          status: response.status,
          logLevel: response.status === 400 ? 'warning' : 'error',
        },
      );
    } else {
      throw new CustomError(
        API_MESSAGES.SERVICE.UPDATE_SETTING_REDIRECT_URI.ERROR,
        {
          path,
          status: response.status,
        },
      );
    }
  }
  try {
    authSchema.safeParse(response.data);
  } catch (error) {
    if (error instanceof ZodError) {
      throw new CustomError(error.message, {
        path,
        status: response.status,
        logLevel: 'error',
      });
    }
  }

  return response.data;
};
