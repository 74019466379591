export type CustomerDetailMemo = {
  createUserName: string;
  createdAt: string;
  customerUid: string;
  memo: string;
  orderId: string;
};

export type CustomerDetailOrder = {
  id: string;
  courseId: string;
  courseName: string;
  tranErrorInfo: string;
  tranErrorMessageCustomer: string;
  nextTranDate: string;
  isNextTran: boolean;
  nextPrice: number;
  nextPromotionName: string;
  tranId: string;
  execTranDate: string;
  price: number;
  rightsExpirationDate: string;
  cancelReason: number;
};

export type Customer = {
  isActive: true;
  zip: string;
  prefName: string;
  address: string;
  lastName: string;
  firstName: string;
  tel: string;
}

export type CustomerDetail = {
  customer: Customer;
  customerUid: string;
  email: string;
  name: string;
  address: string;
  tel: string;
  order: CustomerDetailOrder[];
  memos: CustomerDetailMemo[];
};

export type ApiCustomerDetail = {
  data: CustomerDetail;
  error: number;
  errorMessage: string;
  hasError: boolean;
  responseType: string;
  status: number;
  url: string;
};

export type CustomerListResultOrder = {
  id: string;
  courseId: string;
  courseName: string;
};

export type CustomerListResult = {
  customerUid: string;
  email: string;
  orders: CustomerListResultOrder[];
};

// こちらの型ガードは廃止する
export const isCustomerDetail = (data: unknown): data is CustomerListResult => {
  const el = data as CustomerDetail;

  return (
    typeof el?.customerUid === 'string' &&
    typeof el?.email === 'string' &&
    typeof el?.name === 'string' &&
    typeof el?.address === 'string' &&
    typeof el?.tel === 'string' &&
    typeof el?.order === 'object' &&
    typeof el?.memos === 'object'
  );
};

export type CustomerList = {
  count: number;
  currentPage: number;
  countFrom: number;
  countTo: number;
  perPage: number;
  pageCount: number;
  next: string;
  previous: string;
  results: CustomerListResult[];
};

export const isCustomerList = (data: unknown): data is CustomerList => {
  const el = data as CustomerList;

  return (
    typeof el?.count === 'number' &&
    typeof el?.currentPage === 'number' &&
    typeof el?.countTo === 'number' &&
    typeof el?.perPage === 'number' &&
    typeof el?.pageCount === 'number' &&
    typeof el?.next === 'string' &&
    typeof el?.previous === 'string' &&
    typeof el?.results === 'object'
  );
};

export type ApiCustomerList = {
  data: CustomerList;
  error: number;
  errorMessage: string;
  hasError: boolean;
  responseType: string;
  status: number;
  url: string;
};

export const isCustomerDetailOrder = (data: unknown): data is CustomerDetailOrder => {
  const el = data as CustomerDetailOrder;

  return (
    typeof el?.id === 'string' &&
    typeof el?.courseId === 'string' &&
    typeof el?.courseName === 'string' &&
    typeof el?.tranErrorInfo === 'string' &&
    typeof el?.tranErrorMessageCustomer === 'string' &&
    typeof el?.nextTranDate === 'string' &&
    typeof el?.isNextTran === 'boolean' &&
    typeof el?.nextPrice === 'number' &&
    typeof el?.nextPromotionName === 'string' &&
    typeof el?.tranId === 'string' &&
    typeof el?.execTranDate === 'string' &&
    typeof el?.price === 'number' &&
    typeof el?.rightsExpirationDate === 'string' &&
    typeof el?.cancelReason === 'string'
  );
};

export const isCustomerDetailOrders = (datas: unknown[]): datas is CustomerDetailOrder[] =>
  !datas.some((d) => !isCustomerDetailOrder(d));

export const isCustomerDetailMemo = (data: unknown): data is CustomerDetailMemo => {
  const el = data as CustomerDetailMemo;

  return (
    typeof el?.createUserName === 'string' &&
    typeof el?.createdAt === 'string' &&
    typeof el?.customerUid === 'string' &&
    typeof el?.memo === 'string' &&
    typeof el?.orderId === 'string'
  );
};

export const isCustomerDetailMemos = (datas: unknown[]): datas is CustomerDetailMemo[] =>
  !datas.some((d) => !isCustomerDetailMemo(d));

// こちらの型ガードを残す
export const isCustomerDetailType = (data: unknown): data is CustomerDetail => {
  const el = data as CustomerDetail;

  return (
    typeof el?.customerUid === 'string' &&
    typeof el?.email === 'string' &&
    typeof el?.name === 'string' &&
    typeof el?.address === 'string' &&
    typeof el?.tel === 'string' &&
    isCustomerDetailOrders(el?.order) &&
    isCustomerDetailMemo(el?.memos)
  );
};
