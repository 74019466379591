import { Options } from 'ky';
import { request } from 'api/request';
import { logger } from 'api/logger';
import { CustomError } from 'api/error/CustomError';
import { MailSetting, isMailSetting } from './types';
import { messages } from './messages';

// メール配信設定情報取得API
export const getMailSetting = async (
  tenantId: string,
  mailId: string,
  options?: Options,
): Promise<MailSetting> => {
  const path = `mail/${tenantId}/${mailId}/`;

  const response = await request({
    path,
    method: 'get',
    options,
  });

  const responseData = (await response.data) as unknown[];

  if (response.hasError) {
    throw new CustomError(messages.getMailSetting.customer.error);
  }

  if (!isMailSetting(responseData)) {
    await logger({
      loglevel:
        response.status === 404 || response.status === 450
          ? 'warning'
          : 'error',
      data: responseData,
      message: messages.getMailSetting.system.typeError,
    });

    throw new CustomError(messages.getMailSetting.customer.failed);
  }

  return responseData;
};
