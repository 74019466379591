import { UseMutateFunction, useMutation } from 'react-query';
import { UseFormSetError } from 'react-hook-form';
import { useParams, useNavigate } from 'react-router-dom';
import { putPasswordReset } from 'api/user/putPasswordReset';
import { ResponseError } from 'api/types';
import { PasswordResetForm } from 'api/user/types';
import { isGetValidationError, toMultiError } from 'utils/form';
import { useCustomToast } from 'hooks/useCustomToast';

type UnPromisify<T> = T extends Promise<infer U> ? U : T;

const userTenantDeleteData: PasswordResetForm = {
  uuid: '',
  password: '',
  passwordConfirm: '',
};

/**
 * パスワード再設定 hooks
 * @returns {Object} { onSubmit, isLoading }
 */
export const usePasswordResetId = ({
  setError,
  setGlobalErrors,
}: {
  setError: UseFormSetError<PasswordResetForm>;
  setGlobalErrors: (errors: string[]) => void;
}): {
  onSubmit: UseMutateFunction<
    UnPromisify<ReturnType<typeof putPasswordReset>>,
    unknown,
    PasswordResetForm,
    unknown
  >;
  isLoading: boolean;
  isSuccess: boolean;
} => {
  const navigate = useNavigate();
  const toast = useCustomToast();
  const { uuid = '' } = useParams();

  const {
    mutate: onSubmit,
    isLoading,
    isSuccess,
  } = useMutation(
    ({ password }: PasswordResetForm) => putPasswordReset({ uuid, password }),
    {
      onSuccess: (result: boolean | ResponseError) => {
        if (typeof result === 'boolean' && result) {
          toast({
            status: 'success',
            position: 'bottom',
            duration: 2000,
            isClosable: true,
            title: 'リセットしました',
          });
          // 未ログイン時のTOPページへ遷移する
          navigate('/');

          return;
        }

        if (
          isGetValidationError<PasswordResetForm>(
            result,
            Object.keys(userTenantDeleteData),
          )
        ) {
          if (result?.others) {
            setGlobalErrors([result?.others?.join('')]);
          } else {
            Object.keys(result).forEach((k) => {
              const key = k as keyof PasswordResetForm;
              const errMsgs = result?.[key];
              if (errMsgs) {
                setError(key, { types: toMultiError(errMsgs) });
              }
            });
          }
        }
      },
    },
  );

  return { onSubmit, isLoading, isSuccess };
};
