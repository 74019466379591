import { Box, Text } from '@chakra-ui/react';
import { withSuspenseAndErrorBoundary } from 'admin/components/Error/WithErrorBoundary';
import { MemberDetailLogContents } from 'admin/components/member/detail/log/memberDetailLogContents';
import { Breadcrumb } from 'admin/components/ui/breadcrumb';
import { BreadcrumbType } from 'admin/types/ui/breadcrumb';
import { ErrorContents, LoadingSpinner } from 'components/common/atoms';
import { useSetPageTitle } from 'hooks/useSetPageTitle';
import { FC, memo, useMemo } from 'react';
import { useParams } from 'react-router-dom';

export const memberLog: FC = memo(() => {
  const params = useParams();
  const memberId = useMemo(() => params.memberId ?? '', [params.memberId]);

  useSetPageTitle('利用履歴一覧');

  const breadcrumbItems: BreadcrumbType[] = [
    { url: '/admin', title: '組織管理TOP' },
    { url: '/admin/member', title: '会員管理' },
    { url: `/admin/member/${memberId}`, title: '会員詳細' },
    { url: '', title: '利用履歴一覧' },
  ];

  return (
    <>
      <Breadcrumb breadcrumbItems={breadcrumbItems} />
      <Box mt="40px">
        <Text
          fontFamily="Hiragino Sans"
          fontSize="18px"
          fontWeight="700"
          lineHeight="170%"
        >
          利用履歴一覧
        </Text>
      </Box>
      <MemberDetailLogContents />
    </>
  );
});

export const MemberLog = withSuspenseAndErrorBoundary(memberLog, {
  ErrorComponent: <ErrorContents name="利用履歴一覧" />,
  LoadingComponent: <LoadingSpinner />,
});
