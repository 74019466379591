import { VFC, memo, Suspense } from 'react';
import { useParams } from 'react-router-dom';
import { Box, Text } from '@chakra-ui/react';
import ErrorBoundary from 'api/ErrorBoundary';
import { useErrorBoundaryReset } from 'hooks/useErrorBoundaryReset';
import { useUserTenantId } from 'hooks/user/useUserTenantId';
import { useGroupInfo } from 'hooks/tenant/useGroupInfo';
import { LoadingSkeleton, ErrorContents } from 'components/common/atoms';
import { GroupsUserList } from 'components/setting/groups/organisms/GroupsUserList';

export const GroupsBody: VFC = memo(() => {
  const { ebKey, onError } = useErrorBoundaryReset();
  const { groupId = '' } = useParams();
  const userTenantId = useUserTenantId();
  const group = useGroupInfo({ tenantId: userTenantId, groupId });

  return (
    <>
      <Text mb={4}>
        <Box as="span" fontSize="xl" pr={2}>
          {group?.name}
        </Box>
        に所属のユーザー
      </Text>
      <ErrorBoundary
        errorComponent={<ErrorContents name="グループユーザー" />}
        onError={onError}
        key={ebKey.current}
      >
        <Suspense fallback={<LoadingSkeleton />}>
          <GroupsUserList />
        </Suspense>
      </ErrorBoundary>
    </>
  );
});
