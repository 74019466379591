/* eslint-disable react/require-default-props */
import {
  Box,
  Drawer as ChakraDrawer,
  DrawerBody,
  DrawerCloseButton,
  DrawerContent,
  DrawerFooter,
  DrawerHeader,
  DrawerOverlay,
  DrawerProps,
  Flex,
} from '@chakra-ui/react';
import { Button } from 'components/paywall/atoms/Button';
import { ColorSet } from 'components/paywall/config/Colors';
import { CopyIdWithMenu } from 'components/paywall/features/CopyIdWithMenu';
import { usePaywallUtil } from 'components/paywall/hooks/usePaywallUtil';
import { FC } from 'react';
import { FieldValues, Path, useFormContext } from 'react-hook-form';
import { AiFillInfoCircle } from 'react-icons/ai';

type Props<T> = {
  type: Path<T>;
  headerLabel?: string | null;
  icon?: React.ReactElement;
  title?: React.ReactNode;
  children: React.ReactNode;
  disabled?: boolean;
  isLoading?: boolean;
  submitButtonName?: string;
  isOpen: boolean;
  onClose: () => void;
  onSubmit: () => void;
  placement?: DrawerProps['placement'];
  size?: DrawerProps['size'];
  isAdd?: boolean;
  drawerType?: 'item';
};
/**
 * @see 汎用ドロワー
 * @param icon ドロワータイトルのアイコン
 * @param title ドロワーのタイトル
 * @param children ドロワーの内容
 * @param disabled Submitボタンの可否
 * @param isLoading ボタンLoading
 * @param submitButtonName Submitボタンの名前
 * @param buttonTextColor ドロワーボタンのテキストカラー
 * @param submitButtonName 実行ボタン（デフォルトは保存）
 * @param isOpen 開閉の判定
 * @param onClose ドロワーのクローズ
 * @param onSubmit ドロワーの実行
 * @param placement ドロワーの位置（デフォルトは右）
 * @param size ドロワーのサイズ（デフォルトはmd）
 */
export const Drawer = <T extends FieldValues>({
  type,
  headerLabel = null,
  icon,
  title,
  children,
  disabled,
  isLoading,
  submitButtonName = '保存',
  isOpen,
  onClose,
  onSubmit,
  placement = 'right',
  size = 'sm',
  isAdd,
  drawerType,
}: Props<T>): ReturnType<FC> => {
  const { isOperationAuth } = usePaywallUtil();
  const { getValues } = useFormContext<T>();

  return (
    <>
      <ChakraDrawer
        isOpen={isOpen}
        placement={placement}
        onClose={onClose}
        size={size}
        closeOnOverlayClick={false}
        closeOnEsc={false}
      >
        <DrawerOverlay />
        <DrawerContent>
          <DrawerHeader borderWidth="1px" pb="24px">
            <Flex alignItems="center" style={{ color: '#444', gap: 5 }}>
              {icon ? (
                <Box>{icon}</Box>
              ) : (
                <Box style={{ height: 24 }}>
                  {title && (
                    <AiFillInfoCircle style={{ width: 24, height: 24 }} />
                  )}
                </Box>
              )}
              {title}
              <Box>
                <DrawerCloseButton />
              </Box>
            </Flex>
            {!isAdd && !!headerLabel && (
              <Flex style={{ fontSize: 14, alignItems: 'center' }} pt="24px">
                <Box mr={6}>{headerLabel}:</Box>
                <CopyIdWithMenu id={getValues(type)} type={drawerType} />
              </Flex>
            )}
          </DrawerHeader>
          <DrawerBody pt="16px" pb="16px">
            {children}
          </DrawerBody>
          <DrawerFooter borderWidth="1px">
            {onSubmit && (
              <>
                <Button
                  {...ColorSet.WHITE}
                  mr={3}
                  onClick={onClose}
                  padding="8px 16px"
                >
                  キャンセル
                </Button>
                <Button
                  disabled={disabled || !isOperationAuth()}
                  {...ColorSet.BLUE}
                  onClick={onSubmit}
                  isLoading={isLoading}
                  padding="8px 16px"
                >
                  {submitButtonName}
                </Button>
              </>
            )}
          </DrawerFooter>
        </DrawerContent>
      </ChakraDrawer>
    </>
  );
};
