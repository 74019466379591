import { WarningTwoIcon } from '@chakra-ui/icons';
import {
  Box,
  Flex,
  Link as EditPageLink,
  Switch,
  Text,
  Tooltip,
} from '@chakra-ui/react';
import { Colors } from 'components/paywall/config';
import { useCommonDialog } from 'components/paywall/hooks/contexts/CommonDialog';
import {
  MailSettingPersonalForm,
  MailSettingStatuses,
} from 'components/paywall/pages/MailSetting/typed';
import {
  convertStatusTitle,
  convertStatusTitleMessage,
} from 'components/paywall/pages/MailSetting/utils';
import { useUpdateMailSettingPersonalActivate } from 'hooks/paywall/useUpdateMailSettingPersonalActivate';
import { FC, memo, useCallback } from 'react';
import { useNavigate } from 'react-router-dom';

export type Props = {
  type: keyof MailSettingStatuses;
  checked: boolean;
  isDisabled: boolean;
  message: string;
};

export const MailSettingPersonalItem: FC<Props> = memo(
  ({ type, checked, isDisabled, message }) => {
    const navigate = useNavigate();
    const { openDialog, closeDialog } = useCommonDialog();
    const { onSubmit } = useUpdateMailSettingPersonalActivate({ type });

    const onClickEditPageLink = useCallback(() => {
      navigate('/paywall/mail/edit', { state: { type, checked } });
    }, [navigate, type, checked]);

    /**
     * ステータス変更処理
     */
    const onChangeStatus = useCallback(() => {
      const sendData: MailSettingPersonalForm = {
        subject: '',
        bodyText: '',
        status: !checked,
      };
      const dialogMsg = checked
        ? '設定後、送信は停止されます。変更しますか？'
        : '設定後、メール送信されるようになります。変更しますか？';
      openDialog({
        title: convertStatusTitle(type),
        message: dialogMsg,
        cancelBtnCaption: '閉じる',
        okBtnCaption: '変更する',
        okBtnColor: 'red',
        cancelBtnColor: 'gray',
        onOk: () => {
          onSubmit(sendData);
          closeDialog();
        },
        onCancel: () => {
          closeDialog();
        },
      });
    }, [checked, closeDialog, onSubmit, openDialog, type]);

    return (
      <Box>
        <Flex fontFamily="Hiragino Sans">
          <EditPageLink
            ml="10px"
            fontSize="16px"
            fontWeight="700"
            color={Colors.BLUE_20}
            onClick={onClickEditPageLink}
            _before={{
              content: '"・"',
              display: 'inline-block',
            }}
          >
            {convertStatusTitle(type)}
          </EditPageLink>
        </Flex>
        <Text
          m="0px 0px 8px 24px"
          mt="4px"
          mb="8px"
          color={Colors.GRAY_00}
          fontFamily="Hiragino Sans"
        >
          {convertStatusTitleMessage(type)}
        </Text>
        {/* Switchが有効な時はTooltipは非活性 */}
        <Flex ml="24px" alignItems="center">
          <Tooltip
            hasArrow
            isDisabled={!isDisabled}
            label={message}
            role="tooltip"
          >
            <Box display="inline-block" data-testid={`${type}-box`}>
              <Switch
                onChange={onChangeStatus}
                isChecked={checked}
                isDisabled={isDisabled}
                name={type}
              />
            </Box>
          </Tooltip>
          <Text as="span" fontSize="14px" color="#444" ml="5px">
            {checked ? '有効' : '無効'}
          </Text>
          {isDisabled && <WarningTwoIcon color="red" fontSize={16} ml="4px" />}
        </Flex>
      </Box>
    );
  },
);
