import { Box, Button, Flex, Text } from '@chakra-ui/react';
import { FC, memo } from 'react';
import { MdOutlineAdd } from 'react-icons/md';

type Props = {
  transactionMailName: string;
  onClick: () => void;
};

export const TransactionMailsTitleComponent: FC<Props> = memo(
  ({ transactionMailName, onClick }: Props) => (
    <Box mt="40px">
      <Flex justifyContent="space-between">
        <Text
          fontFamily="Hiragino Sans"
          fontSize="18px"
          fontWeight="700"
          lineHeight="170%"
        >
          {transactionMailName} 送信内容設定
        </Text>
        <Button
          colorScheme="primary"
          p="8px 24px"
          onClick={onClick}
          h="40px"
          fontWeight={700}
          fontSize="14px"
        >
          <MdOutlineAdd size="20px" />
          <Text as="span" ml="4px">
            メール設定作成
          </Text>
        </Button>
      </Flex>
    </Box>
  ),
);
