import { Box, Flex, VStack } from '@chakra-ui/react';
import { useSetPageTitle } from 'hooks/useSetPageTitle';
import { FC, memo } from 'react';
import { Outlet } from 'react-router-dom';

export const TransactionMail: FC = memo(() => {
  useSetPageTitle('トランザクションメール設定');

  return (
    <VStack>
      <Flex w="full" flex={1}>
        <Box w="full">
          <Outlet />
        </Box>
      </Flex>
    </VStack>
  );
});
