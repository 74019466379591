import {
  AlertDialogBody,
  AlertDialogFooter,
  Box,
  Button,
  FormControl,
  FormErrorMessage,
  Heading,
  Link,
  ListItem,
  Text,
  UnorderedList,
} from '@chakra-ui/react';
import { RhfInput } from 'admin/components/form/RhfInput';
import { RhfSelect } from 'admin/components/form/RhfSelect';
import { AlertInfoBar } from 'admin/components/ui/alertInfo/alertInfoBar';
import { RequiredIcon } from 'admin/components/ui/icon/requiredIcon';
import { LoadingLayer } from 'admin/components/ui/loadingLayer';
import { Colors, ColorSet } from 'admin/define/colors';
import { ServiceType } from 'admin/types/service';
import { ServiceFormType } from 'admin/types/service/form';
import { IdPoolConsumerList } from 'admin/types/userPool';
import { AUTH_CODE_TYPE_OUTER, AUTH_CODE_TYPE_UNIIKEY } from 'define';
import { FC, memo, useMemo } from 'react';
import { useFormContext, useFormState } from 'react-hook-form';
import { Link as ReactRouterLink } from 'react-router-dom';
import { toErrMsgList } from 'utils/form';

type Props = {
  service: ServiceType;
  serviceUserPoolList: IdPoolConsumerList;
  isLoading: boolean;
  formId: string;
  onClose: () => void;
  onSubmit: (value: ServiceFormType) => void;
};

export const ServiceFormComponent: FC<Props> = memo(
  ({
    service,
    serviceUserPoolList,
    isLoading,
    formId,
    onClose,
    onSubmit,
  }: Props) => {
    const { handleSubmit } = useFormContext<ServiceFormType>();
    const { errors, isValid } = useFormState<ServiceFormType>();
    const hasUserPoolList = useMemo<boolean>(
      () => serviceUserPoolList.length > 0,
      [serviceUserPoolList],
    );

    return (
      <>
        {isLoading && <LoadingLayer />}
        <AlertDialogBody maxH="400px" overflowY="auto">
          <form onSubmit={handleSubmit(onSubmit)} id={formId}>
            {/* ユーザープール未設定 */}
            {!hasUserPoolList && (
              <Box>
                <AlertInfoBar type="warning" iconType="error">
                  ユーザープールが作成されていません。
                  <br />「
                  <Link
                    as={ReactRouterLink}
                    to="/admin/idPoolConsumer"
                    color={Colors.BLUE_20}
                    textDecoration="underline"
                  >
                    IDシステム設定管理機能
                  </Link>
                  」からユーザープールを作成してください。
                </AlertInfoBar>
              </Box>
            )}
            {/* ユーザープール設定済 */}
            {hasUserPoolList && (
              <Box>
                <Box>
                  <Heading as="h3" fontSize="14px" mb={2}>
                    <Box display="flex" alignItems="center">
                      テナント名
                    </Box>
                  </Heading>
                  <Box borderBottom="1px solid" borderColor={Colors.GRAY_30}>
                    <Text p="8px 16px" fontSize="16px">
                      {service.name}
                    </Text>
                  </Box>
                </Box>
                <Box mt="24px">
                  <FormControl isInvalid={Boolean(errors.userPoolId)}>
                    <Heading as="h3" fontSize="14px" mb={2}>
                      <Box display="flex" alignItems="center">
                        使用するユーザープール <RequiredIcon ml="8px" />
                      </Box>
                    </Heading>
                    <RhfSelect<ServiceFormType>
                      name="userPoolId"
                      placeholder="選択してください"
                      bgColor={Colors.WHITE}
                    >
                      {serviceUserPoolList
                        .filter(
                          (item) =>
                            !!item.authenticationType &&
                            [
                              AUTH_CODE_TYPE_OUTER,
                              AUTH_CODE_TYPE_UNIIKEY,
                            ].includes(item.authenticationType),
                        )
                        .map((serviceUserPool, idx) => (
                          <option
                            value={serviceUserPool.id}
                            key={`${serviceUserPool.id}_${String(idx)}`}
                          >
                            {serviceUserPool.name}
                          </option>
                        ))}
                    </RhfSelect>
                    {toErrMsgList(errors, 'userPoolId').map((err) => (
                      <FormErrorMessage key={`userPoolId_${err}`}>
                        {err}
                      </FormErrorMessage>
                    ))}
                  </FormControl>
                </Box>
                <Box mt="24px">
                  <AlertInfoBar type="error" iconType="error">
                    <UnorderedList>
                      <ListItem>
                        一度割り当てたユーザープールの変更はできません。
                      </ListItem>
                      <ListItem>十分確認した上で実行してください。</ListItem>
                    </UnorderedList>
                  </AlertInfoBar>
                </Box>
                <Box mt="24px">
                  <Heading as="h3" fontSize="14px" mb={2}>
                    実行する場合は「assign」と入力後に「割り当てボタン」を押下してください。
                  </Heading>
                  <FormControl isInvalid={Boolean(errors.word?.message)}>
                    <Box>
                      <RhfInput<ServiceFormType>
                        name="word"
                        bgColor={Colors.WHITE}
                      />
                    </Box>
                    <FormErrorMessage>{errors.word?.message}</FormErrorMessage>
                  </FormControl>
                </Box>
              </Box>
            )}
          </form>
        </AlertDialogBody>
        <AlertDialogFooter>
          {/* ユーザープール未設定 */}
          {!hasUserPoolList && (
            <Button
              variant="outline"
              onClick={onClose}
              p="16px 24px"
              w="120px"
              {...ColorSet.Default}
            >
              閉じる
            </Button>
          )}
          {/* ユーザープール設定済 */}
          {hasUserPoolList && (
            <>
              <Button
                variant="outline"
                mr={3}
                onClick={onClose}
                p="16px 24px"
                w="120px"
                {...ColorSet.Default}
              >
                キャンセル
              </Button>
              <Button
                type="submit"
                form={formId}
                p="16px 24px"
                w="120px"
                colorScheme="primary"
                isDisabled={!isValid}
              >
                割り当て
              </Button>
            </>
          )}
        </AlertDialogFooter>
      </>
    );
  },
);
