import { request } from 'admin/api/Request';
import { API_MESSAGES } from 'admin/define/apiMessages';
import { CustomError } from 'admin/error/CustomError';
import { serviceAttributeChildSchema } from 'admin/schema/service/attribute';
import {
  ServiceAttributeChildType,
  UserPoolChildAttributeType,
} from 'admin/types/service/attribute';
import { Options } from 'ky';
import { ZodError } from 'zod';

export const getServiceAttributeDetail = async ({
  userPoolChildId,
  userPoolChildAttrId,
  options,
}: {
  userPoolChildId: string;
  userPoolChildAttrId: UserPoolChildAttributeType['id'];
  options?: Options;
}): Promise<ServiceAttributeChildType> => {
  const path = `uniikey/${userPoolChildId}/child/attribute/${userPoolChildAttrId}/`;

  const response = await request<ServiceAttributeChildType>({
    path,
    method: 'get',
    options,
  });

  if (response.hasError) {
    if (response.status === 404) {
      throw new CustomError(
        API_MESSAGES.SERVICE.GET_SEVICE_ATTRIBUTE_DETAIL.NOT_FOUND,
        {
          path,
          status: response.status,
        },
      );
    } else {
      throw new CustomError(
        API_MESSAGES.SERVICE.GET_SEVICE_ATTRIBUTE_DETAIL.ERROR,
        {
          path,
          status: response.status,
          logLevel: response.status === 400 ? 'warning' : 'error',
        },
      );
    }
  }

  try {
    serviceAttributeChildSchema.parse(response.data);
  } catch (error) {
    if (error instanceof ZodError) {
      throw new CustomError(error.message, {
        path,
        status: response.status,
        logLevel: 'error',
      });
    }
  }

  return response.data;
};
