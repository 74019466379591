import { useIdPoolConsumerId } from 'admin/hooks/useIdPoolConsumerId';
import { useAllCodeGroups } from 'admin/hooks/userPool/master/useAllCodeGroups';

export const useGetCodeGroupName = (
  userPoolId?: string | null,
): ((id: string | null | undefined) => string | null | undefined) => {
  const idPoolConsumerId = useIdPoolConsumerId();
  const { data: codeGroups } = useAllCodeGroups({
    id: userPoolId || idPoolConsumerId,
  });

  const getCodeGroupName = (id: string | null | undefined) =>
    codeGroups?.find((codeGroup) => codeGroup.id === id)?.displayNameJa;

  return getCodeGroupName;
};
