import { memo } from 'react';
import { MemberInfoConfirmPage as Template } from 'view-template/dist/page';
import { TemplatePageProps, Wrap } from '../Page';
import { defaultTheme, dummyUserInfo } from '../util';

export const MemberInfoConfirmPage = memo(
  ({ watch, force }: TemplatePageProps) => {
    const dummyProps = {
      successMessage: undefined,
      errorMessage: undefined,
      backUrl: '',
      email: dummyUserInfo.email,
      userInfo: dummyUserInfo,
    };

    return (
      <Wrap>
        <Template {...dummyProps} theme={defaultTheme(watch)} force={force} />
      </Wrap>
    );
  },
);
