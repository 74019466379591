import { request } from 'admin/api/Request';
import { API_MESSAGES } from 'admin/define/apiMessages';
import { CustomError } from 'admin/error/CustomError';
import { userSchema } from 'admin/schema/users';
import { UserType } from 'admin/types/user';
import { Options } from 'ky';
import { ZodError } from 'zod';

export const getUserDetail = async ({
  id,
  corporationId,
  options,
}: {
  id: UserType['id'];
  corporationId: string;
  options?: Options;
}): Promise<UserType> => {
  const path = `user/corporation/${corporationId}/${id}/`;
  const response = await request<UserType>({
    path,
    method: 'get',
    options,
  });
  if (response.hasError) {
    if (response.status === 404) {
      throw new CustomError(API_MESSAGES.USER.GET_USER_DETAIL.NOT_FOUND, {
        path,
        status: response.status,
      });
    } else {
      throw new CustomError(API_MESSAGES.USER.GET_USER_DETAIL.ERROR, {
        path,
        status: response.status,
        logLevel: response.status === 400 ? 'warning' : 'error',
      });
    }
  }

  try {
    userSchema.parse(response.data);
  } catch (error) {
    if (error instanceof ZodError) {
      throw new CustomError(error.message, {
        path,
        status: response.status,
        logLevel: 'error',
      });
    }
  }

  return response.data;
};
