import { customErrorMessage, toZod } from 'admin/config/zod';
import { FormGroupDetailType, FormGroupType } from 'admin/types/userPool/formGroup';
import { EnqueteFormType } from 'api/enquete/types';
import { z } from 'zod';

// zodErrorの日本語化対応
z.setErrorMap(customErrorMessage);

const baseEnqueteFormTypeSchema = z.object<toZod<EnqueteFormType>>({
  id: z.number().optional().nullable(),
  dndId: z.string(),
  type: z.string(),
  heading: z.string().optional().nullable(),
  headingHtml: z.string().optional().nullable(),
  bgHeading: z.string().optional().nullable(),
  questionTitle: z.string().optional().nullable(),
  descriptionTop: z.string().optional().nullable(),
  descriptionTopHtml: z.string().optional().nullable(),
  descriptionBottom: z.string().optional().nullable(),
  descriptionBottomHtml: z.string().optional().nullable(),
  wysiwyg: z.string().optional().nullable(),
  wysiwygHtml: z.string().optional().nullable(),
  imageUpload: z.string().optional().nullable(),
  imageUploadAlign: z.string().optional().nullable(),
  imageUploadSize: z.number().optional().nullable(),
  freeRowCount: z.string().optional().nullable(),
  freeTextMin: z.string().optional().nullable(),
  freeTextMax: z.string().optional().nullable(),
  freeFormat: z.number().optional().nullable(),
  checkMax: z.string().optional().nullable(),
  checkMin: z.string().optional().nullable(),
  checkMatrixMax: z.string().optional().nullable(),
  checkMatrixMin: z.string().optional().nullable(),
  items: z.string().optional().nullable(),
  itemsCol: z.string().optional().nullable(),
  itemsRow: z.string().optional().nullable(),
  fileUploadNum: z.number().optional().nullable(),
  fileUploadSize: z.number().optional().nullable(),
  fileUploadFormat: z.number().optional().nullable(),
  clipstudioTextUrl: z.string().optional().nullable(),
  clipstudioTextPassword: z.string().optional().nullable(),
  formPresetType: z.string().optional().nullable(),
  isEmailConfirm: z.boolean().optional().nullable(),
  isHideWordLimit: z.boolean().optional().nullable(),
  isSingleLineItems: z.boolean().optional().nullable(),
  isTextScroll: z.boolean().optional().nullable(),
  isCondFlg: z.boolean(),
  condTitle: z.string().nullable(),
  condItems: z.string().nullable(),
  condContent: z.number().nullable(),
  required: z.boolean().optional(),
  mailMagazineId: z.string().optional().nullable(),
  confirmationMethod: z.number().optional().nullable(),
  uniikeyUserAttributeSettingId: z.string().optional().nullable(),
  typeOrUserAttrId: z.string().optional().nullable(),
  sortOrder: z.number().optional().nullable(),
});

export const formGroupSchema = z.object<toZod<FormGroupType>>({
  id: z.string(),
  groupUnits: z.array(baseEnqueteFormTypeSchema.omit({
    dndId: true,
  })),
  name: z.string(),
  status: z.boolean().optional(),
  userPoolId: z.string(),
});

export const formGroupsSchema = z.array(formGroupSchema);

// 一覧のページ以外
export const formGroupDetailSchema = z.object<toZod<FormGroupDetailType>>({
  id: z.string(),
  groupUnits: z.array(baseEnqueteFormTypeSchema),
  name: z.string(),
  status: z.boolean().optional(),
  userPoolId: z.string(),
});

export const formGroupDetailReponseSchema = formGroupDetailSchema.extend({
  groupUnits: z.array(baseEnqueteFormTypeSchema.omit({
    dndId: true,
  })),
});
