/* eslint-disable react/require-default-props */
import {
  Box,
  FormControl,
  FormLabel,
  ListItem,
  UnorderedList,
} from '@chakra-ui/react';
import { InputText } from 'components/common/atoms';
import { ColorSet, Colors } from 'components/paywall/config';
import { DetailDialog } from 'components/paywall/features/Detail';
import { ChangeEvent, FC, memo, useCallback, useState } from 'react';

type Props = {
  title: string;
  submitBtnTitle: string;
  cancelBtnTitle: string;
  dialogConfirmText?: string;
  instructionText?: string;
  descriptionPrefix: string;
  warningTexts: string[];
  isLoading: boolean;
  isOpen: boolean;
  onClose: () => void;
  submitBtnHandler: () => void;
  children?: React.ReactNode | null;
};

export const DoubleConfirmDialog: FC<Props> = memo(
  ({
    title,
    submitBtnTitle,
    cancelBtnTitle,
    dialogConfirmText = 'change',
    instructionText = '',
    descriptionPrefix,
    warningTexts,
    isLoading,
    isOpen,
    onClose,
    submitBtnHandler,
    children = null,
  }: Props) => {
    const [confirmText, setConfirmText] = useState<string>('');

    const onChangeText = useCallback((e: ChangeEvent<HTMLInputElement>) => {
      setConfirmText(e.target.value);
    }, []);

    // フォームエラーの存在チェック
    const isFailed = useCallback(
      (): boolean => confirmText !== dialogConfirmText,
      [confirmText, dialogConfirmText],
    );

    const onSubmitDialog = useCallback(() => {
      submitBtnHandler();
      setConfirmText('');
    }, [submitBtnHandler]);

    return (
      <DetailDialog
        title={title}
        isOpen={isOpen}
        onClose={onClose}
        submitBtnTitle={submitBtnTitle}
        submitBtnProps={{
          width: '100px',
          padding: '8px 16px',
          disabled: isFailed(),
          isLoading,
          type: 'submit',
          ...ColorSet.RED,
        }}
        submitBtnHandelr={onSubmitDialog}
        cancelBtnTitle={cancelBtnTitle}
        cancelBtnProps={{
          padding: '8px 16px',
          backgroundColor: 'WHITE',
          border: '1px solid #CFD6DD',
        }}
      >
        {warningTexts.length > 0 && (
          <Box backgroundColor={Colors.RED_40} borderRadius="8px" p={4}>
            <UnorderedList>
              {warningTexts.map((text, idx) => (
                <ListItem
                  key={`${text}_${String(idx)}`}
                  fontSize="14px"
                  fontWeight="700"
                  color={Colors.RED_20}
                >
                  {text}
                </ListItem>
              ))}
            </UnorderedList>
          </Box>
        )}

        {children}

        <FormControl>
          {!instructionText && (
            <FormLabel
              htmlFor="confirmText"
              fontSize="sm"
              fontWeight="700"
              mt={6}
              width="100%"
            >
              {descriptionPrefix}する場合は「{dialogConfirmText}」と入力後に「
              {submitBtnTitle}ボタン」を押下してください。
            </FormLabel>
          )}
          {instructionText && (
            <FormLabel
              htmlFor="confirmText"
              fontSize="sm"
              fontWeight="700"
              mt={6}
              width="100%"
            >
              {instructionText}
            </FormLabel>
          )}
          <InputText name="confirmText" onChange={(e) => onChangeText(e)} />
        </FormControl>
      </DetailDialog>
    );
  },
);
