import { LockIcon } from '@chakra-ui/icons';
import { Box, Button, Stack, Text } from '@chakra-ui/react';
import { LoginFormType } from 'api/user/types';
import { LoginTroubleLink } from 'components/login/loginTroubleLink';
import { PasswordReminderLink } from 'components/login/passwordReminderLink';
import { FC, memo } from 'react';
import { useFormContext } from 'react-hook-form';
import { LoginInputForm } from '../loginInputForm';

type Props = {
  isShow: boolean;
  formId: string;
  isLoading: boolean;
}

export const LoginFormStepGidp: FC<Props> = memo(({ formId, isLoading, isShow }: Props) => {
  const { getValues } = useFormContext<LoginFormType>();

  if(!isShow) return <></>;

  return (
    <Stack spacing={4}>
      <Stack spacing={4}>
        <Box w="100%">
          <Text fontSize={12} fontWeight={700} mb="8px">メールアドレス</Text>
          <Text>{getValues('email')}</Text>
        </Box>
        <Box w="100%">
          <LoginInputForm<LoginFormType>
            name="password"
            type="password"
            labelElement={<Text fontSize={12} fontWeight={700}>パスワード</Text>}
            icon={LockIcon}
          />
        </Box>
      </Stack>
      <Button
        w="100%"
        type="submit"
        variant="primary"
        form={formId}
        isLoading={isLoading}
      >
        ログイン
      </Button>
      <PasswordReminderLink />
      <LoginTroubleLink />
    </Stack>
  )
})
