import { request } from 'admin/api/Request';
import { API_MESSAGES } from 'admin/define/apiMessages';
import { CustomError } from 'admin/error/CustomError';
import { serviceAllocateUserPoolResponseSchema } from 'admin/schema/service';
import {
  ServiceAllocateUserPoolResponseType,
  ServiceFormType,
} from 'admin/types/service/form';
import { isResponseError } from 'api/types';
import { Options } from 'ky';
import { getValidationError } from 'utils/form';
import { ZodError } from 'zod';

export const updateService = async (
  data: ServiceFormType,
  options?: Options,
): Promise<ServiceAllocateUserPoolResponseType> => {
  const params = data;
  const path = `tenant/${params.id}/allocate_user_pool/`;

  const response = await request<ServiceAllocateUserPoolResponseType>({
    path,
    method: 'patch',
    body: { userPoolId: params.userPoolId },
    options,
  });

  if (response.hasError) {
    if (response.status === 404) {
      throw new CustomError(API_MESSAGES.SERVICE.UPDATE_SERVICE.FAILED, {
        path,
        status: response.status,
      });
    } else if (isResponseError(response.error)) {
      const customError = response.error;
      const formError = getValidationError<Omit<ServiceFormType, 'word'>>({
        formData: data,
        response: customError,
      });

      throw new CustomError(API_MESSAGES.SERVICE.UPDATE_SERVICE.ERROR, {
        path,
        options,
        params,
        errors: formError,
        status: response.status,
        logLevel: response.status === 400 ? 'warning' : 'error',
      });
    } else {
      throw new CustomError(API_MESSAGES.SERVICE.UPDATE_SERVICE.ERROR, {
        path,
        status: response.status,
      });
    }
  }
  try {
    serviceAllocateUserPoolResponseSchema.parse(response.data);
  } catch (error) {
    if (error instanceof ZodError) {
      throw new CustomError(error.message, {
        path,
        status: response.status,
        logLevel: 'error',
      });
    }
  }

  return response.data;
};
