import { withSuspenseAndErrorBoundary } from 'admin/components/Error/WithErrorBoundary';
import { MemberIdPoolTabs } from 'admin/components/member/memberIdPoolTabs';
import { MemberTitle } from 'admin/components/member/memberTitle';
import { Breadcrumb } from 'admin/components/ui/breadcrumb';
import { BreadcrumbType } from 'admin/types/ui/breadcrumb';
import { ErrorContents, LoadingSpinner } from 'components/common/atoms';
import { useSetPageTitle } from 'hooks/useSetPageTitle';
import { FC, memo } from 'react';

export const memberIndex: FC = memo(() => {
  useSetPageTitle('会員管理');

  const breadcrumbItems: BreadcrumbType[] = [
    { url: '/admin', title: '組織管理TOP' },
    { url: '', title: '会員管理' },
  ];

  return (
    <>
      <Breadcrumb breadcrumbItems={breadcrumbItems} />
      <MemberTitle />
      <MemberIdPoolTabs />
    </>
  );
});

export const MemberIndex = memo(
  withSuspenseAndErrorBoundary(memberIndex, {
    ErrorComponent: <ErrorContents name="会員管理" />,
    LoadingComponent: <LoadingSpinner />,
  }),
);
