import {
  Alert, AlertDescription, AlertIcon, AlertProps, AlertTitle, Flex, Text
} from '@chakra-ui/react';
import { Colors } from 'components/paywall/config';
import { VFC } from 'react';

type AlertBoxProps = {
  title: string;
  message: string;
} & AlertProps;

export const AlertBox: VFC<AlertBoxProps> = ({ title, message, ...rest }) => (
  <Alert {...rest}>
    <AlertIcon />
    <AlertTitle mr={2}>{title}</AlertTitle>
    <AlertDescription>{message}</AlertDescription>
  </Alert>
);

type AlertBarProps = {
  message: string | string[];
} & AlertProps;

export const AlertBar: VFC<AlertBarProps> = (props) => {
  const { message, fontSize = 'xs', ...rest } = props;

  if (typeof message === 'string' && !message) {
    return <></>;
  }

  const msgList = typeof message === 'string' ? [message] : message;

  if (msgList.length === 0) {
    return <></>;
  }

  return (
    <Alert fontSize={fontSize} {...rest}>
      {msgList.map((msg, idx) => (
        <Flex key={`errmsg-${String(idx)}`} alignItems="center">
          <AlertIcon />
          <Text>{msg}</Text>
        </Flex>
      ))}
    </Alert>
  );
};


type AlertInfoProps = {
  message: string | string[];
} & AlertProps;
export const AlertInfo: VFC<AlertInfoProps> = (props) => {
  const { message, fontSize = 'xs', ...rest } = props;

  if (typeof message === 'string' && !message) {
    return <></>;
  }

  return (
    <Alert
      color={Colors.RED_20}
      fontSize={fontSize}
      borderRadius="8px"
      border="1px solid #F49090"
      bgColor={Colors.RED_50}
      {...rest}
    >
      <AlertIcon width="18px" height="18px" color={Colors.RED_20}/>
      <Text>{message}</Text>
    </Alert>
  );
}
