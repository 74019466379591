import {
  MailInsertWordKeyPattern,
  MailInsertWordPattern,
  MailSettingStatuses,
} from 'components/paywall/pages/MailSetting/typed';

export const commonSettingWords = {
  heading: '共通要素の設定',
  description: 'ペイウォールメールで共通で利用する要素が設定できます。',
  fromAddress: '差出人メールアドレス',
  replyAddress: '返信先メールアドレス',
  header: '共通ヘッダー文章',
  footer: '共通フッター文章',
} as const;

export const commonSettingFormWords = {
  fromAddress: { placeholder: '差出人メールアドレスを選択して下さい' },
  replyAddress: { placeholder: '返信先メールアドレスを選択して下さい' },
  header: { placeholder: '共通ヘッダー文章を入力してください' },
  footer: { placeholder: '共通フッター文章を入力してください' },
} as const;

export const personalSettingWords = {
  heading: 'メール設定',
  subject: '件名',
  bodyText: '本文',
  header: '共通ヘッダー文章',
  footer: '共通フッター文章',
  testSend: 'テスト送信',
  back: 'キャンセル',
  submit: '保存',
};

export const statusTitleObject: {
  [key in keyof MailSettingStatuses]: string;
} = {
  createPaymentInfo: 'カード情報登録 完了メール',
  completeCoursePayment: 'サブスクリプション初回購入 完了メール',
  completeItemPayment: '単品決済完了メール',
  completeContinuePayment: 'サブスクリプション継続決済 成功メール',
  failContinuePayment: 'サブスクリプション継続決済 失敗メール',
  completeStopPayment: 'サブスクリプション次回支払い 停止メール',
  completeRestartPayment: 'サブスクリプション次回支払い 有効化メール',
  cancelPurchase: '購入取り消しメール',
} as const;

export const statusTitleObjectMessage: {
  [key in keyof MailSettingStatuses]: string;
} = {
  createPaymentInfo:
    'ユーザーがカード情報を新しく登録した際に自動送信されるメールです。',
  completeCoursePayment:
    'サブスクリプション商品の初回購入が完了した際に自動送信されるメールです。',
  completeItemPayment:
    '単品記事商品の決済が完了した際に自動送信されるメールです。',
  completeContinuePayment:
    'サブスクリプション商品の継続決済が成功した際に自動送信されるメールです。',
  failContinuePayment:
    'サブスクリプション商品の継続決済が失敗した際に自動送信されるメールです。',
  completeStopPayment:
    'ユーザーがサブスクリプション商品の次回支払いの自動更新を停止した際に自動送信されるメールです。',
  completeRestartPayment:
    'ユーザーがサブスクリプション商品の次回支払いの自動更新を有効化した際に自動送信されるメールです。',
  cancelPurchase:
    '管理画面から購入を取消処理した際に自動送信されるメールです。',
} as const;

export const statusTitleObjectSort: {
  [key in keyof MailSettingStatuses]: number;
} = {
  createPaymentInfo: 1,
  completeCoursePayment: 3,
  completeItemPayment: 8,
  completeContinuePayment: 4,
  failContinuePayment: 5,
  completeStopPayment: 6,
  completeRestartPayment: 7,
  cancelPurchase: 2,
} as const;

export const mailInsertWordTitleObject: {
  [key in MailInsertWordKeyPattern]: string;
} = {
  purchaseDate: '購入日(決済日時)',
  productName: '商品名',
  productUrl: '商品URL',
  price: '税込価格',
  taxRate: '税率',
  taxPrice: '税額',
  priceWithoutTax: '税抜額',
  itemEndDate: '単品最終権利日',
  courseEndDate: 'サブスク最終権利日',
  period: '決済期間',
  nextPaymentDate: '次回決済日',
  error: '決済エラー内容',
};

export const mailInsertWordsPattern: {
  [statusKey in keyof MailSettingStatuses]: {
    [sentenceKey in keyof MailInsertWordPattern]: boolean;
  };
} = {
  createPaymentInfo: {
    purchaseDate: false,
    productName: false,
    productUrl: false,
    price: false,
    taxRate: false,
    taxPrice: false,
    priceWithoutTax: false,
    itemEndDate: false,
    courseEndDate: false,
    period: false,
    nextPaymentDate: false,
    error: false,
  },
  completeItemPayment: {
    purchaseDate: true,
    productName: true,
    productUrl: true,
    price: true,
    taxRate: true,
    taxPrice: true,
    priceWithoutTax: true,
    itemEndDate: true,
    courseEndDate: false,
    period: false,
    nextPaymentDate: false,
    error: false,
  },
  completeCoursePayment: {
    purchaseDate: true,
    productName: true,
    productUrl: false,
    price: true,
    taxRate: true,
    taxPrice: true,
    priceWithoutTax: true,
    itemEndDate: false,
    courseEndDate: true,
    period: true,
    nextPaymentDate: true,
    error: false,
  },
  completeContinuePayment: {
    purchaseDate: true,
    productName: true,
    productUrl: false,
    price: true,
    taxRate: true,
    taxPrice: true,
    priceWithoutTax: true,
    itemEndDate: false,
    courseEndDate: true,
    period: true,
    nextPaymentDate: true,
    error: false,
  },
  failContinuePayment: {
    purchaseDate: true,
    productName: true,
    productUrl: false,
    price: true,
    taxRate: true,
    taxPrice: true,
    priceWithoutTax: true,
    itemEndDate: false,
    courseEndDate: true,
    period: true,
    nextPaymentDate: false,
    error: true,
  },
  completeStopPayment: {
    purchaseDate: false,
    productName: true,
    productUrl: false,
    price: false,
    taxRate: false,
    taxPrice: false,
    priceWithoutTax: false,
    itemEndDate: false,
    courseEndDate: true,
    period: true,
    nextPaymentDate: false,
    error: false,
  },
  completeRestartPayment: {
    purchaseDate: false,
    productName: true,
    productUrl: false,
    price: false,
    taxRate: false,
    taxPrice: false,
    priceWithoutTax: false,
    itemEndDate: false,
    courseEndDate: true,
    period: true,
    nextPaymentDate: true,
    error: false,
  },
  cancelPurchase: {
    purchaseDate: true,
    productName: true,
    productUrl: false,
    price: true,
    taxRate: true,
    taxPrice: true,
    priceWithoutTax: true,
    itemEndDate: false,
    courseEndDate: false,
    period: false,
    nextPaymentDate: false,
    error: false,
  },
};
