import { logger } from 'api/logger';
import { request } from 'api/request';
import { isResponseError, ResponseError } from 'api/types';
import produce from 'immer';
import { Options } from 'ky';
import { getValidationError, ValidationError } from 'utils/form';
import { messages } from './messages';
import { isSamlLoginResponseType, SamlLoginAuthType, SamlLoginResponseType } from './types';

const DEFAULT_ERR_MESSAGE: ValidationError<SamlLoginAuthType> = {
  others: [messages.loginWithSaml.customer.error],
};
const DEFAULT_SYSTEM_ERR_MESSAGE: ValidationError<SamlLoginAuthType> = {
  others: [messages.loginWithSaml.customer.failed],
};

const path = 'user/login_saml/';

export const loginWithSaml = async (
  formParam: SamlLoginAuthType,
  options?: Options,
): Promise<SamlLoginResponseType | ResponseError | boolean> => {
  const { email, userPoolId } = formParam;
  const postParam = { email, userPoolId };

  const mergedOptions = options
    ? produce(options, (draft) => {
        draft.json = { ...postParam };
      })
    : { json: postParam };

  const response = await request({
    path,
    method: 'post',
    options: mergedOptions,
  });

  if (response.status >= 500) {
    return DEFAULT_ERR_MESSAGE;
  }

  const responseData = response.data;

  // 成功
  if (isSamlLoginResponseType(responseData)) {
    return responseData;
  }

  const { error } = response;
  if (response.hasError) {
    // APIにて返却されたエラー形式
    if (isResponseError(error)) {
      const formError = getValidationError<SamlLoginAuthType>({
        formData: formParam,
        response: error,
      });

      return formError;
    }
  }

  // 予期せぬエラーなので
  // バックエンドに状態を通知
  await logger({
    loglevel:
      response.status === 404 || response.status === 450 ? 'warning' : 'error',
    data: error,
    message: messages.loginWithSaml.system.typeError,
  });

  // 登録できていない旨を通知
  return DEFAULT_SYSTEM_ERR_MESSAGE;
};
