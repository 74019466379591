import { TableBodyProps, Tbody as ChakraTbody } from '@chakra-ui/react';
import { FC, memo, ReactNode } from 'react';

type Props = {
  children: ReactNode;
} & TableBodyProps;

export const Tbody: FC<Props> = memo(({ children, ...rest }: Props) => (
  <ChakraTbody {...rest}>{children}</ChakraTbody>
));
