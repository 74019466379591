import { withSuspenseAndErrorBoundary } from 'admin/components/Error/WithErrorBoundary';
import { AuthBody } from 'admin/components/idPoolConsumer/auth/authBody';
import { AuthTitle } from 'admin/components/idPoolConsumer/auth/authTitle';
import { Breadcrumb } from 'admin/components/ui/breadcrumb';
import { useUserPoolId } from 'admin/hooks/params/useUserPoolId';
import { useUserPool } from 'admin/hooks/userPool/useUserPool';
import { BreadcrumbType } from 'admin/types/ui/breadcrumb';
import { ErrorContents, LoadingSpinner } from 'components/common/atoms';
import { useSetPageTitle } from 'hooks/useSetPageTitle';
import { FC, memo } from 'react';

const AuthTop: FC = memo(() => {
  useSetPageTitle('認証認可とセキュリティ設定');
  const userPoolId = useUserPoolId();
  const { data: userPool } = useUserPool(userPoolId);
  const breadcrumbItems: BreadcrumbType[] = [
    { url: '/admin', title: '組織管理TOP' },
    {
      url: '/admin/idPoolConsumer',
      title: 'IDシステム設定管理',
    },
    { url: '', title: userPool?.name || '' },
    { url: '', title: '認証認可とセキュリティ設定' },
  ];

  return (
    <>
      <Breadcrumb breadcrumbItems={breadcrumbItems} />
      <AuthTitle />
      <AuthBody />
    </>
  );
});

export const Auth = withSuspenseAndErrorBoundary(AuthTop, {
  ErrorComponent: <ErrorContents name="認証認可とセキュリティ設定TOP" />,
  LoadingComponent: <LoadingSpinner />,
});
