import { request } from 'admin/api/Request';
import { API_MESSAGES } from 'admin/define/apiMessages';
import { CustomError } from 'admin/error/CustomError';
import { usersSchema } from 'admin/schema/users';
import { UsersListType } from 'admin/types/users';
import { Options } from 'ky';
import { ZodError } from 'zod';

export const getUsers = async ({
  id,
  options,
}: {
  id: string;
  options?: Options;
}): Promise<UsersListType> => {
  const path = `user/corporation/${id}/`;
  const response = await request<UsersListType>({
    path,
    method: 'get',
    options,
  });
  if (response.hasError) {
    if (response.status === 404) {
      throw new CustomError(API_MESSAGES.SERVICE.GET_SERVICE.NOT_FOUND, {
        path,
        status: response.status,
      });
    } else {
      throw new CustomError(API_MESSAGES.SERVICE.GET_SERVICE.ERROR, {
        path,
        status: response.status,
        logLevel: response.status === 400 ? 'warning' : 'error',
      });
    }
  }

  try {
    usersSchema.parse(response.data);
  } catch (error) {
    if (error instanceof ZodError) {
      throw new CustomError(error.message, {
        path,
        status: response.status,
        logLevel: 'error',
      });
    }
  }

  return response.data;
};
