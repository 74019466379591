import { Options } from 'ky';
import { request } from 'api/request';
import { logger } from 'api/logger';
import { CustomError } from 'api/error/CustomError';
import { User, isUser } from './types';
import { messages } from './messages';

export const getUser = async (
  userId?: string,
  options?: Options,
): Promise<User> => {
  const path = userId ? `user/${userId}/` : 'user/';

  const response = await request({
    path,
    method: 'get',
    options,
  });

  const responseData = await response.data;

  if (response.hasError) {
    if (response.status === 404) {
      throw new CustomError(
        messages.getUser.customer.notFound,
        response.status,
      );
    } else {
      throw new CustomError(messages.getUser.customer.error);
    }
  }

  if (!isUser(responseData)) {
    await logger({
      loglevel:
        response.status === 404 || response.status === 450
          ? 'warning'
          : 'error',
      data: responseData,
      message: messages.getUser.system.typeError,
    });

    throw new CustomError(messages.getUser.customer.typeError);
  }

  return responseData;
};
