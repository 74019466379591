import { Table, Tbody, Td, Tr, VStack } from '@chakra-ui/react';
import { ColorPicker } from 'components/common/atoms';
import { RichTextElement } from 'components/enquete/atoms/RichTextElement';
import { Toggle } from 'components/enquete/atoms/Toggle';
import { Dispatch, FC, SetStateAction, useCallback, useState } from 'react';
import 'react-draft-wysiwyg/dist/react-draft-wysiwyg.css';
import { FieldValues, Path, PathValue, UnpackNestedValue, useFormContext } from 'react-hook-form';

type Props<T> = {
  baseName: Path<T>;
  placeholder?: string;
  option?: string[];
  index: number | -1;
  position: string;
  setIsEdited: Dispatch<SetStateAction<boolean>>;
};

export const HeadingElement = <T extends FieldValues>({
  baseName,
  placeholder,
  option,
  index,
  position,
  setIsEdited,
}: Props<T>): ReturnType<FC> => {
  const [toggle, setToggle] = useState(false);
  const { getValues } = useFormContext<T>();

  const [bgColor, setBgColor] = useState(
    (getValues(`${baseName}.${index}.bgHeading` as Path<T>) as string) || '#FFFFFF',
  );

  const handleChangeBgColor = useCallback((color: string) => {
    setBgColor(color);
  }, []);

  return (
    <>
      <VStack w="100%" display={toggle ? 'none' : 'block'}>
        <Table>
          <Tbody>
            <Tr>
              <Td w={140}>見出し</Td>
              <Td>
                <RichTextElement<T>
                  baseName={baseName}
                  placeholder={placeholder}
                  option={option}
                  index={index}
                  position={position}
                  value={getValues(`${baseName}.${index}.heading` as Path<T>) || ''}
                  setIsEdited={setIsEdited}
                  bgColor={bgColor}
                />
              </Td>
            </Tr>
            <Tr>
              <Td w={140}>見出し背景色</Td>
              <Td>
                <ColorPicker<T>
                  name={`${baseName}.${index}.bgHeading` as Path<T>}
                  value={bgColor as UnpackNestedValue<PathValue<T, Path<T>>>}
                  isDisabled={getValues('uneditableState' as Path<T>)}
                  onChangeColor={handleChangeBgColor}
                />
              </Td>
            </Tr>
          </Tbody>
        </Table>
      </VStack>
      <Toggle toggle={toggle} setToggle={setToggle} />
    </>
  );
};
