import { useQuery } from 'react-query';
import { useSearchParams } from 'react-router-dom';
import { Page } from 'api/common/types';
import { MailInfo, MailList } from 'api/mail/types';
import { getMailList } from 'api/mail/getMailList';
import { useCustomToast } from 'hooks/useCustomToast';
import { useUserTenantId } from 'hooks/user/useUserTenantId';
import { queryMailKey } from './queryMailKey';

/**
 * メール一覧情報取得 hooks
 */
export const useMailList = (): {
  page: Page;
  mailList: MailInfo[];
} => {
  const toast = useCustomToast();
  const tenantId = useUserTenantId();
  const [searchParams] = useSearchParams();

  const queryOrdering = searchParams.get('ordering') || '';
  const queryPage = searchParams.get('page') || '';
  const queryOpenDrawer = searchParams.get('openDrawer') || '';
  const queryPerPage = searchParams.get('perPage') || '';
  const queryStatus = searchParams.get('status') || '';
  const queryDeliveryTo = searchParams.get('deliveryTo') || '';
  const querySegment = searchParams.get('segment') || '';
  const queryReserve = searchParams.get('reserve') || '';

  const response = useQuery(
    queryMailKey.getMailList({
      tenantId,
      ordering: queryOrdering,
      page: queryPage,
      openDrawer: queryOpenDrawer,
      perPage: queryPerPage,
      status: queryStatus,
      deliveryTo: queryDeliveryTo,
      segment: querySegment,
      reserve: queryReserve,
    }),
    () =>
      getMailList({
        tenantId,
        ordering: queryOrdering,
        page: queryPage,
        perPage: queryPerPage,
        status: queryStatus,
        deliveryTo: queryDeliveryTo,
        segment: querySegment,
        reserve: queryReserve,
      }),
    {
      onError: (err: unknown) => {
        if (err instanceof Error) {
          toast.closeAll();
          toast({
            title: err.message,
            status: 'error',
          });
        }
      },
    },
  );

  const result = response.data as MailList;
  const page: Page = {
    count: result.count,
    countFrom: result.countFrom,
    countTo: result.countTo,
    currentPage: result.currentPage,
    next: result.next,
    pageCount: result.pageCount,
    perPage: result.perPage,
    previous: result.previous,
  };
  const { results } = result;

  return {
    page,
    mailList: results,
  };
};
