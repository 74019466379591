import { zodResolver } from '@hookform/resolvers/zod';
import { withSuspenseAndErrorBoundary } from 'admin/components/Error/WithErrorBoundary';
import { useIdPoolConsumerId } from 'admin/hooks/useIdPoolConsumerId';
import { useCodeGroup } from 'admin/hooks/userPool/master/useCodeGroup';
import { useEditEditCodeGroup } from 'admin/hooks/userPool/master/useEditEditCodeGroup';
import { editCodeGroupSchema } from 'admin/schema/idPoolConsumer/master';
import { EditCodeGroupFormType } from 'admin/types/userPool/master/form';
import { AlertBar } from 'components/common/atoms';
import { FC, memo, useState } from 'react';
import { FormProvider, SubmitHandler, useForm } from 'react-hook-form';
import { MasterEditFormComponent } from './MasterEditFormComponent';

type Props = {
  onClose: () => void;
  formId: string;
  masterSettingId: string;
  currentPage: number;
};

const FetchMasterEditForm: FC<Props> = memo(
  ({ onClose, formId, masterSettingId, currentPage }: Props) => {
    const idPoolConsumerId = useIdPoolConsumerId();
    const [globalErrors, setGlobalErrors] = useState<string[]>([]);

    const { data: masterSetting } = useCodeGroup(
      idPoolConsumerId,
      masterSettingId,
    );

    const methods = useForm<EditCodeGroupFormType>({
      defaultValues: {
        id: masterSetting?.id ?? '',
        displayNameJa: masterSetting?.displayNameJa ?? '',
        displayNameForeign: masterSetting?.displayNameForeign ?? '',
        userPool: masterSetting?.userPool ?? '',
      },
      resolver: zodResolver(editCodeGroupSchema),
    });
    const { mutate, isLoading } = useEditEditCodeGroup(
      currentPage,
      methods.setError,
      setGlobalErrors,
    );

    const onSubmit: SubmitHandler<EditCodeGroupFormType> = async (
      data: EditCodeGroupFormType,
    ) => {
      await mutate(data);
      onClose();
    };

    return (
      <FormProvider {...methods}>
        {globalErrors.map((err, idx) => (
          <AlertBar
            key={`global-err-idx${String(idx)}`}
            message={err}
            status="error"
            mb={4}
          />
        ))}
        <MasterEditFormComponent
          onSubmit={onSubmit}
          formId={formId}
          isLoading={isLoading}
        />
      </FormProvider>
    );
  },
);

export const MasterEditForm = withSuspenseAndErrorBoundary(FetchMasterEditForm);
