import { customErrorMessage, toZod } from 'admin/config/zod';
import { IdPoolConsumerUserInfoFormType } from 'admin/types/userPool/userInfo/form';
import { z } from 'zod';

// zodErrorの日本語化対応
z.setErrorMap(customErrorMessage);

export const idPoolConsumerUserInfoSchema = z.object<toZod<IdPoolConsumerUserInfoFormType>>({
  id: z.string(),
  userInfoResponse: z.string().nullable(),
})
.superRefine((value, ctx) => {
  if (value.userInfoResponse) {
    try {
      JSON.parse(value.userInfoResponse || '');
    } catch (e) {
      ctx.addIssue({
        code: 'custom',
        message: 'JSON形式が不正です。正しい形式を入力してください。',
        path: ['userInfoResponse'],
        fatal: true,
      });
    }
  }
});
