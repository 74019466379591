import { Box, Button, Tr as ChakraTr } from '@chakra-ui/react';
import { Table, Tbody, Td, Th, Thead, Tr } from 'admin/components/ui/table';
import { CodeSettingsType, CodeSettingType } from 'admin/types/userPool/code/';
import { Dispatch, FC, memo, SetStateAction } from 'react';

type Props = {
  codeSettings: CodeSettingsType;
  setCodeSetting: Dispatch<SetStateAction<CodeSettingType | undefined>>;
  onDrawerOpen: () => void;
  // onModalOpen: (value: CodeSettingType) => void;
};

export const CodeTableComponent: FC<Props> = memo(
  ({ codeSettings, setCodeSetting, onDrawerOpen }: Props) => (
    <Box mt="16px">
      <Table w="100%" maxHeight="calc(100vh - 250px)">
        <Thead h="48px">
          <ChakraTr>
            <Th maxWidth="144px" minWidth="144px">
              コードID
            </Th>
            <Th maxWidth="160px" minWidth="160px">
              表示名(日本語)
            </Th>
            <Th maxWidth="160px" minWidth="160px">
              表示名(外国語)
            </Th>
            <Th maxWidth="100px" minWidth="100px">
              コード値
            </Th>
            <Th maxWidth="100px" minWidth="100px">
              並び順
            </Th>
            {/* 削除したコードと同じコード値を登録するとエラーになるため消す
            <Th>
              <></>
            </Th> */}
          </ChakraTr>
        </Thead>
        <Tbody>
          {codeSettings.map((codeSetting) => (
            <Tr key={`rule${codeSetting.id}`}>
              <Td>
                <Button
                  fontWeight="normal"
                  textDecoration="underline"
                  variant="link"
                  colorScheme="primary"
                  onClick={() => {
                    setCodeSetting(codeSetting);
                    onDrawerOpen();
                  }}
                >
                  {codeSetting.id}
                </Button>
              </Td>
              <Td>{codeSetting.displayNameJa}</Td>
              <Td>{codeSetting.displayNameForeign}</Td>
              <Td>{codeSetting.code}</Td>
              <Td>{codeSetting.displayOrder}</Td>
              {/* 削除したコードと同じコード値を登録するとエラーになるため消す
              <Td>
                <Box display="flex" justifyContent="end">
                  <Button
                    variant="outline"
                    colorScheme="danger"
                    onClick={() => onModalOpen(codeSetting)}
                  >
                    <BsTrash />
                    削除
                  </Button>
                </Box>
              </Td> */}
            </Tr>
          ))}
        </Tbody>
      </Table>
    </Box>
  ),
);
