import {
  Avatar,
  Divider,
  Flex,
  Menu,
  MenuButton,
  MenuItem,
  MenuList,
  Select,
} from '@chakra-ui/react';
import { useUserCorporationInfo } from 'admin/hooks/user/useUserCorporationInfo';
import { UserTenant } from 'api/user/types';
import { useRouteAuth } from 'auth/authContext';
import { LocalStorageType, useLocalstorage } from 'hooks/useLocalstorage';
import { ChangeEvent, FC, memo, useCallback, useEffect, useState } from 'react';
import { AiOutlinePoweroff } from 'react-icons/ai';
import { useNavigate } from 'react-router-dom';
import { useRecoilState } from 'recoil';
import { corporationIdState, userState, userTenantIdState } from 'store/user';

type Props = {
  isAdmin?: boolean;
};
export const HeaderAccount: FC<Props> = memo(({ isAdmin = false }: Props) => {
  const [user] = useRecoilState(userState);
  const [, setCurrentTenantId] = useRecoilState(userTenantIdState);
  const [, setCurrentCorporationId] = useRecoilState(corporationIdState);
  const [tenantList, setTenantList] = useState<UserTenant[]>([]);
  const { getLocalStorage, getAllLocalStorage, setLocalStorage } =
    useLocalstorage();
  const { onLogout } = useRouteAuth();
  const { corporations } = useUserCorporationInfo();
  const navigate = useNavigate();

  useEffect(() => {
    if (Array.isArray(user?.tenant)) {
      setTenantList(user?.tenant || []);
    }
  }, [user?.tenant]);

  /**
   * テナントを変更した場合の処理
   */
  const onChangeTenant = useCallback(
    (event: ChangeEvent<HTMLSelectElement>) => {
      setCurrentTenantId(event.target.value);
      // localStorageに法人IDが含まれているためlocalStorageの再生成を行う
      const remakeLocalStorage: LocalStorageType = {
        ...getAllLocalStorage(),
        ...{ tenantId: event.target.value },
      };
      setLocalStorage(remakeLocalStorage);
      // テナントを変更した場合は強制的にTOPへ遷移させる
      window.location.href = '/';
    },
    [setCurrentTenantId, setLocalStorage, getAllLocalStorage],
  );

  /**
   * 法人を変更し組織管理画面へ遷移する処理
   */
  const onMoveCorporation = useCallback(
    (corporationId: string) => {
      // localstorage, recoilのcorporationIDを書き換える
      setCurrentCorporationId(corporationId);
      // localStorageにtenantIDが含まれているためlocalStorageの再生成を行う
      const remakeLocalStorage: LocalStorageType = {
        ...getAllLocalStorage(),
        ...{ corporationId },
      };
      setLocalStorage(remakeLocalStorage);

      const currentPath = window.location.pathname;
      const adminUrlRegExp = new RegExp('^/admin');
      // 現在のpathが/adminの場合は通常のページ遷移
      if (adminUrlRegExp.test(currentPath)) {
        navigate('/admin');

        return;
      }
      // テナント設定管理画面の場合は別ウィンドウでページ遷移
      window.open('/admin', '_blank');
    },
    [setCurrentCorporationId, setLocalStorage, getAllLocalStorage, navigate],
  );

  return (
    <Flex alignItems="center" h="100%">
      {/* 組織管理画面では非表示 */}
      {!isAdmin && (
        <Select
          variant="unstyled"
          cursor="pointer"
          onChange={onChangeTenant}
          value={getLocalStorage({ key: 'tenantId' })}
        >
          {tenantList.map((tenant) => (
            <option
              key={tenant.id}
              value={tenant.id}
              color="#000"
              style={{ all: 'initial' }}
            >
              {tenant.name}
            </option>
          ))}
        </Select>
      )}
      <Divider
        orientation="vertical"
        size="sm"
        mr={4}
        height="80%"
        borderWidth={1}
      />
      <Menu>
        <MenuButton>
          <Avatar size="sm" />
        </MenuButton>
        <MenuList color="teal.700" fontSize="sm" minWidth={24}>
          {corporations.map((corporation, idx) => (
            <MenuItem
              onClick={() => onMoveCorporation(corporation.id)}
              key={`corporations_${corporation.name}_${String(idx)}`}
              justifyContent="center"
            >
              {corporation.name}
            </MenuItem>
          ))}
          <MenuItem
            icon={<AiOutlinePoweroff size={18} />}
            onClick={() => onLogout()}
          >
            ログアウト
          </MenuItem>
        </MenuList>
      </Menu>
    </Flex>
  );
});
