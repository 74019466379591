import { useQuery } from 'react-query';
import { GroupFlat } from 'api/tenant/types';
import { useCustomToast } from 'hooks/useCustomToast';
import { getGroup } from 'api/tenant/getGroup';
import { queryTenantKey } from './queryTenantKey';

/**
 * グループ のサイドメニューにて
 * 選択したグループ詳細取得
 */
export const useGroupInfo = ({
  tenantId,
  groupId,
}: {
  tenantId: string;
  groupId: string;
}): GroupFlat | undefined => {
  const toast = useCustomToast();
  const response = useQuery(
    queryTenantKey.groupDetail({ tenantId, groupId }),
    () => getGroup(tenantId, groupId),
    {
      onError: (err: unknown) => {
        if (err instanceof Error) {
          toast.closeAll();
          toast({
            title: err.message,
            status: 'error',
          });
        }
      },
    },
  );
  const { data: groupInfo } = response;

  return groupInfo;
};
