import { z, ZodIssueOptionalMessage, ZodParsedType } from 'zod';

type ReturnValue = { message: string };
type Context = { defaultError: string; data: unknown };

/**
 * zodのスキーマを作成する際に既存の型から型推論が行えるようにする
 * 例 const GroupSchema = z.object<toZod<Group>>({id: z.string(),name: z.string().optional()});
 * */
export type toZod<T extends Record<string, unknown>> = {
  [K in keyof T]-?: z.ZodType<T[K]>;
};

// zodError messageの日本語対応
export const customErrorMessage = (
  issue: ZodIssueOptionalMessage,
  ctx: Context,
): ReturnValue => {
  switch (issue.code) {
    case z.ZodIssueCode.invalid_type:
      if (issue.received === ZodParsedType.undefined) {
        return {
          message: `必須の項目です。`,
        };
      }

      return {
        message: `${issue.expected}の型を期待していますが、
        ${issue.received}が指定されています。`,
      };
    case z.ZodIssueCode.unrecognized_keys:
      return {
        message: `オブジェクトのキー ${String(issue.keys)} が識別できません。`,
      };
    case z.ZodIssueCode.invalid_literal:
      return {
        message: `${issue.code}: 無効な値です。
        ${String(issue.expected)}を入力してください。`,
      };
    case z.ZodIssueCode.invalid_union: {
      const receiveds = issue.unionErrors.map((errors) =>
        errors.issues.map((item) => {
          if (
            item.code === z.ZodIssueCode.invalid_type ||
            item.code === z.ZodIssueCode.invalid_literal
          ) {
            return item.received;
          }

          return null;
        }),
      );
      const expecteds = issue.unionErrors.map((errors) =>
        errors.issues.map((item) => {
          if (
            item.code === z.ZodIssueCode.invalid_type ||
            item.code === z.ZodIssueCode.invalid_literal
          ) {
            return item.expected;
          }

          return null;
        }),
      );

      return {
        message: `入力形式が間違っています。
        期待した値は${String(expecteds.flat())}です。
        入力された値は${String(...receiveds)}`,
      };
    }

    case z.ZodIssueCode.invalid_union_discriminator:
      return {
        message: `無効な識別子です。
        ${String(issue.options)}で入力してください。`,
      };
    case z.ZodIssueCode.invalid_enum_value:
      return {
        message: `${issue.received}'は無効な値です。
        ${String(issue.options)}で入力してください。`,
      };
    case z.ZodIssueCode.invalid_arguments:
      return {
        message: `引数が間違っています。`,
      };
    case z.ZodIssueCode.invalid_return_type:
      return {
        message: `返値の型が間違っています。`,
      };
    case z.ZodIssueCode.invalid_date:
      return {
        message: `間違った日時データです。`,
      };
    case z.ZodIssueCode.invalid_string:
      return {
        message: `${String(issue.validation)}は無効な形式です`,
      };
    default:
      return { message: ctx.defaultError };
  }
};
