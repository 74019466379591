import {
  Box,
  Tab,
  TabList,
  TabPanel,
  TabPanels,
  Tabs,
  useDisclosure,
} from '@chakra-ui/react';
import { zodResolver } from '@hookform/resolvers/zod';
import { withSuspenseAndErrorBoundary } from 'admin/components/Error/WithErrorBoundary';
import { FirstLoginMailFormType } from 'api/transactionMail/type';
import {
  ChangeLocationDialog,
  ErrorContents,
  LoadingSkeleton,
} from 'components/common/atoms';
import { TextEditor } from 'components/mail/molecules/TextEditor';
import { HtmlEditor } from 'components/transactionMail/HtmlEditor';
import { MailHeader } from 'components/transactionMail/SendingContentForm/MailHeader';
import { useEditSendingContent } from 'hooks/transactionMail/useEditSendingContent';
import { useSendingContent } from 'hooks/transactionMail/useSendingContent';
import { useTransactionMailPermission } from 'hooks/transactionMail/useTransactionMailPermission';
import { useBrowserBackControl } from 'hooks/useBrowserBackControl';
import { useCustomNavigate } from 'hooks/useCustomNavigate';
import { useCustomToast } from 'hooks/useCustomToast';
import { useEmailEditor } from 'hooks/useEmailEditor';
import { FC, memo, useCallback, useEffect, useRef, useState } from 'react';
import EmailEditor from 'react-email-editor';
import { FormProvider, useForm } from 'react-hook-form';
import { EditFirstLoginMailFormSubmitTypeSchema } from 'schema/transactionMail';
import { errorToast } from 'utils/toast';

type Props = {
  id: string;
};

export const FetchMailEditForm: FC<Props> = memo(({ id }) => {
  const { isEditable } = useTransactionMailPermission();
  const { data } = useSendingContent(id);
  const editorRef = useRef<EmailEditor>(null);
  const { isOpen, onOpen, onClose } = useDisclosure();

  const toast = useCustomToast();
  const { exportHtml } = useEmailEditor({ editor: editorRef });
  const [isHandleLoad, setIsHandleLoad] = useState(true);

  const methods = useForm<FirstLoginMailFormType>({
    mode: 'onBlur',
    // エラーのある入力が再度バリデーションされるタイミングを変更(default: onChange)
    reValidateMode: 'onBlur',
    defaultValues: {
      id: data?.id ?? '',
      subject: data?.subject ?? '',
      settingTitle: data?.settingTitle ?? '',
      editorHtml: data?.editorHtml ?? '',
      bodyHtml: data?.editorHtml ?? '',
      editorText: data?.editorText ?? '',
      bodyText: data?.editorText ?? '',
      type: 'startService',
    },
    resolver: zodResolver(EditFirstLoginMailFormSubmitTypeSchema),
  });
  const {
    setValue,
    getValues,
    setError,
    formState: { isDirty },
    handleSubmit,
  } = methods;
  const formId = 'mailEditForm';
  const { mutate, isLoading } = useEditSendingContent(setError);

  // 編集中かどうかをstateで管理
  const [isEdited, setIsEdited] = useState(false);
  // ページ遷移カスタムnavigate
  const { navigate, resetEditState } = useCustomNavigate({
    isEdited,
    // isDirty,
    callback: onOpen,
  });
  // ブラウザバック制御
  useBrowserBackControl({ isEdited, onOpen });

  /**
   * 修正が加えられたかどうか
   */
  useEffect(() => {
    setIsEdited(isDirty);
  }, [isDirty]);

  /**
   * フォーム送信
   */
  const onMailFormSubmit = useCallback(async () => {
    const submitData: FirstLoginMailFormType = {
      ...getValues(),
      editorText: getValues('bodyText'),
    };
    resetEditState();
    // 新規作成
    await mutate(submitData);
  }, [getValues, mutate, resetEditState]);

  /**
   * 保存処理
   */
  const onSave = useCallback(async () => {
    // テキストメールの本文が未入力の時は保存しない
    if (getValues('bodyText') === '') {
      toast({
        ...errorToast,
        description: 'テキストメールの本文が未入力です。',
        duration: 4000,
      });

      return;
    }
    try {
      const { design, html } = await exportHtml();
      setValue('editorHtml', JSON.stringify(design.body));
      setValue('bodyHtml', html);
      await onMailFormSubmit();
    } catch (err) {
      if (err instanceof Error) {
        toast.closeAll();
        toast({
          ...errorToast,
          title: 'HTMLエディタエラー',
          description: err.message,
        });
      }
    }
  }, [getValues, exportHtml, toast, setValue, onMailFormSubmit]);

  return (
    <Box w="100%">
      <FormProvider {...methods}>
        <form id={formId} onSubmit={handleSubmit(onSave)}>
          <MailHeader
            isLoading={isLoading || isHandleLoad}
            isDisabled={!isEditable || isHandleLoad || isLoading}
            editorRef={editorRef}
            navigate={navigate}
          />
          <Tabs w="100%">
            <TabList>
              <Tab isDisabled={isHandleLoad} _focus={{ focus: 'none' }}>
                HTMLメールエディター
              </Tab>
              <Tab isDisabled={isHandleLoad} _focus={{ focus: 'none' }}>
                テキストメールエディタ
              </Tab>
            </TabList>
            <TabPanels>
              <TabPanel p={0}>
                <HtmlEditor
                  editor={editorRef}
                  setIsHandleLoad={setIsHandleLoad}
                  isEditable={!isEditable}
                />
              </TabPanel>
              <TabPanel p={0}>
                <TextEditor isEditable={!isEditable} />
              </TabPanel>
            </TabPanels>
          </Tabs>
        </form>
      </FormProvider>
      <ChangeLocationDialog
        isOpen={isOpen}
        onClose={onClose}
        setIsEdited={setIsEdited}
      />
    </Box>
  );
});

export const MailEditForm = withSuspenseAndErrorBoundary(FetchMailEditForm, {
  ErrorComponent: <ErrorContents name="送信内容設定詳細" />,
  LoadingComponent: <LoadingSkeleton width="100%" />,
});
