import { atom, selector } from 'recoil';
import { NodeModel } from '@minoru/react-dnd-treeview';
import { tableIdState } from 'store/contentsdb';
import { randStr } from 'utils/str';
import { ID_PREFIX_CONTENTS_TBL } from 'define';

// NodeModel.id のデータ型を指定
type NodeModelIdType = Pick<NodeModel, 'id'>;

// ノードツリーをリフレッシュする際に用いるキー
export const treeVersionKeyState = atom<string>({
  key: 'treeVersionKeyState',
  default: randStr(4).toLowerCase(),
});

export const currentNodeIdState = atom<NodeModelIdType['id']>({
  key: 'currentNodeIdState',
  default: '',
});

export const currentNodeIdSelector = selector<NodeModelIdType['id']>({
  key: 'currentNodeIdSelector',
  get: ({ get }) => get(currentNodeIdState),
  set: ({ set }, newValue) => {
    set(currentNodeIdState, newValue || '');
    if (
      typeof newValue === 'string' &&
      newValue.startsWith(ID_PREFIX_CONTENTS_TBL)
    ) {
      set(tableIdState, newValue);
    }
  },
});
