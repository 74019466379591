import { request } from 'api/request';
import { isResponseError, ResponseError } from 'api/types';
import { Options } from 'ky';
import { getValidationError, ValidationError } from 'utils/form';
import { messages } from './messages';
import {
  EnqueteListDefaultValue,
  EnqueteListType,
  isEnqueteListType,
} from './types';

const DEFAULT_ERR_MESSAGE: ValidationError<EnqueteListType> = {
  others: [messages.getEnqueteCopy.customer.error],
};
const DEFAULT_SYSTEM_ERR_MESSAGE: ValidationError<EnqueteListType> = {
  others: [messages.getEnqueteCopy.customer.failed],
};

// フォームコピー処理
export const getEnqueteCopy = async (
  tenantId: string,
  enqueteId: string,
  options?: Options,
): Promise<EnqueteListType | ResponseError> => {
  const path = `enquete/${tenantId}/${enqueteId}/copy/`;

  const response = await request({
    path,
    method: 'get',
    options,
  });

  if (response.status >= 500) {
    return DEFAULT_ERR_MESSAGE;
  }

  if (response.status === 403) {
    return DEFAULT_SYSTEM_ERR_MESSAGE;
  }

  const responseData = (await response.data) as unknown[];

  // フォームコピー成功
  if (isEnqueteListType(responseData)) {
    return responseData;
  }

  const { error } = response;

  if (response.hasError) {
    // APIにて返却されたエラー形式
    if (isResponseError(error)) {
      const formError = getValidationError<EnqueteListType>({
        formData: EnqueteListDefaultValue,
        response: error,
      });

      return formError;
    }
  }

  // 登録できていない旨を通知
  return DEFAULT_SYSTEM_ERR_MESSAGE;
};
