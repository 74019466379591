import { withSuspenseAndErrorBoundary } from 'admin/components/Error/WithErrorBoundary';
import { MemberListTableBodyComponent } from 'admin/components/member/memberListTableBody/MemberListTableBodyComponent';
import { MemberType } from 'admin/types/member';
import { ErrorContents } from 'components/common/atoms';
import { FC, memo } from 'react';
import { useNavigate } from 'react-router-dom';

type Props = {
  member: MemberType;
};

const memberListTableBody: FC<Props> = memo(({ member }: Props) => {
  const navigate = useNavigate();

  return <MemberListTableBodyComponent member={member} navigate={navigate} />;
});

export const MemberListTableBody = withSuspenseAndErrorBoundary(
  memberListTableBody,
  {
    ErrorComponent: <ErrorContents name="会員一覧情報" />,
  },
);
