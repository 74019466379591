import { putTerm } from 'api/paywall/putTerm';
import { isResponseError, ResponseError } from 'api/types';
import { termDefaultValues, termsSettingDefaultValues } from 'components/paywall/pages/TermsSetting/initial';
import {
  isTermsSetting, Term, TermsSetting, TermsSettingSubmitFormType
} from 'components/paywall/pages/TermsSetting/typed';
import { usePaywallUtil } from 'hooks/paywall/usePaywallUtil';
import { useCustomToast } from 'hooks/useCustomToast';
import { FieldPath, UseFormSetError } from 'react-hook-form';
import { UseMutateFunction, useMutation } from 'react-query';
import { getValidationError, isGetNestedValidationError, toMultiError } from 'utils/form';

type UnPromisify<T> = T extends Promise<infer U> ? U : T;

/**
 * 規約情報更新 hooks
 * @param {UseFormSetError<TermsSetting>} setError
 * @param {Function} close
 * @param {Function} reset
 * @returns {Object} { onSubmit, isLoading }
 */
export const useUpdateTerm = ({
  setError,
  close,
  reset,
}: {
  setError?: UseFormSetError<TermsSetting>;
  close?: () => void;
  reset?: () => void;
}): {
  onSubmit: UseMutateFunction<
    UnPromisify<ReturnType<typeof putTerm>>,
    unknown,
    TermsSettingSubmitFormType,
    unknown
  >;
  isLoading: boolean;
  isSuccess: boolean;
} => {
  const toast = useCustomToast();
  const { paywallTermsRefetchQueries } = usePaywallUtil();

  const {
    mutate: onSubmit,
    isLoading,
    isSuccess,
  } = useMutation(
    ({ tenantId, termSetting }: TermsSettingSubmitFormType) =>
      putTerm({ tenantId, termSetting }),
    {
      onSuccess: (result: TermsSetting | boolean | ResponseError) => {
        paywallTermsRefetchQueries();
        if (isTermsSetting(result)) {
          close?.();
          reset?.();
          toast({
            status: 'success',
            position: 'bottom',
            duration: 4000,
            isClosable: true,
            title: '規約情報を更新しました',
          });
        }

        if (
          isGetNestedValidationError<TermsSetting>(
            result,
            Object.keys(termsSettingDefaultValues),
          )
        ) {
          if (result?.others) {
            const errorMsg = Object.entries(result).map(([_, value]) => value);
            toast({
              status: 'error',
              position: 'bottom',
              duration: 4000,
              isClosable: true,
              description: errorMsg[0],
            });
          } else {
            Object.keys(result).forEach((k) => {
              const key = k as keyof TermsSetting;
              const errMsgs = result?.[key];
              if (
                errMsgs &&
                Array.isArray(errMsgs) &&
                errMsgs.every((v) => typeof v === 'string')
              ) {
                setError?.(key, {
                  types: toMultiError(errMsgs),
                });
              } else {
                errMsgs?.forEach((err, idx) => {
                  if (isResponseError(err)) {
                    const nestErrors = getValidationError<Term>({
                      formData: termDefaultValues,
                      response: err as ResponseError,
                    });
                    Object.keys(nestErrors).forEach((tk) => {
                      const termkey = tk as keyof Term;
                      const errNestMsgs = nestErrors[termkey];
                      const errorKey: FieldPath<TermsSetting> = `terms.${idx}.${termkey}`;
                      setError?.(errorKey, {
                        types: toMultiError(errNestMsgs || []),
                      });
                    });
                  }
                });
                toast({
                  status: 'error',
                  position: 'bottom',
                  duration: 4000,
                  isClosable: true,
                  title: '入力エラーがあります。',
                  description: 'エラーを確認してください。',
                });
              }
            });
          }
        }
      },
    },
  );

  return { onSubmit, isLoading, isSuccess };
};
