import {
  AlertDialogBody,
  AlertDialogFooter,
  Box,
  Button,
  FormControl,
  FormErrorMessage,
  Heading,
  ListItem,
  UnorderedList,
} from '@chakra-ui/react';
import { RhfInput } from 'admin/components/form/RhfInput';
import { RhfSelect } from 'admin/components/form/RhfSelect';
import { AlertInfoBar } from 'admin/components/ui/alertInfo/alertInfoBar';
import { RequiredIcon } from 'admin/components/ui/icon/requiredIcon';
import { Colors, ColorSet } from 'admin/define/colors';
import { CreateIdPoolConsumerFormType } from 'admin/types/userPool/form';
import {
  ALLOWED_AUTH_CODE_TYPES,
  AUTH_CODE_TYPE_LIST,
  AUTH_CODE_TYPE_UNIIKEY,
} from 'define';
import { FC, memo, useMemo } from 'react';
import { useFormContext, useFormState, useWatch } from 'react-hook-form';
import { toErrMsgList } from 'utils/form';

type Props = {
  formId: string;
  onSubmit: (value: CreateIdPoolConsumerFormType) => void;
  onClose: () => void;
};

export const CreateFormComponent: FC<Props> = memo(
  ({ formId, onClose, onSubmit }: Props) => {
    const { errors } = useFormState<CreateIdPoolConsumerFormType>();
    const { control, handleSubmit } =
      useFormContext<CreateIdPoolConsumerFormType>();
    const watchAuthEiticationType = useWatch<CreateIdPoolConsumerFormType>({
      control,
      name: 'authenticationType',
    });
    const authenticationList = useMemo(
      () =>
        Array.from(AUTH_CODE_TYPE_LIST).filter((x) =>
          ALLOWED_AUTH_CODE_TYPES.includes(x[0]),
        ),
      [],
    );

    return (
      <form onSubmit={handleSubmit(onSubmit)} id={formId}>
        <AlertDialogBody maxH="400px" overflowY="auto">
          <Box>
            <Box>
              <Box mb="16px">
                <Heading as="h3" fontSize="14px" mb={2}>
                  <Box display="flex" alignItems="center">
                    名前 <RequiredIcon ml="8px" />
                  </Box>
                </Heading>
                <FormControl isInvalid={Boolean(errors.name)}>
                  <Box>
                    <RhfInput<CreateIdPoolConsumerFormType>
                      name="name"
                      bgColor={Colors.WHITE}
                    />
                  </Box>
                  {toErrMsgList(errors, 'name').map((err) => (
                    <FormErrorMessage key={`name_${err}`}>
                      {err}
                    </FormErrorMessage>
                  ))}
                </FormControl>
              </Box>
              {/* 認証タイプがuniikeyの時のみフィールド名を表示する */}
              {Number(watchAuthEiticationType) === AUTH_CODE_TYPE_UNIIKEY && (
                <Box mb="16px">
                  <Heading as="h3" fontSize="14px" mb={2}>
                    <Box display="flex" alignItems="center">
                      フィールド名 <RequiredIcon ml="8px" />
                    </Box>
                  </Heading>
                  <FormControl isInvalid={Boolean(errors.fieldName)}>
                    <Box>
                      <RhfInput<CreateIdPoolConsumerFormType>
                        name="fieldName"
                        bgColor={Colors.WHITE}
                      />
                    </Box>
                    {toErrMsgList(errors, 'fieldName').map((err) => (
                      <FormErrorMessage key={`fieldName_${err}`}>
                        {err}
                      </FormErrorMessage>
                    ))}
                  </FormControl>
                </Box>
              )}
              <Box>
                <Heading as="h3" fontSize="14px" mb={2}>
                  <Box display="flex" alignItems="center">
                    利用ID種別 <RequiredIcon ml="8px" />
                  </Box>
                </Heading>
                <FormControl isInvalid={Boolean(errors.authenticationType)}>
                  <Box>
                    <RhfSelect<CreateIdPoolConsumerFormType>
                      name="authenticationType"
                      placeholder="選択してください。"
                      bgColor={Colors.WHITE}
                    >
                      {authenticationList.map((x) => (
                        <option value={x[0]} key={`authentication_${x[0]}`}>
                          {x[1]}
                        </option>
                      ))}
                    </RhfSelect>
                  </Box>
                  {toErrMsgList(errors, 'authenticationType').map((err) => (
                    <FormErrorMessage key={`authenticationType_${err}`}>
                      {err}
                    </FormErrorMessage>
                  ))}
                </FormControl>
              </Box>
              <Box mt="24px">
                <AlertInfoBar type="error" iconType="error">
                  <UnorderedList>
                    <ListItem>
                      選択されたIDシステムは作成後の変更はできません。
                    </ListItem>
                    <ListItem>
                      一度作成したユーザープールの削除はできません。
                    </ListItem>
                  </UnorderedList>
                </AlertInfoBar>
              </Box>
            </Box>
          </Box>
        </AlertDialogBody>
        <AlertDialogFooter>
          <Button
            variant="outline"
            mr={3}
            onClick={onClose}
            p="16px 24px"
            w="120px"
            {...ColorSet.Default}
          >
            キャンセル
          </Button>

          <Button
            type="submit"
            form={formId}
            p="16px 24px"
            w="120px"
            colorScheme="primary"
          >
            確認へ進む
          </Button>
        </AlertDialogFooter>
      </form>
    );
  },
);
