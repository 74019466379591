import { request } from 'admin/api/Request';
import { API_MESSAGES } from 'admin/define/apiMessages';
import { CustomError } from 'admin/error/CustomError';
import { securitySettingsSchema } from 'admin/schema/idPoolConsumer/auth';
import { UserPool } from 'admin/types/userPool';
import { SecuritySettings } from 'admin/types/userPool/auth';
import { ZodError } from 'zod';

export const getSecuritySettings = async (
  id: UserPool['id'],
): Promise<SecuritySettings> => {
  const path = `uniikey/${id}/security_settings/`;
  const response = await request<SecuritySettings>({
    path,
    method: 'get',
  });

  if (response.hasError) {
    if (response.status === 404) {
      throw new CustomError(
        API_MESSAGES.USER_POOL.GET_AUTH_SECURITY_SETTINGS.NOT_FOUND,
        {
          path,
          status: response.status,
        },
      );
    } else {
      throw new CustomError(
        API_MESSAGES.USER_POOL.GET_AUTH_SECURITY_SETTINGS.ERROR,
        {
          path,
          status: response.status,
        },
      );
    }
  }
  try {
    securitySettingsSchema.parse(response.data);
  } catch (error) {
    if (error instanceof ZodError) {
      throw new CustomError(error.message, {
        path,
        status: response.status,
        logLevel: 'error',
      });
    }
  }

  return response.data;
};
