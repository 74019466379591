import { getDomainList } from 'admin/api/saml/getDomainList';
import { CustomError } from 'admin/error/CustomError';
import { logger } from 'api/logger';
import { useCorporationId } from 'hooks/user/useCorporationId';
import { useQuery } from 'react-query';
import { queryKeys } from './queryKeys';

export const useDomainList = () => {
  const corporationId = useCorporationId();

  const { data } = useQuery({
    queryKey: queryKeys.getDomainList(corporationId),
    queryFn: () => getDomainList({id: corporationId}),
    onError: async (error) => {
      if (error instanceof CustomError) {
        await logger({
          loglevel: error.logLevel,
          data: JSON.stringify(error.cause),
          message: error.message,
        });
      }
    },
  });

  return { data };
};
