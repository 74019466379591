import { useState, useEffect } from 'react';
import { Skin } from 'api/enquete/types';

/**
 * アンケートプレビュー hooks
 * @returns {Object} { onSubmit, isLoading }
 */
export const useEnqueteSkinPreview = (): {
  onPreivew: (redirectUrl: string, sendData: Skin) => void;
} => {
  const [data, setData] = useState<Window | null>(null);
  const [host, setHost] = useState<string>('');
  const [skinData, setSkinData] = useState<Skin>();

  useEffect(() => {
    if (data !== null) {
      /**
       * TODO
       * setTimeoutを行わない場合、open後のview側でデータが受信できなかった
       */
      setTimeout(() => {
        data?.postMessage({ form_skin: skinData }, host);
      }, 1000);
    }
  }, [data]);

  const onPreivew = (redirectUrl: string, sendData: Skin): void => {
    setHost(redirectUrl);
    setSkinData(sendData);
    // プレビューwindow open
    const popup = window.open(redirectUrl, '_blank');
    setData(popup);
  };

  return { onPreivew };
};
