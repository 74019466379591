import { AddIcon, QuestionIcon } from '@chakra-ui/icons';
import {
  Box,
  Button,
  Flex,
  FormControl,
  FormLabel,
  Stack,
  Text,
  Textarea,
  Tooltip,
} from '@chakra-ui/react';
import styled from '@emotion/styled';
import { InputForm } from 'components/common/molecules';
import { Colors } from 'components/paywall/config';
import {
  Term,
  TermsSetting,
} from 'components/paywall/pages/TermsSetting/typed';
import { FC, memo, useCallback, useEffect, useState } from 'react';
import { useFieldArray, useFormContext } from 'react-hook-form';
import { BsTrash } from 'react-icons/bs';

const TermsLinkListStackContainer = styled(Stack)({
  spacing: '1rem',
});
const TermsLinkStackContainer = styled(Stack)({
  spacing: '0.5rem',
  border: '1px solid #E2E8F0',
  borderRadius: '6px',
  background: '#F7FAFC',
  padding: '1rem 1rem 1rem',
  position: 'relative',
});

const StyledHeaderWithToolTip = styled(Box)({
  display: 'flex',
  alignItems: 'center',
});

const StyledToolTip = styled(Tooltip)({
  fontSize: '12px',
  borderRadius: '4px',
});

const StyledQuestionIcon = styled(QuestionIcon)({
  marginLeft: '0.5rem',
  cursor: 'default',
});

type Props = {
  formId: string;
  submit: (data: TermsSetting) => void;
};

export const Form: FC<Props> = memo(({ formId, submit }) => {
  const [description, setDescription] = useState('');
  const { control, handleSubmit, getValues, setValue } =
    useFormContext<TermsSetting>();

  const { fields, append, remove } = useFieldArray({
    control,
    name: 'terms',
    keyName: 'key',
  });

  useEffect(() => {
    setDescription(getValues('description') || '');
  }, [setDescription, getValues]);

  const onChangeDescription = useCallback(
    (val: string) => {
      setDescription(val);
      setValue('description', val);
    },
    [setValue, setDescription],
  );

  // 規約リンクリスト追加
  const addTermUrlLink = useCallback(() => {
    const addTermUrlLinkData: Term = {
      title: '',
      url: '',
    };
    append(addTermUrlLinkData);
  }, [append]);

  // 規約リンクリスト削除
  const removeTermUrlLink = useCallback(
    (index: number) => {
      remove(index);
    },
    [remove],
  );

  return (
    <form id={formId} onSubmit={handleSubmit((data) => submit(data))}>
      <Stack spacing={6}>
        <Box fontFamily="Hiragino Sans">
          <InputForm<TermsSetting>
            name="title"
            type="text"
            labelElement={<Text fontWeight="bold">タイトル</Text>}
          />
        </Box>

        <Box fontFamily="Hiragino Sans">
          <Text mb={2} fontWeight={700}>
            <StyledHeaderWithToolTip>
              説明文
              <StyledToolTip label="テキストのみ入力可能です。説明文内にURLを記載してもリンクは設定はされません。">
                <StyledQuestionIcon />
              </StyledToolTip>
            </StyledHeaderWithToolTip>
          </Text>
          <Textarea
            value={description}
            borderColor="gray.200"
            onChange={(e) => onChangeDescription(e.target.value)}
            size="sm"
            resize="none"
            borderRadius="md"
            minH="304px"
            py="7.5px"
          />
        </Box>
        <FormControl fontFamily="Hiragino Sans">
          <FormLabel htmlFor="terms" fontSize="sm" fontWeight={700}>
            規約リンク
          </FormLabel>
          <TermsLinkListStackContainer>
            {fields.map((field, index) => (
              <TermsLinkStackContainer key={field.key}>
                <Box
                  display="flex"
                  justifyContent="space-between"
                  alignItems="center"
                >
                  <Text fontWeight={700}>規約リンク{index + 1}</Text>
                  <Box>
                    <Tooltip label="規約リンクを削除します">
                      <Button
                        leftIcon={<BsTrash />}
                        variant="outline"
                        borderColor={Colors.BLUE_20}
                        bgColor={Colors.WHITE}
                        color={Colors.BLUE_20}
                        onClick={() => removeTermUrlLink(index)}
                        padding="6px 14px"
                      >
                        削除
                      </Button>
                    </Tooltip>
                  </Box>
                </Box>
                <InputForm<TermsSetting>
                  name={`terms.${index}.title`}
                  type="text"
                  labelElement={
                    <Text fontWeight="bold" marginTop="8px">
                      規約名称
                    </Text>
                  }
                  background="#FFFFFF"
                />
                <InputForm<TermsSetting>
                  name={`terms.${index}.url`}
                  type="text"
                  labelElement={
                    <Text fontWeight="bold" marginTop="8px">
                      URL
                    </Text>
                  }
                  background="#FFFFFF"
                />
              </TermsLinkStackContainer>
            ))}
          </TermsLinkListStackContainer>
        </FormControl>
      </Stack>
      <Flex>
        <Box margin="8px auto 24px" fontFamily="Hiragino Sans">
          <Button
            leftIcon={<AddIcon />}
            variant="outline"
            borderColor={Colors.BLUE_20}
            bgColor={Colors.WHITE}
            color={Colors.BLUE_20}
            onClick={addTermUrlLink}
            padding="8px 16px"
          >
            規約リンクを追加
          </Button>
        </Box>
      </Flex>
    </form>
  );
});
