import { Box, Divider, IconButton, VStack } from '@chakra-ui/react';
import { CustomMenuListItem } from 'admin/components/sideMenu/customeMenuListItem';
import { CustomMenuList } from 'components/chakra/sidemenu';
import { FC, memo } from 'react';
import { FiHelpCircle } from 'react-icons/fi';
import { RiArrowLeftDoubleLine, RiArrowRightDoubleFill } from 'react-icons/ri';
import { useRecoilState } from 'recoil';
import { adminSidebarState } from 'store/adminSidebarState';
import { ADMIN_MENU_BACKGROUND_COLOR } from 'theme';
import { SideMenuList } from './sideMenuList';

/**
 * Adminページ用SideMenu
 */
export const SideMenu: FC = memo(() => {
  const [isOpen, setOpen] = useRecoilState(adminSidebarState);

  return (
    <VStack
      alignItems="flex-start"
      h="100%"
      w="100%"
      bg={ADMIN_MENU_BACKGROUND_COLOR}
    >
      <SideMenuList isOpen={isOpen} />
      <Box w="full">
        <CustomMenuList>
          <CustomMenuListItem
            href="https://uniikey.notion.site/607aaba31fbd432da13963fca3f7fd06"
            name="ヘルプ"
            fontSize="20px"
            tLabel="ヘルプ：利用マニュアル・問い合わせ（別タブで開く）"
            icon={FiHelpCircle}
            isActive={false}
            isOpen={isOpen}
          />
          {/*
          TODO: 現状設定する項目がないので非表示
          <CustomMenuListItem
            to="/admin/setting/"
            fontSize="20px"
            name="設定"
            icon={FiSettings}
            isActive={location.pathname.includes('/setting')}
            isOpen={isOpen}
          /> */}
        </CustomMenuList>
      </Box>

      <Divider borderColor="#C9CCCF" />
      <Box
        onClick={() => setOpen(!isOpen)}
        display="flex"
        alignItems="center"
        justifyContent={`${isOpen ? 'end' : 'center'}`}
        w="100%"
      >
        {isOpen ? (
          <IconButton
            aria-label="close"
            icon={<RiArrowLeftDoubleLine />}
            bg={ADMIN_MENU_BACKGROUND_COLOR}
            mr="0.5rem"
            mb="0.5rem"
          />
        ) : (
          <IconButton
            aria-label="open"
            icon={<RiArrowRightDoubleFill />}
            bg={ADMIN_MENU_BACKGROUND_COLOR}
            mb="0.5rem"
          />
        )}
      </Box>
    </VStack>
  );
});
