import { request } from 'admin/api/Request';
import { API_MESSAGES } from 'admin/define/apiMessages';
import { CustomError } from 'admin/error/CustomError';
import { attributeSchema } from 'admin/schema/idPoolConsumer/attribute';
import { UserPool } from 'admin/types/userPool';
import { IdPoolConsumerAttributeUpdateFormType } from 'admin/types/userPool/attribute/form';
import { IdPoolConsumerAttributeType } from 'admin/types/userPool/attribute/index';
import { ZodError } from 'zod';

export const changeAttributeAutoAppend = async ({
  userPoolId,
  id,
}: {
  userPoolId: UserPool['id'];
  id: IdPoolConsumerAttributeType['id'];
}): Promise<IdPoolConsumerAttributeUpdateFormType> => {
  const params = {
    isAutoAppendToChild: true,
  };

  const path = `uniikey/${userPoolId}/user_attribute/${id}/`;
  const response = await request<IdPoolConsumerAttributeUpdateFormType>({
    path,
    body: params,
    method: 'patch',
  });

  if (response.hasError) {
    if (response.status === 404) {
      throw new CustomError(
        API_MESSAGES.USER_POOL.ATTRIBUTE_AUTO_APPEND_CHANGE.FAILED,
        {
          path,
          status: response.status,
        },
      );
    } else {
      throw new CustomError(
        API_MESSAGES.USER_POOL.ATTRIBUTE_AUTO_APPEND_CHANGE.ERROR,
        {
          path,
          status: response.status,
          logLevel: response.status === 400 ? 'warning' : 'error',
        },
      );
    }
  }
  try {
    attributeSchema.parse(response.data);
  } catch (error) {
    if (error instanceof ZodError) {
      throw new CustomError(error.message, {
        path,
        status: response.status,
        logLevel: 'error',
      });
    }
  }

  return response.data;
};
