import { postMailMagazine } from 'api/mail/postMailMagazine';
import { ResponseError } from 'api/types';
import { mailMagazineDefaultValue } from 'components/setting/mailMagazine/initial';
import {
  MailMagazine,
  isMailMagazine,
  MailMagazineForm,
} from 'components/setting/mailMagazine/typed';
import { useCustomToast } from 'hooks/useCustomToast';
import { UseFormSetError } from 'react-hook-form';
import { UseMutateFunction, useMutation } from 'react-query';
import { isGetValidationError, toMultiError } from 'utils/form';
import { useMailUtil } from 'hooks/mail/useMailUtil';

type UnPromisify<T> = T extends Promise<infer U> ? U : T;

/**
 * メールマガジン作成 hooks
 * @param {UseFormSetError<MailMagazineForm>} setError
 * @param {Function} close
 * @param {Function} reset
 * @returns {Object} { onSubmit, isLoading }
 */

export const useAddMailMagazine = ({
  setError,
  close,
  reset,
}: {
  setError?: UseFormSetError<MailMagazineForm>;
  close?: () => void;
  reset?: () => void;
}): {
  onSubmit: UseMutateFunction<
    UnPromisify<ReturnType<typeof postMailMagazine>>,
    unknown,
    MailMagazineForm,
    unknown
  >;
  isLoading: boolean;
  isSuccess: boolean;
} => {
  const toast = useCustomToast();
  const { MailMagazineListRefetchQueries } = useMailUtil();

  const {
    mutate: onSubmit,
    isLoading,
    isSuccess,
  } = useMutation((params: MailMagazineForm) => postMailMagazine(params), {
    onSuccess: (result: MailMagazine | boolean | ResponseError) => {
      MailMagazineListRefetchQueries();

      if (isMailMagazine(result)) {
        close?.();
        reset?.();
        toast({
          status: 'success',
          position: 'bottom',
          duration: 4000,
          isClosable: true,
          title: 'メールマガジンを作成しました',
        });
      }

      if (
        isGetValidationError<MailMagazineForm>(
          result,
          Object.keys(mailMagazineDefaultValue),
        )
      ) {
        if (result?.others) {
          const errorMsg = Object.entries(result).map(([_, value]) => value);
          toast({
            status: 'error',
            position: 'bottom',
            duration: 4000,
            isClosable: true,
            description: errorMsg[0],
          });
        } else {
          Object.keys(result).forEach((k) => {
            const key = k as keyof MailMagazineForm;
            const errMsgs = result?.[key];
            if (errMsgs) {
              setError?.(key, { types: toMultiError(errMsgs) });
            }
          });
        }
      }
    },
  });

  return { onSubmit, isLoading, isSuccess };
};
