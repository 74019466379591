import { ResponseError } from 'api/types';
import { createUser } from 'api/user/createUser';
import { CreatedTenantUser, InvitationUser, isCreatedTenantUser } from 'api/user/types';
import { useCustomToast } from 'hooks/useCustomToast';
import { UseFormSetError } from 'react-hook-form';
import { UseMutateFunction, useMutation } from 'react-query';
import { useNavigate, useParams } from 'react-router-dom';
import { isGetValidationError, toMultiError } from 'utils/form';
import { successToast } from 'utils/toast';

type UnPromisify<T> = T extends Promise<infer U> ? U : T;

const invitationUserData: InvitationUser = {
  name: '',
  password: '',
  passwordConfirm: '',
};

/**
 * 招待アカウント登録hooks
 * @param {Object} UseFormSetError setError
 * @param {setGlobalErrors} callback setGlobalErrors
 * @returns {Object} { onSubmit, isLoading }
 */
export const useInvitationUserCreate = ({
  setError,
  setGlobalErrors,
}: {
  setError: UseFormSetError<InvitationUser>;
  setGlobalErrors: (errors: string[]) => void;
}): {
  onSubmit: UseMutateFunction<
    UnPromisify<ReturnType<typeof createUser>>,
    unknown,
    InvitationUser,
    unknown
  >;
  isLoading: boolean;
} => {
  const { verifyCode = '' } = useParams();
  const navigate = useNavigate();
  const toast = useCustomToast();

  const { mutate: onSubmit, isLoading } = useMutation(
    ({ name, password }: InvitationUser) =>
      createUser({ name, password, verifyCode }),
    {
      onSuccess: (result: CreatedTenantUser | boolean | ResponseError) => {
        if (isCreatedTenantUser(result)) {
          /**
           * 2024.03.08 itsukaichi
           * 招待受けたユーザーの登録後のフローが変更となる
           * アカウント登録後はログインページへ遷移する
           */
          navigate('/login');
          toast({
            ...successToast,
            duration: 4000,
            title: 'アカウント登録が完了しました。ログインしてください。',
            position: 'bottom',
            variant: 'solid',
          });

          // アカウント処理に成功した場合は、ログイン処理を行う
          // await onLogin(result.email, result.password);

          return;
        }

        if (
          isGetValidationError<InvitationUser>(
            result,
            Object.keys(invitationUserData),
          )
        ) {
          if (result?.others) {
            setGlobalErrors([result?.others?.join('')]);
          } else {
            Object.keys(result).forEach((k) => {
              const key = k as keyof InvitationUser;
              const errMsgs = result?.[key];
              if (errMsgs) {
                setError(key, { types: toMultiError(errMsgs) });
              }
            });
          }
        }
      },
    },
  );

  return { onSubmit, isLoading };
};
