import { RepeatIcon, WarningTwoIcon } from '@chakra-ui/icons';
import { Box, Tooltip } from '@chakra-ui/react';
import { memo, VFC } from 'react';

type ErrorContentsReloadType = {
  msg: string;
  onClick?: React.MouseEventHandler<SVGElement>;
};

export const ErrorContentsReload: VFC<ErrorContentsReloadType> = memo(
  ({
    msg,
    onClick = () => window.location.reload(),
  }: ErrorContentsReloadType) => (
    <Box display="flex" alignItems="center" pr={4}>
      <Box ml={1} display="flex" alignItems="center">
        <WarningTwoIcon color="yellow.600" />
        <Box ml={2}>{msg}</Box>
      </Box>
      <Tooltip label="リロード" fontSize="xs" bg="gray.600">
        <RepeatIcon cursor="pointer" marginLeft={1} onClick={onClick} />
      </Tooltip>
    </Box>
  ),
);
