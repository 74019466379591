import { ResponseError } from 'api/types';
import { FieldError, FieldErrors } from 'react-hook-form';

/**
 * フォームのエラーオブジェクトより
 * 対象要素のエラーメッセージをリスト化
 *
 * ## 単品エラー
 * errors = {
 *   title: {
 *     message: '入力してください',
 *     ref: '...',
 *   },
 *   ...
 * }
 *
 * toErrMsgList(errors, 'title') => ['入力してください']
 *
 * ## 複数エラー
 * errors = {
 *   title: {
 *     types: {
 *       'required': '入力してください',
 *       'max': '3文字以上で入力してください',
 *     },
 *     ref: '...',
 *   },
 *   ...
 * }
 *
 * toErrMsgList(errors, 'title') => [
 *   '入力してください',
 *   '3文字以上で入力してください',
 * ]
 *
 * @param errors: FieldErrors エラーオブジェクト
 * @param name: string 抽出する対象要素名
 * @returns string[]
 */
export const toErrMsgList = (errors: FieldErrors, name: string): string[] => {
  let errMsgList: string[] = [];

  if (!errors || !errors?.[name]) {
    return errMsgList;
  }

  const fieldError = errors[name] as FieldError;

  if ('message' in fieldError && fieldError.message) {
    // 単品エラー
    errMsgList = [fieldError.message];
  } else if ('types' in fieldError && fieldError.types) {
    // 複数エラー
    errMsgList = Object.keys(fieldError.types).map((k) =>
      fieldError.types?.[k] ? fieldError.types[k] : '',
    ) as string[];
  }

  return errMsgList;
};

type GetValidationError<T> = {
  formData: T;
  response: ResponseError;
  replaceKeys?: Record<string, keyof T & string | string>;
};

export type ValidationError<T> = {
  [k in keyof T]?: string[];
} & {
  others?: string[];
};

/**
 * バックエンドのバリデーションエラーレスポンスを
 * フォームの要素別エラーメッセージリストに整形
 *
 * type FormType = {
 *   name: string,
 *   title: string,
 * }
 *
 * const errMsgList = getValidationError<FormType>({
 *   formData: {
 *     name: 'アイウエオ',
 *     title: 'サンプル',
 *   },
 *   response: {
 *     name: [
 *       '半角英数字で入力してください',
 *       '3文字以下で入力してください',
 *     ],
 *     title: 'その名前はすでに登録されています',
 *     // form 要素に存在しないエラー1
 *     nameAbs: [
 *       'その名前は登録できません',
 *     ],
 *     // form 要素に存在しないエラー2
 *     nonVariable: [
 *       '登録できません',
 *     ]
 *   }
 * })
 *
 * --- errMsgList ---
 * nameAbs と nonVariable は formの要素に存在しないエラー
 * formの要素に存在しない場合 戻り値のキーは others に振り分けられます
 *
 * {
 *   name: [
 *     '半角英数字で入力してください',
 *     '3文字以下で入力してください',
 *   ],
 *   title: [
 *     'その名前はすでに登録されています'
 *   ],
 *   others: [
 *     'その名前は登録できません',
 *     '登録できません',
 *   ]
 * }
 *
 * nameAbs のエラーを name に振り分ける場合、引数の
 * replaceKeys に変換先のキーを登録します
 *
 * const errMsgList = getValidationError<FormType>({
 *   formData: {
 *     name: 'アイウエオ',
 *     title: 'サンプル',
 *   },
 *   response: {
 *     name: [
 *       '半角英数字で入力してください',
 *       '3文字以下で入力してください',
 *     ],
 *     title: 'その名前はすでに登録されています',
 *     // form 要素に存在しないエラー1
 *     nameAbs: [
 *       'その名前は登録できません',
 *     ],
 *     // form 要素に存在しないエラー2
 *     nonVariable: [
 *       '登録できません',
 *     ]
 *   },
 *   replaceKeys: {
 *     nameAbs: 'name',
 *   }
 * })
 *
 * --- errMsgList ---
 * nameAbs は name に振り分けられ
 * nonVariable は others に振り分けられます
 *
 * {
 *   name: [
 *     '半角英数字で入力してください',
 *     '3文字以下で入力してください',
 *     'その名前は登録できません',
 *   ],
 *   title: [
 *     'その名前はすでに登録されています'
 *   ],
 *   others: [
 *     '登録できません',
 *   ]
 * }
 *
 * @returns ValidationError<T = Formの型>
 */
export const getValidationError = <T>({
  formData,
  response,
  replaceKeys = {},
}: GetValidationError<T>): ValidationError<T> => {
  type LocalValidationError = ValidationError<T>;

  return Object.keys(response).reduce((acc, responseKey) => {
    let key = responseKey;
    const val = response[key];

    if (responseKey in replaceKeys) {
      key = replaceKeys[responseKey];
    } else if (!(responseKey in formData)) {
      key = 'others';
    }

    let msgList: string[] = [];

    if (typeof val === 'string') {
      msgList = [val];
    } else if (Array.isArray(val)) {
      msgList = val;
    }

    if (key in acc) {
      const accMsgList = acc[key as keyof LocalValidationError] as string[];
      msgList = [...accMsgList, ...msgList];
    }

    return { ...acc, [key]: msgList };
  }, {} as LocalValidationError);
};

/**
 * getValidationError関数の戻り値に対する型ガード
 *
 * @param data: unknown 検査対象
 * @param params: string[] Tのキーリスト
 * @returns boolean
 */
export const isGetValidationError = <T>(
  data: unknown,
  params: string[],
): data is ValidationError<T> => {
  const d = data as ValidationError<T>;

  return Object.keys(d).every((k) => {
    if (k !== 'others' && !params.includes(k)) {
      return false;
    }

    const key = k as keyof T;
    const val = key in d ? d[key] : undefined;

    return Array.isArray(val) && val.every((v) => typeof v === 'string');
  });
};

/**
 * getValidationError関数の戻り値に対する型ガード
 *
 * @param data: unknown 検査対象
 * @param params: string[] Tのキーリスト
 * @returns boolean
 */
export const isGetNestedValidationError = <T>(
  data: unknown,
  params: string[],
): data is ValidationError<T> => {
  const d = data as ValidationError<T>;

  return Object.keys(d).every((k) => {
    if (k !== 'others' && !params.includes(k)) {
      return false;
    }

    const key = k as keyof T;
    const val = key in d ? d[key] : undefined;

    return (
      Array.isArray(val) && (val.every((v) => typeof v === 'string') || true)
    );
  });
};

/**
 *
 * 配列エラーメッセージをオブジェクト形式に変換
 *
 * const errors = toMultiError(['aaa', 'bbb'])
 *
 * --- errors ---
 * {
 *   'validate-0': 'aaa',
 *   'validate-1': 'bbb',
 * }
 *
 * この関数を利用することで 配列形式のエラーメッセージを
 * setError の types プロパティにそのまま設定することが可能です
 *
 * setError('フォーム要素名', { types: errors, });
 *
 * @param msgList: string[] エラーメッセージリスト
 * @returns エラーメッセージオブジェクト
 */
export const toMultiError = (msgList: string[]): { [k: string]: string } =>
  msgList
    .map((msg, idx) => ({ [`validate-${String(idx)}`]: msg }))
    .reduce((acc, cur) => Object.assign(acc, cur), {});
