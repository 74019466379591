export const messages = {
  // フォーム一覧取得
  getEnquete: {
    customer: {
      notFound: 'フォームが存在しません。既に削除された可能性があります。',
      error:
        '申し訳ございません。フォーム一覧情報取得できません。時間を置いて再表示をお願いします。',
      failed:
        '申し訳ありません。正しいフォーム一覧情報を得ることができません。',
    },
    system: {
      typeError:
        '[api.enquete.getEnquete] フォーム一覧取得APIのレスポンス型が予期せぬデータ型です。フロントの修正をしてください',
    },
  },
  // フォーム詳細取得
  getEnqueteId: {
    customer: {
      notFound: 'フォームが存在しません。既に削除された可能性があります。',
      error:
        '申し訳ございません。フォーム詳細情報取得できません。時間を置いて再表示をお願いします。',
      failed:
        '申し訳ありません。正しいフォーム詳細情報を得ることができません。',
    },
    system: {
      typeError:
        '[api.enquete.getEnqueteId] フォーム詳細取得APIのレスポンス型が予期せぬデータ型です。フロントの修正をしてください',
    },
  },
  // フォーム詳細設定情報取得
  getEnqueteIdSetting: {
    customer: {
      error:
        '申し訳ございません。フォーム詳細設定情報取得できません。時間を置いて再表示をお願いします。',
      failed:
        '申し訳ありません。正しいフォーム詳細設定情報を得ることができません。',
    },
    system: {
      typeError:
        '[api.enquete.getEnqueteIdSetting] フォーム詳細設定情報取得APIのレスポンス型が予期せぬデータ型です。フロントの修正をしてください',
    },
  },
  // フォームテンプレートを登録
  postEnquete: {
    customer: {
      error: '申し訳ございません。フォームテンプレートの保存に失敗しました。',
      failed:
        'フォームテンプレートの保存に失敗しました。恐れ入りますが時間をおいて再度登録してください。',
    },
    system: {
      typeError: '[api.enquete.postEnquete] 予期せぬエラーが発生しました。',
    },
  },
  // フォームサンクス設定情報を修正
  postEnqueteEmailSetting: {
    customer: {
      error: '申し訳ございません。テストメールの送信に失敗しました。',
      failed:
        'テストメールの送信に失敗しました。恐れ入りますが時間をおいて再度登録してください。',
    },
    system: {
      typeError:
        '[api.enquete.putEnqueteSetting] 予期せぬエラーが発生しました。',
    },
  },
  // フォームテンプレートを修正
  putEnquete: {
    customer: {
      error: '申し訳ございません。フォームテンプレートの保存に失敗しました。',
      failed:
        'フォームテンプレートの保存に失敗しました。恐れ入りますが時間をおいて再度登録してください。',
    },
    system: {
      typeError: '[api.enquete.putEnquete] 予期せぬエラーが発生しました。',
    },
  },
  // フォーム設定情報を修正
  putEnqueteSetting: {
    customer: {
      error: '申し訳ございません。フォーム設定情報の保存に失敗しました。',
      failed:
        'フォーム設定情報の保存に失敗しました。恐れ入りますが時間をおいて再度登録してください。',
    },
    system: {
      typeError:
        '[api.enquete.putEnqueteSetting] 予期せぬエラーが発生しました。',
    },
  },
  // フォーム公開設定情報を修正
  putEnquetePublish: {
    customer: {
      error: '申し訳ございません。フォーム公開設定情報の保存に失敗しました。',
      failed:
        'フォーム公開設定情報の保存に失敗しました。恐れ入りますが時間をおいて再度登録してください。',
    },
    system: {
      typeError:
        '[api.enquete.putEnquetePublish] 予期せぬエラーが発生しました。',
    },
  },
  // フォーム情報コピー
  getEnqueteCopy: {
    customer: {
      error:
        '申し訳ございません。フォーム情報をコピーできません。時間を置いて再度コピーをお願いします。',
      failed:
        'フォーム情報コピーに失敗しました。恐れ入りますが、時間をおいて再度コピーをしてください。',
    },
    system: {
      typeError:
        '[api.enquete.getEnqueteCopy] フォーム情報コピーAPIのレスポンス型が予期せぬデータ型です。フロントの修正をしてください',
    },
  },
  // フォームプレビュー表示用データ登録
  postEnquetePreview: {
    customer: {
      error:
        '申し訳ございません。フォームプレビュー表示用データの保存に失敗しました。',
      failed:
        'フォームプレビュー表示用データの保存に失敗しました。恐れ入りますが時間をおいて再度登録してください。',
    },
    system: {
      typeError:
        '[api.enquete.postEnquetePreview] 予期せぬエラーが発生しました。',
    },
  },
  // フォームグラフ取得
  getEnqueteChart: {
    customer: {
      error:
        '申し訳ございません。フォームグラフを取得できません。時間を置いて再表示をお願いします。',
      failed: '申し訳ありません。正しいフォームグラフを得ることができません。',
    },
    system: {
      typeError:
        '[api.enquete.getEnqueteChart] フォームグラフ取得APIのレスポンス型が予期せぬデータ型です。フロントの修正をしてください',
    },
  },
  // フォームグラフ フリーテキストリスト取得
  getEnqueteTextList: {
    customer: {
      error:
        '申し訳ございません。フリーテキストリストを取得できません。時間を置いて再表示をお願いします。',
      failed:
        '申し訳ありません。正しいフリーテキストリストを得ることができません。',
    },
    system: {
      typeError:
        '[api.enquete.getEnqueteTextList] フリーテキストリスト取得APIのレスポンス型が予期せぬデータ型です。フロントの修正をしてください',
    },
  },
  // ファイルアップロード取得
  getEnqueteFile: {
    customer: {
      notFound:
        '申し訳ございません。ファイルのダウンロードができません。時間を置いて再度お試しください。',
      error:
        '申し訳ございません。ファイルのダウンロードができません。時間を置いて再度お試しください。',
      failed:
        '申し訳ありません。ファイルのダウンロードができません。時間を置いて再度お試しください。',
    },
    system: {
      typeError:
        '[api.enquete.getEnquete] フォームファイルダウンロードAPIのレスポンス型が予期せぬデータ型です。フロントの修正をしてください',
    },
  },
  // デザインスキン一覧取得
  getSkinList: {
    customer: {
      notFound:
        'デザインスキンが存在しません。既に削除された可能性があります。',
      error:
        '申し訳ございません。デザインスキン一覧情報取得できません。時間を置いて再表示をお願いします。',
      failed:
        '申し訳ありません。正しいデザインスキン一覧情報を得ることができません。',
    },
    system: {
      typeError:
        '[api.enquete.getSkinList] デザインスキン一覧取得APIのレスポンス型が予期せぬデータ型です。フロントの修正をしてください',
    },
  },
  // デザインスキン詳細取得
  getEnqueteSkinId: {
    customer: {
      notFound:
        'デザインスキンが存在しません。既に削除された可能性があります。',
      error:
        '申し訳ございません。デザインスキン詳細情報取得できません。時間を置いて再表示をお願いします。',
      failed:
        '申し訳ありません。正しいデザインスキン詳細情報を得ることができません。',
    },
    system: {
      typeError:
        '[api.enquete.getEnqueteSkinId] デザインスキン詳細取得APIのレスポンス型が予期せぬデータ型です。フロントの修正をしてください',
    },
  },
  // デザインスキンを登録
  postEnqueteSkin: {
    customer: {
      error: '申し訳ございません。デザインスキンの保存に失敗しました。',
      failed:
        'デザインスキンの保存に失敗しました。恐れ入りますが時間をおいて再度登録してください。',
    },
    system: {
      typeError: '[api.enquete.postEnqueteSkin] 予期せぬエラーが発生しました。',
    },
  },
  // デザインスキンを修正
  putEnqueteSkin: {
    customer: {
      error: '申し訳ございません。デザインスキンの保存に失敗しました。',
      failed:
        'デザインスキンの保存に失敗しました。恐れ入りますが時間をおいて再度登録してください。',
    },
    system: {
      typeError: '[api.enquete.putEnqueteSkin] 予期せぬエラーが発生しました。',
    },
  },
  // デザインスキンコピー
  getEnqueteSkinCopy: {
    customer: {
      error:
        '申し訳ございません。デザインスキンをコピーできません。時間を置いて再度コピーをお願いします。',
      failed:
        'デザインスキンのコピーに失敗しました。恐れ入りますが、時間をおいて再度コピーをしてください。',
    },
    system: {
      typeError:
        '[api.enquete.デザインスキンコピー] デザインスキンコピーAPIのレスポンス型が予期せぬデータ型です。フロントの修正をしてください',
    },
  },
  postEnqueteFileDownload: {
    customer: {
      notFound:
        '申し訳ございません。ファイルのダウンロードができません。時間を置いて再度お試しください。',
      error:
        '申し訳ございません。ファイルのダウンロードができません。時間を置いて再度お試しください。',
      failed:
        '申し訳ありません。ファイルのダウンロードができません。時間を置いて再度お試しください。',
    },
    system: {
      typeError:
        '[api.enquete.postEnqueteFileDownload] フォームファイルダウンロードAPIのレスポンス型が予期せぬデータ型です。フロントの修正をしてください',
    },
  },
};
