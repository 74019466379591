import { VFC, useState, useEffect, useCallback } from 'react';
import { useFormContext } from 'react-hook-form';
import { Stack, Link } from '@chakra-ui/react';
import { RegistInviteUserForm, isGroups } from 'api/user/types';
import { useRegistUserInvitation } from 'hooks/user/useRegistUserInvitation';
import { useUserInfo } from 'hooks/useUserInfo';
import { useCustomToast } from 'hooks/useCustomToast';
import { InputForm } from 'components/common/molecules';
import { SelectForm } from 'components/setting/users/atoms/SelectForm';
import { MultiInputForm } from 'components/setting/users/atoms/MultiInputForm';

/**
 * UsersFormProps
 */
type UsersFormProps = {
  formId: string;
  errorMsg?: string;
  startSending: () => void;
  onSuccess: (isError: boolean) => void;
};

/**
 * 表示名入力用バリデーション
 */
const nameValidateAttr = {
  required: 'メールアドレスを入力してください',
};
/**
 * Select入力必須バリデーションメッセージ
 */
const selectRequiredMsg = '[select]を入力してください';

export const UsersForm: VFC<UsersFormProps> = ({
  formId,
  errorMsg = '',
  startSending,
  onSuccess,
}) => {
  const [globalErrors, setGlobalErrors] = useState<string[]>([]);
  const [isError, setIsError] = useState(false);
  const { handleSubmit, setError } = useFormContext<RegistInviteUserForm>();
  const toast = useCustomToast();
  const { permissionList } = useUserInfo();

  /**
   * Select用バリデーション permission
   */
  const selectValidateAttr1 = {
    required: selectRequiredMsg,
  };

  // ユーザー招待登録処理
  const { onSubmit, isSuccess } = useRegistUserInvitation({
    setError,
    setGlobalErrors,
  });

  // エラーメッセージ用トースト
  const errorToast = useCallback((error: string) => {
    toast({
      status: 'error',
      position: 'bottom',
      duration: 2000,
      isClosable: true,
      title: error,
    });
    // TODO:
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  // ユーザー招待処理
  let sendData: RegistInviteUserForm;
  const formSubmit = (data: RegistInviteUserForm) => {
    const { groups } = data;

    if (isGroups(groups)) {
      const groupIds = groups.map((x) => x.id);
      sendData = {
        ...data,
        // パーミッションのデータ型をnumberにキャストする
        permission: Number(data.permission),
        groups: groupIds,
      };
    }
    // groupsが選択されていない場合,配列に空文字が入ってしまうため
    // 空文字を除外する
    if (sendData.groups.length === 1 && sendData.groups[0] === '') {
      const tmp: string[] = sendData.groups as string[];
      sendData.groups = tmp.filter(Boolean);
    }

    startSending();
    onSubmit(sendData);
  };

  useEffect(() => {
    setGlobalErrors([errorMsg]);
  }, [errorMsg]);

  useEffect(() => {
    if (isSuccess) onSuccess(isError);
  }, [isError, isSuccess, onSuccess]);

  useEffect(() => {
    const error = globalErrors.length > 0 ? globalErrors[0] : '';
    if (error !== '') errorToast(error);
    setIsError(error !== '');
  }, [globalErrors, errorToast]);

  return (
    <>
      <form
        id={formId}
        onSubmit={handleSubmit((data) => formSubmit(data))}
        data-testid="setting-user-drawer"
      >
        <Stack
          spacing={8}
          pt={4}
          flexFlow="column"
          justifyContent="space-between"
          height="calc(100vh - 144px)"
          backgroundColor="whiteAlpha.900"
        >
          <Stack spacing={8}>
            {/* 表示名 */}
            <InputForm
              name="emailTo"
              type="email"
              label="メールアドレス"
              attr={nameValidateAttr}
            />

            {/* ビジネスユニット権限 */}
            <SelectForm
              name="permission"
              label="権限"
              list={permissionList}
              attr={selectValidateAttr1}
            />
            <Link
              href="https://uniikey.notion.site/4f54766cbaa54947976385efbc701066"
              mt="4px !important"
              pl={4}
              color="blue"
              target="_blank"
            >
              ※権限の種別について
            </Link>

            {/* グループ */}
            <MultiInputForm />
          </Stack>
        </Stack>
      </form>
    </>
  );
};
