import {
  headTitleOptions,
  screenNameOptions,
  screenDescriptionOptions,
  buttonNameOptions,
  textareaElementOptions,
} from './options';

const groupName = 'withdrawal';

// 退会画面
export default [
  // ロゴ	画像
  {
    name: `${groupName}.headTitle`,
    label: 'ブラウザタブ表示名',
    tooltip: '',
    type: 'Text',
    defaultValue: '退会',
    options: headTitleOptions,
    elementOptions: {},
  },
  {
    name: `${groupName}.screenName`,
    label: '画面名',
    tooltip: '',
    type: 'Text',
    defaultValue: '退会',
    options: screenNameOptions,
    elementOptions: {},
  },
  {
    name: `${groupName}.screenDescription`,
    label: '案内文 画面',
    tooltip: '',
    type: 'Textarea',
    defaultValue:
      '退会を希望される方は、以下のボタンから退会確認画面に進んでください。',
    options: screenDescriptionOptions,
    elementOptions: textareaElementOptions,
  },
  {
    name: `${groupName}.continueButtonName`,
    label: 'ボタン名 退会確認画面に遷移',
    tooltip: '',
    type: 'Text',
    defaultValue: '退会確認画面へ',
    options: buttonNameOptions,
    elementOptions: {},
  },
  {
    name: `${groupName}.backButtonName`,
    label: 'ボタン名 マイページTOPに遷移',
    tooltip: '',
    type: 'Text',
    defaultValue: 'マイページTOPへ',
    options: buttonNameOptions,
    elementOptions: {},
  },
] as const;
