import { CustomError } from 'admin/error/CustomError';
import { logger } from 'api/logger';
import { getMailSetting } from 'api/transactionMail/getMailSetting';
import { useCustomToast } from 'hooks/useCustomToast';
import { useUserTenantId } from 'hooks/user/useUserTenantId';
import { useQuery } from 'react-query';
import { errorToast } from 'utils/toast';
import { queryKeys } from './queryKeys';

export const useMailSetting = () => {
  const toast = useCustomToast();
  const tenantId = useUserTenantId();
  const { data, isLoading } = useQuery({
    queryKey: queryKeys.getMailSetting({ tenantId }),
    queryFn: () => getMailSetting(tenantId),
    onError: async (error) => {
      if (error instanceof CustomError) {
        await logger({
          loglevel: error.logLevel,
          data: JSON.stringify(error.cause),
          message: error.message,
        });
      }
      toast({
        ...errorToast,
        duration: 4000,
        title: '初回ログイン時メール設定情報の取得に失敗しました',
        position: 'bottom',
        variant: 'solid',
      });
    },
  });

  return { data, isLoading };
};
