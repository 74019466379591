/* eslint-disable react/require-default-props */
import { Card, CardHeader } from 'components/paywall/features/Card';
import { PaywallCardHeader } from 'components/paywall/pages/WallItemList/PaywallCardList/PaywallCardHeader';
import { PaywallCardItems } from 'components/paywall/pages/WallItemList/PaywallCardList/PaywallCardItems';
import { WallItem } from 'components/paywall/pages/WallItemList/typed';
import { FC, memo } from 'react';


type Props = {
  wallItem: WallItem;
}
export const PaywallCard: FC<Props> = memo(({wallItem}: Props) => (
  <Card>
    <CardHeader padding="16px">
      <PaywallCardHeader wallItem={wallItem} />
    </CardHeader>
    <PaywallCardItems wallItem={wallItem} />
  </Card>
));
