import { Box, Text, useDisclosure } from '@chakra-ui/react';
import { DoubleConfirmDialog } from 'components/common/molecules';
import { Button } from 'components/paywall/atoms/Button';
import { usePaywallItem } from 'components/paywall/hooks/usePaywallItem';
import { usePaywallUtil } from 'components/paywall/hooks/usePaywallUtil';
import { Drawer } from 'components/paywall/molecules/Drawer';
import { WallItemDefaultValues } from 'components/paywall/pages/WallItemList/initial';
import {
  WallItem,
  WallItemFormType
} from 'components/paywall/pages/WallItemList/typed';
import { FC, memo, useCallback, useState } from 'react';
import { FormProvider, useForm } from 'react-hook-form';
import { Form } from './Form';

type DartyType = {
  [key in "price" | "sellType" | "period"]: boolean;
}

type Props = {
  isAdd?: boolean;
  buttonMode?: 'simple' | 'gray' | 'text';
  buttonName: string;
  obj?: WallItem;
};

/**
 * @see 単品商品の新規作成＆編集ダイアログ
 * @param isAdd 新規作成か編集かの判定
 * @param buttonMode ボタンに渡すモード
 * @param buttonName ボタンの名前
 * @param Promotion 単品商品のオブジェクト
 */
export const ItemDrawer: FC<Props> = memo(
  ({ isAdd, buttonMode, buttonName, obj }) => {
    const { isOpen: isDialogOpen, onClose: onDialogClose, onOpen: onDialogOpen } = useDisclosure();
    const [isLoading, setLoading] = useState<boolean>(false);
    const [isDarty, setIsDarty] = useState<DartyType>({
      price: false,
      sellType: false,
      period: false
    });
    const { isOperationAuth } = usePaywallUtil();
    const methods = useForm<WallItemFormType>({
      mode: 'onBlur',
      defaultValues: WallItemDefaultValues,
    });
    const {
      getValues,
      reset,
      setError,
      formState: { errors },
    } = methods;
    const { isOpen, onOpen, onClose } = useDisclosure();
    const { saveWallItem, onDrawerClose } = usePaywallItem({
      getValues,
      isAdd,
      setError,
      onClose,
      reset,
      setLoading,
      setIsDarty,
    });

    // フォーム送信する処理
    const formSubmit = useCallback(
      () => {
        if(isAdd || (!isDarty.price && !isDarty.sellType && !isDarty.period)) {
          saveWallItem();

          return;
        }

        onDialogOpen();
      },
      [isAdd, isDarty, saveWallItem, onDialogOpen],
    );

    const submitBtnDialogHandler = useCallback(() => {
      saveWallItem();
      onDialogClose();
    }, [saveWallItem, onDialogClose]);

    // 二重チェック用ダイアログに表示する警告メッセージ作成
    const getWarningText = (): string => {
      let warningText = '';
      if(isDarty.price) warningText += '「価格」';
      if(isDarty.sellType) {
        if(warningText !== '') warningText += '、';
        warningText += '「購入後の閲覧期間制限」';
      }
      if(isDarty.period) {
        if(warningText !== '') warningText += '、';
        warningText += '「閲覧期間の日数」';
      }

      return `${warningText}が変更されます。`;
    }

    return (
      <FormProvider {...methods}>
        {buttonMode !== 'text' && (
          <Button
            mode={buttonMode}
            onClick={onOpen}
            disabled={!isOperationAuth()}
          >
            {buttonName}
          </Button>
        )}
        {buttonMode === 'text' && (
          <Text
            as="h3"
            fontSize="18px"
            fontWeight="700"
            lineHeight="140%"
            cursor="pointer"
            textDecoration="underline"
            onClick={onOpen}
          >
            {buttonName}
          </Text>
        )}
        <Drawer<WallItemFormType>
          type='id'
          headerLabel="単品商品ID"
          title={isAdd ? '単品商品新規作成' : '単品商品編集'}
          isOpen={isOpen}
          disabled={Object.keys(errors).length > 0}
          onClose={onDrawerClose}
          onSubmit={formSubmit}
          isLoading={isLoading}
          size="lg"
          isAdd={isAdd}
          drawerType="item"
        >
          <Form isAdd={isAdd} obj={obj} isDarty={isDarty} setIsDarty={setIsDarty} />
        </Drawer>
        <DoubleConfirmDialog
          title="変更についての確認"
          submitBtnTitle="実行"
          cancelBtnTitle="キャンセル"
          descriptionPrefix="実行"
          warningTexts={[
            getWarningText(),
            '保存後、即時に反映されます。',
            '設定変更後に新規で購入する場合が対象になります。既に購入済みのユーザーの価格や適用回数には反映されません。購入時点の内容となります。',
          ]}
          isLoading={isLoading}
          isOpen={isDialogOpen}
          onClose={onDialogClose}
          submitBtnHandler={submitBtnDialogHandler}
        >
          <Box mt="16px">
            <Text>
              <Text as="span" fontWeight="bold" mr="4px">{getValues('id')}</Text>
              の変更を実行しますか？
            </Text>
          </Box>
        </DoubleConfirmDialog>
      </FormProvider>
    );
  },
);
