import { VFC, memo, useCallback, useEffect } from 'react';
import { useQueryClient } from 'react-query';
import { Text, Icon, useDisclosure } from '@chakra-ui/react';
import { MdClear } from 'react-icons/md';
import { Dialog } from 'components/common/atoms';
import { GroupUser } from 'api/tenant/types';
import { useDeleteGroupUser } from 'hooks/user/useDeleteGroupUser';
import { queryUserKey } from 'hooks/user/queryUserKey';

type DeleteGroupUserDialogProps = {
  user: GroupUser;
};

export const DeleteGroupUserDialog: VFC<DeleteGroupUserDialogProps> = memo(
  ({ user }: DeleteGroupUserDialogProps) => {
    const queryClient = useQueryClient();
    const { isOpen, onOpen, onClose } = useDisclosure();
    const { onDelete, isLoading, isSuccess } = useDeleteGroupUser();

    // 削除処理
    const submitBtnHandler = useCallback(() => {
      onDelete({ groupId: user.group, userId: user.user });
    }, [onDelete, user.group, user.user]);

    // 削除キャンセル
    const cancelBtnHandler = useCallback(() => {
      onClose();
    }, [onClose]);

    useEffect(() => {
      if (isSuccess) {
        // ポップアップ非表示
        onClose();
        void queryClient.invalidateQueries(
          queryUserKey.groupUserList({ groupId: user.group }),
        );
      }
    }, [isSuccess, onClose, queryClient, user.group]);

    return (
      <Dialog
        title="ユーザー削除"
        isOpen={isOpen}
        onOpen={onOpen}
        onClose={onClose}
        openBtnChildNode={
          <>
            <Icon as={MdClear} fontSize={16} />
          </>
        }
        openBtnProps={{
          variant: 'ghost',
          color: 'gray.500',
          size: 'xs',
        }}
        cancelBtnTitle="キャンセル"
        cancelBtnHandelr={cancelBtnHandler}
        cancelBtnProps={{ variant: 'secondary', isLoading: isLoading === true }}
        submitBtnTitle="削除"
        submitBtnHandelr={submitBtnHandler}
        submitBtnProps={{
          colorScheme: 'red',
          isLoading: isLoading === true,
        }}
      >
        <Text as="p" mb={2}>
          グループから削除します。よろしいですか？
        </Text>
      </Dialog>
    );
  },
);
