import {
  Box, Button, Divider, IconButton, List,
  ListItem, Modal, ModalBody,
  ModalCloseButton, ModalContent, ModalFooter, ModalHeader, Popover, PopoverArrow, PopoverBody, PopoverCloseButton, PopoverContent, PopoverFooter, PopoverHeader, PopoverTrigger, Text, useDisclosure
} from '@chakra-ui/react';
import { Notice } from 'api/notice/types';
import { NotificationIcon } from 'components/common/atoms';
import { format } from 'date-fns';
import { useNotice } from 'hooks/notice/useNotice';
import { useUpdateNotice } from 'hooks/notice/useUpdateNotice';
import { useUserTenantId } from 'hooks/user/useUserTenantId';
import { FC, memo, useCallback, useEffect, useState } from 'react';
import { BsChevronLeft, BsChevronRight } from 'react-icons/bs';

const DATETIME_FORMAT = 'yyyy-MM-dd HH:mm';

type NoticeDetail = {
  id: string;
  title: string;
  message: string;
  createdAt: string;
};

export const NotificationContents: FC = memo(() => {
  const { isOpen, onOpen, onClose } = useDisclosure();
  const [unreadCount, setUnreadCount] = useState(0);
  const [currentPage, setCurrentPage] = useState(1);
  const [noticeDetailData, setNoticeDetailData] = useState<NoticeDetail>({
    id: '',
    title: '',
    message: '',
    createdAt: '',
  });
  const notice = useNotice(currentPage);
  const tenantId = useUserTenantId();
  const { onSubmit } = useUpdateNotice();

  useEffect(() => {
    setUnreadCount(notice.unreadCount || 0);
    setCurrentPage(notice.currentPage);
  }, [notice, notice.unreadCount]);

  const onChangePage = useCallback(
    (page: number) => {
      if (
        !(page < 0 && currentPage === 1) &&
        !(page > 0 && currentPage === notice.pageCount)
      ) {
        setCurrentPage(currentPage + page);
      }
    },
    [currentPage, notice.pageCount],
  );

  const onClickNoticeMessage = useCallback(
    (item: Notice) => {
      if (!item.isReaded) {
        onSubmit({
          id: item.id,
          tenantId,
          isReaded: true,
          page: currentPage,
        });
      }
      const messageText = item.message;
      const attributesData = item.attributes;
      let replaceMessage = messageText;
      if (attributesData) {
        attributesData.forEach((data, index) => {
          const result =
            data.dataType === 'link'
              ? replaceMessage.replace(
                  `@@@attr_${index}@@@`,
                  `<a href="${data.url}" target="_blank" style="color: #003c7e; text-decoration: underline;">${data.title}</a>`,
                )
              : replaceMessage.replace(
                  `@@@attr_${index}@@@`,
                  `<image src="${data.url}" alt="${data.title}" style="margin: 5px 0;" />`,
                );
          replaceMessage = result;
        });
      }
      setNoticeDetailData({
        id: item.id,
        title: item.title,
        message: replaceMessage,
        createdAt: item.createdAt,
      });
      onOpen();
    },
    [onSubmit, onOpen, tenantId, currentPage],
  );

  return (
    <>
      <Popover closeOnBlur={false}>
        <PopoverTrigger>
          <Button
            variant="ghost"
            p={0}
            _focus={{ outline: 'none' }}
            _hover={{ bg: 'transparent' }}
            _active={{ bg: 'transparent' }}
          >
            <NotificationIcon unreadCount={unreadCount} />
          </Button>
        </PopoverTrigger>
        <PopoverContent boxShadow="2xl" _focus={{ outline: 'none' }}>
          <PopoverArrow bg="messenger.800" />
          <PopoverCloseButton color="white" />
          <PopoverHeader bg="messenger.800" color="white">メッセージボックス</PopoverHeader>
          <PopoverBody color="black" p={0}>
            <List
              mx={2}
              maxH={300}
              overflow="auto"
              css={{
                '&::-webkit-scrollbar': {
                  display: 'none',
                },
              }}
            >
              {notice.results.map((item, idx) => (
                <ListItem
                  key={`notice-list-data-${String(idx)}`}
                  my={1}
                  cursor="pointer"
                  bg="gray.50"
                  borderRadius={4}
                  _hover={{ bg: 'teal.100' }}
                  onClick={() => onClickNoticeMessage(item)}
                >
                  <Box lineHeight={6} p={2}>
                    <Text
                      as="h5"
                      fontWeight={item.isReaded ? 'normal' : 'bold'}
                    >
                      ・{item.title}
                    </Text>
                    <Text
                      fontSize="10px"
                      lineHeight={4}
                      textAlign="right"
                      color="gray.500"
                    >
                      {item.createdAt
                        ? format(new Date(item.createdAt), DATETIME_FORMAT)
                        : ''}
                    </Text>
                  </Box>
                </ListItem>
              ))}
            </List>
          </PopoverBody>
          <PopoverFooter color="black">
            <Box
              display="flex"
              justifyContent="space-between"
              alignItems="center"
            >
              <IconButton
                aria-label="NoticePrevBtn"
                icon={<BsChevronLeft />}
                fontSize={16}
                padding="6px"
                onClick={() => onChangePage(-1)}
                isDisabled={currentPage === 1}
              />
              <Text>
                {currentPage} / {notice.pageCount}
              </Text>
              <IconButton
                aria-label="NoticeNextBtn"
                icon={<BsChevronRight />}
                fontSize={16}
                padding="6px"
                onClick={() => onChangePage(1)}
                isDisabled={notice.pageCount <= currentPage}
              />
            </Box>
          </PopoverFooter>
        </PopoverContent>
      </Popover>
      <Modal
        isOpen={isOpen}
        onClose={onClose}
        isCentered
        scrollBehavior="inside"
        closeOnOverlayClick={false}
      >
        <ModalContent boxShadow="dark-lg">
          <ModalHeader pb={2}>
            <Box mr="1rem">
              <Text fontSize="10px" lineHeight={4}>
                {noticeDetailData.createdAt
                  ? format(
                      new Date(noticeDetailData.createdAt),
                      DATETIME_FORMAT,
                    )
                  : ''}
              </Text>
              <Text fontSize="xl" fontWeight="normal">
                {noticeDetailData.title}
              </Text>
            </Box>
          </ModalHeader>
          <Divider w="90%" margin="0 auto" />
          <ModalBody
            whiteSpace="pre-wrap"
            dangerouslySetInnerHTML={{ __html: noticeDetailData.message }}
          />
          <ModalFooter>
            <Button
              margin="0 auto"
              onClick={onClose}
              bgColor="white"
              borderWidth="1px"
              borderColor="gray.400"
            >
              閉じる
            </Button>
          </ModalFooter>
          <ModalCloseButton _focus={{ outline: 'none' }} />
        </ModalContent>
      </Modal>
    </>
  );
});
