import { maxLength, urlOptions } from './options';

const groupName = 'footer';

export default [
  {
    name: `${groupName}.termsLink1`,
    label: 'URL フッターリンク1',
    tooltip:
      'リンクをエンドユーザー画面に表示する場合、URLとリンクテキストを設定してください。',
    type: 'Text',
    defaultValue: '',
    options: urlOptions,
    elementOptions: {},
  },
  {
    name: `${groupName}.termsName1`,
    label: 'リンクテキスト フッターリンク1',
    tooltip: '',
    type: 'Text',
    defaultValue: '',
    options: {
      maxLength: maxLength(50),
    },
    elementOptions: {},
  },
  {
    name: `${groupName}.termsLink2`,
    label: 'URL フッターリンク2',
    tooltip: '',
    type: 'Text',
    defaultValue: '',
    options: urlOptions,
    elementOptions: {},
  },
  {
    name: `${groupName}.termsName2`,
    label: 'リンクテキスト フッターリンク2',
    tooltip: '',
    type: 'Text',
    defaultValue: '',
    options: {
      maxLength: maxLength(50),
    },
    elementOptions: {},
  },
  {
    name: `${groupName}.termsLink3`,
    label: 'URL フッターリンク3',
    tooltip: '',
    type: 'Text',
    defaultValue: '',
    options: urlOptions,
    elementOptions: {},
  },
  {
    name: `${groupName}.termsName3`,
    label: 'リンクテキスト フッターリンク3',
    tooltip: '',
    type: 'Text',
    defaultValue: '',
    options: {
      maxLength: maxLength(50),
    },
    elementOptions: {},
  },
  {
    name: `${groupName}.termsLink4`,
    label: 'URL フッターリンク4',
    tooltip: '',
    type: 'Text',
    defaultValue: '',
    options: urlOptions,
    elementOptions: {},
  },
  {
    name: `${groupName}.termsName4`,
    label: 'リンクテキスト フッターリンク4',
    tooltip: '',
    type: 'Text',
    defaultValue: '',
    options: {
      maxLength: maxLength(50),
    },
    elementOptions: {},
  },
  {
    name: `${groupName}.termsLink5`,
    label: 'URL フッターリンク5',
    tooltip: '',
    type: 'Text',
    defaultValue: '',
    options: urlOptions,
    elementOptions: {},
  },
  {
    name: `${groupName}.termsName5`,
    label: 'リンクテキスト フッターリンク5',
    tooltip: '',
    type: 'Text',
    defaultValue: '',
    options: {
      maxLength: maxLength(50),
    },
    elementOptions: {},
  },
  {
    name: `${groupName}.termsLink6`,
    label: 'URL フッターリンク6',
    tooltip: '',
    type: 'Text',
    defaultValue: '',
    options: urlOptions,
    elementOptions: {},
  },
  {
    name: `${groupName}.termsName6`,
    label: 'リンクテキスト フッターリンク6',
    tooltip: '',
    type: 'Text',
    defaultValue: '',
    options: {
      maxLength: maxLength(50),
    },
    elementOptions: {},
  },
  {
    name: `${groupName}.termsLink7`,
    label: 'URL フッターリンク7',
    tooltip: '',
    type: 'Text',
    defaultValue: '',
    options: urlOptions,
    elementOptions: {},
  },
  {
    name: `${groupName}.termsName7`,
    label: 'リンクテキスト フッターリンク7',
    tooltip: '',
    type: 'Text',
    defaultValue: '',
    options: {
      maxLength: maxLength(50),
    },
    elementOptions: {},
  },
  {
    name: `${groupName}.copyrightText`,
    label: '表示名 著作権表示',
    tooltip: '',
    type: 'Text',
    defaultValue: '',
    options: {
      maxLength: maxLength(100),
    },
    elementOptions: {},
  },
] as const;
