import { Options } from 'ky';
import { request } from 'api/request';
import { logger } from 'api/logger';
import { GroupFlat, isGroup } from 'api/tenant/types';
import { messages } from 'api/tenant/messages';
import { CustomError } from 'api/error/CustomError';

/**
 * グループ詳細取得API
 *
 * 状態により以下のレスポンスを返却します
 * 正常時: true, 異常時: false
 *
 */
export const getGroup = async (
  tenantId: string,
  groupId: string,
  options?: Options,
): Promise<GroupFlat> => {
  const response = await request({
    path: `tenant/${tenantId}/group/${groupId}`,
    method: 'get',
    options,
  });

  const responseData = (await response.data) as unknown[];

  if (response.hasError) {
    if (response.status === 404) {
      throw new CustomError(
        messages.getGroup.customer.notFound,
        response.status,
      );
    } else {
      throw new CustomError(messages.getGroup.customer.error, response.status);
    }
  }

  if (!isGroup(responseData)) {
    void logger({
      loglevel:
        response.status === 404 || response.status === 450
          ? 'warning'
          : 'error',
      data: responseData,
      message: messages.getGroup.system.typeError,
    });

    throw new CustomError(messages.getGroup.customer.error, response.status);
  }

  return responseData;
};
