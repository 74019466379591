import { request } from 'admin/api/Request';
import { API_MESSAGES } from 'admin/define/apiMessages';
import { CustomError } from 'admin/error/CustomError';
import { formGroupDetailReponseSchema } from 'admin/schema/idPoolConsumer/formGroup';
import { FormGroupType } from 'admin/types/userPool/formGroup';
import { isResponseError } from 'api/types';
import { Options } from 'ky';
import { getValidationError } from 'utils/form';
import { ZodError } from 'zod';

export const formGroupStatusChange = async ({
  data,
  userPoolId,
  options,
}: {
  data: FormGroupType;
  userPoolId: FormGroupType['userPoolId'];
  options?: Options;
}): Promise<FormGroupType> => {
  const { id, status } = data;
  const path = `enquete/${userPoolId}/content_group/${id}/`;
  const params = {
    status: !status,
  };

  const response = await request<FormGroupType>({
    path,
    method: 'patch',
    body: params,
    options,
  });

  if (response.hasError) {
    if (response.status === 404) {
      throw new CustomError(API_MESSAGES.USER_POOL.FORM_GROUP_STATUS.FAILED, {
        path,
        options,
        params,
        status: response.status,
      });
    } else if (isResponseError(response.error)) {
      const customError = response.error;
      const formError = getValidationError<FormGroupType>({
        formData: data,
        response: customError,
      });

      throw new CustomError(API_MESSAGES.USER_POOL.FORM_GROUP_STATUS.ERROR, {
        path,
        options,
        params,
        errors: formError,
        status: response.status,
        logLevel: response.status === 400 ? 'warning' : 'error',
      });
    } else {
      throw new CustomError(API_MESSAGES.USER_POOL.FORM_GROUP_STATUS.ERROR, {
        path,
        options,
        params,
        status: response.status,
      });
    }
  }

  try {
    formGroupDetailReponseSchema.parse(response.data);
  } catch (error) {
    if (error instanceof ZodError) {
      throw new CustomError(error.message, {
        path,
        options,
        params,
        status: response.status,
        logLevel: 'error',
      });
    }
  }

  return response.data;
};
