import { UserPool } from 'admin/types/userPool';
import { NewsletterType } from 'admin/types/userPool/newsletter';
import { Page } from 'api/common/types';

export const queryNewsletterKeyNames = {
  Newsletter: 'Newsletter',
  NewsletterDetail: 'NewsletterDetail',
} as const;

export const queryNewsletterKey = {
  getMailMagazine: (
    userPoolId: UserPool['id'],
    currentPage: Page['currentPage'],
    perPage?: Page['perPage'],
  ) => [queryNewsletterKeyNames.Newsletter, userPoolId, currentPage, perPage],
  getMailMagazineDetail: (mailMagazineId: NewsletterType['id']) => [
    queryNewsletterKeyNames.NewsletterDetail,
    mailMagazineId,
  ],
};
