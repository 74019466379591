import { request } from 'admin/api/Request';
import { API_MESSAGES } from 'admin/define/apiMessages';
import { CustomError } from 'admin/error/CustomError';
import { filterFieldUiTypeWithFieldMasterType } from 'admin/hooks/userPool/attribute/useIsShowMasterType';
import { attributeSchema } from 'admin/schema/idPoolConsumer/attribute';
import { UserPool } from 'admin/types/userPool';
import { IdPoolConsumerAttributeUpdateFormType } from 'admin/types/userPool/attribute/form';
import { IdPoolConsumerAttributeType } from 'admin/types/userPool/attribute/index';
import { isResponseError } from 'api/types';
import { getValidationError } from 'utils/form';
import { ZodError } from 'zod';

export const updateIdPoolConsumerAttribute = async ({
  data,
  userPoolId,
  id,
  isExternalId,
}: {
  data: IdPoolConsumerAttributeUpdateFormType;
  userPoolId: UserPool['id'];
  id: IdPoolConsumerAttributeType['id'];
  isExternalId: boolean;
}): Promise<IdPoolConsumerAttributeUpdateFormType> => {
  const isShowMasterType = filterFieldUiTypeWithFieldMasterType.includes(
    String(data.uiType),
  );

  let params: IdPoolConsumerAttributeType = {
    ...data,
    codeGroup: data.codeGroup !== '' || data.codeGroup ? data.codeGroup : null,
    jsonPath: data.jsonPath !== '' && data.jsonPath ? data.jsonPath : null,
    jsonPathValueType: Number(data.jsonPathValueType)
      ? Number(data.jsonPathValueType)
      : null,

    displayOrder: Number(data?.displayOrder)
      ? Number(data?.displayOrder)
      : 9999,
  };

  if (!isShowMasterType) {
    params = {
      ...params,
      codeGroup: undefined,
      jsonPathValueType: null,
    };
  }
  if (!isExternalId) {
    delete params.jsonPathValueType;
    params = {
      ...params,
      jsonPath: undefined,
    };
  }

  const path = `uniikey/${userPoolId}/user_attribute/${id}/`;
  const response = await request<IdPoolConsumerAttributeUpdateFormType>({
    path,
    body: params,
    method: 'patch',
  });

  if (response.hasError) {
    if (response.status === 404) {
      throw new CustomError(
        API_MESSAGES.USER_POOL.EDIT_ID_POOL_CONSUMER_ATTRIBUTE.FAILED,
        {
          path,
          status: response.status,
        },
      );
    } else if (isResponseError(response.error)) {
      const customError = response.error;

      const formError =
        getValidationError<IdPoolConsumerAttributeUpdateFormType>({
          formData: data,
          response: customError,
        });

      throw new CustomError(
        API_MESSAGES.USER_POOL.EDIT_ID_POOL_CONSUMER_ATTRIBUTE.ERROR,
        {
          path,
          params: data,
          errors: formError,
          status: response.status,
          logLevel: response.status === 400 ? 'warning' : 'error',
        },
      );
    } else {
      throw new CustomError(
        API_MESSAGES.USER_POOL.EDIT_ID_POOL_CONSUMER_ATTRIBUTE.ERROR,
        {
          path,
          status: response.status,
        },
      );
    }
  }
  try {
    attributeSchema.parse(response.data);
  } catch (error) {
    if (error instanceof ZodError) {
      throw new CustomError(error.message, {
        path,
        status: response.status,
        logLevel: 'error',
      });
    }
  }

  return response.data;
};
