import { getCodeSetting } from 'admin/api/userPool/code/getCodeSetting';
import { CustomError } from 'admin/error/CustomError';
import { CodeSettingType } from 'admin/types/userPool/code';
import { CodeGroupType } from 'admin/types/userPool/master';
import { logger } from 'api/logger';
import { useQuery } from 'react-query';
import { queryKeys } from './queryKeys';

export const useCodeSetting = (
  codeGroupId: CodeGroupType['id'],
  id: CodeSettingType['id'],
) => {
  const { data } = useQuery({
    queryKey: queryKeys.getCodeSetting(id, codeGroupId),
    queryFn: () => getCodeSetting({ id, codeGroupId }),
    onError: async (error) => {
      if (error instanceof CustomError) {
        await logger({
          loglevel: error.logLevel,
          data: JSON.stringify(error.cause),
          message: error.message,
        });
      }
    },
  });

  return { data };
};
