import {
  VFC,
  memo,
  useEffect,
  useState,
  useCallback,
  ChangeEvent,
} from 'react';
import { useFormContext } from 'react-hook-form';
import {
  Stack,
  Heading,
  Text,
  Box,
  Flex,
  IconButton,
  RadioGroup,
  Radio,
  Select,
} from '@chakra-ui/react';
import { IoReloadSharp } from 'react-icons/io5';
import { format } from 'date-fns';
import { isMailTargetCnt, MailSetting, MailSubscribe } from 'api/mail/types';
import { getMailTargetCnt } from 'api/mail/getMailTargetCnt';
import { getMailSubscribeList } from 'api/mail/getMailSubscribeList';
import { Segment } from 'api/segment/types';
import { useCustomToast } from 'hooks/useCustomToast';
import { useTenantInfo } from 'hooks/useTenantInfo';
import { useUserInfo } from 'hooks/useUserInfo';
import { useUserTenantId } from 'hooks/user/useUserTenantId';
import { useMailList } from 'hooks/mail/useMailList';
import { errorToast } from 'utils/toast';
import { DatePicker, ErrorTextMsg } from 'components/common/atoms';
import { InputForm, SelectForm, RadioForm } from 'components/common/molecules';
import { AddressUpload } from 'components/mail/atoms/AddressUpload';
import { CsvFileUpload } from 'components/mail/atoms/CsvFileUpload';
import { MailMergeDeliveryHelp } from 'components/mail/atoms/MailMergeDeliveryHelp';
import { MailDeliveryToDirectHelp } from 'components/mail/atoms/MailDeliveryToDirectHelp';
import { CsvFileUploadInfo } from 'components/mail/atoms/CsvFileUploadInfo';
import { CsvFileUploadError } from 'components/mail/atoms/CsvFileUploadError';
import { MailCancelReserveBtn } from 'components/mail/atoms/MailCancelReserveBtn';
import {
  MAIL_DELIVERY_TO_ALL,
  MAIL_DELIVERY_TO_DIRECT,
  MAIL_DELIVERY_TO_INSERT,
  MAIL_DELIVERY_TO_SEGMENT,
  MAIL_DELIVERY_TYPE_IMMEDIATE,
  MAIL_DELIVERY_TYPE_NOT_SET,
  MAIL_DELIVERY_TYPE_RESERVE,
  PERMISSION_DIRECTOR,
} from 'define';
import { MAIL_MAGAZINE_TARGET_TYPE_UID } from 'api/mail/consts';

const DATETIME_FORMAT = 'yyyy-MM-dd HH:mm';

type MailSettingDeliveryType = {
  subscriptId: string;
  segment: string;
  isMagazine: number;
  segmentSubscriptId: string;
};

export type MailFormDisabledPattern = Record<
  | 'mailCancelReserve'
  | 'deliveryType'
  | 'deliveryTypeDate'
  | 'deliverSegmentType'
  | 'deliveryToAll'
  | 'deliveryToSegment'
  | 'deliveryToSegmentType'
  | 'deliveryToSegmentTypeMagazine'
  | 'deliveryToDirect'
  | 'deliveryToDirectFile'
  | 'deliveryToInsert'
  | 'deliveryToInsertHelp'
  | 'deliveryToInsertUpload'
  | 'segmentMailTarget'
  | 'mailSubject'
  | 'mailFromAddress'
  | 'mailReplayAddress',
  boolean
>;

type MailFormSettingsProps = {
  segmentList: Segment[];
  uneditableMessate: string | JSX.Element;
  setDisabledSubmitButton: React.Dispatch<React.SetStateAction<boolean>>;
  reloadData: () => void;
};

export const MailFormSettings: VFC<MailFormSettingsProps> = memo(
  ({
    segmentList,
    uneditableMessate,
    setDisabledSubmitButton,
    reloadData,
  }: MailFormSettingsProps) => {
    const [initFields, setInitFields] = useState(false);
    const [deliveryDate, setDelivaryDate] = useState<Date | null>(null);
    const [mailTitleLength, setMailTitleLength] = useState(0);
    const [subscribeList, setSubscribeList] = useState<MailSubscribe[]>([]);
    const [isSubscription, setIsSubscription] = useState(true);
    const [selectDeliverType, setSelectDeliverType] =
      useState(MAIL_DELIVERY_TO_ALL);
    const [isUploadFile, setIsUploadFile] = useState(false);
    const [mailSettingDeliveryInfo, setMailSettingDeliveryInfo] =
      useState<MailSettingDeliveryType>({
        subscriptId: '',
        segment: '',
        isMagazine: 1,
        segmentSubscriptId: '',
      });
    const toast = useCustomToast();
    const tenantId = useUserTenantId();
    const { isStaff, isAssistant, isEditableTarget } = useUserInfo();
    const numberFormatter = new Intl.NumberFormat('ja-JP');
    const { mailFromAddressList, mailReplyAddressList } = useTenantInfo();
    const { mailList } = useMailList();

    const { setValue, getValues, watch } = useFormContext<MailSetting>();

    const [segmentTarget, setSegmentTarget] = useState<number | string>(0);
    const [isLoading, setIsLoading] = useState<boolean>(false);

    // 配信対象件数取得
    const getMailTarget = useCallback(
      async (mode?: string) => {
        // 配信対象件数を初期化
        // setSegmentTarget('読み込み中...');
        const deliveryToVal = getValues('deliveryTo');
        const segment = getValues('segment') || '';
        const subscription = getValues('subscription') || '';
        const addressFile = getValues('addressFile') || '';

        // 動作が重くなるのを防ぐ為 open 時は件数取得のリクエストをしない
        if (mode === 'open') {
          setSegmentTarget(getValues('estimateCnt') || 0);

          return;
        }

        if (deliveryToVal === 2 && addressFile === '') {
          toast({
            ...errorToast,
            title: '配信対象件数取得できません',
            description:
              '「メールアドレス直接指定」時はメールアドレスのアップロードを行ってください。',
          });

          return;
        }

        // 配信対象件数を「取得中」にする
        setSegmentTarget('取得中');
        setIsLoading(true);

        const mailTarget = await getMailTargetCnt(
          tenantId,
          deliveryToVal,
          segment,
          subscription,
          addressFile,
        )
          .then((res) => {
            if (isMailTargetCnt(res)) {
              setSegmentTarget(res.cnt);
            } else {
              setSegmentTarget('---');
              toast({
                ...errorToast,
                title: '配信対象件数取得エラー',
                description: res,
                duration: 4000,
              });
            }
          })
          .catch((_) => {
            setSegmentTarget('---');
          })
          .finally(() => {
            // ボタンを元に戻す
            setIsLoading(false);
          });

        if (typeof mailTarget === 'string') {
          toast({
            ...errorToast,
            title: '配信対象件数取得エラー',
            description: mailTarget,
            duration: 4000,
          });
        }
        if (isMailTargetCnt(mailTarget)) {
          setSegmentTarget(mailTarget.cnt);
        }
      },
      [getValues, tenantId, toast],
    );

    /**
     * 購読者一覧取得
     */
    const getMailSubscript = useCallback(async () => {
      const subscript = await getMailSubscribeList(tenantId);
      setSubscribeList(subscript.subscriptions);
      setIsSubscription(subscript.subscriptions.length > 0);

      // 初期状態
      if (
        subscript.subscriptions.length !== 0 &&
        getValues('deliveryTo') === 0 &&
        getValues('subscription') === ''
      ) {
        setValue('subscription', subscript.subscriptions[0].id);
      } else if (subscript.subscriptions.length === 0) {
        setValue('deliveryTo', 2);
      }
    }, [tenantId, setValue, getValues]);

    /**
     * 初期処理
     */
    useEffect(() => {
      if (!initFields) {
        const reserve = getValues('reserve') || null;
        const delivaryTo = getValues('deliveryTo');
        const segment = getValues('segment') || '';
        const subscription = getValues('subscription') || '';

        setDelivaryDate(reserve ? new Date(reserve) : null);
        setMailTitleLength(getValues('subject').length);
        // 配信対象件数を初期化
        setSegmentTarget(0);
        getMailTarget('open').catch((_) => {
          toast({
            ...errorToast,
            title: '配信対象者数取得エラー',
            description: '配信対象者数の取得ができませんでした。',
          });
        });
        getMailSubscript().catch((_) => {
          toast({
            ...errorToast,
            title: '購読者一覧取得エラー',
            description: '購読者一覧の取得ができませんでした。',
          });
        });
        setSelectDeliverType(delivaryTo);
        // 配信先の状態を作成
        if (delivaryTo === MAIL_DELIVERY_TO_ALL) {
          setMailSettingDeliveryInfo({
            ...mailSettingDeliveryInfo,
            subscriptId: subscription,
          });
        } else if (delivaryTo === MAIL_DELIVERY_TO_SEGMENT) {
          setMailSettingDeliveryInfo({
            ...mailSettingDeliveryInfo,
            segment,
            segmentSubscriptId: subscription,
            isMagazine: subscription ? 1 : 0,
          });
        } else if (delivaryTo === MAIL_DELIVERY_TO_INSERT) {
          setIsUploadFile(getValues('addressFileOrigin') !== '');
        }
        setInitFields(true);
      }
    }, [
      initFields,
      getValues,
      getMailTarget,
      getMailSubscript,
      toast,
      mailSettingDeliveryInfo,
      subscribeList,
    ]);

    // 監視処理
    useEffect(() => {
      const subscription = watch((value, { name }) => {
        // 配信先の選択状態に応じて、データをセットする
        if (name === 'deliveryTo') {
          setValue('subscription', '');
          setValue('segment', '');
          setSelectDeliverType(value.deliveryTo || MAIL_DELIVERY_TO_ALL);
          if (value.deliveryTo === MAIL_DELIVERY_TO_ALL) {
            let { subscriptId } = mailSettingDeliveryInfo;
            if (!subscriptId) {
              subscriptId = subscribeList[0].id;
            }
            setValue('subscription', subscriptId);
          } else if (value.deliveryTo === MAIL_DELIVERY_TO_SEGMENT) {
            setValue('segment', mailSettingDeliveryInfo.segment);
            if (mailSettingDeliveryInfo.isMagazine === 1) {
              let { segmentSubscriptId } = mailSettingDeliveryInfo;
              if (!segmentSubscriptId) {
                segmentSubscriptId = subscribeList[0].id;
              }
              setValue('subscription', segmentSubscriptId);
            }
          }
        }
      });

      return () => subscription.unsubscribe();
    }, [watch, setValue, mailSettingDeliveryInfo, subscribeList]);

    // 配信日時変更
    const onDateChange = useCallback(
      (date: Date | null) => {
        setDelivaryDate(date || null);
        setValue('reserve', date ? format(date, DATETIME_FORMAT) : null);
      },
      [setValue],
    );

    /**
     * セグメントへの配信内のラジオボタンChange event
     */
    const onChangeSegmentRadio = useCallback(
      (
        event: ChangeEvent<HTMLInputElement>,
        keyName: keyof MailSettingDeliveryType,
      ) => {
        const target = event.target as HTMLInputElement;
        setMailSettingDeliveryInfo({
          ...mailSettingDeliveryInfo,
          [keyName]: Number(target.value),
        });
        if (Number(target.value) === 1) {
          let { segmentSubscriptId } = mailSettingDeliveryInfo;
          if (!segmentSubscriptId) {
            segmentSubscriptId = subscribeList[0].id;
          }
          setValue('subscription', segmentSubscriptId);
        } else {
          setValue('subscription', '');
        }
      },
      [mailSettingDeliveryInfo, setValue, subscribeList],
    );

    /**
     * メルマガ購読者セレクトボックスChange event
     */
    const onChangeSegmentDeliveryInfo = useCallback(
      (
        event: ChangeEvent<HTMLSelectElement>,
        keyName: keyof MailSettingDeliveryType,
      ) => {
        setMailSettingDeliveryInfo({
          ...mailSettingDeliveryInfo,
          [keyName]: event.target.value,
        });
        if (keyName === 'segment') {
          setValue('segment', event.target.value);
        } else {
          setValue('subscription', event.target.value);
        }
      },
      [mailSettingDeliveryInfo, setValue],
    );

    /**
     * CSVファイルエラー内容
     */
    const isCsvFileWarning = (): boolean => {
      const shortagePlaceholders: string =
        getValues('shortagePlaceholders') || '';
      const excessPlaceholders: string = getValues('excessPlaceholders') || '';

      return shortagePlaceholders !== '' || excessPlaceholders !== '';
    };

    // 選択中のメルマガが「公開設定が無効のメルマガ」の場合は保存ボタンを押せなくする
    const isMailMagazine = selectDeliverType === MAIL_DELIVERY_TO_ALL;
    const hasId = mailSettingDeliveryInfo.subscriptId !== '';
    const idNotFound =
      subscribeList.find(
        (item) => item.id === mailSettingDeliveryInfo.subscriptId,
      ) === undefined;
    const isDisabled = isMailMagazine && hasId && idNotFound;

    const mail = mailList.find(
      (item) => item.mailMagazineId === mailSettingDeliveryInfo.subscriptId,
    );

    useEffect(() => {
      setDisabledSubmitButton(isDisabled);
    }, [isDisabled, setDisabledSubmitButton]);

    /**
     * 各フォームのdisabled制御用関数
     * disabled処理が複雑となってしまったため一元管理を行えるようリファクタを行う
     */
    const isFormDisabled = (
      formName: keyof MailFormDisabledPattern,
    ): boolean => {
      const disabledType: MailFormDisabledPattern = {
        mailCancelReserve:
          !getValues('cancellable') || !isEditableTarget(PERMISSION_DIRECTOR),
        deliveryType: uneditableMessate !== '' || isStaff || isAssistant,
        deliveryTypeDate: uneditableMessate !== '' || isStaff || isAssistant,
        deliverSegmentType:
          getValues('isReplaceWord') || uneditableMessate !== '',
        deliveryToAll: uneditableMessate !== '',
        deliveryToSegment:
          getValues('isReplaceWord') || uneditableMessate !== '',
        deliveryToSegmentType: uneditableMessate !== '',
        deliveryToSegmentTypeMagazine: uneditableMessate !== '',
        deliveryToDirect:
          getValues('isReplaceWord') || uneditableMessate !== '',
        deliveryToDirectFile: uneditableMessate !== '',
        deliveryToInsert:
          !getValues('isReplaceWord') || uneditableMessate !== '',
        deliveryToInsertHelp: !getValues('isReplaceWord'),
        deliveryToInsertUpload: uneditableMessate !== '',
        segmentMailTarget: uneditableMessate !== '' || isLoading,
        mailSubject: true,
        mailFromAddress: uneditableMessate !== '',
        mailReplayAddress: uneditableMessate !== '',
      };

      return disabledType[formName];
    };

    return (
      <Stack spacing="24px">
        <Box pb={4} borderBottom="1px solid #eee">
          <Box
            display="flex"
            justifyContent="space-between"
            alignItems="center"
            mb="4"
          >
            <Heading as="h2" size="sm">
              配信日時
            </Heading>
            <MailCancelReserveBtn
              mailId={getValues('id')}
              setDelivaryDate={setDelivaryDate}
              isDisabled={isFormDisabled('mailCancelReserve')}
              reloadData={reloadData}
            />
          </Box>
          <RadioForm<MailSetting>
            name="deliveryType"
            attr={{
              required: '',
            }}
            isDisabled={isFormDisabled('deliveryType')}
            radioList={[
              {
                id: 0,
                value: MAIL_DELIVERY_TYPE_NOT_SET,
                element: <Text>未設定</Text>,
              },
              {
                id: 1,
                value: MAIL_DELIVERY_TYPE_RESERVE,
                element: (
                  <DatePicker
                    startDate={deliveryDate ? new Date(deliveryDate) : null}
                    width={40}
                    onChange={onDateChange}
                    showTimeSelect
                    minDate={new Date()}
                    filterTime
                    filterIntervalTime={30}
                    isDisabled={isFormDisabled('deliveryTypeDate')}
                  />
                ),
              },
              {
                id: 2,
                value: MAIL_DELIVERY_TYPE_IMMEDIATE,
                element: <Text>今すぐに配信する</Text>,
              },
            ]}
            warningModalTitle="配信日時"
            warningModalDescription="即時配信されます、ご注意ください。"
            warningModalSubmitBtnName="OK"
          />
        </Box>

        <Box pb={4} borderBottom="1px solid #eee">
          <Heading as="h2" size="sm" mb="4">
            配信先
            <Text fontSize="12px" fontWeight="normal">
              配信対象を設定してください
            </Text>
            {getValues('isReplaceWord') && (
              <ErrorTextMsg msg="差し込みメールご利用時はメルマガ等の配信は行えません。" />
            )}
          </Heading>
          <RadioGroup name="deliverSegmentType" value={selectDeliverType}>
            {isSubscription && (
              <Box mb={2}>
                <Radio
                  value={MAIL_DELIVERY_TO_ALL}
                  onChange={(e) =>
                    setValue('deliveryTo', Number(e.target.value))
                  }
                  mb={2}
                  isDisabled={isFormDisabled('deliverSegmentType')}
                >
                  <Text>メルマガ購読者全員への配信</Text>
                </Radio>
                {selectDeliverType === MAIL_DELIVERY_TO_ALL && (
                  <Box ml={8}>
                    <Select
                      onChange={(e) =>
                        onChangeSegmentDeliveryInfo(e, 'subscriptId')
                      }
                      value={mailSettingDeliveryInfo.subscriptId}
                      isDisabled={isFormDisabled('deliveryToAll')}
                    >
                      {isDisabled && (
                        <option
                          key={`subscribe_${mailSettingDeliveryInfo.subscriptId}`}
                          value={mailSettingDeliveryInfo.subscriptId}
                          disabled
                        >
                          {mail?.mailMagazineTitle}
                        </option>
                      )}
                      {subscribeList.map((item) => (
                        <option key={`subscribe_${item.id}`} value={item.id}>
                          {item.name}
                        </option>
                      ))}
                    </Select>
                  </Box>
                )}
              </Box>
            )}
            {isSubscription && (
              <Box mb={2}>
                <Radio
                  value={MAIL_DELIVERY_TO_SEGMENT}
                  onChange={(e) =>
                    setValue('deliveryTo', Number(e.target.value))
                  }
                  mb={2}
                  isDisabled={isFormDisabled('deliveryToSegment')}
                >
                  <Text>セグメントへの配信</Text>
                </Radio>
                {selectDeliverType === MAIL_DELIVERY_TO_SEGMENT && (
                  <Box ml={6}>
                    <Box mb={2}>
                      <Select
                        placeholder="セグメントを選択してください"
                        value={mailSettingDeliveryInfo.segment}
                        isDisabled={isFormDisabled('deliveryToSegmentType')}
                        onChange={(e) =>
                          onChangeSegmentDeliveryInfo(e, 'segment')
                        }
                      >
                        {segmentList.map((item) => (
                          <option key={`segment_${item.id}`} value={item.id}>
                            {item.name}
                          </option>
                        ))}
                      </Select>
                    </Box>

                    {/* セグメント > メルマガ絞り込み */}
                    <RadioGroup
                      name="deliverMagazineType"
                      value={mailSettingDeliveryInfo.isMagazine}
                    >
                      <Box>
                        <Radio
                          value={1}
                          onChange={(e) =>
                            onChangeSegmentRadio(e, 'isMagazine')
                          }
                          mb={2}
                        >
                          <Text>メルマガ購読者宛に絞る</Text>
                        </Radio>
                        {mailSettingDeliveryInfo.isMagazine === 1 && (
                          <Box mb={2} ml={6}>
                            <Select
                              onChange={(e) =>
                                onChangeSegmentDeliveryInfo(
                                  e,
                                  'segmentSubscriptId',
                                )
                              }
                              value={mailSettingDeliveryInfo.segmentSubscriptId}
                              isDisabled={isFormDisabled(
                                'deliveryToSegmentTypeMagazine',
                              )}
                            >
                              {subscribeList
                                .filter(
                                  (x) =>
                                    x.targetType ===
                                    MAIL_MAGAZINE_TARGET_TYPE_UID,
                                )
                                .map((item) => (
                                  <option
                                    key={`subscribe_${item.id}`}
                                    value={item.id}
                                  >
                                    {item.name}
                                  </option>
                                ))}
                            </Select>
                          </Box>
                        )}
                      </Box>
                      <Radio
                        value={0}
                        onChange={(e) => onChangeSegmentRadio(e, 'isMagazine')}
                      >
                        <Box>
                          <Text>メルマガ購読者宛に絞らない</Text>
                        </Box>
                      </Radio>
                    </RadioGroup>
                  </Box>
                )}
              </Box>
            )}
            <Box>
              <Radio
                value={MAIL_DELIVERY_TO_DIRECT}
                onChange={(e) => setValue('deliveryTo', Number(e.target.value))}
                mb={2}
                isDisabled={isFormDisabled('deliveryToDirect')}
              >
                <Text as="span">メールアドレス直接指定</Text>
                <MailDeliveryToDirectHelp />
              </Radio>
              {selectDeliverType === MAIL_DELIVERY_TO_DIRECT && (
                <>
                  <Text fontSize="12px" ml={6} mb={2} fontWeight="normal">
                    送信したいメールアドレスを記載したテキストファイルをアップロードしてください
                  </Text>
                  <AddressUpload
                    fileName={getValues('addressFileOrigin') || ''}
                    isDisabled={isFormDisabled('deliveryToDirectFile')}
                    onChangeAddressFile={getMailTarget}
                    setTargetValue={setSegmentTarget}
                  />
                </>
              )}
            </Box>
            <Box>
              <Radio
                value={MAIL_DELIVERY_TO_INSERT}
                onChange={(e) => setValue('deliveryTo', Number(e.target.value))}
                mb={2}
                isDisabled={isFormDisabled('deliveryToInsert')}
              >
                <Flex alignItems="center">
                  <Text as="span">差し込みメール配信</Text>
                  <MailMergeDeliveryHelp
                    isDisabled={isFormDisabled('deliveryToInsertHelp')}
                  />
                </Flex>
              </Radio>
              {selectDeliverType === MAIL_DELIVERY_TO_INSERT && (
                <>
                  <Text fontSize="12px" ml={6} mb={2} fontWeight="normal">
                    差し込みたい内容のCSVファイルをアップロードしてください
                  </Text>
                  <CsvFileUploadInfo
                    csvHeaders={getValues('placeholders') || ''}
                  />
                  {isUploadFile && isCsvFileWarning() && (
                    <CsvFileUploadError
                      errorMsg={{
                        shortagePlaceholders:
                          getValues('shortagePlaceholders') || '',
                        excessPlaceholders:
                          getValues('excessPlaceholders') || '',
                      }}
                    />
                  )}
                  <CsvFileUpload
                    fileName={getValues('addressFileOrigin') || ''}
                    isDisabled={isFormDisabled('deliveryToInsertUpload')}
                    setSegmentTarget={setSegmentTarget}
                    setTargetValue={setSegmentTarget}
                  />
                </>
              )}
            </Box>
          </RadioGroup>

          {/* 配信対象件数表示 */}
          <Box mb={2} bg="gray.50" p={2}>
            <Box
              display="flex"
              justifyContent="space-between"
              alignItems="center"
            >
              <Box
                display="flex"
                justifyContent="space-between"
                alignItems="center"
              >
                <Text>配信対象件数</Text>
                <IconButton
                  color="gray.500"
                  aria-label="target-reload"
                  fontSize={16}
                  icon={<IoReloadSharp />}
                  boxShadow="md"
                  _focus={{ focus: 'none' }}
                  ml={2}
                  onClick={() => getMailTarget()}
                  isDisabled={isFormDisabled('segmentMailTarget')}
                />
              </Box>
              <Text fontSize={24} fontWeight="bold">
                {(typeof segmentTarget === 'number' &&
                  numberFormatter.format(segmentTarget)) ||
                  segmentTarget}
              </Text>
            </Box>
          </Box>
        </Box>
        <Box>
          <Heading as="h2" size="sm" mb="4">
            差出人・返信先設定
          </Heading>
          <Stack spacing={2}>
            <InputForm<MailSetting>
              name="subject"
              type="text"
              labelElement={
                <Flex justifyContent="space-between">
                  <Text>件名</Text>
                  <Text>{mailTitleLength} 文字（推奨：全角35文字）</Text>
                </Flex>
              }
              isDisabled={isFormDisabled('mailSubject')}
              attr={{ required: '件名を入力してください' }}
            />

            <SelectForm<MailSetting>
              name="fromAddress"
              label="差出人メールアドレス"
              placeholder="選択してください"
              attr={{
                required: '差出人メールアドレスは必ず選択してください',
              }}
              isDisabled={isFormDisabled('mailFromAddress')}
              optionList={mailFromAddressList.map((item) => (
                <option key={`from_${item.id}`} value={item.name}>
                  {item.name}
                </option>
              ))}
            />

            <SelectForm<MailSetting>
              name="replayAddress"
              label="返信用メールアドレス（任意）"
              placeholder="選択してください"
              attr={{
                required: '',
              }}
              isDisabled={isFormDisabled('mailReplayAddress')}
              optionList={mailReplyAddressList.map((item) => (
                <option key={`replay_${item.id}`} value={item.name}>
                  {item.name}
                </option>
              ))}
            />
          </Stack>
        </Box>
      </Stack>
    );
  },
);
