import { VFC, memo } from 'react';
import { Helmet } from 'react-helmet-async';
import { TemplateNotLogged } from 'components/common/templates/TemplateNotLogged';
import { ResetPasswordForm } from 'components/user/organisms/ResetPasswordForm';

export const ResetPassword: VFC = memo(() => (
  <>
    <Helmet>
      <title>パスワード再設定</title>
    </Helmet>
    <TemplateNotLogged>
      <ResetPasswordForm />
    </TemplateNotLogged>
  </>
));
