import { PAYWALL_CUSTOM_WIDGET_FOR_COURSE_BUTTONS, PAYWALL_CUSTOM_WIDGET_FOR_ITEM_BUTTONS } from 'define';

/**
 * カスタムウイジェット作成
 */
export const useCustomWidget = (): {
  createCustomWidgetForCourse: (name: string) => string;
  createCustomWidgetForItem: (name: string) => string;
  createCustomWidgetForMember: (name: string) => string;
} => {

  /**
   * カスタムウィジェット説明テキストエレメント
   */
  const payWallCustomWidgetInfoText =
    '<p>ボタン名に商品名や税抜価格などを埋め込むことが可能です。</p><p>ラベルをクリックにて「埋め込みワード」に表示される文字をコピーし、ボタン名に貼り付けてお使いください。</p>';

  /**
   * サブスクリプション用
   */
  const createCustomWidgetForCourse = (name: string): string => `
    unlayer.registerPropertyEditor({
      name: '${name}',
      Widget: unlayer.createWidget({
        render(value, updateValue, data) {
          return '<style>.embedded_view {display: block;padding: 8px 4px;width: 100%;position: relative;}.embedded_view > .embedded_view_input {outline: 0;border: 0;display: block;width: 100%;padding: 8px 0px 6px 0px;font-size: 1.2em;background: rgba(0,0,0,0);color: rgba(0,0,0,0.67);border-bottom: 1px solid rgba(0,0,0,0.4);margin-top: 0.5rem;}.embedded_view > .embedded_view_input:disabled {background: #ffffff;cursor: default !important;}.embedded_view > .embedded_view_label {font-size: 11px;color: #8f9699;font-weight: bold;}.embedded_description {padding: 8px 4px;}.embedded_description > p {font-size: 11px;color: #8f9699;margin-bottom: initial;}.embedded_group {margin-top: 0.5rem;padding: 8px;border: 1px solid #e6e6e6;display: flex;align-items: center;justify-content: flex-start;flex-wrap: wrap;gap: 0.5rem;background: #FFF;}.embedded_group > button {appearance: none;-webkit-user-select: none;-moz-user-select: none;-ms-user-select: none;user-select: none;position: relative;white-space: nowrap;vertical-align: middle;outline: 2px solid transparent;outline-offset: 2px;width: auto;line-height: 1.2;border-radius: 0.375rem;font-weight: 600;height: 1.5rem;min-width: 1.5rem;font-size: 0.75rem;padding: 0;padding-inline-start: 0.5rem;padding-inline-end: 0.5rem;background: #EDF2F7;cursor: pointer;border-color: #EDF2F7;border-style: solid;box-sizing: border-box;}</style><div class="paywall_customTool_widget"><div class="embedded_view"><span class="embedded_view_label">埋め込みワード</span><input class="embedded_view_input" disabled value="'+value+'" /></div><div class="embedded_description">${payWallCustomWidgetInfoText}</div><div class="embedded_group">${PAYWALL_CUSTOM_WIDGET_FOR_COURSE_BUTTONS.map(
    (el, idx) => `<button class="embedded_group_${idx + 1}">${el.NAME}</button>`,
  ).join('')}</div></div>'
        },
        mount(node, value, updateValue, data) {
          const input = node.getElementsByClassName('embedded_view_input')[0]
          input.onchange = function(event) {
            console.log('data', data)
            updateValue('')
          }

          ${PAYWALL_CUSTOM_WIDGET_FOR_COURSE_BUTTONS.map(
            (el, idx) => `
            const embeddedGroupButton${
              idx + 1
            } = node.getElementsByClassName('embedded_group_${idx + 1}')[0];
            embeddedGroupButton${idx + 1}.onclick = function() {
              updateValue('${el.PREFIX}')
            }
          `,
          ).join('')}
        }
      })
    });
  `;

  /**
   * 単品商品用
   */
  const createCustomWidgetForItem = (name: string): string => `
    unlayer.registerPropertyEditor({
      name: '${name}',
      Widget: unlayer.createWidget({
        render(value, updateValue, data) {
          return '<style>.embedded_view {display: block;padding: 8px 4px;width: 100%;position: relative;}.embedded_view > .embedded_view_input {outline: 0;border: 0;display: block;width: 100%;padding: 8px 0px 6px 0px;font-size: 1.2em;background: rgba(0,0,0,0);color: rgba(0,0,0,0.67);border-bottom: 1px solid rgba(0,0,0,0.4);margin-top: 0.5rem;}.embedded_view > .embedded_view_input:disabled {background: #ffffff;cursor: default !important;}.embedded_view > .embedded_view_label {font-size: 11px;color: #8f9699;font-weight: bold;}.embedded_description {padding: 8px 4px;}.embedded_description > p {font-size: 11px;color: #8f9699;margin-bottom: initial;}.embedded_group {margin-top: 0.5rem;padding: 8px;border: 1px solid #e6e6e6;display: flex;align-items: center;justify-content: flex-start;flex-wrap: wrap;gap: 0.5rem;background: #FFF;}.embedded_group > button {appearance: none;-webkit-user-select: none;-moz-user-select: none;-ms-user-select: none;user-select: none;position: relative;white-space: nowrap;vertical-align: middle;outline: 2px solid transparent;outline-offset: 2px;width: auto;line-height: 1.2;border-radius: 0.375rem;font-weight: 600;height: 1.5rem;min-width: 1.5rem;font-size: 0.75rem;padding: 0;padding-inline-start: 0.5rem;padding-inline-end: 0.5rem;background: #EDF2F7;cursor: pointer;border-color: #EDF2F7;border-style: solid;box-sizing: border-box;}</style><div class="paywall_customTool_widget"><div class="embedded_view"><span class="embedded_view_label">埋め込みワード</span><input class="embedded_view_input" disabled value="'+value+'" /></div><div class="embedded_description">${payWallCustomWidgetInfoText}</div><div class="embedded_group">${PAYWALL_CUSTOM_WIDGET_FOR_ITEM_BUTTONS.map(
    (el, idx) => `<button class="embedded_group_${idx + 1}">${el.NAME}</button>`,
  ).join('')}</div></div>'
        },
        mount(node, value, updateValue, data) {
          const input = node.getElementsByClassName('embedded_view_input')[0]
          input.onchange = function(event) {
            updateValue('')
          }
          ${PAYWALL_CUSTOM_WIDGET_FOR_ITEM_BUTTONS.map(
            (el, idx) => `
            const embeddedGroupButton${
              idx + 1
            } = node.getElementsByClassName('embedded_group_${idx + 1}')[0];
            embeddedGroupButton${idx + 1}.onclick = function() {
              updateValue('${el.PREFIX}')
            }
          `,
          ).join('')}
        }
      })
    });
  `;

  /**
   * 会員限定用
   */
  const createCustomWidgetForMember = (name: string): string => `
  unlayer.registerPropertyEditor({
    name: '${name}',
    Widget: unlayer.createWidget({
      render(value, updateValue, data) {
        return '<style>.embedded_view {display: block;padding: 8px 4px;width: 100%;position: relative;}.embedded_view > .embedded_view_input {outline: 0;border: 0;display: block;width: 100%;padding: 8px 0px 6px 0px;font-size: 1.2em;background: rgba(0,0,0,0);color: rgba(0,0,0,0.67);border-bottom: 1px solid rgba(0,0,0,0.4);margin-top: 0.5rem;}.embedded_view > .embedded_view_input:disabled {background: #ffffff;cursor: default !important;}.embedded_view > .embedded_view_label {font-size: 11px;color: #8f9699;font-weight: bold;}.embedded_description {padding: 8px 4px;}.embedded_description > p {font-size: 11px;color: #8f9699;margin-bottom: initial;}.embedded_group {margin-top: 0.5rem;padding: 8px;border: 1px solid #e6e6e6;display: flex;align-items: center;justify-content: flex-start;flex-wrap: wrap;gap: 0.5rem;background: #FFF;}.embedded_group > button {appearance: none;-webkit-user-select: none;-moz-user-select: none;-ms-user-select: none;user-select: none;position: relative;white-space: nowrap;vertical-align: middle;outline: 2px solid transparent;outline-offset: 2px;width: auto;line-height: 1.2;border-radius: 0.375rem;font-weight: 600;height: 1.5rem;min-width: 1.5rem;font-size: 0.75rem;padding: 0;padding-inline-start: 0.5rem;padding-inline-end: 0.5rem;background: #EDF2F7;cursor: pointer;border-color: #EDF2F7;border-style: solid;box-sizing: border-box;}</style>'
      }
    })
  });
  `;

  return {
    createCustomWidgetForCourse,
    createCustomWidgetForItem,
    createCustomWidgetForMember,
  }
}
