import { VFC, memo } from 'react';
import { Box, Text, ButtonGroup, Button, Icon } from '@chakra-ui/react';
import { MdContentCopy, MdOutlineFileDownload } from 'react-icons/md';
import { MailInfo, MeasurementResults } from 'api/mail/types';

type MailReportDrawerHeaderProps = {
  mailInfo: MailInfo;
  list: MeasurementResults[];
  download: (copyData: MeasurementResults[]) => void;
  copy: (copyData: MeasurementResults[]) => void;
};

export const MailReportDrawerHeader: VFC<MailReportDrawerHeaderProps> = memo(
  ({ mailInfo, list, download, copy }: MailReportDrawerHeaderProps) => (
    <Box
      display="flex"
      justifyContent="space-between"
      alignItems="center"
      mb={2}
    >
      <Box display="flex" gridGap={4}>
        <Text color="gray.600">
          <Box as="span" fontSize={16} fontWeight="bold" color="#999" mr={2}>
            配信ID
          </Box>
          {mailInfo.id}
        </Text>
        <Text color="gray.600">
          <Box as="span" fontSize={16} fontWeight="bold" color="#999" mr={2}>
            件名
          </Box>
          {mailInfo.subject}
        </Text>
      </Box>
      <ButtonGroup spacing="4">
        <Button
          bgColor="white"
          borderWidth="1px"
          color="gray.500"
          _focus={{ focus: 'none' }}
          onClick={() => download(list)}
          disabled={list.length === 0}
        >
          ダウンロード
          <Icon as={MdOutlineFileDownload} w={4} h={4} ml={2} />
        </Button>
        <Button
          bgColor="white"
          borderWidth="1px"
          color="gray.500"
          _focus={{ focus: 'none' }}
          onClick={() => copy(list)}
          disabled={list.length === 0}
        >
          全件コピー
          <Icon as={MdContentCopy} ml={2} />
        </Button>
      </ButtonGroup>
    </Box>
  ),
);
