import { UseMutateFunction, useMutation, useQueryClient } from 'react-query';
import { useCustomToast } from 'hooks/useCustomToast';
import { useRefreshUserState } from 'hooks/user/useRefreshUserState';
import { ResponseError } from 'api/types';
import { deleteGroupUser } from 'api/user/deleteGroupUser';
import { queryTenantKey } from '../tenant/queryTenantKey';

type UseDeleteGroupUser = {
  groupId: string;
  userId: string;
};
type UnPromisify<T> = T extends Promise<infer U> ? U : T;

/**
 * グループからユーザーを削除 hooks
 */
export const useDeleteGroupUser = (): {
  onDelete: UseMutateFunction<
    UnPromisify<ReturnType<typeof deleteGroupUser>>,
    // 例外時
    unknown,
    UseDeleteGroupUser,
    unknown
  >;
  isLoading: boolean;
  isSuccess: boolean;
} => {
  const toast = useCustomToast();
  const queryClient = useQueryClient();
  const refreshUserState = useRefreshUserState();

  const {
    mutate: onDelete,
    isLoading,
    isSuccess,
  } = useMutation(
    ({ groupId, userId }: UseDeleteGroupUser) =>
      deleteGroupUser({ groupId, userId }),
    {
      onSuccess: (result: boolean | string[] | ResponseError) => {
        if (typeof result === 'boolean' && result) {
          // 親のグループ一覧を更新する
          void queryClient.refetchQueries(queryTenantKey.groupList);
          // userState の recoilを更新する
          void refreshUserState();
          toast({
            status: 'success',
            position: 'bottom',
            duration: 2000,
            isClosable: true,
            title: '削除しました',
          });

          return;
        }
        if (Array.isArray(result)) {
          result.forEach((msg) => {
            toast({
              status: 'error',
              position: 'bottom',
              duration: 2000,
              isClosable: true,
              title: msg,
            });
          });
        }
      },
    },
  );

  return { onDelete, isLoading, isSuccess };
};
