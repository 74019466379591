import { Apikey } from 'components/setting/apikey/index';
import { FormSetting } from 'components/setting/formSetting';
import { Groups } from 'components/setting/groups/index';
import { GroupsBody } from 'components/setting/groups/templates/GroupsBody';
import { useSettingAuth } from 'components/setting/hooks/useSettingAuth';
import { LoginRedirect } from 'components/setting/loginRedirect';
import { MailMagazine } from 'components/setting/mailMagazine';
import { PaymentAgency } from 'components/setting/paymentAgency';
import { Users } from 'components/setting/users/index';
import { UserSetting } from 'components/setting/userSetting/index';
import { useUserInfo } from 'hooks/useUserInfo';
import { FC } from 'react';
import { Navigate, RouteObject } from 'react-router-dom';

const redirectPath = '/setting';

// ペイウォールアクセス制御処理HOC
const withAuthCheckRoutingControl = (
  WrappedComponent: React.ComponentType,
  redirect: string,
  permissionKey: string,
): FC => {
  const WithAuthCheckRoutingControl: FC = (props) => {
    const { isAllowedPermission } = useSettingAuth();
    const { isEnabledFormHosting } = useUserInfo();

    // アクセスが許可されていない場合は設定されているリダイレクトへ遷移させる
    if (!isAllowedPermission(permissionKey)) return <Navigate to={redirect} />;

    // フォーム関連設定にアクセスする権限がない場合もリダイレクトする
    if (permissionKey === 'formSetting' && !isEnabledFormHosting()) {
      return <Navigate to={redirect} />;
    }

    return <WrappedComponent {...props} />;
  };

  return WithAuthCheckRoutingControl;
};

// HOCを適用する
const SettingUsersListWithAuthCheckRouting = withAuthCheckRoutingControl(
  Users,
  redirectPath,
  'users',
);
const SettingGroupsListWithAuthCheckRouting = withAuthCheckRoutingControl(
  Groups,
  redirectPath,
  'groups',
);
const SettingApikeyListWithAuthCheckRouting = withAuthCheckRoutingControl(
  Apikey,
  redirectPath,
  'apikey',
);
const SettingUserSettingListWithAuthCheckRouting = withAuthCheckRoutingControl(
  UserSetting,
  redirectPath,
  'userSetting',
);
const SettingMailMagazineListWithAuthCheckRouting = withAuthCheckRoutingControl(
  MailMagazine,
  redirectPath,
  'mailMagazine',
);
const SettingPaymentAgencyListWithAuthCheckRouting =
  withAuthCheckRoutingControl(PaymentAgency, redirectPath, 'paymentAgency');
const SettingFormSettingWithAuthCheckRouting = withAuthCheckRoutingControl(
  FormSetting,
  redirectPath,
  'formSetting',
);
const SettingLoginRedirectWithAuthCheckRouting = withAuthCheckRoutingControl(
  LoginRedirect,
  redirectPath,
  'loginRedirect',
);

export const settingRoutes: RouteObject[] = [
  {
    path: 'users',
    element: <SettingUsersListWithAuthCheckRouting />,
  },
  {
    path: 'groups',
    element: <SettingGroupsListWithAuthCheckRouting />,
    children: [
      {
        path: ':groupId',
        element: <GroupsBody />,
      },
    ],
  },
  {
    path: 'apikey',
    element: <SettingApikeyListWithAuthCheckRouting />,
  },
  {
    path: 'userSetting',
    element: <SettingUserSettingListWithAuthCheckRouting />,
  },
  {
    path: 'mailMagazine',
    element: <SettingMailMagazineListWithAuthCheckRouting />,
  },
  {
    path: 'paymentAgency',
    element: <SettingPaymentAgencyListWithAuthCheckRouting />,
  },
  {
    path: 'formSetting',
    element: <SettingFormSettingWithAuthCheckRouting />,
  },
  {
    path: 'loginRedirect',
    element: <SettingLoginRedirectWithAuthCheckRouting />,
  }
];
