import { withSuspenseAndErrorBoundary } from 'admin/components/Error/WithErrorBoundary';
import { useIdPoolConsumerId } from 'admin/hooks/useIdPoolConsumerId';
import { useTerm } from 'admin/hooks/userPool/terms';
import { useLogs } from 'admin/hooks/userPool/terms/useLogs';
import { useTermId } from 'admin/hooks/useTermId';
import { ErrorContents } from 'components/common/atoms';
import { FC } from 'react';
import { LogTableComponent } from './LogTableComponent';

export const LogTable: FC = () => {
  const userPoolId = useIdPoolConsumerId();
  const termId = useTermId();
  const { data: logs } = useLogs();
  const { data: term } = useTerm({
    id: termId,
    userPoolId,
  });
  if (!logs || !term) {
    return null;
  }

  return <LogTableComponent logs={logs} termName={term.displayNameJa} />;
};

export const Log = withSuspenseAndErrorBoundary(LogTable, {
  ErrorComponent: <ErrorContents name="プライバシーポリシー更新履歴" />,
});
