import { RepeatIcon } from '@chakra-ui/icons';
import { Box, Tooltip } from '@chakra-ui/react';
import { memo, VFC } from 'react';
import { useNavigate } from 'react-router-dom';

type ErrorContentsType = {
  code: number;
  redirectPath?: string;
};

export const ErrorStatusContents: VFC<ErrorContentsType> = memo(
  ({ code, redirectPath = "../" }: ErrorContentsType) => {
    const navigate = useNavigate();
    const pageRedirect = () => (code === 404 ? navigate(redirectPath) : navigate('/'));
    const labelText = code === 404 ? '一覧へ戻る' : 'トップへ戻る';

    return (
      <Box display="flex" alignItems="center" pr={4}>
        <Box ml={2}>
          データを取得できませんでした。恐れ入りますが画面の再読み込みをお願いします。
        </Box>
        <Tooltip label={labelText} fontSize="xs" bg="gray.600">
          <RepeatIcon cursor="pointer" marginLeft={1} onClick={pageRedirect} />
        </Tooltip>
      </Box>
    );
  },
);
