import { Button as ChakraButton, ButtonProps, Text } from '@chakra-ui/react';
import styled from '@emotion/styled';
import { memo, VFC } from 'react';
import { NavLink } from 'react-router-dom';


const StyledChakraButton = styled(ChakraButton)<{
  mode: 'submit' | 'cancel' | 'simple' | 'gray';
}>(({ mode }) => ({
  minWidth: 100,
  fontWeight: mode === 'submit' ? 'bold' : 'normal',
  backgroundColor:
    // eslint-disable-next-line no-nested-ternary
    mode === 'gray' ? '#eeeeee' : mode === 'simple' ? '#ffffff' : '',
  // chakra default style reset
  padding: 0,
  height: 'initial',
}));

const StyledNavLink = styled(NavLink)<{ padding: number | string }>(
  ({ padding }) => ({
    width: '100%',
    padding: padding || '',
  }),
);

const StyledButtonInner = styled(Text)<{ padding: number | string }>(
  ({ padding }) => ({
    width: '100%',
    padding: padding || '',
  }),
);

type Props = {
  mode?: 'submit' | 'cancel' | 'simple' | 'gray';
  variant?: ButtonProps['variant'];
  size?: ButtonProps['size'];
  padding?: number | string;
  disabled?: boolean;
  children: React.ReactNode;
  to?: string;
  onClick?: () => void;
} & ButtonProps;

/**
 * @see 汎用ボタン
 * @param mode ボタンのモード
 * @param variant ボタンの種類
 * @param size ボタンのサイズ
 * @param padding ボタン内側のpadding
 * @param disabled ボタン実行の可否
 * @param children ボタンの子要素
 * @param to ジャンプ先のパス
 * @param onClick ボタンクリックのイベントハンドラ
 */
export const Button: VFC<Props> = memo(
  ({
    mode = 'submit',
    variant = mode === 'submit' ? 'solid' : 'outline',
    size = 'sm',
    padding = '0.5rem',
    disabled,
    colorScheme,
    children,
    to,
    onClick,
    ...props
  }) => (
    <StyledChakraButton
      mode={mode}
      variant={variant}
      size={size}
      disabled={disabled}
      colorScheme={colorScheme || (mode === 'submit' ? 'blue' : 'white')}
      onClick={onClick}
      pointerEvents={disabled ? 'none' : 'auto'}
      {...props}
    >
      {to && (
        <StyledNavLink padding={padding} to={to}>
          {children}
        </StyledNavLink>
      )}
      {!to && (
        <StyledButtonInner padding={padding}>{children}</StyledButtonInner>
      )}
    </StyledChakraButton>
  ),
);
