import { FC, memo, Suspense } from 'react';
import ErrorBoundary from 'api/ErrorBoundary';
import { useErrorBoundaryReset } from 'hooks/useErrorBoundaryReset';
import {
  ErrorContents,
  ErrorStatusContents,
  LoadingSkeleton,
} from 'components/common/atoms';

import { StyledTable, StyledBoldTr } from 'components/paywall/styled/global';
import { ItemDesignTableRows } from 'components/paywall/pages/ItemDesignList/ItemDesignTableRows';

export const ItemDesignTable: FC = memo(() => {
  const { ebKey, onError } = useErrorBoundaryReset();
  const renderErrorComponent = {
    404: <ErrorStatusContents code={404} />,
    450: <ErrorStatusContents code={450} />,
  };

  return (
    <StyledTable>
      <table>
        <thead>
          <StyledBoldTr>
            <td>ウォール画面ID</td>
            <td colSpan={2}>名前</td>
            <td style={{ textAlign: 'right' }}>ステータス</td>
          </StyledBoldTr>
        </thead>
        <ErrorBoundary
          errorComponent={<ErrorContents name="ウォール一覧" />}
          statusComponent={renderErrorComponent}
          onError={onError}
          key={ebKey.current}
        >
          <Suspense
            fallback={
              <tbody>
                <tr>
                  <td colSpan={4}>
                    <LoadingSkeleton />
                  </td>
                </tr>
              </tbody>
            }
          >
            <ItemDesignTableRows />
          </Suspense>
        </ErrorBoundary>
      </table>
    </StyledTable>
  );
});
