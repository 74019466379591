import { useMemo } from 'react';
import { useParams } from 'react-router-dom';

export const useUserPoolChildId = () => {
  const params = useParams();

  const userPoolChildId = useMemo(
    () => params.userPoolChildId ?? '',
    [params],
  );

  return userPoolChildId;
};
