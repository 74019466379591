import { zodResolver } from '@hookform/resolvers/zod';
import { withSuspenseAndErrorBoundary } from 'admin/components/Error/WithErrorBoundary';
import { LoadingLayer } from 'admin/components/ui/loadingLayer';
import { UsersDeleteFormComponent } from 'admin/components/users/usersDeleteForm/UsersDeleteFormComponent';
import { useDeleteUser } from 'admin/hooks/user/useDeleteUser';
import { userDeleteSubmitFormSchema } from 'admin/schema/users';
import { UserDeleteStateType } from 'admin/types/user';
import { UserDeleteFormType, UserDeleteSubmitFormType } from 'admin/types/users';
import { FC, memo, useCallback } from 'react';
import { FormProvider, useForm } from 'react-hook-form';

export type Props = {
  deleteList: UserDeleteStateType[]
  onClose: () => void;
};

export const usersDeleteForm: FC<Props> = memo(
  ({ deleteList, onClose }) => {
    const formId = 'userDeleteForm';
    const { mutate, isLoading } = useDeleteUser();

    const methods = useForm<UserDeleteSubmitFormType>({
      defaultValues: {
        word: '',
      },
      mode: 'onBlur',
      resolver: zodResolver(userDeleteSubmitFormSchema),
    });

    const onDelete = useCallback(async () => {
      // 削除処理
      const sendData: UserDeleteFormType = {
        users: [],
      };
      sendData.users = deleteList.map((val) => val.id);
      await mutate(sendData);
      onClose();
    }, [deleteList, mutate, onClose]);

    return (
      <FormProvider {...methods}>
        {isLoading && <LoadingLayer />}
        <UsersDeleteFormComponent
          formId={formId}
          deleteList={deleteList}
          isLoading={isLoading}
          onClose={onClose}
          onDelete={onDelete}
        />
      </FormProvider>
    );
  },
);

export const UsersDeleteForm = withSuspenseAndErrorBoundary(usersDeleteForm);
