import { Options } from 'ky';
import { request } from 'api/request';
import { logger } from 'api/logger';
import { ContentsTable, isContentsTableList } from 'api/contents/types';
import { messages } from 'api/contents/messages';
import { CustomError } from 'api/error/CustomError';

export const getContentsTableList = async (
  tenantId: string,
  options?: Options,
): Promise<ContentsTable[]> => {
  const response = await request({
    path: `contentsdb/${tenantId}/`,
    method: 'get',
    options,
  });

  const responseData = (await response.data) as unknown[];

  if (response.hasError) {
    if (response.status === 404) {
      throw new CustomError(
        messages.getContentsTableList.customer.notFound,
        response.status,
      );
    } else {
      throw new CustomError(
        messages.getContentsTableList.customer.error,
        response.status,
      );
    }
  }

  if (!isContentsTableList(responseData)) {
    void logger({
      loglevel:
        response.status === 404 || response.status === 450
          ? 'warning'
          : 'error',
      data: responseData,
      message: messages.getContentsTableList.system.typeError,
    });

    throw new CustomError(messages.getContentsTableList.customer.typeError);
  }

  return responseData;
};
