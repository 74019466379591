import { Badge, Box } from '@chakra-ui/react';
import { FC, memo } from 'react';
import { IoNotificationsOutline } from 'react-icons/io5';

type NotificationIconProps = {
  unreadCount: number;
};

export const NotificationIcon: FC<NotificationIconProps> = memo(
  ({ unreadCount }) => (
    <Box cursor="pointer" position="relative">
      <IoNotificationsOutline size={29} />
      {unreadCount > 0 && (
        <Badge
          bg="gray.50"
          color="gray.600"
          position="absolute"
          bottom={0}
          fontSize="6px"
          rounded={12}
          boxShadow="md"
        >
          {unreadCount}
        </Badge>
      )}
    </Box>
  ),
);
