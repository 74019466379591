import { Select, Table, Tbody, Td, Text, Tr, VStack } from '@chakra-ui/react';
import { RadioForm } from 'components/common/molecules';
import { QuestionTitleElement } from 'components/enquete/atoms/QuestionTitleElement';
import { RichTextElement } from 'components/enquete/atoms/RichTextElement';
import { Toggle } from 'components/enquete/atoms/Toggle';
import { MailMagazine } from 'components/setting/mailMagazine/typed';
import { Dispatch, FC, SetStateAction, useState } from 'react';
import { FieldValues, Path, useFormContext } from 'react-hook-form';
import { CondElement } from './CondElement';
import { MailMagazineHelp } from './MailMagazineConfirmationHelp';

type EnqueteFormType<T> = {
  baseName: Path<T>;
  index: number;
  setIsEdited: Dispatch<SetStateAction<boolean>>;
  mailMagazineList: MailMagazine[];
};

export const MailMagazineElement = <T extends FieldValues>({ baseName, index, setIsEdited, mailMagazineList }: EnqueteFormType<T>): ReturnType<FC> => {
  const [toggle, setToggle] = useState(false);
  const { getValues, register } = useFormContext<T>();

  return (
    <>
      <VStack w="100%" display={toggle ? 'none' : 'block'}>
        <Table>
          <Tbody>
            <Tr>
              <QuestionTitleElement<T> baseName={baseName} width={140} colspan={1} index={index} />
            </Tr>
            <Tr>
              <Td w={140}>補足説明など</Td>
              <Td>
                <RichTextElement<T>
                  baseName={baseName}
                  placeholder=""
                  index={index}
                  position="descriptionTop"
                  value={
                    getValues(`${baseName}.${index}.descriptionTop` as Path<T>) || ''
                  }
                  setIsEdited={setIsEdited}
                />
              </Td>
            </Tr>
            <Tr>
              <Td w={36} display="inline-flex">
                <Text m="auto">確認方法</Text>
                <MailMagazineHelp />
              </Td>
              <Td>
                <RadioForm<T>
                  name={`${baseName}.${index}.confirmationMethod` as Path<T>}
                  attr={{
                    // RegisterOptions
                    required: '',
                  }}
                  isDisabled={getValues('uneditableState' as Path<T>)}
                  style={{
                    display: 'flex',
                    gap: '1rem',
                  }}
                  radioList={[
                    {
                      id: 1,
                      value: 1,
                      element: <Text>配信希望のみ確認</Text>,
                    },
                    {
                      id: 2,
                      value: 2,
                      element: <Text>配信希望・配信停止を選択</Text>,
                    },
                  ]}
                  contains
                />
              </Td>
            </Tr>
            <Tr>
              <Td w={36}>メルマガ</Td>
              <Td>
                <Select
                  w="full"
                  {...register(`${baseName}.${index}.mailMagazineId` as Path<T>)}
                  style={{
                    backgroundColor: '#f9f9f9',
                    border: '1px solid #ccc',
                    borderRadius: '5px',
                    padding: '10px 32px 10px 12px',
                  }}
                  placeholder="選択してください"
                  isDisabled={
                    getValues('uneditableState' as Path<T>) ||
                    mailMagazineList.length === 0
                  }
                >
                  {mailMagazineList.map((item) => (
                    <option key={item.id} value={item.id}>
                      {`${item.title} ${item.targetType === 1 ? '[会員IDベース]' : '[メールアドレスベース]'}`}
                    </option>
                  ))}
                </Select>
              </Td>
            </Tr>
          </Tbody>
        </Table>
        <Table>
          <Tbody>
            <Tr>
              <Td w={145}>補足説明など</Td>
              <Td>
                <RichTextElement<T>
                  baseName={baseName}
                  placeholder=""
                  index={index}
                  position="descriptionBottom"
                  value={
                    getValues(`${baseName}.${index}.descriptionBottom` as Path<T>) ||
                    ''
                  }
                  setIsEdited={setIsEdited}
                />
              </Td>
            </Tr>
          </Tbody>
        </Table>
        <CondElement<T> baseName={baseName} index={index} />
      </VStack>
      <Toggle toggle={toggle} setToggle={setToggle} />
    </>
  );
};
