import { Input } from '@chakra-ui/react';
import { format } from 'date-fns';
import ja from 'date-fns/locale/ja';
import { FC, memo, useCallback, useEffect, useState } from 'react';
import DatePicker, { registerLocale } from 'react-datepicker';
import { FieldValues, UseFormRegister, UseFormSetValue } from 'react-hook-form';

registerLocale('ja', ja);

type Props = {
  inputSize?: 'sm' | 'md';
  fieldName: string;
  placeholder?: string;
  hasTime?: boolean;
  width?: number | string;
  register: UseFormRegister<FieldValues>;
  setValue: UseFormSetValue<FieldValues>;
  isCalendarReset?: boolean;
  setIsCalendarReset?: (args: boolean) => void;
};
/**
 * @see fieldNameに値をセットするカレンダー
 * @param fieldName 日付を設定するインプットのフィールド名
 * @param placeholder 初期説明テキスト
 * @param width インプットのサイズ、未指定でauto
 * @param register フォームの名前を登録メソッド
 * @param setValue フォームの値を設定するメソッド
 */
export const Calendar: FC<Props> = memo(
  ({
    inputSize = 'md',
    fieldName,
    placeholder,
    hasTime = true,
    width = 'auto',
    register,
    setValue,
    isCalendarReset,
    setIsCalendarReset,
  }) => {
    const DATETIME_FORMAT = hasTime ? 'yyyy-MM-dd HH:mm' : 'yyyy-MM-dd';
    const [myDate, setMyDate] = useState<Date | null>();

    const handleSetDate = useCallback(
      (date: Date | null) => {
        setMyDate(date);
        setValue(fieldName, date ? format(date, DATETIME_FORMAT) : null);
      },
      [setValue, fieldName, DATETIME_FORMAT],
    );

    useEffect(() => {
      if (!isCalendarReset || !setIsCalendarReset) return;
      handleSetDate(null);
      setIsCalendarReset(false);
    }, [handleSetDate, isCalendarReset, setIsCalendarReset]);

    return (
      <>
        <DatePicker
          selected={myDate}
          locale="ja"
          onChange={(date: Date) => handleSetDate(date)}
          placeholderText={placeholder}
          customInput={
            <Input
              width={width}
              style={{
                fontSize: inputSize === 'sm' ? '12px' : '14px',
                backgroundColor: '#fff',
              }}
              {...register(fieldName)}
            />
          }
          dateFormat={DATETIME_FORMAT}
          showTimeSelect={hasTime}
        />
      </>
    );
  },
);
