import { useCustomTool, useCustomToolOptions, useCustomToolRenderer, useCustomWidget, useRegisterCustomTool } from 'hooks/unlayer/config';

/**
 * unlayer editor 設定情報取得 hooks
 */
export const useUnlayerEditorConfig = (): {
  createCustomWidgetForCourse: (name: string) => string;
  createCustomWidgetForItem: (name: string) => string;
  createCustomWidgetForMember: (name: string) => string;
  createCustomTool: (name: string, label: string, icon: string) => string;
  createCustomToolCourseOption: () => string;
  createCustomToolClassOption: () => string;
  createCustomToolButtonTextOption: (defaultValue: string, hasColorPicker: boolean) => string;
  createCustomToolButtonStyleOption: (defaultBgColor: string, defaultHoverBgColor: string) => string;
  createCustomToolRenderer: (defaultClass: string, hasCourseId: boolean) => string;
  setRegisterCustomTool: (
    registerConfig: string,
    configOptions: string[],
    rendererConfig: string,
  ) => string;
} => {
  const { createCustomWidgetForCourse, createCustomWidgetForItem, createCustomWidgetForMember } = useCustomWidget();
  const { createCustomTool } = useCustomTool();
  const { createCustomToolCourseOption, createCustomToolClassOption, createCustomToolButtonTextOption, createCustomToolButtonStyleOption } = useCustomToolOptions();
  const { createCustomToolRenderer } = useCustomToolRenderer();
  const { setRegisterCustomTool } = useRegisterCustomTool();

  return {
    createCustomWidgetForCourse,
    createCustomWidgetForItem,
    createCustomWidgetForMember,
    createCustomTool,
    createCustomToolCourseOption,
    createCustomToolClassOption,
    createCustomToolButtonTextOption,
    createCustomToolButtonStyleOption,
    createCustomToolRenderer,
    setRegisterCustomTool,
  }
}
