import { request } from 'admin/api/Request';
import { CustomError } from 'admin/error/CustomError';
import { messages } from 'api/transactionMail/messages';
import { FirstLoginMailType } from 'api/transactionMail/type';
import { Options } from 'ky';
import { FirstLoginMailListResponsedSchema } from 'schema/transactionMail/index';
import { camelCase } from 'utils/str';
import { ZodError } from 'zod';

export const getSendingContentsList = async (
  tenantId: string,
  options?: Options,
): Promise<FirstLoginMailType[]> => {
  const path = `tenant/${tenantId}/transaction_mails_list/?type=start_service`;

  const response = await request<FirstLoginMailType[]>({
    path,
    method: 'get',
  });

  if (response.hasError) {
    if (response.status === 404) {
      throw new CustomError(
        messages.getSendingContentSettingsList.customer.notFound,
        {
          path,
          options,
          status: response.status,
        },
      );
    } else {
      throw new CustomError(
        messages.getSendingContentSettingsList.customer.error,
        {
          path,
          options,
          status: response.status,
        },
      );
    }
  }

  // メール一覧の各メール情報に含まれるtypeのデータを修正する
  const firstLoginMailListData = response.data.map((val) => ({
    ...val,
    type: camelCase(val.type),
  })) as FirstLoginMailType[];

  try {
    FirstLoginMailListResponsedSchema.parse(firstLoginMailListData);
  } catch (error) {
    if (error instanceof ZodError) {
      throw new CustomError(error.message, {
        path,
        options,
        status: response.status,
        logLevel: 'error',
      });
    }
  }

  return firstLoginMailListData;
};
