import { Options } from 'ky';
import { request } from 'api/request';
import { logger } from 'api/logger';
import { CustomError } from 'api/error/CustomError';
import { MeasurementResults, isMeasurementResultsList } from './types';
import { messages } from './messages';

// メールレポート一覧取得API
export const getMailReportList = async (
  tenantId: string,
  mailId: string,
  options?: Options,
): Promise<MeasurementResults[]> => {
  const path = `mail/${tenantId}/${mailId}/links/`;

  const response = await request({
    path,
    method: 'get',
    options,
  });

  const responseData = (await response.data) as unknown[];

  if (response.hasError) {
    throw new CustomError(messages.getMailReportList.customer.error);
  }

  if (!isMeasurementResultsList(responseData)) {
    await logger({
      loglevel:
        response.status === 404 || response.status === 450
          ? 'warning'
          : 'error',
      data: responseData,
      message: messages.getMailReportList.system.typeError,
    });

    throw new CustomError(messages.getMailReportList.customer.failed);
  }

  return responseData;
};
