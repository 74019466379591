import { VFC, memo, useState, useEffect } from 'react';
import { useFormContext } from 'react-hook-form';
import { Box, Flex, Text, Stack, Divider, Center } from '@chakra-ui/react';
import { InputTextForm, ColorPicker } from 'components/common/atoms';
import { SkinItemPreview } from 'components/enquete/atoms/SkinItemPreview';
import { Skin } from 'api/enquete/types';
import { SkinDefaultValues } from 'components/enquete/EnqueteSkin';

export const SkinBorder: VFC = memo(() => {
  const [ruledLineColor, setRuledLineColor] = useState(
    SkinDefaultValues.ruledLineColor,
  );
  const { getValues, watch } = useFormContext<Skin>();

  useEffect(() => {
    const skinViewData = watch((value, { name }) => {
      if (name === 'ruledLineColor') {
        setRuledLineColor(value[name] || '');
      }
    });

    return () => skinViewData.unsubscribe();
  }, [watch]);

  return (
    <Flex alignItems="stretch" mb={4} mt={4}>
      <Box minW="220px" mt="2px">
        <Text as="label">罫線</Text>
      </Box>
      <Stack minW="250px">
        <Flex alignItems="flex-end">
          <InputTextForm<Skin>
            label="罫線色"
            labelCaption="罫線色"
            name="ruledLineColor"
            value={getValues('ruledLineColor')}
            placeholder="#FFFFFF"
            mr={4}
          />
          <ColorPicker<Skin>
            name="ruledLineColor"
            value={getValues('ruledLineColor') || '#FFFFFF'}
            width="40px"
            height="40px"
          />
        </Flex>
      </Stack>
      <SkinItemPreview
        previewHeight="120px"
        element={
          <Box display="flex" alignItems="center" justifyContent="flex-start">
            <Box w="30%" minW="150px" pr="10px" padding="10px 8px" mr="2px">
              <Text as="h3" color="#000" fontSize="16px">
                設問タイトル
              </Text>
            </Box>
            <Center height="86px">
              <Divider
                orientation="vertical"
                opacity="1"
                borderLeftWidth="1px"
                bgColor={ruledLineColor}
                borderColor={ruledLineColor}
              />
            </Center>
            <Box w="70%" padding="10px 0 10px 18px" ml="2px">
              <Box border="1px solid #ddd" borderRadius="3px">
                <input
                  type="text"
                  style={{
                    backgroundColor: '#f9f9f9',
                    padding: '10px',
                    width: '100%',
                    borderColor: '#ddd',
                    outline: 'none',
                    fontSize: '16px',
                  }}
                />
              </Box>
            </Box>
          </Box>
        }
      />
    </Flex>
  );
});
