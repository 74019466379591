import { zodResolver } from '@hookform/resolvers/zod';
import { withSuspenseAndErrorBoundary } from 'admin/components/Error/WithErrorBoundary';
import { CallbackUrlConfirmFormComponent } from 'admin/components/service/auth/callbackUrlConfirmForm/CallbackUrlConfirmFormComponent';
import { saveSubmitFormSchema } from 'admin/schema/util';
import { AuthFormInputType } from 'admin/types/service/auth/form';
import { FC, memo, useCallback } from 'react';
import { FormProvider, useForm } from 'react-hook-form';

export type Props = {
  target: AuthFormInputType;
  onClose: () => void;
  onSubmit: (values: AuthFormInputType) => Promise<void>;
};

const callbackUrlConfirmForm: FC<Props> = memo(
  ({ onClose, onSubmit, target }) => {
    const formId = 'callbackUrlConfirmForm';

    const methods = useForm({
      defaultValues: {
        word: '',
      },
      mode: 'onBlur',
      resolver: zodResolver(saveSubmitFormSchema),
    });

    const onSubmitHandler = useCallback(async () => {
      onClose();
      await onSubmit(target);
    }, [onSubmit, onClose, target]);

    return (
      <FormProvider {...methods}>
        <CallbackUrlConfirmFormComponent
          formId={formId}
          onClose={onClose}
          onSubmit={onSubmitHandler}
          target={target}
        />
      </FormProvider>
    );
  },
);

export const CallbackUrlConfirmForm = withSuspenseAndErrorBoundary(
  callbackUrlConfirmForm,
);
