import { INITIAL_CURRENT_PER_PAGE, useCurrentPerPage } from 'admin/hooks/pagination/useCurrentPerPage';
import { Page } from 'api/common/types';
import { useMemo } from 'react';
import {
  useSearchParams
} from 'react-router-dom';

const INITIAL_CURRENT_PAGE = 1;

type Props = {
  page: Page;
  defaultPerPage?: number
};

export const usePaginationQuery = ({page, defaultPerPage = INITIAL_CURRENT_PER_PAGE}: Props): {
  query: {
    currentPage: number,
    currentPerPage: number
  },
  onPageChange: (next: number) => void;
  onChangePerPage: (perPage: number) => void;
} => {
  const [searchParams, setSearchParams] = useSearchParams();
  const currentPerPage = useCurrentPerPage(defaultPerPage);

  const queryCurrentPage = useMemo<number>(() => {
    if(!searchParams.get('page')) {
      return page.currentPage??INITIAL_CURRENT_PAGE;
    }

    // クエリパラメータで数値以外が渡された場合は内部的には `INITIAL_CURRENT_PAGE` の値とする
    // ※ページネーション自体の処理は続行できる状態とするため（URLの見た目がおかしくても）
    const currentPage = Number.isNaN(Number(searchParams.get('page'))) ? INITIAL_CURRENT_PAGE : Number(searchParams.get('page'));

    // クエリパラメータのページがページ範囲外の場合はデフォルト値を返却する
    return currentPage > page.pageCount || currentPage < 1 ? INITIAL_CURRENT_PAGE : currentPage;
  }, [searchParams, page]);

  const queryParams = useMemo(() => {
    const query = {};
    Object.assign(query, {page: queryCurrentPage, perPage: currentPerPage});

    return query;
  }, [queryCurrentPage, currentPerPage]);

  const onPageChange = (next: number) => {
    const urlQueryParams = new URLSearchParams(window.location.search);
    const paramsObject = Object.fromEntries(urlQueryParams.entries());

    const query = {...paramsObject};
    Object.assign(query, {...paramsObject, page: next});

    setSearchParams(query);
  }

  const onChangePerPage = (perPage: number) => {
    const query = queryParams;
    // 表示件数切り替え時は、強制的に表示ページを1ページに遷移させる
    Object.assign(query, {page: 1, perPage});

    setSearchParams(query);
  }

  return {
    query: {
      currentPage: queryCurrentPage,
      currentPerPage,
    },
    onPageChange,
    onChangePerPage
  }
}
