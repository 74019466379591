import { request } from 'admin/api/Request';
import { API_MESSAGES } from 'admin/define/apiMessages';
import { CustomError } from 'admin/error/CustomError';
import { idPoolConsumerListSchema } from 'admin/schema/idPoolConsumer';
import { IdPoolConsumerList } from 'admin/types/userPool';
import { Options } from 'ky';
import { ZodError } from 'zod';

export const getIdPoolConsumers = async ({
  id,
  options,
}: {
  id: string;
  options?: Options;
}): Promise<IdPoolConsumerList> => {
  const path = `uniikey/${id}/user_pool/`;

  const response = await request<IdPoolConsumerList>({
    path,
    method: 'get',
    options,
  });

  if (response.hasError) {
    if (response.status === 404) {
      throw new CustomError(
        API_MESSAGES.USER_POOL.GET_ID_POOL_CONSUMERS.NOT_FOUND,
        {
          path: 'dummy',
          status: response.status,
        },
      );
    } else {
      throw new CustomError(
        API_MESSAGES.USER_POOL.GET_ID_POOL_CONSUMERS.ERROR,
        {
          path: 'dummy',
          status: response.status,
        },
      );
    }
  }
  try {
    idPoolConsumerListSchema.parse(response.data);
  } catch (error) {
    if (error instanceof ZodError) {
      throw new CustomError(error.message, {
        path: 'dummy',
        status: response.status,
        logLevel: 'error',
      });
    }
  }

  return response.data;
};
