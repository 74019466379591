import produce from 'immer';
import { QueryClient } from 'react-query';
import { useFormContext } from 'react-hook-form';
import { ContentsDbTblFormType } from 'api/contents/types';
import { createContentsTable } from 'api/contents/createContentsTable';
import { updateContentsTable } from 'api/contents/updateContentsTable';
import { getValidationError, toMultiError, ValidationError } from 'utils/form';
import { successToast, errorToast } from 'utils/toast';
import { useCustomToast } from 'hooks/useCustomToast';
import { tblSchemaValidate } from 'components/contentsdb/molecules/SchemaCreateForm';
import { contentsDbQueryKey } from './queryKey';

export const useTableRegist = ({
  closeHandler,
  setGlobalError,
  tenantId,
  tableId,
}: {
  closeHandler: () => void;
  setGlobalError: (erros: string[]) => void;
  tenantId: string;
  tableId?: string;
}): ((
  rawData: ContentsDbTblFormType,
  queryClient: QueryClient,
) => Promise<void>) => {
  const { setError } = useFormContext<ContentsDbTblFormType>();
  const toast = useCustomToast();

  const regist = async (
    rawData: ContentsDbTblFormType,
    queryClient: QueryClient,
  ) => {
    // rawData は テーブル定義(表)以外のデータは
    // バリデーション済みですが、表はチェックができてません
    // 表はこのサブミット段階でチェックを行います
    const [registData, schemaErros] = tblSchemaValidate(rawData.tblSchema);

    // エラーがある場合はエラーメッセージを格納し終了
    if (schemaErros.length > 0) {
      setError('tblSchema', { types: toMultiError(schemaErros) });
      // toast表示
      toast({
        ...errorToast,
        title: '保存できませんでした',
        description: 'エラーをご確認ください',
      });
    } else {
      // チェックが完了したテーブル定義(表)を
      // フォームデータへ反映
      const formData = produce(rawData, (draft) => {
        draft.tblSchema = registData;
      });

      const response = tableId
        ? await updateContentsTable({ formData, tenantId, tableId })
        : await createContentsTable({ formData, tenantId });

      // 成功
      if (typeof response === 'boolean' && response) {
        const cacheTableDetail = contentsDbQueryKey.tableDetail({
          tenantId,
          tableId: tableId as string,
        });

        await queryClient.invalidateQueries(
          contentsDbQueryKey.tableList({ tenantId }),
        );
        await queryClient.invalidateQueries(cacheTableDetail);
        closeHandler();

        // toast表示
        const toastMessage = tableId ? '修正しました' : '保存しました';
        toast({
          ...successToast,
          title: toastMessage,
        });

        return;
      }

      const errorReplaceKey: Record<string, keyof ContentsDbTblFormType> = {
        pkCols: 'tblSchema',
      };

      const errorMessages =
        typeof response !== 'boolean'
          ? getValidationError<ContentsDbTblFormType>({
              formData,
              response,
              replaceKeys: errorReplaceKey,
            })
          : ({} as ValidationError<ContentsDbTblFormType>);

      Object.keys(errorMessages).forEach((k) => {
        const v =
          errorMessages[k as keyof ValidationError<ContentsDbTblFormType>];
        if (v) {
          if (k in formData) {
            const formKey = k as keyof ContentsDbTblFormType;
            setError(formKey, {
              types: toMultiError(v),
            });
          } else if (k === 'others') {
            setGlobalError(v);
          }
        }
      });
    }
  };

  return regist;
};
