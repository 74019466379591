import { Modal, useDisclosure } from '@chakra-ui/react';
import { zodResolver } from '@hookform/resolvers/zod';
import { LoginSetting } from 'api/tenant/types';
import { AlertBar } from 'components/common/atoms';
import { loginRedirectFormSchema } from 'components/setting/loginRedirect/index.schema';
import { LoginRedirectFormComponent } from 'components/setting/loginRedirect/loginRedirectForm/LoginRedirectFormComponent';
import { LoginRedirectModal } from 'components/setting/loginRedirect/loginRedirectModal';
import { useLoginSettingEdit } from 'hooks/tenant/useLoginSettingEdit';
import { FC, memo, useState } from 'react';
import { FormProvider, useForm } from 'react-hook-form';

type Props = {
  loginSetting: LoginSetting;
  onClose: () => void;
}

export const LoginRedirectForm: FC<Props> = memo(({loginSetting, onClose}: Props) => {
  const [globalErrors, setGlobalErrors] = useState<string[]>([]);
  const formId = 'userSettingLoginRedirect';
  const { isOpen: isModalOpen, onClose: onModalClose, onOpen: onModalOpen } = useDisclosure();
  const [modalPromise, setModalPromise] = useState<() => void>(() => null);
  const methods = useForm<LoginSetting>({
    defaultValues: {
      loginUrl: loginSetting?.loginUrl,
      signupUrl: loginSetting?.signupUrl,
      loginParam: loginSetting?.loginParam,
      loginOtherParam: loginSetting?.loginOtherParam,
    },
    resolver: zodResolver(loginRedirectFormSchema),
  });

  const { setError } = methods;
  const { onSubmit, isLoading } = useLoginSettingEdit({
    setError,
    setGlobalErrors,
    onClose
  });

  const onSubmitBtnHandler = async (data: LoginSetting) => {
    const promise = new Promise<void>((resolve) => {
      setModalPromise(() => resolve);
    });
    onModalOpen();
    await promise;
    onModalClose();
    onSubmit(data);
  };


  return (
    <FormProvider {...methods}>
      {globalErrors.map((err, idx) => (
        <AlertBar
          key={`global-err-idx${String(idx)}`}
          message={err}
          status="error"
          mb={4}
        />
      ))}
      <LoginRedirectFormComponent
        formId={formId}
        onSubmit={onSubmitBtnHandler}
        onClose={onClose}
      />
      <Modal
        isOpen={isModalOpen}
        onClose={onModalClose}
        isCentered
        closeOnOverlayClick={false}
        closeOnEsc={false}
        size="xl"
      >
        <LoginRedirectModal
          isLoading={isLoading}
          onClose={onModalClose}
          onSubmit={modalPromise}
        />
      </Modal>
    </FormProvider>
  );
});
