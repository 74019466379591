import {
  Button,
  IconButton,
  Menu,
  MenuButton,
  MenuButtonProps,
  MenuDivider,
  MenuItem,
  MenuList,
} from '@chakra-ui/react';
import { FC, memo } from 'react';

/**
 * 設定例
 * {
 *   DropDownMenuItemType: 'text';
 *   label: 'Download',
 *   onClick: () => console.log('Download'),
 *   icon: <DeleteIcon />,
 *   testId: 'item-delete',
 * },
 */
type DropDownMenuItemProps = {
  /** 'text' | 'divider' | string */
  menuItemType: string;
  label?: string | JSX.Element;
  onClick?: () => void;
  icon?: JSX.Element;
  testId?: string;
  children?: React.ReactNode;
  isDisabled?: boolean;
};

/**
 * 設定例
 *
 * 枠線削除
 * {
 *   width: 8,
 *   // 枠線や背景色削除
 *   variant: 'none',
 *   icon: <BsThreeDotsVertical />
 * }
 *
 * // アイコンメニュー
 * <DropDownMenu
 *   menuType="icon"
 *   items={menuItems}
 *   icon={<BsThreeDotsVertical />}
 *   variant="none"
 *   width={36}
 * />
 *
 * // ボタンメニュー
 * <DropDownMenu
 *   menuType="button"
 *   buttonElement="メニュー"
 *   items={menuItems}
 *   variant="solid"
 * />
 *
 */
type DropDownMenuProps = {
  menuType: 'icon' | 'button';
  items: DropDownMenuItemProps[];
  icon?: JSX.Element;
  buttonElement?: string | React.ReactNode;
  variant?: 'outline' | 'none' | 'solid';
  zIndex?: string;
} & MenuButtonProps;

export const DropDownMenu: FC<DropDownMenuProps> = memo(
  ({
    menuType,
    items,
    icon,
    buttonElement,
    variant,
    width,
    zIndex = '',
    ...menuBtnProps
  }) => (
    <Menu data-testid="drop-down-menu">
      {menuType === 'icon' ? (
        <MenuButton
          as={IconButton}
          icon={icon}
          variant={variant}
          {...menuBtnProps}
        />
      ) : (
        <MenuButton as={Button} {...menuBtnProps}>
          {buttonElement}
        </MenuButton>
      )}

      <MenuList
        minW={width}
        width={width}
        data-testid="drop-down-menu-list"
        zIndex={zIndex}
      >
        {items.map((m, idx) => {
          if (m.menuItemType === 'text') {
            return (
              <MenuItem
                fontSize="sm"
                size="xs"
                key={`menu-item-${String(idx)}`}
                onClick={m.onClick}
                icon={m?.icon}
                data-testid={m?.testId || 'drop-down-menu-list-item'}
                isDisabled={m?.isDisabled}
                isFocusable={m?.isDisabled}
              >
                {m.label}
              </MenuItem>
            );
          }
          if (m.menuItemType === 'divider') {
            return <MenuDivider key={`divider-${String(idx)}`} />;
          }

          if (m.menuItemType === 'children') {
            return (
              <MenuItem
                fontSize="sm"
                size="xs"
                key={`menu-item-${String(idx)}`}
                onClick={m.onClick}
                icon={m?.icon}
                data-testid={m?.testId || 'drop-down-menu-list-item'}
              >
                {m.children}
              </MenuItem>
            );
          }

          return (
            <MenuItem fontSize="sm" size="xs" key={`menu-item-${String(idx)}`}>
              {m.children}
            </MenuItem>
          );
        })}
      </MenuList>
    </Menu>
  ),
);
