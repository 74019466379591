import { Badge, Flex, Text, VStack } from '@chakra-ui/react';
import { withSuspenseAndErrorBoundary } from 'admin/components/Error/WithErrorBoundary';
import { Colors } from 'admin/define/colors';
import { IdPoolConsumerListItem } from 'admin/types/userPool';
import { ErrorContents } from 'components/common/atoms';
import { FC, memo } from 'react';
import { useNavigate } from 'react-router-dom';

type Props = {
  idPoolConsumer: IdPoolConsumerListItem;
};

export const memberIdPoolInfo: FC<Props> = memo(({ idPoolConsumer }: Props) => {
  // TODO: テナントのAPI待ちの為、一時的にコメントアウト
  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  const navigate = useNavigate();

  return (
    <VStack
      mt="8px"
      mb="24px"
      p={4}
      backgroundColor={Colors.GRAY_70}
      borderRadius="8px"
      alignItems="flex-start"
    >
      <Flex>
        <Text minW="168px" mr="16px" color={Colors.GRAY_00}>
          ユーザープールID
        </Text>
        <Text fontWeight="700">{idPoolConsumer.id}</Text>
      </Flex>
      <Flex>
        <Text minW="168px" mr="16px" color={Colors.GRAY_00}>
          ユーザープール名
        </Text>
        <Text fontWeight="700">{idPoolConsumer.name}</Text>
      </Flex>
      <Flex>
        <Text minW="168px" mr="16px" color={Colors.GRAY_00}>
          使用しているテナント
        </Text>
        <Flex flexWrap="wrap" css={{ gap: '6px 8px' }}>
          {idPoolConsumer.serviceNames.map((serviceName, cIdx) => (
            <Badge
              key={`userPool_serviceName_${serviceName}_${String(cIdx)}}`}
              bgColor={Colors.BLUE_LIGHT_01}
              fontWeight={400}
              px={2}
              borderRadius="6px"
              fontSize="14px"
              // TODO: テナントのAPI待ちの為、一時的にコメントアウト
              // onClick={() => navigate(`/admin/member/service/${serviceName}`)}
              // cursor="pointer"
            >
              {serviceName}
            </Badge>
          ))}
        </Flex>
      </Flex>
    </VStack>
  );
});

export const MemberIdPoolInfo = withSuspenseAndErrorBoundary(memberIdPoolInfo, {
  ErrorComponent: <ErrorContents name="会員管理ユーザープール情報" />,
});
