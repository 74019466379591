import { useState } from 'react';
import { UseMutateFunction, useMutation } from 'react-query';
import { UseFormSetError } from 'react-hook-form';
import { useNavigate } from 'react-router-dom';
import { putMail } from 'api/mail/putMail';
import { ResponseError } from 'api/types';
import {
  EditMailForm,
  MailEditorForm,
  isMailSetting,
  MailEditorFormResponse,
} from 'api/mail/types';
import { isGetValidationError, toMultiError } from 'utils/form';
import { useCustomToast } from 'hooks/useCustomToast';
import { useMailUtil } from 'hooks/mail/useMailUtil';

type UnPromisify<T> = T extends Promise<infer U> ? U : T;

const formData: MailEditorForm = {
  id: '',
  tenant: '',
  subject: '',
  editorHtml: '',
  editorText: '',
  bodyHtml: '',
  bodyText: '',
};

/**
 * メール修正 hooks
 * @param {Object} UseFormSetError setError
 * @param {setGlobalErrors} callback setGlobalErrors
 * @returns {Object} { onSubmit, isLoading }
 */
export const useEditMail = ({
  setError,
  setNextPath,
}: {
  setError: UseFormSetError<MailEditorForm>;
  setNextPath: (url: string) => void;
}): {
  onSubmit: UseMutateFunction<
    UnPromisify<ReturnType<typeof putMail>>,
    unknown,
    EditMailForm,
    unknown
  >;
  isLoading: boolean;
  isWarning: boolean;
  warningMsg: {
    filename: string;
    placeholders: string;
    shortagePlaceholders: string;
    excessPlaceholders: string;
  };
} => {
  const { MailFormRefetchListQueries } = useMailUtil();
  const toast = useCustomToast();
  const navigate = useNavigate();
  const [isWarning, setIsWarning] = useState(false);
  const [warningMsg, setWarningMsg] = useState({
    filename: '',
    placeholders: '',
    shortagePlaceholders: '',
    excessPlaceholders: '',
  });

  const { mutate: onSubmit, isLoading } = useMutation(
    (EditMailFormData: EditMailForm) => putMail(EditMailFormData),
    {
      onSuccess: (result: MailEditorFormResponse | ResponseError) => {
        MailFormRefetchListQueries();

        if (isMailSetting(result)) {
          toast({
            status: 'success',
            position: 'bottom',
            duration: 4000,
            isClosable: true,
            title: 'メールを修正しました',
          });
          const url = `/mail/`;
          // この時、バックエンド側から注意文が帰ってきた時
          // /mailへ遷移せず、Dialogを表示してから遷移を行う
          // 遷移処理についてはMailFormInner側で実行する

          const {
            addressFileOrigin,
            isReplaceWord,
            isReplaceWordMismatch,
            placeholders,
            shortagePlaceholders,
            excessPlaceholders,
          } = result;
          // 差し込み文字が存在している かつ csvファイル未アップロード時
          if (isReplaceWord && addressFileOrigin === null) {
            setIsWarning(true);
            setWarningMsg({
              filename: '',
              placeholders: placeholders || '',
              shortagePlaceholders: '',
              excessPlaceholders: '',
            });
            setNextPath(url);

            // csvファイルアップロード済み かつ 差し込み文字に差分が存在する場合
          } else if (isReplaceWordMismatch) {
            setIsWarning(true);
            setWarningMsg({
              filename: addressFileOrigin || '',
              placeholders: '',
              shortagePlaceholders: shortagePlaceholders || '',
              excessPlaceholders: excessPlaceholders || '',
            });
            setNextPath(url);

            // 差し込み文字が存在しない場合は、今まで通りの処理を行う
          } else {
            navigate(url);
          }
        }

        if (isGetValidationError<EditMailForm>(result, Object.keys(formData))) {
          if (result?.others) {
            const errorMsg = Object.entries(result).map(([_, value]) => value);
            toast({
              status: 'error',
              position: 'bottom',
              duration: 4000,
              isClosable: true,
              description: errorMsg[0],
            });
          } else {
            Object.keys(result).forEach((k) => {
              const key = k as keyof EditMailForm;
              const errMsgs = result?.[key];
              if (errMsgs) {
                setError(key, { types: toMultiError(errMsgs) });
              }
            });
          }
        }
      },
    },
  );

  return { onSubmit, isLoading, isWarning, warningMsg };
};
