import { VFC, memo, useCallback, useState } from 'react';
import { useDisclosure, Box, Heading, Text } from '@chakra-ui/react';
import { MailInfo, MeasurementResults } from 'api/mail/types';
import { getMailReportList } from 'api/mail/getMailReportList';
import { useCopy } from 'hooks/useCopy';
import { useUserTenantId } from 'hooks/user/useUserTenantId';
import { DrawerForm } from 'components/common/atoms';
import { MailReportDrawerHeader } from 'components/mail/molecules/MailReportDrawerHeader';
import { MailReportDrawerBody } from 'components/mail/molecules/MailReportDrawerBody';

type MailReportDrawerFormProps = {
  mailInfo: MailInfo;
  buttonName: string;
};

export const MailReportDrawerForm: VFC<MailReportDrawerFormProps> = memo(
  ({ mailInfo, buttonName }: MailReportDrawerFormProps) => {
    const { isOpen, onOpen, onClose } = useDisclosure();
    const tenantId = useUserTenantId();
    const { copy } = useCopy();
    const [reportList, setReportList] = useState<MeasurementResults[]>([]);
    const formId = 'userEdit-form';

    // レポートボタン押下時
    const onDelivaryMailReport = useCallback(async () => {
      const list = await getMailReportList(tenantId, mailInfo.id);
      setReportList(list);
      onOpen();
    }, [onOpen, tenantId, mailInfo, setReportList]);

    // csv形式のデータ作成
    const createCsv = useCallback((copyData: MeasurementResults[]): string => {
      let text = '';
      // カラム設定
      text += `リンク先記事タイトル\t`;
      text += `URL\t`;
      text += `リンククリック数\t`;
      text += `リンククリック率\t`;
      text += `掲載順番\t`;
      text += `配信数`;

      // データ挿入
      copyData.forEach((item) => {
        text += `\r\n`;
        text += `${item.linkTitle || ''}\t`;
        text += `${item.url}\t`;
        text += `${item.clickCnt}\t`;
        text += `${item.clickRate}%\t`;
        text += `${item.postingOrder}\t`;
        text += `${item.deliveryCnt}`;
      });

      return text;
    }, []);

    // コピー対象となる文字列を作成しコピー処理
    const clipBoardCopyText = useCallback(
      (copyData: MeasurementResults[]): void => {
        copy(createCsv(copyData));
      },
      [copy, createCsv],
    );

    // csvダウンロード
    const downloadCsv = useCallback(
      (copyData: MeasurementResults[]): void => {
        const text = createCsv(copyData);
        const fileName = `${mailInfo.subject}_メールリンク計測一覧.csv`;
        const data = new Blob([text], { type: 'text/csv' });
        const jsonURL = window.URL.createObjectURL(data);
        const link = document.createElement('a');
        document.body.appendChild(link);
        link.href = jsonURL;
        link.setAttribute('download', fileName);
        link.click();
        document.body.removeChild(link);
      },
      [createCsv, mailInfo],
    );

    return (
      <DrawerForm
        title=""
        placement="bottom"
        openBtnChildNode={<Text as="span">{buttonName}</Text>}
        openBtnProps={{
          bgColor: 'white',
          borderWidth: '1px',
        }}
        isOpen={isOpen}
        onOpen={onDelivaryMailReport}
        onClose={onClose}
        closeOnOverlayClick={false}
        closeOnEsc={false}
        autoFocus={false}
        size="md"
        formId={formId}
        isFooter={false}
      >
        <Box p={4}>
          <Heading
            as="h2"
            size="md"
            pb={2}
            mb={4}
            w="100%"
            borderBottom="2px solid #4299e1"
          >
            メールリンク計測一覧
          </Heading>
          <MailReportDrawerHeader
            mailInfo={mailInfo}
            list={reportList || []}
            download={downloadCsv}
            copy={clipBoardCopyText}
          />

          <MailReportDrawerBody
            list={reportList || []}
            status={mailInfo.status}
            copy={clipBoardCopyText}
          />
        </Box>
      </DrawerForm>
    );
  },
);
