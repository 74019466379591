import { request } from 'admin/api/Request';
import { API_MESSAGES } from 'admin/define/apiMessages';
import { CustomError } from 'admin/error/CustomError';
import { attributeGroupSchema } from 'admin/schema/idPoolConsumer/attributeGroup';
import { UserPool } from 'admin/types/userPool';
import { CreateAttributeGroupFormType } from 'admin/types/userPool/attributeGroup/form';
import { isResponseError } from 'api/types';
import { getValidationError } from 'utils/form';
import { ZodError } from 'zod';

export const createAttributeGroup = async (
  data: CreateAttributeGroupFormType,
  id: UserPool['id'],
): Promise<CreateAttributeGroupFormType> => {
  const params = {
    ...data,
    displayNameForeign:
      data?.displayNameForeign !== '' && data?.displayNameForeign
        ? data?.displayNameForeign
        : undefined,
    displayOrder: Number(data?.displayOrder)
      ? Number(data?.displayOrder)
      : undefined,
    attributeGroupBodyList: data.attributeGroupBodyList.filter(
      (item) => item.userAttributeSetting,
    ),
  };

  const path = `uniikey/${id}/attribute_group/`;
  const response = await request<CreateAttributeGroupFormType>({
    path,
    body: params,
    method: 'post',
  });
  if (response.hasError) {
    if (response.status === 404) {
      throw new CustomError(
        API_MESSAGES.USER_POOL.CREATE_ATTRIBUTE_GROUP.FAILED,
        {
          path,
          params: data,
          status: response.status,
        },
      );
    } else if (isResponseError(response.error)) {
      const customError = response.error;

      const formError = getValidationError<CreateAttributeGroupFormType>({
        formData: data,
        response: customError,
      });

      throw new CustomError(
        API_MESSAGES.USER_POOL.CREATE_ATTRIBUTE_GROUP.ERROR,
        {
          path,
          params: data,
          errors: formError,
          status: response.status,
          logLevel: response.status === 400 ? 'warning' : 'error',
        },
      );
    } else {
      throw new CustomError(
        API_MESSAGES.USER_POOL.CREATE_ATTRIBUTE_GROUP.ERROR,
        {
          path,
          params: data,
          status: response.status,
        },
      );
    }
  }
  try {
    attributeGroupSchema.parse(response.data);
  } catch (error) {
    if (error instanceof ZodError) {
      throw new CustomError(error.message, {
        path,
        params: data,
        status: response.status,
        logLevel: 'error',
      });
    }
  }

  return response.data;
};
