import { Text } from '@chakra-ui/react';
import { withSuspenseAndErrorBoundary } from 'admin/components/Error/WithErrorBoundary';
import { ServiceMembersTableComponent } from 'admin/components/member/service/serviceMembersTable/ServiceMembersTableComponent';
import { useServiceMembers } from 'admin/hooks/service/useServiceMembers';
import { Page } from 'api/common/types';
import { ErrorContents } from 'components/common/atoms';
import {
  Dispatch,
  FC,
  memo,
  SetStateAction,
  // useCallback,
  useEffect,
  useMemo,
} from 'react';
import { useParams } from 'react-router-dom';

type Props = {
  currentPage: number;
  perPage: number;
  keyword: string;
  setPage: Dispatch<SetStateAction<Page>>;
  // TODO: 9月末のリリース時点では必要では無い為、コメントアウト
  // setRefetchFunc: Dispatch<SetStateAction<() => void>>;
};

const serviceMembersTable: FC<Props> = memo(
  ({
    currentPage,
    perPage,
    keyword,
    setPage,
  }: // TODO: 9月末のリリース時点では必要では無い為、コメントアウト
  // setRefetchFunc
  Props) => {
    // TODO: 9月末のリリース時点では必要では無い為、コメントアウト
    // const [isLoading, setLoading] = useState<boolean>(false);
    const params = useParams();
    const serviceId = useMemo(() => params.serviceId ?? '', [params.serviceId]);
    const {
      data: members,
      page,
      // TODO: 9月末のリリース時点では必要では無い為、コメントアウト
      // refetch,
    } = useServiceMembers(serviceId, currentPage, perPage, keyword);

    useEffect(() => {
      setPage(page);
    }, [setPage, page]);

    // useCallbackの中にさらに無名関数をセット（非同期処理の兼ね合いで）
    // const onRefetch = useCallback(
    //   () => () => {
    //     setLoading(true);
    //     refetch().finally(() => setLoading(false));
    //   },
    //   [refetch],
    // );

    // useEffect(() => {
    //   setRefetchFunc(onRefetch);
    // });

    if (!members) {
      return <Text>会員一覧データがありません。</Text>;
    }

    // if (isLoading) {
    //   return <LoadingSkeleton />;
    // }

    return <ServiceMembersTableComponent members={members} />;
  },
);

export const ServiceMembersTable = withSuspenseAndErrorBoundary(
  serviceMembersTable,
  {
    ErrorComponent: <ErrorContents name="会員一覧情報" />,
  },
);
