import { VFC, memo, useState, useEffect } from 'react';
import { useFormContext } from 'react-hook-form';
import { Box, Flex, Text, Stack } from '@chakra-ui/react';
import { InputTextForm, ColorPicker } from 'components/common/atoms';
import { SkinItemPreview } from 'components/enquete/atoms/SkinItemPreview';
import { Skin } from 'api/enquete/types';
import { SkinDefaultValues } from 'components/enquete/EnqueteSkin';

export const SkinSubmitButton: VFC = memo(() => {
  const [submitButtonTextColor, setSubmitButtonTextColor] = useState(
    SkinDefaultValues.submitButtonTextColor,
  );
  const [submitButtonBgColor, setSubmitButtonBgColor] = useState(
    SkinDefaultValues.submitButtonBgColor,
  );
  const { getValues, watch } = useFormContext<Skin>();

  useEffect(() => {
    const skinViewData = watch((value, { name }) => {
      if (name === 'submitButtonTextColor') {
        setSubmitButtonTextColor(value[name] || '');
      }
      if (name === 'submitButtonBgColor') {
        setSubmitButtonBgColor(value[name] || '');
      }
    });

    return () => skinViewData.unsubscribe();
  }, [watch]);

  return (
    <Flex alignItems="stretch" mb={4} mt={4}>
      <Box minW="220px" mt="2px">
        <Text as="label">送信ボタン</Text>
      </Box>
      <Stack minW="250px">
        <Flex alignItems="flex-end">
          <InputTextForm<Skin>
            label="背景色"
            labelCaption="背景色"
            name="submitButtonBgColor"
            value={getValues('submitButtonBgColor')}
            placeholder="#FFFFFF"
            mr={4}
          />
          <ColorPicker<Skin>
            name="submitButtonBgColor"
            value={getValues('submitButtonBgColor') || '#FFFFFF'}
            width="40px"
            height="40px"
          />
        </Flex>
        <Flex alignItems="flex-end">
          <InputTextForm<Skin>
            label="文字色"
            labelCaption="文字色"
            name="submitButtonTextColor"
            value={getValues('submitButtonTextColor')}
            placeholder="#FFFFFF"
            mr={4}
          />
          <ColorPicker<Skin>
            name="submitButtonTextColor"
            value={getValues('submitButtonTextColor') || '#FFFFFF'}
            width="40px"
            height="40px"
          />
        </Flex>
      </Stack>
      <SkinItemPreview
        element={
          <Box
            w="100%"
            h="45px"
            borderRadius="4px"
            boxShadow="0 1px 4px rgb(0 0 0 / 20%)"
            fontSize="14px"
            p="12px 20px"
            textAlign="center"
            color={submitButtonTextColor}
            bgColor={submitButtonBgColor}
          >
            送信
          </Box>
        }
        width="calc(50% - 8px)"
      />
    </Flex>
  );
});
