import { Box } from '@chakra-ui/react';
import { Colors } from 'components/paywall/config';
import { FC, memo } from 'react';

type Props = {
  children: React.ReactNode;
}

export const CardItems: FC<Props> = memo(({children}: Props) => (
  <Box
    className="paywall-card-body"
    bg={Colors.WHITE}
    padding="16px 24px"
    borderRadius="0 0 8px 8px"
  >
    {children}
  </Box>
))
