/* eslint-disable react/require-default-props */
import {
  Box,
  FormControl,
  FormLabel,
  Select,
  SelectProps,
} from '@chakra-ui/react';
import { ErrorTextMsg, RequireTag } from 'components/common/atoms';
import { VFC } from 'react';
import {
  FieldValues,
  Path,
  RegisterOptions,
  useController,
  useFormContext,
} from 'react-hook-form';
import { toErrMsgList } from 'utils/form';

/**
 * SelectFormProps
 */
type SelectFormProps<T> = {
  name: Path<T>;
  label?: string;
  labelFontSize?: string;
  labelFontColor?: string;
  labelElement?: JSX.Element | undefined;
  errorPos?: string;
  attr: RegisterOptions;
  requireTag?: boolean;
  optionDefault?: string;
  optionList: JSX.Element[];
} & Pick<
  SelectProps,
  'placeholder' | 'bgColor' | 'borderColor' | 'isDisabled' | 'width'
>;

export const SelectForm = <T extends FieldValues>({
  name,
  label = '',
  labelFontSize = 'sm',
  labelFontColor = '',
  labelElement = undefined,
  placeholder = '',
  errorPos = 'bottom',
  bgColor = '',
  borderColor = 'inherit',
  width = '100%',
  isDisabled = false,
  attr,
  requireTag = false,
  optionDefault,
  optionList,
}: SelectFormProps<T>): ReturnType<VFC> => {
  const { control } = useFormContext<T>();

  const {
    field: { ...formRest },
    fieldState: { error },
  } = useController({
    name,
    control,
    rules: { ...attr },
  });

  return (
    <FormControl>
      {/* アンケート作成画面用 */}
      {errorPos === 'top' && (
        <Box mb={4} textAlign="left">
          {toErrMsgList({ [name]: error }, name).map((err, idx) => (
            <ErrorTextMsg
              fontSize="1rem"
              key={`error-${name}-${String(idx)}`}
              msg={err}
            />
          ))}
        </Box>
      )}
      {(label !== '' || labelElement) && (
        <FormLabel
          htmlFor={name}
          fontSize={labelFontSize}
          color={labelFontColor}
        >
          {label}
          {labelElement && labelElement}
          {requireTag && <RequireTag />}
        </FormLabel>
      )}
      <Select
        id={name}
        placeholder={placeholder}
        {...formRest}
        bgColor={bgColor}
        borderColor={borderColor}
        isDisabled={isDisabled}
        width={width}
      >
        {optionDefault && <option value="">選択してください。</option>}
        {optionList}
      </Select>
      {errorPos === 'bottom' &&
        toErrMsgList({ [name]: error }, name).map((err, idx) => (
          <ErrorTextMsg key={`error-${name}-${String(idx)}`} msg={err} />
        ))}
    </FormControl>
  );
};
