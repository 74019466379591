import { BoxProps, Th as ChakraTh } from '@chakra-ui/react';
import { FC, memo, ReactNode } from 'react';

type Props = {
  children: ReactNode;
} & BoxProps;

export const ThWithBorder: FC<Props> = memo(({ children, ...rest }: Props) => (
  <ChakraTh
    py="10px"
    px="16px"
    {...rest}
    _notLast={{
      borderRightWidth: '1px',
      borderRightStyle: 'solid',
      borderRightColor: '#B9B9B9',
    }}
  >
    {children}
  </ChakraTh>
));
