import { request } from 'admin/api/Request';
import { API_MESSAGES } from 'admin/define/apiMessages';
import { CustomError } from 'admin/error/CustomError';
import { tenantUsersInviteFormType } from 'admin/types/tenantUsers/form';
import { isResponseError } from 'api/types';
import { getValidationError } from 'utils/form';

export const inviteTenantUsers = async (
  data: tenantUsersInviteFormType,
  corporationId: string,
) => {
  const path = `user/corporation/${corporationId}/tenants/user/invite/`;
  const response = await request({
    path,
    body: data,
    method: 'post',
  });
  if (response.hasError) {
    if (response.status === 404) {
      throw new CustomError(
        API_MESSAGES.TENANT_USERS.INVITE_TENANT_USERS.FAILED,
        {
          path,
          params: data,
          status: response.status,
        },
      );
    } else if (isResponseError(response.error)) {
      const customError = response.error;

      const formError = getValidationError<tenantUsersInviteFormType>({
        formData: data,
        response: customError,
      });

      throw new CustomError(
        API_MESSAGES.TENANT_USERS.INVITE_TENANT_USERS.ERROR,
        {
          path,
          params: data,
          errors: formError,
          status: response.status,
          logLevel: response.status === 400 ? 'warning' : 'error',
        },
      );
    } else {
      throw new CustomError(
        API_MESSAGES.TENANT_USERS.INVITE_TENANT_USERS.ERROR,
        {
          path,
          params: data,
          status: response.status,
        },
      );
    }
  }

  return response;
};
