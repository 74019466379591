import { Suspense, VFC, memo, useEffect, useState } from 'react';
import { useNavigate, useLocation } from 'react-router-dom';
import ErrorBoundary from 'api/ErrorBoundary';
import { useErrorBoundaryReset } from 'hooks/useErrorBoundaryReset';
import {
  Box,
  List,
  ListItem,
  Button,
  Icon,
  Text,
  Flex,
  Tooltip,
} from '@chakra-ui/react';
import { WarningTwoIcon, RepeatIcon } from '@chakra-ui/icons';
import { MdPlaylistAdd } from 'react-icons/md';
import { useSegmentList } from 'hooks/segment/useSegmentList';
import { SideMenuLink, LoadingSkeleton } from 'components/common/atoms';

const SegmentListError: VFC = memo(() => (
  <Box display="flex" alignItems="center" justifyContent="space-between" pr={4}>
    <Box ml={1} display="flex" alignItems="center">
      <WarningTwoIcon color="yellow.600" />
      <Box ml={2}>セグメント取得エラー</Box>
    </Box>
    <Tooltip label="リロード" fontSize="xs" bg="gray.600">
      <RepeatIcon cursor="pointer" onClick={() => window.location.reload()} />
    </Tooltip>
  </Box>
));

export const SegmentSide: VFC = memo(() => {
  const navigate = useNavigate();
  const { ebKey, onError } = useErrorBoundaryReset();
  const location = useLocation();
  const [currentPath, setCurrentPath] = useState('');
  const [searchPath, setSearchPath] = useState('');
  const segmentList = useSegmentList();

  useEffect(() => {
    setCurrentPath(location.pathname);
    setSearchPath(location.search);
  }, [location]);

  return (
    <Box>
      <Button
        variant="ghost"
        color="blue.400"
        onClick={() => navigate('/segment/form/')}
        mb={2}
      >
        <Flex alignItems="center">
          <Icon as={MdPlaylistAdd} fontSize={20} mr={2} />
          <Text as="span">セグメント追加</Text>
        </Flex>
      </Button>
      <ErrorBoundary
        errorComponent={<SegmentListError />}
        onError={onError}
        key={ebKey.current}
      >
        <Suspense fallback={<LoadingSkeleton />}>
          <List mr={4} h="100%" maxH="calc(100% - 32px)">
            {(segmentList || []).map((item) => (
              <ListItem
                key={item.id}
                _hover={{ bg: 'gray.50' }}
                display="flex"
                justifyContent="space-between"
                width="100%"
              >
                <SideMenuLink
                  name={item.name}
                  path={`/segment/form/?segmentId=${item.id}`}
                  isSelected={
                    currentPath === `/segment/form/` &&
                    searchPath === `?segmentId=${item.id}`
                  }
                />
              </ListItem>
            ))}
          </List>
        </Suspense>
      </ErrorBoundary>
    </Box>
  );
});
