import { useServices } from 'admin/hooks/service/useServices';
import { AUTH_CODE_TYPE_UNIIKEY } from 'define';
import { useMemo } from 'react';
import { useParams } from 'react-router-dom';

export const useHasUniikey = (): {
  hasUniikey: boolean;
} => {
  const params = useParams();
  const tenantId = useMemo(() => params.tenantId ?? '', [params]);
  const { data: tenants } = useServices();

  const hasUniikey = useMemo<boolean>(() => {
    if (!tenants) {
      return false;
    }

    const authenticationType = tenants.find(
      (tenant) => tenant.id === tenantId,
    )?.authenticationType;

    return authenticationType === AUTH_CODE_TYPE_UNIIKEY;
  }, [tenants, tenantId]);

  return {
    hasUniikey,
  };
};
