import { logger } from 'api/logger';
import { request } from 'api/request';
import { isEnqueteResponseError, ResponseError } from 'api/types';
import { isMailSettingPersonal, MailSettingPersonal, MailSettingPersonalForm } from 'components/paywall/pages/MailSetting/typed';
import produce from 'immer';
import { Options } from 'ky';
import { getValidationError, ValidationError } from 'utils/form';
import { snakeCase } from 'utils/str';
import { messages } from './messages';

const DEFAULT_ERR_MESSAGE: ValidationError<MailSettingPersonal> = {
  others: [messages.patchMailSettingPersonal.customer.error],
};
const DEFAULT_SYSTEM_ERR_MESSAGE: ValidationError<MailSettingPersonal> = {
  others: [messages.patchMailSettingPersonal.customer.failed],
};

/**
 * ペイウォールメール設定ステータス更新 API
 * @param {string} tenantId テナントID
 * @param {MailSettingPersonalForm} formParam API送信パラメータ
 * @param {Options} options APIオプション
 * @return {Promise<MailSettingPersonal | ResponseError>} ペイウォールメール設定情報
 */
export const patchMailSettingPersonalActivate = async (
  tenantId: string,
  formParam: MailSettingPersonalForm,
  type: string,
  options?: Options,
): Promise<MailSettingPersonal | ResponseError> => {
  const path = `paywall/${tenantId}/mail/${snakeCase(type)}/`;
  const param = { status: formParam.status };

  const mergedOptions = options
    ? produce(options, (draft) => {
        draft.json = { ...param };
      })
    : { json: param };

  const response = await request({
    path,
    method: 'patch',
    options: mergedOptions,
  });

  if (response.status >= 500) {
    return DEFAULT_ERR_MESSAGE;
  }

  if (response.status === 403) {
    return DEFAULT_SYSTEM_ERR_MESSAGE;
  }

  const responseData = response.data;

  // ペイウォールメール設定更新成功
  if (isMailSettingPersonal(responseData)) {
    return responseData;
  }

  const { error } = response;

  if (response.hasError) {
    // APIにて返却されたエラー形式
    if (isEnqueteResponseError(error)) {
      const formError = getValidationError<MailSettingPersonalForm>({
        formData: formParam,
        response: error,
      });

      return formError;
    }
  }

  // 予期せぬエラーなので
  // バックエンドに状態を通知
  await logger({
    loglevel:
      response.status === 404 || response.status === 450 ? 'warning' : 'error',
    data: error,
    message: messages.patchMailSettingPersonal.system.typeError,
  });

  // 登録できていない旨を通知
  return DEFAULT_SYSTEM_ERR_MESSAGE;
};
