import { CustomError } from 'api/error/CustomError';
import { logger } from 'api/logger';
import { request } from 'api/request';
import {
  isWallDesignList,
  WallDesignListType,
} from 'components/paywall/pages/WallDesignList/typed';
import { Options } from 'ky';
import { messages } from './messages';

/**
 * ペイウォールデザイン一覧取得 API
 * @param {string} tenantId テナントID
 * @param {string} wallId ウォールID
 * @param {Options} options APIオプション
 * @return {Promise<WallDesignListType>} ペイウォールデザイン一覧
 */
export const getWallDesignList = async ({
  tenantId,
  wallId,
  param,
  options,
}: {
  tenantId: string;
  wallId: string;
  param: string;
  options?: Options;
}): Promise<WallDesignListType> => {
  if (!wallId)
    return {
      count: 0,
      countFrom: 0,
      countTo: 0,
      currentPage: 0,
      next: '',
      pageCount: 0,
      perPage: 0,
      previous: '',
      results: [],
    };

  const path = `paywall/${tenantId}/${wallId}/design/?${param}`;
  const response = await request({
    path,
    method: 'get',
    options,
  });

  const responseData = (await response.data) as unknown[];

  if (response.hasError) {
    if (response.status === 404) {
      throw new CustomError(
        messages.getWallDesignList.customer.notFound,
        response.status,
      );
    } else {
      throw new CustomError(messages.getWallDesignList.customer.error);
    }
  }

  if (!isWallDesignList(responseData)) {
    await logger({
      loglevel:
        response.status === 404 || response.status === 450
          ? 'warning'
          : 'error',
      data: responseData,
      message: messages.getWallDesignList.system.typeError,
    });

    throw new CustomError(messages.getWallDesignList.customer.failed);
  }

  return responseData;
};
