import { useDisclosure } from '@chakra-ui/react';
import { zodResolver } from '@hookform/resolvers/zod';
import { withSuspenseAndErrorBoundary } from 'admin/components/Error/WithErrorBoundary';
import { SECURITY_SETTING_TOKEN } from 'admin/define/idPoolConsumer/auth';
import { useIdPoolConsumerId } from 'admin/hooks/useIdPoolConsumerId';
import { useToken } from 'admin/hooks/userPool/auth/useToken';
import { useUpdateToken } from 'admin/hooks/userPool/auth/useUpdateToken';
import { submitTokenSchema } from 'admin/schema/idPoolConsumer/auth';
import { TokenFormType } from 'admin/types/userPool/auth/form';
import { FC, memo, useCallback, useState } from 'react';
import { FormProvider, useForm } from 'react-hook-form';
import { ConfirmModal } from '../../confirmModal';
import { TokenEditFormComponent } from './TokenEditFormComponent';

type Props = {
  onClose: () => void;
  formId: string;
};

const FetchTokenEditForm: FC<Props> = memo(({ onClose, formId }: Props) => {
  const idPoolConsumerId = useIdPoolConsumerId();
  const token = useToken();
  const [modalPromise, setModalPromise] = useState<() => void>(() => null);
  const {
    isOpen: isModalOpen,
    onClose: onModalClose,
    onOpen: onModalOpen,
  } = useDisclosure();
  const { mutate, isLoading } = useUpdateToken();

  const methods = useForm<TokenFormType>({
    defaultValues: {
      id: idPoolConsumerId,
      jwtDuration: token?.jwtDuration,
      jwtIssuer: token?.jwtIssuer,
      accessTokenDuration: token?.accessTokenDuration,
      refreshTokenDuration: token?.refreshTokenDuration,
    },
    resolver: zodResolver(submitTokenSchema),
  });

  // モーダルの実行ボタンを押した時
  const onSubmitModal = useCallback(
    async (data: TokenFormType) => {
      if (isLoading) return;

      const promise = new Promise<void>((resolve) => {
        setModalPromise(() => resolve);
      });

      onModalOpen();
      await promise;
      onModalClose();
      await mutate(data);
      onClose();
    },
    [isLoading, onModalOpen, onModalClose, mutate, onClose],
  );

  return (
    <FormProvider {...methods}>
      <TokenEditFormComponent
        formId={formId}
        isLoading={isLoading}
        onSubmit={onSubmitModal}
        onClose={onClose}
      />
      <ConfirmModal
        type={SECURITY_SETTING_TOKEN}
        isOpen={isModalOpen}
        onClose={onModalClose}
        onSubmit={modalPromise}
      />
    </FormProvider>
  );
});

export const TokenEditForm = withSuspenseAndErrorBoundary(FetchTokenEditForm);
