import { BoxProps } from '@chakra-ui/react';
import { TdNoBorder } from 'admin/components/ui/table/td/TdNoBorder';
import { TdWithBorder } from 'admin/components/ui/table/td/TdWithBorder';
import { FC, memo, ReactNode } from 'react';

type Props = {
  hasBorder?: boolean;
  children: ReactNode;
} & BoxProps;

export const Td: FC<Props> = memo(
  ({ hasBorder = false, children, ...props }) => {
    const Component = hasBorder ? TdWithBorder : TdNoBorder;

    return <Component {...props}>{children}</Component>;
  },
);
