import { useCustomToast } from 'hooks/useCustomToast';

export const useCopy = (): {
  copy: (text: string) => void;
} => {
  const toast = useCustomToast();

  /**
   * クリップボードコピー処理
   */
  const copy = (text: string) =>
    navigator.clipboard.writeText(text).then(() => {
      toast({
        status: 'success',
        position: 'bottom',
        duration: 4000,
        isClosable: true,
        title: 'コピーしました',
      });
    });

  return { copy };
};
