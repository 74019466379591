import { useQueryClient } from 'react-query';
import { contentsDbQueryKey } from 'hooks/contentsdb/queryKey';

type UseUploadHistoryCacheClear = {
  tenantId: string;
  tableId: string;
};

export const useUploadHistoryCacheClear = ({
  tenantId,
  tableId,
}: UseUploadHistoryCacheClear): (() => void) => {
  const uploadParams = { tenantId, tableId };
  const key = contentsDbQueryKey.uploadHistory(uploadParams);

  const client = useQueryClient();
  const clearCache = (): void => {
    void client.invalidateQueries(key);
  };

  return clearCache;
};
