import { request } from 'admin/api/Request';
import { API_MESSAGES } from 'admin/define/apiMessages';
import { CustomError } from 'admin/error/CustomError';
import { idPoolConsumerAttributesSchema } from 'admin/schema/idPoolConsumer/attribute';
import { IdPoolConsumerAttributesType } from 'admin/types/userPool/attribute/index';
import { UserPool } from 'admin/types/userPool/index';
import { ZodError } from 'zod';

export const getAllAtributes = async (
  id: UserPool['id'],
): Promise<IdPoolConsumerAttributesType> => {
  const path = `uniikey/${id}/user_attribute/?is_all=true`;
  const response = await request<IdPoolConsumerAttributesType>({
    path,
    method: 'get',
  });

  if (response.hasError) {
    if (response.status === 404) {
      throw new CustomError(
        API_MESSAGES.USER_POOL.GET_ID_POOL_CONSUMER_ATTRIBUTE.NOT_FOUND,
        {
          path,
          status: response.status,
        },
      );
    } else {
      throw new CustomError(
        API_MESSAGES.USER_POOL.GET_ID_POOL_CONSUMER_ATTRIBUTE.ERROR,
        {
          path,
          status: response.status,
          logLevel: response.status === 400 ? 'warning' : 'error',
        },
      );
    }
  }

  try {
    idPoolConsumerAttributesSchema.parse(response.data);
  } catch (error) {
    if (error instanceof ZodError) {
      throw new CustomError(error.message, {
        path,
        status: response.status,
        logLevel: 'error',
      });
    }
  }

  return response.data;
};
