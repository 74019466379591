import {
  Box,
  Button,
  FormControl,
  FormErrorMessage,
  HStack,
  ListItem,
  ModalBody,
  ModalCloseButton,
  ModalContent,
  ModalFooter,
  ModalHeader,
  ModalOverlay,
  Text,
} from '@chakra-ui/react';
import { RhfInput } from 'admin/components/form/RhfInput';
import { AlertInfoBar } from 'admin/components/ui/alertInfo';
import { Colors, ColorSet } from 'admin/define/colors';
import { ConfirmSubmitFormType } from 'admin/types/utils/index';
import { FC, memo } from 'react';
import { useFormContext, useFormState } from 'react-hook-form';

export type StatusChangeFormComponentProps = {
  formId: string;
  isLoading: boolean;
  onClose: () => void;
  onSubmit: () => void;
};

export const StatusChangeModalComponent: FC<StatusChangeFormComponentProps> =
  memo(({ formId, isLoading, onClose, onSubmit }) => {
    const { handleSubmit } = useFormContext();
    const { errors, isValid } = useFormState<ConfirmSubmitFormType>();

    return (
      <>
        <ModalOverlay />
        <ModalContent fontSize="md" zIndex={100}>
          <form onSubmit={handleSubmit(onSubmit)} id={formId}>
            <ModalHeader fontSize="lg" fontWeight="bold" p="6">
              ステータス変更の確認
            </ModalHeader>
            <ModalBody p="0 24px">
              <Text mb={6} lineHeight="27px">
                規約のステータスを「下書き」から「確定」へ変更します。
                <br />
                <Text as="span" fontWeight="700" color={Colors.RED_20}>
                  一度「確定」状態にした規約は、下書きに戻すことができません。
                  <br />
                </Text>
                また、次の変更が不可能になります。
              </Text>
              <Box mb="6">
                <ListItem fontWeight="bold">フィールド名</ListItem>
              </Box>

              <AlertInfoBar type="error" iconType="error">
                ・一度実行すると解除できないため注意してください
              </AlertInfoBar>
              <Text mt={6} mb={1} fontSize="sm" fontWeight="bold">
                実行する場合は「confirm」と入力後に「実行ボタン」を押下してください。
              </Text>
              <FormControl isInvalid={Boolean(errors.word?.message)}>
                <RhfInput<ConfirmSubmitFormType> name="word" type="text" />
                <FormErrorMessage>{errors.word?.message}</FormErrorMessage>
              </FormControl>
            </ModalBody>

            <ModalFooter p="6">
              <HStack spacing={4}>
                <Button
                  w="118px"
                  variant="outline"
                  onClick={() => onClose()}
                  {...ColorSet.Default}
                  padding="8px 24px"
                >
                  キャンセル
                </Button>
                <Button
                  w="118px"
                  colorScheme="primary"
                  type="submit"
                  mr={3}
                  form={formId}
                  disabled={!isValid || isLoading}
                  padding="8px 24px"
                >
                  実行
                </Button>
              </HStack>
            </ModalFooter>
            <ModalCloseButton top="21px" right="21px" />
          </form>
        </ModalContent>
      </>
    );
  });
