import { Box, Icon } from '@chakra-ui/react';
import { FC, memo } from 'react';
import { MdArrowBackIos } from 'react-icons/md';
import { NavLink } from 'react-router-dom';

type Props = {
  path: string;
}

export const BackToEnqueteListButton: FC<Props> = memo(({path}: Props) => (
  <NavLink to={path} style={{ color: '#718096', fontWeight: 700 }} >
    <Box display="flex" alignItems="center">
      <Icon as={MdArrowBackIos} />一覧に戻る
    </Box>
  </NavLink>
));
