import { Options } from 'ky';
import { request } from 'api/request';
import { logger } from 'api/logger';
import { CustomError } from 'api/error/CustomError';
import {
  MailMagazine,
  isMailMagazineList,
} from 'components/setting/mailMagazine/typed';
import { messages } from './messages';

// メールマガジン一覧取得API
export const getMailMagazineList = async (
  tenantId: string,
  type?: 'all' | '',
  options?: Options,
): Promise<MailMagazine[]> => {
  const target = 'mail';
  const path = `${target}/${tenantId}/magazine/${
    type === 'all' ? '?get_all=1' : ''
  }`;

  const response = await request({
    path,
    method: 'get',
    options,
  });

  const responseData = (await response.data) as unknown[];

  if (response.hasError) {
    if (response.status === 404) {
      throw new CustomError(
        messages.getMailMagazineList.customer.notFound,
        response.status,
      );
    } else {
      throw new CustomError(
        messages.getMailMagazineList.customer.error,
        response.status,
      );
    }
  }

  if (!isMailMagazineList(responseData)) {
    await logger({
      loglevel:
        response.status === 404 || response.status === 450
          ? 'warning'
          : 'error',
      data: responseData,
      message: messages.getMailMagazineList.system.typeError,
    });

    throw new CustomError(
      messages.getMailMagazineList.customer.failed,
      response.status,
    );
  }

  return responseData;
};
