
/**
 * 規約URLリスト
 */
export type Term = {
  title: string;
  url: string;
}

/**
 * 規約情報
 */
export type TermsSetting = {
  title: string | null;
  description: string | null;
  terms: Term[]
}

/**
 * 規約送信フォームタイプ
 */
export type TermsSettingSubmitFormType = {
  tenantId: string;
  termSetting: TermsSetting;
};

export const isTerm = (data: unknown): data is Term => {
  const el = data as Term;

  return (
    typeof el?.title === 'string' &&
    typeof el?.url === 'string'
  )
}

export const isTerms = (datas: unknown[]): datas is Term[] =>
  !datas.some((d) => !isTerm(d));

export const isTermsSetting = (data: unknown): data is TermsSetting => {
  const el = data as TermsSetting;

  return (
    (el?.title !== null ? typeof el?.title === 'string' : true) &&
    (el?.description !== null ? typeof el?.description === 'string' : true) &&
    (el?.terms.length === 0 ? true : isTerms(el?.terms))
  )
}
