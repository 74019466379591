import { Box, Button, Flex, Text } from '@chakra-ui/react';
import { Colors } from 'admin/define/colors';
import { FC, memo } from 'react';

type Props = {
  fromAddress: string;
  replyAddress: string;
  onOpen: () => void;
};

export const TransactionMailCommonComponent: FC<Props> = memo(
  ({ fromAddress, replyAddress, onOpen }) => (
    <>
      <Box mb="24px">
        <Flex justifyContent="space-between">
          <Box>
            <Text
              fontFamily="Hiragino Sans"
              fontSize="16px"
              fontWeight="700"
              lineHeight="170%"
            >
              共通設定
            </Text>
            <Text color={Colors.GRAY_00}>すべてのメールに適用されます。</Text>
          </Box>
          <Button
            color={Colors.BLUE_20}
            borderColor={Colors.BLUE_20}
            variant="outline"
            onClick={() => onOpen()}
          >
            共通設定
          </Button>
        </Flex>
      </Box>
      <Box
        bg={Colors.WHITE}
        borderRadius="8px"
        border="1px"
        borderStyle="solid"
        borderColor={Colors.GRAY_40}
        padding="16px"
      >
        <Box mb="4px">
          <Text color={Colors.GRAY_00} fontSize="12px" lineHeight="20px">
            差出人メールアドレス
          </Text>
          <Text fontWeight="600" fontSize="14px" lineHeight="24px">
            {fromAddress}
          </Text>
        </Box>
        <Box>
          <Text color={Colors.GRAY_00} fontSize="12px" lineHeight="20px">
            返信用メールアドレス
          </Text>
          <Text fontWeight="600" fontSize="14px" lineHeight="24px">
            {replyAddress}
          </Text>
        </Box>
      </Box>
    </>
  ),
);
