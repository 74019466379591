import { VStack } from '@chakra-ui/react';
import ErrorBoundary from 'api/ErrorBoundary';
import { PaymentAgencyInner } from 'components/setting/paymentAgency/PaymentAgencyInner';
import { useErrorBoundaryReset } from 'hooks/useErrorBoundaryReset';
import { useSetPageTitle } from 'hooks/useSetPageTitle';
import { FC, memo } from 'react';

export const PaymentAgency:FC = memo(() => {
  const { ebKey, onError } = useErrorBoundaryReset();
  useSetPageTitle('決済代行連携設定');

  return(
    <VStack flex={1} alignItems="flex-start">
      <ErrorBoundary onError={onError} key={ebKey.current}>
        <PaymentAgencyInner />
      </ErrorBoundary>
    </VStack>
  );
});
