import { useQuery } from 'react-query';
import { User } from 'api/user/types';
import { getUser } from 'api/user/getUser';
import { useCustomToast } from 'hooks/useCustomToast';
import { useUserId } from 'hooks/user/useUserId';
import { queryUserKey } from './queryUserKey';

/**
 * ユーザー情報取得 hooks
 */
export const useGetUser = (): User | undefined => {
  const userId = useUserId();
  const toast = useCustomToast();

  const response = useQuery(queryUserKey.getUser({ userId }), () => getUser(), {
    onError: (err: unknown) => {
      if (err instanceof Error) {
        toast.closeAll();
        toast({
          title: err.message,
          status: 'error',
        });
      }
    },
  });
  const { data: userData } = response;

  return userData;
};
