// ----- fetch関連 -----
const GET_TENANT_USERS = {
  ERROR:
    '申し訳ございません。テナント アカウント管理一覧情報の取得ができません。時間を置いて再表示をお願いします。',
  NOT_FOUND:
    'テナント アカウント管理一覧が存在しません。既に削除された可能性があります。',
} as const;
const GET_TENANT_LIST = {
  ERROR:
    '申し訳ございません。テナント一覧情報の取得ができません。時間を置いて再表示をお願いします。',
  NOT_FOUND: 'テナント一覧が存在しません。既に削除された可能性があります。',
} as const;

// ----- Mutate関連 -----
const INVITE_TENANT_USERS = {
  ERROR: '申し訳ございません。ユーザー招待に失敗しました。',
  FAILED:
    'ユーザー招待に失敗しました。恐れ入りますが時間をおいて再度実行してください。',
} as const;
const UPDATE_TENANT_USERS = {
  ERROR: '申し訳ございません。ユーザー情報編集に失敗しました。',
  FAILED:
    'ユーザー情報編集に失敗しました。恐れ入りますが時間をおいて再度実行してください。',
} as const;
const DELETE_TENANT_USER = {
  ERROR: '申し訳ございません。テナントアカウント削除に失敗しました。',
  FAILED:
    'テナントアカウント削除に失敗しました。恐れ入りますが時間をおいて再度実行してください。',
} as const;

export const TENANT_USERS = {
  TENANT_USERS: {
    GET_TENANT_USERS,
    INVITE_TENANT_USERS,
    UPDATE_TENANT_USERS,
    DELETE_TENANT_USER,
    GET_TENANT_LIST,
  },
};
