import { useQuery } from 'react-query';
import { useSearchParams } from 'react-router-dom';
import { Page } from 'api/common/types';
import { EnqueteListType, EnqueteInfo } from 'api/enquete/types';
import { getEnqueteList } from 'api/enquete/getEnqueteList';
import { useCustomToast } from 'hooks/useCustomToast';
import { useUserTenantId } from 'hooks/user/useUserTenantId';
import { queryEnqueteKey } from './queryEnqueteKey';

/**
 * メール一覧情報取得 hooks
 */
export const useEnqueteList = (): {
  page: Page;
  enqueteList: EnqueteListType[];
} => {
  const toast = useCustomToast();
  const tenantId = useUserTenantId();
  const [searchParams] = useSearchParams();

  const queryOrdering = searchParams.get('ordering') || '';
  const queryPage = searchParams.get('page') || '';
  const queryOpenDrawer = searchParams.get('openDrawer') || '';
  const queryPerPage = searchParams.get('perPage') || '';
  const queryReserve = searchParams.get('reserve') || '';
  const queryStatus = searchParams.get('status') || '';

  const response = useQuery(
    queryEnqueteKey.getEnqueteList({
      tenantId,
      ordering: queryOrdering,
      page: queryPage,
      openDrawer: queryOpenDrawer,
      perPage: queryPerPage,
      reserve: queryReserve,
      status: queryStatus,
    }),
    () =>
      getEnqueteList({
        tenantId,
        ordering: queryOrdering,
        page: queryPage,
        perPage: queryPerPage,
        reserve: queryReserve,
        status: queryStatus,
      }),
    {
      onError: (err: unknown) => {
        if (err instanceof Error) {
          toast.closeAll();
          toast({
            title: err.message,
            status: 'error',
          });
        }
      },
    },
  );

  const result = response.data as EnqueteInfo;
  const page: Page = {
    count: result.count,
    countFrom: result.countFrom,
    countTo: result.countTo,
    currentPage: result.currentPage,
    next: result.next,
    pageCount: result.pageCount,
    perPage: result.perPage,
    previous: result.previous,
  };
  const { results } = result;

  return {
    page,
    enqueteList: results,
  };
};
