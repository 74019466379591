import {
  Box,
  Checkbox,
  NumberDecrementStepper,
  NumberIncrementStepper,
  NumberInput,
  NumberInputField,
  NumberInputStepper,
  Select,
  Table,
  Tbody,
  Td,
  Text,
  Tr,
  VStack,
} from '@chakra-ui/react';
import { CondElement } from 'components/enquete/atoms/CondElement';
import { RichTextElement } from 'components/enquete/atoms/RichTextElement';
import { Dispatch, FC, SetStateAction, useEffect, useState } from 'react';
import {
  FieldValues,
  Path,
  PathValue,
  UnpackNestedValue,
  useFormContext,
} from 'react-hook-form';
import { CheckLimit } from './CheckLimit';
import { QuestionTitleElement } from './QuestionTitleElement';
import { Toggle } from './Toggle';

type Props<T> = {
  baseName: Path<T>;
  index: number;
  type: string;
  setIsEdited: Dispatch<SetStateAction<boolean>>;
  hasTitle?: boolean;
  hasDescriptionTop?: boolean;
  text?: string | Array<string | JSX.Element>;
  hasFreeRowCount?: boolean;
  hasTextLimit?: boolean;
  hasFreeFormat?: boolean;
  hasSingleLineItems?: boolean;
  hasCheckLimit?: boolean;
  hasEmailConfirm?: boolean;
  hasDescriptionBottom?: boolean;
};

export const UserAttributeElement = <T extends FieldValues>({
  baseName,
  index,
  type,
  setIsEdited,
  hasTitle = true,
  hasDescriptionTop = true,
  text = '',
  hasFreeRowCount = false,
  hasTextLimit = false,
  hasFreeFormat = false,
  hasSingleLineItems = false,
  hasCheckLimit = false,
  hasEmailConfirm = false,
  hasDescriptionBottom = true,
}: Props<T>): ReturnType<FC> => {
  const { register, setValue, getValues } = useFormContext<T>();
  const [toggle, setToggle] = useState(false);

  useEffect(() => {
    if (type === 'uni_text') {
      setValue(
        `${baseName}.${index}.freeRowCount` as Path<T>,
        '1' as UnpackNestedValue<PathValue<T, Path<T>>>,
      );
    } else if (type === 'uni_checkbox_single') {
      setValue(
        `${baseName}.${index}.checkMin` as Path<T>,
        '0' as UnpackNestedValue<PathValue<T, Path<T>>>,
      );
      setValue(
        `${baseName}.${index}.checkMax` as Path<T>,
        '0' as UnpackNestedValue<PathValue<T, Path<T>>>,
      );
    }
  }, [index, setValue, type]);

  return (
    <>
      <VStack w="100%" display={toggle ? 'none' : 'block'}>
        <Table>
          <Tbody>
            {hasTitle && (
              <Tr>
                <QuestionTitleElement<T>
                  baseName={baseName}
                  width={140}
                  colspan={4}
                  index={index}
                />
              </Tr>
            )}
            {hasDescriptionTop && (
              <Tr>
                <Td w={140}>補足説明など</Td>
                <Td colSpan={4}>
                  <RichTextElement<T>
                    baseName={baseName}
                    placeholder=""
                    index={index}
                    position="descriptionTop"
                    value={
                      getValues(
                        `${baseName}.${index}.descriptionTop` as Path<T>,
                      ) || ''
                    }
                    setIsEdited={setIsEdited}
                  />
                </Td>
              </Tr>
            )}
            {text && (
              <Tr>
                <Td colSpan={5}>
                  <Box w="100%" background="whitesmoke" p={5} textAlign="left">
                    <Text>{text}</Text>
                  </Box>
                </Td>
              </Tr>
            )}
            {hasFreeRowCount && (
              <Tr>
                <Td w={140}>行数</Td>
                <Td w={200} pr={0}>
                  <NumberInput
                    min={1}
                    defaultValue={1}
                    isDisabled={getValues('uneditableState' as Path<T>)}
                  >
                    <NumberInputField
                      {...register(
                        `${baseName}.${index}.freeRowCount` as Path<T>,
                      )}
                      backgroundColor="#f9f9f9"
                      borderColor="#ccc"
                    />
                    <NumberInputStepper>
                      <NumberIncrementStepper />
                      <NumberDecrementStepper />
                    </NumberInputStepper>
                  </NumberInput>
                </Td>
                <Td colSpan={3} w={100}>
                  行
                </Td>
              </Tr>
            )}
            {hasTextLimit && (
              <>
                <Tr>
                  <Td w={140} pr={0} rowSpan={2}>
                    <Text>文字数制限</Text>
                    <Text fontSize="xs">
                      ※改行は文字数にカウントされません。
                    </Text>
                  </Td>
                  <Td w={200} pr={0}>
                    <NumberInput
                      min={0}
                      defaultValue={0}
                      isDisabled={getValues('uneditableState' as Path<T>)}
                    >
                      <NumberInputField
                        {...register(
                          `${baseName}.${index}.freeTextMin` as Path<T>,
                        )}
                        backgroundColor="#f9f9f9"
                        borderColor="#ccc"
                      />
                      <NumberInputStepper>
                        <NumberIncrementStepper />
                        <NumberDecrementStepper />
                      </NumberInputStepper>
                    </NumberInput>
                  </Td>
                  <Td w={100} pr={0}>
                    文字以上
                  </Td>
                  <Td w={200} pr={0}>
                    <NumberInput
                      min={0}
                      defaultValue={100}
                      isDisabled={getValues('uneditableState' as Path<T>)}
                    >
                      <NumberInputField
                        {...register(
                          `${baseName}.${index}.freeTextMax` as Path<T>,
                        )}
                        backgroundColor="#f9f9f9"
                        borderColor="#ccc"
                      />
                      <NumberInputStepper>
                        <NumberIncrementStepper />
                        <NumberDecrementStepper />
                      </NumberInputStepper>
                    </NumberInput>
                  </Td>
                  <Td>文字以下</Td>
                </Tr>
                <Tr>
                  <Td colSpan={4}>
                    <Checkbox
                      {...register(
                        `${baseName}.${index}.isHideWordLimit` as Path<T>,
                      )}
                      isDisabled={getValues('uneditableState' as Path<T>)}
                    >
                      <Text fontSize={14}>回答画面へ制限内容を表示しない</Text>
                    </Checkbox>
                  </Td>
                </Tr>
              </>
            )}
            {hasFreeFormat && (
              <Tr>
                <Td>書式</Td>
                <Td colSpan={4}>
                  <Select
                    w={300}
                    {...register(`${baseName}.${index}.freeFormat` as Path<T>)}
                    backgroundColor="#f9f9f9"
                    borderColor="#ccc"
                    isDisabled={getValues('uneditableState' as Path<T>)}
                  >
                    {[
                      { id: 1, name: '制限なし' },
                      { id: 2, name: '半角英数字' },
                      { id: 3, name: '半角数字' },
                      { id: 5, name: 'SNSアカウント(半角英数字 + 記号)' },
                    ].map((item) => (
                      <option key={item.id} value={item.id}>
                        {item.name}
                      </option>
                    ))}
                  </Select>
                </Td>
              </Tr>
            )}
            {hasSingleLineItems && (
              <Tr>
                <Td />
                <Td colSpan={4}>
                  <Checkbox
                    {...register(
                      `${baseName}.${index}.isSingleLineItems` as Path<T>,
                    )}
                    isDisabled={getValues('uneditableState' as Path<T>)}
                  >
                    <Text fontSize={14}>全ての選択肢を1行にする</Text>
                  </Checkbox>
                </Td>
              </Tr>
            )}
            {hasCheckLimit && (
              <Tr>
                <Td colSpan={5}>
                  <CheckLimit<T>
                    baseName={baseName}
                    title="入力制限"
                    name="check"
                    index={index}
                  />
                </Td>
              </Tr>
            )}
            {hasEmailConfirm && (
              <Tr>
                <Td />
                <Td colSpan={4}>
                  <Checkbox
                    {...register(
                      `${baseName}.${index}.isEmailConfirm` as Path<T>,
                    )}
                    style={{ margin: '10px auto 0 0' }}
                    isDisabled={getValues('uneditableState' as Path<T>)}
                  >
                    <Text fontSize={14}>
                      メールアドレス確認用の入力欄を表示する。
                    </Text>
                  </Checkbox>
                </Td>
              </Tr>
            )}
            {hasDescriptionBottom && (
              <Tr>
                <Td w={140}>補足説明など</Td>
                <Td colSpan={4}>
                  <RichTextElement<T>
                    baseName={baseName}
                    placeholder=""
                    index={index}
                    position="descriptionBottom"
                    value={
                      getValues(
                        `${baseName}.${index}.descriptionBottom` as Path<T>,
                      ) || ''
                    }
                    setIsEdited={setIsEdited}
                  />
                </Td>
              </Tr>
            )}
          </Tbody>
        </Table>
        <CondElement<T> baseName={baseName} index={index} />
      </VStack>
      <Toggle toggle={toggle} setToggle={setToggle} />
    </>
  );
};
