import {
  Box,
  Button,
  ButtonGroup,
  Text,
  useDisclosure,
} from '@chakra-ui/react';
import { AddIcon } from '@chakra-ui/icons';
import { AuthHubsResultType } from 'admin/types/authHub';
import { memo } from 'react';
import { QueryObserverResult } from 'react-query';
import { CreateModal } from './CreateModal';

export const Controls = memo(
  ({
    userPoolId,
    tenantId,
    refetch,
  }: {
    userPoolId?: string;
    tenantId?: string;
    refetch: () => Promise<QueryObserverResult<AuthHubsResultType, unknown>>;
  }) => {
    const { isOpen, onOpen, onClose } = useDisclosure();

    return (
      <>
        <CreateModal
          userPoolId={userPoolId}
          tenantId={tenantId}
          isOpen={isOpen}
          onClose={onClose}
          refetch={refetch}
        />
        <Box display="flex" alignSelf="flex-end">
          <ButtonGroup spacing="2">
            <Button colorScheme="blue" onClick={onOpen}>
              <Box display="flex">
                <AddIcon />
                <Text as="span" ml={2}>
                  設定画面を作成
                </Text>
              </Box>
            </Button>
          </ButtonGroup>
        </Box>
      </>
    );
  },
);
