import { Box } from '@chakra-ui/react';
import { Colors } from 'components/paywall/config';
import { Detail, DetailBlock, DetailFlex, DetailItem, DetailItems } from 'components/paywall/features/Detail';
import { DetailCustomerLinkText } from 'components/paywall/features/Detail/DetailCustomer';
import { StatusTag } from 'components/paywall/features/StatusTag';
import { SubscriptionDetailOrderGmo } from 'components/paywall/pages/SubscriptionList/typed';
import { useFormatDate } from 'hooks/useFormatDate';
import { FC, Fragment, memo } from 'react';

type Props = {
  detailOrderGmo: SubscriptionDetailOrderGmo[];
}

// 決済ステータス
const tranStatusType = [
  {code: 1, tranStatus: 1, name:'支払い済み',},
  {code: 3, tranStatus: 5, name:'購入取り消し'},
  {code: 5, tranStatus: 9, name:'決済エラー'},
  {code: 1, tranStatus: 0, name:'その他'},
  {code: 1, tranStatus: 6, name:'返金済み'},
]

export const SubscriptionDetailInnerHistory: FC<Props> = memo(({detailOrderGmo} : Props) => {
  const { dateTimeFormat } = useFormatDate();

  // サブスクリプション詳細情報 > 決済ステータス用ステータスコード取得
  const getTranStatus = (tranStatusCode: number) => {
    const tranStatusResult = tranStatusType.filter((status) => status.tranStatus === tranStatusCode)

    // ステータスが見つからなかった場合はdefaultを返却する
    if(tranStatusResult.length === 0) return{
      code: 0,
      name: '',
      tranStatus: 0
    }

    return tranStatusResult[0];
  }

  return (
    <>
      {detailOrderGmo.map((gmo, idx) => (
        <Detail type={1} title={idx === 0 ? '決済履歴' : ''} spacing={idx !== 0 ? '8px' : undefined} key={`${gmo.id}_${String(idx)}`}>
          <DetailBlock space={16}>
            <DetailFlex>
              <DetailItems spacing="8px">
                <DetailItem label="決済ID" text={<DetailCustomerLinkText path={`/paywall/${gmo.id || ''}/orderDetail`} text={gmo.id || ''} />} labelWidth="56px" fontColor={Colors.BLUE_20} textDecoration="underline" spacing="12px" />
                <DetailItem label="決済金額" text={`${gmo.price.toLocaleString()}円`} labelWidth="56px" spacing="12px" />
              </DetailItems>
              <DetailItems spacing="8px">
                <DetailItem label="決済日時" text={dateTimeFormat(gmo.execTranDate) || ''} labelWidth="98px" spacing="12px" />
                <DetailItem
                  label="決済ステータス"
                  text={(
                    <>
                      <StatusTag type={getTranStatus(gmo.tranStatus).code} status={getTranStatus(gmo.tranStatus).name}/>
                      {gmo.cardErrorInfo.map((info, infoIdx) => (
                        <Fragment key={`history_${info.code}_${String(idx)}_${String(infoIdx)}`}>
                          <Box>{info.code}</Box>
                          <Box fontWeight="400">{info.message}</Box>
                        </Fragment>
                      ))}
                    </>
                  )}
                  labelWidth="98px" spacing="12px" />
              </DetailItems>
            </DetailFlex>
          </DetailBlock>
        </Detail>
      ))}
    </>
  )
});
