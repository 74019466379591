/**
 * カスタムツール登録
 */
export const useRegisterCustomTool = (): {
  setRegisterCustomTool: (
    registerConfig: string,
    configOptions: string[],
    rendererConfig: string,
  ) => string;
} => {

  const setRegisterCustomTool = (
    registerConfig: string,
    configOptions: string[],
    rendererConfig: string,
  ): string => `
    unlayer.registerTool({
      ${registerConfig}
      options: {
        ${configOptions.join(',')}
      },
      values: {},
      renderer: {
        ${rendererConfig}
      },
      validator(data) {
        return [];
      },
    });
  `;

  return {
    setRegisterCustomTool
  }
}
