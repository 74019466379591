import { VFC } from 'react';
import { RegisterOptions } from 'react-hook-form';
import { LockIcon } from '@chakra-ui/icons';
import { InvitationUser } from 'api/user/types';
import { InputTextForm } from 'components/common/atoms';

/**
 * InputPasswordProps
 */
type InputPasswordProps = {
  name: 'password' | 'passwordConfirm';
  placeholder: string;
  attr: RegisterOptions;
};

export const InputPassword: VFC<InputPasswordProps> = ({
  name,
  placeholder,
  attr,
}: InputPasswordProps) => (
  <InputTextForm<InvitationUser>
    name={name}
    type="password"
    icon={LockIcon}
    placeholder={placeholder}
    value=""
    attr={attr}
  />
);
