import React, { FC } from 'react';
import { Navigate } from 'react-router-dom';
import { useAdminAuth } from '../admin/hooks/useAdminAuth';
import { usePaywallAuth } from '../components/paywall/hooks/usePaywallAuth';

/**
 * ユーザーパーミッションの状態に基づいて
 * ルーティングを制御するHOCを定義
 */
export const permissionCheckRoutingControl = (
  WrappedComponent: React.ComponentType,
  routeType: string,
  redirect: string,
): FC => {
  const PermissionCheckRoutingControl: FC = (props) => {
    const { isAllowedPermission: isPaywallAllowedPermission } = usePaywallAuth();
    const { isAllowedPermission: isAdminAllowedPermission } = useAdminAuth();
    // アクセスが許可されていない場合は設定されているリダイレクトへ遷移させる

    if (
      (routeType === 'paywall' && !isPaywallAllowedPermission('top')) ||
      (routeType === 'admin' && !isAdminAllowedPermission('top'))
    ) {
      return <Navigate to={redirect} />;
    }

    return <WrappedComponent {...props} />;
  };

  return PermissionCheckRoutingControl;
};
