import { Drawer, useDisclosure } from '@chakra-ui/react';
import { withSuspenseAndErrorBoundary } from 'admin/components/Error/WithErrorBoundary';
import { NewsletterCreateDrawer } from 'admin/components/idPoolConsumer/newsletter/newsletterDrawer';
import { NewsletterTitleComponent } from 'admin/components/idPoolConsumer/newsletter/newsletterTitle/NewsletterTitleComponent';
import { ErrorContents } from 'components/common/atoms';
import { FC, memo } from 'react';

export const newsletterTitle: FC = memo(() => {
  const { isOpen, onClose, onOpen } = useDisclosure();

  return (
    <>
      <NewsletterTitleComponent onOpen={onOpen} />
      <Drawer
        isOpen={isOpen}
        placement="right"
        onClose={onClose}
        size="md"
        closeOnOverlayClick={false}
        closeOnEsc={false}
        autoFocus={false}
      >
        <NewsletterCreateDrawer onClose={onClose} />
      </Drawer>
    </>
  );
});

export const NewsletterTitle = withSuspenseAndErrorBoundary(newsletterTitle, {
  ErrorComponent: <ErrorContents name="メールマガジン購読設定タイトル" />,
});
