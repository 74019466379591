import { FC, memo } from 'react';
import { Tag } from '@chakra-ui/react';

export const RequireTag: FC = memo(() => (
  <Tag
    minH="auto"
    bgColor="red"
    color="white"
    fontSize="10px"
    p={1}
    ml={2}
  >
    必須
  </Tag>
));
