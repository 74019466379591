import { request } from 'admin/api/Request';
import { API_MESSAGES } from 'admin/define/apiMessages';
import { CustomError } from 'admin/error/CustomError';
import { newsletterFormSchema } from 'admin/schema/idPoolConsumer/newsletter';
import { UserPool } from 'admin/types/userPool';
import { NewsletterFormType } from 'admin/types/userPool/newsletter/form';
import { isResponseError } from 'api/types';
import { getValidationError } from 'utils/form';
import { ZodError } from 'zod';

export const createNewsletter = async ({
  data,
  userPoolId,
}: {
  data: NewsletterFormType;
  userPoolId: UserPool['id'];
}): Promise<NewsletterFormType> => {
  const path = `uniikey/${userPoolId}/newsletter/`;
  const params = data;
  if (!params.displayOrder) {
    delete params?.displayOrder;
  }
  const response = await request<NewsletterFormType>({
    path,
    body: params,
    method: 'post',
  });

  if (response.hasError) {
    if (response.status === 404) {
      throw new CustomError(API_MESSAGES.USER_POOL.CREATE_NEWSLETTER.FAILED, {
        path,
        params: data,
        status: response.status,
      });
    } else if (isResponseError(response.error)) {
      const customError = response.error;

      const formError = getValidationError<NewsletterFormType>({
        formData: data,
        response: customError,
      });

      throw new CustomError(
        API_MESSAGES.USER_POOL.CREATE_ID_POOL_CONSUMER_ATTRIBUTE.ERROR,
        {
          path,
          params: data,
          errors: formError,
          status: response.status,
          logLevel: response.status === 400 ? 'warning' : 'error',
        },
      );
    } else {
      throw new CustomError(API_MESSAGES.USER_POOL.CREATE_NEWSLETTER.ERROR, {
        path,
        params: data,
        status: response.status,
      });
    }
  }
  try {
    newsletterFormSchema.parse(response.data);
  } catch (error) {
    if (error instanceof ZodError) {
      throw new CustomError(error.message, {
        path,
        params: data,
        status: response.status,
        logLevel: 'error',
      });
    }
  }

  return response.data;
};
