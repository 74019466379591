import { Box, Button, Flex, Text } from '@chakra-ui/react';
import { Colors } from 'admin/define/colors';
import { useUserCorporationInfo } from 'admin/hooks/user/useUserCorporationInfo';
import { FC, memo } from 'react';

type Props = {
  onOpen: () => void;
}

export const SamlTitle: FC<Props> = memo(({ onOpen }: Props) => {
  const { hasPermissionSaml } = useUserCorporationInfo();

  return (
    <Box mt="40px">
      <Flex justifyContent="flex-end">
        <Box mr="auto">
          <Text
            fontFamily="Hiragino Sans"
            fontSize="18px"
            fontWeight="700"
            lineHeight="170%"
          >
            SAML設定
          </Text>
          <Text
            fontWeight="400"
            fontSize="14px"
            lineHeight="170%"
            fontFamily="Hiragino Sans"
            color={Colors.GRAY_00}
          >
            管理画面へのログインにSAMLを利用するため、以下の情報を登録します。
          </Text>
        </Box>
        {hasPermissionSaml && (
          <Button colorScheme="primary" p="8px 24px" onClick={onOpen}>
            SAML設定
          </Button>
        )}
      </Flex>
    </Box>
  )
});
