import { request } from 'admin/api/Request';
import { API_MESSAGES } from 'admin/define/apiMessages';
import { CustomError } from 'admin/error/CustomError';
import { ServiceType } from 'admin/types/service/index';
import { ServiceTermType } from 'admin/types/service/terms/index';

export const deleteTerm = async ({
  userPoolChildId,
  userPoolTerms,
}: {
  userPoolChildId: ServiceType['userPoolChildId'];
  userPoolTerms: ServiceTermType['id'];
}): Promise<ServiceTermType> => {
  const path = `uniikey/${userPoolChildId ?? ''}/child/terms/${
    userPoolTerms ?? ''
  }/`;
  const response = await request<ServiceTermType>({
    path,
    method: 'delete',
  });

  if (response.hasError) {
    if (response.status === 404) {
      throw new CustomError(API_MESSAGES.SERVICE.UPDATE_TERM.FAILED, {
        path,
        status: response.status,
      });
    } else {
      throw new CustomError(API_MESSAGES.SERVICE.UPDATE_TERM.ERROR, {
        path,
        status: response.status,
      });
    }
  }

  return response.data;
};
