import { HamburgerIcon } from '@chakra-ui/icons';
import { IconButton, Tooltip } from '@chakra-ui/react';
import { memo, MouseEvent, useCallback, useMemo, VFC } from 'react';
import { SetterOrUpdater, useRecoilState } from 'recoil';
import { sidebarState } from 'store/sidebarState';

type Props = {
  isOpen?: boolean;
  setOpen?: SetterOrUpdater<boolean>;
  hoverColor?: string;
};

export const HamburgerButton: VFC<Props> = memo((props) => {
  const [deafaultIsOpen, defaultSetOpen] = useRecoilState(sidebarState);

  const isOpen = useMemo(() => {
    if (typeof props.isOpen === 'undefined') {
      return deafaultIsOpen;
    }

    return props.isOpen;
  }, [props.isOpen, deafaultIsOpen]);

  const setOpen = useMemo(() => {
    if (typeof props.setOpen === 'undefined') {
      return defaultSetOpen;
    }

    return props.setOpen;
  }, [props.setOpen, defaultSetOpen]);

  // メニュー開閉ボタン
  const clickMenuBtn = useCallback(
    (e: MouseEvent<HTMLButtonElement>): void => {
      e.stopPropagation();
      setOpen(!isOpen);
    },
    [isOpen, setOpen],
  );

  return (
    <Tooltip label="メインメニューを表示する" bgColor="white" color="black">
      <IconButton
        icon={<HamburgerIcon w={6} h={6} />}
        bg=""
        isRound={false}
        aria-label="sidebar"
        onClick={clickMenuBtn}
        _hover={{ bg: `${props.hoverColor ? props.hoverColor : 'gray.900'}` }}
        _active={{ bg: '' }}
        _focus={{ boxShadow: '' }}
      />
    </Tooltip>
  );
});
