import { FormControl, FormHelperText, Link } from '@chakra-ui/react';
import { FC } from 'react';

export const LoginTroubleLink: FC = () => (
  <FormControl>
    <FormHelperText textAlign="right" mt={0} textDecoration="underline">
      <Link
        _hover={{ textDecoration: 'none' }}
        href="https://uniikey.notion.site/1797d742e8fe4520b9b30c3af49ea79f"
        isExternal
      >
        ログインでお困りの方
      </Link>
    </FormHelperText>
  </FormControl>
);
