import { getTenantUsersDetail } from 'admin/api/tenantUsers/getTenantUsersDetail';
import { CustomError } from 'admin/error/CustomError';
import { useUserCorporationId } from 'admin/hooks/user/useUserCorporationId';
import { TenantUsersType } from 'admin/types/tenantUsers';
import { logger } from 'api/logger';
import { useQuery } from 'react-query';
import { queryTenantUsersKey } from './queryTenantUsersKey';

export const useTenantUsersDetail = (
  id: TenantUsersType['id'],
  tenantId: TenantUsersType['tenant']['id'],
) => {
  const corporationId = useUserCorporationId();

  const { data } = useQuery({
    queryKey: queryTenantUsersKey.getTenantUsersDetail({
      id,
      corporationId,
      tenantId,
    }),
    queryFn: () =>
      getTenantUsersDetail({
        id,
        corporationId,
        tenantId,
      }),
    onError: async (error) => {
      if (error instanceof CustomError) {
        await logger({
          loglevel: error.logLevel,
          data: JSON.stringify(error.cause),
          message: error.message,
        });
      }
    },
  });

  return { data };
};
