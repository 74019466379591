import { Box, Text } from '@chakra-ui/react';
import { FC, memo } from 'react';

export const MemberTitle: FC = memo(() => (
  <Box mt="40px">
    <Text
      fontFamily="Hiragino Sans"
      fontSize="18px"
      fontWeight="700"
      lineHeight="170%"
    >
      会員詳細
    </Text>
  </Box>
));
