import { Drawer, useDisclosure } from '@chakra-ui/react';
import { withSuspenseAndErrorBoundary } from 'admin/components/Error/WithErrorBoundary';
import { CodeCreateDrawer } from 'admin/components/idPoolConsumer/code/codeDrawer';
import { CodeTitleComponent } from 'admin/components/idPoolConsumer/code/codeTitle/CodeTitleComponent';
import { useIdPoolConsumerId } from 'admin/hooks/useIdPoolConsumerId';
import { useCodeGroup } from 'admin/hooks/userPool/master/useCodeGroup';
import { ErrorContents } from 'components/common/atoms';
import { FC, memo, useMemo } from 'react';
import { useParams } from 'react-router-dom';

const PlainCodeTitle: FC = memo(() => {
  const params = useParams();
  const idPoolConsumerId = useIdPoolConsumerId();
  const masterId = useMemo(() => params.masterId ?? '', [params]);

  const { data } = useCodeGroup(idPoolConsumerId, masterId);
  const { isOpen, onClose, onOpen } = useDisclosure();
  const formId = 'ruleCreateForm';
  if (!data) {
    return null;
  }

  return (
    <>
      <CodeTitleComponent
        onOpen={onOpen}
        id={data?.id}
        displayNameJa={data?.displayNameJa}
      />
      {/* ドロワー */}
      <Drawer
        isOpen={isOpen}
        placement="right"
        onClose={onClose}
        size="md"
        closeOnOverlayClick={false}
        closeOnEsc={false}
        autoFocus={false}
      >
        <CodeCreateDrawer onClose={onClose} isOpen={isOpen} formId={formId} />
      </Drawer>
    </>
  );
});

export const CodeTitle = withSuspenseAndErrorBoundary(PlainCodeTitle, {
  ErrorComponent: <ErrorContents name="コード設定タイトル" />,
  LoadingComponent: <></>,
});
