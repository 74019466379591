import { CustomError } from 'admin/error/CustomError';
import { logger } from 'api/logger';
import { getSendingContent } from 'api/transactionMail/getSendingContent';
import { FirstLoginMailType } from 'api/transactionMail/type';
import { useCustomToast } from 'hooks/useCustomToast';
import { useUserTenantId } from 'hooks/user/useUserTenantId';
import { useQuery } from 'react-query';
import { errorToast } from 'utils/toast';
import { queryKeys } from './queryKeys';

export const useSendingContent = (id: FirstLoginMailType['id']) => {
  const toast = useCustomToast();
  const tenantId = useUserTenantId();
  const { data, isLoading } = useQuery({
    queryKey: queryKeys.getSendingContent({ tenantId, id }),
    queryFn: () => getSendingContent(tenantId, id),
    onError: async (error) => {
      if (error instanceof CustomError) {
        await logger({
          loglevel: error.logLevel,
          data: JSON.stringify(error.cause),
          message: error.message,
        });
      }
      toast({
        ...errorToast,
        duration: 4000,
        title: '送信内容設定詳細の取得に失敗しました',
        position: 'bottom',
        variant: 'solid',
      });
    },
    cacheTime: 0,
  });

  return { data, isLoading };
};
