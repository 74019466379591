export const queryUserKey = {
  getUser: ({ userId }: { userId: string }) => ['user', userId],
  tenantUserList: ({ tenantId }: { tenantId: string }) => [
    'user/tenant',
    tenantId,
  ],
  tenantUser: ({ tenantId, userId }: { tenantId: string; userId: string }) => [
    'user/tenant/userId',
    tenantId,
    userId,
  ],
  checkInviteUser: ({ verifyCode }: { verifyCode: string }) => [
    'user/invite/user/verifyCode',
    verifyCode,
  ],
  checkInviteCorporation: ({ verifyCode }: { verifyCode: string }) => [
    'user/invite/corporation/verifyCode',
    verifyCode,
  ],
  groupUserList: ({ groupId }: { groupId: string }) => [
    'user/group/groupId',
    groupId,
  ],
  groupUserDelete: ({
    tenantId,
    users,
  }: {
    tenantId: string;
    users: string[];
  }) => ['user/settings/users', tenantId, users],
} as const;
