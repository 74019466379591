import { createUserPool } from 'admin/api/userPool/createUserPool';
import { CustomError } from 'admin/error/CustomError';
import { queryIdPoolConsumersKey } from 'admin/hooks/idPoolConsumers/queryIdPoolConsumersKey';
import { useUserCorporationInfo } from 'admin/hooks/user/useUserCorporationInfo';
import { CreateIdPoolConsumerFormType } from 'admin/types/userPool/form';
import { logger } from 'api/logger';
import { useCustomToast } from 'hooks/useCustomToast';
import { Dispatch, SetStateAction } from 'react';
import { UseFormSetError } from 'react-hook-form';
import { useMutation, useQueryClient } from 'react-query';
import { toMultiError } from 'utils/form';
import { errorToast, successToast } from 'utils/toast';

export const useCreateUserPool = (
  setError: UseFormSetError<CreateIdPoolConsumerFormType>,
  setGlobalErrors: Dispatch<SetStateAction<string[]>>,
  onBack: () => void,
) => {
  const { currentUserPoolGroupId } = useUserCorporationInfo();
  const toast = useCustomToast();
  const queryClient = useQueryClient();
  const { mutateAsync, isLoading } = useMutation({
    mutationFn: (data: CreateIdPoolConsumerFormType) =>
      createUserPool({ data, userPoolGroupId: currentUserPoolGroupId || '' }),
    onSuccess: async () => {
      await queryClient.invalidateQueries(
        queryIdPoolConsumersKey.getIdPoolConsumers(
          currentUserPoolGroupId || '',
        ),
      );
      await queryClient.invalidateQueries(
        queryIdPoolConsumersKey.getIdPoolConsumerUserPoolsCreatedCount(
          currentUserPoolGroupId || '',
        ),
      );
      toast({
        ...successToast,
        duration: 4000,
        title: 'ユーザープールを作成しました',
        position: 'bottom',
        variant: 'solid',
      });
    },
    onError: async (error, variables: CreateIdPoolConsumerFormType) => {
      if (error instanceof CustomError) {
        if (error.cause.errors?.others) {
          const othersMsgs = Array.isArray(error.cause.errors?.others)
            ? error.cause.errors?.others
            : [error.cause.errors?.others];

          setGlobalErrors([othersMsgs.join('')]);
        } else {
          Object.keys(variables).forEach((k) => {
            const key = k as keyof CreateIdPoolConsumerFormType;
            const errMsgs = error.cause.errors?.[key];
            if (errMsgs && Array.isArray(errMsgs)) {
              setError(key, { types: toMultiError(errMsgs) });
            }
          });
          onBack();
        }

        if (error.cause.errors) {
          await logger({
            loglevel: error.logLevel,
            data: JSON.stringify(error.cause),
            message: error.message,
          });
        }
      }
      toast({
        ...errorToast,
        duration: 4000,
        title: 'ユーザープールの作成に失敗しました',
        position: 'bottom',
        variant: 'solid',
      });
    },
  });

  return { mutate: mutateAsync, isLoading };
};
