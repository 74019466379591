import { Modal, ModalOverlay } from '@chakra-ui/react';
import { useCreateAuthHub } from 'admin/hooks/authHub/useCreateAuthHub';
import { AuthHubItemsType, AuthHubsResultType } from 'admin/types/authHub';
import { memo, useCallback } from 'react';
import { FormProvider, useForm } from 'react-hook-form';
import { QueryObserverResult } from 'react-query';
import { settings } from '../settings';
import { NameForm, NameFormType } from './NameForm';

export const CreateModal = memo(
  ({
    userPoolId,
    tenantId,
    isOpen,
    onClose,
    refetch,
  }: {
    userPoolId?: string;
    tenantId?: string;
    isOpen: boolean;
    onClose: () => void;
    refetch: () => Promise<QueryObserverResult<AuthHubsResultType, unknown>>;
  }) => {
    const { createMutate } = useCreateAuthHub();

    const methods = useForm({
      mode: 'onBlur',
      defaultValues: {
        name: '',
      },
    });
    const { reset } = methods;

    const onSubmit = useCallback(
      async (values: NameFormType) => {
        if (userPoolId || tenantId) {
          const authHubItems = Object.entries(
            settings,
          ).reduce<AuthHubItemsType>(
            (acc, [key, vs]) => [
              ...acc,
              ...vs.map((s) => ({
                groupName: key,
                key: s.name.split('.').slice(-1)[0],
                value: s.defaultValue,
              })),
            ],
            [],
          );
          const { name } = values;
          await createMutate({
            userPoolId,
            tenantId,
            name,
            authHubItems,
          });
          await refetch();
        }
        reset();
        onClose();
      },
      [onClose, reset, createMutate, tenantId, userPoolId, refetch],
    );

    return (
      <Modal isOpen={isOpen} size="lg" onClose={onClose} isCentered>
        <ModalOverlay />
        <FormProvider {...methods}>
          <NameForm
            title="新規作成"
            saveButtonLabel="保存"
            onSubmit={onSubmit}
            onClose={onClose}
          />
        </FormProvider>
      </Modal>
    );
  },
);
