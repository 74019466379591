import { request } from 'admin/api/Request';
import { API_MESSAGES } from 'admin/define/apiMessages';
import { CustomError } from 'admin/error/CustomError';
import { UserDeleteFormType } from 'admin/types/users';
import { Options } from 'ky';

export const deleteUser = async (
  data: UserDeleteFormType,
  coporationId: string,
  options?: Options,
): Promise<boolean> => {
  const params = data;
  const path = `user/corporation/${coporationId}/delete/`;

  const response = await request<boolean>({
    path,
    method: 'put',
    body: params,
    options,
  });

  if (response.hasError) {
    if (response.status === 404) {
      throw new CustomError(API_MESSAGES.USER.DELETE_USER.FAILED, {
        path,
        status: response.status,
      });
    } else {
      throw new CustomError(API_MESSAGES.USER.DELETE_USER.ERROR, {
        path,
        status: response.status,
        logLevel: response.status === 400 ? 'warning' : 'error',
      });
    }
  }

  return !response.hasError;
};
