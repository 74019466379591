/* eslint-disable react/require-default-props */
import { Link as ChakraLink } from '@chakra-ui/react';
import { Colors } from 'components/paywall/config';
import { FC, memo } from 'react';
import { ImArrowUpRight2 } from "react-icons/im";
import { Link as ReactRouterLink } from 'react-router-dom';

type Props = {
  text: string;
  path: string;
  fontWeight?: number;
  fontSize?: string;
  color?: string;
  textDecoration?: string;
  withIcon?: boolean;
}
export const DetailCustomerLinkText: FC<Props> = memo(({text, path, fontWeight = 700, fontSize = "14px", color = Colors.BLUE_20, textDecoration = "underline", withIcon = false}: Props) => (
  <ChakraLink as={ReactRouterLink} to={path} fontSize={fontSize} fontWeight={fontWeight} color={color} textDecoration={textDecoration}>
    {text}
    {withIcon && (
      <ImArrowUpRight2 color={color} style={{display: 'inline-block', verticalAlign: 'middle', marginLeft: '4px'}} />
    )}
  </ChakraLink>
));
