import {
  AlertDialogCloseButton,
  AlertDialogContent,
  AlertDialogHeader,
  AlertDialogOverlay,
  Text
} from '@chakra-ui/react';
import { withSuspenseAndErrorBoundary } from 'admin/components/Error/WithErrorBoundary';
import { ServiceForm } from 'admin/components/service/serviceForm';
import { useServiceUserPoolList } from 'admin/hooks/service/useServiceUserPoolList';
import { ServiceType } from 'admin/types/service';
import { ErrorContents, LoadingSpinner } from 'components/common/atoms';
import { FC, memo } from 'react';

type FetchServiceProps = {
  service: ServiceType;
  onClose: () => void;
};

const fetchService: FC<FetchServiceProps> = memo(
  ({ service, onClose }: FetchServiceProps) => {
    const { data: serviceUserPoolList } = useServiceUserPoolList();

    if (!service) {
      return null;
    }

    return (
      <ServiceForm
        service={service}
        serviceUserPoolList={serviceUserPoolList||[]}
        onClose={onClose}
      />
    );
  },
);

export const FetchService = memo(
  withSuspenseAndErrorBoundary(fetchService, {
    ErrorComponent: <ErrorContents name="ユーザープール割り当てダイアログ" />,
    LoadingComponent: <LoadingSpinner />,
  }),
);

type ServiceDialogProps = {
  service: ServiceType;
  onClose: () => void;
};

export const ServiceDialog: FC<ServiceDialogProps> = memo(
  ({ service, onClose }: ServiceDialogProps) => (
    <AlertDialogOverlay>
      <AlertDialogContent>
        <AlertDialogHeader>
          <Text fontSize="18px">ユーザープール割り当て</Text>
        </AlertDialogHeader>
        <FetchService service={service} onClose={onClose} />
        <AlertDialogCloseButton />
      </AlertDialogContent>
    </AlertDialogOverlay>
  ),
);
