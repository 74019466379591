import { fetchApiOrderChargeBack } from 'api/paywall/useApi';
import { ResponseError } from 'api/types';
import { useSearch } from 'components/opt/hooks/useSearch';
import { isOrderChargeBackResponseType, OrderChargeBackResponseType, OrderChargeBackTransactionMessage } from 'components/paywall/pages/OrderList/typed';
import { usePaywallUtil } from 'hooks/paywall/usePaywallUtil';
import { useCustomToast } from 'hooks/useCustomToast';
import { useUserTenantId } from 'hooks/user/useUserTenantId';
import { UseMutateFunction, useMutation } from 'react-query';
import { isGetNestedValidationError } from 'utils/form';
import { errorToast } from 'utils/toast';

type UnPromisify<T> = T extends Promise<infer U> ? U : T;

const orderChargeBackResponseTypeDefaultValue: OrderChargeBackResponseType = {
  gmoStatus: false,
  appStatus: false,
  messageApp: '',
  messageGmo: '',
  transaction: [],
  detail: ''
}

/**
 * チャージバック処理 hooks
 * @returns {Object} { onSubmit, isLoading }
 */
export const useOrderChargeBack = ({
  orderId,
  onClose,
  onOpenCbResponseModal
}:{
  orderId: string;
  onClose: () => void;
  onOpenCbResponseModal: (orderChargeBackResponse: OrderChargeBackResponseType) => void;
}): {
  onOrderChargeBack: UseMutateFunction<
    UnPromisify<ReturnType<typeof fetchApiOrderChargeBack>>,
    unknown,
    void,
    unknown
  >;
  isLoading: boolean;
  isSuccess: boolean;
} => {
  const tenantId = useUserTenantId();
  const toast = useCustomToast();
  const { makeApiQuery } = useSearch();
  const param = makeApiQuery();
  const { paywallOrderListRefetchQueries, paywallOrderDetailRefetchQueries } = usePaywallUtil();

  const {
    mutate: onOrderChargeBack,
    isLoading,
    isSuccess,
  } = useMutation(
    () => fetchApiOrderChargeBack({ tenantId, orderId }),
    {
      onSuccess: (result: OrderChargeBackResponseType | string | ResponseError) => {
        paywallOrderListRefetchQueries(param);
        paywallOrderDetailRefetchQueries(orderId);
        if (isOrderChargeBackResponseType(result)) {
          onClose();

          // チャージバック処理後は専用モーダルにてメッセージを表示する
          onOpenCbResponseModal(result)
        }

        if(typeof result === 'string') {
          toast({
            ...errorToast,
            title: '購入取り消し処理エラー',
            description: result,
            isClosable: true,
            duration: 4000,
          });
        }

        if(isGetNestedValidationError<OrderChargeBackResponseType>(result, Object.keys(orderChargeBackResponseTypeDefaultValue))) {

          if (result?.others && result?.others.length > 0) {
            const errorMsg = Object.entries(result).map(([_, value]) => value);
            toast({
              ...errorToast,
              title: '購入取り消し処理エラー',
              description: errorMsg[0],
              isClosable: true,
              duration: 7000,
            });
          }else{
            // 型ガードを実装したが、typescriptが上手く認識してくれない為、一旦下記の記述で実装を行う(2023.05.08 itsukaichi)
            const errorMsg = (result.transaction as unknown as OrderChargeBackTransactionMessage[]).map((x) => `${x.code}: ${x.message}`).join(`\n`);
            toast({
              ...errorToast,
              title: '購入取り消し処理エラー',
              description: errorMsg,
              isClosable: true,
              duration: 7000,
            });
          }
        }
      },
    },
  );

  return { onOrderChargeBack, isLoading, isSuccess };
};
