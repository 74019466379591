import { fetchApiOrderDelete } from 'api/paywall/useApi';
import { ResponseError } from 'api/types';
import { useSearch } from 'components/opt/hooks/useSearch';
import { DeleteOrderType } from 'components/paywall/pages/SubscriptionDetail/typed';
import { usePaywallUtil } from 'hooks/paywall/usePaywallUtil';
import { useCustomToast } from 'hooks/useCustomToast';
import { useUserTenantId } from 'hooks/user/useUserTenantId';
import { UseMutateFunction, useMutation } from 'react-query';
import { isGetValidationError } from 'utils/form';
import { errorToast, successToast } from 'utils/toast';

type UnPromisify<T> = T extends Promise<infer U> ? U : T;

const deleteOrderTypeDefaultValue: DeleteOrderType = {
  tenantId: '',
  wallOrderId: '',
}

/**
 * 注文キャンセル(サブスクリプション注文) hooks
 * @returns {Object} { onSubmit, isLoading }
 */
export const useDeleteSubscriptionOrder = ({
  wallOrderId,
  customerUid = '',
  onClose
}:{
  wallOrderId: string;
  customerUid?: string;
  onClose: () => void
}): {
  onOrderCancel: UseMutateFunction<
    UnPromisify<ReturnType<typeof fetchApiOrderDelete>>,
    unknown,
    void,
    unknown
  >;
  isLoading: boolean;
  isSuccess: boolean;
} => {
  const tenantId = useUserTenantId();
  const toast = useCustomToast();
  const { makeApiQuery } = useSearch();
  const param = makeApiQuery();
  const {
    paywalSubscriptionListRefetchQueries,
    paywalSubscriptionDetailRefetchQueries,
    paywallCustomerDetailRefetchQueries
  } = usePaywallUtil();

  const {
    mutate: onOrderCancel,
    isLoading,
    isSuccess,
  } = useMutation(
    () => fetchApiOrderDelete({ tenantId, wallOrderId }),
    {
      onSuccess: (result: string | boolean | ResponseError) => {
        paywalSubscriptionListRefetchQueries(param);
        paywalSubscriptionDetailRefetchQueries(wallOrderId);
        if(customerUid) paywallCustomerDetailRefetchQueries(customerUid);
        if (typeof result === 'boolean') {
          toast({
            ...successToast,
            duration: 4000,
            isClosable: true,
            title: '自動更新を停止しました',
          });
        }

        if(typeof result === 'string') {
          toast({
            ...errorToast,
            title: '自動更新停止エラー',
            description: result,
            isClosable: true,
            duration: 4000,
          });
        }

        if(isGetValidationError<DeleteOrderType>(result, Object.keys(deleteOrderTypeDefaultValue))) {
          if (result?.others) {
            const errorMsg = Object.entries(result).map(([_, value]) => value);
            toast({
              ...errorToast,
              title: '自動更新停止エラー',
              description: errorMsg[0],
              isClosable: true,
            });
          }
        }
        onClose();
      },
    },
  );

  return { onOrderCancel, isLoading, isSuccess };
};
