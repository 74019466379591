import { Box, Button, Flex, Text } from '@chakra-ui/react';
import { Colors } from 'admin/define/colors';
import { useUserCorporationInfo } from 'admin/hooks/user/useUserCorporationInfo';
import { FC, memo } from 'react';
import { MdOutlineAdd } from 'react-icons/md';

type Props = {
  onOpen: () => void;
};

export const TenantUsersTitleComponent: FC<Props> = memo(
  ({ onOpen }: Props) => {
    const { hasPermissionTenantUser } = useUserCorporationInfo();

    return (
      <Box mt="40px">
        <Flex justifyContent="flex-end">
          <Box mr="auto">
            <Text
              fontFamily="Hiragino Sans"
              fontSize="18px"
              fontWeight="700"
              lineHeight="170%"
            >
              テナント アカウント管理
            </Text>
            <Text
              fontWeight="400"
              fontSize="14px"
              lineHeight="170%"
              fontFamily="Hiragino Sans"
              color={Colors.GRAY_00}
            >
              テナント管理画面にアクセスできるユーザーを制御します。
            </Text>
          </Box>
          {hasPermissionTenantUser && (
            <Button
              colorScheme="primary"
              p="8px 24px"
              onClick={onOpen}
              leftIcon={<MdOutlineAdd />}
              minW="154px"
            >
              ユーザーを招待
            </Button>
          )}
        </Flex>
      </Box>
    );
  },
);
