import { FC, memo } from 'react';
import { Text } from '@chakra-ui/react';

interface TitleProps {
  text: string;
}

const titleStyle = {
  fontSize: 'xl',
  borderBottom: '1px solid #ccc',
};

export const Title: FC<TitleProps> = memo(({ text }: TitleProps) => (
  <Text mb={3} {...titleStyle}>
    {text}
  </Text>
));
