import { VFC, ReactNode } from 'react';
import { Flex, Stack, StackProps, Box, FlexboxProps } from '@chakra-ui/react';

import { Logo } from 'components/common/atoms/Logo';
import { NotificationBar } from 'components/common/atoms';
import { NOTIFICATION_MARGIN } from 'theme';

const DEPLOY_MODE = process.env.REACT_APP_DEPLOY_MODE;
const logPath = `${process.env.PUBLIC_URL}/static/login.svg`;

type TemplateNotLoggedProps = {
  children: ReactNode;
  formPosition?: FlexboxProps['alignItems'];
} & StackProps;

export const TemplateNotLogged: VFC<TemplateNotLoggedProps> = ({
  formPosition = 'center',
  flexDir = 'column',
  justifyContent = 'center',
  alignItems = 'center',
  spacing = 8,
  children,
  ...rest
}) => (
  <>
    <Box pb={NOTIFICATION_MARGIN}>
      {DEPLOY_MODE !== 'prd' && <NotificationBar />}
    </Box>
    <Flex
      flexDirection="column"
      width="100wh"
      height="100vh"
      backgroundColor="white"
      justifyContent="center"
      alignItems={formPosition}
      style={{
        backgroundImage: `url(${logPath})`,
        backgroundPosition: 'center center',
        backgroundAttachment: 'fixed',
        backgroundSize: 'cover',
      }}
    >
      <Stack
        flexDir={flexDir}
        justifyContent={justifyContent}
        alignItems={alignItems}
        spacing={spacing}
        {...rest}
      >
        <Box>
          <Logo width={192} />
        </Box>
        <Box minW={{ base: '90%', md: '368px' }}>{children}</Box>
      </Stack>
    </Flex>
  </>
);
