import { RepeatIcon } from '@chakra-ui/icons';
import {
  Box,
  Button,
  IconButton,
  Popover,
  PopoverArrow,
  PopoverBody,
  PopoverCloseButton,
  PopoverContent,
  PopoverFooter,
  PopoverHeader,
  PopoverTrigger,
  Text,
  Tooltip
} from '@chakra-ui/react';
import { NotificationIcon } from 'components/common/atoms';
import { FC, memo, useState } from 'react';
import { BsChevronLeft, BsChevronRight } from 'react-icons/bs';

export const NotificationErrorContents: FC = memo(() => {
  const [unreadCount] = useState(0);
  const [currentPage] = useState(1);

  return (
    <Popover closeOnBlur={false}>
      <PopoverTrigger>
        <Button
          variant="ghost"
          p={0}
          _focus={{ outline: 'none' }}
          _hover={{ bg: 'transparent' }}
          _active={{ bg: 'transparent' }}
        >
          <NotificationIcon unreadCount={unreadCount} />
        </Button>
      </PopoverTrigger>
      <PopoverContent boxShadow="2xl" _focus={{ outline: 'none' }}>
        <PopoverArrow bg="messenger.800" />
        <PopoverCloseButton />
        <PopoverHeader bg="messenger.800">メッセージボックス</PopoverHeader>
        <PopoverBody>
          <Box
            color="crimson"
          >
            申し訳ございません。メッセージ情報を取得できません。
          </Box>
          <Box
            display="flex" alignItems="center" pr={4}
          >
            <Box color="crimson">画面を再読み込みしてください。</Box>
            <Tooltip label="リロード" fontSize="xs" bg="gray.600">
              <RepeatIcon color="initial" cursor="pointer" marginLeft={1} onClick={() => window.location.reload()} />
            </Tooltip>
          </Box>
        </PopoverBody>
        <PopoverFooter color="black">
          <Box
            display="flex"
            justifyContent="space-between"
            alignItems="center"
          >
            <IconButton
              aria-label="NoticePrevBtn"
              icon={<BsChevronLeft />}
              fontSize={16}
              padding="6px"
              isDisabled
            />
            <Text>
              {currentPage} / 1
            </Text>
            <IconButton
              aria-label="NoticeNextBtn"
              icon={<BsChevronRight />}
              fontSize={16}
              padding="6px"
              isDisabled
            />
          </Box>
        </PopoverFooter>
      </PopoverContent>
    </Popover>
  );
});
