import { getTerms } from 'admin/api/userPool/terms/getTerms';
import { CustomError } from 'admin/error/CustomError';
import { convertToPage } from 'admin/schema/page';
import { UserPool } from 'admin/types/userPool';
import { TermsType } from 'admin/types/userPool/terms/index';
import { DataWithPage, Page } from 'api/common/types';
import { logger } from 'api/logger';
import { useQuery } from 'react-query';
import { queryKeys } from './queryKeys';

export const useTerms = ({
  id,
  page,
  perPage,
}: {
  id: UserPool['id'];
  page: Page['currentPage'];
  perPage: Page['perPage'];
}) => {
  const { data, refetch, isLoading } = useQuery({
    queryKey: queryKeys.getTerms(id, page, perPage),
    queryFn: () =>
      getTerms({
        id,
        options: {
          searchParams: {
            page,
            perPage,
          },
        },
      }),
    onError: async (error) => {
      if (error instanceof CustomError) {
        await logger({
          loglevel: error.logLevel,
          data: JSON.stringify(error.cause),
          message: error.message,
        });
      }
    },
  });

  return {
    data: data?.results,
    page: convertToPage<DataWithPage<TermsType>>(data),
    refetch,
    isLoading,
  };
};
