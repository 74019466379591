import { AuthHubEditor } from 'admin/components/authHub/authHubEditor';
import { AuthHubList } from 'admin/components/authHub/authHubList';
import { AuthHub } from 'components/authHub';
import { AUTH_CODE_TYPE_UNIIKEY, PERMISSION_DIRECTOR } from 'define';
import { useUserInfo } from 'hooks/useUserInfo';
import { Navigate, RouteObject } from 'react-router-dom';
import { AuthHubDetail } from './AuthHubDetail';

/**
 * 編集権限がない場合、リダイレクト
 */
const HasPermissionRoute = ({ children }: { children: JSX.Element }) => {
  const { userTenant } = useUserInfo();

  // 権限がない場合
  if (
    (userTenant?.permission && userTenant?.permission < PERMISSION_DIRECTOR) ||
    userTenant?.userPoolAuthenticationType !== AUTH_CODE_TYPE_UNIIKEY
  ) {
    return <Navigate to="/" replace />;
  }

  return children;
};

export const AuthHubRoutes: RouteObject[] = [
  {
    path: '',
    element: (
      <HasPermissionRoute>
        <AuthHub />
      </HasPermissionRoute>
    ),
    children: [
      {
        path: '',
        element: <AuthHubList />,
      },
      {
        path: ':authHubId',
        element: <AuthHubDetail />,
        children: [
          // テーマ
          {
            path: 'theme',
            element: <AuthHubEditor target="theme" />,
          },
          // ヘッダー
          {
            path: 'header',
            element: <AuthHubEditor target="header" />,
          },
          // フッター
          {
            path: 'footer',
            element: <AuthHubEditor target="footer" />,
          },
          // 新規登録画面
          {
            path: 'register',
            element: <AuthHubEditor target="register" />,
          },
          // 認証コード入力画面
          {
            path: 'verificationCodeEntry',
            element: <AuthHubEditor target="verificationCodeEntry" />,
          },
          // 会員情報新規入力画面
          {
            path: 'memberInfoEntry',
            element: <AuthHubEditor target="memberInfoEntry" />,
          },
          // 入力情報確認画面
          {
            path: 'infoConfirmation',
            element: <AuthHubEditor target="infoConfirmation" />,
          },
          // 新規登録完了画面
          {
            path: 'registrationComplete',
            element: <AuthHubEditor target="registrationComplete" />,
          },
          // ログイン
          {
            path: 'login',
            element: <AuthHubEditor target="login" />,
          },
          // パスワード作成画面
          {
            path: 'passwordCreate',
            element: <AuthHubEditor target="passwordCreate" />,
          },
          // パスワード未設定画面
          {
            path: 'passwordUnset',
            element: <AuthHubEditor target="passwordUnset" />,
          },
          // パスワードリセット画面
          {
            path: 'passwordReset',
            element: <AuthHubEditor target="passwordReset" />,
          },
          // 新パスワード入力画面
          {
            path: 'newPasswordEntry',
            element: <AuthHubEditor target="newPasswordEntry" />,
          },
          // サービス初回利用情報入力画面
          {
            path: 'firstInfoEntry',
            element: <AuthHubEditor target="firstInfoEntry" />,
          },
          // 不足情報入力画面
          {
            path: 'missingInfoEntry',
            element: <AuthHubEditor target="missingInfoEntry" />,
          },
          // 完了画面
          {
            path: 'complete',
            element: <AuthHubEditor target="complete" />,
          },
          // マイページ画面
          {
            path: 'mypage',
            element: <AuthHubEditor target="mypage" />,
          },
          // 会員情報変更画面
          {
            path: 'memberInfoChange',
            element: <AuthHubEditor target="memberInfoChange" />,
          },
          // 会員情報確認画面
          {
            path: 'memberInfoConfirm',
            element: <AuthHubEditor target="memberInfoConfirm" />,
          },
          // メールアドレス変更画面
          {
            path: 'emailChange',
            element: <AuthHubEditor target="emailChange" />,
          },
          // 新メアド入力画面
          {
            path: 'newEmailEntry',
            element: <AuthHubEditor target="newEmailEntry" />,
          },
          // パスワード変更画面
          {
            path: 'passwordChange',
            element: <AuthHubEditor target="passwordChange" />,
          },
          // 退会画面
          {
            path: 'withdrawal',
            element: <AuthHubEditor target="withdrawal" />,
          },
          // 退会確認画面
          {
            path: 'withdrawalConfirmation',
            element: <AuthHubEditor target="withdrawalConfirmation" />,
          },
          // 退会完了画面
          {
            path: 'withdrawalComplete',
            element: <AuthHubEditor target="withdrawalComplete" />,
          },
          // 各ページに埋め込むタグ
          {
            path: 'embeddedTags',
            element: <AuthHubEditor target="embeddedTags" />,
          },
        ],
      },
    ],
  },
];
