import { TransactionMailPersonalInnerComponent } from 'admin/components/idPoolConsumer/transactionMail/transactionMailPersonalInner/TransactionMailPersonalInnerComponent';
import {
  TransactionMailCategoryType,
  TransactionMailTypeStatusesType,
  TransactionMailTypeStatusType,
} from 'admin/types/userPool/transactionMail';
import { FC, memo, useMemo } from 'react';

type Props = {
  personalType: TransactionMailCategoryType;
  mailTypeStatuses: TransactionMailTypeStatusesType;
};

export const TransactionMailPersonalInner: FC<Props> = memo(
  ({ personalType, mailTypeStatuses }: Props) => {
    const transactionMailTypeStatus =
      useMemo<TransactionMailTypeStatusType>(() => {
        // mailsの取得ができなかった場合の処理
        if (mailTypeStatuses.length === 0) {
          return {
            type: 'authenticationCode',
            isActivated: true,
          };
        }

        return mailTypeStatuses.filter((x) => x.type === personalType)[0];
      }, [mailTypeStatuses, personalType]);

    return (
      <TransactionMailPersonalInnerComponent
        personalType={personalType}
        transactionMailTypeStatus={transactionMailTypeStatus}
      />
    );
  },
);
