import { VFC, memo, ReactNode } from 'react';
import { Box, Text } from '@chakra-ui/react';

type SkinItemPreviewProps = {
  element: ReactNode;
  width?: string;
  previewHeight?: string;
  posTop?: string;
  bgColor?: string;
};
export const SkinItemPreview: VFC<SkinItemPreviewProps> = memo(
  ({
    element,
    width = '95%',
    previewHeight = 'calc(100% - 20px)',
    bgColor = '#FFF',
  }) => (
    <Box w="100%" minW="300px" ml={4}>
      <Text as="span" fontSize="xs" color="teal.800">
        プレビュー
      </Text>
      <Box
        h={previewHeight}
        border="1px dashed #EEE"
        borderRadius="4px"
        position="relative"
        bgColor={bgColor}
      >
        <Box
          position="absolute"
          top="50%"
          left="50%"
          transform="translate(-50%, -50%)"
          w={width}
        >
          {element}
        </Box>
      </Box>
    </Box>
  ),
);
