import { Box } from '@chakra-ui/react';
import { useApiSubscriptionList } from 'api/paywall/useApi';
import { StatusTag } from 'components/paywall/features/StatusTag';
import { Td, Tr } from 'components/paywall/features/Table';
import { useTableNavigation } from 'components/paywall/features/Table/TableNavigation/hooks/useTableNavigation';
import {
  ApiSubscriptionList,
  SubscriptionListResult
} from 'components/paywall/pages/SubscriptionList/typed';
import { useFormatDate } from 'hooks/useFormatDate';
// import { format } from 'date-fns';
import { FC, Fragment, memo, useEffect, useState } from 'react';
import { NavLink } from 'react-router-dom';

const DATETIME_FORMAT = 'yyyy/MM/dd HH:mm';

export const SubscriptionRows: FC = memo(() => {
  const { data, isLoading, isFetching } = useApiSubscriptionList();
  const [results, setResults] = useState<SubscriptionListResult[]>([]);
  const { setCount } = useTableNavigation();
  const { dateTimeFormat } = useFormatDate();
  // const formatDate = (date: string | null): string => date ? format(new Date(date), DATETIME_FORMAT): ''

  // TODO: データ取得処理を見直す
  // custom hookを使用する際に返却するデータ型を特定する実装を行うことで
  // 下記のようなunknown型からの強制的な型変換が不要になることと、
  // 強制的な型変換は意図しないバグを引き起こす原因となるため避ける
  useEffect(() => {
    if (isLoading || isFetching) return;
    if (!data) return;
    const res = data as unknown as ApiSubscriptionList;
    setResults(res.data.results);
    setCount(res.data.count);
  }, [data, isFetching, isLoading, setCount, setResults]);

  const statusTypes = [
    {code: 1, status: 2, name: '終了済み'},
    {code: 2, status: 1, name: 'アクティブ'},
  ]

  const cancelReasonTypes = [
    {code: 3, status: 1, name: 'ユーザーキャンセル'},
    {code: 3, status: 2, name: '管理者キャンセル'},
    {code: 5, status: 3, name: '決済エラー'},
    {code: 4, status: 4, name: 'アップグレード'},
    {code: 1, status: 5, name: '退会'},
  ]

  // サブスクリプション一覧情報 > ステータス用ステータスコード取得
  const getStatus = (statusCode: number) => {
    const result = statusTypes.filter((item) => item.status === statusCode )

    // ステータスが見つからなかった場合はdefaultを返却する
    if(result.length === 0) return {
      code: 0,
      name: '',
      stauts: 0
    }

    return result[0];
  }

  // サブスクリプション一覧情報 > 終了理由用ステータスコード取得
  const getCancelReason = (cancelReasonCode: number) => {
    const cancelReasonResult = cancelReasonTypes.filter((cancelReason) => cancelReason.status === cancelReasonCode)

    // ステータスが見つからなかった場合はdefaultを返却する
    if(cancelReasonResult.length === 0) return {
      code: 0,
      name: '',
      stauts: 0
    }

    return cancelReasonResult[0];
  }

  return (
    <>
      {results.map((row: SubscriptionListResult) => (
        <Tr key={row.id}>
          <Td fontSize="12px" fontWeight="700" columnFix>
            <NavLink
              to={`/paywall/${row.id}/subscriptionDetail`}
              style={{ textDecoration: 'underline' }}
            >
              {row.id}
            </NavLink>
          </Td>
          <Td>{row.email}</Td>
          <Td>{row.customerUid}</Td>
          <Td>{row.courseId}</Td>
          <Td>{row.courseName}</Td>
          <Td><StatusTag type={getStatus(row.status).code} status={getStatus(row.status).name} /></Td>
          <Td>{dateTimeFormat(row.courseStart)}</Td>
          <Td>{dateTimeFormat(row.courseEnd)}</Td>
          <Td><StatusTag type={getCancelReason(row.cancelReason).code} status={getCancelReason(row.cancelReason).name}/></Td>
          <Td>
            {row.cardErrorInfo.map((info, idx) => (
              <Fragment key={`${info.code}_${String(idx)}`}>
                <Box>{info.code}</Box>
                <Box fontWeight="400">{info.message}</Box>
              </Fragment>
            ))}
          </Td>
        </Tr>
      ))}
    </>
  )
});
