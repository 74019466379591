import { getAttributeGroups } from 'admin/api/userPool/attributeGroup/getAttributeGroups';
import { CustomError } from 'admin/error/CustomError';
import { useCurrentPerPage } from 'admin/hooks/pagination/useCurrentPerPage';
import { convertToPage } from 'admin/schema/page';
import { UserPool } from 'admin/types/userPool';
import { AttributeGroupType } from 'admin/types/userPool/attributeGroup/index';
import { DataWithPage, Page } from 'api/common/types';
import { logger } from 'api/logger';
import { useQuery } from 'react-query';
import { queryAttributeGroupKey } from './queryAttributeGroupKey';

export const useAttributeGroups = (
  id: UserPool['id'],
  page: Page['currentPage'],
) => {
  const perPage = useCurrentPerPage();
  const { data, refetch, isLoading } = useQuery({
    queryKey: queryAttributeGroupKey.getAttributeGroups(id, page, perPage),
    queryFn: () => getAttributeGroups({ id, page, perPage }),
    onError: async (error) => {
      if (error instanceof CustomError) {
        await logger({
          loglevel: error.logLevel,
          data: JSON.stringify(error.cause),
          message: error.message,
        });
      }
    },
  });

  return {
    data: data?.results,
    page: convertToPage<DataWithPage<AttributeGroupType[]>>(data),
    refetch,
    isLoading,
  };
};
