import { VFC } from 'react';
import { Outlet } from 'react-router-dom';
import { Box, VStack } from '@chakra-ui/react';

import { useSetPageTitle } from 'hooks/useSetPageTitle';
import { SideMenyLayout } from 'components/common/templates/SideMenyLayout';
import { ContentsdbHeader } from 'components/contentsdb/templates/ContentsdbHeader';
import { ContentsdbSide } from 'components/contentsdb/templates/ContentsdbSide';

export const Contentsdb: VFC = () => {
  useSetPageTitle('コンテンツシェルフ');

  return (
    <VStack spacing={0} h="full">
      <Box
        w="full"
        h={12}
        flexBasis={12}
        flexShrink={0}
        borderBottom="1px"
        borderColor="teal.100"
      >
        <ContentsdbHeader />
      </Box>
      <SideMenyLayout sidemenu={<ContentsdbSide />} main={<Outlet />} />
      {/* フッター */}
      {/* <Box py={1} w="full">
        &nbsp;
      </Box> */}
    </VStack>
  );
};
