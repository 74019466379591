import { Options } from 'ky';
import { request } from 'api/request';
import { Pager, isPager } from 'api/types';
import { logger } from 'api/logger';
import { UploadHistory, isUploadHistoryList } from 'api/contents/types';
import { messages } from 'api/contents/messages';
import { CustomError } from 'api/error/CustomError';

type GetUploadHistory = {
  tenantId: string;
  tableId: string;
  page?: number;
};

export const getUploadHistory = async (
  params: GetUploadHistory,
  options?: Options,
): Promise<Pager<UploadHistory>> => {
  const { tenantId, tableId, page } = params;

  const queryParam = [];
  if (page) {
    queryParam.push(`page=${page}`);
  }

  const param = queryParam.length > 0 ? `?${queryParam.join('&')}` : '';
  const response = await request({
    path: `contentsdb/${tenantId}/${tableId}/upload/${param}`,
    method: 'get',
    options,
  });

  const responseData = await response.data;

  if (response.hasError) {
    if (response.status === 404) {
      throw new CustomError(
        messages.getUploadHistory.customer.notFound,
        response.status,
      );
    } else {
      throw new CustomError(
        messages.getUploadHistory.customer.error,
        response.status,
      );
    }
  }

  if (isPager<UploadHistory>(responseData, isUploadHistoryList)) {
    return responseData;
  }

  await logger({
    loglevel:
      response.status === 404 || response.status === 450 ? 'warning' : 'error',
    data: responseData,
    message: messages.getUploadHistory.system.typeError,
  });

  throw new CustomError(messages.getUploadHistory.customer.typeError);
};
