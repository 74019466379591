import {
  Box,
  FormControl,
  FormErrorMessage,
  Tab,
  TabList,
  TabPanel,
  TabPanels,
  Tabs,
} from '@chakra-ui/react';
import { HtmlEditor } from 'admin/components/idPoolConsumer/transactionMail/htmlEditor';
import { TextEditor } from 'admin/components/idPoolConsumer/transactionMail/textEditor';
import { Colors } from 'admin/define/colors';
import { TransactionMailFormType } from 'admin/types/userPool/transactionMail';
import { Dispatch, FC, memo, RefObject, SetStateAction } from 'react';
import EmailEditor from 'react-email-editor';
import { useFormState } from 'react-hook-form';
import { toErrMsgList } from 'utils/form';

type Props = {
  editor: RefObject<EmailEditor>;
  setIsLoading: Dispatch<SetStateAction<boolean>>;
  isLoading: boolean;
};

export const TransactionMailEditorBody: FC<Props> = memo(
  ({ setIsLoading, isLoading, editor }) => {
    const { errors } = useFormState<TransactionMailFormType>();

    return (
      <FormControl
        isInvalid={
          Boolean(errors.bodyHtml?.message) ||
          Boolean(errors.bodyText?.message) ||
          toErrMsgList(errors, 'bodyHtml').length > 0 ||
          toErrMsgList(errors, 'bodyText').length > 0
        }
      >
        {toErrMsgList(errors, 'bodyHtml').map((err) => (
          <FormErrorMessage key={`bodyHtml_${err}`}>{err}</FormErrorMessage>
        ))}
        {toErrMsgList(errors, 'bodyText').map((err) => (
          <FormErrorMessage key={`bodyText_${err}`}>{err}</FormErrorMessage>
        ))}
        <Box
          border={
            Boolean(errors.bodyHtml?.message) ||
            Boolean(errors.bodyText?.message) ||
            toErrMsgList(errors, 'bodyHtml').length > 0 ||
            toErrMsgList(errors, 'bodyText').length > 0
              ? `1px solid ${Colors.RED}`
              : 'inherit'
          }
          boxShadow={
            Boolean(errors.bodyHtml?.message) ||
            Boolean(errors.bodyText?.message) ||
            toErrMsgList(errors, 'bodyHtml').length > 0 ||
            toErrMsgList(errors, 'bodyText').length > 0
              ? `0 0 0 1px ${Colors.RED}`
              : 'inherit'
          }
        >
          <Tabs w="100%" bg={Colors.WHITE}>
            <TabList>
              <Tab _focus={{ focus: 'none' }}>HTMLメールエディター</Tab>
              <Tab _focus={{ focus: 'none' }}>テキストメールエディタ</Tab>
            </TabList>
            <TabPanels>
              <TabPanel p={0}>
                <HtmlEditor
                  editor={editor}
                  setIsLoading={setIsLoading}
                  isLoading={isLoading}
                />
              </TabPanel>
              <TabPanel p={0}>
                <TextEditor />
              </TabPanel>
            </TabPanels>
          </Tabs>
        </Box>
      </FormControl>
    );
  },
);
