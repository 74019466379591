import { request } from 'admin/api/Request';
import { API_MESSAGES } from 'admin/define/apiMessages';
import { CustomError } from 'admin/error/CustomError';
import { adminCorporationSchema } from 'admin/schema/corporation';
import { AdminCorporationType } from 'admin/types/corporation';
import { Options } from 'ky';
import { ZodError } from 'zod';

export const getCorporation = async ({
  id,
  options,
}: {
  id: string;
  options?: Options;
}): Promise<AdminCorporationType> => {
  const path = `corporation/${id}/`;
  const response = await request<AdminCorporationType>({
    path,
    method: 'get',
    options,
  });
  if (response.hasError) {
    if (response.status === 404) {
      throw new CustomError(
        API_MESSAGES.CORPORATION.GET_CORPORATION.NOT_FOUND,
        {
          path,
          status: response.status,
        },
      );
    } else {
      throw new CustomError(API_MESSAGES.CORPORATION.GET_CORPORATION.ERROR, {
        path,
        status: response.status,
        logLevel: response.status === 400 ? 'warning' : 'error',
      });
    }
  }

  try {
    adminCorporationSchema.parse(response.data);
  } catch (error) {
    if (error instanceof ZodError) {
      throw new CustomError(error.message, {
        path,
        status: response.status,
        logLevel: 'error',
      });
    }
  }

  return response.data;
};
