import { Text } from '@chakra-ui/react';
import { StatusBadge } from 'admin/components/member/statusBadge';
import { Td, Tr } from 'admin/components/ui/table';
import { Colors } from 'admin/define/colors';
import { ServiceMemberType } from 'admin/types/service';
import { MEMBER_STATUS_TYPE_LIST } from 'define';
import { FC, memo } from 'react';
import { NavigateFunction } from 'react-router-dom';

type Props = {
  member: ServiceMemberType;
  navigate: NavigateFunction;
};

export const ServiceTableBodyComponent: FC<Props> = memo(
  ({ member, navigate }: Props) => (
    <Tr>
      <Td>
        <Text
          color={Colors.BLUE_20}
          _hover={{ cursor: 'pointer', textDecoration: 'underline' }}
          onClick={() => navigate(`/admin/member/${member.id}`)}
        >
          {member.id}
        </Text>
      </Td>
      <Td>{member.email}</Td>
      <Td>{member.tel}</Td>
      <Td>{member.registeredDate}</Td>
      <Td>{member.lastLoginDate}</Td>
      <Td>
        <StatusBadge
          status={member.status}
          label={MEMBER_STATUS_TYPE_LIST.get(member.status)}
        />
      </Td>
    </Tr>
  ),
);
