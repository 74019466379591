import { WallItem } from 'components/paywall/pages/WallItemList/typed';

/**
 * WallItemFormType デフォルト値
 */
export const WallItemDefaultValues: WallItem = {
  id: '',
  wall: '',
  wallName: '',
  wallDesignId: '',
  name: '',
  url: '',
  price: 0,
  sellType: 'period',
  period: null,
  sellStart: null,
  sellEnd: null,
  systemCode: '',
  description: '',
  thumbnailUrl: '',
  useOgpFlag: false,
  tags: '',
  memo: '',
  createdAt: '',
  updatedAt: '',
  sellStatus: 0,
  isCheckSkipUrl: false,
} as const;
