import { getUserPoolsCreatedCount } from 'admin/api/userPool/getUserPoolsCreatedCount';
import { CustomError } from 'admin/error/CustomError';
import { useUserCorporationInfo } from 'admin/hooks/user/useUserCorporationInfo';
import { UserPoolsCreatedCount } from 'admin/types/userPool';
import { logger } from 'api/logger';
import { useQuery } from 'react-query';
import { queryIdPoolConsumersKey } from './queryIdPoolConsumersKey';

export const useUserPoolsCreatedCount = (): UserPoolsCreatedCount => {
  const { currentUserPoolGroupId } = useUserCorporationInfo();
  const { data } = useQuery({
    queryKey: queryIdPoolConsumersKey.getIdPoolConsumerUserPoolsCreatedCount(
      currentUserPoolGroupId,
    ),
    queryFn: () =>
      getUserPoolsCreatedCount({
        currentUserPoolGroupId: currentUserPoolGroupId || '',
      }),
    onError: async (error) => {
      if (error instanceof CustomError) {
        await logger({
          loglevel: error.logLevel,
          data: JSON.stringify(error.cause),
          message: error.message,
        });
      }
    },
  });

  // ユーザープールの上限数などの取得に失敗した場合はそれぞれ0件を返却する
  if (!data) {
    const noDataResult: UserPoolsCreatedCount = { max: 0, count: 0 };

    return noDataResult;
  }

  return data;
};
