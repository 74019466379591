import { UseMutateFunction, useMutation } from 'react-query';
import { UseFormSetError } from 'react-hook-form';
import { useNavigate } from 'react-router-dom';
import { postUserPasswordForget } from 'api/user/postUserPasswordForget';
import { ResponseError } from 'api/types';
import { PasswordResetFormForget } from 'api/user/types';
import { isGetValidationError, toMultiError } from 'utils/form';
import { useCustomToast } from 'hooks/useCustomToast';

type UnPromisify<T> = T extends Promise<infer U> ? U : T;

const useUserPasswordForgetType: PasswordResetFormForget = {
  email: '',
};

/**
 * テナントユーザー設定変更hooks
 * @param {Object} UseFormSetError setError
 * @param {setGlobalErrors} callback setGlobalErrors
 * @returns {Object} { onSubmit, isLoading }
 */
export const useUserPasswordForget = ({
  setError,
  setGlobalErrors,
}: {
  setError: UseFormSetError<PasswordResetFormForget>;
  setGlobalErrors: (errors: string[]) => void;
}): {
  onSubmit: UseMutateFunction<
    UnPromisify<ReturnType<typeof postUserPasswordForget>>,
    unknown,
    PasswordResetFormForget,
    unknown
  >;
  isLoading: boolean;
  isSuccess: boolean;
} => {
  const navigate = useNavigate();
  const toast = useCustomToast();
  const {
    mutate: onSubmit,
    isLoading,
    isSuccess,
  } = useMutation(
    ({ email }: PasswordResetFormForget) => postUserPasswordForget({ email }),
    {
      onSuccess: (result: ResponseError | boolean) => {
        // グローバルエラーを初期化
        setGlobalErrors([]);
        if (typeof result === 'boolean' && result) {
          toast({
            status: 'success',
            position: 'bottom',
            duration: 2000,
            isClosable: true,
            title: 'パスワード再設定用のURLを送信いたしました',
          });
          // 未ログイン時のTOPページへ遷移する
          navigate('/');

          return;
        }

        if (
          isGetValidationError<PasswordResetFormForget>(
            result,
            Object.keys(useUserPasswordForgetType),
          )
        ) {
          if (result?.others) {
            setGlobalErrors(result?.others);
          } else {
            Object.keys(result).forEach((k) => {
              const key = k as keyof PasswordResetFormForget;
              const errMsgs = result?.[key];
              if (errMsgs) {
                setError(key, { types: toMultiError(errMsgs) });
              }
            });
          }
        }
      },
    },
  );

  return { onSubmit, isLoading, isSuccess };
};
