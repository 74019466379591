import { getIdPoolConsumers } from 'admin/api/idPoolConsumers/getIdPoolConsumers';
import { CustomError } from 'admin/error/CustomError';
import { queryIdPoolConsumersKey } from 'admin/hooks/idPoolConsumers/queryIdPoolConsumersKey';
import { useUserCorporationInfo } from 'admin/hooks/user/useUserCorporationInfo';
import { logger } from 'api/logger';
import { useCustomToast } from 'hooks/useCustomToast';
import { useQuery } from 'react-query';
import { errorToast } from 'utils/toast';

export const useServiceUserPoolList = () => {
  const toast = useCustomToast();
  const { currentUserPoolGroupId } = useUserCorporationInfo();

  const { data } = useQuery({
    queryKey: queryIdPoolConsumersKey.getIdPoolConsumers(currentUserPoolGroupId || ''),
    queryFn: () => getIdPoolConsumers({id: currentUserPoolGroupId || ''}),
    onError: async (error) => {
      if (error instanceof CustomError) {
        await logger({
          loglevel: error.logLevel,
          data: JSON.stringify(error.cause),
          message: error.message,
        });
      }
      toast({
        ...errorToast,
        duration: 4000,
        title: '使用するユーザープール一覧取得に失敗しました',
        position: 'bottom',
        variant: 'solid',
      });
    },
  });

  return { data };
};
