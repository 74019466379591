import {
  Box, Flex, Image, NumberDecrementStepper, NumberIncrementStepper, NumberInput,
  NumberInputField,
  NumberInputStepper, Select, Text, VStack
} from '@chakra-ui/react';
import { gcsFileUpload } from 'api/storage/gcsFileUpload';
import { isGcsFileUploadResponse } from 'api/storage/types';
import { Toggle } from 'components/enquete/atoms/Toggle';
import { useCustomToast } from 'hooks/useCustomToast';
import { useUserTenantId } from 'hooks/user/useUserTenantId';
import { FC, useCallback, useEffect, useState } from 'react';
import { useDropzone } from 'react-dropzone';
import { FieldValues, Path, PathValue, UnpackNestedValue, useFormContext } from 'react-hook-form';
import { BiImageAdd } from 'react-icons/bi';
import { errorToast, successToast } from 'utils/toast';

type EnqueteFormType<T> = {
  baseName: Path<T>;
  index: number;
};

export const ImageUploader = <T extends FieldValues>({ baseName, index }: EnqueteFormType<T>): ReturnType<FC> => {
  const [files, setFiles] = useState('');
  const [toggle, setToggle] = useState(false);
  const [isUploading, setIsUploading] = useState(false);
  const toast = useCustomToast();
  const tenantId = useUserTenantId();
  const { register, setValue, getValues } = useFormContext<T>();

  useEffect(() => {
    const imageUploadFilePath =
      getValues(`${baseName}.${index}.imageUpload` as Path<T>) || '';
    if (imageUploadFilePath === '') return;
    setFiles(imageUploadFilePath);
  }, [getValues, index]);

  const onDrop = useCallback(
    (acceptedFiles: File[]) => {
      if (acceptedFiles.length !== 0) setFiles('/test.png');
      setIsUploading(true);
      const unixTimeStamp = Math.floor(new Date().getTime() / 1000);
      const uploadPath = `public/enquete/${unixTimeStamp}_${acceptedFiles[0].name}`;
      gcsFileUpload({
        file: acceptedFiles[0],
        filePath: uploadPath,
        tenantId,
      })
        .then((res) => {
          if (isGcsFileUploadResponse(res)) {
            setFiles(res.publicUrl);
            setValue(`${baseName}.${index}.imageUpload` as Path<T>, res.publicUrl as UnpackNestedValue<PathValue<T, Path<T>>>);
            setIsUploading(false);
            toast({
              ...successToast,
              title: 'ファイルをアップロードしました。',
            });
          }
        })
        .catch((_) => {
          toast({
            ...errorToast,
            title: 'ファイルアップロードに失敗しました。',
          });
        });
    },
    [setValue, index, tenantId, toast],
  );

  const { getRootProps, getInputProps } = useDropzone({
    onDrop,
    accept: 'image/*',
    multiple: false,
  });

  const style = {
    width: '100%',
    background: '#e7fff9',
    cursor: 'pointer',
    padding: '80px 20px',
  };

  const styleIcon = {
    display: 'block',
    fontSize: '3rem',
    margin: '0 auto 10px',
  };

  const position = [
    { id: 1, name: '左', value: 'left' },
    { id: 2, name: '中央', value: 'center' },
    { id: 3, name: '右', value: 'right' },
  ];

  return (
    <>
      <VStack w="100%" display={toggle ? 'none' : 'block'}>
        <div
          {...getRootProps({ style })}
          style={{
            padding: '80px 20px',
            color: getValues('uneditableState' as Path<T>) ? '#AAA' : '#1a202c',
            backgroundColor: getValues('uneditableState' as Path<T>)
              ? '#EEE'
              : 'rgb(231, 255, 249)',
            cursor: getValues('uneditableState' as Path<T>) ? 'not-allowed' : 'pointer',
          }}
        >
          <input {...getInputProps()} disabled={getValues('uneditableState' as Path<T>)} />
          <p>
            <BiImageAdd style={styleIcon} />
            ファイルをドラッグ＆ドロップするかクリックして画像をアップロードしてください
          </p>
        </div>
        <Flex alignItems="center" justifyContent="center" pt={4}>
          <Text mr={4}>配置</Text>
          <Select
            w={200}
            {...register(`${baseName}.${index}.imageUploadAlign` as Path<T>)}
            style={{
              backgroundColor: '#f9f9f9',
              border: '1px solid #ccc',
              borderRadius: '5px',
              padding: '10px 12px',
            }}
            isDisabled={getValues('uneditableState' as Path<T>)}
          >
            {position.map((item) => (
              <option key={item.id} value={item.value}>
                {item.name}
              </option>
            ))}
          </Select>
          <Text ml={8} mr={4}>
            画像サイズ
          </Text>
          <NumberInput
            w={100}
            defaultValue={100}
            min={50}
            max={100}
            mr={4}
            isDisabled={getValues('uneditableState' as Path<T>)}
          >
            <NumberInputField
              {...register(`${baseName}.${index}.imageUploadSize` as Path<T>)}
              backgroundColor="#f9f9f9"
              borderColor="#ccc"
            />
            <NumberInputStepper>
              <NumberIncrementStepper />
              <NumberDecrementStepper />
            </NumberInputStepper>
          </NumberInput>
          %
        </Flex>
        {files && (
          <Box>
            {!isUploading && files !== '' ? (
              <Image src={files} w="200px" margin="0 auto" />
            ) : (
              <></>
            )}
          </Box>
        )}
      </VStack>
      <Toggle toggle={toggle} setToggle={setToggle} />
    </>
  );
};
