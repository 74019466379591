import {
  Box,
  BoxProps,
  Flex,
  List,
  ListIcon,
  ListItem,
  ListItemProps,
  ListProps,
  Tooltip,
  useStyleConfig,
} from '@chakra-ui/react';
import { useCustomNavigate } from 'hooks/useCustomNavigate';
import { memo, useCallback, VFC } from 'react';
import { IconType } from 'react-icons';

type CustomMenuBoxProps = {
  // メニュー開閉(true: オープン, false: クローズ)
  isOpen?: boolean;
} & BoxProps;

export const CustomMenuBox: VFC<CustomMenuBoxProps> = memo(
  ({ isOpen = false, ...rest }) => {
    const props = { isOpen, ...rest };
    const styles = useStyleConfig('CustomMenuBox', props);

    return <Box sx={styles} {...rest} />;
  },
);

export const CustomMenuList: VFC<ListProps> = memo((props) => {
  const styles = useStyleConfig('CustomMenuList', props);

  return <List sx={styles} {...props} />;
});

type CustomMenuListItemProps = {
  icon: IconType;
  name: string;
  tLabel?: string;
  to?: string;
  // 選択中フラグ (true: 選択中, false: 非選択)
  isActive?: boolean;
  href?: string;
  onClick?: () => void;
  fontSize?: string;
} & ListItemProps;

export const CustomMenuListItem: VFC<CustomMenuListItemProps> = memo(
  ({
    icon,
    name,
    tLabel = '',
    to = '',
    isActive = false,
    href = '',
    onClick = () => undefined,
    fontSize = '',
    ...rest
  }) => {
    const { navigate } = useCustomNavigate({});
    const props = { isActive, ...rest };
    const styles = useStyleConfig('CustomMenuListItem', props);

    const next = useCallback(() => {
      onClick();
      if (to !== '') navigate(to);
      if (href !== '') window.open(href, '_blank');
    }, [to, href, navigate, onClick]);

    return (
      <Tooltip
        label={tLabel !== '' ? tLabel : name}
        bgColor="white"
        color="black"
      >
        <ListItem sx={styles} {...rest} onClick={() => next()} cursor="pointer">
          <Flex alignItems="center" h="full">
            <ListIcon as={icon} fontSize={fontSize || '2xl'} />
            <Box ml={4} fontSize="sm">
              {name}
            </Box>
          </Flex>
        </ListItem>
      </Tooltip>
    );
  },
);
