import { Box, FormControl, FormLabel, ListItem, Text, UnorderedList, useDisclosure } from '@chakra-ui/react';
import { InputText } from 'components/common/atoms';
import { Colors, ColorSet } from 'components/paywall/config';
import { DetailDialog } from 'components/paywall/features/Detail';
import { OrderChargeBackResponseType } from 'components/paywall/pages/OrderList/typed';
import { useOrderChargeBack } from 'hooks/paywall/useOrderChargeBack';
import { ChangeEvent, FC, memo, useCallback, useState } from 'react';

const dialogConfirmText = 'change';

type Props = {
  orderId: string;
  isShowButton: boolean;
  onOpenCbResponseModal: (orderChargeBackResponse: OrderChargeBackResponseType) => void;
}

export const DetailOrderChargeBackDialog: FC<Props> = memo(({orderId, isShowButton, onOpenCbResponseModal}) => {
  const { isOpen, onOpen, onClose } = useDisclosure();
  const [confirmText, setConfirmText] = useState<string>('');

  // closeボタン押下時
  const closeBtnHandler = useCallback(() => {
    setConfirmText('');
    onClose();
  }, [
    setConfirmText,
    onClose
  ]);
  const { onOrderChargeBack, isLoading } = useOrderChargeBack({orderId, onClose: closeBtnHandler, onOpenCbResponseModal});


  // 購入取り消し処理 実行ボタン押下処理
  const onChargeBack = useCallback(() => {
    onOrderChargeBack();
  }, [onOrderChargeBack]);

  const onChangeText = useCallback((e: ChangeEvent<HTMLInputElement>) => {
    setConfirmText(e.target.value);
  }, []);

  // フォームエラーの存在チェック
  const isFailed = useCallback(
    (): boolean => confirmText !== 'change',
    [confirmText],
  );

  return (
    <DetailDialog
      title="購入取り消し実行の確認"
      isOpen={isOpen}
      onOpen={onOpen}
      onClose={onClose}
      openBtnElement="購入取り消し実行の確認"
      openBtnProps={{
        fontSize: '14px',
        minWidth: '100px',
        disabled: !isShowButton,
        ...ColorSet.RED
      }}
      submitBtnTitle="実行"
      submitBtnProps={{
        colorScheme: 'red',
        disabled: isFailed() || isLoading,
        isLoading,
      }}
      submitBtnHandelr={onChargeBack}
      cancelBtnTitle="閉じる"
    >
      <Box
        backgroundColor={Colors.RED_40}
        p={4}
        mb={4}
      >
        <UnorderedList>
          <ListItem fontSize="14px" fontWeight="700" color={Colors.RED_20}>単品商品の場合購入取り消しとなり記事の閲覧が不可能になります。</ListItem>
          <ListItem fontSize="14px" fontWeight="700" color={Colors.RED_20}>サブスクリプションの場合は即キャンセルとなり契約頂いている記事の閲覧が不可能になります。</ListItem>
          <ListItem fontSize="14px" fontWeight="700" color={Colors.RED_20}>顧客への返金処理も同時に実行します。ただし既に決済代行側で返金済みの場合は返金処理は実行されません。</ListItem>
        </UnorderedList>
      </Box>
      <Box mb={4}>
        <Text fontSize="14px" fontWeight="400">
          <Text as="span" fontWeight="700" mr={1}>{ orderId || '' }</Text>
          の購入取り消しを実行しますか？
        </Text>
        <Text fontSize="14px" fontWeight="700" color={Colors.RED_20}>この手続きは取り消しすることが出来ません。</Text>
      </Box>

      <FormControl>
        <FormLabel htmlFor="deleteText" fontSize="sm">
          実行する場合は {dialogConfirmText} と入力してください
        </FormLabel>
        <InputText
          name="deleteText"
          onChange={(e) => onChangeText(e)}
        />
      </FormControl>
    </DetailDialog>
  )
});
