import { Box, Checkbox, Text, VStack } from '@chakra-ui/react';
import { RichTextElement } from 'components/enquete/atoms/RichTextElement';
import { Toggle } from 'components/enquete/atoms/Toggle';
import { Dispatch, FC, SetStateAction, useState } from 'react';
import { FieldValues, Path, useFormContext } from 'react-hook-form';

type EnqueteFormType<T> = {
  baseName: Path<T>;
  index: number;
  setIsEdited: Dispatch<SetStateAction<boolean>>;
};

export const ExplainTextElement = <T extends FieldValues>({ baseName, index, setIsEdited }: EnqueteFormType<T>): ReturnType<FC> => {
  const [toggle, setToggle] = useState(false);
  const { getValues, register } = useFormContext<T>();

  return (
    <>
      <VStack w="100%" display={toggle ? 'none' : 'block'}>
        <RichTextElement<T>
          baseName={baseName}
          index={index}
          position="wysiwyg"
          value={getValues(`${baseName}.${index}.wysiwyg` as Path<T>) || ''}
          setIsEdited={setIsEdited}
          row={10}
        />
        <Box textAlign="left">
          <Checkbox
            {...register(`${baseName}.${index}.isTextScroll` as Path<T>)}
            style={{ margin: '4px 0 0' }}
            isDisabled={getValues('uneditableState' as Path<T>)}
            defaultChecked={false}
          >
            <Text fontSize={14}>
              インラインフレーム形式で表示する。（説明文章が11行以上存在する場合にのみ適用されます）
            </Text>
          </Checkbox>
        </Box>
      </VStack>
      <Toggle toggle={toggle} setToggle={setToggle} />
    </>
  );
};
