import { MemberType } from 'admin/types/member';
import { ServiceType } from 'admin/types/service';
import { UserPool } from 'admin/types/userPool';
import { Page } from 'api/common/types';

export const queryKeyNames = {
  getMembers: 'getMembers',
  getMemberIdPoolConsumers: 'getMemberIdPoolConsumers',
  getMember: 'getMember',
  getMemberAttributes: 'getMemberAttributes',
  getMemberLogs: 'getMemberLogs',
  getMemberServices: 'getMemberServices',
};

export const queryMemberKey = {
  getMembers: (
    userPoolId: UserPool['id'],
    page: Page['currentPage'],
    perPage?: Page['perPage'],
    keyword?: string,
  ) => [queryKeyNames.getMembers, userPoolId, page, perPage, keyword],
  getMemberIdPoolConsumers: () => [queryKeyNames.getMemberIdPoolConsumers],
  getMember: (
    userPoolId: MemberType['userPool'],
    memberId: MemberType['uid'],
  ) => [queryKeyNames.getMember, userPoolId, memberId],
  getMemberAttributes: (
    userPoolId: MemberType['userPool'],
    memberId: MemberType['uid'],
    page: Page['currentPage'],
    perPage: Page['perPage'],
  ) => [queryKeyNames.getMemberAttributes, userPoolId, memberId, page, perPage],
  getMemberLogs: (
    memberId: MemberType['uid'],
    page: Page['currentPage'],
    perPage: Page['perPage'],
  ) => [queryKeyNames.getMemberLogs, memberId, page, perPage],
  getMemberServices: (serviceId: ServiceType['id']) => [
    queryKeyNames.getMemberServices,
    serviceId,
  ],
} as const;
