import {
  Button,
  HStack,
  ModalBody,
  ModalCloseButton,
  ModalContent,
  ModalFooter,
  ModalHeader,
  ModalOverlay,
  Text,
} from '@chakra-ui/react';
import { ColorSet } from 'admin/define/colors';
import { ServiceTermType } from 'admin/types/service/terms';
import { ConfirmSubmitFormType } from 'admin/types/utils/index';
import { FC, memo } from 'react';
import { useFormContext, useFormState } from 'react-hook-form';

export type Props = {
  formId: string;
  target?: ServiceTermType;
  isLoading: boolean;
  onClose: () => void;
  onSubmit: () => void;
};

export const PublicChangeComponent: FC<Props> = memo(
  ({ formId, target, isLoading, onClose, onSubmit }) => {
    const { handleSubmit } = useFormContext();
    const { isValid } = useFormState<ConfirmSubmitFormType>();

    if (!target) {
      return null;
    }

    return (
      <>
        <ModalOverlay />
        <ModalContent fontSize="md" zIndex={100}>
          <form onSubmit={handleSubmit(onSubmit)} id={formId}>
            <ModalHeader fontSize="lg" fontWeight="bold" p="6">
              利用設定変更の確認
            </ModalHeader>
            <ModalBody>
              <Text fontSize="16px">
                利用設定を
                <Text as="span" fontWeight="bold">
                  「{`${target.isPublic ? '利用しない' : '利用する'}`}」
                </Text>
                に切り替えます。
                <br />
                影響範囲を十分に確認した上で実行してください。
              </Text>
            </ModalBody>

            <ModalFooter p="6">
              <HStack spacing={4}>
                <Button
                  variant="outline"
                  onClick={() => onClose()}
                  {...ColorSet.Default}
                  padding="8px 16px"
                >
                  キャンセル
                </Button>
                <Button
                  colorScheme="primary"
                  type="submit"
                  mr={3}
                  form={formId}
                  disabled={!isValid || isLoading}
                  padding="8px 36px"
                >
                  実行
                </Button>
              </HStack>
            </ModalFooter>
            <ModalCloseButton top="21px" right="21px" />
          </form>
        </ModalContent>
      </>
    );
  },
);
