import {
  maxLength,
  headTitleOptions,
  screenNameOptions,
  buttonNameOptions,
  textareaElementOptions,
} from './options';

const groupName = 'mypage';

// マイページ画面
export default [
  // ロゴ	画像
  {
    name: `${groupName}.headTitle`,
    label: 'ブラウザタブ表示名',
    tooltip: '',
    type: 'Text',
    defaultValue: 'マイページ',
    options: headTitleOptions,
    elementOptions: {},
  },
  {
    name: `${groupName}.screenName`,
    label: '画面名',
    tooltip: '',
    type: 'Text',
    defaultValue: 'マイページ',
    options: screenNameOptions,
    elementOptions: {},
  },
  {
    name: `${groupName}.description`,
    label: '案内文 マイページ',
    tooltip: '',
    type: 'Textarea',
    defaultValue:
      '会員情報を管理します。\n利用状況や設定のご確認や、必要に応じた情報変更が可能です。',
    options: {
      maxLength: maxLength(50),
    },
    elementOptions: textareaElementOptions,
  },
  {
    name: `${groupName}.description2`,
    label: '案内文2 マイページ',
    tooltip: '',
    type: 'Textarea',
    defaultValue: '',
    options: {
      maxLength: maxLength(50),
    },
    elementOptions: textareaElementOptions,
  },
  {
    name: `${groupName}.infoChangeButtonName`,
    label: 'ボタン名 会員情報の確認・変更',
    tooltip: '',
    type: 'Text',
    defaultValue: '会員情報の確認・変更',
    options: buttonNameOptions,
    elementOptions: {},
  },
  {
    name: `${groupName}.emailChangeButtonName`,
    label: 'ボタン名 メールアドレス変更',
    tooltip: '',
    type: 'Text',
    defaultValue: 'メールアドレス変更',
    options: buttonNameOptions,
    elementOptions: {},
  },
  {
    name: `${groupName}.passwordChangeButtonName`,
    label: 'ボタン名 パスワード変更',
    tooltip: '',
    type: 'Text',
    defaultValue: 'パスワード変更',
    options: buttonNameOptions,
    elementOptions: {},
  },
  {
    name: `${groupName}.logoutButtonName`,
    label: 'ボタン名 ログアウト',
    tooltip: '',
    type: 'Text',
    defaultValue: 'ログアウト',
    options: buttonNameOptions,
    elementOptions: {},
  },
  {
    name: `${groupName}.withdrawalProcedureButtonName`,
    label: 'ボタン名 退会手続き',
    tooltip: '',
    type: 'Text',
    defaultValue: '退会手続き',
    options: buttonNameOptions,
    elementOptions: {},
  },
] as const;
