import { Detail, DetailBlock } from 'components/paywall/features/Detail';
import { DetailMemo } from 'components/paywall/features/Detail/DetailMemo';
import { CustomerDetailMemo } from 'components/paywall/pages/CustomerList/typed';
import { FC, memo as rMemo } from 'react';

type Props = {
  memos: CustomerDetailMemo[];
}

export const SubscriptionDetailInnerMemos: FC<Props> = rMemo(({memos} : Props) => {

  if(memos.length === 0) return (<Detail type={1} title="メモ"/>);

  return (
    <Detail type={1} title='メモ'>
      {memos.map((memo, idx) => (
        <DetailBlock space={16} key={`${memo.customerUid}_${String(idx)}`}>
          <DetailMemo date={memo.createdAt || ''} text={memo.memo || ''} author={memo.createUserName || ''} />
        </DetailBlock>
      ))}
    </Detail>
  )
});
