import { request } from 'admin/api/Request';
import { API_MESSAGES } from 'admin/define/apiMessages';
import { CustomError } from 'admin/error/CustomError';
import { TransactionMailTypeStatusesSchema } from 'admin/schema/idPoolConsumer/transactionMail';
import { TransactionMailTypeStatusesType } from 'admin/types/userPool/transactionMail';
import { camelCase } from 'utils/str';
import { ZodError } from 'zod';

export const getTransactionMailTypeStatuses = async (
  idPoolConsumerId: string,
): Promise<TransactionMailTypeStatusesType> => {
  const path = `uniikey/${idPoolConsumerId}/transaction_mail/mail_type/status/`;
  const response = await request<TransactionMailTypeStatusesType>({
    path,
    method: 'get',
  });

  if (response.hasError) {
    if (response.status === 404) {
      throw new CustomError(
        API_MESSAGES.USER_POOL.GET_TRANSACTION_MAIL_STAUTSES.NOT_FOUND,
        {
          path,
          status: response.status,
        },
      );
    } else {
      throw new CustomError(
        API_MESSAGES.USER_POOL.GET_TRANSACTION_MAIL_STAUTSES.ERROR,
        {
          path,
          status: response.status,
        },
      );
    }
  }

  const transactiomMailStatuses = response.data.map((val) => ({
    ...val,
    type: camelCase(val.type),
  })) as TransactionMailTypeStatusesType;

  try {
    TransactionMailTypeStatusesSchema.parse(transactiomMailStatuses);
  } catch (error) {
    if (error instanceof ZodError) {
      throw new CustomError(error.message, {
        path,
        status: response.status,
        logLevel: 'error',
      });
    }
  }

  return transactiomMailStatuses;
};
