import produce from 'immer';
import { Options } from 'ky';
import { request } from 'api/request';
import { logger } from 'api/logger';
import {
  CsvFileUpload,
  CsvFileUploadResponse,
  isCsvFileUploadResponse,
  GcsFileUploadErrorResponse,
  isGcsFileUploadErrorResponse,
} from 'api/storage/types';
import { ResponseError } from 'api/types';
import { ValidationError } from 'utils/form';
import { messages } from './messages';

const DEFAULT_ERR_MESSAGE: ValidationError<CsvFileUpload> = {
  others: [messages.csvFileUpload.customer.error],
};
const DEFAULT_SYSTEM_ERR_MESSAGE: ValidationError<CsvFileUpload> = {
  others: [messages.csvFileUpload.customer.failed],
};

/**
 * CSVファイルアップロードAPI
 *
 * 以下のレスポンスを返却します
 * 正常時: true, 異常時: false
 *
 */
export const csvFileUpload = async ({
  file,
  filePath,
  tenantId,
  mailId,
  options,
}: {
  file: File;
  filePath: string;
  tenantId: string;
  mailId: string;
  options?: Options;
}): Promise<
  CsvFileUploadResponse | boolean | ResponseError | GcsFileUploadErrorResponse
> => {
  const formData = new FormData();
  formData.append('file', file);
  formData.append('filepath', filePath);
  const timeout = 1000 * 60 * 3;

  const mergedOptions = options
    ? produce(options, (draft) => {
        draft.body = formData;
        draft.timeout = timeout;
      })
    : { body: formData, timeout };

  const response = await request({
    path: `mail/${tenantId}/${mailId}/csv/`,
    method: 'post',
    options: mergedOptions,
  });

  const responseData = response.data;

  // ファイルアップロード成功
  if (isCsvFileUploadResponse(responseData)) {
    return responseData;
  }

  // アップロードエラー
  if (isGcsFileUploadErrorResponse(response.error)) {
    return response.error;
  }

  if (response.status >= 500) {
    return DEFAULT_ERR_MESSAGE;
  }

  if (response.status === 400) {
    return DEFAULT_SYSTEM_ERR_MESSAGE;
  }

  if (response.hasError) return DEFAULT_SYSTEM_ERR_MESSAGE;

  const { error } = response;
  // 予期せぬエラーなので
  // バックエンドに状態を通知
  await logger({
    loglevel:
      response.status === 404 || response.status === 450 ? 'warning' : 'error',
    data: error,
    message: messages.csvFileUpload.system.typeError,
  });

  // 登録できていない旨を通知
  return DEFAULT_SYSTEM_ERR_MESSAGE;
};
