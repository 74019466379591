import { VFC } from 'react';
import { Outlet } from 'react-router-dom';
import { VStack } from '@chakra-ui/react';

import { useSetPageTitle } from 'hooks/useSetPageTitle';
import { SideMenyLayout } from 'components/common/templates/SideMenyLayout';
import { SettingsSide } from 'components/setting/SettingsSide';

export const Setting: VFC = () => {
  useSetPageTitle('設定');

  return (
    <VStack spacing={0} h="full">
      <SideMenyLayout sidemenu={<SettingsSide />} main={<Outlet />} />;
    </VStack>
  );
};
