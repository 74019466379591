// ----- fetch関連 -----
const GET_CORPORATION = {
  ERROR:
    '申し訳ございません。法人情報の取得ができません。時間を置いて再表示をお願いします。',
  NOT_FOUND: '法人情報が存在しません。既に削除された可能性があります。',
} as const;

export const CORPORATION = {
  CORPORATION: {
    GET_CORPORATION,
  },
};
