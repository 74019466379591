import { Drawer, useDisclosure } from '@chakra-ui/react';
import { withSuspenseAndErrorBoundary } from 'admin/components/Error/WithErrorBoundary';
import { MasterCreateDrawer } from 'admin/components/idPoolConsumer/master/masterDrawer/index';
import { MasterTitleComponent } from 'admin/components/idPoolConsumer/master/masterTitle/MasterTitleComponent';
import { ErrorContents } from 'components/common/atoms';
import { FC, memo } from 'react';

type Props = {
  currentPage: number;
};

const PlainMasterTitle: FC<Props> = memo(({ currentPage }: Props) => {
  const { isOpen, onClose, onOpen } = useDisclosure();
  const formId = 'masterCreateForm';

  return (
    <>
      <MasterTitleComponent onOpen={onOpen} />
      {/* ドロワー */}
      <Drawer
        isOpen={isOpen}
        placement="right"
        onClose={onClose}
        size="md"
        closeOnOverlayClick={false}
        closeOnEsc={false}
        autoFocus={false}
      >
        <MasterCreateDrawer
          onClose={onClose}
          isOpen={isOpen}
          formId={formId}
          currentPage={currentPage}
        />
      </Drawer>
    </>
  );
});

export const MasterTitle = withSuspenseAndErrorBoundary(PlainMasterTitle, {
  ErrorComponent: <ErrorContents name="選択肢マスタ一覧タイトル" />,
  LoadingComponent: <></>,
});
