import {
  Button,
  FormControl,
  FormErrorMessage,
  FormLabel,
  ModalBody,
  ModalContent,
  ModalFooter,
  ModalHeader,
  Text,
} from '@chakra-ui/react';
import { RhfInput } from 'admin/components/form/RhfInput';
import { memo } from 'react';
import { useFormContext } from 'react-hook-form';

export type NameFormType = {
  name: string;
};

type NameFormProps = {
  title: string;
  saveButtonLabel: string;
  onSubmit: (data: NameFormType) => void;
  onClose: () => void;
};

export const NameForm = memo(
  ({ title, saveButtonLabel, onSubmit, onClose }: NameFormProps) => {
    const {
      handleSubmit,
      formState: { isSubmitting, errors },
    } = useFormContext<NameFormType>();

    return (
      <form onSubmit={handleSubmit(onSubmit)}>
        <ModalContent bg="White">
          <ModalHeader>{title}</ModalHeader>
          <ModalBody p={4}>
            <FormControl isInvalid={false}>
              <FormLabel>設定名</FormLabel>
              <RhfInput<NameFormType> name="name" type="text" />
              <FormErrorMessage>{errors.name?.message}</FormErrorMessage>
            </FormControl>
          </ModalBody>
          <ModalFooter>
            <Button mr={3} onClick={onClose}>
              <Text>キャンセル</Text>
            </Button>
            <Button colorScheme="blue" type="submit" disabled={isSubmitting}>
              <Text>{saveButtonLabel}</Text>
            </Button>
          </ModalFooter>
        </ModalContent>
      </form>
    );
  },
);
