import { VFC, memo, useState, useEffect } from 'react';
import { useFormContext } from 'react-hook-form';
import { Box, Flex, Text, Stack } from '@chakra-ui/react';
import { InputTextForm, ColorPicker } from 'components/common/atoms';
import { SkinItemPreview } from 'components/enquete/atoms/SkinItemPreview';
import { Skin } from 'api/enquete/types';
import { SkinDefaultValues } from 'components/enquete/EnqueteSkin';

export const SkinCheckbox: VFC = memo(() => {
  const [checkboxSelectedColor, setCheckboxSelectedColor] = useState(
    SkinDefaultValues.checkboxSelectedColor,
  );
  const { getValues, watch } = useFormContext<Skin>();

  useEffect(() => {
    const skinViewData = watch((value, { name }) => {
      if (name === 'checkboxSelectedColor') {
        setCheckboxSelectedColor(value[name] || '');
      }
    });

    return () => skinViewData.unsubscribe();
  }, [watch]);

  return (
    <Flex alignItems="stretch" mb={4} mt={4}>
      <Box minW="220px" mt="2px">
        <Text as="label">チェックボックス</Text>
      </Box>
      <Stack minW="250px">
        <Flex alignItems="flex-end">
          <InputTextForm<Skin>
            label="選択色"
            labelCaption="選択色"
            name="checkboxSelectedColor"
            value={getValues('checkboxSelectedColor')}
            placeholder="#FFFFFF"
            mr={4}
          />
          <ColorPicker<Skin>
            name="checkboxSelectedColor"
            value={getValues('checkboxSelectedColor') || '#FFFFFF'}
            width="40px"
            height="40px"
          />
        </Flex>
      </Stack>
      <SkinItemPreview
        element={
          <Box w="20px" position="relative">
            <Text
              as="label"
              _before={{
                content: '""',
                position: 'absolute',
                top: '50%',
                left: '0',
                width: '20px',
                height: '20px',
                transform: 'translate(0, -50%)',
                background: checkboxSelectedColor,
                border: '1px solid',
                borderColor: checkboxSelectedColor,
                borderRadius: '4px',
              }}
              _after={{
                content: '""',
                width: '4px',
                height: '8px',
                position: 'absolute',
                top: '50%',
                left: '7px',
                transform: 'translate(0, -70%) rotate(45deg)',
                borderBottom: '2px solid #fff',
                borderRight: '2px solid #fff',
                boxSizing: 'content-box !important',
              }}
            />
          </Box>
        }
        width="auto"
      />
    </Flex>
  );
});
