import {
  Box,
  Button,
  Stack,
  Switch,
  Table,
  Tbody,
  Td,
  Text,
  Tr,
  useDisclosure,
} from '@chakra-ui/react';
import ErrorBoundary from 'api/ErrorBoundary';
import { DatePicker, ErrorTextMsg } from 'components/common/atoms';
import { ChangeDeleteInsertModal } from 'components/contentsdb/atoms/ChangeDeleteInsertModal';
import { PublishAutoImport } from 'components/contentsdb/atoms/PublishAutoImport';
import { PublishDeleteInsert } from 'components/contentsdb/atoms/PublishDeleteInsert';
import { format, parseISO } from 'date-fns';
import { useTableData } from 'hooks/contentsdb/useTableData';
import { useTableId } from 'hooks/contentsdb/useTableId';
import { useTablePublish } from 'hooks/contentsdb/useTablePublish';
import { useErrorBoundaryReset } from 'hooks/useErrorBoundaryReset';
import { useUserInfo } from 'hooks/useUserInfo';
import { useUserTenantId } from 'hooks/user/useUserTenantId';
import { ChangeEvent, VFC, useCallback, useEffect, useState } from 'react';

type TablePublishInnerProps = {
  tableId: string;
  tenantId: string;
};

const DATETIME_FORMAT = 'yyyy-MM-dd HH:mm:SS';
const ERROR_MSG = '終了日時を開始日以降に設定してください';

const TablePublishInner: VFC<TablePublishInnerProps> = ({
  tableId,
  tenantId,
}) => {
  const [errMsg, setErrMsg] = useState('');
  const contentsTable = useTableData({ tenantId, tblId: tableId });
  const [isPublish, setPublish] = useState(false);
  const [isAutoImport, setAutoImport] = useState(false);
  const [isDeleteInsert, setDeleteInsert] = useState(false);
  const [startDate, setStartDate] = useState<string | null>(null);
  const [endDate, setEndDate] = useState<string | null>(null);
  const { onSubmit, isLoading } = useTablePublish();
  const { isDisablePublish } = useUserInfo();
  const { isOpen, onOpen, onClose } = useDisclosure();

  // 初期処理
  useEffect(() => {
    if (!contentsTable || !contentsTable?.id) {
      setPublish(false);
      setStartDate(null);
      setEndDate(null);
      setAutoImport(false);

      return;
    }

    const publisStartDate = contentsTable?.publishStart
      ? format(parseISO(contentsTable?.publishStart), DATETIME_FORMAT)
      : null;

    const publisEndDate = contentsTable?.publishEnd
      ? format(parseISO(contentsTable?.publishEnd), DATETIME_FORMAT)
      : null;

    setPublish(contentsTable?.publishFlag);
    setStartDate(publisStartDate);
    setEndDate(publisEndDate);
    setAutoImport(contentsTable?.isAutoImport);
    setDeleteInsert(contentsTable?.isDeleteInsert);
  }, [contentsTable]);

  // 公開設定トグルスイッチChangeイベント
  const onSwitchPublishChange = useCallback(
    (event: ChangeEvent<HTMLInputElement>) => {
      setPublish(event.target.checked);
    },
    [],
  );

  // 定期取り込みトグルスイッチChangeイベント
  const onSwitchAutoImportChange = useCallback(
    (event: ChangeEvent<HTMLInputElement>) => {
      setAutoImport(event.target.checked);
      if (!event.target.checked) {
        setDeleteInsert(false);
      }
    },
    [],
  );

  // 公開設定 > 開始日時変更処理
  const onStartChange = useCallback((date: Date | null) => {
    setStartDate(date ? format(date, DATETIME_FORMAT) : null);
  }, []);

  // 公開設定 > 終了日時変更処理
  const onEndChange = useCallback((date: Date | null) => {
    setEndDate(date ? format(date, DATETIME_FORMAT) : null);
  }, []);

  // 洗い替え取り込みチェックボックスイベント
  const onChangeIsDeleteInsert = useCallback(
    (event: ChangeEvent<HTMLInputElement>) => {
      setDeleteInsert(event.target.checked);
    },
    [],
  );

  useEffect(() => {
    if (!startDate || !endDate) {
      setErrMsg('');

      return;
    }

    if (startDate <= endDate) {
      setErrMsg('');

      return;
    }

    setErrMsg(ERROR_MSG);
  }, [startDate, endDate]);

  // 保存処理
  const onSave = useCallback(() => {
    const sendData = {
      publishFlag: isPublish,
      publishStart: startDate,
      publishEnd: endDate,
      isAutoImport,
      isDeleteInsert,
      tenantId,
      tableId,
    };

    onSubmit(sendData);
  }, [
    onSubmit,
    endDate,
    startDate,
    isPublish,
    isAutoImport,
    isDeleteInsert,
    tenantId,
    tableId,
  ]);

  // 洗い替え取り込み変更ボタン処理
  const onChangeSubmit = useCallback(() => {
    onSave();
    onClose();
  }, [onClose, onSave]);

  // 洗い替え取り込みポップアップcloseボタン押下時
  const closeBtnHandler = useCallback(() => {
    onClose();
  }, [onClose]);

  // 保存ボタン押下処理
  const onClickSubmit = () => {
    if (!contentsTable || !contentsTable?.id) {
      return;
    }
    // 洗い替え取り込みの状態が変更されている場合はポップアップを表示する
    if (contentsTable.isDeleteInsert !== isDeleteInsert) {
      onOpen();

      return;
    }

    // 洗い替え取り込みの状態が変更されていなければ、そのまま保存処理を実行する
    onSave();
  };

  return (
    <Box p={1}>
      <Stack spacing={4} mb={4}>
        {/* 公開設定 */}
        <Box bgColor="#fbfbfb" borderRadius="4px" p={4} mb={2}>
          <Text fontSize="md" fontWeight="bold" ml={2} mb={2}>
            公開設定
          </Text>
          <Box bgColor="white" p={4}>
            <Table size="sm">
              <Tbody>
                <Tr>
                  <Td w={36}>データ公開</Td>
                  <Td>
                    <Switch
                      id="email-alerts"
                      size="sm"
                      onChange={onSwitchPublishChange}
                      isChecked={isPublish}
                      isDisabled={!contentsTable?.id || isDisablePublish}
                    />
                  </Td>
                </Tr>
                <Tr>
                  <Td w={36}>開始日時</Td>
                  <Td>
                    {contentsTable?.id && !isDisablePublish ? (
                      <DatePicker
                        startDate={startDate ? new Date(startDate) : null}
                        width={40}
                        onChange={onStartChange}
                        showTimeSelect
                      />
                    ) : (
                      <Text>{startDate}</Text>
                    )}
                  </Td>
                </Tr>
                <Tr>
                  <Td w={36}>終了日時</Td>
                  <Td>
                    {contentsTable?.id && !isDisablePublish ? (
                      <DatePicker
                        startDate={endDate ? new Date(endDate) : null}
                        width={40}
                        onChange={onEndChange}
                        showTimeSelect
                      />
                    ) : (
                      <Text>{endDate}</Text>
                    )}
                    {errMsg && <ErrorTextMsg msg={errMsg} />}
                  </Td>
                </Tr>
              </Tbody>
            </Table>
          </Box>
        </Box>
        {/* 定期取り込み */}
        <Box bgColor="#fbfbfb" borderRadius="4px" p={4} mb={2}>
          <Text fontSize="md" fontWeight="bold" ml={2} mb={2}>
            定期取り込み
          </Text>
          <Box bgColor="white" p={4} display="flex">
            {/* 取り込み */}
            <PublishAutoImport
              id={contentsTable?.id || ''}
              isDisablePublish={isDisablePublish}
              isAutoImport={isAutoImport}
              onSwitchAutoImportChange={onSwitchAutoImportChange}
            />
            {/* 洗い替え取り込み */}
            <PublishDeleteInsert
              isDeleteInsert={isDeleteInsert}
              isAutoImport={isAutoImport}
              isDisablePublish={isDisablePublish}
              onChangeIsDeleteInsert={onChangeIsDeleteInsert}
            />
          </Box>
        </Box>
      </Stack>
      {contentsTable?.id && !isDisablePublish && (
        <Button
          size="xs"
          p={4}
          variant="primary"
          ml={3}
          onClick={onClickSubmit}
          disabled={!!errMsg}
          isLoading={isLoading}
        >
          保 存
        </Button>
      )}
      {/* 洗い替え取り込み制御ポップアップ */}
      <ChangeDeleteInsertModal
        isOpen={isOpen}
        onClose={closeBtnHandler}
        onChangeSubmit={onChangeSubmit}
      />
    </Box>
  );
};

export const TablePublish: VFC = () => {
  const { ebKey, onError } = useErrorBoundaryReset();
  const tableId = useTableId();
  const tenantId = useUserTenantId();

  if (!tableId || !tenantId) {
    return <></>;
  }

  return (
    <ErrorBoundary errorComponent={<></>} onError={onError} key={ebKey.current}>
      <TablePublishInner tableId={tableId} tenantId={tenantId} />
    </ErrorBoundary>
  );
};
