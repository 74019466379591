import {
  Box,
  DrawerCloseButton,
  DrawerContent,
  DrawerHeader,
  DrawerOverlay,
  Text,
} from '@chakra-ui/react';
import { withSuspenseAndErrorBoundary } from 'admin/components/Error/WithErrorBoundary';
import { NewsletterForm } from 'admin/components/service/newsletter/newsletterForm';
import { ServiceNewsletterType } from 'admin/types/service/newsletter';
import { ErrorContents, LoadingSpinner } from 'components/common/atoms';
import { FC, memo } from 'react';

type Props = {
  newsletter?: ServiceNewsletterType;
  onClose: () => void;
};

const fetchNewsletterEditDrawerComponent: FC<Props> = memo(
  ({ newsletter, onClose }: Props) => {
    if (!newsletter) {
      return null;
    }

    return <NewsletterForm newsletter={newsletter} onClose={onClose} />;
  },
);

export const FetchNewsletterEditDrawerComponent = withSuspenseAndErrorBoundary(
  fetchNewsletterEditDrawerComponent,
  {
    ErrorComponent: <ErrorContents name="メールマガジン購読設定詳細" />,
    LoadingComponent: <LoadingSpinner />,
  },
);

export const NewsletterEditDrawer: FC<Props> = memo((props: Props) => (
  <>
    <DrawerOverlay />
    <DrawerContent>
      <DrawerHeader pb={0}>
        <Box m={0} px={0} pb="24px" borderBottomWidth="1px">
          <Text>メールマガジン購読設定</Text>
        </Box>
      </DrawerHeader>
      <FetchNewsletterEditDrawerComponent {...props} />
      <DrawerCloseButton top="16px" right="24px" />
    </DrawerContent>
  </>
));
