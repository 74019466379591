import { logger } from 'api/logger';
import { request } from 'api/request';
import { isResponseError, ResponseError } from 'api/types';
import {
  isPromotion, Promotion, PromotionFormType, PromotionSubmitFormType
} from 'components/paywall/pages/PromotionList/typed';
import produce from 'immer';
import { Options } from 'ky';
import { getValidationError, ValidationError } from 'utils/form';
import { messages } from './messages';

const DEFAULT_ERR_MESSAGE: ValidationError<PromotionSubmitFormType> = {
  others: [messages.postPromotion.customer.error],
};
const DEFAULT_SYSTEM_ERR_MESSAGE: ValidationError<PromotionSubmitFormType> = {
  others: [messages.postPromotion.customer.failed],
};

/**
 * プロモーション作成 API
 * @param {PromotionSubmitFormType} formParam API送信パラメータ
 * @param {Options} options APIオプション
 * @return {Promise<Promotion | ResponseError>} プロモーション
 */
export const postPromotion = async (
  formParam: PromotionSubmitFormType,
  options?: Options,
): Promise<Promotion | ResponseError> => {
  const { tenantId, courseId, promotionForm } = formParam;
  const path = `paywall/${tenantId}/${courseId}/promotion/`;
  const postParam = promotionForm;
  // 新規作成時はステータスを送信しない
  delete postParam.status;

  const mergedOptions = options
    ? produce(options, (draft) => {
        draft.json = { ...postParam };
      })
    : { json: postParam };

  const response = await request({
    path,
    method: 'post',
    options: mergedOptions,
  });

  if (response.status >= 500) {
    return DEFAULT_ERR_MESSAGE;
  }

  if (response.status === 403) {
    return DEFAULT_SYSTEM_ERR_MESSAGE;
  }

  const responseData = response.data;

  // プロモーション作成成功
  if (isPromotion(responseData)) {
    return responseData;
  }

  const { error } = response;

  if (response.hasError) {
    // APIにて返却されたエラー形式
    if (isResponseError(error)) {
      const formError = getValidationError<PromotionFormType>({
        formData: formParam.promotionForm,
        response: error,
      });

      return formError;
    }
  }

  // 予期せぬエラーなので
  // バックエンドに状態を通知
  await logger({
    loglevel:
      response.status === 404 || response.status === 450 ? 'warning' : 'error',
    data: error,
    message: messages.postPromotion.system.typeError,
  });

  // 登録できていない旨を通知
  return DEFAULT_SYSTEM_ERR_MESSAGE;
};
