import { Box, BoxProps } from '@chakra-ui/react';
import { AlertInfoIcon } from 'admin/components/ui/alertInfo/alertInfoIcon';
import { AlertInfoColorSet } from 'admin/define/colors';
import {
  AlertInfoColorType, AlertInfoType
} from 'admin/types/ui/alertInfo';
import { FC, memo, ReactNode, useMemo } from 'react';

type Props = {
  type: AlertInfoType;
  iconType: AlertInfoType;
  children: ReactNode;
} & Pick<
  BoxProps,
  | 'width'
  | 'fontSize'
  | 'border'
  | 'padding'
>;
export const AlertInfoBar: FC<Props> = memo(({
  type,
  iconType,
  children,
  fontSize = '14px',
  ...rest
}: Props) => {
  const alertInfoColor = useMemo<AlertInfoColorType>(() => AlertInfoColorSet[type], [type]);
  const alertInfoIcon = useMemo<JSX.Element>(() => AlertInfoIcon()[iconType], [iconType]);

  return (
    <Box
      borderRadius="8px"
      borderWidth="1px"
      borderStyle="solid"
      display="flex"
      alignItems="center"
      justifyContent="flex-start"
      {...alertInfoColor}
      p="16px"
      {...rest}
    >
      {alertInfoIcon}
      <Box
        fontWeight="bold"
        fontSize={fontSize}
        fontFamily="Hiragino Sans"
        lineHeight="170%"
        ml="16px"
      >
        {children}
      </Box>
    </Box>
  )
});
