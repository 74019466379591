import { ResponseError } from 'api/types';
import { putUser } from 'api/user/putUser';
import { isUser, User, UserSettingForm } from 'api/user/types';
import { useCustomToast } from 'hooks/useCustomToast';
import { queryUserKey } from 'hooks/user/queryUserKey';
import { useUserId } from 'hooks/user/useUserId';
import { useUserTenantId } from 'hooks/user/useUserTenantId';
import { useUserInfo } from 'hooks/useUserInfo';
import { useState } from 'react';
import { UseFormSetError } from 'react-hook-form';
import { UseMutateFunction, useMutation, useQueryClient } from 'react-query';
import { isGetValidationError, toMultiError } from 'utils/form';

type UnPromisify<T> = T extends Promise<infer U> ? U : T;

const userEditSetting: UserSettingForm = {
  name: '',
  email: '',
};

/**
 * テナントユーザー設定変更hooks
 * @param {Object} UseFormSetError setError
 * @param {setGlobalErrors} callback setGlobalErrors
 * @returns {Object} { onSubmit, isLoading }
 */
export const useUserEdit = ({
  setError,
  setGlobalErrors,
}: {
  setError: UseFormSetError<UserSettingForm>;
  setGlobalErrors: (errors: string[]) => void;
}): {
  onSubmit: UseMutateFunction<
    UnPromisify<ReturnType<typeof putUser>>,
    unknown,
    UserSettingForm,
    unknown
  >;
  isLoading: boolean;
  isSuccess: boolean;
} => {
  const queryClient = useQueryClient();
  const { selfData, refreshSelfData } = useUserInfo();
  const userId = useUserId();
  const toast = useCustomToast();
  const [isSuccess, setIsSuccess] = useState(false);
  const tenantId = useUserTenantId();

  const { mutate: onSubmit, isLoading } = useMutation(
    ({ name, email }: UserSettingForm) =>
      putUser(selfData?.id as string, { name, email }),
    {
      onSuccess: (result: User | ResponseError) => {
        void queryClient.invalidateQueries(queryUserKey.getUser({ userId }));
        void queryClient.invalidateQueries(
          queryUserKey.tenantUserList({ tenantId }),
        );
        refreshSelfData();
        // グローバルエラーを初期化
        setGlobalErrors([]);
        if (isUser(result)) {
          toast({
            status: 'success',
            position: 'bottom',
            duration: 2000,
            isClosable: true,
            title: '変更しました',
          });
          setIsSuccess(true);

          return;
        }

        if (
          isGetValidationError<UserSettingForm>(
            result,
            Object.keys(userEditSetting),
          )
        ) {
          if (result?.others) {
            setGlobalErrors(result?.others);
          } else {
            Object.keys(result).forEach((k) => {
              const key = k as keyof UserSettingForm;
              const errMsgs = result?.[key];
              if (errMsgs) {
                setError(key, { types: toMultiError(errMsgs) });
              }
            });
          }
        }
      },
    },
  );

  return { onSubmit, isLoading, isSuccess };
};
