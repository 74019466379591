import { CustomError } from 'api/error/CustomError';
import { logger } from 'api/logger';
import { request } from 'api/request';
import { Options } from 'ky';
import { messages } from './messages';
import { isEnqueteContentGroups } from './types';

// ユーザー定義の設問グループ取得
export const getEnqueteContentGroups = async (
  tenantId: string,
  options?: Options,
) => {
  const target = 'enquete';
  const path = `${target}/${tenantId}/content_group/select/`;

  const response = await request({
    path,
    method: 'get',
    options,
  });

  const responseData = (await response.data) as unknown[];

  if (response.hasError) {
    // 404エラー時は空配列を返却する
    if (response.status === 404) {
      return [];
    }
    throw new CustomError(
      messages.getEnqueteId.customer.error,
      response.status,
    );
  }

  if (!isEnqueteContentGroups(responseData)) {
    await logger({
      loglevel: response.status === 450 ? 'warning' : 'error',
      data: responseData,
      message: messages.getEnqueteId.system.typeError,
    });

    throw new CustomError(
      messages.getEnqueteId.customer.failed,
      response.status,
    );
  }

  return responseData;
};
