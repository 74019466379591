import { Tr } from '@chakra-ui/react';
import { Th } from 'components/paywall/features/Table';
import { FC, memo } from 'react';

export const SubscriptionColumns: FC = memo(() => (
  <Tr>
    <Th maxWidth="235px" minWidth="235px" name="注文ID" columnFix targetKey="orderId" isSort filterType="text" />
    <Th maxWidth="158px" minWidth="158px" name="メールアドレス" targetKey="email" filterType="text" />
    <Th maxWidth="284px" minWidth="284px" name="顧客ID" targetKey="customerUid" isSort filterType="text" />
    <Th maxWidth="108px" minWidth="108px" name="コースID" targetKey="courseId" filterType="text" />
    <Th maxWidth="260px" minWidth="260px" name="コース" targetKey="courseName" filterType="text" />
    <Th maxWidth="142px" minWidth="142px" name="ステータス" targetKey="status" isSort filterType="status" />
    <Th maxWidth="145px" minWidth="145px" name="契約開始日" targetKey="courseStart" isSort filterType="period" />
    <Th maxWidth="145px" minWidth="145px" name="契約終了日" targetKey="courseEnd" isSort filterType="period" />
    <Th maxWidth="171px" minWidth="171px" name="終了理由" targetKey="cancelReason" isSort filterType="cancel" />
    <Th minWidth="396px" name="決済エラー" targetKey="tranErrorInfo" isSort/>
  </Tr>
));
