import { EnqueteInner } from 'components/enquete/organisms/EnqueteInner';
import { useEnqueteDetail } from 'hooks/enquete/useEnqueteDetail';
import { useUserTenantId } from 'hooks/user/useUserTenantId';
import { FC } from 'react';

type Props = {
  formId: string;
};

export const EnqueteEditForm: FC<Props> = ({ formId }: Props) => {
  const tenantId = useUserTenantId();
  const enqueteDetail = useEnqueteDetail({ enqueteId: formId });

  return (
    <EnqueteInner
      tenantId={tenantId}
      enqueteForm={enqueteDetail}
      formIdParams={formId}
    />
  );
};
