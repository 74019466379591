import { Box } from '@chakra-ui/react';
import { withSuspenseAndErrorBoundary } from 'admin/components/Error/WithErrorBoundary';
import { TenantUsersBodyComponent } from 'admin/components/tenantUsers/tenantUsersBody/TenantUsersBodyComponent';
import { ErrorContents } from 'components/common/atoms';
import { FC, memo, useState } from 'react';

const tenantUsersBody: FC = memo(() => {
  const [searchKeyword, setSearchKeyword] = useState('');
  const [tenantId, setTenantId] = useState('');

  // 検索処理
  const onSearch = (keyword: string, id: string) => {
    setSearchKeyword(keyword);
    setTenantId(id);
  };

  return (
    <Box mt="16px">
      <TenantUsersBodyComponent
        searchKeyword={searchKeyword}
        tenantId={tenantId}
        onSearch={onSearch}
      />
    </Box>
  );
});

export const TenantUsersBody = withSuspenseAndErrorBoundary(tenantUsersBody, {
  ErrorComponent: <ErrorContents name="テナント アカウント管理一覧" />,
});
