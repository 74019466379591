import { VFC, memo } from 'react';
import { IconButton } from '@chakra-ui/react';
import { BsChevronUp, BsChevronDown } from 'react-icons/bs';

type Props = {
  toggle: boolean;
  setToggle: React.Dispatch<React.SetStateAction<boolean>>;
};

export const Toggle: VFC<Props> = memo(({ toggle, setToggle }) => {
  const onToggle = () => setToggle((prev) => !prev);

  return (
    <IconButton
      aria-label="EnqueteBlockToggle"
      icon={toggle ? <BsChevronDown /> : <BsChevronUp />}
      background="none"
      fontSize={16}
      padding="0"
      onClick={() => onToggle()}
    />
  );
});
