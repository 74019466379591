import { AttributeTableBodyComponent } from 'admin/components/service/attribute/attributeTableBody/AttributeTableBodyComponent';
import { useGetCodeGroupName } from 'admin/hooks/userPool/master/useGetCodeGroupName';
import {
  ServiceAttributeType,
  UserPoolChildAttributeType,
} from 'admin/types/service/attribute';
import { FC, memo } from 'react';

type DialogHandler = (
  id: ServiceAttributeType['id'],
  userPoolChildAttributeId: UserPoolChildAttributeType['id'],
) => void;

export type AttributeTableBodyProps = {
  attribute: ServiceAttributeType;
  onOpen: DialogHandler;
  onOpenFormAttrDialog: DialogHandler;
  onOpenChildAttrDialog: DialogHandler;
  onOpenUserAttrDialog: DialogHandler;
  onOpenUserAttrRequiredDialog: DialogHandler;
  hasUniikey: boolean;
};

export const AttributeTableBody: FC<AttributeTableBodyProps> = memo(
  ({
    attribute,
    onOpen,
    onOpenFormAttrDialog,
    onOpenChildAttrDialog,
    onOpenUserAttrDialog,
    onOpenUserAttrRequiredDialog,
    hasUniikey,
  }: AttributeTableBodyProps) => {
    const codeGroupName = useGetCodeGroupName(attribute.userPool);

    return (
      <AttributeTableBodyComponent
        attribute={attribute}
        onOpen={onOpen}
        onOpenFormAttrDialog={onOpenFormAttrDialog}
        onOpenChildAttrDialog={onOpenChildAttrDialog}
        onOpenUserAttrDialog={onOpenUserAttrDialog}
        onOpenUserAttrRequiredDialog={onOpenUserAttrRequiredDialog}
        codeGroupName={codeGroupName}
        hasUniikey={hasUniikey}
      />
    );
  },
);
