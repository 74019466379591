import { Box, Button, Flex, Text } from '@chakra-ui/react';
import { Colors } from 'admin/define/colors';
import { FC, memo } from 'react';
import { MdOutlineAdd } from 'react-icons/md';

type Props = {
  onOpen: () => void;
};

export const NewsletterTitleComponent: FC<Props> = memo(({ onOpen }: Props) => (
  <Box mt="40px">
    <Flex justifyContent="flex-end">
      <Box mr="auto">
        <Text
          fontFamily="Hiragino Sans"
          fontSize="18px"
          fontWeight="700"
          lineHeight="170%"
        >
          メールマガジン購読設定
        </Text>
      </Box>
      <Button colorScheme="primary" p="8px 16px" onClick={onOpen}>
        <MdOutlineAdd />
        メールマガジン購読設定
      </Button>
    </Flex>
    <Box mt="16px">
      <Text
        fontWeight="400"
        fontSize="14px"
        lineHeight="170%"
        fontFamily="Hiragino Sans"
        color={Colors.GRAY_00}
      >
        メールマガジン購読を設定します。希望するエンドユーザーに対して定期的に情報やお知らせを発信する際に必要になります。
      </Text>
    </Box>
  </Box>
));
