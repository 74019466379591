import { TenantUsersType } from 'admin/types/tenantUsers';

export const queryTenantUsersKey = {
  getTenantUsers: (corporationId: string) => ['tenantUsers', corporationId],
  getTenantUsersDetail: ({
    corporationId,
    tenantId,
    id,
  }: {
    corporationId: string;
    tenantId: string;
    id: TenantUsersType['id'];
  }) => ['tenantUsersDetail', corporationId, tenantId, id],
  getTenantList: (corporationId: string) => ['tenantList', corporationId],
} as const;
