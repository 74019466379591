import { Text, Tr as ChakraTr } from '@chakra-ui/react';
import { Breadcrumb } from 'admin/components/ui/breadcrumb';
import { Table, Tbody, Td, Th, Thead } from 'admin/components/ui/table';
import { Colors } from 'admin/define/colors';
import { BreadcrumbType } from 'admin/types/ui/breadcrumb';
import { useSetPageTitle } from 'hooks/useSetPageTitle';
import { FC, memo } from 'react';

export const JsonPathExample: FC = memo(() => {
  useSetPageTitle('JSONパス構文の入力方法');
  const breadcrumbItems: BreadcrumbType[] = [
    { url: '/admin', title: '組織管理TOP' },
    {
      url: '/admin/jsonPathExample',
      title: 'JSONパス構文の入力方法',
    },
  ];

  return (
    <>
      <Breadcrumb breadcrumbItems={breadcrumbItems} />
      <Text
        fontWeight="700"
        fontSize="18px"
        lineHeight="170%"
        fontFamily="Hiragino Sans"
        mt={6}
      >
        JSONパス構文の入力方法
      </Text>
      <Text color={Colors.GRAY_00} fontSize="14px" mt={1} mb={4}>
        JSONパス構文の入力方法について
      </Text>
      <Table w="100%" maxHeight="calc(100vh - 250px)">
        <Thead>
          <ChakraTr>
            <Th maxWidth="150px" minWidth="150px">
              JSONパス
            </Th>
            <Th w="100%">説明</Th>
          </ChakraTr>
        </Thead>
        <Tbody bg="white">
          <ChakraTr>
            <Td>$</Td>
            <Td>ルートオブジェクト / 要素</Td>
          </ChakraTr>
          <ChakraTr>
            <Td>.または[]</Td>
            <Td>子オペレータ</Td>
          </ChakraTr>
          <ChakraTr>
            <Td>..</Td>
            <Td>再帰的な子</Td>
          </ChakraTr>
          <ChakraTr>
            <Td>*</Td>
            <Td>ワイルドカード</Td>
          </ChakraTr>
          <ChakraTr>
            <Td>[]</Td>
            <Td>ネイティブ配列演算子</Td>
          </ChakraTr>
          <ChakraTr>
            <Td>[start:end]</Td>
            <Td>配列スライス演算子</Td>
          </ChakraTr>
          <ChakraTr>
            <Td>?()</Td>
            <Td>フィルター式を適用する</Td>
          </ChakraTr>
          <ChakraTr>
            <Td>()</Td>
            <Td>基になるスクリプト エンジンを使用したスクリプト式</Td>
          </ChakraTr>
        </Tbody>
      </Table>
    </>
  );
});
