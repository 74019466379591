import { useDisclosure } from '@chakra-ui/react';
import React from 'react';
import { CommonDialog } from '../../features/CommonDialog/CommonDialog';

type CommonDialogProps = Omit<
  React.ComponentProps<typeof CommonDialog>,
  'onClose'
>;
type OpenDialogProps = Omit<CommonDialogProps, 'onClose' | 'isOpen'>;

interface CommonDialogModel {
  openDialog: (props: OpenDialogProps) => void;
  openPromiseDialog: (props: OpenDialogProps) => Promise<boolean>;
  closeDialog: () => void;
  dialogProps: CommonDialogProps;
}

const dialogPropsInit: OpenDialogProps = {
  message: ['保存されていないデータは削除されますが、よろしいですか？'],
  okBtnColor: 'blue',
  okBtnCaption: '戻る',
  cancelBtnCaption: 'キャンセル',
  cancelBtnColor: 'gray',
};

const useDialogFunctions = (): CommonDialogModel => {
  const { isOpen, onClose, onOpen } = useDisclosure();
  const [dialogProps, setDialogProps] =
    React.useState<OpenDialogProps>(dialogPropsInit);

  const close = React.useCallback(() => {
    onClose();
    setDialogProps({ ...dialogProps });
  }, [dialogProps, onClose]);

  const open = React.useCallback(
    (props: OpenDialogProps) => {
      const { onOk, onCancel, ...rest } = props;
      setDialogProps({
        ...rest,
        message: rest.message || dialogProps.message,
        onOk: () => {
          onOk?.();
          close();
        },
        onCancel: () => {
          onCancel?.();
          close();
        },
      });
      onOpen();
    },
    [close, onOpen, dialogProps.message],
  );

  const promiseOpen = React.useCallback(
    async (props: OpenDialogProps): Promise<boolean> => {
      const { onOk, onCancel, ...rest } = props;
      const res = new Promise<boolean>((resolve) => {
        setDialogProps({
          ...rest,
          message: rest.message || dialogProps.message,
          onOk: () => {
            onOk?.();
            close();
            resolve(true);
          },
          onCancel: () => {
            onCancel?.();
            close();
            resolve(false);
          },
        });
        onOpen();
      });

      return res;
    },
    [close, onOpen, dialogProps.message],
  );

  return {
    openDialog: open,
    openPromiseDialog: promiseOpen,
    closeDialog: close,
    dialogProps: { ...dialogProps, isOpen },
  };
};

const CommonDialogContext = React.createContext<CommonDialogModel | null>(null);

interface Props {
  children: React.ReactNode;
}

export const CommonDialogProvider: React.FC<Props> = ({ children }) => {
  const { openDialog, openPromiseDialog, closeDialog, dialogProps } =
    useDialogFunctions();

  return (
    <CommonDialogContext.Provider
      value={{ openDialog, openPromiseDialog, closeDialog, dialogProps }}
    >
      {children}
    </CommonDialogContext.Provider>
  );
};

export const useCommonDialog = (): CommonDialogModel =>
  React.useContext<CommonDialogModel>(
    CommonDialogContext as React.Context<CommonDialogModel>,
  );
