import { useRecoilCallback } from 'recoil';
import { userState } from 'store/user';
import { getUser } from 'api/user/getUser';

/**
 * userState recoil 情報更新 hooks
 */
export const useRefreshUserState = (): (() => Promise<void>) => {
  const refreshUserState = useRecoilCallback(
    ({ set }) =>
      async () => {
        const response = await getUser();
        set(userState, response);
      },
    [],
  );

  return refreshUserState;
};
