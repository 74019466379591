import { Box, Button, Heading, Link, Text, VStack } from '@chakra-ui/react';
import { withSuspenseAndErrorBoundary } from 'admin/components/Error/WithErrorBoundary';
import { Colors } from 'admin/define/colors';
import { FormGroupDetailType } from 'admin/types/userPool/formGroup';
import { EnqueteFormType } from 'api/enquete/types';
import {
  ErrorContents,
  ErrorTextMsg,
  LoadingSpinner,
} from 'components/common/atoms';
import { DraggableListItem } from 'components/enquete/organisms/DraggableListItem';
import { Dispatch, FC, memo, RefObject, SetStateAction } from 'react';
import { DropResult } from 'react-beautiful-dnd';
import { useFormContext } from 'react-hook-form';
import { MdOutlineAdd } from 'react-icons/md';

// フォーム設問グループの使い方
const FormGroupDetailDescriptionLinkUrl =
  'https://uniikey.notion.site/6e0c7442fb114bc1bfe01494aca57ed9';

type Props = {
  list: EnqueteFormType[];
  apiData: EnqueteFormType[];
  isThanksFlg: boolean;
  copySelectType: string;
  formId: string;
  setIsEdited: Dispatch<SetStateAction<boolean>>;
  handleDragEnd: (result: DropResult) => void;
  onUp: (
    index: number,
    isFirst: boolean,
    curRef: RefObject<HTMLDivElement>,
    prevRef: RefObject<HTMLDivElement> | null,
  ) => void;
  onDown: (
    index: number,
    isLast: boolean,
    curRef: RefObject<HTMLDivElement>,
    nextRef: RefObject<HTMLDivElement> | null,
  ) => void;
  onAdd: (index: number) => void;
  onCopy: (index: number) => void;
  onDelete: (index: number) => void;
  onAddElement: () => void;
  onInsertElement: (index: number, form: EnqueteFormType) => void;
  onSubmit: (data: FormGroupDetailType) => void;
  uniqueId: () => string;
};

export const detailFormGroups: FC<Props> = memo(
  ({
    list,
    apiData,
    isThanksFlg,
    copySelectType,
    formId,
    setIsEdited,
    handleDragEnd,
    onUp,
    onDown,
    onAdd,
    onCopy,
    onDelete,
    onAddElement,
    onSubmit,
    uniqueId,
    onInsertElement,
  }: Props) => {
    const {
      handleSubmit,
      getValues,
      formState: { errors },
    } = useFormContext<FormGroupDetailType>();

    return (
      <VStack w="100%" spacing={4} alignItems="center" mt="24px !important">
        <Box w="100%">
          <Heading as="h2" fontSize="18px" w="100%" lineHeight="170%">
            フォーム設問グループ内容
          </Heading>
          <Text
            fontWeight="400"
            fontSize="14px"
            lineHeight="170%"
            fontFamily="Hiragino Sans"
            color={Colors.GRAY_00}
          >
            属性の設定が全テナントで「フォームで利用する」が有効な場合
            、設問をセット化することが可能です。
            <br />
            詳しくは
            <Link
              // リンク先は未定の為、共有され次第追加する。
              href={FormGroupDetailDescriptionLinkUrl}
              color={Colors.BLUE_20}
              target="_blank"
              textDecoration="underline"
              fontWeight="bold"
            >
              フォーム設問グループの使い方
            </Link>
            を参照してください。
          </Text>
        </Box>

        {getValues('groupUnits').length === 0 && (
          <Box w="100%">
            <ErrorTextMsg
              fontSize="md"
              msg="最低１つ以上の設問を設定してください。"
            />
          </Box>
        )}

        <Box w="100%">
          <form onSubmit={handleSubmit(onSubmit)} id={formId}>
            <DraggableListItem<FormGroupDetailType>
              baseName="groupUnits"
              list={list}
              errors={errors}
              handleDragEnd={handleDragEnd}
              copySelectType={copySelectType}
              onUp={onUp}
              onDown={onDown}
              onAdd={onAdd}
              onAddElement={onAddElement}
              onInsertElement={onInsertElement}
              onCopy={onCopy}
              onDelete={onDelete}
              apiData={apiData}
              isThanksFlg={isThanksFlg}
              setIsEdited={setIsEdited}
              uniqueId={uniqueId}
            />
            <Box display="flex" justifyContent="center">
              <Button
                variant="outline"
                colorScheme="primary"
                bgColor={Colors.WHITE}
                onClick={onAddElement}
                mt={2}
                w="104px"
                h="32px"
              >
                <MdOutlineAdd />
                項目追加
              </Button>
            </Box>
          </form>
        </Box>
      </VStack>
    );
  },
);

export const DetailFormGroups = withSuspenseAndErrorBoundary(detailFormGroups, {
  ErrorComponent: <ErrorContents name="フォーム設問パーツ" />,
  LoadingComponent: <LoadingSpinner />,
});
