import { getCorporation } from 'admin/api/corporation/getCorporation';
import { useCustomToast } from 'hooks/useCustomToast';
import { useQuery } from 'react-query';
import { errorToast } from 'utils/toast';
import { useUserCorporationId } from '../user/useUserCorporationId';
import { queryCorporationKey } from './queryCorporationlKey';

export const useCorporation = () => {
  const corporationId = useUserCorporationId();
  const toast = useCustomToast();
  const { data } = useQuery({
    queryKey: queryCorporationKey.getCorporation(corporationId),
    queryFn: () => getCorporation({ id: corporationId }),
    onError: () => {
      toast({
        ...errorToast,
        duration: 4000,
        title: '法人情報の取得に失敗しました',
        position: 'bottom',
        variant: 'solid',
      });
    },
  });

  return data;
};
