import {
  Box, Button, Heading, Popover, PopoverBody, PopoverContent, PopoverTrigger, Select, Stack, Text, Textarea
} from '@chakra-ui/react';
import { MailEditorForm, SendMailReplaceType } from 'api/mail/types';
import { TenantMailList } from 'api/tenant/types';
import { MailReplacePopover } from 'components/mail/atoms/MailReplacePopover';
import { useTenantInfo } from 'hooks/useTenantInfo';
import { ChangeEvent, memo, useCallback, useState, VFC } from 'react';
import { useFormContext } from 'react-hook-form';

type TestMailPopoverProps = {
  onSendMail: (
    toAddress: string,
    fromAddress: string,
    replayAddress: string,
    testMailPlaceholder: SendMailReplaceType,
  ) => void;
  isLoading: boolean;
  isOpen: boolean;
  onOpen: () => void;
  onClose: () => void;
};

export const TestMailPopover: VFC<TestMailPopoverProps> = memo(
  ({
    onSendMail,
    isLoading,
    isOpen,
    onOpen,
    onClose,
  }: TestMailPopoverProps) => {
    const { mailFromAddressList, mailReplyAddressList } = useTenantInfo();
    const [mailAddress, setMailAddress] = useState('');
    const [mailReplaceWord, setMailReplaceWord] = useState<SendMailReplaceType>(
      {},
    );
    const [fromAddress, setFromAddress] = useState<TenantMailList>(
      mailFromAddressList.length > 0
        ? mailFromAddressList[0]
        : { id: -1, name: '' },
    );
    const [replayAddress, setReplayAddress] = useState<TenantMailList>({
      id: -1,
      name: '',
    });
    const { getValues } = useFormContext<MailEditorForm>();

    // メールフォーム内に記載されている「%%xxx%%」形式の文字列を収集する
    const replaceWord = useCallback(() => {
      let result: string[] = [];
      const reg = new RegExp(/%%.*?%%/g);
      const mailEditorFormData = getValues();
      result = result.concat(mailEditorFormData.subject.match(reg) || []);
      result = result.concat(mailEditorFormData.bodyHtml.match(reg) || []);
      result = result.concat(mailEditorFormData.bodyText.match(reg) || []);
      result = result.map((x) => x.replace(/%/g, ''));
      // 重複削除
      const uniqueReplaceWords = new Set(result);
      const replaceWordObj = [
        ...uniqueReplaceWords,
      ].reduce<SendMailReplaceType>(
        (acc, val) => ({ ...acc, [val]: mailReplaceWord[val] || '' }),
        {},
      );

      setMailReplaceWord(replaceWordObj);
    }, [getValues, mailReplaceWord]);

    // ポップアップOpen
    const onOpenHandler = useCallback(() => {
      replaceWord();
      onOpen();
    }, [onOpen, replaceWord]);

    // テストメール送信
    const onSendMailSubmit = useCallback(() => {
      onSendMail(
        mailAddress,
        fromAddress.name,
        replayAddress.name,
        mailReplaceWord,
      );
    }, [mailAddress, fromAddress, replayAddress, mailReplaceWord, onSendMail]);

    // メールアドレス変更時イベント
    const onChangeAddress = useCallback(
      (
        event: ChangeEvent<HTMLSelectElement>,
        key: 'fromAddress' | 'replayAddress',
      ) => {
        if (key === 'fromAddress') {
          const target = mailFromAddressList.filter(
            (x) => x.id === Number(event.target.value),
          );
          setFromAddress(target[0]);
        }
        if (key === 'replayAddress') {
          const target = mailReplyAddressList.filter(
            (x) => x.id === Number(event.target.value),
          );
          setReplayAddress(target[0]);
        }
      },
      [mailFromAddressList, mailReplyAddressList],
    );

    return (
      <Popover isOpen={isOpen} onClose={onClose} placement="bottom-end">
        <PopoverTrigger>
          <Button borderColor="gray.400" onClick={onOpenHandler}>
            テストメール設定
          </Button>
        </PopoverTrigger>

        <PopoverContent width={450}>
          <PopoverBody>
            <Heading as="h2" size="sm" mb="4">
              テストメールを配信します。
            </Heading>
            <Stack spacing={4} mb={4}>
              <Box>
                <Text mb={2}>メールアドレス</Text>
                <Textarea
                  value={mailAddress}
                  placeholder="xxxxx@xxxxxx.com"
                  borderColor="gray.200"
                  onChange={(e) => setMailAddress(e.target.value)}
                  size="sm"
                  resize="none"
                  borderRadius="md"
                />
              </Box>

              <Box>
                <Text mb={2}>差出人メールアドレス</Text>
                <Select
                  onChange={(e) => onChangeAddress(e, 'fromAddress')}
                  value={fromAddress.id}
                  placeholder="選択してください"
                >
                  {mailFromAddressList.map((item) => (
                    <option key={item.id} value={item.id}>
                      {item.name}
                    </option>
                  ))}
                </Select>
              </Box>

              <Box>
                <Text mb={2}>返信用メールアドレス（任意）</Text>
                <Select
                  onChange={(e) => onChangeAddress(e, 'replayAddress')}
                  value={replayAddress.id}
                  placeholder="選択してください"
                >
                  {mailReplyAddressList.map((item) => (
                    <option key={item.id} value={item.id}>
                      {item.name}
                    </option>
                  ))}
                </Select>
              </Box>
            </Stack>
            <Box
              display="flex"
              justifyContent={
                Object.keys(mailReplaceWord).length === 0
                  ? 'flex-end'
                  : 'space-between'
              }
            >
              {Object.keys(mailReplaceWord).length > 0 && (
                <MailReplacePopover
                  mailReplaceWord={mailReplaceWord}
                  setMailReplaceWord={setMailReplaceWord}
                />
              )}
              <Button
                variant="primary"
                onClick={onSendMailSubmit}
                isLoading={isLoading}
              >
                送信
              </Button>
            </Box>
          </PopoverBody>
        </PopoverContent>
      </Popover>
    );
  },
);
