import {
  maxLength,
  headTitleOptions,
  screenNameOptions,
  buttonNameOptions,
  itemNameOptions,
  textareaElementOptions,
} from './options';

const groupName = 'login';

// ログイン画面
export default [
  // ロゴ	画像
  {
    name: `${groupName}.headTitle`,
    label: 'ブラウザタブ表示名',
    tooltip: '',
    type: 'Text',
    defaultValue: 'ログイン',
    options: headTitleOptions,
    elementOptions: {},
  },
  {
    name: `${groupName}.screenName`,
    label: '画面名',
    tooltip: '',
    type: 'Text',
    defaultValue: 'ログイン',
    options: screenNameOptions,
    elementOptions: {},
  },
  {
    name: `${groupName}.emailItemName`,
    label: '入力欄名 メールアドレス',
    tooltip: '',
    type: 'Text',
    defaultValue: 'メールアドレス',
    options: itemNameOptions,
    elementOptions: {},
  },
  {
    name: `${groupName}.passwordItemName`,
    label: '入力欄名 パスワード',
    tooltip: '',
    type: 'Text',
    defaultValue: 'パスワード',
    options: itemNameOptions,
    elementOptions: {},
  },
  // {
  //   name: `${groupName}.nextAutoLoginGuide`,
  //   label: '次回自動ログイン説明文',
  //   tooltip: '',
  //   type: 'Textarea',
  //   defaultValue: '次回から自動ログインする',
  //   options: {
  //     maxLength: maxLength(50),
  //   },
  //   elementOptions: textareaElementOptions,
  // },
  // captcha	-
  {
    name: `${groupName}.loginButtonName`,
    label: 'ボタン名 ログイン',
    tooltip: '',
    type: 'Text',
    defaultValue: 'ログイン',
    options: buttonNameOptions,
    elementOptions: {},
  },
  {
    name: `${groupName}.passwordRecoveryGuide`,
    label: '案内文 パスワードリセット',
    tooltip: '',
    type: 'Textarea',
    defaultValue: 'パスワードリセットはこちら',
    options: {
      maxLength: maxLength(50),
    },
    elementOptions: textareaElementOptions,
  },
  // SNS選択	-
  {
    name: `${groupName}.unregisteredUserGuide`,
    label: '案内文 未登録者向け案内',
    tooltip: '',
    type: 'Textarea',
    defaultValue: 'アカウントをお持ちでない方',
    options: {
      maxLength: maxLength(50),
    },
    elementOptions: textareaElementOptions,
  },
  {
    name: `${groupName}.signupButtonName`,
    label: 'ボタン名 新規登録',
    tooltip: '',
    type: 'Text',
    defaultValue: '新規登録',
    options: buttonNameOptions,
    elementOptions: {},
  },
] as const;
