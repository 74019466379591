import { patchMailMagazinePublish } from 'api/mail/patchMailMagazinePublish';
import { ResponseError } from 'api/types';
import { mailMagazineDefaultValue } from 'components/setting/mailMagazine/initial';
import {
  MailMagazine,
  isMailMagazine,
  MailMagazineForm,
} from 'components/setting/mailMagazine/typed';
import { useCustomToast } from 'hooks/useCustomToast';
import { UseMutateFunction, useMutation } from 'react-query';
import { isGetValidationError } from 'utils/form';
import { useMailUtil } from 'hooks/mail/useMailUtil';

type UnPromisify<T> = T extends Promise<infer U> ? U : T;

/**
 * メールマガジンステータス変更hooks
 * 正常時: true
 * エラー: ResponseError
 *
 */
export const useMailMagazinePublish = (): {
  onSubmit: UseMutateFunction<
    UnPromisify<ReturnType<typeof patchMailMagazinePublish>>,
    unknown,
    MailMagazineForm,
    unknown
  >;
  isLoading: boolean;
} => {
  const { MailMagazineListRefetchQueries } = useMailUtil();
  const toast = useCustomToast();

  const { mutate: onSubmit, isLoading } = useMutation(
    (params: MailMagazineForm) => patchMailMagazinePublish(params),
    {
      onSuccess: (result: MailMagazine | boolean | ResponseError) => {
        MailMagazineListRefetchQueries();
        if (isMailMagazine(result)) {
          const isPublicStr = result.isPublic ? '有効' : '無効';
          toast({
            status: 'success',
            position: 'bottom',
            duration: 2000,
            isClosable: true,
            title: `メールマガジン「${result.title}」を${isPublicStr}に設定しました`,
          });
        }

        if (
          isGetValidationError<MailMagazineForm>(
            result,
            Object.keys(mailMagazineDefaultValue),
          )
        ) {
          const errorMsg = Object.entries(result).map(([_, value]) => value);
          toast({
            status: 'error',
            position: 'bottom',
            duration: 4000,
            isClosable: true,
            title: errorMsg[0],
          });
        }
      },
    },
  );

  return { onSubmit, isLoading };
};
