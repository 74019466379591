import firstInfoEntry from './firstInfoEntry';
import missingInfoEntry from './missingInfoEntry';
import footer from './footer';
import header from './header';
import login from './login';
import register from './register';
import infoConfirmation from './infoConfirmation';
import memberInfoChange from './memberInfoChange';
import memberInfoConfirm from './memberInfoConfirm';
import memberInfoEntry from './memberInfoEntry';
import mypage from './mypage';
import newEmailEntry from './newEmailEntry';
import newPasswordEntry from './newPasswordEntry';
import passwordCreate from './passwordCreate';
import passwordReset from './passwordReset';
import passwordUnset from './passwordUnset';
import registrationComplete from './registrationComplete';
import theme from './theme';
import verificationCodeEntry from './verificationCodeEntry';
import withdrawal from './withdrawal';
import withdrawalComplete from './withdrawalComplete';
import withdrawalConfirmation from './withdrawalConfirmation';
import complete from './complete';
import emailChange from './emailChange';
import passwordChange from './passwordChange';
import embeddedTags from './embeddedTags';

export const settings = {
  theme,
  header,
  footer,
  login,
  register,
  passwordCreate,
  passwordUnset,
  passwordReset,
  newPasswordEntry,
  memberInfoEntry,
  firstInfoEntry,
  missingInfoEntry,
  infoConfirmation,
  registrationComplete,
  mypage,
  memberInfoChange,
  memberInfoConfirm,
  verificationCodeEntry,
  newEmailEntry,
  withdrawal,
  withdrawalConfirmation,
  withdrawalComplete,
  complete,
  emailChange,
  passwordChange,
  embeddedTags,
} as const;

export type Settings = typeof settings;
