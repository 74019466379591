import { usePaywallAuth } from 'components/paywall/hooks/usePaywallAuth';
import { Paywall } from 'components/paywall/index';
import { CourseList } from 'components/paywall/pages/CourseList/';
import { CustomerDetail } from 'components/paywall/pages/CustomerDetail/';
import { CustomerList } from 'components/paywall/pages/CustomerList/';
import { Dashboard } from 'components/paywall/pages/Dashboard';
import { ItemDesignList } from 'components/paywall/pages/ItemDesignList/';
import { ItemList } from 'components/paywall/pages/ItemList/';
import { MailSetting } from 'components/paywall/pages/MailSetting';
import { MailSettingEdit } from 'components/paywall/pages/MailSetting/MailSettingEdit';
import { MembersOnlyWallSetting } from 'components/paywall/pages/MembersOnlyWallSetting/';
import { OrderDetail } from 'components/paywall/pages/OrderDetail/';
import { OrderList } from 'components/paywall/pages/OrderList/';
import { PromotionList } from 'components/paywall/pages/PromotionList/';
import { SubscriptionDetail } from 'components/paywall/pages/SubscriptionDetail/';
import { SubscriptionList } from 'components/paywall/pages/SubscriptionList/';
import { TermsSetting } from 'components/paywall/pages/TermsSetting/TermsSetting';
import { WallDesignList } from 'components/paywall/pages/WallDesignList';
import { WallEditor } from 'components/paywall/pages/WallDesignList/WallEditor';
import { WallItemList } from 'components/paywall/pages/WallItemList/';
import { WallList } from 'components/paywall/pages/WallList/';
import { FC } from 'react';
import { Navigate, RouteObject } from 'react-router-dom';

const redirectPath = '/paywall';

// ペイウォールアクセス制御処理HOC
const withAuthCheckRoutingControl = (WrappedComponent: React.ComponentType, redirect: string, permissionKey: string): FC => {
  const WithAuthCheckRoutingControl: FC = (props) => {
    const {isAllowedPermission} = usePaywallAuth();
    // アクセスが許可されていない場合は設定されているリダイレクトへ遷移させる
    if(!isAllowedPermission(permissionKey)) return <Navigate to={redirect} />;

    return <WrappedComponent {...props} />;
  };

  return WithAuthCheckRoutingControl;
};

// HOCを適用する
const PaywallWallListWithAuthCheckRouting = withAuthCheckRoutingControl(WallList, redirectPath, 'wallList');
const PaywallCourseListWithAuthCheckRouting = withAuthCheckRoutingControl(CourseList, redirectPath, 'wallList');
const PaywallPromotionListWithAuthCheckRouting = withAuthCheckRoutingControl(PromotionList, redirectPath, 'wallList');
const PaywallItemListWithAuthCheckRouting = withAuthCheckRoutingControl(ItemList, redirectPath, 'itemList');
const PaywallItemDesignListWithAuthCheckRouting = withAuthCheckRoutingControl(ItemDesignList, redirectPath, 'itemList');
const PaywallWallDesignListWithAuthCheckRouting = withAuthCheckRoutingControl(WallDesignList, redirectPath, 'wallList');
const PaywallWallEditorWithAuthCheckRouting = withAuthCheckRoutingControl(WallEditor, redirectPath, 'wallList');
const PaywallWallItemListWithAuthCheckRouting = withAuthCheckRoutingControl(WallItemList, redirectPath, 'itemList');
const PaywallMembersOnlyWallSettingWithAuthCheckRouting = withAuthCheckRoutingControl(MembersOnlyWallSetting, redirectPath, 'membersOnlyWallSetting');
const PaywallDashboardWithAuthCheckRouting = withAuthCheckRoutingControl(Dashboard, redirectPath, 'dashboard');
const PaywallCustomerListWithAuthCheckRouting = withAuthCheckRoutingControl(CustomerList, redirectPath, 'customerList');
const PaywallCustomerDetailWithAuthCheckRouting = withAuthCheckRoutingControl(CustomerDetail, redirectPath, 'customerList');
const PaywallOrderListWithAuthCheckRouting = withAuthCheckRoutingControl(OrderList, redirectPath, 'orderList');
const PaywallOrderDetailWithAuthCheckRouting = withAuthCheckRoutingControl(OrderDetail, redirectPath, 'orderList');
const PaywallSubscriptionListWithAuthCheckRouting = withAuthCheckRoutingControl(SubscriptionList, redirectPath, 'subscriptionList');
const PaywallSubscriptionDetailWithAuthCheckRouting = withAuthCheckRoutingControl(SubscriptionDetail, redirectPath, 'subscriptionList');
const PaywallMailSettingWithAuthCheckRouting = withAuthCheckRoutingControl(MailSetting, redirectPath, 'mail');
const PaywallMailSettingEditWithAuthCheckRouting = withAuthCheckRoutingControl(MailSettingEdit, redirectPath, 'mail');
const PaywallTermsSettingWithAuthCheckRouting = withAuthCheckRoutingControl(TermsSetting, redirectPath, 'terms');

export const PaywallRoutes: RouteObject[] = [
  {
    path: '',
    element: <Paywall />,
  },
  {
    path: 'dashboard',
    element: <PaywallDashboardWithAuthCheckRouting />,
  },
  {
    path: 'wallList',
    element: <PaywallWallListWithAuthCheckRouting />,
  },
  {
    path: ':wallId/courseList',
    element: <PaywallCourseListWithAuthCheckRouting />,
  },
  {
    path: ':wallId/:courseId/promotionList',
    element: <PaywallPromotionListWithAuthCheckRouting />,
  },
  {
    path: 'ItemList',
    element: <PaywallItemListWithAuthCheckRouting />,
  },
  {
    path: 'ItemDesignList',
    element: <PaywallItemDesignListWithAuthCheckRouting />,
  },
  {
    path: ':wallId/wallDesignList',
    element: <PaywallWallDesignListWithAuthCheckRouting />,
  },
  {
    path: ':wallId/WallEditor/form',
    element: <PaywallWallEditorWithAuthCheckRouting />,
  },
  {
    path: ':wallId/wallItemList',
    element: <PaywallWallItemListWithAuthCheckRouting />,
  },
  {
    path: 'MembersOnlyWallSetting',
    element: <PaywallMembersOnlyWallSettingWithAuthCheckRouting />,
  },
  {
    path: 'CustomerList',
    element: <PaywallCustomerListWithAuthCheckRouting />,
  },
  {
    path: ':id/CustomerDetail',
    element: <PaywallCustomerDetailWithAuthCheckRouting />,
  },
  {
    path: 'OrderList',
    element: <PaywallOrderListWithAuthCheckRouting />,
  },
  {
    path: ':orderId/OrderDetail',
    element: <PaywallOrderDetailWithAuthCheckRouting />,
  },
  {
    path: 'SubscriptionList',
    element: <PaywallSubscriptionListWithAuthCheckRouting />,
  },
  {
    path: ':id/SubscriptionDetail',
    element: <PaywallSubscriptionDetailWithAuthCheckRouting />,
  },
  {
    path: 'mail',
    element: <PaywallMailSettingWithAuthCheckRouting />,
  },
  {
    path: 'mail/edit',
    element: <PaywallMailSettingEditWithAuthCheckRouting />,
  },
  {
    path: 'terms',
    element: <PaywallTermsSettingWithAuthCheckRouting />,
  },
];
