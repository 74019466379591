import { getTransactionMails } from 'admin/api/userPool/transactionMail/getTransactionMails';
import { TRANSACTION_MAIL_PERSONALS } from 'admin/define/idPoolConsumer/transactionMail';
import { useIdPoolConsumerId } from 'admin/hooks/useIdPoolConsumerId';
import { TransactionMailCategoryType } from 'admin/types/userPool/transactionMail';
import { useCustomToast } from 'hooks/useCustomToast';
import { useMemo } from 'react';
import { useQuery } from 'react-query';
import { errorToast } from 'utils/toast';
import { queryTransactionMailKey } from './queryTransactionMailKey';

export const useTransactionMails = (mailType: TransactionMailCategoryType) => {
  const transactionMailTypeName = useMemo(
    () => TRANSACTION_MAIL_PERSONALS.get(mailType)?.name || '',
    [mailType],
  );
  const idPoolConsumerId = useIdPoolConsumerId();
  const toast = useCustomToast();
  const { data } = useQuery({
    queryKey: queryTransactionMailKey.getTransactionMails(
      idPoolConsumerId,
      mailType,
    ),
    queryFn: () => getTransactionMails(idPoolConsumerId, mailType),
    onError: () => {
      toast({
        ...errorToast,
        duration: 4000,
        title: `${transactionMailTypeName}設定一覧情報の取得に失敗しました`,
        position: 'bottom',
        variant: 'solid',
      });
    },
  });

  return data;
};
