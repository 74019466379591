import { AlertDialog, Box, Text, useDisclosure } from '@chakra-ui/react';
import { withSuspenseAndErrorBoundary } from 'admin/components/Error/WithErrorBoundary';
import { TransactionMailStatusChangeDialog } from 'admin/components/idPoolConsumer/transactionMail/statusChangeDialog';
import { TransactionMailsTableComponent } from 'admin/components/idPoolConsumer/transactionMail/transactionMailsTable/TransactionMailsTableComponent';
import { Colors } from 'admin/define/colors';
import { TRANSACTION_MAIL_PERSONALS } from 'admin/define/idPoolConsumer/transactionMail';
import { useTransactionMails } from 'admin/hooks/userPool/transactionMail/useTransactionMails';
import {
  TransactionMailCategoryType,
  TransactionMailType,
} from 'admin/types/userPool/transactionMail';
import { ErrorContents } from 'components/common/atoms';
import { FC, memo, useCallback, useMemo, useRef, useState } from 'react';

type Props = {
  transactionMailType: TransactionMailCategoryType;
};

const transactionMailsTable: FC<Props> = memo(({ transactionMailType }) => {
  const cancelRef = useRef(null);
  const transactionMails = useTransactionMails(transactionMailType);
  const { isOpen, onOpen, onClose } = useDisclosure();
  const [transactionMail, setTransactionMail] = useState<TransactionMailType>({
    id: '',
    subject: '',
    status: false,
    type: 'authenticationCode',
    settingTitle: '',
    editorHtml: '',
    editorText: '',
    corporation: '',
    tenant: '',
  });

  const onOpenModal = useCallback(
    (transactionMailsData: TransactionMailType) => {
      setTransactionMail(transactionMailsData);
      onOpen();
    },
    [onOpen],
  );

  if (transactionMails === undefined) {
    return null;
  }

  return (
    <>
      {transactionMails.length === 0 ? (
        <Box
          mt="16px"
          p="40px"
          border="1px solid"
          borderColor={Colors.GRAY_30}
          bgColor={Colors.WHITE}
          borderRadius="8px"
        >
          <Box textAlign="center">
            <Text
              fontWeight="700"
              fontSize="24px"
              lineHeight="170%"
              fontFamily="Hiragino Sans"
            >
              メール設定が未作成です。
            </Text>
          </Box>
        </Box>
      ) : (
        <TransactionMailsTableComponent
          transactionMails={transactionMails}
          transactionMailType={transactionMailType}
          onOpenModal={onOpenModal}
        />
      )}
      <AlertDialog
        isOpen={isOpen}
        onClose={onClose}
        leastDestructiveRef={cancelRef}
        isCentered
        closeOnOverlayClick={false}
        closeOnEsc={false}
        size="xl"
      >
        <TransactionMailStatusChangeDialog
          transactionMail={transactionMail}
          onClose={onClose}
        />
      </AlertDialog>
    </>
  );
});

const CustomErrorContents = ({ transactionMailType }: Props) => {
  const transactionMailTypeName = useMemo(
    () => TRANSACTION_MAIL_PERSONALS.get(transactionMailType)?.name || '',
    [transactionMailType],
  );

  return <ErrorContents name={`${transactionMailTypeName}一覧`} />;
};

export const TransactionMailsTable = (props: Props) => {
  const ErrorComponentWithProps = <CustomErrorContents {...props} />;

  const WrappedTransactionMailsTable = withSuspenseAndErrorBoundary(
    transactionMailsTable,
    {
      ErrorComponent: ErrorComponentWithProps,
    },
  );

  return <WrappedTransactionMailsTable {...props} />;
};
