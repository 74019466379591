import { withSuspenseAndErrorBoundary } from 'admin/components/Error/WithErrorBoundary';
import { MemberContents } from 'admin/components/member/detail/memberContents';
import { MemberTitle } from 'admin/components/member/detail/memberTitle';
import { Breadcrumb } from 'admin/components/ui/breadcrumb';
import { useMember } from 'admin/hooks/member/useMember';
import { useUserPoolId } from 'admin/hooks/params/useUserPoolId';
import { BreadcrumbType } from 'admin/types/ui/breadcrumb';
import { ErrorContents, LoadingSpinner } from 'components/common/atoms';
import {
  MEMBER_STATUS_TYPE_WITHDRAWAL,
  MEMBER_STATUS_TYPE_WITHDRAWAL_COMPULSION,
} from 'define';
import { useSetPageTitle } from 'hooks/useSetPageTitle';
import { FC, memo, useEffect, useMemo } from 'react';
import { useNavigate, useParams } from 'react-router-dom';

export const memberDetail: FC = memo(() => {
  useSetPageTitle('会員詳細');
  const navigate = useNavigate();
  const breadcrumbItems: BreadcrumbType[] = [
    { url: '/admin', title: '組織管理TOP' },
    { url: '/admin/member', title: '会員管理' },
    { url: '', title: '会員詳細' },
  ];

  const userPoolId = useUserPoolId();
  const params = useParams();
  const memberId = useMemo(() => params.memberId ?? '', [params.memberId]);
  const { data: member } = useMember(userPoolId, memberId);

  useEffect(() => {
    // 会員詳細のステータスが「退会済」「退会済（強制）」の会員の場合、無効なアクセスと判定し組織管理画面TOPへ遷移させる
    if (
      member?.status === MEMBER_STATUS_TYPE_WITHDRAWAL ||
      member?.status === MEMBER_STATUS_TYPE_WITHDRAWAL_COMPULSION
    ) {
      navigate('/admin');
    }
  }, [navigate, member]);

  return (
    <>
      <Breadcrumb breadcrumbItems={breadcrumbItems} />
      <MemberTitle />
      <MemberContents />
    </>
  );
});

export const MemberDetail = memo(
  withSuspenseAndErrorBoundary(memberDetail, {
    ErrorComponent: <ErrorContents name="会員詳細" />,
    LoadingComponent: <LoadingSpinner />,
  }),
);
