import { Checkbox, CheckboxProps } from '@chakra-ui/react';
import { FC } from 'react';
import {
  FieldValues,
  Path,
  useController,
  useFormContext,
} from 'react-hook-form';

type RhfCheckboxProps<T> = {
  name: Path<T>;
  isChecked?: boolean;
} & Pick<
  CheckboxProps,
  'variant' | 'isDisabled' | 'size' | 'children' | 'value' | 'alignItems'
>;

export const RhfCheckbox = <T extends FieldValues>({
  name,
  isChecked,
  ...rest
}: RhfCheckboxProps<T>): ReturnType<FC> => {
  const { control, getValues } = useFormContext<FieldValues>();
  const { field } = useController({ name, control });

  return (
    <Checkbox isChecked={getValues(name) || isChecked} {...field} {...rest} />
  );
};
