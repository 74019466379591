import { Term, TermsSetting } from "./typed";

/**
 * TermsSettingType デフォルト値
 */
export const termsSettingDefaultValues: TermsSetting = {
  title: '',
  description: '',
  terms: [] as Term[]
} as const;

/**
 * TermType デフォルト値
 */
export const termDefaultValues: Term = {
  title: '',
  url: ''
}
