// パーミッション定数
// オーナー
// ID設定担当
// テナント権限管理
// 個人情報運用者

export const PERMISSION_ADMIN_DATA_OPERATOR = 20; // 個人情報運用者
export const PERMISSION_ADMIN_INDIVIDUAL_SERVICE = 40; // テナント権限管理
export const PERMISSION_ADMIN_ID_MANAGER = 50; // ID設定担当
export const PERMISSION_ADMIN_OWNER = 90; // オーナー

export const PERMISSION_KEYS = [
  PERMISSION_ADMIN_DATA_OPERATOR,
  PERMISSION_ADMIN_INDIVIDUAL_SERVICE,
  PERMISSION_ADMIN_ID_MANAGER,
  PERMISSION_ADMIN_OWNER,
] as const;

export const PERMISSION_ADMIN_DATA_OPERATOR_LABEL = '個人情報運用者';
export const PERMISSION_ADMIN_INDIVIDUAL_SERVICE_LABEL = 'テナント権限管理';
export const PERMISSION_ADMIN_ID_MANAGER_LABEL = 'ID設定担当';
export const PERMISSION_ADMIN_OWNER_LABEL = 'オーナー';

export const PERMISSION_LABELS = [
  PERMISSION_ADMIN_DATA_OPERATOR_LABEL,
  PERMISSION_ADMIN_INDIVIDUAL_SERVICE_LABEL,
  PERMISSION_ADMIN_ID_MANAGER_LABEL,
  PERMISSION_ADMIN_OWNER_LABEL,
] as const;

// 権限一覧
const PERMISSIONS_MAP = new Map<
  typeof PERMISSION_KEYS[number],
  typeof PERMISSION_LABELS[number]
>([
  [PERMISSION_ADMIN_DATA_OPERATOR, PERMISSION_ADMIN_DATA_OPERATOR_LABEL],
  [
    PERMISSION_ADMIN_INDIVIDUAL_SERVICE,
    PERMISSION_ADMIN_INDIVIDUAL_SERVICE_LABEL,
  ],
  [PERMISSION_ADMIN_ID_MANAGER, PERMISSION_ADMIN_ID_MANAGER_LABEL],
  [PERMISSION_ADMIN_OWNER, PERMISSION_ADMIN_OWNER_LABEL],
]);

export const PERMISSIONS: ReadonlyMap<number, string> = PERMISSIONS_MAP;

export const PERMISSION_FEATURE_ADMIN_DASHBOARD = 10;
export const PERMISSION_FEATURE_ADMIN_USER = 20;
export const PERMISSION_FEATURE_ADMIN_SERVICE = 30;
export const PERMISSION_FEATURE_ADMIN_SAML = 40;
export const PERMISSION_FEATURE_ADMIN_SETTING = 50;
export const PERMISSION_FEATURE_ADMIN_USER_POOL = 60;

export const PERMISSION_FEATURE_ADMIN_KEYS = [
  PERMISSION_FEATURE_ADMIN_DASHBOARD,
  PERMISSION_FEATURE_ADMIN_USER,
  PERMISSION_FEATURE_ADMIN_SERVICE,
  PERMISSION_FEATURE_ADMIN_SAML,
  PERMISSION_FEATURE_ADMIN_SETTING,
  PERMISSION_FEATURE_ADMIN_USER_POOL,
] as const;

export const PERMISSION_FEATURE_ADMIN_DASHBOARD_LABEL = 'ダッシュボード';
export const PERMISSION_FEATURE_ADMIN_USER_LABEL = '組織アカウント管理';
export const PERMISSION_FEATURE_ADMIN_SERVICE_LABEL = 'テナント アカウント管理';
export const PERMISSION_FEATURE_ADMIN_SAML_LABEL = 'SAML設定';
export const PERMISSION_FEATURE_ADMIN_SETTING_LABEL = '設定';
export const PERMISSION_FEATURE_ADMIN_USER_POOL_LABEL =
  'ユーザープール作成・編集・割り当て';

export const PERMISSION_FEATURE_ADMIN_LABELS = [
  PERMISSION_FEATURE_ADMIN_DASHBOARD_LABEL,
  PERMISSION_FEATURE_ADMIN_USER_LABEL,
  PERMISSION_FEATURE_ADMIN_SERVICE_LABEL,
  PERMISSION_FEATURE_ADMIN_SAML_LABEL,
  PERMISSION_FEATURE_ADMIN_SETTING_LABEL,
  PERMISSION_FEATURE_ADMIN_USER_POOL_LABEL,
] as const;

// 機能権限一覧
const PERMISSIONS_FEATURE_MAP = new Map<
  typeof PERMISSION_FEATURE_ADMIN_KEYS[number],
  typeof PERMISSION_FEATURE_ADMIN_LABELS[number]
>([
  [
    PERMISSION_FEATURE_ADMIN_DASHBOARD,
    PERMISSION_FEATURE_ADMIN_DASHBOARD_LABEL,
  ],
  [PERMISSION_FEATURE_ADMIN_USER, PERMISSION_FEATURE_ADMIN_USER_LABEL],
  [PERMISSION_FEATURE_ADMIN_SERVICE, PERMISSION_FEATURE_ADMIN_SERVICE_LABEL],
  [PERMISSION_FEATURE_ADMIN_SAML, PERMISSION_FEATURE_ADMIN_SAML_LABEL],
  [PERMISSION_FEATURE_ADMIN_SETTING, PERMISSION_FEATURE_ADMIN_SETTING_LABEL],
  [
    PERMISSION_FEATURE_ADMIN_USER_POOL,
    PERMISSION_FEATURE_ADMIN_USER_POOL_LABEL,
  ],
]);

export const PERMISSION_FEATURES: ReadonlyMap<number, string> =
  PERMISSIONS_FEATURE_MAP;

export const PERMISSION_ADMIN_USER_POOL_ID_SYSTEM = 10;
export const PERMISSION_ADMIN_USER_POOL_SERVICE = 20;
export const PERMISSION_ADMIN_USER_POOL_MEMBER = 30;

export const PERMISSION_ADMIN_USER_POOL_KEYS = [
  PERMISSION_ADMIN_USER_POOL_ID_SYSTEM,
  PERMISSION_ADMIN_USER_POOL_SERVICE,
  PERMISSION_ADMIN_USER_POOL_MEMBER,
] as const;

export const PERMISSION_ADMIN_USER_POOL_ID_SYSTEM_LABEL =
  'IDシステム管理（コンシューマー）';
export const PERMISSION_ADMIN_USER_POOL_SERVICE_LABEL = 'テナント管理';
export const PERMISSION_ADMIN_USER_POOL_MEMBER_LABEL = '会員管理';

export const PERMISSION_ADMIN_USER_POOL_LABELS = [
  PERMISSION_ADMIN_USER_POOL_ID_SYSTEM_LABEL,
  PERMISSION_ADMIN_USER_POOL_SERVICE_LABEL,
  PERMISSION_ADMIN_USER_POOL_MEMBER_LABEL,
] as const;

// 機能権限のユーザープール権限一覧
const PERMISSION_ADMIN_USER_POOL_MAP = new Map<
  typeof PERMISSION_ADMIN_USER_POOL_KEYS[number],
  typeof PERMISSION_ADMIN_USER_POOL_LABELS[number]
>([
  [
    PERMISSION_ADMIN_USER_POOL_ID_SYSTEM,
    PERMISSION_ADMIN_USER_POOL_ID_SYSTEM_LABEL,
  ],
  [
    PERMISSION_ADMIN_USER_POOL_SERVICE,
    PERMISSION_ADMIN_USER_POOL_SERVICE_LABEL,
  ],
  [PERMISSION_ADMIN_USER_POOL_MEMBER, PERMISSION_ADMIN_USER_POOL_MEMBER_LABEL],
]);

export const PERMISSION_ADMIN_USER_POOLS: ReadonlyMap<number, string> =
  PERMISSION_ADMIN_USER_POOL_MAP;

// TODO: API繋ぎこみ時は不要になる可能性が高いです。(テナントアカウント一覧実装時に追加)
// テナント アカウント管理
export const PERMISSIONS_TENANTUSERS_SERVICE_A = 10; // テストサービスA
export const PERMISSIONS_TENANTUSERS_SERVICE_B = 20; // テストサービスB
export const PERMISSIONS_TENANTUSERS_SERVICE_C = 30; // テストサービスC
export const PERMISSIONS_TENANTUSERS_SERVICE_D = 40; // テストサービスD

export const PERMISSIONS_TENANTUSERS_KEYS = [
  PERMISSIONS_TENANTUSERS_SERVICE_A,
  PERMISSIONS_TENANTUSERS_SERVICE_B,
  PERMISSIONS_TENANTUSERS_SERVICE_C,
  PERMISSIONS_TENANTUSERS_SERVICE_D,
] as const;

export const PERMISSIONS_TENANTUSERS_SERVICE_A_LABEL = 'テストサービスA';
export const PERMISSIONS_TENANTUSERS_SERVICE_B_LABEL = 'テストサービスB';
export const PERMISSIONS_TENANTUSERS_SERVICE_C_LABEL = 'テストサービスC';
export const PERMISSIONS_TENANTUSERS_SERVICE_D_LABEL = 'テストサービスD';

export const PERMISSIONS_TENANTUSERS_LABELS = [
  PERMISSIONS_TENANTUSERS_SERVICE_A_LABEL,
  PERMISSIONS_TENANTUSERS_SERVICE_B_LABEL,
  PERMISSIONS_TENANTUSERS_SERVICE_C_LABEL,
  PERMISSIONS_TENANTUSERS_SERVICE_D_LABEL,
] as const;

// 権限一覧
const PERMISSIONS_TENANTUSERS_MAP = new Map<
  typeof PERMISSIONS_TENANTUSERS_KEYS[number],
  typeof PERMISSIONS_TENANTUSERS_LABELS[number]
>([
  [PERMISSIONS_TENANTUSERS_SERVICE_A, PERMISSIONS_TENANTUSERS_SERVICE_A_LABEL],
  [PERMISSIONS_TENANTUSERS_SERVICE_B, PERMISSIONS_TENANTUSERS_SERVICE_B_LABEL],
  [PERMISSIONS_TENANTUSERS_SERVICE_C, PERMISSIONS_TENANTUSERS_SERVICE_C_LABEL],
  [PERMISSIONS_TENANTUSERS_SERVICE_D, PERMISSIONS_TENANTUSERS_SERVICE_D_LABEL],
]);

export const PERMISSIONS_TENANTUSERS: ReadonlyMap<number, string> =
  PERMISSIONS_TENANTUSERS_MAP;
