import { TRANSACTION_MAIL_PERSONAL_TYPE } from 'admin/define/idPoolConsumer/transactionMail';

export type TransactionMailCommonType = {
  id?: string;
  fromAddress: string;
  replyAddress: string | null;
};

export type TransactionMailCommonCreateType = Omit<
  TransactionMailCommonType,
  'id'
>;

export const TransactionMailCategoryMap = Object.fromEntries(
  TRANSACTION_MAIL_PERSONAL_TYPE.map((type) => [type, null]),
) as { [K in typeof TRANSACTION_MAIL_PERSONAL_TYPE[number]]: null };

export type TransactionMailCategoryType =
  keyof typeof TransactionMailCategoryMap;

export type TransactionMailType = {
  id: string;
  subject: string;
  status: boolean;
  type: TransactionMailCategoryType;
  settingTitle: string;
  editorHtml: string | null;
  editorText: string | null;
  corporation: string;
  tenant: string | null;
};

export type TransactionMailsType = TransactionMailType[];

export type TransactionMailFormType = Omit<
  TransactionMailType,
  'corporation' | 'tenant'
> & {
  bodyHtml: string | null;
  bodyText: string | null;
};

export type TransactionMailTypeStatusType = {
  type: TransactionMailCategoryType;
  isActivated: boolean;
};

export type TransactionMailTypeStatusesType = TransactionMailTypeStatusType[];

export type TransactionMailInsertWord = {
  mailType: TransactionMailCategoryType;
  text: string;
  code: string;
};

export type TransactionMailPersonalType =
  typeof TRANSACTION_MAIL_PERSONAL_TYPE[number];

export type TransactionMailPersonal = {
  name: string;
  description: string;
};

export type TransactionMailPersonals = Record<
  TransactionMailCategoryType,
  TransactionMailPersonal
>;
