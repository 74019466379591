import { AddIcon } from '@chakra-ui/icons';
import {
  Box,
  Button,
  CloseButton,
  DrawerBody,
  DrawerFooter,
  Flex,
  FormControl,
  FormErrorMessage,
  Heading,
  Icon,
  List,
  ListItem,
  Text,
  VStack,
} from '@chakra-ui/react';
import { RhfInput } from 'admin/components/form/RhfInput';
import { RhfSelect } from 'admin/components/form/RhfSelect';
import { RequiredIcon } from 'admin/components/ui/icon/requiredIcon';
import { LoadingLayer } from 'admin/components/ui/loadingLayer';
import { Colors, ColorSet } from 'admin/define/colors';
import {
  DISPLAY_TYPE_ADDRESS_JA,
  DISPLAY_TYPE_LIST,
  UI_TYPE_ZIP_CODE_JA,
} from 'admin/define/field';
import { IdPoolConsumerAttributesType } from 'admin/types/userPool/attribute/index';
import { CreateAttributeGroupFormType } from 'admin/types/userPool/attributeGroup/form';
import { ChangeEvent, FC, memo } from 'react';
import {
  DragDropContext,
  Draggable,
  Droppable,
  DropResult,
} from 'react-beautiful-dnd';
import { useFieldArray, useFormContext, useFormState } from 'react-hook-form';
import { MdDragIndicator } from 'react-icons/md';
import { toErrMsgList } from 'utils/form';

type Props = {
  isEdit?: boolean;
  status?: boolean;
  isLoading: boolean;
  formId: string;
  onClose: () => void;
  onSubmit: (value: CreateAttributeGroupFormType) => void;
  attributes: IdPoolConsumerAttributesType;
};

export const ZIP_CODE_INDEX = 0;
export const PREFECTURE_INDEX = 1;
export const ADDRESS1 = 2;

export const FormComponent: FC<Props> = memo(
  ({
    isLoading,
    formId,
    onClose,
    onSubmit,
    attributes,
    isEdit,
    status,
  }: Props) => {
    const { handleSubmit, clearErrors, control, watch, setValue } =
      useFormContext<CreateAttributeGroupFormType>();
    const { errors } = useFormState<CreateAttributeGroupFormType>();
    const { fields, append, move, remove } = useFieldArray({
      control,
      name: 'attributeGroupBodyList',
      keyName: 'key',
    });
    const handleOnBlur = (e: ChangeEvent<HTMLInputElement>) => {
      if (e.target.value !== '') {
        clearErrors(['displayNameJa', 'displayNameForeign']);
      }
    };
    const handleSelectOnBlur = (e: ChangeEvent<HTMLSelectElement>) => {
      if (e.target.value !== '') {
        clearErrors(['attributeGroupBodyList']);
      }
    };
    const handleSelectOnChange = (index: number, value: string) => {
      setValue(`attributeGroupBodyList.${index}.userAttributeSetting`, value);
      clearErrors([`attributeGroupBodyList.${index}.userAttributeSetting`]);
    };
    const displayType = watch('displayType');

    const addAttribute = () => {
      append({
        userAttributeSetting: '',
        attributeRole: null,
      });
    };

    const onDragEnd = (result: DropResult) => {
      if (!result.destination) return;
      move(result.source.index, result.destination.index);
    };

    const onChangeDisplayType = (e: ChangeEvent<HTMLSelectElement>) => {
      setValue('displayType', e.target.value);
      clearErrors(['displayType', 'attributeGroupBodyList']);

      if (e.target.value === DISPLAY_TYPE_ADDRESS_JA) {
        setValue('attributeGroupBodyList', [
          {
            userAttributeSetting: '',
            attributeRole: 'zip_code_ja',
          },
          {
            userAttributeSetting: '',
            attributeRole: 'prefecture_ja',
          },
          {
            userAttributeSetting: '',
            attributeRole: 'address1',
          },
          {
            userAttributeSetting: '',
            attributeRole: 'address2',
          },
          {
            userAttributeSetting: '',
            attributeRole: 'address3',
          },
        ]);
      } else {
        setValue('attributeGroupBodyList', [
          {
            userAttributeSetting: '',
            attributeRole: null,
          },
        ]);
      }
    };

    return (
      <>
        {isLoading && <LoadingLayer />}
        <DrawerBody pt="16px" pb="16px">
          <form onSubmit={handleSubmit(onSubmit)} id={formId}>
            <VStack spacing={6}>
              <Box w="100%">
                <Heading as="h3" display="flex" size="sm" mb={2}>
                  表示名
                </Heading>
                <Box bg="#F7FAFC" p={4} borderWidth="1px" borderRadius="4px">
                  <Box w="100%">
                    <Heading as="h3" display="flex" fontSize="14px" mb={2}>
                      日本語
                      <RequiredIcon ml="8px" />
                    </Heading>
                    <Box>
                      <FormControl
                        isInvalid={
                          Boolean(errors.displayNameJa?.message) ||
                          toErrMsgList(errors, 'displayNameJa').length > 0
                        }
                      >
                        <Box>
                          <RhfInput<CreateAttributeGroupFormType>
                            name="displayNameJa"
                            bgColor={Colors.WHITE}
                            placeholder="例：住所"
                            onBlur={handleOnBlur}
                          />
                        </Box>

                        {toErrMsgList(errors, 'displayNameJa').map((err) => (
                          <FormErrorMessage key={`displayNameJa${err}`}>
                            {err}
                          </FormErrorMessage>
                        ))}
                      </FormControl>
                    </Box>
                  </Box>
                  <Box w="100%" mt={4}>
                    <Heading as="h3" fontSize="14px" mb={2}>
                      外国語
                    </Heading>
                    <Box>
                      <FormControl
                        isInvalid={
                          Boolean(errors.displayNameForeign?.message) ||
                          toErrMsgList(errors, 'displayNameForeign').length > 0
                        }
                      >
                        <Box>
                          <RhfInput<CreateAttributeGroupFormType>
                            name="displayNameForeign"
                            bgColor={Colors.WHITE}
                            placeholder="例：Address"
                            onBlur={handleOnBlur}
                          />
                        </Box>
                        {toErrMsgList(errors, 'displayNameForeign').map(
                          (err) => (
                            <FormErrorMessage key={`displayNameForeign${err}`}>
                              {err}
                            </FormErrorMessage>
                          ),
                        )}
                      </FormControl>
                    </Box>
                  </Box>
                </Box>
              </Box>
              <Box w="100%">
                <Heading
                  as="h3"
                  display="flex"
                  alignItems="center"
                  size="sm"
                  mb={2}
                >
                  画面上の配置
                  <RequiredIcon ml="8px" />
                </Heading>
                <Box w="100%">
                  <FormControl
                    isInvalid={
                      Boolean(errors.displayType?.message) ||
                      toErrMsgList(errors, 'displayType').length > 0
                    }
                  >
                    <RhfSelect<CreateAttributeGroupFormType>
                      name="displayType"
                      placeholder="選択してください。"
                      bgColor={Colors.WHITE}
                      onChange={onChangeDisplayType}
                      isDisabled={status}
                    >
                      {DISPLAY_TYPE_LIST.map((field, idx) => (
                        <option
                          value={field.value}
                          key={`field-${field.name}${String(idx)}`}
                        >
                          {field.name}
                        </option>
                      ))}
                    </RhfSelect>
                    {toErrMsgList(errors, 'displayType').map((err) => (
                      <FormErrorMessage key={`displayType${err}`}>
                        {err}
                      </FormErrorMessage>
                    ))}
                  </FormControl>
                </Box>
              </Box>

              {displayType !== DISPLAY_TYPE_ADDRESS_JA && (
                // 縦並び・横並び(2段組)の属性選択
                <Box w="100%">
                  <Heading
                    as="h3"
                    display="flex"
                    alignItems="center"
                    size="sm"
                    mb={2}
                  >
                    使用する属性
                    <RequiredIcon ml="8px" />
                  </Heading>
                  <Box w="100%">
                    <FormControl
                      isInvalid={Boolean(errors.attributeGroupBodyList)}
                      mb={4}
                    >
                      <DragDropContext onDragEnd={onDragEnd}>
                        <Droppable droppableId="attributeGroupBodyList">
                          {(dorppableProvided) => (
                            <List
                              mr={4}
                              w="100%"
                              ref={dorppableProvided.innerRef}
                              {...dorppableProvided.droppableProps}
                            >
                              {fields.map((_field, index) => (
                                <FormControl
                                  key={`attributeGroupBodyList${String(index)}`}
                                  isInvalid={Boolean(
                                    errors.attributeGroupBodyList?.[index]
                                      ?.userAttributeSetting,
                                  )}
                                  mb={4}
                                >
                                  <Draggable
                                    draggableId={`attributeGroupBodyList${String(
                                      index,
                                    )}`}
                                    index={index}
                                  >
                                    {(draggableProvided) => (
                                      <ListItem
                                        ref={draggableProvided.innerRef}
                                        {...draggableProvided.draggableProps}
                                      >
                                        <Flex alignItems="center">
                                          {!status && (
                                            <Box
                                              textAlign="center"
                                              display="flex"
                                              mr={2}
                                              {...draggableProvided.dragHandleProps}
                                            >
                                              <Icon
                                                as={MdDragIndicator}
                                                fontSize={24}
                                                color="gray.500"
                                              />
                                            </Box>
                                          )}

                                          <RhfSelect<CreateAttributeGroupFormType>
                                            name={`attributeGroupBodyList.${index}.userAttributeSetting`}
                                            placeholder="選択してください。"
                                            bgColor={Colors.WHITE}
                                            onBlur={handleSelectOnBlur}
                                            isDisabled={status}
                                          >
                                            {attributes
                                              .filter((item) => !!item.status)
                                              .map((attribute, idx) => (
                                                <option
                                                  value={attribute.id}
                                                  key={`attributeGroupBodyList-${
                                                    attribute.id
                                                  }${String(idx)}`}
                                                >
                                                  {attribute.displayNameJa}
                                                </option>
                                              ))}
                                          </RhfSelect>
                                          {!status && index > 0 && (
                                            <CloseButton
                                              onClick={() => remove(index)}
                                              ml="2"
                                              size="md"
                                            />
                                          )}
                                        </Flex>
                                      </ListItem>
                                    )}
                                  </Draggable>
                                  <FormErrorMessage
                                    key={`error_attributeGroupBodyList_${String(
                                      index,
                                    )}_userAttributeSetting`}
                                  >
                                    <Text style={{ textIndent: '2rem' }}>
                                      {
                                        errors.attributeGroupBodyList?.[index]
                                          ?.userAttributeSetting?.message
                                      }
                                    </Text>
                                  </FormErrorMessage>
                                </FormControl>
                              ))}
                              {dorppableProvided.placeholder}
                            </List>
                          )}
                        </Droppable>
                      </DragDropContext>
                      {toErrMsgList(errors, 'attributeGroupBodyList').map(
                        (err) => (
                          <FormErrorMessage
                            key={`attributeGroupBodyList${err}`}
                          >
                            {err}
                          </FormErrorMessage>
                        ),
                      )}
                    </FormControl>
                  </Box>
                  {!status && (
                    <Box textAlign="center">
                      <Button
                        mx="auto"
                        variant="outline"
                        colorScheme="primary"
                        onClick={addAttribute}
                      >
                        <AddIcon mr={2} fontSize="xs" fontWeight="thin" />
                        属性を追加
                      </Button>
                    </Box>
                  )}
                </Box>
              )}
              {displayType === DISPLAY_TYPE_ADDRESS_JA && (
                // 住所セット(日本)の属性選択
                <>
                  <Box w="100%">
                    <Heading
                      as="h3"
                      display="flex"
                      alignItems="center"
                      size="sm"
                      mb={4}
                    >
                      使用する属性
                    </Heading>
                    <FormControl
                      isInvalid={
                        toErrMsgList(errors, 'attributeGroupBodyList').length >
                        0
                      }
                    >
                      <Box w="50%" mb={4}>
                        <Heading
                          as="h3"
                          display="flex"
                          alignItems="center"
                          size="sm"
                          mb={2}
                        >
                          郵便番号
                          <RequiredIcon ml="8px" />
                        </Heading>
                        <Box w="100%">
                          <FormControl
                            isInvalid={Boolean(
                              errors?.attributeGroupBodyList &&
                                errors?.attributeGroupBodyList[ZIP_CODE_INDEX]
                                  ?.userAttributeSetting?.message,
                            )}
                          >
                            <RhfSelect<CreateAttributeGroupFormType>
                              name={`attributeGroupBodyList.${ZIP_CODE_INDEX}.userAttributeSetting`}
                              placeholder="選択してください。"
                              bgColor={Colors.WHITE}
                              isDisabled={status}
                              onChange={(e: ChangeEvent<HTMLSelectElement>) => {
                                handleSelectOnChange(
                                  ZIP_CODE_INDEX,
                                  e.target.value,
                                );
                              }}
                            >
                              {attributes
                                .filter(
                                  (item) =>
                                    item.uiType === UI_TYPE_ZIP_CODE_JA &&
                                    !!item.status,
                                )
                                .map((attribute, idx) => (
                                  <option
                                    value={attribute.id}
                                    key={`attributeGroupBodyList-${UI_TYPE_ZIP_CODE_JA}${
                                      attribute.id
                                    }${String(idx)}`}
                                  >
                                    {attribute.displayNameJa}
                                  </option>
                                ))}
                            </RhfSelect>
                            <FormErrorMessage>
                              {errors?.attributeGroupBodyList &&
                                errors?.attributeGroupBodyList[ZIP_CODE_INDEX]
                                  ?.userAttributeSetting?.message}
                            </FormErrorMessage>
                          </FormControl>
                        </Box>
                      </Box>
                      <Box w="50%" mb={4}>
                        <Heading
                          as="h3"
                          display="flex"
                          alignItems="center"
                          size="sm"
                          mb={2}
                        >
                          都道府県
                          <RequiredIcon ml="8px" />
                        </Heading>
                        <Box w="100%">
                          <FormControl
                            isInvalid={Boolean(
                              errors?.attributeGroupBodyList &&
                                errors?.attributeGroupBodyList[PREFECTURE_INDEX]
                                  ?.userAttributeSetting?.message,
                            )}
                          >
                            <RhfSelect<CreateAttributeGroupFormType>
                              name={`attributeGroupBodyList.${PREFECTURE_INDEX}.userAttributeSetting`}
                              placeholder="選択してください。"
                              bgColor={Colors.WHITE}
                              isDisabled={status}
                              onChange={(e: ChangeEvent<HTMLSelectElement>) => {
                                handleSelectOnChange(
                                  PREFECTURE_INDEX,
                                  e.target.value,
                                );
                              }}
                            >
                              {attributes
                                .filter(
                                  (item) =>
                                    item.uiType === 'selectbox' &&
                                    !!item.status,
                                )
                                .map((attribute, idx) => (
                                  <option
                                    value={attribute.id}
                                    key={`attributeGroupBodyList-prefecture${
                                      attribute.id
                                    }${String(idx)}`}
                                  >
                                    {attribute.displayNameJa}
                                  </option>
                                ))}
                            </RhfSelect>
                            <FormErrorMessage>
                              {errors?.attributeGroupBodyList &&
                                errors?.attributeGroupBodyList[PREFECTURE_INDEX]
                                  ?.userAttributeSetting?.message}
                            </FormErrorMessage>
                          </FormControl>
                        </Box>
                      </Box>
                      <Box w="50%" mb={4}>
                        <Heading
                          as="h3"
                          display="flex"
                          alignItems="center"
                          size="sm"
                          mb={2}
                        >
                          住所1（市区町村）
                          <RequiredIcon ml="8px" />
                        </Heading>
                        <Box w="100%">
                          <FormControl
                            isInvalid={Boolean(
                              errors?.attributeGroupBodyList &&
                                errors?.attributeGroupBodyList[ADDRESS1]
                                  ?.userAttributeSetting?.message,
                            )}
                          >
                            <RhfSelect<CreateAttributeGroupFormType>
                              name={`attributeGroupBodyList.${ADDRESS1}.userAttributeSetting`}
                              placeholder="選択してください。"
                              bgColor={Colors.WHITE}
                              isDisabled={status}
                              onChange={(e: ChangeEvent<HTMLSelectElement>) => {
                                handleSelectOnChange(ADDRESS1, e.target.value);
                              }}
                            >
                              {attributes
                                .filter(
                                  (item) =>
                                    item.uiType === 'text' && !!item.status,
                                )
                                .map((attribute, idx) => (
                                  <option
                                    value={attribute.id}
                                    key={`attributeGroupBodyList-address1${
                                      attribute.id
                                    }${String(idx)}`}
                                  >
                                    {attribute.displayNameJa}
                                  </option>
                                ))}
                            </RhfSelect>
                            <FormErrorMessage>
                              {errors?.attributeGroupBodyList &&
                                errors?.attributeGroupBodyList[ADDRESS1]
                                  ?.userAttributeSetting?.message}
                            </FormErrorMessage>
                          </FormControl>
                        </Box>
                      </Box>
                      <Box mb={4}>
                        <Heading
                          as="h3"
                          display="flex"
                          alignItems="center"
                          size="sm"
                          mb={1}
                        >
                          住所2（番地）
                        </Heading>
                        <Text fontSize="12px" mb={2}>
                          この項目はフォーム作成時の回答設定（必須・任意）に限らず、必ず任意回答になります。
                        </Text>
                        <Box w="50%">
                          <FormControl>
                            <RhfSelect<CreateAttributeGroupFormType>
                              name={`attributeGroupBodyList.${3}.userAttributeSetting`}
                              placeholder="選択してください。"
                              bgColor={Colors.WHITE}
                              isDisabled={status}
                              onBlur={handleSelectOnBlur}
                            >
                              {attributes
                                .filter(
                                  (item) =>
                                    item.uiType === 'text' && !!item.status,
                                )
                                .map((attribute, idx) => (
                                  <option
                                    value={attribute.id}
                                    key={`attributeGroupBodyList-address2${
                                      attribute.id
                                    }${String(idx)}`}
                                  >
                                    {attribute.displayNameJa}
                                  </option>
                                ))}
                            </RhfSelect>
                          </FormControl>
                        </Box>
                      </Box>
                      <Box>
                        <Heading
                          as="h3"
                          display="flex"
                          alignItems="center"
                          size="sm"
                          mb={1}
                        >
                          住所3（建物名・部屋番号）
                        </Heading>
                        <Text fontSize="12px" mb={2}>
                          この項目はフォーム作成時の回答設定（必須・任意）に限らず、必ず任意回答になります。
                        </Text>
                        <Box w="50%">
                          <FormControl>
                            <RhfSelect<CreateAttributeGroupFormType>
                              name={`attributeGroupBodyList.${4}.userAttributeSetting`}
                              placeholder="選択してください。"
                              bgColor={Colors.WHITE}
                              isDisabled={status}
                              onBlur={handleSelectOnBlur}
                            >
                              {attributes
                                .filter(
                                  (item) =>
                                    item.uiType === 'text' && !!item.status,
                                )
                                .map((attribute, idx) => (
                                  <option
                                    value={attribute.id}
                                    key={`attributeGroupBodyList-address3${
                                      attribute.id
                                    }${String(idx)}`}
                                  >
                                    {attribute.displayNameJa}
                                  </option>
                                ))}
                            </RhfSelect>
                          </FormControl>
                        </Box>
                      </Box>
                      {toErrMsgList(errors, 'attributeGroupBodyList').map(
                        (err) => (
                          <FormErrorMessage
                            key={`attributeGroupBodyList${err}`}
                          >
                            {err}
                          </FormErrorMessage>
                        ),
                      )}
                    </FormControl>
                  </Box>
                </>
              )}
              <Box w="100%">
                <Heading
                  as="h3"
                  display="flex"
                  alignItems="center"
                  size="sm"
                  mb={2}
                >
                  並び順
                </Heading>
                <Box>
                  <FormControl
                    isInvalid={
                      Boolean(errors.displayOrder?.message) ||
                      toErrMsgList(errors, 'displayOrder').length > 0
                    }
                  >
                    <RhfInput<CreateAttributeGroupFormType>
                      name="displayOrder"
                      bgColor={Colors.WHITE}
                    />
                    {toErrMsgList(errors, 'displayOrder').map((err) => (
                      <FormErrorMessage key={`displayOrder${err}`}>
                        {err}
                      </FormErrorMessage>
                    ))}
                  </FormControl>
                </Box>
              </Box>
            </VStack>
          </form>
        </DrawerBody>

        <DrawerFooter borderWidth="1px">
          <Button
            variant="outline"
            mr={3}
            p="16px 24px"
            w="120px"
            onClick={onClose}
            {...ColorSet.Default}
          >
            キャンセル
          </Button>
          <Button
            type="submit"
            form={formId}
            p="16px 24px"
            w="120px"
            colorScheme="primary"
            isLoading={isLoading}
          >
            {isEdit ? '保存' : '作成'}
          </Button>
        </DrawerFooter>
      </>
    );
  },
);

FormComponent.defaultProps = {
  isEdit: false,
  status: false,
};
