import { zodResolver } from '@hookform/resolvers/zod';
import { withSuspenseAndErrorBoundary } from 'admin/components/Error/WithErrorBoundary';
import { UserInfoFormComponent } from 'admin/components/idPoolConsumer/userInfo/userInfoForm/UserInfoFormComponent';
import { useUpdateIdPoolConsumerUserInfo } from 'admin/hooks/userPool/userInfo/useUpdateIdPoolConsumerUserInfo';
import { idPoolConsumerUserInfoSchema } from 'admin/schema/idPoolConsumer/userInfo';
import { UserPool } from 'admin/types/userPool';
import { IdPoolConsumerUserInfoFormType } from 'admin/types/userPool/userInfo/form';
import {
  AlertBar,
  ErrorContents,
  LoadingSpinner,
} from 'components/common/atoms';
import { FC, memo, useEffect, useState } from 'react';
import { FormProvider, SubmitHandler, useForm } from 'react-hook-form';

const JSON_FORMATTER_INDENT = 4;

type Props = {
  userInfoDetail: UserPool | undefined;
  onClose: () => void;
};

export const userInfoForm: FC<Props> = memo(
  ({ userInfoDetail, onClose }: Props) => {
    const formId = `userInfoForm`;
    const [globalErrors, setGlobalErrors] = useState<string[]>([]);
    const methods = useForm<IdPoolConsumerUserInfoFormType>({
      defaultValues: {
        id: '',
        userInfoResponse: '',
      },
      resolver: zodResolver(idPoolConsumerUserInfoSchema),
    });

    const { setValue, setError } = methods;

    const { mutate, isLoading } = useUpdateIdPoolConsumerUserInfo(
      setError,
      setGlobalErrors,
    );

    // JSON形式のチェック
    const isJsonFormat = (data: string): boolean => {
      try {
        JSON.parse(data);
      } catch (e) {
        return false;
      }

      if (typeof JSON.parse(data) !== 'object') return false;

      return true;
    };

    useEffect(() => {
      if (userInfoDetail) {
        setValue('id', userInfoDetail.id);
        if (isJsonFormat(userInfoDetail.userInfoResponse || '')) {
          setValue(
            'userInfoResponse',
            JSON.stringify(
              JSON.parse(userInfoDetail.userInfoResponse || ''),
              null,
              JSON_FORMATTER_INDENT,
            ),
          );
        } else {
          setValue('userInfoResponse', userInfoDetail.userInfoResponse);
        }
      }
    }, [setValue, userInfoDetail]);

    const onSubmit: SubmitHandler<IdPoolConsumerUserInfoFormType> = async (
      data: IdPoolConsumerUserInfoFormType,
    ) => {
      const submitData: IdPoolConsumerUserInfoFormType = {
        ...data,
        userInfoResponse:
          data.userInfoResponse === '' ? null : data.userInfoResponse,
      };
      await mutate(submitData);
      onClose();
    };

    return (
      <FormProvider {...methods}>
        {globalErrors.map((err, idx) => (
          <AlertBar
            key={`global-err-idx${String(idx)}`}
            message={err}
            status="error"
            mb={4}
          />
        ))}
        <UserInfoFormComponent
          formId={formId}
          isLoading={isLoading}
          userInfoDetail={userInfoDetail}
          onClose={onClose}
          onSubmit={onSubmit}
        />
      </FormProvider>
    );
  },
);

export const UserInfoForm = withSuspenseAndErrorBoundary(userInfoForm, {
  ErrorComponent: <ErrorContents name="外部IDシステムJSON設定" />,
  LoadingComponent: <LoadingSpinner />,
});
