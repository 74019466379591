import { Box, Flex, Text } from '@chakra-ui/react';
import { DropDownMenu } from 'components/common/atoms';
import { useCopy } from 'hooks/useCopy';
import { FC, memo } from 'react';
import { BsChevronDown } from 'react-icons/bs';
import { MdContentCopy } from 'react-icons/md';

type Props = {
  type?: "wall" | "item";
  id: string;
}

export const CopyIdWithMenu: FC<Props> = memo(({type, id}: Props) => {
  const { copy } = useCopy();

  const menuItems = () => [
    {
      menuItemType: 'text',
      label: (
        <Flex alignItems="flex-start">
          <Box mt="3px">
            <MdContentCopy />
          </Box>
          <Text pl="14px">{type === 'wall' ? 'サブスクリプションウォール' : '単品商品'}IDをコピー</Text>
        </Flex>
      ),
      onClick: () => {
        copy(id);
      },
    },
    {
      menuItemType: 'text',
      label: (
        <Flex alignItems="flex-start">
          <Box mt="3px">
            <MdContentCopy />
          </Box>
          <Text pl="14px">記事埋め込み用タグ（{type === 'wall' ? 'サブスクリプションウォール' : '単品商品'}IDを含む）をコピー</Text>
        </Flex>
      ),
      onClick: () => {
        copy(`<input type="hidden" name="uec-paywall-${type||''}-id" value="${id}">`);
      },
    }
  ];

  return(
    <Flex style={{ fontSize: 14 }} alignItems="center">
      <Text>{id}</Text>
      {type && (
        <DropDownMenu
          menuType="icon"
          items={menuItems()}
          icon={<BsChevronDown />}
          variant="none"
          width={type==='wall' ? "320px" : "290px"}
          zIndex="1500"
        />
      )}
    </Flex>
  );
});
