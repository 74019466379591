import { Box } from '@chakra-ui/react';
import { withSuspenseAndErrorBoundary } from 'admin/components/Error/WithErrorBoundary';
import { UsersBodyComponent } from 'admin/components/users/usersBody/UsersBodyComponent';
import { ErrorContents } from 'components/common/atoms';
import { FC, memo, useState } from 'react';

const usersBody: FC = memo(() => {
  const [searchKeyword, setSearchKeyword] = useState('');

  const onSearch = (keyword: string) => {
    setSearchKeyword(keyword);
  };

  return (
    <Box mt="16px">
      <UsersBodyComponent searchKeyword={searchKeyword} onSearch={onSearch} />
    </Box>
  );
});

export const UsersBody = withSuspenseAndErrorBoundary(usersBody, {
  ErrorComponent: <ErrorContents name="組織アカウント管理一覧" />,
});
