export const querySegmentKey = {
  getSegmentList: ({ tenantId }: { tenantId: string }) => [
    'mail/segmentList',
    tenantId,
  ],
  getSegmentField: ['mail/getSegmentField'],
  getSegmentId: ({
    tenantId,
    segmentId,
  }: {
    tenantId: string;
    segmentId: string;
  }) => ['mail/getSegmentId', tenantId, segmentId],
} as const;
