import { useCustomToast } from 'hooks/useCustomToast';
import { successToast, errorToast } from 'utils/toast';
import { useUserTenantId } from 'hooks/user/useUserTenantId';
import { gcsFileUpload } from 'api/storage/gcsFileUpload';
import {
  isGcsFileUploadResponse,
  isGcsFileUploadErrorResponse,
  GcsFileUploadResponse,
} from 'api/storage/types';

export const useGcsUpload = (): ((
  file: File,
  filePath: string,
) => Promise<GcsFileUploadResponse | null>) => {
  const toast = useCustomToast();
  const tenantId = useUserTenantId();

  const upload = async (
    file: File,
    filePath: string,
  ): Promise<GcsFileUploadResponse | null> => {
    if (!tenantId) {
      toast({
        ...errorToast,
        title: 'アップロードエラー',
        description:
          '認証エラーが発生しました。一度ログアウト頂き再度ログインし操作ください',
      });

      return null;
    }

    if (!file.size) {
      toast({
        ...errorToast,
        title: 'アップロードエラー',
        description:
          'ファイルサイズが 0 です。内容記載の上アップロードしてください',
      });

      return null;
    }

    // const ext = file.name.split('.').pop();
    // if (ext !== 'jpg' && ext !== 'png') {
    //   toast({
    //     ...errorToast,
    //     title: 'アップロードエラー',
    //     description: 'jpg 又は pngファイルのみアップロード可能です',
    //   });

    //   return;
    // }

    try {
      // ファイルサイズが大きいと1分以上待つので
      // バックグランドで処理
      const uploadResult = await gcsFileUpload({ file, filePath, tenantId });
      if (isGcsFileUploadResponse(uploadResult)) {
        toast({
          ...successToast,
          duration: 5000,
          title: 'アップロードしました',
        });

        return uploadResult;
      }

      if (isGcsFileUploadErrorResponse(uploadResult)) {
        toast({
          ...errorToast,
          title: uploadResult.file,
        });
      } else {
        toast({
          ...errorToast,
          title: 'アップロードエラー',
        });
      }
    } catch (e) {
      const err = e as Error;
      toast({
        ...errorToast,
        title: 'アップロードエラー',
        description: err.message,
      });
    }

    return null;
  };

  return upload;
};
