import { Input, Table, Tbody, Td, Tr, VStack } from '@chakra-ui/react';
import { CondElement } from 'components/enquete/atoms/CondElement';
import { QuestionTitleElement } from 'components/enquete/atoms/QuestionTitleElement';
import { RichTextElement } from 'components/enquete/atoms/RichTextElement';
import { Toggle } from 'components/enquete/atoms/Toggle';
import { Dispatch, FC, SetStateAction, useState } from 'react';
import { FieldValues, Path, useFormContext } from 'react-hook-form';

type EnqueteFormType<T> = {
  baseName: Path<T>;
  index: number;
  setIsEdited: Dispatch<SetStateAction<boolean>>;
};

export const ClipStudioShareElement = <T extends FieldValues>({ baseName, index, setIsEdited }: EnqueteFormType<T>): ReturnType<FC> => {
  const { register, getValues } = useFormContext<T>();
  const [toggle, setToggle] = useState(false);

  return (
    <>
      <VStack w="100%" display={toggle ? 'none' : 'block'}>
        <Table>
          <Tbody>
            <Tr>
              <QuestionTitleElement<T> baseName={baseName} width={140} colspan={2} index={index} />
            </Tr>
            <Tr>
              <Td w={140}>補足説明など</Td>
              <Td colSpan={2}>
                <RichTextElement<T>
                  baseName={baseName}
                  placeholder=""
                  index={index}
                  position="descriptionTop"
                  value={
                    getValues(`${baseName}.${index}.descriptionTop` as Path<T>) || ''
                  }
                  setIsEdited={setIsEdited}
                />
              </Td>
            </Tr>
            <Tr>
              <Td w={140}>
                URL入力欄
                <br />
                表示文言
              </Td>
              <Td>
                <Input
                  {...register(`${baseName}.${index}.clipstudioTextUrl` as Path<T>)}
                  placeholder="URL入力欄の上に表示されるラベルの文言を設定してください"
                  backgroundColor="#f9f9f9"
                  borderColor="#ccc"
                  isDisabled={getValues('uneditableState' as Path<T>)}
                />
              </Td>
            </Tr>
            <Tr>
              <Td w={140} pr={2}>
                パスワード
                <br />
                入力欄表示文言
              </Td>
              <Td>
                <Input
                  {...register(
                    `${baseName}.${index}.clipstudioTextPassword` as Path<T>,
                  )}
                  placeholder="パスワード入力欄の上に表示されるラベルの文言を設定してください"
                  backgroundColor="#f9f9f9"
                  borderColor="#ccc"
                  isDisabled={getValues('uneditableState' as Path<T>)}
                />
              </Td>
            </Tr>
            <Tr>
              <Td w={140}>補足説明など</Td>
              <Td>
                <RichTextElement<T>
                  baseName={baseName}
                  placeholder=""
                  index={index}
                  position="descriptionBottom"
                  value={
                    getValues(`${baseName}.${index}.descriptionBottom` as Path<T>) ||
                    ''
                  }
                  setIsEdited={setIsEdited}
                />
              </Td>
            </Tr>
          </Tbody>
        </Table>
        <CondElement<T> baseName={baseName} index={index} />
      </VStack>
      <Toggle toggle={toggle} setToggle={setToggle} />
    </>
  );
};
