import { ResponseError } from 'api/types';
import { loginWithSaml } from 'api/user/loginWithSaml';
import { isSamlLoginResponseType, SamlLoginAuthType, SamlLoginResponseType } from 'api/user/types';
import { UseMutateFunction, useMutation } from 'react-query';
import { isGetValidationError, toMultiError } from 'utils/form';

type UnPromisify<T> = T extends Promise<infer U> ? U : T;

const LoginFormData: Omit<SamlLoginAuthType, 'resolve'> = {
  email: '',
  userPoolId: '',
};

/**
 * 認証タイプ判定hooks
 * @param {Object} UseFormSetError setError
 * @returns {Object} { onSubmit, isLoading }
 */
export const useSamlAuthentication = (): {
  onSamlLogin: UseMutateFunction<
    UnPromisify<ReturnType<typeof loginWithSaml>>,
    unknown,
    SamlLoginAuthType,
    unknown
  >;
  isLoading: boolean;
} => {
  const { mutate: onSamlLogin, isLoading } = useMutation(
    ({ email, userPoolId, resolve }: SamlLoginAuthType) =>
      loginWithSaml({ email, userPoolId, resolve }),
    {
      onSuccess: (result: SamlLoginResponseType | boolean | ResponseError, variable) => {
        if (isSamlLoginResponseType(result)) {
          variable.resolve(result);

          return;
        }

        if (
          isGetValidationError<SamlLoginAuthType>(
            result,
            Object.keys(LoginFormData),
          )
        ) {
          if(!result.others) {
            Object.keys(result).forEach((k) => {
              const key = k as keyof SamlLoginAuthType;
              const errMsgs = result?.[key];
              if (errMsgs) {
                console.log(key, { types: toMultiError(errMsgs) });
              }
            });
          }
        }
      },
    },
  );

  return { onSamlLogin, isLoading };
};
