import { patchMailSettingPersonal } from 'api/paywall/patchMailSettingPersonal';
import { ResponseError } from 'api/types';
import { MailSettingPersonalFormDefaultValue } from 'components/paywall/pages/MailSetting/initial';
import { isMailSettingPersonal, MailSettingPersonal, MailSettingPersonalForm, MailSettingStatuses } from 'components/paywall/pages/MailSetting/typed';
import { usePaywallUtil } from 'hooks/paywall/usePaywallUtil';
import { useCustomToast } from 'hooks/useCustomToast';
import { useUserTenantId } from 'hooks/user/useUserTenantId';
import { UseFormSetError } from 'react-hook-form';
import { UseMutateFunction, useMutation } from 'react-query';
import { useNavigate } from 'react-router-dom';
import { isGetValidationError, toMultiError } from 'utils/form';

type UnPromisify<T> = T extends Promise<infer U> ? U : T;

/**
 * ペイウォールメール設定更新 hooks
 * @param {UseFormSetError<MailSettingPersonalForm>} setError
 * @param {Function} reset
 * @returns {Object} { onSubmit, isLoading }
 */
export const useUpdateMailSettingPersonal = ({
  type,
  setError,
  reset,
}: {
  type: keyof MailSettingStatuses;
  setError?: UseFormSetError<MailSettingPersonalForm>;
  reset?: () => void;
}): {
  onSubmit: UseMutateFunction<
    UnPromisify<ReturnType<typeof patchMailSettingPersonal>>,
    unknown,
    MailSettingPersonalForm,
    unknown
  >;
  isLoading: boolean;
  isSuccess: boolean;
} => {
  const tenantId = useUserTenantId();
  const toast = useCustomToast();
  const navigate = useNavigate();
  const { paywallMailSettingCommonRefetchQueries, paywallMailSettingPersonalRefetchQueries } = usePaywallUtil();

  const {
    mutate: onSubmit,
    isLoading,
    isSuccess,
  } = useMutation(
    (formParam: MailSettingPersonalForm) =>
      patchMailSettingPersonal(tenantId, formParam, type),
    {
      onSuccess: (result: MailSettingPersonal | boolean | ResponseError) => {
        paywallMailSettingCommonRefetchQueries();
        paywallMailSettingPersonalRefetchQueries(type);
        if (isMailSettingPersonal(result)) {
          reset?.();
          toast({
            status: 'success',
            position: 'bottom',
            duration: 4000,
            isClosable: true,
            title: 'ペイウォールメール設定を更新しました',
          });
          navigate('/paywall/mail');
        }

        if (
          isGetValidationError<MailSettingPersonalForm>(
            result,
            Object.keys(MailSettingPersonalFormDefaultValue),
          )
        ) {
          if (result?.others) {
            const errorMsg = Object.entries(result).map(([_, value]) => value);
            toast({
              status: 'error',
              position: 'bottom',
              duration: 4000,
              isClosable: true,
              description: errorMsg[0],
            });
          } else {
            Object.keys(result).forEach((k) => {
              const key = k as keyof MailSettingPersonalForm;
              const errMsgs = result?.[key];
              if (errMsgs) {
                setError?.(key, { types: toMultiError(errMsgs) });
              }
            });
          }
        }
      },
    },
  );

  return { onSubmit, isLoading, isSuccess };
};
