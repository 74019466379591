import {
  AlertDialogBody,
  AlertDialogFooter,
  Box,
  Button,
  FormControl,
  FormErrorMessage,
  Heading,
  Text,
} from '@chakra-ui/react';
import { RhfInput } from 'admin/components/form/RhfInput';
import { LoadingLayer } from 'admin/components/ui/loadingLayer';
import { Colors, ColorSet } from 'admin/define/colors';
import { UserPool } from 'admin/types/userPool';
import { IdPoolConsumerFormType } from 'admin/types/userPool/form';
import { AUTH_CODE_TYPE_LIST, AUTH_CODE_TYPE_UNIIKEY } from 'define';
import { FC, memo } from 'react';
import { useFormContext, useFormState } from 'react-hook-form';
import { toErrMsgList } from 'utils/form';

type Props = {
  userPool: UserPool | undefined;
  onSubmit: (value: IdPoolConsumerFormType) => void;
  onClose: () => void;
  isLoading: boolean;
};

export const EditFormComponent: FC<Props> = memo(
  ({ userPool, onClose, onSubmit, isLoading }: Props) => {
    const { errors } = useFormState<IdPoolConsumerFormType>();
    const { handleSubmit } = useFormContext<IdPoolConsumerFormType>();

    return (
      <>
        {isLoading && <LoadingLayer />}
        <form onSubmit={handleSubmit(onSubmit)}>
          <AlertDialogBody maxH="400px" overflowY="auto">
            <Box>
              <Box>
                <Box mb="16px">
                  <Heading as="h3" fontSize="14px" mb={2}>
                    <Box display="flex" alignItems="center">
                      名前
                    </Box>
                  </Heading>
                  <FormControl isInvalid={Boolean(errors.name)}>
                    <Box>
                      <RhfInput<IdPoolConsumerFormType>
                        name="name"
                        bgColor={Colors.WHITE}
                      />
                    </Box>
                    {toErrMsgList(errors, 'name').map((err) => (
                      <FormErrorMessage key={`name_${err}`}>
                        {err}
                      </FormErrorMessage>
                    ))}
                  </FormControl>
                </Box>
                {Number(userPool?.authenticationType) ===
                  AUTH_CODE_TYPE_UNIIKEY && (
                  <Box mb="16px">
                    <Heading as="h3" fontSize="14px" mb={2}>
                      <Box display="flex" alignItems="center">
                        フィールド名
                      </Box>
                    </Heading>
                    <Box
                      borderWidth="1px"
                      borderStyle="solid"
                      borderColor={Colors.GRAY_30}
                      borderRadius="6px"
                      backgroundColor={Colors.GRAY_75}
                      p="8px 16px"
                    >
                      <Text fontSize="16px" color={Colors.GRAY_15} minH="24px">
                        {userPool?.fieldName}
                      </Text>
                    </Box>
                  </Box>
                )}
                <Box>
                  <Heading as="h3" fontSize="14px" mb={2}>
                    <Box display="flex" alignItems="center">
                      利用ID種別
                    </Box>
                  </Heading>
                  <Box
                    borderWidth="1px"
                    borderStyle="solid"
                    borderColor={Colors.GRAY_30}
                    borderRadius="6px"
                    backgroundColor={Colors.GRAY_75}
                    p="8px 16px"
                  >
                    <Text fontSize="16px" color={Colors.GRAY_15} minH="24px">
                      {AUTH_CODE_TYPE_LIST.get(
                        Number(userPool?.authenticationType),
                      )}
                    </Text>
                  </Box>
                </Box>
              </Box>
            </Box>
          </AlertDialogBody>
          <AlertDialogFooter>
            <Button
              variant="outline"
              mr={3}
              onClick={onClose}
              p="16px 24px"
              w="120px"
              {...ColorSet.Default}
            >
              キャンセル
            </Button>
            <Button type="submit" p="16px 24px" w="120px" colorScheme="primary">
              保存
            </Button>
          </AlertDialogFooter>
        </form>
      </>
    );
  },
);
