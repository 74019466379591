import { request } from 'admin/api/Request';
import { API_MESSAGES } from 'admin/define/apiMessages';
import { CustomError } from 'admin/error/CustomError';
import { authHubResponseSchema } from 'admin/schema/authHub';
import { AuthHubType, AuthHubUpdateFormType } from 'admin/types/authHub';
import { isResponseError } from 'api/types';
import { Options } from 'ky';
// import { getValidationError } from 'utils/form';
import { ZodError } from 'zod';

export const updateAuthHub = async (
  data: AuthHubUpdateFormType,
  options?: Options,
): Promise<AuthHubType> => {
  const path = data.userPoolId
    ? `uniikey/${data.userPoolId}/auth_hub/${data.id}/`
    : (data.tenantId && `auth_hub/${data.tenantId}/auth_hub/${data.id}/`) || '';
  const params = data;
  const response = await request<AuthHubType>({
    path,
    method: 'patch',
    body: params,
    options,
  });

  if (response.hasError) {
    if (response.status === 404) {
      throw new CustomError(API_MESSAGES.AUTH_HUB.SAVE_AUTH_HUB.FAILED, {
        path,
        status: response.status,
      });
    } else if (isResponseError(response.error)) {
      // const customError = response.error;

      // const formError = getValidationError<AuthHubsFormType>({
      //   formData: data,
      //   response: customError,
      // });

      throw new CustomError(API_MESSAGES.AUTH_HUB.SAVE_AUTH_HUB.ERROR, {
        path,
        options,
        params,
        // errors: formError,
        status: response.status,
      });
    } else {
      throw new CustomError(API_MESSAGES.AUTH_HUB.SAVE_AUTH_HUB.ERROR, {
        path,
        status: response.status,
      });
    }
  }

  try {
    authHubResponseSchema.parse(response.data);
  } catch (error) {
    if (error instanceof ZodError) {
      throw new CustomError(error.message, {
        path,
        status: response.status,
        logLevel: 'error',
      });
    }
  }

  return response.data;
};
