export const messages = {
  //  ダッシュボード情報取得処理
  getDashboard: {
    customer: {
      notFound: '登録会員数などの推移データが見つかりませんでした。',
      error:
        '申し訳ございません。データを取得できません。時間を置いて再表示をお願いします。',
      typeError: '申し訳ございません。データ表示できません',
      failed:
        '申し訳ありません。正しいダッシュボード情報を得ることができません。',
    },
    system: {
      typeError:
        '[api.dashboard.getDashboard] ダッシュボード情報取得APIのレスポンス型が予期せぬデータ型です。フロントの修正をしてください',
    },
  },
};
