import { withSuspenseAndErrorBoundary } from 'admin/components/Error/WithErrorBoundary';
import { ServiceMemberListComponent } from 'admin/components/member/service/serviceMemberList/ServiceMemberListComponent';
import { usePaginationQuery } from 'admin/hooks/pagination/usePaginationQuery';
import { Page } from 'api/common/types';
import { ErrorContents } from 'components/common/atoms';
import { FC, memo, useMemo, useState } from 'react';

export const FetchServiceMemberLists: FC = memo(() => {
  // TODO: 9月末のリリース時点では必要では無い為、コメントアウト
  // const [refetchFunc, setRefetchFunc] = useState<() => void>(() => ({}));
  const [searchKeyword, _setSearchKeyword] = useState<string>('');
  const [page, setPage] = useState<Page>({
    count: 0,
    currentPage: 0,
    countFrom: 0,
    countTo: 0,
    perPage: 50,
    pageCount: 0,
    next: '',
    previous: '',
  });
  const { query } = usePaginationQuery({ page });
  const currentPage = useMemo(() => query.currentPage, [query.currentPage]);
  const perPage = useMemo(() => query.currentPerPage, [query.currentPerPage]);

  // =============================================
  // TODO: 9月末のリリース時点では必要では無い為、コメントアウト
  // const onChange = useCallback((keyword: string) => {
  //   setSearchKeyword(keyword);
  // }, []);

  // const onSearch = useCallback(() => {
  //   refetchFunc();
  // }, [refetchFunc]);
  // =============================================

  return (
    <ServiceMemberListComponent
      currentPage={currentPage}
      perPage={perPage}
      keyword={searchKeyword}
      page={page}
      setPage={setPage}
      // TODO: 9月末のリリース時点では必要では無い為、コメントアウト
      // onChange={onChange}
      // onSearch={onSearch}
      // setRefetchFunc={setRefetchFunc}
    />
  );
});

const serviceMemberLists: FC = memo(() => <FetchServiceMemberLists />);

export const ServiceMemberLists = memo(
  withSuspenseAndErrorBoundary(serviceMemberLists, {
    ErrorComponent: <ErrorContents name="会員一覧情報" />,
  }),
);
