import { request } from 'admin/api/Request';
import { API_MESSAGES } from 'admin/define/apiMessages';
import { CustomError } from 'admin/error/CustomError';
import { attributeGroupSchema } from 'admin/schema/idPoolConsumer/attributeGroup';
import { UserPool } from 'admin/types/userPool';
import { EditAttributeGroupFormType } from 'admin/types/userPool/attributeGroup/form';
import { AttributeGroupType } from 'admin/types/userPool/attributeGroup/index';
import { isResponseError } from 'api/types';
import { getValidationError } from 'utils/form';
import { ZodError } from 'zod';

export const updateAttributeGroup = async ({
  data,
  id,
  attributeGroupId,
}: {
  data: EditAttributeGroupFormType;
  id: UserPool['id'];
  attributeGroupId: AttributeGroupType['id'];
}): Promise<EditAttributeGroupFormType> => {
  const params = {
    ...data,
    displayOrder: Number(data?.displayOrder)
      ? Number(data?.displayOrder)
      : 9999,
    attributeGroupBodyList: data.attributeGroupBodyList.filter(
      (item) => item.userAttributeSetting,
    ),
  };

  const path = `uniikey/${id}/attribute_group/${attributeGroupId}/`;
  const response = await request<EditAttributeGroupFormType>({
    path,
    body: params,
    method: 'patch',
  });
  if (response.hasError) {
    if (response.status === 404) {
      throw new CustomError(
        API_MESSAGES.USER_POOL.EDIT_ATTRIBUTE_GROUP.FAILED,
        {
          path,
          status: response.status,
        },
      );
    } else if (isResponseError(response.error)) {
      const customError = response.error;

      const formError = getValidationError<EditAttributeGroupFormType>({
        formData: data,
        response: customError,
      });

      throw new CustomError(API_MESSAGES.USER_POOL.EDIT_ATTRIBUTE_GROUP.ERROR, {
        path,
        params: data,
        errors: formError,
        status: response.status,
        logLevel: response.status === 400 ? 'warning' : 'error',
      });
    } else {
      throw new CustomError(API_MESSAGES.USER_POOL.EDIT_ATTRIBUTE_GROUP.ERROR, {
        path,
        status: response.status,
      });
    }
  }
  try {
    attributeGroupSchema.parse(response.data);
  } catch (error) {
    if (error instanceof ZodError) {
      throw new CustomError(error.message, {
        path,
        status: response.status,
        logLevel: 'error',
      });
    }
  }

  return response.data;
};
