import { zodResolver } from '@hookform/resolvers/zod';
import { TenantUsersDeleteModalComponent } from 'admin/components/tenantUsers/tenantUsersDeleteModal/TenantUsersDeleteModalComponent';
import { LoadingLayer } from 'admin/components/ui/loadingLayer';
import { useDeleteTenantUsers } from 'admin/hooks/tenantUsers/useDeleteTenantUsers';
import { deleteTenantUsersSchema } from 'admin/schema/tenantUsers';
import { TenantUserDeleteStateType } from 'admin/types/tenantUsers';
import { DeleteTenantUsersFormType } from 'admin/types/tenantUsers/form';
import { FC, memo, useCallback } from 'react';
import { FormProvider, useForm } from 'react-hook-form';

type Props = {
  deleteList: TenantUserDeleteStateType[];
  onClose: () => void;
};

export const TenantUsersDeleteModal: FC<Props> = memo(
  ({ deleteList, onClose }: Props) => {
    const formId = 'tenantUserDeleteForm';

    const methods = useForm<DeleteTenantUsersFormType>({
      defaultValues: {
        users: [],
        word: '',
      },
      mode: 'onBlur',
      resolver: zodResolver(deleteTenantUsersSchema),
    });
    const { mutate, isLoading } = useDeleteTenantUsers();

    const onDelete = useCallback(async () => {
      // 削除処理
      const sendData: Omit<DeleteTenantUsersFormType, 'word'> = {
        users: [],
      };
      sendData.users = deleteList.map((val) => ({
        userId: val.id,
        tenantId: val.tenant.id,
      }));
      await mutate(sendData);
      onClose();
    }, [deleteList, mutate, onClose]);

    return (
      <FormProvider {...methods}>
        {isLoading && <LoadingLayer />}
        <TenantUsersDeleteModalComponent
          formId={formId}
          deleteList={deleteList}
          onClose={onClose}
          onDelete={onDelete}
          isLoading={isLoading}
        />
      </FormProvider>
    );
  },
);
