import { VFC, Suspense } from 'react';
import { VStack } from '@chakra-ui/react';
import { useSetPageTitle } from 'hooks/useSetPageTitle';
import ErrorBoundary from 'api/ErrorBoundary';
import { useErrorBoundaryReset } from 'hooks/useErrorBoundaryReset';
import { FEATURE_DASHBOARD } from 'define';
import { useUserInfo } from 'hooks/useUserInfo';
import { LoadingSkeleton } from 'components/common/atoms';
import { ListError } from 'components/common/molecules';
import { HomeInner } from 'components/home/template/HomeInner';

export const Home: VFC = () => {
  const { ebKey, onError } = useErrorBoundaryReset();
  const { hasFeature } = useUserInfo();
  const title = hasFeature(FEATURE_DASHBOARD)
    ? 'ダッシュボード'
    : 'Uniikey Experience Cloud';
  useSetPageTitle(title);

  return (
    <VStack spacing={0} h="full">
      <ErrorBoundary
        errorComponent={<ListError message="ダッシュボードを表示できません" />}
        onError={onError}
        key={ebKey.current}
      >
        <Suspense fallback={<LoadingSkeleton width="100%" />}>
          {hasFeature(FEATURE_DASHBOARD) && <HomeInner />}
        </Suspense>
      </ErrorBoundary>
    </VStack>
  );
};
