import { CheckIcon } from '@chakra-ui/icons';
import { isFalse } from 'utils/bool';
import { CheckBoxViewereProps, DataType } from './types';

export const CheckBoxViewer = <T extends DataType>(
  props: CheckBoxViewereProps<T>,
): JSX.Element => {
  const { value } = props;

  return isFalse(value) ? <></> : <CheckIcon />;
};
