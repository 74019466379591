import { postWallDesign } from 'api/paywall/postWallDesign';
import { ResponseError } from 'api/types';
import { WallDesignFormDefaultValues } from 'components/paywall/pages/WallDesignList/initial';
import {
  isWallDesign, WallDesign,
  WallDesignFormType
} from 'components/paywall/pages/WallDesignList/typed';
import { usePaywallUtil } from 'hooks/paywall/usePaywallUtil';
import { useCustomToast } from 'hooks/useCustomToast';
import { UseFormSetError } from 'react-hook-form';
import { UseMutateFunction, useMutation } from 'react-query';
import { useNavigate } from 'react-router-dom';
import { isGetValidationError, toMultiError } from 'utils/form';

type UnPromisify<T> = T extends Promise<infer U> ? U : T;

/**
 * ウォールデザイン作成 hooks
 * @param {UseFormSetError<WallDesign>} setError
 * @returns {Object} { onSubmit, isLoading }
 */
export const useCreateWallDesign = ({
  setError,
}: {
  setError: UseFormSetError<WallDesign>;
}): {
  onSubmit: UseMutateFunction<
    UnPromisify<ReturnType<typeof postWallDesign>>,
    unknown,
    WallDesignFormType,
    unknown
  >;
  isLoading: boolean;
} => {
  const { paywallDesignListRefetchQueries, paywallListRefetchQueries } = usePaywallUtil();
  const toast = useCustomToast();
  const navigate = useNavigate();

  const { mutate: onSubmit, isLoading } = useMutation(
    (WallDesignData: WallDesignFormType) => postWallDesign(WallDesignData),
    {
      onSuccess: (result: WallDesign | boolean | ResponseError) => {
        paywallDesignListRefetchQueries();
        // 画面作成した際、サブスクリプション一覧or単品商品一覧のボタンのエラーを更新するため、リストをrefetchする
        paywallListRefetchQueries();

        if (isWallDesign(result)) {
          toast({
            status: 'success',
            position: 'bottom',
            duration: 4000,
            isClosable: true,
            title: 'ウォールデザインを作成しました',
          });
          const { wall } = result;
          const url = `/paywall/${wall}/wallDesignList`;
          navigate(url);
        }

        if (
          isGetValidationError<WallDesign>(
            result,
            Object.keys(WallDesignFormDefaultValues),
          )
        ) {
          if (result?.others) {
            const errorMsg = Object.entries(result).map(([_, value]) => value);
            toast({
              status: 'error',
              position: 'bottom',
              duration: 4000,
              isClosable: true,
              description: errorMsg[0],
            });
          } else {
            Object.keys(result).forEach((k) => {
              const key = k as keyof WallDesign;
              const errMsgs = result?.[key];
              if (errMsgs) {
                setError(key, { types: toMultiError(errMsgs) });
              }
            });
          }
        }
      },
    },
  );

  return { onSubmit, isLoading };
};
