import {
  AlertDialogCloseButton,
  AlertDialogContent,
  AlertDialogHeader,
  AlertDialogOverlay,
  Text
} from '@chakra-ui/react';
import { UsersDeleteForm } from 'admin/components/users/usersDeleteForm';
import { UserDeleteStateType } from 'admin/types/user';
import { FC, memo } from 'react';

type Props = {
  deleteList: UserDeleteStateType[]
  onClose: () => void;
};

export const UsersDeleteDialog: FC<Props> = memo(
  ({ deleteList, onClose }) => (
    <AlertDialogOverlay>
      <AlertDialogContent>
        <AlertDialogHeader>
          <Text>ユーザー削除の確認</Text>
        </AlertDialogHeader>
        <UsersDeleteForm deleteList={deleteList} onClose={onClose} />
        <AlertDialogCloseButton />
      </AlertDialogContent>
    </AlertDialogOverlay>
  ),
);
