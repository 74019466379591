import { withSuspenseAndErrorBoundary } from 'admin/components/Error/WithErrorBoundary';
import { useTransactionMailTypeStatuses } from 'admin/hooks/userPool/transactionMail/useTransactionMailTypeStatuses';
import { ErrorContents } from 'components/common/atoms';
import { FC, memo } from 'react';
import { TransactionMailPersonalComponent } from './TransactionMailPersonalComponent';

const fetchTransactionMailPersonal: FC = memo(() => {
  const { data: mailTypeStatuses } = useTransactionMailTypeStatuses();

  if (!mailTypeStatuses) {
    return null;
  }

  return (
    <TransactionMailPersonalComponent mailTypeStatuses={mailTypeStatuses} />
  );
});

export const TransactionMailPersonal: FC = withSuspenseAndErrorBoundary(
  fetchTransactionMailPersonal,
  {
    ErrorComponent: (
      <ErrorContents name="トランザクションメールの個別設定情報一覧" />
    ),
  },
);
