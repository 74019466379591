import { formGroupStatusChange } from 'admin/api/userPool/formGroup/formGroupStatusChange';
import { CustomError } from 'admin/error/CustomError';
import { useCurrentPerPage } from 'admin/hooks/pagination/useCurrentPerPage';
import { useIdPoolConsumerId } from 'admin/hooks/useIdPoolConsumerId';
import { FormGroupType } from 'admin/types/userPool/formGroup';
import { Page } from 'api/common/types';
import { logger } from 'api/logger';
import { useCustomToast } from 'hooks/useCustomToast';
import { useMutation, useQueryClient } from 'react-query';
import { errorToast, successToast } from 'utils/toast';
import { queryFormGroupKey } from './queryFormGroupKey';

export const useFormGroupStatusChange = ({
  page,
}: {
  page: Page['currentPage'];
}) => {
  const perPage = useCurrentPerPage();
  const toast = useCustomToast();
  const queryClient = useQueryClient();
  const userPoolId = useIdPoolConsumerId();

  const { mutateAsync, isLoading } = useMutation({
    mutationFn: (data: FormGroupType) => formGroupStatusChange({ data, userPoolId }),
    onSuccess: () => {
      void queryClient.invalidateQueries(
        queryFormGroupKey.getFormGroups(userPoolId, page, perPage),
      );
      toast({
        ...successToast,
        duration: 4000,
        title: 'ステータスを変更しました',
        position: 'bottom',
        variant: 'solid',
      });
    },
    onError: async (error) => {
      if (error instanceof CustomError) {
        if (error.cause.errors) {
          await logger({
            loglevel: error.logLevel,
            data: JSON.stringify(error.cause),
            message: error.message,
          });
        }
      }
      toast({
        ...errorToast,
        duration: 4000,
        title: 'ステータスの変更に失敗しました',
        position: 'bottom',
        variant: 'solid',
      });
    },
  });

  return { mutate: mutateAsync, isLoading };
};
