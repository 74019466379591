/* eslint-disable react/require-default-props */
import { Box } from '@chakra-ui/react';
import { Colors } from 'components/paywall/config';
import { FC, memo } from 'react';

type Props = {
  children: React.ReactNode
  isChild?: boolean;
}
export const Card: FC<Props> = memo(({children, isChild=false}: Props) => (
  <Box
    className="paywall-card"
    position="relative"
    borderWidth="1px"
    borderColor={Colors.GRAY_30}
    borderRadius="8px"
    mt={isChild ? '16px !important' : ''}
    w={isChild ? "calc(100% - 32px)" : "100%"}
    _before={
      isChild
        ? {
          content: '""',
          position: 'absolute',
          top: 0,
          left: '32px',
          transform: 'translateY(-75%)',
          width: '4px',
          height: '32px',
          backgroundColor: Colors.GRAY_30,
          zIndex: -1
        }
        : {}
    }
  >
    {children}
  </Box>
));
