import { VFC, memo, useCallback, useState, useEffect } from 'react';
import { NavLink, useSearchParams } from 'react-router-dom';
import {
  VStack,
  Text,
  Flex,
  Box,
  Spacer,
  Table,
  Thead,
  Tbody,
  Tr,
  Th,
  Td,
  Button,
  useDisclosure,
} from '@chakra-ui/react';
import { BsChevronDown } from 'react-icons/bs';
import { format } from 'date-fns';
import { successToast, errorToast } from 'utils/toast';
import { getEnqueteSkinCopy } from 'api/enquete/getEnqueteSkinCopy';
import { isSkin, Skin } from 'api/enquete/types';
import { useSetPageTitle } from 'hooks/useSetPageTitle';
import { useCustomToast } from 'hooks/useCustomToast';
import { useUserTenantId } from 'hooks/user/useUserTenantId';
import { useEnqueteUtil } from 'hooks/enquete/useEnqueteUtil';
import { useSkinList } from 'hooks/enquete/useSkinList';
import { DropDownMenu, Paginator } from 'components/common/atoms';
import { EnqueteSkinPreviewModal } from 'components/enquete/molecules/EnqueteSkinPreviewModal';
import { SkinDefaultValues } from 'components/enquete/EnqueteSkin';
import { isGetValidationError } from 'utils/form';

const DATETIME_FORMAT = 'yyyy-MM-dd HH:mm';
const defaultValues: Skin = SkinDefaultValues;

type designSkinColumns = {
  id: string;
  key: string;
  name: string;
  w: number;
};
const columns: designSkinColumns[] = [
  {
    id: '1',
    key: 'id',
    name: 'デザインスキンID',
    w: 100,
  },
  {
    id: '2',
    key: 'title',
    name: 'デザインスキン名',
    w: 200,
  },
  {
    id: '3',
    key: 'createDate',
    name: '作成日',
    w: 90,
  },
  {
    id: '4',
    key: 'updateDate',
    name: '最終更新日',
    w: 90,
  },
  {
    id: '5',
    key: 'preview',
    name: 'プレビュー',
    w: 90,
  },
];

export const EnqueteSkinList: VFC = memo(() => {
  useSetPageTitle('デザインスキン一覧');
  const { page, skinList } = useSkinList({});
  const { ClearEnqueteSkinListCache } = useEnqueteUtil();
  const toast = useCustomToast();
  const tenantId = useUserTenantId();
  const { isOpen, onOpen, onClose } = useDisclosure();
  const [previewValues, setPreviewValues] = useState<Skin>(defaultValues);
  const [searchParams, setSearchParams] = useSearchParams();

  /**
   * デザインスキンコピー
   */
  const onDuplicate = useCallback(
    async (id: string) => {
      const res = await getEnqueteSkinCopy(tenantId, id);
      if (isSkin(res)) {
        ClearEnqueteSkinListCache();
        toast({
          ...successToast,
          duration: 4000,
          title: 'フォームスキンをコピーしました',
          position: 'top-right',
          variant: 'copy',
        });
      }

      if (isGetValidationError<Skin>(res, Object.keys(defaultValues))) {
        if (res?.others) {
          const errorMsg = Object.entries(res).map(([_, value]) => value);
          toast({
            ...errorToast,
            position: 'top-right',
            description: errorMsg[0],
          });
        }
      }
    },
    [tenantId, toast, ClearEnqueteSkinListCache],
  );

  const menuItems = (id: string) => [
    {
      menuItemType: 'text',
      label: 'デザインスキンを複製',
      testId: 'enquete-copy',
      onClick: () => {
        void onDuplicate(id);
      },
    },
  ];

  /**
   * プレビューボタン押下時処理
   */
  const onPreviewOpen = useCallback(
    (id: string) => {
      const target = skinList.filter((x) => x.id === id);
      if (target.length > 0) {
        setPreviewValues(target[0]);
      }
      onOpen();
    },
    [onOpen, skinList, setPreviewValues],
  );

  /**
   * クエリパラメータ設定
   */
  const setQueryParameters = useCallback(
    ({ pageNumber = '' }: { pageNumber?: string | undefined }) => {
      let queryPage = searchParams.get('page');
      if (pageNumber !== '') {
        queryPage = pageNumber;
      } else {
        queryPage = '1';
      }
      const queryParams = {};
      Object.assign(queryParams, { page: queryPage });
      setSearchParams(queryParams);
    },
    [searchParams, setSearchParams],
  );

  /**
   * 初期処理
   */
  useEffect(() => {
    if (!searchParams.get('page')) setQueryParameters({ pageNumber: '1' });
  }, [searchParams, setQueryParameters]);

  return (
    <>
      <VStack w="100%" color="#444" px={1} mb={4}>
        <Flex w="100%" marginBottom={8}>
          <Box>
            <Text fontWeight="bold" mt={2}>
              <NavLink to="../" style={{ color: '#aaa' }}>
                フォーム一覧
              </NavLink>
              &nbsp;/&nbsp;
              <Text as="span" textDecoration="underline">
                デザインスキン一覧
              </Text>
            </Text>
          </Box>
          <Spacer />
          <NavLink to="./form">
            <Button variant="primary">新規作成</Button>
          </NavLink>
        </Flex>
        <Table fontSize={12}>
          <Thead position="sticky" zIndex="sticky" top={0} bgColor="white">
            <Tr>
              {columns.map((item) => (
                <Th color="#777" key={item.id} minW={item.w}>
                  {item.name}
                </Th>
              ))}
            </Tr>
          </Thead>
          <Tbody>
            {skinList?.map((item) => (
              <Tr borderColor="#6699cc" key={item.id}>
                <Td>
                  <Text>
                    {item.id}
                    <DropDownMenu
                      menuType="icon"
                      items={menuItems(item.id)}
                      icon={<BsChevronDown />}
                      variant="none"
                      width="100%"
                      zIndex="1500"
                    />
                  </Text>
                </Td>
                <Td>
                  <Flex>
                    <Text>
                      <NavLink
                        to={`./form?skinId=${item.id}`}
                        style={{ textDecoration: 'underline' }}
                      >
                        {item.name}
                      </NavLink>
                    </Text>
                    <Spacer />
                  </Flex>
                </Td>
                <Td>
                  <Text>
                    {item.createdAt
                      ? format(new Date(item.createdAt), DATETIME_FORMAT)
                      : ''}
                  </Text>
                </Td>
                <Td>
                  <Text>
                    {item.updatedAt
                      ? format(new Date(item.updatedAt), DATETIME_FORMAT)
                      : ''}
                  </Text>
                </Td>
                <Td>
                  <Button
                    bgColor="white"
                    borderWidth="1px"
                    borderColor="gray.400"
                    onClick={() => onPreviewOpen(item.id)}
                  >
                    プレビュー
                  </Button>
                </Td>
              </Tr>
            ))}
          </Tbody>
        </Table>
        {/* プレビュー */}
        <EnqueteSkinPreviewModal
          previewValues={previewValues}
          isOpen={isOpen}
          onClose={onClose}
        />
      </VStack>
      <Paginator pageName="/enquete/skin/" config={page} />
    </>
  );
});
