import { customErrorMessage, toZod } from 'admin/config/zod';
import { NewsletterFormType } from 'admin/types/userPool/newsletter/form';
import { NewsletterType } from 'admin/types/userPool/newsletter/index';
import { z } from 'zod';

// zodErrorの日本語化対応
z.setErrorMap(customErrorMessage);

export const newsletterSchema = z.object<toZod<NewsletterType>>({
  id: z.string(),
  userPool: z.string(),
  displayNameJa: z.string().min(1, '日本語は必須です。'),
  displayNameForeign: z.string(),
  fieldName: z.string().min(1, 'フィールド名は必須です。'),
  isDisplayAccountSettings: z.boolean(),
  status: z.boolean(),
  displayOrder: z.number().or(z.string()).optional(),
  memo: z.string(),
});

export const newslettersSchema = z.array(newsletterSchema);

export const newsletterFormSchema = z.object<toZod<NewsletterFormType>>({
  id: z.string(),
  displayNameJa: z.string().min(1, '日本語は必須です。'),
  displayNameForeign: z.string(),
  fieldName: z.string().min(1, 'フィールド名は必須です。'),
  displayOrder: z.number().or(z.string()).optional(),
  memo: z.string(),
});
