import { VStack } from '@chakra-ui/react';
import { FC, memo } from 'react';

type Props = {
  spacing: string;
  children: React.ReactNode;
}
export const DetailCustomerLink: FC<Props> = memo(({spacing, children}: Props) => (
  <VStack align="flex-start" spacing={spacing}>
    {children}
  </VStack>
));
