import { zodResolver } from '@hookform/resolvers/zod';
import { withSuspenseAndErrorBoundary } from 'admin/components/Error/WithErrorBoundary';
import { FirstLoginMailSettingType } from 'api/transactionMail/type';
import {
  AlertBar,
  ErrorContents,
  LoadingSpinner,
} from 'components/common/atoms';
import { MailSettingFormComponent } from 'components/transactionMail/MailSettingForm/MailSettingFormComponent';
import { useCreateMailSetting } from 'hooks/transactionMail/useCreateMailSetting';
import { useEditMailSetting } from 'hooks/transactionMail/useEditMailSetting';
import { useMailSetting } from 'hooks/transactionMail/useMailSetting';
import { useTenantInfo } from 'hooks/useTenantInfo';
import { FC, memo, useMemo, useState } from 'react';
import { FormProvider, SubmitHandler, useForm } from 'react-hook-form';
import { FirstLoginMailSettingFormSchema } from 'schema/transactionMail/index';

type Props = {
  onClose: () => void;
};

const mailSettingForm: FC<Props> = memo(({ onClose }: Props) => {
  const formId = 'mailSettingForm';

  const { data: mailSetting, isLoading: isLoadingMailSetting } =
    useMailSetting();

  const isEdit = useMemo(() => !!mailSetting?.id, [mailSetting]);

  const methods = useForm<FirstLoginMailSettingType>({
    defaultValues: {
      fromAddress: mailSetting?.fromAddress ?? '',
      replyAddress: mailSetting?.replyAddress ?? '',
    },
    mode: 'onBlur',
    resolver: zodResolver(FirstLoginMailSettingFormSchema),
  });
  const [globalErrors, setGlobalErrors] = useState<string[]>([]);
  const { setError } = methods;
  const { mutate: createMutate, isLoading: isLoadingCreateMailSetting } =
    useCreateMailSetting(setError, setGlobalErrors);
  const { mutate: editMutate, isLoading: isLoadingEditMailSetting } =
    useEditMailSetting(setError, setGlobalErrors);
  const { transactionMailFromAddressList, transactionMailReplyAddressList } =
    useTenantInfo();

  const isLoading = useMemo(
    () =>
      isLoadingMailSetting ||
      isLoadingCreateMailSetting ||
      isLoadingEditMailSetting,
    [
      isLoadingMailSetting,
      isLoadingCreateMailSetting,
      isLoadingEditMailSetting,
    ],
  );

  const onSubmit: SubmitHandler<FirstLoginMailSettingType> = async (
    data: FirstLoginMailSettingType,
  ) => {
    if (isEdit) {
      await editMutate({
        ...data,
        fromAddress: data.fromAddress,
        replyAddress: data.replyAddress,
      });
    } else {
      await createMutate({
        fromAddress: data.fromAddress,
        replyAddress: data.replyAddress,
      });
    }
    onClose();
  };

  return (
    <FormProvider {...methods}>
      {globalErrors.map((err, idx) => (
        <AlertBar
          key={`global-err-idx${String(idx)}`}
          message={err}
          status="error"
          mb={4}
        />
      ))}
      <MailSettingFormComponent
        isLoading={isLoading}
        mailReplyAddressList={transactionMailReplyAddressList}
        mailFromAddressList={transactionMailFromAddressList}
        formId={formId}
        onSubmit={onSubmit}
        onClose={onClose}
      />
    </FormProvider>
  );
});

export const MailSettingForm = withSuspenseAndErrorBoundary(mailSettingForm, {
  ErrorComponent: <ErrorContents name="メール設定" />,
  LoadingComponent: <LoadingSpinner />,
});
