export const isFalse = (val: unknown): boolean => {
  if (!val) {
    return true;
  }

  const checkVal = String(val).toLowerCase().trim();

  return (
    checkVal === 'null' ||
    checkVal === 'undefined' ||
    checkVal === '0' ||
    checkVal === 'false' ||
    checkVal === 'n' ||
    checkVal === 'no'
  );
};
