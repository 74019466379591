import { Options } from 'ky';
import { request } from 'api/request';
import { logger } from 'api/logger';
import { CustomError } from 'api/error/CustomError';
import { SegmentAttribute, isSegmentAttribute } from './types';
import { messages } from './messages';

// セグメント条件フィールドAPI
export const getSegmentField = async (
  tenantId: string,
  options?: Options,
): Promise<SegmentAttribute> => {
  const path = `segment/${tenantId}/fields2/`;

  const response = await request({
    path,
    method: 'get',
    options,
  });

  const responseData = (await response.data) as unknown[];

  if (response.hasError) {
    throw new CustomError(messages.getSegmentAttributes.customer.error);
  }

  if (!isSegmentAttribute(responseData)) {
    await logger({
      loglevel:
        response.status === 404 || response.status === 450
          ? 'warning'
          : 'error',
      data: responseData,
      message: messages.getSegmentAttributes.system.typeError,
    });

    throw new CustomError(messages.getSegmentAttributes.customer.failed);
  }

  return responseData;
};
