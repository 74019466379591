import { UseMutateFunction, useMutation } from 'react-query';
import { putEnquetePublish } from 'api/enquete/putEnquetePublish';
import { ResponseError } from 'api/types';
import { EnquetePublish, isEnquetePublish } from 'api/enquete/types';
import { isGetValidationError } from 'utils/form';
import { useCustomToast } from 'hooks/useCustomToast';
import { useEnqueteUtil } from 'hooks/enquete/useEnqueteUtil';

type UnPromisify<T> = T extends Promise<infer U> ? U : T;

const formData: EnquetePublish = {
  tenantId: '',
  isPublic: false,
};

/**
 * アンケート公開設定変更hooks
 * @param {Object} UseFormSetError setError
 * @param {setGlobalErrors} callback setGlobalErrors
 * @returns {Object} { onSubmit, isLoading }
 */
export const useEnquetePublish = (): {
  onSubmit: UseMutateFunction<
    UnPromisify<ReturnType<typeof putEnquetePublish>>,
    unknown,
    EnquetePublish,
    unknown
  >;
  isLoading: boolean;
} => {
  const { ClearEnqueteListCache } = useEnqueteUtil();
  const toast = useCustomToast();

  const { mutate: onSubmit, isLoading } = useMutation(
    ({ tenantId, enqueteId, isPublic }: EnquetePublish) =>
      putEnquetePublish({ tenantId, enqueteId, isPublic }),
    {
      onSuccess: (result: EnquetePublish | ResponseError) => {
        ClearEnqueteListCache();
        if (isEnquetePublish(result)) {
          const isPublicStr = result.isPublic ? '公開' : '非公開';
          toast({
            status: 'success',
            position: 'bottom',
            duration: 2000,
            isClosable: true,
            title: `フォームを${isPublicStr}に設定しました`,
          });
        }

        if (
          isGetValidationError<EnquetePublish>(result, Object.keys(formData))
        ) {
          const errorMsg = Object.entries(result).map(([_, value]) => value);
          toast({
            status: 'error',
            position: 'bottom',
            duration: 4000,
            isClosable: true,
            title: errorMsg[0],
          });
        }
      },
    },
  );

  return { onSubmit, isLoading };
};
