import { Box, Flex, Text } from '@chakra-ui/react';
import { GmoInfo } from 'api/tenant/types';
import { Colors } from 'components/paywall/config';
import { FC, memo } from 'react';

type Props = {
  gmoInfo: GmoInfo;
};

export const PaymentAgencyBody: FC<Props> = memo(({ gmoInfo }: Props) => {

  const isEmpty = (text: string | null): boolean => text === "" || text === null;

  return (
    <Box mt={5}>
      <Text borderBottom="1px" borderColor="gray.200" pb={4} mb={4}>
        <Text as="span" w={180} display="inline-block">
          ショップID
        </Text>
        {isEmpty(gmoInfo.gmoShopId) && (
          <Text as="span" color={Colors.GRAY_10}>
            未設定
          </Text>
        )}
        {!isEmpty(gmoInfo.gmoShopId) && (
          <Text as="span">
            {gmoInfo.gmoShopId}
          </Text>
        )}
      </Text>
      <Text borderBottom="1px" borderColor="gray.200" pb={4} mb={4}>
        <Text as="span" w={180} display="inline-block">
          ショップパスワード
        </Text>
        {isEmpty(gmoInfo.gmoShopId) && (
          <Text as="span" color={Colors.GRAY_10}>
            未設定
          </Text>
        )}
        {!isEmpty(gmoInfo.gmoShopId) && (
          <Text as="span">
            ●●●●●●●●●●
          </Text>
        )}
      </Text>
      <Text borderBottom="1px" borderColor="gray.200" pb={4} mb={4}>
        <Text as="span" w={180} display="inline-block">
          サイトID
        </Text>
        {isEmpty(gmoInfo.gmoSiteId) && (
          <Text as="span" color={Colors.GRAY_10}>
            未設定
          </Text>
        )}
        {!isEmpty(gmoInfo.gmoSiteId) && (
          <Text as="span">
            {gmoInfo.gmoSiteId}
          </Text>
        )}
      </Text>
      <Flex>
        <Text w={180} display="inline-block">
          サイトパスワード
        </Text>
        {isEmpty(gmoInfo.gmoSiteId) && (
          <Text as="span" color={Colors.GRAY_10}>
            未設定
          </Text>
        )}
        {!isEmpty(gmoInfo.gmoSiteId) && (
          <Text as="span">
            ●●●●●●●●●●
          </Text>
        )}
      </Flex>
    </Box>
  )
});
