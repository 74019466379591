import { Box } from '@chakra-ui/react';
import { Colors } from 'admin/define/colors';
import { FC, memo } from 'react';
import { Link } from 'react-router-dom';

type Props = {
  to: string;
};

export const NavTile: FC<Props> = memo(({ to, children }) => (
  <Link to={to}>
    <Box
      bg={Colors.BLUE_20}
      color="white"
      p="24px"
      fontWeight={700}
      borderRadius="6px"
    >
      {children}
    </Box>
  </Link>
));
