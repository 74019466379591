import { VFC, memo } from 'react';
import {
  Box,
  TableCellProps,
  Table,
  Thead,
  Tbody,
  Tr,
  Td,
  Text,
  Link,
} from '@chakra-ui/react';
import { MeasurementResults } from 'api/mail/types';
import { Copy } from 'components/common/atoms';

const scrollXStyle: TableCellProps = {
  maxHeight: '50px',
  overflowY: 'auto',
  css: {
    '&::-webkit-scrollbar': {
      display: 'none',
    },
  },
};

type MailReportDrawerBodyProps = {
  list: MeasurementResults[];
  status: number;
  copy: (copyData: MeasurementResults[]) => void;
};

export const MailReportDrawerBody: VFC<MailReportDrawerBodyProps> = memo(
  ({ list, status, copy }: MailReportDrawerBodyProps) => {
    const viewValue = (val: number, prefix?: string): string | number =>
      status !== 40 ? '-' : `${val}${prefix || ''}`;

    return (
      <Box w="full" h="100vh" overflowY="auto" maxHeight="300px">
        <Table variant="striped" size="sm">
          <Thead position="sticky" zIndex="sticky" top={0} bgColor="white">
            <Tr>
              <Td fontSize={12} w={100}>
                掲載順番
              </Td>
              <Td fontSize={12} minW={100}>
                リンク先記事タイトル
              </Td>
              <Td fontSize={12} minW={100}>
                URL
              </Td>
              <Td fontSize={12} w={150}>
                リンククリック数
              </Td>
              <Td fontSize={12} w={150}>
                リンククリック率
              </Td>
              <Td fontSize={12} w={100}>
                配信数
              </Td>
              <Td w="50px"> </Td>
            </Tr>
          </Thead>
          {list.length !== 0 && (
            <Tbody>
              {list?.map((item) => (
                <Tr key={item.id}>
                  <Td fontSize={12}>{item.postingOrder}</Td>
                  <Td fontSize={12} maxW={200}>
                    <Box {...scrollXStyle}>{item.linkTitle}</Box>
                  </Td>
                  <Td fontSize={12} maxW={200}>
                    <Box {...scrollXStyle}>
                      <Link
                        href={item.url}
                        isExternal
                        textDecoration="underline"
                      >
                        {item.url}
                      </Link>
                    </Box>
                  </Td>
                  <Td fontSize={12}>{viewValue(item.clickCnt)}</Td>
                  <Td fontSize={12}>{viewValue(item.clickRate, '%')}</Td>
                  <Td fontSize={12}>{item.deliveryCnt}</Td>
                  <Td>
                    <Copy
                      label="クリップボードコピー"
                      onCopy={() => copy([item])}
                    />
                  </Td>
                </Tr>
              ))}
            </Tbody>
          )}
        </Table>
        {list.length === 0 && (
          <Box p={4}>
            <Text fontSize="md" fontWeight="bold" color="#999">
              表示できるデータが存在しません
            </Text>
          </Box>
        )}
      </Box>
    );
  },
);
