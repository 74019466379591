import { memo } from 'react';
import { Tr, Td } from '@chakra-ui/react';
import { GridRowProps, DataType } from './types';

const GridRowComponent = <T extends DataType>(
  props: GridRowProps<T>,
): JSX.Element => {
  const { rownumber, rownumberStyle, children, row, ...rest } = props;

  return (
    <Tr {...rest} key={`grid-row-${row}`}>
      {rownumber ? <Td style={rownumberStyle}>{row + 1}</Td> : <></>}
      {children}
    </Tr>
  );
};

export const GridRow = memo(GridRowComponent) as typeof GridRowComponent;
