import { WarningTwoIcon } from '@chakra-ui/icons';
import { Box, Flex, Tooltip } from '@chakra-ui/react';
import { ActivateDialog } from 'components/paywall/features/ActivateDialog';
import { Td, Tr } from 'components/paywall/features/Table';
import { useTableNavigation } from 'components/paywall/features/Table/TableNavigation/hooks/useTableNavigation';
import { usePaywall } from 'components/paywall/hooks/usePaywall';
import { usePaywallDesign } from 'components/paywall/hooks/usePaywallDesign';
import { PERMISSION_MANAGER } from 'define';
import { useWallDesignList } from 'hooks/paywall/useWallDesignList';
import { useUserInfo } from 'hooks/useUserInfo';
import { FC, memo, useEffect } from 'react';
import { NavLink } from 'react-router-dom';
import { WallDesign } from '../typed';

export const WallDesignRows: FC = memo(() => {
  const { wallId, currentWallType } = usePaywall({});
  const { wallDesignList, page } = useWallDesignList(wallId);
  const { onActivate } = usePaywallDesign();
  const { isEditableTarget } = useUserInfo();
  const { setCount } = useTableNavigation();

  const errorMessage = (wallDesign: WallDesign): string => {
    let message = '';
    if (
      (currentWallType === 'course' || currentWallType === 'item') &&
      !wallDesign.hasPaywallButton
    ) {
      message = '「ペイウォールボタン」';
    } else if (currentWallType === 'member') {
      const buttonMessages = [];
      if (!wallDesign.hasLoginButton) {
        buttonMessages.push('ログインボタン');
      }
      if (!wallDesign.hasMemberRegistButton) {
        buttonMessages.push('会員登録ボタン');
      }
      if (buttonMessages.length > 0) {
        message = `「${buttonMessages.join('、')}」`;
      }
    }

    if (message !== '') {
      return `${message}が未設置の為有効にできません`;
    }

    return message;
  };

  useEffect(() => {
    setCount(page.count);
  }, [setCount, page]);

  return (
    <>
      {(wallDesignList || []).map((obj) => (
        <Tr key={obj.id}>
          <Td fontFamily="Hiragino sans">{obj.id}</Td>
          <Td
            style={{ width: '100%' }}
            fontWeight="700"
            fontFamily="Hiragino sans"
          >
            <NavLink
              to={`/paywall/${obj.wall}/WallEditor/form?type=${currentWallType}&formId=${obj.id}`}
              style={{ textDecoration: 'underline' }}
            >
              {obj.name}
            </NavLink>
          </Td>
          <Td minWidth="123px">
            <Flex style={{ float: 'right', gap: '.25rem' }}>
              {errorMessage(obj) && (
                <Box>
                  <Tooltip
                    label={errorMessage(obj)}
                    bgColor="red.400"
                    color="white"
                  >
                    <WarningTwoIcon color="red" fontSize={16} mr={1} />
                  </Tooltip>
                </Box>
              )}
              <ActivateDialog
                id={obj.id}
                msg={`「${obj.name}」のステータスを「${
                  obj.status ? '無効' : '有効'
                }」に変更しますか？`}
                isEnable={obj.status}
                activate={onActivate}
                isDisabled={
                  !isEditableTarget(PERMISSION_MANAGER) ||
                  errorMessage(obj) !== ''
                }
              />
            </Flex>
          </Td>
        </Tr>
      ))}
    </>
  );
});
