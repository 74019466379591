import { getTerm } from 'api/paywall/getTerm';
import { TermsSetting } from 'components/paywall/pages/TermsSetting/typed';
import { useCustomToast } from 'hooks/useCustomToast';
import { useUserTenantId } from 'hooks/user/useUserTenantId';
import { useQuery } from 'react-query';
import { queryPaywallKey } from './queryPaywallKey';

/**
 * 規約情報取得
 * @return {TermsSetting | undefined} 規約情報
 */
export const useTerm = (): TermsSetting => {
  const toast = useCustomToast();
  const tenantId = useUserTenantId();
  const response = useQuery(
    queryPaywallKey.getTerm({ tenantId }),
    () => getTerm(tenantId),
    {
      onError: (err: unknown) => {
        if (err instanceof Error) {
          toast.closeAll();
          toast({
            title: err.message,
            status: 'error',
          });
        }
      },
    },
  );
  const { data } = response;

  return data as TermsSetting ;
};
