import { request } from 'admin/api/Request';
import { API_MESSAGES } from 'admin/define/apiMessages';
import { CustomError } from 'admin/error/CustomError';
import { TransactionMailsSchema } from 'admin/schema/idPoolConsumer/transactionMail';
import {
  TransactionMailCategoryType,
  TransactionMailsType,
} from 'admin/types/userPool/transactionMail';
import { camelCase, snakeCase } from 'utils/str';
import { ZodError } from 'zod';

export const getTransactionMails = async (
  idPoolConsumerId: string,
  mailType: TransactionMailCategoryType,
): Promise<TransactionMailsType> => {
  const path = `uniikey/${idPoolConsumerId}/transaction_mails_list/?type=${snakeCase(
    mailType,
  )}`;
  const response = await request<TransactionMailsType>({
    path,
    method: 'get',
  });

  if (response.hasError) {
    if (response.status === 404) {
      throw new CustomError(
        API_MESSAGES.USER_POOL.GET_TRANSACTION_MAILS.NOT_FOUND,
        {
          path,
          status: response.status,
        },
      );
    } else {
      throw new CustomError(
        API_MESSAGES.USER_POOL.GET_TRANSACTION_MAILS.ERROR,
        {
          path,
          status: response.status,
        },
      );
    }
  }

  // メール一覧の各メール情報に含まれるtypeのデータを修正する
  const transactionMailData = response.data.map((val) => ({
    ...val,
    type: camelCase(val.type),
  })) as TransactionMailsType;

  try {
    TransactionMailsSchema.parse(transactionMailData);
  } catch (error) {
    if (error instanceof ZodError) {
      throw new CustomError(error.message, {
        path,
        status: response.status,
        logLevel: 'error',
      });
    }
  }

  return transactionMailData;
};
