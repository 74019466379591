import { zodResolver } from '@hookform/resolvers/zod';
import { withSuspenseAndErrorBoundary } from 'admin/components/Error/WithErrorBoundary';
import { AttributeEditFormComponent } from 'admin/components/idPoolConsumer/attribute/attributeForm/attributeEditForm/AttributeEditFormComponent';
import { FIELD_UI_TYPE_LIST, JSON_PATH_VALUE_TYPE } from 'admin/define/field';
import { useIdPoolConsumerId } from 'admin/hooks/useIdPoolConsumerId';
import { useFilterFieldTypeList } from 'admin/hooks/userPool/attribute/useFilterFieldTypeList';
import { useIsShowMasterType } from 'admin/hooks/userPool/attribute/useIsShowMasterType';
import { useUpdateIdPoolConsumerAttribute } from 'admin/hooks/userPool/attribute/useUpdateIdPoolConsumerAttribute';
import { useAllCodeGroups } from 'admin/hooks/userPool/master/useAllCodeGroups';
import { useUserPool } from 'admin/hooks/userPool/useUserPool';
import { useUserPoolAuthenticationType } from 'admin/hooks/userPool/useUserPoolAuthenticationType';
import { idPoolConsumerAttributeFormSchema } from 'admin/schema/idPoolConsumer/attribute';
import { IdPoolConsumerAttributeType } from 'admin/types/userPool/attribute';
import { IdPoolConsumerAttributeUpdateFormType } from 'admin/types/userPool/attribute/form';
import {
  AlertBar,
  ErrorContents,
  LoadingSpinner,
} from 'components/common/atoms';
import { FC, memo, useEffect, useMemo, useState } from 'react';
import {
  FormProvider,
  SubmitHandler,
  useForm,
  useWatch,
} from 'react-hook-form';

type Props = {
  attribute?: IdPoolConsumerAttributeType | undefined;
  onClose: () => void;
};

const attributeEditForm: FC<Props> = memo(({ attribute, onClose }: Props) => {
  const formId = 'attributeForm';
  const idPoolConsumerId = useIdPoolConsumerId();

  const { data: codeGroups, isLoading: isLoadingCodeGroups } = useAllCodeGroups(
    { id: idPoolConsumerId, isEnabled: true },
  );
  const { data: userPool, isLoading: isLoadingUserPool } =
    useUserPool(idPoolConsumerId);
  const { isOuter } = useUserPoolAuthenticationType();
  const methods = useForm<IdPoolConsumerAttributeUpdateFormType>({
    defaultValues: {
      displayNameJa: attribute?.displayNameJa ?? '',
      displayNameForeign: attribute?.displayNameForeign ?? '',
      fieldName: attribute?.fieldName ?? '',
      dataType: attribute?.dataType ?? '',
      uiType: attribute?.uiType ?? '',
      jsonPathValueType: String(attribute?.jsonPathValueType) ?? '',
      codeGroup: attribute?.codeGroup ?? '',
      isPersonalInformation: attribute?.isPersonalInformation ?? false,
      jsonPath: attribute?.jsonPath ? attribute?.jsonPath : '',
      displayOrder: attribute?.displayOrder ?? '',
    },
    resolver: zodResolver(idPoolConsumerAttributeFormSchema),
  });
  const [globalErrors, setGlobalErrors] = useState<string[]>([]);

  const { control, setError, setValue } = methods;

  const selectedFieldUiType = useWatch({
    control,
    name: 'uiType',
  });

  const filterFieldTypeList = useFilterFieldTypeList(selectedFieldUiType);
  const isShowMasterType = useIsShowMasterType(selectedFieldUiType);

  const { mutate, isLoading } = useUpdateIdPoolConsumerAttribute({
    id: attribute?.id ?? '',
    userPoolId: idPoolConsumerId,
    setError,
    setGlobalErrors,
  });

  const isEdit = useMemo<boolean>(
    () => Boolean(attribute?.status),
    [attribute],
  );

  const isPreset = useMemo<boolean>(
    () => Boolean(attribute?.isPreset),
    [attribute],
  );

  const onSubmit: SubmitHandler<IdPoolConsumerAttributeUpdateFormType> = async (
    data: IdPoolConsumerAttributeUpdateFormType,
  ) => {
    await mutate(data);
    onClose();
  };

  // isShowMasterTypeがtrueの時、ドロワー最下部に表示される
  // 「コード値」選択ラジオボタンの初期値を設定する
  useEffect(() => {
    if (isShowMasterType) {
      setValue('jsonPathValueType', JSON_PATH_VALUE_TYPE.displayName);
    } else {
      setValue('jsonPathValueType', '');
    }
  }, [isShowMasterType, setValue]);

  return (
    <FormProvider {...methods}>
      {globalErrors.map((err, idx) => (
        <AlertBar
          key={`global-err-idx${String(idx)}`}
          message={err}
          status="error"
          mb={4}
        />
      ))}
      <AttributeEditFormComponent
        isExternalId={isOuter}
        isEdit={isEdit}
        fieldUiTypeList={FIELD_UI_TYPE_LIST}
        fieldTypeList={filterFieldTypeList}
        isShowMasterType={isShowMasterType}
        onClose={onClose}
        onSubmit={onSubmit}
        isLoading={isLoading || isLoadingCodeGroups || isLoadingUserPool}
        formId={formId}
        codeGroups={codeGroups}
        userInfoResponse={userPool?.userInfoResponse}
        idPoolConsumerId={idPoolConsumerId}
        isPreset={isPreset}
      />
    </FormProvider>
  );
});

export const AttributeEditForm = withSuspenseAndErrorBoundary(
  attributeEditForm,
  {
    ErrorComponent: <ErrorContents name="組織レベル属性詳細" />,
    LoadingComponent: <LoadingSpinner />,
  },
);

attributeEditForm.defaultProps = {
  attribute: undefined,
};
