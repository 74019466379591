import { Text } from '@chakra-ui/react';
import { withSuspenseAndErrorBoundary } from 'admin/components/Error/WithErrorBoundary';
import { MemberAttributeTableComponent } from 'admin/components/member/detail/memberAttribute/memberAttributeTable/MemberAttributeTableComponent';
import { useMemberAttributes } from 'admin/hooks/member/useMemberAttributes';
import { useUserPoolId } from 'admin/hooks/params/useUserPoolId';
import { Page } from 'api/common/types';
import { ErrorContents } from 'components/common/atoms';
import { Dispatch, FC, memo, SetStateAction, useEffect, useMemo } from 'react';
import { useParams } from 'react-router-dom';

type Props = {
  currentPage: number;
  perPage: number;
  setPage: Dispatch<SetStateAction<Page>>;
};

const memberAttributeTable: FC<Props> = memo(
  ({ currentPage, perPage, setPage }: Props) => {
    const userPoolId = useUserPoolId();
    const params = useParams();
    const memberId = useMemo(() => params.memberId ?? '', [params.memberId]);
    const { data: attributes, page } = useMemberAttributes(
      userPoolId,
      memberId,
      currentPage,
      perPage,
    );

    useEffect(() => {
      setPage(page);
    }, [setPage, page]);

    if (!attributes) {
      return <Text>属性一覧がありません。</Text>;
    }

    return <MemberAttributeTableComponent attributes={attributes} />;
  },
);

export const MemberAttributeTable = withSuspenseAndErrorBoundary(
  memberAttributeTable,
  {
    ErrorComponent: <ErrorContents name="属性一覧情報" />,
  },
);
