import { request } from 'admin/api/Request';
import { API_MESSAGES } from 'admin/define/apiMessages';
import { CustomError } from 'admin/error/CustomError';
import { formGroupDetailReponseSchema } from 'admin/schema/idPoolConsumer/formGroup';
import { FormGroupDetailType } from 'admin/types/userPool/formGroup';
import { isEnqueteResponseError } from 'api/types';
import { Options } from 'ky';
import { getValidationError } from 'utils/form';
import { ZodError } from 'zod';

export const editFormGroupDetail = async ({
  data,
  userPoolId,
  options,
}: {
  data: FormGroupDetailType;
  userPoolId: FormGroupDetailType['userPoolId'];
  options?: Options;
}): Promise<FormGroupDetailType> => {
  const params = data;
  const path = `enquete/${userPoolId}/content_group/${params.id}/`;

  const response = await request<FormGroupDetailType>({
    path,
    method: 'patch',
    body: params,
    options,
  });

  if (response.hasError) {
    if (response.status === 404) {
      throw new CustomError(
        API_MESSAGES.USER_POOL.EDIT_FORM_GROUP_DETAIL.FAILED,
        {
          path,
          options,
          params,
          status: response.status,
        },
      );
    } else if (isEnqueteResponseError(response.error)) {
      const customError = response.error;
      const formError = getValidationError<FormGroupDetailType>({
        formData: data,
        response: customError,
      });

      throw new CustomError(
        API_MESSAGES.USER_POOL.EDIT_FORM_GROUP_DETAIL.ERROR,
        {
          path,
          options,
          params,
          errors: formError,
          status: response.status,
          logLevel: response.status === 400 ? 'warning' : 'error',
        },
      );
    } else {
      throw new CustomError(
        API_MESSAGES.USER_POOL.EDIT_FORM_GROUP_DETAIL.ERROR,
        {
          path,
          options,
          params,
          status: response.status,
        },
      );
    }
  }

  try {
    formGroupDetailReponseSchema.parse(response.data);
  } catch (error) {
    if (error instanceof ZodError) {
      throw new CustomError(error.message, {
        path,
        options,
        params,
        status: response.status,
        logLevel: 'error',
      });
    }
  }

  return response.data;
};
