import { VFC, useEffect, useState } from 'react';
import { Box } from '@chakra-ui/react';

const DEPLOY_MODE = process.env.REACT_APP_DEPLOY_MODE;

export const NotificationBar: VFC = () => {
  const [borderColor, setBorderColor] = useState('');
  const [bgColor, setBgColor] = useState('');

  useEffect(() => {
    if (DEPLOY_MODE === 'dev') {
      setBorderColor('#ffbe00');
      setBgColor('#fff2cc');
    } else if (DEPLOY_MODE === 'stg') {
      setBorderColor('#f99a9a');
      setBgColor('#f4cccc');
    }
  }, []);

  return (
    <Box
      width="100%"
      textAlign="center"
      padding="2.3px"
      position="fixed"
      border="2px solid"
      borderColor={borderColor}
      backgroundColor={bgColor}
      zIndex="100"
    >
      これはデモ用の{DEPLOY_MODE}環境です。実際の施策には利用できません。
    </Box>
  );
};
