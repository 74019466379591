import { Box, Text } from '@chakra-ui/react';
import { withSuspenseAndErrorBoundary } from 'admin/components/Error/WithErrorBoundary';
import { Colors } from 'admin/define/colors';
import { useService } from 'admin/hooks/service/useService';
import { ErrorContents, LoadingSkeleton } from 'components/common/atoms';
import { FC, memo, useMemo } from 'react';
import { useParams } from 'react-router-dom';

const serviceTitleLabel: FC = memo(() => {
  const params = useParams();
  const serviceId = useMemo(() => params.serviceId ?? '', [params.serviceId]);
  const { data: service } = useService(serviceId);

  return (
    <Box
      bgColor={Colors.WHITE}
      borderRadius="4px"
      display="inline-block"
      p="2px 4px"
    >
      <Text>{service?.name}</Text>
    </Box>
  )
});

export const ServiceTitleLabel = withSuspenseAndErrorBoundary(serviceTitleLabel, {
  ErrorComponent: <ErrorContents name="テナント詳細ラベル" />,
  LoadingComponent: <LoadingSkeleton line={1} width="150px" pl="0px" pr="0px" />,
});
