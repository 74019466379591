// {
//   "created_at": "2021-12-06T10:39:49.259727",
//   "updated_at": "2021-12-06T10:39:49.259776",
//   "id": "grp_s9c7es0a",
//   "name": "埼玉県",
//   "tenant_id": "tnt_kekqo917",
//   "parent_id": null,
//   "parent_name": ""
// },
export type Tenant = {
  id: string;
  name: string;
  apiKey: string;
  mailFromAddress: string;
  mailReplyAddress: string;
  transactionMailFromAddress: string;
  transactionMailReplyAddress: string;
  loginUrl: string;
  mailmaga: number[];
};

export type TenantMailList = {
  id: number;
  name: string;
};

export type GroupFlat = {
  // グループID
  id: string;
  // グループ名
  name: string;
  // テナントID
  tenantId: string;
  // 親グループID
  parentId: string | null;
  // 親グループ名
  parentName: string | null;
};

export type GroupUser = {
  // ID
  id: number;
  // グループID
  group: string;
  // グループ名
  groupName: string;
  // ユーザーuuid
  user: string;
  // ユーザー名
  userName: string;
  // メールアドレス
  email: string;
  // 更新日
  updatedAt: string;
  // 階層レベル
  treeLevel: number;
};

export type AddGroupFormType = {
  name: string;
  tenantId: string;
  parentId?: string;
};

export type DeleteGroupFormType = {
  groupId: string;
  tenantId: string;
  deleteText: string;
};

export type AddGroupUserFormType = {
  groupId: string;
  user: string;
};

export type DeleteGroupUserFormType = {
  groupId: string;
  userId: string;
};

export type EditGroupFormType = {
  groupId: string;
  tenantId: string;
  parentId: string;
  name: string;
};

export type ApiKey = {
  apiKey?: string;
  updateUserName: string;
  updatedAt: string;
};

export type TenantApiKey = {
  id: string;
  name: string;
  apiKey: string | null;
  accessKey: string | null;
  secretKey: string | null;
  token: string | null;
  history: ApiKey[] | [];
};

export type TenantApiKeyFormType = {
  tenantId: string;
};

export type GroupDeleteCheckType = {
  warning: string;
  error: string;
};
export type WebSiteListType = {
  websiteList: string[];
};

export type GmoInfo = {
  gmoShopId: string | null;
  gmoShopPass?: string;
  gmoSiteId: string | null;
  gmoSitePass?: string;
};

export type FormHostingType = {
  header: null | string;
  body: null | string;
  hostingUrl: string | null;
};

/** POST で送る時のパラメータ */
export type PostFormHostingType = {
  header: null | string;
  body: null | string;
};

export type LoginSetting = {
  loginUrl: string;
  signupUrl: string;
  loginParam: string;
  loginOtherParam: string;
};

export type LoginSettingConfirmType = {
  word: string;
};

export const isTenant = (data: unknown): data is Tenant => {
  const t = data as Tenant;

  return typeof t?.id === 'string' &&
    typeof t?.name === 'string' &&
    t?.apiKey !== null
    ? typeof t?.apiKey === 'string'
    : true &&
        (t?.mailFromAddress !== null
          ? typeof t?.mailFromAddress === 'string'
          : true) &&
        (t?.mailReplyAddress !== null
          ? typeof t?.mailReplyAddress === 'string'
          : true) &&
        (t?.transactionMailFromAddress !== null
          ? typeof t?.transactionMailFromAddress === 'string'
          : true) &&
        (t?.transactionMailReplyAddress !== null
          ? typeof t?.transactionMailReplyAddress === 'string'
          : true) &&
        typeof t?.loginUrl === 'string' &&
        (t?.mailmaga.length > 0
          ? t?.mailmaga.some((d) => typeof d === 'number')
          : true);
};

export const isGroup = (data: unknown): data is GroupFlat => {
  const t = data as GroupFlat;

  return (
    typeof t?.id === 'string' &&
    typeof t?.name === 'string' &&
    typeof t?.tenantId === 'string' &&
    (typeof t?.parentId === 'string' || t?.parentId === null) &&
    (typeof t?.parentName === 'string' || t?.parentName === null)
  );
};

export const isGroupList = (datas: unknown[]): datas is GroupFlat[] =>
  !datas.some((d) => !isGroup(d));

export const isGroupUser = (data: unknown): data is GroupUser => {
  const gu = data as GroupUser;

  return (
    typeof gu.id === 'number' &&
    typeof gu.group === 'string' &&
    typeof gu.groupName === 'string' &&
    typeof gu.user === 'string' &&
    typeof gu.userName === 'string' &&
    typeof gu.email === 'string' &&
    typeof gu.updatedAt === 'string'
  );
};

export const isGroupUserList = (datas: unknown[]): datas is GroupUser[] =>
  !datas.some((d) => !isGroupUser(d));

export const isApiKey = (data: unknown): data is ApiKey => {
  const ak = data as ApiKey;

  return ak.apiKey
    ? typeof ak.apiKey === 'string'
    : true &&
        typeof ak.updateUserName === 'string' &&
        typeof ak.updatedAt === 'string';
};

export const isApiKeys = (datas: unknown[]): datas is ApiKey[] =>
  !datas.some((d) => !isApiKey(d));

export const isTenantApiKey = (data: unknown): data is TenantApiKey => {
  const tak = data as TenantApiKey;

  return typeof tak.id === 'string' &&
    typeof tak.name === 'string' &&
    tak.apiKey
    ? typeof tak.apiKey === 'string'
    : true && (tak.history.length > 0 ? isApiKeys(tak.history) : true);
};

export const isGroupDeleteCheckType = (
  data: unknown,
): data is GroupDeleteCheckType => {
  const gdc = data as GroupDeleteCheckType;

  return typeof gdc.warning === 'string' && typeof gdc.error === 'string';
};

export const isWebSites = (data: unknown): data is WebSiteListType => {
  const tak = data as WebSiteListType;

  if (tak.websiteList.length === 0) {
    return true;
  }

  return !tak.websiteList.some((val) => typeof val !== 'string');
};

export const isGmoInfo = (data: unknown): data is GmoInfo => {
  const dt = data as GmoInfo;

  return (
    (dt?.gmoShopId !== null ? typeof dt?.gmoShopId === 'string' : true) &&
    (dt?.gmoSiteId !== null ? typeof dt?.gmoSiteId === 'string' : true)
  );
};

export const isFormHostingType = (data: unknown): data is FormHostingType => {
  const dt = data as FormHostingType;

  return (
    (dt?.header !== null ? typeof dt?.header === 'string' : true) &&
    (dt?.body !== null ? typeof dt?.body === 'string' : true) &&
    (dt?.hostingUrl !== null ? typeof dt?.hostingUrl === 'string' : true)
  );
};

export const isPostFormHostingType = (
  data: unknown,
): data is PostFormHostingType => {
  const dt = data as PostFormHostingType;

  return (
    (dt?.header !== null ? typeof dt?.header === 'string' : true) &&
    (dt?.body !== null ? typeof dt?.body === 'string' : true)
  );
};

export const isLoginSetting = (data: unknown): data is LoginSetting => {
  const ls = data as LoginSetting;

  return (
    (ls?.loginUrl !== null ? typeof ls?.loginUrl === 'string' : true) &&
    (ls?.signupUrl !== null ? typeof ls?.signupUrl === 'string' : true) &&
    (ls?.loginParam !== null ? typeof ls?.loginParam === 'string' : true) &&
    (ls?.loginOtherParam !== null
      ? typeof ls?.loginOtherParam === 'string'
      : true)
  );
};
