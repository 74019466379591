/* eslint-disable react/require-default-props */
import { PaywallCardHeader } from 'components/paywall//pages/WallList/PaywallCardList/PaywallCardHeader';
import { PaywallCardItems } from 'components/paywall//pages/WallList/PaywallCardList/PaywallCardItems';
import { Wall } from 'components/paywall//pages/WallList/typed';
import { Card, CardBadge, CardHeader } from 'components/paywall/features/Card';
import { FC, memo } from 'react';


type Props = {
  wall: Wall;
  isChild?: boolean;
}
export const PaywallCard: FC<Props> = memo(({wall, isChild=false}: Props) => (
  <Card isChild={isChild}>
    <CardBadge isChild={isChild} />
    <CardHeader padding="8px 24px">
      <PaywallCardHeader wall={wall} />
    </CardHeader>
    <PaywallCardItems courses={wall.courses} />
  </Card>
));
