import { customErrorMessage, toZod } from 'admin/config/zod';
import {
  FirstLoginMailFormType,
  FirstLoginMailSettingType,
  FirstLoginMailType,
} from 'api/transactionMail/type';
import z from 'zod';

// zodErrorの日本語化対応
z.setErrorMap(customErrorMessage);

export const FirstLoginMailSchema = z.object<toZod<FirstLoginMailType>>({
  id: z.string(),
  subject: z.string(),
  status: z.boolean(),
  type: z.literal('startService'),
  settingTitle: z.string(),
  editorHtml: z.string(),
  editorText: z.string(),
  corporation: z.string(),
  tenant: z.string(),
});

export const FirstLoginMailListResponsedSchema = z.array(FirstLoginMailSchema);

export const FirstLoginMailSettingSchema = z.object<
  toZod<FirstLoginMailSettingType>
>({
  id: z.string().optional(),
  fromAddress: z.string(),
  replyAddress: z.string(),
});

export const FirstLoginMailSettingFormSchema =
  FirstLoginMailSettingSchema.extend({
    fromAddress: z.string().min(1, '差出人メールアドレスは必須です'),
  });

export const FirstLoginMailFormTypeSchema = z.object<
  toZod<FirstLoginMailFormType>
>({
  id: z.string(),
  settingTitle: z.string().min(1, '設定名は必須です'),
  subject: z.string().min(1, 'メールタイトルは必須です'),
  bodyHtml: z.string(),
  bodyText: z.string(),
  editorHtml: z.string(),
  editorText: z.string(),
  status: z.boolean(),
  type: z.literal('startService'),
});

export const CreateFirstLoginMailFormTypeSchema =
  FirstLoginMailFormTypeSchema.omit({
    id: true,
    editorHtml: true,
    editorText: true,
    bodyHtml: true,
    status: true,
  });

export const EditFirstLoginMailFormSubmitTypeSchema =
  FirstLoginMailFormTypeSchema.omit({
    editorHtml: true,
    editorText: true,
    bodyHtml: true,
    status: true,
  });
