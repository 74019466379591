import { CustomError } from 'api/error/CustomError';
import { logger } from 'api/logger';
import { request } from 'api/request';
import {
  Course,
  isCourseList
} from 'components/paywall/pages/CourseList/typed';
import { Options } from 'ky';
import { messages } from './messages';

/**
 * コース一覧取得 API
 * @param {string} tenantId テナントID
 * @param {string} wallId ウォールID
 * @param {Options} options APIオプション
 * @return {Promise<Course[]>} コース一覧
 */
export const getCourseList = async (
  tenantId: string,
  wallId: string,
  isIncludeParent: boolean,
  options?: Options,
): Promise<Course[]> => {
  let path = `paywall/${tenantId}/${wallId}/course/`;
  if(isIncludeParent) {
    path += '?include_parent=true';
  }

  const response = await request({
    path,
    method: 'get',
    options,
  });

  const responseData = (await response.data) as unknown[];

  if (response.hasError) {
    if (response.status === 404) {
      throw new CustomError(
        messages.getCourseList.customer.notFound,
        response.status,
      );
    } else {
      throw new CustomError(messages.getCourseList.customer.error);
    }
  }

  if (!isCourseList(responseData)) {
    await logger({
      loglevel:
        response.status === 404 || response.status === 450
          ? 'warning'
          : 'error',
      data: responseData,
      message: messages.getCourseList.system.typeError,
    });

    throw new CustomError(messages.getCourseList.customer.failed);
  }

  return responseData;
};
