import {
  WallDesign,
  WallDesignFormType,
  WallDesignPublish
} from 'components/paywall/pages/WallDesignList/typed';

/**
 * ウォール画面デザイン デフォルト値
 */
export const WallDesignDefaultValues: WallDesign = {
  id: '',
  wall: '',
  name: '',
  source: '',
  html: '',
  status: false,
  hasPaywallButton: false,
  hasLoginButton: false,
  hasMemberRegistButton: false,
  createdAt: '',
  updatedAt: '',
} as const;

/**
 * WallDesignFormType デフォルト値
 */
export const WallDesignFormDefaultValues: WallDesignFormType = {
  id: '',
  tenant: '',
  wallId: '',
  name: '',
  source: '',
  html: '',
};

/**
 * WallDesignPublish デフォルト値
 */
export const WallDesignPublishDefaultValues: WallDesignPublish = {
  id: '',
  tenant: '',
  wallId: '',
  status: false,
};
