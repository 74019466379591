import { Td, Tr } from 'admin/components/ui/table';
import { MemberAttributeType } from 'admin/types/member';
import { FC, memo, useMemo } from 'react';

type Props = {
  attribute: MemberAttributeType;
};

export const MemberAttributeTableBody: FC<Props> = memo(
  ({ attribute }: Props) => {
    const displayAttrValue = useMemo((): string => {
      if (Array.isArray(attribute.value)) {
        return attribute.value.join(', ');
      }

      return attribute.value;
    }, [attribute.value]);

    return (
      <Tr>
        <Td fontSize="14px" p={4}>
          {attribute.id}
        </Td>
        <Td fontSize="14px" p={4}>
          {attribute.displayNameJa}
        </Td>
        <Td fontSize="14px" p={4}>
          {attribute.displayNameForeign || '-'}
        </Td>
        <Td fontSize="14px" p={4}>
          {attribute.fieldName}
        </Td>
        <Td fontSize="14px" p={4} whiteSpace="pre-wrap">
          {displayAttrValue || '-'}
        </Td>
      </Tr>
    );
  },
);
