import {
  Button,
  Modal, ModalBody,
  ModalCloseButton, ModalContent, ModalFooter, ModalHeader, ModalOverlay
} from '@chakra-ui/react';
import { memo, VFC } from 'react';

type Props = {
  title?: string;
  description?: string | JSX.Element;
  submitBtnName?: string;
  cancelBtnName?: string;
  isOpen: boolean;
  onClose: (mode?: string) => void;
  closeOnOverlayClick?: boolean;
};

export const WarningModal: VFC<Props> = memo(
  ({
    title = '',
    description = '',
    submitBtnName = '',
    cancelBtnName = '',
    isOpen,
    onClose,
    closeOnOverlayClick = true,
  }: Props) => (
    <Modal
      isOpen={isOpen}
      onClose={onClose}
      isCentered
      closeOnOverlayClick={closeOnOverlayClick}
    >
      <ModalOverlay />
      <ModalContent>
        <ModalHeader>{title}</ModalHeader>
        <ModalBody>{description}</ModalBody>
        <ModalFooter>
          {cancelBtnName !== '' && (
            <Button mr={3} onClick={() => onClose()}>
              {cancelBtnName}
            </Button>
          )}
          <Button colorScheme="red" onClick={() => onClose('submit')}>
            {submitBtnName}
          </Button>
        </ModalFooter>
        <ModalCloseButton />
      </ModalContent>
    </Modal>
  ),
);
