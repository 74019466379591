import ErrorBoundary from 'api/ErrorBoundary';
import {
  ErrorContents,
  ErrorStatusContents
} from 'components/common/atoms';
import { useErrorBoundaryReset } from 'hooks/useErrorBoundaryReset';
import { FC, memo, ReactNode } from 'react';

type Props = {
  children: ReactNode;
  pageTitle: string;
}

export const CustomErrorBoundary: FC<Props> = memo(({children, pageTitle}: Props) => {
  const { ebKey, onError } = useErrorBoundaryReset();
  const renderErrorComponent = {
    404: <ErrorStatusContents code={404} />,
    450: <ErrorStatusContents code={450} />,
  };

  return (
    <ErrorBoundary
      errorComponent={<ErrorContents name={pageTitle} />}
      statusComponent={renderErrorComponent}
      onError={onError}
      key={ebKey.current}
    >
      {children}
    </ErrorBoundary>
  )
});
