import {
  AlertDialogCloseButton,
  AlertDialogContent,
  AlertDialogHeader,
  AlertDialogOverlay,
  Box,
  Text,
} from '@chakra-ui/react';
import { TenantAppendForm } from 'admin/components/service/newsletter/tenantAppend/tenantAppendForm';
import { Colors } from 'admin/define/colors';
import { ServiceNewsletterType } from 'admin/types/service/newsletter';
import { FC, memo } from 'react';

type Props = {
  newsletter: ServiceNewsletterType | undefined;
  onClose: () => void;
  currentPage: number;
};

export const TenantAppendDialog: FC<Props> = memo((props) => (
  <AlertDialogOverlay>
    <AlertDialogContent color={Colors.GRAY_03}>
      <AlertDialogHeader p={6}>
        <Box>
          <Text>テナント割当の確認</Text>
        </Box>
      </AlertDialogHeader>
      <TenantAppendForm {...props} />
      <AlertDialogCloseButton top="24px" right="24px" />
    </AlertDialogContent>
  </AlertDialogOverlay>
));
