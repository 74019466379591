import {
  CloseAllToastsOptions,
  ToastId,
  useToast,
  UseToastOptions,
} from '@chakra-ui/react';
import './useCustomToast.css';

type UseCustomToast = {
  (options?: UseToastOptions | undefined): string | number | undefined;
  close: (id: ToastId) => void;
  closeAll: (options?: CloseAllToastsOptions | undefined) => void;
  update(id: ToastId, options: UseToastOptions): void;
  isActive: (id: ToastId) => boolean | undefined;
};

export const useCustomToast = (): UseCustomToast =>
  useToast({
    isClosable: true,
    variant: 'subtle',
    position: 'bottom',
  });
