import { Box, Flex, Text } from '@chakra-ui/react';
import { FC, memo } from 'react';

export const AuthTitle: FC = memo(() => (
  <Box mt="40px">
    <Flex justifyContent="flex-end">
      <Box mr="auto">
        <Text
          fontFamily="Hiragino Sans"
          fontSize="18px"
          fontWeight="700"
          lineHeight="170%"
        >
          認証認可とセキュリティ設定
        </Text>
      </Box>
    </Flex>
  </Box>
));
