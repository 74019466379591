import { Box, BoxProps, Button, ButtonProps, Flex, Select, Text } from '@chakra-ui/react';
import { Colors, ColorSet } from 'admin/define/colors';
import {
  Container,
  Next,
  PageGroup,
  Paginator,
  Previous
} from 'chakra-paginator';
import { ChangeEvent, FC, memo } from 'react';
import { BiChevronLeft, BiChevronRight, BiChevronsLeft, BiChevronsRight } from 'react-icons/bi';

const PAGINATOR_OUTER_LIMIT = 3;
const PAGINATOR_INNER_LIMIT = 2;

/* スタイル定義 */
const baseStyles: ButtonProps = {
  w: 10,
  h: 10,
};
const fontBaseStyle: ButtonProps | BoxProps = {
  fontFamily: 'Inter',
  fontSize: '14px',
  fontWeight: '500',
  lineHeight: '20px',
  letterSpacing: '-0.084px'
}
const normalStyles: ButtonProps = {
  ...baseStyles,
  ...fontBaseStyle as ButtonProps,
  ...ColorSet.Default
};
const activeStyles: ButtonProps = {
  ...baseStyles,
  ...fontBaseStyle as ButtonProps,
  bg: Colors.BLUE_LIGHT_01,
};
const separatorStyles: ButtonProps = {
  ...baseStyles,
  ...fontBaseStyle as ButtonProps,
  pointerEvents: 'none'
};
const naviBtnStyle: ButtonProps = {
  ...normalStyles,
  border: 'none',
  bgColor: 'transparent',
  variant: 'ghost',
}
/* スタイル定義 ここまで */

type Props = {
  currentPage: number;
  totalPage: number;
  currentPerPage: number;
  perPageList: number[];
  onPageChangeHandler: (nextPage: number) => void;
  onPerPageChangeHandler: (event: ChangeEvent<HTMLSelectElement>) => void;
}

export const PaginationComponent: FC<Props> = memo(({currentPage, totalPage, currentPerPage, perPageList, onPageChangeHandler, onPerPageChangeHandler}: Props) => (
  <Flex justifyContent="space-between" alignItems="center">
    <Box>
      <Text
        {...fontBaseStyle as BoxProps}
        fontStyle="noraml"
      >
        Page {currentPage} of {totalPage}
      </Text>
    </Box>
    <Paginator
      activeStyles={activeStyles}
      normalStyles={normalStyles}
      currentPage={currentPage}
      pagesQuantity={totalPage}
      onPageChange={onPageChangeHandler}
      separatorStyles={separatorStyles}
      innerLimit={PAGINATOR_INNER_LIMIT}
      outerLimit={PAGINATOR_OUTER_LIMIT}
    >
      <Container style={{ display: 'flex', gap: 10 }}>
        <Box>
          <Button onClick={() => onPageChangeHandler(1)} {...naviBtnStyle} isDisabled={currentPage === 1}>
            <BiChevronsLeft style={{ transform: 'scale(1.5)' }} />
          </Button>
        </Box>
        <Box>
          <Previous {...naviBtnStyle}>
            <BiChevronLeft style={{ transform: 'scale(1.5)' }} />
          </Previous>
        </Box>
        <PageGroup isInline align="center" />
        <Box>
          <Next {...naviBtnStyle}>
            <BiChevronRight style={{ transform: 'scale(1.5)' }} />
          </Next>
        </Box>
        <Box>
          <Button onClick={() => onPageChangeHandler(totalPage)} {...naviBtnStyle} isDisabled={currentPage === totalPage}>
            <BiChevronsRight style={{ transform: 'scale(1.5)' }} />
          </Button>
        </Box>
      </Container>
    </Paginator>
    <Box>
      <Select
        bgColor={Colors.WHITE}
        value={currentPerPage}
        onChange={onPerPageChangeHandler}
      >
        {perPageList.map((perPage: number) => (
          <option key={`${perPage}`} value={perPage}>
            {perPage}件
          </option>
        ))}
      </Select>
    </Box>
  </Flex>
));
