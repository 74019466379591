import { request } from 'admin/api/Request';
import { CustomError } from 'admin/error/CustomError';
import { messages } from 'api/transactionMail/messages';
import { FirstLoginMailSettingType } from 'api/transactionMail/type';
import { Options } from 'ky';
import { FirstLoginMailSettingSchema } from 'schema/transactionMail/index';
import { ZodError } from 'zod';

export const getMailSetting = async (
  tenantId: string,
  options?: Options,
): Promise<FirstLoginMailSettingType> => {
  const path = `tenant/${tenantId}/transaction_mail_commons/`;

  const response = await request<FirstLoginMailSettingType>({
    path,
    method: 'get',
  });

  if (response.hasError) {
    if (response.status === 404) {
      throw new CustomError(messages.getMailSetting.customer.notFound, {
        path,
        options,
        status: response.status,
      });
    } else {
      throw new CustomError(messages.getMailSetting.customer.error, {
        path,
        options,
        status: response.status,
      });
    }
  }
  try {
    FirstLoginMailSettingSchema.parse(response.data);
  } catch (error) {
    if (error instanceof ZodError) {
      throw new CustomError(error.message, {
        path,
        options,
        status: response.status,
        logLevel: 'error',
      });
    }
  }

  return response.data;
};
