import { getIdPoolConsumerAttributeDetail } from 'admin/api/userPool/attribute/getIdPoolConsumerAttributeDetail';
import { CustomError } from 'admin/error/CustomError';
import { IdPoolConsumerAttributeType } from 'admin/types/userPool/attribute';
import { logger } from 'api/logger';
import { useQuery } from 'react-query';
import { queryIdPoolConsumerAttributeKey } from './queryIdPoolConsumerAttributeKey';

export const useIdPoolConsumerAttributeDetail = (
  id: IdPoolConsumerAttributeType['id'],
  userPoolId: string,
) => {
  const { data } = useQuery({
    queryKey: queryIdPoolConsumerAttributeKey.getIdPoolConsumerAttributesDetail(
      id,
      userPoolId,
    ),
    queryFn: () => getIdPoolConsumerAttributeDetail(id, userPoolId),
    onError: async (error) => {
      if (error instanceof CustomError) {
        await logger({
          loglevel: error.logLevel,
          data: JSON.stringify(error.cause),
          message: error.message,
        });
      }
    },
  });

  return { data };
};
