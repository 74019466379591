import { useUserPool } from 'admin/hooks/userPool/useUserPool';
import { AUTH_CODE_TYPE_UNIIKEY } from 'define';
import { useMemo } from 'react';
import { useParams } from 'react-router-dom';

export const useIdPoolConsumerUniikey = (): {
  isIdPoolConsumerUniikey: boolean;
} => {
  const params = useParams();
  const { data: idPoolConsumer } = useUserPool(params.idPoolConsumerId ?? '');

  const isIdPoolConsumerUniikey = useMemo(
    () => idPoolConsumer?.authenticationType === AUTH_CODE_TYPE_UNIIKEY,
    [idPoolConsumer],
  );

  return {
    isIdPoolConsumerUniikey,
  };
};
