import ErrorBoundary from 'api/ErrorBoundary';
import { LoadingSkeleton } from 'components/common/atoms';
import { ListError } from 'components/common/molecules';
import { Frame } from 'components/paywall/pages/Dashboard/Frame';
import { Container } from 'components/paywall/templates/Container';
import { useErrorBoundaryReset } from 'hooks/useErrorBoundaryReset';
import { FC, memo, Suspense } from 'react';

export const Dashboard: FC = memo(() => {
  const { ebKey, onError } = useErrorBoundaryReset();

  return (
    <Container>
      <ErrorBoundary
        errorComponent={<ListError message="ダッシュボードを表示できません" />}
        onError={onError}
        key={ebKey.current}
      >
        <Suspense fallback={<LoadingSkeleton width="100%" />}>
          <Frame />
        </Suspense>
      </ErrorBoundary>
    </Container>
  );
});
