import { VFC } from 'react';
import { FormControl, FormLabel } from '@chakra-ui/react';
import { useUserTenantId } from 'hooks/user/useUserTenantId';
import { isTblExits } from 'api/contents/isTblExits';
import { isIsTblExits, ContentsDbTblFormType } from 'api/contents/types';
import { InputTextForm } from 'components/common/atoms';

type InputTableNameProps = {
  value?: string;
  readonly?: boolean;
};

export const InputTableName: VFC<InputTableNameProps> = ({
  value = '',
  readonly = false,
}) => {
  const tenantId = useUserTenantId();

  const tblNameAttr = {
    required: 'テーブル名を入力してください',
    minLength: {
      value: 3,
      message: '3文字以上で入力してください',
    },
    maxLength: {
      value: 32,
      message: '32文字以下で入力してください',
    },
    validate: {
      startsWith: (val: string) =>
        /^[a-z]/.test(val) || '1文字目は小文字の半角英字のみ入力可能です',
      endWith: (val: string) =>
        /[a-z0-9]$/.test(val) ||
        '最後の文字は小文字の半角英数字のみ入力可能です',
      pattern: (val: string) =>
        /^[a-z0-9_]+$/.test(val) ||
        '小文字の半角英数字とアンダーバー(_)のみ入力可能です',
      isExists: async (val: string) => {
        // readonly: trueの時はテーブル存在チェックを行わない
        if (readonly) return true;
        const response = await isTblExits(val, tenantId);
        if (isIsTblExits(response)) {
          if (response.result) {
            return 'その名前は既に登録されており利用できません';
          }

          return true;
        }

        // エラー発生時
        return response.message;
      },
    },
  };

  return (
    <FormControl>
      <FormLabel htmlFor="name">テーブル名</FormLabel>
      <InputTextForm<ContentsDbTblFormType>
        label="テーブル名"
        name="name"
        value={value}
        readOnly={readonly}
        attr={tblNameAttr}
        labelCaption="(半角英数字)"
        placeholder="tbl_items"
      />
    </FormControl>
  );
};
