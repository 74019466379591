import {
  AlertDialog,
  Box,
  Button,
  Drawer,
  useDisclosure,
} from '@chakra-ui/react';
import { withSuspenseAndErrorBoundary } from 'admin/components/Error/WithErrorBoundary';
import { UsersDeleteDialog } from 'admin/components/users/usersDeleteDialog';
import { UsersEditDrawer } from 'admin/components/users/usersEditDrawer';
import { UsersListComponent } from 'admin/components/users/usersList/usersListComponent';
import { Colors } from 'admin/define/colors';
import { useUserCorporationInfo } from 'admin/hooks/user/useUserCorporationInfo';
import { useUsers } from 'admin/hooks/users/useUsers';
import { UserDeleteStateType } from 'admin/types/user';
import { ErrorContents } from 'components/common/atoms';
import { useUserInfo } from 'hooks/useUserInfo';
import {
  FC,
  memo,
  useCallback,
  useEffect,
  useMemo,
  useRef,
  useState,
} from 'react';

type Props = {
  searchKeyword: string;
};

const FetchUsersList: FC<Props> = memo(({ searchKeyword }) => {
  const {
    isOpen: isOpenEdit,
    onOpen: onOpenEdit,
    onClose: onCloseEdit,
  } = useDisclosure();
  const {
    isOpen: isOpenDelete,
    onOpen: onOpenDelete,
    onClose: onCloseDelete,
  } = useDisclosure();
  const cancelRef = useRef(null);
  const [detailUserId, setDetailuserId] = useState<string>('');
  const { data: users } = useUsers();
  const [isAllChecked, setAllChecked] = useState(false);
  const [userStateList, setUserStateList] = useState<UserDeleteStateType[]>([]);
  const { isMine } = useUserInfo();
  const { hasPermissionUser } = useUserCorporationInfo();

  // TODO: 検索条件を確認する
  const userList = useMemo(() => {
    if (!users) return [];

    // 検索条件は ユーザー名、メールアドレス
    return users.filter(
      (user) =>
        user.name.indexOf(searchKeyword) !== -1 ||
        user.email.indexOf(searchKeyword) !== -1,
    );
  }, [users, searchKeyword]);

  useEffect(() => {
    if (userList) {
      const tmpUserStateList = userList.map((user) => ({
        ...user,
        state: false,
      }));
      setUserStateList(tmpUserStateList);
    }
  }, [userList]);

  const onOpenEditModal = (id: string) => {
    setDetailuserId(id);
    onOpenEdit();
  };

  const onOpenDeleteModal = () => {
    onOpenDelete();
  };

  // 個別チェック
  const setTargetCheck = useCallback(
    (id: string): void => {
      const targetUsers = userStateList.filter((item) => !isMine(item.id));

      const changedCheckItem: UserDeleteStateType[] = targetUsers.map(
        (item) => ({
          ...item,
          state: item.id === id ? !item.state : item.state,
        }),
      );

      setUserStateList(changedCheckItem);

      const allChecked = changedCheckItem.every(
        (val) => !isMine(val.id) && val.state,
      );
      setAllChecked(allChecked);
    },
    [userStateList, isMine],
  );

  // 全チェック
  const setAllCheck = useCallback((): void => {
    const changedCheckItem: UserDeleteStateType[] = userStateList.map(
      (item) => ({
        ...item,
        state: !isAllChecked,
      }),
    );

    setUserStateList(changedCheckItem);
    setAllChecked(!isAllChecked);
  }, [userStateList, isAllChecked]);

  // ユーザー一覧の削除対象フィルター処理
  const filterDelTargetUsers = useCallback(
    () =>
      userStateList.filter(
        (item) =>
          item.state &&
          userList?.some((user) => !isMine(user.id) && user.id === item.id),
      ),
    [userStateList, userList],
  );

  const canDeleted = useMemo(
    () => filterDelTargetUsers().length > 0,
    [filterDelTargetUsers],
  );

  if (!userList) {
    return null;
  }

  return (
    <Box>
      {hasPermissionUser && (
        <Box mb="16px">
          <Button
            variant="outline"
            // カラー定義が決まったら修正する想定
            color={Colors.RED_20}
            borderColor={Colors.RED_20}
            bgColor={Colors.WHITE}
            _hover={{ bg: '#FFF5F5' }}
            _active={{ bg: '#FED7D7' }}
            onClick={onOpenDeleteModal}
            disabled={!canDeleted}
          >
            ユーザーを削除
          </Button>
        </Box>
      )}
      {/* ユーザー一覧 */}
      <UsersListComponent
        userList={userList}
        isAllChecked={isAllChecked}
        userStateList={userStateList}
        onOpen={onOpenEditModal}
        setAllCheck={setAllCheck}
        setTargetCheck={setTargetCheck}
      />
      {/* ユーザー編集ドロワー */}
      <Drawer
        isOpen={isOpenEdit}
        placement="right"
        size="sm"
        onClose={onCloseEdit}
        closeOnOverlayClick={false}
        closeOnEsc={false}
        autoFocus={false}
      >
        <UsersEditDrawer onClose={onCloseEdit} userId={detailUserId} />
      </Drawer>
      {/* ユーザー削除ダイアログ */}
      <AlertDialog
        isOpen={isOpenDelete}
        onClose={onCloseDelete}
        leastDestructiveRef={cancelRef}
        isCentered
        closeOnOverlayClick={false}
        closeOnEsc={false}
        size="xl"
      >
        <UsersDeleteDialog
          onClose={onCloseDelete}
          deleteList={filterDelTargetUsers()}
        />
      </AlertDialog>
    </Box>
  );
});

export const UsersList = withSuspenseAndErrorBoundary(FetchUsersList, {
  ErrorComponent: <ErrorContents name="組織アカウント一覧情報" />,
});
