import { Box, Text } from '@chakra-ui/react';
import { FC, memo } from 'react';

type Props = {
  text: string;
}

export const DetailMemoText: FC<Props> = memo(({text}) => (
  <Box w="100%" textAlign="left">
    <Text>
      {text}
    </Text>
  </Box>
));
