import { postWallItem } from 'api/paywall/postWallItem';
import { ResponseError } from 'api/types';
import { WallItemDefaultValues } from 'components/paywall/pages/WallItemList/initial';
import {
  isWallItem, WallItem, WallItemFormType, WallItemSubmitFormType
} from 'components/paywall/pages/WallItemList/typed';
import { usePaywallUtil } from 'hooks/paywall/usePaywallUtil';
import { useCustomToast } from 'hooks/useCustomToast';
import { UseFormSetError } from 'react-hook-form';
import { UseMutateFunction, useMutation } from 'react-query';
import { isGetValidationError, toMultiError } from 'utils/form';

type UnPromisify<T> = T extends Promise<infer U> ? U : T;

/**
 * 単品商品作成 hooks
 * @param {UseFormSetError<WallItemFormType>} setError
 * @param {Function} close
 * @param {Function} reset
 * @returns {Object} { onSubmit, isLoading }
 */
export const useAddWallItem = ({
  setError,
  close,
  reset,
}: {
  setError?: UseFormSetError<WallItemFormType>;
  close?: () => void;
  reset?: () => void;
}): {
  onSubmit: UseMutateFunction<
    UnPromisify<ReturnType<typeof postWallItem>>,
    unknown,
    WallItemSubmitFormType,
    unknown
  >;
  isLoading: boolean;
  isSuccess: boolean;
} => {
  const toast = useCustomToast();
  const { paywallWallItemListRefetchQueries, paywallListRefetchQueries } = usePaywallUtil();

  const {
    mutate: onSubmit,
    isLoading,
    isSuccess,
  } = useMutation(
    ({ tenantId, wallId, wallItemForm }: WallItemSubmitFormType) =>
      postWallItem({ tenantId, wallId, wallItemForm }),
    {
      onSuccess: (result: WallItem | boolean | ResponseError) => {
        paywallWallItemListRefetchQueries();
        // 画面作成した際、サブスクリプション一覧or単品商品一覧のボタンのエラーを更新するため、リストをrefetchする
        paywallListRefetchQueries();
        if (isWallItem(result)) {
          close?.();
          reset?.();
          toast({
            status: 'success',
            position: 'bottom',
            duration: 4000,
            isClosable: true,
            title: '単品商品を作成しました',
          });
        }

        if (
          isGetValidationError<WallItemFormType>(
            result,
            Object.keys(WallItemDefaultValues),
          )
        ) {
          if (result?.others) {
            const errorMsg = Object.entries(result).map(([_, value]) => value);
            toast({
              status: 'error',
              position: 'bottom',
              duration: 4000,
              isClosable: true,
              description: errorMsg[0],
            });
          } else {
            Object.keys(result).forEach((k) => {
              const key = k as keyof WallItemFormType;
              const errMsgs = result?.[key];
              if (errMsgs) {
                setError?.(key, { types: toMultiError(errMsgs) });
              }
            });
          }
        }
      },
    },
  );

  return { onSubmit, isLoading, isSuccess };
};
