import { useRouteAuth } from 'auth/authContext';
import ErrorModal from 'components/common/atoms/ErrorModal';
import { LoadingSpinner } from 'components/common/atoms/LoadingSpinner';
import { CommonDialog } from 'components/paywall/features/CommonDialog';
import { useCommonDialog } from 'components/paywall/hooks/contexts/CommonDialog';
import { ErrorModalProvider } from 'context/ErrorModalContext';
import { FC, Suspense } from 'react';
import { Router } from 'router';

const App: FC = () => {
  const { dialogProps, closeDialog } = useCommonDialog();
  const { duringAuthentication } = useRouteAuth();

  // 認証処理中にRouter処理が走らないよう対応を行う
  if (duringAuthentication) {
    // 認証処理中はローディング画面を表示
    return <LoadingSpinner />;
  }

  return (
    <ErrorModalProvider>
      <Suspense fallback={<LoadingSpinner />}>
        <CommonDialog {...dialogProps} onClose={closeDialog} />
        <Router />
        <ErrorModal />
      </Suspense>
    </ErrorModalProvider>
  );
};

export default App;
