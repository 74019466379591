import {
  Button,
  FormControl,
  FormLabel,
  FormHelperText,
  FormErrorMessage,
  Icon,
  InputGroup,
  InputLeftElement,
  ListItem,
  Text,
  UnorderedList,
} from '@chakra-ui/react';
import { useCustomToast } from 'hooks/useCustomToast';
import { memo, useRef, useState, Ref } from 'react';
import { errorToast, successToast } from 'utils/toast';
import { BiCloudUpload } from 'react-icons/bi';
import { ChangeHandler, RegisterOptions, FieldErrors } from 'react-hook-form';
import { FormType } from 'admin/types/authHub';

export const ImageUploader = memo(
  ({
    name,
    label,
    register,
    errors,
    doUpload,
  }: {
    name: string;
    label: string;
    register: (
      _name: string,
      options?: RegisterOptions,
    ) => {
      onChange: ChangeHandler;
      onBlur: ChangeHandler;
      name: string;
      /* eslint-disable-next-line */
      ref: Ref<any>;
    };
    errors: FieldErrors<FormType>;
    doUpload: (file: File) => Promise<string>;
  }) => {
    const [isUploading, setIsUploading] = useState(false);
    const fileInput = useRef<HTMLInputElement | null>(null);
    const toast = useCustomToast();
    const path = name.split('.');
    const error: FieldErrors<FormType> = errors[path[0]];
    const onUpload = async () => {
      setIsUploading(true);
      const currentFile = fileInput.current;
      try {
        if (currentFile?.files && currentFile?.files[0]) {
          const url = await doUpload(currentFile.files[0]);
          if (url) {
            toast({
              ...successToast,
              title: 'ファイルをアップロードしました。',
            });

            return;
          }
        }
      } catch {
        // ignored
      } finally {
        setIsUploading(false);
      }

      toast({
        ...errorToast,
        title: 'ファイルアップロードに失敗しました。',
      });
    };

    return (
      <>
        <FormControl isInvalid={!!(error && error[path[1]])}>
          <FormLabel mb="0">
            <Text as="b" color="teal.800">
              {label}
            </Text>
          </FormLabel>
          <InputGroup
            display="grid"
            gridTemplateColumns="auto 1fr"
            gridTemplateRows="1fr"
          >
            <InputLeftElement pointerEvents="none" mt="-1">
              <Icon as={BiCloudUpload} color="blue" />
            </InputLeftElement>
            <input
              {...register(name)}
              onChange={onUpload}
              type="file"
              accept="image/*"
              ref={fileInput}
              disabled={isUploading}
              style={{ display: 'none' }}
            />
            <Button
              pl={8}
              py={4}
              isLoading={isUploading}
              bg="white"
              colorScheme="blue"
              variant="outline"
              onClick={() => {
                if (fileInput?.current) {
                  fileInput.current.value = '';

                  return fileInput.current.click();
                }

                return undefined;
              }}
            >
              画像のアップロード
            </Button>
          </InputGroup>
          <FormHelperText>
            <UnorderedList>
              <ListItem>対応形式 : JPG、PNG</ListItem>
              <ListItem>上限容量 : 512KB</ListItem>
              <ListItem>
                推奨サイズ : 横幅640px、縦幅は任意、容量は50KB以内
              </ListItem>
            </UnorderedList>
          </FormHelperText>
          <FormErrorMessage>
            {error && error[path[1]] && error[path[1]]?.message}
          </FormErrorMessage>
        </FormControl>
      </>
    );
  },
);
