import {
  Box,
  DrawerBody,
  FormControl,
  FormErrorMessage,
  Heading,
  VStack
} from '@chakra-ui/react';
import { RhfInput } from 'admin/components/form/RhfInput';
import { RequiredIcon } from 'admin/components/ui/icon/requiredIcon';
import { LoadingLayer } from 'admin/components/ui/loadingLayer';
import { Colors } from 'admin/define/colors';
import { EditCodeGroupFormType } from 'admin/types/userPool/master/form';
import { ChangeEvent, FC, memo } from 'react';
import { useFormContext, useFormState } from 'react-hook-form';
import { toErrMsgList } from 'utils/form';

type Props = {
  formId: string;
  onSubmit: (value: EditCodeGroupFormType) => void;
  isLoading: boolean;
};
export const MasterEditFormComponent: FC<Props> = memo(
  ({ onSubmit, formId, isLoading }) => {
    const { handleSubmit, getValues, clearErrors } =
      useFormContext<EditCodeGroupFormType>();
    const { errors } = useFormState<EditCodeGroupFormType>();

    const handleOnBlur = (e: ChangeEvent<HTMLInputElement>) => {
      if (e.target.value !== '') {
        clearErrors(['displayNameJa', 'displayNameForeign']);
      }
    };

    return (
      <>
        {isLoading && <LoadingLayer />}
        <DrawerBody pt="16px" pb="16px">
          <form onSubmit={handleSubmit(onSubmit)} id={formId}>
            <VStack spacing={6}>
              <Box w="100%">
                <Heading as="h3" size="sm" mb={2}>
                  選択肢マスタID
                </Heading>
                <Box>{getValues('id')}</Box>
              </Box>
              <Box w="100%">
                <Heading as="h3" fontSize="14px" mb={2}>
                  <Box display="flex" alignItems="bottom">
                    表示名
                  </Box>
                </Heading>
                <Box bg="#F7FAFC" p="16px" borderWidth="1px" borderRadius="4px">
                  <Box w="100%">
                    <Heading as="h3" display="flex" fontSize="14px" mb={2}>
                      日本語
                      <RequiredIcon ml="8px" />
                    </Heading>
                    <Box>
                      <FormControl
                        isInvalid={
                          Boolean(errors.displayNameJa?.message) ||
                          toErrMsgList(errors, 'displayNameJa').length > 0
                        }
                      >
                        <Box>
                          <RhfInput<EditCodeGroupFormType>
                            name="displayNameJa"
                            bgColor={Colors.WHITE}
                            placeholder="例: 都道府県"
                            onBlur={handleOnBlur}
                          />
                        </Box>
                        {toErrMsgList(errors, 'displayNameJa').map((err) => (
                          <FormErrorMessage key={`displayNameJa${err}`}>
                            {err}
                          </FormErrorMessage>
                        ))}
                      </FormControl>
                    </Box>
                  </Box>
                  <Box w="100%" mt={4}>
                    <Heading as="h3" fontSize="14px" mb={2}>
                      外国語
                    </Heading>
                    <Box>
                      <FormControl
                        isInvalid={
                          Boolean(errors.displayNameForeign?.message) ||
                          toErrMsgList(errors, 'displayNameForeign').length > 0
                        }
                      >
                        <Box>
                          <RhfInput<EditCodeGroupFormType>
                            name="displayNameForeign"
                            bgColor={Colors.WHITE}
                            placeholder="例: Prefectures"
                            onBlur={handleOnBlur}
                          />
                        </Box>
                        {toErrMsgList(errors, 'displayNameForeign').map(
                          (err) => (
                            <FormErrorMessage key={`displayNameForeign${err}`}>
                              {err}
                            </FormErrorMessage>
                          ),
                        )}
                      </FormControl>
                    </Box>
                  </Box>
                </Box>
              </Box>
            </VStack>
          </form>
        </DrawerBody>
      </>
    );
  },
);
