import { request } from 'admin/api/Request';
import { CustomError } from 'admin/error/CustomError';
import { serviceAttributeFormSchema } from 'admin/schema/service/attribute';
import { ServiceAttributeChildType } from 'admin/types/service/attribute';
import { ServiceAttributeFormType } from 'admin/types/service/attribute/form';
import { Options } from 'ky';
import { ZodError } from 'zod';

export const changeServiceAttributeStatus = async ({
  userPoolChildId,
  userPoolChildAttrId,
  options,
  data,
}: {
  userPoolChildId: string;
  userPoolChildAttrId: ServiceAttributeChildType['id'];
  options?: Options;
  data: ServiceAttributeFormType;
}): Promise<ServiceAttributeFormType> => {
  const params = data;
  const path = `uniikey/${userPoolChildId}/child/attribute/${userPoolChildAttrId}/`;

  const response = await request<ServiceAttributeFormType>({
    path,
    method: 'patch',
    body: params,
    options,
  });
  if (response.hasError) {
    throw new CustomError('error', {
      path,
      params,
      status: response.status,
    });
  }
  try {
    serviceAttributeFormSchema.parse(response.data);
  } catch (error) {
    if (error instanceof ZodError) {
      throw new CustomError(error.message, {
        path,
        params,
        status: response.status,
        logLevel: 'error',
      });
    }
  }

  return response.data;
};
