import { Box, Checkbox, Flex, Input, Text, Textarea } from '@chakra-ui/react';
import { FC } from 'react';
import { FieldValues, Path, useFormContext } from 'react-hook-form';


type Props<T> = {
  baseName: Path<T>;
  index: number;
};

export const CondElement = <T extends FieldValues>({ baseName, index }: Props<T>): ReturnType<FC> => {
  const { getValues, register } = useFormContext<T>();

  return (
    <Flex
      w="100%"
      alignItems="center"
      paddingTop={2}
      paddingBottom={4}
      marginTop="8px !important"
      borderBottom="1px solid #edf2f6"
    >
      <Box w="18%" textAlign="left" paddingLeft={7} paddingRight={5}>
        <Checkbox
          {...register(`${baseName}.${index}.isCondFlg` as Path<T>)}
          isDisabled={getValues('uneditableState' as Path<T>)}
        >
          <Text fontSize={14}>表示条件指定</Text>
        </Checkbox>
        <Text fontSize={10} marginTop={2}>
          有効にすると、特定の回答をしたユーザーにのみこの設問を表示します。
        </Text>
      </Box>
      <Box w="41%" paddingRight={6}>
        <Text textAlign="left" paddingBottom={2}>
          表示条件の設問タイトル
        </Text>
        <Input
          w="100%"
          {...register(`${baseName}.${index}.condTitle` as Path<T>)}
          placeholder="表示条件の設問タイトルを入力してください"
          backgroundColor="#f9f9f9"
          borderColor="#ccc"
        />
      </Box>
      <Box w="41%" paddingLeft={6} paddingRight={6}>
        <Text textAlign="left" paddingBottom={2}>
          対象の選択肢
        </Text>
        <Textarea
          w="100%"
          {...register(`${baseName}.${index}.condItems` as Path<T>)}
          placeholder="対象の選択肢を入力してください"
          backgroundColor="#f9f9f9"
          borderColor="#ccc"
          rows={3}
        />
      </Box>
    </Flex>
  );
};
