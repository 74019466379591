import { CustomerDetailMemo, isCustomerDetailMemos } from 'components/paywall/pages/CustomerList/typed';

export type SubscriptionDetailOrderGmo = {
  id: string;
  execTranDate: string;
  price: number;
  tranErrorInfo: string;
  tranErrorMessageCustomer: string;
  tranStatus: number;
  cardErrorInfo: CardErrorInfo[];
  createdAt: string;
};

export type SubscriptionDetailOrder = {
  id: string;
  courseId: string;
  courseName: string;
  courseStart: string;
  courseEnd: string;
  tranErrorInfo: string;
  tranErrorMessageCustomer: string;
  tranId: string;
  execTranDate: string;
  price: number;
  nextTranDate: string;
  isNextTran: boolean;
  nextPrice: number;
  nextPromotionName: string;
  rightsExpirationDate: string;
  cancelReason: number;
  status: number;
  cardErrorInfo: CardErrorInfo[];
};

export type Customer = {
  address: string;
  lastName: string;
  firstName: string;
  prefName: string;
  tel: string;
  zip: string;
  isActive: boolean;
}

export type SubscriptionDetail = {
  customer: Customer;
  customerUid: string;
  email: string;
  orderGmo: SubscriptionDetailOrderGmo[];
  order: SubscriptionDetailOrder;
  memos: CustomerDetailMemo[];
};

// これは削除する（データ型チェックができていないため）
export const isSubscriptionDetail = (
  data: unknown,
): data is SubscriptionDetail => {
  const el = data as SubscriptionDetail;

  return (
    typeof el?.customerUid === 'string' &&
    typeof el?.email === 'string' &&
    typeof el?.orderGmo === 'string' &&
    typeof el?.order === 'object'
  );
};

export type ApiSubscriptionDetail = {
  data: SubscriptionDetail;
  error: number;
  errorMessage: string;
  hasError: boolean;
  responseType: string;
  status: number;
  url: string;
};

export type SubscriptionListResult = {
  id: string;
  customerUid: string;
  courseId: string;
  courseName: string;
  courseStart: string;
  courseEnd: string;
  cancelReason: number;
  tranErrorCode: string;
  status: number;
  email: string;
  cardErrorInfo: CardErrorInfo[];
};

export type SubscriptionList = {
  count: number;
  currentPage: number;
  countFrom: number;
  countTo: number;
  perPage: number;
  pageCount: number;
  next: string;
  previous: string;
  results: SubscriptionListResult[];
};

type CardErrorInfo = {
  code: string;
  message: string;
}

export const isSubscriptionList = (data: unknown): data is SubscriptionList => {
  const el = data as SubscriptionList;

  return (
    typeof el?.count === 'number' &&
    typeof el?.currentPage === 'number' &&
    typeof el?.countTo === 'number' &&
    typeof el?.perPage === 'number' &&
    typeof el?.pageCount === 'number' &&
    typeof el?.next === 'string' &&
    typeof el?.previous === 'string' &&
    typeof el?.results === 'object'
  );
};

export type ApiSubscriptionList = {
  data: SubscriptionList;
  error: number;
  errorMessage: string;
  hasError: boolean;
  responseType: string;
  status: number;
  url: string;
};

export const isSubscriptionDetailOrderGmo = (data: unknown): data is SubscriptionDetailOrderGmo => {
  const el = data as SubscriptionDetailOrderGmo;

  return (
    typeof el?.id === 'string' &&
    typeof el?.execTranDate === 'string' &&
    typeof el?.price === 'number' &&
    typeof el?.tranErrorInfo === 'string' &&
    typeof el?.tranErrorMessageCustomer === 'string'
  )
}

export const isSubscriptionDetailOrderGmos = (datas: unknown[]): datas is SubscriptionDetailOrderGmo[] =>
  !datas.some((d) => !isSubscriptionDetailOrderGmo(d));

export const isSubscriptionDetailOrder = (data: unknown): data is SubscriptionDetailOrder => {
  const el = data as SubscriptionDetailOrder;

  return (
    typeof el?.id === 'string' &&
    typeof el?.courseId === 'string' &&
    typeof el?.courseName === 'string' &&
    typeof el?.tranErrorInfo === 'string' &&
    typeof el?.tranErrorMessageCustomer === 'string' &&
    typeof el?.tranId === 'string' &&
    typeof el?.execTranDate === 'string' &&
    typeof el?.price === 'number' &&
    typeof el?.nextTranDate === 'string' &&
    typeof el?.isNextTran === 'boolean' &&
    typeof el?.nextPrice === 'number' &&
    typeof el?.nextPromotionName === 'number' &&
    typeof el?.rightsExpirationDate === 'string' &&
    typeof el?.cancelReason === 'string'
  );
};


// こちらの型ガードを残す
export const isSubscriptionDetailType = (data: unknown): data is SubscriptionDetail => {
  const el = data as SubscriptionDetail;

  return (
    typeof el?.customerUid === 'string' &&
    typeof el?.email === 'string' &&
    isSubscriptionDetailOrderGmos(el?.orderGmo) &&
    isSubscriptionDetailOrder(el?.order) &&
    isCustomerDetailMemos(el?.memos)
  );
};
