import { BoxProps, Tr as ChakraTr } from '@chakra-ui/react';
import { Colors } from 'admin/define/colors';
import { FC, memo, ReactNode } from 'react';

type Props = {
  children: ReactNode;
} & BoxProps;

export const Tr: FC<Props> = memo(({ children, ...rest }: Props) => (
  <ChakraTr
    px="16px"
    backgroundColor={Colors.WHITE}
    _hover={{
      backgroundColor: Colors.GRAY_80,
    }}
    {...rest}
  >
    {children}
  </ChakraTr>
));
