import { useIdPoolConsumers } from 'admin/hooks/idPoolConsumers/useIdPoolConsumers';
import { useUserCorporationInfo } from 'admin/hooks/user/useUserCorporationInfo';
import { AUTH_CODE_TYPE_UNIIKEY } from 'define';

export const useMemberIdPoolConsumers = () => {
  const { data } = useIdPoolConsumers();
  const { currentCorporation } = useUserCorporationInfo();

  const validCorporationIds = currentCorporation?.userPools
    .filter(
      (x) =>
        x.authenticationType === AUTH_CODE_TYPE_UNIIKEY &&
        x.permission.includes('member'),
    )
    .map((x) => x.userPoolId);

  const permissionEnabledData = data.filter(
    (x) =>
      x.authenticationType === AUTH_CODE_TYPE_UNIIKEY &&
      validCorporationIds?.includes(x.id),
  );

  return permissionEnabledData;
};
