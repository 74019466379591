/* eslint-disable @typescript-eslint/no-unsafe-assignment */

type Merge<T, U> = T extends Record<string, unknown>
  ? U extends Record<string, unknown>
    ? {
        [K in keyof (T & U)]: K extends keyof U
          ? K extends keyof T
            ? Merge<T[K], U[K]>
            : U[K]
          : K extends keyof T
          ? T[K]
          : never;
      }
    : U
  : U;

type NonPrimitive = Exclude<unknown, null | undefined | boolean | number | string | symbol | bigint>;

type ArrayElementType<T> = T extends (infer U)[] ? U : never;

const isObject = <T extends NonPrimitive>(item: T): item is Exclude<T, null | undefined> =>
  typeof item === 'object' && item !== null;

/**
 * deep merge関数
 * lodash.mergeを使用していたものをmerge関数に置き換える
 */
export const deepMerge = <T extends Record<string, unknown>, U extends Record<string, unknown>>(
  target: T,
  ...sources: U[]
): Merge<T, U> => {
  if (!sources.length) {
    return target as Merge<T, U>;
  }

  const merged = { ...target };

  sources.forEach((source) => {
    if (isObject(target) && isObject(source)) {
      const sourceKeys = Object.keys(source) as Array<keyof U & keyof T>;

      sourceKeys.forEach((sourceKey) => {
        const targetValue = target[sourceKey];
        const sourceValue = source[sourceKey];

        if (Array.isArray(targetValue) && Array.isArray(sourceValue)) {
          merged[sourceKey] = [
            ...(targetValue as Array<ArrayElementType<typeof targetValue>>),
            ...(sourceValue as Array<ArrayElementType<typeof sourceValue>>)
          ] as any;
        } else if (isObject(targetValue) && isObject(sourceValue)) {
          merged[sourceKey] = deepMerge(targetValue as any, sourceValue as any);
        } else if (Array.isArray(sourceValue)) {
          merged[sourceKey] = [...sourceValue] as any;
        } else if (isObject(sourceValue)) {
          merged[sourceKey] = { ...(targetValue as any), ...(sourceValue as any) };
        } else {
          merged[sourceKey] = sourceValue as any;
        }
      });
    }
  });

  return merged as Merge<T, U>;
};
