import { Flex, Text, VStack } from '@chakra-ui/react';
import { Colors } from 'admin/define/colors';
import { ServiceType } from 'admin/types/service';
import { FC, memo } from 'react';

type Props = {
  service: ServiceType;
};

export const ServiceInfoComponent: FC<Props> = memo(({ service }: Props) => (
  <VStack
    mt="8px"
    mb="24px"
    p={4}
    backgroundColor={Colors.GRAY_70}
    borderRadius="8px"
    alignItems="flex-start"
  >
    <Flex>
      <Text minW="99px" mr="16px">
        テナントID
      </Text>
      <Text fontWeight="700">{service.id}</Text>
    </Flex>
    <Flex>
      <Text minW="99px" mr="16px">
        テナント名
      </Text>
      <Text fontWeight="700">{service.name}</Text>
    </Flex>
  </VStack>
));
