/**
 * ErrorModalContext.tsx
 *
 * このコンテキストは、アプリケーションのグローバルなエラーハンドリング機能。
 * エラーモーダルの表示状態とエラーメッセージの状態管理を含む。
 * また、APIリクエスト中に403エラーが発生したときにエラーモーダルを表示するための
 * コールバック関数を showErrorModalHelper を使って設定。
 */

import { setShowErrorModal } from 'helper/showErrorModalHelper';
import {
  createContext,
  FC,
  ReactNode,
  useContext,
  useEffect,
  useState,
} from 'react';

interface ErrorModalContextType {
  showErrorModal: (title: string, message: string) => void;
  hideErrorModal: () => void;
  isErrorModalVisible: boolean;
  errorModalTitle: string;
  errorModalMessage: string;
}

const ErrorModalContext = createContext<ErrorModalContextType | undefined>(
  undefined,
);

export const ErrorModalProvider: FC<{ children: ReactNode }> = ({
  children,
}) => {
  const [isErrorModalVisible, setModalVisible] = useState(false);
  const [errorModalTitle, setErrorModalTitle] = useState('');
  const [errorModalMessage, setErrorModalMessage] = useState('');

  const showErrorModal = (title: string, message: string) => {
    setErrorModalTitle(title);
    setErrorModalMessage(message);
    setModalVisible(true);
  };
  const hideErrorModal = () => {
    setModalVisible(false);
    setErrorModalTitle('');
    setErrorModalMessage('');
  };

  useEffect(() => {
    // モーダル表示用のコールバック関数を設定
    setShowErrorModal(showErrorModal);
  }, []);

  return (
    <ErrorModalContext.Provider
      value={{
        showErrorModal,
        hideErrorModal,
        isErrorModalVisible,
        errorModalTitle,
        errorModalMessage,
      }}
    >
      {children}
    </ErrorModalContext.Provider>
  );
};

export const useErrorModal = (): ErrorModalContextType => {
  const context = useContext(ErrorModalContext);
  if (!context) {
    throw new Error('useError must be used within an ErrorModalProvider');
  }

  return context;
};
