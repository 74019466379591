import produce from 'immer';
import { Options } from 'ky';
import { logger } from 'api/logger';
import { request } from 'api/request';
import { ResponseError, isResponseError } from 'api/types';
import { isContentsTable, ContentsDbTblFormType } from 'api/contents/types';
import { messages } from 'api/contents/messages';

const DEFAULT_ERR_MESSAGE = {
  other: messages.createContentsTable.customer.error,
};

/**
 * コンテンツシェルフテーブル登録API
 *
 * 状態により以下のレスポンスを返却します
 * 正常時: true
 * エラー: ResponseError
 *
 */
export const createContentsTable = async ({
  formData,
  tenantId,
  options,
}: {
  formData: ContentsDbTblFormType;
  tenantId: string;
  options?: Options;
}): Promise<ResponseError | boolean> => {
  const mergedOptions = options
    ? produce(options, (draft) => {
        draft.json = formData;
      })
    : { json: formData };

  const response = await request({
    path: `contentsdb/${tenantId}/`,
    method: 'post',
    options: mergedOptions,
  });

  const responseData = response.data;

  if (response.status >= 500) {
    return DEFAULT_ERR_MESSAGE;
  }

  if (response.hasError) {
    const { error } = response;
    // APIにて返却されたエラー形式
    if (isResponseError(error)) {
      return error;
    }

    // 予期せぬエラーなので
    // バックエンドに状態を通知
    await logger({
      loglevel:
        response.status === 404 || response.status === 450
          ? 'warning'
          : 'error',
      data: error,
      message: messages.createContentsTable.system.failed,
    });

    // 登録できていない旨を通知
    return DEFAULT_ERR_MESSAGE;
  }

  // 登録成功時
  if (!isContentsTable(responseData)) {
    // 登録は成功したものの
    // レスポンスの型ガードでエラー
    await logger({
      loglevel:
        response.status === 404 || response.status === 450
          ? 'warning'
          : 'error',
      data: responseData,
      message: messages.createContentsTable.system.typeError,
    });
  }

  return true;
};
