/* eslint-disable react/require-default-props */
import { Box, BoxProps, Flex, Text } from '@chakra-ui/react';
import { Colors } from 'components/paywall/config';
import { CopyIdWithMenu } from 'components/paywall/features/CopyIdWithMenu';
import { FC, memo } from 'react';

type Props = {
  children: React.ReactNode;
  id?: string;
  idName?: string;
  type: 'wall' | 'item';
  isIdCopy?: boolean;
} & BoxProps;

export const CardHeaderTitle: FC<Props> = memo(
  ({ children, id = '', idName, type, isIdCopy = true, ...rest }: Props) => {
    // 2023.11.1
    // IDコピーが必要なケースと不要なケースがあるため、
    // isIdCopy Propsを新設し、IDコピー機能表示の出し分けを行う
    const idElement = (): JSX.Element => {
      if (isIdCopy) {
        return (
          <>
            <Flex alignItems="center" fontSize="14px" color={Colors.GRAY_00}>
              <Text>{idName}</Text>
              <CopyIdWithMenu id={id} type={type} />
            </Flex>
          </>
        );
      }

      return <Text color="#555F6D">{id}</Text>;
    };

    return (
      <Box {...rest}>
        {children}
        {id !== '' && idElement()}
      </Box>
    );
  },
);
