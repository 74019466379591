import { zodResolver } from '@hookform/resolvers/zod';
import { withSuspenseAndErrorBoundary } from 'admin/components/Error/WithErrorBoundary';
import { StatusChangeFormComponent } from 'admin/components/idPoolConsumer/attributeGroup/attributeGroupForm/statusChangeForm/StatusChangeFormComponent';
import { LoadingLayer } from 'admin/components/ui/loadingLayer';
import { useIdPoolConsumerId } from 'admin/hooks/useIdPoolConsumerId';
import { useAttributeGroupStatusChange } from 'admin/hooks/userPool/attributeGroup/useChangeAttributeGroupStatus';
import { confirmSubmitFormSchema } from 'admin/schema/util';
import { AttributeGroupType } from 'admin/types/userPool/attributeGroup';
import { FC, memo, useCallback } from 'react';
import { FormProvider, useForm } from 'react-hook-form';

export type Props = {
  target: AttributeGroupType | undefined;
  onClose: () => void;
};

export const statusChangeForm: FC<Props> = memo(({ onClose, target }) => {
  const formId = 'statusChangeForm';
  const idPoolConsumerId = useIdPoolConsumerId();

  const { mutate, isLoading } = useAttributeGroupStatusChange({
    id: idPoolConsumerId,
    attributeGroupId: target?.id ?? '',
  });

  const methods = useForm({
    defaultValues: {
      word: '',
    },
    mode: 'onBlur',
    resolver: zodResolver(confirmSubmitFormSchema),
  });

  const onSubmit = useCallback(async () => {
    await mutate();
    onClose();
  }, [mutate, onClose]);

  return (
    <FormProvider {...methods}>
      {isLoading && <LoadingLayer />}
      <StatusChangeFormComponent
        formId={formId}
        isLoading={isLoading}
        onClose={onClose}
        onSubmit={onSubmit}
      />
    </FormProvider>
  );
});

export const StatusChangeForm = withSuspenseAndErrorBoundary(statusChangeForm);
