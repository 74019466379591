export const SECURITY_SETTING_AUTH_HUB = 'authHub';
export const SECURITY_SETTING_TOKEN = 'token';
export const SECURITY_SETTING_RECAPTCHA = 'recaptcha';

export const SECURITY_SETTING_TYPES = [
  SECURITY_SETTING_AUTH_HUB,
  SECURITY_SETTING_TOKEN,
  SECURITY_SETTING_RECAPTCHA,
] as const;

export const SECURITY_SETTING_LABEL_AUTH_HUB = 'Auth Hub';
export const SECURITY_SETTING_LABEL_TOKEN = 'トークン';
export const SECURITY_SETTING_LABEL_RECAPTCHA = 'reCAPTCHA';

export const SECURITY_SETTING_LABELS = [
  SECURITY_SETTING_LABEL_AUTH_HUB,
  SECURITY_SETTING_LABEL_TOKEN,
  SECURITY_SETTING_LABEL_RECAPTCHA,
] as const;

export const SECURITY_SETTING_TYPES_MAP = new Map<
  typeof SECURITY_SETTING_TYPES[number],
  typeof SECURITY_SETTING_LABELS[number]
>([
  [SECURITY_SETTING_AUTH_HUB, SECURITY_SETTING_LABEL_AUTH_HUB],
  [SECURITY_SETTING_TOKEN, SECURITY_SETTING_LABEL_TOKEN],
  [SECURITY_SETTING_RECAPTCHA, SECURITY_SETTING_LABEL_RECAPTCHA],
]);
