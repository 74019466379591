import { withSuspenseAndErrorBoundary } from 'admin/components/Error/WithErrorBoundary';
import { AuthTitleComponent } from 'admin/components/service/auth/authTitle/AuthTitleComponent';
import { useTenantName } from 'admin/hooks/service/useTenantName';
import { ErrorContents, LoadingSpinner } from 'components/common/atoms';
import { FC, memo } from 'react';

export const authTitle: FC = memo(() => {
  const tenantName = useTenantName();

  return <AuthTitleComponent label={tenantName} />;
});

export const AuthTitle = memo(
  withSuspenseAndErrorBoundary(authTitle, {
    ErrorComponent: <ErrorContents name="認証認可とセキュリティ設定タイトル" />,
    LoadingComponent: <LoadingSpinner />,
  }),
);
