import { request } from 'admin/api/Request';
import { API_MESSAGES } from 'admin/define/apiMessages';
import { CustomError } from 'admin/error/CustomError';
import { memberSchema } from 'admin/schema/member';
import { MemberType } from 'admin/types/member';
import { Options } from 'ky';
import { ZodError } from 'zod';

export const getMember = async ({
  userPoolId,
  memberId,
  options,
}: {
  userPoolId: MemberType['userPool'];
  memberId: MemberType['uid'];
  options?: Options;
}): Promise<MemberType> => {
  const path = `uniikey/${userPoolId}/users/${memberId}/`;
  const response = await request<MemberType>({
    path,
    method: 'get',
    options,
  });

  if (response.hasError) {
    if (response.status === 404) {
      throw new CustomError(API_MESSAGES.MEMBER.GET_MEMBER.NOT_FOUND, {
        path,
        status: response.status,
      });
    } else {
      throw new CustomError(API_MESSAGES.MEMBER.GET_MEMBER.ERROR, {
        path,
        status: response.status,
      });
    }
  }
  try {
    memberSchema.parse(response.data);
  } catch (error) {
    if (error instanceof ZodError) {
      throw new CustomError(error.message, {
        path,
        status: response.status,
        logLevel: 'error',
      });
    }
  }

  return response.data;
};
