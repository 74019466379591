import { VFC, ChangeEvent, KeyboardEvent } from 'react';
import { Box, InputGroup, InputLeftElement, Input } from '@chakra-ui/react';
import { SearchIcon } from '@chakra-ui/icons';

type SearchFormProps = {
  filter: (keyword: string) => void;
};

export const SearchForm: VFC<SearchFormProps> = ({ filter }) => {
  const onChange = (event: ChangeEvent<HTMLInputElement>) => {
    filter(event.target.value || '');
  };

  const onKeypress = (event: KeyboardEvent<HTMLInputElement>) => {
    if (event.key === 'Enter') {
      event.preventDefault();
      filter(event.currentTarget.value || '');
    }
  };

  return (
    <Box w="full">
      <InputGroup>
        <InputLeftElement
          pointerEvents="none"
          children={<SearchIcon color="gray.300" />}
        />
        <Input placeholder="検索" onChange={onChange} onKeyPress={onKeypress} />
      </InputGroup>
    </Box>
  );
};
