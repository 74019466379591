import { Box } from '@chakra-ui/react';
import { withSuspenseAndErrorBoundary } from 'admin/components/Error/WithErrorBoundary';
import { TransactionMailCommon } from 'admin/components/idPoolConsumer/transactionMail/transactionMailCommon';
import { TransactionMailPersonal } from 'admin/components/idPoolConsumer/transactionMail/transactionMailPersonal';
import { Colors } from 'admin/define/colors';
import { ErrorContents, LoadingSpinner } from 'components/common/atoms';
import { FC, memo } from 'react';

const transactionMailSettingBody: FC = memo(() => (
  <Box
    w="100%"
    bg={Colors.WHITE}
    borderRadius="8px"
    border="1px"
    borderStyle="solid"
    borderColor={Colors.GRAY_30}
    padding="24px"
  >
    <TransactionMailCommon />
    <TransactionMailPersonal />
  </Box>
));

export const TransactionMailSettingBody = withSuspenseAndErrorBoundary(
  transactionMailSettingBody,
  {
    ErrorComponent: <ErrorContents name="ID会員登録時メール設定内容" />,
    LoadingComponent: <LoadingSpinner />,
  },
);
