import { BoxProps, Td as ChakraTd } from '@chakra-ui/react';
import { FC, memo, ReactNode } from 'react';

type Props = {
  children: ReactNode;
} & BoxProps;

export const TdNoBorder: FC<Props> = memo(({ children, ...rest }: Props) => (
  <ChakraTd
    px="16px"
    py="14px"
    fontSize="12px"
    fontWeight="400"
    whiteSpace="normal"
    wordBreak="break-word"
    bgColor="inherit"
    {...rest}
  >
    {children}
  </ChakraTd>
));
