import { withSuspenseAndErrorBoundary } from 'admin/components/Error/WithErrorBoundary';
import { TermsTable } from 'admin/components/service/terms/termsTable';
import { TermsTitle } from 'admin/components/service/terms/termsTitle';
import { Breadcrumb } from 'admin/components/ui/breadcrumb';
import { Pagination } from 'admin/components/ui/pagination';
import { usePaginationQuery } from 'admin/hooks/pagination/usePaginationQuery';
import { useServices } from 'admin/hooks/service/useServices';
import { BreadcrumbType } from 'admin/types/ui/breadcrumb';
import { Page } from 'api/common/types';
import { ErrorContents, LoadingSpinner } from 'components/common/atoms';
import { useSetPageTitle } from 'hooks/useSetPageTitle';
import { FC, memo, useMemo, useState } from 'react';
import { useParams } from 'react-router-dom';

const FetchServiseRule: FC = memo(() => {
  useSetPageTitle('規約設定');
  const params = useParams();
  const [page, setPage] = useState<Page>({
    count: 0,
    currentPage: 1,
    countFrom: 0,
    countTo: 0,
    perPage: 0,
    pageCount: 0,
    next: '',
    previous: '',
  });
  const { query } = usePaginationQuery({ page });
  const currentPage = useMemo(() => query.currentPage, [query.currentPage]);
  const perPage = useMemo(() => query.currentPerPage, [query.currentPerPage]);

  const tenantId = useMemo(() => params.tenantId ?? '', [params]);
  const { data: tenants } = useServices();

  const tenantName = useMemo(() => {
    if (!tenants) {
      return '';
    }

    return tenants.find((tenant) => tenant.id === tenantId)?.name ?? '';
  }, [tenants, tenantId]);

  const breadcrumbItems: BreadcrumbType[] = [
    { url: '/admin', title: '組織管理TOP' },
    { url: '/admin/service', title: 'テナント設定管理' },
    { url: '', title: '規約設定' },
  ];

  return (
    <>
      <Breadcrumb breadcrumbItems={breadcrumbItems} />
      <TermsTitle tenantName={tenantName} />
      <TermsTable
        currentPage={currentPage}
        setPage={setPage}
        perPage={perPage}
      />
      <Pagination page={page} />
    </>
  );
});

export const ServiseRule = withSuspenseAndErrorBoundary(FetchServiseRule, {
  ErrorComponent: <ErrorContents name="規約設定" />,
  LoadingComponent: <LoadingSpinner />,
});
