import { updateToken } from 'admin/api/userPool/auth/updateToken';
import { CustomError } from 'admin/error/CustomError';
import { useIdPoolConsumerId } from 'admin/hooks/useIdPoolConsumerId';
import { TokenFormType } from 'admin/types/userPool/auth/form';
import { logger } from 'api/logger';
import { useCustomToast } from 'hooks/useCustomToast';
import { useMutation, useQueryClient } from 'react-query';
import { errorToast, successToast } from 'utils/toast';
import { queryKeys } from './queryKeys';

export const useUpdateToken = () => {
  const idPoolConsumerId = useIdPoolConsumerId();
  const queryClient = useQueryClient();
  const toast = useCustomToast();
  const { mutateAsync, isLoading } = useMutation({
    mutationFn: (data: TokenFormType) => updateToken(data),
    onError: async (error) => {
      if (error instanceof CustomError) {
        await logger({
          loglevel: error.logLevel,
          data: JSON.stringify(error.cause),
          message: error.message,
        });
      }
      toast({
        ...errorToast,
        duration: 4000,
        title: 'トークン設定の更新に失敗しました',
        position: 'bottom',
        variant: 'solid',
      });
    },
    onSuccess: () => {
      void queryClient.invalidateQueries(
        queryKeys.getSecuritySettings(idPoolConsumerId),
      );
      toast({
        ...successToast,
        duration: 4000,
        title: 'トークン設定を更新しました',
        position: 'bottom',
        variant: 'solid',
      });
    },
  });

  return { mutate: mutateAsync, isLoading };
};
