import { Drawer, useDisclosure } from '@chakra-ui/react';
import { SamlDrawer } from 'admin/components/saml/samlDrawer';
import { SamlSettingList } from 'admin/components/saml/samlSettingList';
import { SamlTitle } from 'admin/components/saml/samlTitle';
import { Breadcrumb } from 'admin/components/ui/breadcrumb';
import { BreadcrumbType } from 'admin/types/ui/breadcrumb';
import { useSetPageTitle } from 'hooks/useSetPageTitle';
import { FC, memo } from 'react';

export const SAML: FC = memo(() => {
  useSetPageTitle('SAML設定');
  const { isOpen, onClose, onOpen } = useDisclosure();
  const breadcrumbItems: BreadcrumbType[] = [
    { url: '/admin', title: '組織管理TOP' },
    {
      url: '/admin/saml',
      title: 'SAML設定',
    },
  ];

  return (
    <>
      <Breadcrumb breadcrumbItems={breadcrumbItems} />
      <SamlTitle onOpen={onOpen} />
      <SamlSettingList />
      <Drawer
        isOpen={isOpen}
        placement="right"
        onClose={onClose}
        size="md"
        closeOnOverlayClick={false}
        closeOnEsc={false}
        autoFocus={false}
      >
        <SamlDrawer onClose={onClose} />
      </Drawer>
    </>
  );
});
