import { zodResolver } from '@hookform/resolvers/zod';
import { TenantUsersEditFormComponent } from 'admin/components/tenantUsers/tenantUsersEditForm/TenantUsersEditFormComponent';
import { useUpdateTenantUsersdetail } from 'admin/hooks/tenantUsers/useUpdateTenantUsersDetail';
import { tenantUsersUpdateSchema } from 'admin/schema/tenantUsers';
import { TenantUsersType } from 'admin/types/tenantUsers';
import { tenantUsersUpdateFormType } from 'admin/types/tenantUsers/form';
import { AlertBar } from 'components/common/atoms';
import { FC, memo, useState } from 'react';
import { FormProvider, SubmitHandler, useForm } from 'react-hook-form';

type Props = {
  tenantUsersDetail: TenantUsersType;
  onClose: () => void;
};

export const TenantUsersEditForm: FC<Props> = memo(
  ({ tenantUsersDetail, onClose }: Props) => {
    const formId = 'tenantUsersEditForm';

    const [globalErrors, setGlobalErrors] = useState<string[]>([]);

    const methods = useForm<tenantUsersUpdateFormType>({
      defaultValues: {
        id: tenantUsersDetail.id ?? '',
        tenantId: tenantUsersDetail.tenant.id ?? '',
        permission: tenantUsersDetail.tenant.permission ?? '',
        groups: tenantUsersDetail.tenant.groups ?? [],
      },
      resolver: zodResolver(tenantUsersUpdateSchema),
    });
    const { mutate, isLoading } = useUpdateTenantUsersdetail(
      methods.setError,
      setGlobalErrors,
    );
    const onSubmit: SubmitHandler<tenantUsersUpdateFormType> = async (
      data: tenantUsersUpdateFormType,
    ) => {
      await mutate(data);
      onClose();
    };

    return (
      <FormProvider {...methods}>
        {globalErrors.map((err, idx) => (
          <AlertBar
            key={`global-err-idx${String(idx)}`}
            message={err}
            status="error"
            mb={4}
          />
        ))}
        <TenantUsersEditFormComponent
          tenantUsersDetail={tenantUsersDetail}
          formId={formId}
          isLoading={isLoading}
          onClose={onClose}
          onSubmit={onSubmit}
        />
      </FormProvider>
    );
  },
);
