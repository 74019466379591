import { patchWallDesignPublish } from 'api/paywall/patchWallDesignPublish';
import { ResponseError } from 'api/types';
import { WallDesignPublishDefaultValues } from 'components/paywall/pages/WallDesignList/initial';
import {
  isWallDesign, WallDesign,
  WallDesignPublish
} from 'components/paywall/pages/WallDesignList/typed';
import { usePaywallUtil } from 'hooks/paywall/usePaywallUtil';
import { useCustomToast } from 'hooks/useCustomToast';
import { UseMutateFunction, useMutation } from 'react-query';
import { isGetValidationError } from 'utils/form';

type UnPromisify<T> = T extends Promise<infer U> ? U : T;

/**
 * ウォールデザインステータス変更hooks
 * @returns {Object} { onSubmit, isLoading }
 */
export const usePaywallDesignPublish = (): {
  onSubmit: UseMutateFunction<
    UnPromisify<ReturnType<typeof patchWallDesignPublish>>,
    unknown,
    WallDesignPublish,
    unknown
  >;
  isLoading: boolean;
} => {
  const { paywallDesignListRefetchQueries, paywallListRefetchQueries } = usePaywallUtil();
  const toast = useCustomToast();

  const { mutate: onSubmit, isLoading } = useMutation(
    ({ id, tenant, wallId, status }: WallDesignPublish) =>
      patchWallDesignPublish({ id, tenant, wallId, status }),
    {
      onSuccess: (result: WallDesign | ResponseError) => {
        paywallDesignListRefetchQueries();
        // 画面作成した際、サブスクリプション一覧or単品商品一覧のボタンのエラーを更新するため、リストをrefetchする
        paywallListRefetchQueries();
        if (isWallDesign(result)) {
          const isPublicStr = result.status ? '有効' : '無効';
          toast({
            status: 'success',
            position: 'bottom',
            duration: 2000,
            isClosable: true,
            title: `ウォールデザイン「${result.name}」を${isPublicStr}に設定しました`,
          });
        }

        if (
          isGetValidationError<WallDesignPublish>(
            result,
            Object.keys(WallDesignPublishDefaultValues),
          )
        ) {
          const errorMsg = Object.entries(result).map(([_, value]) => value);
          toast({
            status: 'error',
            position: 'bottom',
            duration: 4000,
            isClosable: true,
            title: errorMsg[0],
          });
        }
      },
    },
  );

  return { onSubmit, isLoading };
};
