import { ArrowLeftIcon, ArrowRightIcon } from '@chakra-ui/icons';
import { IconButton, Tooltip } from '@chakra-ui/react';
import { FC, MouseEvent, memo, useCallback } from 'react';
import { useRecoilState } from 'recoil';
import { paywallSidebarState } from 'store/paywallSidebarState';

export const SideMenuOperationButton: FC = memo(() => {
  const [isOpen, setOpen] = useRecoilState(paywallSidebarState);

  // ペイウォールサイドメニュー開閉ボタン
  const clickMenuBtn = useCallback(
    (e: MouseEvent<HTMLButtonElement>): void => {
      e.stopPropagation();
      setOpen(!isOpen);
    },
    [isOpen, setOpen],
  );

  return (
    <Tooltip label={`ペイウォールのナビゲーションを${isOpen ? '非' : ''}表示する`} bgColor="black" color="white">
      <IconButton
        icon={isOpen ? <ArrowLeftIcon w={3} h={3} /> : <ArrowRightIcon w={3} h={3} />}
        bg="#f1f4f8"
        isRound={false}
        aria-label="sidebar"
        position="fixed"
        bottom="14px"
        left={isOpen ? "272px" : "64px"}
        zIndex="100"
        _hover={{ bg: 'gray.300' }}
        _active={{ bg: '' }}
        _focus={{ boxShadow: '' }}
        onClick={clickMenuBtn}
        transition='all .2s ease'
      />
    </Tooltip>
  );
});
