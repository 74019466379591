import { Radio, RadioProps } from '@chakra-ui/react';
import { FC } from 'react';
import {
  FieldValues,
  Path,
  useController,
  useFormContext,
} from 'react-hook-form';

type RhfRadioProps<T> = {
  name: Path<T>;
} & Pick<
  RadioProps,
  | 'variant'
  | 'isDisabled'
  | 'colorScheme'
  | 'size'
  | 'bgColor'
  | 'children'
  | 'value'
> & {
    checked?: boolean;
  };

export const RhfRadio = <T extends FieldValues>({
  name,
  checked,
  ...rest
}: RhfRadioProps<T>): ReturnType<FC> => {
  const { control } = useFormContext<FieldValues>();
  const { field } = useController({ name, control });

  return (
    <Radio defaultChecked={checked} bgColor="WHITE" {...field} {...rest} />
  );
};

// defaultPropsの宣言を追加
RhfRadio.defaultProps = {
  checked: false,
};
