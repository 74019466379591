import { Box } from '@chakra-ui/react';
// import { ServiceMemberSearch } from 'admin/components/member/service/serviceMemberSearch';
import { ServiceMembersTable } from 'admin/components/member/service/serviceMembersTable';
import { Pagination } from 'admin/components/ui/pagination';
import { Page } from 'api/common/types';
import { Dispatch, FC, memo, SetStateAction } from 'react';

type Props = {
  currentPage: number;
  perPage: number;
  keyword: string;
  page: Page;
  setPage: Dispatch<SetStateAction<Page>>;
  // TODO: 9月末のリリース時点では必要では無い為、コメントアウト
  // onChange: (keyword: string) => void;
  // onSearch: () => void;
  // setRefetchFunc: Dispatch<SetStateAction<() => void>>;
};

export const ServiceMemberListComponent: FC<Props> = memo(
  ({
    currentPage,
    perPage,
    keyword,
    page,
    setPage,
  }: // TODO: 9月末のリリース時点では必要では無い為、コメントアウト
  // onChange,
  // onSearch,
  // setRefetchFunc,
  Props) => (
    <Box mt="16px">
      {/*
        TODO: ServiceMemberSearchコンポーネントをリリースする時に削除予定
        ServiceMemberSearchコンポーネントにタイトルが含まれていた為、一時的に外に出しています。
      */}
      <Box fontWeight="700" fontSize="1rem" mb="16px">
        会員一覧
      </Box>
      {/* TODO: 9月末のリリース時点では必要では無い為、コメントアウト */}
      {/* <ServiceMemberSearch onChange={onChange} onSearch={onSearch} /> */}
      <ServiceMembersTable
        currentPage={currentPage}
        perPage={perPage}
        keyword={keyword}
        setPage={setPage}
        // TODO: 9月末のリリース時点では必要では無い為、コメントアウト
        // setRefetchFunc={setRefetchFunc}
      />
      <Pagination page={page} />
    </Box>
  ),
);
