import { VFC } from 'react';
import { Stack, Skeleton, SkeletonProps } from '@chakra-ui/react';

type LoadingSkeletonProps = {
  line?: number;
  space?: number;
  height?: Omit<SkeletonProps, 'height'>;
  endColor?: Omit<SkeletonProps, 'endColor'>;
  testId?: string;
} & SkeletonProps;

export const LoadingSkeleton: VFC<LoadingSkeletonProps> = ({
  endColor = 'gray.300',
  height = '10px',
  space = '4',
  line = 6,
  testId = 'loading-skeleton',
  w,
  width,
  ...rest
}) => (
  <Stack p={4} spacing={space} w={w} width={width} data-testid={testId}>
    {[...Array(line).keys()].map((_, idx) => (
      <Skeleton
        key={`loading-skeleton-${String(idx)}`}
        height={height}
        endColor={endColor}
        {...rest}
      />
    ))}
  </Stack>
);
