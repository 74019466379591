import {
  Box,
  Button,
  DrawerCloseButton,
  DrawerContent,
  DrawerFooter,
  DrawerHeader,
  DrawerOverlay,
  Text,
} from '@chakra-ui/react';
import { TermsCreateForm } from 'admin/components/idPoolConsumer/terms/termsForm';
import { ColorSet } from 'admin/define/colors';
import { FC, memo } from 'react';

type Props = {
  onClose: () => void;
  formId: string;
  isOpen: boolean;
};

export const TermsCreateDrawer: FC<Props> = memo(({ onClose, formId }) => (
  <>
    <DrawerOverlay />
    <DrawerContent>
      <DrawerHeader pb={0}>
        <Box m={0} px={0} pb="24px" borderBottomWidth="1px">
          <Text>規約設定</Text>
        </Box>
      </DrawerHeader>
      <TermsCreateForm formId={formId} onClose={onClose} />
      <DrawerFooter>
        <Button
          variant="outline"
          mr={3}
          onClick={onClose}
          p="8px 16px"
          w="104px"
          h="40px"
          {...ColorSet.Default}
        >
          キャンセル
        </Button>
        <Button
          type="submit"
          form={formId}
          p="8px 16px"
          w="104px"
          h="40px"
          colorScheme="primary"
        >
          作成
        </Button>
      </DrawerFooter>
      <DrawerCloseButton top="16px" right="24px" />
    </DrawerContent>
  </>
));
