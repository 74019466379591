import { useToast } from '@chakra-ui/react';
import { login } from 'api/user/login';
import { isUser, User } from 'api/user/types';
import { useLocalstorage } from 'hooks/useLocalstorage';
import { useCallback, useState } from 'react';
import { useSetRecoilState } from 'recoil';
import { corporationIdState, userState, userTenantIdState } from 'store/user';

export const useAuth = (): {
  isLoading: boolean;
  isSuccess: boolean;
  onAuthLogin: (email: string, password: string) => Promise<void>;
  setRecoilAndLocalStorage: (user: User) => void;
} => {
  const [isLoading, setLoading] = useState(false);
  const [isSuccess, setSuccess] = useState(false);
  const setUser = useSetRecoilState(userState);
  const setCurrentTenantId = useSetRecoilState(userTenantIdState);
  const setCurrentCorporationId = useSetRecoilState(corporationIdState);
  const toast = useToast();
  const { setLocalStorage } = useLocalstorage();

  const setRecoilAndLocalStorage = useCallback(
    (user: User) => {
      setUser(user);
      // テナントIDをセット
      let tenantId = '';
      if (Array.isArray(user.tenant)) {
        if (user.tenant.length > 0) {
          tenantId = user.tenant[0].id;
          setCurrentTenantId(tenantId);
        }
      } else {
        tenantId = user.tenant.id;
        setCurrentTenantId(tenantId);
      }
      // 法人IDをセット
      const corporationId = user.corporations[0]?.id;
      setCurrentCorporationId(corporationId);

      setLocalStorage({ tenantId, corporationId });
    },
    [setCurrentCorporationId, setCurrentTenantId, setLocalStorage, setUser],
  );

  const onAuthLogin = useCallback(
    async (email: string, password: string) => {
      setLoading(true);
      const result = await login({ email, password });

      setLoading(false);

      if (isUser(result)) {
        setSuccess(true);
        setRecoilAndLocalStorage(result);
      } else {
        toast({
          status: 'error',
          position: 'bottom',
          duration: 2000,
          isClosable: true,
          title: result.title,
          description: result.description,
        });
      }
    },
    [setLoading, setSuccess, toast, setRecoilAndLocalStorage],
  );

  return {
    isLoading,
    isSuccess,
    onAuthLogin,
    setRecoilAndLocalStorage,
  };
};
