import {
  Box,
  Button,
  DrawerBody,
  DrawerFooter,
  Flex,
  FormControl,
  FormErrorMessage,
  Heading,
  Text,
  VStack,
} from '@chakra-ui/react';
import { RhfInput } from 'admin/components/form/RhfInput';
import { RequiredIcon } from 'admin/components/ui/icon';
import { LoadingLayer } from 'admin/components/ui/loadingLayer';
import { Colors, ColorSet } from 'admin/define/colors';
import { AuthHubFormType } from 'admin/types/userPool/auth/form';
import { FC, memo } from 'react';
import { useFormContext, useFormState } from 'react-hook-form';
import { toErrMsgList } from 'utils/form';

type AuthHubEditFormComponentProps = {
  formId: string;
  isLoading: boolean;
  onSubmit: (value: AuthHubFormType) => void;
  onClose: () => void;
};
export const AuthHubEditFormComponent: FC<AuthHubEditFormComponentProps> = memo(
  ({ formId, isLoading, onSubmit, onClose }) => {
    const { handleSubmit } = useFormContext<AuthHubFormType>();
    const { errors } = useFormState<AuthHubFormType>();

    return (
      <>
        {isLoading && <LoadingLayer />}
        <DrawerBody pt="24px" pb="16px" color={Colors.GRAY_03}>
          <form onSubmit={handleSubmit(onSubmit)} id={formId}>
            <VStack spacing={6}>
              <Box w="100%">
                <Heading as="h3" size="sm" mb={2}>
                  <Flex alignItems="center" style={{ gap: '8px' }}>
                    <Text as="span" fontSize="14px">
                      セッション有効期限
                    </Text>
                    <RequiredIcon />
                  </Flex>
                </Heading>
                <Box>
                  <FormControl
                    isInvalid={
                      Boolean(errors.duration?.message) ||
                      toErrMsgList(errors, 'duration').length > 0
                    }
                  >
                    <Flex gridGap={2} alignItems="center">
                      <RhfInput<AuthHubFormType>
                        name="duration"
                        bgColor={Colors.WHITE}
                        width="216px"
                      />
                      <Text as="span" fontSize="16px">
                        秒
                      </Text>
                    </Flex>

                    {toErrMsgList(errors, 'duration').map((err) => (
                      <FormErrorMessage key={`duration${err}`}>
                        {err}
                      </FormErrorMessage>
                    ))}
                  </FormControl>
                </Box>
              </Box>
            </VStack>
          </form>
        </DrawerBody>
        <DrawerFooter borderWidth="1px">
          <Button
            variant="outline"
            mr={4}
            onClick={onClose}
            p="8px 16px"
            w="104px"
            h="40px"
            fontWeight={700}
            {...ColorSet.Default}
          >
            キャンセル
          </Button>
          <Button
            type="submit"
            form={formId}
            p="8px 16px"
            w="104px"
            h="40px"
            fontWeight={700}
            colorScheme="primary"
          >
            保存
          </Button>
        </DrawerFooter>
      </>
    );
  },
);
