import { request } from 'admin/api/Request';
import { API_MESSAGES } from 'admin/define/apiMessages';
import { CustomError } from 'admin/error/CustomError';
import { newsletterSchema } from 'admin/schema/idPoolConsumer/newsletter';
import { UserPool } from 'admin/types/userPool';
import { NewsletterType } from 'admin/types/userPool/newsletter';
import { ZodError } from 'zod';

export const getNewsletterDetail = async ({
  newsletterId,
  userpoolId,
}: {
  newsletterId: NewsletterType['id'];
  userpoolId: UserPool['id'];
}): Promise<NewsletterType> => {
  const path = `uniikey/${userpoolId}/newsletter/${newsletterId}/`;
  const response = await request<NewsletterType>({
    path,
    method: 'get',
  });

  if (response.hasError) {
    if (response.status === 404) {
      throw new CustomError(
        API_MESSAGES.USER_POOL.GET_NEWSLETTER_DETAIL.NOT_FOUND,
        {
          path,
          status: response.status,
          params: {
            userpoolId,
          },
        },
      );
    } else {
      throw new CustomError(
        API_MESSAGES.USER_POOL.GET_NEWSLETTER_DETAIL.ERROR,
        {
          path,
          status: response.status,
          params: {
            userpoolId,
          },
        },
      );
    }
  }
  try {
    newsletterSchema.parse(response.data);
  } catch (error) {
    if (error instanceof ZodError) {
      throw new CustomError(error.message, {
        path,
        status: response.status,
        params: {
          userpoolId,
        },
        logLevel: 'error',
      });
    }
  }

  return response.data;
};
