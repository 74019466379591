import { NewsletterFormComponent } from 'admin/components/service/newsletter/newsletterForm/NewsletterFormComponent';
import { ServiceNewsletterType } from 'admin/types/service/newsletter';
import { FC, memo } from 'react';
import { FormProvider, useForm } from 'react-hook-form';

type Props = {
  newsletter: ServiceNewsletterType;
  onClose: () => void;
};

export const NewsletterForm: FC<Props> = memo(
  ({ newsletter, onClose }: Props) => {
    const methods = useForm<ServiceNewsletterType>();

    return (
      <FormProvider {...methods}>
        <NewsletterFormComponent newsletter={newsletter} onClose={onClose} />
      </FormProvider>
    );
  },
);
