import { memo } from 'react';
import { LoginPage as Template } from 'view-template/dist/page';
import { TemplatePageProps, Wrap } from '../Page';
import { defaultTheme } from '../util';

export const LoginPage = memo(
  ({
    watch,
    force,
    showsSignUpButton,
  }: TemplatePageProps & { showsSignUpButton: boolean }) => {
    const dummyProps = {
      csrfToken: '',
      postPath: '',
      errorMessage: undefined,
      oauthRequest: {
        response_type: '',
        client_id: '',
        redirect_uri: '',
      },
      passwordResetUrl: '',
      signupUrl: '',
      email: undefined,
      showsSignUpButton,
    };

    return (
      <Wrap>
        <Template {...dummyProps} theme={defaultTheme(watch)} force={force} />
      </Wrap>
    );
  },
);
