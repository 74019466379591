import { CustomerDetailMemo, isCustomerDetailMemos } from 'components/paywall/pages/CustomerList/typed';

export type OrderStatus = {
  orderId: string;
  customerUid: string;
  wallOrderId: string;
  planName: string;
  planId: string;
  isItem: boolean;
  isCourse: boolean;
  hasPromotion: boolean;
  promotionName: string;
  promotionId: string;
  isPaid: boolean;
  errorInfo: string;
  errorMessage: string;
  jobCode: string;
  cardSeq: number;
  isCanceled: boolean;
  price: number;
  memos: CustomerDetailMemo[];
  isChargeBack: boolean;
  cancelReason: number;
};

export type WallOrder = {
  id: string;
  courseName: string;
  itemName: string;
  promotionName: string;
  memos: CustomerDetailMemo[];
  customerUid: string;
  courseStart: string;
  courseEnd: string;
  coursePrice: number;
  courseCancelFlag: boolean;
  courseMonth: number;
  rightsExpirationDate: string;
  nextTranData: string;
  promotionStart: string;
  promotionEnd: string;
  promotionPrice: number;
  promotionMonth: number;
  itemSellType: string;
  itemPeriod: number;
  itemPeriodStart: string;
  itemPeriodEnd: string;
  itemPrice: number;
  itemCancelFlag: boolean;
  tranErrorInfo: string;
  tranErrorMessageCustomer: string;
  canceledAt: string;
  chargebackFlag: boolean;
  cancelReason: number;
  tenant: string;
  course: string;
  promotion: string;
  item: string;
  systemCode: string;
  tags: string | null;
};

export type Customer = {
  address: string;
  lastName: string;
  firstName: string;
  prefName: string;
  tel: string;
  zip: string;
  isActive: boolean;
}

export type OrderDetailResult = {
  id: string;
  wallOrder: WallOrder;
  email: string;
  createdAt: string;
  customer: Customer;
  customerUid: string;
  tranJobCode: string;
  cardSeq: number;
  price: number;
  execTranDate: string;
  tranErrorCode: string;
  tranErrorInfo: string;
  tranErrorMessageSystem: string;
  tranErrorMessageCustomer: string;
  cancelTranDate: string;
  cancelTranId: string;
  cardErrorInfo: CardErrorInfo[];
  tranStatus: number;
};

export type ApiOrderDetail = {
  data: OrderDetailResult;
  error: number;
  errorMessage: string;
  hasError: boolean;
  responseType: string;
  status: number;
  url: string;
};

export type OrderChargeBackType = {
  tenantId: string;
  orderId: string;
}

export type OrderChargeBackTransactionMessage = {
  code: string;
  message: string;
}
export type OrderChargeBackResponseType = {
  gmoStatus: boolean;
  appStatus: boolean;
  messageApp: string;
  messageGmo: string;
  transaction: OrderChargeBackTransactionMessage[];
  detail: string;
}

export const isOrderChargeBackResponseType = (data: unknown): data is OrderChargeBackResponseType => {
  const obj = data as OrderChargeBackResponseType;

  return (
    typeof obj?.gmoStatus === 'boolean' &&
    typeof obj?.appStatus === 'boolean' &&
    typeof obj?.messageApp === 'string' &&
    typeof obj?.messageGmo === 'string' &&
    // typeof obj?.transaction === 'string' &&
    typeof obj?.detail === 'string'
  )
}

// こちらの型ガードは廃止する
export const isOrderDetail = (data: unknown): data is OrderDetailResult => {
  const el = data as OrderDetailResult;

  return (
    typeof el?.id === 'string' &&
    (el?.email !== null ? typeof el?.email === 'string' : true) &&
    typeof el?.createdAt === 'string' &&
    typeof el?.customerUid === 'string' &&
    typeof el?.tranJobCode === 'string' &&
    typeof el?.cardSeq === 'number' &&
    typeof el?.price === 'number' &&
    (el?.tranErrorCode !== null
      ? typeof el?.tranErrorCode === 'string'
      : true) &&
    (el?.tranErrorInfo !== null
      ? typeof el?.tranErrorInfo === 'string'
      : true) &&
    (el?.tranErrorMessageSystem !== null
      ? typeof el?.tranErrorMessageSystem === 'string'
      : true) &&
    (el?.tranErrorMessageCustomer !== null
      ? typeof el?.tranErrorMessageCustomer === 'string'
      : true)
  );
};

export type OrderListResult = {
  id: string;
  wallOrder: WallOrder;
  email: string;
  createdAt: string;
  customerUid: string;
  execTranDate: string;
  tranJobCode: string;
  tranStatus: number;
  cardSeq: number;
  price: number;
  cancelTranDate: string;
  cancelTranId: string;
  cardErrorInfo: CardErrorInfo[];
};

export type OrderList = {
  count: number;
  currentPage: number;
  countFrom: number;
  countTo: number;
  perPage: number;
  pageCount: number;
  next: string;
  previous: string;
  results: OrderListResult[];
};

export type ApiOrderList = {
  data: OrderList;
  error: number;
  errorMessage: string;
  hasError: boolean;
  responseType: string;
  status: number;
  url: string;
};

export type CardErrorInfo = {
  code: string;
  message: string;
}

export const isOrderList = (data: unknown): data is OrderList => {
  const el = data as OrderList;

  return (
    typeof el?.count === 'number' &&
    typeof el?.currentPage === 'number' &&
    typeof el?.countTo === 'number' &&
    typeof el?.perPage === 'number' &&
    typeof el?.pageCount === 'number' &&
    typeof el?.next === 'string' &&
    typeof el?.previous === 'string' &&
    typeof el?.results === 'object'
  );
};

// こちらの型ガードを残す
export const isWallOrder = (data: unknown): data is WallOrder => {
  const el = data as WallOrder;

  return (
    typeof el?.id === 'string' &&
    typeof el?.courseName === 'string' &&
    typeof el?.itemName === 'string' &&
    typeof el?.promotionName === 'string' &&
    isCustomerDetailMemos(el?.memos) &&
    typeof el?.customerUid === 'string' &&
    typeof el?.courseStart === 'string' &&
    typeof el?.courseEnd === 'string' &&
    typeof el?.coursePrice === 'number' &&
    typeof el?.courseCancelFlag === 'boolean' &&
    typeof el?.courseMonth === 'number' &&
    typeof el?.rightsExpirationDate === 'string' &&
    typeof el?.nextTranData === 'string' &&
    typeof el?.promotionStart === 'string' &&
    typeof el?.promotionEnd === 'string' &&
    typeof el?.promotionPrice === 'number' &&
    typeof el?.promotionMonth === 'number' &&
    typeof el?.itemSellType === 'string' &&
    typeof el?.itemPeriod === 'number' &&
    typeof el?.itemPeriodStart === 'string' &&
    typeof el?.itemPeriodEnd === 'string' &&
    typeof el?.itemPrice === 'number' &&
    typeof el?.itemCancelFlag === 'boolean' &&
    typeof el?.tranErrorInfo === 'string' &&
    typeof el?.tranErrorMessageCustomer === 'string' &&
    typeof el?.canceledAt === 'string' &&
    typeof el?.chargebackFlag === 'boolean' &&
    typeof el?.cancelReason === 'number' &&
    typeof el?.tenant === 'string' &&
    typeof el?.course === 'string' &&
    typeof el?.promotion === 'string' &&
    typeof el?.item === 'string'
  );
};

// こちらの型ガードを残す
export const isOrderDetailType = (data: unknown): data is OrderDetailResult => {
  const el = data as OrderDetailResult;

  return (
    typeof el?.id === 'string' &&
    isWallOrder(el?.wallOrder) &&
    typeof el?.email === 'string' &&
    typeof el?.createdAt === 'string' &&
    typeof el?.customerUid === 'string' &&
    typeof el?.tranJobCode === 'string' &&
    typeof el?.cardSeq === 'number' &&
    typeof el?.price === 'number' &&
    typeof el?.tranErrorCode === 'string' &&
    typeof el?.tranErrorInfo === 'string' &&
    typeof el?.tranErrorMessageSystem === 'string' &&
    typeof el?.tranErrorMessageCustomer === 'string' &&
    typeof el?.cancelTranDate === 'string' &&
    typeof el?.cancelTranId === 'string'
  );
};
