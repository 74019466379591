import { Box, Drawer } from '@chakra-ui/react';
import { LoginSetting } from 'api/tenant/types';
import { LoginRedirectContentsComponent } from 'components/setting/loginRedirect/loginRedirectContents/LoginRedirectContentsComponent';
import { LoginRedirectDrawer } from 'components/setting/loginRedirect/loginRedirectDrawer';
import { useLoginSetting } from 'hooks/tenant/useLoginSetting';
import { useUserTenantId } from 'hooks/user/useUserTenantId';
import { FC, memo, useMemo } from 'react';

type Props = {
  isOpen: boolean;
  onClose: () => void;
}

export const LoginRedirectContents: FC<Props> = memo(({isOpen, onClose}: Props) => {
  const tenantId = useUserTenantId();
  const loginSettingResponse = useLoginSetting({ tenantId });

  const loginSetting = useMemo<LoginSetting>(() => ({
    loginUrl: loginSettingResponse?.loginUrl || '-',
    signupUrl: loginSettingResponse?.signupUrl || '-',
    loginParam: loginSettingResponse?.loginParam || '-',
    loginOtherParam: loginSettingResponse?.loginOtherParam || '-',
  }), [loginSettingResponse]);

  return (
    <Box mt="16px" borderWidth="1px" borderRadius="8px" p={4} w="100%">
      <LoginRedirectContentsComponent loginSetting={loginSetting} />
      <Drawer
        isOpen={isOpen}
        placement="right"
        size="sm"
        onClose={onClose}
        closeOnOverlayClick={false}
        closeOnEsc={false}
        autoFocus={false}
      >
        <LoginRedirectDrawer
          onClose={onClose}
        />
      </Drawer>
    </Box>
  )
});
