import { CopyIcon } from '@chakra-ui/icons';
import {
  Box,
  Flex,
  HStack,
  ListItem,
  StackDivider,
  Text,
  UnorderedList,
  VStack,
} from '@chakra-ui/react';
import { withSuspenseAndErrorBoundary } from 'admin/components/Error/WithErrorBoundary';
import { AccessSecretKey } from 'admin/components/service/auth/accessSecretKey';
// import { ApiWhiteList } from 'admin/components/service/auth/apiWhiteList';
import { CallbackUrl } from 'admin/components/service/auth/callbackUrl';
import { TokenRequestSecretKey } from 'admin/components/service/auth/tokenRequestSecretKey';
import { Colors } from 'admin/define/colors';
import { useAuth } from 'admin/hooks/service/auth/useAuth';
import { ErrorContents, LoadingSpinner } from 'components/common/atoms';
import { useCopy } from 'hooks/useCopy';
import { FC, memo } from 'react';

const FetchauthBody: FC = memo(() => {
  const { copy } = useCopy();

  const { data: authData } = useAuth();
  if (!authData) {
    return null;
  }

  return (
    <VStack
      spacing={8}
      alignItems="flex-start"
      divider={<StackDivider borderColor={Colors.GRAY_30} />}
    >
      <HStack w="100%" spacing={8} justify="space-around">
        <VStack
          spacing={4}
          alignItems="flex-start"
          fontFamily="Hiragino Sans"
          display="inline-block"
          w="100%"
        >
          <Box>
            <Text fontWeight="700">ユーザープールチャイルドID</Text>
            <UnorderedList
              fontSize="12px"
              color={Colors.GRAY_00}
              marginInlineStart="24px"
            >
              <ListItem>
                IDシステムから各テナントに一意に発番されるIDです。
              </ListItem>
              <ListItem>
                Auth
                HubのURL上に存在するパラメーターの内、client_idに対応します。
              </ListItem>
            </UnorderedList>
          </Box>
          <Flex
            p="8px 16px"
            bgColor={Colors.GRAY_70}
            borderRadius="8px"
            alignItems="center"
            justifyContent="space-between"
          >
            <Text fontSize="16px">{authData.clientId}</Text>
            <CopyIcon
              cursor="pointer"
              onClick={() => copy(authData.clientId)}
              ml={1}
            />
          </Flex>
        </VStack>
        <VStack
          spacing={4}
          alignItems="flex-start"
          fontFamily="Hiragino Sans"
          display="inline-block"
          w="100%"
        >
          <Box>
            <Text fontWeight="700">テナントID</Text>
            <UnorderedList fontSize="12px" color={Colors.GRAY_00}>
              <ListItem>テナント管理画面ごとに発番されるIDです。</ListItem>
              <ListItem>
                データ出力時にどのテナントかを表示するために使われます。
              </ListItem>
            </UnorderedList>
          </Box>
          <Flex
            p="8px 16px"
            bgColor={Colors.GRAY_70}
            borderRadius="8px"
            alignItems="center"
            justifyContent="space-between"
          >
            <Text fontSize="16px">{authData.tenantId}</Text>
            <CopyIcon
              cursor="pointer"
              onClick={() => copy(authData.tenantId)}
              ml={1}
            />
          </Flex>
        </VStack>
      </HStack>

      <TokenRequestSecretKey authData={authData} />
      <CallbackUrl authData={authData} />
      <AccessSecretKey authData={authData} />
      {/* 11月の対応では不要のためコメントアウト */}
      {/* <ApiWhiteList authData={authData} /> */}
    </VStack>
  );
});

export const authBody: FC = memo(() => <FetchauthBody />);

export const AuthBody = memo(
  withSuspenseAndErrorBoundary(authBody, {
    ErrorComponent: <ErrorContents name="認証情報設定" />,
    LoadingComponent: <LoadingSpinner />,
  }),
);
