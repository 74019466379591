// TODO: AuthConfigTypeは仕様決まり次第変更予定
export type AuthConfigType = {
  ASSISTANT: boolean,
  STAFF: boolean,
  DIRECTOR: boolean,
  MANAGER: boolean,
  OWNER: boolean
}

// 各画面表示可否
// TODO: 以下判定処理は仕様決まり次第変更予定
export const AdminAuth: {[key: string]: AuthConfigType} = {
  // top
  top: {
    ASSISTANT: false,
    STAFF: false,
    DIRECTOR: false,
    MANAGER: false,
    OWNER: true
  },
}
