import {
  AlertDialogBody,
  AlertDialogFooter,
  Box,
  Button,
  FormControl,
  FormErrorMessage,
  Heading,
  ListItem,
  Text,
  UnorderedList,
} from '@chakra-ui/react';
import { RhfInput } from 'admin/components/form/RhfInput';
import { AlertInfoBar } from 'admin/components/ui/alertInfo/alertInfoBar';
import { LoadingLayer } from 'admin/components/ui/loadingLayer';
import { Colors, ColorSet } from 'admin/define/colors';
import {
  CreateConfirmIdPoolConsumerFormType,
  CreateIdPoolConsumerFormType,
} from 'admin/types/userPool/form';
import { AUTH_CODE_TYPE_LIST, AUTH_CODE_TYPE_UNIIKEY } from 'define';
import { FC, memo } from 'react';
import { useFormContext, useFormState } from 'react-hook-form';

type Props = {
  idPoolConsumer: CreateIdPoolConsumerFormType;
  onSubmit: (value: CreateConfirmIdPoolConsumerFormType) => void;
  onBack: () => void;
  isLoading: boolean;
};

export const ConfirmFormComponent: FC<Props> = memo(
  ({ idPoolConsumer, onBack, onSubmit, isLoading }: Props) => {
    const { errors, isValid } =
      useFormState<CreateConfirmIdPoolConsumerFormType>();
    const { handleSubmit } =
      useFormContext<CreateConfirmIdPoolConsumerFormType>();

    return (
      <>
        {isLoading && <LoadingLayer />}
        <form onSubmit={handleSubmit(onSubmit)}>
          <AlertDialogBody maxH="500px" overflowY="auto">
            <Box>
              <Box>
                <Box mb="32px">
                  <Heading as="h3" fontSize="14px" mb={2}>
                    <Box display="flex" alignItems="center">
                      名前
                    </Box>
                  </Heading>
                  <Box borderBottom="1px solid" borderColor={Colors.GRAY_30}>
                    <Text p="8px 16px" fontSize="16px">
                      {idPoolConsumer.name}
                    </Text>
                  </Box>
                </Box>
                {Number(idPoolConsumer.authenticationType) ===
                  AUTH_CODE_TYPE_UNIIKEY && (
                  <Box mb="32px">
                    <Heading as="h3" fontSize="14px" mb={2}>
                      <Box display="flex" alignItems="center">
                        フィールド名
                      </Box>
                    </Heading>
                    <Box borderBottom="1px solid" borderColor={Colors.GRAY_30}>
                      <Text p="8px 16px" fontSize="16px" minH="40px">
                        {idPoolConsumer.fieldName}
                      </Text>
                    </Box>
                  </Box>
                )}
                <Box mb="32px">
                  <Heading as="h3" fontSize="14px" mb={2}>
                    <Box display="flex" alignItems="center">
                      利用ID種別
                    </Box>
                  </Heading>
                  <Box borderBottom="1px solid" borderColor={Colors.GRAY_30}>
                    <Text p="8px 16px" fontSize="16px">
                      {AUTH_CODE_TYPE_LIST.get(
                        Number(idPoolConsumer.authenticationType),
                      )}
                    </Text>
                  </Box>
                </Box>
                <Box mb="32px">
                  <AlertInfoBar type="error" iconType="error">
                    <UnorderedList>
                      <ListItem>
                        選択されたIDシステムは作成後の変更はできません。
                      </ListItem>
                      <ListItem>
                        一度作成したユーザープールの削除はできません。
                      </ListItem>
                    </UnorderedList>
                  </AlertInfoBar>
                </Box>
                <Box>
                  <Heading as="h3" fontSize="14px" mb={2}>
                    実行する場合は「create」と入力後に「作成ボタン」を押下してください。
                  </Heading>
                  <FormControl isInvalid={Boolean(errors.word?.message)}>
                    <Box>
                      <RhfInput<CreateConfirmIdPoolConsumerFormType>
                        name="word"
                        bgColor={Colors.WHITE}
                      />
                    </Box>
                    <FormErrorMessage>{errors.word?.message}</FormErrorMessage>
                  </FormControl>
                </Box>
              </Box>
            </Box>
          </AlertDialogBody>
          <AlertDialogFooter>
            <Button
              variant="outline"
              mr={3}
              onClick={onBack}
              p="16px 24px"
              w="120px"
              {...ColorSet.Default}
            >
              戻る
            </Button>

            <Button
              type="submit"
              p="16px
              24px"
              w="120px"
              colorScheme="primary"
              isDisabled={!isValid}
            >
              作成
            </Button>
          </AlertDialogFooter>
        </form>
      </>
    );
  },
);
