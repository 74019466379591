import {
  Flex, NumberDecrementStepper, NumberIncrementStepper, NumberInput,
  NumberInputField,
  NumberInputStepper, Text
} from '@chakra-ui/react';
import { FC } from 'react';
import { FieldValues, Path, useFormContext } from 'react-hook-form';

type Props<T> = {
  baseName: Path<T>;
  title: string;
  name: string;
  index: number;
};

export const CheckLimit = <T extends FieldValues>({ baseName, title, name, index }: Props<T>): ReturnType<FC> => {
  const { getValues, register } = useFormContext<T>();

  return (
    <Flex alignItems="center">
      <Text w={140} textAlign="left">
        {title}
      </Text>
      <NumberInput
        w={100}
        min={0}
        mr={4}
        defaultValue={0}
        isDisabled={getValues('uneditableState' as Path<T>)}
      >
        {name === 'check' && (
          <NumberInputField
            {...register(`${baseName}.${index}.checkMin` as Path<T>)}
            backgroundColor="#f9f9f9"
            borderColor="#ccc"
          />
        )}
        {name === 'checkmatrix' && (
          <NumberInputField
            {...register(`${baseName}.${index}.checkMatrixMin` as Path<T>)}
            backgroundColor="#f9f9f9"
            borderColor="#ccc"
          />
        )}
        <NumberInputStepper>
          <NumberIncrementStepper />
          <NumberDecrementStepper />
        </NumberInputStepper>
      </NumberInput>
      個以上選択
      <NumberInput
        w={100}
        min={0}
        mx={4}
        defaultValue={0}
        isDisabled={getValues('uneditableState' as Path<T>)}
      >
        {name === 'check' && (
          <NumberInputField
            {...register(`${baseName}.${index}.checkMax` as Path<T>)}
            backgroundColor="#f9f9f9"
            borderColor="#ccc"
          />
        )}
        {name === 'checkmatrix' && (
          <NumberInputField
            {...register(`${baseName}.${index}.checkMatrixMax` as Path<T>)}
            backgroundColor="#f9f9f9"
            borderColor="#ccc"
          />
        )}
        <NumberInputStepper>
          <NumberIncrementStepper />
          <NumberDecrementStepper />
        </NumberInputStepper>
      </NumberInput>
      個以下選択
    </Flex>
  );
};
