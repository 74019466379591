import { WallOrder } from 'components/paywall/pages/OrderList/typed';

export const defaultWallOrder: WallOrder = {
  id: '',
  courseName: '',
  itemName: '',
  promotionName: '',
  memos: [],
  customerUid: '',
  courseStart: '',
  courseEnd: '',
  coursePrice: 0,
  courseCancelFlag: false,
  courseMonth: 0,
  rightsExpirationDate: '',
  nextTranData: '',
  promotionStart: '',
  promotionEnd: '',
  promotionPrice: 0,
  promotionMonth: 0,
  itemSellType: '',
  itemPeriod: 0,
  itemPeriodStart: '',
  itemPeriodEnd: '',
  itemPrice: 0,
  itemCancelFlag: false,
  tranErrorInfo: '',
  tranErrorMessageCustomer: '',
  canceledAt: '',
  chargebackFlag: false,
  cancelReason: 0,
  tenant: '',
  course: '',
  promotion: '',
  item: '',
  systemCode: '',
  tags: '',
}
