import { Box, Button, ButtonProps, Text, Tooltip } from '@chakra-ui/react';
import { AlertInfoIcon } from 'admin/components/ui/alertInfo/alertInfoIcon';
import { AlertInfoBtnColorSet, ColorSet } from 'admin/define/colors';
import {
  AlertInfoBtnColorType, AlertInfoType
} from 'admin/types/ui/alertInfo';
import { FC, memo, ReactNode, useMemo } from 'react';

type Props = {
  type: AlertInfoType;
  iconType: AlertInfoType;
  children: ReactNode;
  hasAlert?: boolean;
  toolTip?: string;
} & Pick<
  ButtonProps,
  | 'width'
  | 'fontSize'
  | 'onClick'
>;
export const AlertInfoButton: FC<Props> = memo(({
  type,
  iconType,
  children,
  hasAlert = false,
  toolTip = '',
  fontSize = '14px',
  ...rest
}: Props) => {
  const alertInfoBtnColor = useMemo<AlertInfoBtnColorType>(() => {
    if(!hasAlert) return ColorSet.Default as AlertInfoBtnColorType;

    return AlertInfoBtnColorSet[type];
  }, [hasAlert, type]);
  const alertInfoIcon = useMemo<JSX.Element>(() => AlertInfoIcon('16px')[iconType], [iconType]);

  return (
    <Button
      position="relative"
      borderRadius="8px"
      borderWidth="1px"
      borderStyle="solid"
      {...alertInfoBtnColor}
      {...rest}
    >
      <Text
        fontWeight="bold"
        fontSize={fontSize}
        fontFamily="Hiragino Sans"
        lineHeight="170%"
        color="black"
      >
        {children}
      </Text>
      {hasAlert && (
        <Box
          position="absolute"
          top="-12px"
          left="-4px"
        >
          {toolTip && (
            <Tooltip
              label={toolTip}
              bgColor={alertInfoBtnColor.borderColor}
              color="white"
              shouldWrapChildren
            >
              {alertInfoIcon}
            </Tooltip>
          )}
          {!toolTip && alertInfoIcon}
        </Box>
      )}
    </Button>
  )
});

// defaultPropsの宣言を追加
AlertInfoButton.defaultProps = {
  hasAlert: false,
  toolTip: '',
};

