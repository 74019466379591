import { Button, Flex, Text, Tooltip } from '@chakra-ui/react';
import { EditIcon } from 'admin/components/ui/icon/editIcon';
import { StatusLabel as Status } from 'admin/components/ui/status/StatusLabel';
import { Td, Tr } from 'admin/components/ui/table';
import { Colors } from 'admin/define/colors';
import { NewsletterType } from 'admin/types/userPool/newsletter';
import { Dispatch, FC, memo, SetStateAction, useMemo } from 'react';

type Props = {
  newsletter: NewsletterType;
  onOpen: (id: NewsletterType['id']) => void;
  onOpenStatusDialog: () => void;
  setTargetNewsletter: Dispatch<SetStateAction<NewsletterType | undefined>>;
  onOpenMypageModalHandler: (
    id: NewsletterType['isDisplayAccountSettings'],
  ) => void;
};

export const NewsletterTableBody: FC<Props> = memo(
  ({
    newsletter,
    onOpen,
    onOpenStatusDialog,
    setTargetNewsletter,
    onOpenMypageModalHandler,
  }: Props) => {
    const viewIsDisplayMyPage = useMemo<boolean>(() => {
      if (!newsletter) return false;

      return newsletter.isDisplayAccountSettings;
    }, [newsletter]);

    const trimMemo = useMemo(
      () =>
        newsletter.memo.length > 25
          ? `${newsletter.memo.substring(0, 25)} ...`
          : newsletter.memo,
      [newsletter.memo],
    );

    return (
      <Tr>
        <Td fontSize="14px" maxWidth="150px">
          <Text
            color={Colors.BLUE_20}
            cursor="pointer"
            textDecoration="underline"
            fontWeight="bold"
            onClick={() => onOpen(newsletter.id)}
          >
            {newsletter.id}
          </Text>
        </Td>
        <Td fontSize="14px" maxWidth="180px">
          {newsletter.displayNameJa}
        </Td>
        <Td fontSize="14px" maxWidth="180px">
          {newsletter.displayNameForeign || '-'}
        </Td>
        <Td fontSize="14px" maxWidth="120px">
          {newsletter.fieldName}
        </Td>
        <Td fontSize="14px" maxWidth="240px">
          <Tooltip
            wordBreak="break-all"
            overflowWrap="break-word"
            isDisabled={!newsletter.memo}
            label={
              <Text
                whiteSpace="pre-wrap"
                height="auto"
                wordBreak="break-all"
                overflowWrap="break-word"
              >
                {newsletter.memo}
              </Text>
            }
          >
            {trimMemo || '-'}
          </Tooltip>
        </Td>
        <Td fontSize="14px">
          <Flex gridGap={2} alignItems="center">
            <Status
              onLabel="確定"
              offLabel="下書き"
              status={!!newsletter.status}
            />
            <Button
              variant="outline"
              colorScheme="primary"
              bgColor="WHITE"
              w="32px"
              h="32px"
              p="0"
              isDisabled={newsletter.status}
              onClick={() => {
                setTargetNewsletter(newsletter);
                onOpenStatusDialog();
              }}
            >
              <EditIcon />
            </Button>
          </Flex>
        </Td>
        <Td fontSize="14px">
          <Flex gridGap={2} alignItems="center">
            <Status onLabel="ON" offLabel="OFF" status={viewIsDisplayMyPage} />
            <Button
              variant="outline"
              colorScheme="primary"
              bgColor="WHITE"
              w="32px"
              h="32px"
              p="0"
              isDisabled={!newsletter.status}
              onClick={() => {
                setTargetNewsletter(newsletter);
                onOpenMypageModalHandler(viewIsDisplayMyPage);
              }}
            >
              <EditIcon />
            </Button>
          </Flex>
        </Td>
        <Td fontSize="14px">{newsletter.displayOrder}</Td>
      </Tr>
    );
  },
);
