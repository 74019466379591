import { textareaElementOptions } from './options';

const groupName = 'embeddedTags';

export default [
  {
    name: `${groupName}.head`,
    label: 'Head用タグ',
    tooltip: '',
    type: 'Textarea',
    defaultValue: '',
    options: {},
    elementOptions: {
      ...textareaElementOptions,
      rows: 10,
    },
  },
  {
    name: `${groupName}.body`,
    label: 'Body用タグ',
    tooltip: '',
    type: 'Textarea',
    defaultValue: '',
    options: {},
    elementOptions: {
      ...textareaElementOptions,
      rows: 10,
    },
  },
] as const;
