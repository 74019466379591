import { zodResolver } from '@hookform/resolvers/zod';
import { NewsletterEditFormComponent } from 'admin/components/idPoolConsumer/newsletter/newsletterForm/newsletterEditForm/NewsletterEditFormComponent';
import { useIdPoolConsumerId } from 'admin/hooks/useIdPoolConsumerId';
import { useEditNewsletter } from 'admin/hooks/userPool/newsletter/useEditNewsletter';
import { newsletterFormSchema } from 'admin/schema/idPoolConsumer/newsletter';
import { NewsletterType } from 'admin/types/userPool/newsletter';
import { NewsletterFormType } from 'admin/types/userPool/newsletter/form';
import { AlertBar } from 'components/common/atoms';
import { FC, memo, useMemo, useState } from 'react';
import { FormProvider, SubmitHandler, useForm } from 'react-hook-form';

type Props = {
  newsletterDetail: NewsletterType;
  onClose: () => void;
};

export const NewsletterEditForm: FC<Props> = memo(
  ({ newsletterDetail, onClose }: Props) => {
    const formId = 'newsletterEditForm';
    const idPoolConsumerId = useIdPoolConsumerId();
    const methods = useForm<NewsletterFormType>({
      defaultValues: {
        id: newsletterDetail.id,
        displayNameJa: newsletterDetail.displayNameJa,
        displayNameForeign: newsletterDetail.displayNameForeign,
        fieldName: newsletterDetail.fieldName,
        displayOrder: newsletterDetail.displayOrder ?? undefined,
        memo: newsletterDetail.memo,
      },
      resolver: zodResolver(newsletterFormSchema),
    });

    const status = useMemo(() => newsletterDetail.status, [newsletterDetail]);

    const [globalErrors, setGlobalErrors] = useState<string[]>([]);
    const { setError, reset } = methods;

    const { mutate, isLoading } = useEditNewsletter({
      id: newsletterDetail.id,
      userPoolId: idPoolConsumerId,
      setError,
      setGlobalErrors,
    });

    const onSubmit: SubmitHandler<NewsletterFormType> = async (
      data: NewsletterFormType,
    ) => {
      if (!isLoading) {
        await mutate(data);
      }
      reset();
      onClose();
    };

    return (
      <FormProvider {...methods}>
        {globalErrors.map((err, idx) => (
          <AlertBar
            key={`global-err-idx${String(idx)}`}
            message={err}
            status="error"
            mb={4}
          />
        ))}
        <NewsletterEditFormComponent
          formId={formId}
          isLoading={isLoading}
          onClose={onClose}
          onSubmit={onSubmit}
          status={status}
        />
      </FormProvider>
    );
  },
);
