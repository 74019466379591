export const messages = {
  // コンテンツシェルフ登録処理
  createContentsTable: {
    customer: {
      error:
        'テーブル登録できませんでした。恐れ入りますが時間をおいて再度登録をお願いします。',
    },
    system: {
      failed:
        '[api.createContentsTable]コンテンツテーブル登録APIのエラーレスポンス型が予期せぬデータ型です。フロントの修正をしてください',
      typeError:
        '[api.createContentsTable]コンテンツテーブル登録APIのレスポンス型が予期せぬデータ型です。フロントの修正をしてください',
    },
  },
  // コンテンツシェルフ 登録するファイル情報を登録
  createUploadData: {
    customer: {
      error: 'ファイル情報の登録に失敗しました',
      typeError: 'ファイル情報を登録できませんでした',
    },
    system: {
      typeError:
        '[api.contents.createUploadData] コンテンツシェルフアップロード登録APIのレスポンス型が予期せぬデータ型です。フロントの修正をしてください',
    },
  },
  // コンテンツシェルフ テーブルデータ取得
  getContentsTable: {
    customer: {
      notFound: 'コンテンツが存在しません。既に削除された可能性があります。',
      error:
        '申し訳ございません。コンテンツを取得できません。時間を置いて再表示をお願いします。',
      typeError: '申し訳ございません。コンテンツデータ表示できません',
    },
    system: {
      typeError:
        '[api.contents.getContentsTable] コンテンツシェルフ テーブル情報取得APIのレスポンス型が予期せぬデータ型です。フロントの修正をしてください',
    },
  },
  getContentsTableList: {
    customer: {
      notFound: 'コンテンツが存在しません。既に削除された可能性があります。',
      error:
        '申し訳ございません。コンテンツデータを取得できません。時間を置いて再表示をお願いします。',
      typeError: '申し訳ございません。コンテンツデータを参照できません。',
    },
    system: {
      responseError:
        '[api.contents.getContentsTableList] コンテンツシェルフテーブル取得エラー',
      typeError: '[api.contents.getContentsTableList] レスポンス型不一致',
    },
  },
  getData: {
    customer: {
      error:
        '申し訳ございません。データを取得できません。時間を置いて再表示をお願いします。',
      typeError: '申し訳ございません。データを表示できません',
    },
    system: {
      typeError:
        '[api.contents.getData] コンテンツシェルフ 検索APIのレスポンス型が予期せぬデータ型です。フロントの修正をしてください',
    },
  },
  getUploadHistory: {
    customer: {
      notFound: 'コンテンツが存在しません。既に削除された可能性があります。',
      error:
        '申し訳ございません。履歴を取得できません。時間を置いて再表示をお願いします。',
      typeError: '申し訳ございません。データを表示できません',
    },
    system: {
      typeError:
        '[api.contents.getUploadHistory] コンテンツシェルフ アップロード履歴APIのレスポンス型が予期せぬデータ型です。フロントの修正をしてください',
    },
  },
  isTblExits: {
    customer: {
      noAuth: '認証に失敗しました。恐れ入りますが再度ログインをお願いします。',
      error:
        '申し訳ございません。テーブルを取得できません。時間を置いて再表示をお願いします。',
      typeError:
        'データ不正につき表示できません。恐れ入りますが画面の再読み込みをお願いします。',
    },
    system: {
      typeError: '',
    },
  },
  updateContentsTable: {
    customer: {
      error:
        'テーブル更新できませんでした。恐れ入りますが時間をおいて再度登録をお願いします。',
    },
    system: {
      responseError:
        '[api.contents.updateContentsTable]コンテンツテーブル更新APIのエラーレスポンス型が予期せぬデータ型です。フロントの修正をしてください',
      typeError:
        '[api.contents.updateContentsTable]コンテンツテーブル更新APIのレスポンス型が予期せぬデータ型です。フロントの修正をしてください',
    },
  },
  updatePublish: {
    customer: {
      error:
        '保存できませんでした。恐れ入りますが時間をおいて再度登録をお願いします。',
    },
    system: {
      responseError:
        '[api.contents.updatePublish] コンテンツテーブル公開設定APIのエラーレスポンス型が予期せぬデータ型です。フロントの修正をしてください',
      typeError:
        '[api.contents.updatePublish] コンテンツテーブル公開設定APIのレスポンス型が予期せぬデータ型です。フロントの修正をしてください',
    },
  },
  // 高度な検索 JSON文字列生成API
  createSearchFilter: {
    customer: {
      error:
        '申し訳ございません。検索条件の変換に失敗しました。時間を置いて再表示をお願いします。',
      failed: '申し訳ございません。検索条件を表示できません',
    },
    system: {
      typeError:
        '[api.contents.createSearchFilter] コンテンツシェルフ 高度な検索条件生成APIのレスポンス型が予期せぬデータ型です。フロントの修正をしてください',
    },
  },
  // データ検索 API
  getSearchData: {
    customer: {
      error:
        '申し訳ございません。データを取得できません。時間を置いて再表示をお願いします。',
      failed: '申し訳ございません。データを表示できません',
    },
    system: {
      typeError:
        '[api.contents.getSearchData] コンテンツシェルフ 検索APIのレスポンス型が予期せぬデータ型です。フロントの修正をしてください',
    },
  },
};
