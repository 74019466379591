import { ButtonProps } from '@chakra-ui/react';
import { ChangeEvent, useCallback, useEffect, useState } from 'react';
import { useLocation } from 'react-router-dom';
import { atom, useRecoilState } from 'recoil';
/*
const isCheckedContext = atom<boolean>({
  key: 'global/isCheckedContext',
  default: false,
});
const isAllCheckedContext = atom<boolean>({
  key: 'global/isAllCheckedContext',
  default: false,
});
const checkedIdContext = atom<string[]>({
  key: 'global/checkedIdContext',
  default: [],
});
*/

export const useCheckUtility = () => {
  const [isChecked, setIsChecked] = useState(false);
  const [isAllChecked, setIsAllChecked] = useState(false);
  const [checkedId, setCheckedId] = useState<string[]>([]);

  const handleChecked = useCallback(
    (e: React.ChangeEvent<HTMLInputElement>) => {
      setIsChecked(e.currentTarget.checked);
    },
    [setIsChecked],
  );

  const handleCheckAll = useCallback(
    (e: ChangeEvent<HTMLInputElement>): void => {
      setIsAllChecked(e.currentTarget.checked);
    },
    [setIsAllChecked],
  );

  const handleCheckId = useCallback(
    (id: string): void => {
      setCheckedId([...checkedId, id]);
    },
    [checkedId, setCheckedId],
  );

  const handleUnCheckId = useCallback(
    (id: string): void => {
      setCheckedId(checkedId.filter((d) => d !== id));
    },
    [checkedId, setCheckedId],
  );

  const handleClickCheckId = useCallback(
    (e: ChangeEvent<HTMLInputElement>, id: string): void => {
      if (e.currentTarget.checked) handleCheckId(id);
      if (!e.currentTarget.checked) handleUnCheckId(id);
    },
    [handleCheckId, handleUnCheckId],
  );
  const findIsCheckId = useCallback(
    (id: string): boolean => {
      const found = checkedId.find((d) => d === id);

      return !!found;
    },
    [checkedId],
  );

  return {
    isChecked,
    setIsChecked,
    handleChecked,
    isAllChecked,
    setCheckedId,
    handleCheckAll,
    handleClickCheckId,
    findIsCheckId,
  };
};

const isOpenModalContext = atom<boolean>({
  key: 'global/isOpenModalContext',
  default: false,
});
type ModalProps = {
  submitModal?: () => void;
};
export const useModalUtility = ({ submitModal }: ModalProps) => {
  const [isOpenModal, setIsOpenModal] = useRecoilState(isOpenModalContext);

  const handleOpenModal = useCallback(() => {
    setIsOpenModal(true);
  }, [setIsOpenModal]);
  const handleCloseModal = useCallback(() => {
    setIsOpenModal(false);
  }, [setIsOpenModal]);
  const handleSubmitModal = useCallback(() => {
    setIsOpenModal(false);
    if (submitModal) {
      submitModal();
    }
  }, [setIsOpenModal, submitModal]);

  return { isOpenModal, handleOpenModal, handleCloseModal, handleSubmitModal };
};

/* global */
const isOpenDialogContext = atom<boolean>({
  key: 'global/isOpenDialogContext',
  default: false,
});
const dialogTitleContext = atom<string>({
  key: 'global/dialogTitleContext',
  default: '',
});
const dialogElementContext = atom<React.ReactNode>({
  key: 'global/dialogElementContext',
  default: undefined,
});
const dialogSubmitContext = atom<{ fn: (arg?: boolean) => void }>({
  key: 'global/dialogSubmitContext',
  default: { fn: () => 0 },
});
type dialogProps = {
  submitButtonName?: string;
  cancelButtonName?: string;
  submitButtonColorScheme?: ButtonProps['colorScheme'];
  cancelButtonColorScheme?: ButtonProps['colorScheme'];
};
const initialDialogProps = {
  submitButtonName: '保存',
  cancelButtonName: 'キャンセル',
  submitButtonColorScheme: 'blue',
  cancelButtonColorScheme: 'gray',
};
const dialogPropsObjContext = atom<dialogProps>({
  key: 'global/dialogPropsObjContext',
  default: initialDialogProps,
});

export const useDialogUtility = () => {
  const [isOpenDialog, setIsOpenDialog] = useRecoilState(isOpenDialogContext);
  const [dialogTitle, setDialogTitle] = useRecoilState(dialogTitleContext);
  const [dialogElement, setDialogElement] =
    useRecoilState(dialogElementContext);
  const [dialogSubmit, setDialogSubmit] = useRecoilState(dialogSubmitContext);
  const [dialogPropsObj, setDialogPropsObj] = useRecoilState(
    dialogPropsObjContext,
  );

  const initializeDialog = useCallback(() => {
    setDialogTitle('');
    setDialogElement(undefined);
    setDialogSubmit({ fn: () => 0 });
    setDialogPropsObj(initialDialogProps);
  }, [setDialogElement, setDialogPropsObj, setDialogSubmit, setDialogTitle]);

  const handleOpenDialog = useCallback(() => {
    setIsOpenDialog(true);
  }, [setIsOpenDialog]);

  const handleCloseDialog = useCallback(() => {
    setIsOpenDialog(false);
    initializeDialog();
  }, [initializeDialog, setIsOpenDialog]);

  const handleSubmitDialog = useCallback(() => {
    dialogSubmit.fn(true);
    setIsOpenDialog(false);
  }, [dialogSubmit, setIsOpenDialog]);

  return {
    isOpenDialog,
    handleOpenDialog,
    handleCloseDialog,
    handleSubmitDialog,
    dialogTitle,
    setDialogTitle,
    dialogElement,
    setDialogElement,
    setIsOpenDialog,
    setDialogSubmit,
    dialogPropsObj,
    setDialogPropsObj,
    initializeDialog,
  };
};

const isOpenPopperContext = atom<boolean>({
  key: 'global/isOpenPopperContext',
  default: false,
});
const popperContentContext = atom<React.ReactElement | null>({
  key: 'global/popperContentContext',
  default: null,
});
const positionContext = atom<{ top: number; left: number }>({
  key: 'global/positionContext',
  default: { top: 0, left: 0 },
});

export const usePopperUtility = () => {
  const [isOpenPopper, setIsOpenPopper] = useRecoilState(isOpenPopperContext);
  const [popperContent, setPopperContent] =
    useRecoilState(popperContentContext);
  const [position, setPosition] = useRecoilState(positionContext);

  const handleOpenPopper = useCallback(
    (e: React.MouseEvent<HTMLElement>, element: React.ReactElement): void => {
      const { top, left, height } = e.currentTarget.getBoundingClientRect();
      setPosition({ top, left });

      const sx = document.documentElement.scrollLeft;
      const sy = document.documentElement.scrollTop;
      /* 複数個を使いそうにないので決め打ち */
      document.querySelector('#tablePopper')?.animate(
        [
          {
            position: 'absolute',
            visibility: 'visible',
            opacity: 0,
            top: `${top + sy}px`,
            left: `${left + sx}px`,
          },
          {
            position: 'absolute',
            visibility: 'visible',
            opacity: 1,
            top: `${top + sy + height}px`,
            left: `${left + sx}px`,
          },
        ],
        {
          duration: 100,
          fill: 'forwards',
        },
      );

      setPopperContent(element);
      setIsOpenPopper(true);
    },
    [setIsOpenPopper, setPopperContent, setPosition],
  );

  const handleClosePopper = useCallback((): void => {
    setIsOpenPopper(false);
  }, [setIsOpenPopper]);

  useEffect(() => {
    if (!isOpenPopper) return;
    const ADJUST_X = 50;
    const ADJUST_Y = 20;
    const sx = document.documentElement.scrollLeft;
    const sy = document.documentElement.scrollTop;

    const popperBoxElement = document.querySelector('#popperBox');
    if (!popperBoxElement) return;
    const { width, height } = popperBoxElement.getBoundingClientRect();

    if (width + position.left + sx > window.innerWidth) {
      document.querySelector('#tablePopper')?.animate(
        [
          {
            left: `${window.innerWidth - width - ADJUST_X}px`,
          },
        ],
        0,
      );
    }
    if (height + position.top + sy > window.innerHeight) {
      document.querySelector('#tablePopper')?.animate(
        [
          {
            top: `${window.innerHeight - height - ADJUST_Y}px`,
          },
        ],
        0,
      );
    }
  }, [isOpenPopper, position.left, position.top]);

  return {
    isOpenPopper,
    handleOpenPopper,
    handleClosePopper,
    popperContent,
  };
};

const isOpenSnackbarContext = atom<boolean>({
  key: 'global/isOpenSnackbarContext',
  default: false,
});
const snackbarTextContext = atom<string>({
  key: 'global/snackbarTextContext',
  default: '',
});
const snackbarThemeContext = atom<'success' | 'error' | undefined>({
  key: 'global/snackbarThemeContext',
  default: 'success',
});

export const useSnackbarUtility = () => {
  const [isOpenSnackbar, setIsOpenSnackbar] = useRecoilState(
    isOpenSnackbarContext,
  );
  const [snackbarTheme, setSnackbarTheme] =
    useRecoilState(snackbarThemeContext);
  const [snackbarText, setSnackbarText] = useRecoilState(snackbarTextContext);
  const handleOpenSnackbar = useCallback(() => {
    setIsOpenSnackbar(true);
  }, [setIsOpenSnackbar]);
  const handleOpenSnackbarAlert = useCallback(() => {
    setIsOpenSnackbar(true);
    setSnackbarTheme('error');
  }, [setIsOpenSnackbar, setSnackbarTheme]);
  const handleCloseSnackbar = useCallback(() => {
    setIsOpenSnackbar(false);
    setSnackbarTheme('success');
  }, [setIsOpenSnackbar, setSnackbarTheme]);

  return {
    isOpenSnackbar,
    handleOpenSnackbar,
    handleOpenSnackbarAlert,
    handleCloseSnackbar,
    snackbarText,
    setSnackbarText,
    snackbarTheme,
    setSnackbarTheme,
  };
};

const pagePathContext = atom<string>({
  key: 'page/pagePathContext',
  default: '',
});

const IsPageTransitionContext = atom<boolean>({
  key: 'page/IsPageTransitionContext',
  default: false,
});

export const usePageUtilities = () => {
  const location = useLocation();
  const [pagePath, setPagePath] = useRecoilState(pagePathContext);
  const [isPageTransition, setIsPageTransition] = useRecoilState(
    IsPageTransitionContext,
  );

  useEffect(() => {
    if (pagePath === location.pathname) return;
    if (isPageTransition) return;

    setPagePath(location.pathname);
    setIsPageTransition(true);
  }, [
    isPageTransition,
    location.pathname,
    pagePath,
    setIsPageTransition,
    setPagePath,
  ]);

  return {
    pagePath,
    isPageTransition,
    setIsPageTransition,
  };
};
