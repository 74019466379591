import { ServiceType } from 'admin/types/service';
import { ServiceNewsletterType } from 'admin/types/service/newsletter';
import { Page } from 'api/common/types';

export const queryNewsletterKeyNames = {
  ServiceNewsletter: 'ServiceNewsletter',
  ServiceNewsletterDetail: 'ServiceNewsletterDetail',
} as const;

export const queryNewsletterKey = {
  getServiceNewsletter: (
    userPoolChildId: ServiceType['userPoolChildId'],
    currentPage: Page['currentPage'],
    perPage?: Page['perPage'],
  ) => [
    queryNewsletterKeyNames.ServiceNewsletter,
    userPoolChildId,
    currentPage,
    perPage,
  ],
  getServiceNewsletterDetail: (newsletterId: ServiceNewsletterType['id']) => [
    queryNewsletterKeyNames.ServiceNewsletterDetail,
    newsletterId,
  ],
};
