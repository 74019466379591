import { request } from 'admin/api/Request';
import { API_MESSAGES } from 'admin/define/apiMessages';
import {
  SECURITY_SETTING_AUTH_HUB,
  SECURITY_SETTING_RECAPTCHA,
} from 'admin/define/idPoolConsumer/auth';
import { CustomError } from 'admin/error/CustomError';
import { userPoolSchema } from 'admin/schema/userPool';
import {
  SecuritySettingLabelsExcludeToken,
  SecuritySettings,
} from 'admin/types/userPool/auth';
import { SecuritySettingsFormType } from 'admin/types/userPool/auth/form';
import { ZodError } from 'zod';

/**
 * トークン以外のauthHub, recaptchaの更新処理
 */
export const updateSecuritySettingsExcludeToken = async (
  userPoolGroupId: string,
  data: SecuritySettingsFormType,
  settingType: SecuritySettingLabelsExcludeToken,
): Promise<SecuritySettings> => {
  // エラーメッセージの設定
  const errorMessages = {
    error: '',
    failed: '',
  };

  switch (settingType) {
    case SECURITY_SETTING_AUTH_HUB:
      errorMessages.error = API_MESSAGES.USER_POOL.EDIT_AUTH_HUB.ERROR;
      errorMessages.failed = API_MESSAGES.USER_POOL.EDIT_AUTH_HUB.FAILED;
      break;
    case SECURITY_SETTING_RECAPTCHA:
      errorMessages.error = API_MESSAGES.USER_POOL.EDIT_RECAPTCHA.ERROR;
      errorMessages.failed = API_MESSAGES.USER_POOL.EDIT_RECAPTCHA.FAILED;
      break;
    default:
      break;
  }

  const { id, ...params } = data;
  const path = `uniikey/${userPoolGroupId}/user_pool/${id}/`;
  const response = await request<SecuritySettings>({
    path,
    body: params,
    method: 'patch',
  });

  if (response.hasError) {
    if (response.status === 404) {
      throw new CustomError(errorMessages.failed, {
        path,
        status: response.status,
      });
    } else {
      throw new CustomError(errorMessages.error, {
        path,
        status: response.status,
      });
    }
  }
  try {
    userPoolSchema.parse(response.data);
  } catch (error) {
    if (error instanceof ZodError) {
      throw new CustomError(error.message, {
        path,
        status: response.status,
        logLevel: 'error',
      });
    }
  }

  return response.data;
};
