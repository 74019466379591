/* eslint-disable @typescript-eslint/no-unused-vars */
import { UseMutateFunction, useMutation, useQueryClient } from 'react-query';
import { ResponseError } from 'api/types';
import { putNoticeType, isPutNotice, updateNoticeType } from 'api/notice/types';
import { putNotice } from 'api/notice/putNotice';
import { isGetValidationError } from 'utils/form';
import { useUserTenantId } from 'hooks/user/useUserTenantId';
import { useCustomToast } from 'hooks/useCustomToast';
import { queryNoticeKey } from './queryNoticeKey';

type UnPromisify<T> = T extends Promise<infer U> ? U : T;

const formData: updateNoticeType = {
  id: '',
  tenantId: '',
  isReaded: false,
};

/**
 * メッセージ既読更新 hooks
 * @returns {Object} { onSubmit, isLoading }
 */
export const useUpdateNotice = (): {
  onSubmit: UseMutateFunction<
    UnPromisify<ReturnType<typeof putNotice>>,
    unknown,
    updateNoticeType,
    unknown
  >;
  isLoading: boolean;
} => {
  const queryClient = useQueryClient();
  const tenantId = useUserTenantId();
  const toast = useCustomToast();

  const { mutate: onSubmit, isLoading } = useMutation(
    (updateNoticeData: updateNoticeType) => putNotice(updateNoticeData),
    {
      onSuccess: (result: putNoticeType | ResponseError) => {
        if (isPutNotice(result)) {
          void queryClient.refetchQueries(
            queryNoticeKey.getNotice({
              tenantId,
              page: result.page,
            }),
          );
        }

        if (
          isGetValidationError<updateNoticeType>(result, Object.keys(formData))
        ) {
          const errorMsg = Object.entries(result).map(([_, value]) => value);
          toast({
            status: 'error',
            position: 'bottom',
            duration: 4000,
            isClosable: true,
            title: '通知更新に失敗しました',
            description: errorMsg[0],
          });
        }
      },
    },
  );

  return { onSubmit, isLoading };
};
