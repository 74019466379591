import { Text } from '@chakra-ui/react';
import { PaywallCardItem } from 'components/paywall//pages/WallList/PaywallCardList/PaywallCardItem';
import { Colors } from 'components/paywall/config';
import { CardItems } from 'components/paywall/features/Card';
import { Course } from 'components/paywall/pages/CourseList/typed';
import { FC, memo } from 'react';

type Props = {
  courses: Course[];
}

export const PaywallCardItems: FC<Props> = memo(({courses}: Props) => {

  // コースを持っているかどうか
  const hasCourses = (): boolean => courses.length > 0

  return (
    <CardItems>
      {!hasCourses() && (
        <Text fontSize="14px" fontWeight="400" lineHeight="140%" color={Colors.GRAY_10}>コースが設定されていません。「コース設定」からコースを設定してください。</Text>
      )}

      {hasCourses() && courses.map((course) => (
        <PaywallCardItem key={course.id} course={course} />
      ))}
    </CardItems>
  )
})
