import { updateService } from 'admin/api/service/updateService';
import { CustomError } from 'admin/error/CustomError';
import { useUserCorporationId } from 'admin/hooks/user/useUserCorporationId';
import { useUserCorporationInfo } from 'admin/hooks/user/useUserCorporationInfo';
import { ServiceFormType } from 'admin/types/service/form';
import { logger } from 'api/logger';
import { useCustomToast } from 'hooks/useCustomToast';
import { Dispatch, SetStateAction } from 'react';
import { UseFormSetError } from 'react-hook-form';
import { useMutation, useQueryClient } from 'react-query';
import { toMultiError } from 'utils/form';
import { errorToast, successToast } from 'utils/toast';
import { queryIdPoolConsumersKey } from '../idPoolConsumers/queryIdPoolConsumersKey';
import { queryServiceKey } from './queryServiceKey';

export const useUpdateService = (
  setError: UseFormSetError<ServiceFormType>,
  setGlobalErrors: Dispatch<SetStateAction<string[]>>,
) => {
  const toast = useCustomToast();
  const queryClient = useQueryClient();
  const corporationId = useUserCorporationId();
  const { currentUserPoolGroupId } = useUserCorporationInfo();

  const { mutateAsync, isLoading } = useMutation({
    mutationFn: (data: ServiceFormType) => updateService(data),
    onSuccess: async () => {
      await queryClient.invalidateQueries(
        queryServiceKey.getServices(corporationId),
      );
      await queryClient.invalidateQueries(
        queryIdPoolConsumersKey.getIdPoolConsumers(
          currentUserPoolGroupId || '',
        ),
      );
      toast({
        ...successToast,
        duration: 4000,
        title: 'ユーザープール割り当てを行いました',
        position: 'bottom',
        variant: 'solid',
      });
    },
    onError: async (error, variables: ServiceFormType) => {
      if (error instanceof CustomError) {
        if (error.cause.errors?.others) {
          const othersMsgs = Array.isArray(error.cause.errors?.others)
            ? error.cause.errors?.others
            : [error.cause.errors?.others];

          setGlobalErrors([othersMsgs.join('')]);
        } else {
          Object.keys(variables).forEach((k) => {
            const key = k as keyof ServiceFormType;
            const errMsgs = error.cause.errors?.[key];
            if (errMsgs && Array.isArray(errMsgs)) {
              setError(key, { types: toMultiError(errMsgs) });
            }
          });
        }

        if (error.cause.errors) {
          await logger({
            loglevel: error.logLevel,
            data: JSON.stringify(error.cause),
            message: error.message,
          });
        }
      }
      toast({
        ...errorToast,
        duration: 4000,
        title: 'ユーザープール割り当てに失敗しました',
        position: 'bottom',
        variant: 'solid',
      });
    },
  });

  return { mutate: mutateAsync, isLoading };
};
