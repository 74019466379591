import { useCallback, useState, memo } from 'react';
import {
  NumberInput,
  NumberInputField,
  NumberInputStepper,
  NumberIncrementStepper,
  NumberDecrementStepper,
  NumberInputProps,
  Flex,
  Button,
} from '@chakra-ui/react';

export type SchemaAddRowProps<T> = {
  // 1行分の白紙データ
  templateData: T[];
  setData: React.Dispatch<React.SetStateAction<T[][]>>;
} & NumberInputProps;

export const SchemaAddRow = <T,>({
  templateData,
  setData,
  size = 'sm',
  step = 10,
  defaultValue = 10,
  min = 10,
  max = 100,
  ...rest
}: SchemaAddRowProps<T>): JSX.Element => {
  const [row, setRow] = useState<number>(
    typeof defaultValue === 'number' ? defaultValue : 10,
  );

  const handleChange = useCallback(
    (inputValue: string) => {
      if (/^[1-9]+([0-9]+)?$/.test(inputValue)) {
        setRow(Number(inputValue));
      }
    },
    [setRow],
  );

  const btnHandler = useCallback(() => {
    const addData = [...Array(row).keys()].map(() => [...templateData]);

    setData((current) => [...current, ...addData]);
  }, [row, setData, templateData]);

  return (
    <Flex>
      <NumberInput
        w={20}
        size={size}
        step={step}
        defaultValue={defaultValue}
        min={min}
        max={max}
        onChange={handleChange}
        {...rest}
      >
        <NumberInputField />
        <NumberInputStepper>
          <NumberIncrementStepper />
          <NumberDecrementStepper />
        </NumberInputStepper>
      </NumberInput>

      <Button ml={4} onClick={btnHandler}>
        行追加
      </Button>
    </Flex>
  );
};

export const SchemaAddRowBtn = memo(SchemaAddRow) as typeof SchemaAddRow;
