import { defaultWallOrder } from 'components/paywall/pages/OrderList/initial';
import { OrderDetailResult } from 'components/paywall/pages/OrderList/typed';

export const defaultWallOrderDetail: OrderDetailResult = {
  id: '',
  wallOrder: defaultWallOrder,
  email: '',
  createdAt: '',
  execTranDate: '',
  customer: {
    address: '',
    lastName: '',
    firstName: '',
    prefName: '',
    tel: '',
    zip: '',
    isActive: true,
  },
  customerUid: '',
  tranJobCode: '',
  cardSeq: 0,
  price: 0,
  tranErrorCode: '',
  tranErrorInfo: '',
  tranErrorMessageSystem: '',
  tranErrorMessageCustomer: '',
  cancelTranDate: '',
  cancelTranId: '',
  cardErrorInfo: [],
  tranStatus: 0,
}
