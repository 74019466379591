import { PublicChangeComponent } from 'admin/components/service/terms/publicChangeModal/PublicChangeComponent';
import { LoadingLayer } from 'admin/components/ui/loadingLayer';
import { useServiceCreateTerm } from 'admin/hooks/service/terms/useServiceCreateTerm';
import { useServiceDeleteTerm } from 'admin/hooks/service/terms/useServiceDeleteTerm';
import { ServiceTermType } from 'admin/types/service/terms';
import { FC, memo, useCallback, useMemo } from 'react';
import { FormProvider, useForm } from 'react-hook-form';

export type Props = {
  term?: ServiceTermType;
  onClose: () => void;
};

export const PublicChangeModal: FC<Props> = memo(({ onClose, term }) => {
  const formId = 'publicChangeModal';
  const { mutate: createTerm, isLoading: isCreateLoading } =
    useServiceCreateTerm(term?.id ?? '');
  const { mutate: deleteTerm, isLoading: isDeleteLoading } =
    useServiceDeleteTerm(term?.id ?? '');

  const isLoading = useMemo(
    () => isCreateLoading || isDeleteLoading,
    [isCreateLoading, isDeleteLoading],
  );

  const methods = useForm({
    mode: 'onBlur',
  });

  const onSubmit = useCallback(async () => {
    if (term?.isPublic) {
      await deleteTerm();
    } else {
      await createTerm();
    }
    onClose();
  }, [createTerm, deleteTerm, onClose]);

  return (
    <FormProvider {...methods}>
      {isLoading && <LoadingLayer />}
      <PublicChangeComponent
        target={term}
        formId={formId}
        isLoading={isLoading}
        onClose={onClose}
        onSubmit={onSubmit}
      />
    </FormProvider>
  );
});
