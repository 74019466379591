import { PERMISSION_MANAGER } from 'define';
import { useUserInfo } from 'hooks/useUserInfo';
import { useCallback } from 'react';

export const usePaywallUtil = () => {
  const { isEditableTarget } = useUserInfo();
  // 権限の有無
  // 権限がない場合はfalse
  const isOperationAuth = (): boolean => isEditableTarget(PERMISSION_MANAGER)

  // 表示テキストの整形
  const formatText = useCallback(
    ({
      text,
      trimCnt = 20
    }: {
      text: string | number | boolean,
      trimCnt?: number
    }): {
      originText: string;
      trimText: string;
      isDisabled: boolean;
    } => {
      if (text === '' || text === null || text === undefined) {
        return {
          originText: '',
          trimText: '',
          isDisabled: true,
        };
      }

      const originText = String(text);
      const trimText =
        originText.length > trimCnt
          ? `${originText.substring(0, trimCnt)} ...`
          : originText;

      return {
        originText,
        trimText,
        isDisabled: originText.length <= trimCnt,
      };
    },
    [],
  );

  /**
   * Tを含む日付文字列から日付へ変換
   * @param dateStr string 日付文字列
   * @param mode 'date' | 'dateTime'
   * @param delimiter default /
   * @returns String 年月日 | 年月日時
   */
  const formatToDateForStringInnerT = ({
    date,
    mode = 'date',
    delimiter = '/'
  }:{
    date: string,
    mode?: 'date' | 'dateTime';
    delimiter?: string;
  }): string => {
    const regex = RegExp(/(.+)\s(.+):(.+)/).exec(date);
    if(!regex) return '';
    const dates = regex[1].split('-');

    let result = `${dates[0]}${delimiter}${dates[1]}${delimiter}${dates[2]}`;
    if(mode === 'dateTime') result += ` ${regex[2]}:${regex[3]}`;

    return result;
  }

  return {
    isOperationAuth,
    formatText,
    formatToDateForStringInnerT,
  };
};
