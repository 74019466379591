import { useRecoilValue } from 'recoil';
import { useParams } from 'react-router-dom';
import { tableIdState } from 'store/contentsdb';
import { useUserTenantId } from 'hooks/user/useUserTenantId';

/**
 * コンテンツシェルフ のサイドメニューにて
 * 選択したテーブルのIDを取得
 */
export const useTableId = (): string => {
  const { tableId = '' } = useParams();

  return tableId;
};

type useTenantTblIdReturnType = {
  tenantId: ReturnType<typeof useUserTenantId>;
  tableId: string;
};

export const useTenantTblId = (): useTenantTblIdReturnType => {
  const tableId = useRecoilValue(tableIdState);
  const tenantId = useUserTenantId();

  return { tenantId, tableId };
};
