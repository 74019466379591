import { request } from 'admin/api/Request';
import { API_MESSAGES } from 'admin/define/apiMessages';
import { CustomError } from 'admin/error/CustomError';
import { serviceNewsletterCreateFormSchema } from 'admin/schema/service/newsletter';
import { ServiceNewsletterFormType } from 'admin/types/service/newsletter/form';
import {
  ChangeServiceMailMagazineStatusParam,
  ServiceNewsletterChild,
} from 'admin/types/service/newsletter/index';
import { ZodError } from 'zod';

export const changeServiceNewsletterStatus = async ({
  userPoolChildNewsletterId,
  userPoolChildId,
  params,
}: {
  userPoolChildNewsletterId: ServiceNewsletterChild['id'];
  userPoolChildId: string;
  params: ChangeServiceMailMagazineStatusParam;
}): Promise<ServiceNewsletterFormType> => {
  const errorMessage = {
    failed: '',
    error: '',
  };

  if (Object.keys(params).includes('isDeliveryAvailable')) {
    errorMessage.failed =
      API_MESSAGES.SERVICE.CHANGE_SEVICE_NEWSLETTER_DELIVERY_AVAILABLE.FAILED;
    errorMessage.error =
      API_MESSAGES.SERVICE.CHANGE_SEVICE_NEWSLETTER_DELIVERY_AVAILABLE.ERROR;
  } else if (Object.keys(params).includes('isDisplayWhenRegistration')) {
    errorMessage.failed =
      API_MESSAGES.SERVICE.CHANGE_SEVICE_NEWSLETTER_DISPLAY_WHEN_REGISTRATION.FAILED;
    errorMessage.error =
      API_MESSAGES.SERVICE.CHANGE_SEVICE_NEWSLETTER_DISPLAY_WHEN_REGISTRATION.ERROR;
  } else if (Object.keys(params).includes('isCheckWhenRegistration')) {
    errorMessage.failed =
      API_MESSAGES.SERVICE.CHANGE_SEVICE_NEWSLETTER_CHECK_WHEN_REGISTRATION.FAILED;
    errorMessage.error =
      API_MESSAGES.SERVICE.CHANGE_SEVICE_NEWSLETTER_CHECK_WHEN_REGISTRATION.ERROR;
  }

  const path = `uniikey/${userPoolChildId}/child/newsletter/${userPoolChildNewsletterId}/`;

  const response = await request<ServiceNewsletterFormType>({
    path,
    body: params,
    method: 'patch',
  });

  if (response.hasError) {
    if (response.status === 404) {
      throw new CustomError(errorMessage.failed, {
        path,
        params,
        status: response.status,
      });
    } else {
      throw new CustomError(errorMessage.error, {
        path,
        params,
        status: response.status,
        logLevel: response.status === 400 ? 'warning' : 'error',
      });
    }
  }
  try {
    serviceNewsletterCreateFormSchema.parse(response.data);
  } catch (error) {
    if (error instanceof ZodError) {
      throw new CustomError(error.message, {
        path,
        params,
        status: response.status,
        logLevel: 'error',
      });
    }
  }

  return response.data;
};
