import { Box, Button, Flex, Text } from '@chakra-ui/react';
import { Colors } from 'admin/define/colors';
import { FC, memo } from 'react';
import { MdOutlineAdd } from 'react-icons/md';

type Props = {
  onOpen: () => void;
};

export const TermTitleComponent: FC<Props> = memo(({ onOpen }: Props) => (
  <Box mt="40px">
    <Flex justifyContent="flex-end">
      <Box mr="auto">
        <Text
          fontFamily="Hiragino Sans"
          fontSize="18px"
          fontWeight="700"
          lineHeight="170%"
        >
          規約設定
        </Text>
        <Text
          fontWeight="400"
          fontSize="14px"
          lineHeight="170%"
          fontFamily="Hiragino Sans"
          color={Colors.GRAY_00}
        >
          組織として利用したい規約やポリシーを設定します。
        </Text>
      </Box>

      <Button colorScheme="primary" p="8px 24px" onClick={onOpen} w="128px">
        <Flex alignItems="center" gridGap="4px">
          <MdOutlineAdd />
          規約作成
        </Flex>
      </Button>
    </Flex>
  </Box>
));
