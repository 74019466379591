import { CardItems } from 'components/paywall/features/Card';
import { PaywallCardItem } from 'components/paywall/pages/WallItemList/PaywallCardList/PaywallCardItem';
import { WallItem } from 'components/paywall/pages/WallItemList/typed';
import { FC, memo } from 'react';

type Props = {
  wallItem: WallItem;
}

export const PaywallCardItems: FC<Props> = memo(({wallItem}: Props) => (
  <CardItems>
    <PaywallCardItem key={wallItem.id} wallItem={wallItem} />
  </CardItems>
))
