import { VFC, memo, useEffect, useCallback, useState, forwardRef } from 'react';
import {
  FormControl,
  InputGroup,
  InputLeftElement,
  Input,
  FormLabel,
  Text,
  Icon,
  ComponentWithAs,
  InputProps,
  InputRightElement,
  Button,
} from '@chakra-ui/react';
import { IconProps } from '@chakra-ui/icons';
import { IconType } from 'react-icons';
import { ErrorTextMsg } from 'components/common/atoms/ErrorTextMsg';

export type InputTextProps = {
  name: string;
  icon?: IconType | ComponentWithAs<'svg', IconProps>;
  label?: string;
  labelCaption?: string;
  iconColor?: IconProps['color'];
  errors?: string[];
  readOnly?: boolean;
} & InputProps;

export const InputText: VFC<InputTextProps> = memo(
  forwardRef<HTMLInputElement, InputTextProps>((props, ref) => {
    const {
      name,
      value,
      type,
      placeholder,
      icon,
      label,
      labelCaption,
      iconColor = 'gray.300',
      errors = [],
      readOnly = false,
      ...rest
    } = props;
    const [showPassword, setShowPassword] = useState(false);
    const [passwordType, setPasswordType] = useState<
      'password' | 'text' | null
    >(null);

    const handleShowClick = useCallback(() => {
      if (!passwordType) {
        return;
      }
      setShowPassword(!showPassword);
      setPasswordType(passwordType === 'password' ? 'text' : 'password');
    }, [passwordType, showPassword]);

    useEffect(() => {
      if (type && type.toLocaleLowerCase() === 'password') {
        setPasswordType('password');
      }
    }, [type]);

    return (
      <FormControl>
        {labelCaption ? (
          <FormLabel htmlFor={label}>
            <Text as="span" fontSize="xs" color="teal.800">
              {labelCaption}
            </Text>
          </FormLabel>
        ) : null}
        {readOnly ? (
          <Text as="div" fontSize="md" pl={4}>
            {value}
          </Text>
        ) : (
          <InputGroup>
            {icon ? (
              <InputLeftElement pointerEvents="none">
                <Icon as={icon} color={iconColor} />
              </InputLeftElement>
            ) : null}
            <Input
              id={label}
              ref={ref}
              value={value}
              type={passwordType || type}
              placeholder={placeholder}
              {...rest}
            />
            {type === 'password' ? (
              <InputRightElement width="4.5rem">
                <Button h="1.75rem" size="sm" onClick={handleShowClick}>
                  {showPassword ? 'Hide' : 'Show'}
                </Button>
              </InputRightElement>
            ) : null}
          </InputGroup>
        )}
        {errors &&
          errors.map((err, idx) => (
            <ErrorTextMsg key={`error-${name}-${String(idx)}`} msg={err} />
          ))}
      </FormControl>
    );
  }),
);
