import { VFC, memo, useCallback, useState, useEffect, forwardRef } from 'react';
import { useSearchParams } from 'react-router-dom';

import {
  Box,
  Tr,
  Th,
  Text,
  Icon,
  VStack,
  Menu,
  MenuButton,
  MenuList,
  MenuItem,
  IconButton,
  Button,
  ButtonProps,
} from '@chakra-ui/react';
import {
  MdArrowDropUp,
  MdArrowDropDown,
  MdOutlineFilterAlt,
  MdFilterAlt,
} from 'react-icons/md';
import { format } from 'date-fns';
import ReactDatePicker, { registerLocale } from 'react-datepicker';
import ja from 'date-fns/locale/ja';
import 'react-datepicker/dist/react-datepicker.css';

import { MailColumns, MailColumnsFilter } from 'api/mail/types';
import 'components/mail/organisms/MailListTableHeader.css';

registerLocale('ja', ja);
const DateCustomInput = forwardRef<ButtonProps>(({ onClick }: ButtonProps) => {
  const [searchParams] = useSearchParams();
  const [isFilterDate, setFilterDate] = useState(false);

  useEffect(() => {
    const queryDate = searchParams.get('reserve');
    setFilterDate(queryDate !== null);
  }, [searchParams]);

  const getFilterIcon = () =>
    isFilterDate ? <MdFilterAlt /> : <MdOutlineFilterAlt />;

  return (
    <Button
      as={IconButton}
      icon={getFilterIcon()}
      variant="ghost"
      onClick={onClick}
      bg="transparent"
      _hover={{ bg: 'transparent' }}
      _active={{ bg: 'transparent' }}
    />
  );
});

type MailListTableHeaderProps = {
  columns: MailColumns[];
  statusList: MailColumnsFilter[];
  deliveryList: MailColumnsFilter[];
  onSort: (item: MailColumns) => void;
  onColumnFilter: (item: MailColumns, filterItem: MailColumnsFilter) => void;
};
const DATETIME_FORMAT = 'yyyy-MM-dd HH:mm';

export const MailListTableHeader: VFC<MailListTableHeaderProps> = memo(
  ({
    columns,
    statusList,
    deliveryList,
    onSort,
    onColumnFilter,
  }: MailListTableHeaderProps) => {
    const [searchParams] = useSearchParams();
    const [targetDate, setTargetDate] = useState<string | null>(null);
    const [isFilterStatus, setFilterStatus] = useState(false);
    const [isFilterDeliveryTo, setFilterDeliveryTo] = useState(false);

    const onClickEvent = useCallback(
      (item: MailColumns) => {
        onSort(item);
      },
      [onSort],
    );

    const onTargetChange = useCallback(
      (date: Date | null, item: MailColumns) => {
        if (date !== null) {
          setTargetDate(format(date, DATETIME_FORMAT));
          onColumnFilter(item, {
            id: format(date, DATETIME_FORMAT),
            name: format(date, DATETIME_FORMAT),
          });
        } else {
          setTargetDate(null);
        }
      },
      [onColumnFilter],
    );

    useEffect(() => {
      const queryStatus = searchParams.get('status');
      const queryDeliveryTo = searchParams.get('deliveryTo');
      setFilterStatus(queryStatus !== null);
      setFilterDeliveryTo(queryDeliveryTo !== null);
    }, [searchParams]);

    const getFilterIcon = (key: string) => {
      switch (key) {
        case 'status':
          if (isFilterStatus) return <MdFilterAlt />;

          return <MdOutlineFilterAlt />;
        case 'deliveryTo':
          if (isFilterDeliveryTo) return <MdFilterAlt />;

          return <MdOutlineFilterAlt />;
        default:
          return <MdOutlineFilterAlt />;
      }
    };

    return (
      <Tr>
        {columns.map((item) => (
          <Th
            fontSize={12}
            key={item.id}
            minW={item.w}
            position="relative"
            cursor={item.isSort || item.isFilter ? 'pointer' : 'default'}
            onClick={() => onClickEvent(item)}
          >
            {!item.isFilter && (
              <Box>
                <Text>{item.name}</Text>
                {item.isSort && (
                  <Box
                    position="absolute"
                    top="20%"
                    right="20px"
                    transform="translateY(-50%)"
                  >
                    <VStack position="relative">
                      <Icon
                        as={MdArrowDropUp}
                        boxSize={4}
                        position="absolute"
                        top="50%"
                        left="50%"
                        transform="translate(-50%)"
                        color={
                          searchParams.get('ordering') === item.key
                            ? 'gray.800'
                            : 'gray.400'
                        }
                      />
                      <Icon
                        as={MdArrowDropDown}
                        boxSize={4}
                        position="absolute"
                        top="50%"
                        left="50%"
                        transform="translate(-50%)"
                        color={
                          searchParams.get('ordering') === `-${item.key}`
                            ? 'gray.800'
                            : 'gray.400'
                        }
                      />
                    </VStack>
                  </Box>
                )}
              </Box>
            )}
            {item.isFilter && item.key === 'status' && (
              <>
                <Menu>
                  {({ isOpen }) => (
                    <>
                      <Text>{item.name}</Text>
                      <Box
                        position="absolute"
                        top="10%"
                        right="0px"
                        transform="translate(-50%)"
                      >
                        <MenuButton
                          isActive={isOpen}
                          as={IconButton}
                          icon={getFilterIcon(item.key)}
                          variant="ghost"
                          _hover={{ bg: 'transparent' }}
                          _active={{ bg: 'transparent' }}
                        />
                        <MenuList>
                          {item.key === 'status' &&
                            statusList.map((filterItem) => (
                              <MenuItem
                                key={filterItem.id}
                                onClick={() => onColumnFilter(item, filterItem)}
                                bgColor={
                                  Number(searchParams.get('status')) ===
                                  filterItem.id
                                    ? 'gray.100'
                                    : ''
                                }
                              >
                                {filterItem.name}
                              </MenuItem>
                            ))}
                        </MenuList>
                      </Box>
                    </>
                  )}
                </Menu>
              </>
            )}

            {item.isFilter && item.key === 'deliveryTo' && (
              <>
                <Menu>
                  {({ isOpen }) => (
                    <>
                      <Text>{item.name}</Text>
                      <Box
                        position="absolute"
                        top="10%"
                        right="0px"
                        transform="translate(-50%)"
                      >
                        <MenuButton
                          isActive={isOpen}
                          as={IconButton}
                          icon={getFilterIcon(item.key)}
                          variant="ghost"
                          _hover={{ bg: 'transparent' }}
                          _active={{ bg: 'transparent' }}
                        />
                        <MenuList>
                          {deliveryList.map((delivery) => (
                            <MenuItem
                              key={delivery.id}
                              onClick={() => onColumnFilter(item, delivery)}
                              bgColor={
                                searchParams.get('deliveryTo') === delivery.id
                                  ? 'gray.100'
                                  : ''
                              }
                            >
                              {delivery.name}
                            </MenuItem>
                          ))}
                        </MenuList>
                      </Box>
                    </>
                  )}
                </Menu>
              </>
            )}

            {item.isFilter && item.key === 'reserve' && (
              <Box>
                <Text>{item.name}</Text>
                <Box
                  position="absolute"
                  top="10%"
                  right="0px"
                  transform="translate(-50%)"
                >
                  <ReactDatePicker
                    selected={targetDate ? new Date(targetDate) : null}
                    onChange={(date: Date | null) => onTargetChange(date, item)}
                    showTimeSelect
                    timeCaption="時刻"
                    customInput={<DateCustomInput />}
                    dateFormat="yyyy-MM-dd HH:mm"
                    locale="ja"
                  />
                </Box>
              </Box>
            )}
          </Th>
        ))}
      </Tr>
    );
  },
);
