import { UseMutateFunction, useQueryClient, useMutation } from 'react-query';
import { UseFormSetError } from 'react-hook-form';
import { postUserGroup } from 'api/user/postUserGroup';
import { ResponseError } from 'api/types';
import { AddGroupUserFormType, GroupUser, isGroupUser } from 'api/tenant/types';
import { isGetValidationError, toMultiError } from 'utils/form';
import { useCustomToast } from 'hooks/useCustomToast';
import { queryUserKey } from 'hooks/user/queryUserKey';

type UnPromisify<T> = T extends Promise<infer U> ? U : T;

const addGroupUserFormData: AddGroupUserFormType = {
  groupId: '',
  user: '',
};

/**
 * グループにユーザー追加 hooks
 * @returns {Object} { onSubmit, isLoading }
 */
export const useAddGroupUser = ({
  setError,
  onClose,
}: {
  setError: UseFormSetError<AddGroupUserFormType>;
  onClose: () => void;
}): {
  onSubmit: UseMutateFunction<
    UnPromisify<ReturnType<typeof postUserGroup>>,
    unknown,
    AddGroupUserFormType,
    unknown
  >;
  isLoading: boolean;
  isSuccess: boolean;
} => {
  const queryClient = useQueryClient();
  const toast = useCustomToast();

  const {
    mutate: onSubmit,
    isLoading,
    isSuccess,
  } = useMutation(
    ({ user, groupId }: AddGroupUserFormType) =>
      postUserGroup({ user, groupId }),
    {
      onSuccess: (result: GroupUser | boolean | ResponseError) => {
        if (isGroupUser(result)) {
          void queryClient.invalidateQueries(
            queryUserKey.groupUserList({ groupId: result.group }),
          );
          toast({
            status: 'success',
            position: 'bottom',
            duration: 2000,
            isClosable: true,
            title: 'ユーザーを追加しました',
          });
          onClose();

          return;
        }

        if (
          isGetValidationError<AddGroupUserFormType>(
            result,
            Object.keys(addGroupUserFormData),
          )
        ) {
          if (result?.others) {
            const errorMsg = Object.entries(result).map(([_, value]) => value);
            toast({
              status: 'error',
              position: 'bottom',
              duration: 4000,
              isClosable: true,
              title: errorMsg[0],
            });
          } else {
            Object.keys(result).forEach((k) => {
              const key = k as keyof AddGroupUserFormType;
              const errMsgs = result?.[key];
              if (errMsgs) {
                setError(key, { types: toMultiError(errMsgs) });
              }
            });
          }
        }
      },
    },
  );

  return { onSubmit, isLoading, isSuccess };
};
