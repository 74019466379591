import { request } from 'admin/api/Request';
import { API_MESSAGES } from 'admin/define/apiMessages';
import { CustomError } from 'admin/error/CustomError';
import { createCodeGroupSchema } from 'admin/schema/idPoolConsumer/master';
import { CodeGroupType } from 'admin/types/userPool/master';
import { CreateCodeGroupFormType } from 'admin/types/userPool/master/form';
import { isResponseError } from 'api/types';
import { Options } from 'ky';
import { getValidationError } from 'utils/form';
import { ZodError } from 'zod';

export const createCodeGroup = async (
  data: CreateCodeGroupFormType,
  options?: Options,
): Promise<CodeGroupType> => {
  const { id, ...params } = data;

  const path = `uniikey/${id}/code_group/`;
  const response = await request<CodeGroupType>({
    path,
    options,
    body: params,
    method: 'post',
  });

  if (response.hasError) {
    if (response.status === 404) {
      throw new CustomError(
        API_MESSAGES.USER_POOL.CREATE_MASTER_SETTING.FAILED,
        {
          path,
          options,
          params,
          status: response.status,
        },
      );
    } else if (isResponseError(response.error)) {
      const customError = response.error;

      const formError = getValidationError<CreateCodeGroupFormType>({
        formData: data,
        response: customError,
      });

      throw new CustomError(
        API_MESSAGES.USER_POOL.CREATE_MASTER_SETTING.ERROR,
        {
          path,
          options,
          params,
          errors: formError,
          status: response.status,
          logLevel: response.status === 400 ? 'warning' : 'error',
        },
      );
    } else {
      throw new CustomError(API_MESSAGES.USER_POOL.EDIT_MASTER_SETTING.ERROR, {
        path,
        options,
        params,
        status: response.status,
      });
    }
  }
  try {
    createCodeGroupSchema.parse(response.data);
  } catch (error) {
    if (error instanceof ZodError) {
      throw new CustomError(error.message, {
        path,
        options,
        params,
        status: response.status,
        logLevel: 'error',
      });
    }
  }

  return response.data;
};
