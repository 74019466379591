import {
  Box,
  DrawerCloseButton,
  DrawerContent,
  DrawerHeader,
  DrawerOverlay,
  Text,
} from '@chakra-ui/react';
import { CreateForm } from 'admin/components/idPoolConsumer/attributeGroup/attributeGroupForm/createForm';
import { FC, memo } from 'react';

type AttributeGroupCreateDrawerProps = {
  onClose: () => void;
};

export const AttributeGroupCreateDrawer: FC<AttributeGroupCreateDrawerProps> =
  memo(({ onClose }: AttributeGroupCreateDrawerProps) => (
    <>
      <DrawerOverlay />
      <DrawerContent>
        <DrawerHeader pb={0}>
          <Box m={0} px={0} pb="24px" borderBottomWidth="1px">
            <Text>属性グループ作成</Text>
          </Box>
        </DrawerHeader>
        <DrawerCloseButton top="16px" right="24px" />
        <CreateForm onClose={onClose} />
      </DrawerContent>
    </>
  ));
