import { useMemo } from 'react';
import { useSearchParams } from 'react-router-dom';

export const INITIAL_CURRENT_PER_PAGE = 50;

export const useCurrentPerPage = (defaultPerPage: number = INITIAL_CURRENT_PER_PAGE) => {
  const [searchParams] = useSearchParams();

  const currentPerPage = useMemo<number>(() => {
    if(!searchParams.get('perPage')) {
      return defaultPerPage;
    }

    // クエリパラメータで数値以外が渡された場合は内部的には `INITIAL_CURRENT_PAGE` の値とする
    // ※ページネーション自体の処理は続行できる状態とするため（URLの見た目がおかしくても）
    const perPage = Number.isNaN(Number(searchParams.get('perPage'))) ? defaultPerPage : Number(searchParams.get('perPage'));

    return perPage
  }, [searchParams, defaultPerPage]);

  return currentPerPage;
};
