import { Options } from 'ky';
import { request } from 'api/request';
import { logger } from 'api/logger';
import { ContentsTable, isContentsTable } from 'api/contents/types';
import { messages } from 'api/contents/messages';
import { CustomError } from 'api/error/CustomError';

export const getContentsTable = async (
  tblId: string,
  tenantId: string,
  options?: Options,
): Promise<ContentsTable> => {
  if (!tenantId || !tblId) {
    return {} as ContentsTable;
  }

  const target = 'contentsdb';
  const response = await request({
    path: `${target}/${tenantId}/${tblId}/`,
    method: 'get',
    options,
  });

  const responseData = (await response.data) as unknown[];

  if (response.hasError) {
    if (response.status === 404) {
      throw new CustomError(
        messages.getContentsTable.customer.notFound,
        response.status,
      );
    } else {
      throw new CustomError(
        messages.getContentsTable.customer.error,
        response.status,
      );
    }
  }

  if (!isContentsTable(responseData)) {
    await logger({
      loglevel:
        response.status === 404 || response.status === 450
          ? 'warning'
          : 'error',
      data: responseData,
      message: messages.getContentsTable.system.typeError,
    });

    throw new CustomError(
      messages.getContentsTable.customer.typeError,
      response.status,
    );
  }

  return responseData;
};
