import { VFC, useState, useCallback, Ref, forwardRef } from 'react';
import { Tree, TreeProps, NodeModel } from '@minoru/react-dnd-treeview';
import { List, ListProps, ListItem, ListItemProps } from '@chakra-ui/react';
import { TreeNode } from 'components/setting/groups/atoms/TreeNode';

const CustomNodeContainer: VFC<ListProps> = forwardRef(
  ({ children, ...props }, ref: Ref<HTMLUListElement>) => (
    <List ref={ref} {...props}>
      {children}
    </List>
  ),
);
const CustomNodeItem: VFC<ListItemProps> = forwardRef(
  ({ children, ...props }, ref: Ref<HTMLLIElement>) => (
    <ListItem ref={ref} {...props}>
      {children}
    </ListItem>
  ),
);

// rootId: そのデータが直下のデータであることを示すid
// tree: 階層データ
type GroupTreeViewProps<T> = Pick<TreeProps<T>, 'rootId' | 'tree'>;

export const GroupTreeView = <T,>({
  tree,
  rootId,
}: GroupTreeViewProps<T>): JSX.Element => {
  const [selectedNode, setSelectedNode] = useState<NodeModel<T>>();
  // Drag & Drop は禁止(誤操作防止につき)するため以下実装なし
  const handleDrop = useCallback(() => undefined, []);
  const handleDragPreview = useCallback(() => <></>, []);

  const onSelectNode = useCallback(
    (node: NodeModel<T>) => {
      setSelectedNode(node);
    },
    [setSelectedNode],
  );

  // 対象グループの子グループが存在するかどうか
  const isFolder = (id: string | number) => tree.some((u) => u.parent === id);

  return (
    <Tree
      tree={tree}
      rootId={rootId}
      listComponent={CustomNodeContainer}
      listItemComponent={CustomNodeItem}
      render={(node: NodeModel<T>, { depth, isOpen, onToggle }) => (
        <TreeNode
          node={node}
          isOpen={isOpen}
          onSelect={onSelectNode}
          onToggle={onToggle}
          isSelected={selectedNode ? node.id === selectedNode?.id : false}
          isFolder={isFolder(node.id)}
          depth={depth}
        />
      )}
      sort={false}
      onDrop={handleDrop}
      dragPreviewRender={handleDragPreview}
    />
  );
};
