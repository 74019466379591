import { getSecuritySettings } from 'admin/api/userPool/auth/getSecuritySettings';
import { CustomError } from 'admin/error/CustomError';
import { useIdPoolConsumerId } from 'admin/hooks/useIdPoolConsumerId';
import { TokenType } from 'admin/types/userPool/auth';
import { logger } from 'api/logger';
import { useQuery } from 'react-query';
import { queryKeys } from './queryKeys';

export const useToken = () => {
  const idPoolConsumerId = useIdPoolConsumerId();

  const { data } = useQuery({
    queryKey: queryKeys.getSecuritySettings(idPoolConsumerId),
    queryFn: () => getSecuritySettings(idPoolConsumerId),
    onError: async (error) => {
      if (error instanceof CustomError) {
        await logger({
          loglevel: error.logLevel,
          data: JSON.stringify(error.cause),
          message: error.message,
        });
      }
    },
  });

  const token: TokenType = {
    jwtDuration: data?.jwtDuration || 0,
    jwtIssuer: data?.jwtIssuer || '',
    accessTokenDuration: data?.accessTokenDuration || 0,
    refreshTokenDuration: data?.refreshTokenDuration || 0,
  };

  return token;
};
