import {
  Box, Table as ChakraTable
} from '@chakra-ui/react';
import { usePopperUtility } from 'components/opt/hooks/useGlobalUtilities';
import { Popper } from 'components/paywall/features/Popper';
import { TableContainer } from 'components/paywall/features/Table/TableContainer';
import { FC, memo } from 'react';

type Props = {
  children: React.ReactNode
}

export const Table: FC<Props> = memo(({children}: Props) => {
  const { isOpenPopper, handleClosePopper, popperContent } = usePopperUtility();

  return (
    <TableContainer>
      <ChakraTable size='sm' id="paywall-table">
        {children}
      </ChakraTable>

      <Popper
        id="tablePopper"
        isOpenPopper={isOpenPopper}
        hasClose={false}
        onClose={handleClosePopper}
      >
        <Box id="popperBox">{popperContent}</Box>
      </Popper>
    </TableContainer>
  )
})
