import {
  Box,
  Button,
  DrawerBody,
  DrawerFooter,
  HStack,
  Text,
} from '@chakra-ui/react';
import { ColorSet } from 'admin/define/colors';
import {
  ServiceAttributeGroupType,
  ServiceAttributeType,
} from 'admin/types/service/attribute';
import { FC, memo, useMemo } from 'react';

/**
 * 属性
 */
export const USER_ATTR_REQUIRED_FROM_TYPE = 1;
/**
 * 属性グループ
 */
export const USER_ATTR_GROUP_REQUIRED_FROM_TYPE = 2;

export type Props = {
  userAttrRequiredFormType: number;
  isLoading: boolean;
  onClose: () => void;
  onSubmit: () => void;
  target: ServiceAttributeType | ServiceAttributeGroupType | null;
};

export const UserAttrRequiredFormComponent: FC<Props> = memo(
  ({ userAttrRequiredFormType, isLoading, onClose, onSubmit, target }) => {
    const attrTypeName = useMemo(() => {
      if (userAttrRequiredFormType === USER_ATTR_REQUIRED_FROM_TYPE)
        return '属性';

      return '属性グループ';
    }, [userAttrRequiredFormType]);

    return (
      <>
        <DrawerBody fontSize="16px">
          <Box mb={6}>
            <Text>
              会員登録ページで必須にする設定を
              <Text as="span" fontWeight="bold">
                {!target?.userPoolChildAttribute?.isUserAttrRequired
                  ? '有効'
                  : '無効'}
              </Text>
              にします。
              <br />
              <Text as="span" fontWeight="bold">
                {!target?.userPoolChildAttribute?.isUserAttrRequired
                  ? '有効'
                  : '無効'}
              </Text>
              にすると、Auth Hub機能で作成する会員登録ページで、この
              {attrTypeName}が入力
              {!target?.userPoolChildAttribute?.isUserAttrRequired
                ? '必須'
                : '任意'}
              項目となります。
            </Text>
          </Box>
        </DrawerBody>
        <DrawerFooter pb={6}>
          <HStack spacing={4}>
            <Button
              variant="outline"
              onClick={() => onClose()}
              {...ColorSet.Default}
              padding="8px 16px"
            >
              キャンセル
            </Button>
            <Button
              colorScheme="primary"
              mr={3}
              disabled={isLoading}
              padding="8px 36px"
              onClick={() => onSubmit()}
            >
              実行
            </Button>
          </HStack>
        </DrawerFooter>
      </>
    );
  },
);
