import { Page } from 'api/common/types';
import { getWallItemList } from 'api/paywall/getWallItemList';
import {
  WallItem, WallItemListType
} from 'components/paywall/pages/WallItemList/typed';
import { useCustomToast } from 'hooks/useCustomToast';
import { useUserTenantId } from 'hooks/user/useUserTenantId';
import { useQuery } from 'react-query';
import { useSearchParams } from 'react-router-dom';
import { queryPaywallKey } from './queryPaywallKey';

/**
 * 単品商品リスト取得
 * @return {Promise<{page: Page, wallItemList: WallItem[]}>} 単品商品リスト
 */
export const useWallItemList = ({
  wallId,
}: {
  wallId: string;
}): {
  page: Page;
  wallItemList: WallItem[];
} => {
  const toast = useCustomToast();
  const tenantId = useUserTenantId();
  const [searchParams] = useSearchParams();
  const queryfilter = searchParams.get('filter') || '';
  const queryPage = searchParams.get('page') || '';
  const queryPerPage = searchParams.get('perPage') || '50';

  const response = useQuery(
    queryPaywallKey.getWallItemList({
      tenantId,
      wallId,
      filter: queryfilter,
      page: queryPage,
      perPage: queryPerPage,
    }),
    () => getWallItemList({
      tenantId,
      wallId,
      filter: queryfilter,
      page: queryPage,
      perPage: queryPerPage,
    }),
    {
      onError: (err: unknown) => {
        if (err instanceof Error) {
          toast.closeAll();
          toast({
            title: err.message,
            status: 'error',
          });
        }
      },
    },
  );

  const result = response.data as WallItemListType;
  const page: Page = {
    count: result.count,
    countFrom: result.countFrom,
    countTo: result.countTo,
    currentPage: result.currentPage,
    next: result.next,
    pageCount: result.pageCount,
    perPage: result.perPage,
    previous: result.previous,
  };
  const { results } = result;

  return {
    page,
    wallItemList: results,
  };
};
