import { Box, Link, Stack, Text } from '@chakra-ui/react';
import { UserTenantEditForm, isGroups } from 'api/user/types';
import { MultiInputForm } from 'components/setting/users/atoms/MultiInputForm';
import { SelectForm } from 'components/setting/users/atoms/SelectForm';
import { useCustomToast } from 'hooks/useCustomToast';
import { useUserInfo } from 'hooks/useUserInfo';
import { useUserTenantEdit } from 'hooks/user/useUserTenantEdit';
import { VFC, useCallback, useEffect, useState } from 'react';
import { useFormContext } from 'react-hook-form';

/**
 * UsersFormProps
 */
type UsersEditFormProps = {
  formId: string;
  errorMsg?: string;
  startSending: () => void;
  onSuccess: (isError: boolean) => void;
};

/**
 * Select入力必須バリデーションメッセージ
 */
const selectRequiredMsg = '[select]を入力してください';

export const UsersEditForm: VFC<UsersEditFormProps> = ({
  formId,
  errorMsg = '',
  startSending,
  onSuccess,
}) => {
  const [globalErrors, setGlobalErrors] = useState<string[]>([]);
  const [isError, setIsError] = useState(false);
  const { handleSubmit, setError, getValues } =
    useFormContext<UserTenantEditForm>();
  const toast = useCustomToast();
  const { permissionList } = useUserInfo();

  /**
   * Select用バリデーション permission
   */
  const selectValidateAttr1 = {
    required: selectRequiredMsg,
  };

  // ユーザー情報変更登録処理
  const { onSubmit, isSuccess } = useUserTenantEdit({
    setError,
    setGlobalErrors,
  });

  // エラーメッセージ用トースト
  const errorToast = useCallback((error: string) => {
    toast({
      status: 'error',
      position: 'bottom',
      isClosable: true,
      title: error,
    });
    // TODO:
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  // ユーザー情報変更登録
  let sendData: UserTenantEditForm;
  const formSubmit = (data: UserTenantEditForm) => {
    const { groups } = data;

    if (isGroups(groups)) {
      const groupIds = groups.map((x) => x.id);
      sendData = {
        ...data,
        // パーミッションのデータ型をnumberにキャストする
        permission: Number(data.permission),
        groups: groupIds,
      };
    }
    startSending();
    onSubmit(sendData);
  };

  useEffect(() => {
    setGlobalErrors([errorMsg]);
  }, [errorMsg]);

  useEffect(() => {
    if (isSuccess) {
      onSuccess(isError);
    }
  }, [isError, isSuccess, onSuccess]);

  useEffect(() => {
    const error = globalErrors.length > 0 ? globalErrors[0] : '';
    if (error !== '') errorToast(error);
    setIsError(error !== '');
  }, [globalErrors, errorToast]);

  return (
    <>
      <form
        id={formId}
        onSubmit={handleSubmit((data) => formSubmit(data))}
        data-testid="setting-user-drawer"
      >
        <Stack
          spacing={8}
          pt={4}
          flexFlow="column"
          justifyContent="space-between"
          height="calc(100vh - 144px)"
          backgroundColor="whiteAlpha.900"
        >
          <Stack spacing={8}>
            {/* 表示名 */}
            <Box>
              <Text mb={2}>メールアドレス</Text>
              <Text px={4}>{getValues('email')}</Text>
            </Box>

            {/* ユニット権限 */}
            <SelectForm
              name="permission"
              label="権限"
              list={permissionList}
              attr={selectValidateAttr1}
            />
            <Link
              href="https://uniikey.notion.site/4f54766cbaa54947976385efbc701066"
              mt="4px !important"
              pl={4}
              color="blue"
              target="_blank"
            >
              ※権限の種別について
            </Link>

            {/* グループ */}
            <MultiInputForm />
          </Stack>
        </Stack>
      </form>
    </>
  );
};
