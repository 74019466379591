/**
 * カスタムツールレンダリングエレメント作成
 */
export const useCustomToolRenderer = (): {
  createCustomToolRenderer: (defaultClass: string, hasCourseId: boolean) => string;
} => {

  /**
   * カスタムツールレンダリング
   */
  const createCustomToolRenderer = (defaultClass: string, hasCourseId: boolean) => `
    Viewer: unlayer.createViewer({
      render(values) {
        return '<div style="text-align: '+values.btnAlignment+';"><a ${hasCourseId ? "id=\"'+values.courseId+'\"": ""} style=" color: '+values.btnColor+'; background-color: '+values.btnBackgroundColor+'; border-radius: '+values.btnBorderRadius+'px; line-height:120%; display:inline-block; text-decoration:none; text-align:center; padding:10px 20px; width:'+values.btnWidth+'; max-width:100%; word-wrap:break-word; cursor: pointer; border-width: '+values.btnBorder.borderTopWidth+' '+values.btnBorder.borderRightWidth+' '+values.btnBorder.borderBottomWidth+' '+values.btnBorder.borderLeftWidth+'; border-style:  '+values.btnBorder.borderTopStyle+' '+values.btnBorder.borderRightStyle+' '+values.btnBorder.borderBottomStyle+' '+values.btnBorder.borderLeftStyle+'; border-color:  '+values.btnBorder.borderTopColor+' '+values.btnBorder.borderRightColor+' '+values.btnBorder.borderBottomColor+' '+values.btnBorder.borderLeftColor+';"><span> '+values.btnValue+' </span></a></div>'
      }
    }),
    exporters: {
      web: function(values) {
        return '<div style="text-align: '+values.btnAlignment+';"><a ${hasCourseId ? "id=\"'+values.courseId+'\"": ""} class="${defaultClass} '+values.elmClass+'" style=" color: '+values.btnColor+'; background-color: '+values.btnBackgroundColor+'; border-radius: '+values.btnBorderRadius+'px; line-height:120%; display:inline-block; text-decoration:none; text-align:center; padding:10px 20px; width:'+values.btnWidth+'; max-width:100%; word-wrap:break-word; cursor: pointer; border-width: '+values.btnBorder.borderTopWidth+' '+values.btnBorder.borderRightWidth+' '+values.btnBorder.borderBottomWidth+' '+values.btnBorder.borderLeftWidth+'; border-style:  '+values.btnBorder.borderTopStyle+' '+values.btnBorder.borderRightStyle+' '+values.btnBorder.borderBottomStyle+' '+values.btnBorder.borderLeftStyle+'; border-color:  '+values.btnBorder.borderTopColor+' '+values.btnBorder.borderRightColor+' '+values.btnBorder.borderBottomColor+' '+values.btnBorder.borderLeftColor+';"><span> '+values.btnValue+' </span></a></div>'
      },
      email: function(values) {
        return '<div style="text-align: '+values.btnAlignment+';"><a ${hasCourseId ? "id=\"'+values.courseId+'\"": ""} class="${defaultClass} '+values.elmClass+'" style=" color: '+values.btnColor+'; background-color: '+values.btnBackgroundColor+'; border-radius: '+values.btnBorderRadius+'px; line-height:120%; display:inline-block; text-decoration:none; text-align:center; padding:10px 20px; width:'+values.btnWidth+'; max-width:100%; word-wrap:break-word; cursor: pointer; border-width: '+values.btnBorder.borderTopWidth+' '+values.btnBorder.borderRightWidth+' '+values.btnBorder.borderBottomWidth+' '+values.btnBorder.borderLeftWidth+'; border-style:  '+values.btnBorder.borderTopStyle+' '+values.btnBorder.borderRightStyle+' '+values.btnBorder.borderBottomStyle+' '+values.btnBorder.borderLeftStyle+'; border-color:  '+values.btnBorder.borderTopColor+' '+values.btnBorder.borderRightColor+' '+values.btnBorder.borderBottomColor+' '+values.btnBorder.borderLeftColor+';"><span> '+values.btnValue+' </span></a></div>'
      }
    },
    head: {
      css: function(values) {
        return '#'+values._meta.htmlID+' a:hover{ color: '+values.btnHoverColor+'!important; background-color: '+values.btnHoverBackgroundColor+' !important; }'
      },
      js: function(values) {}
    }
  `;

  return {
    createCustomToolRenderer
  }
}
