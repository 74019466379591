export type ResponseError = Record<string, string[] | string>;
/**
 * バックエンドのエラーレスポンスを
 * 判定する型ガード
 *
 * エラーレスポンス例1 -------
 * {
 *   "detail": "このアクションを実行する権限がありません。"
 * }
 *
 * エラーレスポンス例2 -------
 * {
 *   "non_field_errors": [
 *      "テーブル作成できませんでした"
 *   ],
 *   "name": [
 *       "この項目を入力してください。"
 *   ]
 * }
 *
 * @param data unknown エラーレスポンス
 * @returns true: エラーレスポンス
 */
export const isResponseError = (data: unknown): data is ResponseError => {
  const d = data as ResponseError;

  return Object.keys(d).every((k) => {
    const val = d[k];

    return (
      (typeof k === 'string' && typeof val === 'string') ||
      (Array.isArray(val) && val.every((v) => typeof v === 'string'))
    );
  });
};

// TODO: アンケート用エラーの型定義
export const isEnqueteResponseError = (
  data: unknown,
): data is ResponseError => {
  const d = data as ResponseError;

  return Object.keys(d).every((k) => {
    const val = d[k];

    return (
      (typeof k === 'string' && typeof val === 'string') ||
      (Array.isArray(val) && val.every((v) => typeof v === 'string')) ||
      // TODO: アンケートのネストしたデータ型の型ガード
      true
    );
  });
};

export type Pager<T> = {
  // 件数
  count: number;
  // 現在のページ番号
  currentPage: number;
  // 現在のページに表示中の行番号(開始)
  countFrom: number;
  // 現在のページに表示中の行番号(終了)
  countTo: number;
  // ページあたりの表示件数
  perPage: number;
  // 最大ページ数
  pageCount: number;
  // 次ページへのリンク
  next: string | null;
  // 前ページへのリンク
  previous: string | null;
  // 結果
  results: T[];
};

export const isPager = <T>(
  data: unknown,
  isT?: (datas: unknown[]) => boolean,
): data is Pager<T> => {
  const d = data as Pager<T>;

  return typeof d?.count === 'number' &&
    typeof d?.currentPage === 'number' &&
    typeof d?.countFrom === 'number' &&
    typeof d?.countTo === 'number' &&
    typeof d?.perPage === 'number' &&
    typeof d?.pageCount === 'number' &&
    (typeof d?.next === 'string' || d?.next === null) &&
    (typeof d?.previous === 'string' || d?.previous === null) &&
    isT
    ? isT(d?.results)
    : true;
};
