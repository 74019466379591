import { Box, ListItem, Tr as ChakraTr, UnorderedList } from '@chakra-ui/react';
import {
  AttributeGroupTableBody,
  AttributeGroupTableBodyProps,
} from 'admin/components/service/attribute/attributeGroupTableBody';
import { HelpTooltip } from 'admin/components/ui/helpTooltip';
import { Table, Tbody, Th, Thead } from 'admin/components/ui/table';
import { ServiceAttributeGroupsType } from 'admin/types/service/attribute';
import { FC, memo } from 'react';

type Props = {
  attributeGroups: ServiceAttributeGroupsType;
} & Omit<AttributeGroupTableBodyProps, 'attributeGroup'>;

export const AttributeGroupTableComponent: FC<Props> = memo(
  ({
    attributeGroups,
    onOpen,
    onOpenFormAttrDialog,
    onOpenChildAttrDialog,
    onOpenUserAttrDialog,
    onOpenUserAttrRequiredDialog,
    hasUniikey,
  }: Props) => (
    <Box>
      <Table w="100%" maxHeight="calc(100vh - 420px)">
        <Thead>
          <ChakraTr>
            <Th
              maxWidth="130px"
              minWidth="130px"
              fontSize="14px"
              fontWeight="600"
              p="16px"
            >
              属性グループID
            </Th>
            <Th
              maxWidth="180px"
              minWidth="180px"
              fontSize="14px"
              fontWeight="600"
              p="16px"
            >
              表示名（日本語）
            </Th>
            <Th
              maxWidth="180px"
              minWidth="180px"
              fontSize="14px"
              fontWeight="600"
              p="16px"
            >
              表示名（外国語）
            </Th>
            <Th
              maxWidth="161px"
              minWidth="161px"
              fontSize="14px"
              fontWeight="600"
              p="16px"
            >
              画面上の配置
            </Th>
            <Th
              hasBorder={!hasUniikey}
              maxWidth="230px"
              minWidth="230px"
              fontSize="14px"
              fontWeight="600"
              p="16px"
            >
              使用する属性
            </Th>
            {hasUniikey && (
              <Th
                hasBorder
                maxWidth="165px"
                minWidth="165px"
                fontSize="14px"
                fontWeight="600"
                p="16px"
              >
                共通マイページ表示
              </Th>
            )}
            <Th
              maxWidth="154px"
              minWidth="154px"
              fontSize="14px"
              fontWeight="600"
              p="0"
              textAlign="center"
            >
              テナント割当
              <HelpTooltip
                label={
                  <UnorderedList>
                    <ListItem>
                      ONにすると、対象の属性グループが操作中のテナントで利用可能になります。
                    </ListItem>
                    <ListItem>一度ONにした設定はOFFに変更できません。</ListItem>
                  </UnorderedList>
                }
              />
            </Th>
            <Th
              hasBorder
              maxWidth="154px"
              minWidth="154px"
              fontSize="14px"
              fontWeight="600"
              p="0"
              textAlign="center"
            >
              フォームで利用
              <HelpTooltip
                label={
                  <UnorderedList>
                    <ListItem>
                      ONにすると、対象の属性グループが操作中のテナントのフォームで表示されます。
                    </ListItem>
                    <ListItem>一度ONにした設定はOFFに変更できません。</ListItem>
                  </UnorderedList>
                }
              />
            </Th>
            {hasUniikey && (
              <>
                <Th
                  maxWidth="154px"
                  minWidth="154px"
                  fontSize="14px"
                  fontWeight="600"
                  p="0"
                  textAlign="center"
                >
                  会員登録時利用
                  <HelpTooltip
                    label={
                      <UnorderedList>
                        <ListItem>
                          「テナント割当」が「ON」の場合、操作が可能です。
                        </ListItem>
                        <ListItem>
                          ONにすると、対象の属性グループがAuth
                          Hubの会員登録時に表示されます。
                        </ListItem>
                      </UnorderedList>
                    }
                  />
                </Th>
                <Th
                  hasBorder
                  maxWidth="130px"
                  minWidth="130px"
                  fontSize="14px"
                  fontWeight="600"
                  p="16px"
                >
                  必須
                  <HelpTooltip
                    label={
                      <UnorderedList>
                        <ListItem>
                          「テナント割当」が「ON」の場合、操作が可能です。
                        </ListItem>
                        <ListItem>
                          ONにすると、対象の属性グループが会員登録時の必須登録項目になります。また、「会員登録時利用」も連動してONになります。
                        </ListItem>
                      </UnorderedList>
                    }
                  />
                </Th>
              </>
            )}
          </ChakraTr>
        </Thead>
        <Tbody>
          {attributeGroups.map((attributeGroup) => (
            <AttributeGroupTableBody
              key={`attribute-group-table-body_${attributeGroup.id}`}
              attributeGroup={attributeGroup}
              onOpen={onOpen}
              onOpenFormAttrDialog={onOpenFormAttrDialog}
              onOpenChildAttrDialog={onOpenChildAttrDialog}
              onOpenUserAttrDialog={onOpenUserAttrDialog}
              onOpenUserAttrRequiredDialog={onOpenUserAttrRequiredDialog}
              hasUniikey={hasUniikey}
            />
          ))}
        </Tbody>
      </Table>
    </Box>
  ),
);
