import { Box, Popover, PopoverContent } from '@chakra-ui/react';
import { TextButton } from 'components/paywall/atoms/TextButton';
import { Colors } from 'components/paywall/config';
import { StyledFlexEnd } from 'components/paywall/styled/global';
import { StyledIconClosePopper } from 'components/paywall/styled/local';
import { FC, memo } from 'react';

type Props = {
  id: string;
  isOpenPopper: boolean;
  children: React.ReactNode;
  hasClose?: boolean;
  onClose: () => void;
};

/**
 * @see イベント発生座標にポップアップするボックス
 * @param id ポッパーのId
 * @param isOpenPopper ポッパーの開閉有無
 * @param children ポッパーの子要素
 * @param hasClose クローズボタンの表示有無
 * @param onClose ポッパーを閉じるメソッド
 */
export const Popper: FC<Props> = memo(
  ({ id, isOpenPopper, children, hasClose = true, onClose }) => (
    /**
     * 2023-08-23
     * popperのcloseOnBlurが意図しないタイミングで発生してしまうため
     * PopoverのcloseOnBlurをfalseとする
     * その代わりに、明示的にoverlayを設定し、popper以外をクリックした際は
     * popperを非表示となるよう修正
     */
    <Box
      position="fixed"
      display={isOpenPopper ? 'fixed' : 'none'}
      top={0}
      left={0}
      width="100%"
      height="100%"
      bgColor="transparent"
      zIndex="10000"
      onClick={onClose}
    >
      <Box
        id={id}
        position="absolute"
        zIndex={1200}
        display={isOpenPopper ? 'block' : 'none'}
        // 親のoverlayにクリック情報を伝播させない
        onClick={(e) => e.stopPropagation()}
      >
        <Popover isOpen={isOpenPopper} closeOnBlur={false} onClose={onClose}>
          <PopoverContent
            minW={0}
            w="auto"
            borderWidth="1px"
            borderStyle="solid"
            borderColor={Colors.GRAY_50}
            borderRadius="6px"
            boxShadow="0px 8px 16px -2px rgba(27, 36, 44, 0.12), 0px 2px 2px -1px rgba(27, 35, 44, 0.04)"
            _focus={{outline: 'none'}}
          >
            <Box
              p="10px"
              backgroundColor={Colors.WHITE}
              borderRadius="6px"
            >
              {children}
            </Box>
            {hasClose && (
              <Box
                backgroundColor='#ddd'
                p="5px"
                >
                <StyledFlexEnd>
                  <TextButton
                    size="xs"
                    mode="simple"
                    icon={<StyledIconClosePopper />}
                    onClick={onClose}
                  />
                </StyledFlexEnd>
              </Box>
            )}
          </PopoverContent>
        </Popover>
      </Box>
    </Box>
  ),
);
