import { withSuspenseAndErrorBoundary } from 'admin/components/Error/WithErrorBoundary';
import { UserInfo as UserInfoInner } from 'admin/components/idPoolConsumer/userInfo/userInfoInner';
import { Breadcrumb } from 'admin/components/ui/breadcrumb';
import { Truncate } from 'admin/components/ui/truncate';
import { useIdPoolConsumerId } from 'admin/hooks/useIdPoolConsumerId';
import { useUserPool } from 'admin/hooks/userPool/useUserPool';
import { BreadcrumbType } from 'admin/types/ui/breadcrumb';
import { ErrorContents, LoadingSpinner } from 'components/common/atoms';
import { useSetPageTitle } from 'hooks/useSetPageTitle';
import { FC, memo, useMemo } from 'react';

const fetchBreadCrumb: FC = memo(() => {
  const userPoolId = useIdPoolConsumerId();
  const { data: userPool } = useUserPool(userPoolId);

  const breadcrumbItems = useMemo<BreadcrumbType[]>(() => {
    const breadcrumbs: BreadcrumbType[] = [
      { url: '/admin', title: '組織管理TOP' },
      {
        url: '/admin/idPoolConsumer',
        title: 'IDシステム設定管理',
      },
    ];

    if (userPool && userPool.name) {
      breadcrumbs.push({
        url: '',
        title: <Truncate>{userPool.name}</Truncate>,
      });
    }

    breadcrumbs.push({ url: '', title: '外部IDシステムJSON設定' });

    return breadcrumbs;
  }, [userPool]);

  return <Breadcrumb breadcrumbItems={breadcrumbItems} />;
});

const FetchBreadCrumb = withSuspenseAndErrorBoundary(fetchBreadCrumb, {
  ErrorComponent: (
    <ErrorContents name="外部IDシステムJSON設定 パンくずリスト" />
  ),
  LoadingComponent: <LoadingSpinner />,
});

export const UserInfo: FC = memo(() => {
  useSetPageTitle('外部IDシステムJSON設定');

  return (
    <>
      <FetchBreadCrumb />
      <UserInfoInner />
    </>
  );
});
