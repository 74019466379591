import { withSuspenseAndErrorBoundary } from 'admin/components/Error/WithErrorBoundary';
import { DetailInner } from 'admin/components/idPoolConsumer/formGroup/detail/detailInner';
import { ErrorContents, LoadingSpinner } from 'components/common/atoms';
import { useSetPageTitle } from 'hooks/useSetPageTitle';
import { FC, memo } from 'react';

export const detail: FC = memo(() => {
  useSetPageTitle('フォーム設問グループ詳細設定');

  return <DetailInner />;
});

export const Detail = withSuspenseAndErrorBoundary(detail, {
  ErrorComponent: <ErrorContents name="フォーム設問グループ詳細設定" />,
  LoadingComponent: <LoadingSpinner />,
});
