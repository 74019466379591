import { Box, Text, Tr } from '@chakra-ui/react';
import { TransactionMailPersonalInner } from 'admin/components/idPoolConsumer/transactionMail/transactionMailPersonalInner';
import { Table, Tbody, Th, Thead } from 'admin/components/ui/table';
import { Colors } from 'admin/define/colors';
import { TRANSACTION_MAIL_PERSONAL_TYPE } from 'admin/define/idPoolConsumer/transactionMail';
import { TransactionMailTypeStatusesType } from 'admin/types/userPool/transactionMail';
import { FC, memo } from 'react';

type Props = {
  mailTypeStatuses: TransactionMailTypeStatusesType;
};

export const TransactionMailPersonalComponent: FC<Props> = memo(
  ({ mailTypeStatuses }: Props) => (
    <Box mt="40px">
      <Box my="16px">
        <Text
          fontFamily="Hiragino Sans"
          fontSize="18px"
          fontWeight="700"
          lineHeight="170%"
        >
          個別設定
        </Text>
        <Text color={Colors.GRAY_00}>
          各メール種別ごとに文面や利用状態を設定します。
        </Text>
      </Box>
      <Table tableWidth="100%" border="1px">
        <Thead>
          <Tr>
            <Th minWidth="200px">メール名</Th>
            <Th width="140px" children={null} />
          </Tr>
        </Thead>
        <Tbody>
          {TRANSACTION_MAIL_PERSONAL_TYPE.map((keyName) => (
            <TransactionMailPersonalInner
              personalType={keyName}
              mailTypeStatuses={mailTypeStatuses}
              key={`transaction_mail_personal_${keyName}`}
            />
          ))}
        </Tbody>
      </Table>
    </Box>
  ),
);
