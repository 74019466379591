import { extendTheme } from '@chakra-ui/react';
import { AdminTheme as AdminComponents } from 'admin/theme';
import { components, styles } from 'theme/index';
import { AdminColors } from './index';

// 新しいテーマを作成します
const newTheme = extendTheme({
  styles,
  components: {
    ...components,
    ...AdminComponents,
  },
  colors: {
    ...AdminColors,
  },
});

export default newTheme;
