import { UseMutateFunction, useMutation } from 'react-query';
import { UseFormSetError } from 'react-hook-form';
import { ResponseError } from 'api/types';
import {
  PasswordChange,
  isPasswordChange,
  UserEditPasswordForm,
} from 'api/user/types';
import { isGetValidationError, toMultiError } from 'utils/form';
import { useCustomToast } from 'hooks/useCustomToast';
import { putPassword } from 'api/user/putPassword';

type UnPromisify<T> = T extends Promise<infer U> ? U : T;

const useUserPasswordEditKey: UserEditPasswordForm = {
  password: '',
  passwordConfirm: '',
};

/**
 * パスワード変更処理 hooks
 * @param {Object} UseFormSetError setError
 * @param {setGlobalErrors} callback setGlobalErrors
 * @returns {Object} { onSubmit, isLoading }
 */
export const useUserPasswordEdit = ({
  setError,
  setGlobalErrors,
  onClose,
}: {
  setError: UseFormSetError<UserEditPasswordForm>;
  setGlobalErrors: (errors: string[]) => void;
  onClose: () => void;
}): {
  onSubmit: UseMutateFunction<
    UnPromisify<ReturnType<typeof putPassword>>,
    unknown,
    UserEditPasswordForm,
    unknown
  >;
  isLoading: boolean;
} => {
  const toast = useCustomToast();

  const { mutate: onSubmit, isLoading } = useMutation(
    ({ password, passwordConfirm }: UserEditPasswordForm) =>
      putPassword({ password, passwordConfirm }),
    {
      onSuccess: (result: PasswordChange | ResponseError) => {
        // グローバルエラーを初期化
        setGlobalErrors([]);
        if (isPasswordChange(result)) {
          toast({
            status: 'success',
            position: 'bottom',
            duration: 2000,
            isClosable: true,
            title: '変更しました',
          });
          onClose();

          return;
        }

        if (
          isGetValidationError<UserEditPasswordForm>(
            result,
            Object.keys(useUserPasswordEditKey),
          )
        ) {
          if (result?.others) {
            setGlobalErrors(result?.others);
          } else {
            Object.keys(result).forEach((k) => {
              const key = k as keyof UserEditPasswordForm;
              const errMsgs = result?.[key];
              if (errMsgs) {
                setError(key, { types: toMultiError(errMsgs) });
              }
            });
          }
        }
      },
    },
  );

  return { onSubmit, isLoading };
};
