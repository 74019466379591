import { VFC, memo } from 'react';
import { Box } from '@chakra-ui/react';
import {
  Chart as ChartJS,
  CategoryScale,
  LinearScale,
  BarElement,
  Title,
  Tooltip,
  Legend,
} from 'chart.js';
import { Bar } from 'react-chartjs-2';

ChartJS.register(
  CategoryScale,
  LinearScale,
  BarElement,
  Title,
  Tooltip,
  Legend,
);

type Props = {
  text?: string;
  labels: string[];
  data: number[];
};

export const BarChart: VFC<Props> = memo(({ text, labels, data }) => {
  const barHeight: number = data.length * 20 + 75;

  const options = {
    indexAxis: 'y' as const,
    responsive: false,
    maintainAspectRatio: false,
    plugins: {
      legend: {
        position: 'top' as const,
        labels: {
          font: {
            size: 14,
          },
        },
      },
      title: {
        display: false,
        text,
        font: {
          size: 20,
        },
      },
    },
    scale: {
      ticks: {
        precision: 0,
      },
    },
  };

  const graphData = {
    labels,
    datasets: [
      {
        label: '回答数',
        data,
        backgroundColor: [
          'rgba(255, 99, 132, 0.5)',
          'rgba(54, 162, 235, 0.5)',
          'rgba(255, 206, 86, 0.5)',
          'rgba(75, 192, 192, 0.5)',
          'rgba(245, 160, 74, 0.5)',
          'rgba(147, 214, 151, 0.5)',
          'rgba(0, 223, 198, 0.5)',
          'rgba(174, 235, 248, 0.5)',
          'rgba(255, 100, 160, 0.5)',
          'rgba(200, 200, 10, 0.5)',
        ],
        borderColor: [
          'rgba(255, 99, 132, 1)',
          'rgba(54, 162, 235, 1)',
          'rgba(255, 206, 86, 1)',
          'rgba(75, 192, 192, 1)',
          'rgba(245, 160, 74, 1)',
          'rgba(147, 214, 151, 1)',
          'rgba(0, 223, 198, 1)',
          'rgba(174, 235, 248, 1)',
          'rgba(255, 100, 160, 1)',
          'rgba(200, 200, 10, 1)',
        ],
      },
    ],
  };

  return (
    <Box pt={2} pb={5}>
      <Bar
        width={800}
        height={barHeight}
        options={options}
        data={graphData}
        style={{ margin: '0 auto' }}
      />
    </Box>
  );
});
