import { useServices } from 'admin/hooks/service/useServices';
import { useMemo } from 'react';
import { useParams } from 'react-router-dom';

/**
 * テナント名取得 hooks
 */
export const useTenantName = (): string => {
  const params = useParams();
  const { data: tenants } = useServices();

  const tenantId = useMemo(() => params.tenantId ?? '', [params]);

  const tenantName = useMemo(() => {
    if (!tenants) {
      return '';
    }

    return tenants.find((tenant) => tenant.id === tenantId)?.name ?? '';
  }, [tenants, tenantId]);

  return tenantName;
};
