import { Box, Flex, Text, Tr as ChakraTr } from '@chakra-ui/react';
import { Table, Tbody, Td, Th, Thead, Tr } from 'admin/components/ui/table';
import { Colors } from 'admin/define/colors';
import { LogsType } from 'admin/types/userPool/terms';
import { FC, memo } from 'react';

type LogTableComponentProps = {
  logs: LogsType;
  termName: string;
};

export const LogTableComponent: FC<LogTableComponentProps> = memo(
  ({ logs, termName }: LogTableComponentProps) => (
    <Box>
      <Box mt="40px" mb="16px">
        <Flex justifyContent="flex-end">
          <Box mr="auto">
            <Text
              fontFamily="Hiragino Sans"
              fontSize="18px"
              fontWeight="700"
              lineHeight="170%"
            >
              {termName}更新履歴
            </Text>
            <Text
              fontWeight="400"
              fontSize="14px"
              lineHeight="170%"
              fontFamily="Hiragino Sans"
              color={Colors.GRAY_00}
            >
              規約設定画面で行った設定変更の履歴を確認することができます。
            </Text>
          </Box>
        </Flex>
      </Box>
      <Table maxHeight="calc(100vh - 250px)">
        <Thead>
          <ChakraTr>
            <Th w="11%" minW="102px">
              バージョン
            </Th>
            <Th w="30%" minW="280px">
              更新内容
            </Th>
            <Th w="27%" minW="250px">
              メモ
            </Th>
            <Th w="20%" minW="184px">
              更新日時
            </Th>
            <Th w="12%" minW="116px">
              更新者
            </Th>
          </ChakraTr>
        </Thead>
        <Tbody>
          {logs.map((log) => (
            <Tr key={`log${log.id}`}>
              <Td>{log.version}</Td>
              <Td whiteSpace="pre">{log.details}</Td>
              <Td>{log.memo}</Td>
              <Td>{log.createdAt}</Td>
              <Td>{log.createBy}</Td>
            </Tr>
          ))}
        </Tbody>
      </Table>
    </Box>
  ),
);
