import {
  maxLength,
  headTitleOptions,
  screenNameOptions,
  screenDescriptionOptions,
  urlOptions,
  buttonNameOptions,
  itemNameOptions,
  textareaElementOptions,
} from './options';

const groupName = 'register';

// 新規登録画面
export default [
  {
    name: `${groupName}.headTitle`,
    label: 'ブラウザタブ表示名',
    tooltip: '',
    type: 'Text',
    defaultValue: '新規会員登録',
    options: headTitleOptions,
    elementOptions: {},
  },
  {
    name: `${groupName}.screenName`,
    label: '画面名',
    tooltip: '',
    type: 'Text',
    defaultValue: '新規会員登録',
    options: screenNameOptions,
    elementOptions: {},
  },
  {
    name: `${groupName}.screenDescription`,
    label: '案内文 画面',
    tooltip: '',
    type: 'Textarea',
    defaultValue:
      '会員登録は無料です。\n会員登録を行うことで、編集部主催のキャンペーンへの応募やメールマガジンの購読ができるようになります。',
    options: screenDescriptionOptions,
    elementOptions: textareaElementOptions,
  },
  {
    name: `${groupName}.emailItemName`,
    label: '入力欄名 メールアドレス',
    tooltip: '',
    type: 'Text',
    defaultValue: 'メールアドレス',
    options: itemNameOptions,
    elementOptions: {},
  },
  {
    name: `${groupName}.deemedConscentDescription`,
    label: '案内文 みなし同意',
    tooltip:
      '・みなし同意とは、エンドユーザーが「次に進む操作」を行うことで、案内内容に同意したと見なす機能です。\n・リンクをエンドユーザー画面に表示する場合、URLとリンクテキストをセットで設定してください。',
    type: 'Text',
    defaultValue: '',
    options: {
      maxLength: maxLength(50),
    },
    elementOptions: {},
  },
  {
    name: `${groupName}.deemedConscentLink1`,
    label: 'URL みなし同意規約リンク1',
    tooltip: '',
    type: 'Text',
    defaultValue: '',
    options: urlOptions,
    elementOptions: {},
  },
  {
    name: `${groupName}.deemedConscentLinkName1`,
    label: 'リンクテキスト みなし同意規約リンク1',
    tooltip: '',
    type: 'Text',
    defaultValue: '',
    options: {
      maxLength: maxLength(50),
    },
    elementOptions: {},
  },
  {
    name: `${groupName}.deemedConscentLink2`,
    label: 'URL みなし同意規約リンク2',
    tooltip: '',
    type: 'Text',
    defaultValue: '',
    options: urlOptions,
    elementOptions: {},
  },
  {
    name: `${groupName}.deemedConscentLinkName2`,
    label: 'リンクテキスト みなし同意規約リンク2',
    tooltip: '',
    type: 'Text',
    defaultValue: '',
    options: {
      maxLength: maxLength(50),
    },
    elementOptions: {},
  },
  {
    name: `${groupName}.deemedConscentLink3`,
    label: 'URL みなし同意規約リンク3',
    tooltip: '',
    type: 'Text',
    defaultValue: '',
    options: urlOptions,
    elementOptions: {},
  },
  {
    name: `${groupName}.deemedConscentLinkName3`,
    label: 'リンクテキスト みなし同意規約リンク3',
    tooltip: '',
    type: 'Text',
    defaultValue: '',
    options: {
      maxLength: maxLength(50),
    },
    elementOptions: {},
  },
  {
    name: `${groupName}.deemedConscentLink4`,
    label: 'URL みなし同意規約リンク4',
    tooltip: '',
    type: 'Text',
    defaultValue: '',
    options: urlOptions,
    elementOptions: {},
  },
  {
    name: `${groupName}.deemedConscentLinkName4`,
    label: 'リンクテキスト みなし同意規約リンク4',
    tooltip: '',
    type: 'Text',
    defaultValue: '',
    options: {
      maxLength: maxLength(50),
    },
    elementOptions: {},
  },
  {
    name: `${groupName}.registerButtonName`,
    label: 'ボタン名 会員登録',
    tooltip: '',
    type: 'Text',
    defaultValue: '会員登録',
    options: buttonNameOptions,
    elementOptions: {},
  },
  {
    name: `${groupName}.existingUserDescription`,
    label: '案内文 既存利用者向け案内',
    tooltip: '',
    type: 'Textarea',
    defaultValue: 'すでにアカウントをお持ちの方',
    options: {
      maxLength: maxLength(50),
    },
    elementOptions: textareaElementOptions,
  },
  {
    name: `${groupName}.loginButtonName`,
    label: 'ボタン名 ログイン',
    tooltip: '',
    type: 'Text',
    defaultValue: 'ログイン',
    options: buttonNameOptions,
    elementOptions: {},
  },
] as const;
