import { withSuspenseAndErrorBoundary } from 'admin/components/Error/WithErrorBoundary';
import { LogTable } from 'admin/components/idPoolConsumer/terms/logTable';
import { Breadcrumb } from 'admin/components/ui/breadcrumb';
import { Truncate } from 'admin/components/ui/truncate';
import { useIdPoolConsumerId } from 'admin/hooks/useIdPoolConsumerId';
import { useTerm } from 'admin/hooks/userPool/terms';
import { useLogs } from 'admin/hooks/userPool/terms/useLogs';
import { useUserPool } from 'admin/hooks/userPool/useUserPool';
import { useTermId } from 'admin/hooks/useTermId';
import { BreadcrumbType } from 'admin/types/ui/breadcrumb';
import { ErrorContents, LoadingSpinner } from 'components/common/atoms';
import { useSetPageTitle } from 'hooks/useSetPageTitle';
import { FC, memo } from 'react';

const PlainLog: FC = memo(() => {
  useSetPageTitle('プライバシーポリシー更新履歴');
  const userPoolId = useIdPoolConsumerId();
  const termId = useTermId();
  const { data: logs } = useLogs();
  const { data: term } = useTerm({
    id: termId,
    userPoolId,
  });
  const { data: userPool } = useUserPool(userPoolId);
  if (!logs || !term) {
    return null;
  }

  const breadcrumbItems: BreadcrumbType[] = [
    { url: '/admin', title: '組織管理TOP' },
    {
      url: '/admin/idPoolConsumer',
      title: 'IDシステム設定管理',
    },
    {
      url: '',
      title: <Truncate>{userPool?.name || ''}</Truncate>,
    },
    {
      url: `/admin/idPoolConsumer/${userPoolId}/terms`,
      title: '規約設定',
    },
    {
      url: '',
      title: `${term.displayNameJa}更新履歴`,
    },
  ];

  return (
    <>
      <Breadcrumb breadcrumbItems={breadcrumbItems} />
      <LogTable />
    </>
  );
});

export const Log = withSuspenseAndErrorBoundary(PlainLog, {
  ErrorComponent: <ErrorContents name="プライバシーポリシー更新履歴" />,
  LoadingComponent: <LoadingSpinner />,
});
