import { changeServiceFormAttrGroup } from 'admin/api/service/attribute/changeServiceFormAttrGroup';
import { createServiceAttributeGroup } from 'admin/api/service/attribute/createServiceAttributeGroup';
import { CustomError } from 'admin/error/CustomError';
import { useUserPoolChildId } from 'admin/hooks/useUserPoolChildId';
import { ServiceAttributeGroupType } from 'admin/types/service/attribute';
import { logger } from 'api/logger';
import { useCustomToast } from 'hooks/useCustomToast';
import { useMutation, useQueryClient } from 'react-query';
import { errorToast, successToast } from 'utils/toast';
import {
  queryKeyNames,
  querySerivceAttributeKey,
} from './querySerivceAttributeKey';

export const useChangeServiceFormAttrGroup = (
  target: ServiceAttributeGroupType | null,
) => {
  const userPoolChildId = useUserPoolChildId();
  const toast = useCustomToast();
  const queryClient = useQueryClient();
  const { mutateAsync, isLoading } = useMutation({
    mutationFn: () => {
      if (target?.userPoolChildAttribute?.id) {
        return changeServiceFormAttrGroup({
          userPoolChildId,
          userPoolChildAttrId: target?.userPoolChildAttribute?.id,
          data: {
            userAttributeSetting:
              target.userPoolChildAttribute.userAttributeSetting ?? '',
            attributeGroupHead:
              target.userPoolChildAttribute.attributeGroupHead ?? '',
            isFormAttr: true,
          },
        });
      }

      return createServiceAttributeGroup({
        userPoolChildId,
        data: {
          userAttributeSetting: null,
          attributeGroupHead: target?.id ?? '',
          isUserAttr: false,
          isUserAttrRequired: false,
          isFormAttr: true,
        },
      });
    },
    onSuccess: () => {
      void queryClient.invalidateQueries(
        queryKeyNames.getSerivceAttributeGroups,
      );
      void queryClient.invalidateQueries(
        querySerivceAttributeKey.getSerivceAttributeGroupDetail(
          userPoolChildId,
          target?.userPoolChildAttribute?.id ?? '',
        ),
      );
      toast({
        ...successToast,
        duration: 4000,
        title: 'フォーム利用設定を更新しました',
        position: 'bottom',
        variant: 'solid',
      });
    },
    onError: async (error) => {
      if (error instanceof CustomError) {
        if (error.cause.errors) {
          await logger({
            loglevel: error.logLevel,
            data: JSON.stringify(error.cause),
            message: error.message,
          });
        }
      }
      toast({
        ...errorToast,
        duration: 4000,
        title: 'フォーム利用設定の更新に失敗しました',
        position: 'bottom',
        variant: 'solid',
      });
    },
  });

  return { mutate: mutateAsync, isLoading };
};
