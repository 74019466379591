import {
  Box,
  Button,
  DrawerBody,
  DrawerFooter,
  FormControl,
  FormErrorMessage,
  Heading,
  Text
} from '@chakra-ui/react';
import { RhfInput } from 'admin/components/form/RhfInput';
import { RhfSelect } from 'admin/components/form/RhfSelect';
import { RequiredIcon } from 'admin/components/ui/icon/requiredIcon';
import { LoadingLayer } from 'admin/components/ui/loadingLayer';
import { Colors, ColorSet } from 'admin/define/colors';
import { TenantListType } from 'admin/types/tenantUsers';
import { tenantUsersInviteFormType } from 'admin/types/tenantUsers/form';
import { FC, memo } from 'react';
import { useFormContext, useFormState } from 'react-hook-form';
import { MdMail } from 'react-icons/md';
import { toErrMsgList } from 'utils/form';

type Props = {
  formId: string;
  onSubmit: (value: tenantUsersInviteFormType) => void;
  isLoading: boolean;
  onClose: () => void;
  tenantList: TenantListType;
};

export const TenantUsersInviteFormComponent: FC<Props> = memo(
  ({ formId, onSubmit, isLoading, onClose, tenantList }: Props) => {
    const { handleSubmit } = useFormContext<tenantUsersInviteFormType>();
    const { errors } = useFormState<tenantUsersInviteFormType>();

    return (
      <>
        {isLoading && <LoadingLayer />}
        <DrawerBody>
          <form onSubmit={handleSubmit(onSubmit)} id={formId}>
            <Box mt="24px">
              <FormControl
                isInvalid={
                  Boolean(errors.emailTo?.message) ||
                  toErrMsgList(errors, 'emailTo').length > 0
                }
              >
                <Heading as="h3" size="sm" mb="4px">
                  <Box display="flex" alignItems="bottom">
                    メールアドレス <RequiredIcon ml="8px" />
                  </Box>
                </Heading>
                <Box>
                  <RhfInput<tenantUsersInviteFormType>
                    name="emailTo"
                    bgColor={Colors.WHITE}
                  />
                </Box>
                {toErrMsgList(errors, 'emailTo').map((err) => (
                  <FormErrorMessage key={`emailTo${err}`}>
                    {err}
                  </FormErrorMessage>
                ))}
              </FormControl>
            </Box>
            <Box mt="24px">
              <FormControl
                isInvalid={
                  Boolean(errors.tenant?.message) ||
                  toErrMsgList(errors, 'tenant').length > 0
                }
              >
                <Heading as="h3" size="sm" mb="4px">
                  <Box display="flex" alignItems="bottom">
                    担当テナント <RequiredIcon ml="8px" />
                  </Box>
                </Heading>
                <Box>
                  <RhfSelect<tenantUsersInviteFormType>
                    name="tenant"
                    placeholder="選択してください。"
                    bgColor={Colors.WHITE}
                    width="312px"
                  >
                    {tenantList.map((tenant) => (
                      <option key={`tenant${tenant.id}`} value={tenant.id}>
                        {tenant.name}
                      </option>
                    ))}
                  </RhfSelect>
                </Box>
                {toErrMsgList(errors, 'tenant').map((err) => (
                  <FormErrorMessage key={`tenant${err}`}>
                    {err}
                  </FormErrorMessage>
                ))}
              </FormControl>
            </Box>
            <Box mt="24px">
              <FormControl
                isInvalid={
                  Boolean(errors.permission?.message) ||
                  toErrMsgList(errors, 'permission').length > 0
                }
              >
                <Heading as="h3" size="sm" mb="4px">
                  <Box display="flex" alignItems="bottom">
                    権限
                  </Box>
                </Heading>
                <Text>01_●オーナー</Text>

                {/*
                TODO: 4月以降対応
                <Box>
                  <RhfSelect<tenantUsersInviteFormType>
                    name="permission"
                    placeholder="選択してください。"
                    bgColor={Colors.WHITE}
                    width="312px"
                  >
                    {TENANT_PERMISSION_LIST.map((item) => (
                      <option
                        value={item.value}
                        key={`tenantUsersInvitePermission${item.text}`}
                      >
                        {item.label}
                      </option>
                    ))}
                  </RhfSelect>
                </Box> */}
                {toErrMsgList(errors, 'permission').map((err) => (
                  <FormErrorMessage key={`permission${err}`}>
                    {err}
                  </FormErrorMessage>
                ))}
              </FormControl>
            </Box>
          </form>
        </DrawerBody>
        <DrawerFooter display="flex" justifyContent="end" gridGap={4}>
          <Button
            variant="outline"
            onClick={() => onClose()}
            lineHeight={5}
            p="8px 24px"
            h="auto"
            {...ColorSet.Default}
          >
            キャンセル
          </Button>
          <Button
            colorScheme="primary"
            type="submit"
            form={formId}
            lineHeight={5}
            p="8px 16px"
            h="auto"
            leftIcon={<MdMail />}
          >
            招待メール送信
          </Button>
        </DrawerFooter>
      </>
    );
  },
);
