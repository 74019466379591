import { Box, Button, Flex, Text } from '@chakra-ui/react';
import { Colors, ColorSet } from 'components/paywall/config';
import { FC, memo } from 'react';
import { MdEdit } from 'react-icons/md';

type Props = {
  onOpen: () => void;
}

export const LoginRedirectTitle: FC<Props> = memo(({ onOpen }: Props) => (
  <Box mt="40px">
    <Flex justifyContent="flex-end">
      <Box mr="auto">
        <Text
          fontFamily="Hiragino Sans"
          fontSize="18px"
          fontWeight="700"
          lineHeight="170%"
        >
          ログインリダイレクト設定
        </Text>
        <Text
          fontWeight="400"
          fontSize="14px"
          lineHeight="170%"
          fontFamily="Hiragino Sans"
          color={Colors.GRAY_00}
        >
          未ログイン状態のユーザーをログインページにリダイレクトさせる設定をカスタマイズすることができます。会員限定フォーム、ペイウォールの機能で利用します。
        </Text>
      </Box>
      <Button leftIcon={<MdEdit />} p="8px 24px" onClick={onOpen} {...ColorSet.BLUE}>
        設定
      </Button>
    </Flex>
  </Box>
));
