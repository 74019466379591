import { UseFormWatch } from 'react-hook-form';
import { Basename, FormType } from 'admin/types/authHub';
import { Settings } from '../settings';

type Temp = {
  [K in keyof Settings]: {
    [KK in Settings[K][number]['name']]: string;
  };
};

export type TemplatePageProps = {
  watch: UseFormWatch<FormType>;
  force?: {
    hover?: boolean;
    focus?: boolean;
  };
};

export type PageProps<T extends string> = T extends keyof Temp
  ? {
      [K in Basename<keyof Temp[T]>]: T extends keyof Temp
        ? `${T}.${K}` extends keyof Temp[T]
          ? Temp[T][`${T}.${K}`]
          : never
        : never;
    }
  : never;

export const Wrap = ({ children }: { children: JSX.Element }) => (
  <div style={{ margin: '64px auto', width: '400px' }}>{children}</div>
);
