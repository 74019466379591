/* eslint-disable @typescript-eslint/no-unused-vars */
import { isResponseError } from 'api/types';
import { isVerifyUser, VerifyUser } from 'api/user/types';
import { TemplateNotLogged } from 'components/common/templates/TemplateNotLogged';
import { InviteUserForm } from 'components/user/InviteUser/InviteUserForm';
import { AUTH_CODE_TYPE_UNIIKEY } from 'define';
import { useInvitationUserVerifyCode } from 'hooks/user/useInvitationUserVerifyCode';
import { FC, useEffect, useMemo, useState } from 'react';
import { Helmet } from 'react-helmet-async';
import { useParams } from 'react-router-dom';

export const InviteUser: FC = () => {
  const [verifyUser, setVerifyUser] = useState<VerifyUser>({
    invitationCode: '',
    authTypeCode: AUTH_CODE_TYPE_UNIIKEY,
    message: '',
    email: '',
  })
  const [ErrorMsgs, setErrorMsgs] = useState<string[]>([]);
  const { verifyCode = '' } = useParams();
  const data = useInvitationUserVerifyCode(verifyCode);

  const accountCreateType = useMemo<VerifyUser['authTypeCode']>(() => verifyUser.authTypeCode, [verifyUser.authTypeCode]);
  const inviteEmailAddress = useMemo<VerifyUser['email']>(() => verifyUser.email, [verifyUser.email]);

  useEffect(() => {
    if (isResponseError(data)) {
      const msgs = data.others as string[];
      setErrorMsgs(msgs)
    }
    if (isVerifyUser(data)) {
      setVerifyUser(data);
    }
  }, [data]);

  return (
    <>
      <Helmet>
        <title>アカウント登録</title>
      </Helmet>
      <TemplateNotLogged>
        <InviteUserForm createType={accountCreateType} inviteEmailAddress={inviteEmailAddress} errorMsgs={ErrorMsgs} />
      </TemplateNotLogged>
    </>
  );
};
