import { FirstLoginMailType } from 'api/transactionMail/type';

export const queryKeyNames = {
  getMailSetting: 'getMailSetting',
  getSendingContentsList: 'getSendingContentsList',
  getSendingContent: 'getSendingContent',
} as const;

export const queryKeys = {
  getMailSetting: ({ tenantId }: { tenantId: string }) => [
    queryKeyNames.getMailSetting,
    tenantId,
  ],
  getSendingContentsList: ({ tenantId }: { tenantId: string }) => [
    queryKeyNames.getSendingContentsList,
    tenantId,
  ],
  getSendingContent: ({
    tenantId,
    id,
  }: {
    tenantId: string;
    id: FirstLoginMailType['id'];
  }) => [queryKeyNames.getSendingContent, tenantId, id],
} as const;
