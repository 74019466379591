import {
  AlertDialogCloseButton,
  AlertDialogContent,
  AlertDialogHeader,
  AlertDialogOverlay,
  Button,
  DrawerBody,
  DrawerFooter,
  HStack,
  Text,
} from '@chakra-ui/react';
import { withSuspenseAndErrorBoundary } from 'admin/components/Error/WithErrorBoundary';
import { LoadingLayer } from 'admin/components/ui/loadingLayer';
import { ColorSet } from 'admin/define/colors';
import { FirstLoginMailType } from 'api/transactionMail/type';
import { ErrorContents, LoadingSkeleton } from 'components/common/atoms';
import { useChangeSendingContentStatus } from 'hooks/transactionMail/useChangeSendingContentStatus';
import { Dispatch, FC, SetStateAction, useCallback, useMemo } from 'react';

type Props = {
  onClose: () => void;
  target?: FirstLoginMailType;
  setTarget: Dispatch<SetStateAction<FirstLoginMailType | undefined>>;
};

const FetchSettingContentStatusChangeDialog: FC<Props> = ({
  onClose,
  target,
  setTarget,
}) => {
  const { mutate, isLoading } = useChangeSendingContentStatus(target?.id ?? '');

  const onSubmit = useCallback(async () => {
    await mutate(Boolean(target?.status));
    onClose();
    setTarget(undefined);
  }, [mutate, onClose, setTarget, target]);

  const afterStatus = useMemo(() => target?.status, [target]);

  return (
    <>
      {isLoading && <LoadingLayer />}
      <AlertDialogOverlay>
        <AlertDialogContent>
          <AlertDialogHeader p={6}>
            <Text>使用設定変更の確認</Text>
          </AlertDialogHeader>
          <DrawerBody fontSize="16px" py={0}>
            <Text fontSize="sm">
              使用設定を
              <Text as="span" fontWeight={700}>
                「{afterStatus ? '停止中' : '使用中'}」
              </Text>
              にします。
            </Text>
            <Text fontSize="sm">
              <Text as="span" fontWeight={700}>
                「{afterStatus ? '停止中' : '使用中'}」
              </Text>
              にしたメール設定
              {!afterStatus ? (
                <Text as="span" fontWeight={700}>
                  のみが有効
                </Text>
              ) : (
                <>
                  が
                  <Text as="span" fontWeight={700}>
                    無効
                  </Text>
                </>
              )}
              になります。
            </Text>
          </DrawerBody>
          <DrawerFooter pb="24px">
            <HStack spacing={4}>
              <Button
                variant="outline"
                onClick={() => onClose()}
                {...ColorSet.Default}
                padding="8px 16px"
              >
                キャンセル
              </Button>
              <Button
                variant="primary"
                mr={3}
                padding="8px 36px"
                onClick={onSubmit}
              >
                実行
              </Button>
            </HStack>
          </DrawerFooter>
          <AlertDialogCloseButton top="24px" right="24px" />
        </AlertDialogContent>
      </AlertDialogOverlay>
    </>
  );
};

export const SettingContentStatusChangeDialog = withSuspenseAndErrorBoundary(
  FetchSettingContentStatusChangeDialog,
  {
    ErrorComponent: <ErrorContents name="送信内容設定ステータス変更" />,
    LoadingComponent: <LoadingSkeleton width="100%" />,
  },
);
