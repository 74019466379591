import { updateIdPoolConsumerAttribute } from 'admin/api/userPool/attribute/updateIdPoolConsumerAttribute';
import { CustomError } from 'admin/error/CustomError';
import { useResetAllAttributes } from 'admin/hooks/userPool/attribute/useResetAllAttributes';
import { useUserPoolAuthenticationType } from 'admin/hooks/userPool/useUserPoolAuthenticationType';
import { IdPoolConsumerAttributeType } from 'admin/types/userPool/attribute';
import { IdPoolConsumerAttributeUpdateFormType } from 'admin/types/userPool/attribute/form';
import { logger } from 'api/logger';
import { useCustomToast } from 'hooks/useCustomToast';
import { Dispatch, SetStateAction } from 'react';
import { UseFormSetError } from 'react-hook-form';
import { useMutation, useQueryClient } from 'react-query';
import { toMultiError } from 'utils/form';
import { errorToast, successToast } from 'utils/toast';
import { queryIdPoolConsumerAttributeKey } from './queryIdPoolConsumerAttributeKey';

export const useUpdateIdPoolConsumerAttribute = ({
  id,
  userPoolId,
  setError,
  setGlobalErrors,
}: {
  id: IdPoolConsumerAttributeType['id'];
  userPoolId: IdPoolConsumerAttributeType['userPool'];
  setError: UseFormSetError<IdPoolConsumerAttributeUpdateFormType>;
  setGlobalErrors: Dispatch<SetStateAction<string[]>>;
}) => {
  const toast = useCustomToast();
  const queryClient = useQueryClient();
  const resetAttribute = useResetAllAttributes(userPoolId);
  const { isOuter: isExternalId } = useUserPoolAuthenticationType();
  const { mutateAsync, isLoading } = useMutation({
    mutationFn: (data: IdPoolConsumerAttributeUpdateFormType) =>
      updateIdPoolConsumerAttribute({ data, userPoolId, id, isExternalId }),
    onError: async (
      error,
      variables: IdPoolConsumerAttributeUpdateFormType,
    ) => {
      if (error instanceof CustomError) {
        if (error.cause.errors?.others) {
          const othersMsgs = Array.isArray(error.cause.errors?.others)
            ? error.cause.errors?.others
            : [error.cause.errors?.others];

          setGlobalErrors(othersMsgs);
        } else {
          Object.keys(variables).forEach((k) => {
            const key = k as keyof IdPoolConsumerAttributeUpdateFormType;
            const errMsgs = error.cause.errors?.[key];
            if (errMsgs && Array.isArray(errMsgs)) {
              setError(key, { types: toMultiError(errMsgs) });
            }
          });
        }

        if (error.cause.errors) {
          await logger({
            loglevel: error.logLevel,
            data: JSON.stringify(error.cause),
            message: error.message,
          });
        }
      }
      toast({
        ...errorToast,
        duration: 4000,
        title: '組織レベル属性設定の更新に失敗しました',
        position: 'bottom',
        variant: 'solid',
      });
    },
    onSuccess: () => {
      void queryClient.invalidateQueries(
        queryIdPoolConsumerAttributeKey.getIdPoolConsumerAttributesDetail(
          id,
          userPoolId,
        ),
      );
      resetAttribute();

      toast({
        ...successToast,
        duration: 4000,
        title: '組織レベル属性設定を更新しました',
        position: 'bottom',
        variant: 'solid',
      });
    },
  });

  return { mutate: mutateAsync, isLoading };
};
