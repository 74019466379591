import { Box, Text } from '@chakra-ui/react';
import { LogoHeaderProps } from 'components/common/molecules';
import { TemplateNotLogged } from 'components/common/templates/TemplateNotLogged';
import { LoginContents } from 'components/login/loginContents';
import { FC } from 'react';
import { Helmet } from 'react-helmet-async';
import { useResetRecoilState } from 'recoil';
import { editCallbackState, isEditState, navigateState } from 'store/navigate';
import { userState, userTenantIdState, userTenantState } from 'store/user';

export const Login: FC<LogoHeaderProps> = () => {
  const resetUserState = useResetRecoilState(userState);
  const resetUserTenantIdState = useResetRecoilState(userTenantIdState);
  const resetUserTenantState = useResetRecoilState(userTenantState);
  const resetNavigatePath = useResetRecoilState(navigateState);
  const resetIsEdit = useResetRecoilState(isEditState);
  const resetEditCallback = useResetRecoilState(editCallbackState);

  // recoil情報を初期化する
  resetUserState();
  resetUserTenantIdState();
  resetUserTenantState();
  resetNavigatePath();
  resetIsEdit();
  resetEditCallback();

  return (
    <TemplateNotLogged m="auto" formPosition="flex-start">
      <Helmet>
        <title>ログイン</title>
      </Helmet>
      <Box
        display="flex"
        justifyContent="center"
        mb="32px"
        fontWeight="700"
        fontSize="24px"
      >
        <Text>ログイン</Text>
      </Box>
      <LoginContents />
    </TemplateNotLogged>
  );
};
