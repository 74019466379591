import { VFC, useCallback, memo } from 'react';
import { useForm, FormProvider } from 'react-hook-form';
import { useSetRecoilState } from 'recoil';
import { Link } from 'react-router-dom';
import { NodeModel } from '@minoru/react-dnd-treeview';
import { Box, Text, ListIcon, Flex } from '@chakra-ui/react';
import { AiFillFolder, AiFillFolderOpen } from 'react-icons/ai';
import { BsThreeDotsVertical } from 'react-icons/bs';
import { DropDownMenu } from 'components/common/atoms';
import { currentNodeIdSelector } from 'store/treenode';
import {
  isGroup,
  AddGroupFormType,
  DeleteGroupFormType,
  EditGroupFormType,
} from 'api/tenant/types';
import { useUserInfo } from 'hooks/useUserInfo';
import { AddGroupDialog } from './AddGroupDialog';
import { DeleteGroupDialog } from './DeleteGroupDialog';
import { EditGroupDialog } from './EditGroupDialog';

// T: NodeModelの data プロパティに格納するデータ型
export type TreeNodeProps<T> = {
  node: NodeModel<T>;
  depth: number;
  isOpen: boolean;
  isSelected: boolean;
  isFolder: boolean;
  onToggle: (id: NodeModel['id']) => void;
  onSelect: (node: NodeModel<T>) => void;
};

const TreeIcon: VFC<{ isOpen: boolean }> = memo(({ isOpen }) => {
  if (isOpen) {
    return <ListIcon as={AiFillFolderOpen} />;
  }

  return <ListIcon as={AiFillFolder} />;
});

const AddGroupDefaultValues: AddGroupFormType = {
  name: '',
  tenantId: '',
  parentId: '',
};

const DeleteGroupDefaultValues: DeleteGroupFormType = {
  groupId: '',
  tenantId: '',
  deleteText: '',
};

const EditGroupDefaultValues: EditGroupFormType = {
  groupId: '',
  tenantId: '',
  parentId: '',
  name: '',
};
let groupId: string;
let tenantId: string;
let parentId: string | null;
let groupName: string;

export const TreeNode = <T,>({
  node,
  depth,
  isOpen,
  isSelected,
  isFolder,
  onToggle,
  onSelect,
}: TreeNodeProps<T>): JSX.Element => {
  const setNodeId = useSetRecoilState(currentNodeIdSelector);
  const { isStaff, isAssistant } = useUserInfo();
  const AddGroupMethods = useForm<AddGroupFormType>({
    mode: 'onBlur',
    defaultValues: AddGroupDefaultValues,
  });
  const DeleteGroupMethods = useForm<DeleteGroupFormType>({
    mode: 'onBlur',
    defaultValues: DeleteGroupDefaultValues,
  });
  const EditGroupMethods = useForm<EditGroupFormType>({
    mode: 'onBlur',
    defaultValues: EditGroupDefaultValues,
  });
  const indent = `${(depth * 4 + 2) * 0.25}rem`;

  if (isGroup(node?.data)) {
    groupId = node?.data.id;
    tenantId = node?.data.tenantId;
    parentId = node?.data.parentId;
    groupName = node?.data.name;
  }

  const clickHandler = useCallback(() => {
    if (
      isFolder &&
      (typeof node.id === 'string' || typeof node.id === 'number')
    ) {
      onToggle(node.id);
    }
    onSelect(node);
    setNodeId(node.id);
  }, [isFolder, onToggle, onSelect, setNodeId, node]);

  const menuItems = [
    {
      menuItemType: 'dialog',
      testId: 'group-add',
      children: (
        <FormProvider {...AddGroupMethods}>
          <AddGroupDialog
            formId="addGroup-form"
            parentId={groupId}
            openBtnElemType="text"
          />
        </FormProvider>
      ),
    },
    {
      menuItemType: 'dialog',
      testId: 'group-delete',
      children: (
        <FormProvider {...EditGroupMethods}>
          <EditGroupDialog
            formId="editGroup-form"
            groupId={groupId}
            tenantId={tenantId}
            parentId={parentId || ''}
            groupName={groupName}
            openBtnElemType="text"
          />
        </FormProvider>
      ),
    },
    {
      menuItemType: 'dialog',
      testId: 'group-delete',
      children: (
        <FormProvider {...DeleteGroupMethods}>
          <DeleteGroupDialog
            formId="deleteGroup-form"
            groupId={groupId}
            tenantId={tenantId}
            openBtnElemType="text"
          />
        </FormProvider>
      ),
    },
  ];

  return (
    <Flex
      lineHeight={9}
      as="span"
      onClick={clickHandler}
      data-id={node.id}
      style={{ cursor: 'pointer' }}
      alignItems="center"
      justifyContent="space-between"
    >
      <Link to={`/setting/groups/${node.id}/`} style={{ width: '100%' }}>
        <Box
          flex={1}
          pl={indent}
          borderRadius={4}
          style={isSelected ? { backgroundColor: '#eee' } : {}}
        >
          <TreeIcon isOpen={isOpen} />
          <Text as="span">{node.text}</Text>
        </Box>
      </Link>

      <Box position="relative" flexBasis={8} width={8}>
        {!isStaff && !isAssistant && (
          <DropDownMenu
            menuType="icon"
            items={menuItems}
            icon={<BsThreeDotsVertical />}
            variant="none"
            width="100%"
            // width={44}
            zIndex="1500"
          />
        )}
      </Box>
    </Flex>
  );
};
