import { UseMutateFunction, useMutation } from 'react-query';
import { createSearchFilter } from 'api/contents/createSearchFilter';
import { ResponseError } from 'api/types';
import {
  CreateSearchFilter,
  isSearchFilter,
  SearchFilter,
} from 'api/contents/types';
import { isGetValidationError, toMultiError } from 'utils/form';
import { FieldErrors } from 'react-hook-form';

type UnPromisify<T> = T extends Promise<infer U> ? U : T;

export const searchFilterData: SearchFilter = {
  page: 1,
  limit: 100,
  sort: [],
  select: [],
  filter: {
    combinator: 'and',
    rules: [],
  },
};

/**
 * 検索JSONデータ取得hooks
 * @param {setFilterJson} callback SearchFilter
 * @param {Object} callback FieldErrors
 * @returns {Object} { onSubmit, isLoading }
 */

export const useCreateSearchFilterJson = ({
  setFilterJson,
  setErrList,
}: {
  setFilterJson: (jsonData: SearchFilter) => void;
  setErrList: (errors: FieldErrors) => void;
}): {
  onSubmit: UseMutateFunction<
    UnPromisify<ReturnType<typeof createSearchFilter>>,
    unknown,
    CreateSearchFilter,
    unknown
  >;
  isLoading: boolean;
} => {
  const { mutate: onSubmit, isLoading } = useMutation(
    (formData: CreateSearchFilter) => createSearchFilter(formData),
    {
      onSuccess: (result: SearchFilter | boolean | ResponseError) => {
        if (isSearchFilter(result)) {
          setFilterJson(result);
        }

        if (
          isGetValidationError<SearchFilter>(
            result,
            Object.keys(searchFilterData),
          )
        ) {
          Object.keys(result).forEach((k) => {
            const key = k as keyof SearchFilter;
            const errMsgs = result?.[key];
            if (errMsgs) {
              setErrList({ [key]: { types: toMultiError(errMsgs) } });
            }
          });
        }
      },
    },
  );

  return { onSubmit, isLoading };
};
