import { VFC, memo } from 'react';
import { useSearchParams } from 'react-router-dom';
import { Helmet } from 'react-helmet-async';
import { Stack } from '@chakra-ui/react';
import ErrorBoundary from 'api/ErrorBoundary';
import { useErrorBoundaryReset } from 'hooks/useErrorBoundaryReset';
import { useSetPageTitle } from 'hooks/useSetPageTitle';
import { ErrorStatusContents } from 'components/common/atoms';
import { SegmentCreateBody } from 'components/segment/organisms/SegmentCreateBody';
import { SegmentEditBody } from 'components/segment/organisms/SegmentEditBody';

const SegmentFormInner: VFC = memo(() => {
  useSetPageTitle('セグメント作成');
  const [searchParams] = useSearchParams();
  const segmentId = searchParams.get('segmentId') || '';

  return (
    <Stack spacing={0} w="100%" maxW="1000px" h="full">
      <Helmet>
        <title>セグメント作成</title>
      </Helmet>
      {segmentId === '' && <SegmentCreateBody />}
      {segmentId !== '' && <SegmentEditBody segmentId={segmentId} />}
    </Stack>
  );
});

export const SegmentForm: VFC = () => {
  const { ebKey, onError } = useErrorBoundaryReset();
  const renderErrorComponent = {
    404: <ErrorStatusContents code={404} />,
    450: <ErrorStatusContents code={450} />,
  };

  return (
    <ErrorBoundary
      errorComponent={<></>}
      statusComponent={renderErrorComponent}
      onError={onError}
      key={ebKey.current}
    >
      <SegmentFormInner />
    </ErrorBoundary>
  );
};
