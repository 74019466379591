import { CustomError } from 'admin/error/CustomError';
import { logger } from 'api/logger';
import { editSendingContent } from 'api/transactionMail/editSendingContent';
import { FirstLoginMailFormType } from 'api/transactionMail/type';
import { useCustomToast } from 'hooks/useCustomToast';
import { useUserTenantId } from 'hooks/user/useUserTenantId';
import { UseFormSetError } from 'react-hook-form';
import { useMutation, useQueryClient } from 'react-query';
import { useNavigate } from 'react-router-dom';
import { toMultiError } from 'utils/form';
import { errorToast, successToast } from 'utils/toast';
import { queryKeys } from './queryKeys';

export const useEditSendingContent = (
  setError: UseFormSetError<FirstLoginMailFormType>,
) => {
  const tenantId = useUserTenantId();
  const toast = useCustomToast();
  const navigate = useNavigate();
  const queryClient = useQueryClient();

  const { mutateAsync, isLoading } = useMutation({
    mutationFn: (data: FirstLoginMailFormType) =>
      editSendingContent(tenantId, data),
    onSuccess: () => {
      void queryClient.invalidateQueries(
        queryKeys.getSendingContentsList({ tenantId }),
      );
      toast({
        ...successToast,
        duration: 4000,
        title: '送信内容設定を保存しました',
        position: 'bottom',
        variant: 'solid',
      });
      const url = `/transactionMail/list`;
      navigate(url);
    },
    onError: async (error, variables: FirstLoginMailFormType) => {
      if (error instanceof CustomError) {
        if (error.cause.errors?.others) {
          const othersMsgs = Array.isArray(error.cause.errors?.others)
            ? error.cause.errors?.others
            : [error.cause.errors?.others];
          toast({
            status: 'error',
            position: 'bottom',
            duration: 4000,
            isClosable: true,
            description: othersMsgs[0],
          });
        } else {
          Object.keys(variables).forEach((k) => {
            const key = k as keyof FirstLoginMailFormType;
            const errMsgs = error.cause.errors?.[key];
            if (errMsgs && Array.isArray(errMsgs)) {
              setError(key, { types: toMultiError(errMsgs) });
            }
          });
        }

        if (error.cause.errors) {
          await logger({
            loglevel: error.logLevel,
            data: JSON.stringify(error.cause),
            message: error.message,
          });
        }
      }
      toast({
        ...errorToast,
        duration: 4000,
        title: '送信内容設定の保存に失敗しました',
        position: 'bottom',
        variant: 'solid',
      });
    },
  });

  return { mutate: mutateAsync, isLoading };
};
