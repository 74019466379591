import { Box, Flex, Icon } from '@chakra-ui/react';
import styled from '@emotion/styled';
import { ErrorTextMsg } from 'components/common/atoms/ErrorTextMsg';
import { Button } from 'components/paywall/atoms/Button';
import { Input } from 'components/paywall/atoms/Input';
import { Link } from 'components/paywall/atoms/Link';
import { usePaywallUtil } from 'components/paywall/hooks/usePaywallUtil';
import { useWallEditor } from 'components/paywall/hooks/useWallEditor';
import { WallDesignDefaultValues } from 'components/paywall/pages/WallDesignList/initial';
import { WallDesign } from 'components/paywall/pages/WallDesignList/typed';
import { Editor } from 'components/paywall/pages/WallDesignList/WallEditor/Editor';
import { FC, memo, useEffect, useRef, useState } from 'react';
import EmailEditor from 'react-email-editor';
import { FormProvider, useForm } from 'react-hook-form';
import { MdArrowBackIos } from 'react-icons/md';
import { toErrMsgList } from 'utils/form';

const StyledFlexHeader = styled(Flex)({
  alignItems: 'center',
  justifyContent: 'space-between',
  marginBottom: '1rem',
});

const StyledTitleInput = styled(Box)({
  width: '75%',
  minWidth: '150px',
});

export const Form: FC = memo(() => {
  const editorRef = useRef<EmailEditor>(null);
  const [isLoading, setLoading] = useState<boolean>(false);
  const [isEditorLoading, setEditorLoading] = useState<boolean>(false);
  const { isOperationAuth } = usePaywallUtil();
  const methods = useForm<WallDesign>({
    mode: 'onBlur',
    defaultValues: WallDesignDefaultValues,
  });
  const { getValues, setValue, register, setError, formState: { errors } } = methods;
  const { saveEditor, init } = useWallEditor({
    editor: editorRef,
    getValues,
    setValue,
    setError,
    setLoading,
  });

  useEffect(() => {
    init();
  }, []);

  return (
    <FormProvider {...methods}>
      <form>
        <StyledFlexHeader>
          <Box>
            <Link to="./../../wallDesignList">
              <Icon as={MdArrowBackIos} />
              一覧へ戻る
            </Link>
          </Box>
          <StyledTitleInput>
            <Input
              placeholder="タイトル"
              ref={register('name')}
              disabled={!isEditorLoading}
            />
            {toErrMsgList(errors, 'name').map((err, idx) => (
              <ErrorTextMsg key={`error-paywall-${String(idx)}`} msg={err} />
            ))}
          </StyledTitleInput>
          <Button
            colorScheme="blue"
            onClick={saveEditor}
            isLoading={!isEditorLoading||isLoading}
            disabled={!isEditorLoading||isLoading||!isOperationAuth()}
          >
            保存
          </Button>
        </StyledFlexHeader>
        {!isOperationAuth() && (
          <ErrorTextMsg msg='編集権限がないため保存することができません' />
        )}
        <Editor
          editor={editorRef}
          setIsLoading={setEditorLoading}
          isLoading={isEditorLoading}
        />
      </form>
    </FormProvider>
  );
});
