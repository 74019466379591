import { Box, Flex, Text } from '@chakra-ui/react';
import { withSuspenseAndErrorBoundary } from 'admin/components/Error/WithErrorBoundary';
import { Colors } from 'admin/define/colors';
import { ErrorContents } from 'components/common/atoms';
import { FC, memo } from 'react';

export const transactionMailSettingTitle: FC = memo(() => (
  <Box mt="40px" mb="24px">
    <Flex justifyContent="flex-end">
      <Box mr="auto">
        <Text
          fontFamily="Hiragino Sans"
          fontSize="18px"
          fontWeight="700"
          lineHeight="170%"
        >
          トランザクションメール設定
        </Text>
        <Text
          fontWeight="400"
          fontSize="14px"
          lineHeight="170%"
          fontFamily="Hiragino Sans"
          color={Colors.GRAY_00}
        >
          エンドユーザーのアクションに応じて自動的に送信される「トランザクションメール」の管理を行います。
        </Text>
      </Box>
    </Flex>
  </Box>
));

export const TransactionMailSettingTitle = withSuspenseAndErrorBoundary(
  transactionMailSettingTitle,
  {
    ErrorComponent: <ErrorContents name="ID会員登録時メール設定タイトル" />,
    LoadingComponent: <></>,
  },
);
