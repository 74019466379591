import { IconButton, Tooltip } from '@chakra-ui/react';
import { FC, memo, MouseEvent, useCallback } from 'react';
import { MdContentCopy } from 'react-icons/md';

type CopyProps = {
  label: string;
  onCopy: () => void;
  color?: string;
  fontSize?: number;
  isAbsolute?: boolean;
};

export const Copy: FC<CopyProps> = memo(({ label, onCopy, color="gray.500", fontSize=16, isAbsolute=true }: CopyProps) => {

  const onCopyHandler = useCallback((e: MouseEvent<HTMLButtonElement>) => {
    e.preventDefault();
    onCopy();
  }, [onCopy]);

  return (
    <Tooltip label={label} bgColor="white" color="black">
      <IconButton
        variant="link"
        color={color}
        aria-label="clipCopy"
        fontSize={fontSize}
        icon={<MdContentCopy />}
        onClick={onCopyHandler}
        _focus={{ focus: 'none' }}
        height={!isAbsolute ? `${(fontSize*1.4)}px` : 'auto'}
        verticalAlign={!isAbsolute ? "text-top" : "baseline"}
      />
    </Tooltip>
  )
});
