import { useEffect, useState, VFC } from 'react';
import { useDashboard } from 'hooks/dashboard/useDashboard';

export const HomeInner: VFC = () => {
  const results = useDashboard();
  const [dashboardUrl, setDashboardUrl] = useState<string>('');

  useEffect(() => {
    setDashboardUrl(results.url);
  }, [results]);

  return (
    <iframe
      width="1000"
      height="100%"
      src={dashboardUrl}
      title="dashboard"
      frameBorder="0"
    />
  );
};
