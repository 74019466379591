import { List, ListItem } from '@chakra-ui/react';
import { SideMenuLink } from 'components/common/atoms';
import { useSettingAuth } from 'components/setting/hooks/useSettingAuth';
import { useUserInfo } from 'hooks/useUserInfo';
import { FC, Fragment, memo } from 'react';
import { FiCreditCard, FiLogIn } from 'react-icons/fi';
import {
  IoBookOutline,
  IoClipboardOutline,
  IoKeyOutline,
  IoPeopleOutline,
  IoPeopleSharp,
  IoPersonOutline
} from 'react-icons/io5';
import { useLocation } from 'react-router-dom';

export const SettingsSide: FC = memo(() => {
  const location = useLocation();
  const { isAllowedPermission } = useSettingAuth();
  const { isEnabledFormHosting } = useUserInfo();
  const settingsMenuList = [
    {
      name: 'ユーザー管理',
      path: '/setting/users',
      icon: IoPeopleOutline,
      isArrowed: isAllowedPermission('users'),
    },
    {
      name: 'グループ管理',
      path: '/setting/groups',
      icon: IoPeopleSharp,
      isArrowed: isAllowedPermission('groups'),
    },
    {
      name: 'APIキー管理',
      path: '/setting/apikey',
      icon: IoKeyOutline,
      isArrowed: isAllowedPermission('apikey'),
    },
    {
      name: 'ユーザー設定',
      path: '/setting/userSetting',
      icon: IoPersonOutline,
      isArrowed: isAllowedPermission('userSetting'),
    },
    {
      name: 'メルマガ管理',
      path: '/setting/mailMagazine',
      icon: IoBookOutline,
      isArrowed: isAllowedPermission('mailMagazine'),
    },
    {
      name: '決済代行連携設定',
      path: '/setting/paymentAgency',
      icon: FiCreditCard,
      isArrowed: isAllowedPermission('paymentAgency'),
    },
  ];
  if (isEnabledFormHosting()) {
    settingsMenuList.push({
      name: 'フォーム関連設定',
      path: '/setting/formSetting',
      icon: IoClipboardOutline,
      isArrowed: isAllowedPermission('formSetting'),
    });
  }
  settingsMenuList.push({
    name: 'ログインリダイレクト設定',
    path: '/setting/loginRedirect',
    icon: FiLogIn,
    isArrowed: isAllowedPermission('loginRedirect'),
  });

  return (
    <List mr={4}>
      {settingsMenuList.map((item) => (
        <Fragment key={item.path}>
          {item.isArrowed && (
            <ListItem _hover={{ bg: 'gray.50' }}>
              <SideMenuLink
                name={item.name}
                path={item.path}
                isSelected={location.pathname.includes(item.path)}
                icon={item.icon}
              />
            </ListItem>
          )}
        </Fragment>
      ))}
    </List>
  );
});
