import { UseMutateFunction, useMutation } from 'react-query';
import { UseFormSetError } from 'react-hook-form';
import { getSearchData } from 'api/contents/getSearchData';
import { ResponseError } from 'api/types';
import {
  isTableDataSearchList,
  SearchFilter,
  TableDataSearchList,
  TableDataSearchListForm,
} from 'api/contents/types';
import { isGetValidationError, toMultiError } from 'utils/form';

type UnPromisify<T> = T extends Promise<infer U> ? U : T;

const tableSearchFilter: SearchFilter = {
  page: 0,
  limit: 0,
  sort: [],
  select: [],
  filter: { combinator: '', rules: [] },
};

export const useTableSearchData = ({
  setSearchList,
  setGlobalErrors,
  setError,
}: {
  setSearchList: (result: TableDataSearchList) => void;
  setGlobalErrors: (errors: string[]) => void;
  setError: UseFormSetError<SearchFilter>;
}): {
  onSubmit: UseMutateFunction<
    UnPromisify<ReturnType<typeof getSearchData>>,
    unknown,
    TableDataSearchListForm,
    unknown
  >;
  isLoading: boolean;
  isSuccess: boolean;
} => {
  const {
    mutate: onSubmit,
    isLoading,
    isSuccess,
  } = useMutation(
    ({ tenantId, tableId, filter }: TableDataSearchListForm) =>
      getSearchData({ tenantId, tableId, filter }),
    {
      onSuccess: (result: TableDataSearchList | boolean | ResponseError) => {
        // グローバルエラーを初期化
        setGlobalErrors([]);
        if (isTableDataSearchList(result)) {
          setSearchList(result);
        }

        if (
          isGetValidationError<SearchFilter>(
            result,
            Object.keys(tableSearchFilter),
          )
        ) {
          if (result?.others) {
            setGlobalErrors([result?.others?.join('')]);
          } else {
            Object.keys(result).forEach((k) => {
              const key = k as keyof SearchFilter;
              const errMsgs = result?.[key];
              if (errMsgs) {
                setError(key, { types: toMultiError(errMsgs) });
              }
            });
          }
        }
      },
    },
  );

  return { onSubmit, isLoading, isSuccess };
};
