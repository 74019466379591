import {
  AlertDialogCloseButton,
  AlertDialogContent,
  AlertDialogHeader,
  AlertDialogOverlay,
  Text,
} from '@chakra-ui/react';
import { CallbackUrlConfirmForm } from 'admin/components/service/auth/callbackUrlConfirmForm';
import { AuthFormInputType } from 'admin/types/service/auth/form';
import { FC, memo } from 'react';

type Props = {
  target: AuthFormInputType;
  onClose: () => void;
  onSubmit: (values: AuthFormInputType) => Promise<void>;
};

export const CallbackUrlConfirmDialog: FC<Props> = memo((props) => (
  <AlertDialogOverlay>
    <AlertDialogContent>
      <AlertDialogHeader p={6}>
        <Text>コールバックURL設定の確認</Text>
      </AlertDialogHeader>
      <CallbackUrlConfirmForm {...props} />
      <AlertDialogCloseButton top="24px" right="24px" />
    </AlertDialogContent>
  </AlertDialogOverlay>
));
