import { request } from 'admin/api/Request';
import { API_MESSAGES } from 'admin/define/apiMessages';
import { CustomError } from 'admin/error/CustomError';
import { TransactionMailTestMailFormType } from 'admin/types/userPool/transactionMail/form';
import { isResponseError } from 'api/types';
import { getValidationError } from 'utils/form';
import { snakeCase } from 'utils/str';

export const sendTestTransactionMail = async (
  idPoolConsumerId: string,
  data: TransactionMailTestMailFormType,
): Promise<TransactionMailTestMailFormType> => {
  const path = `uniikey/${idPoolConsumerId}/transaction_mails/test/`;
  const response = await request<TransactionMailTestMailFormType>({
    path,
    body: {
      ...data,
      type: snakeCase(data.type),
    },
    method: 'post',
  });

  if (response.hasError) {
    if (response.status === 404) {
      throw new CustomError(API_MESSAGES.USER_POOL.SEND_TEST_MAIL.FAILED, {
        path,
        status: response.status,
      });
    } else if (isResponseError(response.error)) {
      const customError = response.error;

      const formError = getValidationError<TransactionMailTestMailFormType>({
        formData: data,
        response: customError,
      });

      throw new CustomError(API_MESSAGES.USER_POOL.SEND_TEST_MAIL.ERROR, {
        path,
        params: data,
        errors: formError,
        status: response.status,
        logLevel: response.status === 400 ? 'warning' : 'error',
      });
    } else {
      throw new CustomError(API_MESSAGES.USER_POOL.SEND_TEST_MAIL.ERROR, {
        path,
        status: response.status,
      });
    }
  }

  return data;
};
