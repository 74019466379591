export type Page = {
  count: number;
  currentPage: number;
  countFrom: number;
  countTo: number;
  perPage: number;
  pageCount: number;
  next: string;
  previous: string;
};

export type DataWithPage<T> = {
  results: T;
} & Page;

export const isPage = (data: unknown): data is Page => {
  const p = data as Page;

  return (
    typeof p?.count === 'number' &&
    typeof p?.currentPage === 'number' &&
    typeof p?.countFrom === 'number' &&
    typeof p?.countTo === 'number' &&
    typeof p?.perPage === 'number' &&
    typeof p?.pageCount === 'number' &&
    (p?.next !== null ? typeof p?.next === 'string' : true) &&
    (p?.previous !== null ? typeof p?.previous === 'string' : true)
  );
};
