import { VFC, memo, useCallback, useState } from 'react';
import {
  VStack,
  List,
  ListItem,
  Box,
  Icon,
  Text,
  IconButton,
} from '@chakra-ui/react';
import { AddIcon } from '@chakra-ui/icons';
import { MdDragIndicator } from 'react-icons/md';
import {
  DragDropContext,
  Droppable,
  Draggable,
  DropResult,
} from 'react-beautiful-dnd';

import ErrorBoundary from 'api/ErrorBoundary';
import { useErrorBoundaryReset } from 'hooks/useErrorBoundaryReset';

type SampleTextType = {
  id: string;
  name: string;
};

type DraggableListItemProps = {
  list: SampleTextType[];
  handleDragEnd: (result: DropResult) => void;
};

/**
 * ドラッグ&ドロップ
 */
const DraggableListItem: VFC<DraggableListItemProps> = memo(
  ({ list, handleDragEnd }: DraggableListItemProps) => (
    <DragDropContext onDragEnd={handleDragEnd}>
      <Droppable droppableId="sampleList">
        {(dorppableProvided) => (
          <List
            mr={4}
            w="100%"
            {...dorppableProvided.droppableProps}
            ref={dorppableProvided.innerRef}
          >
            {list.map((item, index) => (
              <Draggable key={item.id} draggableId={item.id} index={index}>
                {(draggableProvided) => (
                  <ListItem
                    ref={draggableProvided.innerRef}
                    {...draggableProvided.draggableProps}
                  >
                    <Box
                      borderWidth="1px"
                      borderRadius="md"
                      p={4}
                      py={2}
                      mb={2}
                      boxShadow="md"
                      backgroundColor="white"
                    >
                      <Box
                        textAlign="center"
                        {...draggableProvided.dragHandleProps}
                      >
                        <Icon
                          as={MdDragIndicator}
                          fontSize={20}
                          color="gray.500"
                          mr={2}
                          style={{ transform: 'rotate(90deg)' }}
                        />
                      </Box>
                      <Text>{item.name}</Text>
                    </Box>
                  </ListItem>
                )}
              </Draggable>
            ))}
            {dorppableProvided.placeholder}
          </List>
        )}
      </Droppable>
    </DragDropContext>
  ),
);

/**
 * ドラッグエリアテスト
 */
export const DraggableTest: VFC = memo(() => {
  const { ebKey, onError } = useErrorBoundaryReset();
  const [sampleTextList, setSampleTextList] = useState<SampleTextType[]>([]);

  // ドロップ時の処理
  const handleDragEnd = useCallback(
    (result: DropResult) => {
      if (!result.destination) {
        return;
      }

      const newList = [...sampleTextList];

      const [removed] = newList.splice(result.source.index, 1);
      newList.splice(result.destination.index, 0, removed);
      setSampleTextList(newList);
    },
    [sampleTextList],
  );

  // 追加ボタン押下時の処理
  const onClickHandler = useCallback(() => {
    const list: SampleTextType[] = [];
    Object.assign(list, sampleTextList);
    list.push({
      id: String(list.length),
      name: `サンプルテキスト${String(list.length)}`,
    });
    setSampleTextList(list);
  }, [sampleTextList]);

  return (
    <VStack spacing={4} flex={1} alignItems="flex-start">
      <ErrorBoundary onError={onError} key={ebKey.current}>
        <DraggableListItem
          list={sampleTextList}
          handleDragEnd={handleDragEnd}
        />
        <Box w="100%" textAlign="center">
          <IconButton
            aria-label="Search database"
            icon={<AddIcon />}
            onClick={onClickHandler}
          />
        </Box>
      </ErrorBoundary>
    </VStack>
  );
});
