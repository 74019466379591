// ----- fetch関連 -----
const GET_SAML = {
  ERROR:
    '申し訳ございません。SAML設定情報の取得ができません。時間を置いて再表示をお願いします。',
  NOT_FOUND: 'SAML設定が存在しません。既に削除された可能性があります。',
} as const;

const GET_DOMAIN_LIST = {
  ERROR:
    '申し訳ございません。対象ドメインの一覧情報の取得ができません。時間を置いて再表示をお願いします。',
  NOT_FOUND:
    '対象ドメインの一覧が存在しません。既に削除された可能性があります。',
} as const;

// ----- Mutate関連 -----
const SAVE_SAML = {
  ERROR: '申し訳ございません。SAML設定の保存に失敗しました。',
  FAILED:
    'SAML設定の保存に失敗しました。恐れ入りますが時間をおいて再度実行してください。',
} as const;

export const SAML = {
  SAML: {
    GET_SAML,
    GET_DOMAIN_LIST,
    SAVE_SAML,
  },
};
