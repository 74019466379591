import { FC, memo } from 'react';
import { Box, Text, Flex } from '@chakra-ui/react';
import { CodeDisplay } from 'components/setting/apikey/atoms/CodeDisplay';
import { Container } from 'components/setting/apikey/templates/Container';
import { TenantApiKey } from 'api/tenant/types';

interface ApiKeyProps {
  tenantApiKey: TenantApiKey | undefined;
}

export const Apikey: FC<ApiKeyProps> = memo(({ tenantApiKey }: ApiKeyProps) => (
  <Container text="API キー">
    <>
      <Box fontSize={13}>
        <Text>フォームなどのUEC上で作成できるデータに利用します。</Text>
        <Flex mt={2}>
          <Text w={14}>利用環境</Text>:&nbsp;
          <Text>ブラウザ（JavaScript）</Text>
        </Flex>
        <Flex>
          <Text w={14}>用途</Text>:&nbsp;
          <Text>テナントの識別</Text>
        </Flex>
      </Box>
      <CodeDisplay>
        <>
          <Text>Content-Type:&nbsp;application/json;</Text>
          <Text>
            x-apikey:&nbsp;{tenantApiKey?.apiKey || '{ 発行されるAPIキー }'}
          </Text>
        </>
      </CodeDisplay>
      <Text fontSize={12}>
        なおリクエスト/レスポンスに使用する文字コードは&nbsp;UTF-8&nbsp;となります。
      </Text>
    </>
  </Container>
));
