import { ErrorStatusContents, LoadingSkeleton } from 'components/common/atoms';
import { useErrorBoundaryReset } from 'hooks/useErrorBoundaryReset';
import { ComponentType, ReactNode, Suspense } from 'react';
import ErrorBoundary from './ErrorBoundary';

/**
 * ErrorBoundary HOC
 * @param WrappedComponent ErrorBoundaryに内包するコンポーネント
 * @param Option.ErrorComponent Error発生時の代替表示コンポーネント
 * @param Option.LoadingComponent 非同期処理時の表示コンポーネント
 */

export const withSuspenseAndErrorBoundary =
  <T,>(
    WrappedComponent: ComponentType<T>,
    Option?: {
      ErrorComponent?: ReactNode | null,
      LoadingComponent?: ReactNode | null,
    }
  ) =>
  (props: T) => {
    const { ebKey, onError } = useErrorBoundaryReset();
    const renderErrorComponent = {
      404: <ErrorStatusContents code={404} />,
      450: <ErrorStatusContents code={450} />,
    };

    return (
      <ErrorBoundary
        errorComponent={Option?.ErrorComponent??null}
        statusComponent={renderErrorComponent}
        onError={onError}
        key={ebKey.current}
      >
        <Suspense fallback={Option?.LoadingComponent??<LoadingSkeleton />}>
          <WrappedComponent {...props} />
        </Suspense>
      </ErrorBoundary>
    );
  };
