import { Options } from 'ky';
import { request } from 'api/request';
import { logger } from 'api/logger';
import produce from 'immer';
import { ResponseError, isResponseError } from 'api/types';
import { MailSetting, MailSettingForm, isMailSetting } from 'api/mail/types';
import { getValidationError, ValidationError } from 'utils/form';

import { messages } from './messages';

const DEFAULT_ERR_MESSAGE: ValidationError<MailSetting> = {
  others: [messages.putMailSetting.customer.error],
};
const DEFAULT_SYSTEM_ERR_MESSAGE: ValidationError<MailSetting> = {
  others: [messages.putMailSetting.customer.failed],
};

/**
 * メール設定情報変更
 *
 * 状態により以下のレスポンスを返却します
 * 正常時: true
 * エラー: ResponseError
 *
 */
export const putMailSetting = async (
  formParam: MailSettingForm,
  options?: Options,
): Promise<MailSetting | ResponseError> => {
  const { tenantId, mailSetting } = formParam;
  const path = `mail/${tenantId}/${mailSetting.id}/`;
  const postParam = mailSetting;

  const mergedOptions = options
    ? produce(options, (draft) => {
        draft.json = { ...postParam };
      })
    : { json: postParam };

  const response = await request({
    path,
    method: 'put',
    options: mergedOptions,
  });

  if (response.status >= 500) {
    return DEFAULT_ERR_MESSAGE;
  }

  const responseData = response.data;

  // メール設定保存成功
  if (isMailSetting(responseData)) {
    return responseData;
  }

  const { error } = response;
  if (response.hasError) {
    // APIにて返却されたエラー形式
    if (isResponseError(error)) {
      const formError = getValidationError<MailSetting>({
        formData: formParam.mailSetting,
        response: error,
        // replaceKeys: {
        //   nonFieldErrors: 'user',
        // },
      });

      return formError;
    }
  }

  // 予期せぬエラーなので
  // バックエンドに状態を通知
  await logger({
    loglevel:
      response.status === 404 || response.status === 450 ? 'warning' : 'error',
    data: error,
    message: messages.putMailSetting.system.typeError,
  });

  // 登録できていない旨を通知
  return DEFAULT_SYSTEM_ERR_MESSAGE;
};
