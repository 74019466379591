import { getIdPoolConsumerDetail } from 'admin/api/userPool/getIdPoolconsumerDetail';
import { CustomError } from 'admin/error/CustomError';
import { useIdPoolConsumerId } from 'admin/hooks/useIdPoolConsumerId';
import { useUserCorporationInfo } from 'admin/hooks/user/useUserCorporationInfo';
import { queryIdPoolConsumerDetailKey } from 'admin/hooks/userPool/queryIdPoolConsumerDetailKey';
import { logger } from 'api/logger';
import { useCustomToast } from 'hooks/useCustomToast';
import { useQuery } from 'react-query';
import { errorToast } from 'utils/toast';

export const useIdPoolConsumerUserInfo = () => {
  const userPoolId = useIdPoolConsumerId();
  const { currentUserPoolGroupId } = useUserCorporationInfo();
  const toast = useCustomToast();
  const { data } = useQuery({
    queryKey: queryIdPoolConsumerDetailKey.getIdPoolConsumerDetail(userPoolId),
    queryFn: () =>
      getIdPoolConsumerDetail({
        id: userPoolId,
        currentUserPoolGroupId: currentUserPoolGroupId || '',
      }),
    onError: async (error) => {
      if (error instanceof CustomError) {
        await logger({
          loglevel: error.logLevel,
          data: JSON.stringify(error.cause),
          message: error.message,
        });
      }
      toast({
        ...errorToast,
        duration: 4000,
        title: '外部IDシステムJSON設定詳細の取得に失敗しました',
        position: 'bottom',
        variant: 'solid',
      });
    },
  });

  return { data };
};
