import { VFC, memo, useCallback, Suspense } from 'react';
import { Outlet, useNavigate } from 'react-router-dom';
import { VStack } from '@chakra-ui/react';

import ErrorBoundary from 'api/ErrorBoundary';
import { useErrorBoundaryReset } from 'hooks/useErrorBoundaryReset';
import { useSetPageTitle } from 'hooks/useSetPageTitle';
import { useUserInfo } from 'hooks/useUserInfo';
import { LoadingSkeleton } from 'components/common/atoms';
import { SideMenyLayout } from 'components/common/templates/SideMenyLayout';
import { GroupsSide } from 'components/setting/groups/templates/GroupsSide';
import { GroupsHeader } from 'components/setting/groups/templates/GroupsHeader';

export const Groups: VFC = memo(() => {
  useSetPageTitle('グループ管理');
  const { ebKey, onError } = useErrorBoundaryReset();
  const { isStaff } = useUserInfo();
  const navigate = useNavigate();

  const notFoundError = useCallback(() => {
    onError();
    // 設定 > グループ へ遷移
    navigate('/setting/groups/');
  }, [onError, navigate]);

  return (
    <VStack spacing={0} h="full">
      {!isStaff && <GroupsHeader />}
      <SideMenyLayout
        sidemenu={<GroupsSide />}
        main={
          <ErrorBoundary onError={notFoundError} key={ebKey.current}>
            <Suspense fallback={<LoadingSkeleton />}>
              <Outlet />
            </Suspense>
          </ErrorBoundary>
        }
      />
    </VStack>
  );
});
