import { SystemError } from 'api/error';
import { logger } from 'api/logger';
import { getNotice } from 'api/notice/getNotice';
import { NoticeList } from 'api/notice/types';
import { GET_NOTICE_INTERVAL } from 'define';
import { useUserTenantId } from 'hooks/user/useUserTenantId';
import { useQuery } from 'react-query';
import { queryNoticeKey } from './queryNoticeKey';

/**
 * メッセージボックス & メール通知取得 hooks
 */
export const useNotice = (page: number): NoticeList => {
  const tenantId = useUserTenantId();

  const response = useQuery(
    queryNoticeKey.getNotice({ tenantId, page }),
    () => getNotice(tenantId, page),
    {
      refetchInterval: GET_NOTICE_INTERVAL,
      onError: async (error) => {
        if (error instanceof SystemError) {
          await logger({
            loglevel: error.logLevel,
            data: JSON.stringify(error.cause),
            message: error.message,
          });
        }
      },
    },
  );

  const results = response.data as NoticeList;

  return results;
};
