import { VFC, memo } from 'react';
import { Helmet } from 'react-helmet-async';
import { TemplateNotLogged } from 'components/common/templates/TemplateNotLogged';
import { ResetPasswordFormForget } from 'components/user/organisms/ResetPasswordFormForget';

export const ResetPasswordForget: VFC = memo(() => (
  <>
    <Helmet>
      <title>パスワード再登録</title>
    </Helmet>
    <TemplateNotLogged>
      <ResetPasswordFormForget />
    </TemplateNotLogged>
  </>
));
