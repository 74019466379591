import { ExternalLinkIcon } from '@chakra-ui/icons';
import {
  Box, Icon,
  Link, ListItem, OrderedList, Text, UnorderedList, useDisclosure
} from '@chakra-ui/react';
import { Dialog } from 'components/common/atoms';
import { memo, VFC } from 'react';
import { FiHelpCircle } from 'react-icons/fi';

type MailDeliveryToDirectHelpType = {
  isDisabled?: boolean;
};

export const MailDeliveryToDirectHelp: VFC<MailDeliveryToDirectHelpType> = memo(
  ({ isDisabled = false }: MailDeliveryToDirectHelpType) => {
    const { isOpen, onOpen, onClose } = useDisclosure();

    return (
      <Dialog
        title="メールアドレス直接指定について"
        isOpen={isOpen}
        onOpen={onOpen}
        onClose={onClose}
        openBtnChildNode={
          <>
            <Icon as={FiHelpCircle} fontSize={16} />
          </>
        }
        openBtnProps={{
          variant: 'ghost',
          color: '#536DB9',
          disabled: isDisabled,
          _hover: { bg: 'transparent' },
          _active: { bg: 'transparent' },
          _disabled: { pointerEvents: 'none' },
        }}
        submitBtnTitle="閉じる"
        submitBtnHandelr={onClose}
        submitBtnProps={{ colorScheme: 'gray' }}
        size="2xl"
      >
        <Box position="absolute" top="20px" left="360px">
          <Link
            href="https://uniikey.notion.site/85840e1a3a9b4adc91525e2ace1fbd61"
            fontSize="md"
            fontWeight="bold"
            color="blue"
            isExternal
          >
            マニュアルページへ
            <ExternalLinkIcon mx="2px" />
          </Link>
        </Box>
        <UnorderedList maxH="400px" overflowY="auto" pl="1.5rem" pr="1rem">
          <ListItem mb={4}>
            <Text fontWeight="bold" mb={2}>
              メールアドレス直接指定とは
            </Text>
            <Box>
              <Text>
                配信先のメールアドレスを直接記載したテキストファイルをアップロードする事で配信先を設定する機能になります。
              </Text>
            </Box>
          </ListItem>

          <ListItem mb={4}>
            <Text fontWeight="bold" mb={2}>
              アップロード可能なファイルの形式について
            </Text>
            <Text>
              テキストファイル、CSVファイル、TSVファイルのアップロードが可能です。
            </Text>
            <Text mb={2}>メールアドレスごとに改行して保存してください。</Text>
            <Text mb={2}>
              ※CSVファイル、TSVファイルの場合は
              <Text
                as="span"
                fontWeight="bold"
                textDecorationLine="underline"
                textDecorationColor="red"
                textUnderlineOffset="4px"
                textDecorationThickness="2px"
              >
                ヘッダ行を含めないで
              </Text>
              ください。
            </Text>

            <Text>ファイルの仕様は、以下でお願いします。</Text>
            <Text pl={4}>文字コード：UTF-8</Text>
            <Text pl={4} mb={2}>
              ファイルサイズ：32MBまで
            </Text>

            <Text mb={2}>例）テキストファイルの場合</Text>
            <Box
              p={2}
              border="1px dashed #CCC"
              bg="#EEE"
              sx={{
                '.box-line-number li': {
                  pl: '1rem',
                },
                '.box-line-number li::marker': {
                  color: '#aaa',
                },
              }}
            >
              <OrderedList pl={4} className="box-line-number">
                <ListItem>abc@example.com</ListItem>
                <ListItem>def@example.com</ListItem>
                <ListItem>ghi@example.com</ListItem>
              </OrderedList>
            </Box>
          </ListItem>
        </UnorderedList>
      </Dialog>
    );
  },
);
