import { VFC, memo } from 'react';
import { useForm, FormProvider } from 'react-hook-form';
import { Flex } from '@chakra-ui/react';
import { AddGroupFormType } from 'api/tenant/types';
import { useUserInfo } from 'hooks/useUserInfo';
import { AddGroupDialog } from 'components/setting/groups/atoms/AddGroupDialog';

const defaultValues: AddGroupFormType = {
  name: '',
  tenantId: '',
};

export const GroupsHeaderInner: VFC = memo(() => {
  const { isOwner } = useUserInfo();
  const methods = useForm<AddGroupFormType>({
    mode: 'onBlur',
    defaultValues,
  });
  const formId = 'addParentGroup-form';

  if (!isOwner) {
    return <></>;
  }

  return (
    <Flex
      h={12}
      flexBasis={12}
      flexShrink={0}
      w="full"
      alignItems="center"
      justifyContent="start"
    >
      <FormProvider {...methods}>
        <AddGroupDialog formId={formId} />
      </FormProvider>
    </Flex>
  );
});
