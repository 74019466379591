/* eslint-disable @typescript-eslint/no-unused-vars */
import { VFC, memo } from 'react';
import { VStack, Box } from '@chakra-ui/react';
import ErrorBoundary from 'api/ErrorBoundary';
import { useErrorBoundaryReset } from 'hooks/useErrorBoundaryReset';
import { useSetPageTitle } from 'hooks/useSetPageTitle';
import { UserProfileEdit } from 'components/setting/userSetting/templates/UserProfileEdit';
import { UserProfile } from 'components/setting/userSetting/templates/UserProfile';
import { UserProfilePasswordEdit } from 'components/setting/userSetting/templates/UserProfilePasswordEdit';
import { useGetUser } from 'hooks/user/useGetUser';
import { User } from 'api/user/types';

export const UserSetting: VFC = memo(() => {
  const { ebKey, onError } = useErrorBoundaryReset();
  useSetPageTitle('ユーザー設定');
  const selfData = useGetUser();

  return (
    <VStack spacing={4} flex={1} alignItems="flex-start">
      <ErrorBoundary onError={onError} key={ebKey.current}>
        <Box borderWidth="1px" borderRadius="xl" p={4} w="100%">
          <UserProfileEdit selfData={selfData as User} />
          <UserProfile selfData={selfData as User} />
        </Box>
        <Box borderWidth="1px" borderRadius="xl" p={4} w="100%">
          <UserProfilePasswordEdit />
        </Box>
      </ErrorBoundary>
    </VStack>
  );
});
