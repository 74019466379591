import { Box, BoxProps, useStyleConfig } from '@chakra-ui/react';
import { FC, memo } from 'react';

type Props = BoxProps & {
  isActive?: boolean;
};

export const CustomContainerInnerBox: FC<Props> = memo(({isActive = false, ...rest}) => {
  const props = { isActive, ...rest };
  const styles = useStyleConfig('CustomAdminContainerInnerBox', props);

  return <Box sx={styles} {...rest} />;
});
