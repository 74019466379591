import { logger } from 'api/logger';
import { request } from 'api/request';
import { isResponseError, ResponseError } from 'api/types';
import { CreatedCorporationUser, isCreatedCorporationUser } from 'api/user/types';
import produce from 'immer';
import { Options } from 'ky';
import { getValidationError, ValidationError } from 'utils/form';
import { messages } from './messages';


const DEFAULT_ERR_MESSAGE: ValidationError<FormType> = {
  others: [messages.createCorporationUser.customer.error],
};
const DEFAULT_SYSTEM_ERR_MESSAGE: ValidationError<FormType> = {
  others: [messages.createCorporationUser.customer.failed],
};

type CreateUser = {
  verifyCode: string;
  name: string;
  password: string;
};
type FormType = Omit<CreateUser, 'verifyCode'>;

/**
 * 招待ユーザー登録(法人ユーザー)API
 *
 * 状態により以下のレスポンスを返却します
 * 正常時: true
 * エラー: ResponseError
 *
 */
export const createCorporationUser = async (
  formParam: CreateUser,
  options?: Options,
): Promise<CreatedCorporationUser | ResponseError | boolean> => {
  const { verifyCode, name, password } = formParam;
  const path = `user/invite_corporation/${verifyCode}/`;
  const postParam = { name, password };

  const mergedOptions = options
    ? produce(options, (draft) => {
        draft.json = { ...postParam };
      })
    : { json: postParam };

  const response = await request({
    path,
    method: 'post',
    options: mergedOptions,
  });

  if (response.status >= 500) {
    return DEFAULT_ERR_MESSAGE;
  }

  const responseData = response.data;

  // ユーザー招待登録に成功
  if (isCreatedCorporationUser(responseData)) {
    return {
      ...responseData,
      password,
    };
  }

  const { error } = response;
  if (response.hasError) {
    // APIにて返却されたエラー形式
    if (isResponseError(error)) {
      const formError = getValidationError<FormType>({
        formData: formParam,
        response: error,
        replaceKeys: {
          nonFieldErrors: 'password',
        },
      });

      return formError;
    }
  }

  // 予期せぬエラーなので
  // バックエンドに状態を通知
  await logger({
    loglevel:
      response.status === 404 || response.status === 450 ? 'warning' : 'error',
    data: error,
    message: messages.createCorporationUser.system.typeError,
  });

  // 登録できていない旨を通知
  return DEFAULT_SYSTEM_ERR_MESSAGE;
};
