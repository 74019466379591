import { Box, Checkbox, Flex, Text, VStack } from '@chakra-ui/react';
import { CondElement } from 'components/enquete/atoms/CondElement';
import { RichTextElement } from 'components/enquete/atoms/RichTextElement';
import {
  Dispatch, FC, SetStateAction, useEffect, useState
} from 'react';
import { FieldValues, Path, PathValue, UnpackNestedValue, useFormContext } from 'react-hook-form';
import { Toggle } from './Toggle';

type Props<T> = {
  baseName: Path<T>;
  index: number;
  text: string | Array<string | JSX.Element>;
  presetNum: number;
  setIsEdited: Dispatch<SetStateAction<boolean>>;
};

export const UserInfoElement = <T extends FieldValues>({
  baseName,
  index,
  text,
  presetNum,
  setIsEdited,
}: Props<T>): ReturnType<FC> => {
  const { register, setValue, getValues } =
    useFormContext<T>();
  const [toggle, setToggle] = useState(false);

  useEffect(() => {
    setValue(`${baseName}.${index}.formPresetType` as Path<T>, presetNum as UnpackNestedValue<PathValue<T, Path<T>>>);
  }, [setValue, index, presetNum]);

  return (
    <>
      <VStack w="100%" display={toggle ? 'none' : 'block'}>
        <Box w="100%" background="whitesmoke" p={5} textAlign="left">
          <Text>{text}</Text>
        </Box>
        {presetNum === 6 && (
          <Checkbox
            {...register(`${baseName}.${index}.isEmailConfirm` as Path<T>)}
            style={{ margin: '10px auto 0 0' }}
            isDisabled={getValues('uneditableState' as Path<T>)}
          >
            <Text fontSize={14}>
              メールアドレス確認用の入力欄を表示する。
            </Text>
          </Checkbox>
        )}
        <Flex
          w="full"
          alignItems="center"
          paddingTop={4}
          paddingBottom={4}
          borderTop="1px solid #edf2f6"
          borderBottom="1px solid #edf2f6"
        >
          <Text w="18%" textAlign="left" pl={6}>
            補足説明など
          </Text>
          <Box w="79.5%">
            <RichTextElement<T>
              baseName={baseName}
              placeholder=""
              index={index}
              position="descriptionBottom"
              value={
                getValues(`${baseName}.${index}.descriptionBottom` as Path<T>) || ''
              }
              setIsEdited={setIsEdited}
            />
          </Box>
        </Flex>
        <CondElement<T> baseName={baseName} index={index} />
      </VStack>
      <Toggle toggle={toggle} setToggle={setToggle} />
    </>
  );
};
