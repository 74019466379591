import { VFC, memo, useCallback } from 'react';
import { NavLink, useSearchParams } from 'react-router-dom';
import { useForm, FormProvider } from 'react-hook-form';

import {
  Box,
  Button,
  ButtonGroup,
  Text,
  useDisclosure,
} from '@chakra-ui/react';
import { Skin } from 'api/enquete/types';
import { useSetPageTitle } from 'hooks/useSetPageTitle';
import { useUserTenantId } from 'hooks/user/useUserTenantId';
import { useCreateEnqueteSkin } from 'hooks/enquete/useCreateEnqueteSkin';
import { useEditEnqueteSkin } from 'hooks/enquete/useEditEnqueteSkin';
import { InputTextForm } from 'components/common/atoms';
import { EnqueteSkinCreateForm } from 'components/enquete/template/EnqueteSkinCreateForm';
import { EnqueteSkinEditForm } from 'components/enquete/template/EnqueteSkinEditForm';
import { EnqueteSkinPreviewModal } from 'components/enquete/molecules/EnqueteSkinPreviewModal';
import { EnqueteSkinDefaultButton } from 'components/enquete/molecules/EnqueteSkinDefaultButton';

export const SkinDefaultValues: Skin = {
  id: '',
  tenant: '',
  name: '',
  titleBgColor: '#f5f9fe',
  titleTextColor: '#3b8ce7',
  ruledLineColor: '#3b8ce7',
  submitButtonBgColor: '#354f9b',
  submitButtonTextColor: '#FFFFFF',
  labelBgColor: '#f00000',
  labelTextColor: '#FFFFFF',
  selectButtonBgColor: '#3b8ce7',
  selectButtonTextColor: '#FFFFFF',
  radioButtonSelectedColor: '#3b8ce7',
  checkboxSelectedColor: '#3b8ce7',
  finishedButtonBgColor: '#f00000',
  finishedButtonTextColor: '#FFFFFF',
  logoUrl: '',
  logoPosition: 'left',
  errorTextColor: '#f00000',
};

export const EnqueteSkin: VFC = memo(() => {
  useSetPageTitle('デザインスキン作成・編集画面');
  const { isOpen, onOpen, onClose } = useDisclosure();
  const tenantId = useUserTenantId();
  const [searchParams] = useSearchParams();
  const skinId = searchParams.get('skinId') || '';

  const methods = useForm<Skin>({
    mode: 'onBlur',
    // エラーのある入力が再度バリデーションされるタイミングを変更(default: onChange)
    reValidateMode: 'onBlur',
    defaultValues: SkinDefaultValues,
  });
  const { getValues, setError } = methods;
  const formId = 'enquete-skin-form';
  const { onSubmit: onCreateSubmit, isLoading: isCreateLoading } =
    useCreateEnqueteSkin({ setError });
  const { onSubmit: onEditSubmit, isLoading: isEditLoading } =
    useEditEnqueteSkin({ setError });

  const onSave = useCallback(() => {
    // TODO: トンマナを保存するAPIを実行（新規作成、編集に分けて実行する）
    if (skinId === '') {
      onCreateSubmit({
        tenantId,
        skinForm: {
          ...getValues(),
          isPreview: false,
        },
      });
    } else {
      onEditSubmit({
        tenantId,
        skinForm: {
          ...getValues(),
          isPreview: false,
        },
      });
    }
  }, [getValues, tenantId, skinId, onCreateSubmit, onEditSubmit]);

  const viewEnqueteSkinForm = () => {
    if (skinId === '') {
      return <EnqueteSkinCreateForm />;
    }

    return <EnqueteSkinEditForm skinId={skinId} />;
  };

  return (
    <Box w="100%" px={4}>
      <FormProvider {...methods}>
        <form id={formId}>
          <Box mb={4} display="flex" justifyContent="space-between">
            <Text fontWeight="bold" mt={2}>
              <NavLink to="/enquete" style={{ color: '#aaa' }}>
                フォーム一覧
              </NavLink>
              &nbsp;/&nbsp;
              <NavLink to="/enquete/skin" style={{ color: '#aaa' }}>
                デザインスキン一覧
              </NavLink>
              &nbsp;/&nbsp;
              <Text as="span" textDecoration="underline">
                デザインスキン作成・編集画面
              </Text>
            </Text>
            <ButtonGroup
              variant="outline"
              display="block"
              ml="auto"
              spacing="2"
            >
              <Button
                bgColor="white"
                borderWidth="1px"
                borderColor="gray.400"
                onClick={onOpen}
              >
                プレビュー
              </Button>
              <Button
                type="button"
                variant="primary"
                onClick={onSave}
                isLoading={isCreateLoading || isEditLoading}
                isDisabled={isCreateLoading || isEditLoading}
              >
                保存
              </Button>
            </ButtonGroup>
          </Box>
          <Box
            w="100%"
            mb={2}
            display="flex"
            justifyContent="space-between"
            alignItems="center"
            gridGap={2}
          >
            <Box
              width="calc(100% - 200px)"
              minW="150px"
              display="flex"
              alignItems="center"
              gridGap={1}
            >
              <InputTextForm<Skin>
                name="name"
                value={getValues('name')}
                placeholder="デザインスキン名"
              />
            </Box>
            {/* デフォルト設定ボタン */}
            <EnqueteSkinDefaultButton />
          </Box>
          {viewEnqueteSkinForm()}
        </form>
      </FormProvider>
      {/* プレビュー */}
      <EnqueteSkinPreviewModal
        previewValues={{ ...getValues() } || SkinDefaultValues}
        isOpen={isOpen}
        onClose={onClose}
      />
    </Box>
  );
});
