import { VFC } from 'react';
import { Box } from '@chakra-ui/react';
import { WarningIcon } from '@chakra-ui/icons';

type ErrorTextMsgProps = {
  msg?: string | JSX.Element;
  fontSize?: string;
  iconColor?: string;
  textColor?: string;
};

export const ErrorTextMsg: VFC<ErrorTextMsgProps> = ({
  msg = '',
  fontSize = '',
  iconColor = '',
  textColor = 'crimson',
}) =>
  msg ? (
    <Box
      color={textColor}
      fontSize={fontSize === '' ? 'xs' : fontSize}
      mt={2}
      pl={2}
      display="flex"
      alignItems="baseline"
      whiteSpace="pre-wrap"
    >
      <WarningIcon mr={2} color={iconColor} />
      {msg}
    </Box>
  ) : (
    <></>
  );
