import { SendingContentForm } from 'components/transactionMail/SendingContentForm';
import { SendingContentsList } from 'components/transactionMail/SendingContentsList/index';
import { TransactionMailSetting } from 'components/transactionMail/TransactionMailSetting';
import { AUTH_CODE_TYPE_UNIIKEY, PERMISSION_DIRECTOR } from 'define';
import { useUserInfo } from 'hooks/useUserInfo';
import { Navigate, RouteObject } from 'react-router-dom';

/**
 * 編集権限がない場合、リダイレクト
 */
const HasPermissionRoute = ({ children }: { children: JSX.Element }) => {
  const { userTenant } = useUserInfo();

  // 権限がない場合
  if (
    (userTenant?.permission && userTenant?.permission < PERMISSION_DIRECTOR) ||
    userTenant?.userPoolAuthenticationType !== AUTH_CODE_TYPE_UNIIKEY
  ) {
    return <Navigate to="/" replace />;
  }

  return children;
};

export const TransactionMailRoutes: RouteObject[] = [
  {
    path: '',
    element: (
      <HasPermissionRoute>
        <TransactionMailSetting />
      </HasPermissionRoute>
    ),
  },
  {
    path: 'list',
    element: (
      <HasPermissionRoute>
        <SendingContentsList />
      </HasPermissionRoute>
    ),
  },
  {
    path: 'form/',
    element: (
      <HasPermissionRoute>
        <SendingContentForm />
      </HasPermissionRoute>
    ),
    children: [
      {
        path: ':transactionMailId',
        element: <SendingContentForm />,
      },
    ],
  },
];
