import { UserPool } from 'admin/types/userPool';
import { CodeGroupType } from 'admin/types/userPool/master';
import { Page } from 'api/common/types';

export const queryKeyNames = {
  getMasterSettings: 'getMasterSettings',
  getAllMasterSettings: 'getAllMasterSettings',
  getMasterSetting: 'getMasterSetting',
};

export const queryKeys = {
  getMasterSettings: (
    id: UserPool['id'],
    page: Page['currentPage'],
    perPage: Page['perPage'],
  ) => [queryKeyNames.getMasterSettings, id, page, perPage],
  getAllMasterSettings: (id: UserPool['id'], isEnabled?: boolean) => [
    queryKeyNames.getAllMasterSettings,
    id,
    isEnabled,
  ],
  getMasterSetting: (id: UserPool['id'], masterId: CodeGroupType['id']) => [
    queryKeyNames.getMasterSetting,
    id,
    masterId,
  ],
} as const;
