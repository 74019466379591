/* eslint-disable @typescript-eslint/no-unsafe-member-access */
import { Box, Checkbox, CheckboxGroup, Icon } from '@chakra-ui/react';
import styled from '@emotion/styled';
import { SearchParam, useSearch } from 'components/opt/hooks/useSearch';
import { Colors } from 'components/paywall/config';
import { StyledFlex } from 'components/paywall/styled/global';
import { FC, memo, useEffect, useState } from 'react';
import { MdSearch } from 'react-icons/md';

const StyledContainer = styled(Box)({});

const StyledCheckboxGroup = styled(CheckboxGroup)({
  display: 'flex',
  alignItems: 'center',
  color: '#444',
  fontSize: 12,
  gap: 5,
});

type Props = {
  radioName: string;
};
export const FilterStatus: FC<Props> = memo(({ radioName }) => {
  const { register, setValue, handleFilterRadioSearch } = useSearch();
  const { searchParam } = useSearch();
  const [, setSelected] = useState('item');
  useEffect(() => {
    const typeKey = radioName as keyof SearchParam;
    setSelected(searchParam[typeKey] as string);
  }, [searchParam, setValue, radioName]);

  return (
    <StyledContainer>
      <StyledFlex style={{ gap: 5 }}>
        <StyledCheckboxGroup>
          <Box>
            <Box>
              <Checkbox {...register(radioName)} value="アクティブ">
                <span style={{ fontSize: 14 }}>アクティブ</span>
              </Checkbox>
            </Box>
            <Box>
              <Checkbox {...register(radioName)} value="終了済み">
                <span style={{ fontSize: 14 }}>終了済み</span>
              </Checkbox>
            </Box>
          </Box>
          <Box
            onClick={() => handleFilterRadioSearch(radioName)}
            w="24px"
            minW="24px"
            h="24px"
            bgColor={Colors.GRAY_60}
            borderRadius="50%"
            textAlign="center"
            cursor="pointer"
          >
            <Icon
              as={MdSearch}
              boxSize={5}
              color={Colors.GRAY_03}
              verticalAlign="bottom"
            />
          </Box>
        </StyledCheckboxGroup>
      </StyledFlex>
    </StyledContainer>
  );
});
