import {
  VFC,
  memo,
  useState,
  ChangeEvent,
  KeyboardEvent,
  useEffect,
  useCallback,
} from 'react';
import { FieldErrors, useFormContext } from 'react-hook-form';
import {
  Stack,
  Box,
  Text,
  Textarea,
  Accordion,
  AccordionItem,
  AccordionButton,
  AccordionIcon,
  AccordionPanel,
  FormControl,
  FormLabel,
  Switch,
  NumberInput,
  NumberInputField,
  Checkbox,
  Select,
  Tooltip,
} from '@chakra-ui/react';
import { FiHelpCircle } from 'react-icons/fi';
import { CONTENTS_SHELF_SEARCH_LIMIT_MAX } from 'define';
import QueryBuilder, { Field } from 'react-querybuilder';
import { chakraControlElements } from '@react-querybuilder/chakra';
import 'react-querybuilder/dist/query-builder.css';
import '../../segment/molecules/query-builder.css';
import { useCopy } from 'hooks/useCopy';
import { useTableData } from 'hooks/contentsdb/useTableData';
import { useCreateSearchFilterJson } from 'hooks/contentsdb/useCreateSearchFilterJson';
import {
  CreateSearchFilter,
  CreateSearchFilterSort,
  SchemaRowType,
  SearchFilter,
} from 'api/contents/types';
import { Copy, ErrorTextMsg } from 'components/common/atoms';
import { CustomRuleGroupType } from 'api/segment/types';
import { toErrMsgList } from 'utils/form';

const translations = {
  addRule: {
    label: 'ルール +',
    title: 'ルールの追加',
  },
  addGroup: {
    label: 'グループ +',
    title: 'グループ追加',
  },
  removeRule: {
    label: 'x',
    title: 'ルール削除',
  },
  removeGroup: {
    label: 'x',
    title: 'グループ削除',
  },
};

const combinators = [
  { name: 'and', label: 'かつ' },
  { name: 'or', label: 'もしくは' },
];

type SortRowType = {
  column: string;
  displayName: string;
  sort: string;
  isChecked: boolean;
};
type TmpFilterJson = {
  page: number;
  limit: number;
  sort?: CreateSearchFilterSort[];
  select?: string[];
  query?: CustomRuleGroupType;
};
/**
 * 検索用JSONデータ作成フォーム
 */
type TableSearchConditionFormProps = {
  tenantId: string;
  tableId: string;
  formSubmit: (data: SearchFilter) => void;
};
export const TableSearchConditionForm: VFC<TableSearchConditionFormProps> =
  memo(({ tenantId, tableId, formSubmit }) => {
    const contentsTable = useTableData({ tenantId, tblId: tableId });
    const { copy } = useCopy();
    const [fieldColumns, setFieldColumns] = useState<SchemaRowType[]>([]);
    const [sortColumns, setSortColumns] = useState<SortRowType[]>([]);
    const [tmpFilterJson, setTmpFilterJson] = useState<TmpFilterJson>({
      page: 1,
      limit: 100,
    });
    const [filterJson, setFilterJson] = useState<SearchFilter>({
      page: 1,
      limit: 100,
    });
    const [textAreaData, setTextAreaData] = useState('');
    const [settingSortFlg, setSettingSortFlg] = useState(false);
    const [settingSelectFlg, setSettingSelectFlg] = useState(false);
    const [settingFilterFlg, setSettingFilterFlg] = useState(false);
    const [settingPage, setSettingPage] = useState<number>(1);
    const [settingLimit, setSettingLimit] = useState<number>(100);
    const [settingSort, setSettingSort] = useState<CreateSearchFilterSort[]>(
      [],
    );
    const [settingSelect, setSettingSelect] = useState<string[]>([]);
    const [queryFields, setQueryFields] = useState<Field[]>([]);
    const [settingQuery, setSettingQuery] = useState<CustomRuleGroupType>({
      combinator: 'and',
      rules: [],
    });
    const [errList, setErrList] = useState<FieldErrors>();
    const {
      setValue,
      getValues,
      watch,
      formState: { errors },
    } = useFormContext<SearchFilter>();
    const { onSubmit } = useCreateSearchFilterJson({
      setFilterJson,
      setErrList,
    });

    useEffect(() => {
      setErrList(undefined);
      let tmpJson: CreateSearchFilter = {
        page: settingPage,
        limit: tmpFilterJson.limit,
      };
      if (settingSortFlg) {
        tmpJson = {
          ...tmpJson,
          sort: tmpFilterJson.sort,
        };
      }
      if (settingSelectFlg) {
        tmpJson = {
          ...tmpJson,
          select: tmpFilterJson.select,
        };
      }
      if (settingFilterFlg && tmpFilterJson.query) {
        tmpJson = {
          ...tmpJson,
          query: tmpFilterJson.query,
        };
      }
      onSubmit(tmpJson);
    }, [
      settingPage,
      tmpFilterJson,
      settingSortFlg,
      settingSelectFlg,
      settingFilterFlg,
      onSubmit,
    ]);

    useEffect(() => {
      if (Object.keys(filterJson).length > 0) {
        setTextAreaData(String(JSON.stringify(filterJson, null, 4)));
        setValue('page', filterJson.page);
        setValue('limit', filterJson.limit);
        setValue('sort', filterJson.sort);
        setValue('select', filterJson.select);
        setValue('filter', filterJson.filter);
      } else {
        setTextAreaData('');
      }
    }, [filterJson, setValue]);

    // ページ指定, 取得件数 change event
    const onInputChange = useCallback(
      (value: string, key: string) => {
        const val = Number(value);
        if (key === 'setting-page') {
          setSettingPage(val);
        } else if (key === 'setting-limit') {
          setSettingLimit(val);
        }

        setTmpFilterJson({
          page: key === 'setting-page' ? val : settingPage,
          limit: key === 'setting-limit' ? val : settingLimit,
          sort: settingSort,
          select: settingSelect,
          query: settingQuery,
        });
      },
      [settingPage, settingLimit, settingSort, settingSelect, settingQuery],
    );

    // ページネーションでページ変更された場合
    useEffect(() => {
      const pageId = watch((value, { name }) => {
        if (name === 'page') {
          const tmpPageId = value.page || 0;
          if (filterJson.page !== tmpPageId) {
            onInputChange(String(tmpPageId), 'setting-page');
            formSubmit(getValues());
          }
        }
      });

      return () => pageId.unsubscribe();
    }, [watch, onInputChange, filterJson, formSubmit, getValues]);

    // ページ指定, 取得件数 keypressイベント
    const onInputKeypress = (event: KeyboardEvent<HTMLInputElement>) => {
      if (event.key === 'Enter') {
        event.preventDefault();
      }
    };

    // ソート指定 check event
    const onCheckSort = useCallback(
      (event: ChangeEvent<HTMLInputElement>, col: string) => {
        if (!settingSortFlg) return;
        const tmpSortColumns = sortColumns;
        // チェック情報が変更された配列のインデックスを取得
        const fIdx = sortColumns.findIndex(({ column }) => column === col);
        // 対象のチェック情報を変更
        const updateColumn: SortRowType = {
          ...sortColumns[fIdx],
          isChecked: event.target.checked,
        };
        tmpSortColumns[fIdx] = { ...updateColumn };
        // state情報を更新
        setSortColumns(tmpSortColumns);
        // filterに必要な情報の形式に変換
        const tmpSortList: CreateSearchFilterSort[] = tmpSortColumns
          .filter((x) => x.isChecked)
          .map((item) => ({ field: item.column, order: item.sort }));
        // state 更新
        setSettingSort(tmpSortList);

        setTmpFilterJson({
          page: settingPage,
          limit: settingLimit,
          sort: tmpSortList,
          select: settingSelect,
          query: settingQuery,
        });
      },
      [
        sortColumns,
        settingSortFlg,
        settingPage,
        settingLimit,
        settingSelect,
        settingQuery,
      ],
    );

    // ソート指定 change event
    const onChangeSort = useCallback(
      (col: string, sort: string) => {
        if (!settingSortFlg) return;
        const tmpSortColumns = sortColumns;
        // ソート情報が変更された配列のインデックスを取得
        const fIdx = sortColumns.findIndex(({ column }) => column === col);
        // 対象のソート情報を変更
        const updateColumn: SortRowType = {
          ...sortColumns[fIdx],
          sort,
        };
        tmpSortColumns[fIdx] = { ...updateColumn };
        // state情報を更新
        setSortColumns(tmpSortColumns);
        // filterに必要な情報の形式に変換
        const tmpSortList: CreateSearchFilterSort[] = tmpSortColumns
          .filter((x) => x.isChecked)
          .map((item) => ({ field: item.column, order: item.sort }));
        // state 更新
        setSettingSort(tmpSortList);

        setTmpFilterJson({
          page: settingPage,
          limit: settingLimit,
          sort: tmpSortList,
          select: settingSelect,
          query: settingQuery,
        });
      },
      [
        sortColumns,
        settingSortFlg,
        settingPage,
        settingLimit,
        settingSelect,
        settingQuery,
      ],
    );

    // 取得フィールド指定 change event
    const onCheckFields = useCallback(
      (col: string) => {
        if (!settingSelectFlg) return;
        let fieldList = settingSelect;
        if (fieldList.includes(col)) {
          fieldList = fieldList.filter((x) => x !== col);
        } else {
          fieldList.push(col);
        }
        setSettingSelect(fieldList);

        setTmpFilterJson({
          page: settingPage,
          limit: settingLimit,
          sort: settingSort,
          select: fieldList,
          query: settingQuery,
        });
      },
      [
        settingSelectFlg,
        settingPage,
        settingLimit,
        settingSort,
        settingSelect,
        settingQuery,
      ],
    );

    // クエリビルダー change event
    const onFilterQueryChange = useCallback(
      (val: CustomRuleGroupType) => {
        if (!settingFilterFlg) return;
        setSettingQuery(val);

        setTmpFilterJson({
          page: settingPage,
          limit: settingLimit,
          sort: settingSort,
          select: settingSelect,
          query: val,
        });
      },
      [settingFilterFlg, settingPage, settingLimit, settingSort, settingSelect],
    );

    // 検索条件の有効無効切り替え
    const onChangeSwitch = useCallback(
      (event: ChangeEvent<HTMLInputElement>, type: string) => {
        const settingFlgs = {
          sort: settingSortFlg,
          select: settingSelectFlg,
          filter: settingFilterFlg,
        };
        const isChecked = event.target.checked;
        if (type === 'setting-sort') {
          setSettingSortFlg(isChecked);
          settingFlgs.sort = isChecked;
        } else if (type === 'setting-fields') {
          setSettingSelectFlg(isChecked);
          settingFlgs.select = isChecked;
        } else if (type === 'setting-filter') {
          setSettingFilterFlg(isChecked);
          settingFlgs.filter = isChecked;
        }
      },
      [settingSortFlg, settingSelectFlg, settingFilterFlg],
    );

    useEffect(() => {
      if (contentsTable && contentsTable.tblSchema) {
        setFieldColumns(contentsTable.tblSchema);
        const sortList: SortRowType[] = [];
        const fieldList: Field[] = [];
        contentsTable.tblSchema.forEach((item) => {
          // ソート指定有効なカラムのみ抽出
          if (item.isSort) {
            sortList.push({
              column: item.column,
              displayName: item.displayName || item.column,
              sort: 'asc',
              isChecked: false,
            });
          }
          // クエリビルダーField設定
          fieldList.push({
            ...(item.rule as unknown as Field),
          });
        });
        setSortColumns(sortList);
        setQueryFields(fieldList);
      }
    }, [contentsTable]);

    const viewErrorMsg = (key: string) => {
      const errorMsgs = {
        ...errors,
        ...errList,
      };
      if (errorMsgs) {
        return toErrMsgList(errorMsgs, key).map((err, idx) => (
          <ErrorTextMsg key={`error-${key}-${String(idx)}`} msg={err} />
        ));
      }

      return <></>;
    };

    return (
      <Box>
        <Accordion allowMultiple>
          <AccordionItem>
            <h2>
              <AccordionButton>
                <Box flex="1" textAlign="left">
                  検索条件
                </Box>
                <AccordionIcon />
              </AccordionButton>
            </h2>
            <AccordionPanel pb={4}>
              <Stack spacing={6}>
                {/* 取得件数指定 */}
                <Box>
                  <Box
                    mb="0"
                    ml="4"
                    fontSize={16}
                    fontWeight="bold"
                    color="#888"
                  >
                    取得件数指定
                  </Box>
                  <Box ml="2.5rem">
                    <NumberInput
                      defaultValue={15}
                      min={1}
                      max={CONTENTS_SHELF_SEARCH_LIMIT_MAX}
                      w={150}
                      value={settingLimit}
                      onChange={(val) => onInputChange(val, 'setting-limit')}
                      onKeyPress={onInputKeypress}
                    >
                      <NumberInputField />
                    </NumberInput>
                    {viewErrorMsg('limit')}
                  </Box>
                </Box>
                {/* ソート指定 */}
                <Box>
                  <FormControl display="flex" alignItems="center" mb={2}>
                    <Switch
                      size="sm"
                      id="setting-sort"
                      onChange={(e) => onChangeSwitch(e, 'setting-sort')}
                    />
                    <FormLabel
                      htmlFor="setting-sort"
                      mb="0"
                      ml="4"
                      fontSize={16}
                      fontWeight="bold"
                      color="#888"
                    >
                      ソート指定
                      <Text as="span" color="crimson" fontSize="12px" ml={1}>
                        ※「テキスト型」はソート不可のため指定できません。
                      </Text>
                    </FormLabel>
                  </FormControl>
                  <Box
                    display={settingSortFlg ? 'flex' : 'none'}
                    flexWrap="wrap"
                    gridGap="1rem"
                    ml="2.5rem"
                  >
                    {sortColumns.map((item, idx) => (
                      <Box
                        key={`setting-sort-column-row-${String(idx)}`}
                        display="flex"
                        alignItems="center"
                        gridGap="1rem"
                      >
                        <Checkbox onChange={(e) => onCheckSort(e, item.column)}>
                          {item.displayName}
                        </Checkbox>
                        <Select
                          w={100}
                          value={item.sort}
                          onChange={(e) =>
                            onChangeSort(item.column, e.target.value)
                          }
                        >
                          <option value="asc">昇順</option>
                          <option value="desc">降順</option>
                        </Select>
                      </Box>
                    ))}
                  </Box>
                </Box>
                {/* 取得フィールド指定 */}
                <Box>
                  <FormControl display="flex" alignItems="center" mb={2}>
                    <Switch
                      size="sm"
                      id="setting-fields"
                      onChange={(e) => onChangeSwitch(e, 'setting-fields')}
                    />
                    <FormLabel
                      htmlFor="setting-fields"
                      mb="0"
                      ml="4"
                      fontSize={16}
                      fontWeight="bold"
                      color="#888"
                    >
                      取得フィールド指定
                    </FormLabel>
                  </FormControl>
                  <Box
                    display={settingSelectFlg ? 'flex' : 'none'}
                    flexWrap="wrap"
                    gridGap="1rem"
                    ml="2.5rem"
                  >
                    {fieldColumns.map((schema, idx) => (
                      <Box key={`setting-fields-column-row-${String(idx)}`}>
                        <Checkbox onChange={() => onCheckFields(schema.column)}>
                          {schema.displayName || schema.column}
                        </Checkbox>
                      </Box>
                    ))}
                  </Box>
                </Box>
                {/* 条件設定 */}
                <Box>
                  <FormControl display="flex" alignItems="center" mb={2}>
                    <Switch
                      size="sm"
                      id="setting-filter"
                      onChange={(e) => onChangeSwitch(e, 'setting-filter')}
                    />
                    <FormLabel
                      htmlFor="setting-filter"
                      mb="0"
                      ml="4"
                      fontSize={16}
                      fontWeight="bold"
                      color="#888"
                    >
                      条件設定
                    </FormLabel>
                  </FormControl>

                  {settingFilterFlg && (
                    <Box mb={2} maxH="150px" overflow="auto" p="4px 0">
                      <QueryBuilder
                        fields={queryFields}
                        query={settingQuery}
                        onQueryChange={(q) => onFilterQueryChange(q)}
                        translations={translations}
                        combinators={combinators}
                        controlElements={chakraControlElements}
                      />
                    </Box>
                  )}
                </Box>
              </Stack>
            </AccordionPanel>
          </AccordionItem>

          <AccordionItem>
            <h2>
              <AccordionButton>
                <Box flex="1" textAlign="left" position="relative">
                  設定された条件に基づくJSON形式の条件文
                  <Box
                    position="absolute"
                    top="50%"
                    left="315px"
                    transform="translateY(-50%)"
                  >
                    <Tooltip label="JSON式はAPIを通じてデータ取得(検索)する際に利用します">
                      <Box flex="1" textAlign="left">
                        <FiHelpCircle />
                      </Box>
                    </Tooltip>
                  </Box>
                </Box>
                <AccordionIcon />
              </AccordionButton>
            </h2>
            <AccordionPanel pb={4}>
              <Box position="relative">
                <Textarea
                  value={textAreaData}
                  placeholder="設定された条件に基づくJSON形式の条件文が表示されます"
                  borderColor="gray.200"
                  size="sm"
                  resize="none"
                  borderRadius="md"
                  rows={5}
                  readOnly
                />
                <Box position="absolute" top={4} right={4}>
                  <Copy
                    label="JSONデータをコピー"
                    onCopy={() => {
                      copy(textAreaData);
                    }}
                  />
                </Box>
              </Box>
            </AccordionPanel>
          </AccordionItem>
        </Accordion>
      </Box>
    );
  });
