import { memo } from 'react';
import { css, Td } from '@chakra-ui/react';
import { GridCellProps, DataType } from './types';

const cssTd = css({
  padding: '2px !important',
  borderLeft: 'solid 1px #778ca3 !important',
});

const GridCellComponent = <T extends DataType>(
  props: GridCellProps<T>,
): JSX.Element => {
  const {
    className,
    onMouseDown,
    onMouseOver,
    onContextMenu,
    cell,
    row,
    col,
    onDoubleClick,
    style,
    attributesRenderer,
    children,
  } = props;

  const attributes = attributesRenderer
    ? attributesRenderer(cell, row, col)
    : {};

  return (
    <Td
      key={`gridcell-${row}-${col}`}
      css={cssTd}
      className={className}
      onMouseDown={onMouseDown}
      onMouseOver={onMouseOver}
      onDoubleClick={onDoubleClick}
      onContextMenu={onContextMenu}
      style={style}
      {...attributes}
      role="gridcell"
    >
      {children}
    </Td>
  );
};

export const GridCell = memo(GridCellComponent) as typeof GridCellComponent;
