import produce from 'immer';
import { Options } from 'ky';
import { request } from 'api/request';
import { logger } from 'api/logger';
import { CustomError } from 'api/error/CustomError';
import { CONTENTSDB_FILE_UPLOAD_TYPE } from 'define';
import { UploadHistory, isUploadHistory } from './types';
import { messages } from './messages';

/**
 * コンテンツシェルフファイル情報登録API
 *
 * 大きいファイルだとリクエスト送信に
 * 時間がかかり、アップロード履歴に
 * なかなか表示されないので、先に情報だけ
 * レコードを作って履歴を表示できる状態にしてから
 * ファイル本体をアップロードする流れとなります
 *
 */
export const createUploadData = async ({
  fileName,
  uploadType,
  tenantId,
  tableId,
  options,
}: {
  fileName: string;
  uploadType: typeof CONTENTSDB_FILE_UPLOAD_TYPE[number];
  tenantId: string;
  tableId: string;
  options?: Options;
}): Promise<UploadHistory> => {
  const formData = {
    file: fileName,
    upload_type: String(uploadType),
  };
  const mergedOptions = options
    ? produce(options, (draft) => {
        draft.json = formData;
      })
    : { json: formData };

  const response = await request({
    path: `contentsdb/${tenantId}/${tableId}/upload/`,
    method: 'post',
    options: mergedOptions,
  });

  if (response.hasError) {
    throw new CustomError(messages.createUploadData.customer.error);
  }

  const { data } = response;

  if (!isUploadHistory(data)) {
    // 登録は成功したものの
    // レスポンスの型ガードでエラー
    await logger({
      loglevel:
        response.status === 404 || response.status === 450
          ? 'warning'
          : 'error',
      data,
      message: messages.createUploadData.system.typeError,
    });
    throw new CustomError(messages.createUploadData.customer.typeError);
  }

  return data;
};
