import { Options } from 'ky';
import { request } from 'api/request';
import { logger } from 'api/logger';
import { CustomError } from 'api/error/CustomError';
import {
  MailMagazine,
  isMailMagazine,
} from 'components/setting/mailMagazine/typed';
import { messages } from './messages';

// メールマガジン詳細取得API
export const getMailMagazineId = async (
  id: string,
  tenantId: string,
  options?: Options,
): Promise<MailMagazine> => {
  const target = 'mail';
  const path = `${target}/${tenantId}/magazine/${id}`;

  const response = await request({
    path,
    method: 'get',
    options,
  });

  const responseData = (await response.data) as unknown[];

  if (response.hasError) {
    if (response.status === 404) {
      throw new CustomError(
        messages.getMailMagazineId.customer.notFound,
        response.status,
      );
    } else {
      throw new CustomError(
        messages.getMailMagazineId.customer.error,
        response.status,
      );
    }
  }

  if (!isMailMagazine(responseData)) {
    await logger({
      loglevel:
        response.status === 404 || response.status === 450
          ? 'warning'
          : 'error',
      data: responseData,
      message: messages.getMailMagazineId.system.typeError,
    });

    throw new CustomError(
      messages.getMailMagazineId.customer.failed,
      response.status,
    );
  }

  return responseData;
};
