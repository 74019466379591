import { patchMailSettingPersonalActivate } from 'api/paywall/patchMailSettingPersonalActivate';
import { ResponseError } from 'api/types';
import { MailSettingPersonalFormDefaultValue } from 'components/paywall/pages/MailSetting/initial';
import { isMailSettingPersonal, MailSettingPersonal, MailSettingPersonalForm, MailSettingStatuses } from 'components/paywall/pages/MailSetting/typed';
import { usePaywallUtil } from 'hooks/paywall/usePaywallUtil';
import { useCustomToast } from 'hooks/useCustomToast';
import { useUserTenantId } from 'hooks/user/useUserTenantId';
import { UseMutateFunction, useMutation } from 'react-query';
import { useNavigate } from 'react-router-dom';
import { isGetValidationError } from 'utils/form';

type UnPromisify<T> = T extends Promise<infer U> ? U : T;

/**
 * ペイウォールメール設定更新 hooks
 * @param {UseFormSetError<MailSettingPersonalForm>} setError
 * @param {Function} reset
 * @returns {Object} { onSubmit, isLoading }
 */
export const useUpdateMailSettingPersonalActivate = ({
  type
}: {
  type: keyof MailSettingStatuses;
}): {
  onSubmit: UseMutateFunction<
    UnPromisify<ReturnType<typeof patchMailSettingPersonalActivate>>,
    unknown,
    MailSettingPersonalForm,
    unknown
  >;
  isLoading: boolean;
} => {
  const tenantId = useUserTenantId();
  const toast = useCustomToast();
  const navigate = useNavigate();
  const { paywallMailSettingCommonRefetchQueries, paywallMailSettingPersonalRefetchQueries } = usePaywallUtil();

  const {
    mutate: onSubmit,
    isLoading,
  } = useMutation(
    (formParam: MailSettingPersonalForm) =>
      patchMailSettingPersonalActivate(tenantId, formParam, type),
    {
      onSuccess: (result: MailSettingPersonal | boolean | ResponseError) => {
        paywallMailSettingCommonRefetchQueries();
        paywallMailSettingPersonalRefetchQueries(type);
        if (isMailSettingPersonal(result)) {
          toast({
            status: 'success',
            position: 'bottom',
            duration: 4000,
            isClosable: true,
            title: 'ステータスを変更しました',
          });
          navigate('/paywall/mail');
        }

        if (
          isGetValidationError<MailSettingPersonalForm>(
            result,
            Object.keys(MailSettingPersonalFormDefaultValue),
          )
        ) {
          const errorMsg = Object.entries(result).map(([_, value]) => value);
          toast({
            status: 'error',
            position: 'bottom',
            duration: 4000,
            isClosable: true,
            title: errorMsg[0],
          });
        }
      },
    },
  );

  return { onSubmit, isLoading };
};
