import { logger } from 'api/logger';
import { request } from 'api/request';
import { isResponseError, ResponseError } from 'api/types';
import {
  isRegistInviteUser,
  RegistInviteUser,
  RegistInviteUserForm,
} from 'api/user/types';
import produce from 'immer';
import { Options } from 'ky';
import { getValidationError, ValidationError } from 'utils/form';
import { messages } from './messages';

const DEFAULT_ERR_MESSAGE: ValidationError<RegistInviteUserForm> = {
  others: [messages.postInvite.customer.error],
};
const DEFAULT_SYSTEM_ERR_MESSAGE: ValidationError<RegistInviteUserForm> = {
  others: [messages.postInvite.customer.failed],
};

/**
 * テナントにユーザーを招待API
 *
 * 状態により以下のレスポンスを返却します
 * 正常時: true
 * エラー: ResponseError
 *
 */
export const postInvite = async (
  formParam: RegistInviteUserForm,
  options?: Options,
): Promise<RegistInviteUser | ResponseError | boolean> => {
  const { emailTo, tenant, permission, groups, corporation } = formParam;
  const path = `user/invite/`;
  const postParam = {
    email_to: emailTo,
    tenant,
    permission,
    groups,
    corporation,
  };

  const mergedOptions = options
    ? produce(options, (draft) => {
        draft.json = { ...postParam };
      })
    : { json: postParam };

  const response = await request({
    path,
    method: 'post',
    options: mergedOptions,
  });

  if (response.status >= 500) {
    return DEFAULT_ERR_MESSAGE;
  }

  const responseData = response.data;

  // ユーザー招待登録に成功
  if (isRegistInviteUser(responseData)) {
    return responseData;
  }

  const { error } = response;
  if (response.hasError) {
    // APIにて返却されたエラー形式
    if (isResponseError(error)) {
      // TODO: 複数inputに対するエラー表示処理
      let customError = error;
      if ('groups' in customError) {
        customError = { ...error, others: Object.values(customError.groups) };
      }
      const formError = getValidationError<RegistInviteUserForm>({
        formData: formParam,
        response: customError,
      });

      return formError;
    }
  }

  // 予期せぬエラーなので
  // バックエンドに状態を通知
  await logger({
    loglevel:
      response.status === 404 || response.status === 450 ? 'warning' : 'error',
    data: error,
    message: messages.postInvite.system.typeError,
  });

  // 登録できていない旨を通知
  return DEFAULT_SYSTEM_ERR_MESSAGE;
};
