import { getAllAtributes } from 'admin/api/userPool/attribute/getAllAtributes';
import { CustomError } from 'admin/error/CustomError';
import { UserPool } from 'admin/types/userPool';
import { logger } from 'api/logger';
import { useQuery } from 'react-query';
import { queryIdPoolConsumerAttributeKey } from './queryIdPoolConsumerAttributeKey';

export const useAllAttributes = (id: UserPool['id']) => {
  const { data, isLoading } = useQuery({
    queryKey: queryIdPoolConsumerAttributeKey.getAllAttributes(id),
    queryFn: () => getAllAtributes(id),
    onError: async (error) => {
      if (error instanceof CustomError) {
        await logger({
          loglevel: error.logLevel,
          data: JSON.stringify(error.cause),
          message: error.message,
        });
      }
    },
  });

  // dataがundefinedの時はfilter処理でエラーとなるため、undefined時は空配列とする
  const filterData = (data || []).filter((x) => x.isPreset !== true);

  return {
    data: filterData,
    isLoading,
  };
};
