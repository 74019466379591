import { getIdPoolConsumerDetail } from 'admin/api/userPool/getIdPoolconsumerDetail';
import { CustomError } from 'admin/error/CustomError';
import { useUserCorporationInfo } from 'admin/hooks/user/useUserCorporationInfo';
import { queryIdPoolConsumerDetailKey } from 'admin/hooks/userPool/queryIdPoolConsumerDetailKey';
import { UserPool } from 'admin/types/userPool';
import { logger } from 'api/logger';
import { useCustomToast } from 'hooks/useCustomToast';
import { useQuery } from 'react-query';
import { errorToast } from 'utils/toast';

export const useUserPool = (id: UserPool['id']) => {
  const { currentUserPoolGroupId } = useUserCorporationInfo();
  const toast = useCustomToast();
  const { data, isLoading } = useQuery({
    queryKey: queryIdPoolConsumerDetailKey.getIdPoolConsumerDetail(id),
    queryFn: () =>
      getIdPoolConsumerDetail({
        id,
        currentUserPoolGroupId: currentUserPoolGroupId || '',
      }),
    onError: async (error) => {
      if (error instanceof CustomError) {
        await logger({
          loglevel: error.logLevel,
          data: JSON.stringify(error.cause),
          message: error.message,
        });
      }
      toast({
        ...errorToast,
        duration: 4000,
        title: 'ユーザープール詳細情報の取得に失敗しました',
        position: 'bottom',
        variant: 'solid',
      });
    },
  });

  return { data, isLoading };
};
