import { UseMutateFunction, useMutation } from 'react-query';
import { UseFormSetError } from 'react-hook-form';
import { putUserTenantEdit } from 'api/user/putUserTenantEdit';
import { ResponseError } from 'api/types';
import { User, isUser, UserTenantEditForm } from 'api/user/types';
import { isGetValidationError, toMultiError } from 'utils/form';
import { useCustomToast } from 'hooks/useCustomToast';

type UnPromisify<T> = T extends Promise<infer U> ? U : T;

const userTenantEditData: UserTenantEditForm = {
  tenantId: '',
  userId: '',
  email: '',
  permission: 0,
  groups: [],
};

/**
 * テナントユーザー設定変更hooks
 * @param {Object} UseFormSetError setError
 * @param {setGlobalErrors} callback setGlobalErrors
 * @returns {Object} { onSubmit, isLoading }
 */
export const useUserTenantEdit = ({
  setError,
  setGlobalErrors,
}: {
  setError: UseFormSetError<UserTenantEditForm>;
  setGlobalErrors: (errors: string[]) => void;
}): {
  onSubmit: UseMutateFunction<
    UnPromisify<ReturnType<typeof putUserTenantEdit>>,
    unknown,
    UserTenantEditForm,
    unknown
  >;
  isLoading: boolean;
  isSuccess: boolean;
} => {
  const toast = useCustomToast();

  const {
    mutate: onSubmit,
    isLoading,
    isSuccess,
  } = useMutation(
    ({ tenantId, userId, email, permission, groups }: UserTenantEditForm) =>
      putUserTenantEdit({ tenantId, userId, email, permission, groups }),
    {
      onSuccess: (result: User | boolean | ResponseError) => {
        // グローバルエラーを初期化
        setGlobalErrors([]);
        if (isUser(result)) {
          toast({
            status: 'success',
            position: 'bottom',
            duration: 2000,
            isClosable: true,
            title: '設定しました',
          });

          return;
        }

        if (
          isGetValidationError<UserTenantEditForm>(
            result,
            Object.keys(userTenantEditData),
          )
        ) {
          if (result?.others) {
            setGlobalErrors(result?.others);
          } else {
            Object.keys(result).forEach((k) => {
              const key = k as keyof UserTenantEditForm;
              const errMsgs = result?.[key];
              if (errMsgs) {
                setError(key, { types: toMultiError(errMsgs) });
              }
            });
          }
        }
      },
    },
  );

  return { onSubmit, isLoading, isSuccess };
};
