import { useState, useEffect } from 'react';
import { useQuery } from 'react-query';
import { NodeModel } from '@minoru/react-dnd-treeview';
import { GroupFlat } from 'api/tenant/types';
import { getGroupList } from 'api/tenant/getGroupList';
import { useCustomToast } from 'hooks/useCustomToast';
import { queryTenantKey } from './queryTenantKey';

// ツリーの直下を示すノードID定義
export const ROOT_NODE = '';

export const useGroupList = (tenantId: string): NodeModel<GroupFlat>[] => {
  const [treeData, setTreeData] = useState<NodeModel<GroupFlat>[]>([]);

  const toast = useCustomToast();
  const key = queryTenantKey.groupList;
  const grpListResponse = useQuery(key, () => getGroupList(tenantId), {
    onError: (err: unknown) => {
      if (err instanceof Error) {
        toast.closeAll();
        toast({
          title: err.message,
          status: 'error',
        });
      }
    },
  });

  const { data: groupList } = grpListResponse;

  useEffect(() => {
    if (groupList === undefined) {
      setTreeData([]);

      return;
    }

    // グループをNode化
    const treeGroupList: NodeModel<GroupFlat>[] = groupList
      ? groupList.reduce((acc, current) => {
          // 既に登録済みの場合スキップ
          if (acc.some((t) => t.id === current.id)) {
            return acc;
          }

          acc.push({
            id: current.id,
            // 親グループが別に存在するが、取得したデータには
            // 対象親グループが存在していない場合のための分岐処理
            parent:
              current.parentId &&
              groupList.some((u) => u.id === current.parentId)
                ? current.parentId
                : ROOT_NODE,
            droppable: false,
            text: current.name,
            // 親グループが別に存在するが、取得したデータには
            // 対象親グループが存在していない場合のための分岐処理
            data: groupList.some((u) => u.id === current.parentId)
              ? current
              : {
                  ...current,
                  parentId: null,
                },
          });

          return acc;
        }, [] as NodeModel<GroupFlat>[])
      : [];

    setTreeData(treeGroupList);
  }, [setTreeData, groupList]);

  return treeData;
};
