import {
  Box,
  DrawerCloseButton,
  DrawerContent,
  DrawerHeader,
  DrawerOverlay,
  Text,
} from '@chakra-ui/react';
import { withSuspenseAndErrorBoundary } from 'admin/components/Error/WithErrorBoundary';
import { EditForm } from 'admin/components/idPoolConsumer/attributeGroup/attributeGroupForm/editForm';
import { useIdPoolConsumerId } from 'admin/hooks/useIdPoolConsumerId';
import { useAttributeGroupDetail } from 'admin/hooks/userPool/attributeGroup/useAttributeGroupDetail';
import { AttributeGroupType } from 'admin/types/userPool/attributeGroup';
import { ErrorContents, LoadingSpinner } from 'components/common/atoms';
import { FC, memo } from 'react';

type AttributeGroupEditDrawerProps = {
  id: AttributeGroupType['id'];
  onClose: () => void;
};

const fetchAttributeGroupEditDrawer: FC<AttributeGroupEditDrawerProps> = memo(
  ({ id, onClose }: AttributeGroupEditDrawerProps) => {
    const userPoolId = useIdPoolConsumerId();

    const { data: attributeGroup } = useAttributeGroupDetail(userPoolId, id);

    if (!attributeGroup) {
      return null;
    }

    return <EditForm attributeGroup={attributeGroup} onClose={onClose} />;
  },
);

export const FetchAttributeGroupEditDrawer = withSuspenseAndErrorBoundary(
  fetchAttributeGroupEditDrawer,
  {
    ErrorComponent: <ErrorContents name="属性グループ編集" />,
    LoadingComponent: <LoadingSpinner />,
  },
);

export const AttributeGroupEditDrawer: FC<AttributeGroupEditDrawerProps> = memo(
  (props: AttributeGroupEditDrawerProps) => (
    <>
      <DrawerOverlay />
      <DrawerContent>
        <DrawerHeader pb={0}>
          <Box m={0} px={0} pb="24px" borderBottomWidth="1px">
            <Text>属性グループ編集</Text>
          </Box>
        </DrawerHeader>
        <FetchAttributeGroupEditDrawer {...props} />
        <DrawerCloseButton top="16px" right="24px" />
      </DrawerContent>
    </>
  ),
);
