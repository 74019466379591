import { Options } from 'ky';
import { request } from 'api/request';
import { ResponseError, isResponseError } from 'api/types';
import { getValidationError, ValidationError } from 'utils/form';
import { MailCancelReserve } from 'api/mail/types';
import { messages } from './messages';

const DEFAULT_ERR_MESSAGE: ValidationError<MailCancelReserve> = {
  others: [messages.deleteMailReserve.customer.error],
};
const DEFAULT_SYSTEM_ERR_MESSAGE: ValidationError<MailCancelReserve> = {
  others: [messages.deleteMailReserve.customer.failed],
};

/**
 * メール配信予約解除API
 *
 * 状態により以下のレスポンスを返却します
 * 正常時: true, 異常時: false
 *
 */
export const deleteMailReserve = async ({
  tenantId,
  mailId,
  options,
}: {
  tenantId: string;
  mailId: string;
  options?: Options;
}): Promise<boolean | ResponseError> => {
  const response = await request({
    path: `mail/${tenantId}/${mailId}/cancel_reserve/`,
    method: 'delete',
    options,
  });

  if (response.status >= 500) {
    return DEFAULT_ERR_MESSAGE;
  }

  if (response.status === 403) {
    return DEFAULT_SYSTEM_ERR_MESSAGE;
  }

  const { error } = response;
  if (response.hasError) {
    // APIにて返却されたエラー形式
    if (isResponseError(error)) {
      const formError = getValidationError<MailCancelReserve>({
        formData: { mailId },
        response: error,
      });

      return formError;
    }
  }

  return !response.hasError;
};
