import {
  Box,
  Button,
  DrawerBody,
  DrawerFooter,
  FormControl,
  FormErrorMessage,
  Text,
} from '@chakra-ui/react';
import { RhfTextarea } from 'admin/components/form/RhfTextarea';
import { LoadingLayer } from 'admin/components/ui/loadingLayer';
import { Colors, ColorSet } from 'admin/define/colors';
import { AuthFormInputType } from 'admin/types/service/auth/form';
import { FC, memo } from 'react';
import { useFormState } from 'react-hook-form';
import { toErrMsgList } from 'utils/form';

type Props = {
  isLoading: boolean;
  formId: string;
  onClose: () => void;
  onDialogOpen: () => void;
};

export const CallbackUrlSettingFormComponent: FC<Props> = memo(
  ({ isLoading, formId, onClose, onDialogOpen }: Props) => {
    const { errors } = useFormState<AuthFormInputType>();

    return (
      <>
        {isLoading && <LoadingLayer />}
        <DrawerBody pt="24px" pb="16px">
          <form id={formId}>
            <Box fontFamily="Hiragino Sans">
              <Text fontSize="14px">
                コールバックURLを
                <Text as="span" fontWeight="bold">
                  改行区切りで記入
                </Text>
                してください
              </Text>
              <Box mt="8px">
                <FormControl isInvalid={Boolean(errors.redirectUri)}>
                  <Box>
                    <RhfTextarea
                      p="8px 16px"
                      placeholder={`https://aaaa.com
https://bbbb.com`}
                      name="redirectUri"
                      bgColor={Colors.WHITE}
                      size="sm"
                      borderRadius="md"
                      fontSize="16px"
                      rows={12}
                    />
                  </Box>
                  {toErrMsgList(errors, 'redirectUri').map((err) => (
                    <FormErrorMessage key={`redirectUri${err}`}>
                      {err}
                    </FormErrorMessage>
                  ))}
                </FormControl>
              </Box>
            </Box>
          </form>
        </DrawerBody>
        <DrawerFooter>
          <Button
            variant="outline"
            mr={3}
            p="8px 16px"
            w="104px"
            h="40px"
            onClick={onClose}
            {...ColorSet.Default}
          >
            キャンセル
          </Button>
          <Button
            form={formId}
            mr={3}
            p="8px 16px"
            w="104px"
            h="40px"
            bg={Colors.BLUE_20}
            onClick={onDialogOpen}
            color="white"
          >
            保存
          </Button>
        </DrawerFooter>
      </>
    );
  },
);
