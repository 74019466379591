import { editTransactionMail } from 'admin/api/userPool/transactionMail/editTransactionMail';
import { TRANSACTION_MAIL_PERSONALS } from 'admin/define/idPoolConsumer/transactionMail';
import { CustomError } from 'admin/error/CustomError';
import { useIdPoolConsumerId } from 'admin/hooks/useIdPoolConsumerId';
import {
  TransactionMailCategoryType,
  TransactionMailFormType,
} from 'admin/types/userPool/transactionMail';
import { TransactionMailEditFormType } from 'admin/types/userPool/transactionMail/form';
import { logger } from 'api/logger';
import { useCustomToast } from 'hooks/useCustomToast';
import { useMemo } from 'react';
import { UseFormSetError } from 'react-hook-form';
import { useMutation, useQueryClient } from 'react-query';
import { useNavigate, useParams } from 'react-router-dom';
import { toMultiError } from 'utils/form';
import { errorToast, successToast } from 'utils/toast';
import { queryTransactionMailKey } from './queryTransactionMailKey';

export const useEditTransactionMail = ({
  setError,
}: {
  setError?: UseFormSetError<TransactionMailFormType>;
}) => {
  const params = useParams();
  const transactionMailType = useMemo(
    () => params?.transactionMailType ?? '',
    [params],
  );
  const transactionMailTypeName = useMemo(
    () =>
      TRANSACTION_MAIL_PERSONALS.get(
        transactionMailType as TransactionMailCategoryType,
      )?.name || '',
    [transactionMailType],
  );
  const toast = useCustomToast();
  const queryClient = useQueryClient();
  const navigate = useNavigate();
  const idPoolConsumerId = useIdPoolConsumerId();

  const { mutateAsync, isLoading } = useMutation({
    mutationFn: (data: TransactionMailEditFormType) =>
      editTransactionMail(idPoolConsumerId, data),
    onSuccess: (data) => {
      void queryClient.invalidateQueries(
        queryTransactionMailKey.getTransactionMails(
          idPoolConsumerId,
          data.type,
        ),
      );
      void queryClient.invalidateQueries(
        queryTransactionMailKey.getTransactionMail(idPoolConsumerId, data.id),
      );
      toast({
        ...successToast,
        duration: 4000,
        title: `${transactionMailTypeName}を更新しました`,
        position: 'bottom',
        variant: 'solid',
      });

      const { type } = data;
      const url = `/admin/idPoolConsumer/${idPoolConsumerId}/transactionMail/${type}`;
      navigate(url);
    },
    onError: async (error, variables: TransactionMailEditFormType) => {
      let errorDescription = '';
      if (error instanceof CustomError) {
        if (error.cause.errors?.others) {
          const othersMsgs = Array.isArray(error.cause.errors?.others)
            ? error.cause.errors?.others
            : [error.cause.errors?.others];

          errorDescription = othersMsgs.join('¥r¥n');
        } else {
          Object.keys(variables).forEach((k) => {
            const key = k as keyof TransactionMailEditFormType;
            const errMsgs = error.cause.errors?.[key];
            if (errMsgs && Array.isArray(errMsgs)) {
              setError?.(key, { types: toMultiError(errMsgs) });
            }
          });
        }

        if (error.cause.errors) {
          await logger({
            loglevel: error.logLevel,
            data: JSON.stringify(error.cause),
            message: error.message,
          });
        }
      }
      toast({
        ...errorToast,
        duration: 4000,
        title: `${transactionMailTypeName}を更新に失敗しました`,
        description: errorDescription,
        position: 'bottom',
        variant: 'solid',
      });
    },
  });

  return { mutate: mutateAsync, isLoading };
};
