import { Options } from 'ky';
import { request } from 'api/request';
import { logger } from 'api/logger';
import produce from 'immer';
import { ResponseError, isResponseError } from 'api/types';
import {
  CreateSearchFilter,
  isSearchFilter,
  SearchFilter,
} from 'api/contents/types';
import { getValidationError, ValidationError } from 'utils/form';

import { messages } from './messages';

const DEFAULT_ERR_MESSAGE: ValidationError<FormType> = {
  others: [messages.createSearchFilter.customer.error],
};
const DEFAULT_SYSTEM_ERR_MESSAGE: ValidationError<FormType> = {
  others: [messages.createSearchFilter.customer.failed],
};

type FormType = CreateSearchFilter;

/**
 * 高度な検索条件のJSON文字列生成API
 *
 * 状態により以下のレスポンスを返却します
 * 正常時: searchTableList
 * エラー: ResponseError
 */
export const createSearchFilter = async (
  formParam: FormType,
  options?: Options,
): Promise<SearchFilter | ResponseError | boolean> => {
  const path = `contentsdb/create-filter/`;
  const postParam = formParam;

  const mergedOptions = options
    ? produce(options, (draft) => {
        draft.json = { ...postParam };
      })
    : { json: postParam };

  const response = await request({
    path,
    method: 'post',
    options: mergedOptions,
  });

  if (response.status >= 500) {
    return DEFAULT_ERR_MESSAGE;
  }

  const responseData = response.data;

  const { error } = response;
  if (response.hasError) {
    // APIにて返却されたエラー形式
    if (isResponseError(error)) {
      const formError = getValidationError<FormType>({
        formData: formParam,
        response: error,
      });

      return formError;
    }
  }

  if (isSearchFilter(responseData)) {
    return responseData;
  }

  // 予期せぬエラーなので
  // バックエンドに状態を通知
  await logger({
    loglevel: 'error',
    data: error,
    message: messages.createSearchFilter.system.typeError,
  });

  // 登録できていない旨を通知
  return DEFAULT_SYSTEM_ERR_MESSAGE;
};
