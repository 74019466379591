import { VFC, memo, useCallback } from 'react';
import { Box, Text, useDisclosure } from '@chakra-ui/react';
import { Dialog } from 'components/common/atoms';
import { useDeleteMailReserve } from 'hooks/mail/useDeleteMailReserve';
import { useFormContext } from 'react-hook-form';
import { MailSetting } from 'api/mail/types';

type Props = {
  mailId: string;
  isDisabled: boolean;
  setDelivaryDate: React.Dispatch<React.SetStateAction<Date | null>>;
  reloadData: () => void;
};

/**
 * メール配信予約解除ボタン
 * @param mailId メールID
 * @param status メール配信ステータス
 * @param mailId メール配信日時
 */
export const MailCancelReserveBtn: VFC<Props> = memo(
  ({ mailId, isDisabled, setDelivaryDate, reloadData }: Props) => {
    const { isOpen, onOpen, onClose } = useDisclosure();
    const { reset } = useFormContext<MailSetting>();
    const { onCancel } = useDeleteMailReserve({
      mailId,
      onClose,
      reset,
      setDelivaryDate,
      reloadData,
    });
    // 配信予約解除ボタン押下時
    const openBtnHandler = useCallback(() => {
      onOpen();
    }, [onOpen]);

    // 閉じるボタン押下時
    const closeBtnHandler = useCallback(() => {
      onClose();
    }, [onClose]);

    // 配信予約解除ボタン押下時
    const onCancelReserve = useCallback(() => {
      onCancel();
    }, [onCancel]);

    // 配信予約ボタン有効かどうかの判定
    const isActive = (): boolean => {
      if (isDisabled) return false;

      return true;
    };

    // 配信予約解除ボタンが無効の場合はボタンを非表示とする
    if (!isActive()) return <></>;

    return (
      <Box textAlign="right">
        <Dialog
          title="配信予約の解除"
          isOpen={isOpen}
          onOpen={openBtnHandler}
          onClose={closeBtnHandler}
          openBtnProps={{
            colorScheme: 'red',
          }}
          openBtnChildNode="配信予約解除"
          submitBtnTitle="解除する"
          submitBtnProps={{
            colorScheme: 'red',
          }}
          submitBtnHandelr={onCancelReserve}
          cancelBtnTitle="閉じる"
        >
          <Text>配信予約を解除しますか？</Text>
        </Dialog>
      </Box>
    );
  },
);
