import { zodResolver } from '@hookform/resolvers/zod';
import { NewsletterCreateFormComponent } from 'admin/components/idPoolConsumer/newsletter/newsletterForm/newsletterCreateForm/NewsletterCreateFormComponent';
import { useCreateNewsletter } from 'admin/hooks/userPool/newsletter/useCreateNewsletter';
import { newsletterFormSchema } from 'admin/schema/idPoolConsumer/newsletter';
import { NewsletterFormType } from 'admin/types/userPool/newsletter/form';
import { AlertBar } from 'components/common/atoms';
import { FC, memo, useMemo, useState } from 'react';
import { FormProvider, SubmitHandler, useForm } from 'react-hook-form';
import { useParams } from 'react-router-dom';

type Props = {
  onClose: () => void;
};

export const NewsletterCreateForm: FC<Props> = memo(({ onClose }: Props) => {
  const formId = 'newsletterCreateForm';
  const params = useParams();
  const idPoolConsumerId = useMemo(
    () => params.idPoolConsumerId ?? '',
    [params],
  );

  const methods = useForm<NewsletterFormType>({
    defaultValues: {
      id: '',
      displayNameJa: '',
      displayNameForeign: '',
      fieldName: '',
      displayOrder: undefined,
      memo: '',
    },
    resolver: zodResolver(newsletterFormSchema),
  });

  const [globalErrors, setGlobalErrors] = useState<string[]>([]);
  const { setError, reset } = methods;

  const { mutate, isLoading } = useCreateNewsletter(
    idPoolConsumerId,
    setError,
    setGlobalErrors,
  );

  const onSubmit: SubmitHandler<NewsletterFormType> = async (
    data: NewsletterFormType,
  ) => {
    if (!isLoading) {
      await mutate(data);
    }
    reset();
    onClose();
  };

  return (
    <FormProvider {...methods}>
      {globalErrors.map((err, idx) => (
        <AlertBar
          key={`global-err-idx${String(idx)}`}
          message={err}
          status="error"
          mb={4}
        />
      ))}
      <NewsletterCreateFormComponent
        formId={formId}
        isLoading={isLoading}
        onSubmit={onSubmit}
        onClose={onClose}
      />
    </FormProvider>
  );
});
