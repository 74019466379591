import { TRANSACTION_MAIL_EMBEDDED_WORDS } from 'admin/define/idPoolConsumer/transactionMail';
import { useIdPoolConsumerId } from 'admin/hooks/useIdPoolConsumerId';
import { TransactionMailCategoryType } from 'admin/types/userPool/transactionMail';
import { useCopy } from 'hooks/useCopy';
import { FC, memo, RefObject, useCallback, useMemo } from 'react';
import EmailEditor from 'react-email-editor';
import { TransactionMailEditorHeaderComponent } from './TransactionMailEditorHeaderComponent';

type Props = {
  editor: RefObject<EmailEditor>;
  transactionMailType: TransactionMailCategoryType;
  onSave: () => void;
  isLoading: boolean;
  navigate: (path: string) => void;
};

export const TransactionMailEditorHeader: FC<Props> = memo(
  ({ editor, transactionMailType, onSave, isLoading, navigate }) => {
    const { copy } = useCopy();
    const idPoolConsumerId = useIdPoolConsumerId();

    const embededWords = useMemo(
      () =>
        TRANSACTION_MAIL_EMBEDDED_WORDS.filter(
          (word) => word.mailType === transactionMailType,
        ),
      [transactionMailType],
    );

    const handleClick = useCallback(
      (tag: string) => {
        copy(`%%${tag}%%`);
      },
      [copy],
    );

    return (
      <TransactionMailEditorHeaderComponent
        editor={editor}
        transactionMailType={transactionMailType}
        backUrl={`/admin/idPoolConsumer/${idPoolConsumerId}/transactionMail/${transactionMailType}`}
        onSave={onSave}
        isLoading={isLoading}
        embededWords={embededWords}
        onWordClick={handleClick}
        navigate={navigate}
      />
    );
  },
);
