import { Box, Button, Flex, Text } from '@chakra-ui/react';
import { Colors } from 'admin/define/colors';
import { CodeSettingType } from 'admin/types/userPool/code';
import { FC, memo } from 'react';
import { MdOutlineAdd } from 'react-icons/md';

type Props = {
  onOpen: () => void;
  id: CodeSettingType['id'];
  displayNameJa: CodeSettingType['displayNameJa'];
};

export const CodeTitleComponent: FC<Props> = memo(
  ({ onOpen, id, displayNameJa }: Props) => (
    <Box mt="40px">
      <Box
        bg="white"
        borderRadius="6px"
        display="inline-block"
        py="2px"
        px="8px"
        mb="8px"
      >
        {id} | {displayNameJa}
      </Box>

      <Flex justifyContent="flex-end">
        <Box mr="auto">
          <Text
            fontFamily="Hiragino Sans"
            fontSize="18px"
            fontWeight="700"
            lineHeight="170%"
          >
            コード設定
          </Text>
          <Text
            fontWeight="400"
            fontSize="14px"
            lineHeight="170%"
            fontFamily="Hiragino Sans"
            color={Colors.GRAY_00}
          >
            エンドユーザーが設問に回答する際に使用する、プルダウンメニューで表示される選択肢の管理を行います。
          </Text>
        </Box>
        <Button colorScheme="primary" p="8px 24px" onClick={onOpen}>
          <MdOutlineAdd />
          コード登録
        </Button>
      </Flex>
    </Box>
  ),
);
