import {
  Box,
  DrawerCloseButton,
  DrawerContent,
  DrawerHeader,
  DrawerOverlay,
  Text,
} from '@chakra-ui/react';
import { withSuspenseAndErrorBoundary } from 'admin/components/Error/WithErrorBoundary';
import { TransactionMailCommonForm } from 'admin/components/idPoolConsumer/transactionMail/transactionMailCommonForm';
import { TransactionMailCommonType } from 'admin/types/userPool/transactionMail';
import { ErrorContents, LoadingSpinner } from 'components/common/atoms';
import { FC, memo } from 'react';

type Props = {
  formId: string;
  onClose: () => void;
  mailCommon: TransactionMailCommonType;
};

const transactionMailCommonDrawer: FC<Props> = memo(
  ({ formId, onClose, mailCommon }) => (
    <>
      <DrawerOverlay />
      <DrawerContent>
        <DrawerHeader pb={0}>
          <Box m={0} px={0} pb="24px" borderBottomWidth="1px">
            <Text>共通設定</Text>
          </Box>
        </DrawerHeader>
        <TransactionMailCommonForm
          formId={formId}
          onClose={onClose}
          mailCommon={mailCommon}
        />
        <DrawerCloseButton top="16px" right="24px" />
      </DrawerContent>
    </>
  ),
);

export const TransactionMailCommonDrawer = withSuspenseAndErrorBoundary(
  transactionMailCommonDrawer,
  {
    ErrorComponent: (
      <ErrorContents name="トランザクションメール共通設定ドロワー" />
    ),
    LoadingComponent: <LoadingSpinner />,
  },
);
