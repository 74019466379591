import {
  TransactionMailInsertWord,
  TransactionMailPersonal,
  TransactionMailPersonalType,
} from 'admin/types/userPool/transactionMail';

/**
 * トランザクションメールの埋め込みワード
 */
export const TRANSACTION_MAIL_EMBEDDED_WORDS: TransactionMailInsertWord[] = [
  { mailType: 'authenticationCode', text: '認証コード', code: 'AuthCode' },
  {
    mailType: 'passwordReminder',
    text: 'パスワード変更URL',
    code: 'PassChange',
  },
  {
    mailType: 'changedMemberInformation',
    text: 'メールアドレス変更',
    code: 'ChangedInfo',
  },
];

export const TRANSACTION_MAIL_PERSONAL_TYPE_AUTHENTICATION =
  'authenticationCode';
export const TRANSACTION_MAIL_PERSONAL_TYPE_REGISTERED = 'registeredMember';
export const TRANSACTION_MAIL_PERSONAL_TYPE_PASSWORD = 'passwordReminder';
export const TRANSACTION_MAIL_PERSONAL_TYPE_UNSUBSCRIBED = 'unsubscribedMember';
export const TRANSACTION_MAIL_PERSONAL_TYPE_CHANGE_MEMBER =
  'changedMemberInformation';

// 個別設定一覧
export const TRANSACTION_MAIL_PERSONAL_TYPE = [
  TRANSACTION_MAIL_PERSONAL_TYPE_AUTHENTICATION,
  TRANSACTION_MAIL_PERSONAL_TYPE_REGISTERED,
  TRANSACTION_MAIL_PERSONAL_TYPE_PASSWORD,
  TRANSACTION_MAIL_PERSONAL_TYPE_UNSUBSCRIBED,
  TRANSACTION_MAIL_PERSONAL_TYPE_CHANGE_MEMBER,
  // 'startService',
] as const;

// トランザクションメール 個別設定 常時有効ステータス
export const TRANSACTION_MAIL_PERSONAL_STATIC_STATUS = [
  TRANSACTION_MAIL_PERSONAL_TYPE_AUTHENTICATION,
  TRANSACTION_MAIL_PERSONAL_TYPE_REGISTERED,
  TRANSACTION_MAIL_PERSONAL_TYPE_PASSWORD,
];

// トランザクションメール 個別設定 トグルスイッチステータス
export const TRANSACTION_MAIL_PERSONAL_DYNAMIC_STATUS = [
  TRANSACTION_MAIL_PERSONAL_TYPE_UNSUBSCRIBED,
  TRANSACTION_MAIL_PERSONAL_TYPE_CHANGE_MEMBER,
];

const TRANSACTION_MAIL_PERSONAL_VALUES: TransactionMailPersonal[] = [
  {
    name: '認証コードメール',
    description: '本人確認が必要な際に送信される一時的な認証コードを含むメール',
  },
  {
    name: 'ID登録完了メール',
    description: 'ID会員としての登録が成功したことを通知する確認メール',
  },
  {
    name: 'パスワードリマインダーメール',
    description: '忘れたパスワードをリセットするための指示が記載されたメール',
  },
  {
    name: 'ID退会完了メール',
    description: 'ユーザーの退会手続きが正常に完了したことを伝える通知メール',
  },
  {
    name: 'メールアドレス・パスワード変更完了メール',
    description:
      'メールアドレス・パスワードの設定変更が完了したことを通知する確認メール',
  },
];

export const TRANSACTION_MAIL_PERSONALS = new Map<
  TransactionMailPersonalType,
  TransactionMailPersonal
>(
  TRANSACTION_MAIL_PERSONAL_TYPE.map((type, index) => [
    type,
    TRANSACTION_MAIL_PERSONAL_VALUES[index],
  ]),
);
