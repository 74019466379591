import { Page, isPage } from 'api/common/types';

export type ChartList = {
  id: string;
  title: string;
  typeString: string;
  type: number;
  text?: string;
  labels: string[];
  data: number[];
};

export type ChartTextList = {
  results: string[];
} & Page;

export const isChartInfo = (data: unknown): data is ChartList => {
  const m = data as ChartList;

  return (
    (typeof m?.id === 'string' || typeof m?.id === 'number') &&
    typeof m?.type === 'number' &&
    (m?.text !== null ? typeof m?.text === 'string' : true) &&
    (m?.labels.length > 0
      ? m?.labels.some((x) => typeof x === 'string')
      : true) &&
    (m?.data.length > 0 ? m?.data.some((x) => typeof x === 'number') : true)
  );
};

export const isChartList = (datas: unknown[]): datas is ChartList[] =>
  !datas.some((d) => !isChartInfo(d));

export const isChartTextList = (data: unknown): data is ChartTextList => {
  const ctl = data as ChartTextList;

  return (
    isPage(ctl) &&
    (ctl.results.length === 0
      ? true
      : ctl.results.some((d) => typeof d === 'string'))
  );
};
