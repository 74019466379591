import { VFC } from 'react';
import {
  useFormContext,
  useController,
  RegisterOptions,
} from 'react-hook-form';
import { FormControl, Select, FormLabel } from '@chakra-ui/react';
import { RegistInviteUserForm } from 'api/user/types';
import { ErrorTextMsg } from 'components/common/atoms';
import { toErrMsgList } from 'utils/form';

/**
 * SelectFormProps
 */
type SelectFormProps = {
  name: 'permission';
  label: string;
  attr: RegisterOptions;
  list: { value: number; text: string; label: string }[];
};

const SELECT_FORM_DATA = {
  permission: {
    placeholder: '選択してください（●は個人情報権限あり）',
    errorText: '権限',
  },
};

export const SelectForm: VFC<SelectFormProps> = ({
  name,
  label,
  attr,
  list,
}: SelectFormProps) => {
  const { control } = useFormContext<RegistInviteUserForm>();
  const requiredMsg = (attr.required as string).replace(
    /\[select\]/g,
    SELECT_FORM_DATA[name].errorText,
  );

  const {
    field: { ...formRest },
    fieldState: { error },
  } = useController({
    name,
    control,
    rules: {
      ...attr,
      required: requiredMsg,
      validate: (val) => val > 0 || requiredMsg,
    },
  });

  return (
    <FormControl>
      <FormLabel htmlFor={name} fontSize="sm">
        {label}
      </FormLabel>
      <Select
        id={name}
        placeholder={SELECT_FORM_DATA[name].placeholder}
        {...formRest}
      >
        {list.map((item) => (
          <option key={`role_${item.value}`} value={item.value}>
            {item.label}
          </option>
        ))}
      </Select>
      {toErrMsgList({ [name]: error }, name).map((err, idx) => (
        <ErrorTextMsg key={`error-${name}-${String(idx)}`} msg={err} />
      ))}
    </FormControl>
  );
};
