import { request } from 'admin/api/Request';
import { API_MESSAGES } from 'admin/define/apiMessages';
import { CustomError } from 'admin/error/CustomError';
import { editCodeSchema as codeSettingSchema } from 'admin/schema/idPoolConsumer/code';
import { CodeSettingType } from 'admin/types/userPool/code';
import { CodeGroupType } from 'admin/types/userPool/master/index';
import { Options } from 'ky';
import { ZodError } from 'zod';

export const getCodeSetting = async ({
  id,
  codeGroupId,
  options,
}: {
  id: CodeSettingType['id'];
  codeGroupId: CodeGroupType['id'];
  options?: Options;
}): Promise<CodeSettingType> => {
  const path = `uniikey/${codeGroupId}/code/${id}/`;
  const response = await request<CodeSettingType>({
    path,
    method: 'get',
    options,
  });

  if (response.hasError) {
    if (response.status === 404) {
      throw new CustomError(API_MESSAGES.USER_POOL.GET_CODE_SETTING.NOT_FOUND, {
        path,
        status: response.status,
      });
    } else {
      throw new CustomError(API_MESSAGES.USER_POOL.GET_CODE_SETTING.ERROR, {
        path,
        status: response.status,
        logLevel: response.status === 400 ? 'warning' : 'error',
      });
    }
  }
  try {
    codeSettingSchema.parse(response.data);
  } catch (error) {
    if (error instanceof ZodError) {
      throw new CustomError(error.message, {
        path,
        status: response.status,
        logLevel: 'error',
      });
    }
  }

  return response.data;
};
