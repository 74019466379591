import { Box, ListIcon, Text } from '@chakra-ui/react';
import { memo, VFC } from 'react';
import { IconType } from 'react-icons';
import { Link } from 'react-router-dom';

type SideMenuLinkProps = {
  name: string;
  path: string;
  isSelected?: boolean;
  icon?: IconType;
};

export const SideMenuLink: VFC<SideMenuLinkProps> = memo(
  ({ name, path, isSelected = false, icon }) => (
    <Link to={path} style={{ width: '100%' }}>
      <Box
        padding="10px"
        borderRadius="5px"
        style={isSelected ? { backgroundColor: '#a9f2e94D' } : {}}
      >
        {icon && <ListIcon as={icon} />}
        <Text as="span">{name}</Text>
      </Box>
    </Link>
  ),
);
