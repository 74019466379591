import produce from 'immer';
import { Options } from 'ky';
import { logger } from 'api/logger';
import { request } from 'api/request';
import { ResponseError, isResponseError } from 'api/types';
import { isContentsTable, ContentsDbTblFormType } from 'api/contents/types';
import { messages } from 'api/contents/messages';

const DEFAULT_ERR_MESSAGE = {
  other: messages.updateContentsTable.customer.error,
};

/**
 * コンテンツシェルフテーブル更新API
 *
 * 状態により以下のレスポンスを返却します
 * 正常時: true
 * エラー: ResponseError
 *
 */
export const updateContentsTable = async ({
  formData,
  tenantId,
  tableId,
  options,
}: {
  formData: ContentsDbTblFormType;
  tenantId: string;
  tableId: string;
  options?: Options;
}): Promise<ResponseError | boolean> => {
  const mergedOptions = options
    ? produce(options, (draft) => {
        draft.json = formData;
      })
    : { json: formData };

  const response = await request({
    path: `contentsdb/${tenantId}/${tableId}/`,
    method: 'put',
    options: mergedOptions,
  });

  const responseData = response.data;

  if (response.status >= 500 && response.status < 600) {
    return DEFAULT_ERR_MESSAGE;
  }

  if (response.hasError) {
    // APIにて返却されたエラー形式
    if (isResponseError(responseData)) {
      return responseData;
    }

    // 予期せぬエラーなので
    // バックエンドに状態を通知
    await logger({
      loglevel:
        response.status === 404 || response.status === 450
          ? 'warning'
          : 'error',
      data: response,
      message: messages.updateContentsTable.system.responseError,
    });

    // 登録できていない旨を通知
    return DEFAULT_ERR_MESSAGE;
  }

  // 更新成功時
  if (!isContentsTable(responseData)) {
    // 更新は成功したものの
    // レスポンスの型ガードでエラー
    await logger({
      loglevel:
        response.status === 404 || response.status === 450
          ? 'warning'
          : 'error',
      data: responseData,
      message: messages.updateContentsTable.system.typeError,
    });
  }

  return true;
};
