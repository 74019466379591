import { UserPool } from 'admin/types/userPool';
import { IdPoolConsumerAttributeType } from 'admin/types/userPool/attribute';
import { Page } from 'api/common/types';

export const queryIdPoolConsumerAttributeKeyNames = {
  idPoolConsumerAttributes: 'idPoolConsumerAttributes',
  idPoolConsumerAttributesDetail: 'idPoolConsumerAttributesDetail',
  getAllAttributes: 'getAllAttributes',
} as const;

export const queryIdPoolConsumerAttributeKey = {
  getIdPoolConsumerAttributes: (
    id?: UserPool['id'],
    currentPage?: Page['currentPage'],
    perPage?: Page['perPage'],
  ) =>
    [
      queryIdPoolConsumerAttributeKeyNames.idPoolConsumerAttributes,
      id,
      currentPage,
      perPage,
    ].filter((item) => item),
  getIdPoolConsumerAttributesDetail: (
    id: IdPoolConsumerAttributeType['id'],
    userPoolId: IdPoolConsumerAttributeType['userPool'],
  ) => [
    queryIdPoolConsumerAttributeKeyNames.idPoolConsumerAttributesDetail,
    id,
    userPoolId,
  ],
  getAllAttributes: (id: UserPool['id']) => [
    queryIdPoolConsumerAttributeKeyNames.getAllAttributes,
    id,
  ],
} as const;
