import { request } from 'admin/api/Request';
import { API_MESSAGES } from 'admin/define/apiMessages';
import { CustomError } from 'admin/error/CustomError';
import { tenantUsersSchema } from 'admin/schema/tenantUsers';
import { tenantUsersUpdateFormType } from 'admin/types/tenantUsers/form';
import { TenantUsersType } from 'admin/types/tenantUsers/index';
import { isResponseError } from 'api/types';
import { getValidationError } from 'utils/form';
import { ZodError } from 'zod';

export const updateTenantUsersDetail = async (
  data: tenantUsersUpdateFormType,
  corporationId: string,
): Promise<TenantUsersType> => {
  const path = `user/corporation/${corporationId}/tenants/${data.tenantId}/user/${data.id}/`;
  const response = await request<TenantUsersType>({
    path,
    body: data,
    method: 'patch',
  });

  if (response.hasError) {
    if (response.status === 404) {
      throw new CustomError(
        API_MESSAGES.TENANT_USERS.UPDATE_TENANT_USERS.FAILED,
        {
          path,
          params: data,
          status: response.status,
        },
      );
    } else if (isResponseError(response.error)) {
      const customError = response.error;

      const formError = getValidationError<tenantUsersUpdateFormType>({
        formData: data,
        response: customError,
      });

      throw new CustomError(
        API_MESSAGES.TENANT_USERS.UPDATE_TENANT_USERS.ERROR,
        {
          path,
          params: data,
          errors: formError,
          status: response.status,
          logLevel: response.status === 400 ? 'warning' : 'error',
        },
      );
    } else {
      throw new CustomError(
        API_MESSAGES.TENANT_USERS.UPDATE_TENANT_USERS.ERROR,
        {
          path,
          params: data,
          status: response.status,
        },
      );
    }
  }
  try {
    tenantUsersSchema.parse(response.data);
  } catch (error) {
    if (error instanceof ZodError) {
      throw new CustomError(error.message, {
        path,
        params: data,
        status: response.status,
        logLevel: 'error',
      });
    }
  }

  return response.data;
};
