import { customErrorMessage, toZod } from 'admin/config/zod';
import { LogType, TermType } from 'admin/types/userPool/terms';
import { z } from 'zod';

// zodErrorの日本語化対応
z.setErrorMap(customErrorMessage);

export const termSchema = z.object<toZod<TermType>>({
  id: z.string(),
  userPool: z.string(),
  displayNameJa: z.string(),
  displayNameForeign: z.string(),
  fieldName: z.string(),
  version: z.number(),
  status: z.boolean(),
  url: z.string(),
  consentRequiredVersion: z.optional(z.number().nullable()),
  isEnabled: z.boolean(),
  isAutoAppendToChild: z.boolean(),
  displayOrder: z.number(),
  memo: z.optional(z.string().nullable()),
});

export const termsSchema = z.array(termSchema);

export const logSchema = z.object<toZod<LogType>>({
  id: z.number(),
  version: z.number(),
  details: z.string(),
  memo: z.optional(z.string().nullable()),
  createBy: z.string(),
  createdAt: z.string(),
});

export const logsSchema = z.array(logSchema);

export const termCreateFormSchema = z.object<
  toZod<
    Omit<
      TermType,
      | 'id'
      | 'userPool'
      | 'version'
      | 'memo'
      | 'consentRequiredVersion'
      | 'status'
      | 'isAutoAppendToChild'
      | 'isEnabled'
    >
  >
>({
  displayNameJa: z.string().min(1, '日本語は必須です'),
  displayNameForeign: z.string().optional(),
  fieldName: z.string().min(1, 'フィールド名は必須です'),
  url: z.string().min(1, '規約ページのURLは必須です'),
  displayOrder: z.number().or(z.string()).optional(),
});

export const termFormSchema = z.object<
  toZod<Omit<TermType, 'id' | 'userPool'>>
>({
  displayNameJa: z.string().min(1, '日本語は必須です'),
  displayNameForeign: z.string().optional(),
  fieldName: z.string().min(1, 'フィールド名は必須です'),
  url: z.string().min(1, '規約ページのURLは必須です'),
  isEnabled: z.boolean(),
  status: z.boolean(),
  isAutoAppendToChild: z.boolean(),
  displayOrder: z.number().optional(),
  memo: z.optional(z.string().nullable()),
  version: z.number(),
  consentRequiredVersion: z.number().optional().nullable(),
});

export const agreeFormSchema = z.object({
  message: z.string().refine((val) => val === 'agree', {
    message: '"agree"と入力してください',
  }),
});

export const versionupFormSchema = z.object({
  memo: z.string().optional(),
  message: z.string().refine((val) => val === 'agree', {
    message: '"agree"と入力してください',
  }),
});
