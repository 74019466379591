import { Text } from '@chakra-ui/react';
import { withSuspenseAndErrorBoundary } from 'admin/components/Error/WithErrorBoundary';
import { ServiceInfoComponent } from 'admin/components/member/service/serviceInfo/ServiceInfoComponent';
import { useService } from 'admin/hooks/service/useService';
import { ErrorContents } from 'components/common/atoms';
import { FC, memo, useMemo } from 'react';
import { useParams } from 'react-router-dom';

const serviceInfo: FC = memo(() => {
  const params = useParams();
  const serviceId = useMemo(() => params.serviceId ?? '', [params.serviceId]);
  const { data: service } = useService(serviceId);

  if (!service) {
    return <Text>テナント詳細情報がありません。</Text>;
  }

  return <ServiceInfoComponent service={service} />;
});

export const ServiceInfo = withSuspenseAndErrorBoundary(serviceInfo, {
  ErrorComponent: <ErrorContents name="テナント詳細情報" />,
});
