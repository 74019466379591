import { VFC, memo, useState, useEffect, useCallback, Suspense } from 'react';
import { useForm, FormProvider } from 'react-hook-form';
import { Stack, Box } from '@chakra-ui/react';
import { LoadingSkeleton } from 'components/common/atoms';
import { TableDataSearch } from 'components/contentsdb/molecules/TableDataSearch';
import { TableSearchConditionForm } from 'components/contentsdb/molecules/TableSearchConditionForm';
import { useUserTenantId } from 'hooks/user/useUserTenantId';
import { useTableId } from 'hooks/contentsdb/useTableId';
import ErrorBoundary from 'api/ErrorBoundary';
import { SearchFilter, TableDataSearchList } from 'api/contents/types';
import { useTableSearchData } from 'hooks/contentsdb/useTableSearchData';
import { useErrorBoundaryReset } from 'hooks/useErrorBoundaryReset';

const defaultFilterValues: SearchFilter = {
  page: 1,
  limit: 100,
};
const TableSearchInner: VFC = memo(() => {
  const [globalErrors, setGlobalErrors] = useState<string[]>([]);
  const [searchList, setSearchList] = useState<TableDataSearchList>();
  const tableId = useTableId();
  const tenantId = useUserTenantId();
  const methods = useForm<SearchFilter>({
    mode: 'onBlur',
    reValidateMode: 'onBlur',
    defaultValues: defaultFilterValues,
  });
  const { handleSubmit, setError } = methods;
  const formId = 'table-search-form';
  const { onSubmit: getList, isSuccess } = useTableSearchData({
    setSearchList,
    setGlobalErrors,
    setError,
  });

  useEffect(() => {
    getList({
      tenantId,
      tableId,
      filter: defaultFilterValues,
    });
  }, [getList, tenantId, tableId]);

  const formSubmit = useCallback(
    (data: SearchFilter) => {
      setSearchList(undefined);
      getList({
        tenantId,
        tableId,
        filter: data,
      });
    },
    [getList, tenantId, tableId],
  );

  return (
    <Stack spacing="24px">
      <FormProvider {...methods}>
        <TableSearchConditionForm
          tableId={tableId}
          tenantId={tenantId}
          formSubmit={formSubmit}
        />
        <form id={formId} onSubmit={handleSubmit((data) => formSubmit(data))}>
          <Box>
            <TableDataSearch
              searchList={searchList}
              isSuccess={isSuccess}
              globalErrors={globalErrors}
            />
          </Box>
        </form>
      </FormProvider>
    </Stack>
  );
});

export const TableSearch: VFC = memo(() => {
  const { ebKey, onError } = useErrorBoundaryReset();

  return (
    <ErrorBoundary onError={onError} key={ebKey.current}>
      <Suspense fallback={<LoadingSkeleton />}>
        <TableSearchInner />
      </Suspense>
    </ErrorBoundary>
  );
});
