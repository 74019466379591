import { request as baseRequest } from 'api/request';
import produce from 'immer';
import { Options } from 'ky';

const API_HOST = process.env.REACT_APP_API_HOST;

export type ReturnType<T> = {
  errorMessage: string;
  error: unknown;
  hasError: boolean;
  responseType: ResponseType;
  status: number;
  url: string;
  data: T; // dataをジェネリクスで指定する
};

export type RequestType = {
  path: string;
  method?: string;
  body?: Record<string, any>;
  options?: Options;
};

export const request = async <T>({
  path,
  method = 'get',
  body,
  options,
}: RequestType): Promise<ReturnType<T>> => {
  let mergedOptions = options;
  if (!['get', 'head'].includes(method.toLowerCase())) {
    mergedOptions = options
      ? produce(options, (draft) => {
          draft.json = { ...body };
        })
      : { json: body };
  }

  const response = await baseRequest({
    path,
    method,
    options: {
      ...mergedOptions,
      prefixUrl: API_HOST,
    },
  });

  return response as ReturnType<T>;
};
