import { customErrorMessage, toZod } from 'admin/config/zod';
import {
  AuthHubType,
  AuthHubItemType,
  AuthHubsResultType,
} from 'admin/types/authHub';
import { z } from 'zod';
// zodErrorの日本語化対応
z.setErrorMap(customErrorMessage);

export const authHubItemSchema = z.object<toZod<AuthHubItemType>>({
  groupName: z.string(),
  key: z.string(),
  value: z.string(),
});

export const authHubItemsSchema = z.array(authHubItemSchema);

export const authHubSchema = z.object<toZod<AuthHubType>>({
  id: z.string(),
  name: z.string(),
  isActive: z.boolean(),
  authHubItems: authHubItemsSchema,
});

const authHubShallowSchema = authHubSchema.omit({ authHubItems: true });
export const authHubsSchema = z.array(authHubShallowSchema);

export const authHubResponseSchema = z.object<toZod<AuthHubType>>({
  id: z.string(),
  name: z.string(),
  isActive: z.boolean(),
  authHubItems: authHubItemsSchema,
});

export const authHubsResponseSchema = z.object<toZod<AuthHubsResultType>>({
  results: authHubsSchema,
});
