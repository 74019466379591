import { useDisclosure } from '@chakra-ui/react';
import { Button } from 'components/paywall/atoms/Button';
import { TextButton } from 'components/paywall/atoms/TextButton';
import { usePaywall } from 'components/paywall/hooks/usePaywall';
import { Dialog } from 'components/paywall/molecules/Dialog';
import { WallFormDefaultValues } from 'components/paywall/pages/WallList/initial';
import { Wall, WallFormType } from 'components/paywall/pages/WallList/typed';
import { FC, memo } from 'react';
import { FormProvider, useForm } from 'react-hook-form';
import { Form } from './Form';

type Props = {
  isAdd?: boolean;
  buttonMode?: 'simple' | 'text';
  buttonName: string;
  obj?: Wall;
};

/**
 * @see 単品ウォールの新規作成＆編集ダイアログ
 * @param isAdd 新規作成か編集かの判定
 * @param buttonMode ボタンに渡すモード
 * @param buttonName ボタンの名前
 * @param Wall ウォールのオブジェクト
 */
export const ItemWallDialog: FC<Props> = memo(
  ({ isAdd, buttonMode, buttonName, obj }) => {
    const methods = useForm<WallFormType>({
      mode: 'onBlur',
      defaultValues: WallFormDefaultValues,
    });
    const {
      getValues,
      reset,
      setError,
      formState: { errors },
    } = methods;
    const { isOpen, onOpen, onClose } = useDisclosure();
    const { saveWall, onDrawerClose } = usePaywall({
      getValues,
      isAdd,
      setError,
      onClose,
      reset,
    });

    return (
      <FormProvider {...methods}>
        {buttonMode !== 'text' && (
          <Button mode={buttonMode} onClick={onOpen}>
            {buttonName}
          </Button>
        )}
        {buttonMode === 'text' && (
          <TextButton onClick={onOpen}>{buttonName}</TextButton>
        )}
        <Dialog
          showIcon
          title={isAdd ? '単品ウォール新規作成' : '単品ウォール編集'}
          isOpen={isOpen}
          disabled={Object.keys(errors).length > 0}
          onClose={onDrawerClose}
          onSubmit={() => saveWall({ wallType: 'item' })}
        >
          <Form isAdd={isAdd} obj={obj} />
        </Dialog>
      </FormProvider>
    );
  },
);
