import { Tr } from '@chakra-ui/react';
import { Th } from 'components/paywall/features/Table';
import { FC, memo } from 'react';

export const OrderColumns: FC = memo(() => (

  <Tr>
    <Th maxWidth="250px" minWidth="250px" name="決済ID" columnFix targetKey="id" isSort filterType="text" />
    <Th maxWidth="145px" minWidth="145px" name="決済日時" targetKey="execTranDate" isSort filterType="period" />
    <Th maxWidth="108px" minWidth="108px" name="商品ID" targetKey="productId" filterType="plan" />
    <Th maxWidth="207px" minWidth="207px" name="プラン名称" targetKey="productName" filterType="plan" />
    <Th maxWidth="96px" minWidth="96px" name="金額" targetKey="price" isSort filterType="price" />
    <Th maxWidth="123px" minWidth="123px" name="決済ステータス"/>
    <Th maxWidth="199px" minWidth="199px" name="メールアドレス" targetKey="email" filterType="text" />
    <Th maxWidth="284px" minWidth="284px" name="顧客ID" targetKey="customerUid" isSort filterType="text" />
    <Th maxWidth="177px" minWidth="177px" name="システム番号" targetKey="systemCode" isSort filterType="text" />
    <Th maxWidth="229px" minWidth="229px" name="単品タグ" targetKey="tags" filterType="text" />
  </Tr>
));

