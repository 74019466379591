import { UserPool } from 'admin/types/userPool';
import { TermType } from 'admin/types/userPool/terms';
import { Page } from 'api/common/types';

export const queryKeyNames = {
  getTerms: 'getTerms',
  getTerm: 'getTerm',
  getLogs: 'getLogs',
};

export const queryKeys = {
  getTerms: (
    id: UserPool['id'],
    currentPage: Page['currentPage'],
    perPage?: Page['perPage'],
  ) => [queryKeyNames.getTerms, id, currentPage, perPage],
  getTerm: (id: TermType['id'], userPoolId: UserPool['id']) => [
    queryKeyNames.getTerm,
    id,
    userPoolId,
  ],
  getLogs: (id: TermType['id'], userPoolId: UserPool['id']) => [
    queryKeyNames.getLogs,
    id,
    userPoolId,
  ],
} as const;
