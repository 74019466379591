import { Box, Flex, Text, Tooltip } from '@chakra-ui/react';
import ErrorBoundary from 'api/ErrorBoundary';
import { ErrorContents, ErrorStatusContents } from 'components/common/atoms';
import { Link } from 'components/paywall/atoms/Link';
import { Colors } from 'components/paywall/config';
import { Pagination } from 'components/paywall/features/Table';
import { usePaywall } from 'components/paywall/hooks/usePaywall';
import { usePaywallCourse } from 'components/paywall/hooks/usePaywallCourse';
import { usePaywallUtil } from 'components/paywall/hooks/usePaywallUtil';
import { PromotionCardList } from 'components/paywall/pages/PromotionList/promotionCardList';
import { PromotionDrawer } from 'components/paywall/pages/PromotionList/PromotionDrawer';
import { Container } from 'components/paywall/templates/Container';
import { useErrorBoundaryReset } from 'hooks/useErrorBoundaryReset';
import { FC, memo } from 'react';

export const PromotionList: FC = memo(() => {
  const { wallName, wallId } = usePaywall({});
  const { courseName } = usePaywallCourse({});
  const { formatText } = usePaywallUtil();
  const { ebKey, onError } = useErrorBoundaryReset();
  const renderErrorComponent = {
    404: <ErrorStatusContents code={404} />,
    450: <ErrorStatusContents code={450} />,
  };

  return (
    <Container
      title={
        <Flex flexWrap="wrap">
          <Link to="/paywall/wallList">
            <Flex>
              <Text
                fontSize="14px"
                color={Colors.GRAY_20}
                fontWeight="700"
                cursor="pointer"
              >
                サブスクリプション商品設定
              </Text>
            </Flex>
          </Link>
          <Text as="span" color={Colors.BLACK} fontWeight="700">
            &nbsp;/&nbsp;
          </Text>
          <Link to={`/paywall/${wallId}/courseList`}>
            <Flex style={{ color: '#aaaaaa' }}>
              <Tooltip
                label={formatText({ text: wallName, trimCnt: 10 }).originText}
                bg="gray.100"
                color="black"
                isDisabled={
                  formatText({ text: wallName, trimCnt: 10 }).isDisabled
                }
              >
                <Text cursor="pointer">
                  {formatText({ text: wallName, trimCnt: 10 }).trimText} -
                  コース設定
                </Text>
              </Tooltip>
            </Flex>
          </Link>
          <Text as="span" color={Colors.BLACK} fontWeight="700">
            &nbsp;/&nbsp;
          </Text>
          <Flex>
            <Tooltip
              label={formatText({ text: courseName, trimCnt: 10 }).originText}
              bg="gray.100"
              color="black"
              isDisabled={
                formatText({ text: courseName, trimCnt: 10 }).isDisabled
              }
            >
              <Text>
                {formatText({ text: courseName, trimCnt: 10 }).trimText} -
                プロモーション一覧
              </Text>
            </Tooltip>
          </Flex>
        </Flex>
      }
      label={<PromotionDrawer isAdd buttonName="新規作成" />}
    >
      <Box mt="40px">
        <ErrorBoundary
          errorComponent={<ErrorContents name="プロモーション一覧" />}
          statusComponent={renderErrorComponent}
          onError={onError}
          key={ebKey.current}
        >
          <PromotionCardList />
        </ErrorBoundary>
      </Box>
      <Box pt="40px">
        <Pagination />
      </Box>
    </Container>
  );
});
