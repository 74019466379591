import {
  Box,
  Button,
  DrawerBody,
  DrawerFooter,
  Heading,
  Text,
  VStack,
} from '@chakra-ui/react';
import { RhfCheckbox } from 'admin/components/form/RhfCheckbox';
import { Colors, ColorSet } from 'admin/define/colors';
import { ServiceNewsletterType } from 'admin/types/service/newsletter';
import { FC, memo } from 'react';

type Props = {
  newsletter: ServiceNewsletterType;
  onClose: () => void;
};

export const NewsletterFormComponent: FC<Props> = memo(
  ({ newsletter, onClose }: Props) => (
    <>
      <DrawerBody>
        <VStack spacing={6}>
          <Box
            w="100%"
            borderBottom="1px solid"
            borderColor={Colors.GRAY_30}
            mt={6}
          >
            <Heading as="h3" fontSize="14px" mb="8px">
              メールマガジンID
            </Heading>
            <Text fontSize="16px" pb="8px">
              {newsletter.id}
            </Text>
          </Box>
          <Box w="100%" borderBottom="1px solid" borderColor={Colors.GRAY_30}>
            <Heading as="h3" fontSize="14px" mb="8px">
              表示名
            </Heading>
            <Box pb="8px">
              <Box mb="8px">
                <Text fontSize="12px" color={Colors.GRAY_00} mb="4px">
                  日本語
                </Text>
                <Text fontSize="16px">{newsletter.displayNameJa}</Text>
              </Box>
              <Box>
                <Text fontSize="12px" color={Colors.GRAY_00} mb="4px">
                  外国語
                </Text>
                <Text fontSize="16px">{newsletter.displayNameForeign}</Text>
              </Box>
            </Box>
          </Box>
          <Box w="100%" borderBottom="1px solid" borderColor={Colors.GRAY_30}>
            <Heading as="h3" fontSize="14px" mb="8px">
              フィールド名
            </Heading>
            <Text fontSize="16px" color={Colors.GRAY_03} pb="8px">
              {newsletter.fieldName}
            </Text>
          </Box>
          <Box w="100%" borderBottom="1px solid" borderColor={Colors.GRAY_30}>
            <Heading as="h3" fontSize="14px" mb="8px">
              共通マイページ
            </Heading>
            <Box mb={2}>
              <RhfCheckbox<ServiceNewsletterType>
                name="isDisplayAccountSettings"
                isChecked={!!newsletter.isDisplayAccountSettings}
                isDisabled
              >
                <Text fontSize="14px">マイページで表示する</Text>
              </RhfCheckbox>
            </Box>
          </Box>
          <Box w="100%" borderBottom="1px solid" borderColor={Colors.GRAY_30}>
            <Heading as="h3" fontSize="14px" mb="8px">
              メモ
            </Heading>
            <Text fontSize="16px" color={Colors.GRAY_03} pb="8px">
              {newsletter.memo}
            </Text>
          </Box>
          <Box w="100%">
            <Heading as="h3" fontSize="14px" mb="8px">
              利用設定
            </Heading>
            <Box mb={2}>
              <RhfCheckbox<ServiceNewsletterType>
                name="userPoolChildNewsletter.isDeliveryAvailable"
                isChecked={
                  !!newsletter.userPoolChildNewsletter?.isDeliveryAvailable
                }
                isDisabled
              >
                <Text fontSize="14px">配信で利用する</Text>
              </RhfCheckbox>
            </Box>
            <Box mb={2}>
              <RhfCheckbox<ServiceNewsletterType>
                name="userPoolChildNewsletter.isDisplayWhenRegistration"
                isChecked={
                  !!newsletter.userPoolChildNewsletter
                    ?.isDisplayWhenRegistration
                }
                isDisabled
              >
                <Text fontSize="14px">新規登録時に表示する</Text>
              </RhfCheckbox>
            </Box>
            <Box mb={2} ml={6}>
              <RhfCheckbox<ServiceNewsletterType>
                name="userPoolChildNewsletter.isCheckWhenRegistration"
                isChecked={
                  !!newsletter.userPoolChildNewsletter?.isCheckWhenRegistration
                }
                isDisabled
              >
                <Text fontSize="14px">初期表示でチェック済みにする</Text>
              </RhfCheckbox>
            </Box>
          </Box>
        </VStack>
      </DrawerBody>
      <DrawerFooter borderWidth="1px">
        <Button
          variant="outline"
          mr={3}
          p="16px 24px"
          w="120px"
          onClick={onClose}
          {...ColorSet.Default}
        >
          閉じる
        </Button>
      </DrawerFooter>
    </>
  ),
);
