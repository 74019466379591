import { VFC, memo, useState, useEffect } from 'react';
import { useFormContext } from 'react-hook-form';
import { Box, Flex, Text, Stack, Badge } from '@chakra-ui/react';
import { InputTextForm, ColorPicker } from 'components/common/atoms';
import { SkinItemPreview } from 'components/enquete/atoms/SkinItemPreview';
import { Skin } from 'api/enquete/types';
import { SkinDefaultValues } from 'components/enquete/EnqueteSkin';

export const SkinRequireLabel: VFC = memo(() => {
  const [labelTextColor, setLabelTextColor] = useState(
    SkinDefaultValues.labelTextColor,
  );
  const [labelBgColor, setLabelBgColor] = useState(
    SkinDefaultValues.labelBgColor,
  );
  const { getValues, watch } = useFormContext<Skin>();

  useEffect(() => {
    const skinViewData = watch((value, { name }) => {
      if (name === 'labelTextColor') {
        setLabelTextColor(value[name] || '');
      }
      if (name === 'labelBgColor') {
        setLabelBgColor(value[name] || '');
      }
    });

    return () => skinViewData.unsubscribe();
  }, [watch]);

  return (
    <Flex alignItems="stretch" mb={4} mt={4}>
      <Box minW="220px" mt="2px">
        <Text as="label">必須ラベル</Text>
      </Box>
      <Stack minW="250px">
        <Flex alignItems="flex-end">
          <InputTextForm<Skin>
            label="背景色"
            labelCaption="背景色"
            name="labelBgColor"
            value={getValues('labelBgColor')}
            placeholder="#FFFFFF"
            mr={4}
          />
          <ColorPicker<Skin>
            name="labelBgColor"
            value={getValues('labelBgColor') || '#FFFFFF'}
            width="40px"
            height="40px"
          />
        </Flex>
        <Flex alignItems="flex-end">
          <InputTextForm<Skin>
            label="文字色"
            labelCaption="文字色"
            name="labelTextColor"
            value={getValues('labelTextColor')}
            placeholder="#FFFFFF"
            mr={4}
          />
          <ColorPicker<Skin>
            name="labelTextColor"
            value={getValues('labelTextColor') || '#FFFFFF'}
            width="40px"
            height="40px"
          />
        </Flex>
      </Stack>
      <SkinItemPreview
        element={
          <Badge
            p="2px 5px"
            borderRadius="4px"
            fontSize="12px"
            fontWeight="normal"
            color={labelTextColor}
            bgColor={labelBgColor}
          >
            必須
          </Badge>
        }
        width="auto"
      />
    </Flex>
  );
});
