import { VFC, useCallback, memo } from 'react';
import { useSetRecoilState } from 'recoil';
import { Link } from 'react-router-dom';
import { NodeModel } from '@minoru/react-dnd-treeview';
import { Box, Text, ListIcon, Flex } from '@chakra-ui/react';
import { AiFillFolder, AiFillFolderOpen } from 'react-icons/ai';
import { FiDatabase } from 'react-icons/fi';
import { currentNodeIdSelector } from 'store/treenode';
// =======================================
// 20221110: 削除ボタン削除（念のため残します）
// =======================================
// import { DeleteIcon } from '@chakra-ui/icons';
// import { BsThreeDotsVertical } from 'react-icons/bs';
// import { DropDownMenu } from 'components/common/atoms';
// import { useTableDelete } from 'hooks/contentsdb/useTableDelete';
// import { useUserInfo } from 'hooks/useUserInfo';
// import { isContentsTable } from 'api/contents/types';

// T: NodeModelの data プロパティに格納するデータ型
export type TreeNodeProps<T> = {
  node: NodeModel<T>;
  depth: number;
  isOpen: boolean;
  isSelected: boolean;
  onToggle: (id: NodeModel['id']) => void;
  onSelect: (node: NodeModel<T>) => void;
};

const TreeIcon: VFC<{ isFolder: boolean; isOpen: boolean }> = memo(
  ({ isFolder, isOpen }) => {
    if (!isFolder) {
      return <ListIcon as={FiDatabase} />;
    }

    if (isOpen) {
      return <ListIcon as={AiFillFolderOpen} />;
    }

    return <ListIcon as={AiFillFolder} />;
  },
);

// const isInstanceOf = <T,>(ctor: new (...args: any) => T) =>
//     (x: any): x is T => x instanceof ctor;

// memo化するとジェネリクスを付けて
// 呼び出せなくなるため注意
//
// 以下のように定義できない
// ↓↓↓ T: CustomData の場合 ↓↓↓
// <TreeNode <CustomData> ... />
export const TreeNode = <T,>({
  node,
  depth,
  isOpen,
  isSelected,
  onToggle,
  onSelect,
}: TreeNodeProps<T>): JSX.Element => {
  const setNodeId = useSetRecoilState(currentNodeIdSelector);
  // =======================================
  // 20221110: 削除ボタン削除（念のため残します）
  // =======================================
  // const onDelete = useTableDelete();
  // const { isStaff } = useUserInfo();
  const indent = `${(depth * 4 + 2) * 0.25}rem`;
  const isFolder = !node?.data;
  // =======================================
  // 20221110: 削除ボタン削除（念のため残します）
  // =======================================
  // let tableId = '';
  // let tenantId = '';

  // 20221110: 削除ボタン削除（念のため残す）
  // if (isContentsTable(node?.data)) {
  //   tableId = node?.data.id;
  //   tenantId = node?.data.tenant;
  // }

  const clickHandler = useCallback(() => {
    if (
      isFolder &&
      (typeof node.id === 'string' || typeof node.id === 'number')
    ) {
      onToggle(node.id);
    }
    onSelect(node);
    setNodeId(node.id);
  }, [isFolder, onToggle, onSelect, setNodeId, node]);

  // =======================================
  // 20221110: 削除ボタン削除（念のため残します）
  // =======================================
  // const menuItems = [
  //   {
  //     menuItemType: 'text',
  //     label: '削除',
  //     onClick: () => {
  //       onDelete({ tableId, tenantId });
  //     },
  //     icon: <DeleteIcon />,
  //     testId: 'table-delete',
  //   },
  // ];

  return (
    <Flex
      lineHeight={9}
      as="span"
      onClick={clickHandler}
      data-id={node.id}
      style={{ cursor: 'pointer' }}
      alignItems="center"
      justifyContent="space-between"
    >
      {isFolder ? (
        <Box
          flex={1}
          pl={indent}
          borderRadius={4}
          style={isSelected ? { backgroundColor: '#eee' } : {}}
        >
          <TreeIcon isFolder={isFolder} isOpen={isOpen} />
          <Text as="span">{node.text}</Text>
        </Box>
      ) : (
        <Link to={`${node.id}/`} style={{ width: '100%' }}>
          <Box
            flex={1}
            pl={indent}
            borderRadius={4}
            style={isSelected ? { backgroundColor: '#eee' } : {}}
          >
            <TreeIcon isFolder={isFolder} isOpen={isOpen} />
            <Text as="span">{node.text}</Text>
          </Box>
        </Link>
      )}

      {/* ======================================= */}
      {/* 20221110: 削除ボタン削除（念のため残す） */}
      {/* ======================================= */}
      {/* <Box position="relative" flexBasis={8} width={8}>
        {tableId && tenantId && !isStaff ? (
          <DropDownMenu
            menuType="icon"
            items={menuItems}
            icon={<BsThreeDotsVertical />}
            variant="none"
            width={36}
            zIndex="1"
          />
        ) : null}
      </Box> */}
    </Flex>
  );
};
