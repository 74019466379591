import { zodResolver } from '@hookform/resolvers/zod';
import { withSuspenseAndErrorBoundary } from 'admin/components/Error/WithErrorBoundary';
import { TransactionMailCommonFormComponent } from 'admin/components/idPoolConsumer/transactionMail/transactionMailCommonForm/TransactionMailCommonFormComponent';
import { useCreateTransactionMailCommon } from 'admin/hooks/userPool/transactionMail/useCreateTransactionMailCommon';
import { useTransactionMailAddressList } from 'admin/hooks/userPool/transactionMail/useTransactionMailAddressList';
import { useUpdateTransactionMailCommon } from 'admin/hooks/userPool/transactionMail/useUpdateTransactionMailCommon';
import { TransactionMailCommonSubmitSchema } from 'admin/schema/idPoolConsumer/transactionMail';
import { TransactionMailCommonType } from 'admin/types/userPool/transactionMail';
import {
  AlertBar,
  ErrorContents,
  LoadingSpinner,
} from 'components/common/atoms';
import { FC, memo, useMemo, useState } from 'react';
import { FormProvider, SubmitHandler, useForm } from 'react-hook-form';

type Props = {
  formId: string;
  onClose: () => void;
  mailCommon: TransactionMailCommonType;
};

export const transactionMailCommonForm: FC<Props> = memo(
  ({ formId, onClose, mailCommon }: Props) => {
    const isEdit = useMemo(() => !!mailCommon.id, [mailCommon]);
    const [globalErrors, setGlobalErrors] = useState<string[]>([]);
    const { transactionMailFromAddressList, transactionMailReplyAddressList } =
      useTransactionMailAddressList();
    const methods = useForm<TransactionMailCommonType>({
      defaultValues: {
        id: mailCommon.id || '',
        fromAddress: mailCommon.fromAddress,
        replyAddress: mailCommon.replyAddress,
      },
      mode: 'onBlur',
      resolver: zodResolver(TransactionMailCommonSubmitSchema),
    });
    const { handleSubmit, setError } = methods;
    const { mutate: createMutate, isLoading: createIsLoading } =
      useCreateTransactionMailCommon({ setError, setGlobalErrors });
    const { mutate: updateMutate, isLoading: updateIsLoading } =
      useUpdateTransactionMailCommon({ setError, setGlobalErrors });

    const onSubmit: SubmitHandler<TransactionMailCommonType> = async (
      data: TransactionMailCommonType,
    ) => {
      if (isEdit) {
        await updateMutate({
          ...data,
          fromAddress: data.fromAddress,
          replyAddress: data.replyAddress,
        });
      } else {
        await createMutate({
          fromAddress: data.fromAddress,
          replyAddress: data.replyAddress,
        });
      }
      onClose();
    };

    return (
      <FormProvider {...methods}>
        {globalErrors.map((err, idx) => (
          <AlertBar
            key={`global-err-idx${String(idx)}`}
            message={err}
            status="error"
            mb={4}
          />
        ))}
        <TransactionMailCommonFormComponent
          formId={formId}
          onClose={onClose}
          isLoading={createIsLoading || updateIsLoading}
          fromAddressList={transactionMailFromAddressList}
          replyAddressList={transactionMailReplyAddressList}
          onSubmit={onSubmit}
          handleSubmit={handleSubmit}
        />
      </FormProvider>
    );
  },
);

export const TransactionMailCommonForm = withSuspenseAndErrorBoundary(
  transactionMailCommonForm,
  {
    ErrorComponent: (
      <ErrorContents name="トランザクションメール共通設定ドロワーフォーム" />
    ),
    LoadingComponent: <LoadingSpinner />,
  },
);
