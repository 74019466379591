import {
  Alert,
  AlertDescription,
  AlertTitle,
  Box,
  Button,
  FormControl,
  FormLabel,
  Input,
  Modal,
  ModalContent,
  ModalBody,
  ModalFooter,
  ModalHeader,
  ModalOverlay,
  Text,
} from '@chakra-ui/react';
import { WarningTwoIcon } from '@chakra-ui/icons';
import { useDeleteAuthHub } from 'admin/hooks/authHub/useDeleteAuthHub';
import { AuthHubsResultType } from 'admin/types/authHub';
import { useCallback, FC } from 'react';
import { QueryObserverResult } from 'react-query';
import { FormProvider, useForm } from 'react-hook-form';

type ConfirmForm = {
  confirm: string;
};

type Props = {
  userPoolId?: string;
  tenantId?: string;
  deleteTarget?: [string, string];
  setDeleteTarget: (_?: [string, string]) => void;
  refetch: () => Promise<QueryObserverResult<AuthHubsResultType, unknown>>;
};

export const DeleteConfirmModal: FC<Props> = ({
  userPoolId,
  tenantId,
  deleteTarget,
  setDeleteTarget,
  refetch,
}) => {
  const form = useForm<ConfirmForm>({
    mode: 'all',
  });

  const [deleteTargetId, authHubName] = (deleteTarget && deleteTarget) || [
    undefined,
    undefined,
  ];
  const onClose = useCallback(() => {
    form.reset();
    setDeleteTarget(undefined);
  }, [form, setDeleteTarget]);
  const { deleteMutate } = useDeleteAuthHub();
  const onDelete = useCallback(async () => {
    if ((userPoolId || tenantId) && deleteTargetId) {
      form.reset();
      setDeleteTarget(undefined);
      const id = deleteTargetId;
      await deleteMutate({ userPoolId, tenantId, id });
      await refetch();
    }
  }, [
    userPoolId,
    deleteTargetId,
    deleteMutate,
    refetch,
    setDeleteTarget,
    tenantId,
    form,
  ]);

  return (
    <Modal isOpen={!!deleteTargetId} size="lg" onClose={onClose} isCentered>
      <ModalOverlay />
      <ModalContent bg="White">
        <ModalHeader>Auth Hub設定 削除の確認</ModalHeader>
        <ModalBody>
          <Alert status="error" mb={4} borderRadius="md">
            <AlertTitle>
              <WarningTwoIcon />
            </AlertTitle>
            <AlertDescription>
              一度実行すると元の状態に戻せないため注意してください
            </AlertDescription>
          </Alert>
          <Box mb={4}>
            {deleteTargetId && authHubName && (
              <Text>
                <Text as="b">
                  {authHubName}（{deleteTargetId}）
                </Text>
                の削除を実行しますか？
              </Text>
            )}
            <Text color="red.500" as="b">
              この手続きは取り消しすることが出来ません。
            </Text>
          </Box>
          <FormProvider {...form}>
            <FormControl>
              <FormLabel>
                <Text as="b">
                  削除する場合は「delete」と入力後に「削除」ボタンを押下してください。
                </Text>
              </FormLabel>
              <Input
                {...form.register('confirm', {
                  validate: (value) => value === 'delete',
                })}
              />
            </FormControl>
          </FormProvider>
        </ModalBody>
        <ModalFooter>
          <Button mr={3} onClick={onClose} variant="outline">
            <Text>キャンセル</Text>
          </Button>
          <Button
            bg="red.500"
            color="white"
            onClick={onDelete}
            disabled={!form.formState.isValid}
          >
            <Text>削除</Text>
          </Button>
        </ModalFooter>
      </ModalContent>
    </Modal>
  );
};
