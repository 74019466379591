import { useCreateTransactionMail } from 'admin/hooks/userPool/transactionMail/useCreateTransactionMail';
import { useEditTransactionMail } from 'admin/hooks/userPool/transactionMail/useEditTransactionMail';
import { TransactionMailFormType } from 'admin/types/userPool/transactionMail';
import {
  TransactionMailCreateFormType,
  TransactionMailEditFormType,
} from 'admin/types/userPool/transactionMail/form';
import { useCustomToast } from 'hooks/useCustomToast';
import { useEmailEditor } from 'hooks/useEmailEditor';
import {
  Dispatch,
  RefObject,
  SetStateAction,
  useCallback,
  useEffect,
  useMemo,
} from 'react';
import EmailEditor, { Design } from 'react-email-editor';
import {
  UseFormGetValues,
  UseFormSetError,
  UseFormSetValue,
} from 'react-hook-form';
import { useParams } from 'react-router-dom';
import { errorToast, successToast } from 'utils/toast';

type Props = {
  editor: RefObject<EmailEditor>;
  getValues: UseFormGetValues<TransactionMailFormType>;
  setValue: UseFormSetValue<TransactionMailFormType>;
  setLoading?: Dispatch<SetStateAction<boolean>>;
  setError?: UseFormSetError<TransactionMailFormType>;
};

export const useTransactionMailEditor = ({
  editor,
  getValues,
  setValue,
  setLoading,
  setError,
}: Props): {
  init: (TransactionMail?: TransactionMailFormType) => void;
  handleLoad: ({
    setIsLoading,
  }: {
    setIsLoading: (bool: boolean) => void;
  }) => void;
  saveEditor: () => void;
} => {
  const params = useParams();
  const TransactionMailId = useMemo(
    () => params?.transactionMailId ?? '',
    [params],
  );
  const toast = useCustomToast();
  const {
    exportHtml,
    loadDesign,
    editorReady,
    defaultDesignValue,
    imageUploadFinish,
  } = useEmailEditor({ editor });
  const { mutate: onCreateSubmit, isLoading: isCreateLoading } =
    useCreateTransactionMail({
      setError,
    });
  const { mutate: onEditSubmit, isLoading: isEditLoading } =
    useEditTransactionMail({
      setError,
    });

  useEffect(() => {
    setLoading?.(isCreateLoading || isEditLoading);
  }, [isCreateLoading, isEditLoading, setLoading]);

  /**
   * 初期処理
   */
  const init = (TransactionMail?: TransactionMailFormType) => {
    setValue('id', TransactionMail?.id || '');
    setValue('settingTitle', TransactionMail?.settingTitle || '');
    setValue('subject', TransactionMail?.subject || '');
    setValue('status', TransactionMail?.status || false);
    setValue('bodyHtml', TransactionMail?.bodyHtml || '');
    setValue('bodyText', TransactionMail?.bodyText || '');
    setValue('editorHtml', TransactionMail?.editorHtml || '');
    setValue('editorText', TransactionMail?.editorText || '');
  };

  const handleLoad = useCallback(
    async ({ setIsLoading }: { setIsLoading: (bool: boolean) => void }) => {
      const editorText = getValues('editorHtml');

      if (!editorText) {
        loadDesign(defaultDesignValue);
      } else {
        const editorDesignData = JSON.parse(editorText) as Design;
        Object.assign(defaultDesignValue, { body: editorDesignData });
        loadDesign(defaultDesignValue);
      }
      await editorReady().then(({ isReady }) => {
        setIsLoading(isReady);
      });
      await imageUploadFinish().then(({ message }) => {
        toast({
          ...successToast,
          title: message,
        });
      });
    },
    [
      imageUploadFinish,
      toast,
      defaultDesignValue,
      getValues,
      loadDesign,
      editorReady,
    ],
  );

  const saveEditor = useCallback(async () => {
    setLoading?.(true);
    await exportHtml()
      .then(async ({ design, html }) => {
        setValue('bodyHtml', html);
        setValue('editorHtml', JSON.stringify(design.body));

        let errorMsg = '';
        if (getValues('subject') === '') {
          errorMsg = 'メールタイトルが未入力です。';
        } else if (getValues('settingTitle') === '') {
          errorMsg = '設定名が未入力です。';
        } else if (getValues('editorText') === '') {
          errorMsg = 'テキストメールの本文が未入力です。記入お願いします。';
        }
        if (errorMsg !== '') {
          toast({
            ...errorToast,
            description: errorMsg,
            duration: 4000,
          });
          setLoading?.(false);

          return;
        }

        if (!TransactionMailId) {
          const submitData: TransactionMailCreateFormType = {
            type: getValues('type'),
            settingTitle: getValues('settingTitle'),
            subject: getValues('subject'),
            status: getValues('status'),
            bodyHtml: getValues('bodyHtml'),
            bodyText: getValues('editorText'),
            editorHtml: getValues('editorHtml'),
            editorText: getValues('editorText'),
          };

          await onCreateSubmit(submitData);
        } else {
          const submitData: TransactionMailEditFormType = {
            id: getValues('id'),
            type: getValues('type'),
            settingTitle: getValues('settingTitle'),
            subject: getValues('subject'),
            status: getValues('status'),
            bodyHtml: getValues('bodyHtml'),
            bodyText: getValues('editorText'),
            editorHtml: getValues('editorHtml'),
            editorText: getValues('editorText'),
          };

          await onEditSubmit(submitData);
        }
      })
      .catch((err) => {
        if (err instanceof Error) {
          toast.closeAll();
          toast({
            ...errorToast,
            title: 'HTMLエディタエラー',
            description: err.message,
          });
        }
      });
  }, [
    exportHtml,
    setValue,
    getValues,
    onCreateSubmit,
    onEditSubmit,
    setLoading,
    TransactionMailId,
    toast,
  ]);

  return {
    init,
    handleLoad,
    saveEditor,
  };
};
