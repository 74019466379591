import { Box, Divider, Flex, Heading, Stack, Text } from '@chakra-ui/react';
import { SettingMaskingText } from 'admin/components/saml/settingMaskingText';
import { Colors } from 'admin/define/colors';
import { SamlSettingType } from 'admin/types/saml/index';
import { Copy } from 'components/common/atoms';
import { useCopy } from 'hooks/useCopy';
import { FC, memo } from 'react';

type Props = {
  samlSetting: SamlSettingType;
};

export const SamlSettingListComponent: FC<Props> = memo(
  ({ samlSetting }) => {
    const { copy } = useCopy();
    const displayText = (txt: string) => txt && txt !== "" ? txt : "-";

    return (
      <Box bg="white" mt="16px" p="24px" borderRadius={4}>
        <Stack spacing="32px" divider={<Divider />}>
          <Box w="100%">
            <Heading as="h3" size="sm" mb="16px">
              対象ドメイン
            </Heading>
            <Box p="8px 16px" bgColor={Colors.GRAY_70} borderRadius="8px">
              {samlSetting.domains.length > 0 && samlSetting.domains.map((item) => (
                <Text fontSize="16px" key={`domain-name${item}`}>{item}</Text>
              ))}
              {samlSetting.domains.length === 0 && (
                <Text>-</Text>
              )}
            </Box>
          </Box>
          <Box w="100%">
            <Heading as="h3" size="sm" mb="16px">
              ログインURL
            </Heading>
            <Box p="8px 16px" bgColor={Colors.GRAY_70} borderRadius="8px">
              <Text fontSize="16px">{displayText(samlSetting.singleSignOnUrl)}</Text>
            </Box>
          </Box>
          <Box w="100%">
            <Heading as="h3" size="sm" mb="16px">
              識別子URL
            </Heading>
            <Box p="8px 16px" bgColor={Colors.GRAY_70} borderRadius="8px">
              <Text fontSize="16px">{displayText(samlSetting.entityId)}</Text>
            </Box>
          </Box>
          <Box w="100%">
            <Heading as="h3" size="sm" mb="16px">
              X.509 証明書
            </Heading>
            <Box p="8px 16px" bgColor={Colors.GRAY_70} borderRadius="8px">
            {samlSetting.responseCertificate ? (
                <SettingMaskingText text={samlSetting.responseCertificate} />
              ) : (
                <Text>-</Text>
              )
            }
            </Box>
          </Box>
          <Box w="100%">
            <Heading as="h3" size="sm" mb="16px">
              Azure管理画面設定に必要な情報
            </Heading>
            <Text color={Colors.GRAY_00}>以下の情報はAzure管理画面での設定に必要な情報となります。それぞれをコピーして使用してください。</Text>
            <Stack mt="16px" spacing="16px">
              <Box w="100%" p="8px 16px" bgColor={Colors.GRAY_70} borderRadius="8px">
                <Flex alignItems="center" justifyContent="space-between">
                  <Box>
                    <Heading as="h4" fontSize="14px">
                      識別子（エンティティID）
                    </Heading>
                    <Text fontSize="16px">{displayText(samlSetting?.info?.entityUrl || '')}</Text>
                  </Box>
                  {samlSetting?.info?.entityUrl && (
                    <Copy
                      label="識別子コピー"
                      onCopy={() => copy(samlSetting?.info?.entityUrl || '')}
                    />
                  )}
                </Flex>
              </Box>
              <Box w="100%" p="8px 16px" bgColor={Colors.GRAY_70} borderRadius="8px">
                <Flex alignItems="center" justifyContent="space-between">
                  <Box>
                    <Heading as="h4" fontSize="14px">
                      応答URL（Assertion Consumer Service URL）
                    </Heading>
                    <Text fontSize="16px">{displayText(samlSetting?.info?.assertionUrl || '')}</Text>
                  </Box>
                  {samlSetting?.info?.assertionUrl && (
                    <Copy
                      label="応答URLコピー"
                      onCopy={() => copy(samlSetting?.info?.assertionUrl || '')}
                    />
                  )}
                </Flex>
              </Box>
            </Stack>
          </Box>
        </Stack>
      </Box>
    )
  },
);
