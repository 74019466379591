import { getGmoInfo } from 'api/tenant/getGmoInfo';
import { GmoInfo } from 'api/tenant/types';
import { gmoInfoDefaultData } from 'components/setting/paymentAgency/initial';
import { useCustomToast } from 'hooks/useCustomToast';
import { useQuery } from 'react-query';
import { queryTenantKey } from './queryTenantKey';

/**
 * Gmo情報取得 hooks
 */
export const useGmoInfo = ({
  tenantId,
}: {
  tenantId: string;
}): GmoInfo => {
  const toast = useCustomToast();
  const response = useQuery(queryTenantKey.gmoInfo, () => getGmoInfo(tenantId), {
    onError: (err: unknown) => {
      if (err instanceof Error) {
        toast.closeAll();
        toast({
          title: err.message,
          status: 'error',
        });
      }
    },
  });
  const { data: gmoInfoData } = response;

  return gmoInfoData || gmoInfoDefaultData;
};
