import { Box, Input } from '@chakra-ui/react';
import { useRef, VFC } from 'react';
import {
  FieldValues, Path,
  PathValue, UnpackNestedValue, useController, useFormContext
} from 'react-hook-form';

type ColorPickerProps<T> = {
  name: Path<T>;
  value: UnpackNestedValue<PathValue<T, Path<T>>>;
  isDisabled?: boolean;
  hidden?: boolean;
  width?: string;
  height?: string;
  onChangeColor?: (color: string) => void;
};

export const ColorPicker = <T extends FieldValues>({
  name,
  value,
  isDisabled = false,
  hidden = false,
  width = '94px',
  height = '30px',
  onChangeColor = undefined,
}: ColorPickerProps<T>): ReturnType<VFC> => {
  const inputColor = useRef<HTMLInputElement>(null);
  const { control, getValues } = useFormContext<T>();

  const {
    field: { value: formValue, onChange, ...formRest },
  } = useController({ name, control, defaultValue: value });

  /**
   * 「親コンポーネントから渡した処理」と元々の「見出し背景色の色変更処理」を併せ持つ
   */
  const onChangeMerged = (e: React.ChangeEvent<HTMLInputElement>) => {
    onChange(e);
    if (onChangeColor !== undefined) {
      onChangeColor(e.target.value);
    }
  };

  return (
    <Box position="relative" display={hidden ? 'none' : 'block'}>
      <Box
        w={width}
        h={height}
        border="1px solid #E2E8F0"
        position="relative"
        zIndex="2"
        bg={getValues(name) || '#FFFFFF'}
        cursor={isDisabled ? 'not-allowed' : 'pointer'}
        onClick={() => inputColor.current?.click()}
      />
      <Input
        type="color"
        bg="none"
        border="none"
        p={0}
        w="10px"
        h="100%"
        position="absolute"
        bottom="0"
        zIndex="1"
        opacity="0"
        value={formValue as string}
        isDisabled={isDisabled}
        hidden={hidden}
        {...formRest}
        ref={inputColor}
        onChange={onChangeMerged}
      />
    </Box>
  );
};
