import { TableContainer as ChakraTableContainer } from '@chakra-ui/react';
import { Colors } from 'components/paywall/config';
import { createContext, Dispatch, FC, memo, ReactNode, SetStateAction, useContext, useEffect, useRef, useState } from 'react';

interface ScrollContextType {
  scrollLeftValue: number;
  setScrollLeftValue: Dispatch<SetStateAction<number>>;
}

const ScrollContext = createContext<ScrollContextType | undefined>(undefined);

export const useScrollContext = (): ScrollContextType => {
  const context = useContext(ScrollContext);
  if (!context) {
    throw new Error('useScrollContext must be used within a ScrollContextProvider');
  }

  return context;
};


type Props = {
  children: ReactNode
}

export const TableContainer: FC<Props> = memo(({children}: Props) => {
  const scrollRef = useRef<HTMLDivElement>(null);
  const [scrollLeftValue, setScrollLeftValue] = useState(0);

  useEffect(() => {
    const scrollElement = scrollRef.current;
    if (scrollElement) {
      const handleScroll = () => {
        const { scrollLeft } = scrollElement;
        setScrollLeftValue(scrollLeft);
      };
      scrollElement.addEventListener('scroll', handleScroll);

      return () => {
        scrollElement.removeEventListener('scroll', handleScroll);
      };
    }

    // `scrollElement`が存在しない場合のために値を返す
    return undefined;
  }, []);


  return (
    <ScrollContext.Provider value={{ scrollLeftValue, setScrollLeftValue }}>
      <ChakraTableContainer
        ref={scrollRef}
        maxH="calc(100vh - 281px)"
        overflowY="auto"
        borderWidth="1px"
        borderStyle="solid"
        borderColor={Colors.GRAY_70}
      >
        {/* 横スクロールする要素のコンテンツ */}
        {children}
      </ChakraTableContainer>
    </ScrollContext.Provider>
  );
})
