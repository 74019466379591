import { WallItemDefaultValues } from 'components/paywall/pages/WallItemList/initial';
import { WallItemFormType } from 'components/paywall/pages/WallItemList/typed';
import { useAddWallItem } from 'hooks/paywall/useAddWallItem';
import { useEditWallItem } from 'hooks/paywall/useEditWallItem';
import { useUserTenantId } from 'hooks/user/useUserTenantId';
import { Dispatch, SetStateAction, useCallback, useEffect } from 'react';
import { UseFormGetValues, UseFormSetError } from 'react-hook-form';
import { useParams } from 'react-router-dom';

type DartyType = {
  [key in "price" | "sellType" | "period"]: boolean;
}

type Props = {
  getValues?: UseFormGetValues<WallItemFormType>;
  isAdd?: boolean;
  setError?: UseFormSetError<WallItemFormType>;
  onClose?: () => void;
  reset?: () => void;
  setLoading?: Dispatch<SetStateAction<boolean>>;
  setIsDarty: Dispatch<SetStateAction<DartyType>>;
};

/**
 * ペイウォール単品商品hooks
 * @param {UseFormGetValues<WallItemFormType>} getValues WallItemFormType型 react query getValues
 * @param {boolean} isAdd 新規作成or編集
 */
export const usePaywallItem = ({
  getValues,
  isAdd,
  setError,
  onClose,
  reset,
  setLoading,
  setIsDarty,
}: Props) => {
  const tenantId = useUserTenantId();
  const params = useParams();
  const { onSubmit: addWallItemSubmit, isLoading: isAddLoading } = useAddWallItem({
    setError,
    close: onClose,
    reset,
  });
  const { onSubmit: editWallItemSubmit, isLoading: isEditLoading } = useEditWallItem({
    setError,
    close: onClose,
    reset,
    setIsDarty
  });

  useEffect(() => {
    setLoading?.(isAddLoading || isEditLoading)
  }, [setLoading, isAddLoading, isEditLoading])

  /**
   * 単品商品新規作成：編集
   * @param {function(): void} onClose close Function
   * @param {function(): void} reset reset Function
   */
  const saveWallItem = useCallback(() => {
    setLoading?.(true);
    const fdata: WallItemFormType = {
      ...(getValues?.() || WallItemDefaultValues)
    };
    // OGPフラグが有効の場合、設定済みの説明文書、サムネイル画像は削除する
    if(fdata.useOgpFlag) {
      fdata.description = '';
      fdata.thumbnailUrl = '';
    }
    // 新規作成時
    if (isAdd) {
      addWallItemSubmit({
        tenantId,
        wallId: params.wallId || '',
        wallItemForm: fdata,
      });
    } else {
      editWallItemSubmit({
        tenantId,
        wallId: params.wallId || '',
        wallItemForm: fdata,
      });
    }
  }, [
    getValues,
    addWallItemSubmit,
    editWallItemSubmit,
    setLoading,
    isAdd,
    tenantId,
    params,
  ]);

  const onDrawerClose = useCallback(() => {
    onClose?.();
    reset?.();
  }, [onClose, reset]);

  return { saveWallItem, onDrawerClose };
};
