import { editTerm } from 'admin/api/userPool/terms/editTerm';
import { CustomError } from 'admin/error/CustomError';
import { useUserPoolId } from 'admin/hooks/params/useUserPoolId';
import { queryKeyNames as serviceQueryKeyNames } from 'admin/hooks/service/terms/queryKeys';
import { TermType } from 'admin/types/userPool/terms';
import { logger } from 'api/logger';
import { useCustomToast } from 'hooks/useCustomToast';
import { useMutation, useQueryClient } from 'react-query';
import { errorToast, successToast } from 'utils/toast';
import { queryKeyNames } from './queryKeys';

export const useChangeAgreeTerm = (id: TermType['id']) => {
  const toast = useCustomToast();
  const queryClient = useQueryClient();
  const userPoolId = useUserPoolId();

  const { mutateAsync, isLoading } = useMutation({
    mutationFn: (version: TermType['version']) =>
      editTerm({
        id,
        userPoolId,
        data: {
          consentRequiredVersion: version,
        },
      }),
    onError: async (error) => {
      if (error instanceof CustomError) {
        await logger({
          loglevel: error.logLevel,
          data: JSON.stringify(error.cause),
          message: error.message,
        });
      }
      toast({
        ...errorToast,
        duration: 4000,
        title: '現バージョンへの同意を必須にするのに失敗しました',
        position: 'bottom',
        variant: 'solid',
      });
    },
    onSuccess: () => {
      toast({
        ...successToast,
        duration: 4000,
        title: '現バージョンへの同意を必須にしました',
        position: 'bottom',
        variant: 'solid',
      });
      void queryClient.invalidateQueries(queryKeyNames.getTerms);
      void queryClient.invalidateQueries(queryKeyNames.getLogs);
      void queryClient.invalidateQueries(serviceQueryKeyNames.getServiceTerms);
    },
  });

  return { mutate: mutateAsync, isLoading };
};
