import { zodResolver } from '@hookform/resolvers/zod';
import { useCreateUserPool } from 'admin/hooks/userPool/useCreateUserPool';
import { confirmIdPoolConsumerFormSchema } from 'admin/schema/idPoolConsumer';
import { CreateConfirmIdPoolConsumerFormType, CreateIdPoolConsumerFormType } from 'admin/types/userPool/form';
import { AlertBar } from 'components/common/atoms';
import { AUTH_CODE_TYPE_OUTER, AUTH_CODE_TYPE_UNIIKEY } from 'define';
import { FC, memo, useState } from 'react';
import { FormProvider, SubmitHandler, useForm, UseFormSetError } from 'react-hook-form';
import { ConfirmFormComponent } from './ConfirmFormComponent';

type Props = {
  idPoolConsumer: CreateIdPoolConsumerFormType;
  setError: UseFormSetError<CreateIdPoolConsumerFormType>,
  onClose: () => void;
  onBack: () => void;
};

export const IdPoolConsumerConfirmForm: FC<Props> = memo(
  ({ idPoolConsumer, setError, onClose, onBack }: Props) => {
    const [globalErrors, setGlobalErrors] = useState<string[]>([]);
    const methods = useForm<CreateConfirmIdPoolConsumerFormType>({
      defaultValues: {
        word: '',
      },
      mode: 'onBlur',
      resolver: zodResolver(confirmIdPoolConsumerFormSchema),
    })

    const { mutate, isLoading } = useCreateUserPool(
      setError,
      setGlobalErrors,
      onBack,
    );

    const onSubmit: SubmitHandler<CreateConfirmIdPoolConsumerFormType> = async () => {
      const submitData: CreateIdPoolConsumerFormType = {
        ...idPoolConsumer,
        authenticationType: Number(idPoolConsumer.authenticationType),
        isSelfRegistration: Number(idPoolConsumer.authenticationType) === AUTH_CODE_TYPE_UNIIKEY
      }

      // 認証タイプが 外部連携:5 の場合はfieldNameをオブジェクトから除外する
      if (Number(submitData.authenticationType) === AUTH_CODE_TYPE_OUTER) {
        if (!submitData.fieldName) {
          delete submitData.fieldName
        }
      }

      await mutate(submitData);
      onClose();
    };

    return (
      <FormProvider {...methods}>
        {globalErrors.map((err, idx) => (
          <AlertBar
            key={`global-err-idx${String(idx)}`}
            message={err}
            status="error"
            mb={4}
          />
        ))}
        <ConfirmFormComponent
          idPoolConsumer={idPoolConsumer}
          onSubmit={onSubmit}
          onBack={onBack}
          isLoading={isLoading}
        />
      </FormProvider>
    );
  },
);
