import { ResponseError } from 'api/types';
import { postInvite } from 'api/user/postInvite';
import {
  isRegistInviteUser,
  RegistInviteUser,
  RegistInviteUserForm,
} from 'api/user/types';
import { useCustomToast } from 'hooks/useCustomToast';
import { UseFormSetError } from 'react-hook-form';
import { UseMutateFunction, useMutation } from 'react-query';
import { isGetValidationError, toMultiError } from 'utils/form';

type UnPromisify<T> = T extends Promise<infer U> ? U : T;

const invitationUserData: RegistInviteUserForm = {
  emailTo: '',
  tenant: '',
  permission: 0,
  groups: [],
};

/**
 * ユーザー招待登録hooks
 * @param {Object} UseFormSetError setError
 * @param {setGlobalErrors} callback setGlobalErrors
 * @returns {Object} { onSubmit, isLoading }
 */
export const useRegistUserInvitation = ({
  setError,
  setGlobalErrors,
}: {
  setError: UseFormSetError<RegistInviteUserForm>;
  setGlobalErrors: (errors: string[]) => void;
}): {
  onSubmit: UseMutateFunction<
    UnPromisify<ReturnType<typeof postInvite>>,
    unknown,
    RegistInviteUserForm,
    unknown
  >;
  isLoading: boolean;
  isSuccess: boolean;
} => {
  const toast = useCustomToast();

  const {
    mutate: onSubmit,
    isLoading,
    isSuccess,
  } = useMutation(
    ({ emailTo, tenant, permission, groups }: RegistInviteUserForm) =>
      postInvite({ emailTo, tenant, permission, groups }),
    {
      onSuccess: (result: RegistInviteUser | boolean | ResponseError) => {
        // グローバルエラーを初期化
        setGlobalErrors([]);
        if (isRegistInviteUser(result)) {
          toast({
            status: 'success',
            position: 'bottom',
            duration: 2000,
            isClosable: true,
            title: '送信しました',
          });

          return;
        }

        if (
          isGetValidationError<RegistInviteUserForm>(
            result,
            Object.keys(invitationUserData),
          )
        ) {
          if (result?.others) {
            setGlobalErrors(result?.others);
          } else {
            Object.keys(result).forEach((k) => {
              const key = k as keyof RegistInviteUserForm;
              const errMsgs = result?.[key];
              if (errMsgs) {
                setError(key, { types: toMultiError(errMsgs) });
              }
            });
          }
        }
      },
    },
  );

  return { onSubmit, isLoading, isSuccess };
};
