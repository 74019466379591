import { Box, Text, useDisclosure } from '@chakra-ui/react';
import { fetchApiCustomerMemo } from 'api/paywall/useApi';
import { Colors, ColorSet } from 'components/paywall/config';
import { DetailDialog } from 'components/paywall/features/Detail';
import { usePaywallUtil } from 'hooks/paywall/usePaywallUtil';
import { useCustomToast } from 'hooks/useCustomToast';
import { useUserTenantId } from 'hooks/user/useUserTenantId';
import { FC, memo, useCallback } from 'react';
import { errorToast } from 'utils/toast';

type Props = {
  orderId: string;
  customerUid: string;
  memo: string;
  setMemoTxt: React.Dispatch<React.SetStateAction<string>>
  type: 'subscription' | 'order' | 'customer';
}

export const DetailMemoDialog: FC<Props> = memo(({orderId, customerUid, memo: memoTxt, setMemoTxt, type}: Props) => {
  const { isOpen, onOpen, onClose } = useDisclosure();
  const tenantId = useUserTenantId();
  const toast = useCustomToast();
  const { paywalSubscriptionDetailRefetchQueries, paywallOrderDetailRefetchQueries, paywallCustomerDetailRefetchQueries } = usePaywallUtil();

  // closeボタン押下時
  const closeBtnHandler = useCallback(() => {
    onClose();
    setMemoTxt('');
  }, [
    onClose,
    setMemoTxt
  ]);

  // 編集メモ 保存ボタン押下処理
  const onSubmit = useCallback(() => {
    if(memoTxt !== '') {
      void (async () => {
        await fetchApiCustomerMemo(
          tenantId,
          customerUid,
          orderId,
          memoTxt,
        );
        if(type === 'subscription') {
          paywalSubscriptionDetailRefetchQueries(orderId);
        } else if(type === 'order') {
          paywallOrderDetailRefetchQueries(orderId);
        } else {
          paywallCustomerDetailRefetchQueries(customerUid);
        }
        toast({
          title: 'メモを保存しました',
          status: 'success',
          duration: 3000,
          isClosable: true,
        });
      })();
      closeBtnHandler();

      return;
    }

    toast({
      ...errorToast,
      title: 'メモを入力してください',
      duration: 3000,
    });
    closeBtnHandler();
  }, [
    toast,
    memoTxt,
    tenantId,
    customerUid,
    orderId,
    type,
    closeBtnHandler,
    paywalSubscriptionDetailRefetchQueries,
    paywallOrderDetailRefetchQueries,
    paywallCustomerDetailRefetchQueries
  ]);

  return (
    <DetailDialog
      title="メモの保存"
      isOpen={isOpen}
      onOpen={onOpen}
      onClose={onClose}
      openBtnElement="保存"
      openBtnProps={{
        fontSize: '14px',
        minWidth: '100px',
        ...ColorSet.BLUE
      }}
      submitBtnTitle="保存"
      submitBtnProps={{
        minW: '100px',
        ...ColorSet.BLUE
      }}
      submitBtnHandelr={onSubmit}
      cancelBtnTitle="キャンセル"
      cancelBtnProps={{
        minW: '100px',
      }}
    >
      <Box mb={4}>
        <Text fontSize="14px" fontWeight="400">
          {orderId && (
            <>
              <Text as="span" fontWeight="700" mr={1}>{ orderId || '' }</Text>の
            </>
          )}
          メモを保存しますか？
        </Text>
        <Text as="span" fontSize="14px" borderBottom={`1px solid ${Colors.RED_20}`}>保存後の編集や削除は出来ません。</Text>
      </Box>
    </DetailDialog>
  )
});
