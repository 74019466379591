import { Box } from '@chakra-ui/react';
import { Pagination } from 'components/paywall/features/Table/TableNavigation/Pagination';
import { Select } from 'components/paywall/features/Table/TableNavigation/Select'; // Selectは暫定版
import {
  StyledFlex,
  StyledFlexEnd,
  StyledTableNavigation
} from 'components/paywall/styled/global';
import { FC, memo } from 'react';
import { useTableNavigation } from './hooks/useTableNavigation';

type Props = {
  hasPagination?: boolean;
  hasPageDisplay?: boolean;
  primary?: string;
  secondary?: string;
};

type PaginationPerPage = {
  val: number,
  name: string,
}
// 1ページあたりの表示件数プルダウン用リスト
const perPageList: PaginationPerPage[] = [
  { val: 10, name: '10件' },
  { val: 20, name: '20件' },
  { val: 50, name: '50件' }
]

/**
 * @see テーブルのページネーションと表示件数ナビゲーション
 * @see テーブルでなくても配列に対して使用可能
 * @param hasPagination ページネーションの有無
 * @param hasPageDisplay 表示件数の有無
 * @param count 表示する項目の総数
 * @param perPage 現在の表示件数
 * @param page 現在のページ
 * @param primary プライマリーカラー
 * @param secondary セカンダリーカラー
 * @param handlePagination ページングを行うメソッド
 * @param handlePerPage 表示件数を変更するメソッド
 */
export const TableNavigation: FC<Props> = memo(
  ({
    hasPagination = true,
    hasPageDisplay = true,
    primary = 'blue',
    secondary = 'gray',
  }) => {
    const { count, page, perPage, handlePagination, handlePerPage } =
      useTableNavigation();

    return (
      <StyledTableNavigation>
        <StyledFlex style={{ width: '33%' }} />
        {hasPagination ? (
          <Box style={{ textAlign: 'center', width: '33%' }}>
            <Pagination
              count={count}
              perPage={perPage}
              page={page}
              primary={primary}
              secondary={secondary}
              onChange={handlePagination}
            />
          </Box>
        ) : (
          <StyledFlex />
        )}
        {hasPageDisplay ? (
          <StyledFlexEnd style={{ textAlign: 'right' }}>
            <Select
              defaultValue={perPage}
              style={{ width: 200 }}
              array={perPageList}
              onChange={(e) => {
                handlePerPage(e);
              }}
            />
          </StyledFlexEnd>
        ) : (
          <StyledFlex />
        )}
      </StyledTableNavigation>
    );
  },
);
