import { Box, Tr as ChakraTr } from '@chakra-ui/react';
import { MemberListTableBody } from 'admin/components/member/memberListTableBody';
import { Table, Tbody, Th, Thead } from 'admin/components/ui/table';
import { MembersType } from 'admin/types/member';
import { FC, memo } from 'react';

type Props = {
  members: MembersType;
};

export const MemberListTableComponent: FC<Props> = memo(
  ({ members }: Props) => (
    <Box>
      <Table tableHeight="100%">
        <Thead>
          <ChakraTr>
            <Th p={4} fontSize="14px" maxWidth="130px" minWidth="130px">
              会員ID
            </Th>
            <Th p={4} fontSize="14px" maxWidth="180px" minWidth="180px">
              メールアドレス
            </Th>
            <Th p={4} fontSize="14px" maxWidth="150px" minWidth="150px">
              電話番号
            </Th>
            <Th p={4} fontSize="14px" maxWidth="120px" minWidth="120px">
              テナント
            </Th>
            <Th p={4} fontSize="14px" maxWidth="180px" minWidth="180px">
              登録日時
            </Th>
            <Th p={4} fontSize="14px" maxWidth="180px" minWidth="180px">
              最終ログイン日時
            </Th>
            <Th p={4} fontSize="14px" maxWidth="152px" minWidth="152px">
              ステータス
            </Th>
          </ChakraTr>
        </Thead>
        <Tbody>
          {members.map((member, idx) => (
            <MemberListTableBody
              member={member}
              key={`member_${String(idx)}`}
            />
          ))}
        </Tbody>
      </Table>
    </Box>
  ),
);
