import { VStack } from '@chakra-ui/react';
import { useSetPageTitle } from 'hooks/useSetPageTitle';
import { FC } from 'react';
import { Outlet } from 'react-router-dom';

export const TransactionMail: FC = () => {
  useSetPageTitle('初回ログイン時メール設定');

  return (
    <VStack>
      <Outlet />
    </VStack>
  );
};
