import { Box, Text } from '@chakra-ui/react';
import { Colors } from 'admin/define/colors';
import { FC, memo } from 'react';

type Props = {
  label: string;
};

export const AttributeTitleComponent: FC<Props> = memo(({ label }: Props) => (
  <Box mt="40px">
    {label && (
      <Box
        bgColor={Colors.WHITE}
        borderRadius="4px"
        display="inline-block"
        p="2px 4px"
      >
        <Text>{label}</Text>
      </Box>
    )}
    <Box mt="8px">
      <Text
        fontFamily="Hiragino Sans"
        fontSize="18px"
        fontWeight="700"
        lineHeight="170%"
      >
        属性設定
      </Text>
      <Text
        fontWeight="400"
        fontSize="14px"
        lineHeight="170%"
        fontFamily="Hiragino Sans"
        color={Colors.GRAY_00}
      >
        組織に登録されている各属性の利用設定状況が確認できます。
        <br />
        <Text as="span" color={Colors.RED_20} fontWeight="700">
          「＊」の項目は編集不可です。
        </Text>
        <br />
        {`表示名（日本語・外国語）、個人情報、JSONパス、並び順は、「IDシステム設定管理 > 属性設定」から編集可能です。`}
      </Text>
    </Box>
  </Box>
));
