import { EnquetDefaultValue, Enquete } from 'api/enquete/types';
import { EnqueteInner } from 'components/enquete/organisms/EnqueteInner';
import { useUserTenantId } from 'hooks/user/useUserTenantId';
import { FC } from 'react';

const defaultValues: Enquete = EnquetDefaultValue;

export const EnqueteCreateForm: FC = () => {
  const tenantId = useUserTenantId();

  return <EnqueteInner tenantId={tenantId} enqueteForm={defaultValues} />;
};
