import { CardHeaderTitle } from 'components/paywall/features/Card';
import { CourseDrawer } from 'components/paywall/pages/CourseList/CourseDrawer';
import { Course } from 'components/paywall/pages/CourseList/typed';
import { FC, memo } from 'react';

type Props = {
  course: Course;
};
export const CourseCardHeader: FC<Props> = memo(({ course }: Props) => (
  <CardHeaderTitle
    id={course.id}
    fontFamily="Hiragino sans"
    type="wall"
    isIdCopy={false}
  >
    <CourseDrawer
      isAdd={false}
      buttonMode="text"
      buttonName={course.name}
      obj={course}
    />
  </CardHeaderTitle>
));
