import { VFC } from 'react';

type LogoProps = {
  width?: number;
};

export const Logo: VFC<LogoProps> = ({ width = 0 }) => (
  <img
    src={`${process.env.PUBLIC_URL}/static/UEC_logo_with_text.png`}
    {...(width > 0 ? { width } : {})}
    alt="Uniikey Experience Cloud"
  />
);
