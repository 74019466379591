import {
  Box,
  Button,
  DrawerBody,
  DrawerFooter,
  FormControl,
  FormErrorMessage,
  HStack,
  Text,
} from '@chakra-ui/react';
import { RhfInput } from 'admin/components/form/RhfInput';
import { AlertInfoBar } from 'admin/components/ui/alertInfo';
import { ColorSet } from 'admin/define/colors';
import { ConfirmSubmitFormType } from 'admin/types/utils';
import { FC, memo } from 'react';
import { useFormContext, useFormState } from 'react-hook-form';

export type FormAttrFormComponentProps = {
  formId: string;
  isLoading: boolean;
  onClose: () => void;
  onSubmit: () => void;
};

/**
 * 属性、属性グループの全フォーム利用フォームコンポーネント
 * 属性グループでも共通で使用している
 */
export const FormAttrFormComponent: FC<FormAttrFormComponentProps> = memo(
  ({ formId, isLoading, onClose, onSubmit }) => {
    const { handleSubmit } = useFormContext<ConfirmSubmitFormType>();
    const { errors, isValid } = useFormState<ConfirmSubmitFormType>();

    return (
      <>
        <DrawerBody fontSize="16px">
          <Box mb={6}>
            <Text>
              この属性の
              <Text as="span" fontWeight="bold">
                「全フォーム利用」
              </Text>
              を有効にします。
            </Text>
          </Box>
          <AlertInfoBar type="error" iconType="error">
            ・一度実行すると解除できないため注意してください
          </AlertInfoBar>

          <form onSubmit={handleSubmit(onSubmit)} id={formId}>
            <Text mt={6} mb={1} fontSize="sm" fontWeight="bold">
              実行する場合は「confirm」と入力後に「実行ボタン」を押下してください。
            </Text>
            <FormControl isInvalid={Boolean(errors.word?.message)}>
              <RhfInput<ConfirmSubmitFormType> name="word" type="text" />
              <FormErrorMessage>{errors.word?.message}</FormErrorMessage>
            </FormControl>
          </form>
        </DrawerBody>
        <DrawerFooter>
          <HStack spacing={4}>
            <Button
              variant="outline"
              onClick={() => onClose()}
              {...ColorSet.Default}
              padding="8px 16px"
            >
              キャンセル
            </Button>
            <Button
              colorScheme="primary"
              type="submit"
              mr={3}
              form={formId}
              disabled={!isValid || isLoading}
              padding="8px 36px"
            >
              実行
            </Button>
          </HStack>
        </DrawerFooter>
      </>
    );
  },
);
