
import { Stack } from '@chakra-ui/react';
import { TenantUsersFilter } from 'admin/components/tenantUsers/tenantUsersFilter';
import { TenantUsersList } from 'admin/components/tenantUsers/tenantUsersList';
import { FC, memo } from 'react';

type Props = {
  searchKeyword: string;
  tenantId: string;
  onSearch: (keyword: string, tenantId: string) => void;
}

export const TenantUsersBodyComponent: FC<Props> = memo(
  ({
    searchKeyword,
    tenantId,
    onSearch,
  }: Props) => (
    <Stack spacing={4}>
      <TenantUsersFilter onSearch={onSearch}/>
      <TenantUsersList
        searchKeyword={searchKeyword}
        tenantId={tenantId}
      />
    </Stack>
));
