/**
 * メールマガジン Type
 */
export type MailMagazine = {
  id: string;
  tenant: string;
  title: string;
  description: string;
  targetType: number;
  deliveryCount: number;
  color: string | null;
  sort: number | null;
  isPublic: boolean;
};

/**
 * メールマガジン作成・編集 Type
 */
export type MailMagazineForm = Omit<MailMagazine, 'deliveryCount'>;

export const isMailMagazine = (data: unknown): data is MailMagazine => {
  const dt = data as MailMagazine;

  return (
    typeof dt?.id === 'string' &&
    typeof dt?.tenant === 'string' &&
    typeof dt?.title === 'string' &&
    typeof dt?.description === 'string' &&
    typeof dt?.targetType === 'number' &&
    typeof dt?.deliveryCount === 'number' &&
    (dt?.color !== null ? typeof dt?.color === 'string' : true) &&
    (dt?.sort !== null ? typeof dt?.sort === 'number' : true) &&
    typeof dt?.isPublic === 'boolean'
  );
};

export const isMailMagazineList = (datas: unknown[]): datas is MailMagazine[] =>
  !datas.some((d) => !isMailMagazine(d));
