import { updateAttributeGroup } from 'admin/api/userPool/attributeGroup/updateAttributeGroup';
import { CustomError } from 'admin/error/CustomError';
import { useResetAllAttributeGroups } from 'admin/hooks/userPool/attributeGroup/useResetAllAttributeGroups';
import { UserPool } from 'admin/types/userPool';
import { EditAttributeGroupFormType } from 'admin/types/userPool/attributeGroup/form';
import { AttributeGroupType } from 'admin/types/userPool/attributeGroup/index';
import { logger } from 'api/logger';
import { useCustomToast } from 'hooks/useCustomToast';
import { Dispatch, SetStateAction } from 'react';
import { UseFormSetError } from 'react-hook-form';
import { useMutation, useQueryClient } from 'react-query';
import { toMultiError } from 'utils/form';
import { errorToast, successToast } from 'utils/toast';
import { queryAttributeGroupKey } from './queryAttributeGroupKey';

export const useUpdateAttributeGroup = ({
  id,
  attributeGroupId,
  setError,
  setGlobalErrors,
}: {
  id: UserPool['id'];
  attributeGroupId: AttributeGroupType['id'];
  setError: UseFormSetError<EditAttributeGroupFormType>;
  setGlobalErrors: Dispatch<SetStateAction<string[]>>;
}) => {
  const toast = useCustomToast();
  const queryClient = useQueryClient();
  const resetAttributeGroups = useResetAllAttributeGroups(id);

  const { mutateAsync, isLoading } = useMutation({
    mutationFn: (data: EditAttributeGroupFormType) =>
      updateAttributeGroup({ data, id, attributeGroupId }),
    onError: async (error, variables: EditAttributeGroupFormType) => {
      if (error instanceof CustomError) {
        if (error.cause.errors?.others) {
          const othersMsgs = Array.isArray(error.cause.errors?.others)
            ? error.cause.errors?.others
            : [error.cause.errors?.others];

          setGlobalErrors(othersMsgs);
        } else {
          Object.keys(variables).forEach((k) => {
            const key = k as keyof EditAttributeGroupFormType;
            const errMsgs = error.cause.errors?.[key];
            if (errMsgs && Array.isArray(errMsgs)) {
              setError(key, { types: toMultiError(errMsgs) });
            }
          });
        }

        if (error.cause.errors) {
          await logger({
            loglevel: error.logLevel,
            data: JSON.stringify(error.cause),
            message: error.message,
          });
        }
      }
      toast({
        ...errorToast,
        duration: 4000,
        title: '組織レベル属性設定の更新に失敗しました',
        position: 'bottom',
        variant: 'solid',
      });
    },
    onSuccess: () => {
      void queryClient.invalidateQueries(
        queryAttributeGroupKey.getAttributeGroupDetail(id, attributeGroupId),
      );
      resetAttributeGroups();
      toast({
        ...successToast,
        duration: 4000,
        title: '組織レベル属性設定を更新しました',
        position: 'bottom',
        variant: 'solid',
      });
    },
  });

  return { mutate: mutateAsync, isLoading };
};
