import { zodResolver } from '@hookform/resolvers/zod';
import { withSuspenseAndErrorBoundary } from 'admin/components/Error/WithErrorBoundary';
import { LoadingLayer } from 'admin/components/ui/loadingLayer';
import { useChangeAgreeTerm } from 'admin/hooks/userPool/terms';
import { agreeFormSchema } from 'admin/schema/idPoolConsumer/terms';
import { TermType } from 'admin/types/userPool/terms';
import { AgreeFormType } from 'admin/types/userPool/terms/form';
import { FC, memo } from 'react';
import { FormProvider, SubmitHandler, useForm } from 'react-hook-form';
import { AgreeModalComponent } from './AgreeModalComponent';

type FetchAgreeModalProps = {
  onClose: () => void;
  term: TermType | null;
};

const FetchAgreeModal: FC<FetchAgreeModalProps> = memo(
  ({ onClose, term }: FetchAgreeModalProps) => {
    const { mutate, isLoading } = useChangeAgreeTerm(term?.id ?? '');
    const methods = useForm<AgreeFormType>({
      defaultValues: {
        message: '',
      },
      mode: 'onBlur',
      resolver: zodResolver(agreeFormSchema),
    });

    if (!term) {
      return null;
    }

    const onSubmit: SubmitHandler<AgreeFormType> = async () => {
      await mutate(term.version);
      onClose();
    };

    return (
      <FormProvider {...methods}>
        {isLoading && <LoadingLayer />}
        <AgreeModalComponent
          onClose={onClose}
          onSubmit={onSubmit}
          term={term}
          isLoading={isLoading}
        />
      </FormProvider>
    );
  },
);

export const AgreeModal = withSuspenseAndErrorBoundary(FetchAgreeModal);
