import { Icon, IconProps } from '@chakra-ui/react';
import { FC, memo } from 'react';

export const RemoveIcon: FC<IconProps> = memo(({ ...props }) => (
  <Icon width="3" height="2" viewBox="0 0 10 2" fill="none" {...props}>
    <path
      d="M0.866211 1.59844V0.398438H9.13288V1.59844H0.866211Z"
      fill="currentColor"
    />
  </Icon>
));

// defaultPropsを設定することでエラーを解消
RemoveIcon.defaultProps = {
  color: '#272E35',
};
