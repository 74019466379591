import { withSuspenseAndErrorBoundary } from 'admin/components/Error/WithErrorBoundary';
import { NewsletterBody } from 'admin/components/service/newsletter/newsletterBody';
import { NewsletterTitle } from 'admin/components/service/newsletter/newsletterTitle';
import { Breadcrumb } from 'admin/components/ui/breadcrumb';
import { BreadcrumbType } from 'admin/types/ui/breadcrumb';
import { ErrorContents, LoadingSpinner } from 'components/common/atoms';
import { useSetPageTitle } from 'hooks/useSetPageTitle';
import { FC, memo } from 'react';

export const newsletter: FC = memo(() => {
  useSetPageTitle('メールマガジン購読設定');
  const breadcrumbItems: BreadcrumbType[] = [
    { url: '/admin', title: '組織管理TOP' },
    { url: '/admin/service', title: 'テナント設定管理' },
    { url: '', title: 'メールマガジン購読設定' },
  ];

  return (
    <>
      <Breadcrumb breadcrumbItems={breadcrumbItems} />
      <NewsletterTitle />
      <NewsletterBody />
    </>
  );
});

export const Newsletter = withSuspenseAndErrorBoundary(newsletter, {
  ErrorComponent: <ErrorContents name="メールマガジン購読設定" />,
  LoadingComponent: <LoadingSpinner />,
});
