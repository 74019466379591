import { useCallback, useEffect } from 'react';

/**
 * ブラウザバック時の制御
 * isEditedがtrue時にブラウザバックを行った場合、ダイアログを表示する
 */
export const useBrowserBackControl = ({
  isEdited,
  onOpen,
}: {
  isEdited: boolean;
  onOpen: () => void;
}) => {
  const handlePopstate = useCallback(() => {
    onOpen();
  }, [onOpen]);

  useEffect(() => {
    // 編集中になったとき、現在のページを履歴に挿入し、handlePopstateをイベント登録
    if (isEdited) {
      // ダミー履歴を挿入して「戻る」を1回分吸収できる状態にする
      window.history.pushState(null, '', null);
      window.addEventListener('popstate', handlePopstate, false);
    }

    // 他のページに影響しないようclear
    return () => {
      window.removeEventListener('popstate', handlePopstate, false);
    };
  }, [isEdited, handlePopstate]);
};
