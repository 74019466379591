import { Drawer, useDisclosure } from '@chakra-ui/react';
import { withSuspenseAndErrorBoundary } from 'admin/components/Error/WithErrorBoundary';
import { CallbackUrlComponent } from 'admin/components/service/auth/callbackUrl/CallbackUrlComponent';
import { CallbackUrlDrawer } from 'admin/components/service/auth/callbackUrlDrawer';
import { AuthType } from 'admin/types/service/auth';
import { ErrorContents, LoadingSpinner } from 'components/common/atoms';
import { FC, memo } from 'react';

type Props = {
  authData: AuthType;
};

export const callbackUrl: FC<Props> = memo(({ authData }: Props) => {
  const { isOpen, onClose, onOpen } = useDisclosure();

  return (
    <>
      <CallbackUrlComponent onOpen={onOpen} authData={authData} />
      <Drawer
        isOpen={isOpen}
        placement="right"
        onClose={onClose}
        size="md"
        closeOnOverlayClick={false}
        closeOnEsc={false}
        autoFocus={false}
      >
        <CallbackUrlDrawer onClose={onClose} />
      </Drawer>
    </>
  );
});

export const CallbackUrl = memo(
  withSuspenseAndErrorBoundary(callbackUrl, {
    ErrorComponent: <ErrorContents name="コールバックURL" />,
    LoadingComponent: <LoadingSpinner />,
  }),
);
