import { Options } from 'ky';
import { request } from 'api/request';
import { logger } from 'api/logger';
import { CustomError } from 'api/error/CustomError';
import { Mail, isMail } from './types';
import { messages } from './messages';

// メール詳細情報取得API
export const getMailId = async (
  tenantId: string,
  mailId: string,
  options?: Options,
): Promise<Mail> => {
  const target = 'mail';
  const path = `${target}/${tenantId}/${mailId}/`;

  const response = await request({
    path,
    method: 'get',
    options,
  });

  const responseData = (await response.data) as unknown[];

  if (response.hasError) {
    if (response.status === 404) {
      throw new CustomError(
        messages.getMailId.customer.notFound,
        response.status,
      );
    } else {
      throw new CustomError(messages.getMailId.customer.error, response.status);
    }
  }

  if (!isMail(responseData)) {
    await logger({
      loglevel:
        response.status === 404 || response.status === 450
          ? 'warning'
          : 'error',
      data: responseData,
      message: messages.getMailId.system.typeError,
    });

    throw new CustomError(messages.getMailId.customer.failed, response.status);
  }

  return responseData;
};
