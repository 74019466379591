import { Box, Icon } from '@chakra-ui/react';
import styled from '@emotion/styled';
import { Calendar } from 'components/opt/features/Calendar';
import { useSearch } from 'components/opt/hooks/useSearch';
import { Colors } from 'components/paywall/config';
import { StyledFlex } from 'components/paywall/styled/global';
import { FC, memo } from 'react';
import { MdSearch } from 'react-icons/md';

const StyledContainer = styled(Box)({});

type Props = {
  inputName: string;
  hasTime?: boolean;
};

export const FilterPeriod: FC<Props> = memo(({ inputName, hasTime = true }) => {
  const {
    register,
    setValue,
    handleFilterPeriod,
    isCalendarReset,
    setIsCalendarReset,
  } = useSearch();

  return (
    <StyledContainer>
      <StyledFlex style={{ gap: 5 }}>
        <Calendar
          fieldName={`${inputName}From`}
          placeholder="開始日を選択して下さい"
          register={register}
          setValue={setValue}
          isCalendarReset={isCalendarReset}
          setIsCalendarReset={setIsCalendarReset}
          hasTime={hasTime}
        />
        <Calendar
          fieldName={`${inputName}To`}
          placeholder="終了日を選択して下さい"
          register={register}
          setValue={setValue}
          isCalendarReset={isCalendarReset}
          setIsCalendarReset={setIsCalendarReset}
          hasTime={hasTime}
        />
        <StyledFlex style={{ gap: 5 }}>
          <Box
            onClick={() => handleFilterPeriod(inputName)}
            w="24px"
            minW="24px"
            h="24px"
            bgColor={Colors.GRAY_60}
            borderRadius="50%"
            textAlign="center"
            cursor="pointer"
          >
            <Icon
              as={MdSearch}
              boxSize={5}
              color={Colors.GRAY_03}
              verticalAlign="bottom"
            />
          </Box>
        </StyledFlex>
      </StyledFlex>
    </StyledContainer>
  );
});
