import { Box, Heading } from '@chakra-ui/react';
import { withSuspenseAndErrorBoundary } from 'admin/components/Error/WithErrorBoundary';
import { FormGroupTableComponent } from 'admin/components/idPoolConsumer/formGroup/formGroupTable/FormGroupTableComponent';
import { useIdPoolConsumerId } from 'admin/hooks/useIdPoolConsumerId';
import { useFormGroups } from 'admin/hooks/userPool/formGroup/useFormGroups';
import { useFormGroupStatusChange } from 'admin/hooks/userPool/formGroup/useFormGroupStatusChange';
import { FormGroupType } from 'admin/types/userPool/formGroup';
import { Page } from 'api/common/types';
import { ErrorContents, LoadingSpinner } from 'components/common/atoms';
import {
  Dispatch,
  FC,
  memo,
  SetStateAction,
  useCallback,
  useEffect,
} from 'react';

type Props = {
  currentPage: number;
  setPage: Dispatch<SetStateAction<Page>>;
};

const fetchFormGroupTable: FC<Props> = memo(
  ({ currentPage, setPage }: Props) => {
    const idPoolConsumerId = useIdPoolConsumerId();
    const { data: formGroups, page } = useFormGroups(
      idPoolConsumerId,
      currentPage,
    );

    const { mutate } = useFormGroupStatusChange({
      page: currentPage,
    });

    const onChangeStatus = useCallback(
      async (data: FormGroupType) => {
        await mutate(data);
      },
      [mutate],
    );

    useEffect(() => {
      setPage(page);
    }, [page, setPage]);

    if (!formGroups) {
      return null;
    }

    if (formGroups.length === 0) {
      return (
        <Box
          w="100%"
          textAlign="center"
          bg="white"
          mt={6}
          borderRadius={4}
          padding={10}
        >
          <Heading as="h3" fontWeight="bold" fontSize="24px">
            フォーム設問グループが未設定です。
          </Heading>
        </Box>
      );
    }

    return (
      <FormGroupTableComponent
        formGroupDatas={formGroups}
        idPoolConsumerId={idPoolConsumerId}
        onChangeStatus={onChangeStatus}
      />
    );
  },
);

export const FormGroupTable = withSuspenseAndErrorBoundary(
  fetchFormGroupTable,
  {
    ErrorComponent: <ErrorContents name="フォーム設問グループ設定一覧" />,
    LoadingComponent: <LoadingSpinner />,
  },
);
