import { Text } from '@chakra-ui/react';
import { withSuspenseAndErrorBoundary } from 'admin/components/Error/WithErrorBoundary';
import { MemberLogTableComponent } from 'admin/components/member/detail/memberLogs/memberLogTable/MemberLogTableComponent';
import { useMemberLogs } from 'admin/hooks/member/useMemberLogs';
import { Page } from 'api/common/types';
import { ErrorContents } from 'components/common/atoms';
import { Dispatch, FC, memo, SetStateAction, useEffect, useMemo } from 'react';
import { useNavigate, useParams } from 'react-router-dom';

type Props = {
  currentPage?: number;
  perPage?: number;
  hasTitle?: boolean;
  setPage?: Dispatch<SetStateAction<Page>>;
};

const memberLogTable: FC<Props> = memo(
  ({ currentPage, perPage, hasTitle = true, setPage }: Props) => {
    const params = useParams();
    const memberId = useMemo(() => params.memberId ?? '', [params.memberId]);
    const { data: memberLogs, page } = useMemberLogs(
      memberId,
      currentPage || 1,
      perPage || 50,
    );
    const navigate = useNavigate();

    useEffect(() => {
      setPage?.(page);
    }, [setPage, page]);

    if (!memberLogs) {
      return <Text>利用履歴がありません。</Text>;
    }

    return (
      <MemberLogTableComponent
        memberId={memberId}
        memberLogs={memberLogs}
        navigate={navigate}
        hasTitle={hasTitle}
      />
    );
  },
);

memberLogTable.defaultProps = {
  currentPage: undefined,
  hasTitle: true,
  setPage: undefined,
};

export const MemberLogTable = withSuspenseAndErrorBoundary(memberLogTable, {
  ErrorComponent: <ErrorContents name="利用履歴情報" />,
});
