import { FormControl, FormLabel } from '@chakra-ui/react';
import { InputTextForm, InputTextProps } from 'components/common/atoms';
import { FC } from 'react';
import {
  FieldValues,
  Path, RegisterOptions, useController, useFormContext
} from 'react-hook-form';

/**
 * LoginInputFormProps
 */
type LoginInputFormProps<T> = {
  name: Path<T>;
  labelElement?: JSX.Element | undefined;
  attr?: RegisterOptions;
  horizontal?: boolean;
} & InputTextProps;

/**
 * ログイン画面用 Input コンポーネント
 * 共通のコンポーネントとは別で作成し, Enter による Submit を阻害しない
 */
export const LoginInputForm = <T extends FieldValues>({
  name,
  label = '',
  labelElement = undefined,
  horizontal = false,
  ...rest
}: LoginInputFormProps<T>): ReturnType<FC> => {
  const { control } = useFormContext<T>();

  const {
    field: { value },
  } = useController({ name, control });

  const formAttr = horizontal ? { display: 'flex', alignItems: 'center' } : {};

  return (
    <FormControl {...formAttr}>
      <FormLabel htmlFor={name} fontSize="sm">
        {label && label}
        {labelElement && labelElement}
      </FormLabel>
      <InputTextForm<T>
        {...rest}
        name={name}
        value={value}
      />
    </FormControl>
  );
};

LoginInputForm.defaultProps = {
  horizontal: false,
};
