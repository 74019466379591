import { withSuspenseAndErrorBoundary } from 'admin/components/Error/WithErrorBoundary';
import { NewsletterBody } from 'admin/components/idPoolConsumer/newsletter/newsletterBody';
import { NewsletterTitle } from 'admin/components/idPoolConsumer/newsletter/newsletterTitle';
import { Breadcrumb } from 'admin/components/ui/breadcrumb';
import { Truncate } from 'admin/components/ui/truncate';
import { useUserPoolId } from 'admin/hooks/params/useUserPoolId';
import { useUserPool } from 'admin/hooks/userPool/useUserPool';
import { BreadcrumbType } from 'admin/types/ui/breadcrumb';
import { ErrorContents, LoadingSpinner } from 'components/common/atoms';
import { useSetPageTitle } from 'hooks/useSetPageTitle';
import { FC, memo } from 'react';

export const newsletter: FC = memo(() => {
  useSetPageTitle('メールマガジン購読設定');
  const userPoolId = useUserPoolId();
  const { data: userPool } = useUserPool(userPoolId);
  const breadcrumbItems: BreadcrumbType[] = [
    { url: '/admin', title: '組織管理TOP' },
    {
      url: '/admin/idPoolConsumer',
      title: 'IDシステム設定管理',
    },
    {
      url: '',
      title: <Truncate>{userPool?.name || ''}</Truncate>,
    },
    { url: '', title: 'メールマガジン購読設定' },
  ];

  return (
    <>
      <Breadcrumb breadcrumbItems={breadcrumbItems} />
      <NewsletterTitle />
      <NewsletterBody />
    </>
  );
});

export const Newsletter = withSuspenseAndErrorBoundary(newsletter, {
  ErrorComponent: <ErrorContents name="メールマガジン購読設定" />,
  LoadingComponent: <LoadingSpinner />,
});
