import { VFC, memo, useState, ChangeEvent } from 'react';
import {
  Input,
  Button,
  Popover,
  PopoverTrigger,
  PopoverContent,
  PopoverBody,
  Table,
  Thead,
  Tbody,
  Tr,
  Th,
  Td,
} from '@chakra-ui/react';
import { SendMailReplaceType } from 'api/mail/types';

type MailReplacePopoverProps = {
  mailReplaceWord: SendMailReplaceType;
  setMailReplaceWord: (val: SendMailReplaceType) => void;
};

export const MailReplacePopover: VFC<MailReplacePopoverProps> = memo(
  ({ mailReplaceWord, setMailReplaceWord }: MailReplacePopoverProps) => {
    const [isOpen, setIsOpen] = useState(false);
    const onOpen = () => setIsOpen(!isOpen);
    const onClose = () => setIsOpen(false);

    const onChangeValue = (key: string, e: ChangeEvent<HTMLInputElement>) => {
      const newMailReplaceWord = Object.assign(mailReplaceWord, {
        [key]: e.target.value,
      });
      setMailReplaceWord(newMailReplaceWord);
    };

    return (
      <Popover isOpen={isOpen} onClose={onClose}>
        <PopoverTrigger>
          <Button onClick={onOpen}>差し込み文字設定</Button>
        </PopoverTrigger>

        <PopoverContent width={400}>
          <PopoverBody maxH="300px" overflow="auto" padding="0">
            <Table variant="simple" size="sm">
              <Thead
                top={0}
                bgColor="gray.100"
                position="sticky"
                zIndex="5"
                h="44px"
              >
                <Tr>
                  <Th>差し込み語</Th>
                  <Th>置換文字</Th>
                </Tr>
              </Thead>
              <Tbody>
                {Object.keys(mailReplaceWord).map((key) => (
                  <Tr key={key}>
                    <Td>{key}</Td>
                    <Td>
                      <Input
                        variant="flushed"
                        placeholder="置換文字名"
                        onChange={(e) => onChangeValue(key, e)}
                      />
                    </Td>
                  </Tr>
                ))}
              </Tbody>
            </Table>
          </PopoverBody>
        </PopoverContent>
      </Popover>
    );
  },
);
