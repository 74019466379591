import { zodResolver } from '@hookform/resolvers/zod';
import { withSuspenseAndErrorBoundary } from 'admin/components/Error/WithErrorBoundary';
import { FormComponent } from 'admin/components/idPoolConsumer/attributeGroup/attributeGroupForm/createForm/FormComponent';
import { useIdPoolConsumerId } from 'admin/hooks/useIdPoolConsumerId';
import { useAllAttributes } from 'admin/hooks/userPool/attribute/useAllAttributes';
import { useCreateAttributeGroup } from 'admin/hooks/userPool/attributeGroup/useCreateAttributeGroup';
import { createAttributeGroupSchema } from 'admin/schema/idPoolConsumer/attributeGroup';
import { CreateAttributeGroupFormType } from 'admin/types/userPool/attributeGroup/form';
import {
  AlertBar,
  ErrorContents,
  LoadingSpinner,
} from 'components/common/atoms';
import { FC, memo, useState } from 'react';
import { FormProvider, SubmitHandler, useForm } from 'react-hook-form';

type Props = {
  onClose: () => void;
};

const createForm: FC<Props> = memo(({ onClose }: Props) => {
  const formId = 'createAttributeGroupForm';
  const idPoolConsumerId = useIdPoolConsumerId();
  const { data: attributes, isLoading: isLoadingAttributes } =
    useAllAttributes(idPoolConsumerId);

  const methods = useForm<CreateAttributeGroupFormType>({
    defaultValues: {
      displayNameJa: '',
      displayNameForeign: '',
      displayType: '',
      displayOrder: '',
      attributeGroupBodyList: [
        {
          userAttributeSetting: '',
          attributeRole: null,
        },
      ],
    },
    resolver: zodResolver(createAttributeGroupSchema),
  });
  const [globalErrors, setGlobalErrors] = useState<string[]>([]);
  const { setError, reset } = methods;

  const { mutate, isLoading } = useCreateAttributeGroup(
    setError,
    setGlobalErrors,
  );

  const onSubmit: SubmitHandler<CreateAttributeGroupFormType> = async (
    data: CreateAttributeGroupFormType,
  ) => {
    if (!isLoading && !isLoadingAttributes) {
      await mutate(data);
    }
    reset();
    onClose();
  };

  if (!attributes) {
    return null;
  }

  return (
    <FormProvider {...methods}>
      {globalErrors.map((err, idx) => (
        <AlertBar
          key={`global-err-idx${String(idx)}`}
          message={err}
          status="error"
          mb={4}
        />
      ))}
      <FormComponent
        onClose={onClose}
        onSubmit={onSubmit}
        isLoading={isLoading || isLoadingAttributes}
        formId={formId}
        attributes={attributes}
      />
    </FormProvider>
  );
});

export const CreateForm = withSuspenseAndErrorBoundary(createForm, {
  ErrorComponent: <ErrorContents name="属性グループ詳細" />,
  LoadingComponent: <LoadingSpinner />,
});
