import { CustomError } from 'api/error/CustomError';
import { logger } from 'api/logger';
import { request } from 'api/request';
import {
  isWallItemList, WallItemListType
} from 'components/paywall/pages/WallItemList/typed';
import { Options } from 'ky';
import { messages } from './messages';

/**
 * 単品商品一覧取得 API
 * @param {string} tenantId テナントID
 * @param {string} wallId ウォールID
 * @param {Options} options APIオプション
 * @return {Promise<WallItemListType>} 単品商品一覧
 */
export const getWallItemList = async ({
  tenantId,
  wallId,
  filter,
  page,
  perPage,
  options
}: {
  tenantId: string,
  wallId: string,
  filter?: string,
  page?: string;
  perPage?: string;
  options?: Options,
}): Promise<WallItemListType> => {
  const param = [];
  if (filter) {
    param.push(`filter=${filter}`);
  }
  if (page) {
    param.push(`page=${page}`);
  }
  if (perPage) {
    param.push(`per_page=${perPage}`);
  }
  const queryParam = param.length > 0 ? `?${param.join('&')}` : '';
  const path = `paywall/${tenantId}/${wallId}/item/${queryParam}`;
  if (!wallId)
    return {
      count: 0,
      countFrom: 0,
      countTo: 0,
      currentPage: 0,
      next: '',
      pageCount: 0,
      perPage: 0,
      previous: '',
      results: [],
    };

  const response = await request({
    path,
    method: 'get',
    options,
  });

  const responseData = (await response.data) as unknown[];

  if (response.hasError) {
    if (response.status === 404) {
      throw new CustomError(
        messages.getWallItemList.customer.notFound,
        response.status,
      );
    } else {
      throw new CustomError(messages.getWallItemList.customer.error);
    }
  }

  if (!isWallItemList(responseData)) {
    await logger({
      loglevel:
        response.status === 404 || response.status === 450
          ? 'warning'
          : 'error',
      data: responseData,
      message: messages.getWallItemList.system.typeError,
    });

    throw new CustomError(messages.getWallItemList.customer.failed);
  }

  return responseData;
};
