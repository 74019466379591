import { sendTestTransactionMail } from 'admin/api/userPool/transactionMail/sendTestTransactionMail';
import { CustomError } from 'admin/error/CustomError';
import { useIdPoolConsumerId } from 'admin/hooks/useIdPoolConsumerId';
import { TransactionMailTestMailFormType } from 'admin/types/userPool/transactionMail/form';
import { logger } from 'api/logger';
import { useCustomToast } from 'hooks/useCustomToast';
import { UseFormSetError } from 'react-hook-form';
import { useMutation } from 'react-query';
import { toMultiError } from 'utils/form';
import { errorToast, successToast } from 'utils/toast';

export const useTestTransactionMail = ({
  setError,
}: {
  setError?: UseFormSetError<TransactionMailTestMailFormType>;
}) => {
  const idPoolConsumerId = useIdPoolConsumerId();
  const toast = useCustomToast();

  const { mutateAsync, isLoading } = useMutation({
    mutationFn: (data: TransactionMailTestMailFormType) =>
      sendTestTransactionMail(idPoolConsumerId, data),
    onSuccess: () => {
      toast({
        ...successToast,
        duration: 4000,
        title: 'テストメールを送信しました',
        position: 'bottom',
        variant: 'solid',
      });
    },
    onError: async (error, variables: TransactionMailTestMailFormType) => {
      let errorDescription = '';
      if (error instanceof CustomError) {
        if (error.cause.errors?.others) {
          const othersMsgs = Array.isArray(error.cause.errors?.others)
            ? error.cause.errors?.others
            : [error.cause.errors?.others];

          errorDescription = othersMsgs.join('¥r¥n');
        } else {
          Object.keys(variables).forEach((k) => {
            const key = k as keyof TransactionMailTestMailFormType;
            const errMsgs = error.cause.errors?.[key];
            if (errMsgs && Array.isArray(errMsgs)) {
              setError?.(key, { types: toMultiError(errMsgs) });
            }
          });
        }

        if (error.cause.errors) {
          await logger({
            loglevel: error.logLevel,
            data: JSON.stringify(error.cause),
            message: error.message,
          });
        }
      }
      toast({
        ...errorToast,
        duration: 4000,
        title: 'テストメールの送信に失敗しました',
        description: errorDescription,
        position: 'bottom',
        variant: 'solid',
      });
    },
  });

  return { mutate: mutateAsync, isLoading };
};
