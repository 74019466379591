import { Box, Tag } from '@chakra-ui/react';
import { FC, memo } from 'react';

type Props = {
  author: string;
}

export const DetailMemoAuthor: FC<Props> = memo(({author}) => (
  <Box w="100%" textAlign="right">
    <Tag>
      作成者：{author}
    </Tag>
  </Box>
));
