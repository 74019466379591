import {
  Box,
  Button,
  ButtonGroup,
  Icon,
  Text,
  useDisclosure,
} from '@chakra-ui/react';
import { CreateFirstLoginMailFormType } from 'api/transactionMail/type';
import { InputForm } from 'components/common/molecules';
import { useMailSetting } from 'hooks/transactionMail/useMailSetting';
import { FC, useCallback, useMemo } from 'react';
import EmailEditor from 'react-email-editor';
import { MdArrowBackIos } from 'react-icons/md';
import { TestMailPopover } from './TestMailPopover';

type Props = {
  isLoading: boolean;
  isDisabled: boolean;
  editorRef: React.RefObject<EmailEditor>;
  navigate: (path: string) => void;
};

export const MailHeader: FC<Props> = ({
  isLoading,
  isDisabled,
  editorRef,
  navigate,
}) => {
  const {
    isOpen: isTestMailPopupOpen,
    onOpen: onTestMailPopupOpen,
    onClose: onTestMailPopupClose,
  } = useDisclosure();
  const { data: mailSetting } = useMailSetting();
  const hasFromAddress = useMemo<boolean>(
    () => !!mailSetting?.fromAddress,
    [mailSetting],
  );

  const onBackPage = useCallback(() => {
    navigate('/transactionMail/list/');
  }, [navigate]);

  return (
    <>
      <Box
        w="100%"
        mb={4}
        display="flex"
        justifyContent="space-between"
        alignItems="center"
        gridGap={2}
      >
        <Box>
          <Button
            variant="ghost"
            _focus={{ focus: 'none' }}
            onClick={onBackPage}
            color="gray.500"
          >
            <Icon as={MdArrowBackIos} />
            一覧へ戻る
          </Button>
        </Box>
        <ButtonGroup variant="outline" spacing="2">
          <Box position="relative" ml={4}>
            <TestMailPopover
              hasFromAddress={hasFromAddress}
              editorRef={editorRef}
              isOpen={isTestMailPopupOpen}
              onOpen={onTestMailPopupOpen}
              onClose={onTestMailPopupClose}
            />
          </Box>
          <Button
            ml={2}
            variant="primary"
            type="submit"
            isLoading={isLoading}
            isDisabled={isDisabled}
          >
            保存
          </Button>
        </ButtonGroup>
      </Box>
      <Box mb={2}>
        <Box display="flex" alignItems="flex-start" gridGap={1} mb="4">
          <Text minW="98px" mt="18px" mr="12px" fontWeight="bold" color="#999">
            件名
          </Text>
          <InputForm<CreateFirstLoginMailFormType>
            name="subject"
            type="text"
            w="666px"
            minW="120px"
            isDisabled={isDisabled}
          />
        </Box>
        <Box display="flex" alignItems="flex-start" gridGap={1}>
          <Text
            minW="98px"
            mt="18px"
            mr="12px"
            fontWeight="bold"
            color="#999"
            mb={-2}
          >
            設定名
          </Text>
          <InputForm<CreateFirstLoginMailFormType>
            name="settingTitle"
            type="text"
            w="260px"
            minW="120px"
            isDisabled={isDisabled}
          />
        </Box>
      </Box>
    </>
  );
};
