import { Stack, Text } from '@chakra-ui/react';
import { MemberIdPoolInfo } from 'admin/components/member/memberIdPoolInfo';
import { MemberListTable } from 'admin/components/member/memberListTable/';
// TODO: 9月末のリリース時点では必要では無い為、コメントアウト
// import { MemberSearch } from 'admin/components/member/memberSearch';
import { Pagination } from 'admin/components/ui/pagination';
import { IdPoolConsumerListItem } from 'admin/types/userPool';
import { Page } from 'api/common/types';
import { Dispatch, FC, memo, SetStateAction, useState } from 'react';

type Props = {
  idPoolConsumer: IdPoolConsumerListItem;
  selectedId: string;
  currentPage: number;
  perPage: number;
  page: Page;
  setPage: Dispatch<SetStateAction<Page>>;
  // TODO: 9月末のリリース時点では必要では無い為、コメントアウト
  // searchKeyword: string;
  // onSearch: (keyword: string) => void;
  // setRefetchFunc: Dispatch<SetStateAction<() => void>>;
};

export const MemberIdPoolPanelComponent: FC<Props> = memo(
  ({
    idPoolConsumer,
    selectedId,
    currentPage,
    perPage,
    // TODO: 9月末のリリース時点では必要では無い為、コメントアウト
    // searchKeyword,
    // onSearch,
    // setRefetchFunc,
    page,
    setPage,
  }: Props) => {
    const [hasMemberList, setHasMemberList] = useState<boolean>(true);

    return (
      <Stack spacing={4}>
        <MemberIdPoolInfo idPoolConsumer={idPoolConsumer} />
        {/*
          TODO: MemberSearchをリリースする時に削除予定。
          MemberSearchコンポーネントの中にタイトルが含まれていた為、一時的に外に出しています。
        */}
        <Text fontWeight="700" fontSize="1rem">
          会員一覧
        </Text>
        {/* TODO: 9月末のリリース時点では必要では無い為、コメントアウト */}
        {/* <MemberSearch onSearch={onSearch} /> */}
        <MemberListTable
          selectedId={selectedId}
          currentPage={currentPage}
          perPage={perPage}
          setPage={setPage}
          setHasMemberList={setHasMemberList}
          // TODO: 9月末のリリース時点では必要では無い為、コメントアウト
          // searchKeyword={searchKeyword}
          // setRefetchFunc={setRefetchFunc}
        />
        {hasMemberList && <Pagination page={page} />}
      </Stack>
    );
  },
);
