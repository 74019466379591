import { withSuspenseAndErrorBoundary } from 'admin/components/Error/WithErrorBoundary';
import { AttributeBody } from 'admin/components/service/attribute/attributeBody';
import { AttributeTitle } from 'admin/components/service/attribute/attributeTitle';
import { Breadcrumb } from 'admin/components/ui/breadcrumb';
import { BreadcrumbType } from 'admin/types/ui/breadcrumb';
import { ErrorContents, LoadingSpinner } from 'components/common/atoms';
import { useSetPageTitle } from 'hooks/useSetPageTitle';
import { FC, memo } from 'react';

export const attribute: FC = memo(() => {
  useSetPageTitle('属性設定');

  const breadcrumbItems: BreadcrumbType[] = [
    { url: '/admin', title: '組織管理TOP' },
    { url: '/admin/service', title: 'テナント設定管理' },
    { url: '', title: '属性設定' },
  ];

  return (
    <>
      <Breadcrumb breadcrumbItems={breadcrumbItems} />
      <AttributeTitle />
      <AttributeBody />
    </>
  );
});

export const Attribute = memo(
  withSuspenseAndErrorBoundary(attribute, {
    ErrorComponent: <ErrorContents name="属性設定" />,
    LoadingComponent: <LoadingSpinner />,
  }),
);
