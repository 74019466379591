import { VFC, useEffect } from 'react';
import { useFormContext, useController } from 'react-hook-form';
import { Select, FormLabel, Box } from '@chakra-ui/react';
import { useIndentGroupList } from 'hooks/tenant/useIndentGroupList';
import { useUserTenantId } from 'hooks/user/useUserTenantId';
import { ContentsDbTblFormType } from 'api/contents/types';
import { toErrMsgList } from 'utils/form';
import { ErrorTextMsg } from 'components/common/atoms';

export const GroupSelect: VFC<{ value?: string }> = ({ value = '' }) => {
  const tenantId = useUserTenantId();
  const groupList = useIndentGroupList(tenantId);
  const name = 'group';

  const {
    setValue,
    control,
    formState: { errors },
  } = useFormContext<ContentsDbTblFormType>();

  const {
    field: { ...formRest },
  } = useController({
    name,
    control,
  });

  useEffect(() => {
    if (value) {
      setValue(name, value);
    }
  }, [setValue, name, value]);

  const errMsgList = toErrMsgList(errors, name);

  return (
    <Box>
      <FormLabel htmlFor={name}>グループ</FormLabel>
      <Select id={name} {...formRest}>
        <option value="">グループなし</option>
        {groupList.map((grp) => (
          <option key={grp.id} value={grp.id}>
            {grp.name}
          </option>
        ))}
      </Select>
      {errMsgList.map((err, idx) => (
        <ErrorTextMsg key={`error-${name}-${String(idx)}`} msg={err} />
      ))}
    </Box>
  );
};
