import { request } from 'admin/api/Request';
import { API_MESSAGES } from 'admin/define/apiMessages';
import { CustomError } from 'admin/error/CustomError';
import { formGroupsSchema } from 'admin/schema/idPoolConsumer/formGroup';
import { convertToPage, pageSchema } from 'admin/schema/page';
import { FormGroupType, FormGroupsType } from 'admin/types/userPool/formGroup';
import { DataWithPage, Page } from 'api/common/types';
import { Options } from 'ky';
import { ZodError } from 'zod';

export const getFormGroups = async ({
  id,
  page,
  perPage,
  options,
}: {
  id: FormGroupType['userPoolId'];
  page?: Page['currentPage'];
  perPage?: Page['perPage'];
  options?: Options;
}): Promise<DataWithPage<FormGroupsType>> => {
  const queryParam = [];
  if (page) {
    queryParam.push(`page=${page}`);
  }
  if (perPage) {
    queryParam.push(`perPage=${perPage}`);
  }
  const param = queryParam.length > 0 ? `?${queryParam.join('&')}` : '';

  const path = `enquete/${id}/content_group/${param}`;
  const response = await request<DataWithPage<FormGroupsType>>({
    path,
    method: 'get',
    options,
  });

  if (response.hasError) {
    if (response.status === 404) {
      throw new CustomError(API_MESSAGES.USER_POOL.GET_FORM_GROUP.NOT_FOUND, {
        path,
        status: response.status,
      });
    } else {
      throw new CustomError(API_MESSAGES.USER_POOL.GET_FORM_GROUP.ERROR, {
        path,
        status: response.status,
        logLevel: response.status === 400 ? 'warning' : 'error',
      });
    }
  }
  try {
    formGroupsSchema.parse(response.data.results);
    pageSchema.parse(
      convertToPage<DataWithPage<FormGroupsType>>(response.data),
    );
  } catch (error) {
    if (error instanceof ZodError) {
      throw new CustomError(error.message, {
        path,
        options,
        status: response.status,
        logLevel: 'error',
      });
    }
  }

  return response.data;
};
