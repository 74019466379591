import { Drawer, useDisclosure } from '@chakra-ui/react';
import { withSuspenseAndErrorBoundary } from 'admin/components/Error/WithErrorBoundary';
import { AttributeGroupCreateDrawer } from 'admin/components/idPoolConsumer/attributeGroup/attributeGroupDrawer/createDrawer/index';
import { AttributeGroupTitleComponent } from 'admin/components/idPoolConsumer/attributeGroup/attributeGroupTitle/AttributeGroupTitleComponent';
import { ErrorContents } from 'components/common/atoms';
import { FC, memo } from 'react';

export const attributeGroupTitle: FC = memo(() => {
  const { isOpen, onClose, onOpen } = useDisclosure();

  return (
    <>
      <AttributeGroupTitleComponent onOpen={onOpen} />
      {/* ドロワー */}
      <Drawer
        isOpen={isOpen}
        placement="right"
        onClose={onClose}
        size="lg"
        closeOnOverlayClick={false}
        closeOnEsc={false}
        autoFocus={false}
      >
        <AttributeGroupCreateDrawer onClose={onClose} />
      </Drawer>
    </>
  );
});

export const AttributeGroupTitle = withSuspenseAndErrorBoundary(
  attributeGroupTitle,
  {
    ErrorComponent: <ErrorContents name="属性グループ設定タイトル" />,
  },
);
