import { customErrorMessage, toZod } from 'admin/config/zod';
import { filterFieldUiTypeWithFieldMasterType } from 'admin/hooks/userPool/attribute/useIsShowMasterType';
import { codeGroupWithCodeSchema } from 'admin/schema/idPoolConsumer/master';
import { IdPoolConsumerAttributeType } from 'admin/types/userPool/attribute';
import { IdPoolConsumerAttributeCreateFormType } from 'admin/types/userPool/attribute/form';
import { z } from 'zod';
import { isPositiveInteger } from '../util/index';

// zodErrorの日本語化対応
z.setErrorMap(customErrorMessage);

export const attributeSchema = z.object<toZod<IdPoolConsumerAttributeType>>({
  id: z.string(),
  userPool: z.string(),
  codeGroup: z.string().nullable(),
  status: z.boolean(),
  isAutoAppendToChild: z.boolean(),
  displayNameForeign: z.string().optional().nullable(),
  displayNameJa: z.string(),
  fieldName: z.string().nullable(),
  dataType: z.string(),
  uiType: z.string(),
  jsonPathValueType: z.number().nullable().optional(),
  isPersonalInformation: z.boolean().optional(),
  jsonPath: z.string().nullable(),
  displayOrder: z.number(),
  isPreset: z.boolean().optional(),
  isFormAttr: z.boolean(),
  isDisplayAccountSettings: z.boolean().optional(),
});

// フォーム設問ページで使用する、属性 + 属性グループ用のschema定義
export const attributeAndGroupSchema = attributeSchema
  .omit({
    codeGroup: true,
    status: true,
    isFormAttr: true,
    dataType: true,
  })
  .extend({
    dataType: z.string().nullable(),
    codeGroup: codeGroupWithCodeSchema.nullable(),
  });

export const idPoolConsumerAttributesSchema = z.array(attributeSchema);

// フォーム設問ページで使用する、属性 + 属性グループ一覧用のschema定義
export const attributeAndGroupsSchema = z.array(attributeAndGroupSchema);

export const idPoolConsumerAttributeFormSchema = z
  .object<
    toZod<
      Omit<
        IdPoolConsumerAttributeCreateFormType,
        | 'userPool'
        | 'id'
        | 'isAutoAppendToChild'
        | 'status'
        | 'isFormAttr'
        | 'isDisplayAccountSettings'
      >
    >
  >({
    displayNameJa: z.string().min(1, '日本語は必須です。'),
    displayNameForeign: z.string().optional().nullable(),
    fieldName: z.string().min(1, 'フィールド名は必須です。'),
    uiType: z.string().min(1, 'UIタイプは必須です。'),
    dataType: z.string().min(1, 'データ型は必須です。'),
    codeGroup: z.string().nullable().optional(),
    jsonPath: z.string().nullable(),
    jsonPathValueType: z.string().nullable().optional(),
    isPersonalInformation: z.boolean().optional(),
    displayOrder: z
      .string()
      .or(z.number())
      .optional()
      .refine((val) => isPositiveInteger(String(val)), {
        message: '正数値のみが許可されます。小数点以下は含められません。',
      }),
    isPreset: z.boolean().optional(),
  })
  .superRefine((value, ctx) => {
    if (filterFieldUiTypeWithFieldMasterType.includes(value.uiType)) {
      if (value.codeGroup === '') {
        ctx.addIssue({
          code: 'custom',
          message: '選択肢マスタは必須です。',
          path: ['codeGroup'],
          fatal: true,
        });
      }
    }
  });
