import { Box, Flex, Text, Tooltip } from '@chakra-ui/react';
import { Link } from 'components/paywall/atoms/Link';
import { Colors } from 'components/paywall/config';
import { usePaywall } from 'components/paywall/hooks/usePaywall';
import { usePaywallUtil } from 'components/paywall/hooks/usePaywallUtil';
import { ItemDrawer } from 'components/paywall/pages/WallItemList/ItemDrawer';
import { PaywallCardList } from 'components/paywall/pages/WallItemList/PaywallCardList';
import { SearchForm } from 'components/paywall/pages/WallItemList/SearchForm';
import { Container } from 'components/paywall/templates/Container';
import { FC, memo } from 'react';

export const WallItemList: FC = memo(() => {
  const { wallName } = usePaywall({});
  const { formatText } = usePaywallUtil();

  return (
    <Container
      title={
        <Flex flexWrap="wrap">
          <Link to="/paywall/itemList">
            <Flex>
              <Text
                fontSize="14px"
                color={Colors.GRAY_20}
                fontWeight="700"
                cursor="pointer"
              >
                単品商品設定
              </Text>
            </Flex>
          </Link>
          <Text as="span" color={Colors.BLACK} fontWeight="700">
            &nbsp;/&nbsp;
          </Text>
          <Tooltip
            label={formatText({ text: wallName, trimCnt: 10 }).originText}
            bg="gray.100"
            color="black"
            isDisabled={formatText({ text: wallName, trimCnt: 10 }).isDisabled}
          >
            <Text>{formatText({ text: wallName, trimCnt: 10 }).trimText}</Text>
          </Tooltip>
        </Flex>
      }
      label={<ItemDrawer isAdd buttonName="新規作成" />}
      px="45px"
    >
      <Box my="45px">
        <SearchForm />
        <PaywallCardList />
      </Box>
    </Container>
  );
});
