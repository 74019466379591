import { zodResolver } from '@hookform/resolvers/zod';
import { withSuspenseAndErrorBoundary } from 'admin/components/Error/WithErrorBoundary';
import { FormComponent } from 'admin/components/idPoolConsumer/attributeGroup/attributeGroupForm/createForm/FormComponent';
import { useIdPoolConsumerId } from 'admin/hooks/useIdPoolConsumerId';
import { useAllAttributes } from 'admin/hooks/userPool/attribute/useAllAttributes';
import { useUpdateAttributeGroup } from 'admin/hooks/userPool/attributeGroup/useUpdateAttributeGroup';
import { editAttributeGroupSchema } from 'admin/schema/idPoolConsumer/attributeGroup';
import { EditAttributeGroupFormType } from 'admin/types/userPool/attributeGroup/form';
import { AttributeGroupType } from 'admin/types/userPool/attributeGroup/index';
import {
  AlertBar,
  ErrorContents,
  LoadingSpinner,
} from 'components/common/atoms';
import { FC, memo, useState } from 'react';
import { FormProvider, SubmitHandler, useForm } from 'react-hook-form';

type Props = {
  onClose: () => void;
  attributeGroup: AttributeGroupType;
};

const editForm: FC<Props> = memo(({ onClose, attributeGroup }: Props) => {
  const formId = 'editAttributeGroupForm';
  const idPoolConsumerId = useIdPoolConsumerId();
  const { data: attributes, isLoading: isLoadingAttributes } =
    useAllAttributes(idPoolConsumerId);

  const initAttributeGroupBodyList: EditAttributeGroupFormType['attributeGroupBodyList'] =
    attributeGroup.attributeGroupBodyList.map((item) => {
      if (!item) {
        return {
          userAttributeSetting: '',
          attributeRole: null,
        };
      }

      return {
        userAttributeSetting: String(item.userAttributeSetting.id) || '',
        attributeRole: item.attributeRole || null,
      };
    });

  if (
    initAttributeGroupBodyList.find((item) => item.attributeRole === 'address1')
  ) {
    if (
      !initAttributeGroupBodyList.find(
        (item) => item.attributeRole === 'address2',
      )
    ) {
      initAttributeGroupBodyList.push({
        userAttributeSetting: '',
        attributeRole: 'address2',
      });
    }
    if (
      !initAttributeGroupBodyList.find(
        (item) => item.attributeRole === 'address3',
      )
    ) {
      initAttributeGroupBodyList.push({
        userAttributeSetting: '',
        attributeRole: 'address3',
      });
    }
  }

  const methods = useForm<EditAttributeGroupFormType>({
    defaultValues: {
      displayNameJa: attributeGroup?.displayNameJa ?? '',
      displayNameForeign: attributeGroup?.displayNameForeign ?? '',
      displayType: attributeGroup?.displayType ?? '',
      displayOrder: String(attributeGroup?.displayOrder) ?? '',
      attributeGroupBodyList: [...initAttributeGroupBodyList],
    },
    resolver: zodResolver(editAttributeGroupSchema),
  });
  const [globalErrors, setGlobalErrors] = useState<string[]>([]);
  const { setError } = methods;

  const { mutate, isLoading } = useUpdateAttributeGroup({
    id: idPoolConsumerId,
    attributeGroupId: attributeGroup.id,
    setError,
    setGlobalErrors,
  });

  const onSubmit: SubmitHandler<EditAttributeGroupFormType> = async (
    data: EditAttributeGroupFormType,
  ) => {
    await mutate(data);
    onClose();
  };

  if (!attributes) {
    return null;
  }

  return (
    <FormProvider {...methods}>
      {globalErrors.map((err, idx) => (
        <AlertBar
          key={`global-err-idx${String(idx)}`}
          message={err}
          status="error"
          mb={4}
        />
      ))}
      <FormComponent
        status={attributeGroup.status}
        onClose={onClose}
        onSubmit={onSubmit}
        isLoading={isLoading || isLoadingAttributes}
        formId={formId}
        attributes={attributes}
        isEdit
      />
    </FormProvider>
  );
});

export const EditForm = withSuspenseAndErrorBoundary(editForm, {
  ErrorComponent: <ErrorContents name="属性グループ詳細" />,
  LoadingComponent: <LoadingSpinner />,
});
