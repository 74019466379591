import { VFC, useCallback } from 'react';
import { useFormContext, useFieldArray } from 'react-hook-form';
import { FormControl, FormLabel, IconButton, Box } from '@chakra-ui/react';
import { MdOutlineAddCircleOutline } from 'react-icons/md';
import { UserTenantEditForm } from 'api/user/types';
import {
  IndentGroupListType,
  useIndentGroupList,
} from 'hooks/tenant/useIndentGroupList';
import { useUserTenantId } from 'hooks/user/useUserTenantId';
import { useCustomToast } from 'hooks/useCustomToast';
import { GroupSelect } from 'components/setting/users/atoms/GroupSelect';

// TODO: 複数inputに対するエラー表示処理
export const MultiInputForm: VFC = () => {
  const tenantId = useUserTenantId();
  const groupList = useIndentGroupList(tenantId);
  const toast = useCustomToast();
  const { control } = useFormContext<UserTenantEditForm>();

  const { fields, append, remove } = useFieldArray({
    control,
    name: 'groups',
    keyName: 'key',
  });

  // グループinput追加
  const addGroup = () => {
    const addGroupData: IndentGroupListType =
      groupList.length > 0 ? groupList[0] : { id: '', name: '' };
    append(addGroupData);
  };

  // グループinput削除処理
  const removeGroup = useCallback(
    (index: number) => {
      if (fields.length <= 1) {
        toast({
          status: 'error',
          position: 'bottom',
          duration: 2000,
          isClosable: true,
          title: '全てのグループを削除する事はできません',
        });

        return;
      }

      remove(index);
    },
    [fields, toast, remove],
  );

  return (
    <>
      <FormControl>
        <FormLabel htmlFor="groups" fontSize="sm">
          所属グループ
        </FormLabel>
        {fields.map((field, index) => (
          <Box key={field.key} mb={2}>
            <GroupSelect
              index={index}
              name={`groups.${index}.id`}
              fields={fields}
              groupList={groupList}
              remove={removeGroup}
            />
          </Box>
        ))}
      </FormControl>
      <Box textAlign="center">
        <IconButton
          variant="ghost"
          aria-label="addGroups"
          fontSize={20}
          icon={<MdOutlineAddCircleOutline />}
          onClick={() => addGroup()}
        />
      </Box>
    </>
  );
};
