import { Box, Flex, Image, Link, Tag, Text } from '@chakra-ui/react';
import { Copy } from 'components/common/atoms';
import { Colors } from 'components/paywall/config';
import {
  CardItem,
  CardItemBlock,
  CardItemList,
  CardItemListItem,
} from 'components/paywall/features/Card';
import { StatusTag } from 'components/paywall/features/StatusTag';
import { WallItem } from 'components/paywall/pages/WallItemList/typed';
import { format } from 'date-fns';
import { useAmountUtil } from 'hooks/useAmountUtil';
import { useCopy } from 'hooks/useCopy';
import { FC, memo } from 'react';

type Props = {
  wallItem: WallItem;
};
type StatusType = {
  code: number;
  name: string;
  status: number;
};

const statusTypes: StatusType[] = [
  { code: 1, name: '開始前', status: 1 },
  { code: 2, name: '実施中', status: 2 },
  { code: 1, name: '終了済み', status: 3 },
];

const WallItemSellType = {
  period: '期間限定',
  indefinite: '無期限',
};
const DATETIME_FORMAT = 'yyyy/MM/dd HH:mm';

export const PaywallCardItem: FC<Props> = memo(({ wallItem }: Props) => {
  const { amountFormat, calcTaxAmount } = useAmountUtil();
  const { copy } = useCopy();

  const formatDate = (date: string | null): string =>
    date ? format(new Date(date), DATETIME_FORMAT) : '';

  const sellPeriod = (): string => {
    let period = '';
    if (wallItem.sellStart) period += `${formatDate(wallItem.sellStart)} ～ `;
    if (wallItem.sellEnd) {
      if (period === '') period += ' ～ ';
      period += formatDate(wallItem.sellEnd);
    }

    return period;
  };
  const rightsValidityPeriod = () => {
    const wallItemPeriod =
      wallItem.period !== null ? `${wallItem.period}日間` : '';

    return wallItem.sellType === 'indefinite'
      ? WallItemSellType[wallItem.sellType]
      : wallItemPeriod;
  };
  const tagStringToArray = (): string[] =>
    wallItem.tags !== null ? wallItem.tags.split('\n') : [];

  const getStatus = (statusCode: number) => {
    const result = statusTypes.filter((item) => item.status === statusCode);

    // ステータスが見つからなかった場合はdefaultを返却する
    if (result.length === 0)
      return {
        code: 0,
        name: '',
        status: 0,
      };

    return result[0];
  };

  return (
    <CardItem spacing="32px">
      <CardItemBlock w="250px">
        <Box
          h="131px"
          borderWidth="1px"
          borderColor={Colors.GRAY_50}
          borderStyle="solid"
        >
          <Image
            w="100%"
            h="100%"
            objectFit="contain"
            borderRadius="8px"
            src={wallItem.thumbnailUrl}
            alt="No Image"
          />
        </Box>
        <Text
          fontSize="14px"
          lineHeight="140%"
          fontWeight="400"
          color={Colors.GRAY_03}
          mt="12px"
        >
          {wallItem.description}
        </Text>
      </CardItemBlock>

      <CardItemList spacing="8px">
        {/* 税抜価格 */}
        <CardItemListItem
          labelMinW="100px"
          label="税抜価格"
          text={`${amountFormat(wallItem.price)}円`}
        />
        {/* 税込価格 */}
        <CardItemListItem
          labelMinW="100px"
          label="税込価格"
          text={`${calcTaxAmount(wallItem.price)}円`}
        />
        {/* 購入タイプ */}
        <CardItemListItem
          labelMinW="100px"
          label="購入タイプ"
          text={WallItemSellType[wallItem.sellType]}
        />
        {/* 権利有効期間 */}
        <CardItemListItem
          labelMinW="100px"
          label="権利有効期間"
          text={rightsValidityPeriod()}
        />
        {/* 販売期間 */}
        <CardItemListItem
          labelMinW="100px"
          label="販売期間"
          text={sellPeriod()}
        />
        {/* ステータス */}
        <CardItemListItem
          labelMinW="140px"
          label="ステータス"
          text={
            <StatusTag
              type={getStatus(wallItem.sellStatus).code}
              status={getStatus(wallItem.sellStatus).name}
            />
          }
        />
        {/* システム番号 */}
        <CardItemListItem
          labelMinW="100px"
          label="システム番号"
          text={`${wallItem.systemCode}`}
        />
        {/* 記事URL */}
        <CardItemListItem>
          <Box width="84px" minW="100px">
            <Text color={Colors.GRAY_10}>記事URL</Text>
          </Box>
          <Flex maxW="calc(100% - (84px + 12px))">
            <Link
              w="100%"
              href={wallItem.url}
              textDecoration="underline"
              isExternal
            >
              {wallItem.url}
              <Copy
                label="記事URLをコピー"
                color="#09f"
                fontSize={14}
                isAbsolute={false}
                onCopy={() => {
                  copy(wallItem.url);
                }}
              />
            </Link>
          </Flex>
        </CardItemListItem>
        {/* OGP */}
        <CardItemListItem
          labelMinW="100px"
          label="OGP利用フラグ"
          text={`${wallItem.useOgpFlag ? '有効' : '無効'}`}
        />
        {/* タグ */}
        <CardItemListItem>
          <Box width="84px" minW="100px">
            <Text color={Colors.GRAY_10}>タグ</Text>
          </Box>
          <Flex
            maxW="calc(100% - (84px + 12px))"
            flexWrap="wrap"
            style={{ gap: '4px' }}
          >
            {tagStringToArray().map((tag, idx) => (
              <Tag
                px="8px"
                key={`${tag}-${String(idx)}`}
                bgColor={Colors.BLUE_LIGHT_01}
                color={Colors.GRAY_03}
              >
                {tag}
              </Tag>
            ))}
          </Flex>
        </CardItemListItem>
      </CardItemList>
    </CardItem>
  );
});
