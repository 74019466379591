import { Box, BoxProps, Grid, Text } from '@chakra-ui/react';
import { memo, useEffect, useState, VFC } from 'react';
import InfiniteScroll from 'react-infinite-scroller';
import { LoadingSpinner } from './LoadingSpinner';

type InfinityScrollerProps = {
  uid: string;
  fetchData: (page: number) => Promise<{
    isNextPage: boolean;
    results: string[];
  }>;
  total: number;
  limit?: number;
} & BoxProps;

type TextListType = {
  id: string;
  text: string;
};

type OriginListType = {
  [id: string]: TextListType[];
};

// フリーテキストエリアのリスト（初期化が必要な為、最初はダミーデータを挿入）
const originList: OriginListType = { '000': [] };

export const InfinityScroller: VFC<InfinityScrollerProps> = memo(
  ({ uid, fetchData, total, limit = 0, ...rest }: InfinityScrollerProps) => {
    const [list, setList] = useState<TextListType[]>([]); // 表示するデータ
    const [hasMore, setHasMore] = useState(true); // 再読み込み判定
    const [isNext, setIsNext] = useState(true);

    // 項目を読み込むときのコールバック
    const loadMore = async (page: number) => {
      // 表示上限を超える場合は無限スクロールを解除
      if (limit <= list.length) {
        setHasMore(false);

        return;
      }
      // 次ページが存在しない場合は無限スクロールを解除
      if (!isNext) {
        setHasMore(false);

        return;
      }

      // フリーアンサーページ情報取得
      const { isNextPage, results } = await fetchData(page);

      setIsNext(isNextPage);

      // 以下処理はデータの蓄積が上手く動作しなかったため、あらかじめ全ての
      // 配列をoriginListに集積し、重複しないようなリストを都度生成する
      const tmpList: TextListType[] = results.map((item, idx) => ({
        id: `${page}-${idx}`,
        text: item,
      }));

      // 既存の配列を取得
      const tmpOriginList = uid in originList ? originList[uid] : [];
      // API実行時に取得した配列を既存配列にマージする
      const mergeOriginList: TextListType[] = tmpOriginList.concat(tmpList);
      // {chartId: list[]}の形で保持する
      originList[uid] = mergeOriginList;
      // ダミーデータを削除する
      if ('000' in originList) delete originList['000'];

      // chartidに紐づいている取得データをリストに追加
      setList(originList[uid]);
      setHasMore(true);
    };

    useEffect(() => {
      if (list.length === 0) setHasMore(false);
    }, [list]);

    // ロード中に表示する項目
    const loader = <LoadingSpinner key={0} />;

    return (
      <>
        {total > limit && (
          <Box fontSize="xs" mb={2}>
            {`最大で直近 ${limit} 件までを表示、${
              limit + 1
            } 件以降はCSVをダウンロードしてご確認ください。`}
          </Box>
        )}
        <Box overflow="auto" {...rest}>
          <InfiniteScroll
            pageStart={0}
            loadMore={loadMore}
            hasMore={hasMore}
            loader={loader}
            useWindow={false}
          >
            <Grid mt={1} gap={1}>
              {list.map((item) => (
                <Text
                  key={`${item.id}`}
                  bg="gray.100"
                  p={2}
                  borderRadius="4px"
                  whiteSpace="pre-wrap"
                >
                  {item.text}
                </Text>
              ))}
            </Grid>
          </InfiniteScroll>
        </Box>
      </>
    );
  },
);
