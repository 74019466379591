import { Box, Flex, Tag, Text, Tooltip } from '@chakra-ui/react';
import { useApiOrderList } from 'api/paywall/useApi';
import { Colors } from 'components/paywall/config';
import { StatusTag } from 'components/paywall/features/StatusTag';
import { Td, Tr } from 'components/paywall/features/Table';
import { useTableNavigation } from 'components/paywall/features/Table/TableNavigation/hooks/useTableNavigation';
import { ApiOrderList, OrderListResult } from 'components/paywall/pages/OrderList/typed';
import { useFormatDate } from 'hooks/useFormatDate';
// import { format } from 'date-fns';
import { FC, Fragment, memo, useEffect, useState } from 'react';
import { NavLink } from 'react-router-dom';

const DATETIME_FORMAT = 'yyyy/MM/dd HH:mm';

export const OrderRows: FC = memo(() => {
  const { data, isLoading, isFetching } = useApiOrderList();
  const [results, setResults] = useState<OrderListResult[]>([]);
  const { setCount } = useTableNavigation();
  // const formatDate = (date: string | null): string => date ? format(new Date(date), DATETIME_FORMAT): '';
  const tagStringToArray = (tags: string | null): string[] => tags !== null ? tags.split('\n') : [];
  const { dateTimeFormat } = useFormatDate();

  useEffect(() => {
    if (isLoading || isFetching) return;
    if (!data) return;
    const res = data as unknown as ApiOrderList;
    setResults(res.data.results);
    setCount(res.data.count);
  }, [data, isFetching, isLoading, setCount, setResults]);

  // 決済ステータス
  const tranStatusType = [
    {code: 1, tranStatus: 1, name:'支払い済み'},
    {code: 3, tranStatus: 5, name:'購入取り消し'},
    {code: 5, tranStatus: 9, name:'決済エラー'},
    {code: 1, tranStatus: 0, name:'その他'},
    {code: 1, tranStatus: 6, name:'返金済み'},
  ]

  // 決済一覧情報 > 決済ステータス用ステータスコード取得
  const getTranStatus = (tranStatusCode: number) => {
    const tranStatusResult = tranStatusType.filter((status) => status.tranStatus === tranStatusCode)

    if(tranStatusResult.length === 0) return{
      code: 0,
      name: '',
      tranStatus: 0
    }

    return tranStatusResult[0];
  }

  return (
    <>
      {results.map((row: OrderListResult) => (
        <Tr key={row.id}>
          <Td fontSize="12px" fontWeight="700" columnFix >
            <NavLink
              to={`/paywall/${row.id}/orderDetail`}
              style={{ textDecoration: 'underline'}}
            >
              {row.id}
            </NavLink>
          </Td>
          <Td>{dateTimeFormat(row.execTranDate)}</Td>
          <Td>{row.wallOrder.course ? row.wallOrder.course : row.wallOrder.item}</Td>
          <Td style={{ width: '15%' }}>{row.wallOrder.courseName ? row.wallOrder.courseName : row.wallOrder.itemName}</Td>
          <Td textAlign='right'>{`${row.price.toLocaleString()}円`}</Td>
          <Td>
            <StatusTag type={getTranStatus(row.tranStatus).code} status={getTranStatus(row.tranStatus).name}/>
            {row.cardErrorInfo.map((info, idx) => (
              <Fragment key={`${info.code}_${String(idx)}`}>
                <Box>
                  <Tooltip
                    hasArrow
                    label={<Box>{info.message}</Box>}
                  >
                    <Text display="inline-block" fontWeight="700" cursor="default">{info.code}</Text>
                  </Tooltip>
                </Box>
              </Fragment>
            ))}
          </Td>
          <Td>{row.email}</Td>
          <Td>{row.customerUid}</Td>
          <Td>{row.wallOrder.systemCode}</Td>
          <Td>
            <Flex flexWrap="wrap" style={{gap: '4px'}}>
              {tagStringToArray(row.wallOrder.tags).map((tag, idx) => (
                <Tag px="8px" key={`${tag}-${String(idx)}`} bgColor={Colors.BLUE_LIGHT_01} color={Colors.GRAY_03}>
                  {tag}
                </Tag>
              ))}
            </Flex>
          </Td>
        </Tr>
      ))}
    </>
  );
});
