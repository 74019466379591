import { Box } from '@chakra-ui/react';
import { LoginAuthModeValue, LoginAuthType, LoginFormType, SamlLoginResponseType } from 'api/user/types';
import { useRouteAuth } from 'auth/authContext';
import { LoginErrorMessage } from 'components/login/loginErrorMessage';
import { LoginFormComponent } from 'components/login/loginForm/LoginFormComponent';
import {
  AUTH_CODE_TYPE_GIDP, AUTH_CODE_TYPE_SAML, LOGIN_AUTH_MODE_EMAIL,
  LOGIN_AUTH_MODE_GIDP_AUTH
} from 'define';
import { useCustomToast } from 'hooks/useCustomToast';
import { useAuthType } from 'hooks/user/useAuthType';
import { useSamlAuthentication } from 'hooks/user/useSamlAuthentication';
import { Dispatch, FC, memo, SetStateAction, useCallback, useMemo, useState } from 'react';
import { FormProvider, SubmitHandler, useForm } from 'react-hook-form';
import { toMultiError } from 'utils/form';
import { errorToast } from 'utils/toast';

type Props = {
  authMode: LoginAuthModeValue;
  setAuthMode: Dispatch<SetStateAction<LoginAuthModeValue>>
}

export const LoginForm: FC<Props> = memo(({authMode, setAuthMode}: Props) => {
  const [isFirstRender, setIsFirstRender] = useState<boolean>(true);
  const toast = useCustomToast();
  const formId = 'login-form';
  const methods = useForm<LoginFormType>({
    defaultValues: {
      email: '',
      password: '',
    },
  });
  const { setError } = methods;
  const { onSubmit: onVerifyEmailAuthType, isLoading: isVerifyEmailLoading } = useAuthType({ setError });
  const { onLogin, isLoading: isLoginLoading } = useRouteAuth();
  const { onSamlLogin, isLoading: isSamlLoginLoading } = useSamlAuthentication();
  const isLoading = useMemo(() => (isVerifyEmailLoading || isLoginLoading), [isVerifyEmailLoading, isLoginLoading]);

  // 認証モード切替
  const onChangeMode = useCallback(async (auth: LoginAuthType) => {
    // GIDP認証
    if(auth.authType.code === AUTH_CODE_TYPE_GIDP) {
      setAuthMode(LOGIN_AUTH_MODE_GIDP_AUTH);

      return;
    }

    // SAML認証
    if(auth.authType.code === AUTH_CODE_TYPE_SAML) {
      const samlResponse = await new Promise<SamlLoginResponseType>((resolve) => {
        onSamlLogin({
          email: auth.email,
          userPoolId: auth.userPoolId,
          resolve
        });
      });

      window.location.replace(samlResponse.redirectUrl);
    }
  }, [onSamlLogin, setAuthMode]);

  /**
   * 各種認証処理
   * authMode1: メールアドレスによる認証・AuthType取得
   * authMode2: gidp経由のログイン認証
   * authMode3: saml経由のログイン認証
   * ※SPAのエレメントを切り替える実装の為Submitは共通とした
   * @param data LoginFormType
   */
  const onSubmitHandler: SubmitHandler<LoginFormType> = async (data: LoginFormType) => {
    let hasError = false;
    const { email, password } = data;

    // メールアドレス認証
    if (authMode === LOGIN_AUTH_MODE_EMAIL) {
      if (!email) {
        setError('email', {
          types: toMultiError(['メールアドレスは必ず入力してください']),
        });
        hasError = true;
      }

      if (hasError) {
        toast({
          ...errorToast,
          duration: 4000,
          title: 'エラーがあります',
        });

        return;
      }

      const responseLoginAuthType = await new Promise<LoginAuthType>((resolve) => {
        onVerifyEmailAuthType({
          email,
          password: '',
          resolve
        });
      });

      await onChangeMode(responseLoginAuthType);
      // メール認証成功後は初回レンダリングではなくなる為falseにする
      setIsFirstRender(false);

      return;
    }

    // GIDP認証
    if (authMode === LOGIN_AUTH_MODE_GIDP_AUTH) {
      await onLogin(email, password);
    }
  };

  return (
    <Box>
      {/* 初回レンダリング時のみ表示を行う想定 */}
      {isFirstRender && (
        <LoginErrorMessage />
      )}
      <Box mt="1rem">
        <FormProvider {...methods}>
          <LoginFormComponent
            authMode={authMode}
            formId={formId}
            isLoading={isLoading||isSamlLoginLoading}
            onSubmit={onSubmitHandler}
          />
        </FormProvider>
      </Box>
    </Box>
  );
});
