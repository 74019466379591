import { Textarea, VStack } from '@chakra-ui/react';
import { Detail } from 'components/paywall/features/Detail';
import { DetailMemoDialog } from 'components/paywall/features/Detail/DetailDialog/DetailMemoDialog';
import { FC, memo, useState } from 'react';

type Props = {
  orderId: string;
  customerUid: string;
};

export const CustomerDetailInnerMemo: FC<Props> = memo(({orderId, customerUid}: Props) => {
  const [memoTxt, setMemoTxt] = useState<string>('');

  return (
    <>
      <Detail type={3} title="編集メモ入力欄">
        <VStack spacing="40px" alignItems="flex-end">
          <Textarea
            resize="none"
            height="193px"
            value={memoTxt}
            onChange={(e) => setMemoTxt(e.target.value)}
          />
          <DetailMemoDialog
            orderId={orderId}
            customerUid={customerUid}
            memo={memoTxt}
            setMemoTxt={setMemoTxt}
            type="customer"
          />
        </VStack>
      </Detail>
    </>
  );
});
