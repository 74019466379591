import {
  Button,
  DrawerBody,
  DrawerFooter,
  HStack,
  Text,
} from '@chakra-ui/react';
import { ColorSet } from 'admin/define/colors';
import { TransactionMailEditFormType } from 'admin/types/userPool/transactionMail/form';
import { FC, memo } from 'react';
import { useFormContext } from 'react-hook-form';

export type Props = {
  formId: string;
  isLoading: boolean;
  onClose: () => void;
  onSubmit: (value: TransactionMailEditFormType) => void;
};

export const TransactionMailStatusChangeFormComponent: FC<Props> = memo(
  ({ formId, isLoading, onClose, onSubmit }) => {
    const { handleSubmit, watch } =
      useFormContext<TransactionMailEditFormType>();
    const afterStatus = watch('status');

    return (
      <>
        <DrawerBody fontSize="16px" lineHeight="1.7" py={0} mb="8px">
          <form onSubmit={handleSubmit(onSubmit)} id={formId}>
            <Text fontSize="sm">
              使用設定を
              <Text as="span" fontWeight={700}>
                「{afterStatus ? '使用中' : '停止中'}」
              </Text>
              にします。
            </Text>
            <Text fontSize="sm">
              <Text as="span" fontWeight={700}>
                「{afterStatus ? '使用中' : '停止中'}」
              </Text>
              にしたメール設定
              {afterStatus ? (
                <Text as="span" fontWeight={700}>
                  のみが有効
                </Text>
              ) : (
                <>
                  が
                  <Text as="span" fontWeight={700}>
                    無効
                  </Text>
                </>
              )}
              になります。
            </Text>
          </form>
        </DrawerBody>
        <DrawerFooter>
          <HStack spacing={4}>
            <Button
              variant="outline"
              onClick={() => onClose()}
              {...ColorSet.Default}
              padding="8px 16px"
            >
              キャンセル
            </Button>
            <Button
              colorScheme="primary"
              type="submit"
              mr={3}
              form={formId}
              disabled={isLoading}
              padding="8px 36px"
            >
              実行
            </Button>
          </HStack>
        </DrawerFooter>
      </>
    );
  },
);
