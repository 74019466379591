import React, {
  VFC,
  useState,
  useEffect,
  useCallback,
  memo,
  useRef,
} from 'react';
import { useFormContext } from 'react-hook-form';
import ReactDataSheet from 'react-datasheet';
import { Box } from '@chakra-ui/react';
import {
  GridTable,
  GridRow,
  GritTableColumnType,
  GridCell,
  DataType,
  SelectOptionType,
  SelectViewer,
  SelectEditor,
  CheckBoxEditor,
  CheckBoxViewer,
} from 'components/lib/react-datasheet';
import { isFalse } from 'utils/bool';
import { isBlank, isStrTest } from 'utils/str';
import { toErrMsgList } from 'utils/form';
import {
  SCHEMA_COLUMNS,
  SCHEMA_DATA_TYPE,
  SchemaRowType,
  SchemaType,
  ContentsDbTblFormType,
} from 'api/contents/types';
import { ErrorTextMsg } from 'components/common/atoms';
import { SchemaAddRowBtn } from 'components/contentsdb/atoms/SchemaAddRowBtn';

type CustomDataType = {
  key: typeof SCHEMA_COLUMNS[number];
  value: string;
} & DataType;

// Cellのデータ型
type GridElement = CustomDataType & ReactDataSheet.Cell<CustomDataType>;

// フォーム登録用のテンプレートデータ(1行分の空データ)
const templateTblRow = SCHEMA_COLUMNS.reduce(
  (acc, cur) => ({ ...acc, [cur]: '' }),
  {},
) as SchemaRowType;

// 列のデータ型の選択肢
const dataTypeOption: SelectOptionType[] = [
  { label: '', value: '' },
  ...SCHEMA_DATA_TYPE,
];

const LABEL_PK = '主キー';
const LABEL_DISPLAYNAME = '表示名';
const LABEL_COLUMN = 'カラム名';
const LABEL_DATATYPE = 'データ型';

// 行番号を表示する場合
// components/lib/GridTable.tsx の 行番号の Th に定義した
// 列幅を合わせて100%になるように調整します。
const columns: GritTableColumnType[] = [
  {
    label: LABEL_PK,
    width: '12%',
    hint: '全てのデータで一意になる列をチェックします。例えば商品IDや会員IDなどの列が主キーになりえます。',
  },
  {
    label: LABEL_COLUMN,
    width: '30%',
    hint: 'DBに設定する列名で、半角英数字とアンダースコアのみ入力可能です。',
  },
  {
    label: LABEL_DATATYPE,
    width: '16%',
    hint: 'このカラムに格納する値の種類を選択します。URLやコード、メールアドレスなど文字列全体で意味を表すものは「文字列」、タイトルや商品名など表記揺らぎも含めて検索する場合は「テキスト」、価格など整数を格納する場合は「整数型」、誕生日などを格納する場合は「年月日」、時間まで指定する場合は「日時」を選択します。',
  },
  {
    label: LABEL_DISPLAYNAME,
    width: '30%',
    hint: 'カラムを説明する名前(日本語名など)を設定します。',
  },
];

// 行追加する際に利用するデータ型
// 配列の各要素はそれぞれの列に対応します
const templateData: GridElement[] = [
  {
    key: 'pk',
    align: 'center',
    value: '',
    dataEditor: CheckBoxEditor,
    valueViewer: CheckBoxViewer,
  },
  {
    key: 'column',
    value: '',
  },
  {
    key: 'dataType',
    value: '',
    options: dataTypeOption,
    dataEditor: SelectEditor,
    valueViewer: SelectViewer,
  },
  {
    key: 'displayName',
    value: '',
  },
  // notNull の設定欄は非表示
  // {
  //   key: 'notNull',
  //   value: '',
  // },
];

// 初期表示する行数
const INIT_ROWS = 20;
// const INIT_CELL_DATA = [...Array(INIT_ROWS).keys()].map(() => [
//   ...templateData,
// ]);

type TableSchemaProps = {
  setSchemaData?: (arg: SchemaType) => void;
  getValues?: (arg?: string) => SchemaType;
  initValue?: GridElement[][];
  readonly?: boolean;
};

const TableSchema: VFC<TableSchemaProps> = memo(
  ({ setSchemaData, getValues = () => undefined, initValue, readonly }) => {
    // 描画時点で初期値を生成しないと
    // 画面を閉じても元の値が表示されるため注意
    const initData =
      initValue || [...Array(INIT_ROWS).keys()].map(() => [...templateData]);
    const [cellData, setCellData] = useState<GridElement[][]>(initData);
    // 更新による再レンダリング防止のため ref で対応
    const shemaDataRef = useRef<SchemaType>({});

    const handleChanges = useCallback(
      (changes: ReactDataSheet.CellsChangedArgs<GridElement>) => {
        const newFormData = changes.reduce<{
          [key: number]: SchemaRowType;
        }>((acc, { row, col, value, cell }) => {
          setCellData((current) => {
            // ここでの引数更新は影響のない更新のためルール変更
            // eslint-disable-next-line no-param-reassign
            current[row][col] = { ...current[row][col], value: value || '' };

            return current;
          });

          const fieldkey = cell?.key || '';

          if (!fieldkey) {
            return acc;
          }

          const formRowNum = row + 1;
          const rowData =
            !acc || !(formRowNum in acc)
              ? { ...templateTblRow }
              : acc[formRowNum];

          const oldTblSchema = getValues('tblSchema') || [];
          const mergedRowData = {
            ...(oldTblSchema[formRowNum] || {}),
            ...{ [fieldkey]: value },
          };

          return {
            ...acc,
            [formRowNum]: { ...rowData, ...mergedRowData },
          };
        }, shemaDataRef.current);

        if (setSchemaData) {
          setSchemaData(newFormData);
        }

        shemaDataRef.current = newFormData;
      },
      [shemaDataRef, setSchemaData, getValues],
    );

    const valueRenderer = useCallback((cell: GridElement) => cell.value, []);

    const seetRenderer = useCallback(
      (props) => (
        <GridTable<GridElement> {...props} columns={columns} rownumber />
      ),
      [],
    );

    const rowRenderer = useCallback(
      (props) => (
        <GridRow<GridElement>
          rownumber
          rownumberStyle={{ textAlign: 'center', background: '#e9faf9' }}
          {...props}
        />
      ),
      [],
    );

    const cellRenderer = useCallback(
      (
        props: React.PropsWithChildren<
          ReactDataSheet.CellRendererProps<GridElement, string>
        >,
      ) => {
        const { cell } = props;
        // key: "displayName"のcell:readonly定義をパーミッションを元に権限を変更
        if (cell.key === 'displayName') {
          cell.readOnly = false;
        }
        // この段階での style 指定はないため
        // propsから除去して破棄
        const { style: _, ...rest } = props;
        const align = 'align' in cell && cell.align ? cell.align : 'left';

        return <GridCell<GridElement> style={{ textAlign: align }} {...rest} />;
      },
      [],
    );

    return (
      <Box>
        <Box
          h="400px"
          style={{ overflowY: 'scroll' }}
          borderWidth={1}
          borderColor="gray.300"
        >
          <ReactDataSheet
            data={cellData}
            valueRenderer={valueRenderer}
            sheetRenderer={seetRenderer}
            cellRenderer={cellRenderer}
            rowRenderer={rowRenderer}
            onCellsChanged={handleChanges}
          />
        </Box>
        {readonly ? null : (
          <Box mt={4}>
            <SchemaAddRowBtn
              templateData={templateData}
              setData={setCellData}
            />
          </Box>
        )}
      </Box>
    );
  },
);

/**
 * テーブル定義入力チェック
 *
 * 一般的なテキストボックスなどは focusout のタイミングで
 * 入力チェックを行いますが、テーブル定義はテキストボックスと
 * 異なり、複数のデータをまとめて入力するため focusout で
 * チェックすると、常にエラーメッセージが表示される
 * 状態となります。
 *
 * そのため、この入力チェックは Submit ボタン実行時に
 * 行います。
 *
 * @param schemaData SchemaType 入力したテーブル定義
 * @returns [登録可能データリスト, エラーメッセージリスト]
 */
export const tblSchemaValidate = (
  schemaData: SchemaType,
): [SchemaRowType[], string[]] => {
  if (!schemaData || Object.keys(schemaData).length === 0) {
    return [[], ['テーブル定義を入力ください']];
  }

  const registData: SchemaRowType[] = [];

  let isPkExists = false;
  const colNames: string[] = [];
  const errMsgs: string[] = [];
  Object.keys(schemaData).forEach((rowNum) => {
    const row = schemaData[Number(rowNum)];
    const { pk, column, displayName, dataType, notNull, isSort, rule } = row;

    const isEmptyRow =
      isBlank(column) && isBlank(displayName) && isBlank(dataType);

    // 未入力行はスキップ
    if (isEmptyRow) {
      return;
    }

    const PTN_START_SPACE_NG = '^\\s';
    const PTN_END_SPACE_NG = '\\s$';
    const PTN_COLUMN_START_OK = '^[a-z]';
    const PTN_COLUMN_END_OK = '[a-z0-9]$';
    const PTN_COLUMN_OK = '^[a-z]([a-z0-9_]+)?([a-z0-9]+)?$';

    // カラム名のチェック
    if (isBlank(column)) {
      errMsgs.push(`${rowNum}行目: ${LABEL_COLUMN} を入力してください`);
    } else if (
      isStrTest(column, PTN_START_SPACE_NG) ||
      isStrTest(column, PTN_END_SPACE_NG)
    ) {
      errMsgs.push(
        `${rowNum}行目: ${LABEL_COLUMN} の前後に空白があります。除去してください`,
      );
    } else if (!isStrTest(column, PTN_COLUMN_START_OK)) {
      errMsgs.push(
        `${rowNum}行目: ${LABEL_COLUMN} 1文字目は小文字の英字で入力ください`,
      );
    } else if (!isStrTest(column, PTN_COLUMN_END_OK)) {
      errMsgs.push(
        `${rowNum}行目: ${LABEL_COLUMN} 最後の文字は小文字の英数字で入力ください`,
      );
    } else if (!isStrTest(column, PTN_COLUMN_OK)) {
      errMsgs.push(
        `${rowNum}行目: ${LABEL_COLUMN} 小文字の半角英数字とアンバーバー(_)のみ入力可能です`,
      );
    } else if (colNames.includes(column)) {
      errMsgs.push(
        `${rowNum}行目: ${LABEL_COLUMN} 「${column}」が重複してます`,
      );
    } else {
      colNames.push(column);
    }

    // データ型のチェック
    if (isBlank(dataType)) {
      errMsgs.push(`${rowNum}行目: ${LABEL_DATATYPE} を入力してください`);
    } else if (!dataTypeOption.some((option) => option.value === dataType)) {
      errMsgs.push(
        `${rowNum}行目: ${LABEL_DATATYPE} データ型は選択肢よりお選びください`,
      );
    }

    registData.push({
      pk,
      column,
      displayName,
      dataType,
      notNull,
      isSort,
      rule,
    });

    // まだPKがなかったら
    // いずれかの行にPKがあることを確認
    if (!isPkExists) {
      isPkExists = !isFalse(pk);
    }
  });

  if (!isPkExists) {
    errMsgs.push(`いずれかの行で ${LABEL_PK} を選択してください`);
  }

  if (errMsgs.length > 0) {
    return [[], errMsgs];
  }

  return [registData, []];
};

type SchemaCreateFormProps = {
  value?: SchemaRowType[];
  readonly?: boolean;
};

export const SchemaCreateForm: VFC<SchemaCreateFormProps> = ({
  value = undefined,
  readonly = false,
}) => {
  const name = 'tblSchema';
  const {
    setValue,
    getValues,
    formState: { errors },
  } = useFormContext<ContentsDbTblFormType>();

  const errMsgList = toErrMsgList(errors, name);

  const [schemaData, setSchemaData] = useState<SchemaType>(
    value
      ? value.reduce((acc, cur, idx) => {
          // eslint-disable-next-line no-param-reassign
          acc[idx + 1] = cur;

          return acc;
        }, {} as SchemaType)
      : {},
  );

  useEffect(() => {
    setValue('tblSchema', schemaData);
  }, [setValue, schemaData]);

  const initValue = value?.map((row) =>
    Object.keys(row)
      .map((k) => {
        const schemaKey = k as keyof SchemaRowType;
        const v = row[schemaKey];
        const gridelement = templateData.find((t) => t.key === schemaKey);

        if (!gridelement) {
          return undefined;
        }

        return {
          ...gridelement,
          value: v,
          readOnly: readonly,
        };
      })
      .filter(Boolean),
  ) as GridElement[][];

  return (
    <Box>
      {errMsgList.length ? (
        <Box mb={4}>
          {errMsgList.map((err, idx) => (
            <ErrorTextMsg key={`error-${name}-${String(idx)}`} msg={err} />
          ))}
        </Box>
      ) : null}
      <TableSchema
        setSchemaData={setSchemaData}
        getValues={getValues}
        initValue={initValue}
        readonly={readonly}
      />
    </Box>
  );
};
