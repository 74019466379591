import { TableHeadProps, Thead as ChakraThead } from '@chakra-ui/react';
import { Colors } from 'admin/define/colors';
import { FC, memo, ReactNode } from 'react';

type Props = {
  children: ReactNode;
} & TableHeadProps;

export const Thead: FC<Props> = memo(({ children, ...rest }: Props) => (
  <ChakraThead
    px="16px"
    position="sticky"
    zIndex="sticky"
    top={0}
    bgColor={Colors.GRAY_70}
    {...rest}
  >
    {children}
  </ChakraThead>
));
