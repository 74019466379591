import { patchLoginSetting } from 'api/tenant/patchLoginSetting';
import { isLoginSetting, LoginSetting } from 'api/tenant/types';
import { ResponseError } from 'api/types';
import { queryTenantKey } from 'hooks/tenant/queryTenantKey';
import { useCustomToast } from 'hooks/useCustomToast';
import { useUserTenantId } from 'hooks/user/useUserTenantId';
import { UseFormSetError } from 'react-hook-form';
import { UseMutateFunction, useMutation, useQueryClient } from 'react-query';
import { isGetValidationError, toMultiError } from 'utils/form';
import { successToast } from 'utils/toast';

type UnPromisify<T> = T extends Promise<infer U> ? U : T;

const LoginSettingFormData: LoginSetting = {
  loginUrl: '',
  signupUrl: '',
  loginParam: '',
  loginOtherParam: '',
};

/**
 * ログインリダイレクト設定情報更新 hooks
 * @returns {Object} { onSubmit, isLoading }
 */
export const useLoginSettingEdit = ({
  setError,
  setGlobalErrors,
  onClose,
}: {
  setError: UseFormSetError<LoginSetting>;
  setGlobalErrors: (errors: string[]) => void;
  onClose: () => void;
}): {
  onSubmit: UseMutateFunction<
    UnPromisify<ReturnType<typeof patchLoginSetting>>,
    unknown,
    LoginSetting,
    unknown
  >;
  isLoading: boolean;
  isSuccess: boolean;
} => {
  const queryClient = useQueryClient();
  const tenantId = useUserTenantId();
  const toast = useCustomToast();

  const {
    mutate: onSubmit,
    isLoading,
    isSuccess,
  } = useMutation(
    (params: LoginSetting) =>
      patchLoginSetting(tenantId, params),
    {
      onSuccess: (result: LoginSetting | boolean | ResponseError) => {
        void queryClient.invalidateQueries( queryTenantKey.getLoginSetting({tenantId}) );
        // ダイアログのエラーを初期化
        setGlobalErrors([]);

        if (isLoginSetting(result)) {
          toast({
            ...successToast,
            duration: 4000,
            title: 'ログインリダイレクト設定を更新しました',
            position: 'bottom',
            variant: 'solid',
          });
          onClose();

          return;
        }

        if (
          isGetValidationError<LoginSetting>(
            result,
            Object.keys(LoginSettingFormData),
          )
        ) {
          if (result?.others) {
            setGlobalErrors(result?.others);
          } else {
            Object.keys(result).forEach((k) => {
              const key = k as keyof LoginSetting;
              const errMsgs = result?.[key];
              if (errMsgs) {
                setError(key, { types: toMultiError(errMsgs) });
              }
            });
          }
        } else {
          onClose();
        }
      },
    },
  );

  return { onSubmit, isLoading, isSuccess };
};
