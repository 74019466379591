import { VFC, memo } from 'react';
import { useUserTenantId } from 'hooks/user/useUserTenantId';
import { Mail, MailDefaultValue } from 'api/mail/types';
import { MailFormInner } from 'components/mail/organisms/MailFormInner';

const defaultValues: Mail = MailDefaultValue;

export const MailCreateForm: VFC = memo(() => {
  const tenantId = useUserTenantId();

  return <MailFormInner tenantId={tenantId} mailForm={defaultValues} />;
});
