import { Text, useDisclosure } from '@chakra-ui/react';
import { DrawerForm } from 'components/common/atoms';
import { Colors, ColorSet } from 'components/paywall/config';
import { useCommonDialog } from 'components/paywall/hooks/contexts/CommonDialog';
import { usePaywallUtil } from 'components/paywall/hooks/usePaywallUtil';
import {
  mailSettingCommonDefaultValue,
  MailSettingCommonFormDefaultValue,
} from 'components/paywall/pages/MailSetting/initial';
import {
  MailSettingCommon,
  MailSettingCommonForm,
} from 'components/paywall/pages/MailSetting/typed';
import { useUpdateMailSettingCommon } from 'hooks/paywall/useUpdateMailSettingCommon';
import { FC, memo, useCallback } from 'react';
import { FormProvider, useForm } from 'react-hook-form';
import { Form } from './Form';

type Props = {
  obj?: MailSettingCommon;
};

/**
 * @see メール設定・共通項目編集ダイアログ
 * @param MailSettingCommon
 */
export const MailSettingDialog: FC<Props> = memo(({ obj }) => {
  const { isOperationAuth } = usePaywallUtil();
  const methods = useForm<MailSettingCommonForm>({
    mode: 'onBlur',
    defaultValues: MailSettingCommonFormDefaultValue,
  });
  const {
    getValues,
    reset,
    setError,
    formState: { errors },
  } = methods;
  const { isOpen, onOpen, onClose } = useDisclosure();
  const { openDialog } = useCommonDialog();
  const { onSubmit, isLoading } = useUpdateMailSettingCommon({
    setError,
    close: onClose,
    reset,
  });
  const formId = 'paywall-mail-setting-form';

  /**
   * 保存ボタン押下時処理
   */
  const onSave = useCallback(() => {
    onSubmit(getValues());
  }, [getValues, onSubmit]);

  /**
   * キャンセルボタン押下時処理
   */
  const onCancel = useCallback(() => {
    openDialog({
      title: '編集を中断しますか？',
      okBtnCaption: '中断',
      cancelBtnCaption: 'キャンセル',
      cancelBtnColor: 'gray',
      okBtnColor: 'blue',
      size: 'lg',
      message: (
        <Text>
          保存ボタンを押さずに中断すると、
          <Text as="span" fontWeight="bold">
            入力中の内容は保存されません。
          </Text>
          編集を中断してもよろしいですか？
        </Text>
      ),
      onOk: () => {
        reset();
        onClose();
      },
    });
  }, [onClose, openDialog, reset]);

  return (
    <FormProvider {...methods}>
      <DrawerForm
        title="共通要素の編集"
        openBtnChildNode={<Text as="span">編集する</Text>}
        openBtnProps={{
          width: '104px',
          padding: '8px 16px',
          ...ColorSet.BLUE,
          isDisabled: !isOperationAuth(),
        }}
        cancelBtnTitle="キャンセル"
        cancelBtnProps={{
          width: '104px',
          padding: '8px 16px',
          marginRight: '16px',
          borderColor: Colors.GRAY_30,
          disabled: isLoading,
        }}
        submitBtnTitle="保存"
        submitBtnProps={{
          width: '104px',
          padding: '8px 16px',
          ...ColorSet.BLUE,
          disabled: Object.keys(errors).length > 0,
          isLoading,
        }}
        submitBtnHandelr={onSave}
        isOpen={isOpen}
        onOpen={onOpen}
        onClose={onCancel}
        closeOnOverlayClick={false}
        closeOnEsc={false}
        size="lg"
        formId={formId}
        drawerCloseButtonProps={{ disabled: isLoading }}
        drawerFooterJustify="flex-end"
      >
        <Form obj={obj || mailSettingCommonDefaultValue} />
      </DrawerForm>
    </FormProvider>
  );
});
