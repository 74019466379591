import { request } from 'admin/api/Request';
import { API_MESSAGES } from 'admin/define/apiMessages';
import { CustomError } from 'admin/error/CustomError';
import { authSchema } from 'admin/schema/service/auth';
import { AuthType } from 'admin/types/service/auth';
import { ZodError } from 'zod';

export const getAuth = async (userPoolChildId: string): Promise<AuthType> => {
  const path = `uniikey/${userPoolChildId}/child/security_settings/`;

  const response = await request<AuthType>({
    path,
    method: 'get',
  });

  if (response.hasError) {
    if (response.status === 404) {
      throw new CustomError(API_MESSAGES.SERVICE.GET_AUTH_SETTING.NOT_FOUND, {
        path,
        status: response.status,
      });
    } else {
      throw new CustomError(API_MESSAGES.SERVICE.GET_AUTH_SETTING.ERROR, {
        path,
        status: response.status,
      });
    }
  }
  try {
    authSchema.parse(response.data);
  } catch (error) {
    if (error instanceof ZodError) {
      throw new CustomError(error.message, {
        path,
        status: response.status,
        logLevel: 'error',
      });
    }
  }

  return response.data;
};
