import { VStack } from '@chakra-ui/react';
import { getTenantUserList } from 'api/user/getTenantUserList';
import { User, UserDeleteState } from 'api/user/types';
import { UsersBody } from 'components/setting/users/templates/UsersBody';
import { UsersHeader } from 'components/setting/users/templates/UsersHeader';
import { queryUserKey } from 'hooks/user/queryUserKey';
import { useUserTenantId } from 'hooks/user/useUserTenantId';
import { useSetPageTitle } from 'hooks/useSetPageTitle';
import { useUserInfo } from 'hooks/useUserInfo';
import { FC, memo, useCallback, useEffect, useState } from 'react';
import { useQuery } from 'react-query';

export const Users: FC = memo(() => {
  useSetPageTitle('ユーザー管理');
  const [userList, setUserList] = useState<User[]>([]);
  const tenantId = useUserTenantId();
  const [userDelStateList, setUserDelStateList] = useState<UserDeleteState[]>(
    [],
  );
  const [isAllChecked, setAllChecked] = useState(false);
  const { isMine, isEditableTarget } = useUserInfo();
  const [errorMessage, setErrorMessage] = useState('');

  const {
    isLoading,
    isError,
    error,
    data: allUsers,
    refetch,
  } = useQuery<User[]>(
    queryUserKey.tenantUserList({ tenantId }),
    () => getTenantUserList(tenantId),
    {
      suspense: false,
    },
  );

  useEffect(() => {
    if (allUsers) {
      setUserList(allUsers);
    }
  }, [allUsers]);

  useEffect(() => {
    const userStateList: UserDeleteState[] = userList.map((user) => {
      let permission = 0;
      if (!Array.isArray(user.tenant)) {
        permission = user.tenant.permission;
      }

      return {
        id: user.id,
        name: user.name,
        email: user.email,
        permission,
        state: false,
      };
    });
    setUserDelStateList(userStateList);
  }, [userList]);

  const refetchTenantUserList = useCallback(async () => {
    await refetch();
  }, [refetch]);

  /**
   * SettingUsersBody チェックボックス チェック処理
   */
  const setTargetCheck = useCallback(
    (id: string): void => {
      const targetUsers = userDelStateList.filter(
        (item) => !isMine(item.id) && isEditableTarget(item.permission),
      );

      const changedCheckItem: UserDeleteState[] = targetUsers.map((item) => ({
        id: item.id,
        name: item.name,
        email: item.email,
        permission: item.permission,
        state: item.id === id ? !item.state : item.state,
      }));

      setUserDelStateList(changedCheckItem);

      const allChecked = changedCheckItem.every(
        (val) => !isMine(val.id) && val.state,
      );
      setAllChecked(allChecked);
    },
    [userDelStateList, isMine, isEditableTarget],
  );

  /**
   * SettingUsersBody チェックボックス全チェック処理
   */
  const setAllCheck = useCallback((): void => {
    const changedCheckItem: UserDeleteState[] = userDelStateList.map(
      (item) => ({
        id: item.id,
        name: item.name,
        email: item.email,
        permission: item.permission,
        state: !isAllChecked,
      }),
    );

    setUserDelStateList(changedCheckItem);
    setAllChecked(!isAllChecked);
  }, [userDelStateList, isAllChecked]);

  /**
   * SettingUsersHeader 検索ボックスフィルター処理
   */
  const userFilter = useCallback(
    (keyword: string): void => {
      if (!keyword) {
        setUserList(allUsers || []);

        return;
      }
      if (!allUsers) return;

      const result = allUsers.filter(
        (user) =>
          user.name.indexOf(keyword) !== -1 ||
          user.email.indexOf(keyword) !== -1,
      );

      setUserList(result);
    },
    [allUsers, setUserList],
  );

  if (isError) {
    if (error && error instanceof Error) {
      setErrorMessage(error.message);
    }
  }

  const filterDelTargetUsers = (): UserDeleteState[] =>
    userDelStateList.filter(
      (item) =>
        item.state &&
        userList.some(
          (user) =>
            !Array.isArray(user.tenant) &&
            isEditableTarget(user.tenant.permission) &&
            !isMine(user.id) &&
            user.id === item.id,
        ),
    );

  return (
    <VStack spacing={4} flex={1}>
      <UsersHeader
        userFilter={userFilter}
        deleteList={filterDelTargetUsers()}
        refetch={refetchTenantUserList}
      />
      <UsersBody
        list={userList}
        isError={isError}
        isLoading={isLoading}
        errorMessage={errorMessage}
        delStateList={userDelStateList}
        isAllChecked={isAllChecked}
        setTargetCheck={setTargetCheck}
        setAllCheck={setAllCheck}
        refetch={refetchTenantUserList}
      />
    </VStack>
  );
});
