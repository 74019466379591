import {
  Accordion,
  AccordionButton,
  AccordionIcon,
  AccordionItem,
  AccordionPanel,
  Box,
  Text,
  Tr as ChakraTr
} from '@chakra-ui/react';
import { withSuspenseAndErrorBoundary } from 'admin/components/Error/WithErrorBoundary';
import { JsonEditor } from 'admin/components/jsonEditor';
import { Breadcrumb } from 'admin/components/ui/breadcrumb';
import { Table, Tbody, Td, Th, Thead } from 'admin/components/ui/table';
import { Colors } from 'admin/define/colors';
import { useIdPoolConsumerId } from 'admin/hooks/useIdPoolConsumerId';
import { useUserPool } from 'admin/hooks/userPool/useUserPool';
import { BreadcrumbType } from 'admin/types/ui/breadcrumb';
import { ErrorContents, LoadingSpinner } from 'components/common/atoms';
import { useSetPageTitle } from 'hooks/useSetPageTitle';
import { FC, memo } from 'react';

export const jsonPath: FC = memo(() => {
  useSetPageTitle('JSONパス解析ツール');
  const breadcrumbItems: BreadcrumbType[] = [
    { url: '/admin', title: '組織管理TOP' },
    { url: '', title: 'JSONパス解析ツール' },
  ];

  const idPoolConsumerId = useIdPoolConsumerId();

  const { data: userPool } = useUserPool(idPoolConsumerId);

  return (
    <>
      <Breadcrumb breadcrumbItems={breadcrumbItems} />
      <Text
        fontWeight="700"
        fontSize="18px"
        lineHeight="170%"
        fontFamily="Hiragino Sans"
        mt={6}
      >
        JSONパス解析ツール
      </Text>
      <Text color={Colors.GRAY_00} fontSize="14px" mt={1} mb={4}>
        入力したJSONデータのパスを解析するツールです。JSONパス構文は、
        {`$.<parameter name>`} の形式で入力してください。
        <br />
        入力されたパラメータ名に適合するオブジェクトは出力される結果ボックスに表示されます。
      </Text>
      <JsonEditor jsonData={userPool?.userInfoResponse ?? ''} />
      <Accordion bg="white" allowToggle defaultIndex={[0]} mt={8}>
        <Box>
          <AccordionItem>
            <AccordionButton>
              <Box as="span" flex="1" textAlign="left" fontWeight="bold">
                JSONパス構文のサンプル
              </Box>
              <AccordionIcon />
            </AccordionButton>
            <AccordionPanel>
              <Text>
                以下は、上記のサンプルJSONデータに対するJSONパス式とその結果の例です。
              </Text>

              <Table tableWidth="700px">
                <Thead bg="white">
                  <ChakraTr>
                    <Th maxWidth="230px" minWidth="230px">
                      JSON PATHの例
                    </Th>
                    <Th maxWidth="230px" minWidth="230px">
                      取得結果
                    </Th>

                    <Th maxWidth="230px" minWidth="230px">
                      説明
                    </Th>
                  </ChakraTr>
                </Thead>
                <Tbody bg="white">
                  <ChakraTr>
                    <Td>$.address.postalCode</Td>
                    <Td>630-0192</Td>

                    <Td>階層を上から順に指定しています。</Td>
                  </ChakraTr>
                  <ChakraTr>
                    <Td>$.phoneNumbers[1].type</Td>
                    <Td>home</Td>
                    <Td>phoneNumbers内で2番目の項目を指定しています。</Td>
                  </ChakraTr>
                  <ChakraTr>
                    <Td>$.address.*</Td>
                    <Td>naist street,Nara,630-0192</Td>
                    <Td>address内の全ての項目の値を取得します。</Td>
                  </ChakraTr>
                  <ChakraTr>
                    <Td>$..type</Td>
                    <Td>iPhone,home</Td>
                    <Td>
                      ..と記載することでtypeという項目の値を全て取得します。
                    </Td>
                  </ChakraTr>
                  <ChakraTr>
                    <Td>$.phoneNumbers[-1].type</Td>
                    <Td>home</Td>
                    <Td>
                      [-1]と記載することで[]内の最後の項目を指定することができます。
                    </Td>
                  </ChakraTr>
                  <ChakraTr>
                    <Td>$.&apos;備考&apos;[0].value</Td>
                    <Td>男性</Td>
                    <Td>
                      日本語の場合はシングルクォーテーション「&apos;⚫️⚫️&apos;」で囲む必要があります。また備考内で1番目の項目を指定しています。
                    </Td>
                  </ChakraTr>
                  <ChakraTr>
                    <Td>
                      $.phoneNumbers[?(@.type == &apos;home&apos;).number]
                    </Td>
                    <Td>0123-4567-8910</Td>
                    <Td>[]内で、任意の項目を検索することができます。</Td>
                  </ChakraTr>
                </Tbody>
              </Table>
            </AccordionPanel>
          </AccordionItem>
        </Box>
      </Accordion>
    </>
  );
});

export const JsonPath = withSuspenseAndErrorBoundary(jsonPath, {
  ErrorComponent: <ErrorContents name="JSONパス解析ツール" />,
  LoadingComponent: <LoadingSpinner />,
});
