import { Suspense, VFC } from 'react';
import ErrorBoundary from 'api/ErrorBoundary';
import { ContentsTable } from 'api/contents/types';
import { useErrorBoundaryReset } from 'hooks/useErrorBoundaryReset';
import { useUserTenantId } from 'hooks/user/useUserTenantId';
import { useTableList, ROOT_NODE } from 'hooks/contentsdb/useTableList';
import { LoadingSkeleton } from 'components/common/atoms';
import { TableTreeError } from 'components/contentsdb/atoms/TableTreeError';
import { TreeView } from 'components/contentsdb/molecules/TreeView';

type TableTreeInnerProps = {
  tenantId: string;
};

const TableTreeInner: VFC<TableTreeInnerProps> = ({ tenantId }) => {
  const treeData = useTableList(tenantId);

  return <TreeView<ContentsTable> tree={treeData} rootId={ROOT_NODE} />;
};

export const TableTree: VFC = () => {
  const tenantId = useUserTenantId();
  const { ebKey, onError } = useErrorBoundaryReset();

  return (
    <ErrorBoundary
      errorComponent={<TableTreeError />}
      onError={onError}
      key={ebKey.current}
    >
      <Suspense fallback={<LoadingSkeleton />}>
        {tenantId ? <TableTreeInner tenantId={tenantId} /> : <></>}
      </Suspense>
    </ErrorBoundary>
  );
};
