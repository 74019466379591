import { ChangeEvent, useCallback } from 'react';
import { atom, useRecoilState } from 'recoil';

const countContext = atom<number>({
  key: 'tableNavigation/count',
  default: 0,
});
const perPageContext = atom<number>({
  key: 'tableNavigation/perPage',
  default: 10,
});
const pageContext = atom<number>({
  key: 'tableNavigation/page',
  default: 1,
});

export const useTableNavigation = () => {
  const [count, setCount] = useRecoilState(countContext);
  const [perPage, setPerPage] = useRecoilState(perPageContext);
  const [page, setPage] = useRecoilState(pageContext);

  const initializeTableNavigation = useCallback((): void => {
    setPerPage(10);
    setPage(1);
  }, [setPage, setPerPage]);

  const handlePagination = useCallback(
    (num: number): void => {
      setPage(num);
    },
    [setPage],
  );

  const handlePerPage = useCallback(
    (
      e:
        | ChangeEvent<HTMLInputElement>
        | ChangeEvent<HTMLSelectElement>
        | undefined,
    ): void => {
      if (!e) return;
      setPerPage(Number(e.currentTarget.value));
      setPage(1);
      window.scrollTo(0, 0);
      // if (document.querySelector('.paywallContainer')) {
      //  document.querySelector('.paywallContainer')?.scrollTo(0, 0);
      // }
    },
    [setPerPage, setPage],
  );

  return {
    count,
    setCount,
    page,
    setPage,
    perPage,
    setPerPage,
    handlePagination,
    handlePerPage,
    initializeTableNavigation,
  };
};
