import { ServiceType } from 'admin/types/service';
import { Page } from 'api/common/types';

export const queryKeyNames = {
  getServices: 'getServices',
  getService: 'getService',
  getServiceMembers: 'getServiceMembers',
};

export const queryServiceKey = {
  getServices: (corporationId: string) => [
    queryKeyNames.getServices,
    corporationId,
  ],
  getService: (id: ServiceType['id']) => [queryKeyNames.getService, id],
  getServiceMembers: (
    serviceId: ServiceType['id'],
    page: Page['currentPage'],
    perPage: Page['perPage'],
  ) => [queryKeyNames.getServiceMembers, serviceId, page, perPage],
} as const;
