import { Box, Flex, Text } from '@chakra-ui/react';
import { HamburgerButton } from 'components/common/atoms';
import { Notification } from 'components/common/molecules';
import { useCustomNavigate } from 'hooks/useCustomNavigate';
import { FC, memo } from 'react';
import { useRecoilValue } from 'recoil';
import { pageTitleState } from 'store/pageTitleState';
import { HeaderAccount } from './HeaderAccount';

export const Header: FC = memo(() => {
  const title = useRecoilValue(pageTitleState);
  const { navigate } = useCustomNavigate({});

  return (
    <Flex justifyContent="space-between" alignItems="center" h="full">
      <Flex w="calc(100% - 350px)" alignItems="center" flex={1}>
        <HamburgerButton />
        <Box minW={45} ml={6}>
          <Box onClick={() => navigate('/')} cursor="pointer">
            <img
              src={`${process.env.PUBLIC_URL}/static/UEC_favicon_180x180.png`}
              width="45px"
              alt="UEC"
            />
          </Box>
        </Box>
        <Text overflow="hidden" textOverflow="ellipsis" whiteSpace="nowrap" ml={4} fontWeight="bold">
          {title}
        </Text>
      </Flex>
      <Flex alignItems="center" gridGap={6} h="100%">
        <Notification />
        <HeaderAccount />
      </Flex>
    </Flex>
  );
});
