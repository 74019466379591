import { Stack } from '@chakra-ui/react';
import { UsersFilter } from 'admin/components/users/usersFilter/index';
import { UsersList } from 'admin/components/users/usersList';
import { FC, memo } from 'react';

type Props = {
  searchKeyword: string;
  onSearch: (keyword: string) => void;
}

export const UsersBodyComponent: FC<Props> = memo(({
  searchKeyword,
  onSearch,
}: Props) => (
  <Stack spacing={4}>
    <UsersFilter onSearch={onSearch} />
    <UsersList searchKeyword={searchKeyword} />
  </Stack>
))
