import { request } from 'api/request';
import { API_MESSAGES } from 'admin/define/apiMessages';
import { CustomError } from 'admin/error/CustomError';
import { ResponseError } from 'api/types';
import { Options } from 'ky';

type UploadResultType = {
  url: string;
  internalUrl: string;
};

export const uploadLogo = async (
  {
    userPoolId,
    tenantId,
    authHubId,
    file,
  }: {
    userPoolId?: string;
    tenantId?: string;
    authHubId: string;
    file: File;
  },
  options?: Options,
): Promise<UploadResultType> => {
  const timeout = 1000 * 60 * 3;
  const formData = new FormData();
  formData.append('file', file);
  const mergedOptions = { ...options, body: formData, timeout };
  const path = userPoolId
    ? `uniikey/${userPoolId}/auth_hub/${authHubId}/logo/`
    : (tenantId && `auth_hub/${tenantId}/auth_hub/${authHubId}/logo/`) || '';

  const response = await request({
    path,
    method: 'post',
    options: mergedOptions,
  });

  if (response.hasError) {
    const errors = response.error as ResponseError;
    const { status } = response;
    if (status === 404) {
      throw new CustomError(API_MESSAGES.AUTH_HUB.UPLOAD_LOGO.FAILED, {
        path,
        status,
        errors,
      });
    } else {
      throw new CustomError(API_MESSAGES.AUTH_HUB.UPLOAD_LOGO.ERROR, {
        path,
        status,
        errors,
      });
    }
  }

  return response.data as UploadResultType;
};
