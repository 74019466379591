import { VFC, memo } from 'react';
import { useParams } from 'react-router-dom';
import { useForm, FormProvider } from 'react-hook-form';
import { Box, Text, Table, Thead, Tbody, Th, Tr, Td } from '@chakra-ui/react';
import { AddGroupUserFormType } from 'api/tenant/types';
import { useUserInfo } from 'hooks/useUserInfo';
import { useGroupUserList } from 'hooks/user/useGroupUserList';
import { AddGroupMemberDialog } from 'components/setting/groups/atoms/AddGroupMemberDialog';
import { DeleteGroupUserDialog } from 'components/setting/groups/atoms/DeleteGroupUserDialog';

const defaultValues: AddGroupUserFormType = {
  groupId: '',
  user: '',
};

export const GroupsUserList: VFC = memo(() => {
  const { groupId = '' } = useParams();
  const userList = useGroupUserList({ groupId });
  const { isStaff, isAssistant } = useUserInfo();
  const methods = useForm<AddGroupUserFormType>({
    mode: 'onBlur',
    defaultValues,
  });
  const formId = 'addGroupUser-form';

  const isDelete = (groupKey: string): boolean => groupId === groupKey;

  return (
    <Box w="full" overflowY="auto" maxHeight="calc(100vh - 145px)">
      <Table variant="striped" size="sm">
        <Thead position="sticky" zIndex="sticky" top={0} bgColor="white">
          <Tr>
            <Th fontSize="sm">ユーザー名</Th>
            <Th fontSize="sm">メールアドレス</Th>
            {!isStaff && !isAssistant && (
              <Th fontSize="sm">
                <FormProvider {...methods}>
                  <AddGroupMemberDialog formId={formId} groupId={groupId} />
                </FormProvider>
              </Th>
            )}
          </Tr>
        </Thead>
        <Tbody>
          {userList?.map((item, idx) => (
            <Tr
              key={`setting-group-data-${String(idx)}`}
              data-testid="setting-group-table-tr"
            >
              <Td minW={150} w={180}>
                {item.userName}
              </Td>
              <Td minW={150} w={240}>
                {item.email}
              </Td>
              {!isStaff && !isAssistant && (
                <Td>
                  {isDelete(item.group) ? (
                    <DeleteGroupUserDialog user={item} />
                  ) : (
                    <Text fontSize={12} color="gray.500">
                      {item.groupName || 'test groupname'}
                    </Text>
                  )}
                </Td>
              )}
            </Tr>
          ))}
        </Tbody>
      </Table>
    </Box>
  );
});
