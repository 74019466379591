import { Box } from '@chakra-ui/react';
import { LoadingSpinner } from 'components/common/atoms/LoadingSpinner';

export const LoadingLayer = () => (
  <Box
    zIndex={100000}
    position="fixed"
    top={0}
    left={0}
    right={0}
    bottom={0}
    bgColor="rgba(0,0,0,0.1)"
  >
    <LoadingSpinner />
  </Box>
);
