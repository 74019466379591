import { FormControl, FormLabel, TextareaProps } from '@chakra-ui/react';
import { Textarea } from 'components/paywall/atoms/Textarea';
import { VFC } from 'react';
import {
  FieldValues,
  Path,
  PathValue,
  RegisterOptions,
  UnpackNestedValue, useController,
  useFormContext, UseFormRegister
} from 'react-hook-form';

/**
 * TextareaFormProps
 */
type TextareaFormProps<T> = {
  name: Path<T>;
  value: UnpackNestedValue<PathValue<T, Path<T>>>;
  label?: string;
  labelElement?: JSX.Element | undefined;
  attr?: RegisterOptions;
  horizontal?: boolean;
  register: UseFormRegister<T & FieldValues>;
} & TextareaProps;

export const TextareaForm = <T extends FieldValues>({
  name,
  value,
  label = '',
  labelElement = undefined,
  horizontal = false,
  register,
  ...rest
}: TextareaFormProps<T>): ReturnType<VFC> => {
  const { control } = useFormContext<T>();

  const {
    field: { value: formValue },
  } = useController({ name, control, defaultValue: value });

  const formAttr = horizontal ? { display: 'flex', alignItems: 'center' } : {};

  return (
    <FormControl {...formAttr}>
      <FormLabel htmlFor={name} fontSize="sm">
        {label && label}
        {labelElement && labelElement}
      </FormLabel>
      <Textarea
        {...rest}
        name={name}
        ref={register(name)}
        value={formValue as string}
      />
    </FormControl>
  );
};

TextareaForm.defaultProps = {
  horizontal: false,
};
