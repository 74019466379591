/* eslint-disable react/require-default-props */
import { Box } from '@chakra-ui/react';
import { Colors } from 'components/paywall/config';
import { FC, memo } from 'react';

type Props = {
  space: number;
  children: React.ReactNode;
  divider?: boolean;
  spacerTitle?: string;
}
export const DetailBlock: FC<Props> = memo(({space, children, divider = true, spacerTitle = ''}: Props) => (
  <Box
    position="relative"
    _notFirst={{
      borderTop: divider ? '1px solid #CFD6DD' : 'none',
      mt: `${space + (spacerTitle ? 11.5 : 0)}px`,
      pt: `${space + (spacerTitle ? 3.5 : 0)}px`,
    }}
    _before={{
      content: spacerTitle ? `"${spacerTitle}"` : 'none',
      position: 'absolute',
      top: "-24px",
      left: "0px",
      transform: "translateY(50%)",
      bgColor: Colors.GRAY_70,
      height: '24px',
      paddingRight: "16px",
      fontWeight: 700
    }}
  >
    {children}
  </Box>
));
