import { Box, Flex, Grid } from '@chakra-ui/react';
import { ErrorTextMsg } from 'components/common/atoms/ErrorTextMsg';
import { Input } from 'components/paywall/atoms/Input';
import { WallFormDefaultValues } from 'components/paywall/pages/WallList/initial';
import { Wall, WallFormType } from 'components/paywall/pages/WallList/typed';
import { FC, memo, useEffect } from 'react';
import { useFormContext } from 'react-hook-form';
import { toErrMsgList } from 'utils/form';

type Props = {
  isAdd?: boolean;
  obj?: Wall;
};
export const Form: FC<Props> = memo(({ isAdd, obj }) => {
  const {
    setValue,
    register,
    formState: { errors },
  } = useFormContext<WallFormType>();

  useEffect(() => {
    if (!isAdd) {
      setValue('id', obj?.id);
      setValue('name', obj?.name || WallFormDefaultValues.name);
    }
  }, [isAdd, setValue, obj]);

  return (
    <form>
      <Grid gap={5}>
        <Box>
          {isAdd ? (
            <>会員限定ウォールの新規作成を行います</>
          ) : (
            <>会員限定ウォールの編集を行います</>
          )}
        </Box>

        {isAdd ? (
          <></>
        ) : (
          <Flex style={{ fontSize: 14, alignItems: 'center' }}>
            <Box style={{ width: 100 }}>ウォールID:</Box>
            <Box style={{ fontSize: 18 }}>{obj?.id}</Box>
          </Flex>
        )}

        <Grid gap={2}>
          <Box>名前</Box>
          <Input
            defaultValue={isAdd ? WallFormDefaultValues.name : obj?.name}
            ref={register('name', {
              required: '名前を入力してください',
            })}
          />
          {toErrMsgList(errors, 'name').map((err, idx) => (
            <ErrorTextMsg key={`error-paywall-${String(idx)}`} msg={err} />
          ))}
        </Grid>
      </Grid>
    </form>
  );
});
