/* eslint-disable react/require-default-props */
import { BoxProps, Flex, Text, Th as ChakraTh } from '@chakra-ui/react';
import { Colors } from 'components/paywall/config';
import { FilterButton } from 'components/paywall/features/Table/FilterButton';
import { SortButton } from 'components/paywall/features/Table/SortButton';
import { useScrollContext } from 'components/paywall/features/Table/TableContainer';
import { FC, memo, useEffect, useState } from 'react';

type Props = {
  name: string;
  targetKey?: string;
  isSort?: boolean;
  filterType?: 'text' | 'status' | 'price' | 'plan' | 'period' | 'cancel' | '';
  columnFix?: boolean;
  titleAlign?: string;
} & BoxProps;

export const Th: FC<Props> = memo(
  ({
    name,
    targetKey = '',
    isSort = false,
    filterType = '',
    columnFix = false,
    titleAlign = 'flex-start',
    ...rest
  }: Props) => {
    const [thStyle, setThStyle] = useState<React.CSSProperties>({
      position: 'relative',
      zIndex: -1,
    });
    const { scrollLeftValue } = useScrollContext();

    useEffect(() => {
      if (columnFix) {
        setThStyle({
          position: 'sticky',
          left: '0',
          backgroundColor: Colors.GRAY_70,
          filter:
            scrollLeftValue === 0
              ? 'none'
              : 'drop-shadow(.0625rem 0 0 #dde0e4)',
        });
      }
    }, [columnFix, scrollLeftValue]);

    return (
      <ChakraTh py="10px" px="16px" style={thStyle} {...rest}>
        <Flex style={{ gap: '4px' }} justifyContent={titleAlign}>
          <Text color={Colors.GRAY_03} fontSize="12px" fontWeight="600">
            {name}
          </Text>
          {isSort && !!targetKey && <SortButton sortTarget={targetKey} />}
          {!!filterType && !!targetKey && (
            <FilterButton
              filterName={name}
              filterKey={targetKey}
              filterType={filterType}
            />
          )}
        </Flex>
      </ChakraTh>
    );
  },
);
