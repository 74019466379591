import { Box, Flex, Text } from '@chakra-ui/react';
import { Colors } from 'components/paywall/config';
import { CustomErrorBoundary } from 'components/paywall/molecules/CustomErrorBoundary';
import { MailSettingContents } from 'components/paywall/pages/MailSetting/MailSettingContents';
import { Container } from 'components/paywall/templates/Container';
import { FC, memo } from 'react';

export const MailSetting: FC = memo(() => (
  <CustomErrorBoundary pageTitle="メール設定">
    <Container
      title={
        <Flex>
          <Text
            fontSize="14px"
            color={Colors.BLACK}
            fontWeight="700"
            fontFamily="Hiragino Sans"
          >
            ペイウォールメール設定
          </Text>
        </Flex>
      }
      px="45px"
    >
      <Box mt="32px" fontFamily="Hiragino Sans">
        <Text fontSize="18px" fontWeight="700">
          ペイウォールメール設定
        </Text>
        <Text color={Colors.GRAY_00}>
          ペイウォール関連の各アクションに応じて自動送信するメールの有無、内容についてカスタマイズすることができます。
        </Text>
      </Box>
      <MailSettingContents />
    </Container>
  </CustomErrorBoundary>
));
