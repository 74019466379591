import { SearchIcon } from '@chakra-ui/icons';
import { Button, Flex, Input, InputGroup } from '@chakra-ui/react';
import { HelpTooltip } from 'admin/components/ui/helpTooltip';
import { FC, KeyboardEvent, memo, useState } from 'react';

type Props = {
  onSearch: (keyword: string) => void;
};

export const ServiceSearch: FC<Props> = memo(({ onSearch }: Props) => {
  const [inputValue, setInputValue] = useState('');

  const onKeyDown = (e: KeyboardEvent<HTMLInputElement>) => {
    if (e.key === 'Enter') {
      onSearch(inputValue);
    }
  };

  return (
    <Flex alignItems="center">
      <HelpTooltip label="テナント名を用いた検索が可能です。" />
      <InputGroup ml="8px">
        <Input
          placeholder="例：テナントA"
          value={inputValue}
          onChange={(e) => setInputValue(e.target.value)}
          bgColor="WHITE"
          minWidth="280px"
          onKeyDown={onKeyDown}
        />
      </InputGroup>
      <Button
        variant="outline"
        colorScheme="primary"
        leftIcon={<SearchIcon />}
        onClick={() => onSearch(inputValue)}
        h="37px"
        w="87px"
        ml="8px"
        bgColor="WHITE"
      >
        検索
      </Button>
    </Flex>
  );
});
