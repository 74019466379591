import { Box } from '@chakra-ui/react';
import ErrorBoundary from 'api/ErrorBoundary';
import { useErrorBoundaryReset } from 'hooks/useErrorBoundaryReset';
import { useUserTenantId } from 'hooks/user/useUserTenantId';
import { FC, memo, Suspense } from 'react';
import { NotificationContents } from './NotificationContents';
import { NotificationErrorContents } from './NotificationErrorContents';

export const Notification: FC = memo(() => {
  const { ebKey, onError } = useErrorBoundaryReset();
  const tenantId = useUserTenantId();

  // テナントログインが無効の場合は通知処理を表示しない
  if (!tenantId) return <></>

  return (
    <ErrorBoundary
      errorComponent={<NotificationErrorContents />}
      onError={onError}
      key={ebKey.current}
    >
      <Suspense fallback={<Box h={140}>&nbsp;</Box>}>
        <NotificationContents />
      </Suspense>
    </ErrorBoundary>
  )
})
