import { UseMutateFunction, useQueryClient, useMutation } from 'react-query';
import { useCustomToast } from 'hooks/useCustomToast';
import { contentsDbQueryKey } from 'hooks/contentsdb/queryKey';
import { updatePublish, UpdatePublish } from 'api/contents/updatePublish';
import { ResponseError } from 'api/types';

type UnPromisify<T> = T extends Promise<infer U> ? U : T;

export const useTablePublish = (): {
  onSubmit: UseMutateFunction<
    UnPromisify<ReturnType<typeof updatePublish>>,
    unknown,
    UpdatePublish,
    unknown
  >;
  isLoading: boolean;
} => {
  const queryClient = useQueryClient();
  const toast = useCustomToast();

  const { mutate: onSubmit, isLoading } = useMutation(
    (updateData: UpdatePublish) => updatePublish(updateData),
    {
      onSuccess: (result: boolean | ResponseError, sendData: UpdatePublish) => {
        void queryClient.invalidateQueries(
          contentsDbQueryKey.tableDetail({
            tenantId: sendData.tenantId,
            tableId: sendData.tableId,
          }),
        );

        if (typeof result === 'boolean' && result) {
          toast({
            status: 'success',
            position: 'bottom',
            duration: 2000,
            isClosable: true,
            title: '保存しました',
          });

          return;
        }

        toast({
          status: 'error',
          position: 'bottom',
          duration: 2000,
          isClosable: true,
          title: '公開日を保存できませんでした',
        });
      },
    },
  );

  return { onSubmit, isLoading };
};
