import { CustomError } from 'api/error/CustomError';
import { logger } from 'api/logger';
import { request } from 'api/request';
import { messages } from 'api/tenant/messages';
import { isLoginSetting, LoginSetting } from 'api/tenant/types';
import { Options } from 'ky';

/**
 * ログインリダイレクト設定取得API
 */
export const getLoginSetting = async (
  tenantId: string,
  options?: Options,
): Promise<LoginSetting> => {
  const response = await request({
    path: `tenant/${tenantId}/login_setting/`,
    method: 'get',
    options,
  });

  const responseData = (await response.data) as unknown[];

  if (response.hasError) {
    if (response.status === 404) {
      throw new CustomError(
        messages.getLoginSetting.customer.error,
        response.status,
      );
    } else {
      throw new CustomError(
        messages.getLoginSetting.customer.failed,
        response.status,
      );
    }
  }

  if (!isLoginSetting(responseData)) {
    void logger({
      loglevel:
        response.status === 404 || response.status === 450
          ? 'warning'
          : 'error',
      data: responseData,
      message: messages.getLoginSetting.system.typeError,
    });

    throw new CustomError(messages.getLoginSetting.customer.failed);
  }

  return responseData;
};
