import { withSuspenseAndErrorBoundary } from 'admin/components/Error/WithErrorBoundary';
import { TransactionMailsTable } from 'admin/components/idPoolConsumer/transactionMail/transactionMailsTable';
import { TransactionMailsTitle } from 'admin/components/idPoolConsumer/transactionMail/transactionMailsTitle';
import { Breadcrumb } from 'admin/components/ui/breadcrumb';
import { Truncate } from 'admin/components/ui/truncate';
import { TRANSACTION_MAIL_PERSONALS } from 'admin/define/idPoolConsumer/transactionMail';
import { useUserPoolId } from 'admin/hooks/params/useUserPoolId';
import { useIdPoolConsumerId } from 'admin/hooks/useIdPoolConsumerId';
import { useUserPool } from 'admin/hooks/userPool/useUserPool';
import { BreadcrumbType } from 'admin/types/ui/breadcrumb';
import {
  TransactionMailCategoryType,
  TransactionMailPersonalType,
} from 'admin/types/userPool/transactionMail';
import { ErrorContents, LoadingSpinner } from 'components/common/atoms';
import { useSetPageTitle } from 'hooks/useSetPageTitle';
import { FC, memo, useMemo } from 'react';
import { Helmet } from 'react-helmet-async';
import { useParams } from 'react-router-dom';

const transactionMails: FC = memo(() => {
  const idPoolConsumerId = useIdPoolConsumerId();
  const params = useParams();
  const transactionMailType = useMemo<TransactionMailPersonalType>(
    () => params?.transactionMailType as TransactionMailPersonalType,
    [params],
  );
  const transactionMailName = useMemo(
    () => TRANSACTION_MAIL_PERSONALS.get(transactionMailType)?.name || '',
    [transactionMailType],
  );
  const title = useMemo(
    () => `${transactionMailName} 送信内容設定`,
    [transactionMailName],
  );

  useSetPageTitle(title);
  const userPoolId = useUserPoolId();
  const { data: userPool } = useUserPool(userPoolId);
  const breadcrumbItems: BreadcrumbType[] = [
    { url: '/admin', title: '組織管理TOP' },
    {
      url: '/admin/idPoolConsumer',
      title: 'IDシステム設定管理',
    },
    {
      url: '',
      title: <Truncate>{userPool?.name || ''}</Truncate>,
    },
    {
      url: `/admin/idPoolConsumer/${idPoolConsumerId}/transactionMail`,
      title: 'トランザクションメール設定',
    },
    { url: '', title },
  ];

  return (
    <>
      <Helmet>
        <title>{title}</title>
      </Helmet>
      <Breadcrumb breadcrumbItems={breadcrumbItems} />
      <TransactionMailsTitle />
      <TransactionMailsTable transactionMailType={transactionMailType} />
    </>
  );
});

const CustomErrorContents = () => {
  const params = useParams();
  const transactionMailType = useMemo(
    () => params?.transactionMailType ?? '',
    [params],
  );
  const transactionMailTypeName = useMemo(
    () =>
      TRANSACTION_MAIL_PERSONALS.get(
        transactionMailType as TransactionMailCategoryType,
      )?.name || '',
    [transactionMailType],
  );

  return <ErrorContents name={`${transactionMailTypeName}画面設定`} />;
};

export const TransactionMails = () => {
  const ErrorComponentWithProps = <CustomErrorContents />;

  const WrappedTransactionMailsTable = withSuspenseAndErrorBoundary(
    transactionMails,
    {
      ErrorComponent: ErrorComponentWithProps,
      LoadingComponent: <LoadingSpinner />,
    },
  );

  return <WrappedTransactionMailsTable />;
};
