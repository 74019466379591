import {
  Button,
  HStack,
  ModalBody,
  ModalCloseButton,
  ModalContent,
  ModalFooter,
  ModalHeader,
  ModalOverlay,
  Text,
} from '@chakra-ui/react';
import { ColorSet } from 'admin/define/colors';
import { TermType } from 'admin/types/userPool/terms';
import { FC, useMemo } from 'react';

type Props = {
  onClose: () => void;
  onSubmit: () => void;
  term: TermType | null;
};

export const AutoAppendModalComponent: FC<Props> = ({
  onClose,
  term,
  onSubmit,
}) => {
  const label = useMemo(
    () => (term?.isAutoAppendToChild ? 'OFF' : 'ON'),
    [term],
  );

  return (
    <>
      <ModalOverlay />
      <ModalContent fontSize="md" zIndex={100}>
        <ModalHeader fontSize="lg" fontWeight="bold" p="6">
          全テナント利用を{label}にする
        </ModalHeader>
        <ModalBody p="0 24px">
          <Text fontSize="14px" fontWeight="bold">
            規約名
          </Text>

          <Text mt="2" mb="6" p="8px 16px" borderBottom="solid 1px #CFD6DD">
            {term?.displayNameJa
              ? term?.displayNameJa
              : term?.displayNameForeign}
          </Text>
          <Text lineHeight="27px">
            この規約の全テナント利用の設定を
            <Text as="span" fontWeight="bold">
              {label}
            </Text>
            に変更します。
            <br />
            {label}にすると、全テナント利用が
            {term?.isAutoAppendToChild ? '不可' : '可能'}になります。
          </Text>
        </ModalBody>

        <ModalFooter p="6">
          <HStack spacing={4}>
            <Button
              w="118px"
              padding="8px 24px"
              variant="solid"
              {...ColorSet.Default}
              onClick={onClose}
            >
              キャンセル
            </Button>
            <Button
              w="118px"
              onClick={() => onSubmit()}
              colorScheme="primary"
              mr={3}
              padding="8px 24px"
            >
              実行
            </Button>
          </HStack>
        </ModalFooter>
        <ModalCloseButton top="21px" right="21px" />
      </ModalContent>
    </>
  );
};
