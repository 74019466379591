import { useQuery } from 'react-query';
import { dashBoard } from 'api/dashboard/types';
import { getDashboard } from 'api/dashboard/getDashboard';
import { useCustomToast } from 'hooks/useCustomToast';
import { useUserTenantId } from 'hooks/user/useUserTenantId';
import { queryDashboardKey } from './queryDashboardKey';

/**
 * ダッシュボード取得 hooks
 */
export const useDashboard = (): dashBoard => {
  const toast = useCustomToast();
  const tenantId = useUserTenantId();

  const response = useQuery(
    queryDashboardKey.getDashboard({ tenantId }),
    () => getDashboard(tenantId),
    {
      refetchInterval: 600000,
      staleTime: 0,
      cacheTime: 0,
      onError: (err: unknown) => {
        if (err instanceof Error) {
          toast.closeAll();
          toast({
            title: err.message,
            status: 'error',
          });
        }
      },
    },
  );

  const results = response.data as dashBoard;

  return results;
};
