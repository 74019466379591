import { FC, memo } from 'react';
import { Outlet, useParams } from 'react-router-dom';
import { FormProvider } from 'react-hook-form';
import { Breadcrumb } from 'admin/components/ui/breadcrumb';
import { BreadcrumbType } from 'admin/types/ui/breadcrumb';
import { Layout } from 'admin/components/authHub/layout';
import {
  AuthHubProvider,
  useAuthHubContext,
} from 'admin/components/authHub/authHubContext';
import { useSetPageTitle } from 'hooks/useSetPageTitle';

const AuthHubDetailInner: FC = memo(() => {
  const params = useParams();
  const authHubId = params?.authHubId ?? '';

  const { authHub, form } = useAuthHubContext();

  const breadcrumbItems: BreadcrumbType[] = [
    { url: '/admin', title: '組織管理TOP' },
    { url: '/admin/authHub', title: 'Auth Hub設定' },
    {
      url: `/admin/authHub/${authHubId}`,
      title: authHub?.data?.name,
    },
  ];

  useSetPageTitle('Auth Hub詳細設定');

  return (
    <>
      <Breadcrumb breadcrumbItems={breadcrumbItems} />
      <Layout>
        <FormProvider {...form}>
          <Outlet />
        </FormProvider>
      </Layout>
    </>
  );
});

export const AuthHubDetail: FC = memo(() => (
  <AuthHubProvider>
    <AuthHubDetailInner />
  </AuthHubProvider>
));
