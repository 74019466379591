import { VFC, useState } from 'react';
import {
  Box,
  Text,
  Button,
  Modal,
  ModalOverlay,
  ModalContent,
  ModalHeader,
  ModalBody,
  ModalCloseButton,
  useDisclosure,
  Flex,
  Stack,
  IconButton,
  Tooltip,
} from '@chakra-ui/react';
import { AiOutlineCloudUpload } from 'react-icons/ai';
import { BsTrash } from 'react-icons/bs';
import { TableUploadHistory } from 'components/contentsdb/molecules/TableUploadHistory';
import { TableFileUpload } from 'components/contentsdb/molecules/TableFileUpload';
import {
  CONTENTSDB_FILE_UPLOAD_TYPE,
  CONTENTSDB_FILE_UPLOAD_TYPE_SAVE,
  CONTENTSDB_FILE_UPLOAD_TYPE_DELETE,
} from 'define';

type UploadType = typeof CONTENTSDB_FILE_UPLOAD_TYPE[number];

export const TableUpload: VFC = () => {
  const { isOpen, onOpen, onClose } = useDisclosure();
  const [uploadType, setUploadType] = useState<UploadType>(
    CONTENTSDB_FILE_UPLOAD_TYPE_SAVE,
  );

  const onOpenUploadDialog = (mode: UploadType) => {
    setUploadType(mode);

    onOpen();
  };

  return (
    <Stack justifyContent="space-between" h="full">
      <TableUploadHistory />
      <Flex
        alignItems="center"
        justifyContent="space-between"
        mt={4}
        flexBasis={8}
        flexShrink={0}
      >
        <Button
          variant="primary"
          onClick={() => onOpenUploadDialog(CONTENTSDB_FILE_UPLOAD_TYPE_SAVE)}
        >
          <AiOutlineCloudUpload />
          <Text as="span" ml={2}>
            アップロード
          </Text>
        </Button>
        <Tooltip label="データを削除します">
          <IconButton
            aria-label="データ削除"
            icon={
              <BsTrash
                onClick={() =>
                  onOpenUploadDialog(CONTENTSDB_FILE_UPLOAD_TYPE_DELETE)
                }
                style={{
                  cursor: 'pointer',
                  fontSize: '26px',
                  width: '100%',
                  height: '100%',
                }}
              />
            }
            variant="none"
          />
        </Tooltip>
      </Flex>

      <Modal isOpen={isOpen} size="lg" onClose={onClose} isCentered>
        <ModalOverlay />
        <ModalContent
          bg={
            uploadType === CONTENTSDB_FILE_UPLOAD_TYPE_DELETE
              ? 'pink.50'
              : 'White'
          }
        >
          <ModalHeader pb={0}>
            {uploadType === CONTENTSDB_FILE_UPLOAD_TYPE_DELETE ? (
              <Text color="red.800">データ削除</Text>
            ) : (
              <Text color="gray.800">データアップロード</Text>
            )}
          </ModalHeader>
          <ModalBody pt={0} pb={6}>
            <Box
              mt={4}
              border="2px"
              borderColor={
                uploadType === CONTENTSDB_FILE_UPLOAD_TYPE_DELETE
                  ? 'red.400'
                  : 'teal.50'
              }
              shadow="xs"
              rounded="md"
            >
              <TableFileUpload modalClose={onClose} uploadType={uploadType} />
            </Box>
          </ModalBody>
          <ModalCloseButton />
        </ModalContent>
      </Modal>
    </Stack>
  );
};
