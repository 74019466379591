import { Box, Flex, Heading, Text, VStack } from '@chakra-ui/react';
import { Colors } from 'components/paywall/config';
import { commonSettingWords } from 'components/paywall/pages/MailSetting/constants';
import { MailSettingDialog } from 'components/paywall/pages/MailSetting/MailSettingDialog';
import { MailSettingCommon as MailSettingCommonType } from 'components/paywall/pages/MailSetting/typed';
import { FC, memo } from 'react';

type Props = {
  commonData: MailSettingCommonType;
};

export const MailSettingCommon: FC<Props> = memo(({ commonData }: Props) => (
  <Box w="100%">
    <Flex justifyContent="space-between">
      <Box fontFamily="Hiragino Sans">
        <Heading role="heading" as="h2" fontSize="18px" fontWeight="bold">
          {commonSettingWords.heading}
        </Heading>
        <Text role="contentinfo" fontSize={14}>
          {commonSettingWords.description}
        </Text>
      </Box>
      <Box>
        <MailSettingDialog obj={commonData} />
      </Box>
    </Flex>
    <Box
      w="100%"
      borderWidth="1px"
      borderRadius="md"
      p="16px"
      mt="8px"
      fontFamily="Hiragino Sans"
    >
      <VStack spacing={4} align="stretch">
        <Box display="flex" alignItems="center" justifyContent="left">
          <Text
            w="140px"
            mr="16px"
            fontSize="14px"
            lineHeight="170%"
            color={Colors.GRAY_10}
          >
            {commonSettingWords.fromAddress}
          </Text>
          <Text w="auto">{commonData.fromAddress}</Text>
        </Box>
        <Box display="flex" alignItems="center" justifyContent="left">
          <Text
            w="140px"
            mr="16px"
            fontSize="14px"
            lineHeight="170%"
            color={Colors.GRAY_10}
          >
            {commonSettingWords.replyAddress}
          </Text>
          <Text w="auto">{commonData.replyAddress}</Text>
        </Box>
        <Box
          display="flex"
          alignItems="flex-start"
          justifyContent="left"
          whiteSpace="pre-wrap"
        >
          <Text
            w="140px"
            mr="16px"
            fontSize="14px"
            lineHeight="170%"
            color={Colors.GRAY_10}
          >
            {commonSettingWords.header}
          </Text>
          <Text w="auto">{commonData.header}</Text>
        </Box>
        <Box
          display="flex"
          alignItems="flex-start"
          justifyContent="left"
          whiteSpace="pre-wrap"
        >
          <Text
            w="140px"
            mr="16px"
            fontSize="14px"
            lineHeight="170%"
            color={Colors.GRAY_10}
          >
            {commonSettingWords.footer}
          </Text>
          <Text w="auto">{commonData.footer}</Text>
        </Box>
      </VStack>
    </Box>
  </Box>
));
