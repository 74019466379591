import { Tr } from '@chakra-ui/react';
import { Th } from 'components/paywall/features/Table';
import { FC, memo } from 'react';

export const ItemListColumns: FC = memo(() => (
  <Tr>
    <Th
      maxWidth="122px"
      minWidth="122px"
      fontFamily="Hiragino sans"
      name="単品ウォールID"
    />
    <Th
      maxWidth="560px"
      minWidth="560px"
      fontFamily="Hiragino sans"
      name="単品ウォール名"
    />
    <Th name="" />
  </Tr>
));
