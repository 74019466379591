import {
  DISPLAY_TYPE_ADDRESS_JA,
  DISPLAY_TYPE_HORIZONTAL2,
  DISPLAY_TYPE_VERTICAL
} from 'admin/define/field';
import { FC, memo } from 'react';

interface Props {
  iconType: string;
}

export const DisplayTypeIcon: FC<Props> = memo(({ iconType }) => {
  if (iconType === DISPLAY_TYPE_VERTICAL) {
    return (
      <svg
        width="14"
        height="14"
        viewBox="0 0 14 14"
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
      >
        <path
          d="M14 4.5C14 4.9125 13.8531 5.26562 13.5594 5.55937C13.2656 5.85312 12.9125 6 12.5 6L1.5 6C1.09722 6 0.746528 5.85312 0.447917 5.55937C0.149306 5.26562 0 4.9125 0 4.5L0 1.5C0 1.09722 0.149306 0.746527 0.447917 0.447916C0.746528 0.149305 1.09722 0 1.5 0H12.5C12.9125 0 13.2656 0.149305 13.5594 0.447916C13.8531 0.746527 14 1.09722 14 1.5V4.5ZM1.5 4.5H12.5V1.5H1.5V4.5ZM14 12.5C14 12.9125 13.8531 13.2656 13.5594 13.5594C13.2656 13.8531 12.9125 14 12.5 14H1.5C1.09722 14 0.746528 13.8531 0.447917 13.5594C0.149306 13.2656 0 12.9125 0 12.5L0 9.5C0 9.09722 0.149306 8.74653 0.447917 8.44792C0.746528 8.14931 1.09722 8 1.5 8H12.5C12.9125 8 13.2656 8.14931 13.5594 8.44792C13.8531 8.74653 14 9.09722 14 9.5V12.5ZM1.5 12.5H12.5V9.5H1.5L1.5 12.5Z"
          fill="#272E35"
        />
      </svg>
    );
  }

  if (iconType === DISPLAY_TYPE_HORIZONTAL2) {
    return (
      <svg
        width="14"
        height="14"
        viewBox="0 0 14 14"
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
      >
        <path
          d="M9.5 14C9.0875 14 8.73438 13.8531 8.44063 13.5594C8.14688 13.2656 8 12.9125 8 12.5V1.5C8 1.09722 8.14688 0.746528 8.44063 0.447917C8.73438 0.149306 9.0875 0 9.5 0H12.5C12.9028 0 13.2535 0.149306 13.5521 0.447917C13.8507 0.746528 14 1.09722 14 1.5V12.5C14 12.9125 13.8507 13.2656 13.5521 13.5594C13.2535 13.8531 12.9028 14 12.5 14H9.5ZM9.5 1.5V12.5H12.5V1.5H9.5ZM1.5 14C1.0875 14 0.734375 13.8531 0.440625 13.5594C0.146875 13.2656 0 12.9125 0 12.5V1.5C0 1.09722 0.146875 0.746528 0.440625 0.447917C0.734375 0.149306 1.0875 0 1.5 0H4.5C4.90278 0 5.25347 0.149306 5.55208 0.447917C5.85069 0.746528 6 1.09722 6 1.5V12.5C6 12.9125 5.85069 13.2656 5.55208 13.5594C5.25347 13.8531 4.90278 14 4.5 14H1.5ZM1.5 1.5V12.5H4.5V1.5H1.5Z"
          fill="#272E35"
        />
      </svg>
    );
  }
  if (iconType === DISPLAY_TYPE_ADDRESS_JA) {
    return (
      <svg
        width="12"
        height="14"
        viewBox="0 0 12 14"
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
      >
        <path
          d="M6 0L12 4.5V14H0V4.5L6 0ZM6 9C6.625 9 7.15625 8.78125 7.59375 8.34375C8.03125 7.90625 8.25 7.375 8.25 6.75C8.25 6.125 8.03125 5.59375 7.59375 5.15625C7.15625 4.71875 6.625 4.5 6 4.5C5.375 4.5 4.84375 4.71875 4.40625 5.15625C3.96875 5.59375 3.75 6.125 3.75 6.75C3.75 7.375 3.96875 7.90625 4.40625 8.34375C4.84375 8.78125 5.375 9 6 9ZM6.0044 7.5C5.79313 7.5 5.61458 7.42855 5.46875 7.28565C5.32292 7.14273 5.25 6.96565 5.25 6.7544C5.25 6.54313 5.32145 6.36458 5.46435 6.21875C5.60727 6.07292 5.78435 6 5.9956 6C6.20687 6 6.38542 6.07145 6.53125 6.21435C6.67708 6.35727 6.75 6.53435 6.75 6.7456C6.75 6.95687 6.67855 7.13542 6.53565 7.28125C6.39273 7.42708 6.21565 7.5 6.0044 7.5ZM6 11.5C5.41142 11.5 4.83719 11.5868 4.27731 11.7604C3.71744 11.934 3.1875 12.1806 2.6875 12.5H9.3125C8.82639 12.1806 8.30112 11.934 7.73669 11.7604C7.17227 11.5868 6.59338 11.5 6 11.5ZM1.5 5.25V11.5C2.15 11.0125 2.85625 10.6406 3.61875 10.3844C4.38125 10.1281 5.175 10 6 10C6.82403 10 7.61684 10.125 8.37844 10.375C9.14003 10.625 9.84722 11 10.5 11.5V5.25L6 1.875L1.5 5.25Z"
          fill="#272E35"
        />
      </svg>
    );
  }

  return <>-</>;
});
