import { withSuspenseAndErrorBoundary } from 'admin/components/Error/WithErrorBoundary';
import { TransactionMailEditor } from 'admin/components/idPoolConsumer/transactionMail/transactionMailEditor';
import { TRANSACTION_MAIL_PERSONALS } from 'admin/define/idPoolConsumer/transactionMail';
import {
  TransactionMailCategoryType,
  TransactionMailPersonalType,
} from 'admin/types/userPool/transactionMail';
import { ErrorContents, LoadingSpinner } from 'components/common/atoms';
import { useSetPageTitle } from 'hooks/useSetPageTitle';
import { FC, memo, useMemo } from 'react';
import { Helmet } from 'react-helmet-async';
import { useParams } from 'react-router-dom';

const transactionMailEdit: FC = memo(() => {
  const params = useParams();
  const transactionMailId = useMemo(
    () => params?.transactionMailId ?? '',
    [params],
  );
  const isEdit = useMemo(() => !!transactionMailId, [transactionMailId]);
  const transactionMailType = useMemo<TransactionMailPersonalType>(
    () => params?.transactionMailType as TransactionMailPersonalType,
    [params],
  );
  const mailWallName = useMemo(
    () => TRANSACTION_MAIL_PERSONALS.get(transactionMailType)?.name || '',
    [transactionMailType],
  );
  const title = useMemo(() => {
    const suffix = isEdit ? '編集' : '新規作成';

    return `${mailWallName}画面設定 ${suffix}`;
  }, [mailWallName, isEdit]);
  useSetPageTitle(title);

  return (
    <>
      <Helmet>
        <title>{title}</title>
      </Helmet>
      <TransactionMailEditor
        transactionMailType={transactionMailType}
        id={transactionMailId}
        isEdit={isEdit}
      />
    </>
  );
});

const CustomErrorContents = () => {
  const params = useParams();
  const transactionMailType = useMemo(
    () => params?.transactionMailType ?? '',
    [params],
  );
  const transactionMailTypeName = useMemo(
    () =>
      TRANSACTION_MAIL_PERSONALS.get(
        transactionMailType as TransactionMailCategoryType,
      )?.name || '',
    [transactionMailType],
  );

  return <ErrorContents name={`${transactionMailTypeName}設定画面エディタ`} />;
};

export const TransactionMailEdit = () => {
  const ErrorComponentWithProps = <CustomErrorContents />;

  const WrappedMailWallTable = withSuspenseAndErrorBoundary(
    transactionMailEdit,
    {
      ErrorComponent: ErrorComponentWithProps,
      LoadingComponent: <LoadingSpinner />,
    },
  );

  return <WrappedMailWallTable />;
};
