import {
  Button,
  Modal,
  ModalBody,
  ModalCloseButton,
  ModalContent,
  ModalFooter,
  ModalHeader,
  ModalOverlay,
} from '@chakra-ui/react';
import { AlertInfoBar } from 'admin/components/ui/alertInfo';
import { ColorSet } from 'admin/define/colors';
import { useErrorModal } from 'context/ErrorModalContext';
import { FC } from 'react';

const ErrorModal: FC = () => {
  const {
    isErrorModalVisible,
    hideErrorModal,
    errorModalTitle,
    errorModalMessage,
  } = useErrorModal();

  if (!isErrorModalVisible) return null;

  return (
    <Modal
      isOpen
      onClose={() => hideErrorModal()}
      size="md"
      isCentered
      closeOnEsc={false}
      closeOnOverlayClick={false}
    >
      <ModalOverlay />
      <ModalContent>
        <ModalHeader>{errorModalTitle}</ModalHeader>
        <ModalCloseButton />
        <ModalBody>
          <AlertInfoBar type="error" iconType="error">
            {errorModalMessage}
          </AlertInfoBar>
        </ModalBody>
        <ModalFooter justifyContent="center">
          <Button
            onClick={() => {
              hideErrorModal();
              window.location.reload();
            }}
            {...ColorSet.Primary}
          >
            リロード
          </Button>
        </ModalFooter>
      </ModalContent>
    </Modal>
  );
};

export default ErrorModal;
