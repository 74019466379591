export const messages = {
  // テナント情報取得
  getTenant: {
    customer: {
      error:
        '申し訳ございません。テナント情報を取得できません。時間を置いて再表示をお願いします。',
      failed: '申し訳ございません。テナント情報を参照できません。',
    },
    system: {
      typeError:
        '[api.tenant.getTenant] テナント情報のレスポンス型が予期せぬデータ型です。フロントの修正をしてください',
    },
  },
  // グループリスト取得
  getGroupList: {
    customer: {
      notFound:
        'グループリストが存在しません。既に削除された可能性があります。',
      error:
        '申し訳ございません。グループリストを取得できません。時間を置いて再表示をお願いします。',
      typeError: '申し訳ございません。グループリストを参照できません。',
    },
    system: {
      typeError:
        '[api.tenant.getGroupList] グループリストのレスポンス型が予期せぬデータ型です。フロントの修正をしてください',
    },
  },
  // グループ詳細取得
  getGroup: {
    customer: {
      notFound: 'グループが存在しません。既に削除された可能性があります。',
      error:
        '申し訳ございません。グループ詳細を取得できません。時間を置いて再表示をお願いします。',
    },
    system: {
      typeError:
        '[api.tenant.getGroup] グループ詳細のレスポンス型が予期せぬデータ型です。フロントの修正をしてください',
    },
  },
  // テナントにグループ追加
  postAddGroup: {
    customer: {
      error: 'グループ追加できませんでした。',
      failed:
        'グループ追加に失敗しました。恐れ入りますが時間をおいて再度登録ください。',
    },
    system: {
      typeError:
        '[api.tenant.postAddGroup] グループ追加のレスポンス型が予期せぬデータ型です。フロントの修正をしてください',
    },
  },
  // グループ情報修正
  putEditGroup: {
    customer: {
      error: '申し訳ございません。グループ編集に失敗しました。',
      failed:
        '申し訳ございません。グループ編集に失敗しました。恐れ入りますが時間をおいて再度登録ください。',
    },
    system: {
      typeError:
        '[api.tenant.putEditGroup] グループ編集のレスポンス型が予期せぬデータ型です。フロントの修正をしてください',
    },
  },
  // テナントAPIキー取得
  getApiKey: {
    customer: {
      error: '申し訳ございません。APIキーを取得できませんでした。',
      failed:
        '申し訳ございません。APIキーを取得できませんでした。恐れ入りますが時間をおいて再度登録ください。',
    },
    system: {
      typeError:
        '[api.tenant.getApiKey] APIキー取得のレスポンス型が予期せぬデータ型です。フロントの修正をしてください',
    },
  },
  // グループ削除可否チェック
  getGroupDeleteCheck: {
    customer: {
      notFound: 'グループが存在しません。既に削除された可能性があります。',
      error: '申し訳ございません。グループ削除可否チェックに失敗しました。',
      failed:
        '申し訳ございません。グループ削除可否チェックに失敗しました。恐れ入りますが時間をおいて再度登録ください。',
    },
    system: {
      typeError:
        '[api.tenant.getGroupDeleteCheck] グループ削除可否のレスポンス型が予期せぬデータ型です。フロントの修正をしてください',
    },
  },
  // テナント情報取得
  getWebUrls: {
    customer: {
      error:
        '申し訳ございません。テナント情報を取得できません。時間を置いて再表示をお願いします。',
      failed: '申し訳ございません。テナント情報を参照できません。',
    },
    system: {
      typeError:
        '[api.tenant.getTenant] テナント情報のレスポンス型が予期せぬデータ型です。フロントの修正をしてください',
    },
  },
  // Gmo情報取得
  getGmoInfo: {
    customer: {
      error: '申し訳ございません。GMO情報を取得できませんでした。',
      failed:
        '申し訳ございません。GMO情報を取得できませんでした。恐れ入りますが時間をおいて再度登録ください。',
    },
    system: {
      typeError:
        '[api.tenant.getGmoInfo] GMO情報取得のレスポンス型が予期せぬデータ型です。フロントの修正をしてください',
    },
  },
  // Gmo情報更新
  patchGmoInfo: {
    customer: {
      error: '申し訳ございません。GMO情報の更新に失敗しました。',
      failed:
        '申し訳ございません。GMO情報の更新に失敗しました。恐れ入りますが時間をおいて再度登録ください。',
    },
    system: {
      typeError:
        '[api.tenant.patchGmoInfo] GMO情報の更新のレスポンス型が予期せぬデータ型です。フロントの修正をしてください',
    },
  },
  // フォーム関連設定取得
  getFormHosting: {
    customer: {
      error: '申し訳ございません。フォーム関連設定情報を取得できませんでした。',
      failed:
        '申し訳ございません。フォーム関連設定情報を取得できませんでした。恐れ入りますが時間をおいて再度登録ください。',
    },
    system: {
      typeError:
        '[api.tenant.getFormHosting] フォーム関連設定情報取得のレスポンス型が予期せぬデータ型です。フロントの修正をしてください',
    },
  },
  // テナントにグループ追加
  postFormHosting: {
    customer: {
      error: '申し訳ございません。フォーム関連設定情報の更新に失敗しました。',
      failed:
        '申し訳ございません。フォーム関連設定情報の更新に失敗しました。恐れ入りますが時間をおいて再度登録ください。',
    },
    system: {
      typeError:
        '[api.tenant.postFormHosting] フォーム関連設定情報の更新のレスポンス型が予期せぬデータ型です。フロントの修正をしてください',
    },
  },
  // ログインリダイレクト設定取得
  getLoginSetting: {
    customer: {
      error:
        '申し訳ございません。ログインリダイレクト設定を取得できません。時間を置いて再表示をお願いします。',
      failed: '申し訳ございません。ログインリダイレクト設定を参照できません。',
    },
    system: {
      typeError:
        '[api.tenant.getLoginSetting] ログインリダイレクト設定のレスポンス型が予期せぬデータ型です。フロントの修正をしてください',
    },
  },
  // ログインリダイレクト設定更新
  patchLoginSetting: {
    customer: {
      error: '申し訳ございません。ログインリダイレクト設定の更新に失敗しました。',
      failed:
        '申し訳ございません。ログインリダイレクト設定の更新に失敗しました。恐れ入りますが時間をおいて再度登録ください。',
    },
    system: {
      typeError:
        '[api.tenant.patchLoginSetting] ログインリダイレクト設定の更新のレスポンス型が予期せぬデータ型です。フロントの修正をしてください',
    },
  },
};
