import { withSuspenseAndErrorBoundary } from 'admin/components/Error/WithErrorBoundary';
import { TransactionMailsTitleComponent } from 'admin/components/idPoolConsumer/transactionMail/transactionMailsTitle/TransactionMailsTitleComponent';
import { TRANSACTION_MAIL_PERSONALS } from 'admin/define/idPoolConsumer/transactionMail';
import { useIdPoolConsumerId } from 'admin/hooks/useIdPoolConsumerId';
import { TransactionMailPersonalType } from 'admin/types/userPool/transactionMail';
import { ErrorContents } from 'components/common/atoms';
import { FC, memo, useCallback, useMemo } from 'react';
import { useNavigate, useParams } from 'react-router-dom';

const transactionMailsTitle: FC = memo(() => {
  const params = useParams();
  const navigate = useNavigate();
  const idPoolConsumerId = useIdPoolConsumerId();
  const transactionMailType = useMemo<TransactionMailPersonalType>(
    () => params?.transactionMailType as TransactionMailPersonalType,
    [params],
  );
  const transactionMailName = useMemo(
    () => TRANSACTION_MAIL_PERSONALS.get(transactionMailType)?.name || '',
    [transactionMailType],
  );

  // 新規作成ボタンを押した時
  const onClick = useCallback(() => {
    navigate(
      `/admin/idPoolConsumer/${idPoolConsumerId}/transactionMail/${transactionMailType}/wallEditor/`,
    );
  }, [idPoolConsumerId, transactionMailType, navigate]);

  return (
    <TransactionMailsTitleComponent
      transactionMailName={transactionMailName}
      onClick={onClick}
    />
  );
});

export const TransactionMailsTitle = withSuspenseAndErrorBoundary(
  transactionMailsTitle,
  {
    ErrorComponent: <ErrorContents name="ID会員登録時メール画面設定タイトル" />,
    LoadingComponent: <></>,
  },
);
