import { UseMutateFunction, useMutation } from 'react-query';
import { ResponseError } from 'api/types';
import { MailCancelReserve, MailSetting } from 'api/mail/types';
import { deleteMailReserve } from 'api/mail/deleteMailReserve';
import { useCustomToast } from 'hooks/useCustomToast';
import { useUserTenantId } from 'hooks/user/useUserTenantId';
import { useMailUtil } from 'hooks/mail/useMailUtil';
import { successToast, errorToast } from 'utils/toast';
import { isGetValidationError } from 'utils/form';
import { UseFormReset } from 'react-hook-form';

type UnPromisify<T> = T extends Promise<infer U> ? U : T;

const successTitle = '配信予約を解除しました。';
const errorTitle = '配信予約解除に失敗しました。';

/**
 * メール配信予約解除 hooks
 */
export const useDeleteMailReserve = ({
  mailId,
  onClose,
  reset,
  setDelivaryDate,
  reloadData,
}: {
  mailId: string;
  onClose: () => void;
  reset: UseFormReset<MailSetting>;
  setDelivaryDate: React.Dispatch<React.SetStateAction<Date | null>>;
  reloadData: () => void;
}): {
  onCancel: UseMutateFunction<
    UnPromisify<ReturnType<typeof deleteMailReserve>>,
    // 例外時
    unknown,
    void,
    unknown
  >;
  isLoading: boolean;
  isSuccess: boolean;
} => {
  const toast = useCustomToast();
  const tenantId = useUserTenantId();
  const { MailListRefetchQueries } = useMailUtil();

  const {
    mutate: onCancel,
    isLoading,
    isSuccess,
  } = useMutation(() => deleteMailReserve({ tenantId, mailId }), {
    onSuccess: (result: boolean | ResponseError) => {
      MailListRefetchQueries();
      onClose();

      if (typeof result === 'boolean' && result) {
        reset();
        setDelivaryDate(null);
        reloadData();
        toast({
          ...successToast,
          title: successTitle,
        });

        return;
      }

      if (
        isGetValidationError<MailCancelReserve>(
          result,
          Object.keys({ mailId: '' }),
        )
      ) {
        if (result?.others) {
          const errorMsg = Object.entries(result).map(([_, value]) => value);
          toast({
            ...errorToast,
            duration: 4000,
            title: errorTitle,
            description: errorMsg[0],
          });
        }
      } else {
        toast({
          ...errorToast,
          title: errorTitle,
        });
      }
    },
  });

  return { onCancel, isLoading, isSuccess };
};
