import { zodResolver } from '@hookform/resolvers/zod';
import { withSuspenseAndErrorBoundary } from 'admin/components/Error/WithErrorBoundary';
import { LoadingLayer } from 'admin/components/ui/loadingLayer';
import { useCodeSetting } from 'admin/hooks/userPool/code/useCodeSetting';
import { useEditCodeSetting } from 'admin/hooks/userPool/code/useEditCodeSetting';
import { editCodeSchema } from 'admin/schema/idPoolConsumer/code';
import { EditCodeFormType } from 'admin/types/userPool/code/form';
import { AlertBar } from 'components/common/atoms';
import { FC, memo, useMemo, useState } from 'react';
import { FormProvider, SubmitHandler, useForm } from 'react-hook-form';
import { useParams } from 'react-router-dom';
import { CodeEditFormComponent } from './CodeEditFormComponent';

type Props = {
  onClose: () => void;
  formId: string;
  codeSettingId: string;
};

const FetchCodeEditForm: FC<Props> = memo(
  ({ onClose, formId, codeSettingId }: Props) => {
    const params = useParams();
    const codeGroupId = useMemo(() => params.masterId ?? '', [params]);
    const [globalErrors, setGlobalErrors] = useState<string[]>([]);

    const { data: codeSetting } = useCodeSetting(codeGroupId, codeSettingId);

    const methods = useForm<EditCodeFormType>({
      defaultValues: {
        id: codeSetting?.id,
        codeGroup: codeGroupId,
        displayNameJa: codeSetting?.displayNameJa ?? '',
        displayNameForeign: codeSetting?.displayNameForeign ?? '',
        code: codeSetting?.code ?? '',
        displayOrder: codeSetting?.displayOrder ?? '',
      },
      resolver: zodResolver(editCodeSchema),
    });

    const { mutate, isLoading } = useEditCodeSetting(
      methods.setError,
      setGlobalErrors,
    );

    const onSubmit: SubmitHandler<EditCodeFormType> = async (
      data: EditCodeFormType,
    ) => {
      await mutate(data);
      onClose();
    };

    return (
      <FormProvider {...methods}>
        {isLoading && <LoadingLayer />}

        {globalErrors.map((err, idx) => (
          <AlertBar
            key={`global-err-idx${String(idx)}`}
            message={err}
            status="error"
            mb={4}
          />
        ))}
        <CodeEditFormComponent
          onSubmit={onSubmit}
          formId={formId}
          isLoading={isLoading}
        />
      </FormProvider>
    );
  },
);

export const CodeEditForm = withSuspenseAndErrorBoundary(FetchCodeEditForm);
