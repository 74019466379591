import { Icon, IconProps } from '@chakra-ui/react';

type CustomIconType = (props: IconProps) => JSX.Element;
export const WarningIcon: CustomIconType = (props: IconProps) => (
  <Icon xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" fill="none" {...props}>
    <mask x="0" y="0" width="24" height="24">
      <rect width="24" height="24" fill="#D9D9D9"/>
    </mask>
    <g>
      <path d="M0.275391 21.4234L12.0004 1.14844L23.7254 21.4234H0.275391ZM12.0004 17.9234C12.3004 17.9234 12.5629 17.8109 12.7879 17.5859C13.0129 17.3609 13.1254 17.0984 13.1254 16.7984C13.1254 16.4984 13.0129 16.2401 12.7879 16.0234C12.5629 15.8068 12.3004 15.6984 12.0004 15.6984C11.7004 15.6984 11.4379 15.8068 11.2129 16.0234C10.9879 16.2401 10.8754 16.4984 10.8754 16.7984C10.8754 17.0984 10.9879 17.3609 11.2129 17.5859C11.4379 17.8109 11.7004 17.9234 12.0004 17.9234ZM11.0004 14.9984H13.0004V10.2734H11.0004V14.9984Z" fill="currentColor"/>
    </g>
  </Icon>
)
