import {
  maxLength,
  headTitleOptions,
  screenNameOptions,
  screenDescriptionOptions,
  buttonNameOptions,
  itemNameOptions,
  textareaElementOptions,
} from './options';

const groupName = 'verificationCodeEntry';

// 認証コード入力画面
export default [
  // ロゴ	画像
  {
    name: `${groupName}.headTitle`,
    label: 'ブラウザタブ表示名',
    tooltip: '',
    type: 'Text',
    defaultValue: 'メールアドレス確認',
    options: headTitleOptions,
    elementOptions: {},
  },
  {
    name: `${groupName}.screenName`,
    label: '画面名',
    tooltip: '',
    type: 'Text',
    defaultValue: 'メールアドレス確認',
    options: screenNameOptions,
    elementOptions: {},
  },
  {
    name: `${groupName}.screenDescription`,
    label: '案内文 画面',
    tooltip: '',
    type: 'Textarea',
    defaultValue:
      '前画面で登録したメールアドレス宛に届いたメールに記載されている６桁のコードを入力してください。',
    options: screenDescriptionOptions,
    elementOptions: textareaElementOptions,
  },
  {
    name: `${groupName}.codeEntryItemName`,
    label: '入力欄名 認証コード',
    tooltip: '',
    type: 'Text',
    defaultValue: 'コードの入力',
    options: itemNameOptions,
    elementOptions: {},
  },
  {
    name: `${groupName}.continueButtonName`,
    label: 'ボタン名 次の画面に遷移',
    tooltip: '',
    type: 'Text',
    defaultValue: '続ける',
    options: buttonNameOptions,
    elementOptions: {},
  },
  {
    name: `${groupName}.backButtonName`,
    label: 'ボタン名 前の画面に遷移',
    tooltip: '',
    type: 'Text',
    defaultValue: '戻る',
    options: buttonNameOptions,
    elementOptions: {},
  },
  {
    name: `${groupName}.resendInstructionText1`,
    label: '案内文 再送信案内',
    tooltip: '',
    type: 'Textarea',
    defaultValue:
      '認証コードメールが届いていない場合、前の画面に戻り再度送信をお試しください。',
    options: {
      maxLength: maxLength(50),
    },
    elementOptions: textareaElementOptions,
  },
] as const;
