import { Options } from 'ky';
import { request } from 'api/request';
import { logger } from 'api/logger';
import { CustomError } from 'api/error/CustomError';
import { SkinInfo, isSkinInfo } from './types';
import { messages } from './messages';

// デザインスキン一覧取得API
export const getSkinList = async ({
  tenantId,
  ordering,
  page,
  perPage,
  options,
  reserve,
  status,
}: {
  tenantId: string;
  ordering?: string;
  page?: string;
  perPage?: string;
  options?: Options;
  reserve?: string;
  status?: string;
}): Promise<SkinInfo> => {
  const queryParam = [];
  if (ordering) {
    queryParam.push(`ordering=${ordering}`);
  }
  if (page) {
    queryParam.push(`page=${page}`);
  }
  if (perPage) {
    queryParam.push(`per_page=${perPage}`);
  }
  if (reserve) {
    queryParam.push(`start_date=${reserve}`);
  }
  if (status) {
    queryParam.push(`status=${status}`);
  }

  const param = queryParam.length > 0 ? `?${queryParam.join('&')}` : '';
  const path = `enquete/${tenantId}/skin/${param}`;

  const response = await request({
    path,
    method: 'get',
    options,
  });

  const responseData = (await response.data) as unknown[];

  if (response.hasError) {
    if (response.status === 404) {
      throw new CustomError(
        messages.getSkinList.customer.notFound,
        response.status,
      );
    } else {
      throw new CustomError(
        messages.getSkinList.customer.error,
        response.status,
      );
    }
  }

  if (!isSkinInfo(responseData)) {
    await logger({
      loglevel:
        response.status === 404 || response.status === 450
          ? 'warning'
          : 'error',
      data: responseData,
      message: messages.getSkinList.system.typeError,
    });

    throw new CustomError(messages.getSkinList.customer.failed);
  }

  return responseData;
};
