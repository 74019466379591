import { SearchIcon } from '@chakra-ui/icons';
import { Button, Flex, Input } from '@chakra-ui/react';
import { FC, KeyboardEvent, memo, useState } from 'react';

type Props = {
  onSearch: (keyword: string) => void;
};

export const UsersFilter: FC<Props> = memo(({ onSearch }: Props) => {
  const [inputValue, setInputValue] = useState('');

  const onKeyDown = (e: KeyboardEvent<HTMLInputElement>) => {
    if (e.key === 'Enter') {
      onSearch(inputValue);
    }
  };

  return (
    <Flex justifyContent="space-between" alignItems="center">
      <Input
        placeholder="ユーザー名・メールアドレスで検索"
        value={inputValue}
        onChange={(e) => setInputValue(e.target.value)}
        bgColor="WHITE"
        onKeyDown={onKeyDown}
      />
      <Button
        variant="outline"
        colorScheme="primary"
        leftIcon={<SearchIcon />}
        onClick={() => onSearch(inputValue)}
        h="37px"
        ml="8px"
        bgColor="WHITE"
      >
        検索
      </Button>
    </Flex>
  );
});
