import {
  Box,
  Button,
  DrawerBody,
  DrawerFooter,
  FormControl,
  FormErrorMessage,
  HStack,
  ListItem,
  Text,
} from '@chakra-ui/react';
import { RhfInput } from 'admin/components/form/RhfInput';
import { AlertInfoBar } from 'admin/components/ui/alertInfo';
import { Colors, ColorSet } from 'admin/define/colors';
import {
  AuthFormInputType,
  AuthFormOutputType,
} from 'admin/types/service/auth/form';
import { ConfirmSubmitFormType } from 'admin/types/utils';
import { FC, memo } from 'react';
import { useFormContext, useFormState } from 'react-hook-form';

export type Props = {
  target: AuthFormInputType;
  onClose: () => void;
  onSubmit: (values: AuthFormOutputType) => void;
  formId: string;
};

export const CallbackUrlConfirmFormComponent: FC<Props> = memo(
  ({ onClose, onSubmit, formId, target }) => {
    const { handleSubmit } = useFormContext<ConfirmSubmitFormType>();
    const { errors, isValid } = useFormState<ConfirmSubmitFormType>();

    return (
      <>
        <DrawerBody fontSize="16px">
          <Box mb={6}>
            <Text fontSize="14px" fontWeight="bold">
              コールバックURLの一覧
            </Text>
            <Text
              whiteSpace="pre-wrap"
              p="8px 16px"
              borderBottom={`solid 1px ${Colors.GRAY_30}`}
            >
              {target.redirectUri}
            </Text>
          </Box>
          <AlertInfoBar type="error" iconType="error">
            <ListItem fontWeight="bold">
              コールバックURLの設定は、セキュリティとシステムの正しい動作に重要な影響を与えます。
            </ListItem>
            <ListItem fontWeight="bold">
              設定するコールバックURLが正しいか十分に確認してください。
            </ListItem>
          </AlertInfoBar>

          <form onSubmit={handleSubmit(onSubmit)} id={formId}>
            <Text mt={6} mb={1} fontSize="sm" fontWeight="bold">
              保存する場合は「save」と入力後に「保存ボタン」を押下してください。
            </Text>
            <FormControl isInvalid={Boolean(errors.word?.message)}>
              <RhfInput<ConfirmSubmitFormType> name="word" type="text" />
              <FormErrorMessage>{errors.word?.message}</FormErrorMessage>
            </FormControl>
          </form>
        </DrawerBody>
        <DrawerFooter>
          <HStack spacing={4}>
            <Button
              w="60px"
              variant="outline"
              onClick={() => onClose()}
              {...ColorSet.Default}
              padding="8px 16px"
            >
              戻る
            </Button>
            <Button
              w="100px"
              colorScheme="primary"
              type="submit"
              mr={3}
              form={formId}
              disabled={!isValid}
              padding="8px 36px"
            >
              保存
            </Button>
          </HStack>
        </DrawerFooter>
      </>
    );
  },
);
