import { VFC, memo, useState, useEffect } from 'react';
import { useFormContext } from 'react-hook-form';
import { Box, Flex, Text, Stack } from '@chakra-ui/react';
import { InputTextForm, ColorPicker } from 'components/common/atoms';
import { SkinItemPreview } from 'components/enquete/atoms/SkinItemPreview';
import { Skin } from 'api/enquete/types';
import { SkinDefaultValues } from 'components/enquete/EnqueteSkin';

export const SkinRadioButton: VFC = memo(() => {
  const [radioButtonSelectedColor, setRadioButtonSelectedColor] = useState(
    SkinDefaultValues.radioButtonSelectedColor,
  );
  const { getValues, watch } = useFormContext<Skin>();

  useEffect(() => {
    const skinViewData = watch((value, { name }) => {
      if (name === 'radioButtonSelectedColor') {
        setRadioButtonSelectedColor(value[name] || '');
      }
    });

    return () => skinViewData.unsubscribe();
  }, [watch]);

  return (
    <Flex alignItems="stretch" mb={4} mt={4}>
      <Box minW="220px" mt="2px">
        <Text as="label">ラジオボタン</Text>
      </Box>
      <Stack minW="250px">
        <Flex alignItems="flex-end">
          <InputTextForm<Skin>
            label="選択色"
            labelCaption="選択色"
            name="radioButtonSelectedColor"
            value={getValues('radioButtonSelectedColor')}
            placeholder="#FFFFFF"
            mr={4}
          />
          <ColorPicker<Skin>
            name="radioButtonSelectedColor"
            value={getValues('radioButtonSelectedColor') || '#FFFFFF'}
            width="40px"
            height="40px"
          />
        </Flex>
      </Stack>
      <SkinItemPreview
        element={
          <Box w="20px" position="relative">
            <Text
              as="label"
              _before={{
                content: '""',
                width: '20px',
                height: '20px',
                position: 'absolute',
                top: '50%',
                left: '0',
                transform: 'translate(0, -50%)',
                background: '#FFF',
                border: '1px solid #ccc',
                borderRadius: '100%',
              }}
              _after={{
                content: '""',
                width: '12px',
                height: '12px',
                position: 'absolute',
                top: '50%',
                left: '4px',
                transform: 'translate(0, -50%)',
                background: radioButtonSelectedColor,
                border: 'none',
                borderRadius: '100%',
              }}
            />
          </Box>
        }
        width="auto"
      />
    </Flex>
  );
});
