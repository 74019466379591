import { Box, Text } from '@chakra-ui/react';
import { withSuspenseAndErrorBoundary } from 'admin/components/Error/WithErrorBoundary';
import { MemberAttributeTable } from 'admin/components/member/detail/memberAttribute/memberAttributeTable';
import { Pagination } from 'admin/components/ui/pagination';
import { usePaginationQuery } from 'admin/hooks/pagination/usePaginationQuery';
import { Page } from 'api/common/types';
import { ErrorContents } from 'components/common/atoms';
import { FC, memo, useMemo, useState } from 'react';

const memberAttribute: FC = memo(() => {
  const [page, setPage] = useState<Page>({
    count: 0,
    currentPage: 1,
    countFrom: 0,
    countTo: 0,
    perPage: 50,
    pageCount: 0,
    next: '',
    previous: '',
  });
  const { query } = usePaginationQuery({ page });
  const currentPage = useMemo(() => query.currentPage, [query.currentPage]);
  const perPage = useMemo(() => query.currentPerPage, [query.currentPerPage]);

  return (
    <>
      <Box>
        <Box>
          <Text fontWeight="700" fontSize="1rem">
            属性一覧
          </Text>
        </Box>
        <MemberAttributeTable
          currentPage={currentPage}
          perPage={perPage}
          setPage={setPage}
        />
        <Pagination page={page} />
      </Box>
    </>
  );
});

export const MemberAttribute = withSuspenseAndErrorBoundary(memberAttribute, {
  ErrorComponent: <ErrorContents name="属性一覧情報" />,
});
