import { useApiCustomerDetail } from 'api/paywall/useApi';
import { CustomerDetailInnerInfo } from 'components/paywall/pages/CustomerDetail/CustomerDetailInner/CustomerDetailInnerInfo';
import { CustomerDetailInnerMemo } from 'components/paywall/pages/CustomerDetail/CustomerDetailInner/CustomerDetailInnerMemo';
import { CustomerDetailInnerMemos } from 'components/paywall/pages/CustomerDetail/CustomerDetailInner/CustomerDetailInnerMemos';
import { CustomerDetailInnerOrders } from 'components/paywall/pages/CustomerDetail/CustomerDetailInner/CustomerDetailInnerOrders';
import {
  ApiCustomerDetail,
  CustomerDetail
} from 'components/paywall/pages/CustomerList/typed';
import { FC, memo, useEffect, useState } from 'react';

type Props = {
  tenantId: string;
  customerUid: string;
};

export const CustomerDetailInner: FC<Props> = memo(({ customerUid }) => {
  const [customerDetail, setCustomerDetail] = useState<CustomerDetail>();
  const { data, isLoading, isFetching } = useApiCustomerDetail(customerUid);

  useEffect(() => {
    if (isLoading || isFetching) return;
    if (!data) return;
    const apiReturn = data as unknown as ApiCustomerDetail;
    if (!apiReturn.data) return;
    setCustomerDetail(apiReturn.data as unknown as CustomerDetail);
  }, [data, isFetching, isLoading]);

  return (
    <>
      {/* 顧客情報 */}
      <CustomerDetailInnerInfo customerDetail={customerDetail} />
      {/* 有効なサブスクリプション注文 */}
      <CustomerDetailInnerOrders orders={customerDetail?.order||[]} customerUid={customerDetail?.customerUid || ''}/>
      {/* メモ */}
      <CustomerDetailInnerMemos memos={customerDetail?.memos||[]}/>
      {/* 編集メモ入力欄 注文IDを指定しない方針の為、orderIdには空文字をセットする */}
      <CustomerDetailInnerMemo orderId='' customerUid={customerDetail?.customerUid||''} />
    </>
  );
});
