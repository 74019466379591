import {
  Box,
  Button,
  Flex,
  ListItem,
  Text,
  UnorderedList,
} from '@chakra-ui/react';
import { Colors } from 'admin/define/colors';
import { AuthType } from 'admin/types/service/auth';
import { FC, memo } from 'react';

type Props = {
  authData: AuthType;
  onOpen: () => void;
};

export const CallbackUrlComponent: FC<Props> = memo(
  ({ authData, onOpen }: Props) => (
    <Box display="inline-block" w="100%">
      <Flex>
        <Box display="block" mr="auto" fontFamily="Hiragino Sans">
          <Text fontWeight="700">コールバックURL</Text>
          <UnorderedList marginInlineStart="24px">
            <ListItem>
              <Text color={Colors.GRAY_00} fontSize="12px">
                Auth Hubで利用する認証認可機能で必要になります。
              </Text>
            </ListItem>
          </UnorderedList>
        </Box>
        <Button
          variant="outline"
          colorScheme="primary"
          p="4px 12px"
          onClick={onOpen}
        >
          コールバックURL設定
        </Button>
      </Flex>
      <Box
        mt="16px"
        p="8px 16px"
        height="auto"
        minHeight="40px"
        bgColor={Colors.GRAY_70}
        fontFamily="Hiragino Sans"
        borderRadius="8px"
        whiteSpace="pre-wrap"
      >
        {authData.redirectUri && authData.redirectUri.length > 0 ? (
          authData.redirectUri.map((item) => (
            <Text fontSize="16px">{item}</Text>
          ))
        ) : (
          <Text fontSize="16px">-</Text>
        )}
      </Box>
    </Box>
  ),
);
