import {
  Box,
  Button,
  DrawerBody,
  DrawerFooter,
  Flex,
  FormControl,
  FormErrorMessage,
  Heading,
  VStack,
} from '@chakra-ui/react';
import { RhfSelect } from 'admin/components/form/RhfSelect';
import { RequiredIcon } from 'admin/components/ui/icon';
import { LoadingLayer } from 'admin/components/ui/loadingLayer';
import { Colors, ColorSet } from 'admin/define/colors';
import { TransactionMailCommonType } from 'admin/types/userPool/transactionMail';
import { TenantMailList } from 'api/tenant/types';
import { FC, memo } from 'react';
import { UseFormHandleSubmit, useFormState } from 'react-hook-form';

type Props = {
  formId: string;
  onClose: () => void;
  isLoading: boolean;
  fromAddressList: TenantMailList[];
  replyAddressList: TenantMailList[];
  onSubmit: (value: TransactionMailCommonType) => void;
  handleSubmit: UseFormHandleSubmit<TransactionMailCommonType>;
};

export const TransactionMailCommonFormComponent: FC<Props> = memo(
  ({
    formId,
    onClose,
    isLoading,
    fromAddressList,
    replyAddressList,
    onSubmit,
    handleSubmit,
  }: Props) => {
    const { errors } = useFormState<TransactionMailCommonType>();

    return (
      <>
        {isLoading && <LoadingLayer />}
        <DrawerBody pt="24px" pb="16px">
          <form onSubmit={handleSubmit(onSubmit)} id={formId}>
            <VStack spacing={6}>
              <Box w="100%">
                <Heading as="h3" size="sm" mb="4px">
                  <Flex justifyContent="left" alignItems="start">
                    差出人メールアドレス
                    <RequiredIcon ml="8px" />
                  </Flex>
                </Heading>
                <Box>
                  <FormControl isInvalid={Boolean(errors.fromAddress?.message)}>
                    <RhfSelect<TransactionMailCommonType>
                      name="fromAddress"
                      placeholder="選択してください"
                      bgColor={Colors.WHITE}
                    >
                      {fromAddressList.map((v) => (
                        <option key={v.id} value={v.name}>
                          {v.name}
                        </option>
                      ))}
                    </RhfSelect>
                    <FormErrorMessage>
                      {errors.fromAddress?.message}
                    </FormErrorMessage>
                  </FormControl>
                </Box>
              </Box>
              <Box w="100%">
                <Heading as="h3" size="sm" mb="4px">
                  <Flex justifyContent="left" alignItems="start">
                    返信用メールアドレス
                  </Flex>
                </Heading>
                <FormControl isInvalid={Boolean(errors.replyAddress?.message)}>
                  <RhfSelect<TransactionMailCommonType>
                    name="replyAddress"
                    placeholder="選択してください"
                    bgColor={Colors.WHITE}
                  >
                    {replyAddressList.map((v) => (
                      <option key={v.id} value={v.name}>
                        {v.name}
                      </option>
                    ))}
                  </RhfSelect>
                  <FormErrorMessage>
                    {errors.replyAddress?.message}
                  </FormErrorMessage>
                </FormControl>
              </Box>
            </VStack>
          </form>
        </DrawerBody>
        <DrawerFooter borderWidth="1px">
          <Button
            variant="outline"
            mr={4}
            onClick={onClose}
            p="8px 16px"
            w="104px"
            h="40px"
            {...ColorSet.Default}
          >
            キャンセル
          </Button>
          <Button
            type="submit"
            form={formId}
            p="8px 16px"
            w="104px"
            h="40px"
            colorScheme="primary"
          >
            保存
          </Button>
        </DrawerFooter>
      </>
    );
  },
);
