import { Box, Button, Tr as ChakraTr } from '@chakra-ui/react';
import { AlertInfoButton } from 'admin/components/ui/alertInfo';
import { Table, Tbody, Td, Th, Thead, Tr } from 'admin/components/ui/table';
import { CodeGroupsType } from 'admin/types/userPool/master';
import { useCustomNavigate } from 'hooks/useCustomNavigate';
import { Dispatch, FC, memo, SetStateAction } from 'react';

type Props = {
  masterSettings: CodeGroupsType;
  idPoolConsumerId: string;
  setMasterSettingId: Dispatch<SetStateAction<string>>;
  onDrawerOpen: () => void;
};

export const MasterTableComponent: FC<Props> = memo(
  ({
    masterSettings,
    idPoolConsumerId,
    setMasterSettingId,
    onDrawerOpen,
  }: Props) => {
    const { navigate } = useCustomNavigate({});
    // コード設定URL生成
    const masterCodeUrl = (id: string) =>
      `/admin/idPoolConsumer/${idPoolConsumerId}/master/${id}/code`

    return (
      <Box mt="16px">
        <Table w="100%" maxHeight="calc(100vh - 300px)">
          <Thead h="48px">
            <ChakraTr>
              <Th maxWidth="70px" minWidth="70px">
                選択肢マスタID
              </Th>
              <Th maxWidth="100px" minWidth="100px">
                表示名(日本語)
              </Th>
              <Th maxWidth="100px" minWidth="100px">
                表示名(外国語)
              </Th>
              <Th>
                <></>
              </Th>
            </ChakraTr>
          </Thead>
          <Tbody>
            {masterSettings.map((masterSetting) => (
              <Tr key={`rule${masterSetting.id}`}>
                <Td maxWidth="70px" minWidth="70px">
                  <Button
                    fontWeight="normal"
                    textDecoration="underline"
                    variant="link"
                    colorScheme="primary"
                    onClick={() => {
                      setMasterSettingId(masterSetting.id);
                      onDrawerOpen();
                    }}
                  >
                    {masterSetting.id}
                  </Button>
                </Td>
                <Td maxWidth="100px" minWidth="100px">
                  {masterSetting.displayNameJa}
                </Td>
                <Td maxWidth="100px" minWidth="100px">
                  {masterSetting.displayNameForeign}
                </Td>
                <Td>
                  {masterSetting.hasCodes
                    ? (
                      <Box display="flex" justifyContent="end">
                        <Button
                          variant="outline"
                          colorScheme="primary"
                          onClick={() =>
                            navigate(masterCodeUrl(masterSetting.id))
                          }
                        >
                          コード設定
                        </Button>
                      </Box>
                    ) : (
                      <Box display="flex" justifyContent="end">
                        <AlertInfoButton
                          type="error"
                          iconType="error"
                          hasAlert
                          onClick={() =>
                            navigate(masterCodeUrl(masterSetting.id))
                          }
                        >
                            コード設定
                        </AlertInfoButton>
                      </Box>
                    )
                  }
                </Td>
              </Tr>
            ))}
          </Tbody>
        </Table>
      </Box>
    );
  },
);
