import { ExternalLinkIcon } from '@chakra-ui/icons';
import { Badge, Box, Divider, Heading, Link, Text } from '@chakra-ui/react';
import { Colors } from 'components/paywall/config';
import { useFormHostingInfo } from 'hooks/tenant/useFormHostingInfo';
import { useUserInfo } from 'hooks/useUserInfo';
import { useUserTenantId } from 'hooks/user/useUserTenantId';
import { FC, memo } from 'react';
import { FormSettingEdit } from './FormSettingEdit';

const AvailableBadge: FC = () => (
  <Badge
    bgColor={Colors.BLUE_LIGHT_01}
    color={Colors.BLUE_00}
    px={2}
    borderRadius="4px"
    borderRightWidth="4px"
    textAlign="center"
    height="24px"
    minWidth="77px"
    display="inline-flex"
    alignItems="center"
  >
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="16"
      height="16"
      viewBox="0 0 16 16"
      fill="none"
    >
      <circle cx="8" cy="8" r="4" fill="#113997" />
    </svg>
    利用可能
  </Badge>
);

const NotAvailableBadge: FC = () => (
  <Badge
    bgColor={Colors.GRAY_40}
    color={Colors.GRAY_03}
    px={2}
    borderRadius="4px"
    borderRightWidth="4px"
    textAlign="center"
    height="24px"
    minWidth="77px"
    display="inline-flex"
    alignItems="center"
  >
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="16"
      height="16"
      viewBox="0 0 16 16"
      fill="none"
    >
      <mask
        id="mask0_8664_81061"
        // style="mask-type:alpha"
        maskUnits="userSpaceOnUse"
        x="0"
        y="0"
        width="16"
        height="16"
      >
        <rect width="16" height="16" fill="#D9D9D9" />
      </mask>
      <g mask="url(#mask0_8664_81061)">
        <path
          d="M8 12C6.89523 12 5.95238 11.6095 5.17143 10.8286C4.39048 10.0476 4 9.10477 4 8C4 6.89523 4.39048 5.95238 5.17143 5.17143C5.95238 4.39048 6.89523 4 8 4C9.10477 4 10.0476 4.39048 10.8286 5.17143C11.6095 5.95238 12 6.89523 12 8C12 9.10477 11.6095 10.0476 10.8286 10.8286C10.0476 11.6095 9.10477 12 8 12ZM8.0033 10.8C8.77888 10.8 9.43889 10.5267 9.98333 9.98003C10.5278 9.43339 10.8 8.77228 10.8 7.9967C10.8 7.22112 10.5267 6.56111 9.98003 6.01667C9.43339 5.47222 8.77228 5.2 7.9967 5.2C7.22112 5.2 6.56111 5.47332 6.01667 6.01997C5.47222 6.56661 5.2 7.22772 5.2 8.0033C5.2 8.77888 5.47332 9.43889 6.01997 9.98333C6.56661 10.5278 7.22772 10.8 8.0033 10.8Z"
          fill="#1C1B1F"
        />
      </g>
    </svg>
    <Text as="span" color="#272E35">
      利用不可
    </Text>
  </Badge>
);

export const FormSettingBody: FC = memo(() => {
  const tenantId = useUserTenantId();
  const formHostingInfo = useFormHostingInfo({ tenantId });
  const { isEnabledFormHosting } = useUserInfo();
  const isEnabled = isEnabledFormHosting();

  return (
    <>
      <Box borderWidth="1px" borderRadius="xl" p={4} w="100%" lineHeight="170%">
        <Box
          display="flex"
          flexDirection="column"
          justifyContent="center"
          alignItems="flex-start"
          alignSelf="stretch"
        >
          <Box p={0} m={0}>
            <Heading as="h5" size="sm" mb={4}>
              ・ホスティング型表示機能の利用可否
            </Heading>
            <Box pl="1em">
              {isEnabled ? <AvailableBadge /> : <NotAvailableBadge />}
            </Box>
          </Box>
        </Box>
        {formHostingInfo.hostingUrl && (
          <>
            <Divider my="24px" />
            <Heading as="h5" size="sm" pb={2}>
              ・ホスティング型表示URL
            </Heading>
            <Link
              href={isEnabled ? formHostingInfo.hostingUrl : undefined}
              isExternal
              ml="1em"
              borderWidth="1px"
              borderRadius="xl"
              py={2}
              px={4}
              display="flex"
              alignItems="center"
              alignSelf="stretch"
              justifyContent="space-between"
            >
              <Text fontSize="sm">{formHostingInfo.hostingUrl || ''}</Text>
              <Text>
                <ExternalLinkIcon mx="2px" />
              </Text>
            </Link>
          </>
        )}
      </Box>

      <Box borderWidth="1px" borderRadius="xl" p={4} w="100%">
        <FormSettingEdit />
        <Text pl="1em">
          ホスティング型のフォームページに埋め込むタグマネージャーなどのタグの指定、編集ができます。
        </Text>
      </Box>
    </>
  );
});
