import { Flex } from '@chakra-ui/react';
import { ActivateDialog } from 'components/paywall/features/ActivateDialog';
import { usePaywall } from 'components/paywall/hooks/usePaywall';
import { ItemWallDialog } from 'components/paywall/pages/ItemDesignList/ItemWallDialog';
import { StyledPartition } from 'components/paywall/styled/global';
import { useWallList } from 'hooks/paywall/useWallList';
import { FC, memo } from 'react';


export const ItemDesignTableRows: FC = memo(() => {
  const {wallList, childWallList} = useWallList({ wallType: 'item' });
  const { onActivate } = usePaywall({});

  return (
    <>
      {[...wallList, ...childWallList].map((obj) => (
        <tbody key={obj.id}>
          <tr>
            <td colSpan={4}>
              <StyledPartition />
            </td>
          </tr>
          <tr>
            <td>{obj.id}</td>
            <td colSpan={2}>
              <ItemWallDialog
                buttonMode="text"
                buttonName={obj.name}
                obj={obj}
              />
            </td>
            <td>
              <Flex style={{ float: 'right' }}>
                <ActivateDialog
                  id={obj.id}
                  msg={`「${obj.name}」のステータスを「${obj.status ? '無効' : '有効'}」に変更しますか？`}
                  isEnable={obj.status}
                  activate={onActivate}
                />
              </Flex>
            </td>
          </tr>
        </tbody>
      ))}
    </>
  );
});
