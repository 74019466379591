import { getServiceAttributeDetail } from 'admin/api/service/attribute/getServiceAttributeDetail';
import { CustomError } from 'admin/error/CustomError';
import { useUserPoolChildId } from 'admin/hooks/useUserPoolChildId';
import { UserPoolChildAttributeType } from 'admin/types/service/attribute';
import { logger } from 'api/logger';
import { useCustomToast } from 'hooks/useCustomToast';
import { useQuery } from 'react-query';
import { errorToast } from 'utils/toast';
import { querySerivceAttributeKey } from './querySerivceAttributeKey';

export const useServiceAttributeDetail = (
  userPoolChildAttrId: UserPoolChildAttributeType['id']
) => {
  const userPoolChildId = useUserPoolChildId();
  const toast = useCustomToast();
  const { data } = useQuery({
    queryKey: querySerivceAttributeKey.getSerivceAttributeDetail(userPoolChildId, userPoolChildAttrId),
    queryFn: () => getServiceAttributeDetail({userPoolChildId, userPoolChildAttrId}),
    onError: async (error) => {
      if (error instanceof CustomError) {
        await logger({
          loglevel: error.logLevel,
          data: JSON.stringify(error.cause),
          message: error.message,
        });
      }
      toast({
        ...errorToast,
        duration: 4000,
        title: '属性利用設定の取得に失敗しました',
        position: 'bottom',
        variant: 'solid',
      });
    },
  });

  return { data };
};
