import { VFC, memo, useState, useEffect } from 'react';
import { useParams } from 'react-router-dom';
import { Stack, Box, Flex } from '@chakra-ui/react';
import LazyLoad from 'react-lazyload';
import { ChartList } from 'api/chart/types';
import ErrorBoundary from 'api/ErrorBoundary';
import { useErrorBoundaryReset } from 'hooks/useErrorBoundaryReset';
import { useEnqueteDetail } from 'hooks/enquete/useEnqueteDetail';
import { useSetPageTitle } from 'hooks/useSetPageTitle';
import { ErrorStatusContents } from 'components/common/atoms';
import { ChartBlock } from 'components/chart/templates/ChartBlock';

const defaultChart: ChartList = {
  id: '',
  title: '',
  type: 1,
  typeString: '',
  text: '',
  labels: [],
  data: [],
};

const ChartInner: VFC = memo(() => {
  const [initFields, setInitFields] = useState(false);
  const [chartList, setChartList] = useState<ChartList[]>([]);
  const params = useParams();

  // フォーム詳細情報取得
  const {
    id: enqueteId,
    title,
    enqueteContents,
  } = useEnqueteDetail({
    enqueteId: params.enqueteId || '',
  });

  // フォーム名をタイトルに設定
  useSetPageTitle(title);

  useEffect(() => {
    if (initFields) return;
    setInitFields(true);
    // 日時回答数は一番最初に表示する
    const tmpChartList: ChartList[] = [
      {
        ...defaultChart,
        id: 'daily',
        title: '日次回答数',
      },
    ];
    enqueteContents.forEach((item) => {
      tmpChartList.push({
        ...defaultChart,
        id: String(item.id),
        title: item.questionTitle || '',
        typeString: item.type,
      });
    });
    setChartList(tmpChartList);
  }, [enqueteContents, initFields]);

  return (
    <Stack spacing={0} w="100%" maxW="1000px">
      <Box mb={4} borderBottom="2px" borderColor="blue.200" p={2}>
        <Flex alignItems="center" borderBottom="1px" borderColor="gray.200" pb={4} mb={4}>
          <Box
            w={200}
            display="inline-block"
            fontSize="md"
            fontWeight="bold"
            color="#999"
          >
            フォームタイトル
          </Box>
          <Box flex={1}>{title}</Box>
        </Flex>
        <Flex alignItems="center" pb={2}>
          <Box
            w={200}
            display="inline-block"
            fontSize="md"
            fontWeight="bold"
            color="#999"
          >
            フォームID
          </Box>
          <Box flex={1}>{enqueteId}</Box>
        </Flex>
      </Box>
      <Box>
        {chartList?.map((item) => (
          <LazyLoad key={item.id} height={150} offset={[0, 0]}>
            <ChartBlock enqueteId={params.enqueteId || ''} chart={item} />
          </LazyLoad>
        ))}
      </Box>
    </Stack>
  );
});

export const Chart: VFC = () => {
  const { ebKey, onError } = useErrorBoundaryReset();
  const renderErrorComponent = {
    404: <ErrorStatusContents code={404} />,
    450: <ErrorStatusContents code={450} />,
  };

  return (
    <ErrorBoundary
      statusComponent={renderErrorComponent}
      onError={onError}
      key={ebKey.current}
    >
      <ChartInner />
    </ErrorBoundary>
  );
};
