import { Tr } from '@chakra-ui/react';
import { Th } from 'components/paywall/features/Table';
import { FC, memo } from 'react';

export const MembersOnlyWallSettingColumns: FC = memo(() => (
  <Tr>
    <Th
      maxWidth="130px"
      minWidth="130px"
      fontFamily="Hiragino sans"
      name="会員限定ウォールID"
    />
    <Th fontFamily="Hiragino sans" name="会員限定ウォール名" />
    <Th name="" />
  </Tr>
));
