export const contentsDbQueryKey = {
  tableList: ({ tenantId }: { tenantId: string }) => [
    'contents/list',
    tenantId,
  ],
  tableDetail: ({
    tenantId,
    tableId,
  }: {
    tenantId: string;
    tableId: string;
  }) => ['contents', tenantId, tableId],
  uploadHistory: ({
    tenantId,
    tableId,
    page,
  }: {
    tenantId: string;
    tableId: string;
    page?: number;
  }): string[] =>
    page
      ? ['upload/history', tenantId, tableId, String(page)]
      : ['upload/history', tenantId, tableId],
} as const;
