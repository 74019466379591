import { WallDesignDefaultValues } from 'components/paywall/pages/WallDesignList/initial';
import {
  WallDesign,
  WallDesignFormType
} from 'components/paywall/pages/WallDesignList/typed';
import { useCreateWallDesign } from 'hooks/paywall/useCreateWallDesign';
import { useEditWallDesign } from 'hooks/paywall/useEditWallDesign';
import { usePaywallDesign } from 'hooks/paywall/usePaywallDesign';
import { useCustomToast } from 'hooks/useCustomToast';
import { useEmailEditor } from 'hooks/useEmailEditor';
import { useUserTenantId } from 'hooks/user/useUserTenantId';
import { Dispatch, RefObject, SetStateAction, useCallback, useEffect } from 'react';
import EmailEditor, { Design } from 'react-email-editor';
import {
  UseFormGetValues,
  UseFormSetError,
  UseFormSetValue
} from 'react-hook-form';
import { useParams, useSearchParams } from 'react-router-dom';
import { errorToast, successToast } from 'utils/toast';

type Props = {
  editor: RefObject<EmailEditor>;
  getValues: UseFormGetValues<WallDesign>;
  setValue: UseFormSetValue<WallDesign>;
  setError: UseFormSetError<WallDesign>;
  setLoading?: Dispatch<SetStateAction<boolean>>;
};

/**
 * ウォールデザイン作成・編集hooks
 * @param {RefObject<EmailEditor>} editor Email Editor Ref
 * @param {UseFormGetValues<WallDesign>} getValues WallDesign型 react query getValues
 * @param {UseFormSetValue<WallDesign>} setValue WallDesign型 react query setValue
 * @param {UseFormSetError<WallDesign>} setError WallDesign型 react query setError
 * @returns {Object} { init, handleLoad, saveEditor }
 */
export const useWallEditor = ({
  editor,
  getValues,
  setValue,
  setError,
  setLoading,
}: Props): {
  init: () => void;
  handleLoad: ({
    setIsLoading,
  }: {
    setIsLoading: (bool: boolean) => void;
  }) => void;
  saveEditor: () => void;
} => {
  const tenantId = useUserTenantId();
  const [searchParams] = useSearchParams();
  const designId = searchParams.get('formId') || '';
  const params = useParams();
  const toast = useCustomToast();
  const wallDesign = usePaywallDesign({
    wallId: params.wallId || '',
    designId,
  });
  const {
    exportHtml,
    loadDesign,
    editorReady,
    defaultDesignValue,
    imageUploadFinish,
  } = useEmailEditor({ editor });
  const { onSubmit: onCreateSubmit, isLoading: isCreateLoading } = useCreateWallDesign({ setError });
  const { onSubmit: onEditSubmit, isLoading: isEditLoading } = useEditWallDesign({ setError });

  useEffect(() => {
    setLoading?.(isCreateLoading || isEditLoading)
  }, [setLoading, isCreateLoading, isEditLoading])

  /**
   * 現在の日付取得
   */
  const createDate = () => {
    const now = new Date();

    return `${now.getFullYear()}/${
      now.getMonth() + 1
    }/${now.getDay()} ${now.getHours()}:${now.getMinutes()}:${now.getSeconds()}`;
  };

  /**
   * 初期処理
   */
  const init = () => {
    setValue('id', designId || WallDesignDefaultValues.id);
    setValue('wall', params.wallId || WallDesignDefaultValues.wall);
    setValue('name', wallDesign.name || `新規作成（${createDate()}）`);
    setValue('source', wallDesign.source);
    setValue('html', wallDesign.html);
  };

  const handleLoad = useCallback(
    async ({ setIsLoading }: { setIsLoading: (bool: boolean) => void }) => {
      const editorText = getValues('source');
      if (editorText === '') {
        loadDesign(defaultDesignValue);
      } else {
        const editorDesignData = JSON.parse(editorText) as Design;
        Object.assign(defaultDesignValue, { body: editorDesignData });
        loadDesign(defaultDesignValue);
      }
      await editorReady().then(({ isReady }) => {
        setIsLoading(isReady);
      });
      await imageUploadFinish().then(({ message }) => {
        toast({
          ...successToast,
          title: message,
        });
      });
    },
    [
      imageUploadFinish,
      toast,
      defaultDesignValue,
      getValues,
      loadDesign,
      editorReady,
    ],
  );

  const saveEditor = useCallback(async () => {
    setLoading?.(true);
    const designName = getValues('name');
    await exportHtml()
      .then(({ design, html }) => {
        setValue('source', JSON.stringify(design.body));
        setValue('html', html);
        if (!designId) {
          // ウォールデザイン新規作成
          const submitData: WallDesignFormType = {
            tenant: tenantId,
            wallId: getValues('wall'),
            name: designName,
            source: getValues('source'),
            html: getValues('html'),
          };
          onCreateSubmit(submitData);
        } else {
          // ウォールデザイン修正
          const submitData: WallDesignFormType = {
            id: designId,
            tenant: tenantId,
            wallId: getValues('wall'),
            name: designName,
            source: getValues('source'),
            html: getValues('html'),
          };
          onEditSubmit(submitData);
        }
      })
      .catch((err) => {
        if (err instanceof Error) {
          toast.closeAll();
          toast({
            ...errorToast,
            title: 'HTMLエディタエラー',
            description: err.message,
          });
        }
      });
  }, [
    exportHtml,
    setValue,
    getValues,
    onCreateSubmit,
    onEditSubmit,
    setLoading,
    designId,
    toast,
    tenantId,
  ]);

  return {
    init,
    handleLoad,
    saveEditor,
  };
};
