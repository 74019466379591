import { Box, Text } from '@chakra-ui/react';
import { BackToFormGroupListButton } from 'admin/components/idPoolConsumer/formGroup/detail/backToFormGroupListButton';
import { DetailTitle } from 'admin/components/idPoolConsumer/formGroup/detail/detailTitle';
import { useIdPoolConsumerId } from 'admin/hooks/useIdPoolConsumerId';
import { FC, memo } from 'react';

type Props = {
  formId: string;
};

export const DetailHeader: FC<Props> = memo(({ formId }: Props) => {
  const idPoolConsumerId = useIdPoolConsumerId();

  return (
    <Box w="100%">
      <BackToFormGroupListButton path={`/admin/idPoolConsumer/${idPoolConsumerId}/formGroup`} />
      <Box mt="24px">
        <Text fontWeight="700" fontSize="18px">
          フォーム設問グループ詳細設定
        </Text>
      </Box>
      <Box mt="24px">
        <DetailTitle formId={formId}/>
      </Box>
    </Box>
  );
});
