import {
  VFC,
  memo,
  useCallback,
  useState,
  ChangeEvent,
  useEffect,
} from 'react';
import { useUserTenantId } from 'hooks/user/useUserTenantId';
import { useEnquetePublish } from 'hooks/enquete/useEnquetePublish';
import { Dialog } from 'components/common/atoms';

type Props = {
  eventInfo: ChangeEvent<HTMLInputElement> | undefined;
  isOpen: boolean;
  onClose: () => void;
  itemId: string;
};

export const ChangePublicModal: VFC<Props> = memo(
  ({ eventInfo, isOpen, onClose, itemId }: Props) => {
    const tenantId = useUserTenantId();
    const { onSubmit } = useEnquetePublish();
    const [checkText, setCheckText] = useState('');

    // 公開非公開設定
    const onSwitchChange = useCallback(
      (enqueteId: string) => {
        onSubmit({
          tenantId,
          enqueteId,
          isPublic: !eventInfo?.target.checked || false,
        });
        onClose();
      },
      [onSubmit, tenantId, eventInfo, onClose],
    );

    useEffect(() => {
      const checkVal = eventInfo?.target.checked;
      if (checkVal) {
        setCheckText(
          '公開期間中の場合には、ユーザーはアクセスしてもフォームが表示されなくなり、回答ができなくなります。',
        );
      } else {
        setCheckText(
          '回答可能になります。公開期間外の場合は回答できないのでご注意ください。',
        );
      }
    }, [eventInfo]);

    return (
      <Dialog
        openBtnElemType="text"
        title="公開設定"
        isOpen={isOpen}
        onOpen={() => null}
        onClose={onClose}
        openBtnChildNode={null}
        cancelBtnTitle="閉じる"
        submitBtnTitle="変更する"
        submitBtnHandelr={() => onSwitchChange(itemId)}
        submitBtnProps={{ colorScheme: 'red' }}
      >
        {checkText}
      </Dialog>
    );
  },
);
