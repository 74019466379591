import { customErrorMessage, toZod } from 'admin/config/zod';
import { AdminCorporationType } from 'admin/types/corporation';
import { z } from 'zod';

// zodErrorの日本語化対応
z.setErrorMap(customErrorMessage);

export const adminCorporationSchema = z.object<toZod<AdminCorporationType>>({
  id: z.string(),
  name: z.string(),
  userPoolGroupId: z.string(),
  datasetName: z.string().nullable(),
  answerExportTableName: z.string().nullable(),
  transactionMailFromAddress: z.string().nullable(),
  transactionMailReplyAddress: z.string().nullable(),
});
