import {
  Box,
  DrawerCloseButton,
  DrawerContent,
  DrawerHeader,
  DrawerOverlay,
  Text,
} from '@chakra-ui/react';
import { MailSettingForm } from 'components/transactionMail/MailSettingForm';
import { FC, memo } from 'react';

type MailSettingDrawerProps = {
  onClose: () => void;
};

export const MailSettingDrawer: FC<MailSettingDrawerProps> = memo(
  ({ onClose }: MailSettingDrawerProps) => (
    <>
      <DrawerOverlay />
      <DrawerContent>
        <DrawerHeader pb={0} borderBottomWidth="1px">
          <Box m={0} px={0} pb="24px">
            <Text>メール設定</Text>
          </Box>
        </DrawerHeader>
        <MailSettingForm onClose={onClose} />
        <DrawerCloseButton top="16px" right="24px" />
      </DrawerContent>
    </>
  ),
);
