import { CreateIdPoolConsumerFormType } from 'admin/types/userPool/form';
import { FC, memo } from 'react';
import { SubmitHandler } from 'react-hook-form';
import { CreateFormComponent } from './CreateFormComponent';

type Props = {
  formId: string;
  onClose: () => void;
  onConfirmed: () => void;
};

export const IdPoolConsumerCreateForm: FC<Props> = memo(
  ({ formId, onClose, onConfirmed }: Props) => {
    const onSubmit: SubmitHandler<CreateIdPoolConsumerFormType> = () => {
      onConfirmed();
    };

    return <CreateFormComponent formId={formId} onSubmit={onSubmit} onClose={onClose} />;
  },
);