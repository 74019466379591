import { getMembers } from 'admin/api/member/getMembers';
import { CustomError } from 'admin/error/CustomError';
import { queryMemberKey } from 'admin/hooks/member/queryMemberKey';
import { convertToPage } from 'admin/schema/page';
import { MembersType } from 'admin/types/member';
import { UserPool } from 'admin/types/userPool';
import { DataWithPage, Page } from 'api/common/types';
import { logger } from 'api/logger';
import { useCustomToast } from 'hooks/useCustomToast';
import { useQuery } from 'react-query';
import { errorToast } from 'utils/toast';

export const useMembers = (
  userPoolId: UserPool['id'],
  page: Page['currentPage'],
  perPage: Page['perPage'],
  keyword?: string,
) => {
  const toast = useCustomToast();
  const { data, refetch } = useQuery({
    queryKey: queryMemberKey.getMembers(userPoolId, page, perPage, keyword),
    queryFn: () =>
      getMembers({
        userPoolId,
        keyword,
        options: {
          searchParams: {
            page,
            perPage,
          },
        },
      }),
    onError: async (error) => {
      if (error instanceof CustomError) {
        await logger({
          loglevel: error.logLevel,
          data: JSON.stringify(error.cause),
          message: error.message,
        });
      }
      toast({
        ...errorToast,
        duration: 4000,
        title: '会員管理一覧情報の取得に失敗しました',
        position: 'bottom',
        variant: 'solid',
      });
    },
  });

  return {
    data: data?.results || [],
    page: convertToPage<DataWithPage<MembersType>>(data),
    refetch,
  };
};
