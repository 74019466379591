import { request } from 'admin/api/Request';
import { API_MESSAGES } from 'admin/define/apiMessages';
import { CustomError } from 'admin/error/CustomError';
import { DeleteTenantUsersFormType } from 'admin/types/tenantUsers/form';

export const deleteTenantUsers = async (
  data: Omit<DeleteTenantUsersFormType, 'word'>,
  corporationId: string,
): Promise<boolean> => {
  const path = `user/corporation/${corporationId}/tenants/user/delete/`;

  const response = await request<boolean>({
    path,
    body: data,
    method: 'put',
  });

  if (response.hasError) {
    if (response.status === 404) {
      throw new CustomError(
        API_MESSAGES.TENANT_USERS.DELETE_TENANT_USER.FAILED,
        {
          path,
          status: response.status,
        },
      );
    } else {
      throw new CustomError(
        API_MESSAGES.TENANT_USERS.DELETE_TENANT_USER.ERROR,
        {
          path,
          status: response.status,
          logLevel: response.status === 400 ? 'warning' : 'error',
        },
      );
    }
  }

  return !response.hasError;
};
