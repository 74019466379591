import { useQuery } from 'react-query';
import {
  EnqueteFormType,
  Enquete,
  EnquetDefaultValue,
} from 'api/enquete/types';
import { getEnqueteId } from 'api/enquete/getEnqueteId';
import { useCustomToast } from 'hooks/useCustomToast';
import { useUserTenantId } from 'hooks/user/useUserTenantId';
import { randStr } from 'utils/str';
import { queryEnqueteKey } from './queryEnqueteKey';

const defaultValues: Enquete = EnquetDefaultValue;

/**
 * フォーム詳細取得 hooks
 */
export const useEnqueteDetail = ({
  enqueteId,
  enabled = true,
}: {
  enqueteId: string;
  enabled?: boolean;
}): Enquete => {
  const toast = useCustomToast();
  const tenantId = useUserTenantId();

  const response = useQuery(
    queryEnqueteKey.getEnqueteId({ tenantId, enqueteId }),
    () => getEnqueteId(tenantId, enqueteId),
    {
      enabled,
      cacheTime: 0,
      onError: (err: unknown) => {
        if (err instanceof Error) {
          toast.closeAll();
          toast({
            title: err.message,
            status: 'error',
          });
        }
      },
    },
  );
  if (!enabled) return null as unknown as Enquete;

  const enqueteFormList = response.data?.enqueteContents as EnqueteFormType[];
  const enqueteContents: EnqueteFormType[] = [];
  enqueteFormList.forEach((item) => {
    // dndIdのユニーク値生成
    const uniqueId = () => {
      // dndIdのユニーク値生成のための再帰処理
      const uid = () => {
        const str = randStr();
        if (enqueteContents.some((form) => form.dndId === str)) uid();

        return str;
      };

      return uid();
    };

    const data: EnqueteFormType = {
      ...item,
      dndId: uniqueId(),
    };
    enqueteContents.push(data);
  });

  const receiveData = {
    ...defaultValues,
    ...response.data,
    enqueteContents,
  };

  return receiveData;
};
