import { Box, Flex, Text } from '@chakra-ui/react';
import { NavTile } from 'admin/components/admin/NavTile';
import { useMenuPermission } from 'admin/hooks/useMenuPermission';
import { useSetPageTitle } from 'hooks/useSetPageTitle';
import { FC } from 'react';
import { BiSolidCheckShield } from 'react-icons/bi';
import { IoMdUnlock } from 'react-icons/io';
import {
  MdOutlineAccountTree,
  MdCorporateFare,
  MdOutlineGroup,
  MdSettingsApplications,
} from 'react-icons/md';
import { PiUserCircleFill } from 'react-icons/pi';

export const Admin: FC = () => {
  useSetPageTitle('組織管理');
  const {
    hasAllowedIdPoolConsumer,
    hasAllowedSaml,
    hasAllowedService,
    hasAllowedMember,
    hasAllowedAuthHub,
  } = useMenuPermission();

  return (
    <>
      <Box
        mt="12px"
        ml="12px"
        display="grid"
        gridTemplateColumns=" 1fr 1fr"
        gridGap="24px"
        maxW="780px"
      >
        <NavTile to="/admin/users">
          <Flex alignItems="center" gap="16px">
            <MdCorporateFare fontSize="48px" />
            <Text ml="16px" fontSize="20px">
              組織アカウント管理
            </Text>
          </Flex>
        </NavTile>
        <NavTile to="/admin/tenantUsers">
          <Flex alignItems="center" gap="16px">
            <PiUserCircleFill fontSize="48px" />
            <Text ml="16px" fontSize="20px">
              テナント アカウント管理
            </Text>
          </Flex>
        </NavTile>
        {hasAllowedIdPoolConsumer && (
          <NavTile to="/admin/idPoolConsumer">
            <Flex alignItems="center" gap="16px">
              <BiSolidCheckShield fontSize="48px" />
              <Text ml="16px" fontSize="20px">
                IDシステム設定管理
              </Text>
            </Flex>
          </NavTile>
        )}
        {hasAllowedSaml && (
          <NavTile to="/admin/saml/">
            <Flex alignItems="center" gap="16px">
              <IoMdUnlock fontSize="48px" />
              <Text ml="16px" fontSize="20px">
                SAML設定
              </Text>
            </Flex>
          </NavTile>
        )}
        {hasAllowedService && (
          <NavTile to="/admin/service/">
            <Flex alignItems="center" gap="16px">
              <MdSettingsApplications fontSize="48px" />
              <Text ml="16px" fontSize="20px">
                テナント設定管理
              </Text>
            </Flex>
          </NavTile>
        )}
        {hasAllowedMember && (
          <NavTile to="/admin/member/">
            <Flex alignItems="center" gap="16px">
              <MdOutlineGroup fontSize="48px" />
              <Text ml="16px" fontSize="20px">
                会員管理
              </Text>
            </Flex>
          </NavTile>
        )}
        {hasAllowedAuthHub && (
          <NavTile to="/admin/authHub/">
            <Flex alignItems="center" gap="16px">
              <MdOutlineAccountTree fontSize="48px" />
              <Text ml="16px" fontSize="20px">
                Auth Hub設定
              </Text>
            </Flex>
          </NavTile>
        )}
      </Box>
    </>
  );
};
