import { Box } from '@chakra-ui/react';
import { useApiCustomerList } from 'api/paywall/useApi';
import { Td, Tr } from 'components/paywall/features/Table';
import { useTableNavigation } from 'components/paywall/features/Table/TableNavigation/hooks/useTableNavigation';
import { ApiCustomerList, CustomerListResult, CustomerListResultOrder } from 'components/paywall/pages/CustomerList/typed';
import { FC, memo, useEffect, useState } from 'react';
import { NavLink } from 'react-router-dom';

export const CustomerRows: FC = memo(() => {
  const { data, isLoading, isFetching } = useApiCustomerList();
  const [results, setResults] = useState<CustomerListResult[]>([]);
  const { setCount } = useTableNavigation();

  useEffect(() => {
    if (isLoading || isFetching) return;
    if (!data) return;
    const res = data as unknown as ApiCustomerList;
    setResults(res.data.results);
    setCount(res.data.count);
  }, [data, isFetching, isLoading, setCount, setResults]);

  return (
    <>
      {results.map((row: CustomerListResult) => (
        <Tr key={row.customerUid}>
          <Td fontWeight="700" columnFix >
            <NavLink
              to={`/paywall/${row.customerUid}/customerDetail`}
              style={{textDecoration: 'underLine'}}
            >
              {row.customerUid}
            </NavLink>
          </Td>
          <Td>{row.email}</Td>
          <Td>
            <Box style={{ display: 'grid', gap: 4 }}>
              {row.orders &&
                row.orders.length > 0 &&
                row.orders.map((order: CustomerListResultOrder) => (
                  <Box key={order.id}>
                    {order.courseName}（ID:{order.courseId}）
                  </Box>
                ))}
            </Box>
          </Td>
        </Tr>
      ))}
    </>
  )
});
