import { toZod } from 'admin/config/zod';
import {
  TransactionMailCategoryMap,
  TransactionMailCommonType,
  TransactionMailFormType,
  TransactionMailType,
  TransactionMailTypeStatusType,
} from 'admin/types/userPool/transactionMail';
import { TransactionMailCommonOutputType } from 'admin/types/userPool/transactionMail/form';
import { z } from 'zod';

export const TransactionMailCommonSchema = z.object<
  toZod<TransactionMailCommonType>
>({
  id: z.string().optional(),
  fromAddress: z.string(),
  replyAddress: z.string().nullable(),
});

export const TransactionMailCommonSubmitSchema = z.object<
  toZod<TransactionMailCommonOutputType>
>({
  id: z.string(),
  fromAddress: z.string().min(1, '差出人メールアドレスは必須です'),
  replyAddress: z.string().nullable(),
});

export const TransactionMailCategorySchema = z.enum(
  Object.keys(TransactionMailCategoryMap) as [
    keyof typeof TransactionMailCategoryMap,
  ],
);

export const TransactionMailTypeStatusSchema = z.object<
  toZod<TransactionMailTypeStatusType>
>({
  type: TransactionMailCategorySchema,
  isActivated: z.boolean(),
});

export const TransactionMailTypeStatusesSchema = z.array(
  TransactionMailTypeStatusSchema,
);

export const TransactionMailSchema = z.object<toZod<TransactionMailType>>({
  id: z.string(),
  subject: z.string(),
  status: z.boolean(),
  type: TransactionMailCategorySchema,
  settingTitle: z.string(),
  editorHtml: z.string().nullable(),
  editorText: z.string().nullable(),
  corporation: z.string(),
  tenant: z.string().nullable(),
});

export const TransactionMailsSchema = z.array(TransactionMailSchema);

export const TransactionmailFormSchema = z.object<
  toZod<Omit<TransactionMailFormType, 'id' | 'transactionMailId'>>
>({
  type: TransactionMailCategorySchema,
  settingTitle: z.string().min(1, '設定名は必須です。'),
  subject: z.string().min(1, 'メールタイトルは必須です。'),
  status: z.boolean(),
  bodyHtml: z.string().min(1, 'HTMLメールは必須です。'),
  bodyText: z.string().min(1, 'テキストメールは必須です。'),
  editorHtml: z.string().min(1, 'HTMLメールは必須です。'),
  editorText: z.string().min(1, 'テキストメールは必須です。'),
});

export const TransactionMailCreateSchema = TransactionmailFormSchema;

export const TransactionMailEditSchema = z.object<
  toZod<TransactionMailFormType>
>({
  id: z.string(),
  type: TransactionMailCategorySchema,
  settingTitle: z.string(),
  subject: z.string(),
  status: z.boolean(),
  bodyHtml: z.string(),
  bodyText: z.string(),
  editorHtml: z.string(),
  editorText: z.string(),
});

export const TransactionMailTestMailSchema = z.object<
  toZod<
    Pick<
      TransactionMailFormType,
      'subject' | 'bodyHtml' | 'bodyText' | 'type'
    > & { toAddress: string }
  >
>({
  subject: z.string(),
  bodyHtml: z.string(),
  bodyText: z.string(),
  type: TransactionMailCategorySchema,
  toAddress: z.string(),
});
