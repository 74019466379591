import { Input, Td } from '@chakra-ui/react';
import { FC } from 'react';
import { FieldValues, Path, useFormContext } from 'react-hook-form';

type Props<T> = {
  baseName: Path<T>
  width: number;
  colspan: number;
  index: number;
}

export const QuestionTitleElement = <T extends FieldValues>({ baseName, width, colspan, index }: Props<T>): ReturnType<FC> => {
  const { getValues, register } = useFormContext<T>();

  return (
    <>
      <Td w={width}>設問タイトル</Td>
      <Td colSpan={colspan}>
        <Input
          {...register(`${baseName}.${index}.questionTitle` as Path<T>)}
          backgroundColor="#f9f9f9"
          borderColor="#ccc"
          isDisabled={getValues('uneditableState' as Path<T>)}
        />
      </Td>
    </>
  );
};
