import { VFC, useEffect } from 'react';
import { useFormContext } from 'react-hook-form';
import { Skin } from 'api/enquete/types';
import { useEnqueteSkinDetail } from 'hooks/enquete/useEnqueteSkinDetail';
import { EnqueteSkinFormInner } from 'components/enquete/organisms/EnqueteSkinFormInner';

type Props = {
  skinId: string;
};

export const EnqueteSkinEditForm: VFC<Props> = ({ skinId }: Props) => {
  const skinDetail = useEnqueteSkinDetail({ skinId });
  const { setValue } = useFormContext<Skin>();

  useEffect(() => {
    setValue('id', skinDetail.id);
    setValue('tenant', skinDetail.tenant);
    setValue('name', skinDetail.name);
    setValue('titleBgColor', skinDetail.titleBgColor);
    setValue('titleTextColor', skinDetail.titleTextColor);
    setValue('ruledLineColor', skinDetail.ruledLineColor);
    setValue('submitButtonBgColor', skinDetail.submitButtonBgColor);
    setValue('submitButtonTextColor', skinDetail.submitButtonTextColor);
    setValue('labelBgColor', skinDetail.labelBgColor);
    setValue('labelTextColor', skinDetail.labelTextColor);
    setValue('selectButtonBgColor', skinDetail.selectButtonBgColor);
    setValue('selectButtonTextColor', skinDetail.selectButtonTextColor);
    setValue('radioButtonSelectedColor', skinDetail.radioButtonSelectedColor);
    setValue('checkboxSelectedColor', skinDetail.checkboxSelectedColor);
    setValue('finishedButtonBgColor', skinDetail.finishedButtonBgColor);
    setValue('finishedButtonTextColor', skinDetail.finishedButtonTextColor);
    setValue('logoUrl', skinDetail.logoUrl);
    setValue('logoPosition', skinDetail.logoPosition);
    setValue('errorTextColor', skinDetail.errorTextColor);
  }, [setValue, skinDetail]);

  return <EnqueteSkinFormInner />;
};
