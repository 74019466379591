import { API_MESSAGES } from 'admin/define/apiMessages';
import { CustomError } from 'admin/error/CustomError';
import { convertToPage, pageSchema } from 'admin/schema/page';
import { serviceMembersSchema } from 'admin/schema/service';
import { ServiceMembersType, ServiceType } from 'admin/types/service';
import { getDummySuccessResponse } from 'admin/utils/getDummyData';
import { DataWithPage, Page } from 'api/common/types';
import { Options } from 'ky';
import { ZodError } from 'zod';

const dummyPage: Page = {
  count: 123,
  currentPage: 1,
  countFrom: 11,
  countTo: 20,
  perPage: 50,
  pageCount: 30,
  next: 'http://api.example.org/accounts/?page=4',
  previous: 'http://api.example.org/accounts/?page=2',
};

const dummyData: ServiceMembersType = [
  {
    id: 'child_fa6om',
    serviceId: 'dummy-service-id-1',
    email: 'aaa@bbb.com',
    tel: '09011111111',
    registeredDate: '2023年11月20日08:00',
    lastLoginDate: '2024年01年08日10:32',
    status: 1,
  },
  {
    id: 'child_fa6om',
    serviceId: 'dummy-service-id-2',
    email: 'bbbb@ccc.com',
    tel: '03000000000',
    registeredDate: '2023年11月20日23:05',
    lastLoginDate: '2024年01年08日21:08',
    status: 0,
  },
  {
    id: 'child_fa6om',
    serviceId: 'dummy-service-id-3',
    email: '222@aaa.jp',
    tel: '080222222222',
    registeredDate: '2021年08月23日08:00',
    lastLoginDate: '2022年11年02日08:00',
    status: -2,
  },
];

export const getServiceMembers = async ({
  serviceId,
  // TODO: 機能実装時にeslintコメントを削除する
  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  options,
}: {
  serviceId: ServiceType['id'];
  keyword?: string;
  options?: Options;
}): Promise<DataWithPage<ServiceMembersType>> => {
  // TODO: APIの繋ぎ込みの際に実装する
  // const response = await request<getIdPoolConsumersType>({
  //   path,
  //   method: 'get',
  //   options,
  // });

  await new Promise<string>((resolve) => {
    setTimeout(() => {
      resolve('Timer done');
    }, 1000);
  });

  const filterData: ServiceMembersType = dummyData.filter(
    (x) => x.serviceId === serviceId,
  );

  const response = await getDummySuccessResponse<
    DataWithPage<ServiceMembersType>
  >({
    results: filterData,
    ...dummyPage,
  });

  if (response.hasError) {
    if (response.status === 404) {
      throw new CustomError(API_MESSAGES.MEMBER.GET_MEMBER_SERVICES.NOT_FOUND, {
        path: 'dummy',
        status: response.status,
      });
    } else {
      throw new CustomError(API_MESSAGES.MEMBER.GET_MEMBER_SERVICES.ERROR, {
        path: 'dummy',
        status: response.status,
      });
    }
  }
  try {
    serviceMembersSchema.parse(response.data.results);
    pageSchema.parse(
      convertToPage<DataWithPage<ServiceMembersType>>(response.data),
    );
  } catch (error) {
    if (error instanceof ZodError) {
      throw new CustomError(error.message, {
        path: 'dummy',
        status: response.status,
        logLevel: 'error',
      });
    }
  }

  return response.data;
};
