import { Text } from '@chakra-ui/react';
import { StatusBadge } from 'admin/components/member/statusBadge';
import { Td, Tr } from 'admin/components/ui/table';
import { Colors } from 'admin/define/colors';
import { MemberType } from 'admin/types/member';
import {
  MEMBER_STATUS_TYPE_LIST,
  MEMBER_STATUS_TYPE_WITHDRAWAL,
  MEMBER_STATUS_TYPE_WITHDRAWAL_COMPULSION,
} from 'define';
import { useFormatDate } from 'hooks/useFormatDate';
import { FC, memo, useCallback, useMemo } from 'react';
import { NavigateFunction } from 'react-router-dom';

type Props = {
  member: MemberType;
  navigate: NavigateFunction;
};

export const MemberListTableBodyComponent: FC<Props> = memo(
  ({ member, navigate }: Props) => {
    const { dateTimeFormat } = useFormatDate();
    const isWithDrawal = useMemo<boolean>(
      () =>
        member.status === MEMBER_STATUS_TYPE_WITHDRAWAL ||
        member.status === MEMBER_STATUS_TYPE_WITHDRAWAL_COMPULSION,
      [member.status],
    );

    const textColor = useMemo<string>(() => {
      if (isWithDrawal) {
        return Colors.GRAY_15;
      }

      return 'inherit';
    }, [isWithDrawal]);

    const displayMaskingData = useCallback(
      (val: string): string => {
        if (isWithDrawal) return '**********';

        return val;
      },
      [isWithDrawal],
    );

    return (
      <Tr>
        <Td fontSize="14px" p={4}>
          {!isWithDrawal ? (
            <Text
              color={Colors.BLUE_20}
              fontWeight="bold"
              textDecoration="underline"
              _hover={{ cursor: 'pointer', textDecoration: 'underline' }}
              onClick={() =>
                navigate(`/admin/member/${member.userPool}/${member.uid}`)
              }
            >
              {member.uid}
            </Text>
          ) : (
            <Text color={textColor}>{member.uid}</Text>
          )}
        </Td>
        <Td p={4} fontSize="14px" color={textColor}>
          {member.email || '-'}
        </Td>
        <Td p={4} fontSize="14px" color={textColor}>
          {member.tel || '-'}
        </Td>
        <Td p={4} fontSize="14px">
          {member.userPoolChildren.map((service, idx) => (
            <Text
              key={`member-${String(member.uid)}-service-${String(idx)}`}
              color={textColor}
              // TODO: テナントのAPI待ちの為、一時的にコメントアウト
              // color={Colors.BLUE_20}
              // _hover={{ cursor: 'pointer', textDecoration: 'underline' }}
              // onClick={() => navigate(`/admin/member/service/${service.id}`)}
            >
              {displayMaskingData(service.name)}
            </Text>
          ))}
        </Td>
        <Td p={4} fontSize="14px" color={textColor}>
          {displayMaskingData(dateTimeFormat(member.createdAt))}
        </Td>
        <Td p={4} fontSize="14px" color={textColor}>
          {displayMaskingData(
            member.lastLoginAt ? dateTimeFormat(member.lastLoginAt) : '-',
          )}
        </Td>
        <Td p={4}>
          <StatusBadge
            status={member.status}
            label={MEMBER_STATUS_TYPE_LIST.get(member.status)}
          />
        </Td>
      </Tr>
    );
  },
);
