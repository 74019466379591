import {
  AlertDialogCloseButton,
  AlertDialogContent,
  AlertDialogHeader,
  AlertDialogOverlay,
  Text,
} from '@chakra-ui/react';
import { TransactionMailStatusChangeForm } from 'admin/components/idPoolConsumer/transactionMail/statusChangeForm';
import { TransactionMailType } from 'admin/types/userPool/transactionMail';
import { FC, memo } from 'react';

type TransactionMailStatusChangeDialogProps = {
  transactionMail: TransactionMailType;
  onClose: () => void;
};

// 送信内容設定ページ用Dialog
export const TransactionMailStatusChangeDialog: FC<TransactionMailStatusChangeDialogProps> =
  memo((props) => (
    <AlertDialogOverlay>
      <AlertDialogContent>
        <AlertDialogHeader p={6}>
          <Text fontSize="18px">使用設定変更の確認</Text>
        </AlertDialogHeader>
        <TransactionMailStatusChangeForm {...props} />
        <AlertDialogCloseButton top="24px" right="24px" />
      </AlertDialogContent>
    </AlertDialogOverlay>
  ));
