import { Box } from '@chakra-ui/react';
import { withSuspenseAndErrorBoundary } from 'admin/components/Error/WithErrorBoundary';
import { MemberLogTable } from 'admin/components/member/detail/memberLogs/memberLogTable';
import { Pagination } from 'admin/components/ui/pagination';
import { usePaginationQuery } from 'admin/hooks/pagination/usePaginationQuery';
import { Page } from 'api/common/types';
import { ErrorContents } from 'components/common/atoms';
import { FC, memo, useMemo, useState } from 'react';

const memberLogsWithPagination: FC = memo(() => {
  const [page, setPage] = useState<Page>({
    count: 0,
    currentPage: 1,
    countFrom: 0,
    countTo: 0,
    perPage: 50,
    pageCount: 0,
    next: '',
    previous: '',
  });
  const { query } = usePaginationQuery({ page });
  const currentPage = useMemo(() => query.currentPage, [query.currentPage]);
  const perPage = useMemo(() => query.currentPerPage, [query.currentPerPage]);

  return (
    <Box>
      <MemberLogTable
        currentPage={currentPage}
        perPage={perPage}
        hasTitle={false}
        setPage={setPage}
      />
      <Pagination page={page} />
    </Box>
  );
});

export const MemberLogsWithPagination = withSuspenseAndErrorBoundary(
  memberLogsWithPagination,
  {
    ErrorComponent: <ErrorContents name="利用履歴一覧" />,
  },
);

type Props = {
  hasPagination?: boolean;
};

const memberLogs: FC<Props> = memo(({ hasPagination }: Props) => {
  if (hasPagination) {
    return <MemberLogsWithPagination />;
  }

  return <MemberLogTable />;
});

memberLogs.defaultProps = {
  hasPagination: true,
};

export const MemberLogs = withSuspenseAndErrorBoundary(memberLogs, {
  ErrorComponent: <ErrorContents name="利用履歴情報" />,
});
