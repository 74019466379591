/* eslint-disable @typescript-eslint/no-unused-vars */
import {
  AlertDialog,
  AlertDialogBody,
  AlertDialogCloseButton,
  AlertDialogContent,
  AlertDialogFooter,
  AlertDialogHeader,
  AlertDialogOverlay,
  Box,
  Button,
  Flex,
  Text,
} from '@chakra-ui/react';
import { Colors, ColorSet } from 'components/paywall/config';
import { memo, useRef } from 'react';
import { AiFillInfoCircle } from 'react-icons/ai';

interface Props {
  isOpen: boolean;
  onClose: () => void;
  icon?: React.ReactElement;
  showIcon?: boolean;
  title?: string;
  message: React.ReactChild | string[];
  children?: React.ReactNode;
  disabled?: boolean;
  okBtnCaption: string;
  cancelBtnCaption: string;
  cancelBtnColor: 'gray' | 'white';
  okBtnColor: 'blue' | 'red';
  isCentered?: boolean;
  size?: string;
  onCancel?: () => void;
  onOk?: () => void;
}
/**
 * @see 汎用ダイアログ
 * @param icon タイトル横のアイコン
 * @param mode 値によってデフォルトが変化する
 * @param showIcon アイコンを表示させるかどうか
 * @param title ダイアログのタイトル
 * @param message ダイアログの本文
 * @param children ダイアログの中身
 * @param disabled ボタン実行の可否
 * @param okBtnCaption 実行ボタンの名前
 * @param cancelBtnCaption 実行ボタンの名前
 * @param isCentered ダイアログのセンタリングの有無
 * @param size ダイアログの幅サイズ
 * @param isOpen ダイアログの開閉状態
 * @param onClose ダイアログを閉じるイベントハンドラ
 * @param onOk OKのボタンを実行するイベントハンドラ
 * @param onCancel cancelのボタンを実行するイベントハンドラ
 */
export const CommonDialog: React.FC<Props> = memo(
  ({
    icon,
    title,
    children,
    disabled = false,
    okBtnCaption = '保存',
    cancelBtnCaption = 'キャンセル',
    okBtnColor = 'blue',
    cancelBtnColor = 'gray',
    isOpen = false,
    showIcon = false,
    isCentered = true,
    size = 'md',
    onCancel,
    onOk,
    onClose,
    message,
  }) => {
    const cancelRef = useRef(null);

    /**
     * キャンセルボタンを押した後にコールバックを行ってから閉じる
     */
    const handleClickCancel = () => {
      onCancel?.();
      onClose();
    };
    /**
     * OKを押した後にコールバックの処理を行ってから閉じる
     */
    const handleClickOk = () => {
      onOk?.();
      onClose();
    };

    return (
      <>
        <AlertDialog
          isOpen={isOpen}
          leastDestructiveRef={cancelRef}
          onClose={onClose}
          isCentered={isCentered}
          size={size}
          closeOnOverlayClick={false}
          closeOnEsc={false}
        >
          <AlertDialogOverlay>
            <AlertDialogContent>
              <AlertDialogHeader fontSize="lg" fontWeight="bold" pt="24px">
                <Flex alignItems="center" style={{ color: '#444', gap: 5 }}>
                  {showIcon && icon ? (
                    <Box>{icon}</Box>
                  ) : (
                    <Box style={{ height: 24 }}>
                      {title && showIcon && (
                        <AiFillInfoCircle style={{ width: 24, height: 24 }} />
                      )}
                    </Box>
                  )}
                  {title}
                  <Box>
                    <AlertDialogCloseButton />
                  </Box>
                </Flex>
              </AlertDialogHeader>
              <AlertDialogBody>
                {Array.isArray(message)
                  ? message.map((msg, index) => (
                      <Text as="p" mb={2} key={index.toString()}>
                        {msg}
                      </Text>
                    ))
                  : message || children}
              </AlertDialogBody>
              <AlertDialogFooter pb="24px">
                {onOk && (
                  <>
                    <Button
                      mode="cancel"
                      onClick={handleClickCancel}
                      mr={3}
                      bgColor={Colors.WHITE}
                      border="1px solid #CFD6DD"
                      p="8px 16px"
                      w="100px"
                    >
                      {cancelBtnCaption}
                    </Button>
                    <Button
                      disabled={disabled}
                      colorScheme={okBtnColor}
                      onClick={handleClickOk}
                      {...ColorSet.BLUE}
                      p="8px 16px"
                      w="100px"
                    >
                      {okBtnCaption}
                    </Button>
                  </>
                )}
              </AlertDialogFooter>
            </AlertDialogContent>
          </AlertDialogOverlay>
        </AlertDialog>
      </>
    );
  },
);
