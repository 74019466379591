import { AttributeFieldUiTypes } from 'admin/types/userPool/attribute';

export const UI_TYPE_TEXT = 'text';
export const UI_TYPE_TEXTAREA = 'textarea';
export const UI_TYPE_RADIO = 'radio';
export const UI_TYPE_CHECKBOX = 'checkbox';
export const UI_TYPE_SELECTBOX = 'selectbox';
export const UI_TYPE_DATE_CALENDAR = 'date_calendar';
export const UI_TYPE_DATE_SPLIT = 'date_split';
export const UI_TYPE_EMAIL = 'email';
export const UI_TYPE_TEL = 'tel';
export const UI_TYPE_ZIP_CODE_JA = 'zip_code_ja';

// UIタイプ選択肢データ
export const FIELD_UI_TYPE_LIST: AttributeFieldUiTypes[] = [
  { value: UI_TYPE_TEXT, name: 'テキスト1行' },
  { value: UI_TYPE_TEXTAREA, name: 'テキスト複数行' },
  { value: UI_TYPE_RADIO, name: 'ラジオボタン' },
  { value: UI_TYPE_CHECKBOX, name: 'チェックボックス' },
  { value: UI_TYPE_SELECTBOX, name: 'セレクトボックス' },
  { value: UI_TYPE_DATE_CALENDAR, name: '日付(カレンダー)' },
  { value: UI_TYPE_DATE_SPLIT, name: '日付(年月日別)' },
  { value: UI_TYPE_EMAIL, name: 'メールアドレス' },
  { value: UI_TYPE_TEL, name: '電話番号' },
  { value: UI_TYPE_ZIP_CODE_JA, name: '郵便番号(日本)' },
  // { value: 'zip_code_foreign', name: '郵便番号(海外)' }, // 郵便番号(海外)のバリデーションの検討が済んでいないため対応を見送り
];

export const DISPLAY_TYPE_VERTICAL = 'vertical' as const;
export const DISPLAY_TYPE_HORIZONTAL2 = 'horizontal2' as const;
export const DISPLAY_TYPE_ADDRESS_JA = 'address_ja' as const;

export const DISPLAY_TYPE_LIST = [
  {
    value: DISPLAY_TYPE_VERTICAL,
    name: '縦並び',
  },
  {
    value: DISPLAY_TYPE_HORIZONTAL2,
    name: '横並び (2段組)',
  },
  {
    value: DISPLAY_TYPE_ADDRESS_JA,
    name: '日本の住所セット',
  },
] as const;

export const JSON_PATH_VALUE_TYPE = {
  codeValue: '1',
  displayName: '2',
} as const;
