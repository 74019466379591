import { Box, Link } from '@chakra-ui/react';
import { FC, memo, useMemo } from 'react';

export const LoginEnvironmentInfo: FC = memo(() => {
  const deployMode = useMemo(() => process.env.REACT_APP_DEPLOY_MODE, []);
  const borderColor = useMemo(() => {
    if(deployMode === 'dev') return '#ffbe00';
    if(deployMode === 'stg') return '#f99a9a';

    return '';
  }, [deployMode]);
  const bgColor = useMemo(() => {
    if(deployMode === 'dev') return '#fff2cc';
    if(deployMode === 'stg') return '#f4cccc';

    return '';
  }, [deployMode]);

  if (deployMode === 'prd') return <></>

  return (
    <Box
      width="100%"
      padding="2.3px"
      border="2px solid"
      borderColor={borderColor}
      backgroundColor={bgColor}
      fontSize="10px"
    >
      デモ・テスト用のステージング環境へのログインページです。
      <br />
      本番用の商用環境へは
      <Link
        href="https://uniikey-cloud.com/login"
        textDecoration="underline"
        mx="2px"
      >
        こちら
      </Link>
      からログインしてください。
      <br />
    </Box>
  )
});
