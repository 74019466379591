import {
  Box,
  Button, FormControl, FormErrorMessage, Heading,
  HStack, ListItem, ModalBody,
  ModalFooter,
  Stack,
  Text,
  UnorderedList
} from '@chakra-ui/react';
import { RhfInput } from 'admin/components/form/RhfInput';
import { AlertInfoBar } from 'admin/components/ui/alertInfo';
import { Colors, ColorSet } from 'admin/define/colors';
import { SamlSettingAgreeType, SamlSettingFormType } from 'admin/types/saml';
import { FC, memo } from 'react';
import { useFormContext, useFormState } from 'react-hook-form';

type Props = {
  formId: string;
  samlSetting: SamlSettingFormType;
  isLoading: boolean;
  onClose: () => void;
  onSubmit: () => void,
};

export const AgreeModalComponent: FC<Props> = memo(({ formId, samlSetting, isLoading, onClose, onSubmit }: Props) => {
  const { handleSubmit } = useFormContext<SamlSettingAgreeType>();
  const { errors, isValid } = useFormState<SamlSettingAgreeType>();

  return (
    <>
      <ModalBody maxHeight="500px" overflowY="auto">
        <form onSubmit={handleSubmit(onSubmit)} id={formId}>
          <Stack spacing={6} mb="8px">
            <Box>
              <Heading as="h3" size="sm" mb="4px">
                対象ドメイン
              </Heading>
              <Box
                p="8px 16px"
                borderBottomWidth="1px"
                borderBottomColor={Colors.GRAY_30}
              >
                {samlSetting.domains.map((item) => (
                  <Text key={`domain-name${item.url}`}>{item.url ?? '-'}</Text>
                ))}
              </Box>
            </Box>
            <Box w="100%">
              <Heading as="h3" size="sm" mb="4px">
                ログインURL
              </Heading>
              <Box
                p="8px 16px"
                borderBottomWidth="1px"
                borderBottomColor={Colors.GRAY_30}
              >
                <Text>{samlSetting.singleSignOnUrl ?? '-'}</Text>
              </Box>
            </Box>
            <Box w="100%">
              <Heading as="h3" size="sm" mb="4px">
                識別子URL
              </Heading>
              <Box
                p="8px 16px"
                borderBottomWidth="1px"
                borderBottomColor={Colors.GRAY_30}
              >
                <Text>{samlSetting.entityId ?? '-'}</Text>
              </Box>
            </Box>
            <Box w="100%">
              <Heading as="h3" size="sm" mb="4px">
                X.509 証明書
              </Heading>
              <Box
                p="8px 16px"
                borderBottomWidth="1px"
                borderBottomColor={Colors.GRAY_30}
              >
                <Text fontSize="12px">{samlSetting.responseCertificate ?? '-'}</Text>
              </Box>
            </Box>
          </Stack>
          <AlertInfoBar type="error" iconType="error">
            <UnorderedList>
              <ListItem>一度設定したドメインは変更できません。</ListItem>
              <ListItem>また、設定を行うと対象ドメインを持つユーザーは、SAMLでのログインとなり、通常のメールアドレスとパスワードでのログインができなくなります。</ListItem>
            </UnorderedList>
          </AlertInfoBar>
          <Box mt="32px">
            <Text mb={1} fontSize="sm" fontWeight="bold">
              実行する場合は「saml」と入力後に「SAML設定ボタン」を押下してください。
            </Text>
            <FormControl isInvalid={Boolean(errors.word?.message)}>
              <RhfInput<SamlSettingAgreeType> name="word" type="text" />
              <FormErrorMessage>{errors.word?.message}</FormErrorMessage>
            </FormControl>
          </Box>
        </form>
      </ModalBody>
      <ModalFooter>
        <HStack spacing={4}>
          <Button variant="solid" {...ColorSet.Default} onClick={onClose}>
            戻る
          </Button>
          <Button
            colorScheme="primary"
            mr={3}
            disabled={!isValid || isLoading}
            onClick={() => onSubmit()}
          >
            SAML設定をする
          </Button>
        </HStack>
      </ModalFooter>
    </>
  )
})
