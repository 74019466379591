import { FC, memo, ReactElement } from 'react';
import { Box } from '@chakra-ui/react';

interface CodeDisplayProps {
  children: ReactElement;
}

const codeStyle = {
  backgroundColor: '#f2f2f2',
  borderWidth: '1px',
  borderColor: '#dcdcdc',
  display: 'inline-block',
};

export const CodeDisplay: FC<CodeDisplayProps> = memo(
  ({ children }: CodeDisplayProps) => (
    <Box minW={415} px={4} py={3} mt={3} mb={4} {...codeStyle}>
      {children}
    </Box>
  ),
);
