import { Stack } from '@chakra-ui/react';
import ErrorBoundary from 'api/ErrorBoundary';
import {
  ErrorContents,
  ErrorStatusContents, LoadingSkeleton
} from 'components/common/atoms';
import { MailCreateForm } from 'components/mail/templates/MailCreateForm';
import { MailEditForm } from 'components/mail/templates/MailEditForm';
import { useErrorBoundaryReset } from 'hooks/useErrorBoundaryReset';
import { useSetPageTitle } from 'hooks/useSetPageTitle';
import { memo, Suspense, VFC } from 'react';
import { useSearchParams } from 'react-router-dom';

export const MailForm: VFC = memo(() => {
  useSetPageTitle('メール原稿設定');
  const { ebKey, onError } = useErrorBoundaryReset();
  const [searchParams] = useSearchParams();
  const formId = searchParams.get('formId') || '';
  const renderErrorComponent = {
    404: <ErrorStatusContents code={404} />,
    450: <ErrorStatusContents code={450} />,
  };

  return (
    <ErrorBoundary
      errorComponent={<ErrorContents name="メール情報" />}
      statusComponent={renderErrorComponent}
      onError={onError}
      key={ebKey.current}
    >
      <Suspense fallback={<LoadingSkeleton />}>
        <Stack spacing={0} w="100%" h="full">
          {formId === '' && <MailCreateForm />}
          {formId !== '' && <MailEditForm formId={formId} />}
        </Stack>
      </Suspense>
    </ErrorBoundary>
  );
});
