import { deleteTerm } from 'admin/api/service/terms/deleteTerm';
import { CustomError } from 'admin/error/CustomError';
import { queryKeyNames } from 'admin/hooks/service/terms/queryKeys';
import { useUserPoolChildId } from 'admin/hooks/useUserPoolChildId';
import { ServiceTermType } from 'admin/types/service/terms/index';
import { logger } from 'api/logger';
import { useCustomToast } from 'hooks/useCustomToast';
import { useMutation, useQueryClient } from 'react-query';
import { errorToast, successToast } from 'utils/toast';

export const useServiceDeleteTerm = (userPoolTerms: ServiceTermType['id']) => {
  const toast = useCustomToast();
  const userPoolChildId = useUserPoolChildId();
  const queryClient = useQueryClient();

  const { mutateAsync, isLoading } = useMutation({
    mutationFn: () =>
      deleteTerm({
        userPoolChildId,
        userPoolTerms,
      }),
    onError: async (error) => {
      if (error instanceof CustomError) {
        await logger({
          loglevel: error.logLevel,
          data: JSON.stringify(error.cause),
          message: error.message,
        });
      }
      toast({
        ...errorToast,
        duration: 4000,
        title: '規約の利用設定の更新に失敗しました',
        position: 'bottom',
        variant: 'solid',
      });
    },
    onSuccess: () => {
      toast({
        ...successToast,
        duration: 4000,
        title: '規約の利用設定を更新しました',
        position: 'bottom',
        variant: 'solid',
      });
      void queryClient.invalidateQueries(queryKeyNames.getServiceTerms);
    },
  });

  return { mutate: mutateAsync, isLoading };
};
