import { useQuery } from 'react-query';
import { getMailId } from 'api/mail/getMailId';
import { Mail, MailDefaultValue } from 'api/mail/types';
import { useCustomToast } from 'hooks/useCustomToast';
import { useUserTenantId } from 'hooks/user/useUserTenantId';
import { queryMailKey } from './queryMailKey';

const defaultValues: Mail = MailDefaultValue;

/**
 * メール詳細取得 hooks
 */
export const useMailDetail = ({ mailId }: { mailId: string }): Mail => {
  const toast = useCustomToast();
  const tenantId = useUserTenantId();

  const response = useQuery(
    queryMailKey.getMailId({ tenantId, mailId }),
    () => getMailId(tenantId, mailId),
    {
      cacheTime: 0,
      onError: (err: unknown) => {
        if (err instanceof Error) {
          toast.closeAll();
          toast({
            title: err.message,
            status: 'error',
          });
        }
      },
    },
  );
  const { data: mailDetail } = response;

  return mailDetail || defaultValues;
};
