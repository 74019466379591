/* eslint-disable react/require-default-props */
import { Box } from '@chakra-ui/react';
import { DetailHeader } from 'components/paywall/features/Detail/DetailCommon/DetailHeader';
import { DetailInner } from 'components/paywall/features/Detail/DetailCommon/DetailInner';
import { FC, memo } from 'react';

type Props = {
  type: number;
  title?: string;
  children?: React.ReactNode;
  spacing?: string
  customerHelp?: boolean;
}
export const Detail: FC<Props> = memo(({type, title='', children, spacing = '40px', customerHelp = false}: Props) => (
  <Box
    _notFirst={{
      mt: spacing,
    }}
  >
    {/* タイトル */}
    <DetailHeader title={title} customerHelp={customerHelp} />
    {/* inner */}
    {children && (
      <DetailInner type={type}>
        {children}
      </DetailInner>
    )}
  </Box>
));
