import { Divider, Flex, HStack, Stack, Text } from '@chakra-ui/react';
import { HelpTooltip } from 'admin/components/ui/helpTooltip';
import { LoginSetting } from 'api/tenant/types';
import { Colors } from 'components/paywall/config';
import { FC, memo } from 'react';

type Props = {
  loginSetting: LoginSetting
};

export const LoginRedirectContentsComponent: FC<Props> = memo(({loginSetting}: Props) => (
  <Stack divider={<Divider color={Colors.GRAY_30}/>} spacing="16px">
    <Flex>
      <HStack w={310} minW={310}>
        <Text>ログインページURL</Text>
      </HStack>
      <Text width="calc(100% - 310px)">{loginSetting.loginUrl}</Text>
    </Flex>
    <Flex>
      <HStack w={310} minW={310}>
        <Text>会員登録ページURL</Text>
      </HStack>
      <Text width="calc(100% - 310px)">{loginSetting.signupUrl}</Text>
    </Flex>
    <Flex>
      <HStack w={310} minW={310}>
        <Text>リダイレクト先指定クエリパラメータ</Text>
        <HelpTooltip label="ログイン・会員登録完了後のリダイレクト先のURLを指定するためのクエリパラメータです。不明な場合にはご利用のIDシステムのご担当者にご確認ください。" />
      </HStack>
      <Text width="calc(100% - 310px)">{loginSetting.loginParam}</Text>
    </Flex>
    <Flex>
      <HStack w={310} minW={310}>
        <Text>任意のクエリパラメータ</Text>
        <HelpTooltip label="未ログイン時のユーザーをログインページ・会員登録ページにリダイレクトする時に任意のクエリパラメータを付与できます。" />
      </HStack>
      <Text width="calc(100% - 310px)">{loginSetting.loginOtherParam}</Text>
    </Flex>
  </Stack>
));
