import {
  Box,
  Button,
  Flex,
  Switch,
  Text,
  Tr as ChakraTr,
} from '@chakra-ui/react';
import {
  Table,
  Tbody,
  Td,
  TdWithBorder,
  Th,
  Thead,
  ThWithBorder,
  Tr,
} from 'admin/components/ui/table';
import { ServiceTermsType, ServiceTermType } from 'admin/types/service/terms';
import { Dispatch, FC, memo, SetStateAction } from 'react';

type Props = {
  terms: ServiceTermsType;
  onDrawerOpen: () => void;
  onModalOpen: () => void;
  setTarget: Dispatch<SetStateAction<ServiceTermType | undefined>>;
};

export const RuleTableComponent: FC<Props> = memo(
  ({ terms, onDrawerOpen, onModalOpen, setTarget }: Props) => (
    <Box mt="16px">
      <Table w="100%" maxHeight="calc(100vh - 250px)">
        <Thead>
          <ChakraTr>
            <Th maxWidth="130px" minWidth="130px">
              規約ID
            </Th>
            <Th maxWidth="180px" minWidth="180px">
              表示名（日本語）
            </Th>
            <Th maxWidth="180px" minWidth="180px">
              表示名（外国語）
            </Th>
            <Th maxWidth="120px" minWidth="120px">
              フィールド名
            </Th>
            <ThWithBorder maxWidth="240px" minWidth="240px">
              URL
            </ThWithBorder>
            <Th maxWidth="102px" minWidth="102px">
              バージョン
            </Th>
            <ThWithBorder maxWidth="120px" minWidth="120px">
              規約同意必須
              <br />
              バージョン
            </ThWithBorder>
            <Th maxWidth="141px" minWidth="141px">
              利用設定
            </Th>
          </ChakraTr>
        </Thead>
        <Tbody>
          {terms.map((term) => (
            <Tr key={`serviceRule${term.id}${term.id}`}>
              <Td maxWidth="100px">
                <Button
                  fontWeight="normal"
                  textDecoration="underline"
                  variant="link"
                  colorScheme="primary"
                  whiteSpace="break-spaces"
                  textAlign="left"
                  onClick={() => {
                    setTarget(term);
                    onDrawerOpen();
                  }}
                >
                  {term.id}
                </Button>
              </Td>
              <Td maxWidth="180px" minWidth="180px">
                {term.displayNameJa}
              </Td>
              <Td maxWidth="180px" minWidth="180px">
                {term.displayNameForeign || '-'}
              </Td>
              <Td maxWidth="180px" minWidth="180px">
                {term.fieldName}
              </Td>
              <TdWithBorder maxWidth="200px" minWidth="200px">
                {term.url}
              </TdWithBorder>
              <Td maxWidth="100px" minWidth="100px">
                {term.version}
              </Td>
              <TdWithBorder maxWidth="100px" minWidth="100px">
                {term.consentRequiredVersion}
              </TdWithBorder>
              <Td>
                <Flex>
                  <Switch
                    isChecked={term.isPublic}
                    colorScheme="primary"
                    isDisabled={term.isAutoAppendToChild}
                    onChange={() => {
                      setTarget(term);
                      onModalOpen();
                    }}
                  />
                  <Text ml="5px" fontWeight={600}>
                    {term.isPublic ? '利用する' : '利用しない'}
                  </Text>
                </Flex>
              </Td>
            </Tr>
          ))}
        </Tbody>
      </Table>
    </Box>
  ),
);
