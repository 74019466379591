import {
  headTitleOptions,
  screenNameOptions,
  screenDescriptionOptions,
  buttonNameOptions,
  textareaElementOptions,
} from './options';

const groupName = 'memberInfoChange';

// 会員情報変更画面
export default [
  // ロゴ	画像
  {
    name: `${groupName}.headTitle`,
    label: 'ブラウザタブ表示名',
    tooltip: '',
    type: 'Text',
    defaultValue: '会員情報の変更',
    options: headTitleOptions,
    elementOptions: {},
  },
  {
    name: `${groupName}.screenName`,
    label: '画面名',
    tooltip: '',
    type: 'Text',
    defaultValue: '会員情報の変更',
    options: screenNameOptions,
    elementOptions: {},
  },
  {
    name: `${groupName}.screenDescription`,
    label: '案内文 画面',
    tooltip: '',
    type: 'Textarea',
    defaultValue:
      '会員情報を変更し、画面最下部にある「保存」を押下することで会員情報の変更が完了します。',
    options: screenDescriptionOptions,
    elementOptions: textareaElementOptions,
  },
  {
    name: `${groupName}.saveButtonName`,
    label: 'ボタン名 変更した会員情報の保存',
    tooltip: '',
    type: 'Text',
    defaultValue: '保存',
    options: buttonNameOptions,
    elementOptions: {},
  },
  {
    name: `${groupName}.backButtonName`,
    label: 'ボタン名 前の画面に遷移',
    tooltip: '',
    type: 'Text',
    defaultValue: '戻る',
    options: buttonNameOptions,
    elementOptions: {},
  },
] as const;
