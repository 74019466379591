import { Box, Button, Flex, Text } from '@chakra-ui/react';
import { Colors, ColorSet } from 'components/paywall/config';
import {
  CardItem,
  CardItemBlock,
  CardItemList,
  CardItemListItem,
} from 'components/paywall/features/Card';
import { StatusTag } from 'components/paywall/features/StatusTag';
import { Course } from 'components/paywall/pages/CourseList/typed';
import { format } from 'date-fns';
import { useAmountUtil } from 'hooks/useAmountUtil';
import { FC, memo } from 'react';
import { useNavigate } from 'react-router-dom';

type Props = {
  course: Course;
};
type StatusType = {
  code: number;
  name: string;
  status: number;
};

const statusTypes: StatusType[] = [
  { code: 1, name: '開始前', status: 1 },
  { code: 2, name: '実施中', status: 2 },
  { code: 1, name: '終了済み', status: 3 },
];

const DATETIME_FORMAT = 'yyyy年MM月dd HH:mm';

export const CourseCardItem: FC<Props> = memo(({ course }: Props) => {
  const { amountFormat, calcTaxAmount } = useAmountUtil();
  const navigate = useNavigate();

  const formatDate = (date: string | null): string =>
    date ? format(new Date(date), DATETIME_FORMAT) : '';

  const getStatus = (statusCode: number) => {
    const result = statusTypes.filter((item) => item.status === statusCode);

    // ステータスが見つからなかった場合はdefaultを返却する
    if (result.length === 0)
      return {
        code: 0,
        name: 'なし',
        status: 0,
      };

    return result[0];
  };

  return (
    <>
      {/* コース */}
      <CardItem spacing="32px">
        <CardItemBlock w="100%" px="16px">
          <CardItemList spacing="8px" fontFamily="Hiragino sans">
            {/* 期間 */}
            <CardItemListItem label="期間" text={`${course.month || 0}ヶ月`} />
            {/* 税抜価格 */}
            <CardItemListItem
              label="税抜価格"
              text={`${amountFormat(course.price || 0)}円`}
            />
            {/* 税込み価格 */}
            <CardItemListItem
              label="税込み価格"
              text={`${calcTaxAmount(course.price || 0)}円`}
            />
          </CardItemList>
        </CardItemBlock>
      </CardItem>

      {/* プロモーション */}
      <CardItem spacing="32px" isDivider={false} mt="16px">
        <CardItemBlock
          w="100%"
          backgroundColor={Colors.GRAY_65}
          borderRadius="8px"
          p="16px"
          mb="8px"
        >
          <Box>
            <Flex
              borderBottom={`1px solid ${Colors.GRAY_30}`}
              pb="8px"
              mb="8px"
              fontFamily="Hiragino sans"
            >
              <Box display="flex" alignItems="center">
                <Text fontSize="14px" fontWeight="700" lineHeight="170%">
                  {course.promotions !== null
                    ? '有効なプロモーション（有り）'
                    : '有効なプロモーション（無し）'}
                </Text>
              </Box>
              <Button
                ml="auto"
                p="6px 12px"
                alignItems="end"
                onClick={() =>
                  navigate(
                    `/paywall/${course.wall.id}/${course.id}/promotionList`,
                  )
                }
                {...ColorSet.WHITE}
              >
                プロモーション一覧
              </Button>
            </Flex>
            <CardItemList spacing="8px" w="100%" fontFamily="Hiragino sans">
              {/* プロモーション名 */}
              <CardItemListItem>
                <Text width="140px" minW="140px" color={Colors.GRAY_10}>
                  プロモーション名
                </Text>
                {course.promotions !== null && (
                  <Text
                    fontSize="14px"
                    fontWeight="bold"
                    maxW="calc(100% - (140px + 12px))"
                    color={Colors.GRAY_03}
                  >
                    {course.promotions.name}
                  </Text>
                )}
                {course.promotions === null && (
                  <Text
                    fontSize="14px"
                    maxW="calc(100% - (140px + 12px))"
                    color={Colors.GRAY_03}
                  >
                    -
                  </Text>
                )}
              </CardItemListItem>
              {/* プロモーションの期間 */}
              <CardItemListItem
                label="プロモーションの期間"
                text={
                  course.promotions !== null
                    ? `${formatDate(
                        course.promotions.startDate,
                      )} ～ ${formatDate(course.promotions.endDate)}`
                    : '-'
                }
              />
              {/* 税抜価格 */}
              <CardItemListItem
                label="税抜価格"
                text={
                  course.promotions !== null
                    ? `${amountFormat(course.promotions.price || 0)}円`
                    : '-'
                }
              />
              {/* 税込み価格 */}
              <CardItemListItem
                label="税込み価格"
                text={
                  course.promotions !== null
                    ? `${calcTaxAmount(course.promotions.price || 0)}円`
                    : '-'
                }
              />
              {/* ステータス */}
              <CardItemListItem
                label="ステータス"
                text={
                  course.promotions !== null ? (
                    <StatusTag
                      type={getStatus(course.promotions.sellStatus).code}
                      status={getStatus(course.promotions.sellStatus).name}
                    />
                  ) : (
                    'なし'
                  )
                }
              />
            </CardItemList>
          </Box>
        </CardItemBlock>
      </CardItem>
    </>
  );
});
