import { patchWallItem } from 'api/paywall/patchWallItem';
import { ResponseError } from 'api/types';
import { WallItemDefaultValues } from 'components/paywall/pages/WallItemList/initial';
import {
  isWallItem, WallItem, WallItemFormType, WallItemSubmitFormType
} from 'components/paywall/pages/WallItemList/typed';
import { usePaywallUtil } from 'hooks/paywall/usePaywallUtil';
import { useCustomToast } from 'hooks/useCustomToast';
import { Dispatch, SetStateAction } from 'react';
import { UseFormSetError } from 'react-hook-form';
import { UseMutateFunction, useMutation } from 'react-query';
import { isGetValidationError, toMultiError } from 'utils/form';

type UnPromisify<T> = T extends Promise<infer U> ? U : T;

type DartyType = {
  [key in "price" | "sellType" | "period"]: boolean;
}

/**
 * 単品商品修正 hooks
 * @param {UseFormSetError<WallItemFormType>} setError
 * @param {Function} close
 * @param {Function} reset
 * @returns {Object} { onSubmit, isLoading }
 */
export const useEditWallItem = ({
  setError,
  close,
  reset,
  setIsDarty,
}: {
  setError?: UseFormSetError<WallItemFormType>;
  close?: () => void;
  reset?: () => void;
  setIsDarty?: Dispatch<SetStateAction<DartyType>>;
}): {
  onSubmit: UseMutateFunction<
    UnPromisify<ReturnType<typeof patchWallItem>>,
    unknown,
    WallItemSubmitFormType,
    unknown
  >;
  isLoading: boolean;
} => {
  const toast = useCustomToast();
  const { paywallWallItemListRefetchQueries } = usePaywallUtil();

  const { mutate: onSubmit, isLoading } = useMutation(
    ({ tenantId, wallId, wallItemForm }: WallItemSubmitFormType) =>
      patchWallItem({ tenantId, wallId, wallItemForm }),
    {
      onSuccess: (result: WallItem | boolean | ResponseError) => {
        paywallWallItemListRefetchQueries();

        if (isWallItem(result)) {
          close?.();
          reset?.();
          toast({
            status: 'success',
            position: 'bottom',
            duration: 4000,
            isClosable: true,
            title: '単品商品を修正しました',
          });
          setIsDarty?.({
            price: false,
            sellType: false,
            period: false
          });
        }

        if (
          isGetValidationError<WallItem>(
            result,
            Object.keys(WallItemDefaultValues),
          )
        ) {
          if (result?.others) {
            const errorMsg = Object.entries(result).map(([_, value]) => value);
            toast({
              status: 'error',
              position: 'bottom',
              duration: 4000,
              isClosable: true,
              description: errorMsg[0],
            });
          } else {
            Object.keys(result).forEach((k) => {
              const key = k as keyof WallItemFormType;
              const errMsgs = result?.[key];
              if (errMsgs) {
                setError?.(key, { types: toMultiError(errMsgs) });
              }
            });
          }
        }
      },
    },
  );

  return { onSubmit, isLoading };
};
