import {
  VFC,
  memo,
  useState,
  useEffect,
  useCallback,
  Dispatch,
  SetStateAction,
} from 'react';
import { useFormContext } from 'react-hook-form';
import {
  Box,
  Text,
  Button,
  Modal,
  ModalOverlay,
  ModalContent,
  ModalHeader,
  ModalBody,
  ModalCloseButton,
  useDisclosure,
  Icon,
  ButtonProps,
} from '@chakra-ui/react';
import { MdClear } from 'react-icons/md';
import { MailSetting } from 'api/mail/types';
import { FileUpload } from 'components/mail/atoms/FileUpload';

type AddressUploadProps = {
  fileName: string;
  onChangeAddressFile: () => void;
  setTargetValue: Dispatch<SetStateAction<number | string>>;
  // setTargetValue: Dispatch<SetStateAction<string | number>>;
} & Pick<ButtonProps, 'isDisabled'>;

export const AddressUpload: VFC<AddressUploadProps> = memo(
  ({
    fileName,
    onChangeAddressFile,
    setTargetValue,
    isDisabled = false,
  }: AddressUploadProps) => {
    const [addressFileName, setAddressFileName] = useState('');
    const { isOpen, onOpen, onClose } = useDisclosure();
    const { setValue, watch } = useFormContext<MailSetting>();

    useEffect(() => {
      setAddressFileName(fileName);
    }, [fileName]);

    const onDelete = useCallback(() => {
      setAddressFileName('');
      setValue('addressFile', '');
      setValue('addressFileOrigin', '');
    }, [setValue]);

    // アップロードファイルに変更があった場合の処理
    useEffect(() => {
      const fname = watch((val, { name }) => {
        if (name === 'addressFile') {
          // アップロードファイル変更
          if (val[name]) {
            onChangeAddressFile();

            // アップロードファイル削除
          } else {
            setTargetValue(0);
          }
        }
      });

      return () => fname.unsubscribe();
    }, [watch, onChangeAddressFile, setTargetValue]);

    return (
      <Box mb={4}>
        <Button
          variant="outline"
          ml={6}
          mb={2}
          onClick={() => onOpen()}
          isDisabled={isDisabled}
        >
          メールアドレスアップロード
        </Button>
        {addressFileName !== '' && (
          <Box ml={8} p={2} bg="gray.50">
            <Text mb={1}>現在設定されているファイル</Text>
            <Box
              bg="white"
              boxShadow="md"
              borderRadius={4}
              p={1}
              display="flex"
              justifyContent="space-between"
              alignItems="center"
            >
              <Text>{addressFileName}</Text>
              {!isDisabled && (
                <Box
                  w="1rem"
                  h="1rem"
                  position="relative"
                  cursor="pointer"
                  onClick={() => onDelete()}
                >
                  <Icon
                    position="absolute"
                    top={0}
                    left={0}
                    as={MdClear}
                    fontSize="1rem"
                  />
                </Box>
              )}
            </Box>
          </Box>
        )}

        <Modal isOpen={isOpen} size="lg" onClose={onClose} isCentered>
          <ModalOverlay />
          <ModalContent bg="White">
            <ModalHeader pb={0}>
              <Text color="gray.800">データアップロード</Text>
            </ModalHeader>
            <ModalBody pt={0} pb={6}>
              <Box
                mt={4}
                border="2px"
                borderColor="teal.50"
                shadow="xs"
                rounded="md"
              >
                <FileUpload
                  type="address"
                  ext=".txt,.csv,.tsv"
                  uploadParentPath="mail"
                  modalClose={onClose}
                  setFileName={setAddressFileName}
                />
              </Box>
            </ModalBody>
            <ModalCloseButton />
          </ModalContent>
        </Modal>
      </Box>
    );
  },
);
