import { VFC, memo } from 'react';
import { useUserTenantId } from 'hooks/user/useUserTenantId';
import { useMailDetail } from 'hooks/mail/useMailDetail';
import { MailFormInner } from 'components/mail/organisms/MailFormInner';

type Props = {
  formId: string;
};

export const MailEditForm: VFC<Props> = memo(({ formId }: Props) => {
  const tenantId = useUserTenantId();
  const mailDetail = useMailDetail({ mailId: formId });

  return (
    <MailFormInner
      tenantId={tenantId}
      mailForm={mailDetail}
      formIdParams={formId}
    />
  );
});
