import { Button, Flex, Text } from '@chakra-ui/react';
import { EditIcon } from 'admin/components/ui/icon/editIcon';
import { StatusLabel as Status } from 'admin/components/ui/status/StatusLabel';
import { Td, Tr } from 'admin/components/ui/table';
import { Colors } from 'admin/define/colors';
import {
  getDataTypeNameByValue,
  getUiTypeNameByValue,
} from 'admin/hooks/userPool/attribute/useFilterFieldTypeList';
import { useGetCodeGroupName } from 'admin/hooks/userPool/master/useGetCodeGroupName';
import { IdPoolConsumerAttributeType } from 'admin/types/userPool/attribute';
import { Dispatch, FC, memo, SetStateAction, useMemo } from 'react';

type Props = {
  attribute: IdPoolConsumerAttributeType;
  isUniikey: boolean;
  isOuter: boolean;
  onOpen: (id: IdPoolConsumerAttributeType['id']) => void;
  setTargetAttribute: Dispatch<
    SetStateAction<IdPoolConsumerAttributeType | undefined>
  >;
  onOpenStatusDialog: () => void;
  onOpenAutoAppendDialog: () => void;
  onOpenFormAttrDialog: () => void;
  onOpenCommonMyPageModalHandler: (status: boolean) => void;
};

export const AttributeTableInner: FC<Props> = memo(
  ({
    attribute,
    isUniikey,
    isOuter,
    onOpen,
    setTargetAttribute,
    onOpenStatusDialog,
    onOpenAutoAppendDialog,
    onOpenFormAttrDialog,
    onOpenCommonMyPageModalHandler,
  }: Props) => {
    const codeGroupName = useGetCodeGroupName();
    const ViewIsDisplayMyPage = useMemo<boolean>(() => {
      if (!attribute) return false;

      return attribute.isDisplayAccountSettings ?? false;
    }, [attribute]);

    return (
      <Tr>
        <Td fontSize="14px">
          <Text
            color={Colors.BLUE_20}
            cursor="pointer"
            textDecoration="underline"
            fontWeight="bold"
            onClick={() => onOpen(attribute.id)}
          >
            {attribute.id}
          </Text>
        </Td>
        <Td fontSize="14px">{attribute.displayNameJa}</Td>
        <Td fontSize="14px">{attribute.displayNameForeign || '-'}</Td>
        <Td fontSize="14px">{attribute.fieldName}</Td>
        <Td fontSize="14px">{getUiTypeNameByValue(attribute.uiType)}</Td>
        <Td fontSize="14px">{getDataTypeNameByValue(attribute.dataType)}</Td>
        <Td fontSize="14px">{codeGroupName(attribute.codeGroup) || '-'}</Td>

        <Td hasBorder={!isOuter} fontSize="14px">
          <Status
            status={attribute.isPersonalInformation || false}
            onLabel="ON"
            offLabel="OFF"
          />
        </Td>
        {isOuter && (
          <Td hasBorder={isOuter} fontSize="14px">
            {attribute.jsonPath || '-'}
          </Td>
        )}
        <Td fontSize="14px" pr={0}>
          <Flex gridGap={2} alignItems="center">
            <Status
              status={!!attribute.status}
              onLabel="確定"
              offLabel="下書き"
            />
            <Button
              variant="outline"
              colorScheme="primary"
              bgColor="WHITE"
              w="32px"
              h="32px"
              p="0"
              isDisabled={attribute.status}
              onClick={() => {
                setTargetAttribute(attribute);
                onOpenStatusDialog();
              }}
            >
              <EditIcon />
            </Button>
          </Flex>
        </Td>
        <Td fontSize="14px">
          <Flex gridGap={2} alignItems="center">
            <Status
              status={!!attribute.isAutoAppendToChild}
              onLabel="ON"
              offLabel="OFF"
            />
            <Button
              variant="outline"
              colorScheme="primary"
              bgColor="WHITE"
              w="32px"
              h="32px"
              p="0"
              isDisabled={!attribute.status || !!attribute.isAutoAppendToChild}
              onClick={() => {
                setTargetAttribute(attribute);
                onOpenAutoAppendDialog();
              }}
            >
              <EditIcon />
            </Button>
          </Flex>
        </Td>
        <Td hasBorder={!isUniikey} fontSize="14px">
          <Flex gridGap={2} alignItems="center">
            <Status
              status={!!attribute.isFormAttr}
              onLabel="ON"
              offLabel="OFF"
            />
            <Button
              variant="outline"
              colorScheme="primary"
              bgColor="WHITE"
              w="32px"
              h="32px"
              p="0"
              isDisabled={!attribute.status || !!attribute.isFormAttr}
              onClick={() => {
                setTargetAttribute(attribute);
                onOpenFormAttrDialog();
              }}
            >
              <EditIcon />
            </Button>
          </Flex>
        </Td>
        {isUniikey && (
          <Td hasBorder={isUniikey} fontSize="14px">
            <Flex gridGap={2} alignItems="center">
              <Status
                status={!!attribute.isDisplayAccountSettings}
                onLabel="ON"
                offLabel="OFF"
              />
              <Button
                variant="outline"
                colorScheme="primary"
                bgColor="WHITE"
                w="32px"
                h="32px"
                p="0"
                isDisabled={!attribute.status}
                onClick={() => {
                  setTargetAttribute(attribute);
                  onOpenCommonMyPageModalHandler(ViewIsDisplayMyPage);
                }}
              >
                <EditIcon />
              </Button>
            </Flex>
          </Td>
        )}
        <Td fontSize="14px">{attribute.displayOrder}</Td>
      </Tr>
    );
  },
);
