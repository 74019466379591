import { Options } from 'ky';
import { request } from 'api/request';
import { logger } from 'api/logger';
import produce from 'immer';
import { ResponseError, isResponseError } from 'api/types';
import {
  WallDesign,
  WallDesignFormType,
  isWallDesign,
} from 'components/paywall/pages/WallDesignList/typed';
import { WallDesignDefaultValues } from 'components/paywall/pages/WallDesignList/initial';
import { getValidationError, ValidationError } from 'utils/form';

import { messages } from './messages';

const DEFAULT_ERR_MESSAGE: ValidationError<WallDesignFormType> = {
  others: [messages.patchWallDesign.customer.error],
};
const DEFAULT_SYSTEM_ERR_MESSAGE: ValidationError<WallDesignFormType> = {
  others: [messages.patchWallDesign.customer.failed],
};

/**
 * ウォールデザイン変更 API
 * @param {WallDesignFormType} formParam API送信パラメータ
 * @param {Options} options APIオプション
 * @return {Promise<WallDesign | ResponseError>} ウォールデザイン | レスポンスエラー
 */
export const patchWallDesign = async (
  formParam: WallDesignFormType,
  options?: Options,
): Promise<WallDesign | ResponseError> => {
  const { id, tenant, wallId, name, source, html } = formParam;
  // idが存在しない場合は処理を中止する
  if (!id) return WallDesignDefaultValues;
  const path = `paywall/${tenant}/${wallId}/design/${id}/`;

  const postParam = {
    name,
    source,
    html,
  };

  const mergedOptions = options
    ? produce(options, (draft) => {
        draft.json = { ...postParam };
      })
    : { json: postParam };

  const response = await request({
    path,
    method: 'patch',
    options: mergedOptions,
  });

  if (response.status >= 500) {
    return DEFAULT_ERR_MESSAGE;
  }

  const responseData = response.data;

  // ウォールデザイン保存成功
  if (isWallDesign(responseData)) {
    return responseData;
  }

  const { error } = response;
  if (response.hasError) {
    // APIにて返却されたエラー形式
    if (isResponseError(error)) {
      const formError = getValidationError<WallDesignFormType>({
        formData: formParam,
        response: error,
      });

      return formError;
    }
  }

  // 予期せぬエラーなので
  // バックエンドに状態を通知
  await logger({
    loglevel:
      response.status === 404 || response.status === 450 ? 'warning' : 'error',
    data: error,
    message: messages.patchWallDesign.system.typeError,
  });

  // 登録できていない旨を通知
  return DEFAULT_SYSTEM_ERR_MESSAGE;
};
