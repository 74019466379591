import { Box, Flex, Text } from '@chakra-ui/react';
import styled from '@emotion/styled';
import ErrorBoundary from 'api/ErrorBoundary';
import { Link } from 'components/paywall/atoms/Link';
import { Colors } from 'components/paywall/config';
import { MailSettingPersonalData } from 'components/paywall/pages/MailSetting/typed';
import { convertStatusTitle } from 'components/paywall/pages/MailSetting/utils';
import { Container } from 'components/paywall/templates/Container';
import { useErrorBoundaryReset } from 'hooks/useErrorBoundaryReset';
import { FC, memo } from 'react';
import { useLocation } from 'react-router-dom';
import { MailSettingEditForm } from './MailSettingEditForm';

const StyledContainer = styled(Box)({
  width: '100%',
  margin: '0 auto',
  borderColor: 'black',
});

export const MailSettingEdit: FC = memo(() => {
  const { ebKey, onError } = useErrorBoundaryReset();
  const location = useLocation();
  const { type, checked } = location.state as Pick<
    MailSettingPersonalData,
    'type' | 'checked'
  >;

  return (
    <Container
      title={
        <Flex fontFamily="Hiragino Sans">
          <Link to="/paywall/mail">
            <Flex>
              <Text
                fontSize="14px"
                color={Colors.GRAY_10}
                fontweight="400"
                cursor="pointer"
              >
                ペイウォールメール設定
              </Text>
            </Flex>
          </Link>
          <Text as="span" color={Colors.GRAY_03} fontWeight="400">
            &nbsp;/&nbsp;
          </Text>
          <Text fontSize="14px" color={Colors.GRAY_03} fontWeight="700">
            {convertStatusTitle(type)}
          </Text>
        </Flex>
      }
    >
      <StyledContainer>
        <ErrorBoundary onError={onError} key={ebKey.current}>
          <MailSettingEditForm type={type} checked={checked} />
        </ErrorBoundary>
      </StyledContainer>
    </Container>
  );
});
