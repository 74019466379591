import { Colors } from 'components/paywall/config';
import { Detail, DetailBlock, DetailFlex, DetailItem, DetailItems } from 'components/paywall/features/Detail';
import { DetailCustomerLinkText } from 'components/paywall/features/Detail/DetailCustomer';
import { OrderDetailResult } from 'components/paywall/pages/OrderList/typed';
import { FC, memo } from 'react';

type Props = {
  customerDetail: OrderDetailResult | undefined;
}

export const OrderDetailInnerCustomer: FC<Props> = memo(({customerDetail}: Props) => (
  <>
    <Detail type={1} title="顧客情報" customerHelp>
      <DetailBlock space={16}>
        <DetailFlex>
          <DetailItems spacing="8px">
            <DetailItem
              label="顧客ID"
              text={<DetailCustomerLinkText path={`/paywall/${customerDetail?.customerUid || ''}/customerDetail`} text={customerDetail?.customerUid || ''} />}
              labelWidth="56px"
              fontColor={Colors.BLUE_20}
              textDecoration="underline"
              spacing="12px"
              isCustomerActive={customerDetail?.customer?.isActive}
            />
            <DetailItem label="姓名" text={`${customerDetail?.customer?.lastName || ''} ${customerDetail?.customer?.firstName || ''}`} labelWidth="56px" spacing="12px" />
          </DetailItems>
          <DetailItems spacing="8px">
            <DetailItem label="メールアドレス" text={customerDetail?.email || ''} labelWidth="98px" spacing="12px" />
            <DetailItem label="電話番号" text={customerDetail?.customer?.tel || ''} labelWidth="98px" spacing="12px" />
          </DetailItems>
        </DetailFlex>
      </DetailBlock>
    </Detail>
  </>
));
