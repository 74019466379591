import { Options } from 'ky';
import { request } from 'api/request';
import { IsTblExits, isIsTblExits } from 'api/contents/types';

/**
 * コンテンツシェルフに同名のテーブルがあるかをチェック
 *
 * @param tblName string チェックするテーブル名
 * @param tenantId string テナントID
 * @param options Options リクエストオプション
 * @returns Promise<IsTblExits | { message: string }>
 */
export const isTblExits = async (
  tblName: string,
  tenantId: string,
  options?: Options,
): Promise<IsTblExits | { message: string }> => {
  const response = await request({
    path: `contentsdb/${tenantId}/is_tbl_exits/?name=${tblName}`,
    method: 'get',
    options,
  });

  const responseData = await response.data;

  if (response.hasError) {
    if (response.status === 401) {
      return {
        message:
          '認証に失敗しました。恐れ入りますが再度ログインをお願いします。',
      };
    }

    return {
      message:
        '申し訳ございません。テーブルを取得できません。時間を置いて再表示をお願いします。',
    };
  }

  if (!isIsTblExits(responseData)) {
    return {
      message:
        'データ不正につき表示できません。恐れ入りますが画面の再読み込みをお願いします。',
    };
  }

  return responseData;
};
