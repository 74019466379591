import { VFC } from 'react';
import {
  Accordion,
  AccordionItem,
  AccordionButton,
  AccordionPanel,
  AccordionIcon,
  Text,
  Flex,
  Box,
} from '@chakra-ui/react';
import { InfoOutlineIcon } from '@chakra-ui/icons';

type CsvFileUploadErrorType = {
  errorMsg: {
    shortagePlaceholders: string;
    excessPlaceholders: string;
  };
};

export const CsvFileUploadError: VFC<CsvFileUploadErrorType> = ({
  errorMsg,
}) => (
  <Accordion allowMultiple mb={2} ml={6}>
    <AccordionItem>
      <AccordionButton
        fontSize="sm"
        justifyContent="space-between"
        bg="red.500"
        _hover={{ bg: 'red.400' }}
      >
        <Flex alignItems="center">
          <InfoOutlineIcon color="white" />
          <Text color="white" ml={4}>
            エラー：CSVファイルの修正が必要です
          </Text>
        </Flex>
        <AccordionIcon color="white" />
      </AccordionButton>
      <AccordionPanel pt={2} pb={2} fontSize="xs" bg="red.100">
        {errorMsg.shortagePlaceholders && (
          <Box>
            <Text fontWeight="bold" color="#666">
              CSVファイルのヘッダーに以下の列を追加してください
            </Text>
            <Text p={2} bg="red.200" fontWeight="bold">
              {errorMsg.shortagePlaceholders}
            </Text>
          </Box>
        )}
        {errorMsg.excessPlaceholders && (
          <Box mt={errorMsg.shortagePlaceholders ? 4 : 0}>
            <Text fontWeight="bold" color="#666">
              CSVファイルのヘッダーから以下の列を削除してください
            </Text>
            <Text p={2} bg="red.200" fontWeight="bold">
              {errorMsg.excessPlaceholders}
            </Text>
          </Box>
        )}
      </AccordionPanel>
    </AccordionItem>
  </Accordion>
);
