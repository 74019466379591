import { MailSettingCommon, MailSettingCommonForm, MailSettingPersonal, MailSettingPersonalForm, MailSettingStatuses } from "./typed";

export const MailSettingStatusesDefaultValue: MailSettingStatuses = {
  createPaymentInfo: {
    checked: false,
    warningMessage: '',
  },
  completeCoursePayment: {
    checked: false,
    warningMessage: '',
  },
  completeItemPayment: {
    checked: false,
    warningMessage: '',
  },
  completeContinuePayment: {
    checked: false,
    warningMessage: '',
  },
  failContinuePayment: {
    checked: false,
    warningMessage: '',
  },
  completeStopPayment: {
    checked: false,
    warningMessage: '',
  },
  completeRestartPayment: {
    checked: false,
    warningMessage: '',
  },
  cancelPurchase: {
    checked: false,
    warningMessage: '',
  },
};

/**
 * MailSettingCommon デフォルト値
 */
export const mailSettingCommonDefaultValue: MailSettingCommon = {
  id: 0,
  tenant: '',
  status: MailSettingStatusesDefaultValue,
  fromAddressList: [],
  replyAddressList: [],
  header: '',
  footer: '',
  fromAddress: '',
  replyAddress: '',
}

export const MailSettingCommonFormDefaultValue: MailSettingCommonForm = {
  header: '',
  footer: '',
  fromAddress: '',
  replyAddress: '',
}

/**
 * MailSettingPersonal デフォルト値
 */
export const mailSettingPersonalDefaultValue: MailSettingPersonal = {
  id: 0,
  tenant: '',
  type: '',
  subject: '',
  bodyText: '',
  status: false,
}

export const MailSettingPersonalFormDefaultValue: MailSettingPersonalForm = {
  subject: '',
  bodyText: '',
}


