import { Box, Text } from '@chakra-ui/react';
import { withSuspenseAndErrorBoundary } from 'admin/components/Error/WithErrorBoundary';
import { ErrorContents } from 'components/common/atoms';
import { FC, memo } from 'react';

export const memberTitle: FC = memo(() => (
  <Box mt="40px">
    <Text
      fontFamily="Hiragino Sans"
      fontSize="18px"
      fontWeight="700"
      lineHeight="170%"
    >
      会員管理
    </Text>
  </Box>
));

export const MemberTitle = memo(
  withSuspenseAndErrorBoundary(memberTitle, {
    ErrorComponent: <ErrorContents name="会員管理タイトル" />,
  }),
);
