import { useRef, MutableRefObject } from 'react';

/**
 * ErrorBoundary をリセットするための
 * 材料を提供する hooks です
 *
 * ErrorBoundaryでエラーをレンダリングすると
 * コンポーネントの key が変わらないため
 * 入力値を正常にしても Errorコンポーネントが
 * 表示され続ける問題があります
 *
 * その対応として ErrorBoundary コンポーネントの
 * key を変えてあげることで、エラーが解消時に
 * ErrorBoundary のコンポーネントを
 * 消す(リセット)ことができます
 *
 * ========== 使い方 ==========
 * import { useErrorBoundaryReset } from 'hooks/useErrorBoundaryReset';
 *
 * const Sample = () => {
 *   const { ebKey, onError } = useErrorBoundaryReset();
 *   <ErrorBoundary onError={onError} key={ebKey.current}>
 *     <Suspense>
 *       <div>any...</div>
 *     </Suspense>
 *   </ErrorBoundary>
 * }
 *
 */
export const useErrorBoundaryReset = (): {
  ebKey: MutableRefObject<number>;
  onError: () => void;
} => {
  const ebKey = useRef(0);
  const onError = () => {
    ebKey.current += 1;
  };

  return { ebKey, onError };
};
