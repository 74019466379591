/**
 * ペイウォール一覧 カラム Type
 */
export type PaywallColumns = {
  id: string;
  key: string;
  name: string;
  w: number;
};

/**
 * 単品一覧 カラム Type
 */
export type ItemColumns = {
  id: string;
  key: string;
  name: string;
  w: number;
  bgColor: string;
};

/**
 * 単品ウォール一覧 カラム Type
 */
export type ItemWallColumns = {
  id: string;
  key: string;
  name: string;
  w: number;
};

/**
 * ペイウォールコース期間 カラム Type
 */
export type CourseColumns = {
  id: string;
  name: string;
};

export type AddCourseFormType = {
  name: string;
  term: string;
  price: string;
};

export type PromotionInputs = {
  name: string;
  discount: string;
  fixedAmount: string;
  startDate: string | null;
  endDate: string | null;
  tag: string;
  valid: boolean;
  memo: string;
};

export type dashBoard = {
  url: string;
};

export type Memo = {
  tenantId: string;
  customerUid: string;
  orderId: string;
  memo: string;
}


export const isDashboardType = (data: unknown): data is dashBoard => {
  const e = data as dashBoard;

  return typeof e.url === 'string';
};
