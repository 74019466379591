import { Flex, Text } from '@chakra-ui/react';
import { PaywallCardList } from 'components/paywall//pages/WallList/PaywallCardList';
import { WallDialog } from 'components/paywall//pages/WallList/WallDialog';
import { Colors } from 'components/paywall/config';
import { CustomErrorBoundary } from 'components/paywall/molecules/CustomErrorBoundary';
import { Container } from 'components/paywall/templates/Container';
import { FC, memo } from 'react';

export const WallList: FC = memo(() => (
  <CustomErrorBoundary pageTitle="サブスクリプション商品設定">
    <Container
      title={
        <Flex>
          <Text fontSize="14px" color={Colors.BLACK} fontWeight="700">
            サブスクリプション商品設定
          </Text>
        </Flex>
      }
      label={<WallDialog isAdd buttonName="新規作成" />}
      px="0px"
      contentsPl="45px"
    >
      <PaywallCardList />
    </Container>
  </CustomErrorBoundary>
));
