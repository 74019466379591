export const queryAuthHubKey = {
  getAuthHub: ({
    userPoolId,
    tenantId,
    authHubId,
  }: {
    userPoolId?: string;
    tenantId?: string;
    authHubId: string;
  }) => ['authHub', userPoolId, tenantId, authHubId],
  getAuthHubs: ({
    userPoolId,
    tenantId,
  }: {
    userPoolId?: string;
    tenantId?: string;
  }) => ['authHubs', userPoolId, tenantId],
} as const;
