import { VFC, memo, useCallback, useState } from 'react';
import {
  Button,
  Modal,
  ModalOverlay,
  ModalContent,
  ModalHeader,
  ModalFooter,
  ModalBody,
  ModalCloseButton,
  Text,
  Input,
} from '@chakra-ui/react';

type Props = {
  isOpen: boolean;
  onClose: () => void;
  onChangeSubmit: () => void;
};

export const ChangeDeleteInsertModal: VFC<Props> = memo(
  ({ isOpen, onClose, onChangeSubmit }: Props) => {
    const [deleteText, setDeleteText] = useState('');

    const onChangeBtn = useCallback(() => {
      onChangeSubmit();
      setDeleteText('');
    }, [onChangeSubmit]);

    const onClosePopup = useCallback(() => {
      onClose();
      setDeleteText('');
    }, [onClose]);

    const isDisabled = (): boolean => deleteText === 'CHANGE';

    return (
      <Modal
        closeOnOverlayClick={false}
        isOpen={isOpen}
        onClose={onClosePopup}
        isCentered
      >
        <ModalOverlay />
        <ModalContent>
          <ModalHeader>洗い替え取り込み</ModalHeader>
          <ModalBody>
            <Text mb={4}>変更する場合は CHANGEと入力してください。</Text>
            <Input
              type="text"
              value={deleteText}
              onChange={(e) => setDeleteText(e.target.value)}
            />
          </ModalBody>

          <ModalFooter>
            <Button
              variant="primary"
              onClick={() => onChangeBtn()}
              isDisabled={!isDisabled()}
            >
              変更
            </Button>
          </ModalFooter>
          <ModalCloseButton />
        </ModalContent>
      </Modal>
    );
  },
);
