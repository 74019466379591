import { BoxProps } from '@chakra-ui/react';
import { ThNoBorder } from 'admin/components/ui/table/th/ThNoBorder';
import { ThWithBorder } from 'admin/components/ui/table/th/ThWithBorder';
import { FC, memo, ReactNode } from 'react';

type Props = {
  hasBorder?: boolean;
  children: ReactNode;
} & BoxProps;

export const Th: FC<Props> = memo(
  ({ hasBorder = false, children, ...props }) => {
    const Component = hasBorder ? ThWithBorder : ThNoBorder;

    return <Component {...props}>{children}</Component>;
  },
);
