import {
  DrawerCloseButton,
  DrawerContent,
  DrawerHeader,
  DrawerOverlay,
} from '@chakra-ui/react';
import { withSuspenseAndErrorBoundary } from 'admin/components/Error/WithErrorBoundary';
import { TenantUsersEditForm } from 'admin/components/tenantUsers/tenantUsersEditForm';
import { useTenantUsersDetail } from 'admin/hooks/tenantUsers/useTenantUsersDetail';
import { ErrorContents, LoadingSpinner } from 'components/common/atoms';
import { FC, memo } from 'react';

type Props = {
  tenantUsersId: string;
  onClose: () => void;
  tenantId: string;
};

const fetchTenantUsersEditDrawer: FC<Props> = memo(
  ({ tenantUsersId, tenantId, onClose }: Props) => {
    const { data: tenantUsersDetail } = useTenantUsersDetail(
      tenantUsersId,
      tenantId,
    );

    if (!tenantUsersDetail) {
      return null;
    }

    return (
      <TenantUsersEditForm
        tenantUsersDetail={tenantUsersDetail}
        onClose={onClose}
      />
    );
  },
);

export const FetchTenantUsersEditDrawer = withSuspenseAndErrorBoundary(
  fetchTenantUsersEditDrawer,
  {
    ErrorComponent: <ErrorContents name="ユーザー情報編集" />,
    LoadingComponent: <LoadingSpinner />,
  },
);

export const TenantUsersEditDrawer: FC<Props> = memo(
  ({ tenantUsersId, tenantId, onClose }: Props) => (
    <>
      <DrawerOverlay />
      <DrawerContent>
        <DrawerHeader borderBottomWidth="1px">ユーザー情報編集</DrawerHeader>
        <FetchTenantUsersEditDrawer
          tenantUsersId={tenantUsersId}
          onClose={onClose}
          tenantId={tenantId}
        />
        <DrawerCloseButton />
      </DrawerContent>
    </>
  ),
);
