import { request } from 'admin/api/Request';
import { API_MESSAGES } from 'admin/define/apiMessages';
import { CustomError } from 'admin/error/CustomError';
import { attributeSchema } from 'admin/schema/idPoolConsumer/attribute';
import { UserPool } from 'admin/types/userPool';
import { IdPoolConsumerAttributeCreateFormType } from 'admin/types/userPool/attribute/form';
import { isResponseError } from 'api/types';
import { getValidationError } from 'utils/form';
import { ZodError } from 'zod';

export const createIdPoolConsumerAttribute = async (
  data: IdPoolConsumerAttributeCreateFormType,
  id: UserPool['id'],
): Promise<IdPoolConsumerAttributeCreateFormType> => {
  const params = {
    ...data,
    displayNameForeign:
      data?.displayNameForeign !== '' && data?.displayNameForeign
        ? data?.displayNameForeign
        : undefined,
    codeGroup:
      data.codeGroup !== '' || data.codeGroup ? data.codeGroup : undefined,
    jsonPath: data.jsonPath !== '' || data.jsonPath ? data.jsonPath : undefined,
    jsonPathValueType: Number(data.jsonPathValueType)
      ? Number(data.jsonPathValueType)
      : undefined,
    displayOrder: Number(data?.displayOrder)
      ? Number(data?.displayOrder)
      : undefined,
  };

  const path = `uniikey/${id}/user_attribute/`;
  const response = await request<IdPoolConsumerAttributeCreateFormType>({
    path,
    body: params,
    method: 'post',
  });
  if (response.hasError) {
    if (response.status === 404) {
      throw new CustomError(
        API_MESSAGES.USER_POOL.CREATE_ID_POOL_CONSUMER_ATTRIBUTE.FAILED,
        {
          path,
          params: data,
          status: response.status,
        },
      );
    } else if (isResponseError(response.error)) {
      const customError = response.error;

      const formError =
        getValidationError<IdPoolConsumerAttributeCreateFormType>({
          formData: data,
          response: customError,
        });

      throw new CustomError(
        API_MESSAGES.USER_POOL.CREATE_ID_POOL_CONSUMER_ATTRIBUTE.ERROR,
        {
          path,
          params: data,
          errors: formError,
          status: response.status,
          logLevel: response.status === 400 ? 'warning' : 'error',
        },
      );
    } else {
      throw new CustomError(
        API_MESSAGES.USER_POOL.CREATE_ID_POOL_CONSUMER_ATTRIBUTE.ERROR,
        {
          path,
          params: data,
          status: response.status,
        },
      );
    }
  }
  try {
    attributeSchema.parse(response.data);
  } catch (error) {
    if (error instanceof ZodError) {
      throw new CustomError(error.message, {
        path,
        params: data,
        status: response.status,
        logLevel: 'error',
      });
    }
  }

  return response.data;
};
