import { Drawer, useDisclosure } from '@chakra-ui/react';
import { withSuspenseAndErrorBoundary } from 'admin/components/Error/WithErrorBoundary';
import { TermsCreateDrawer } from 'admin/components/idPoolConsumer/terms/termsDrawer/index';
import { TermTitleComponent } from 'admin/components/idPoolConsumer/terms/termsTitle/TermTitleComponent';
import { ErrorContents } from 'components/common/atoms';
import { FC, memo } from 'react';

const PlainTermsTitle: FC = memo(() => {
  const { isOpen, onClose, onOpen } = useDisclosure();
  const formId = 'termCreateForm';

  return (
    <>
      <TermTitleComponent onOpen={onOpen} />
      {/* ドロワー */}
      <Drawer
        isOpen={isOpen}
        placement="right"
        onClose={onClose}
        size="md"
        closeOnOverlayClick={false}
        closeOnEsc={false}
        autoFocus={false}
      >
        <TermsCreateDrawer onClose={onClose} isOpen={isOpen} formId={formId} />
      </Drawer>
    </>
  );
});

export const TermsTitle = withSuspenseAndErrorBoundary(PlainTermsTitle, {
  ErrorComponent: <ErrorContents name="規約設定タイトル" />,
  LoadingComponent: <></>,
});
