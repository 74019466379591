import { Box, BoxProps } from '@chakra-ui/react';
import { FC, memo } from 'react';

type Props = {
  children: React.ReactNode;
} & BoxProps;

export const CardItemBlock: FC<Props> = memo(({ children, ...rest }: Props) => (
  <Box w="240px" minW="240px" {...rest}>
    {children}
  </Box>
));
