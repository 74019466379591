import {
  Button,
  HStack,
  ModalBody,
  ModalCloseButton,
  ModalContent,
  ModalFooter,
  ModalHeader,
  ModalOverlay,
  Text,
} from '@chakra-ui/react';
import { LoadingLayer } from 'admin/components/ui/loadingLayer';
import { ColorSet } from 'admin/define/colors';
import { FC, memo, useMemo } from 'react';

type Props = {
  isLoading: boolean;
  status: boolean;
  onClose: () => void;
  onSubmit: () => void;
};

export const CommonMypageChangeModal: FC<Props> = memo(
  ({ isLoading, status, onClose, onSubmit }: Props) => {
    const displayStatus = useMemo(
      () => (
        <Text as="span" fontWeight={700}>
          {!status ? '有効' : '無効'}
        </Text>
      ),
      [status],
    );

    return (
      <>
        {isLoading && <LoadingLayer />}
        <ModalOverlay />
        <ModalContent fontSize="md" zIndex={100}>
          <ModalHeader fontSize="lg" fontWeight="bold" pt={6}>
            共通マイページ表示の{!status ? '有効' : '無効'}化
          </ModalHeader>
          <ModalBody>
            <Text letterSpacing="1px">
              共通マイページに表示する設定を{displayStatus}にします。
              <br />
              {displayStatus}
              にすると、AuthHub機能で作成する共通マイページに、この属性が
              {!status ? '表示されるように' : '表示されなく'}なります。
            </Text>
          </ModalBody>
          <ModalFooter pb={6}>
            <HStack spacing={4}>
              <Button
                variant="solid"
                {...ColorSet.Default}
                padding="8px 16px"
                onClick={onClose}
              >
                キャンセル
              </Button>
              <Button
                colorScheme="primary"
                mr={3}
                padding="8px 36px"
                onClick={() => onSubmit()}
              >
                実行
              </Button>
            </HStack>
          </ModalFooter>
          <ModalCloseButton />
        </ModalContent>
      </>
    );
  },
);
