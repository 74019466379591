import { Button, Flex, Text, Tooltip } from '@chakra-ui/react';
import { EditIcon } from 'admin/components/ui/icon/editIcon';
import { StatusLabel as Status } from 'admin/components/ui/status/StatusLabel';
import { Td, TdWithBorder, Tr } from 'admin/components/ui/table';
import { Colors } from 'admin/define/colors';
import {
  ServiceNewsletterChild,
  ServiceNewsletterType,
} from 'admin/types/service/newsletter';
import { Dispatch, FC, memo, SetStateAction, useMemo } from 'react';

export type Props = {
  newsletter: ServiceNewsletterType;
  setTargetServiceNewsletter: Dispatch<
    SetStateAction<ServiceNewsletterType | undefined>
  >;
  onOpenEditDrawer: (target: ServiceNewsletterType) => void;
  onOpenTenantAppendDialogHandler: (newsletter: ServiceNewsletterType) => void;
  onOpenDeliveryAvailableModalHandler: (
    status: ServiceNewsletterChild['isDeliveryAvailable'],
    newsletter: ServiceNewsletterType,
  ) => void;
  onOpenDisplayWhenRegistrationModalHandler: (
    status: ServiceNewsletterChild['isDisplayWhenRegistration'],
    newsletter: ServiceNewsletterType,
  ) => void;
  onOpenCheckWhenRegistrationModalHandler: (
    status: ServiceNewsletterChild['isCheckWhenRegistration'],
    newsletter: ServiceNewsletterType,
  ) => void;
};

export const NewsletterTableBody: FC<Props> = memo(
  ({
    newsletter,
    setTargetServiceNewsletter,
    onOpenEditDrawer,
    onOpenTenantAppendDialogHandler,
    onOpenDeliveryAvailableModalHandler,
    onOpenDisplayWhenRegistrationModalHandler,
    onOpenCheckWhenRegistrationModalHandler,
  }: Props) => {
    const viewIsDeliveryAvailable = useMemo<boolean>(() => {
      if (!newsletter.userPoolChildNewsletter) return false;

      return newsletter.userPoolChildNewsletter.isDeliveryAvailable;
    }, [newsletter.userPoolChildNewsletter]);

    const viewIsDisplayWhenRegistration = useMemo<boolean>(() => {
      if (!newsletter.userPoolChildNewsletter) return false;

      return newsletter.userPoolChildNewsletter.isDisplayWhenRegistration;
    }, [newsletter.userPoolChildNewsletter]);

    const viewIsCheckWhenRegistration = useMemo<boolean>(() => {
      if (!newsletter.userPoolChildNewsletter) return false;

      return newsletter.userPoolChildNewsletter.isCheckWhenRegistration;
    }, [newsletter.userPoolChildNewsletter]);

    const trimMemo = useMemo(
      () =>
        newsletter.memo.length > 25
          ? `${newsletter.memo.substring(0, 25)} ...`
          : newsletter.memo,
      [newsletter.memo],
    );

    return (
      <Tr>
        <Td fontSize="14px" maxWidth="150px">
          <Text
            color={Colors.BLUE_20}
            cursor="pointer"
            textDecoration="underline"
            fontWeight="bold"
            onClick={() => onOpenEditDrawer(newsletter)}
          >
            {newsletter.id}
          </Text>
        </Td>
        <Td fontSize="14px" maxWidth="180px">
          {newsletter.displayNameJa}
        </Td>
        <Td fontSize="14px" maxWidth="180px">
          {newsletter.displayNameForeign || '-'}
        </Td>
        <Td fontSize="14px" maxWidth="120px">
          {newsletter.fieldName}
        </Td>
        <Td fontSize="14px" minWidth="140px">
          <Status
            onLabel="ON"
            offLabel="OFF"
            status={newsletter.isDisplayAccountSettings}
          />
        </Td>
        <TdWithBorder maxWidth="240px" fontSize="14px">
          <Tooltip
            wordBreak="break-all"
            overflowWrap="break-word"
            isDisabled={!newsletter.memo}
            label={
              <Text
                whiteSpace="pre-wrap"
                height="auto"
                wordBreak="break-all"
                overflowWrap="break-word"
              >
                {newsletter.memo}
              </Text>
            }
          >
            {trimMemo || '-'}
          </Tooltip>
        </TdWithBorder>
        <Td fontSize="14px">
          <Flex gridGap={2} alignItems="center">
            <Status
              onLabel="ON"
              offLabel="OFF"
              status={!!newsletter.userPoolChildNewsletter}
            />
            <Button
              variant="outline"
              colorScheme="primary"
              bgColor="WHITE"
              w="32px"
              h="32px"
              p="0"
              isDisabled={!!newsletter.userPoolChildNewsletter}
              onClick={() => {
                onOpenTenantAppendDialogHandler(newsletter);
              }}
            >
              <EditIcon />
            </Button>
          </Flex>
        </Td>
        <Td fontSize="14px">
          <Flex gridGap={2} alignItems="center">
            <Status
              onLabel="ON"
              offLabel="OFF"
              status={viewIsDeliveryAvailable}
            />
            <Button
              variant="outline"
              colorScheme="primary"
              bgColor="WHITE"
              w="32px"
              h="32px"
              p="0"
              isDisabled={!newsletter.userPoolChildNewsletter}
              onClick={() => {
                setTargetServiceNewsletter(newsletter);
                onOpenDeliveryAvailableModalHandler(
                  viewIsDeliveryAvailable,
                  newsletter,
                );
              }}
            >
              <EditIcon />
            </Button>
          </Flex>
        </Td>
        <Td fontSize="14px">
          <Flex gridGap={2} alignItems="center">
            <Status
              onLabel="ON"
              offLabel="OFF"
              status={viewIsDisplayWhenRegistration}
            />
            <Button
              variant="outline"
              colorScheme="primary"
              bgColor="WHITE"
              w="32px"
              h="32px"
              p="0"
              isDisabled={!newsletter.userPoolChildNewsletter}
              onClick={() => {
                setTargetServiceNewsletter(newsletter);
                onOpenDisplayWhenRegistrationModalHandler(
                  viewIsDisplayWhenRegistration,
                  newsletter,
                );
              }}
            >
              <EditIcon />
            </Button>
          </Flex>
        </Td>
        <Td fontSize="14px">
          <Flex gridGap={2} alignItems="center">
            <Status
              onLabel="ON"
              offLabel="OFF"
              status={viewIsCheckWhenRegistration}
            />
            <Button
              variant="outline"
              colorScheme="primary"
              bgColor="WHITE"
              w="32px"
              h="32px"
              p="0"
              isDisabled={!newsletter.userPoolChildNewsletter}
              onClick={() => {
                setTargetServiceNewsletter(newsletter);
                onOpenCheckWhenRegistrationModalHandler(
                  viewIsCheckWhenRegistration,
                  newsletter,
                );
              }}
            >
              <EditIcon />
            </Button>
          </Flex>
        </Td>
      </Tr>
    );
  },
);
