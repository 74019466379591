import { useDisclosure } from '@chakra-ui/react';
import { withSuspenseAndErrorBoundary } from 'admin/components/Error/WithErrorBoundary';
import { useToken } from 'admin/hooks/userPool/auth/useToken';
import { ErrorContents } from 'components/common/atoms';
import { FC, memo } from 'react';
import { TokenDrawer } from '../tokenDrawer';
import { TokenSettingsComponent } from './TokenSettingsComponent';

const fetchTokenSettings: FC = memo(() => {
  const token = useToken();
  const { isOpen, onClose, onOpen } = useDisclosure();
  const formId = `tokenForm`;

  return (
    <>
      <TokenSettingsComponent token={token} onDrawerOpen={onOpen} />
      <TokenDrawer isOpen={isOpen} onClose={onClose} formId={formId} />
    </>
  );
});

export const TokenSettings = memo(
  withSuspenseAndErrorBoundary(fetchTokenSettings, {
    ErrorComponent: (
      <ErrorContents name="認証認可とセキュリティ設定トークン設定" />
    ),
  }),
);
