import { zodResolver } from '@hookform/resolvers/zod';
import { withSuspenseAndErrorBoundary } from 'admin/components/Error/WithErrorBoundary';
import { EnableChangeFormComponent } from 'admin/components/idPoolConsumer/terms/enableChangeModal/EnableChangeFormComponent';
import { LoadingLayer } from 'admin/components/ui/loadingLayer';
import { useChangeTermEnable } from 'admin/hooks/userPool/terms/useChangeTermEnable';
import { agreeSubmitFormSchema } from 'admin/schema/util';
import { TermType } from 'admin/types/userPool/terms';
import { FC, memo, useCallback } from 'react';
import { FormProvider, useForm } from 'react-hook-form';

export type Props = {
  target: TermType | null;
  onClose: () => void;
};

export const enableChangeModal: FC<Props> = memo(({ onClose, target }) => {
  const formId = 'enableChangeForm';
  const { mutate, isLoading } = useChangeTermEnable(target?.id ?? '');

  const methods = useForm({
    defaultValues: {
      word: '',
    },
    mode: 'onBlur',
    resolver: target?.isEnabled
      ? zodResolver(agreeSubmitFormSchema)
      : undefined,
  });

  const onSubmit = useCallback(async () => {
    await mutate(!!target?.isEnabled);
    onClose();
  }, [mutate, onClose]);

  return (
    <FormProvider {...methods}>
      {isLoading && <LoadingLayer />}
      <EnableChangeFormComponent
        target={target}
        formId={formId}
        isLoading={isLoading}
        onClose={onClose}
        onSubmit={onSubmit}
      />
    </FormProvider>
  );
});

export const EnableChangeModal =
  withSuspenseAndErrorBoundary(enableChangeModal);
