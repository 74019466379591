import { postEnqueteSkin } from 'api/enquete/postEnqueteSkin';
import { AddSkinForm, isSkin, Skin, SkinFormType } from 'api/enquete/types';
import { ResponseError } from 'api/types';
import { useEnqueteUtil } from 'hooks/enquete/useEnqueteUtil';
import { useCustomToast } from 'hooks/useCustomToast';
import { UseFormSetError } from 'react-hook-form';
import { UseMutateFunction, useMutation } from 'react-query';
import { useNavigate } from 'react-router-dom';
import {
  // getValidationError,
  isGetNestedValidationError,
  toMultiError,
} from 'utils/form';

type UnPromisify<T> = T extends Promise<infer U> ? U : T;

const formData: SkinFormType = {
  id: '',
  name: '',
  titleBgColor: '#f5f9fe',
  titleTextColor: '#3b8ce7',
  ruledLineColor: '#3b8ce7',
  submitButtonBgColor: '#354f9b',
  submitButtonTextColor: '#FFFFFF',
  labelBgColor: '#f00000',
  labelTextColor: '#FFFFFF',
  selectButtonBgColor: '#3b8ce7',
  selectButtonTextColor: '#FFFFFF',
  radioButtonSelectedColor: '#3b8ce7',
  checkboxSelectedColor: '#3b8ce7',
  finishedButtonBgColor: '#f00000',
  finishedButtonTextColor: '#FFFFFF',
  logoUrl: '',
  logoPosition: 'left',
  errorTextColor: '#f00000',
  isPreview: false,
};

/**
 * デザインスキン作成 hooks
 * @returns {Object} { onSubmit, isLoading }
 */
export const useCreateEnqueteSkin = ({
  setError,
}: {
  setError: UseFormSetError<Skin>;
}): {
  onSubmit: UseMutateFunction<
    UnPromisify<ReturnType<typeof postEnqueteSkin>>,
    unknown,
    AddSkinForm,
    unknown
  >;
  isLoading: boolean;
} => {
  const { EnqueteSkinRefetchListQueries, ClearEnqueteSkinListCache } =
    useEnqueteUtil();
  const toast = useCustomToast();
  const navigate = useNavigate();

  const { mutate: onSubmit, isLoading } = useMutation(
    ({ tenantId, skinForm }: AddSkinForm) =>
      postEnqueteSkin({ tenantId, skinForm }),
    {
      onSuccess: (result: Skin | boolean | ResponseError) => {
        EnqueteSkinRefetchListQueries();
        ClearEnqueteSkinListCache();
        if (isSkin(result)) {
          toast({
            status: 'success',
            position: 'bottom',
            duration: 4000,
            isClosable: true,
            title: 'デザインスキンを作成しました',
          });
          navigate(`/enquete/skin/`);
        }

        if (isGetNestedValidationError<Skin>(result, Object.keys(formData))) {
          if (result?.others) {
            const errorMsg = Object.entries(result).map(([_, value]) => value);
            toast({
              status: 'error',
              position: 'bottom',
              duration: 4000,
              isClosable: true,
              title: errorMsg[0],
            });
          } else {
            Object.keys(result).forEach((k) => {
              const key = k as keyof Skin;
              const errMsgs = result?.[key];
              if (errMsgs) {
                setError(key, { types: toMultiError(errMsgs) });
              }
            });
          }
        }
      },
      onError: (err: unknown) => {
        if (err instanceof Error) {
          toast.closeAll();
          toast({
            title: err.message,
            status: 'error',
          });
        }
      },
    },
  );

  return { onSubmit, isLoading };
};
