import { Box, Flex, Heading, Text, VStack } from '@chakra-ui/react';
import { EnqueteSurveyForm } from 'api/enquete/types';
import { InputForm } from 'components/common/molecules';
import { CloseBtnElement } from 'components/enquete/atoms/CloseBtnElement';
import { RichTextElement } from 'components/enquete/atoms/RichTextElement';
import { Dispatch, FC, SetStateAction } from 'react';
import { FieldValues, Path, useFormContext } from 'react-hook-form';

type EnqueteCompleteProps<T> = {
  baseName: Path<T>;
  setIsEdited: Dispatch<SetStateAction<boolean>>;
};


export const EnqueteComplete = <T extends FieldValues>({
  baseName,
  setIsEdited,
}: EnqueteCompleteProps<T>): ReturnType<FC> => {
  const { getValues } = useFormContext<EnqueteSurveyForm>();

  return (
    <VStack spacing={6} pb={10}>
      <Heading
        as="h2"
        size="md"
        pb={2}
        w="100%"
        borderBottom="2px solid #4299e1"
      >
        送信ボタン・完了ページ文言設定
      </Heading>
      <Box
        w="full"
        bg="#fff"
        borderWidth="1px"
        borderRadius="md"
        px="8"
        py="6"
      >
        <Text fontSize={16} fontWeight="bold" mb={2}>
          送信ボタン
        </Text>
        <Text fontSize={14}>指定がない場合は「送信」と表示されます。</Text>
        <Flex
          alignItems="center"
          padding="10px 24px 0"
          marginTop={3}
          borderTop="1px solid #edf2f6"
        >
          <Text w={170}>ボタン表示名</Text>
          <InputForm<EnqueteSurveyForm>
            name="submitButtonText"
            type="text"
            placeholder="ボタン表示名を入力してください"
            backgroundColor="#f9f9f9"
            borderColor="#ccc"
            isDisabled={getValues('uneditableState')}
          />
        </Flex>
      </Box>
      <Box
        w="full"
        bg="#fff"
        borderWidth="1px"
        borderRadius="md"
        px="8"
        py="6"
      >
        <Text fontSize={16} fontWeight="bold" mb={3}>
          フォーム回答後に画面に表示する文言を入力してください。
        </Text>
        <RichTextElement<T>
          baseName={baseName}
          placeholder="例）フォームにご回答いただきありがとうございました。"
          option={['inline', 'colorPicker', 'fontSize']}
          index={-1}
          position="thanks"
          value={getValues('thanks')}
          setIsEdited={setIsEdited}
        />
      </Box>
      <Box
        w="full"
        bg="#fff"
        borderWidth="1px"
        borderRadius="md"
        px="8"
        py="6"
      >
        <Text fontSize={16} fontWeight="bold" mb={2}>
          フォーム回答後の画面に表示するボタン文言、遷移先URLを入力してください。
        </Text>
        <Text
          fontSize={14}
          paddingBottom={3}
          borderBottom="1px solid #edf2f6"
        >
          指定がない場合はモーダルを閉じるボタンが設置されます。
        </Text>
        <CloseBtnElement width={140} colspan={4} />
      </Box>
    </VStack>
  );
};
