import { zodResolver } from '@hookform/resolvers/zod';
import { withSuspenseAndErrorBoundary } from 'admin/components/Error/WithErrorBoundary';
import { AttributeGroupEditFormComponent } from 'admin/components/service/attribute/attributeGroupForm/attributeGroupEditForm/AttributeGroupEditFormComponent';
import { useUpdateServiceAttributeGroup } from 'admin/hooks/service/attribute/useUpdateServiceAttributeGroup';
import { serviceAttributeGroupFormSchema } from 'admin/schema/service/attribute';
import { ServiceAttributeGroupChildType } from 'admin/types/service/attribute';
import { ServiceAttributeGroupFormType } from 'admin/types/service/attribute/form';
import {
  AlertBar,
  ErrorContents,
  LoadingSpinner,
} from 'components/common/atoms';
import { FC, memo, useEffect, useState } from 'react';
import { FormProvider, SubmitHandler, useForm } from 'react-hook-form';

type Props = {
  attributeGroup: ServiceAttributeGroupChildType;
  onClose: () => void;
};

const attributeGroupEditForm: FC<Props> = memo(
  ({ attributeGroup, onClose }: Props) => {
    const [globalErrors, setGlobalErrors] = useState<string[]>([]);
    const formId = `serviceAttributeEditGroupForm_${attributeGroup.id}`;
    const methods = useForm<ServiceAttributeGroupFormType>({
      defaultValues: {
        userAttributeSetting: '',
        attributeGroupHead: '',
        isFormAttr: false,
        isUserAttr: false,
        isUserAttrRequired: false,
      },
      resolver: zodResolver(serviceAttributeGroupFormSchema),
    });

    const { setValue, setError } = methods;
    const { mutate, isLoading } = useUpdateServiceAttributeGroup(
      attributeGroup.id,
      setError,
      setGlobalErrors,
    );

    useEffect(() => {
      if (attributeGroup) {
        setValue('attributeGroupHead', attributeGroup.attributeGroupHead.id);
        setValue('isFormAttr', attributeGroup?.isFormAttr);
        setValue('isUserAttr', attributeGroup?.isUserAttr);
        setValue('isUserAttrRequired', attributeGroup?.isUserAttrRequired);
      }
    }, [setValue, attributeGroup]);

    const onSubmit: SubmitHandler<ServiceAttributeGroupFormType> = async (
      data: ServiceAttributeGroupFormType,
    ) => {
      await mutate(data);
      onClose();
    };

    return (
      <FormProvider {...methods}>
        {globalErrors.map((err, idx) => (
          <AlertBar
            key={`global-err-idx${String(idx)}`}
            message={err}
            status="error"
            mb={4}
          />
        ))}
        <AttributeGroupEditFormComponent
          attributeGroup={attributeGroup}
          formId={formId}
          isLoading={isLoading}
          onClose={onClose}
          onSubmit={onSubmit}
        />
      </FormProvider>
    );
  },
);

export const AttributeGroupEditForm = withSuspenseAndErrorBoundary(
  attributeGroupEditForm,
  {
    ErrorComponent: <ErrorContents name="組織レベル属性詳細" />,
    LoadingComponent: <LoadingSpinner />,
  },
);
