import { createCodeGroup } from 'admin/api/userPool/master/createCodeGroup';
import { CustomError } from 'admin/error/CustomError';
import { useCurrentPerPage } from 'admin/hooks/pagination/useCurrentPerPage';
import { CreateCodeGroupFormType } from 'admin/types/userPool/master/form';
import { Page } from 'api/common/types';
import { logger } from 'api/logger';
import { useCustomToast } from 'hooks/useCustomToast';
import { Dispatch, SetStateAction } from 'react';
import { UseFormSetError } from 'react-hook-form';
import { useMutation, useQueryClient } from 'react-query';
import { toMultiError } from 'utils/form';
import { errorToast, successToast } from 'utils/toast';
import { queryKeyNames, queryKeys } from './queryKeys';

export const useCreateCodeGroup = (
  setError: UseFormSetError<CreateCodeGroupFormType>,
  setGlobalErrors: Dispatch<SetStateAction<string[]>>,
  currentPage: Page['currentPage'],
) => {
  const perPage = useCurrentPerPage();
  const toast = useCustomToast();
  const queryClient = useQueryClient();

  const { mutateAsync, isLoading } = useMutation({
    mutationFn: (data: CreateCodeGroupFormType) => createCodeGroup(data),
    onError: async (error, variables: CreateCodeGroupFormType) => {
      if (error instanceof CustomError) {
        if (error.cause.errors?.others) {
          const othersMsgs = Array.isArray(error.cause.errors?.others)
            ? error.cause.errors?.others
            : [error.cause.errors?.others];

          setGlobalErrors(othersMsgs);
        } else {
          Object.keys(variables).forEach((k) => {
            const key = k as keyof CreateCodeGroupFormType;
            const errMsgs = error.cause.errors?.[key];
            if (errMsgs && Array.isArray(errMsgs)) {
              setError(key, { types: toMultiError(errMsgs) });
            }
          });
        }

        if (error.cause.errors) {
          await logger({
            loglevel: error.logLevel,
            data: JSON.stringify(error.cause),
            message: error.message,
          });
        }
      }
      toast({
        ...errorToast,
        duration: 4000,
        title: '選択肢マスタの作成に失敗しました',
        position: 'bottom',
        variant: 'solid',
      });
    },
    onSuccess: (response) => {
      void queryClient.invalidateQueries(queryKeyNames.getAllMasterSettings);
      void queryClient.invalidateQueries(
        queryKeys.getMasterSettings(response.userPool, currentPage, perPage),
      );
      toast({
        ...successToast,
        duration: 4000,
        title: '選択肢マスタを作成しました',
        position: 'bottom',
        variant: 'solid',
      });
    },
  });

  return { mutate: mutateAsync, isLoading };
};
