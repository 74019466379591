import { ExternalLinkIcon } from '@chakra-ui/icons';
import {
  Box, Icon, Image, Link, ListItem, OrderedList, Text, UnorderedList, useDisclosure
} from '@chakra-ui/react';
import { Dialog } from 'components/common/atoms';
import { memo, VFC } from 'react';
import { FiHelpCircle } from 'react-icons/fi';

type MailMergeDeliveryHelpType = {
  isDisabled?: boolean;
};

export const MailMergeDeliveryHelp: VFC<MailMergeDeliveryHelpType> = memo(
  ({ isDisabled = false }: MailMergeDeliveryHelpType) => {
    const { isOpen, onOpen, onClose } = useDisclosure();

    return (
      <Dialog
        title="差し込みメール配信について"
        isOpen={isOpen}
        onOpen={onOpen}
        onClose={onClose}
        openBtnChildNode={
          <>
            <Icon as={FiHelpCircle} fontSize={16} />
          </>
        }
        openBtnProps={{
          variant: 'ghost',
          color: '#536DB9',
          disabled: isDisabled,
          _hover: { bg: 'transparent' },
          _active: { bg: 'transparent' },
          _disabled: { pointerEvents: 'none' },
        }}
        submitBtnTitle="閉じる"
        submitBtnHandelr={onClose}
        submitBtnProps={{ colorScheme: 'gray' }}
        size="2xl"
      >
        <Box position="absolute" top="20px" left="300px">
          <Link
            href="https://uniikey.notion.site/1f8cbdbb67174c6d831eabe55e74c389"
            fontSize="md"
            fontWeight="bold"
            color="blue"
            isExternal
          >
            マニュアルページへ
            <ExternalLinkIcon mx="2px" />
          </Link>
        </Box>
        <UnorderedList maxH="400px" overflowY="auto" pl="1.5rem" pr="1rem">
          <ListItem mb={4}>
            <Text fontWeight="bold" mb={2}>
              差し込みメール配信とは
            </Text>
            <Box>
              <Text>
                メール本文や件名に事前に登録した情報を差し込める機能になります。
              </Text>
              <Text mb={2}>例）赤線の箇所を差し込んでいる場合</Text>
              <Box p={2} border="1px dashed #CCC" bg="#EEE">
                <Text mb={4}>
                  <Text
                    as="span"
                    textDecorationLine="underline"
                    textDecorationColor="red"
                    textUnderlineOffset="4px"
                    textDecorationThickness="2px"
                  >
                    鈴木太郎
                  </Text>
                  様
                </Text>
                <Text mb={4}>
                  平素より弊社サービスをご利用いただきまして、誠にありがとうございます。
                  <br />
                  この度は、ご応募いただいたキャンペーンの参加特典として、クーポンコードを配布いたします。
                </Text>
                <Text>▼クーポンコード</Text>
                <Text
                  textDecorationLine="underline"
                  textDecorationColor="red"
                  textUnderlineOffset="4px"
                  textDecorationThickness="2px"
                  mb={4}
                >
                  ABCD1234
                </Text>
                <Text>
                  ぜひこの機会に新商品をお試しいただけましたら幸いです。
                </Text>
              </Box>
            </Box>
          </ListItem>
          <ListItem mb={4}>
            <Text fontWeight="bold" mb={2}>
              設定方法
            </Text>
            <OrderedList>
              <ListItem mb={4}>
                <Text mb={1}>メール文言の設定</Text>
                <Text>
                  メール文言の中で差し込みたい箇所に、差し込み変数（例
                  %%name%%）をいれてください。%%で囲まれた間の文字は、任意に設定できます。
                </Text>
                <Text mb={2}>
                  ただし、利用可能文字種は半角英数字または_（アンダースコア）となります。
                </Text>
                <Box p={2} border="1px dashed #CCC" bg="#EEE">
                  <Text mb={4}>
                    <Text
                      as="span"
                      textDecorationLine="underline"
                      textDecorationColor="red"
                      textUnderlineOffset="4px"
                      textDecorationThickness="2px"
                    >
                      %%name%%
                    </Text>
                    様
                  </Text>
                  <Text mb={4}>
                    平素より弊社サービスをご利用いただきまして、誠にありがとうございます。
                    <br />
                    この度は、ご応募いただいたキャンペーンの参加特典として、クーポンコードを配布いたします。
                  </Text>
                  <Text>▼クーポンコード</Text>
                  <Text
                    textDecorationLine="underline"
                    textDecorationColor="red"
                    textUnderlineOffset="4px"
                    textDecorationThickness="2px"
                    mb={4}
                  >
                    %%coupon_code%%
                  </Text>
                  <Text>
                    ぜひこの機会に新商品をお試しいただけましたら幸いです。
                  </Text>
                </Box>
              </ListItem>
              <ListItem mb={4}>
                <Text mb={1}>差し込む内容の設定</Text>
                <Text>
                  差し込む内容は外部ファイルを読み込み設定してください。
                </Text>
                <Text>
                  外部ファイルは、以下のようなCSVファイルを作成してください。
                </Text>
                <Text>CSVファイルの仕様は、以下でお願いします。</Text>
                <Text pl={4}>
                  文字コード：Windows Shift
                  JIS（シフトJIS）およびUTF-8のどちらか
                </Text>
                <Text pl={4} mb={2}>
                  ファイルサイズ：32MBまで
                </Text>
                <Box border="1px solid #CCC">
                  <Image
                    boxSize="100%"
                    objectFit="contain"
                    src="/static/mail_merge_delivery_help_image.png"
                    alt="mail_merge_delivery_help_image.png"
                  />
                </Box>
              </ListItem>
            </OrderedList>
          </ListItem>
        </UnorderedList>
      </Dialog>
    );
  },
);
