import { useQuery } from 'react-query';
import { Skin } from 'api/enquete/types';
import { getEnqueteSkinId } from 'api/enquete/getEnqueteSkinId';
import { useCustomToast } from 'hooks/useCustomToast';
import { useUserTenantId } from 'hooks/user/useUserTenantId';
import { SkinDefaultValues } from 'components/enquete/EnqueteSkin';
import { queryEnqueteKey } from './queryEnqueteKey';

const defaultValues: Skin = SkinDefaultValues;

/**
 * デザインスキン詳細取得 hooks
 */
export const useEnqueteSkinDetail = ({ skinId }: { skinId: string }): Skin => {
  const toast = useCustomToast();
  const tenantId = useUserTenantId();

  const response = useQuery(
    queryEnqueteKey.getEnqueteSkinId({ tenantId, skinId }),
    () => getEnqueteSkinId(tenantId, skinId),
    {
      cacheTime: 0,
      onError: (err: unknown) => {
        if (err instanceof Error) {
          toast.closeAll();
          toast({
            title: err.message,
            status: 'error',
          });
        }
      },
    },
  );
  const { data: skinDetail } = response;

  return skinDetail || defaultValues;
};
