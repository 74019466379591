import {
  Button,
  Drawer,
  DrawerCloseButton,
  DrawerContent,
  DrawerFooter,
  DrawerHeader,
  DrawerOverlay,
  Text
} from '@chakra-ui/react';
import { MasterEditForm } from 'admin/components/idPoolConsumer/master/masterForm';
import { ColorSet } from 'admin/define/colors';
import { FC, memo } from 'react';

type MasterEditDrawerProps = {
  onClose: () => void;
  formId: string;
  isOpen: boolean;
  masterSettingId: string;
  currentPage: number;
};

export const MasterEditDrawer: FC<MasterEditDrawerProps> = memo(
  ({ onClose, formId, isOpen, masterSettingId, currentPage }) => (
    <Drawer
      isOpen={isOpen}
      placement="right"
      onClose={onClose}
      size="md"
      closeOnOverlayClick={false}
      closeOnEsc={false}
      autoFocus={false}
    >
      <DrawerOverlay />
      <DrawerContent>
        <DrawerHeader borderWidth="1px" pb="24px">
          <Text>選択肢マスタ編集</Text>
        </DrawerHeader>
        <MasterEditForm
          masterSettingId={masterSettingId}
          formId={formId}
          onClose={onClose}
          currentPage={currentPage}
        />
        <DrawerFooter>
          <Button
            variant="outline"
            mr={3}
            onClick={onClose}
            p="16px 24px"
            w="120px"
            {...ColorSet.Default}
          >
            キャンセル
          </Button>
          <Button
            type="submit"
            form={formId}
            p="16px 24px"
            w="120px"
            colorScheme="primary"
          >
            保存
          </Button>
        </DrawerFooter>
        <DrawerCloseButton />
      </DrawerContent>
    </Drawer>
  ),
);
