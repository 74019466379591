import {
  Box,
  Button,
  DrawerBody,
  DrawerFooter,
  FormControl,
  FormErrorMessage,
  Heading,
  VStack,
} from '@chakra-ui/react';
import { RhfInput } from 'admin/components/form/RhfInput';
import { RhfTextarea } from 'admin/components/form/RhfTextarea';
import { HelpTooltip } from 'admin/components/ui/helpTooltip';
import { RequiredIcon } from 'admin/components/ui/icon/requiredIcon';
import { LoadingLayer } from 'admin/components/ui/loadingLayer';
import { Colors, ColorSet } from 'admin/define/colors';
import { NewsletterFormType } from 'admin/types/userPool/newsletter/form';
import { ChangeEvent, FC, memo } from 'react';
import { useFormContext, useFormState } from 'react-hook-form';
import { toErrMsgList } from 'utils/form';

type Props = {
  formId: string;
  isLoading: boolean;
  onClose: () => void;
  onSubmit: (value: NewsletterFormType) => void;
  status: boolean;
};

export const NewsletterEditFormComponent: FC<Props> = memo(
  ({ formId, isLoading, onClose, onSubmit, status }: Props) => {
    const { handleSubmit, clearErrors } = useFormContext<NewsletterFormType>();
    const { errors } = useFormState<NewsletterFormType>();

    const handleOnBlur = (e: ChangeEvent<HTMLInputElement>) => {
      if (e.target.value !== '') {
        clearErrors(['displayNameJa', 'displayNameForeign']);
      }
    };

    return (
      <>
        {isLoading && <LoadingLayer />}
        <DrawerBody>
          <form onSubmit={handleSubmit(onSubmit)} id={formId}>
            <VStack spacing={6}>
              <Box w="100%">
                <Heading as="h3" display="flex" size="sm" mb={2}>
                  表示名
                </Heading>
                <Box bg="#F7FAFC" p={4} borderWidth="1px" borderRadius="4px">
                  <Box w="100%">
                    <Heading as="h3" display="flex" fontSize="14px" mb={2}>
                      日本語
                      <RequiredIcon ml="8px" />
                    </Heading>
                    <Box>
                      <FormControl
                        isInvalid={
                          Boolean(errors.displayNameJa?.message) ||
                          toErrMsgList(errors, 'displayNameJa').length > 0
                        }
                      >
                        <Box>
                          <RhfInput<NewsletterFormType>
                            name="displayNameJa"
                            bgColor={Colors.WHITE}
                            placeholder="例：メールアドレス"
                            onBlur={handleOnBlur}
                          />
                        </Box>

                        {toErrMsgList(errors, 'displayNameJa').map((err) => (
                          <FormErrorMessage key={`displayNameJa${err}`}>
                            {err}
                          </FormErrorMessage>
                        ))}
                      </FormControl>
                    </Box>
                  </Box>
                  <Box w="100%" mt={4}>
                    <Heading as="h3" fontSize="14px" mb={2}>
                      外国語
                    </Heading>
                    <Box>
                      <FormControl
                        isInvalid={
                          Boolean(errors.displayNameForeign?.message) ||
                          toErrMsgList(errors, 'displayNameForeign').length > 0
                        }
                      >
                        <Box>
                          <RhfInput<NewsletterFormType>
                            name="displayNameForeign"
                            bgColor={Colors.WHITE}
                            placeholder="例：Email address"
                            onBlur={handleOnBlur}
                          />
                        </Box>
                        {toErrMsgList(errors, 'displayNameForeign').map(
                          (err) => (
                            <FormErrorMessage key={`displayNameForeign${err}`}>
                              {err}
                            </FormErrorMessage>
                          ),
                        )}
                      </FormControl>
                    </Box>
                  </Box>
                </Box>
              </Box>
              <Box w="100%">
                <Heading
                  as="h3"
                  display="flex"
                  alignItems="center"
                  size="sm"
                  mb={2}
                >
                  フィールド名
                  <HelpTooltip label="この項目はデータベース上でのフィールド名です。" />
                  <RequiredIcon ml="8px" />
                </Heading>
                <Box>
                  <FormControl
                    isInvalid={
                      Boolean(errors.fieldName?.message) ||
                      toErrMsgList(errors, 'fieldName').length > 0
                    }
                  >
                    <RhfInput<NewsletterFormType>
                      name="fieldName"
                      placeholder="例：email"
                      bgColor={Colors.WHITE}
                      isDisabled={status}
                    />
                    {toErrMsgList(errors, 'fieldName').map((err) => (
                      <FormErrorMessage key={`fieldName${err}`}>
                        {err}
                      </FormErrorMessage>
                    ))}
                  </FormControl>
                </Box>
              </Box>
              <Box w="100%">
                <Heading
                  as="h3"
                  display="flex"
                  alignItems="center"
                  size="sm"
                  mb={2}
                >
                  並び順
                </Heading>
                <Box>
                  <FormControl
                    isInvalid={
                      Boolean(errors.displayOrder?.message) ||
                      toErrMsgList(errors, 'displayOrder').length > 0
                    }
                  >
                    <RhfInput<NewsletterFormType>
                      name="displayOrder"
                      bgColor={Colors.WHITE}
                    />
                    {toErrMsgList(errors, 'displayOrder').map((err) => (
                      <FormErrorMessage key={`displayOrder${err}`}>
                        {err}
                      </FormErrorMessage>
                    ))}
                  </FormControl>
                </Box>
              </Box>
              <Box w="100%">
                <Box display="flex" justifyContent="space-between" mb="8px">
                  <Heading as="h3" size="sm" mb="4px">
                    メモ
                  </Heading>
                </Box>
                <FormControl isInvalid={Boolean(errors.memo)}>
                  <Box>
                    <RhfTextarea
                      name="memo"
                      border="1px solid red"
                      borderRadius="md"
                      bgColor={Colors.WHITE}
                      size="sm"
                      rows={12}
                    />
                  </Box>
                  {toErrMsgList(errors, 'memo').map((err) => (
                    <FormErrorMessage key={`memo${err}`}>
                      {err}
                    </FormErrorMessage>
                  ))}
                </FormControl>
              </Box>
            </VStack>
          </form>
        </DrawerBody>
        <DrawerFooter borderWidth="1px">
          <Button
            variant="outline"
            mr={3}
            p="16px 24px"
            w="120px"
            onClick={onClose}
            {...ColorSet.Default}
          >
            キャンセル
          </Button>
          <Button
            type="submit"
            form={formId}
            p="16px 24px"
            w="120px"
            colorScheme="primary"
            isLoading={isLoading}
          >
            保存
          </Button>
        </DrawerFooter>
      </>
    );
  },
);
