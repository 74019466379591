import { WallFormDefaultValues } from 'components/paywall/pages/WallList/initial';
import { WallFormType } from 'components/paywall/pages/WallList/typed';
import { useAddWall } from 'hooks/paywall/useAddWall';
import { useEditWall } from 'hooks/paywall/useEditWall';
import { usePaywallPublish } from 'hooks/paywall/usePaywallPublish';
import { useWallList } from 'hooks/paywall/useWallList';
import { useUserTenantId } from 'hooks/user/useUserTenantId';
import { Dispatch, SetStateAction, useCallback, useEffect, useState } from 'react';
import { UseFormGetValues, UseFormSetError } from 'react-hook-form';
import { useParams } from 'react-router-dom';

type Props = {
  getValues?: UseFormGetValues<WallFormType>;
  isAdd?: boolean;
  setError?: UseFormSetError<WallFormType>;
  onClose?: () => void;
  reset?: () => void;
  setLoading?: Dispatch<SetStateAction<boolean>>;
};

/**
 * ペイウォールhooks
 * @param {UseFormGetValues<WallFormType>} getValues WallFormType型 react query getValues
 * @param {boolean} isAdd 新規作成or編集
 */
export const usePaywall = ({
  getValues,
  isAdd,
  setError,
  onClose,
  reset,
  setLoading,
}: Props) => {
  const [wallName, setWallName] = useState('');
  const [paywallMenuName, setPaywallMenuName] = useState('');
  const [wallId, setWallId] = useState('');
  const [currentWallType, setCurrentWallType] = useState('');
  const [parentPath, setParentpath] = useState('');
  const tenantId = useUserTenantId();
  const params = useParams();
  const {wallList, childWallList} = useWallList({ wallType: 'all' });
  const { onSubmit: addWallSubmit, isLoading: isAddLoading } = useAddWall({
    setError,
    close: onClose,
    reset,
  });
  const { onSubmit: editWallSubmit, isLoading: isEditLoading } = useEditWall({
    setError,
    close: onClose,
    reset,
  });
  const { onSubmit: publishSubmit } = usePaywallPublish();

  const getParentPath = (wallType: string): string => {
    let path = '';
    if(wallType === 'course') {
      path = '/paywall/wallList';
    }else if(wallType === 'item') {
      path = '/paywall/itemList';
    }else {
      path = '/paywall/membersOnlyWallSetting';
    }

    return path;
  }

  const getPaywallMenuName = (wallType: string): string => {
    let name = '';
    if(wallType === 'course') {
      name = 'サブスクリプション商品設定';
    }else if(wallType === 'item') {
      name = '単品商品設定';
    }else {
      name = '会員限定ウォール設定';
    }

    return name;
  }

  useEffect(() => {
    const target = [...wallList, ...childWallList]?.filter((x) => x.id === params.wallId);
    if (target && target?.length > 0) {
      // ウォール名セット
      setWallName(target[0].name);
      // ウォールIDセット
      setWallId(params.wallId || '');
      // ウォールタイプセット
      setCurrentWallType(target[0].wallType);
      // パンくず用一つ上の階層へ戻るパス
      setParentpath(getParentPath(target[0].wallType));
      // ペイウォールメニュー名セット
      setPaywallMenuName(getPaywallMenuName(target[0].wallType));
    }
  }, [wallList, childWallList, params]);

  useEffect(() => {
    setLoading?.(isAddLoading || isEditLoading)
  }, [setLoading, isAddLoading, isEditLoading])

  /**
   * ウォール新規作成：編集
   * @param {function(): void} onClose close Function
   * @param {function(): void} reset reset Function
   */
  const saveWall = useCallback(
    ({ wallType }: { wallType: 'course' | 'item' | 'member' }) => {
      setLoading?.(true);
      const fdata: WallFormType = {
        ...(getValues?.() || WallFormDefaultValues),
        wallType,
        status: true,
      };
      // 新規作成時
      if (isAdd) {
        addWallSubmit({
          tenantId,
          wallForm: fdata,
        });
      } else {
        editWallSubmit({
          tenantId,
          wallForm: fdata,
        });
      }
    },
    [getValues, addWallSubmit, editWallSubmit, setLoading, isAdd, tenantId],
  );

  /**
   * ステータス変更ダイアログ active化処理
   * @param {string} id 該当ID
   * @param {boolean} status ステータス
   * @param {function(): void} callback close Function
   * @param {function(): void} close close Function
   */
  const activateWall = useCallback(
    ({
      id,
      status,
      callback,
    }: {
      id: string;
      status: boolean;
      callback: (val: string, bool: boolean) => void;
    }) => {
      callback(id, status);
      onClose?.();
    },
    [onClose],
  );

  const onDrawerClose = useCallback(() => {
    onClose?.();
    reset?.();
  }, [onClose, reset]);

  /**
   * ステータス変更処理
   * @param {string} id 該当ID
   * @param {boolean} status ステータス
   */
  const onActivate = useCallback(
    (id: string, status: boolean) => {
      publishSubmit({
        id,
        tenantId,
        status: !status,
      });
    },
    [publishSubmit, tenantId],
  );

  return {
    wallName,
    paywallMenuName,
    wallId,
    currentWallType,
    parentPath,
    saveWall,
    activateWall,
    onDrawerClose,
    onActivate,
  };
};
