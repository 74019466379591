import {
  headTitleOptions,
  buttonNameOptions,
  screenNameOptions,
  screenDescriptionOptions,
  textareaElementOptions,
} from './options';

const groupName = 'withdrawalComplete';

// 退会確認画面
export default [
  // ロゴ	画像
  {
    name: `${groupName}.headTitle`,
    label: 'ブラウザタブ表示名',
    tooltip: '',
    type: 'Text',
    defaultValue: '退会完了',
    options: headTitleOptions,
    elementOptions: {},
  },
  {
    name: `${groupName}.screenName`,
    label: '画面名',
    tooltip: '',
    type: 'Text',
    defaultValue: '退会完了',
    options: screenNameOptions,
    elementOptions: {},
  },
  {
    name: `${groupName}.screenDescription`,
    label: '案内文 画面',
    tooltip: '',
    type: 'Textarea',
    defaultValue:
      '退会が完了いたしました。\n ご利用いただきありがとうございました。',
    options: screenDescriptionOptions,
    elementOptions: textareaElementOptions,
  },
  {
    name: `${groupName}.backButtonName`,
    label: 'ボタン名 TOPページに戻る',
    tooltip: '',
    type: 'Text',
    defaultValue: 'TOPページに戻る',
    options: buttonNameOptions,
    elementOptions: {},
  },
] as const;
