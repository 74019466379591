import { Box, Tr as ChakraTr } from '@chakra-ui/react';
import { ServiceTableBody } from 'admin/components/member/service/serviceTableBody';
import { Table, Tbody, Th, Thead } from 'admin/components/ui/table';
import { ServiceMembersType } from 'admin/types/service';
import { FC, memo } from 'react';

type Props = {
  members: ServiceMembersType;
};

export const ServiceMembersTableComponent: FC<Props> = memo(
  ({ members }: Props) => (
    <Box>
      <Table tableHeight="100%">
        <Thead>
          <ChakraTr>
            <Th>会員ID</Th>
            <Th>メールアドレス</Th>
            <Th>電話番号</Th>
            <Th>登録日時</Th>
            <Th>最終ログイン日時</Th>
            <Th>ステータス</Th>
          </ChakraTr>
        </Thead>
        <Tbody>
          {members.map((member, idx) => (
            <ServiceTableBody
              member={member}
              key={`service_member_${String(idx)}`}
            />
          ))}
        </Tbody>
      </Table>
    </Box>
  ),
);
