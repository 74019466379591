import { isPage, Page } from 'api/common/types';

/**
 * 単品商品一覧情報 Type
 */
export type WallItemListType = {
  results: WallItem[];
} & Page;

/**
 * 単品商品データ型
 */
export type WallItem = {
  id: string;
  wall: string;
  wallName: string;
  wallDesignId: string;
  name: string;
  url: string;
  price: number;
  sellType: WallItemSellTypeEnum;
  period: number | null;
  sellStart: string | null;
  sellEnd: string | null;
  systemCode: string;
  description: string;
  thumbnailUrl: string;
  useOgpFlag: boolean;
  tags: string;
  memo: string;
  isCheckSkipUrl: boolean;
  createdAt: string;
  updatedAt: string;
  sellStatus: number;
};

export type WallItemSellTypeEnum = 'period' | 'indefinite';

export type WallItemFormType = {
  id?: string;
  wall: string;
  wallName: string;
  wallDesignId: string;
  name: string;
  url: string;
  price: number;
  sellType: WallItemSellTypeEnum;
  period: number | null;
  sellStart: string | null;
  sellEnd: string | null;
  systemCode: string;
  description: string;
  thumbnailUrl: string;
  useOgpFlag: boolean;
  tags: string;
  memo: string;
  isCheckSkipUrl: boolean;
};

export type WallItemSubmitFormType = {
  tenantId: string;
  wallId: string;
  wallItemForm: WallItemFormType;
};

export const isWallItem = (data: unknown): data is WallItem => {
  const dt = data as WallItem;

  return (
    typeof dt?.id === 'string' &&
    typeof dt?.wall === 'string' &&
    typeof dt?.wallName === 'string' &&
    typeof dt?.name === 'string' &&
    typeof dt?.url === 'string' &&
    typeof dt?.price === 'number' &&
    typeof dt?.sellType === 'string' &&
    (dt?.period !== null ? typeof dt?.period === 'number' : true) &&
    (dt?.sellStart !== null ? typeof dt?.sellStart === 'string' : true) &&
    (dt?.sellEnd !== null ? typeof dt?.sellEnd === 'string' : true) &&
    typeof dt?.systemCode === 'string' &&
    (dt?.description !== null ? typeof dt?.description === 'string' : true) &&
    typeof dt?.thumbnailUrl === 'string' &&
    typeof dt?.useOgpFlag === 'boolean' &&
    (dt?.tags !== null ? typeof dt?.tags === 'string' : true) &&
    typeof dt?.memo === 'string' &&
    typeof dt?.sellStatus === 'number' &&
    (dt?.createdAt !== null ? typeof dt?.createdAt === 'string' : true) &&
    (dt?.updatedAt !== null ? typeof dt?.updatedAt === 'string' : true)
  );
};

export const isWallItemList = (data: unknown): data is WallItemListType => {
  const dt = data as WallItemListType;

  return isPage(dt) && dt?.results.length !== 0
    ? dt?.results.some((d) => isWallItem(d))
    : true;
};
