import {
  Box,
  Button, FormControl,
  FormErrorMessage, HStack, ModalBody,
  ModalFooter,
  Text
} from '@chakra-ui/react';
import { RhfInput } from 'admin/components/form/RhfInput';
import { Colors, ColorSet } from 'admin/define/colors';
import { LoginSettingConfirmType } from 'api/tenant/types';
import { FC, memo } from 'react';
import { useFormContext, useFormState } from 'react-hook-form';

type Props = {
  isLoading: boolean;
  formId: string;
  onSubmit: () => void,
  onClose: () => void;
}

export const LoginRedirectModalComponent: FC<Props> = memo(
  ({
    isLoading,
    formId,
    onSubmit,
    onClose
  }: Props) => {
  const { handleSubmit } = useFormContext<LoginSettingConfirmType>();
  const {
    errors,
    isValid
  } = useFormState<LoginSettingConfirmType>();

  return (

    <>
      <ModalBody maxHeight="500px" overflowY="auto">
        <form onSubmit={handleSubmit(onSubmit)} id={formId}>
          <Box
            p="4"
            mb="4"
            bgColor="#FEF5F5"
            borderRadius={8}
            color={Colors.RED_20}
          >
            <Text
              fontWeight="bold"
              fontSize="sm"
              display="flex"
              alignItems="center"
            >
              ・変更すると、即時に切り替わります。
            </Text>
            <Text
              fontWeight="bold"
              fontSize="sm"
              display="flex"
              alignItems="center"
            >
              ・影響範囲を十分に確認した上で行ってください。
            </Text>
          </Box>
          <Box mt="32px">
            <Text mb={1} fontSize="sm" fontWeight="bold">
              実行する場合は「confirm」と入力後に「実行ボタン」を押下してください。
            </Text>
            <FormControl isInvalid={Boolean(errors.word?.message)}>
              <RhfInput<LoginSettingConfirmType> name="word" type="text" />
              <FormErrorMessage>{errors.word?.message}</FormErrorMessage>
            </FormControl>
          </Box>
        </form>
      </ModalBody>
      <ModalFooter>
        <HStack spacing={4}>
          <Button
            variant="solid"
            onClick={() => onClose()}
            {...ColorSet.Default}>
            キャンセル
          </Button>
          <Button
            mr={3}
            disabled={!isValid || isLoading}
            onClick={() => onSubmit()}
            {...ColorSet.Warning}
          >
            実行
          </Button>
        </HStack>
      </ModalFooter>
    </>
  );
});
