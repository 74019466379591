import {
  FormControl,
  FormLabel,
  forwardRef,
  Switch as ChakraSwitch,
  SwitchProps,
} from '@chakra-ui/react';
import styled from '@emotion/styled';
import { ChangeEvent } from 'react';
import { Ref } from 'react-hook-form';

const StyledFormLabel = styled(FormLabel)({
  padding: 0,
  margin: 0,
  '&:hover': {
    cursor: 'pointer',
  },
});

type Props = {
  hasLabel?: boolean;
  id?: string;
  fontSize?: string | number;
  color?: string;
  defaultChecked?: boolean;
  activeLabel?: string;
  inactiveLabel?: string;
  children?: React.ReactNode;
  onChange?: (e?: ChangeEvent<HTMLInputElement>) => void;
  ref?: Ref;
} & Omit<SwitchProps, ''>;

/**
 * @see 汎用スイッチ
 * @param hasLabel ラベルの有無
 * @param id ラベル用のid
 * @param fontSize ラベルのフォントサイズ
 * @param defaultChecked デフォルト時のチェック状態
 * @param activeLabel チェック時のラベルテキスト
 * @param inactiveLabel チェックOff時のラベルテキスト
 * @param children 変化なしのラベルテキスト
 * @param onChange ボタンクリックのイベントハンドラ
 * @param ref ReactHookFormのregisterへのref
 */
export const Switch = forwardRef<Props, 'switch'>(
  (
    {
      hasLabel,
      id,
      fontSize = 14,
      color = '#444',
      activeLabel = '有効',
      inactiveLabel = '無効',
      children,
      isChecked,
      ...props
    }: Omit<Props, 'ref'>,
    ref,
  ) => (
    <>
      {hasLabel && (
        <FormControl style={{ display: 'flex', fontSize, gap: 5 }}>
          <ChakraSwitch
            id={id || ''}
            isChecked={isChecked}
            {...props}
            {...ref}
          />
          <StyledFormLabel htmlFor={id || ''} style={{ fontSize, color }}>
            {children || (isChecked ? activeLabel : inactiveLabel)}
          </StyledFormLabel>
        </FormControl>
      )}
      {!hasLabel && <ChakraSwitch isChecked={isChecked} {...props} {...ref} />}
    </>
  ),
);
