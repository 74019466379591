import { useQuery } from 'react-query';
import { getMailMagazineId } from 'api/mail/getMailMagazineId';
import { MailMagazine } from 'components/setting/mailMagazine/typed';
import { useCustomToast } from 'hooks/useCustomToast';
import { useUserTenantId } from 'hooks/user/useUserTenantId';
import { queryMailKey } from './queryMailKey';

/**
 * メールマガジン詳細取得 hooks
 * @param {string} id メールマガジンID
 * @return {MailMagazine} メールマガジン
 */
export const useMailMagazineId = ({
  id,
  enabled = true,
}: {
  id: string;
  enabled?: boolean;
}): MailMagazine | undefined => {
  const toast = useCustomToast();
  const tenantId = useUserTenantId();

  const response = useQuery(
    queryMailKey.getMailMagazineId({ id, tenantId }),
    () => getMailMagazineId(id, tenantId),
    {
      enabled,
      cacheTime: 0,
      onError: (err: unknown) => {
        if (err instanceof Error) {
          toast.closeAll();
          toast({
            title: err.message,
            status: 'error',
          });
        }
      },
    },
  );
  if (!enabled) return null as unknown as MailMagazine;

  const { data } = response;

  return data as MailMagazine;
};
