import { AlertDialog, useDisclosure } from '@chakra-ui/react';
import { withSuspenseAndErrorBoundary } from 'admin/components/Error/WithErrorBoundary';
import { CallbackUrlConfirmDialog } from 'admin/components/service/auth/callbackUrlConfirmDialog';
import { CallbackUrlSettingFormComponent } from 'admin/components/service/auth/callbackUrlSettingForm/CallbackUrlSettingFormComponent';
import { useUpdateCallbackUrl } from 'admin/hooks/service/auth/useUpdateCallbackUrl';
import { authFormSchema } from 'admin/schema/service/auth';
import { AuthType } from 'admin/types/service/auth';
import { AuthFormInputType } from 'admin/types/service/auth/form';
import {
  AlertBar,
  ErrorContents,
  LoadingSpinner,
} from 'components/common/atoms';
import { FC, memo, useEffect, useRef, useState } from 'react';
import { FormProvider, useForm } from 'react-hook-form';

type Props = {
  authDetail: AuthType;
  onClose: () => void;
};

const callbackUrlSettingForm: FC<Props> = memo(
  ({ authDetail, onClose }: Props) => {
    const [globalErrors, setGlobalErrors] = useState<string[]>([]);
    const formId = 'callbackUrlSettingForm';
    const methods = useForm<AuthFormInputType>({
      defaultValues: {
        redirectUri: '',
      },
    });
    const cancelRef = useRef(null);

    const { setValue, getValues, setError } = methods;
    const { mutate, isLoading } = useUpdateCallbackUrl(
      setError,
      setGlobalErrors,
    );

    useEffect(() => {
      setValue('redirectUri', authDetail.redirectUri.join('\n') ?? '');
    }, [setValue, authDetail]);

    const onSubmit = async (values: AuthFormInputType) => {
      await mutate(authFormSchema.parse(values));
      onClose();
    };

    const {
      isOpen: isDialogOpen,
      onClose: onDialogClose,
      onOpen: onDialogOpen,
    } = useDisclosure();

    return (
      <>
        <FormProvider {...methods}>
          {globalErrors.map((err, idx) => (
            <AlertBar
              key={`global-err-idx${String(idx)}`}
              message={err}
              status="error"
              mb={4}
            />
          ))}
          <CallbackUrlSettingFormComponent
            onClose={onClose}
            isLoading={isLoading}
            formId={formId}
            onDialogOpen={onDialogOpen}
          />
        </FormProvider>
        <AlertDialog
          isOpen={isDialogOpen}
          onClose={onDialogClose}
          leastDestructiveRef={cancelRef}
          isCentered
          closeOnOverlayClick={false}
          closeOnEsc={false}
          size="xl"
        >
          <CallbackUrlConfirmDialog
            onClose={onDialogClose}
            target={getValues()}
            onSubmit={onSubmit}
          />
        </AlertDialog>
      </>
    );
  },
);

export const CallbackUrlSettingForm = memo(
  withSuspenseAndErrorBoundary(callbackUrlSettingForm, {
    ErrorComponent: <ErrorContents name="コールバックURL設定" />,
    LoadingComponent: <LoadingSpinner />,
  }),
);
