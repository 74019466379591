/* eslint-disable react/require-default-props */
import {
  BoxProps, Td as ChakraTd
} from '@chakra-ui/react';
import { useScrollContext } from 'components/paywall/features/Table/TableContainer';
import { FC, memo, useEffect, useState } from 'react';


type Props = {
  children?: React.ReactNode
  columnFix?: boolean;
} & BoxProps;

export const Td: FC<Props> = memo(({children, columnFix = false, ...rest}: Props) => {
  const [tdStyle, setTdStyle] = useState<React.CSSProperties>({});
  const { scrollLeftValue } = useScrollContext();

  useEffect(() => {
    if(columnFix) {
      setTdStyle({
        position: "sticky",
        left: "0",
        filter: scrollLeftValue === 0 ? "none" : "drop-shadow(.0625rem 0 0 #dde0e4)"
      });
    }
  }, [columnFix, scrollLeftValue]);

  return (
    <ChakraTd
      px="16px"
      py="14px"
      fontSize="12px"
      fontWeight="400"
      whiteSpace="normal"
      wordBreak="break-word"
      bgColor='inherit'
      style={tdStyle}
      {...rest}
    >
      {children}
    </ChakraTd>
  )
})
