import { getFormHosting } from 'api/tenant/getFormHosting';
import { FormHostingType } from 'api/tenant/types';
import { formHostingTypeDefaultData } from 'components/setting/formSetting/initial';
import { useCustomToast } from 'hooks/useCustomToast';
import { useQuery } from 'react-query';
import { queryTenantKey } from './queryTenantKey';

/**
 * フォーム関連設定情報取得 hooks
 */
export const useFormHostingInfo = ({
  tenantId,
}: {
  tenantId: string;
}): FormHostingType => {
  const toast = useCustomToast();
  const response = useQuery(
    queryTenantKey.formHosting,
    () => getFormHosting(tenantId),
    {
      onError: (err: unknown) => {
        if (err instanceof Error) {
          toast.closeAll();
          toast({
            title: err.message,
            status: 'error',
          });
        }
      },
    },
  );
  const { data: formHostingData } = response;

  return formHostingData || formHostingTypeDefaultData;
};
