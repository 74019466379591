import { Box, Input, InputProps } from '@chakra-ui/react';
import ja from 'date-fns/locale/ja';
import { forwardRef, memo, VFC } from 'react';
import ReactDatePicker, { registerLocale } from 'react-datepicker';
import 'react-datepicker/dist/react-datepicker.css';

registerLocale('ja', ja);

const CustomInput = forwardRef<HTMLInputElement, InputProps>(
  (props: InputProps, ref) => (
    <Input
      size="xs"
      w={40}
      borderRadius={5}
      px={2}
      py={4}
      fontSize={16}
      {...props}
      ref={ref}
    />
  ),
);

type DatePickerProps = {
  startDate?: Date | null;
  endDate?: Date | null;
  showTimeSelect?: boolean;
  filterTime?: boolean;
  filterIntervalTime?: number | null;
  minDate?: Date;
  maxDate?: Date;
  dateFormat?: string;
  onChange: (date: Date) => void;
} & Pick<InputProps, 'width' | 'isDisabled'>;

export const DatePicker: VFC<DatePickerProps> = memo((props) => {
  const {
    startDate,
    endDate,
    showTimeSelect = false,
    filterTime = false,
    filterIntervalTime = null,
    minDate = null,
    maxDate = null,
    onChange,
    dateFormat = 'yyyy-MM-dd HH:mm',
    width,
    isDisabled = false,
  } = props;

  const filterPassedTime = filterTime
    ? (time: Date) => {
        const currentDate = new Date();
        if (filterIntervalTime !== null) {
          currentDate.setMinutes(currentDate.getMinutes() + filterIntervalTime);
        }
        const selectedDate = new Date(time);

        return currentDate.getTime() < selectedDate.getTime();
      }
    : undefined;

  return (
    <Box w={width}>
      <ReactDatePicker
        selected={startDate || endDate}
        onChange={onChange}
        showTimeSelect={showTimeSelect}
        minDate={minDate}
        maxDate={maxDate}
        timeCaption="時刻"
        customInput={<CustomInput />}
        filterTime={filterPassedTime}
        dateFormat={dateFormat}
        locale="ja"
        disabled={isDisabled}
      />
    </Box>
  );
});
