import {
  Box,
  Button,
  Heading,
  Popover,
  PopoverBody,
  PopoverContent,
  PopoverTrigger,
  Stack,
  Text,
  Textarea,
} from '@chakra-ui/react';
import {
  FirstLoginMailFormType,
  SendFirstLoginTestMailFormType,
} from 'api/transactionMail/type';
import { Colors } from 'components/paywall/config';
import { useSendTestMail } from 'hooks/transactionMail/useSendTestMail';
import { useCustomToast } from 'hooks/useCustomToast';
import { useEmailEditor } from 'hooks/useEmailEditor';
import { FC, memo, useCallback, useState } from 'react';
import EmailEditor from 'react-email-editor';
import { FormProvider, useForm, useFormContext } from 'react-hook-form';
import { errorToast } from 'utils/toast';

type TestMailPopoverProps = {
  hasFromAddress: boolean;
  editorRef: React.RefObject<EmailEditor>;
  isOpen: boolean;
  onOpen: () => void;
  onClose: () => void;
};

export const TestMailPopover: FC<TestMailPopoverProps> = memo(
  ({
    hasFromAddress,
    editorRef,
    isOpen,
    onOpen,
    onClose,
  }: TestMailPopoverProps) => {
    const [mailAddress, setMailAddress] = useState('');
    const { exportHtml } = useEmailEditor({ editor: editorRef });
    const { getValues: getMailFormValues } =
      useFormContext<FirstLoginMailFormType>();

    const toast = useCustomToast();

    const methods = useForm<SendFirstLoginTestMailFormType>({
      mode: 'onBlur',
      // エラーのある入力が再度バリデーションされるタイミングを変更(default: onChange)
      reValidateMode: 'onBlur',
      defaultValues: {
        subject: '',
        bodyHtml: '',
        bodyText: '',
        type: 'startService',
        toAddress: '',
      },
    });
    const { setError } = methods;

    const { mutate, isLoading } = useSendTestMail(setError);

    // ポップアップOpen
    const onOpenHandler = useCallback(() => {
      onOpen();
    }, [onOpen]);

    // テストメール送信
    const onSendMail = useCallback(async () => {
      const submitData: SendFirstLoginTestMailFormType = {
        bodyHtml: '',
        bodyText: getMailFormValues('bodyText'),
        subject: getMailFormValues('subject'),
        type: getMailFormValues('type'),
        toAddress: mailAddress,
      };
      try {
        const { html } = await exportHtml();
        submitData.bodyHtml = html;
        if (submitData.bodyText === '' || submitData.bodyHtml === '') {
          toast({
            ...errorToast,
            title: 'テストメールの送信ができません。',
            description: '本文が未入力の為テストメールの送信を中止しました。',
          });

          return;
        }
        await mutate(submitData);
        onClose();
      } catch (err) {
        if (err instanceof Error) {
          toast.closeAll();
          toast({
            ...errorToast,
            title: 'HTMLエディタエラー',
            description: err.message,
          });
        }
      }
    }, [exportHtml, getMailFormValues, mutate, toast, mailAddress]);

    return (
      <Popover isOpen={isOpen} onClose={onClose} placement="bottom-end">
        <PopoverTrigger>
          <Button borderColor="gray.400" onClick={onOpenHandler}>
            テストメール設定
          </Button>
        </PopoverTrigger>

        <PopoverContent width={424}>
          <PopoverBody p={6}>
            <FormProvider {...methods}>
              <Heading as="h2" size="sm" mb="6">
                テストメールを送信します。
              </Heading>
              {!hasFromAddress && (
                <Box mb={6}>
                  <Text>
                    テストメールを送信するには、
                    <Text as="span" fontWeight={700}>
                      「初回ログイン時メール設定&nbsp;&gt;&nbsp;メール設定」
                    </Text>
                    から、
                    <Text as="span" fontWeight={700}>
                      差出人メールアドレス
                    </Text>
                    を設定してください。
                  </Text>
                </Box>
              )}
              {hasFromAddress && (
                <>
                  <Box mb="2">
                    <Text fontWeight="bold" fontSize="14px" mb={1}>
                      宛先メールアドレス
                    </Text>
                    <Text fontSize="12px" color={Colors.GRAY_00}>
                      宛先メールアドレスを
                      <Text as="span" fontWeight="bold">
                        改行区切りで記入
                      </Text>
                      してください
                    </Text>
                  </Box>
                  <Stack spacing={4} mb={4}>
                    <Box>
                      <Textarea
                        value={mailAddress}
                        p="8px 16px"
                        placeholder={`xxxxx@xxxxxx.com\r\nyyyyy@yyyyyy.com\r\nzzzzz@zzzzzz.com`}
                        borderColor="gray.200"
                        onChange={(e) => setMailAddress(e.target.value)}
                        size="sm"
                        resize="none"
                        borderRadius="md"
                        height="256px"
                      />
                    </Box>
                  </Stack>
                </>
              )}
              <Box display="flex" justifyContent="end">
                <Button
                  height="40px"
                  w="76px"
                  variant="primary"
                  onClick={onSendMail}
                  isLoading={isLoading}
                  disabled={!hasFromAddress}
                >
                  送信
                </Button>
              </Box>
            </FormProvider>
          </PopoverBody>
        </PopoverContent>
      </Popover>
    );
  },
);
