import {
  DrawerCloseButton,
  DrawerContent,
  DrawerHeader,
  DrawerOverlay,
  Text,
} from '@chakra-ui/react';
import { MasterCreateForm } from 'admin/components/idPoolConsumer/master/masterForm';
import { FC, memo } from 'react';

type MasterCreateDrawerProps = {
  onClose: () => void;
  formId: string;
  isOpen: boolean;
  currentPage: number;
};

export const MasterCreateDrawer: FC<MasterCreateDrawerProps> = memo(
  ({ onClose, formId, currentPage }) => (
    <>
      <DrawerOverlay />
      <DrawerContent>
        <DrawerHeader pb="24px">
          <Text>選択肢マスタ登録</Text>
        </DrawerHeader>
        <MasterCreateForm
          formId={formId}
          currentPage={currentPage}
          onClose={onClose}
        />
        <DrawerCloseButton />
      </DrawerContent>
    </>
  ),
);
