import {
  Box,
  Button,
  FormControl,
  FormErrorMessage,
  HStack,
  List,
  ListItem,
  ModalBody,
  ModalCloseButton,
  ModalContent,
  ModalFooter,
  ModalHeader,
  ModalOverlay,
  Text,
} from '@chakra-ui/react';
import { RhfInput } from 'admin/components/form/RhfInput';
import { AlertInfoBar } from 'admin/components/ui/alertInfo';
import { ColorSet } from 'admin/define/colors';
import { TenantUserDeleteStateType } from 'admin/types/tenantUsers';
import { DeleteTenantUsersFormType } from 'admin/types/tenantUsers/form';
import { FC, memo } from 'react';
import { useFormContext, useFormState } from 'react-hook-form';

type Props = {
  formId: string;
  deleteList: TenantUserDeleteStateType[];
  isLoading: boolean;
  onClose: () => void;
  onDelete: () => void;
};

export const TenantUsersDeleteModalComponent: FC<Props> = memo(
  ({ formId, deleteList, isLoading, onClose, onDelete }: Props) => {
    const { handleSubmit } = useFormContext<DeleteTenantUsersFormType>();
    const { errors, isValid } = useFormState<DeleteTenantUsersFormType>();

    return (
      <>
        <ModalOverlay />
        <ModalContent fontSize="md" zIndex={100}>
          <ModalHeader fontSize="lg" fontWeight="bold">
            ユーザーの権限の削除
          </ModalHeader>
          <form onSubmit={handleSubmit(onDelete)} id={formId}>
            <ModalBody>
              <Box w="512px">
                <AlertInfoBar type="error" iconType="error">
                  <Text>・削除すると即時に利用できなくなります。</Text>
                </AlertInfoBar>
              </Box>
              <Text fontSize="sm" fontWeight="bold" mt="24px">
                下記ユーザーの権限を削除します。よろしいですか？
              </Text>
              <List mt="6px" mb="6px">
                {deleteList.map((item) => (
                  <ListItem key={`del-alert-dialog-${item.id}`}>
                    <Text
                      as="p"
                      fontSize="14px"
                    >{`${item.name}さん（${item.email}）`}</Text>
                  </ListItem>
                ))}
              </List>
              <Text mt={6} mb={1} fontSize="sm" fontWeight="bold">
                削除する場合は「delete」と入力後に「削除ボタン」を押下してください。
              </Text>
              <FormControl isInvalid={Boolean(errors.word?.message)}>
                <RhfInput<DeleteTenantUsersFormType> name="word" type="text" />
                <FormErrorMessage>{errors.word?.message}</FormErrorMessage>
              </FormControl>
            </ModalBody>
          </form>
          <ModalFooter>
            <HStack spacing={4}>
              <Button
                variant="solid"
                {...ColorSet.Default}
                onClick={onClose}
                padding="8px 16px"
              >
                キャンセル
              </Button>
              <Button
                colorScheme="danger"
                type="submit"
                mr={3}
                form={formId}
                disabled={!isValid || isLoading}
                padding="8px 36px"
              >
                削除
              </Button>
            </HStack>
          </ModalFooter>
          <ModalCloseButton />
        </ModalContent>
      </>
    );
  },
);
