import { isPromotion, Promotion } from 'components/paywall/pages/PromotionList/typed';

export type Course = {
  id: string;
  promotions: Promotion;
  name: string;
  month: number | null;
  price: number | null;
  wall: CourseWall;
  systemCode: string;
  tags: string;
  memo: string;
  createdAt: string;
  updatedAt: string;
};

export type CourseWall = {
  id: string;
  name: string;
  wallType: string;
  status: true;
  tenant: string;
  parent: string | null;
  createdAt: string;
  updatedAt: string;
  createBy: string;
  createUser: string;
  createUserName: string;
  updateBy: string;
  updateUser: string;
  updateUserName: string;
}

export type CourseFormType = {
  id?: string;
  name: string;
  month: number | null;
  price: number | null;
  wall: string;
  systemCode: string;
  tags: string;
  memo: string;
};

export type CourseSubmitFormType = {
  tenantId: string;
  wallId: string;
  courseForm: CourseFormType;
};

export const isCourseWall = (data: unknown): data is CourseWall => {
  const dt = data as CourseWall;

  return (
    typeof dt?.id === 'string' &&
    typeof dt?.name === 'string' &&
    typeof dt?.wallType === 'string' &&
    typeof dt?.status === 'boolean' &&
    typeof dt?.tenant === 'string' &&
    (dt?.parent !== null ? typeof dt?.parent === 'string' : true) &&
    typeof dt?.createdAt === 'string' &&
    typeof dt?.updatedAt === 'string' &&
    typeof dt?.createBy === 'string' &&
    typeof dt?.createUser === 'string' &&
    typeof dt?.createUserName === 'string' &&
    typeof dt?.updateBy === 'string' &&
    typeof dt?.updateUser === 'string' &&
    typeof dt?.updateUserName === 'string'
  )
}

export const isCourse = (data: unknown): data is Course => {
  const dt = data as Course;

  return (
    typeof dt?.id === 'string' &&
    typeof dt?.name === 'string' &&
    (dt?.month !== null ? typeof dt?.month === 'number' : true) &&
    (dt?.price !== null ? typeof dt?.price === 'number' : true) &&
    isCourseWall(dt?.wall) &&
    (dt?.promotions !== null ? isPromotion(dt?.promotions) : true) &&
    (dt?.createdAt !== null ? typeof dt?.createdAt === 'string' : true) &&
    (dt?.updatedAt !== null ? typeof dt?.updatedAt === 'string' : true)
  );
};

export const isCourseList = (datas: unknown[]): datas is Course[] =>
  !datas.some((d) => !isCourse(d));
