import {
  Box,
  Button,
  Modal,
  ModalOverlay,
  ModalContent,
  ModalHeader,
  ModalBody,
  ModalFooter,
  Text,
  VStack,
} from '@chakra-ui/react';
import { ChevronRightIcon } from '@chakra-ui/icons';
import { memo } from 'react';
import { Link } from 'react-router-dom';

export const SavedModal = memo(
  ({
    isOpen,
    onClose,
    backUrl,
  }: {
    isOpen: boolean;
    onClose: () => void;
    backUrl: string;
  }) => (
    <Modal isOpen={isOpen} size="lg" onClose={onClose} isCentered>
      <ModalOverlay />
      <ModalContent bg="White">
        <ModalHeader>
          <Text as="b">Auth Hub設定を保存しました</Text>
        </ModalHeader>
        <ModalBody>
          <Text>Auth Hub設定を保存しました。</Text>
          <Text>
            このダイアログを閉じてAuth Hub設定の編集を続けるか、Auth
            Hub設定一覧に戻るを選択してください。
          </Text>
        </ModalBody>
        <ModalFooter>
          <VStack spacing={4} w="full">
            <Button colorScheme="blue" size="md" w="full" onClick={onClose}>
              <Text as="b">このまま編集を続ける</Text>
            </Button>
            <Button colorScheme="blue" variant="outline" size="md" w="full">
              <Link to={backUrl}>
                <Box display="flex" alignItems="center">
                  <Text as="b">Auth Hub設定一覧に戻る</Text>
                  <ChevronRightIcon w={6} h={6} />
                </Box>
              </Link>
            </Button>
          </VStack>
        </ModalFooter>
      </ModalContent>
    </Modal>
  ),
);
