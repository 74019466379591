import { UseMutateFunction, useMutation } from 'react-query';
import { postEnqueteFileDownload } from 'api/enquete/postEnqueteFileDownload';
import { ResponseError } from 'api/types';
import {
  fileDownloadRequestType,
  isFileDownloadType,
  fileDownloadType,
} from 'api/enquete/types';
import { isGetValidationError } from 'utils/form';
import { successToast } from 'utils/toast';
import { useCustomToast } from 'hooks/useCustomToast';

type UnPromisify<T> = T extends Promise<infer U> ? U : T;

const formData: fileDownloadRequestType = {
  tenantId: '',
  enqueteId: '',
  target: '',
  startDate: '',
  endDate: '',
  isRandom: false,
};

/**
 * フォームダウンロード hooks
 * @returns {Object} { onSubmit, isLoading }
 */
export const useEnqueteFormDownload = (): {
  onSubmit: UseMutateFunction<
    UnPromisify<ReturnType<typeof postEnqueteFileDownload>>,
    unknown,
    fileDownloadRequestType,
    unknown
  >;
  isLoading: boolean;
} => {
  const toast = useCustomToast();

  const { mutate: onSubmit, isLoading } = useMutation(
    ({
      tenantId,
      enqueteId,
      target,
      startDate,
      endDate,
      isRandom,
    }: fileDownloadRequestType) =>
      postEnqueteFileDownload({
        tenantId,
        enqueteId,
        target,
        startDate,
        endDate,
        isRandom,
      }),
    {
      onSuccess: (result: fileDownloadType | ResponseError) => {
        if (isFileDownloadType(result)) {
          toast({
            ...successToast,
            duration: 4000,
            title: 'ダウンロード準備を受け付けました。しばらくお待ちください。',
            description:
              '準備が完了したら、画面右上のメッセージボックスでダウンロードURLを通知します。',
          });
        }

        if (
          isGetValidationError<fileDownloadRequestType>(
            result,
            Object.keys(formData),
          )
        ) {
          if (result?.others) {
            const errorMsg = Object.entries(result).map(([_, value]) => value);
            toast({
              status: 'error',
              position: 'bottom',
              duration: 4000,
              isClosable: true,
              description: errorMsg[0],
            });
          }
        }
      },
      onError: (err: unknown) => {
        if (err instanceof Error) {
          toast.closeAll();
          toast({
            title: err.message,
            status: 'error',
          });
        }
      },
    },
  );

  return { onSubmit, isLoading };
};
