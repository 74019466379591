import { Box, DrawerBody, Heading, Text, VStack } from '@chakra-ui/react';
import { Colors } from 'admin/define/colors';
import { ServiceTermType } from 'admin/types/service/terms';
import { FC, memo } from 'react';

type Props = {
  term: ServiceTermType;
};

export const TermDetail: FC<Props> = memo(({ term }) => (
  <DrawerBody pt="24px" pb="16px">
    <VStack spacing={6}>
      <Box w="100%">
        <Heading as="h3" fontSize="14px" mb="8px">
          規約ID
        </Heading>
        <Text
          fontSize="16px"
          color={Colors.GRAY_03}
          pb="8px"
          borderBottom="1px solid"
          borderColor={Colors.GRAY_30}
        >
          {term.id}
        </Text>
      </Box>
      <Box w="100%">
        <Heading as="h3" fontSize="14px" mb="8px">
          表示名
        </Heading>
        <Box borderBottom="1px solid" borderColor={Colors.GRAY_30} pb="8px">
          <Box mb="8px">
            <Text fontSize="12px" color={Colors.GRAY_00} mb="4px">
              日本語
            </Text>
            <Text fontSize="16px">{term?.displayNameJa}</Text>
          </Box>
          <Box>
            <Text fontSize="12px" color={Colors.GRAY_00} mb="4px">
              外国語
            </Text>
            <Text fontSize="16px">{term?.displayNameForeign || '-'}</Text>
          </Box>
        </Box>
      </Box>
      <Box w="100%">
        <Heading as="h3" fontSize="14px" mb="8px">
          フィールド名
        </Heading>
        <Text
          fontSize="16px"
          color={Colors.GRAY_03}
          pb="8px"
          borderBottom="1px solid"
          borderColor={Colors.GRAY_30}
        >
          {term.fieldName}
        </Text>
      </Box>
      <Box w="100%">
        <Heading as="h3" fontSize="14px" mb="8px">
          URL
        </Heading>
        <Text
          fontSize="16px"
          color={Colors.GRAY_03}
          pb="8px"
          borderBottom="1px solid"
          borderColor={Colors.GRAY_30}
        >
          {term.url}
        </Text>
      </Box>
      <Box w="100%">
        <Heading as="h3" fontSize="14px" mb="8px">
          バージョン
        </Heading>
        <Text
          fontSize="16px"
          color={Colors.GRAY_03}
          pb="8px"
          borderBottom="1px solid"
          borderColor={Colors.GRAY_30}
        >
          {term.version}
        </Text>
      </Box>
      <Box w="100%">
        <Heading as="h3" fontSize="14px" mb="8px">
          規約同意必須バージョン
        </Heading>
        <Text
          fontSize="16px"
          color={Colors.GRAY_03}
          pb="8px"
          borderBottom="1px solid"
          borderColor={Colors.GRAY_30}
        >
          {term.consentRequiredVersion}
        </Text>
      </Box>
    </VStack>
  </DrawerBody>
));
