import {
  headTitleOptions,
  screenNameOptions,
  screenDescriptionOptions,
  itemNameOptions,
  buttonNameOptions,
  textareaElementOptions,
} from './options';

const groupName = 'infoConfirmation';

// 入力情報確認画面
export default [
  // ロゴ	画像
  // 前画面での入力内容表示領域	-
  {
    name: `${groupName}.headTitle`,
    label: 'ブラウザタブ表示名',
    tooltip: '',
    type: 'Text',
    defaultValue: '入力情報の確認',
    options: headTitleOptions,
    elementOptions: {},
  },
  {
    name: `${groupName}.screenName`,
    label: '画面名',
    tooltip: '',
    type: 'Text',
    defaultValue: '入力情報の確認',
    options: screenNameOptions,
    elementOptions: {},
  },
  {
    name: `${groupName}.screenDescription`,
    label: '案内文 画面',
    tooltip: '',
    type: 'Textarea',
    defaultValue:
      'まだ登録は完了していません。\n入力内容に誤りがなければ、この内容で登録してください。',
    options: screenDescriptionOptions,
    elementOptions: textareaElementOptions,
  },
  {
    name: `${groupName}.emailItemName`,
    label: '表示名 メールアドレス',
    tooltip: '',
    type: 'Text',
    defaultValue: 'メールアドレス',
    options: itemNameOptions,
    elementOptions: {},
  },
  {
    name: `${groupName}.registerButtonName`,
    label: 'ボタン名 完了画面に遷移',
    tooltip: '',
    type: 'Text',
    defaultValue: '登録',
    options: buttonNameOptions,
    elementOptions: {},
  },
  {
    name: `${groupName}.backAndEditButtonName`,
    label: 'ボタン名 前の画面に戻り修正',
    tooltip: '',
    type: 'Text',
    defaultValue: '入力内容を修正する',
    options: buttonNameOptions,
    elementOptions: {},
  },
] as const;
