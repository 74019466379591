import { FormControl, FormLabel } from '@chakra-ui/react';
import { InputTextForm, InputTextProps } from 'components/common/atoms';
import { KeyboardEvent, useCallback, VFC } from 'react';
import {
  FieldValues,
  Path, RegisterOptions, useController, useFormContext
} from 'react-hook-form';

/**
 * InputFormProps
 */
type InputFormProps<T> = {
  name: Path<T>;
  labelElement?: JSX.Element | undefined;
  attr?: RegisterOptions;
  horizontal?: boolean;
} & InputTextProps;

export const InputForm = <T extends FieldValues>({
  name,
  label = '',
  labelElement = undefined,
  horizontal = false,
  ...rest
}: InputFormProps<T>): ReturnType<VFC> => {
  const { control } = useFormContext<T>();

  const {
    field: { value },
  } = useController({ name, control });

  const onKeyDown = useCallback((e: KeyboardEvent<HTMLInputElement>) => {
    // エンターボタン押下で想定外のsubmitを行わない
    if (e.key === 'Enter') {
      e.preventDefault();
    }
  }, []);

  const formAttr = horizontal ? { display: 'flex', alignItems: 'center' } : {};

  return (
    <FormControl {...formAttr}>
      <FormLabel htmlFor={name} fontSize="sm">
        {label && label}
        {labelElement && labelElement}
      </FormLabel>
      <InputTextForm<T>
        {...rest}
        name={name}
        value={value}
        onKeyDown={onKeyDown}
      />
    </FormControl>
  );
};

InputForm.defaultProps = {
  horizontal: false,
};
