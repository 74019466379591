import { useDisclosure } from '@chakra-ui/react';
import { memo, useCallback, useState } from 'react';
import { CustomError } from 'api/error/CustomError';
import { API_MESSAGES } from 'admin/define/apiMessages';
import { Breadcrumb } from 'admin/components/ui/breadcrumb';
import { BreadcrumbType } from 'admin/types/ui/breadcrumb';
import { AuthHubInner } from 'admin/components/authHub/authHubInner';
import {
  AuthHubProvider,
  useAuthHubContext,
} from 'admin/components/authHub/authHubContext';
import { useBrowserBackControl } from 'hooks/useBrowserBackControl';
import { useCustomNavigate } from 'hooks/useCustomNavigate';
import { NotSavedConfirmModal } from './NotSavedConfirmModal';

const AuthHubDetailInner = () => {
  const { authHub, authHubId, form } = useAuthHubContext();
  const { isOpen, onClose, onOpen } = useDisclosure();
  const [isBack, setIsBack] = useState(false);
  const isEdited = form.formState.isDirty;

  const onCloseModal = useCallback(() => {
    setIsBack(false);
    onClose();
  }, [setIsBack, onClose]);
  const { afterNavigate } = useCustomNavigate({
    isEdited,
    callback: onOpen,
  });
  const onNext = useCallback(() => {
    if (isBack) {
      window.history.back();
    } else {
      afterNavigate();
    }
  }, [afterNavigate, isBack]);

  const onBack = useCallback(() => {
    setIsBack(true);
    onOpen();
  }, [onOpen, setIsBack]);
  useBrowserBackControl({ isEdited, onOpen: onBack });

  if (!authHubId) {
    throw new CustomError(API_MESSAGES.AUTH_HUB.GET_AUTH_HUB.ID_MISSING);
  }

  const breadcrumbItems: BreadcrumbType[] = [
    { url: '/authHub', title: 'Auth Hub設定' },
    { url: `/authHub/${authHubId}`, title: authHub?.data?.name ?? '' },
  ];

  return (
    <>
      <NotSavedConfirmModal
        isOpen={isOpen}
        onClose={onCloseModal}
        onNext={onNext}
      />
      <Breadcrumb breadcrumbItems={breadcrumbItems} />
      <AuthHubInner />
    </>
  );
};

export const AuthHubDetail = memo(() => (
  <AuthHubProvider>
    <AuthHubDetailInner />
  </AuthHubProvider>
));
