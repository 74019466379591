import { patchCourse } from 'api/paywall/patchCourse';
import { ResponseError } from 'api/types';
import { CourseFormDefaultValues } from 'components/paywall/pages/CourseList/initial';
import {
  Course, CourseFormType, CourseSubmitFormType, isCourse
} from 'components/paywall/pages/CourseList/typed';
import { usePaywallUtil } from 'hooks/paywall/usePaywallUtil';
import { useCustomToast } from 'hooks/useCustomToast';
import { UseFormSetError } from 'react-hook-form';
import { UseMutateFunction, useMutation } from 'react-query';
import { isGetValidationError, toMultiError } from 'utils/form';

type UnPromisify<T> = T extends Promise<infer U> ? U : T;

/**
 * コース修正 hooks
 * @param {UseFormSetError<CourseFormType>} setError
 * @param {Function} close
 * @returns {Object} { onSubmit, isLoading }
 */
export const useEditCourse = ({
  setError,
  close,
}: {
  setError?: UseFormSetError<CourseFormType>;
  close?: () => void;
}): {
  onSubmit: UseMutateFunction<
    UnPromisify<ReturnType<typeof patchCourse>>,
    unknown,
    CourseSubmitFormType,
    unknown
  >;
  isLoading: boolean;
} => {
  const { paywallListRefetchQueries, paywallCourseListRefetchQueries } = usePaywallUtil();
  const toast = useCustomToast();

  const { mutate: onSubmit, isLoading } = useMutation(
    ({ tenantId, wallId, courseForm }: CourseSubmitFormType) =>
      patchCourse({ tenantId, wallId, courseForm }),
    {
      onSuccess: (result: Course | boolean | ResponseError) => {
        paywallListRefetchQueries();
        paywallCourseListRefetchQueries();

        if (isCourse(result)) {
          close?.();
          toast({
            status: 'success',
            position: 'bottom',
            duration: 4000,
            isClosable: true,
            title: 'コースを修正しました',
          });
        }

        if (
          isGetValidationError<CourseFormType>(
            result,
            Object.keys(CourseFormDefaultValues),
          )
        ) {
          if (result?.others) {
            const errorMsg = Object.entries(result).map(([_, value]) => value);
            toast({
              status: 'error',
              position: 'bottom',
              duration: 4000,
              isClosable: true,
              description: errorMsg[0],
            });
          } else {
            Object.keys(result).forEach((k) => {
              const key = k as keyof CourseFormType;
              const errMsgs = result?.[key];
              if (errMsgs) {
                setError?.(key, { types: toMultiError(errMsgs) });
              }
            });
          }
        }
      },
    },
  );

  return { onSubmit, isLoading };
};
