import { RouteObject } from 'react-router-dom';
import { EnqueteList } from 'components/enquete/EnqueteList';
import { EnqueteForm } from 'components/enquete/EnqueteForm';
import { Chart } from 'components/chart/';
import { EnqueteSkinList } from 'components/enquete/EnqueteSkinList';
import { EnqueteSkin } from 'components/enquete/EnqueteSkin';

export const EnqueteRoutes: RouteObject[] = [
  {
    path: '',
    element: <EnqueteList />,
  },
  {
    path: 'form/',
    element: <EnqueteForm />,
    children: [
      {
        path: ':enqueteId',
        element: <EnqueteForm />,
      },
    ],
  },
  {
    path: 'results/',
    element: <Chart />,
    children: [
      {
        path: ':enqueteId',
        element: <Chart />,
      },
    ],
  },
  {
    path: 'skin/',
    element: <EnqueteSkinList />,
  },
  {
    path: 'skin/form',
    element: <EnqueteSkin />,
  },
];
