import { SearchIcon } from '@chakra-ui/icons';
import { Box, Input, InputGroup, InputLeftElement } from '@chakra-ui/react';
import { ChangeEvent, FC, KeyboardEvent, memo, useCallback, useEffect, useState } from 'react';
import { useSearchParams } from 'react-router-dom';

/**
 * 単品商品一覧検索フォーム
 */
export const SearchForm: FC = memo(() => {
  const [searchWord, setSearchWord] = useState<string>('');
  const [searchParams, setSearchParams] = useSearchParams();

  /**
   * ページ読み込み時にクエリパラメータを取得し、設定済みURLの場合は検索ボックスへ反映させる
   */
  useEffect(() => {
    if(searchParams.get('filter')) {
      setSearchWord(searchParams.get('filter')||'');
    }
  }, [searchParams]);

  /**
   * クエリパラメータが変更された時の処理
   */
  useEffect(() => {
    const qFilter = searchParams.get('filter');
    const qPage = searchParams.get('page');
    const qPerPage = searchParams.get('perPage');
    if(qFilter || qPage || qPerPage) {
      const queryParams = {};
      if (qFilter) Object.assign(queryParams, { filter: qFilter });
      if (qPage) Object.assign(queryParams, { page: qPage });
      if (qPerPage) Object.assign(queryParams, { perPage: qPerPage });
      setSearchParams(queryParams);
    }
  }, [setSearchParams, searchParams]);

  // 検索ボックスの入力内容が変更されたとき
  const onChangeSearch = useCallback((event: ChangeEvent<HTMLInputElement>) => {
    if(!event.target.value && searchWord !== '') {
      setSearchParams({})
    }
    setSearchWord(event.target.value)
  }, [searchWord, setSearchParams]);

  /**
   * 検索処理（enter押下時）
   */
  const onSearch = useCallback((event: KeyboardEvent<HTMLInputElement>) => {
    // 検索ボックスに入力中のエンター押下時のみ実行する
    if (event.key === 'Enter') {
      event.preventDefault();
      setSearchParams({filter: searchWord});
    }
  }, [searchWord, setSearchParams]);

  return (
    <Box mb="32px">
      <InputGroup>
        <InputLeftElement
          pointerEvents="none"
          children={<SearchIcon color="gray.300" />}
        />
        <Input
          placeholder="検索"
          type="search"
          value={searchWord}
          onChange={onChangeSearch}
          onKeyPress={onSearch}
        />
      </InputGroup>
    </Box>
  );
});
