import { VFC, memo, useState, useCallback, useEffect } from 'react';
import { useForm, FormProvider } from 'react-hook-form';
import { useSearchParams } from 'react-router-dom';
import { useDisclosure, Text, Box } from '@chakra-ui/react';
import { MailSetting, defaultMailSetting } from 'api/mail/types';
import { Segment } from 'api/segment/types';
import { getMailSetting } from 'api/mail/getMailSetting';
import { useCustomToast } from 'hooks/useCustomToast';
import { useUserInfo } from 'hooks/useUserInfo';
import { useUserTenantId } from 'hooks/user/useUserTenantId';
import { useEditMailSetting } from 'hooks/mail/useEditMailSetting';
import { errorToast } from 'utils/toast';
import {
  DrawerForm,
  ErrorTextMsg,
  WarningModal,
} from 'components/common/atoms';
import { MailFormSettings } from 'components/mail/molecules/MailFormSettings';
import { MAIL_DELIVERY_TO_DIRECT, MAIL_DELIVERY_TO_INSERT } from 'define';

type MailSettingDrawerFormProps = {
  mailId: string;
  buttonName: string;
  isError: boolean;
  segmentList: Segment[];
};

const defaultValues: MailSetting = defaultMailSetting;

export const MailSettingDrawerForm: VFC<MailSettingDrawerFormProps> = memo(
  ({
    mailId = '',
    buttonName,
    isError,
    segmentList,
  }: MailSettingDrawerFormProps) => {
    const [initFields, setInitFields] = useState(false);
    const [uneditableMessate, setUneditableMessate] = useState<
      string | JSX.Element
    >('');
    const [tmpReserve, setTmpReserve] = useState<string | null>(null);
    const [enqueteEmailId, setEnqueteEmailId] = useState('');
    const [enqueteEmailSubject, setEnqueteEmailSubject] = useState('');
    const toast = useCustomToast();
    const [searchParams, setSearchParams] = useSearchParams();
    const { isOpen, onOpen, onClose } = useDisclosure();
    const [submitData, setSubmitData] = useState<MailSetting>(defaultValues);
    const [disabledSubmitButton, setDisabledSubmitButton] = useState(false);
    const {
      isOpen: isOpenAlert,
      onOpen: onOpenAlert,
      onClose: onCloseAlert,
    } = useDisclosure();
    const tenantId = useUserTenantId();
    const { isStaff, isAssistant } = useUserInfo();

    const methods = useForm<MailSetting>({
      mode: 'onBlur',
      // エラーのある入力が再度バリデーションされるタイミングを変更(default: onChange)
      reValidateMode: 'onBlur',
      defaultValues,
    });
    const { reset, setValue, handleSubmit, setError } = methods;
    const formId = 'mailEdit-form';

    const onDrawerClose = useCallback(() => {
      const params = {};
      if (searchParams.get('order'))
        Object.assign(params, { order: searchParams.get('order') });
      if (searchParams.get('sort'))
        Object.assign(params, { sort: searchParams.get('sort') });
      if (searchParams.get('status'))
        Object.assign(params, { status: searchParams.get('status') });
      if (searchParams.get('segment'))
        Object.assign(params, { segment: searchParams.get('segment') });
      if (searchParams.get('page'))
        Object.assign(params, { page: searchParams.get('page') });
      if (searchParams.get('perPage'))
        Object.assign(params, { perPage: searchParams.get('perPage') });
      setSearchParams(params);
      reset();
      onClose();
    }, [onClose, reset, searchParams, setSearchParams]);

    const { onSubmit, isLoading } = useEditMailSetting({
      setError,
      onDrawerClose,
    });

    const submit = useCallback(
      (mailSetting: MailSetting) => {
        const data: MailSetting = defaultValues;
        Object.entries(mailSetting).forEach((item) => {
          if (
            item[0] === 'uneditableMessate' ||
            item[0] === 'cancellable' ||
            item[0] === 'status'
          )
            return;
          if (
            item[0] !== 'reserve' &&
            item[0] !== 'subscription' &&
            item[0] !== 'segment' &&
            (item[1] === null || item[1] === '')
          )
            return;
          // 配信予定日に変更が無かった場合は配信日時を変更しない
          if (item[0] === 'reserve' && item[1] === tmpReserve) {
            delete data.reserve;

            return;
          }
          Object.assign(data, { [item[0]]: item[1] });
        });
        if (
          mailSetting.deliveryTo === MAIL_DELIVERY_TO_DIRECT ||
          mailSetting.deliveryTo === MAIL_DELIVERY_TO_INSERT
        ) {
          Object.assign(data, {
            addressFile: mailSetting.addressFile || '',
          });
        }
        onSubmit({ tenantId, mailSetting: data });
      },
      [tenantId, tmpReserve, onSubmit],
    );

    const onCloseModal = useCallback(
      (mode?: string) => {
        onCloseAlert();
        if (mode === 'submit') {
          submit(submitData);
        }
      },
      [onCloseAlert, submit, submitData],
    );

    const formSubmit = useCallback(
      (mailSetting: MailSetting) => {
        setSubmitData(mailSetting);
        if (mailSetting.deliveryType === 2) {
          onOpenAlert();
        } else {
          submit(mailSetting);
        }
      },
      [setSubmitData, onOpenAlert, submit],
    );

    // 配信設定ボタン押下時
    const onDelivaryMailSetting = useCallback(async () => {
      const setting = await getMailSetting(tenantId, mailId);
      setEnqueteEmailId(setting.id);
      setEnqueteEmailSubject(setting.subject);
      setValue('id', setting.id);
      setValue('subject', setting.subject);
      setValue('fromAddress', setting.fromAddress || '');
      setValue('replayAddress', setting.replayAddress || '');
      setValue('deliveryType', setting.deliveryType || 0);
      setValue('reserve', setting.reserve || null);
      setValue('deliveryTo', setting.deliveryTo || 0);
      setValue('addressFile', setting.addressFile || '');
      setValue('subscription', setting.subscription || '');
      setValue('segment', setting.segment || '');
      setValue('addressFileOrigin', setting.addressFileOrigin || '');
      setValue('placeholders', setting.placeholders);
      setValue('shortagePlaceholders', setting.shortagePlaceholders || '');
      setValue('excessPlaceholders', setting.excessPlaceholders || '');
      setValue('isReplaceWord', setting.isReplaceWord);
      setValue('status', setting.status);
      setValue('uneditableMessate', setting.uneditableMessate || '');
      setValue('cancellable', setting.cancellable);
      setValue('estimateCnt', setting.estimateCnt);

      // 配信予約日のマスタデータ
      setTmpReserve(setting.reserve || null);

      const uneditableMess =
        (setting.deliveryType === 1 || setting.deliveryType === 2) &&
        setting.reserve !== '' &&
        (isStaff || isAssistant) ? (
          <Text as="p">
            配信予約済みのため、編集することができません。
            <br />
            編集する場合は配信予約を解除してください。
          </Text>
        ) : (
          ''
        );
      setUneditableMessate(setting.uneditableMessate || uneditableMess);
      onOpen();
    }, [mailId, onOpen, setValue, tenantId, isStaff, isAssistant]);

    useEffect(() => {
      if (initFields) return;
      const openDrawer = searchParams.get('openDrawer');
      if (mailId === openDrawer) {
        onDelivaryMailSetting().catch((_) => {
          toast({
            ...errorToast,
            title: '配信設定読み込みエラー',
            description:
              '配信設定の読み込みに失敗したため、ドロワー表示ができませんでした。',
          });
        });
      }
      setInitFields(true);
    }, [searchParams, initFields, mailId, onDelivaryMailSetting, toast]);

    const reloadData = () => {
      onDelivaryMailSetting().catch((_) => {
        toast({
          ...errorToast,
          title: '配信設定読み込みエラー',
          description:
            '配信設定の読み込みに失敗したため、ドロワー表示ができませんでした。',
        });
      });
    };

    return (
      <FormProvider {...methods}>
        <DrawerForm
          title="配信設定"
          subInfo={
            <Text fontSize={14} fontWeight="normal">
              配信ID : {enqueteEmailId}
              <br />
              件名 : {enqueteEmailSubject}
            </Text>
          }
          openBtnChildNode={<Text as="span">{buttonName}</Text>}
          openBtnProps={{
            bgColor: 'white',
            borderWidth: '1px',
            borderColor: isError ? 'red.400' : 'gray.400',
          }}
          submitBtnTitle="保存・配信予約"
          submitBtnProps={{
            variant: 'primary',
            isLoading,
            disabled: disabledSubmitButton,
          }}
          isOpen={isOpen}
          onOpen={onDelivaryMailSetting}
          onClose={onDrawerClose}
          closeOnOverlayClick={false}
          closeOnEsc={false}
          size="lg"
          formId={formId}
          drawerCloseButtonProps={{
            mr: 4,
            disabled: isLoading,
          }}
          drawerFooterJustify="flex-end"
          isFooter={uneditableMessate === ''}
          firstFocus="button"
        >
          <form
            id={formId}
            onSubmit={handleSubmit((data) => formSubmit(data))}
            data-testid="setting-user-drawer"
          >
            {uneditableMessate !== '' && (
              <ErrorTextMsg msg={uneditableMessate} />
            )}
            <Box p={2} py={4}>
              <MailFormSettings
                segmentList={segmentList}
                uneditableMessate={uneditableMessate}
                setDisabledSubmitButton={setDisabledSubmitButton}
                reloadData={reloadData}
              />
            </Box>
          </form>
        </DrawerForm>
        <WarningModal
          title="配信設定"
          description={
            <Text>
              即時配信を実行しますか。
              <br />
              即時配信すると配信処理が開始し、取り消しはできません。
            </Text>
          }
          submitBtnName="OK 即時配信する"
          cancelBtnName="キャンセル"
          isOpen={isOpenAlert}
          onClose={onCloseModal}
        />
      </FormProvider>
    );
  },
);
