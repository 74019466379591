import {
  headTitleOptions,
  screenNameOptions,
  screenDescriptionOptions,
  buttonNameOptions,
  itemNameOptions,
  textareaElementOptions,
} from './options';

const groupName = 'newPasswordEntry';

// 新パスワード入力画面
export default [
  // ロゴ	画像
  {
    name: `${groupName}.headTitle`,
    label: 'ブラウザタブ表示名',
    tooltip: '',
    type: 'Text',
    defaultValue: 'パスワード登録',
    options: headTitleOptions,
    elementOptions: {},
  },
  {
    name: `${groupName}.screenName`,
    label: '画面名',
    tooltip: '',
    type: 'Text',
    defaultValue: 'パスワード登録',
    options: screenNameOptions,
    elementOptions: {},
  },
  {
    name: `${groupName}.screenDescription`,
    label: '案内文 画面',
    tooltip: '',
    type: 'Textarea',
    defaultValue:
      '以下のメールアドレスに認証コードメールを送信しました。\n\nメールに記載されている６桁のコードを入力し、\n新しいパスワードを設定して「パスワードの更新」を押下してください。。',
    options: screenDescriptionOptions,
    elementOptions: textareaElementOptions,
  },
  {
    name: `${groupName}.codeEntryItemName`,
    label: '入力欄名 認証コード',
    tooltip: '',
    type: 'Text',
    defaultValue: 'コードの入力',
    options: itemNameOptions,
    elementOptions: {},
  },
  {
    name: `${groupName}.passwordItemName`,
    label: '入力欄名 新パスワード',
    tooltip: '',
    type: 'Text',
    defaultValue: '新しいパスワード',
    options: itemNameOptions,
    elementOptions: {},
  },
  {
    name: `${groupName}.passwordConfirmationItemName`,
    label: '入力欄名 新パスワード（確認用）',
    tooltip: '',
    type: 'Text',
    defaultValue: '新しいパスワード（確認用）',
    options: itemNameOptions,
    elementOptions: {},
  },
  {
    name: `${groupName}.continueButtonName`,
    label: 'ボタン名 パスワードの更新',
    tooltip: '',
    type: 'Text',
    defaultValue: 'パスワードの更新',
    options: buttonNameOptions,
    elementOptions: {},
  },
  {
    name: `${groupName}.backButtonName`,
    label: 'ボタン名 戻る',
    tooltip: '',
    type: 'Text',
    defaultValue: '戻る',
    options: buttonNameOptions,
    elementOptions: {},
  },
  {
    name: `${groupName}.resendInstructionText`,
    label: '案内文 再送信案内',
    tooltip: '',
    type: 'Textarea',
    defaultValue:
      '認証コードメールが届かない場合、\n前の画面に戻り再度送信をお試しください。',
    options: buttonNameOptions,
    elementOptions: textareaElementOptions,
  },
] as const;
