/* eslint-disable @typescript-eslint/no-unsafe-call */
/* eslint-disable @typescript-eslint/no-unsafe-member-access */
// eslint-disable-next-line @typescript-eslint/ban-ts-comment
// @ts-nocheck
import { Course } from 'components/paywall/pages/CourseList/typed';
import {
  PAYWALL_CUSTOM_TOOL_BUTTON_CLASS, PAYWALL_CUSTOM_TOOL_BUTTON_DEFAULT_COLOR, PAYWALL_CUSTOM_TOOL_BUTTON_DEFAULT_NAME, PAYWALL_CUSTOM_TOOL_LOGIN_BUTTON_CLASS, PAYWALL_CUSTOM_TOOL_LOGIN_BUTTON_DEFAULT_COLOR, PAYWALL_CUSTOM_TOOL_LOGIN_BUTTON_DEFAULT_NAME, PAYWALL_CUSTOM_TOOL_MEMBER_REGIST_BUTTON_CLASS, PAYWALL_CUSTOM_TOOL_REGIST_BUTTON_DEFAULT_COLOR, PAYWALL_CUSTOM_TOOL_REGIST_BUTTON_DEFAULT_NAME
} from 'define';
import { useUnlayerEditorConfig } from 'hooks/unlayer/useUnlayerEditorConfig';
import { RefObject } from 'react';
import {
  Design,
  FileInfo,
  FileUploadDoneCallback,
  HtmlExport,
  ToolsConfig,
  UnlayerOptions
} from 'react-email-editor';
import { deepMerge } from 'utils/deepMerge';

/**
 * UnlayerOptions 型に audit を追加する為の定義
 *
 * @types/react/react-email-editor の最新バージョン 1.5.1 で
 * audit が未定義の為。
 */
interface FeaturesV151 extends Features {
  readonly audit?: boolean | undefined;
}
interface UnlayerOptionsV151 extends UnlayerOptions {
  readonly features?: FeaturesV151;
}

/**
 * Unlayer ID
 */
const UNLAYER_ID = process.env.REACT_APP_UNLAYER_PROJECTID || '0';

/**
 * Unlayer Editor 共通設定
 */
const baseOptions: UnlayerOptionsV151 = {
  displayMode: 'email',
  projectId: Number(UNLAYER_ID),
  locale: 'ja-JP',
  appearance: {
    theme: 'light',
    panels: {
      tools: {
        dock: 'left',
      },
    },
  },
  features: {
    preview: false,
    imageEditor: false,
    stockImages: false,
    audit: false,
    textEditor: {
      inlineFontControls: true,
      cleanPaste: true,
    },
  },
  customCSS: [
    '.target_field.input-group { display: none; }',
    '.smart_headings_panel { display: none; }',
    '.magic_image_panel { display: none; }',
    '.smart_buttons_panel { display: none; }',
    '.ai-enabled-icon { display: none; }',
    '.blockbuilder-content-tool-name { text-overflow: initial !important; white-space: initial !important; }',
    '.tox-toolbar__group:has(.smart-text-button) .smart-text-button {display: none;}',
  ],
};

type useEmailEditorType = {
  editor: RefObject<EmailEditor>;
  options?: UnlayerOptions;
};

export const useEmailEditor = ({
  editor,
  options = baseOptions,
}: useEmailEditorType): {
  unlayerId: number;
  editorOptions: () => UnlayerOptions;
  editorOptionsWithCustomTools: ({
    type,
  }: {
    type: 'course' | 'item' | 'member';
  }) => UnlayerOptions;
  defaultDesignValue: Design;
  loadDesign: (design: Design) => void;
  editorReady: () => Promise<{ isReady: boolean }>;
  editorUpdated: () => Promise<HtmlExport>;
  exportHtml: () => Promise<HtmlExport>;
  imageUpload: () => Promise<{
    file: File;
    filePath: string;
    done: FileUploadDoneCallback;
  }>;
  imageUploadFinish: () => Promise<{ message: string }>;
  customPayWallButtonToolsConfig: (courseList: Course[]) => ToolsConfig;
} => {
  const {
    setRegisterCustomTool,
    createCustomWidgetForCourse,
    createCustomTool,
    createCustomToolCourseOption,
    createCustomToolClassOption,
    createCustomToolButtonTextOption,
    createCustomToolButtonStyleOption,
    createCustomToolRenderer,
    createCustomWidgetForItem,
    createCustomWidgetForMember
  } = useUnlayerEditorConfig();
  /**
   * unlayerId
   */
  const unlayerId = Number(UNLAYER_ID);

  /**
   * Unlayer editor options
   */
  const editorOptions = (): UnlayerOptions =>
    deepMerge(baseOptions, options);

  const editorOptionsWithCustomTools = ({
    type,
  }: {
    type: 'course' | 'item' | 'member';
  }): UnlayerOptions => {
    let CustomJs: string[] = [];
    if(type === 'course') {
      CustomJs = [
        createCustomWidgetForCourse('my_color_picker'),
        setRegisterCustomTool(
          createCustomTool('paywall_button', 'ペイウォールボタン', 'fa-p'),
          [
            createCustomToolCourseOption(),
            createCustomToolClassOption(),
            createCustomToolButtonTextOption(PAYWALL_CUSTOM_TOOL_BUTTON_DEFAULT_NAME, true),
            createCustomToolButtonStyleOption(PAYWALL_CUSTOM_TOOL_BUTTON_DEFAULT_COLOR, PAYWALL_CUSTOM_TOOL_BUTTON_DEFAULT_COLOR),
          ],
          createCustomToolRenderer(PAYWALL_CUSTOM_TOOL_BUTTON_CLASS, true)
        )
      ]
    }else if(type === 'item') {
      CustomJs = [
        createCustomWidgetForItem('my_color_picker'),
        setRegisterCustomTool(
          createCustomTool('paywall_button', 'ペイウォールボタン', 'fa-p'),
          [
            createCustomToolClassOption(),
            createCustomToolButtonTextOption(PAYWALL_CUSTOM_TOOL_BUTTON_DEFAULT_NAME, true),
            createCustomToolButtonStyleOption(PAYWALL_CUSTOM_TOOL_BUTTON_DEFAULT_COLOR, PAYWALL_CUSTOM_TOOL_BUTTON_DEFAULT_COLOR),
          ],
          createCustomToolRenderer(PAYWALL_CUSTOM_TOOL_BUTTON_CLASS, false)
        )
      ]
    }else {
      CustomJs = [
        createCustomWidgetForMember('my_color_picker'),
        setRegisterCustomTool(
          createCustomTool('paywall_login_button', 'ログインボタン', 'fa-l'),
          [
            createCustomToolClassOption(),
            createCustomToolButtonTextOption(PAYWALL_CUSTOM_TOOL_LOGIN_BUTTON_DEFAULT_NAME, false),
            createCustomToolButtonStyleOption(PAYWALL_CUSTOM_TOOL_LOGIN_BUTTON_DEFAULT_COLOR, PAYWALL_CUSTOM_TOOL_LOGIN_BUTTON_DEFAULT_COLOR),
          ],
          createCustomToolRenderer(PAYWALL_CUSTOM_TOOL_LOGIN_BUTTON_CLASS, false)
        ),
        setRegisterCustomTool(
          createCustomTool('paywall_MemberRegist_button', '会員登録ボタン', 'fa-r'),
          [
            createCustomToolClassOption(),
            createCustomToolButtonTextOption(PAYWALL_CUSTOM_TOOL_REGIST_BUTTON_DEFAULT_NAME, false),
            createCustomToolButtonStyleOption(PAYWALL_CUSTOM_TOOL_REGIST_BUTTON_DEFAULT_COLOR, PAYWALL_CUSTOM_TOOL_REGIST_BUTTON_DEFAULT_COLOR),
          ],
          createCustomToolRenderer(PAYWALL_CUSTOM_TOOL_MEMBER_REGIST_BUTTON_CLASS, false)
        ),
      ]
    }
    const mergedBaseOptions = deepMerge(baseOptions, options);

    return deepMerge(mergedBaseOptions, {
      customCSS: [
        '.email_settings_panel { display: none; }',
        '.tox-toolbar__group { align-items: baseline !important; }',
        '.tox-toolbar:first-child>:last-child { justify-content: flex-start !important; flex-wrap: nowrap !important; }',
      ],
      customJS: [...CustomJs],
      features: {
        textEditor: {
          inlineFontControls: true,
        },
      },
    });
  };

  /**
   * default unlayer Design
   */
  const defaultDesignValue: Design = {
    body: { rows: [], values: { backgroundColor: '#ffffff' } },
  };

  /**
   * unlayer loadDesign method wrapper
   */
  const loadDesign = (design: Design) => {
    if (editor && editor.current) {
      editor.current.loadDesign(design);
    }
  };

  /**
   * unlayer loadDesign finished event wrapper
   */
  const editorReady = (): Promise<{ isReady: boolean }> =>
    new Promise((resolve) => {
      if (editor && editor.current) {
        editor.current.addEventListener('editor:ready', () => {
          resolve({ isReady: true });
        });
      }
    });

  /**
   * unlayer editor updated event wrapper
   */
  const editorUpdated = (): Promise<HtmlExport> =>
    new Promise((resolve) => {
      if (editor && editor.current) {
        editor.current.addEventListener('design:updated', () => {
          editor.current?.exportHtml(({ design, html }: HtmlExport) => {
            resolve({ design, html });
          });
        });
      }
    });

  /**
   * unlayer exportHtml method wrapper
   */
  const exportHtml = (): Promise<HtmlExport> =>
    new Promise((resolve, reject) => {
      if (editor && editor.current) {
        editor.current.exportHtml(({ design, html }: HtmlExport) => {
          resolve({ design, html });
        });
      } else {
        reject(
          new Error(
            'HTMLエディタの読み込みに失敗したため、保存処理ができません。',
          ),
        );
      }
    });

  /**
   * unlayer image file upload wrapper
   *
   * 2023.06.08
   * imageUploadで使用しているregisterCallbackは恐らく
   * addEventListenerの拡張
   * そのため、Promise関数として切り出した場合、最初の1回しか
   * イベント発火しないため、複数のimageをuploadしようとした場合
   * 2つ目以降のupload処理が行われない
   *
   * 解決策として、以下の処理は使用せず、それぞれのエディタ内で
   * editor.current.registerCallbackを実行するよう修正を行った
   */
  const imageUpload = (): Promise<{
    file: File;
    filePath: string;
    done: FileUploadDoneCallback;
  }> =>
    new Promise((resolve) => {
      if (editor && editor.current) {
        editor.current.registerCallback(
          'image',
          (file: FileInfo, done: FileUploadDoneCallback) => {
            const uploadPath = `public/${file.attachments[0].name}`;
            done({ progress: 50 });
            resolve({
              file: file.attachments[0],
              filePath: uploadPath,
              done,
            });
          },
        );
      }
    });

  /**
   * unlayer image file uploaded event wrapper
   */
  const imageUploadFinish = (): Promise<{ message: string }> =>
    new Promise((resolve) => {
      if (editor && editor.current) {
        editor.current.addEventListener('image:uploaded', () => {
          resolve({ message: 'ファイルをアップロードしました。' });
        });
      }
    });

  /**
   * unlayer CustomTools Paywall config
   */
  const customPayWallButtonToolsConfig = (
    courseList: Course[],
  ): ToolsConfig => ({
    'custom#paywall_button': {
      properties: {
        courseId: {
          editor: {
            data: {
              options: [
                { label: 'コースIDを選択してください', value: '' },
                ...courseList.map((x) => ({
                  label: `[${x.wall.name}] ${x.name}`,
                  value: x.id,
                })),
              ],
            },
          },
        },
      },
    },
  });

  return {
    unlayerId,
    editorOptions,
    editorOptionsWithCustomTools,
    defaultDesignValue,
    loadDesign,
    editorReady,
    editorUpdated,
    exportHtml,
    imageUpload,
    imageUploadFinish,
    customPayWallButtonToolsConfig,
  };
};
