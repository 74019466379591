import { Text, useDisclosure } from '@chakra-ui/react';
import { WallFormDefaultValues } from 'components/paywall//pages/WallList/initial';
import { Wall, WallFormType } from 'components/paywall//pages/WallList/typed';
import { Button } from 'components/paywall/atoms/Button';
import { usePaywall } from 'components/paywall/hooks/usePaywall';
import { usePaywallUtil } from 'components/paywall/hooks/usePaywallUtil';
import { Dialog } from 'components/paywall/molecules/Dialog';
import { FC, memo, useState } from 'react';
import { FormProvider, useForm } from 'react-hook-form';
import { Form } from './Form';

type Props = {
  isAdd?: boolean;
  buttonMode?: 'simple' | 'text';
  buttonName: string;
  obj?: Wall;
};

/**
 * @see ウォールの新規作成＆編集ダイアログ
 * @param isAdd 新規作成か編集かの判定
 * @param buttonMode ボタンに渡すモード
 * @param buttonName ボタンの名前
 * @param Wall ウォールのオブジェクト
 */
export const WallDialog: FC<Props> = memo(
  ({ isAdd, buttonMode, buttonName, obj }) => {
    const [isLoading, setLoading] = useState<boolean>(false);
    const { isOperationAuth } = usePaywallUtil();
    const methods = useForm<WallFormType>({
      mode: 'onBlur',
      defaultValues: WallFormDefaultValues,
    });
    const {
      getValues,
      reset,
      setError,
      formState: { errors },
    } = methods;
    const { isOpen, onOpen, onClose } = useDisclosure();
    const { saveWall, onDrawerClose } = usePaywall({
      getValues,
      isAdd,
      setError,
      onClose,
      reset,
      setLoading,
    });

    return (
      <FormProvider {...methods}>
        {buttonMode !== 'text' && (
          <Button
            mode={buttonMode}
            onClick={onOpen}
            disabled={!isOperationAuth()}
          >
            {buttonName}
          </Button>
        )}
        {buttonMode === 'text' && (
          <Text
            as="h3"
            fontSize="18px"
            fontWeight="700"
            lineHeight="140%"
            cursor="pointer"
            textDecoration="underline"
            onClick={onOpen}
          >
            {buttonName}
          </Text>
        )}
        <Dialog
          title={
            isAdd ? 'サブスクリプション新規作成' : 'サブスクリプション編集'
          }
          isOpen={isOpen}
          disabled={Object.keys(errors).length > 0}
          onClose={onDrawerClose}
          onSubmit={() => saveWall({ wallType: 'course' })}
          isLoading={isLoading}
        >
          <Form isAdd={isAdd} obj={obj} />
        </Dialog>
      </FormProvider>
    );
  },
);
