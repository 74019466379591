import { API_MESSAGES } from 'admin/define/apiMessages';
import { CustomError } from 'admin/error/CustomError';
import { memberLogsSchema } from 'admin/schema/member';
import { convertToPage, pageSchema } from 'admin/schema/page';
import { MemberLogsType, MemberType } from 'admin/types/member';
import { getDummySuccessResponse } from 'admin/utils/getDummyData';
import { DataWithPage, Page } from 'api/common/types';
import { Options } from 'ky';
import { ZodError } from 'zod';

const dummyPage: Page = {
  count: 123,
  currentPage: 1,
  countFrom: 11,
  countTo: 20,
  perPage: 50,
  pageCount: 30,
  next: 'http://api.example.org/accounts/?page=4',
  previous: 'http://api.example.org/accounts/?page=2',
};

const dummyData: MemberLogsType = [
  {
    id: '10',
    memberId: '123xxx45',
    historyDate: '2023/11/12 13:24',
    content: '会員削除（自主退会）',
  },
  {
    id: '9',
    memberId: '123xxx45',
    historyDate: '2023/11/12 13:24',
    content: '規約「利用規約」のバージョン１に同意',
  },
  {
    id: '8',
    memberId: '123xxx45',
    historyDate: '2023/11/12 13:24',
    content: 'ログイン',
  },
  {
    id: '7',
    memberId: '123xxx45',
    historyDate: '2023/11/12 13:24',
    content: 'ステータスの「無効」を解除',
  },
  {
    id: '6',
    memberId: '123xxx45',
    historyDate: '2023/11/12 13:24',
    content: 'ステータスを「無効」に変更',
  },
  {
    id: '5',
    memberId: '123xxx45',
    historyDate: '2023/11/12 13:24',
    content: 'ログイン',
  },
  {
    id: '4',
    memberId: '123xxx45',
    historyDate: '2023/11/12 13:24',
    content: 'ログイン',
  },
  {
    id: '3',
    memberId: '123xxx45',
    historyDate: '2023/11/12 13:24',
    content: 'ログイン',
  },
  {
    id: '2',
    memberId: '123xxx45',
    historyDate: '2023/11/12 13:24',
    content: 'ログイン',
  },
  {
    id: '1',
    memberId: '123xxx45',
    historyDate: '2023/11/12 13:24',
    content: '会員登録',
  },
];

export const getMemberLogs = async ({
  memberId,
  // TODO: 機能実装時にeslintコメントを削除する
  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  options,
}: {
  memberId: MemberType['uid'];
  options?: Options;
}): Promise<DataWithPage<MemberLogsType>> => {
  // TODO: APIの繋ぎ込みの際に実装する
  // const response = await request<getIdPoolConsumersType>({
  //   path,
  //   method: 'get',
  //   options,
  // });

  await new Promise<string>((resolve) => {
    setTimeout(() => {
      resolve('Timer done');
    }, 1000);
  });

  const filterData: MemberLogsType = dummyData.filter(
    (x) => x.memberId === memberId,
  );

  const response = await getDummySuccessResponse<DataWithPage<MemberLogsType>>({
    results: filterData,
    ...dummyPage,
  });

  if (response.hasError) {
    if (response.status === 404) {
      throw new CustomError(API_MESSAGES.MEMBER.GET_MEMBER_LOGS.NOT_FOUND, {
        path: 'dummy',
        status: response.status,
      });
    } else {
      throw new CustomError(API_MESSAGES.MEMBER.GET_MEMBER_LOGS.ERROR, {
        path: 'dummy',
        status: response.status,
      });
    }
  }
  try {
    memberLogsSchema.parse(response.data.results);
    pageSchema.parse(
      convertToPage<DataWithPage<MemberLogsType>>(response.data),
    );
  } catch (error) {
    if (error instanceof ZodError) {
      throw new CustomError(error.message, {
        path: 'dummy',
        status: response.status,
        logLevel: 'error',
      });
    }
  }

  return response.data;
};
