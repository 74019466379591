import {
  Box,
  Button,
  Flex,
  ListItem,
  StackDivider,
  Text,
  VStack,
} from '@chakra-ui/react';
import { AlertInfoBar } from 'admin/components/ui/alertInfo/alertInfoBar';
import { Colors } from 'admin/define/colors';
import { useUserCorporationInfo } from 'admin/hooks/user/useUserCorporationInfo';
import { ServiceType } from 'admin/types/service';
import { AUTH_CODE_TYPE_UNIIKEY } from 'define';
import { FC, memo, useMemo } from 'react';
import { NavigateFunction } from 'react-router-dom';

type Props = {
  service: ServiceType;
  onOpen: () => void;
  navigate: NavigateFunction;
};

export const ServiceBlockComponent: FC<Props> = memo(
  ({ service, onOpen, navigate }: Props) => {
    const { hasPermissionUserPool, isShowTenantService } =
      useUserCorporationInfo();

    const isAllowedUserPoolAllocation = useMemo<boolean>(
      () => service.userPoolId === null,
      [service],
    );
    const isUniikeyAuthenticationType = useMemo(
      () => Number(service.authenticationType) === AUTH_CODE_TYPE_UNIIKEY,
      [service.authenticationType],
    );
    const isShowMenuButton = useMemo<boolean>(
      () => isShowTenantService(service.userPoolId || ''),
      [service.userPoolId, isShowTenantService],
    );

    return (
      <ListItem
        border="1px solid"
        borderColor={Colors.GRAY_30}
        overflow="hidden"
        w="100%"
        borderRadius="8px"
        bg={Colors.WHITE}
      >
        <Flex
          alignItems="center"
          p="16px 24px"
          bgColor={Colors.GRAY_70}
          justifyContent="space-between"
        >
          <Text as="a" fontSize="18px" fontWeight="700">
            {service.name}
          </Text>
        </Flex>
        <VStack
          alignItems="flex-start"
          divider={<StackDivider borderColor={Colors.GRAY_30} />}
          p="24px"
          spacing={8}
        >
          <Box w="100%">
            <Flex justifyContent="space-between" alignItems="center">
              <Box>
                <Text fontWeight="700" fontSize="16px">
                  ユーザープール利用設定
                </Text>
              </Box>
              {hasPermissionUserPool && (
                <Box textAlign="center">
                  <Button
                    p="4px 24px"
                    fontSize="14px"
                    colorScheme="primary"
                    variant="outline"
                    onClick={onOpen}
                    isDisabled={!isAllowedUserPoolAllocation}
                  >
                    ユーザープール割り当て
                  </Button>
                </Box>
              )}
            </Flex>
            {/* ユーザープール未割当時 */}
            {!service.userPoolId && (
              <Box mt="16px">
                <AlertInfoBar type="warning" iconType="error">
                  <Text color={Colors.GRAY_03}>
                    ユーザープールが未割り当てです。
                  </Text>
                  {hasPermissionUserPool ? (
                    <>
                      <Text color={Colors.GRAY_03}>
                        「ユーザープール割り当て」ボタンからユーザープールを割り当ててください。
                      </Text>
                      <Text mt="8px" color={Colors.RED_20}>
                        ユーザープールは一度割り当てると変更出来ません。
                      </Text>
                    </>
                  ) : (
                    <Text color={Colors.GRAY_03}>
                      権限を持っている担当者に依頼してください。
                    </Text>
                  )}
                </AlertInfoBar>
              </Box>
            )}
            {/* ユーザープール割当時 */}
            {service.userPoolId && (
              <Box
                border="1px solid"
                borderColor={Colors.GRAY_30}
                overflow="hidden"
                w="100%"
                borderRadius="8px"
                bg={Colors.WHITE}
                mt="16px"
              >
                <Flex
                  alignItems="center"
                  p="8px 16px"
                  bgColor={Colors.GRAY_70}
                  justifyContent="space-between"
                >
                  <Text as="a" fontSize="14px" fontWeight="600">
                    ユーザープール名
                  </Text>
                </Flex>
                <Box p="8px 16px">
                  <Text fontSize="14px">{service.userPoolName}</Text>
                </Box>
              </Box>
            )}
          </Box>

          {service.userPoolId && isShowMenuButton && (
            <Box w="100%">
              <Flex justifyContent="space-between" alignItems="center">
                <Box>
                  <Text fontWeight="700" fontSize="16px">
                    属性設定
                  </Text>
                  <Text
                    fontSize="12px"
                    color={Colors.GRAY_00}
                    lineHeight="20.4px"
                  >
                    組織に登録されている各属性の利用設定状況が確認できます。
                  </Text>
                </Box>
                <Box>
                  <Button
                    p="4px 24px"
                    fontSize="14px"
                    width="118px"
                    colorScheme="primary"
                    variant="outline"
                    fontWeight="700"
                    onClick={() =>
                      navigate(
                        `/admin/service/${service.id}/${
                          service.userPoolChildId || ''
                        }/attribute`,
                      )
                    }
                  >
                    属性設定
                  </Button>
                </Box>
              </Flex>
              {/* TODO: API繋ぎこみ時に必要なレイアウトの為コメントアウト */}
              {/* <Box mt="16px">
                <AlertInfoBar type="warning" iconType="error">
                  <Text color={Colors.GRAY_03}>属性が未設定です。</Text>
                  <Text color={Colors.GRAY_03}>「属性設定」ボタンから属性を設定してください</Text>
                </AlertInfoBar>
              </Box> */}
            </Box>
          )}
          {/* TODO: 2024.4月末時点では不要となるため一旦コメントアウト */}
          {service.userPoolId &&
            isUniikeyAuthenticationType &&
            isShowMenuButton && (
              <Box w="100%">
                <Flex justifyContent="space-between" alignItems="center">
                  <Box>
                    <Text fontWeight="700" fontSize="16px">
                      規約設定
                    </Text>
                    <Text
                      fontSize="12px"
                      color={Colors.GRAY_00}
                      lineHeight="20.4px"
                    >
                      テナントで適用可能な規約の利用状況の管理を行います。
                    </Text>
                  </Box>
                  <Box>
                    <Button
                      p="4px 24px"
                      fontSize="14px"
                      width="118px"
                      colorScheme="primary"
                      variant="outline"
                      fontWeight="700"
                      onClick={() =>
                        navigate(
                          `/admin/service/${service.id}/${
                            service.userPoolChildId || ''
                          }/terms`,
                        )
                      }
                    >
                      規約設定
                    </Button>
                  </Box>
                </Flex>
              </Box>
            )}
          {/* TODO: 2024.4月末時点では不要となるため一旦コメントアウト */}
          {/* <Box mt="16px">
              <AlertInfoBar type="warning" iconType="error">
                <Text color={Colors.GRAY_03}>規約が未設定です。</Text>
                <Text color={Colors.GRAY_03}>「規約設定」ボタンから規約を設定してください</Text>
              </AlertInfoBar>
            </Box> */}
          {/* </Box> */}
          {service.userPoolId &&
            isShowMenuButton &&
            isUniikeyAuthenticationType && (
              <Box w="100%">
                <Flex justifyContent="space-between" alignItems="center">
                  <Box>
                    <Text fontWeight="700" fontSize="16px">
                      メールマガジン購読設定
                    </Text>
                    <Text
                      fontSize="12px"
                      color={Colors.GRAY_00}
                      lineHeight="20.4px"
                    >
                      テナントで利用可能なメールマガジン購読設定の利用状況の管理を行います。
                    </Text>
                  </Box>
                  <Box>
                    <Button
                      p="4px 24px"
                      fontSize="14px"
                      colorScheme="primary"
                      variant="outline"
                      fontWeight="700"
                      onClick={() =>
                        navigate(
                          `/admin/service/${service.id}/${
                            service.userPoolChildId || ''
                          }/newsletter`,
                        )
                      }
                    >
                      メールマガジン購読設定
                    </Button>
                  </Box>
                </Flex>
              </Box>
            )}
          {service.userPoolId &&
            isShowMenuButton &&
            isUniikeyAuthenticationType && (
              <Flex w="100%" justifyContent="space-between" alignItems="center">
                <Box>
                  <Text fontWeight="700" fontSize="16px">
                    認証認可とセキュリティ設定
                  </Text>
                </Box>
                <Box>
                  <Button
                    p="4px 24px"
                    fontSize="14px"
                    colorScheme="primary"
                    variant="outline"
                    fontWeight="700"
                    onClick={() =>
                      navigate(
                        `/admin/service/${service.id}/${
                          service.userPoolChildId || ''
                        }/auth`,
                      )
                    }
                  >
                    認証認可とセキュリティ設定
                  </Button>
                </Box>
              </Flex>
            )}
        </VStack>
      </ListItem>
    );
  },
);
