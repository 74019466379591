import { Box, Flex, Text } from '@chakra-ui/react';
import { Link } from 'components/paywall/atoms/Link';
import { Colors } from 'components/paywall/config';
import { FC, memo } from 'react';
import { FormSettingBody } from './FormSettingItem/FormSettingBody';

export const FormSettingInner: FC = memo(() => (
  <>
    <Flex mt="20px">
      <Link to="/setting">
        <Flex>
          <Text
            fontSize="14px"
            color={Colors.GRAY_20}
            fontWeight="700"
            cursor="pointer"
          >
            設定
          </Text>
          <Text as="span" color={Colors.BLACK} fontWeight="700">
            &nbsp;/&nbsp;
          </Text>
        </Flex>
      </Link>
      <Text fontSize="14px" color={Colors.BLACK} fontWeight="700">
        フォーム関連設定
      </Text>
    </Flex>
    <Flex>
      <Box>
        <Text
          mt="24px"
          fontSize="18px"
          lineHeight="170%"
          color={Colors.BLACK}
          fontWeight="700"
        >
          フォーム関連設定
        </Text>
        <Text>
          フォーム関連の設定、内容についてカスタマイズすることができます。
        </Text>
      </Box>
    </Flex>
    <FormSettingBody />
  </>
));
