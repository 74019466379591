import { SystemError } from 'api/error';
import { isNoticeList, NoticeList } from 'api/notice/types';
import { request } from 'api/request';
import { Options } from 'ky';
import { messages } from './messages';

// メッセージボックス & メール通知取得
export const getNotice = async (
  tenantId: string,
  page: number,
  options?: Options,
): Promise<NoticeList> => {
  const queryParam = [];
  queryParam.push(`page=${page}`);
  const param = queryParam.length > 0 ? `?${queryParam.join('&')}` : '';
  const path = `notice/${tenantId}/${param}`;

  const response = await request({
    path,
    method: 'get',
    options,
  });

  const responseData = (await response.data) as unknown[];

  if (response.hasError) {
    throw new SystemError(messages.getNotice.customer.error, {
      path,
      options,
      response: responseData,
      status: response.status,
    });
  }

  if (!isNoticeList(responseData)) {
    throw new SystemError(messages.getNotice.customer.typeError, {
      path,
      options,
      status: response.status,
      response: responseData,
      logLevel: 'error',
    });
  }

  return responseData;
};
