import { VFC, memo } from 'react';
import { VStack, Heading, Box, Text, Flex } from '@chakra-ui/react';
import { EnqueteSurveyForm, Skin } from 'api/enquete/types';
import { SelectForm } from 'components/common/molecules';

type Props = {
  skinList: Skin[];
};

export const EnqueteDesignSkin: VFC<Props> = memo(({ skinList }: Props) => (
  <VStack spacing={6} pb={10}>
    <Heading as="h2" size="md" pb={2} w="100%" borderBottom="2px solid #4299e1">
      デザインスキン設定
    </Heading>
    <Box w="full" bg="#fff" borderWidth="1px" borderRadius="md" px="8" py="6">
      <Text fontSize={16} fontWeight="bold" mb={2}>
        デザインスキン
      </Text>
      <Text fontSize={14}>
        作成済みのデザインスキンを選択してください。指定がない場合には、初期デザインが適用されます。
        <br />
        新規デザインスキンは、フォーム一覧画面の「デザインスキン」から作成できます。
      </Text>
      <Flex
        alignItems="center"
        padding="10px 24px 0"
        marginTop={3}
        borderTop="1px solid #edf2f6"
      >
        <Text w={170}>デザインスキン選択</Text>
        <SelectForm<EnqueteSurveyForm>
          name="skin"
          attr={{
            required: '',
          }}
          optionDefault="true"
          optionList={skinList.map((skin) => (
            <option key={skin.id} value={skin.id}>
              {skin.name}
            </option>
          ))}
        />
      </Flex>
    </Box>
  </VStack>
));
