import { Box } from '@chakra-ui/react';
import { useTransactionMailEditor } from 'admin/hooks/userPool/transactionMail/useTransactionMailEditor';
import { TransactionMailFormType } from 'admin/types/userPool/transactionMail';
import { useEmailEditor } from 'hooks/useEmailEditor';
import {
  Dispatch,
  FC,
  memo,
  RefObject,
  SetStateAction,
  useCallback,
} from 'react';
import EmailEditor from 'react-email-editor';
import { useFormContext } from 'react-hook-form';

type HtmlEditorProps = {
  editor: RefObject<EmailEditor>;
  setIsLoading: Dispatch<SetStateAction<boolean>>;
  isLoading: boolean;
};

export const HtmlEditor: FC<HtmlEditorProps> = memo(
  ({ editor, setIsLoading, isLoading }) => {
    const { unlayerId, editorOptions } = useEmailEditor({
      editor,
      options: { user: { id: 0 } },
    });

    const { setValue, getValues } = useFormContext<TransactionMailFormType>();
    const { handleLoad } = useTransactionMailEditor({
      editor,
      getValues,
      setValue,
    });

    const onEditorReady = useCallback(() => {
      handleLoad({ setIsLoading });
    }, [handleLoad, setIsLoading]);

    return (
      <Box w="100%" h="calc(100vh - 180px)">
        {!isLoading && <Box />}
        <EmailEditor
          ref={editor}
          projectId={unlayerId}
          options={editorOptions()}
          style={{
            minHeight: 'calc(100vh - 210px)',
            minWidth: 'initial',
          }}
          onReady={onEditorReady}
        />
      </Box>
    );
  },
);
