import { Box, BoxProps, Table as ChakraTable } from '@chakra-ui/react';
import { FC, memo, ReactNode } from 'react';

type Props = {
  children: ReactNode;
  maxHeight?: string;
  tableWidth?: string;
  variant?: string;
  tableHeight?: string;
} & BoxProps;

export const Table: FC<Props> = memo(
  ({ children, maxHeight, tableWidth, variant, tableHeight }: Props) => (
    <Box
      w={tableWidth}
      overflow="hidden"
      borderRadius="4px"
      borderTop="solid 1px #B9B9B9"
      borderLeft="solid 1px #B9B9B9"
      borderRight="solid 1px #B9B9B9"
    >
      <Box overflowX="auto" maxHeight={maxHeight}>
        <ChakraTable
          maxH="calc(100vh - 281px)"
          overflowY="auto"
          variant={variant}
          h={tableHeight}
        >
          {children}
        </ChakraTable>
      </Box>
    </Box>
  ),
);

Table.defaultProps = {
  maxHeight: '',
  tableWidth: '100%',
  variant: '',
};
