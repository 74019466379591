import { VFC, useCallback, Dispatch, SetStateAction } from 'react';
import { useFormContext } from 'react-hook-form';
import { useDropzone } from 'react-dropzone';
import { Flex } from '@chakra-ui/react';
import { AiOutlineCloudUpload } from 'react-icons/ai';
import { MailSetting } from 'api/mail/types';
import {
  isCsvFileUploadResponse,
  isGcsFileUploadResponse,
} from 'api/storage/types';
import { useGcsUpload } from 'hooks/storage/useGcsUpload';
import { useCsvGcsUpload } from 'hooks/mail/useCsvGcsUpload';

type FileUploadProps = {
  type: string;
  ext?: string;
  uploadParentPath: string;
  modalClose?: () => void;
  setFileName: Dispatch<SetStateAction<string>>;
  setSegmentTarget?: (val: number) => void;
  setCsvUploadError?: (val: string) => void;
};

export const FileUpload: VFC<FileUploadProps> = ({
  type,
  ext = '',
  uploadParentPath,
  modalClose = () => undefined,
  setFileName,
  setSegmentTarget = () => undefined,
  setCsvUploadError = () => undefined,
}) => {
  const { setValue, getValues } = useFormContext<MailSetting>();
  const upload = useGcsUpload();
  const csvUpload = useCsvGcsUpload({ mailId: getValues('id') });
  const onDrop = useCallback(
    async (files: File[]) => {
      if (files.length === 0) return;
      const uploadPath = `${uploadParentPath}/${files[0].name}`;
      // アドレスファイルアップロード
      if (type === 'address') {
        const uploadResult = await upload(files[0], uploadPath);
        if (isGcsFileUploadResponse(uploadResult)) {
          setFileName(files[0].name);
          setValue('addressFile', uploadResult.filepath);
          setValue('addressFileOrigin', files[0].name);
        }
        // CSVファイルアップロード
      } else if (type === 'csvFile') {
        setCsvUploadError('');
        setFileName('');
        setSegmentTarget(0);
        const uploadResult = await csvUpload(files[0], uploadPath);
        if (uploadResult !== null && isCsvFileUploadResponse(uploadResult)) {
          if (!uploadResult.error || uploadResult.error === '') {
            setFileName(files[0].name);
            setValue('addressFile', uploadResult.filepath);
            setValue('addressFileOrigin', files[0].name);
            setSegmentTarget(uploadResult.addressCount);
          } else {
            setFileName('');
            setCsvUploadError(uploadResult.error || '');
            setSegmentTarget(0);
          }
        }
      }

      modalClose();
    },
    [
      modalClose,
      setFileName,
      setValue,
      upload,
      csvUpload,
      uploadParentPath,
      type,
      setSegmentTarget,
      setCsvUploadError,
    ],
  );

  const { getRootProps, getInputProps } = useDropzone({
    onDrop,
  });

  return (
    <div className="container">
      <div {...getRootProps({ className: 'dropzone' })}>
        <input {...getInputProps()} accept={ext} />
        <Flex
          bg="teal.50"
          color="teal.200"
          w="full"
          h={48}
          _hover={{
            cursor: 'pointer',
            backgroundColor: 'gray.100',
          }}
          direction="column"
          alignItems="center"
          justifyContent="center"
          style={{
            transition: 'all 300ms 0s ease',
          }}
          borderRadius="md"
        >
          <AiOutlineCloudUpload size="4em" />
          <p>ファイルをドラッグ＆ドロップしてください</p>
        </Flex>
      </div>
    </div>
  );
};
