import { Box, Flex, Grid } from '@chakra-ui/react';
import { ErrorTextMsg } from 'components/common/atoms/ErrorTextMsg';
import { SelectForm } from 'components/common/molecules';
import { WallFormDefaultValues } from 'components/paywall//pages/WallList/initial';
import { Wall, WallFormType } from 'components/paywall//pages/WallList/typed';
import { Input } from 'components/paywall/atoms/Input';
import { useWallList } from 'hooks/paywall/useWallList';
import { FC, memo, useEffect } from 'react';
import { useFormContext } from 'react-hook-form';
import { toErrMsgList } from 'utils/form';

type Props = {
  isAdd?: boolean;
  obj?: Wall;
};
export const Form: FC<Props> = memo(({ isAdd, obj }) => {
  const {wallList} = useWallList({ wallType: 'course' });
  const {
    setValue,
    register,
    formState: { errors },
  } = useFormContext<WallFormType>();

  const parentWallList = (): { val: string | number; name: string }[] => {
    const excludedSelfCourseList = wallList.filter((x) => x.id !== obj?.id);

    return [
      // TODO: 2023/03/03 元のSELECTを使用する関係で下記記述をコメントアウトする
      // { val: '', name: '未選択' },
      ...excludedSelfCourseList.map((x) => ({ val: x.id, name: x.name })),
    ];
  };

  useEffect(() => {
    if (!isAdd) {
      setValue('id', obj?.id);
      setValue('name', obj?.name || WallFormDefaultValues.name);
      setValue('parent', obj?.parent || WallFormDefaultValues.parent);
    }
  }, [isAdd, setValue, obj]);

  return (
    <form>
      <Grid gap={5}>
        <Box>
          {isAdd ? (
            <>サブスクリプションの新規作成を行います</>
          ) : (
            <>サブスクリプションの編集を行います</>
          )}
        </Box>

        {isAdd ? (
          <></>
        ) : (
          <Flex style={{ fontSize: 14, alignItems: 'center' }}>
            <Box style={{ width: '50px' }}>ID:</Box>
            <Box style={{ fontSize: 18 }}>{obj?.id}</Box>
          </Flex>
        )}

        <Grid gap={2}>
          <Box>名前</Box>
          <Input
            defaultValue={isAdd ? WallFormDefaultValues.name : obj?.name}
            ref={register('name', {
              required: '名前を入力してください',
            })}
          />
          {toErrMsgList(errors, 'name').map((err, idx) => (
            <ErrorTextMsg key={`error-paywall-${String(idx)}`} msg={err} />
          ))}
        </Grid>
        <Grid gap={2}>
          <SelectForm<Wall>
            name="parent"
            label="親サブスクリプション"
            placeholder="選択してください"
            attr={{
              required: '',
            }}
            optionList={parentWallList().map((item) => (
              <option key={`wall_parent_${item.val}`} value={item.val}>
                {item.name}
              </option>
            ))}
            isDisabled={!isAdd}
          />
        </Grid>
      </Grid>
    </form>
  );
});
