import { getLoginSetting } from 'api/tenant/getLoginSetting';
import { LoginSetting } from 'api/tenant/types';
import { useCustomToast } from 'hooks/useCustomToast';
import { useQuery } from 'react-query';
import { queryTenantKey } from './queryTenantKey';

/**
 * ログインリダイレクト設定取得 hooks
 */
export const useLoginSetting = ({
  tenantId,
}: {
  tenantId: string;
}): LoginSetting | undefined => {
  const toast = useCustomToast();
  const response = useQuery(
    queryTenantKey.getLoginSetting({ tenantId }),
    () => getLoginSetting(tenantId),
    {
      onError: (err: unknown) => {
        if (err instanceof Error) {
          toast.closeAll();
          toast({
            title: err.message,
            status: 'error',
          });
        }
      },
    },
  );
  const { data } = response;

  return data;
};
