import { VFC, memo, useState, useEffect, useCallback } from 'react';
import { useFormContext } from 'react-hook-form';
import {
  Box,
  Flex,
  Text,
  Stack,
  Image,
  IconButton,
  Badge,
  Tooltip,
} from '@chakra-ui/react';
import { QuestionIcon, CloseIcon } from '@chakra-ui/icons';
import { useDropzone } from 'react-dropzone';
import { BiImageAdd } from 'react-icons/bi';
import { gcsFileUpload } from 'api/storage/gcsFileUpload';
import { isGcsFileUploadResponse } from 'api/storage/types';
import { successToast, errorToast } from 'utils/toast';
import { useUserTenantId } from 'hooks/user/useUserTenantId';
import { useCustomToast } from 'hooks/useCustomToast';
import { SelectForm } from 'components/common/molecules';
import { InputTextForm, ColorPicker } from 'components/common/atoms';
import { SkinItemPreview } from 'components/enquete/atoms/SkinItemPreview';
import { Skin } from 'api/enquete/types';
import { SkinDefaultValues } from 'components/enquete/EnqueteSkin';

type LogoPositionType = {
  [key: string]: string;
};

export const SkinTitle: VFC = memo(() => {
  const [isUploading, setIsUploading] = useState(false);
  const [logoUrl, setLogoUrl] = useState(SkinDefaultValues.logoUrl);
  const [logoPosition, setLogoPosition] = useState<LogoPositionType>({
    left: '2px',
  });
  const [titleTextColor, setTitleTextColor] = useState(
    SkinDefaultValues.titleTextColor,
  );
  const [titleBgColor, setTitleBgColor] = useState(
    SkinDefaultValues.titleBgColor,
  );
  const toast = useCustomToast();
  const tenantId = useUserTenantId();
  const { setValue, getValues, watch } = useFormContext<Skin>();

  useEffect(() => {
    const skinViewData = watch((value, { name }) => {
      if (name === 'logoUrl') {
        setLogoUrl(value[name] || '');
      }
      if (name === 'logoPosition') {
        if (value[name] === 'center') {
          setLogoPosition({ left: '50%', transform: 'translateX(-50%)' });
        } else if (value[name] === 'left') {
          setLogoPosition({ left: '2px' });
        } else if (value[name] === 'right') {
          setLogoPosition({ right: '44px' });
        }
      }
      if (name === 'titleTextColor') {
        setTitleTextColor(value[name] || '');
      }
      if (name === 'titleBgColor') {
        setTitleBgColor(value[name] || '');
      }
    });

    return () => skinViewData.unsubscribe();
  }, [watch]);

  const onDrop = useCallback(
    (acceptedFiles: File[]) => {
      if (acceptedFiles.length !== 0) setLogoUrl('/test.png');
      setIsUploading(true);
      const unixTimeStamp = Math.floor(new Date().getTime() / 1000);
      const uploadPath = `public/enquete/${tenantId}/skin/${unixTimeStamp}_${acceptedFiles[0].name}`;
      gcsFileUpload({
        file: acceptedFiles[0],
        filePath: uploadPath,
        tenantId,
      })
        .then((res) => {
          if (isGcsFileUploadResponse(res)) {
            setLogoUrl(res.publicUrl);
            setValue('logoUrl', res.publicUrl);
            setIsUploading(false);
            toast({
              ...successToast,
              title: 'ファイルをアップロードしました。',
            });
          }
        })
        .catch((_) => {
          toast({
            ...errorToast,
            title: 'ファイルアップロードに失敗しました。',
          });
        });
    },
    [setValue, tenantId, toast],
  );

  const { getRootProps, getInputProps } = useDropzone({
    onDrop,
    accept: 'image/*',
    multiple: false,
  });

  const style = {
    width: '100%',
    background: '#e7fff9',
    cursor: 'pointer',
    padding: '80px 20px',
  };

  const styleIcon = {
    display: 'block',
    fontSize: '3rem',
    margin: '0 auto',
    color: '888',
  };

  const position = [
    { id: 1, name: '左', value: 'left' },
    { id: 2, name: '中央', value: 'center' },
    { id: 3, name: '右', value: 'right' },
  ];

  /**
   * 画像削除処理
   */
  const onDeleteImage = useCallback(() => {
    setLogoUrl('');
    setValue('logoUrl', '');
  }, [setValue]);

  /**
   * ロゴ画像表示用エレメント
   */
  const logoPreviewElement = () => {
    if (!isUploading && logoUrl !== '') {
      return (
        <>
          <Image
            src={logoUrl}
            objectFit="contain"
            h="100%"
            maxW="initial"
            margin={logoPosition}
          />
          <IconButton
            position="absolute"
            top="0px"
            right="0px"
            transform="translate(50%, -50%) scale(0.7)"
            colorScheme="red"
            aria-label="Call Segun"
            size="xs"
            isRound
            icon={<CloseIcon />}
            onClick={onDeleteImage}
          />
        </>
      );
    }

    return <></>;
  };

  return (
    <Flex alignItems="stretch" mb={4}>
      <Box minW="220px" mt="2px">
        <Text as="label">タイトル</Text>
      </Box>
      <Stack minW="250px">
        <Box>
          <Flex alignItems="center">
            <Text as="span" fontSize="xs" color="teal.800">
              <Badge variant="solid" colorScheme="gray" transform="scale(0.8)">
                任意
              </Badge>
              ロゴ画像アップロード
            </Text>
            <Tooltip label="フォームタイトルに自社のロゴ画像を表示することができます。">
              <QuestionIcon
                ml={4}
                color="gray.600"
                style={{ cursor: 'pointer' }}
              />
            </Tooltip>
          </Flex>
          <div
            {...getRootProps({ style })}
            style={{
              padding: '4px',
              color: '#1a202c',
              backgroundColor: 'rgb(231, 255, 249)',
              cursor: 'pointer',
              borderRadius: '4px',
            }}
          >
            <input {...getInputProps()} />
            <p>
              <BiImageAdd style={styleIcon} />
            </p>
          </div>
        </Box>
        <Box>
          <SelectForm<Skin>
            name="logoPosition"
            label="ロゴ画像表示位置"
            labelFontSize="xs"
            labelFontColor="teal.800"
            attr={{
              required: '',
            }}
            optionList={position.map((grp) => (
              <option key={grp.id} value={grp.value}>
                {grp.name}
              </option>
            ))}
          />
        </Box>
        <Flex alignItems="flex-end">
          <InputTextForm<Skin>
            label="タイトル帯背景色"
            labelCaption="タイトル帯背景色"
            name="titleBgColor"
            value={getValues('titleBgColor')}
            placeholder="#FFFFFF"
            mr={4}
          />
          <ColorPicker<Skin>
            name="titleBgColor"
            value={getValues('titleBgColor')}
            width="40px"
            height="40px"
          />
        </Flex>
        <Flex alignItems="flex-end">
          <InputTextForm<Skin>
            label="タイトル帯文字色"
            labelCaption="タイトル帯文字色"
            name="titleTextColor"
            value={getValues('titleTextColor')}
            placeholder="#FFFFFF"
            mr={4}
          />
          <ColorPicker<Skin>
            name="titleTextColor"
            value={getValues('titleTextColor')}
            width="40px"
            height="40px"
          />
        </Flex>
      </Stack>
      <SkinItemPreview
        element={
          <Box p="60px 0px" bg="#FFF" borderRadius="6px" position="relative">
            <Box h="55px" position="absolute" top="2px" {...logoPosition}>
              {logoPreviewElement()}
            </Box>
            <Box
              position="absolute"
              top="15px"
              right="8px"
              width="28px"
              height="28px"
              _before={{
                position: 'absolute',
                top: '50%',
                left: '0',
                marginTop: '-1px',
                content: '""',
                width: '100%',
                height: '2px',
                display: 'block',
                backgroundColor: '#aaa',
                pointerEvents: 'none',
                transform: 'rotate(-45deg)',
              }}
              _after={{
                position: 'absolute',
                top: '50%',
                left: '0',
                marginTop: '-1px',
                content: '""',
                width: '100%',
                height: '2px',
                display: 'block',
                backgroundColor: '#aaa',
                pointerEvents: 'none',
                transform: 'rotate(45deg)',
              }}
            />
            <Box>
              <Text
                as="h1"
                w="100%"
                fontSize="26px"
                p="20px"
                textAlign="center"
                fontWeight="bold"
                color={titleTextColor}
                bgColor={titleBgColor}
              >
                タイトル
              </Text>
            </Box>
          </Box>
        }
        bgColor="#000000BF"
      />
    </Flex>
  );
});
