import { Options } from 'ky';
import { request } from 'api/request';

const path = 'user/logout/';

export const logout = async ({
  options = {},
}: {
  options?: Options;
}): Promise<boolean> => {
  const response = await request({
    path,
    method: 'get',
    options,
  });

  if (response.status === 200) {
    return true;
  }

  return false;
};
