import {
  AlertDialog,
  AlertDialogBody, AlertDialogCloseButton, AlertDialogContent, AlertDialogFooter,
  AlertDialogHeader, AlertDialogOverlay, Button,
  ButtonProps, Flex
} from '@chakra-ui/react';
import { FC, memo, useCallback, useRef } from 'react';
import { MdError } from 'react-icons/md';

type Props = {
  title?: string;
  isTitleWithIcon?: boolean;
  children: React.ReactNode;
  openBtnElement?: React.ReactNode;
  openBtnProps?: ButtonProps;
  cancelBtnTitle?: string;
  cancelBtnProps?: ButtonProps;
  cancelBtnHandelr?: () => void;
  submitBtnTitle?: string;
  submitBtnProps?: ButtonProps;
  submitBtnHandelr?: () => void;
  isOpen: boolean;
  onOpen?: () => void;
  onClose: () => void;
  size?: string;
};

export const DetailDialog: FC<Props> = memo(
  ({
    title = '',
    isTitleWithIcon = true,
    children,
    openBtnElement = undefined,
    openBtnProps = {},
    cancelBtnTitle = '',
    cancelBtnProps = { variant: 'secondary' },
    cancelBtnHandelr = () => undefined,
    submitBtnTitle = '',
    submitBtnProps = { variant: 'primary' },
    submitBtnHandelr = () => undefined,
    isOpen,
    onOpen = () => undefined,
    onClose,
    size = 'xl',
  }) => {
    const cancelRef = useRef<HTMLInputElement>(null);

    const closeHandler = useCallback(() => {
      if (cancelBtnHandelr) {
        cancelBtnHandelr();
      }
      onClose();
    }, [cancelBtnHandelr, onClose]);

    const clickHandler = useCallback(() => {
      if (submitBtnHandelr) {
        submitBtnHandelr();
      }
    }, [submitBtnHandelr]);

    return (
      <>
        {!!openBtnElement && (
          <Button type="button" {...openBtnProps} onClick={onOpen}>
            {openBtnElement}
          </Button>
        )}
        <AlertDialog
          size={size}
          motionPreset="slideInBottom"
          leastDestructiveRef={cancelRef}
          onClose={onClose}
          isOpen={isOpen}
          isCentered
          closeOnOverlayClick={false}
          closeOnEsc={false}
        >
          <AlertDialogOverlay/>

          <AlertDialogContent>
            {title !== '' && (
              <AlertDialogHeader >
                <Flex alignItems="center" style={{gap: '8px'}}>
                  {isTitleWithIcon && <MdError fontSize="1.5rem"/>}
                  {title}
                </Flex>
              </AlertDialogHeader>
            )}
            <AlertDialogCloseButton zIndex={1} />
            <AlertDialogBody>{children}</AlertDialogBody>
            <AlertDialogFooter>
              {cancelBtnTitle && (
                <Button onClick={closeHandler} {...cancelBtnProps}>
                  {cancelBtnTitle}
                </Button>
              )}
              {submitBtnTitle && (
                <Button
                  data-testid="deletePopup-deleteBtn"
                  onClick={clickHandler}
                  ml={3}
                  {...submitBtnProps}
                >
                  {submitBtnTitle}
                </Button>
              )}
            </AlertDialogFooter>
          </AlertDialogContent>
        </AlertDialog>
      </>
    );
  },
);
