import { Colors } from 'components/paywall/config';
import {
  CardItem,
  CardItemBlock,
  CardItemList,
  CardItemListItem,
} from 'components/paywall/features/Card';
import { StatusTag } from 'components/paywall/features/StatusTag';
import { Promotion } from 'components/paywall/pages/PromotionList/typed';
import { format } from 'date-fns';
import { useAmountUtil } from 'hooks/useAmountUtil';
import { FC, memo } from 'react';

type Props = {
  promotion: Promotion;
};
type StatusType = {
  code: number;
  name: string;
  status: number;
};

const statusTypes: StatusType[] = [
  { code: 1, name: '開始前', status: 1 },
  { code: 2, name: '実施中', status: 2 },
  { code: 1, name: '終了済み', status: 3 },
];

const DATETIME_FORMAT = 'yyyy年MM月dd HH:mm';

export const PromotionCardItem: FC<Props> = memo(({ promotion }: Props) => {
  const { amountFormat, calcTaxAmount } = useAmountUtil();

  const formatDate = (date: string | null): string =>
    date ? format(new Date(date), DATETIME_FORMAT) : '';

  const getStatus = (statusCode: number) => {
    const result = statusTypes.filter((item) => item.status === statusCode);

    // ステータスが見つからなかった場合はdefaultを返却する
    if (result.length === 0)
      return {
        code: 0,
        name: 'なし',
        status: 0,
      };

    return result[0];
  };

  return (
    <>
      {/* プロモーション */}
      <CardItem spacing="32px" isDivider={false}>
        <CardItemBlock
          w="100%"
          backgroundColor={Colors.GRAY_65}
          borderRadius="8px"
          p="16px"
          mb="8px"
        >
          <CardItemList spacing="8px" fontFamily="Hiragino sans">
            {/* 回数 */}
            <CardItemListItem
              label="回数"
              text={promotion.month !== null ? `${promotion.month}回` : '-'}
            />
            {/* プロモーションの期間 */}
            <CardItemListItem
              label="プロモーションの期間"
              text={
                promotion.startDate !== null || promotion.endDate !== null
                  ? `${formatDate(promotion.startDate)} ～ ${formatDate(
                      promotion.endDate,
                    )}`
                  : '-'
              }
            />
            {/* 税抜価格 */}
            <CardItemListItem
              label="税抜価格"
              text={
                promotion.price !== null
                  ? `${amountFormat(promotion.price || 0)}円`
                  : '-'
              }
            />
            {/* 税込み価格 */}
            <CardItemListItem
              label="税込み価格"
              text={
                promotion.price !== null
                  ? `${calcTaxAmount(promotion.price || 0)}円`
                  : '-'
              }
            />
            {/* ステータス */}
            <CardItemListItem
              label="ステータス"
              text={
                <StatusTag
                  type={getStatus(promotion.sellStatus).code}
                  status={getStatus(promotion.sellStatus).name}
                />
              }
            />
          </CardItemList>
        </CardItemBlock>
      </CardItem>
    </>
  );
});
