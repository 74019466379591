import { Api } from 'components/api/Api';
import { AuthHub } from 'components/authHub';
import { AuthHubRoutes } from 'components/authHub/AuthHubRoutes';
import { Contentsdb } from 'components/contentsdb/Contentsdb';
import { contetsdbRoutes } from 'components/contentsdb/contetsdbRoutes';
import { Enquete } from 'components/enquete';
import { EnqueteRoutes } from 'components/enquete/EnqueteRoutes';
import { Home } from 'components/home/Home';
import { Mail } from 'components/mail';
import { MailRoutes } from 'components/mail/MailRoutes';
import { Paywall } from 'components/paywall';
import { PaywallRoutes } from 'components/paywall/PaywallRoutes';
import { Segment } from 'components/segment';
import { SegmentRoutes } from 'components/segment/SegmentRoutes';
import { Setting } from 'components/setting/Setting';
import { settingRoutes } from 'components/setting/settingRoutes';
import { DraggableTest } from 'components/test/DraggableTest';
import { TransactionMail } from 'components/transactionMail';
import { TransactionMailRoutes } from 'components/transactionMail/TransactionMailRoutes';
import { permissionCheckRoutingControl } from 'hoc/permissionCheckRoutingControl';
import { RouteObject } from 'react-router-dom';

// HOCを適用する
const PaywallWithAuthCheckRouting = permissionCheckRoutingControl(
  Paywall,
  'paywall',
  '/',
);

const notFoundElement: RouteObject = {
  path: '*',
  element: <></>,
};

export const TenantRoutes: RouteObject[] = [
  { path: 'api', element: <Api /> },
  { path: 'setting', element: <Setting />, children: [...settingRoutes] },
  {
    path: 'contentsdb',
    element: <Contentsdb />,
    children: [contetsdbRoutes, notFoundElement],
  },
  { path: 'enquete', element: <Enquete />, children: [...EnqueteRoutes] },
  { path: 'mail', element: <Mail />, children: [...MailRoutes] },
  {
    path: 'segment',
    element: <Segment />,
    children: [...SegmentRoutes],
  },
  {
    path: 'paywall',
    element: <PaywallWithAuthCheckRouting />,
    children: [...PaywallRoutes],
  },
  {
    path: 'transactionMail',
    element: <TransactionMail />,
    children: [...TransactionMailRoutes],
  },
  {
    path: 'authHub',
    element: <AuthHub />,
    children: [...AuthHubRoutes],
  },
  { path: 'test/Draggable', element: <DraggableTest /> },
  { path: '/', element: <Home /> },
  { path: '*', element: <Home /> },
];
