import { withSuspenseAndErrorBoundary } from 'admin/components/Error/WithErrorBoundary';
import { LoadingLayer } from 'admin/components/ui/loadingLayer';
import { useChangeAutoAppend } from 'admin/hooks/userPool/terms/useChangeAutoAppend';
import { TermType } from 'admin/types/userPool/terms';
import { FC, memo } from 'react';
import { AutoAppendModalComponent } from './AutoAppendModalComponent';

type Props = {
  onClose: () => void;
  term: TermType | null;
};

const FetchAutoAppendModal: FC<Props> = memo(({ onClose, term }) => {
  const { mutate, isLoading } = useChangeAutoAppend(term?.id ?? '');

  const onSubmit = async () => {
    await mutate(!!term?.isAutoAppendToChild);
    onClose();
  };

  return (
    <>
      {isLoading && <LoadingLayer />}
      <AutoAppendModalComponent
        onClose={onClose}
        onSubmit={onSubmit}
        term={term}
      />
    </>
  );
});

export const AutoAppendModal =
  withSuspenseAndErrorBoundary(FetchAutoAppendModal);
