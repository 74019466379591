import { Colors } from 'admin/define/colors';
import {
  ADMIN_MENU_BACKGROUND_COLOR,
  ADMIN_MENU_WIDTH_CLOSE,
  ADMIN_MENU_WIDTH_OPEN,
  HEADER_HEIGHT,
  MENU_WIDTH_OPEN,
  NOTIFICATION_MARGIN,
  Z_INDEX_HEADER,
  Z_INDEX_MENU,
} from 'theme';

/**
 * Adminページ用 拡張 ChakraUi theme config
 */
export const AdminTheme = {
  CustomAdminHeaderBox: {
    baseStyle: () => ({
      height: HEADER_HEIGHT,
      // width, maxWidth はスクロールバーの表示によるずれを防ぐため vw 指定
      width: '100vw',
      left: 0,
      bg: 'white',
      padding: '0 10px',
      position: 'fixed',
      zIndex: Z_INDEX_HEADER,
      marginTop: `${NOTIFICATION_MARGIN} !important`,
      borderBottom: '1px solid #EAEAEC',
    }),
  },
  CustomAdminContainerBox: {
    baseStyle: ({ isActive = false }: { isActive?: boolean }) => ({
      bg: '#E1E9F0',
      minHeight: `calc(100vh - ${HEADER_HEIGHT})`,
      width: `${
        isActive
          ? `calc(100% - (${ADMIN_MENU_WIDTH_OPEN}))`
          : `calc(100% - ${ADMIN_MENU_WIDTH_CLOSE})`
      }`,
      ml: isActive ? ADMIN_MENU_WIDTH_OPEN : ADMIN_MENU_WIDTH_CLOSE,
      mt: `calc(48px + ${NOTIFICATION_MARGIN})`,
      zIndex: 4,
      transition: 'all 0.2s ease',
    }),
  },
  CustomAdminContainerInnerBox: {
    baseStyle: () => ({
      width: '100%',
      height: '100%',
      padding: 6,
      transition: 'all 0.2s ease',
    }),
  },
  CustomMenuBox: {
    baseStyle: ({ isOpen = false }: { isOpen?: boolean }) => ({
      width: isOpen ? ADMIN_MENU_WIDTH_OPEN : ADMIN_MENU_WIDTH_CLOSE,
      height: `calc(100vh - ${HEADER_HEIGHT} - ${NOTIFICATION_MARGIN})`,
      bg: ADMIN_MENU_BACKGROUND_COLOR,
      paddingTop: '16px',
      borderRightWidth: '1px',
      borderColor: '#C9CCCF',
      whiteSpace: 'nowrap',
      position: 'fixed',
      top: HEADER_HEIGHT,
      left: 0,
      zIndex: Z_INDEX_MENU,
      overflow: 'hidden',
      transition: 'all .2s ease',
      marginTop: NOTIFICATION_MARGIN,
    }),
    variants: {
      isOpen: {
        width: MENU_WIDTH_OPEN,
      },
    },
  },
  CustomMenuListItem: {
    baseStyle: ({ isActive = false }: { isActive?: boolean }) => ({
      h: '36px',
      marginX: '16px',
      borderRadius: '6px',
      marginBottom: '4px',
      paddingLeft: '8px',
      transition: 'all .2s ease',
      bg: isActive ? '#E6EEFE' : '',
      color: isActive ? Colors.BLUE_20 : '',
      fontWeight: 'bold',
      overflow: 'hidden',
      _hover: {
        bg: '#E6EEFE',
        color: Colors.BLUE_20,
      },
    }),
  },
  CustomMenuListItemText: {
    baseStyle: ({ isOpen = false }: { isOpen?: boolean }) => ({
      opacity: isOpen ? 1 : 0,
      transition: 'all 0.2s ease',
      fontSize: 'sm',
    }),
  },
  Table: {
    baseStyle: {
      th: {
        borderBottom: 'solid 1px #B9B9B9',
      },
      td: {
        borderBottom: 'solid 1px #B9B9B9',
      },
    },
  },
  // node_modules/@chakra-ui/theme/src/components/input.ts
  Input: {
    variants: {
      outline: {
        field: {
          _disabled: {
            bgColor: Colors.GRAY_65,
            color: Colors.GRAY_15,
            opacity: 1,
          },
        },
      },
    },
  },
  RhfSelect: {
    baseStyle: () => ({
      _disabled: {
        bgColor: Colors.GRAY_65,
        color: Colors.GRAY_15,
        opacity: 1,
      },
    }),
  },

  Checkbox: {
    baseStyle: {
      control: {
        borderColor: Colors.GRAY_03, // チェックボックスの枠線の色
        // チェックされた状態の色
        _checked: {
          bg: Colors.BLUE_20,
          borderColor: Colors.BLUE_20,
          color: Colors.WHITE,
          // チェックされた状態かつホバー時の色
          _hover: {
            bg: Colors.BLUE_20,
          },
          // チェックされたかつ無効状態の色
          _disabled: {
            bg: Colors.BLUE_20,
            borderColor: Colors.BLUE_20,
            color: Colors.WHITE,
            opacity: 0.5,
          },
        },
        // 無効状態の色
        _disabled: {
          bg: Colors.WHITE,
          borderColor: Colors.GRAY_15,
          color: Colors.WHITE,
        },
      },
    },
  },
};

/**
 * Admin用 カラーセット
 * ※各カラーは暫定の色なので将来的に修正が入ります。
 * ※特定のカラーコードについては、熊倉さん側からの情報が無いため暫定対応
 */
export const AdminColors = {
  primary: {
    50: '#ebf8ff', // ghost,outline: hover-bg-color
    100: '#bee3f8', // ghost,outline: active-bg-color
    500: Colors.BLUE_20, // bg-color
    600: Colors.BLUE_10, // solid: hover-bg-color
    700: Colors.BLUE_00, // solid: active-bg-color
  },
  secondary: {
    50: '#ebf8ff', // ghost,outline: hover-bg-color
    100: '#bee3f8', // ghost,outline: active-bg-color
    500: Colors.BLUE_20, // bg-color
    600: Colors.BLUE_10, // solid: hover-bg-color
    700: Colors.BLUE_00, // solid: active-bg-color
  },
  success: {
    50: '#F0FFF4', // ghost,outline: hover-bg-color
    100: '#C6F6D5', // ghost,outline: active-bg-color
    500: Colors.GREEN_10, // bg-color
    600: Colors.GREEN_10, // solid: hover-bg-color
    700: Colors.GREEN_00, // solid: active-bg-color
  },
  info: {
    50: '#ebf8ff', // ghost,outline: hover-bg-color
    100: '#bee3f8', // ghost,outline: active-bg-color
    500: Colors.BLUE_20, // bg-color
    600: Colors.BLUE_10, // solid: hover-bg-color
    700: Colors.BLUE_00, // solid: active-bg-color
  },
  danger: {
    50: '#FFF5F5', // ghost,outline: hover-bg-color
    100: '#FED7D7', // ghost,outline: active-bg-color
    500: Colors.RED_20, // bg-color
    600: Colors.RED_10, // solid: hover-bg-color
    700: Colors.RED_00, // solid: active-bg-color
  },
  warning: {
    50: '#FFFAF0', // ghost,outline: hover-bg-color
    100: '#FEEBC8', // ghost,outline: active-bg-color
    500: Colors.ORANGE_10, // bg-color
    600: Colors.ORANGE_10, // solid: hover-bg-color
    700: Colors.ORANGE_00, // solid: active-bg-color
  },
};
