import { useMemo } from 'react';
import { useParams } from 'react-router-dom';

export const useTermId = () => {
  const params = useParams();

  const termId = useMemo(() => params.termId ?? '', [params]);

  return termId;
};
