import { VFC, useEffect, useCallback } from 'react';
import { useFormContext } from 'react-hook-form';
import {
  FormControl,
  FormLabel,
  Icon,
  Text,
  useDisclosure,
} from '@chakra-ui/react';
import { AiOutlineUsergroupAdd } from 'react-icons/ai';
import { AddGroupFormType } from 'api/tenant/types';
import { InputTextForm, Dialog } from 'components/common/atoms';
import { useUserTenantId } from 'hooks/user/useUserTenantId';
import { useAddGroup } from 'hooks/tenant/useAddGroup';

/**
 * SettingUsersFormProps
 */
type AddGroupDialogProps = {
  formId: string;
  parentId?: string;
  openBtnElemType?: 'button' | 'text';
};

/**
 * 入力用バリデーション
 */
const validateAttr = {
  required: 'グループ名を入力してください',
};

export const AddGroupDialog: VFC<AddGroupDialogProps> = ({
  formId,
  parentId = '',
  openBtnElemType = 'button',
}) => {
  const { isOpen, onOpen, onClose } = useDisclosure();
  const tenantId = useUserTenantId();
  const {
    reset,
    handleSubmit,
    setValue,
    formState: { errors },
  } = useFormContext<AddGroupFormType>();
  const { onSubmit, isLoading, isSuccess } = useAddGroup();

  // グループ追加ポップアップ表示
  const addGroupBtnHandler = useCallback(() => {
    setValue('tenantId', tenantId);
    // parentIdが存在する場合は子グループ作成
    if (parentId || parentId !== '') {
      setValue('parentId', parentId);
    }
    onOpen();
  }, [tenantId, parentId, setValue, onOpen]);

  // グループ追加処理
  const onAddGroupSubmit = useCallback(
    (data: AddGroupFormType): void => {
      onSubmit(data);
    },
    [onSubmit],
  );

  // closeボタン押下時
  const closeBtnHandler = useCallback(() => {
    reset();
    onClose();
  }, [reset, onClose]);

  // フォームエラーの存在チェック
  const isFailed = useCallback(
    (): boolean => Object.keys(errors).length > 0,
    [errors],
  );

  useEffect(() => {
    if (isSuccess) {
      onClose();
    }
  }, [isSuccess, onClose]);

  const getGroupFormId = (): string => {
    let fid = formId;
    if (parentId) fid += `-${parentId}`;

    return fid;
  };

  return (
    <>
      <form
        id={getGroupFormId()}
        onSubmit={handleSubmit((data) => onAddGroupSubmit(data))}
      >
        <Dialog
          title="グループ追加"
          isOpen={isOpen}
          onOpen={addGroupBtnHandler}
          onClose={closeBtnHandler}
          openBtnElemType={openBtnElemType}
          openBtnChildNode={
            <>
              <Icon as={AiOutlineUsergroupAdd} fontSize={20} mr={2} />
              <Text as="span">グループ追加</Text>
            </>
          }
          openBtnProps={{
            variant: 'ghost',
            color: 'blue.400',
          }}
          submitBtnTitle="保存"
          submitBtnProps={{
            variant: 'primary',
            type: 'submit',
            form: getGroupFormId(),
            disabled: isFailed(),
            isLoading: isLoading === true,
          }}
        >
          <FormControl>
            <FormLabel htmlFor="name" fontSize="sm">
              グループ名を入力して下さい
            </FormLabel>
            <InputTextForm<AddGroupFormType>
              name="name"
              type="text"
              placeholder=""
              value=""
              attr={validateAttr}
            />
          </FormControl>
        </Dialog>
      </form>
    </>
  );
};
