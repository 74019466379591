import { getIdPoolConsumers } from 'admin/api/idPoolConsumers/getIdPoolConsumers';
import { CustomError } from 'admin/error/CustomError';
import { useUserCorporationInfo } from 'admin/hooks/user/useUserCorporationInfo';
import { logger } from 'api/logger';
import { ALLOWED_AUTH_CODE_TYPES } from 'define';
import { useQuery } from 'react-query';
import { queryIdPoolConsumersKey } from './queryIdPoolConsumersKey';

export const useIdPoolConsumers = () => {
  const { currentUserPoolGroupId } = useUserCorporationInfo();
  const { data } = useQuery({
    queryKey: queryIdPoolConsumersKey.getIdPoolConsumers(
      currentUserPoolGroupId || '',
    ),
    queryFn: () => getIdPoolConsumers({ id: currentUserPoolGroupId || '' }),
    onError: async (error) => {
      if (error instanceof CustomError) {
        await logger({
          loglevel: error.logLevel,
          data: JSON.stringify(error.cause),
          message: error.message,
        });
      }
    },
  });

  /**
   * ユーザープールの一覧は、authenticationTypeが5（外部連携）のみとする
   * 2024.06.04時点の制御の為、仕様変更時は、条件判定を修正してください
   */
  // dataがundefinedの時はfilter処理でエラーとなるため、undefined時はから配列とする
  const filterData = (data || []).filter(
    (x) =>
      x.authenticationType &&
      ALLOWED_AUTH_CODE_TYPES.includes(x.authenticationType),
  );

  return { data: filterData };
};
