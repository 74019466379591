import { getTransactionMail } from 'admin/api/userPool/transactionMail/getTransactionMail';
import { TRANSACTION_MAIL_PERSONALS } from 'admin/define/idPoolConsumer/transactionMail';
import { useIdPoolConsumerId } from 'admin/hooks/useIdPoolConsumerId';
import {
  TransactionMailCategoryType,
  TransactionMailFormType,
} from 'admin/types/userPool/transactionMail';
import { useCustomToast } from 'hooks/useCustomToast';
import { useMemo } from 'react';
import { useQuery } from 'react-query';
import { useParams } from 'react-router-dom';
import { errorToast } from 'utils/toast';
import { queryTransactionMailKey } from './queryTransactionMailKey';

export const useTransactionMail = (
  id: TransactionMailFormType['id'],
  isEdit: boolean,
) => {
  const params = useParams();
  const transactionMailType = useMemo(
    () => params?.transactionMailType ?? '',
    [params],
  );
  const transactionMailTypeName = useMemo(
    () =>
      TRANSACTION_MAIL_PERSONALS.get(
        transactionMailType as TransactionMailCategoryType,
      )?.name || '',
    [transactionMailType],
  );
  const idPoolConsumerId = useIdPoolConsumerId();
  const toast = useCustomToast();
  const { data } = useQuery({
    queryKey: queryTransactionMailKey.getTransactionMail(idPoolConsumerId, id),
    queryFn: () => getTransactionMail(idPoolConsumerId, id),
    onError: () => {
      toast({
        ...errorToast,
        duration: 4000,
        title: `${transactionMailTypeName}情報の取得に失敗しました`,
        position: 'bottom',
        variant: 'solid',
      });
    },
    enabled: isEdit,
  });

  return { data };
};
