import { customErrorMessage, toZod } from 'admin/config/zod';
import { editCodeSchema } from 'admin/schema/idPoolConsumer/code';
import {
  CodeGroupType,
  CodeGroupWithCodeType,
} from 'admin/types/userPool/master';
import { z } from 'zod';

// zodErrorの日本語化対応
z.setErrorMap(customErrorMessage);

export const createCodeGroupSchema = z.object<
  toZod<Omit<CodeGroupType, 'hasCodes' | 'displayOrder' | 'userPool'>>
>({
  id: z.string(),
  displayNameJa: z.string().min(1, '日本語は必須です。'),
  displayNameForeign: z.string().optional().nullable(),
});
export const editCodeGroupSchema = z.object<
  toZod<Omit<CodeGroupType, 'hasCodes' | 'displayOrder'>>
>({
  id: z.string(),
  displayNameJa: z.string().min(1, '日本語は必須です。'),
  displayNameForeign: z.string().optional().nullable(),
  userPool: z.string(),
});

export const codeGroupsSchema = z.array(editCodeGroupSchema);

// 属性グループにcodeが含まれる型
export const codeGroupWithCodeSchema = z.object<
  toZod<Omit<CodeGroupWithCodeType, 'hasCodes'>>
>({
  id: z.string(),
  displayNameJa: z.string().min(1, '日本語は必須です。'),
  displayNameForeign: z.string().optional().nullable(),
  userPool: z.string(),
  displayOrder: z.number(),
  codes: z.array(editCodeSchema),
});
