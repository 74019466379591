import { WarningIcon } from '@chakra-ui/icons';
import {
  Box,
  Button,
  IconButton,
  ListItem,
  Select,
  Stack,
  Text,
  Tooltip,
  UnorderedList,
} from '@chakra-ui/react';
import { chakraControlElements } from '@react-querybuilder/chakra';
import {
  CustomRuleTemporaryType,
  CustomRuleType,
  isSegmentUserAttribute,
  SegmentAttributeList,
  SegmentChildAttribute,
  SegmentChildAttrList,
  SegmentDetail,
  SegmentField,
  SegmentUserAttribute,
} from 'api/segment/types';
import { ErrorTextMsg } from 'components/common/atoms';
import { InputForm, SelectForm } from 'components/common/molecules';
import {
  ChangeEvent,
  memo,
  useCallback,
  useEffect,
  useState,
  VFC,
} from 'react';
import { useFieldArray, useFormContext } from 'react-hook-form';
import { BsTrash } from 'react-icons/bs';
import QueryBuilder, { NameLabelPair, RuleType } from 'react-querybuilder';
import 'react-querybuilder/dist/query-builder.css';
import './query-builder.css';

const translations = {
  addRule: {
    label: 'ルール +',
    title: 'ルールの追加',
  },
  addGroup: {
    label: 'グループ +',
    title: 'グループ追加',
  },
  removeRule: {
    label: 'x',
    title: 'ルール削除',
  },
  removeGroup: {
    label: 'x',
    title: 'グループ削除',
  },
};

const combinators = [
  { name: 'and', label: 'かつ' },
  { name: 'or', label: 'もしくは' },
];

type SegmentInnerProps = {
  attributeList: SegmentAttributeList[];
  segmentDetail: SegmentDetail;
};

export const SegmentInner: VFC<SegmentInnerProps> = memo(
  ({ attributeList, segmentDetail }: SegmentInnerProps) => {
    // データ属性リスト
    const [segmentAttributeList, setSegmentAttributeList] = useState<
      SegmentAttributeList[]
    >([]);
    // フィールド属性リスト(enquete, contents (※userは含まない))
    const [segmentChildAttrList, setSegmentChildAttrList] = useState<
      Array<SegmentChildAttrList[]>
    >([]);
    // 各データ属性に含まれる（フィールド属性が存在する場合はフィールド属性に含まれる）fieldsリスト
    const [segmentFieldList, setSegmentFieldList] = useState<
      Array<SegmentField[]>
    >([]);
    const [queryTemporary, setQueryTemporary] = useState<
      CustomRuleTemporaryType[]
    >([]);
    const { control, setValue, reset } = useFormContext<SegmentDetail>();

    const { fields, append, remove } = useFieldArray({
      control,
      name: 'query.rules',
      keyName: 'fieldId',
    });

    const setFieldValues = useCallback(
      (detail: SegmentDetail) => {
        setValue('id', detail.id);
        setValue('name', detail.name);
        setValue('query.combinator', detail.query.combinator);

        // データ属性の初期値を元にsegmentFieldListを作成する
        const tmpList: Array<SegmentField[]> = [];
        // セグメントクエリを格納
        const tmpQuery: CustomRuleTemporaryType[] = [];
        // フィールド属性リストを格納
        const tmpSegmentChildAttrList: Array<SegmentChildAttrList[]> = [];

        // attributeList の初期値を修正する
        const tmpAttributeList = attributeList || [];
        tmpAttributeList.map((tmpFields) => {
          if (tmpFields.id === 'enquete') {
            (tmpFields as SegmentChildAttribute).fieldList.map((field) => {
              field.fields.map((val) => {
                const result = val;

                // 表示上は初期選択肢が選択されている様に見えるが value に '' が入っている為、1番目の選択肢を選択する
                if (
                  val.valueEditorType === 'select' ||
                  val.valueEditorType === 'radio'
                ) {
                  // defaultOperator が '' の場合、 operators に '' が存在しないなら1番目を入れる
                  if (val.defaultOperator === '' && val.operators.length > 0) {
                    const operators = val.operators as NameLabelPair[];
                    if (!operators.find((v) => v.name === '')) {
                      result.defaultOperator = operators[0].name;
                    }
                  }

                  // defaultValue が '' の場合、 values に '' が存在しないなら1番目を入れる
                  if (val.defaultValue === '' && val.values.length > 0) {
                    const values = val.values as NameLabelPair[];
                    if (!values.find((v) => v.name === '')) {
                      result.defaultValue = values[0].name;
                    }
                  }
                }

                return result;
              });

              return field;
            });
          }

          return tmpFields;
        });

        detail.query.rules.forEach((item) => {
          append(item);
          const targetFields = (tmpAttributeList || []).filter(
            (x) => x.id === item.customeGroupType,
          );

          // ユーザーのフィールド属性を作成
          if (item.customeGroupType === 'user') {
            const fieldData = (targetFields[0] as SegmentUserAttribute)
              .fields as SegmentField[];
            tmpList.push(fieldData);

            // フォーム、コンテンツシェルフのネストしたフィールド属性用ダミー配列を追加する
            tmpSegmentChildAttrList.push([]);

            // アンケート、コンテンツシェルフのフィールド属性を作成
          } else {
            const childFieldList = (targetFields[0] as SegmentChildAttribute)
              .fieldList;
            tmpSegmentChildAttrList.push(childFieldList);
            // 選択されているフィールド属性のフィールドを設定
            const targetSegmentFieldList = childFieldList.filter(
              (x) => x.id === item.customeGroupId,
            );
            tmpList.push(
              targetSegmentFieldList.length > 0
                ? (targetSegmentFieldList[0].fields as SegmentField[])
                : [],
            );
          }
          setSegmentFieldList(tmpList);
          setSegmentChildAttrList(tmpSegmentChildAttrList);

          // クエリの状態を保存する
          tmpQuery.push({
            user: item.customeGroupType === 'user' ? item.rules : [],
            enquete:
              item.customeGroupType === 'enquete'
                ? { id: item.customeGroupId || '', rules: item.rules }
                : { id: '', rules: [] },
            contents:
              item.customeGroupType === 'contents'
                ? { id: item.customeGroupId || '', rules: item.rules }
                : { id: '', rules: [] },
          });
        });
        setQueryTemporary(tmpQuery);
        setSegmentAttributeList(tmpAttributeList);
      },
      [setValue, append, attributeList],
    );

    /**
     * セグメントクエリ情報の更新
     */
    const setQuery = useCallback(
      (val: CustomRuleType, idx: number) => {
        const tmpFieldList: CustomRuleType[] = [];
        const tmpQuery: CustomRuleTemporaryType[] = [];
        fields.forEach((item, i) => {
          if (i !== idx) {
            tmpFieldList.push(item);
            // 一時保存用
            tmpQuery.push({
              user:
                item.customeGroupType === 'user'
                  ? item.rules
                  : queryTemporary[i].user,
              enquete:
                item.customeGroupType === 'enquete'
                  ? { id: item.customeGroupId || '', rules: item.rules }
                  : { id: '', rules: queryTemporary[i].enquete.rules },
              contents:
                item.customeGroupType === 'contents'
                  ? { id: item.customeGroupId || '', rules: item.rules }
                  : { id: '', rules: queryTemporary[i].contents.rules },
            });
          }
          if (i === idx) {
            tmpFieldList.push({
              ...fields[idx],
              ...val,
            });
            // 一時保存用
            tmpQuery.push({
              user:
                val.customeGroupType === 'user'
                  ? val.rules
                  : queryTemporary[i].user,
              enquete:
                val.customeGroupType === 'enquete'
                  ? { id: val.customeGroupId || '', rules: val.rules }
                  : { id: '', rules: queryTemporary[i].enquete.rules },
              contents:
                val.customeGroupType === 'contents'
                  ? { id: val.customeGroupId || '', rules: val.rules }
                  : { id: '', rules: queryTemporary[i].enquete.rules },
            });
          }
        });
        setValue(`query.rules`, tmpFieldList);
        setQueryTemporary(tmpQuery);
      },
      [fields, setValue, queryTemporary],
    );

    /**
     * データ属性変更
     */
    const onChangeSegmentTarget = useCallback(
      (event: ChangeEvent<HTMLSelectElement>, idx: number) => {
        const targetFields = (attributeList || []).filter(
          (x) => x.id === event.target.value,
        );

        // 対象のsegment queryの中身を初期化する
        // ※属性変更時segment queryの状態が変化しないため
        const tmpFieldList: CustomRuleType[] = [];
        fields.forEach((item, i) => {
          if (i !== idx) {
            tmpFieldList.push(item);
          }
          if (i === idx) {
            // 対象がuserの時は'user'をセット
            // 対象がuser以外の時かつ子要素リストが存在する場合はfieldList[0]のIDを付与
            // それ以外はIDに空文字を付与する
            const getCustomeGroupId = (
              gid: keyof CustomRuleTemporaryType,
            ): string => {
              if (gid === 'user') return 'user';
              if (
                (targetFields[0] as SegmentChildAttribute).fieldList.length ===
                0
              ) {
                return '';
              }

              return (targetFields[0] as SegmentChildAttribute).fieldList[0].id;
            };

            const tmp: CustomRuleType = {
              ...item,
              customeGroupType: event.target.value as
                | 'user'
                | 'enquete'
                | 'contents',
              customeGroupName: targetFields[0].name,
              customeGroupId: getCustomeGroupId(
                event.target.value as keyof CustomRuleTemporaryType,
              ),
              // userは今まで通りの動作
              // enquete, contentsはここではrulesを設定しない
              rules:
                event.target.value === 'user'
                  ? queryTemporary[idx][event.target.value]
                  : [],
            };
            tmpFieldList.push(tmp);
          }
        });
        setValue(`query.rules`, tmpFieldList);

        // TODO: データを読み込む時の処理を修正
        // 選択されたデータ属性をsegmentFieldListに追加する
        const fieldList: Array<SegmentField[]> = [];
        const targetUserFields = targetFields[0] as SegmentUserAttribute;
        // ユーザーのフィールド属性を作成
        if (event.target.value === 'user') {
          segmentFieldList.forEach((item, i) => {
            if (i !== idx) fieldList.push(item);
            if (i === idx) {
              fieldList.push(targetUserFields.fields as SegmentField[]);
            }
          });
          setSegmentFieldList(fieldList);

          // アンケート、コンテンツシェルフのフィールド属性を作成
        } else {
          const childFieldList = (targetFields[0] as SegmentChildAttribute)
            .fieldList;
          const tmpSegmentChildAttrList: Array<SegmentChildAttrList[]> =
            segmentChildAttrList;
          if (segmentChildAttrList.length === 0) {
            tmpSegmentChildAttrList.push(childFieldList);
          } else {
            tmpSegmentChildAttrList[idx] = childFieldList;
          }
          setSegmentChildAttrList(tmpSegmentChildAttrList);

          // segmentFieldListに追加する
          const childFields =
            childFieldList.length !== 0
              ? (childFieldList[0].fields as SegmentField[])
              : [];
          segmentFieldList.forEach((item, i) => {
            if (i !== idx) fieldList.push(item);
            if (i === idx) fieldList.push(childFields);
          });
          setSegmentFieldList(fieldList);
        }
      },
      [
        setValue,
        attributeList,
        setSegmentFieldList,
        segmentFieldList,
        fields,
        queryTemporary,
        segmentChildAttrList,
      ],
    );

    /**
     * フィールド属性変更
     */
    const onChangeSegmentChildTarget = useCallback(
      (event: ChangeEvent<HTMLSelectElement>, idx: number) => {
        const targetAttribute: SegmentChildAttrList[] = (
          segmentChildAttrList[idx] || []
        ).filter((x) => x.id === event.target.value);
        // 対象のsegment queryの中身を初期化する
        // ※属性変更時segment queryの状態が変化しないため
        const tmpFieldList: CustomRuleType[] = [];
        fields.forEach((item, i) => {
          if (i !== idx) {
            tmpFieldList.push(item);
          }
          if (i === idx) {
            const gType =
              item.customeGroupType as keyof CustomRuleTemporaryType;
            if (gType === 'user') return;
            const tmpQueryRules = queryTemporary[idx][gType];
            // 変更される直前に保持していたセグメントの一部のフィールド名を取得
            const tmpQueryFieldName =
              tmpQueryRules.rules.length > 0
                ? (tmpQueryRules.rules[0] as RuleType).field
                : '';
            // 以下の条件をチェック
            // ・変更される直前に保持していたセグメントの親グループIDが同一
            // ・変更される直前に保持していたセグメント情報に含まれるIDが選択されたfieldsIDに含まれているかどうか確認
            const isExsitRule = (fieldName: string): boolean =>
              targetAttribute[0].fields.some(
                (x) =>
                  targetAttribute[0].id === tmpQueryRules.id &&
                  x.name === fieldName,
              );
            const tmp: CustomRuleType = {
              ...item,
              customeGroupId:
                item.customeGroupType === 'user'
                  ? 'user'
                  : targetAttribute[0].id,
              // 変更される直前に保持していたセグメント情報が選択されたグループに含まれている場合は
              // 保持していた情報をセットする
              // 含まれていない場合は空配列をセットする
              rules: isExsitRule(tmpQueryFieldName) ? tmpQueryRules.rules : [],
            };
            tmpFieldList.push(tmp);
          }
        });
        setValue(`query.rules`, tmpFieldList);

        const fieldList: Array<SegmentField[]> = [];

        segmentFieldList.forEach((item, i) => {
          if (i !== idx) fieldList.push(item);
          if (i === idx) {
            fieldList.push(targetAttribute[0].fields as SegmentField[]);
          }
        });
        setSegmentFieldList(fieldList);
      },
      [
        segmentChildAttrList,
        segmentFieldList,
        fields,
        setValue,
        queryTemporary,
      ],
    );

    /**
     * セグメント情報追加
     */
    const addQueryBuilders = useCallback(() => {
      const defaultQueryBuilder: CustomRuleType = {
        combinator: 'and',
        customeGroupType: 'user',
        customeGroupId: 'user',
        not: false,
        rules: [],
      };
      append(defaultQueryBuilder);

      const tmpQuery: CustomRuleTemporaryType[] = Object.assign(
        queryTemporary,
      ) as CustomRuleTemporaryType[];
      tmpQuery.push({
        user: [],
        enquete: {
          id: '',
          rules: [],
        },
        contents: {
          id: '',
          rules: [],
        },
      });
      setQueryTemporary(tmpQuery);

      // データ属性が変更されたとき
      const tmpSegmentFieldList: Array<SegmentField[]> = [];
      Object.assign(tmpSegmentFieldList, segmentFieldList);
      const targetFields = (attributeList || []).filter((x) => x.id === 'user');
      if (isSegmentUserAttribute(targetFields[0])) {
        tmpSegmentFieldList.push(targetFields[0].fields as SegmentField[]);
      }
      setSegmentFieldList(tmpSegmentFieldList);

      // フィールド属性が変更された時
      const tmpSegmentChildAttrList: Array<SegmentChildAttrList[]> =
        segmentChildAttrList;
      tmpSegmentChildAttrList.push([]);
      setSegmentChildAttrList(tmpSegmentChildAttrList);
    }, [
      append,
      attributeList,
      segmentFieldList,
      queryTemporary,
      setSegmentChildAttrList,
      segmentChildAttrList,
    ]);

    /**
     * セグメント削除
     */
    const onDelete = useCallback(
      (idx: number) => {
        remove(idx);

        const tmpQuery: CustomRuleTemporaryType[] = Object.assign(
          queryTemporary,
        ) as CustomRuleTemporaryType[];
        tmpQuery.splice(idx, 1);
        setQueryTemporary(tmpQuery);

        const fieldList: Array<SegmentField[]> = Object.assign(
          segmentFieldList,
        ) as Array<SegmentField[]>;
        fieldList.splice(idx, 1);
        setSegmentFieldList(fieldList);
      },
      [remove, segmentFieldList, queryTemporary],
    );

    /**
     * 初期処理
     */
    useEffect(() => {
      reset();
      setFieldValues(segmentDetail);
    }, [reset, segmentDetail]);

    /**
     * セグメント中タイトル取得
     */
    const getSegmentSubTitle = (
      type: Omit<keyof CustomRuleTemporaryType, 'user'>,
    ): string => (type === 'enquete' ? 'フォームタイトル名' : 'テーブル名');

    /**
     * エラーメッセージ表示
     */
    const segmentSubListErrorMsg = (
      type: Omit<keyof CustomRuleTemporaryType, 'user'>,
    ) => (
      <ErrorTextMsg
        msg={
          type === 'enquete'
            ? '会員限定フォームが存在しないため利用できません'
            : 'ユーザーID (uid) の列を持つテーブルが存在しないため利用できません'
        }
      />
    );

    return (
      <Stack mb={4}>
        <Box>
          <InputForm<SegmentDetail>
            name="name"
            type="text"
            label="セグメント名"
            attr={{ required: 'セグメント名は必須です' }}
          />
        </Box>
        <Box
          borderWidth="1px"
          borderColor="gray.200"
          borderRadius={4}
          p={2}
          bgColor="cyan.50"
        >
          <SelectForm<SegmentDetail>
            name="query.combinator"
            attr={{
              required: '',
            }}
            bgColor="white"
            width={160}
            optionList={combinators.map((comb) => (
              <option key={comb.name} value={comb.name}>
                {comb.label}
              </option>
            ))}
          />
          {fields.map((field, idx) => (
            <Box
              p={4}
              mt={4}
              key={`${field.id || field.fieldId}`}
              borderWidth="1px"
              borderColor="gray.200"
              borderRadius={4}
              bgColor="cyan.100"
            >
              <Box mb={2}>
                <Text>条件に利用するものを選択してください</Text>
                <Box display="flex">
                  <Select
                    bg="white"
                    onChange={(e) => onChangeSegmentTarget(e, idx)}
                    value={field.customeGroupType}
                  >
                    {segmentAttributeList.map((target) => (
                      <option key={target.id} value={target.id}>
                        {target.name}
                      </option>
                    ))}
                  </Select>
                  <Tooltip label="削除" bg="white" color="black">
                    <IconButton
                      variant="link"
                      aria-label="SegmentBlockDelete"
                      icon={<BsTrash />}
                      fontSize={16}
                      padding="6px"
                      onClick={() => onDelete(idx)}
                      disabled={fields.length === 1}
                    />
                  </Tooltip>
                </Box>
              </Box>
              {(field.customeGroupType === 'enquete' ||
                field.customeGroupType === 'contents') && (
                <Box mb={2}>
                  <Text>{getSegmentSubTitle(field.customeGroupType)}</Text>
                  <Box display="flex">
                    <Select
                      bg="white"
                      onChange={(e) => onChangeSegmentChildTarget(e, idx)}
                      value={field.customeGroupId}
                    >
                      {segmentFieldList[idx].length === 0 && (
                        <option value="noids">利用できません</option>
                      )}
                      {(segmentChildAttrList[idx] || []).map((target) => (
                        <option key={target.id} value={target.id}>
                          {target.title}
                        </option>
                      ))}
                    </Select>
                  </Box>
                </Box>
              )}
              {segmentFieldList[idx].length === 0 &&
                segmentSubListErrorMsg(
                  field.customeGroupType as Omit<
                    keyof CustomRuleTemporaryType,
                    'user'
                  >,
                )}
              {segmentFieldList[idx].length !== 0 &&
                // 回答有無で「回答なし」を選択していれば
                field.rules.some(
                  // eslint-disable-next-line
                  (rule) => (rule as unknown as any)?.value === 'notAnswered',
                ) && (
                  <>
                    {/* // 青アイコン、背景色なし */}
                    <Text fontSize="xs" px="1rem" py="2px">
                      <WarningIcon mr={2} color="blue" />
                      「回答なし」をご利用の場合は以下の制約があります。
                      <UnorderedList ml="1.4rem">
                        <ListItem>
                          フォーム属性のセグメントルール内で「回答なし」を条件に含む場合は、設問への回答に応じた他の条件を追加することはできません。
                        </ListItem>
                        <ListItem>
                          ユーザー属性や別のフォーム属性など、他のセグメントルールと併用する必要があります。
                        </ListItem>
                        <ListItem>
                          他のセグメントルールとの組み合わせでは「かつ」を指定する必要があります。
                        </ListItem>
                      </UnorderedList>
                    </Text>
                  </>
                )}
              {segmentFieldList[idx].length !== 0 && (
                <QueryBuilder
                  fields={segmentFieldList[idx]}
                  query={field}
                  onQueryChange={(q) => setQuery(q, idx)}
                  translations={translations}
                  combinators={combinators}
                  controlElements={chakraControlElements}
                />
              )}
            </Box>
          ))}
          <Box display="flex" justifyContent="center" mt={4}>
            <Button
              variant="outline"
              bgColor="white"
              onClick={() => addQueryBuilders()}
            >
              項目追加
            </Button>
          </Box>
        </Box>
      </Stack>
    );
  },
);
