export type AuthConfigType = {
  ASSISTANT: boolean;
  STAFF: boolean;
  DIRECTOR: boolean;
  MANAGER: boolean;
  OWNER: boolean;
};

// 各画面表示可否
export const SettingAuth: { [key: string]: AuthConfigType } = {
  // ユーザ管理
  users: {
    ASSISTANT: true,
    STAFF: true,
    DIRECTOR: true,
    MANAGER: true,
    OWNER: true,
  },
  // グループ管理
  groups: {
    ASSISTANT: true,
    STAFF: true,
    DIRECTOR: true,
    MANAGER: true,
    OWNER: true,
  },
  // APIキー管理
  apikey: {
    ASSISTANT: true,
    STAFF: true,
    DIRECTOR: true,
    MANAGER: true,
    OWNER: true,
  },
  // ユーザー設定
  userSetting: {
    ASSISTANT: true,
    STAFF: true,
    DIRECTOR: true,
    MANAGER: true,
    OWNER: true,
  },
  // メルマガ管理
  mailMagazine: {
    ASSISTANT: true,
    STAFF: true,
    DIRECTOR: true,
    MANAGER: true,
    OWNER: true,
  },
  // 決済代行連携設定
  paymentAgency: {
    ASSISTANT: false,
    STAFF: false,
    DIRECTOR: false,
    MANAGER: false,
    OWNER: true,
  },
  // フォーム関連設定
  formSetting: {
    ASSISTANT: true,
    STAFF: true,
    DIRECTOR: true,
    MANAGER: true,
    OWNER: true,
  },
  // フォーム関連設定
  loginRedirect: {
    ASSISTANT: false,
    STAFF: false,
    DIRECTOR: false,
    MANAGER: false,
    OWNER: true,
  },
};
