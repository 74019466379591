export const PRODUCT_NAME = 'Uniikey Experience Cloud';
export const LOCAL_STORAGE_NAME = 'cdmCloud';

export const IS_DEV = process.env.REACT_APP_DEPLOY_MODE === 'dev';
export const IS_STG = process.env.REACT_APP_DEPLOY_MODE === 'stg';

// コンテンツシェルフのテーブルID (例: tbl_kx4jugsh)
export const ID_PREFIX_CONTENTS_TBL = 'tbl';
// テナントID (例: tnt_kekqo917)
export const ID_PREFIX_TENANT = 'tnt';
// グループID (例: grp_pftpwsj5)
export const ID_PREFIX_GROUP = 'grp';

// テナントユーザーのパーミッション定数
// 05_●個人情報スタッフ
// 04_エディター
// 03_マネージャー（個人情報なし）
// 02_●マネージャー
// 01_●オーナー (テナント単位 契約に関する権限)
export const PERMISSION_ASSISTANT = 10; // 05_●個人情報スタッフ
export const PERMISSION_STAFF = 20; // 04_エディター
export const PERMISSION_DIRECTOR = 40; // 03_マネージャー（個人情報なし）
export const PERMISSION_MANAGER = 50; // 02_●マネージャー
export const PERMISSION_OWNER = 90; // 01_●オーナー

// テナントユーザーのパーミッション
export const PERMISSIONS = [
  PERMISSION_ASSISTANT,
  PERMISSION_STAFF,
  PERMISSION_DIRECTOR,
  PERMISSION_MANAGER,
  PERMISSION_OWNER,
];

// 公開権限を利用できない設定
export const DISABLE_PUBLISH_PERMISSION = [
  PERMISSION_ASSISTANT,
  PERMISSION_STAFF,
];

// テナントユーザーのパーミッションリスト
export const TENANT_PERMISSION_LIST = [
  { value: PERMISSION_STAFF, text: 'STAFF', label: '05_●個人情報スタッフ' },
  { value: PERMISSION_ASSISTANT, text: 'ASSISTANT', label: '04_エディター' },
  {
    value: PERMISSION_DIRECTOR,
    text: 'DIRECTOR',
    label: '03_マネージャー(個人情報なし)',
  },
  { value: PERMISSION_MANAGER, text: 'MANAGER', label: '02_●マネージャー' },
  { value: PERMISSION_OWNER, text: 'OWNER', label: '01_●オーナー' },
];

// コンテンツシェルフのファイルアップロード種別
export const CONTENTSDB_FILE_UPLOAD_TYPE_SAVE = 1;
export const CONTENTSDB_FILE_UPLOAD_TYPE_DELETE = 2;
export const CONTENTSDB_FILE_UPLOAD_TYPE_DELETE_IMPORT = 3;
export const CONTENTSDB_FILE_UPLOAD_TYPE = [
  CONTENTSDB_FILE_UPLOAD_TYPE_SAVE,
  CONTENTSDB_FILE_UPLOAD_TYPE_DELETE,
  CONTENTSDB_FILE_UPLOAD_TYPE_DELETE_IMPORT,
] as const;

// 処理待ち
export const CONTENTSDB_FILE_UPLOAD_STATUS_YET = 0;
// 入力チェック中
export const CONTENTSDB_FILE_UPLOAD_STATUS_VALIDATE = 1;
// BigQuery取り込み待ち
export const CONTENTSDB_FILE_UPLOAD_STATUS_IMPORT_WAIT = 2;
// BigQuery取り込み中
export const CONTENTSDB_FILE_UPLOAD_STATUS_IMPORTING = 3;
// ElasticSearch取り込み待ち
export const CONTENTSDB_FILE_UPLOAD_STATUS_ES_WAIT = 4;
// ElasticSearch取り込み中
export const CONTENTSDB_FILE_UPLOAD_STATUS_ES_IMPORT = 5;
// エラー
export const CONTENTSDB_FILE_UPLOAD_STATUS_ERROR = 8;
// 終了
export const CONTENTSDB_FILE_UPLOAD_STATUS_DONE = 9;

export const contentDbUpStatus = (status: number): string => {
  let statusName = '';

  switch (status) {
    case CONTENTSDB_FILE_UPLOAD_STATUS_YET:
      statusName = '未';
      break;
    case CONTENTSDB_FILE_UPLOAD_STATUS_VALIDATE:
    case CONTENTSDB_FILE_UPLOAD_STATUS_IMPORT_WAIT:
    case CONTENTSDB_FILE_UPLOAD_STATUS_IMPORTING:
    case CONTENTSDB_FILE_UPLOAD_STATUS_ES_WAIT:
    case CONTENTSDB_FILE_UPLOAD_STATUS_ES_IMPORT:
      statusName = '処理中';
      break;
    case CONTENTSDB_FILE_UPLOAD_STATUS_ERROR:
      statusName = 'エラー';
      break;
    case CONTENTSDB_FILE_UPLOAD_STATUS_DONE:
      statusName = '終了';
      break;
    default:
      break;
  }

  return statusName;
};

// 機能ID
export const FEATURE_GIGYA = 10; // GIGYA利用
export const FEATURE_DASHBOARD = 20; // ダッシュボード
export const FEATURE_MAIL = 30; // メール
export const FEATURE_ENQUETE = 40; // アンケート
export const FEATURE_SEGMENT = 50; // セグメント
export const CONTENTS_SHELF = 60; // コンテンツシェルフ
export const FEATURE_PAYWALL = 70; // ペイウォール
// export const FEATURE_AUTH_HUB = 80; // Auth Hub
export const FEATURE_AUTH_HUB = 70; // Auth Hub

// メール通知再取得間隔
export const GET_NOTICE_INTERVAL = 30000; // (30秒)

// アンケートグラフ:フリーアンサー表示リミット
export const ENQUETE_CHART_FREETEXT_LIMIT = 500;

// コンテンツシェルフ 高度な検索
// ページ指定上限
export const CONTENTS_SHELF_SEARCH_PAGE_MAX = 100;
// 取得件数指定上限
export const CONTENTS_SHELF_SEARCH_LIMIT_MAX = 200;

// 認証エラーステータス
export const HTTP_STATUS_UNAUTHORIZED = 401;
// 認証エラーステータス（forbidden）
export const HTTP_STATUS_FORBIDDEN = 403;
// 機能利用権限なし
export const HTTP_STATUS_TENANT_NOT_AVAILABLE = 450;
// 強制ログアウトステータス
export const HTTP_STATUS_FORCE_LOGOUT = 451;

// 強制ログアウト時のエラーメッセージ
// export const FORCE_LOGOUT_STATUS_MESSAGE =
//   '認証が切れた為、再度ログインを行ってください。';
// ログアウト状態でログイン画面にアクセスした際に必ずメッセージが
// 出力されてしまうので、空文字で置いておく
export const FORCE_LOGOUT_STATUS_MESSAGE = '';

// 認証処理除外URLリスト
export const AUTHORIZED_EXCLUDED_URLS = [
  'login',
  'logout',
  'user/invite/[A-Za-z0-9]+',
  'user/invite_corporation/[A-Za-z0-9]+',
  'user/password_reset',
  'password_forget',
];

/**
 * 配信先種別: 未設定
 */
export const MAIL_DELIVERY_TYPE_NOT_SET = 0;
/**
 * 配信先種別: 予約配信
 */
export const MAIL_DELIVERY_TYPE_RESERVE = 1;
/**
 * 配信先種別: 即時配信
 */
export const MAIL_DELIVERY_TYPE_IMMEDIATE = 2;
/**
 * メール配信先種別: メルマガ購読者全員への配信
 */
export const MAIL_DELIVERY_TO_ALL = 0;
/**
 * メール配信先種別: セグメントへの配信
 */
export const MAIL_DELIVERY_TO_SEGMENT = 1;
/**
 * メール配信先種別: メールアドレス直接指定
 */
export const MAIL_DELIVERY_TO_DIRECT = 2;
/**
 * メール配信先種別: 差し込みメール配信
 */
export const MAIL_DELIVERY_TO_INSERT = 3;

/**
 * 税率デフォルト
 * 以下税率configで有効な期間内の税率が存在しない場合の税率
 */
export const DEFAULT_TAX_CONFIG = {
  TAX: 10,
  EFFECTIVE_PERIOD: {
    FROM: '',
    TO: '',
  },
};

/**
 * 税率
 * 設定税率の有効期間を設定し、
 * その期間のみ該当の税率が有効となる
 */
export const TAX_CONFIG_LIST = [
  {
    TAX: 10,
    EFFECTIVE_PERIOD: {
      FROM: '2019/10/01 00:00:00',
      TO: '2099/03/31 23:59:59',
    },
  },
];

/**
 * ペイウォール カスタムツールボタン デフォルトテキスト
 */
export const PAYWALL_CUSTOM_TOOL_BUTTON_DEFAULT_NAME = 'ペイウォールボタン';
/**
 * ペイウォール カスタムツールボタン デフォルトカラー
 */
export const PAYWALL_CUSTOM_TOOL_BUTTON_DEFAULT_COLOR = '#DB8820';

/**
 * ペイウォール カスタムツールボタン ログイン用 デフォルトテキスト
 */
export const PAYWALL_CUSTOM_TOOL_LOGIN_BUTTON_DEFAULT_NAME = 'ログインボタン';
/**
 * ペイウォール カスタムツールボタン ログイン用  デフォルトカラー
 */
export const PAYWALL_CUSTOM_TOOL_LOGIN_BUTTON_DEFAULT_COLOR = '#236FA1';

/**
 * ペイウォール カスタムツールボタン 会員登録用 デフォルトテキスト
 */
export const PAYWALL_CUSTOM_TOOL_REGIST_BUTTON_DEFAULT_NAME = '会員登録ボタン';
/**
 * ペイウォール カスタムツールボタン 会員登録用  デフォルトカラー
 */
export const PAYWALL_CUSTOM_TOOL_REGIST_BUTTON_DEFAULT_COLOR = '#843FA1';

/**
 * ペイウォール カスタムツールボタン用 エレメントクラス
 */
export const PAYWALL_CUSTOM_TOOL_BUTTON_CLASS = 'cart-button';
/**
 * ペイウォール カスタムツールボタン ログイン用 エレメントクラス
 */
export const PAYWALL_CUSTOM_TOOL_LOGIN_BUTTON_CLASS = 'login-button';
/**
 * ペイウォール カスタムツールボタン 会員登録用 エレメントクラス
 */
export const PAYWALL_CUSTOM_TOOL_MEMBER_REGIST_BUTTON_CLASS =
  'member-regist-button';

/**
 * ペイウォール カスタムウイジェット埋め込みボタン名
 */
export const ENBEDDED_PRODUCT_NAME_BUTTON = {
  NAME: '商品名',
  PREFIX: '%%productName%%',
};
export const ENBEDDED_PRICE_BUTTON = {
  NAME: '税込価格',
  PREFIX: '%%price%%',
};
export const ENBEDDED_PRICE_EXCLUDING_TAX_BUTTON = {
  NAME: '税抜価格',
  PREFIX: '%%priceExcludingTax%%',
};
export const ENBEDDED_BROWSABLE_PERIOD_BUTTON = {
  NAME: '閲覧可能日数',
  PREFIX: '%%browsablePeriod%%',
};

/**
 * ペイウォール カスタムウイジェット埋め込みボタンリスト(サブスクリプション)
 */
export const PAYWALL_CUSTOM_WIDGET_FOR_COURSE_BUTTONS = [
  ENBEDDED_PRODUCT_NAME_BUTTON,
  ENBEDDED_PRICE_BUTTON,
  ENBEDDED_PRICE_EXCLUDING_TAX_BUTTON,
];
/**
 * ペイウォール カスタムウイジェット埋め込みボタンリスト(単品商品)
 */
export const PAYWALL_CUSTOM_WIDGET_FOR_ITEM_BUTTONS = [
  ENBEDDED_PRODUCT_NAME_BUTTON,
  ENBEDDED_PRICE_BUTTON,
  ENBEDDED_PRICE_EXCLUDING_TAX_BUTTON,
  ENBEDDED_BROWSABLE_PERIOD_BUTTON,
];

// ログイン認証種別
// email 認証
export const LOGIN_AUTH_MODE_EMAIL = 1;
// Google Idp 認証
export const LOGIN_AUTH_MODE_GIDP_AUTH = 2;
// SAML 認証
export const LOGIN_AUTH_MODE_SAML_AUTH = 3;

export const LOGIN_AUTH_MODE = [
  LOGIN_AUTH_MODE_EMAIL,
  LOGIN_AUTH_MODE_GIDP_AUTH,
  LOGIN_AUTH_MODE_SAML_AUTH,
] as const;

// 認証種別
// Uniikey
export const AUTH_CODE_TYPE_UNIIKEY = 1;
// 外部連携
export const AUTH_CODE_TYPE_OUTER = 5;
// SAML
export const AUTH_CODE_TYPE_SAML = 11;
// Google Idp
export const AUTH_CODE_TYPE_GIDP = 90;

export const AUTH_CODE_TYPES = [
  AUTH_CODE_TYPE_UNIIKEY,
  AUTH_CODE_TYPE_OUTER,
  AUTH_CODE_TYPE_SAML,
  AUTH_CODE_TYPE_GIDP,
] as const;

export const ALLOWED_AUTH_CODE_TYPES = [
  AUTH_CODE_TYPE_UNIIKEY,
  AUTH_CODE_TYPE_OUTER,
];

// Uniikey
export const AUTH_CODE_TYPE_UNIIKEY_LABEL = 'Uniikey';
// 外部連携
export const AUTH_CODE_TYPE_OUTER_LABEL = '外部連携';
// SAML
export const AUTH_CODE_TYPE_SAML_LABEL = 'SAML';
// Google Idp
export const AUTH_CODE_TYPE_GIDP_LABEL = 'Google Idp';

export const AUTH_CODE_TYPE_LABELS = [
  AUTH_CODE_TYPE_UNIIKEY_LABEL,
  AUTH_CODE_TYPE_OUTER_LABEL,
  AUTH_CODE_TYPE_SAML_LABEL,
  AUTH_CODE_TYPE_GIDP_LABEL,
] as const;

// 認証種別一覧
const AUTH_CODE_TYPES_MAP = new Map<
  typeof AUTH_CODE_TYPES[number],
  typeof AUTH_CODE_TYPE_LABELS[number]
>([
  [AUTH_CODE_TYPE_UNIIKEY, AUTH_CODE_TYPE_UNIIKEY_LABEL],
  [AUTH_CODE_TYPE_OUTER, AUTH_CODE_TYPE_OUTER_LABEL],
  [AUTH_CODE_TYPE_SAML, AUTH_CODE_TYPE_SAML_LABEL],
  [AUTH_CODE_TYPE_GIDP, AUTH_CODE_TYPE_GIDP_LABEL],
]);

export const AUTH_CODE_TYPE_LIST: ReadonlyMap<number, string> =
  AUTH_CODE_TYPES_MAP;

// ユーザープール種別
// consumer
export const USER_POOL_TYPE_CONSUMER = 10;
// UEC
export const USER_POOL_TYPE_UEC = 20;

export const USER_POOL_TYPES = [
  USER_POOL_TYPE_CONSUMER,
  USER_POOL_TYPE_UEC,
] as const;

// 法人ユーザープール権限
export const CORPORATION_USER_POOL_PERMISSION_USER_POOL = 'userpool';
export const CORPORATION_USER_POOL_PERMISSION_TENANT = 'tenant';
export const CORPORATION_USER_POOL_PERMISSION_AUTH_HUB = 'auth_hub';
export const CORPORATION_USER_POOL_PERMISSION_MEMBER = 'member';

// 法人用会員ステータス
// 有効
export const MEMBER_STATUS_TYPE_VALID = 1;
// 無効
export const MEMBER_STATUS_TYPE_INVALID = 0;
// 退会済
export const MEMBER_STATUS_TYPE_WITHDRAWAL = -2;
// 退会済（強制）
export const MEMBER_STATUS_TYPE_WITHDRAWAL_COMPULSION = -3;

export const MEMBER_STATUS_TYPES = [
  MEMBER_STATUS_TYPE_VALID,
  MEMBER_STATUS_TYPE_INVALID,
  MEMBER_STATUS_TYPE_WITHDRAWAL,
  MEMBER_STATUS_TYPE_WITHDRAWAL_COMPULSION,
] as const;

// 有効
export const MEMBER_STATUS_TYPE_VALID_LABEL = '有効';
// 無効
export const MEMBER_STATUS_TYPE_INVALID_LABEL = '無効';
// 退会済
export const MEMBER_STATUS_TYPE_WITHDRAWAL_LABEL = '退会済';
// 退会済（強制）
export const MEMBER_STATUS_TYPE_WITHDRAWAL_COMPULSION_LABEL = '退会済（強制）';

export const MEMBER_STATUS_TYPE_LABELS = [
  MEMBER_STATUS_TYPE_VALID_LABEL,
  MEMBER_STATUS_TYPE_INVALID_LABEL,
  MEMBER_STATUS_TYPE_WITHDRAWAL_LABEL,
  MEMBER_STATUS_TYPE_WITHDRAWAL_COMPULSION_LABEL,
] as const;

// 法人用会員ステータス一覧
export const MEMBER_STATUS_TYPES_MAP = new Map<
  typeof MEMBER_STATUS_TYPES[number],
  typeof MEMBER_STATUS_TYPE_LABELS[number]
>([
  [MEMBER_STATUS_TYPE_VALID, MEMBER_STATUS_TYPE_VALID_LABEL],
  [MEMBER_STATUS_TYPE_INVALID, MEMBER_STATUS_TYPE_INVALID_LABEL],
  [MEMBER_STATUS_TYPE_WITHDRAWAL, MEMBER_STATUS_TYPE_WITHDRAWAL_LABEL],
  [
    MEMBER_STATUS_TYPE_WITHDRAWAL_COMPULSION,
    MEMBER_STATUS_TYPE_WITHDRAWAL_COMPULSION_LABEL,
  ],
]);

export const MEMBER_STATUS_TYPE_LIST: ReadonlyMap<number, string> =
  MEMBER_STATUS_TYPES_MAP;

/**
 * フォームタイプ uniikey
 */
export const AUTH_TYPE_UNIIKEY = 1;

/**
 * フォームタイプ gigya
 */
export const AUTH_TYPE_GIGYA = 2;

/**
 * フォームタイプ 集英社
 */
export const AUTH_TYPE_SHUEISHA = 3;

/**
 * フォームタイプ 未設定（新規）
 *
 * ※ 定数名については今後変更となると思われます
 */
// export const AUTH_TYPE_UNSET = 0;

export const AUTH_TYPE_KEYS = [
  AUTH_TYPE_UNIIKEY,
  AUTH_TYPE_GIGYA,
  AUTH_TYPE_SHUEISHA,
  // AUTH_TYPE_UNSET,
] as const;
