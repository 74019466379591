import { useSetPageTitle } from 'hooks/useSetPageTitle';
import { FC } from 'react';
import { Outlet } from 'react-router-dom';
import { VStack } from '@chakra-ui/react';

export const AuthHub: FC = () => {
  useSetPageTitle('Auth Hub設定');

  return (
    <VStack spacing={0} h="full" w="full">
      <Outlet />
    </VStack>
  );
};
