import { VFC, memo } from 'react';
import { Box } from '@chakra-ui/react';
import {
  Chart as ChartJS,
  CategoryScale,
  LinearScale,
  PointElement,
  LineElement,
  Title,
  Tooltip,
  Legend,
} from 'chart.js';
import { Line } from 'react-chartjs-2';

ChartJS.register(
  CategoryScale,
  LinearScale,
  PointElement,
  LineElement,
  Title,
  Tooltip,
  Legend,
);

type Props = {
  text?: string;
  labels: string[];
  data: number[];
};

export const LineChart: VFC<Props> = memo(({ text, labels, data }) => {
  const options = {
    responsive: false,
    maintainAspectRatio: false,
    plugins: {
      legend: {
        position: 'top' as const,
        labels: {
          font: {
            size: 14,
          },
        },
      },
      title: {
        display: false,
        text,
        font: {
          size: 20,
        },
      },
    },
    scale: {
      ticks: {
        precision: 0,
      },
    },
  };

  const graphData = {
    labels,
    datasets: [
      {
        label: '回答数',
        data,
        borderColor: 'rgb(255, 99, 132)',
        backgroundColor: 'rgba(255, 99, 132, 0.5)',
      },
    ],
  };

  return (
    <Box pt={2} pb={5}>
      <Line
        width={800}
        height={300}
        options={options}
        data={graphData}
        style={{ margin: '0 auto' }}
      />
    </Box>
  );
});
