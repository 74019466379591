import {
  Course,
  CourseFormType
} from 'components/paywall/pages/CourseList/typed';
import { PromotionDefaultValues } from 'components/paywall/pages/PromotionList/initial';

/**
 * コース デフォルト値
 */
export const CourseDefaultValues: Course = {
  id: '',
  promotions: PromotionDefaultValues,
  name: '',
  month: null,
  price: null,
  wall: {
    id: '',
    name: '',
    wallType: '',
    status: true,
    tenant: '',
    parent: null,
    createdAt: '',
    updatedAt: '',
    createBy: '',
    createUser: '',
    createUserName: '',
    updateBy: '',
    updateUser: '',
    updateUserName: '',
  },
  systemCode: '',
  tags: '',
  memo: '',
  createdAt: '',
  updatedAt: '',
} as const;

/**
 * コースフォームタイプ デフォルト値
 */
export const CourseFormDefaultValues: CourseFormType = {
  id: '',
  name: '',
  month: null,
  price: null,
  wall: '',
  systemCode: '',
  tags: '',
  memo: '',
};
