import { Box, Text, useDisclosure } from '@chakra-ui/react';
import { DrawerForm } from 'components/common/atoms';
import { Colors, ColorSet } from 'components/paywall/config';
import { usePaywallUtil } from 'components/paywall/hooks/usePaywallUtil';
import { termsSettingDefaultValues } from 'components/paywall/pages/TermsSetting/initial';
import {
  TermsSetting,
  TermsSettingSubmitFormType,
} from 'components/paywall/pages/TermsSetting/typed';
import { useUpdateTerm } from 'hooks/paywall/useUpdateTerm';
import { useUserTenantId } from 'hooks/user/useUserTenantId';
import { FC, memo, useCallback } from 'react';
import { FormProvider, useForm } from 'react-hook-form';
import { Form } from './Form';

type Props = {
  termData: TermsSetting;
};

/**
 * @see 規約設定の新規作成＆編集ドロワー
 * @param termData 規約データ
 */
export const TermsSettingDrawer: FC<Props> = memo(({ termData }: Props) => {
  const tenantId = useUserTenantId();
  const { isOpen, onClose, onOpen } = useDisclosure();
  const { isOperationAuth } = usePaywallUtil();
  const methods = useForm<TermsSetting>({
    mode: 'onBlur',
    defaultValues: termsSettingDefaultValues,
  });
  const formId = 'paywall-terms-form';
  const {
    setValue,
    setError,
    clearErrors,
    formState: { errors },
  } = methods;
  const closeBtnHandler = useCallback(() => {
    onClose();
    clearErrors();
  }, [onClose, clearErrors]);

  // ドロワーを開くときの処理
  const onClickEdit = useCallback(() => {
    setValue('title', termData.title);
    setValue('description', termData.description);
    setValue('terms', termData.terms);
    onOpen();
  }, [onOpen, setValue, termData]);

  const { onSubmit, isLoading } = useUpdateTerm({
    setError,
    close: onClose,
  });

  // フォーム送信する処理
  const formSubmit = useCallback(
    (data: TermsSetting) => {
      const submitData: TermsSettingSubmitFormType = {
        tenantId,
        termSetting: data,
      };
      onSubmit(submitData);
    },
    [onSubmit, tenantId],
  );

  return (
    <Box
      className="debug"
      display="flex"
      alignItems="center"
      justifyContent="flex-end"
      mb={4}
    >
      <FormProvider {...methods}>
        <DrawerForm
          title="規約設定の編集"
          openBtnChildNode={<Text as="span">編集する</Text>}
          openBtnProps={{
            width: '104px',
            padding: '8px 16px',
            ...ColorSet.BLUE,
            isDisabled: !isOperationAuth(),
          }}
          cancelBtnTitle="キャンセル"
          cancelBtnHandelr={closeBtnHandler}
          cancelBtnProps={{
            width: '104px',
            padding: '8px 16px',
            marginRight: '16px',
            borderColor: Colors.GRAY_30,
            disabled: isLoading,
          }}
          submitBtnTitle="保存"
          submitBtnProps={{
            width: '104px',
            padding: '8px 16px',
            ...ColorSet.BLUE,
            disabled: Object.keys(errors).length > 0,
            isLoading,
          }}
          isOpen={isOpen}
          onOpen={onClickEdit}
          onClose={closeBtnHandler}
          closeOnOverlayClick={false}
          closeOnEsc={false}
          autoFocus={false}
          size="md"
          formId={formId}
          drawerCloseButtonProps={{ disabled: isLoading }}
          drawerFooterJustify="flex-end"
          hasIconWithTitle
        >
          <Form formId={formId} submit={formSubmit} />
        </DrawerForm>
      </FormProvider>
    </Box>
  );
});
