import { deleteTenantUsers } from 'admin/api/tenantUsers/deleteTenantUsers';
import { CustomError } from 'admin/error/CustomError';
import { useUserCorporationId } from 'admin/hooks/user/useUserCorporationId';
import { DeleteTenantUsersFormType } from 'admin/types/tenantUsers/form';
import { logger } from 'api/logger';
import { useCustomToast } from 'hooks/useCustomToast';
import { useMutation, useQueryClient } from 'react-query';
import { errorToast, successToast } from 'utils/toast';
import { queryTenantUsersKey } from './queryTenantUsersKey';

export const useDeleteTenantUsers = () => {
  const toast = useCustomToast();
  const queryClient = useQueryClient();
  const corporationId = useUserCorporationId();

  const { mutateAsync, isLoading } = useMutation({
    mutationFn: (data: Omit<DeleteTenantUsersFormType, 'word'>) =>
      deleteTenantUsers(data, corporationId),
    onError: async (error) => {
      if (error instanceof CustomError) {
        if (error.cause.errors) {
          await logger({
            loglevel: error.logLevel,
            data: JSON.stringify(error.cause),
            message: error.message,
          });
        }
      }
      toast({
        ...errorToast,
        duration: 4000,
        title: 'テナントアカウント削除に失敗しました',
        position: 'bottom',
        variant: 'solid',
      });
    },
    onSuccess: () => {
      void queryClient.invalidateQueries(
        queryTenantUsersKey.getTenantUsers(corporationId),
      );
      toast({
        ...successToast,
        duration: 4000,
        title: 'テナントアカウントを削除しました',
        position: 'bottom',
        variant: 'solid',
      });
    },
  });

  return { mutate: mutateAsync, isLoading };
};
