import { getLogs } from 'admin/api/userPool/terms/getLogs';
import { CustomError } from 'admin/error/CustomError';
import { useUserPoolId } from 'admin/hooks/params/useUserPoolId';
import { logger } from 'api/logger';
import { useMemo } from 'react';
import { useQuery } from 'react-query';
import { useParams } from 'react-router-dom';
import { queryKeys } from './queryKeys';

export const useLogs = () => {
  const userPoolId = useUserPoolId();
  const params = useParams();
  const termId = useMemo(() => params.termId ?? '', [params]);

  const { data, isLoading } = useQuery({
    queryKey: queryKeys.getLogs(termId, userPoolId),
    queryFn: () =>
      getLogs({
        id: termId,
        userPoolId,
      }),
    onError: async (error) => {
      if (error instanceof CustomError) {
        await logger({
          loglevel: error.logLevel,
          data: JSON.stringify(error.cause),
          message: error.message,
        });
      }
    },
  });

  return { data, isLoading };
};
