/* eslint-disable react/require-default-props */
import { Box, HStack, Text } from '@chakra-ui/react';
import { Colors } from 'components/paywall/config';
import { FC, memo } from 'react';

type Props = {
  label: string;
  labelWidth: string;
  text: string | JSX.Element;
  fontWeight?: number;
  fontColor?: string;
  textDecoration?: string;
  spacing: string;
  isCustomerActive?: boolean;
}
export const DetailItem: FC<Props> = memo(({
  spacing,
  label,
  labelWidth,
  text,
  fontWeight = 700,
  fontColor = 'inherit',
  textDecoration = '',
  isCustomerActive = true
}: Props) => (
  <HStack align="flex-start" w="100%" spacing={spacing}>
    <Text
      width={labelWidth}
      minW={labelWidth}
      color={Colors.GRAY_00}
    >
      {label}
    </Text>
    <Box
      fontSize="14px"
      display="inline-block"
      w={`calc(100% - (${spacing} + ${labelWidth}))`}
    >
      <Text
        display="inline-block"
        color={fontColor}
        fontWeight={fontWeight}
        textDecoration={textDecoration}
      >
        {text}
      </Text>
      {!isCustomerActive && (
        <Text as="span">（退会済み）</Text>
      )}
    </Box>
  </HStack>
));
