import { Box, Textarea } from '@chakra-ui/react';
import { Colors } from 'admin/define/colors';
import { TransactionMailFormType } from 'admin/types/userPool/transactionMail';
import { FC, memo } from 'react';
import { useFormContext } from 'react-hook-form';

export const TextEditor: FC = memo(() => {
  const { register } = useFormContext<TransactionMailFormType>();

  return (
    <Box>
      <Textarea
        id="mailTextEditor"
        height="calc(100vh - 250px)"
        borderRadius="0px 0px 0.375rem 0.375rem"
        {...register('editorText')}
        bg={Colors.WHITE}
        border="none !important"
        boxShadow="unset !important"
      />
    </Box>
  );
});
