import { zodResolver } from '@hookform/resolvers/zod';
import { withSuspenseAndErrorBoundary } from 'admin/components/Error/WithErrorBoundary';
import { TermsCreateFormComponent } from 'admin/components/idPoolConsumer/terms/termsForm/termsCreateForm/TermsCreateFormComponent';
import { useIdPoolConsumerId } from 'admin/hooks/useIdPoolConsumerId';
import { useEditTerm, useTerm } from 'admin/hooks/userPool/terms';
import { termCreateFormSchema } from 'admin/schema/idPoolConsumer/terms';
import { TermFormType } from 'admin/types/userPool/terms/form';
import { AlertBar } from 'components/common/atoms';
import { FC, memo, useState } from 'react';
import { FormProvider, SubmitHandler, useForm } from 'react-hook-form';

type Props = {
  onClose: () => void;
  formId: string;
  termId: string;
};

const FetchTermsEditForm: FC<Props> = memo(
  ({ onClose, formId, termId }: Props) => {
    const [globalErrors, setGlobalErrors] = useState<string[]>([]);
    const userPoolId = useIdPoolConsumerId();
    const { data: termValue } = useTerm({
      id: termId,
      userPoolId,
    });

    const methods = useForm<TermFormType>({
      defaultValues: {
        fieldName: termValue?.fieldName,
        displayNameJa: termValue?.displayNameJa ?? '',
        displayNameForeign: termValue?.displayNameForeign ?? '',
        url: termValue?.url,
        displayOrder: termValue?.displayOrder,
      },
      resolver: zodResolver(termCreateFormSchema),
    });

    const { setError } = methods;

    const { mutate, isLoading } = useEditTerm(
      termId,
      setError,
      setGlobalErrors,
    );

    const onSubmit: SubmitHandler<TermFormType> = async (
      data: TermFormType,
    ) => {
      const submitData = {
        ...data,
        id: termId,
      };

      await mutate(submitData);
      onClose();
    };

    return (
      <>
        <FormProvider {...methods}>
          {globalErrors.map((err, idx) => (
            <AlertBar
              key={`global-err-idx${String(idx)}`}
              message={err}
              status="error"
              mb={4}
            />
          ))}
          <TermsCreateFormComponent
            onSubmit={onSubmit}
            formId={formId}
            isLoading={isLoading}
            term={termValue}
          />
        </FormProvider>
      </>
    );
  },
);

export const TermsEditForm = withSuspenseAndErrorBoundary(FetchTermsEditForm);
