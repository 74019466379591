import { Select } from '@chakra-ui/react';
import { ChangeEvent, useCallback, FC } from 'react';
import { useNavigate } from 'react-router-dom';
import { SettingType } from './type';

type Props = {
  prefix: string;
  authHubId: string;
  settingType: SettingType;
  target: string;
};

export const PageSelector: FC<Props> = ({
  prefix,
  authHubId,
  settingType,
  target,
}) => {
  const predicate = ({
    organization,
    tenant,
  }: {
    organization: boolean;
    tenant: boolean;
  }): boolean =>
    (settingType === 'organization' && organization) ||
    (settingType === 'tenant' && tenant);

  const common = [
    {
      key: 'header',
      name: 'ヘッダー',
      path: `${prefix}${authHubId}/header`,
      organization: true,
      tenant: true,
    },
    {
      key: 'footer',
      name: 'フッター',
      path: `${prefix}${authHubId}/footer`,
      organization: true,
      tenant: true,
    },
  ].filter(predicate);
  const register = [
    {
      key: 'register',
      name: '新規登録画面',
      path: `${prefix}${authHubId}/register`,
      organization: false,
      tenant: true,
    },
    {
      key: 'verificationCodeEntry',
      name: '認証コード入力画面',
      path: `${prefix}${authHubId}/verificationCodeEntry`,
      organization: false,
      tenant: true,
    },
    {
      key: 'memberInfoEntry',
      name: '会員情報新規入力画面',
      path: `${prefix}${authHubId}/memberInfoEntry`,
      organization: false,
      tenant: true,
    },
    {
      key: 'infoConfirmation',
      name: '入力情報確認画面',
      path: `${prefix}${authHubId}/infoConfirmation`,
      organization: false,
      tenant: true,
    },
    {
      key: 'registrationComplete',
      name: '新規登録完了画面',
      path: `${prefix}${authHubId}/registrationComplete`,
      organization: false,
      tenant: true,
    },
  ].filter(predicate);
  const login = [
    {
      key: 'login',
      name: 'ログイン画面',
      path: `${prefix}${authHubId}/login`,
      organization: true,
      tenant: true,
    },
    {
      key: 'verificationCodeEntry',
      name: '認証コード入力画面',
      path: `${prefix}${authHubId}/verificationCodeEntry`,
      organization: true,
      tenant: false,
    },
    {
      key: 'infoConfirmation',
      name: '入力情報確認画面',
      path: `${prefix}${authHubId}/infoConfirmation`,
      organization: true,
      tenant: false,
    },
    {
      key: 'passwordUnset',
      name: 'パスワード未設定画面',
      path: `${prefix}${authHubId}/passwordUnset`,
      organization: true,
      tenant: true,
    },
    {
      key: 'firstInfoEntry',
      name: 'サービス初回利用情報入力画面',
      path: `${prefix}${authHubId}/firstInfoEntry`,
      organization: true,
      tenant: true,
    },
    {
      key: 'missingInfoEntry',
      name: '不足情報入力画面',
      path: `${prefix}${authHubId}/missingInfoEntry`,
      organization: true,
      tenant: true,
    },
    {
      key: 'complete',
      name: '完了画面',
      path: `${prefix}${authHubId}/complete`,
      organization: true,
      tenant: true,
    },
  ].filter(predicate);
  const password = [
    {
      key: 'passwordReset',
      name: 'パスワードリセット画面',
      path: `${prefix}${authHubId}/passwordReset`,
      organization: true,
      tenant: true,
    },
    {
      key: 'passwordCreate',
      name: 'パスワード作成画面',
      path: `${prefix}${authHubId}/passwordCreate`,
      organization: true,
      tenant: true,
    },
  ].filter(predicate);
  const mypage = [
    {
      key: 'mypage',
      name: 'マイページ画面',
      path: `${prefix}${authHubId}/mypage`,
      organization: true,
      tenant: false,
    },
    {
      key: 'memberInfoChange',
      name: '会員情報変更画面',
      path: `${prefix}${authHubId}/memberInfoChange`,
      organization: true,
      tenant: false,
    },
    {
      key: 'memberInfoConfirm',
      name: '会員情報確認画面',
      path: `${prefix}${authHubId}/memberInfoConfirm`,
      organization: true,
      tenant: false,
    },
    {
      key: 'emailChange',
      name: 'メールアドレス変更画面',
      path: `${prefix}${authHubId}/emailChange`,
      organization: true,
      tenant: false,
    },
    {
      key: 'newEmailEntry',
      name: '新メールアドレス入力画面',
      path: `${prefix}${authHubId}/newEmailEntry`,
      organization: true,
      tenant: false,
    },
    {
      key: 'passwordChange',
      name: 'パスワード変更画面',
      path: `${prefix}${authHubId}/passwordChange`,
      organization: true,
      tenant: false,
    },
    {
      key: 'newPasswordEntry',
      name: '新パスワード入力画面',
      path: `${prefix}${authHubId}/newPasswordEntry`,
      organization: true,
      tenant: false,
    },
    {
      key: 'withdrawal',
      name: '退会画面',
      path: `${prefix}${authHubId}/withdrawal`,
      organization: true,
      tenant: false,
    },
    {
      key: 'withdrawalConfirmation',
      name: '退会確認画面',
      path: `${prefix}${authHubId}/withdrawalConfirmation`,
      organization: true,
      tenant: false,
    },
    {
      key: 'withdrawalComplete',
      name: '退会完了画面',
      path: `${prefix}${authHubId}/withdrawalComplete`,
      organization: true,
      tenant: false,
    },
  ].filter(predicate);

  const navigate = useNavigate();

  const onChange = useCallback(
    (e: ChangeEvent<HTMLSelectElement>) => {
      navigate(e.target.value);
    },
    [navigate],
  );

  const items = [
    { label: '全画面', data: common },
    { label: '新規会員登録', data: register },
    { label: 'ログイン', data: login },
    { label: 'パスワードリセット', data: password },
    { label: 'マイページ', data: mypage },
  ].filter(({ data }) => data.length > 0);

  const [value] = Object.values(items)
    .map(({ data }) => data)
    .reduce((x, acc) => [...acc, ...x], [])
    .filter((x) => x.key === target)
    .map((x) => x.path);

  return (
    <Select
      bg="white"
      placeholder="選択してください"
      onChange={onChange}
      value={value}
    >
      {items.map(({ label, data }) => (
        <optgroup key={label} label={label}>
          {data.map(({ key, name, path }) => (
            <option key={key} value={path}>
              {name}
            </option>
          ))}
        </optgroup>
      ))}
    </Select>
  );
};
