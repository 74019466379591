import { Page, isPage } from 'api/common/types';

type Attributes = {
  dataType: string;
  id: number;
  title: string;
  url: string;
};

export type Notice = {
  id: string;
  title: string;
  message: string;
  isReaded: boolean;
  attributes: Attributes[];
  createdAt: string;
  updatedAt: string;
};

export type NoticeList = {
  results: Notice[];
  unreadCount: number;
} & Page;

export type updateNoticeType = {
  id: string;
  tenantId: string;
  isReaded: boolean;
  page?: number;
};

export type putNoticeType = {
  page: number;
} & Notice;

export const isNotice = (data: unknown): data is Notice => {
  const n = data as Notice;

  return (
    typeof n?.id === 'string' &&
    typeof n?.title === 'string' &&
    typeof n?.message === 'string' &&
    typeof n?.isReaded === 'boolean' &&
    typeof n?.createdAt === 'string' &&
    typeof n?.updatedAt === 'string'
  );
};

export const isNotices = (datas: unknown[]): datas is Notice[] =>
  !datas.some((d) => !isNotice(d));

export const isNoticeList = (data: unknown): data is NoticeList => {
  const n = data as NoticeList;

  return (
    isPage(n) && isNotices(n?.results) && typeof n?.unreadCount === 'number'
  );
};

export const isPutNotice = (data: unknown): data is putNoticeType => {
  const pn = data as putNoticeType;

  return typeof pn.page === 'number' && isNotice(pn);
};
