import { withSuspenseAndErrorBoundary } from 'admin/components/Error/WithErrorBoundary';
import { FormGroupTable } from 'admin/components/idPoolConsumer/formGroup/formGroupTable';
import { FormGroupTitle } from 'admin/components/idPoolConsumer/formGroup/formGroupTitle';
import { Breadcrumb } from 'admin/components/ui/breadcrumb';
import { Pagination } from 'admin/components/ui/pagination';
import { usePaginationQuery } from 'admin/hooks/pagination/usePaginationQuery';
import { useUserPoolId } from 'admin/hooks/params/useUserPoolId';
import { useUserPool } from 'admin/hooks/userPool/useUserPool';
import { BreadcrumbType } from 'admin/types/ui/breadcrumb';
import { Page } from 'api/common/types';
import { ErrorContents, LoadingSpinner } from 'components/common/atoms';
import { useSetPageTitle } from 'hooks/useSetPageTitle';
import { FC, memo, useMemo, useState } from 'react';

export const formGroup: FC = memo(() => {
  useSetPageTitle('フォーム設問グループ設定');
  const userPoolId = useUserPoolId();
  const { data: userPool } = useUserPool(userPoolId);
  const breadcrumbItems: BreadcrumbType[] = [
    { url: '/admin', title: '組織管理TOP' },
    {
      url: '/admin/idPoolConsumer',
      title: 'IDシステム管理設定',
    },
    {
      url: '',
      title: userPool?.name || '',
    },
    { url: '', title: 'フォーム設問グループ設定' },
  ];

  const [page, setPage] = useState<Page>({
    count: 0,
    currentPage: 0,
    countFrom: 0,
    countTo: 0,
    perPage: 0,
    pageCount: 0,
    next: '',
    previous: '',
  });
  const { query } = usePaginationQuery({ page });
  const currentPage = useMemo(() => query.currentPage, [query.currentPage]);

  return (
    <>
      <Breadcrumb breadcrumbItems={breadcrumbItems} />
      <FormGroupTitle />
      <FormGroupTable currentPage={currentPage} setPage={setPage}/>
      <Pagination page={page}/>
    </>
  );
});

export const FormGroup = withSuspenseAndErrorBoundary(formGroup, {
  ErrorComponent: <ErrorContents name="フォーム設問グループ設定" />,
  LoadingComponent: <LoadingSpinner />,
});
