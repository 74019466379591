import { Box, Heading, Modal, useDisclosure } from '@chakra-ui/react';
import { withSuspenseAndErrorBoundary } from 'admin/components/Error/WithErrorBoundary';
import { AutoAppendModal } from 'admin/components/idPoolConsumer/terms/autoAppendModal';
import { EnableChangeModal } from 'admin/components/idPoolConsumer/terms/enableChangeModal';
import { StatusChangeModal } from 'admin/components/idPoolConsumer/terms/statusChangeModal';
import { TermsEditDrawer } from 'admin/components/idPoolConsumer/terms/termsDrawer/index';
import { VersionupModal } from 'admin/components/idPoolConsumer/terms/versionupModal';
import { LoadingLayer } from 'admin/components/ui/loadingLayer';
import { useIdPoolConsumerId } from 'admin/hooks/useIdPoolConsumerId';
import { useTerms } from 'admin/hooks/userPool/terms/useTerms';
import { TermType } from 'admin/types/userPool/terms';
import { Page } from 'api/common/types';
import { ErrorContents } from 'components/common/atoms';
import {
  Dispatch,
  FC,
  memo,
  SetStateAction,
  useCallback,
  useEffect,
  useState,
} from 'react';
import { AgreeModal } from '../agreeModal';
import { TermsTableComponent } from './TermsTableComponent';

type Props = {
  currentPage: number;
  perPage: number;
  setPage: Dispatch<SetStateAction<Page>>;
};

const fetchTermsTable: FC<Props> = memo(({ currentPage, perPage, setPage }) => {
  const idPoolConsumerId = useIdPoolConsumerId();
  const {
    isOpen: isDrawerOpen,
    onClose: onDrawerClose,
    onOpen: onDrawerOpen,
  } = useDisclosure();

  const {
    isOpen: isEnabledModalOpen,
    onClose: onEnabledModalClose,
    onOpen: onEnabledModalOpen,
  } = useDisclosure();

  const {
    isOpen: isAgreeModalOpen,
    onClose: onAgreeModalClose,
    onOpen: onAgreeModalOpen,
  } = useDisclosure();
  const {
    isOpen: isAutoAppendModalOpen,
    onClose: onAutoAppendModalClose,
    onOpen: onAutoAppendModalOpen,
  } = useDisclosure();
  const {
    isOpen: isOpenStatusChangeModal,
    onClose: onCloseStatusChangeModal,
    onOpen: onOpenStatusChangeModal,
  } = useDisclosure();
  const {
    isOpen: isOpenVersionupModal,
    onClose: onCloseVersionupModal,
    onOpen: onOpenVersionupModal,
  } = useDisclosure();

  const [termId, setTermId] = useState<string>('');
  const [selectedTerm, setSelectedTerm] = useState<TermType | null>(null);

  const formId = `termEditForm${termId}`;

  const {
    data: terms,
    page,
    isLoading: isLoadingTerms,
  } = useTerms({
    id: idPoolConsumerId,
    page: currentPage,
    perPage,
  });

  const agreeModalOpen = useCallback(
    (term: TermType) => {
      onAgreeModalOpen();
      setSelectedTerm(term);
    },
    [onAgreeModalOpen],
  );

  const autoAppendModalOpen = useCallback(
    (term: TermType) => {
      onAutoAppendModalOpen();
      setSelectedTerm(term);
    },
    [onAutoAppendModalOpen],
  );

  const statusChangeModal = useCallback(
    (term: TermType) => {
      onOpenStatusChangeModal();
      setSelectedTerm(term);
    },
    [onOpenStatusChangeModal],
  );

  const enabledModalOpen = useCallback(
    (term: TermType) => {
      onEnabledModalOpen();
      setSelectedTerm(term);
    },
    [onEnabledModalOpen],
  );

  const versionupModal = useCallback(
    (term: TermType) => {
      onOpenVersionupModal();
      setSelectedTerm(term);
    },
    [onOpenVersionupModal],
  );

  useEffect(() => {
    setPage(page);
  }, [setPage, page]);

  if (!terms) {
    return null;
  }

  if (terms.length === 0) {
    return (
      <Box
        w="100%"
        textAlign="center"
        bg="white"
        mt="16px"
        borderRadius={4}
        padding={10}
      >
        <Heading as="h3" fontWeight="bold" fontSize="24px">
          規約が未作成です。
        </Heading>
      </Box>
    );
  }

  return (
    <>
      {isLoadingTerms && <LoadingLayer />}
      <TermsTableComponent
        terms={terms}
        idPoolConsumerId={idPoolConsumerId}
        onDrawerOpen={onDrawerOpen}
        onAgreeModalOpen={agreeModalOpen}
        onAutoAppendModalOpen={autoAppendModalOpen}
        onOpenStatusChangeModal={statusChangeModal}
        onEnabledModalOpen={enabledModalOpen}
        onOpenVersionupModal={versionupModal}
        setTermId={setTermId}
      />
      <TermsEditDrawer
        termId={termId}
        onClose={onDrawerClose}
        isOpen={isDrawerOpen}
        formId={formId}
      />
      <Modal
        isOpen={isAgreeModalOpen}
        onClose={onAgreeModalClose}
        isCentered
        size="xl"
      >
        <AgreeModal onClose={onAgreeModalClose} term={selectedTerm} />
      </Modal>
      <Modal
        isOpen={isAutoAppendModalOpen}
        onClose={onAutoAppendModalClose}
        isCentered
        size="xl"
      >
        <AutoAppendModal onClose={onAutoAppendModalClose} term={selectedTerm} />
      </Modal>
      <Modal
        isOpen={isEnabledModalOpen}
        onClose={onEnabledModalClose}
        isCentered
        size="xl"
      >
        <EnableChangeModal
          onClose={onEnabledModalClose}
          target={selectedTerm}
        />
      </Modal>
      <Modal
        isOpen={isOpenStatusChangeModal}
        onClose={onCloseStatusChangeModal}
        isCentered
        size="xl"
      >
        <StatusChangeModal
          onClose={onCloseStatusChangeModal}
          target={selectedTerm}
        />
      </Modal>
      <Modal
        isOpen={isOpenVersionupModal}
        onClose={onCloseVersionupModal}
        isCentered
        size="xl"
      >
        <VersionupModal onClose={onCloseVersionupModal} term={selectedTerm} />
      </Modal>
    </>
  );
});

export const TermsTable = memo(
  withSuspenseAndErrorBoundary(fetchTermsTable, {
    ErrorComponent: <ErrorContents name="規約設定" />,
  }),
);
