export const messages = {
  // セグメント一覧取得
  getSegmentList: {
    customer: {
      notFound: 'セグメントが存在しません。既に削除された可能性があります。',
      error:
        '申し訳ございません。セグメント一覧情報取得できません。時間を置いて再表示をお願いします。',
      failed:
        '申し訳ありません。正しいセグメント一覧情報を得ることができません。',
    },
    system: {
      typeError:
        '[api.mail.getSegmentList] セグメント一覧取得APIのレスポンス型が予期せぬデータ型です。フロントの修正をしてください',
    },
  },
  // セグメント詳細取得
  getSegmentId: {
    customer: {
      notFound: 'セグメントが存在しません。既に削除された可能性があります。',
      error:
        '申し訳ございません。セグメント詳細が取得できません。時間を置いて再表示をお願いします。',
      failed: '申し訳ありません。正しいセグメント詳細を得ることができません。',
    },
    system: {
      typeError:
        '[api.mail.getSegmentId] セグメント詳細取得APIのレスポンス型が予期せぬデータ型です。フロントの修正をしてください',
    },
  },
  // セグメントデータ属性情報取得
  getSegmentAttributes: {
    customer: {
      error:
        '申し訳ございません。セグメントデータ属性情報が取得できません。時間を置いて再表示をお願いします。',
      failed:
        '申し訳ありません。正しいセグメントデータ属性情報を得ることができません。',
    },
    system: {
      typeError:
        '[api.mail.getSegmentAttributes] セグメントデータ属性情報取得APIのレスポンス型が予期せぬデータ型です。フロントの修正をしてください',
    },
  },
  // セグメント作成
  postSegment: {
    customer: {
      error: '申し訳ございません。セグメント作成に失敗しました。',
      failed:
        'セグメント作成に失敗しました。恐れ入りますが時間をおいて再度登録してください。',
    },
    system: {
      typeError: '[api.user.postSegment] 予期せぬエラーが発生しました。',
    },
  },
  // セグメント修正
  putSegment: {
    customer: {
      error: '申し訳ございません。セグメントの保存に失敗しました。',
      failed:
        'セグメントの保存に失敗しました。恐れ入りますが時間をおいて再度登録してください。',
    },
    system: {
      typeError: '[api.user.putSegment] 予期せぬエラーが発生しました。',
    },
  },
};
