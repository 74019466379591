import { withSuspenseAndErrorBoundary } from 'admin/components/Error/WithErrorBoundary';
import { MemberIdPoolPanelComponent } from 'admin/components/member/memberIdPoolPanel/MemberIdPoolPanelComponent';
import { usePaginationQuery } from 'admin/hooks/pagination/usePaginationQuery';
import { MemberIdPoolConsumerType } from 'admin/types/member';
import { IdPoolConsumerListItem } from 'admin/types/userPool';
import { Page } from 'api/common/types';
import { ErrorContents } from 'components/common/atoms';
import { FC, memo, useMemo, useState } from 'react';

type FetchMemberIdPoolPanelProps = {
  idPoolConsumer: IdPoolConsumerListItem;
  selectedId: string;
};

const fetchMemberIdPoolPanel: FC<FetchMemberIdPoolPanelProps> = memo(
  ({ idPoolConsumer, selectedId }: FetchMemberIdPoolPanelProps) => {
    // TODO: 9月末のリリース時点では必要では無い為、コメントアウト
    // const [refetchFunc, setRefetchFunc] = useState<() => void>(() => ({}));
    // const [searchKeyword, setSearchKeyword] = useState('');
    const [page, setPage] = useState<Page>({
      count: 0,
      currentPage: 1,
      countFrom: 0,
      countTo: 0,
      perPage: 50,
      pageCount: 0,
      next: '',
      previous: '',
    });
    const { query } = usePaginationQuery({ page });
    const currentPage = useMemo(() => query.currentPage, [query.currentPage]);
    const perPage = useMemo(() => query.currentPerPage, [query.currentPerPage]);

    // TODO: 9月末のリリース時点では必要では無い為、コメントアウト
    // const onSearch = useCallback(
    //   (keyword: string) => {
    //     setSearchKeyword(keyword);
    //     refetchFunc();
    //   },
    //   [refetchFunc],
    // );

    return (
      <MemberIdPoolPanelComponent
        idPoolConsumer={idPoolConsumer}
        selectedId={selectedId}
        currentPage={currentPage}
        perPage={perPage}
        page={page}
        setPage={setPage}
        // TODO: 9月末のリリース時点では必要では無い為、コメントアウト
        // searchKeyword={searchKeyword}
        // onSearch={onSearch}
        // setRefetchFunc={setRefetchFunc}
      />
    );
  },
);

const FetchMemberIdPoolPanel = withSuspenseAndErrorBoundary(
  fetchMemberIdPoolPanel,
  {
    ErrorComponent: <ErrorContents name="会員管理詳細情報" />,
  },
);

type Props = {
  idPoolConsumer: IdPoolConsumerListItem;
  selectedId: MemberIdPoolConsumerType['id'];
};

export const MemberIdPoolPanel: FC<Props> = memo(
  ({ idPoolConsumer, selectedId }: Props) => {
    if (idPoolConsumer.id !== selectedId) {
      return null;
    }

    return (
      <FetchMemberIdPoolPanel
        idPoolConsumer={idPoolConsumer}
        selectedId={selectedId}
      />
    );
  },
);
