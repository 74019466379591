import { QuestionIcon } from '@chakra-ui/icons';
import { Tooltip, TooltipProps } from '@chakra-ui/react';
import { FC, memo, ReactNode } from 'react';

type Props = {
  label: string | ReactNode;
} & Omit<TooltipProps, 'children'>;

export const HelpTooltip: FC<Props> = memo(({ label, ...rest }: Props) => (
  <Tooltip label={label} fontSize="12px" orderRadius="4px" {...rest}>
    <QuestionIcon ml={4} cursor="default" marginLeft="0.5rem" />
  </Tooltip>
));
