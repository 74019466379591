import { Box, Flex, VStack } from '@chakra-ui/react';
import { CustomContainerBox } from 'components/chakra/CustomContainerBox';
import { CustomHeaderBox } from 'components/chakra/CustomHeaderBox';
import { CustomMenuBox } from 'components/chakra/sidemenu';
import { NotificationBar } from 'components/common/atoms';
import { Header, SideMenu } from 'components/common/organisms';
import { MouseEvent, useCallback, VFC } from 'react';
import { Helmet } from 'react-helmet-async';
import { Outlet, useLocation } from 'react-router-dom';
import { useRecoilState, useRecoilValue } from 'recoil';
import { pageTitleState } from 'store/pageTitleState';
import { sidebarState } from 'store/sidebarState';

const DEPLOY_MODE = process.env.REACT_APP_DEPLOY_MODE;

export const Template: VFC = () => {
  const [isOpen, setOpen] = useRecoilState(sidebarState);
  const title = useRecoilValue(pageTitleState);
  const location = useLocation();

  // 画面のいずれかクリックでメニュークローズ
  const clickOutSide = useCallback(
    (e: MouseEvent<HTMLDivElement>): void => {
      e.stopPropagation();
      setOpen(false);
    },
    [setOpen],
  );

  return (
    <>
      <Helmet>
        <title>{title}</title>
      </Helmet>
      <VStack spacing={0} onClick={clickOutSide}>
        {DEPLOY_MODE !== 'prd' && <NotificationBar />}
        <CustomHeaderBox>
          <Header />
        </CustomHeaderBox>
        <Box width="full" flexGrow={1}>
          <Flex position="relative">
            <CustomMenuBox isOpen={isOpen}>
              <SideMenu />
            </CustomMenuBox>
            <CustomContainerBox>
              {location.pathname.includes('/enquete/form') ? (
                <Box p={4} bg="#f9f9f9">
                  <Outlet />
                </Box>
              ) : (
                <Box p={4} h="100%">
                  <Outlet />
                </Box>
              )}
            </CustomContainerBox>
          </Flex>
        </Box>
      </VStack>
    </>
  );
};
