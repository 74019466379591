import { Box, Icon, Text, useDisclosure } from '@chakra-ui/react';
import { DrawerForm } from 'components/common/atoms';
import { mailMagazineDefaultValue } from 'components/setting/mailMagazine/initial';
import {
  MailMagazine,
  MailMagazineForm,
} from 'components/setting/mailMagazine/typed';
import { useAddMailMagazine } from 'hooks/mail/useAddMailMagazine';
import { useEditMailMagazine } from 'hooks/mail/useEditMailMagazine';
import { useUserTenantId } from 'hooks/user/useUserTenantId';
import { FC, memo, useCallback } from 'react';
import { FormProvider, useForm } from 'react-hook-form';
import { MdCreate } from 'react-icons/md';
import { Form } from './Form';

type Props = {
  /* eslint react/require-default-props: 0 */
  isAdd?: boolean;
  buttonName?: string;
  obj?: MailMagazine;
};

/**
 * @see メルマガの新規作成＆編集ドロワー
 * @param {boolean} isAdd 新規作成 or 編集
 * @param {string} buttonName ボタン名
 * @param {MailMagazine} obj メルマガデータ
 */
export const MailMagazineDrawer: FC<Props> = memo(
  ({ isAdd, buttonName, obj }: Props) => {
    const tenantId = useUserTenantId();
    const { isOpen, onClose, onOpen } = useDisclosure();
    const methods = useForm<MailMagazineForm>({
      mode: 'onBlur',
      defaultValues: mailMagazineDefaultValue,
    });
    const formId = 'setting-mailMagazine-form';
    const {
      setValue,
      setError,
      clearErrors,
      reset,
      formState: { errors },
    } = methods;
    const { onSubmit: onAddMailMagazine, isLoading: addLoading } =
      useAddMailMagazine({
        setError,
        close: onClose,
        reset,
      });
    const { onSubmit: onEditMailMagazine, isLoading: editLoading } =
      useEditMailMagazine({
        setError,
        close: onClose,
        reset,
      });

    const closeBtnHandler = useCallback(() => {
      onClose();
      clearErrors();
      reset();
    }, [onClose, reset, clearErrors]);

    // ドロワーを開くときの処理
    const onClickEdit = useCallback(() => {
      setValue('tenant', tenantId);
      if (!isAdd) {
        setValue('id', obj?.id || mailMagazineDefaultValue.id);
        setValue('title', obj?.title || mailMagazineDefaultValue.title);
        setValue(
          'description',
          obj?.description || mailMagazineDefaultValue.description,
        );
        setValue(
          'targetType',
          obj?.targetType || mailMagazineDefaultValue.targetType,
        );
        setValue('color', obj?.color || mailMagazineDefaultValue.color);
        setValue('sort', obj?.sort || mailMagazineDefaultValue.sort);
      }
      onOpen();
    }, [onOpen, setValue, isAdd, obj, tenantId]);

    // フォーム送信する処理
    const formSubmit = useCallback(
      (data: MailMagazineForm) => {
        if (isAdd) {
          onAddMailMagazine(data);
        } else {
          onEditMailMagazine(data);
        }
      },
      [isAdd, onAddMailMagazine, onEditMailMagazine],
    );

    return (
      <Box
        display="flex"
        alignItems="center"
        justifyContent="flex-end"
        position="relative"
      >
        <FormProvider {...methods}>
          <DrawerForm
            title={isAdd ? 'メルマガ新規作成' : 'メルマガ編集'}
            openBtnChildNode={
              isAdd ? (
                <Text as="span">{buttonName}</Text>
              ) : (
                <>
                  <Icon as={MdCreate} fontSize={20} color="gray.500" />
                </>
              )
            }
            openBtnProps={{ variant: isAdd ? 'primary' : 'ghost' }}
            cancelBtnTitle="キャンセル"
            cancelBtnHandelr={closeBtnHandler}
            cancelBtnProps={{ disabled: addLoading || editLoading }}
            submitBtnTitle="保存"
            submitBtnProps={{
              variant: 'primary',
              disabled:
                Object.keys(errors).length > 0 || addLoading || editLoading,
              isLoading: addLoading || editLoading,
            }}
            isOpen={isOpen}
            onOpen={onClickEdit}
            onClose={closeBtnHandler}
            closeOnOverlayClick={false}
            closeOnEsc={false}
            autoFocus={false}
            size="md"
            formId={formId}
            drawerCloseButtonProps={{ disabled: addLoading || editLoading }}
            drawerFooterJustify="space-between"
          >
            <Form formId={formId} submit={formSubmit} isAdd={isAdd} />
          </DrawerForm>
        </FormProvider>
      </Box>
    );
  },
);
