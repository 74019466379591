import { VFC, useEffect, useState } from 'react';
import {
  useFormContext,
  useController,
  FieldArrayWithId,
} from 'react-hook-form';
import { Flex, Select, IconButton, Box } from '@chakra-ui/react';
import { BsTrash } from 'react-icons/bs';
import { PERMISSION_OWNER } from 'define';
import { UserTenantEditForm } from 'api/user/types';
import { IndentGroupListType } from 'hooks/tenant/useIndentGroupList';
import { toErrMsgList } from 'utils/form';
import { ErrorTextMsg } from 'components/common/atoms';

type GroupSelectProps = {
  index: number;
  name: `groups.${number}.id`;
  fields: FieldArrayWithId<UserTenantEditForm, 'groups', 'key'>[];
  groupList: IndentGroupListType[];
  remove: (idx: number) => void;
};

export const GroupSelect: VFC<GroupSelectProps> = ({
  index,
  name,
  fields,
  groupList,
  remove,
}: GroupSelectProps) => {
  const [isEdit, setIsEdit] = useState(false);
  const { control, getValues, watch } = useFormContext<UserTenantEditForm>();

  // グループIDリスト
  const groupIds = fields.map((_, idx) => watch(`groups.${idx}.id`));

  useEffect(() => {
    setIsEdit(getValues('tenantId') !== undefined);
  }, [getValues, setIsEdit]);

  // 入力されたグループのユニークチェック
  const isGroupIdUnique = (): boolean => {
    if (groupIds[index] === '') return true;

    return groupIds.length === new Set(groupIds).size;
  };

  // 入力必須バリデーション
  const isGroupRequired = (): boolean => {
    if (Number(getValues('permission')) === PERMISSION_OWNER) return true;
    if (groupIds[index] !== '') return true;

    return false;
  };

  const {
    field: { ...formRest },
    fieldState: { error },
  } = useController({
    name,
    control,
    rules: {
      validate: {
        isUnique: () =>
          isGroupIdUnique() || '同じグループを選択することはできません',
        isRequired: () => isGroupRequired() || '所属グループは入力必須です',
      },
    },
  });

  return (
    <>
      <Flex mb={2}>
        <Select id="group" {...formRest}>
          {!isEdit && <option value="">なし</option>}
          {groupList.map((grp) => (
            <option key={grp.id} value={grp.id}>
              {grp.name}
            </option>
          ))}
        </Select>
        <Box textAlign="center" ml={2}>
          <IconButton
            variant="ghost"
            aria-label="addGroups"
            fontSize={20}
            icon={<BsTrash />}
            onClick={() => remove(index)}
          />
        </Box>
      </Flex>
      {toErrMsgList({ [name]: error }, name).map((err, idx) => (
        <ErrorTextMsg key={`error-${name}-${String(idx)}`} msg={err} />
      ))}
    </>
  );
};
