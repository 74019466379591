import { request } from 'api/request';
import { isResponseError } from 'api/types';
import { Options } from 'ky';

/**
 * グループ削除API
 *
 * 状態により以下のレスポンスを返却します
 * 正常時: true, 異常時: エラーメッセージリスト
 *
 */
export const deleteGroup = async ({
  tenantId,
  groupId,
  options,
}: {
  tenantId: string;
  groupId: string;
  options?: Options;
}): Promise<boolean | string[]> => {
  const response = await request({
    path: `tenant/${tenantId}/group/${groupId}/`,
    method: 'delete',
    options,
  });

  if (response.error) {
    let errMessageList: string[] = [];
    if (isResponseError(response.error)) {
      if (Array.isArray(response.error?.nonFieldErrors)) {
        errMessageList = response.error.nonFieldErrors.map(
          (d: string): string => d,
        );
      }
    }
    if (!errMessageList.length) {
      errMessageList.push('グループを削除できませんでした');
    }

    return errMessageList;
  }

  return !response.hasError;
};
