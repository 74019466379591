import { Box, Button, ButtonGroup, Flex, FormControl, FormErrorMessage, Heading } from '@chakra-ui/react';
import { RhfInput } from 'admin/components/form/RhfInput';
import { RequiredIcon } from 'admin/components/ui/icon/requiredIcon';
import { Colors } from 'admin/define/colors';
import { FormGroupDetailType } from 'admin/types/userPool/formGroup';
import { FC, memo } from 'react';
import { useFormState } from 'react-hook-form';
import { toErrMsgList } from 'utils/form';

type Props = {
  formId: string;
};

export const DetailTitle: FC<Props> = memo(({ formId }: Props) => {
  const { errors } = useFormState<FormGroupDetailType>();

  return (
    <Box w="100%">
      <Heading
        as="h2"
        fontSize="14px"
        display="inline"
        whiteSpace="nowrap"
      >
        <Box display="flex" alignItems="center">
          タイトル
          <RequiredIcon ml="8px" bgColor={Colors.WHITE}/>
        </Box>
      </Heading>
      <Flex mt="8px" justifyContent="flex-end">
        <Box w="100%" mr="16px">
          <FormControl
            isInvalid={
              Boolean(errors.name?.message) ||
              toErrMsgList(errors, 'name').length > 0
            }
          >
            <Box>
              <RhfInput<FormGroupDetailType>
                name="name"
                type="text"
                bgColor={Colors.WHITE}
              />
            </Box>
            {toErrMsgList(errors, 'name').map((err) => (
              <FormErrorMessage key={`name${err}`}>
                {err}
              </FormErrorMessage>
            ))}
          </FormControl>
        </Box>
        <Box>
          <ButtonGroup spacing={4}>
            <Button
              type="submit"
              form={formId}
              p="8px 16px"
              h="40px"
              w="96px"
              colorScheme="primary"
            >
              保存
            </Button>
          </ButtonGroup>
        </Box>

      </Flex>
    </Box>
  );
});
