import { ButtonProps, Button as ChakraButton } from '@chakra-ui/react';
import styled from '@emotion/styled';

import { StyledFlex } from 'components/paywall/styled/global';
import { memo, VFC } from 'react';
import { NavLink } from 'react-router-dom';

const StyledChakraButton = styled(ChakraButton)<{
  mode: string;
}>(({ mode }) => ({
  minWidth: 0,
  padding: 0,
  margin: 0,
  color: mode === 'simple' ? '#444' : '',
  ':hover': {
    color: '#06f',
  },
  textDecoration: 'underline',
}));

type Props = {
  mode?: 'simple' | '';
  hasIcon?: boolean;
  icon?: React.ReactNode;
  iconPosition?: 'left' | 'right';
  variant?: ButtonProps['variant'];
  colorScheme?: ButtonProps['colorScheme'];
  size?: ButtonProps['size'];
  disabled?: boolean;
  children?: React.ReactNode;
  to?: string;
  onClick?: (e?: any, arg?: any) => void;
} & ButtonProps;

/**
 * @see 汎用テキストボタン
 * @param mode ボタンのモード
 * @param variant ボタンの種類
 * @param size ボタンのサイズ
 * @param disabled ボタン実行の可否
 * @param children ボタンの子要素
 * @param to ジャンプ先のパス
 * @param onClick ボタンクリックのイベントハンドラ
 */
export const TextButton: VFC<Props> = memo(
  ({
    mode = 'simple',
    hasIcon = 'false',
    icon,
    iconPosition = 'left',
    variant = 'solid',
    colorScheme = 'white',
    size = 'xs',
    disabled,
    children,
    to,
    onClick,
    ...props
  }) => (
    <StyledChakraButton
      mode={mode}
      variant={variant}
      colorScheme={colorScheme}
      size={size}
      disabled={disabled}
      onClick={onClick}
      {...props}
    >
      <StyledFlex>
        {hasIcon && iconPosition === 'left' && <>{icon && <>{icon}</>}</>}
        {to && <NavLink to={to}>{children}</NavLink>}
        {!to && <>{children}</>}
        {hasIcon && iconPosition === 'right' && <>{icon && <>{icon}</>}</>}
      </StyledFlex>
    </StyledChakraButton>
  ),
);
