import { SelectViewerProps, DataType } from './types';

export const SelectViewer = <T extends DataType>(
  props: SelectViewerProps<T>,
): JSX.Element => {
  const { cell } = props;
  const { value, options } = cell;

  if (!options) {
    return <></>;
  }

  const selectOption = options.find((o) => o.value === value);

  return <div>{selectOption?.label}</div>;
};
