import { CustomError } from 'api/error/CustomError';
import { logger } from 'api/logger';
import { request } from 'api/request';
import { isMailSettingPersonal, MailSettingPersonal, MailSettingStatuses } from 'components/paywall/pages/MailSetting/typed';
import { Options } from 'ky';
import { snakeCase } from 'utils/str';
import { messages } from './messages';

/**
 * ペイウォールメール設定取得 API
 * @param {string} tenantId テナントID
 * @param {keyof MailSettingStatuses} type メール設定タイプ
 * @return {Promise<MailSettingPersonal>} 規約情報
 */
export const getMailSettingPersonal = async (
  tenantId: string,
  type: keyof MailSettingStatuses,
  options?: Options,
): Promise<MailSettingPersonal> => {
  const target = 'paywall';
  const path = `${target}/${tenantId}/mail/${snakeCase(type)}/`;

  const response = await request({
    path,
    method: 'get',
    options,
  });

  const responseData = (await response.data) as unknown[];

  if (response.hasError) {
    if (response.status === 404) {
      throw new CustomError(
        messages.getMailSettingPersonal.customer.notFound,
        response.status,
      );
    } else {
      throw new CustomError(messages.getMailSettingPersonal.customer.error, response.status);
    }
  }

  if (!isMailSettingPersonal(responseData)) {
    await logger({
      loglevel:
        response.status === 404 || response.status === 450
          ? 'warning'
          : 'error',
      data: responseData,
      message: messages.getMailSettingPersonal.system.typeError,
    });

    throw new CustomError(messages.getMailSettingPersonal.customer.failed, response.status);
  }

  return responseData;
};
