import { VFC } from 'react';
import {
  Accordion,
  AccordionItem,
  AccordionButton,
  AccordionPanel,
  AccordionIcon,
  Text,
  Flex,
} from '@chakra-ui/react';
import { QuestionOutlineIcon } from '@chakra-ui/icons';
import { useCopy } from 'hooks/useCopy';
import { Copy } from 'components/common/atoms';

type CsvFileUploadInfoType = {
  csvHeaders: string;
};

export const CsvFileUploadInfo: VFC<CsvFileUploadInfoType> = ({
  csvHeaders,
}) => {
  const { copy } = useCopy();

  return (
    <Accordion allowMultiple mb={2} ml={6}>
      <AccordionItem>
        <AccordionButton
          bg="gray.50"
          fontSize="sm"
          justifyContent="space-between"
        >
          <Flex alignItems="center">
            <QuestionOutlineIcon />
            <Text ml={4}>CSVファイルのヘッダー行について</Text>
          </Flex>
          <AccordionIcon />
        </AccordionButton>
        <AccordionPanel pt={4} pb={4} fontSize="xs">
          <Text mb={2}>
            メール内に、以下の差し込み配信の項目が使用されています。これらの項目をアップロードするCSVファイルのヘッダー行へ設定してください。
          </Text>
          {csvHeaders !== '' && (
            <Flex
              alignItems="center"
              justifyContent="space-between"
              p={2}
              bg="blackAlpha.50"
            >
              <Text wordBreak="break-word">{csvHeaders}</Text>
              <Copy
                label="クリップボードコピー"
                onCopy={() => copy(csvHeaders)}
              />
            </Flex>
          )}
        </AccordionPanel>
      </AccordionItem>
    </Accordion>
  );
};
