import { Options } from 'ky';
import { request } from 'api/request';
import { logger } from 'api/logger';
import { CustomError } from 'api/error/CustomError';
import {
  WallDesign,
  isWallDesign,
} from 'components/paywall/pages/WallDesignList/typed';
import { WallDesignDefaultValues } from 'components/paywall/pages/WallDesignList/initial';
import { messages } from './messages';

/**
 * ウォールデザイン詳細取得 API
 * @param {string} tenantId テナントID
 * @param {string} wallId ウォールID
 * @param {string} designId デザインID
 * @param {Options} options APIオプション
 * @return {Promise<WallDesign>} ウォールデザイン詳細
 */
export const getWallDesign = async (
  tenantId: string,
  wallId: string,
  designId: string,
  options?: Options,
): Promise<WallDesign> => {
  const target = 'paywall';
  const path = `${target}/${tenantId}/${wallId}/design/${designId}/`;
  // 新規作成時はAPIを実行しない
  if (!designId) return WallDesignDefaultValues;

  const response = await request({
    path,
    method: 'get',
    options,
  });

  const responseData = (await response.data) as unknown[];

  if (response.hasError) {
    if (response.status === 404) {
      throw new CustomError(
        messages.getWallDesign.customer.notFound,
        response.status,
      );
    } else {
      throw new CustomError(
        messages.getWallDesign.customer.error,
        response.status,
      );
    }
  }

  if (!isWallDesign(responseData)) {
    await logger({
      loglevel:
        response.status === 404 || response.status === 450
          ? 'warning'
          : 'error',
      data: responseData,
      message: messages.getWallDesign.system.typeError,
    });

    throw new CustomError(
      messages.getWallDesign.customer.failed,
      response.status,
    );
  }

  return responseData;
};
